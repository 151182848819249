import React from "react";

const KitchenIcon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 126 180" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_30_16)">
                    <path
                         d="M30.3544 6.43803C24.5173 6.43803 18.8519 6.35219 13.1436 6.43803C9.96753 6.48095 7.43526 9.14198 7.13482 12.4039C7.0919 13.0477 7.13482 13.7344 7.13482 14.4211C7.13482 64.766 7.13482 115.068 7.13482 165.413C7.13482 171.164 9.45249 173.482 15.1179 173.482C41.8999 173.482 68.6389 173.482 95.4209 173.482C100.872 173.482 103.189 171.164 103.189 165.628C103.189 157.988 103.189 150.348 103.189 142.751C103.189 141.936 103.189 141.12 103.447 140.391C103.919 138.889 105.078 138.073 106.623 138.202C108.168 138.331 109.198 139.232 109.499 140.777C109.499 140.992 109.584 141.163 109.541 141.378C109.541 150.391 109.67 159.447 109.413 168.46C109.198 174.641 103.576 179.662 97.2235 179.877C93.3607 179.963 89.455 179.877 85.5922 179.877C62.0293 179.877 38.4663 179.877 14.9033 179.877C5.93307 179.877 0.653931 174.641 0.653931 165.713C0.69685 115.197 0.69685 64.6802 0.69685 14.1636C0.69685 5.2792 5.97599 6.10352e-05 14.7745 6.10352e-05C41.7711 6.10352e-05 68.7247 6.10352e-05 95.6784 6.10352e-05C104.305 6.10352e-05 109.584 5.23628 109.584 13.8632C109.584 21.6746 109.584 29.5289 109.584 37.3403C109.584 37.9412 109.584 38.542 109.499 39.1429C109.198 40.7309 108.168 41.6323 106.58 41.7181C104.992 41.8039 103.876 41.0314 103.404 39.4863C103.189 38.7137 103.189 37.8982 103.189 37.1257C103.189 29.3572 103.189 21.5887 103.189 13.8202C103.189 8.88446 100.7 6.43803 95.8071 6.43803C91.1289 6.43803 86.4506 6.52387 81.7724 6.43803C80.356 6.43803 79.7551 6.86723 79.4118 8.19774C78.9397 10.0004 78.2959 11.7601 77.695 13.5198C77.137 15.2366 75.9782 16.1379 74.0897 16.1379C61.5142 16.1379 48.9387 16.1379 36.3632 16.1379C34.5177 16.1379 33.3588 15.2795 32.8009 13.5198C32.0712 11.245 31.2558 8.9703 30.4403 6.52387L30.3544 6.43803ZM72.9738 6.60971H37.3933C37.3933 9.22782 38.7238 9.74286 40.9985 9.69993C50.4838 9.57118 60.012 9.65702 69.4973 9.65702C71.443 9.65702 72.6018 8.64125 72.9738 6.60971Z"
                         fill="black"
                    />
                    <path d="M54.399 85.0991C51.18 85.0991 47.961 85.0991 44.7849 85.0991C38.9478 85.0562 34.9134 81.0646 34.8704 75.2705C34.8275 68.6608 34.8275 62.0082 34.8704 55.3986C34.9134 49.7761 39.0337 45.6987 44.6991 45.6558C51.3087 45.6558 57.9613 45.6129 64.5709 45.6558C70.1076 45.6558 74.2279 49.7761 74.2708 55.3127C74.3137 62.0512 74.3567 68.8325 74.2708 75.5709C74.2279 80.893 70.1505 84.9274 64.8714 85.0562C61.3949 85.142 57.9184 85.0562 54.4419 85.0562L54.399 85.0991ZM54.399 78.6611C57.6179 78.6611 60.794 78.6611 64.013 78.6611C66.674 78.6611 67.7899 77.5452 67.7899 74.8842C67.7899 68.5321 67.7899 62.1799 67.7899 55.8278C67.7899 53.2955 66.6311 52.0938 64.1418 52.0938C57.7467 52.0938 51.3087 52.0938 44.9137 52.0938C42.4243 52.0938 41.2655 53.2526 41.2655 55.7849C41.2655 62.137 41.2655 68.4891 41.2655 74.8413C41.2655 77.5881 42.3814 78.6611 45.2141 78.6611C48.3043 78.6611 51.3517 78.6611 54.4419 78.6611H54.399Z" fill="black" />
                    <path d="M98.5632 89.7775C101.911 89.7775 105.259 89.7775 108.606 89.7775C114.143 89.8633 118.263 93.8119 118.349 99.3915C118.435 106.087 118.435 112.782 118.349 119.478C118.263 125.143 114.143 129.178 108.521 129.221C101.911 129.264 95.2583 129.264 88.6487 129.221C83.2408 129.178 79.1205 125.358 78.9917 119.993C78.8201 112.954 78.8201 105.958 78.9917 98.9194C79.1205 93.5973 83.1979 89.9062 88.5628 89.8204C91.9106 89.7775 95.2583 89.8204 98.6061 89.8204L98.5632 89.7775ZM98.7778 96.2154C95.5588 96.2154 92.3827 96.2154 89.1637 96.2154C86.4598 96.2154 85.3439 97.2884 85.3009 99.9495C85.258 106.302 85.258 112.654 85.3009 119.006C85.3009 121.667 86.5027 122.783 89.1208 122.826C95.4729 122.826 101.825 122.826 108.177 122.826C110.667 122.826 111.911 121.624 111.954 119.178C111.997 112.782 111.997 106.345 111.954 99.9495C111.954 97.4172 110.752 96.3013 108.22 96.3013C105.087 96.3013 101.954 96.3013 98.8207 96.3013L98.7778 96.2154Z" fill="black" />
                    <path d="M98.5203 85.0988C95.2583 85.0988 91.9535 85.0988 88.6916 85.0988C83.155 85.013 79.1205 81.279 78.9917 75.7423C78.8201 68.8751 78.8201 61.9651 78.9917 55.055C79.1205 49.5612 83.2408 45.7414 88.7345 45.6984C95.3442 45.6555 101.997 45.6555 108.606 45.6984C114.143 45.6984 118.306 49.7758 118.392 55.3125C118.521 62.0509 118.521 68.8322 118.392 75.5706C118.306 81.1073 114.143 85.0559 108.563 85.0988C105.216 85.0988 101.868 85.0988 98.5203 85.0988ZM98.649 78.6609C101.868 78.6609 105.044 78.6609 108.263 78.6609C110.752 78.6609 111.954 77.502 111.954 74.9698C111.954 68.6176 111.954 62.2655 111.954 55.9134C111.954 53.2953 110.752 52.0935 108.134 52.0935C101.782 52.0935 95.43 52.0935 89.0779 52.0935C86.5456 52.0935 85.3439 53.2094 85.3009 55.6988C85.258 62.0938 85.258 68.5318 85.3009 74.9268C85.3009 77.5879 86.4598 78.6179 89.1637 78.6179C92.2969 78.6179 95.43 78.6179 98.5632 78.6179L98.649 78.6609Z" fill="black" />
                    <path d="M54.6994 89.7773C57.9613 89.7773 61.2661 89.7773 64.528 89.7773C70.1934 89.8631 74.2279 93.8547 74.2708 99.563C74.3137 106.173 74.3137 112.825 74.2708 119.435C74.2708 125.057 70.1076 129.178 64.4851 129.178C57.8755 129.221 51.2229 129.221 44.6132 129.178C38.9907 129.135 34.9134 125.014 34.8704 119.349C34.8275 112.739 34.8275 106.087 34.8704 99.4772C34.9134 93.8118 38.9478 89.8202 44.6562 89.7344C48.0039 89.7344 51.3517 89.7344 54.6994 89.7344V89.7773ZM54.6565 122.783C57.7896 122.783 60.9228 122.783 64.0559 122.783C66.674 122.783 67.8329 121.624 67.8758 119.006C67.8758 112.654 67.8758 106.301 67.8758 99.9493C67.8758 97.3741 66.7599 96.2582 64.2276 96.2153C57.8325 96.2153 51.3946 96.2153 44.9995 96.2153C42.4673 96.2153 41.3084 97.3312 41.3084 99.8635C41.3084 106.259 41.3084 112.696 41.3084 119.092C41.3084 121.581 42.5102 122.74 45.0424 122.74C48.2614 122.74 51.4375 122.74 54.6565 122.74V122.783Z" fill="black" />
                    <path d="M55.1611 167.044C51.1266 167.044 47.1351 167.044 43.1006 167.044C40.5254 167.044 39.152 165.885 39.152 163.825C39.152 161.764 40.5683 160.649 43.1435 160.649C51.1695 160.649 59.1955 160.649 67.2215 160.649C69.7109 160.649 71.256 161.893 71.2131 163.868C71.2131 165.842 69.668 167.044 67.1786 167.087C63.1441 167.087 59.1526 167.087 55.1181 167.087L55.1611 167.044Z" fill="black" />
               </g>
               <defs>
                    <clipPath id="clip0_30_16">
                         <rect width="125.24" height="179.92" fill="white" transform="translate(0.696167)" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default KitchenIcon;
