import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import GridViewOptions from '../../../../components/gridView/gridViewOptions';
import GridViewButton from '../../../../components/gridView/gridViewButton';
import { EnumDays, EnumWeeks, EnumMonths } from '../../../../utils/enums/enumUtil';
import * as reportStyles from '../../../reports/reportUtils/styles';

import IncidentType from './components/IncidentType';

const arraySort = require('array-sort');

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');

const stringUtil = require('../../../../utils/string/stringUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const validator = require('../../../../utils/validator/validator');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const passportSelectors = require('../../../../utils/state/stateSelectors/passportSelectors');

class IncidentTypes extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadIncidentTypes = this.loadIncidentTypes.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Incident Types";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadIncidentTypes;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        gridViewOptions.getNewRow = () => ({
            // creation node
            franchisorId: currentOrgNode.franchisorId,
            incidentTypeQuestions: []
        });

        gridViewOptions.getComponent = (isNew, row) =>
            <IncidentType
                data={row}
                isRowReadOnly={this.props.isReadOnly} />;

        gridViewOptions.getComponentTitle = (isNew, row) => isNew ? "Incident Type - New" : `Incident Type - ${row.name}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = this.isRowReadOnly;
        gridViewOptions.getRowStyle = this.getRowStyle;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.buttons = [this.getDeleteIncidentTypeActionButton()];

        gridViewOptions.messageContext = "Incident Types";

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new incident type.";
            return "Please wait while updating incident type.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New incident type added successfully";
            return "IncidentType updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new incident type:";
            return "Following error occurred while updating incident type:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row) => this.props.isReadOnly;

    getRowStyle = (params) =>
    {
        if (this.isRowReadOnly(params.data)) 
        {
            return { background: reportStyles.readOnlyRowBackgroundColor };
        }

        return null;
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.name))
            return "Incident Type Name cannot be left empty";

        if(!row.incidentTypeQuestions || row.incidentTypeQuestions.length < 1)
            return "No Questions provided for the incident type";

        return null;
    }

    async loadIncidentTypes()
    {
        const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/foodsafety/incidents/incidentTypes`;
        const incidentTypes = await rmsApiProxy.get(resourceUrl);

        return arraySort(incidentTypes, stringUtil.localeCompareProp("name"));
    }

    async save(isNew, row, remoteData)
    {
        if (isNew)
        {
            row.createdOn = dateUtil.getNow();
            row.lastUpdatedOn = null;
        }
        else
        {
            row.lastUpdatedOn = dateUtil.getNow();
        }

        try
        {
            const updatedIncidentType = await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/foodsafety/incidents/incidentTypes`, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(incidentType => incidentType.id == updatedIncidentType.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedIncidentType);
            else
                remoteData.push(updatedIncidentType);

            const response = {};
            response.remoteData = arraySort(remoteData, stringUtil.localeCompareProp("name"));;
            response.addUpdatedRow = updatedIncidentType;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    getDeleteIncidentTypeActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Delete Incident Type";
        button.color = "danger";

        button.onRowSelect = true;
        button.actionType = "action";

        button.actionConfirmationTitle = "Delete Incident Type";
        button.onActionConfirmationMessage = (selectedIncidentType) => 
        {
            return `Do you wish to delete selected incidentType "${selectedIncidentType.name}" ?`
        }

        button.onActionWaitMessage = (selectedIncidentType, customButton) => "Please wait while deleting incident type ...";
        button.onActionSuccessMessage = (selectedIncidentType, customButton) => "Selected incident type deleted successfully";
        button.onActionFailMessage = (selectedIncidentType, customButton, error) => error.toString();

        button.onAction = async (selectedIncidentType, remoteData, customButton) =>
        {
            const incidentTypeId = selectedIncidentType.id;

            try
            {
                await rmsApiProxy.deleted(`${rmsApiProxy.getFranchisorOrgContextUrl()}/foodsafety/incidents/incidentTypes?incidentTypeId=${incidentTypeId}`)

                if (!remoteData) remoteData = [];

                // Delete incidentType from the remoteData collection
                const existingIndex = remoteData.findIndex(incidentType => incidentType.id === incidentTypeId);
                remoteData.splice(existingIndex, 1);

                const response = {};
                response.remoteData = remoteData;
                response.addUpdatedRow = null;

                return response;

            } catch (error)
            {
                throw new Error(`Failed to delete incident type with error: ${error}`);
            }
        }

        return button;
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name - 50;
        headers.push(header);


        header = {};
        header.colId = "incidentTypeQuestions";
        header.valueGetter = params => params.data.incidentTypeQuestions.length;
        header.headerName = "Questions";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "createdOn";
        header.headerName = "Created On";
        header.width = columnWidthConstants.dateTime;
        header.type = dataTypeConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "lastUpdatedOn";
        header.headerName = "Last Updated On";
        header.width = columnWidthConstants.dateTime;
        header.type = dataTypeConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default IncidentTypes;