const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');

const rmsApiProxy = require('./rmsApiProxy');


export async function getTaxDetails(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/vat`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getSales(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getSalesDetails(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/details`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getSalesSummary(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/summary`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getTipsSummary(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/tips/summary`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getPaymentMethodsSummary(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/paymentMethods/summary`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getChargesSummary(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/charges/summary`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getDiscountsSummary(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/discounts/summary`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

export async function getRefundsSummary(fromDate, toDate)
{
    const resourceUrl = `${getCurrentReportsContextUrl()}/accounts/sales/refunds/summary`;
    const queryParams = `fromDate=${fromDate}&toDate=${toDate}`;

    let data = await rmsApiProxy.get(`${resourceUrl}?${queryParams}`);
    return data;
}

function getCurrentReportsContextUrl()
{
    // Routes starting with api/reports serve snowflake based reports

    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

    if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
    {
        return `reports/${orgContext.franchisorId}`;
    }
    else if (currentOrgNodeSelectors.isCurrentFranchiseeSelected())
    {
        return `reports/${orgContext.franchisorId}/${orgContext.franchiseeId}`;
    }
    else if (currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        return `reports/${orgContext.franchisorId}/${orgContext.franchiseeId}/${orgContext.propertyId}`;
    }
    else
    {
        return 'reports/system';
    }
}