
import React, { Component } from 'react';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

class CdnImage extends Component 
{

    render()
    {
        var grayScaleTransformation = null;
        if (this.props.grayScale)
            grayScaleTransformation = <Transformation effect="grayscale" />

        return (<CloudinaryContext cloudName={this.props.cloudName}>
                    <div align="middle">
                        <Image gravity="custom" publicId={this.props.publicId}>
                            <Transformation quality="100" {...this.props} gravity="custom" crop="fill" />
                            {grayScaleTransformation}
                        </Image>
                    </div>
                </CloudinaryContext>);
    }
}

export default CdnImage;