import React, { Component } from 'react';
import { Card, CardHeader } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import SearchBarComponent from '../../../../../components/form/searchBarComponent';
import CloseButton from '../../../../../components/button/closeButton';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EmptyData from '../../../../../components/empty/emptyData';
import SimpleToolTip from '../../../../../components/tooltip/simpleToolTip';
import BrandWiseTabbedReport from '../../../reportUtils/components/BrandWiseTabbedReport';

import { getFormattedData } from './utils/dataUtils';
import VatDetailData from './components/VatDetailData';

import { isNumeric } from '../../../../../utils/validator/validator';

const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const reportsLoadingFacade = require('../../../../../utils/api/reportsLoadingFacade');

const formatter = require('../../../../../utils/formatter/formatter');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');


class SalesVatAnalytics extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            searchCriteria: null,
            data: null,
            brands: null,
            showBrandWise: false,
            isLoading: false,
            error: null,
        }
    }

    get vatCaption() { return localizationUtils.getVatCaption() }

    render()
    {
        let component;

        const { isLoading, error } = this.state;

        if (isLoading === true) 
        {
            component = null;
        }
        else if (error != null)
        {
            component = <ErrorMessage message={error} />
        }
        else
        {
            component = this.renderData();
        }

        return (
            <div className='d-flex flex-column'>

                <Card>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <div>
                            <SearchBarComponent
                                searchSingleDate={false}
                                onChange={this.onSearchCriteriaChanged}
                                onSearch={this.onSearch}
                            />
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='fw-bold'>Sales {this.vatCaption} Analytics (*New)</div>
                            <div className='text-center text-muted' style={{ fontSize: "0.65em" }}>(rounding errors excepted)</div>
                        </div>
                        <div>
                            <CloseButton disabled={false} />
                        </div>
                    </CardHeader>
                </Card>

                <div>
                    {component}
                </div>

            </div>
        );
    }

    renderData()
    {
        return <BrandWiseTabbedReport brands={this.state.brands} getReport={this.getReport} />
    }

    onSearchCriteriaChanged = (searchCriteria) =>
    {
        this.setState({ searchCriteria: null, data: null, brands: null, error: null });
    }

    onSearch = async (searchCriteria) =>
    {
        try
        {
            this.setState({ isLoading: true, searchCriteria: null, data: null, brands: null, error: null });

            viewUtil.showSystemModalSpinner("Loading data, please wait ...");

            const { startDate: fromDate, endDate: toDate } = searchCriteria;
            const data = await reportsLoadingFacade.getTaxDetails(fromDate, toDate);

            this.setState({ searchCriteria: searchCriteria, data: data, brands: this.getBrands(data) });
        }
        catch (error)
        {
            this.setState({ error: error });
        }
        finally
        {
            this.setState({ isLoading: false });
            viewUtil.closeModalSpinner();
        }
    }

    getBrands(data)
    {
        return [...new Set(data.map(d => d.storefrontBrand))];
    }

    getReport = (brand) =>
    {
        const data = this.state.data ? getFormattedData(this.state.data, brand) : null;

        let searchCriteria = this.state.searchCriteria;
        let component;

        if (data == null)
        {
            let title = 'Data required';
            let text = 'Please choose date range and hit Search to get report data';
            let action = null;

            if (searchCriteria != null) 
            {
                // Implies search did not return any data

                title = 'No Data Found';
                text = 'No data was found for your selected date range !!'
                action = 'Please choose a different date range and hit Search to get report data';
            }

            component = <EmptyData title={title} text={text} action={action} />
        }
        else
        {
            component = <div className='d-flex flex-column'>

                            <div className='align-self-end me-4 text-muted' style={{ fontSize: "0.75em", marginTop: "20px", marginBottom: "-40px" }}>
                                Adjusted {this.vatCaption} = {this.vatCaption} payable after adjusting for any sale discount
                            </div>

                            <Tabs className="mt-2">

                                <TabList>
                                    <Tab>Summary Data</Tab>
                                    <Tab>Detail Data</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='p-3 d-flex flex-column'>

                                        <div className='d-flex justify-content-between'>
                                            <div className='me-1 container' style={{ width: "50%" }}>{this.renderVatSummaryData(data)}</div>
                                            <div className='ms-1 container' style={{ width: "50%" }}>{this.renderVatByPercentageData(data)}</div>
                                        </div>

                                        <div className='my-4 border border-bottom' />

                                        <div className='mb-4'>
                                            {this.renderVatByPropertyData(data)}
                                        </div>
                                        <div className='mb-4'>
                                            {this.renderVatByVenueCodeData(data)}
                                        </div>
                                        <div className='mb-4'>
                                            {this.renderVatByStorefrontData(data)}
                                        </div>
                                        <div className='mb-1'>
                                            {this.renderVatByDispatchTypeData(data)}
                                        </div>

                                        <div className='my-4 border border-bottom' />

                                        <div className='d-flex align-items-stretch'>
                                            <div className='w-50 me-1'>
                                                {this.renderVatByTop20CategoriesData(data)}
                                            </div>
                                            <div className='w-50 ms-1'>
                                                {this.renderVatByBottom20CategoriesData(data)}
                                            </div>
                                        </div>

                                        <div className='my-4 border border-bottom' />

                                        <div className='d-flex align-items-stretch'>
                                            <div className='w-50 me-1'>
                                                {this.renderVatByTop20ItemsData(data)}
                                            </div>
                                            <div className='w-50 ms-1'>
                                                {this.renderVatByBottom20ItemsData(data)}
                                            </div>
                                        </div>

                                    </div>

                                </TabPanel>

                                <TabPanel>
                                    <div className='mt-3 border rounded-top d-flex flex-column shadow-sm'>
                                        <div className='py-2 text-center fw-bold' style={{ backgroundColor: "#eff6ff" }}>Vat Detail Data</div>
                                        <div className='mt-1'>
                                            <VatDetailData vatDetailData={data.vatDetailData} />
                                        </div>
                                    </div>
                                </TabPanel>

                            </Tabs>

                        </div>
        }

        return component;
    }

    renderVatSummaryData({ vatSummary })
    {
        return (
            <div className='h-100 row shadow-sm'>
                <div className='col p-0 rounded-start text-white d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "#0d6efd" }}>
                    <div className='fw-bold'>Total Sales</div>
                    <div>{this.formatAmount(vatSummary.totalRetailOrderPrice)}</div>
                </div>
                <div className='col p-0 text-white d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "#5cb85c" }}>
                    <div className='fw-bold'>Total {this.vatCaption}</div>
                    <div>{this.formatAmount(vatSummary.totalTax)}</div>
                </div>
            </div>);
    }

    renderVatByPercentageData({ vatByPercentage })
    {
        return (
            <div className='h-100 row border rounded shadow-sm'>
                {
                    vatByPercentage.map((data, index) =>
                        <div key={data.vatPercentage} className='col py-3 border-end text-muted d-flex flex-column justify-content-center align-items-center'>
                            <div className='fw-bold'>{this.vatCaption} @ {this.formatPercentage(data.vatPercentage)}</div>
                            <div id={`vat-${index}`}>{this.formatAmount(data.totalTax)}</div>
                            <div id={`sale-${index}`} className='text-muted' style={{ fontSize: "0.75em" }}>{this.formatAmount(data.totalRetailOrderPrice)}</div>
                            <>
                                <SimpleToolTip target={`vat-${index}`}>{this.vatCaption}</SimpleToolTip>
                                <SimpleToolTip target={`sale-${index}`}>Sale</SimpleToolTip>
                            </>
                        </div>)
                }
            </div>);
    }

    renderVatByPropertyData({ vatByProperty })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "50%" }}>Stores</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>{this.vatCaption}</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "50%" }}>
                        {
                            vatByProperty.map(data => <div key={data.propertyId} className='w-100 py-1 text-truncate'>{data.propertyName}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByProperty.map(data => <div key={data.propertyId} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByProperty.map(data => <div key={data.propertyId} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByStorefrontData({ vatByStorefront })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "50%" }}>Storefronts</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>{this.vatCaption}</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "50%" }}>
                        {
                            vatByStorefront.map(data => <div key={data.storefrontId} className='w-100 py-1 text-truncate'>{data.storefrontName}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByStorefront.map(data => <div key={data.storefrontId} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByStorefront.map(data => <div key={data.storefrontId} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByVenueCodeData({ vatByVenueCode })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "50%" }}>Venues</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>{this.vatCaption}</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "50%" }}>
                        {
                            vatByVenueCode.map(data => <div key={data.venueCode} className='w-100 py-1 text-truncate'>{data.venueCode}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByVenueCode.map(data => <div key={data.venueCode} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByVenueCode.map(data => <div key={data.venueCode} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByDispatchTypeData({ vatByDispatchType })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "50%" }}>Dispatch Types</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>{this.vatCaption}</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "50%" }}>
                        {
                            vatByDispatchType.map(data => <div key={data.dispatchType} className='w-100 py-1 text-truncate'>{data.dispatchType}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByDispatchType.map(data => <div key={data.dispatchType} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByDispatchType.map(data => <div key={data.dispatchType} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByTop20CategoriesData({ vatByTop20Categories })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "50%" }}>Top 20 Categories</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>{this.vatCaption}</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "50%" }}>
                        {
                            vatByTop20Categories.map(data => <div key={data.category} className='w-100 py-1 text-truncate'>{data.category}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByTop20Categories.map(data => <div key={data.category} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByTop20Categories.map(data => <div key={data.category} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByBottom20CategoriesData({ vatByBottom20Categories })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "50%" }}>Bottom 20 Categories</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "25%" }}>{this.vatCaption}</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "50%" }}>
                        {
                            vatByBottom20Categories.map(data => <div key={data.category} className='w-100 py-1 text-truncate'>{data.category}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByBottom20Categories.map(data => <div key={data.category} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "25%" }}>
                        {
                            vatByBottom20Categories.map(data => <div key={data.category} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByTop20ItemsData({ vatByTop20Items })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "45%" }}>Top 20 Items</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "20%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "20%" }}>{this.vatCaption}</div>
                    <div className='py-2 pe-3 text-end' style={{ width: "15%" }}>{this.vatCaption} %</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "45%" }}>
                        {
                            vatByTop20Items.map(data => <div key={data.caption} className='w-100 py-1 text-truncate'>{data.caption}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "20%" }}>
                        {
                            vatByTop20Items.map(data => <div key={data.caption} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "20%" }}>
                        {
                            vatByTop20Items.map(data => <div key={data.caption} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 text-end' style={{ width: "15%" }}>
                        {
                            vatByTop20Items.map(data => <div key={data.caption} className='py-1'>{this.formatPercentage(data.vatPercentage)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    renderVatByBottom20ItemsData({ vatByBottom20Items })
    {
        return (
            <div className='h-100 border rounded-top d-flex flex-column shadow-sm'>

                <div className='fw-bold text-muted d-flex rounded-top' style={{ backgroundColor: "#eff6ff" }}>
                    <div className='py-2 ps-3 border-end text-start' style={{ width: "45%" }}>Bottom 20 Items</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "20%" }}>Sales</div>
                    <div className='py-2 pe-3 border-end text-end' style={{ width: "20%" }}>{this.vatCaption}</div>
                    <div className='py-2 pe-3 text-end' style={{ width: "15%" }}>{this.vatCaption} %</div>
                </div>

                <div className='flex-grow-1 d-flex'>
                    <div className='h-100 py-2 ps-3 border-end d-flex flex-column' style={{ width: "45%" }}>
                        {
                            vatByBottom20Items.map(data => <div key={data.caption} className='w-100 py-1 text-truncate'>{data.caption}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "20%" }}>
                        {
                            vatByBottom20Items.map(data => <div key={data.caption} className='py-1'>{this.formatAmount(data.totalRetailOrderPrice)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 border-end text-end' style={{ width: "20%" }}>
                        {
                            vatByBottom20Items.map(data => <div key={data.caption} className='py-1'>{this.formatAmount(data.totalTax)}</div>)
                        }
                    </div>
                    <div className='h-100 py-2 pe-3 text-end' style={{ width: "15%" }}>
                        {
                            vatByBottom20Items.map(data => <div key={data.caption} className='py-1'>{this.formatPercentage(data.vatPercentage)}</div>)
                        }
                    </div>
                </div>

            </div>);
    }

    formatAmount = value => formatter.formatToAmount(value);

    formatPercentage = value => isNumeric(value) && value >= 0 ? `${value} %` : "Others";
}

export default SalesVatAnalytics;