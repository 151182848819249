
import React, { Component } from 'react';
import EmptyLine from './previews/emptyLineElement';
import Separator from './previews/separatorElement';

import GenericTextElement from './previews/genericTextElement';
import GenericImageElement from './previews/genericImageElement';
import GenericQRElement from './previews/genericQRElement';

import OrderGridElement from './previews/orderGridElement';
import KitchenStationOrderGridElement from './previews/kitchenStationOrderGridElement';
import VatGridElement from './previews/vatGridElement';
import DeliveryGridElement from './previews/deliveryGridElement';
import RefundsGridElement from './previews/refundsGridElement';
import ReceiptDiscountsGrid from './previews/receiptDiscountsGrid';
import ServiceChargesGrid from './previews/serviceChargesGrid';
import EndorsementGridElement from './previews/endorsementGridElement';
import CashChangeGridElement from './previews/cashChangeGridElement';
import ChargesGridElement from './previews/chargesGridElement';
import JournalGridElement from './previews/journalGridElement';
import MenuSalesSummaryGridElement from './previews/menuItemsSummaryElement';
import PaymentsSummaryGridElement from './previews/paymentsSummaryElement';
import DiscountsSummaryGridElement from './previews/discountsSummaryElement';
import RefundsSummaryGridElement from './previews/refundsSummaryElement';
import VenuesSummaryGridElement from './previews/venuesSummaryElement';
import DispatchTypeSummaryGridElement from './previews/dispatchTypesSummaryGridElement';
import DispatchTypePaymentsSummaryElement from './previews/dispatchTypePaymentsSummaryElement';
import VenuePaymentsSummaryGridElement from './previews/venuePaymentsSummaryElement';
import MenuCategoriesSummaryGridElement from './previews/menuCategoriesSummaryElement';
import CancelledSalesGridElement from './previews/cancelledSalesGridElement';
import CancelledSaleItemsGridElement from './previews/cancelledSaleItemsGridElement';
import ChargesSummaryGridElement from './previews/chargesSummaryGridElement';
import CashDrawerLogsGridElement from './previews/cashDrawerLogsGridElement';
import PaymentStatusElement from './previews/paymentStatusElement';
import PaymentReceiptLinesGrid from './previews/paymentReceiptLinesGrid';
import TableViewElement from './previews/tableViewElement';

const stringUtil = require('../../../../utils/string/stringUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const formatter = require('../../../../utils/formatter/formatter');
const domainConstants = require('../../../../utils/domain/constants');
const dailySummaryReportTemplateUtils = require('./utils/dailySummaryReportTemplateUtils');

class PreviewElement extends Component 
{
    render()
    {
        if (stringUtil.areStringSame(this.props.element.type, "emptyLine"))
            return <EmptyLine element={this.props.element}/>

        if (stringUtil.areStringSame(this.props.element.type, "separator"))
            return <Separator element={this.props.element}/>

        if (stringUtil.areStringSame(this.props.element.type, "text"))
            return <GenericTextElement isCaptionCompulsory={true} suffix="" element={this.props.element}/>

        if (stringUtil.areStringSame(this.props.element.type, "cancelledCaption"))
            return <GenericTextElement isCaptionCompulsory={true} suffix="" element={this.props.element}/>
        
        if (stringUtil.areStringSame(this.props.element.type, "refundedCaption"))
            return <GenericTextElement isCaptionCompulsory={true} suffix="" element={this.props.element}/>

        if (stringUtil.areStringSame(this.props.element.type, "variable"))
            return <GenericTextElement isCaptionCompulsory={true} suffix="" element={this.props.element}/>

        if (stringUtil.areStringSame(this.props.element.type, "image"))
            return <GenericImageElement element={this.props.element}/>

        if (stringUtil.areStringSame(this.props.element.type, "table"))
        {
            if (this.props.printingTemplateType === domainConstants.printingTemplateTypes.dailySummaryReport) 
            {
                const tableData = dailySummaryReportTemplateUtils.getTableData(this.props.element.field);
                return <TableViewElement element={this.props.element} tableData={tableData} />
            }
        }

        if (stringUtil.areStringSame(this.props.element.type, "field"))
        {
            if (stringUtil.areStringSame(this.props.element.field, "driver"))
                return <GenericTextElement suffix="John Smith" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "operator"))
                return <GenericTextElement suffix="William Hill" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "server"))
                return <GenericTextElement suffix="Kate Moss" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "orderNumber"))
                return <GenericTextElement isJustifiable={true}suffix="6765" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "tableNumber"))
                return <GenericTextElement suffix="T21" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "orderAndTableNumber"))
                return <GenericTextElement suffix="T21 / 6765" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "venueCode"))
                return <GenericTextElement suffix="JustEats" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "dispatchType"))
                return <GenericTextElement suffix="Collection" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "venueAndDispatchType"))
                return <GenericTextElement suffix="JustEats / Collection" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "storefront"))
                return <GenericTextElement suffix="Storefront" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "paymentStatus"))
                return <PaymentStatusElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "printTime"))
                return <GenericTextElement isDate={true} suffix={dateUtil.getNow()} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "orderDate"))
                return <GenericTextElement isDate={true} defaultFormat={dateUtil.internalDateFormat} suffix={dateUtil.getNow()} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "orderGrid"))
                return <OrderGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "kitchenStationOrderGrid"))
                return <KitchenStationOrderGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "covers"))
                return <GenericTextElement suffix={4} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalRetailOrderPrice"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(88)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalOrderPrice"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(100)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalDiscount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(5)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalServiceCharges"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(2)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalDeliveryCharges"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "totalDepositReturnCharges"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalPayableAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "paymentMode"))
                return <GenericTextElement suffix="Credit" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalPaidAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalRefund"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalOutstanding"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "totalTipCharges"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "title"))
                return <GenericTextElement suffix={"Food World\nUtopia Shopping Center\nOut Of The World Kingdom"}
                                element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "footer"))
                return <GenericTextElement suffix={"Thank you for your visit!\nVisit our website for latest offers"} 
                                element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "showPreOrderStatus"))
                return <GenericTextElement suffix="Pre-Order / ASAP" element={this.props.element}/>
                
            if(stringUtil.areStringSame(this.props.element.field, "kitchenStation"))
                return <GenericTextElement suffix="Pack" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "plannedDeliveryDateTime"))
                return <GenericTextElement isDate={true} defaultFormat={dateUtil.internalDateTimeFormat} suffix={dateUtil.getNow()} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "expectedTime"))
                return <GenericTextElement isDate={true} defaultFormat={dateUtil.internalDateTimeFormat} suffix={dateUtil.getNow()} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "expectedPlatformDateTime"))
                return <GenericTextElement isDate={true} defaultFormat={dateUtil.internalDateTimeFormat} suffix={dateUtil.getNow()} element={this.props.element}/>    

            if(stringUtil.areStringSame(this.props.element.field, "customerName"))
                return <GenericTextElement suffix="John Smith" element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "customerPhoneNumber"))
                return <GenericTextElement suffix="447470501526" element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "deliveryAddress"))
                return <GenericTextElement suffix="BA1 2EJ, Croydon, UK" element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "startedBy"))
                return <GenericTextElement suffix="John Smith at 2022-11-11 09:30" element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "closedBy"))
                return <GenericTextElement suffix="John Smith at 2022-11-11 21:30" element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "status"))
                return <GenericTextElement suffix="Open" element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "openingBalance"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(500)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "availableCash"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(350)} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalCashIn"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(350)} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalCashOut"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(350)} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalExpenses"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(55)} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "netChangePayment"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(10)} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalSalesQuantity"))
                return <GenericTextElement suffix={398} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalSalesAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(5490)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalSalesQtyAndAmount"))
                return <GenericTextElement suffix={`398 / ${formatter.convertToCurrencyFormat(5490)}`} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalNetSalesAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(5000)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalGrossSalesAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(5200)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCancelledSalesQuantity"))
                return <GenericTextElement suffix={5} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCancelledSalesAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(45)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCancelledSalesQuantityAndAmount"))
                return <GenericTextElement suffix={`5 / ${formatter.convertToCurrencyFormat(45)}`} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalCancelledSaleItemsQuantity"))
                return <GenericTextElement suffix={5} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCancelledSaleItemsAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(45)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCancelledSaleItemsQuantityAndAmount"))
                return <GenericTextElement suffix={`5 / ${formatter.convertToCurrencyFormat(45)}`} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalRefundedSalesQuantity"))
                return <GenericTextElement suffix={5} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalRefundedSalesAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(45)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalRefundedSalesQuantityAndAmount"))
                return <GenericTextElement suffix={`5 / ${formatter.convertToCurrencyFormat(45)}`} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalTax"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(1024)} element={this.props.element}/>
                
            if(stringUtil.areStringSame(this.props.element.field, "totalCurrentSessionOutstandingAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(30)} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCurrentSessionOutstandingQuantity"))
                return <GenericTextElement suffix={3} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalCurrentSessionOutstandingQuantityAndAmount"))
                return <GenericTextElement suffix={`${3} / ${formatter.convertToCurrencyFormat(30)}`} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "totalPreviousSessionOutstandingQuantity"))
                return <GenericTextElement suffix={3} element={this.props.element}/>
            
            if(stringUtil.areStringSame(this.props.element.field, "totalPreviousSessionOutstandingAmount"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(30)} element={this.props.element}/>
    
            if(stringUtil.areStringSame(this.props.element.field, "totalPreviousSessionOutstandingQuantityAndAmount"))
                return <GenericTextElement suffix={`${3} / ${formatter.convertToCurrencyFormat(30)}`} element={this.props.element}/>

            if(stringUtil.areStringSame(this.props.element.field, "averageRevenuePerCover"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(30)} element={this.props.element}/>
    
            if(stringUtil.areStringSame(this.props.element.field, "totalNoOfCovers"))
                return <GenericTextElement suffix={formatter.convertToCurrencyFormat(30)} element={this.props.element}/>
    
            if (stringUtil.areStringSame(this.props.element.field, "paymentsSummaryGrid"))
                return <PaymentsSummaryGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "dispatchTypesSummaryGrid"))
                return <DispatchTypeSummaryGridElement element={this.props.element}/>
                
            if (stringUtil.areStringSame(this.props.element.field, "dispatchTypePaymentsSummaryGrid"))
                return <DispatchTypePaymentsSummaryElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "venuesSummaryGrid"))
                return <VenuesSummaryGridElement element={this.props.element}/>
                
            if (stringUtil.areStringSame(this.props.element.field, "venuePaymentsSummaryGrid"))
                return <VenuePaymentsSummaryGridElement element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "discountsSummaryGrid"))
                return <DiscountsSummaryGridElement element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "refundsSummaryGrid"))
                return <RefundsSummaryGridElement element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "chargesSummaryGrid"))
                return <ChargesSummaryGridElement element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "menuSalesSummaryGrid"))
                return <MenuSalesSummaryGridElement element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "menuCategoriesSummaryGrid"))
                return <MenuCategoriesSummaryGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "cancelledSalesGrid"))
                return <CancelledSalesGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "cancelledSaleItemsGrid"))
                return <CancelledSaleItemsGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "journalGrid"))
                return <JournalGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "cashDrawerLogsGrid"))
                return <CashDrawerLogsGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "cashChangeGrid"))
                return <CashChangeGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "paymentReceiptLinesGrid"))
                return <PaymentReceiptLinesGrid element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "vatGrid"))
                return <VatGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "chargesGrid"))
                return <ChargesGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "receiptDiscountsGrid"))
                return <ReceiptDiscountsGrid element={this.props.element}/>
            
            if (stringUtil.areStringSame(this.props.element.field, "serviceChargesGrid"))
                return <ServiceChargesGrid element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "refundsGrid"))
                return <RefundsGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "deliveryGrid"))
                return <DeliveryGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "endorsementGrid"))
                return <EndorsementGridElement element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "voucherIssueDate"))
                return <GenericTextElement isDate={true} defaultFormat={dateUtil.internalDateFormat} suffix={dateUtil.getNow()} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "voucherExpiryDate"))
                return <GenericTextElement isDate={true} defaultFormat={dateUtil.internalDateFormat} suffix={dateUtil.getNow()} element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "voucherDetail"))
                return <GenericTextElement suffix="Redeem the voucher to get £5.00 off on your order over £20.00." element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "voucherCode"))
                return <GenericTextElement suffix="UHTBA" element={this.props.element}/>

            if (stringUtil.areStringSame(this.props.element.field, "voucherQR"))
                return <GenericQRElement element={this.props.element}/>
             
        }
    }
}

export default PreviewElement;