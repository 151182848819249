import React, { Component } from 'react';

class Cell extends Component 
{

    constructor(props)
    {
        super(props);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }


    render()
    {
        return <td onDoubleClick={this.onDoubleClick} onDragOver={this.onDragOver} onDrop={this.onDrop}>
                    {this.props.children}
                </td>
    }

    onDoubleClick(event)
    {
        if (this.props.onDoubleClick)
        {
            this.props.onDoubleClick(event);
            event.stopPropagation();
        }
    }

    onDragOver(event)
    {
        event.preventDefault();
    }

    onDrop(event)
    {  
        if (this.props.onDrop)
            this.props.onDrop(JSON.parse(event.dataTransfer.getData("dragInfo")));
    }

}

export default Cell;
