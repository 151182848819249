import React, { Component } from 'react';
import TargetCriteria from './TargetCriteria';

export default class CampaignTarget extends Component
{
    constructor(props)
    {
        super(props)
    }

    render() 
    {
        const profile = this.props.data;

        return <TargetCriteria
            {...this.props}
            data={profile.targetCriteria}
            onChange={() =>
            {
                if (this.props.onChange)
                    this.props.onChange()
            }} />
    }
}