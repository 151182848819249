
import React, { Component } from 'react';
import { Input, Label } from 'reactstrap';

class CheckComponent extends Component 
{
    render()
    {
        return (
            <div className='d-flex align-items-center'>
                <Input
                    className='mb-3 me-2'
                    type="checkbox"
                    id={this.props.fieldName}
                    disabled={this.props.inputReadOnly}
                    checked={this.props.formManager.getValue(this.props.fieldName)}
                    onChange={this.props.formManager.onValueChanged} />
                <Label htmlFor={this.props.fieldName}>
                    {this.props.caption}
                </Label>
            </div>
        );
    }
}

export default CheckComponent;