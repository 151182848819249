import React, { Component } from 'react';

import InputComponent from '../../../../components/form/inputComponent'

const FormManager = require('../../../../utils/view/formManager');

class TransactionAccount extends Component 
{

    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }


    render() 
    {
        return (
            <table className="component-table" >

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Account Code"
                            fieldName="code"
                            hintText="Required"
                            placeholder="Enter transaction account code"
                            formManager={this.state.formManager} />
                    </td>

                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Account Name"
                            fieldName="name"
                            hintText="Required"
                            placeholder="Enter transaction account name"
                            formManager={this.state.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            fieldName="notes"
                            caption="Notes"
                            placeholder="Enter any notes."
                            multiLine={true} rows={4}
                            formManager={this.state.formManager} />
                    </td>
                </tr>

            </table>
        );
    }
}

export default TransactionAccount;

