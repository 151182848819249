
import React, { Component } from "react";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import InputComponent from '../../../../../components/form/inputComponent'
import CheckComponent from '../../../../../components/form/checkComponent.js'
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent.js';
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent'

import CdnImageUploader from '../../../../../components/cdnImage/cdnImageUploader.js'

var configurationManager = require('../../../../../utils/config/configurationManager.js');
var stringUtil = require('../../../../../utils/string/stringUtil.js');
var constants = require('../../../../../utils/domain/constants.js')
var FormManager = require('../../../../../utils/view/formManager.js');


class StaffDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return this.props.isReadOnly; }

    getRoles = () =>
    {
        const roles = [];

        this.props.staffRoles.forEach(x => {
            roles.push({ roleId: x.id, label: x.name });
        })

        return roles;
    }

    getSelectedRoles = () =>
    {
        const selectedRoles = [];

        const roles = this.state.formManager.viewModel.roles;     // Comma separated string

        if (!stringUtil.isStringNullOrEmpty(roles))
        {
            const availableOptions = this.getRoles();

            roles.split(",").forEach(roleId =>
            {
                const selectedOption = availableOptions.find(option => option.roleId == roleId);

                if (selectedOption != null)
                {
                    selectedRoles.push(selectedOption);
                }
            });
        }

        return selectedRoles;
    }

    onSelectedRolesChanged = (selectedRoles) =>
    {
        this.state.formManager.viewModel.roles = selectedRoles.map(option => option.roleId).join();

        this.setState({});
    }

    render()
    {

        var imageFolder = "";
        if (!stringUtil.isStringNullOrEmpty(this.props.franchisorId) && !stringUtil.isStringNullOrEmpty(this.props.data.franchiseeId))
            imageFolder = `franchisors/${this.props.franchisorId}/franchisees/${this.props.data.franchiseeId}/staff`;

        return (
            <Tabs>
                <TabList>
                    <Tab>Details</Tab>
                    <Tab>HR</Tab>
                    <Tab>Configuration</Tab>
                    <Tab>Security</Tab>
                    <Tab>Photo</Tab>
                </TabList>

                <TabPanel style={{ marginTop: '30px' }}>

                    <table width="100%">
                        <tr>
                            <td width="50%">
                                <InputComponent inputType="text" caption="First Name" hintText="Required" style={{ marginRight: '40px' }} fieldName="firstName" placeholder="Enter first name of the staff member" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputType="text" caption="Last Name" hintText="Required" fieldName="lastName" placeholder="Enter last name of the staff member" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <InputComponent inputType="date" caption="Date of Birth" hintText="Required" style={{ marginRight: '20px' }} fieldName="dateOfBirth" placeholder="Enter staff date of birth" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputType="text" caption="Nationality" hintText="Required" fieldName="nationality" placeholder="Enter staff nationality" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>


                        </tr>

                        <tr>

                            <td width="100%" colSpan="2">
                                <InputComponent inputType="address" caption="Address" hintText="Required" fieldName="address" placeholder="Enter staff address" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>

                        </tr>

                        <tr>

                            <td width="50%">
                                <InputComponent inputType="phone" caption="Phone Number" hintText="Required" style={{ marginRight: '20px' }} fieldName="phoneNumber" placeholder="Enter staff's phone number" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputType="email" caption="Email Address" hintText="Required" fieldName="emailAddress" placeholder="Enter staff's email address" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                    fieldName="notes" placeholder="Enter any notes." inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>
                        </tr>
                    </table>

                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>

                    <table width="100%">
                        <tr>
                            <td width="50%">
                                <InputComponent inputType="date" caption="Joining Date" hintText="Required" style={{ marginRight: '20px' }} fieldName="joiningDate" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputType="text" caption="Hourly Wage" hintText="Required" fieldName="hourlyWage" placeholder="Enter hourly wage" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>

                            <td width="50%">
                                <InputComponent inputType="identity" caption="NI Number" hintText="Required" style={{ marginRight: '20px' }} fieldName="niNumber" placeholder="Enter staff NI number" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>

                            <td>
                                <InputComponent inputType="date" caption="Resignation Date" hintText="Date when staff stopped working for the business." style={{ marginRight: '20px' }}
                                    fieldName="resignationDate" placeholder="Date when staff stopped working for the business" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />

                            </td>
                        </tr>

                        <tr>
                            <td>
                                <MultiSelectComponent
                                    caption="Roles"
                                    valueFieldName="roleId"
                                    displayFieldName="label"
                                    options={this.getRoles()}
                                    selectedOptions={this.getSelectedRoles()}
                                    onSelectionChanged={this.onSelectedRolesChanged}
                                    hintText="Select staff roles"
                                    comboReadOnly={this.isReadOnly}
                                    style={{ marginRight: '20px' }} />
                            </td>
                            <td></td>
                        </tr>

                    </table>

                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>

                    <table width="100%">

                        <tr>
                            <td width="50%">
                                <InputComponent
                                    inputType="money"
                                    fieldName="staffMealsLimit"
                                    caption="Staff Meals Limit"
                                    placeholder="Enter staff meals limit"
                                    hintText="Optional"
                                    inputReadOnly={this.isReadOnly} 
                                    formManager={this.state.formManager}
                                    style={{ marginRight: '20px' }} />
                            </td>
                            <td></td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <InputComponent
                                    inputType="money"
                                    fieldName="guestMealsLimit"
                                    caption="Guest Meals Limit"
                                    placeholder="Enter guest meals limit"
                                    hintText="Optional"
                                    inputReadOnly={this.isReadOnly} 
                                    formManager={this.state.formManager}
                                    style={{ marginRight: '20px' }} />
                            </td>
                            <td width="50%">
                                <InputComponent
                                    inputType="money"
                                    fieldName="freebiesLimit"
                                    caption="Freebies Limit"
                                    placeholder="Enter Freebies limit"
                                    hintText="Optional"
                                    inputReadOnly={this.isReadOnly} 
                                    formManager={this.state.formManager}
                                />
                            </td>
                        </tr>


                        <tr>
                            <td colSpan="2">
                                <Card style={{ marginTop: '20px' }}>
                                    <CardHeader>Discount Limits</CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <InputComponent
                                                    inputType="money"
                                                    fieldName="voucherFixedDiscountLimit"
                                                    caption="Fixed Discount Limit"
                                                    placeholder="Enter Fixed Discount limit"
                                                    inputReadOnly={this.isReadOnly}
                                                    hintText="Maximum fixed amount staff is allowed to offer while creating discount vouchers"
                                                    formManager={this.state.formManager}
                                                />  
                                            </Col>

                                            <Col>
                                                <InputComponent
                                                        inputType="percentage"
                                                        fieldName="voucherPercentageDiscountLimit"
                                                        caption="Percentage Discount Limit"
                                                        placeholder="Enter Percentage Discount limit"
                                                        inputReadOnly={this.isReadOnly}
                                                        hintText="Maximum percentage staff is allowed to offer while creating discount vouchers"
                                                        formManager={this.state.formManager}
                                                />  
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                            </td>
                        </tr>
                    </table>

                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>

                    <table width="100%">
                        <tr>
                            <td width="50%">
                                <InputComponent inputType="text" caption="Pin Code" hintText="Enter 5 digit pin code for this staff" style={{ marginRight: '20px' }} placeholder="Enter 5 digit pin code" fieldName="pinCode" inputReadOnly={this.isReadOnly} formManager={this.state.formManager}/>
                            </td>

                            <td width="50%">
                                <InputComponent inputType="text" caption="Security Card Number" hintText="Enter security card for this staff" fieldName="securityCardNumber" placeholder="Enter security card number" inputReadOnly={this.isReadOnly} formManager={this.state.formManager} />
                            </td>
                        </tr>

                    </table>

                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>
                    <CdnImageUploader cloudName={configurationManager.getConfig().cdnUsersCloudName} imageFolder={imageFolder} imageUrl={this.props.data.pictUrl} isReadOnly={this.isReadOnly}
                        onImageChange={(url) =>
                        {
                            this.props.data.pictUrl = url;
                            this.setState({});
                        }}>
                        <h4 className="display-5">No Photo Found</h4>

                        <p className="lead">Adding a staff photo will help your managers and coworkers recognize you in the app.</p>
                        <hr className="my-2" />
                        <p>Adding team photos to your app will help to give life to your company, and will help to make your company seem friendly and inviting. They are also a great way to show off your company culture.</p>
                    </CdnImageUploader>
                </TabPanel>
            </Tabs>
        );
    }
}

export default StaffDetails;