
const stringUtil = require('../../../../../../utils/string/stringUtil');
const domainConstants = require('../../../../../../utils/domain/constants');
const orgStateSelectors = require('../../../../../../utils/state/stateSelectors/orgStateSelectors');


module.exports.getAllKdsTags = function ()
{
    const options = [];

    const tags = orgStateSelectors.selectCurrentOrgLookupData().tags;
    const kdsTags = tags.filter(tag => tag.type == domainConstants.tagTypes.KDS);

    kdsTags.forEach(tag => options.push(tag.code));

    return options.sort(stringUtil.localeCompareStr);
}