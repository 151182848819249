import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import FormManager from '../../../../../../utils/view/formManager';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import InputDisplayComponent from '../../../../../../components/form/displayComponents/InputDisplayComponent';

import domainConstants from '../../../../../../utils/domain/constants';
import unitUtil from '../../../../../../utils/domain/unitUtil';
import formatter from '../../../../../../utils/formatter/formatter';
import parseUtil from '../../../../../../utils/parsing/parseUtil';
import validator from '../../../../../../utils/validator/validator';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';
import * as localizationUtils from '../../../../../../utils/domain/localizationUtils';

export default class ItemReceiptComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = props.data;    // orderItem
        this.formManager.view = this;
        this.formManager.onFieldChanged = this.onFieldChanged;

        this.amountFormManager = new FormManager();
        this.amountFormManager.viewModel = { totalAmount: this.totalAmount, totalVat: this.totalVat };
        this.amountFormManager.view = this;
        this.amountFormManager.onFieldChanged = this.onAmountFieldChanged;
    }

    onFieldChanged = (event) =>
    {
        const id = event.target.id;

        if (id == "receiptUnitTypeId")
        {
            const receiptUnitTypeId = this.orderItem.receiptUnitTypeId;

            if (receiptUnitTypeId)
            {
                const supplierUnit = this.supplierUnits.find(unit => unit.unitTypeId == receiptUnitTypeId);

                this.orderItem.receiptUnitPrice = supplierUnit.price;
                this.orderItem.receiptVat = supplierUnit.vat;

                this.amountFormManager.viewModel.totalAmount = this.totalAmount;
                this.amountFormManager.viewModel.totalVat = this.totalVat;
            }
        }

        this.props.onFieldChanged(this.orderItem);
    }

    onAmountFieldChanged = (event) =>
    {
        const id = event.target.id;

        if (id == "totalAmount")
        {
            this.orderItem.receiptUnitPrice = null;

            const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
            const totalAmount = parseUtil.parseFloatGreaterThanOrEqualToZeroElseNull(event.target.value);

            if (validator.isAllNumeric(quantity, totalAmount))
            {
                this.orderItem.receiptUnitPrice = totalAmount / quantity;
            }
        } else if (id == "totalVat")
        {
            this.orderItem.receiptVat = null;

            const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
            const totalVat = parseUtil.parseFloatGreaterThanOrEqualToZeroElseNull(event.target.value);

            if (validator.isAllNumeric(quantity, totalVat))
            {
                this.orderItem.receiptVat = totalVat / quantity;
            }
        }

        this.props.onFieldChanged(this.orderItem);
    }

    get purchaseOrder() 
    {
        return this.props.purchaseOrder;
    }

    get orderItem()
    {
        return this.formManager.viewModel;
    }

    get stockItem()
    {
        return purchaseOrderUtil.getStockItem(this.orderItem, this.props.lookupData);
    }

    get stockItemUnits()
    {
        const stockItemId = this.orderItem.stockItemId;
        return unitUtil
            .getPossibleUnitsForStockItemId(stockItemId, this.props.lookupData.stockItems)
            .filter(unit => !unit.isSupplementalUnit);
    }

    get supplierUnits()
    {
        const supplierId = this.purchaseOrder.supplierId;
        const stockItemId = this.orderItem.stockItemId;
        return unitUtil.getPossibleUnitForStockItemSupplier(stockItemId, supplierId, this.props.lookupData.stockItems, !this.props.purchaseOrderContext.isNewOrder);
    }

    get optionUnits()
    {
        return this.supplierUnits
            .map(supplierUnit => this.stockItemUnits.find(stockItemUnit => stockItemUnit.id == supplierUnit.unitTypeId))
            .filter(unit => unit != null);
    }

    get selectedUnit()
    {
        const receiptUnitTypeId = this.orderItem.receiptUnitTypeId;
        return this.optionUnits.find(unit => unit.id === receiptUnitTypeId);
    }

    get pluCode()
    {
        if (this.selectedUnit == null) return "N/A";

        const selectedUnitTypeId = this.selectedUnit.id;
        const supplierUnit = this.supplierUnits.find(unit => unit.unitTypeId == selectedUnitTypeId);
        return supplierUnit.pluCode;
    }

    get quantity()
    {
        return this.orderItem.receiptQuantity;
    }

    get quantityInCoreUnits()
    {
        if (this.selectedUnit == null) return NaN;

        const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
        if (quantity == null) return null;
        return unitUtil.convertToStockItemCoreUnitsByStockItemId(
            this.stockItem.id, this.selectedUnit.id, quantity, this.props.lookupData.stockItems);
    }

    get unitPrice()
    {
        return this.orderItem.receiptUnitPrice;
    }

    get vat()
    {
        return this.orderItem.receiptVat;
    }

    get totalAmount()
    {
        const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
        const unitPrice = parseUtil.parseFloatGreaterThanOrEqualToZeroElseNull(this.unitPrice);
        if (quantity == null || unitPrice == null) return null;
        return unitPrice * quantity;
    }

    get totalVat()
    {
        const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
        const vat = parseUtil.parseFloatGreaterThanOrEqualToZeroElseNull(this.vat);
        if (quantity == null || vat == null) return null;
        return vat * quantity;
    }

    get totalGrossAmount()
    {
        if (this.totalAmount == null || this.totalVat == null) return null;
        return this.totalAmount + this.totalVat;
    }

    get isReadOnly()
    {
        return (
            !purchaseOrderUtil.isUserAuthorizedForAnyAction() ||
            this.purchaseOrder.status !== domainConstants.PurchaseOrderStatusType.submitted);
    }

    render()
    {
        const vatCaption = localizationUtils.getVatCaption();
        return (
            <Card className='h-100'>
                <CardHeader>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div className="fw-bold">Receipt Details</div>
                        <div>Plu Code: {this.pluCode}</div>
                        <div>Quantity In Core Units: {this.quantityInCoreUnits
                            ? `${this.quantityInCoreUnits} ${this.stockItem.coreUnitCode}`
                            : "n/a"}</div>
                    </div>
                </CardHeader>
                <CardBody style={{ padding: "5px 10px", backgroundColor: purchaseOrderUtil.getReceiptItemBackgroundColor(this.orderItem) }}>

                    <div className="d-flex flex-column">

                        <div className="d-flex flex-row align-items-center">
                            <div className="w-25 mx-2 my-1">
                                <SelectComponent
                                    caption="Unit Type"
                                    fieldName="receiptUnitTypeId"
                                    optionFieldName="id"
                                    optionDisplayFieldName="code"
                                    optionValues={this.optionUnits}
                                    clearable={false}
                                    comboReadOnly={this.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className="w-25 mx-2 my-1">
                                <InputComponent
                                    inputType="number"
                                    caption="Quantity"
                                    fieldName="receiptQuantity"
                                    placeholder="Enter quantity"
                                    inputReadOnly={this.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className="w-25 mx-2 my-1">
                                <InputDisplayComponent
                                    caption="Unit Price"
                                    value={formatter.convertToCurrencyFormat(this.unitPrice)} />
                            </div>
                            <div className="w-25 mx-2 my-1">
                                <InputDisplayComponent
                                    caption={vatCaption}
                                    value={formatter.convertToCurrencyFormat(this.vat)} />
                            </div>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                            <div className="w-25 mx-2">
                                <InputComponent
                                    inputType="number"
                                    caption="Total Amount"
                                    fieldName="totalAmount"
                                    placeholder="Enter total amount"
                                    inputReadOnly={this.isReadOnly}
                                    formManager={this.amountFormManager} />
                            </div>
                            <div className="w-25 mx-2">
                                <InputComponent
                                    inputType="number"
                                    caption={`Total ${vatCaption}`}
                                    fieldName="totalVat"
                                    placeholder={`Enter total ${vatCaption}`}
                                    inputReadOnly={this.isReadOnly}
                                    formManager={this.amountFormManager} />
                            </div>
                            <div className="w-25 mx-2">
                                <InputDisplayComponent
                                    caption="Total Gross"
                                    value={formatter.convertToCurrencyFormat(this.totalGrossAmount)} />
                            </div>
                            <div className="w-25 mx-2"></div>
                        </div>

                    </div>

                </CardBody>
            </Card>
        );
    }
}