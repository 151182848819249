const stringUtil = require('../../../../utils/string/stringUtil')

module.exports.getCentralKitchenStockItemPrice = function (pluCode, stockItems)
{
    var item = stockItems.find(item => item.pluCode == pluCode);
    if (item) return { price: item.price, vat: item.vat };
    return null;
}

module.exports.getDefaultSupplierUnitType = function (unitTypes, centralKitchenStockItems)
{
    for (let index = 0; index < unitTypes.length; index++)
    {
        const unitType = unitTypes[index];

        const disabledReason = this.getStockItemUnitDisabledReason(unitType, centralKitchenStockItems);
        if (stringUtil.isStringNullOrEmpty(disabledReason))
        {
            return unitType;
        }
    }

    return null;
}

module.exports.getStockItemDisabledReason = function (stockItemSupplier, centralKitchenStockItems)
{
    const disabledReasons = [];

    stockItemSupplier.unitTypes.forEach(unitType =>
    {
        const centralKitchenStockItem = centralKitchenStockItems.find(effectiveSupplierStockItem => effectiveSupplierStockItem.pluCode == unitType.pluCode);
        if (centralKitchenStockItem)
        {
            disabledReasons.push(centralKitchenStockItem.unavailableReason);
        }
    });

    //Some of the unit items is enabled
    if (disabledReasons.some(reason => stringUtil.isStringNullOrEmpty(reason)))
        return null;

    let disabledReason = '';

    disabledReasons.forEach(reason =>
    {
        if (!stringUtil.isStringNullOrEmpty(reason) && !stringUtil.areStringSame(disabledReason, reason))
        {
            disabledReason += reason;
        }
    })

    return disabledReason;
}

module.exports.getStockItemUnitDisabledReason = function (supplierUnit, centralKitchenStockItems)
{
    const centralKitchenStockItem = centralKitchenStockItems.find(effectiveSupplierStockItem => effectiveSupplierStockItem.pluCode == supplierUnit.pluCode);

    if (centralKitchenStockItem == null)
        return 'Supplier item not defined for unit'

    return centralKitchenStockItem.unavailableReason;
}