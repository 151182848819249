import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import FormManager from '../../../../../../../utils/view/formManager'
import customerUtil from '../../../../../../../utils/view/customers/utils';
import campaignUtil from '../../../utils/campaignUtil';
import CampaignPreviewMessage from '../../../components/CampaignPreviewMessage';

const stringUtil = require('../../../../../../../utils/string/stringUtil');
const orgSelectors = require('../../../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const formatter = require('../../../../../../../utils/formatter/phoneNumberFormatter');



export default class CampaignPreviewMessageStep extends Component 
{
    constructor(props)
    {
        super(props)
        this.state = {};
        this.state.sent = false;
        this.state.error = null;
        this.state.status = null;

        this.franchisor = orgSelectors.selectFranchisor(currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId);
        
        const campaign = props.data;


        this.formManager = new FormManager();
        this.formManager.viewModel = campaign;
        this.formManager.view = this;

        this.formManager.onFieldChanged = (() =>
        {
            this.state.status = null;
            this.state.sent = false;
        })
        this.onPreviewCampaignMessage = this.onPreviewCampaignMessage.bind(this);
    }

    render() 
    {
        const preview = <CampaignPreviewMessage
            error={this.state.error}
            status={this.state.status}
            formManager={this.formManager} />

        return <Card>
            <CardHeader>
                Preview Campaign Message
            </CardHeader>
            <CardBody>
                {preview}
            </CardBody>
            <CardFooter>
                {this.renderNavigation()}
            </CardFooter>
        </Card>;
    }

    renderNavigation()
    {
        const campaign = this.props.data;
        const phoneNumber = formatter.formatPhoneNumberForPersistence(campaign.testPhoneNumber);
        const invalidPhoneNumber = stringUtil.isStringNullOrEmpty(phoneNumber);

        const nextDisabled = !this.state.sent;

        return (<table width="100%">
            <tr>
                <td align="left">
                    <Button color="primary" disabled={invalidPhoneNumber}
                        onClick={this.onPreviewCampaignMessage}>Send Preview Message</Button>
                </td>
                <td align="right">
                    <Button color="secondary" style={{ marginRight: '10px' }} onClick={this.props.previousStep}>Back</Button>
                    <Button color="success" disabled={nextDisabled} onClick={this.props.nextStep}>Next</Button>
                </td>
            </tr>
        </table>);
    }

    async onPreviewCampaignMessage()
    {
        const campaign = this.props.data;
        const phoneNumber = formatter.formatPhoneNumberForPersistence(campaign.testPhoneNumber);

        this.setState({ error: null, status: "Sending preview message"});

        try
        {
            await campaignUtil.previewMessage(phoneNumber, campaign);

            this.setState({ error: null, status: "Please check your mobile to see the preview and verify.", sent : true });
        }
        catch (error)
        {
            this.setState({ error: error, status: null });
        }
    }
}
