import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent'
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent'

import * as FormManager from '../../../../../utils/view/formManager';

class CreditBook extends Component 
{
    constructor(props) 
    {
        super(props);

        this.props.data.isDisabled = this.props.data.isDisabled || false;

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    render() 
    {
        return (
            <table className="component-table">

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Credit Book Name"
                            hintText="Required"
                            fieldName="name"
                            placeholder="Enter name of the credit book"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Disabled"
                            fieldName="isDisabled"
                            hintText="Disable credit book"
                            clearable={false}
                            comboReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <InputComponent
                            inputType="text"
                            caption="Credit Book Description"
                            hintText="Required"
                            fieldName="description"
                            placeholder="Enter text describing the credit book"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            multiLine={true}
                            caption="Notes"
                            rows={4}
                            fieldName="notes"
                            placeholder="Enter any notes"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

            </table>
        )
    }
}

export default CreditBook;