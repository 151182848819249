
import React, { Component } from 'react';
import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../components/form/booleanSelectComponent';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import Select from "react-select";

const FormManager = require('../../../../utils/view/formManager.js');
const signagePlayerUtil = require('../../../../utils/domain/signagePlayerUtil');
const constants = require('../../../../utils/domain/constants');
const stationState = require('../stationState');

class POSConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.model;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>


                            <tr>
                                <td>
                                    <SelectComponent
                                        caption="Choose Storefront"
                                        fieldName="storeFrontId"
                                        hintText="Select storefront"
                                        optionFieldName="id"
                                        optionDisplayFieldName="name"
                                        optionValues={stationState.storefronts.filter(s => s.type == constants.storefrontTypes.POS)}
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>

                                <td>
                                    <InputComponent
                                        inputType="text"
                                        caption="Float Identifier"
                                        fieldName="floatIdentifier"
                                        placeholder="Enter Float Id"
                                        hintText="Enter the Float Id"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                            </tr>
                            
                            <tr>
                                <td>
                                    <SelectComponent
                                        optionValues={signagePlayerUtil.getSlidesSignagePlayers(stationState.signagePlayers)}
                                        optionFieldName="id"
                                        optionDisplayFieldName="name"
                                        caption="Choose Signage Player"
                                        fieldName="signagePlayerId"
                                        hintText="Select the signage player for this terminal"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>

                                </td>
                            </tr>
                            
                        </tbody>

                    </ table>

    </div>
    }
}

export default POSConfiguration;