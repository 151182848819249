import React from "react";
import InputComponent from '../../../../../components/form/inputComponent.js';

const stringUtil = require('../../../../../utils/string/stringUtil');

export default function CampaignPreviewMessage(props)
{
    const formManager = props.formManager;
    return <div>
        <div>
            Enter the phone number to send the preview message. 
            Click 'Send Preview Message' and verify the message is displayed as expected.   
        </div>
        <hr></hr>
        <div>
            <InputComponent style={{ marginLeft: '5px' }}
                inputType="phone"
                caption="Phone Number"
                fieldName="testPhoneNumber"
                formManager={formManager} />
        </div>
        {renderStateMessage(props)}
    </div>
}

function renderStateMessage(props)
{
    if (!stringUtil.isStringNullOrEmpty(props.error))
    {
        return <div className="text-center text-muted" style={{ color: 'red', fontFamily: 'Shadows Into Light', fontFamily: 'Ubuntu' }}>
            <b>{props.error}</b>
        </div>
    }

    return <div className="text-center text-muted" style={{ fontFamily: 'Shadows Into Light', fontFamily: 'Ubuntu' }}>
        {props.status}
    </div>
}
