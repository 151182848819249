import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../../../components/form/multiSelectComponent';

const arraySort = require('array-sort');

const FormManager = require('../../../../../../utils/view/formManager');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const unitUtil = require('../../../../../../utils/domain/unitUtil');
const domainConstants = require('../../../../../../utils/domain/constants');

class RecipeProfile extends Component 
{

    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    getSelectedDispatchTypes = () =>
    {
        // recipeItem.dispatchTypes is a nullable comma separated string

        const recipeItem = this.formManager.viewModel;
        const dispatchTypes = recipeItem.dispatchTypes;

        if (dispatchTypes) 
        {
            return dispatchTypes.split(",").map(dispatchType => ({ dispatchType }));
        }

        return [];
    }

    onSelectedDispatchTypesChanged = (selectedDispatchTypes) =>
    {
        // recipeItem.dispatchTypes is a nullable comma separated string

        const recipeItem = this.formManager.viewModel;

        recipeItem.dispatchTypes = selectedDispatchTypes.length === 0
            ? null
            : selectedDispatchTypes.map(option => option.dispatchType).join(",");

        this.setState({});
    }

    render()
    {
        // Why we do not allow an existing ingredient to be replaced (this.props.isExisting) ?

        const availableIngredientStockItems = this.getAvailableIngredientStockItems();

        let units = unitUtil
            .getPossibleUnitsForStockItemId(this.formManager.viewModel.ingredientStockItemId, availableIngredientStockItems)
            .filter(unit => !unit.isSupplementalUnit);

        units = arraySort(units, stringUtil.localeCompareProp("code"));

        return (
            <table className="component-table">

                <tr>
                    <td>
                        <SelectComponent
                            caption="Ingredient"
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            fieldName="ingredientStockItemId"
                            optionValues={availableIngredientStockItems}
                            hintText="Select Ingredient"
                            formManager={this.formManager}
                            clearable={false}
                            comboReadOnly={this.props.isRowReadOnly || this.props.isExisting}
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        <MultiSelectComponent
                            caption="Dispatch Types"
                            valueFieldName="dispatchType"
                            displayFieldName="dispatchType"
                            options={domainConstants.getDispatchTypes()}
                            selectedOptions={this.getSelectedDispatchTypes()}
                            onSelectionChanged={this.onSelectedDispatchTypesChanged}
                            hintText="Select if this ingredient applies to specific dispatch types"
                            comboReadOnly={this.props.isRowReadOnly}
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        <table className="component-table">
                            <tr>
                                <td width="70%">
                                    <InputComponent
                                        inputType="text"
                                        caption="Quantity"
                                        fieldName="quantity"
                                        placeholder="Enter ingredient quantity"
                                        hintText="Required"
                                        inputReadOnly={this.props.isRowReadOnly}
                                        formManager={this.formManager} />
                                </td>
                                <td width="30%">
                                    <SelectComponent
                                        caption="Unit"
                                        optionFieldName="id"
                                        optionDisplayFieldName="code"
                                        fieldName="unitTypeId"
                                        optionValues={units}
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        searchable={false}
                                        formManager={this.formManager}
                                        hintText="Required"
                                    />
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>

            </table>
        )
    }

    getAvailableIngredientStockItems = () =>
    {
        // Filter out any stockItem where editStockItem exists as an ingredient in the recipe chain

        const recipeItem = this.props.data;
        const editStockItem = this.props.stockItem;
        const stockItemsLookupData = this.props.lookupData.stockItemsLookupData;

        return stockItemsLookupData.filter(stockItem =>
        {
            if (stockItem.id === editStockItem.id) return false;    // Exclude self

            if (editStockItem.recipeProfile.recipeItems.some(recipeItem => recipeItem.ingredientStockItemId === stockItem.id))
            {
                // Exclude editStockItem recipeItems except the one currently being edited
                return recipeItem.ingredientStockItemId == null ? false : recipeItem.ingredientStockItemId === stockItem.id;
            }

            if (stockItem.recipeProfile == null) return true;   // Allow raw item

            // Implies stockItem with recipeProfile, we exclude such stockItem if editStockItem exists as one of the
            // recipeItems in the entire recipe chain evaluated through recursion below

            let editStockItemExistsAsIngredient = false;

            (function checkIngredients(stockItem)
            {
                if (editStockItemExistsAsIngredient === true) return;

                const recipeItems = stockItem.recipeProfile.recipeItems;

                if (recipeItems.some(recipeItem => recipeItem.ingredientStockItemId === editStockItem.id)) 
                {
                    editStockItemExistsAsIngredient = true;
                    return;
                }

                for (const recipeItem of recipeItems) 
                {
                    const recipeStockItem = stockItemsLookupData.find(stockItem => stockItem.id === recipeItem.ingredientStockItemId);
                    if (recipeStockItem && recipeStockItem.recipeProfile) 
                    {
                        checkIngredients(recipeStockItem);
                    }
                }
            })(stockItem);

            return !editStockItemExistsAsIngredient;
        });
    }
}

export default RecipeProfile;


