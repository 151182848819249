import React, { Component } from 'react';

import TokenStringsInputComponent from '../../../../../components/form/tokenStringsInputComponent';

export default class TaskTokens extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = this.props.formManager;
    }

    render() 
    {
        return (
            <div className='w-75'>
                <TokenStringsInputComponent
                    caption="Comment Options"
                    dataProperty="commentOptions"
                    isRowReadOnly={this.props.isRowReadOnly}
                    hintText="Specify option strings to be presented as choices to the task operator for comments"
                    minHeight="200px"
                    formManager={this.formManager} />
            </div>
        );
    }
}