
const rmsApiProxy = require('../../api/rmsApiProxy');
const constants = require('../constants');
const orgContextUtil = require('../../domain/org/orgContextUtil');
const validator = require('../../validator/validator');
var localizationUtils = require('../../domain/localizationUtils');

module.exports.calculateTotalDiscount = function(sale)
{
    var totalDiscount = 0.0;

    if (sale == null || sale.discounts == null)
        return totalDiscount;

    sale.discounts.forEach (discount =>
    {
        totalDiscount = totalDiscount + discount.amount;
    });

    return totalDiscount;
}

module.exports.calculateTotalCharges = function(sale)
{
    var totalCharges = 0.0;

    if (sale == null || sale.charges == null)
        return totalCharges;

    sale.charges.forEach (charge =>
    {
        totalCharges = totalCharges + charge.amount;
    });

    return totalCharges;
}

module.exports.calculateTotalChargesVAT = function(sale)
{
    if (sale == null || sale.charges == null || sale.charges.length <= 0)
        return 0;

    var vatTotal = 0;
    
    sale.charges.forEach (charge =>
        {
            vatTotal = vatTotal + charge.vat;
        });

    return vatTotal;
}

module.exports.calculateTotalRefunds = function(sale)
{
    var totalRefunds = 0.0;

    if (sale == null || sale.refunds == null)
        return totalRefunds;

    sale.refunds.forEach (refund =>
    {
        totalRefunds = totalRefunds + refund.refundAmount;
    });

    return totalRefunds;
}

module.exports.calculateTotalOrderPrice = function(sale)
{
    if (sale == null || sale.saleItems == null || sale.saleItems.length <= 0)
    {
        return 0;
    }

    var saleTotal = 0;
  
    sale.saleItems.forEach (saleItem =>
    {
        saleTotal = saleTotal + this.calculateTotalItemPrice(saleItem);
    });

    return saleTotal;
}

module.exports.calculateTotalItemPrice = function(saleItem)
{
    var saleTotal = saleItem.unitPrice * saleItem.quantity;
    
    saleItem.saleItems.forEach (saleItem =>
    {
        saleTotal = saleTotal + this.calculateTotalItemPrice(saleItem);
    });

    return saleTotal;
}

module.exports.calculateTotalOrderPoints = function(sale)
{
    if (sale == null || sale.saleItems == null || sale.saleItems.length <= 0)
    {
        return 0;
    }

    var pointsTotal = 0;
    sale.saleItems.forEach (saleItem =>
    {
        pointsTotal = pointsTotal + this.calculateTotalItemPoints(saleItem);
    });

    return pointsTotal;
}

module.exports.calculateTotalItemPoints = function(saleItem)
{
    var pointsTotal = saleItem.unitPoints * saleItem.quantity;
    saleItem.saleItems.forEach (saleItem =>
    {
        pointsTotal = pointsTotal + this.calculateTotalItemPoints(saleItem);
    });

    return pointsTotal;
}

module.exports.calculateTotalSalePrice = function(sale)
{
    return this.calculateTotalOrderPrice(sale) + this.calculateTotalCharges(sale)
}

module.exports.calculateTotalNetSalePrice = function(sale)
{

    var totalNetSalePrice =  this.calculateTotalSalePrice(sale) - this.calculateTotalDiscount(sale);
    if (!localizationUtils.isVatInclusive())
    {
        totalNetSalePrice = totalNetSalePrice + 
                                this.calculateTotalOrderVAT(sale) +
                                this.calculateTotalChargesVAT(sale);
    }
    return totalNetSalePrice;
}

module.exports.calculateTotalItemVAT = function(saleItem)
{
    var vatTotal = saleItem.vat * saleItem.quantity;
    
    saleItem.saleItems.forEach (saleItem =>
    {
        vatTotal = vatTotal + this.calculateTotalItemVAT(saleItem);
    });

    return vatTotal;
}

module.exports.calculateTotalOrderVAT = function(sale)
{
    if (sale == null || sale.saleItems == null || sale.saleItems.length <= 0)
        return 0;
    
    var vatTotal = 0;
    sale.saleItems.forEach (saleItem =>
    {
        vatTotal = vatTotal + this.calculateTotalItemVAT(saleItem);
    });

    return vatTotal;
}

module.exports.calculateTotalVAT = function (sale)
{
    return this.calculateTotalOrderVAT(sale) + this.calculateTotalChargesVAT(sale);
}

module.exports.calculateTotalDiscountAdjustedVAT = function (sale)
{
    // Returns total payable vat on sale after adjusting for sale discount

    const totalNonAdjustedVat = this.calculateTotalVAT(sale);   // orderVat + chargesVat
    if (totalNonAdjustedVat < constants.numericThresholdValue) return totalNonAdjustedVat;

    const totalDiscount = this.calculateTotalDiscount(sale);
    if (totalDiscount < constants.numericThresholdValue) return totalNonAdjustedVat;

    const totalOrderVat = this.calculateTotalOrderVAT(sale);
    if (totalOrderVat < constants.numericThresholdValue) return totalNonAdjustedVat;                        // Only order VAT is adjustable for discount

    const totalRetailOrderPrice = this.calculateTotalRetailOrderPrice(sale);                                // retail price exclusive of tax
    if (totalRetailOrderPrice < constants.numericThresholdValue) return totalNonAdjustedVat;

    const totalAdjustedOrderVat = totalOrderVat - totalOrderVat * totalDiscount / totalRetailOrderPrice;    // excludes charges vat

    return totalNonAdjustedVat - totalOrderVat + totalAdjustedOrderVat;
}

module.exports.calculateRemainingPayableAmount = function(sale)
{
    return this.calculateTotalNetSalePrice(sale) - sale.paidAmount;
}

module.exports.isSaleFullyPaid = function (sale)
{
    return this.calculateRemainingPayableAmount(sale) <= constants.numericThresholdValue;
}

module.exports.calculateTotalRetailOrderPrice = function (sale)
{
    let totalRetailOrderPrice = this.calculateTotalOrderPrice(sale);

    if (localizationUtils.isVatInclusive())
    {
        totalRetailOrderPrice -= this.calculateTotalOrderVAT(sale);
    }

    return totalRetailOrderPrice;
}

module.exports.calculateTotalCoversSales = function (sales)
{
    if (!Array.isArray(sales)) sales = [sales];

    let totalSalePriceIncTax = 0;
    let totalNumberOfGuests = 0;

    sales = sales.filter(sale => sale.dispatchType === constants.dispatchTypes.dineIn && validator.isNumeric(sale.noOfGuests) && sale.noOfGuests > 0);

    sales.forEach(sale =>
    {
        totalSalePriceIncTax += sale.salePriceIncTax;
        totalNumberOfGuests += sale.noOfGuests;
    });

    return { totalNumberOfGuests, totalSalePriceIncTax };
}

// These are not actual status on sales
module.exports.saleOrderState = {
    Cancelled : "Cancelled",
    IsRefunded : "IsRefunded"
}


module.exports.assignDriver = function(propertyId, saleId, driverId, saleDateTime)
{
    const orgContexts = orgContextUtil.getOrgContextsFromPropertyIds(propertyId);
    const {franchisorId, franchiseeId } = orgContexts[0];
    
    var resourceUrl = `${rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId, propertyId)}/accounts/sales/${saleId}/driver/${driverId}?saleDateTime=${saleDateTime}`;
    return rmsApiProxy.post(resourceUrl)
            .then(result =>
            {
                return Promise.resolve(result);
            }, error =>
            {
                return Promise.reject(error);
            });
}
