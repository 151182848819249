import React from 'react';
import CdnImageUploader from '../../../../../../components/cdnImage/cdnImageUploader';

const configurationManager = require('../../../../../../utils/config/configurationManager.js');

function PropertyLogo(props) {

    const imageFolder = `franchisors/${props.formManager.viewModel.franchisorId}/properties/${props.formManager.viewModel.id}/logo`;
        
    return (

        <CdnImageUploader
            isReadOnly={false}
            cloudName={configurationManager.getConfig().cdnUsersCloudName}
            imageFolder={imageFolder}
            imageUrl={props.formManager.viewModel.logoImageUrl}
            onImageChange={(url) => {
                props.formManager.viewModel.logoImageUrl = url;
                props.onImageUpdate();
            }}>
            <h4 className="display-5">No Image Found</h4>

            <p className="lead">A icon is much more than just an image; it is start of settings standards and following conventions.</p>
            <hr className="my-2" />
            <p>A well-designed icon effectively conveys the message.</p>
        </CdnImageUploader>

    )

}
export default PropertyLogo;