import React, { Component } from "react";
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent.js';

const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const FormManager = require("../../../../../utils/view/formManager");
const domainConstants = require("../../../../../utils/domain/constants");
const stringUtil = require("../../../../../utils/string/stringUtil");

const exclusionTags = domainConstants.propertyNames.discountExclusionTags;     // "exclusionTags"

class DiscountTagsComponent extends Component
{
    //
    // Configures paramsJson.tags.Discount property (Discount Exclusion Tags)
    //

    constructor(props)
    {
        super(props);

        this.state = {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.paramsJson;       // Object property
        this.state.formManager.view = this;
    }

    getDiscountOptions = () =>
    {
        const options = [];

        const tags = orgStateSelectors.selectCurrentOrgLookupData().tags;
        const discountTags = tags.filter(tag => tag.type == domainConstants.tagTypes.Discount);

        discountTags.forEach(tag => options.push({ code: tag.code }));

        return options.sort(stringUtil.localeCompareProp('code'));
    }

    getSelectedDiscountOptions = () =>
    {
        const selectedOptions = [];

        const tags = this.state.formManager.viewModel.tags[exclusionTags];   // Comma separated tags string

        if (!stringUtil.isStringNullOrEmpty(tags))
        {
            const availableOptions = this.getDiscountOptions();         // get all available options

            tags.split(",").forEach(tag => 
            {
                tag = stringUtil.trimString(tag);

                if (!stringUtil.isStringNullOrEmpty(tag))
                {
                    const selectedOption = availableOptions.find(option => option.code == tag);

                    if (selectedOption != null)
                    {
                        selectedOptions.push(selectedOption);
                    }
                }
            });
        }

        return selectedOptions;
    }

    onDiscountSelectionChanged = (selectedOptions) =>
    {
        let tags = "";

        if (selectedOptions.length > 0)
        {
            tags = selectedOptions.map(option => option.code).join();
        }

        this.state.formManager.viewModel.tags[exclusionTags] = tags;

        this.setState({});
    }

    render()
    {
        return (
            <MultiSelectComponent
                caption="Discount Exclusion Tags"
                valueFieldName="code"
                displayFieldName="code"
                options={this.getDiscountOptions()}
                selectedOptions={this.getSelectedDiscountOptions()}
                onSelectionChanged={this.onDiscountSelectionChanged}
                hintText="Select tags that will exclude this discount on menu items"
                comboReadOnly={this.props.inputReadOnly}
                style={this.props.style} />
        );
    }
}

export default DiscountTagsComponent;
