import React, { memo } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const NavigationTabs = ({ selectedTab, onChange = () => {} }) => {
     return (
          <Nav fill justified pills style={{border:"1px solid var(--bs-nav-pills-link-active-bg)",borderRadius:8}}>
               <NavItem>
                    <NavLink {...(selectedTab === 0 ? { active: true } : {})} href="#" onClick={() => onChange(0)} >
                         Overview
                    </NavLink>
               </NavItem>
               <NavItem>
                    <NavLink {...(selectedTab === 1 ? { active: true } : {})} href="#" onClick={() => onChange(1)}>
                         Settings
                    </NavLink>
               </NavItem>
          </Nav>
     );
};

export default memo(NavigationTabs);
