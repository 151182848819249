
const validator = require('../validator/validator');

//
// table style and pure-table class names
//
const tableDefaultStyle = { width: "100%" }
const pureTableClassNames = "pure-table pure-table-bordered pure-table-striped";

//
// <th> styles
//
const thDefaultStyle = { fontWeight: "bold", padding: "15px", whiteSpace: "nowrap" };
const thLeftAlignedStyle = Object.assign({}, thDefaultStyle, { textAlign: "left" });
const thCenterAlignedStyle = Object.assign({}, thDefaultStyle, { textAlign: "center" });
const thRightAlignedStyle = Object.assign({}, thDefaultStyle, { textAlign: "right" });

//
// <td> styles
//
const tdDefaultStyle = { padding: "10px", whiteSpace: "nowrap" };
const tdLeftAlignedStyle = Object.assign({}, tdDefaultStyle, { textAlign: "left" });
const tdCenterAlignedStyle = Object.assign({}, tdDefaultStyle, { textAlign: "center" });
const tdRightAlignedStyle = Object.assign({}, tdDefaultStyle, { textAlign: "right" });

//
// <tfoot>/<td> Total/SubTotal Row styles
//
const tfDefaultStyle =
{
    backgroundColor: "Gray", color: "White", fontWeight: "bold",
    padding: "10px", whiteSpace: "nowrap"
};
const tfLeftAlignedStyle = Object.assign({}, tfDefaultStyle, { textAlign: "left" });
const tfCenterAlignedStyle = Object.assign({}, tfDefaultStyle, { textAlign: "center" });
const tfRightAlignedStyle = Object.assign({}, tfDefaultStyle, { textAlign: "right" });

//
// <tfoot>/<td> Grand Total Row styles
//

const tgDefaultStyle =
{
    backgroundColor: "SlateGray", color: "White", fontWeight: "bold",
    padding: "10px", whiteSpace: "nowrap"
};
const tgLeftAlignedStyle = Object.assign({}, tgDefaultStyle, { textAlign: "left" });
const tgCenterAlignedStyle = Object.assign({}, tgDefaultStyle, { textAlign: "center" });
const tgRightAlignedStyle = Object.assign({}, tgDefaultStyle, { textAlign: "right" });

//#region Column Red/Green Highlighting

const tdStandardStyle = tdCenterAlignedStyle;
const tdGreenStyle = Object.assign({}, tdRightAlignedStyle, { fontWeight: "bolder", color: "Green" });
const tdRedStyle = Object.assign({}, tdRightAlignedStyle, { fontWeight: "bolder", color: "Red" });

const tfStandardStyle = tfCenterAlignedStyle;
const tfGreenStyle = Object.assign({}, tfRightAlignedStyle, { fontWeight: "bolder", color: "DarkGreen" });
const tfRedStyle = Object.assign({}, tfRightAlignedStyle, { fontWeight: "bolder", color: "DarkRed" });

function getDataColumnHighlightStyle(value, positiveIsGreen)
{
    //
    // positiveIsGreen = true implies value >= 0 is green
    // positiveIsGreen = false implies value <= 0 is green
    //

    positiveIsGreen = positiveIsGreen ?? true;      // default = true

    let tdResultStyle = tdStandardStyle;

    if (!validator.isNumeric(value))
    {
        return tdResultStyle;
    }

    if (positiveIsGreen) 
    {
        tdResultStyle = value < 0 ? tdRedStyle : tdGreenStyle
    }
    else
    {
        tdResultStyle = value > 0 ? tdRedStyle : tdGreenStyle
    }

    return tdResultStyle;
}

function getFooterColumnHighlightStyle(value, positiveIsGreen)
{
    //
    // positiveIsGreen = true implies value >= 0 is green
    // positiveIsGreen = false implies value <= 0 is green
    //

    positiveIsGreen = positiveIsGreen ?? true;      // default = true

    let tfResultStyle = tfStandardStyle;

    if (!validator.isNumeric(value))
    {
        return tfResultStyle;
    }

    if (positiveIsGreen) 
    {
        tfResultStyle = value < 0 ? tfRedStyle : tfGreenStyle
    }
    else
    {
        tfResultStyle = value > 0 ? tfRedStyle : tfGreenStyle
    }

    return tfResultStyle;
}

//#endregion

//#region Report div styles

const divFlexColumnStyle = { display: "flex", flexDirection: "column" };
const divBorderStyle = { margin: "0px 0px 50px 0px" };
const divContentStyle = { margin: "2px 0px 0px 0px", overflow: "auto", borderStyle: "groove" };
const divHeaderStyle =
{
    textAlign: "left",
    paddingTop: "15px", paddingBottom: "15px", paddingLeft: "15px",
    fontSize: "large", fontWeight: "bold",
    color: "white", backgroundColor: "CadetBlue"
};

//#endregion

module.exports =
{
    tableDefaultStyle,
    pureTableClassNames,

    thLeftAlignedStyle,
    thCenterAlignedStyle,
    thRightAlignedStyle,

    tdLeftAlignedStyle,
    tdCenterAlignedStyle,
    tdRightAlignedStyle,

    tfLeftAlignedStyle,
    tfCenterAlignedStyle,
    tfRightAlignedStyle,

    tgLeftAlignedStyle,
    tgCenterAlignedStyle,
    tgRightAlignedStyle,

    divFlexColumnStyle,
    divBorderStyle,
    divContentStyle,
    divHeaderStyle,

    getDataColumnHighlightStyle,
    getFooterColumnHighlightStyle,

}