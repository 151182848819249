
import React, { Component } from 'react';
import formManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

export default class PKSRBEndorsementComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.formManager = new formManager();
        this.formManager.viewModel = this.props.saleEndorsementSettings;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>
                    <tr>

                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Business Name"
                                fieldName="name"
                                placeholder="Enter Business Name"
                                hintText="Enter Business Name (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="POS Id"
                                fieldName="posId"
                                placeholder="Enter POS Id"
                                hintText="Enter POS Id registered with SRB (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                    <tr>
                    <td>
                            <InputComponent
                                inputType="text"
                                caption="NTN Number"
                                fieldName="ntn"
                                placeholder="Enter NTN Number"
                                hintText="Enter Business NTN Number (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Terminal Id"
                                fieldName="TransType"
                                placeholder="Enter Terminal Id"
                                hintText="Transaction type: 'Test' for testing, 'Live' for production (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputComponent
                                inputType="text"
                                caption="Service URL"
                                fieldName="serviceUrl"
                                placeholder="Enter service url"
                                hintText="Enter service url (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                </tbody>
            </ table>
        </div>
    }
}