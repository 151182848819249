import React, { Component } from 'react';
import './App.scss';
import { connect } from 'react-redux'

import SingleSignOnView from './views/login/SingleSignOnView';
import EmbeddedView from './EmbeddedView.jsx';

const rmsApiProxy = require('./utils/api/rmsApiProxy');
const configurationManager = require('./utils/config/configurationManager');
const currentOrgNodeSelectors = require('./utils/state/stateSelectors/currentOrgNodeSelectors');
const stateManager = require('./utils/state/stateManager.js');
const stringUtil = require('./utils/string/stringUtil.js');
const flipdishTheme = require('./utils/domain/flipdishTheme');
const environmentLoadingStagesUtils = require('./views/loading/utils/loadingUtils.js');
const logger = require('./utils/logger/logger')
const typeUtil = require('./utils/type/typeUtil.js')

class EmbeddedApp extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
                        error: null,
                        initializationInProgress: false,
                        loginRequired: false,
                     }



        this.saveStateToLocalStorage.bind(this);
        this.restoreStateFromLocalStorage();
        window.addEventListener("pagehide", this.saveStateToLocalStorage);
    }

    render()
    {
        let component = null;

        if (!stringUtil.isStringNullOrEmpty(this.state.error)) {
            component = this.renderErrorStage();
        } else {
            this.initializeApplication();

            if (this.state.loginRequired) {
                component = <SingleSignOnView />;
            } else if (this.state.initializationInProgress) {
                component = this.renderLoadingComponent();
            } else {
                component = <EmbeddedView />;
            }
        }

        return (
            <div className="App">
                {component}
            </div>
        );
    }


    initializeApplication()
    {
        const globalState = stateManager.getStore().getState();
        
        this.state.initializationInProgress = false;
        this.state.loginRequired = false;

        if (!globalState.environmentInitialized)
        {
            let environmentLoadingStages = environmentLoadingStagesUtils.getEnvironmentLoadingStages();
            this.loadEnvironmentStage(environmentLoadingStages);
           
            console.log("Initializing Environment");
            this.state.initializationInProgress = true;
            return;
        }
       
        if (globalState.passport == null)
        {
            console.log("Logging User");
            this.state.loginRequired = true;
            return;
        }

        if (!globalState.organizationInitialized)
        {
            let environmentLoadingStages = environmentLoadingStagesUtils.getOrganizationLoadingStages();
            let loadingState = {};
            this.loadOrganizationStage(environmentLoadingStages, loadingState);
           
            console.log("Initializing Organization");
            this.state.initializationInProgress = true;
            return;
        }

        this.selectCurrentOrgNode();

        if (!stringUtil.isStringNullOrEmpty(globalState.applicationModeData.storefrontId) &&
            !globalState.applicationModeData.storefrontsInitialized)
        {
            this.loadStorefronts();

            console.log("Initializing storefronts");
            this.state.initializationInProgress = true;
            return;
        }

    }

    async loadEnvironmentStage(environmentLoadingStages)
    {
        const globalState = stateManager.getStore().getState();
        const environmentLoadingStage = environmentLoadingStages.find(stage => !stage.isLoaded);

        try
        {
            if (environmentLoadingStage != null)
            {
                await environmentLoadingStage.loadData();
                environmentLoadingStage.isLoaded = true;
                this.loadEnvironmentStage(environmentLoadingStages);
                return;
            }

            globalState.environmentInitialized = true;
            this.setState({});
        } 
        catch (error)
        {
            this.setState({ error: `${environmentLoadingStage.label} failed with following error:\n\n"${error}"` });
        }
    }


    async loadOrganizationStage(orgLoadingStages, loadingState)
    {
        const globalState = stateManager.getStore().getState();
        const orgLoadingStage = orgLoadingStages.find(stage => !stage.isLoaded);

        try
        {
            if (orgLoadingStage != null)
            {
                await orgLoadingStage.loadData(loadingState);
                orgLoadingStage.isLoaded = true;
                this.loadOrganizationStage(orgLoadingStages, loadingState);
                return;
            }

            globalState.organizationInitialized = true;
            this.setState({});
        } 
        catch (error)
        {
            this.setState({ error: `${orgLoadingStage.label} failed with following error:\n\n"${error}"` });
        }
    }

    async loadStorefronts()
    {
        try
        {
            const globalState = stateManager.getStore().getState();
            const applicationModeData = globalState.applicationModeData;

            const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();
            const resourceUrl = `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/storefronts`;
            const storefronts = await rmsApiProxy.get(resourceUrl);

            applicationModeData.selectedStorefront = storefronts.find(s => stringUtil.areStringSame(s.id, applicationModeData.storefrontId));
            applicationModeData.storefrontsInitialized = true;
            this.setState({});
        }
        catch(error)
        {
            this.setState({ error: `Storefronts loading failed with following error:\n\n"${error}"` });
        }
    }

    renderLoadingComponent()
    {
        const imageUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}/loadng.gif`;

        return (
            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100%", width: '100%' }}>

                <div
                    className='d-flex justify-content-center align-items-center border rounded-circle'
                    style={{ width: '220px', height: '220px' }}>
                    <img src={imageUrl} alt="" style={{ width: '60%', height: '60%' }} />
                </div>

                <div className='mt-2 fs-3 fw-bold' style={{ color: flipdishTheme.color}}>
                    Hold On
                </div>

                <div className='text-muted'>
                    <small>Checking for updates...</small>
                </div>

            </div>
        );
    }

    selectCurrentOrgNode()
    {
        const globalState = stateManager.getStore().getState();
        const applicationModeData = globalState.applicationModeData;
        var organizationTaxonomy = globalState.orgData.organizationTaxonomy;

        var selectedOrgNode =
        {
            franchisorId: null,
            franchiseeId: null,
            propertyId: null
        };

        if (!stringUtil.isStringNullOrEmpty(applicationModeData.franchisorId))
        {
            if (organizationTaxonomy.franchisors.find(o => stringUtil.areStringSame(o.id, applicationModeData.franchisorId)))
            {
                selectedOrgNode.franchisorId = applicationModeData.franchisorId;
            }
        }

        if (!stringUtil.isStringNullOrEmpty(applicationModeData.propertyId))
        {
            let property = organizationTaxonomy.properties.find(o => stringUtil.areStringSame(o.id, applicationModeData.propertyId));
            if (property != null)
            {
                selectedOrgNode.propertyId = property.id;
                selectedOrgNode.franchiseeId = property.franchiseeId;    
            }
        }

        stateManager.getStore().getState().currentOrgNode = selectedOrgNode;
    }

    renderErrorStage()
    {
        return (
            <div className="d-flex flex-column align-items-center" style={{ backgroundColor: '#F6F9FF', height: "100%", width: '100%' }} >
                <div className='flex-grow-1 d-flex justify-content-center align-items-center'>
                    <h3 style={{ color: "#CA0030", whiteSpace: "pre-line" }}>{this.state.error}</h3>
                </div>
            </div>
        );
    }

    restoreStateFromLocalStorage()
    {
        console.log("Restoring state from local storage");

        const globalState = stateManager.getStore().getState();
        const applicationModeData = globalState.applicationModeData;

        if (applicationModeData == null || stringUtil.isStringNullOrEmpty(applicationModeData.sessionId))
        {
            this.state.error = "Failed to find out session id";
            return;    
        }

        globalState.currentFranchisorId = applicationModeData.franchisorId;
        
        if (!stringUtil.areStringSame(localStorage.getItem("embedded.sessionId"), applicationModeData.sessionId))
        {
            localStorage.clear();
            localStorage.setItem("embedded.sessionId", applicationModeData.sessionId);
            console.log("Clearing all local storage");
            return;
        }

        if (!stringUtil.areStringSame(localStorage.getItem("embedded.franchisorId"), applicationModeData.franchisorId))
            {
                localStorage.removeItem("embedded.catalogData");
                localStorage.removeItem("embedded.orgData");
                localStorage.removeItem("embedded.orgStates");
                localStorage.removeItem("embedded.propertyLocalizations");
                localStorage.removeItem("embedded.propertyLicenseSubscriptions");
                localStorage.removeItem("embedded.organizationInitialized");

                localStorage.setItem("embedded.franchisorId", applicationModeData.franchisorId);
                console.log("Clearing franchisor specific states");
                return;
            }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.configuration")))
        {
            configurationManager.setConfig(typeUtil.jsonParse(localStorage.getItem("embedded.configuration")));
            globalState.environmentInitialized = true;
            logger.resetGlobalContext();
            console.log("Previous configuration restored");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.loggedInPassport")))
        {
            globalState.passport = typeUtil.jsonParse(localStorage.getItem("embedded.loggedInPassport"));
            console.log("Previous Passport restored");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.availableFranchisors")))
        {
            globalState.availableFranchisors = typeUtil.jsonParse(localStorage.getItem("embedded.availableFranchisors"));
            globalState.availableFranchisorsLoaded = true;
            console.log("Restored availableFranchisors");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.organizationInitialized")))
        {
            globalState.catalogData = typeUtil.jsonParse(localStorage.getItem("embedded.catalogData"));
            globalState.organizationInitialized = true;
            console.log("Restored catalogData");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.organizationInitialized")))
        {
            globalState.orgData = typeUtil.jsonParse(localStorage.getItem("embedded.orgData"));
            console.log("Restored orgData");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.organizationInitialized")))
        {
            globalState.orgStates = typeUtil.jsonParse(localStorage.getItem("embedded.orgStates"));
            console.log("Restored orgStates");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.organizationInitialized")))
        {
            globalState.propertyLocalizations = typeUtil.jsonParse(localStorage.getItem("embedded.propertyLocalizations"));
            console.log("Restored propertyLocalizations");
        }

        if (!stringUtil.isStringNullOrEmpty(localStorage.getItem("embedded.organizationInitialized")))
        {
            globalState.propertyLicenseSubscriptions = typeUtil.jsonParse(localStorage.getItem("embedded.propertyLicenseSubscriptions"));
            console.log("Restored propertyLicenseSubscriptions");
        }
    }

    saveStateToLocalStorage()
    {
        console.log("Saving state to local storage");

        const globalState = stateManager.getStore().getState();
        const applicationModeData = globalState.applicationModeData;

        localStorage.clear();
        localStorage.setItem("embedded.sessionId", applicationModeData.sessionId);

        if (globalState.environmentInitialized)
        {
            localStorage.setItem("embedded.configuration", typeUtil.jsonStringify(configurationManager.getConfig()));
        }

        if (globalState.passport != null)
        {
            let json = typeUtil.jsonStringify(globalState.passport);
            localStorage.setItem("embedded.loggedInPassport", json);
        }

        if (globalState.availableFranchisorsLoaded)
        {
            let json = typeUtil.jsonStringify(globalState.availableFranchisors);
            localStorage.setItem("embedded.availableFranchisors", json);
        }

        if (globalState.organizationInitialized)
        {
            localStorage.setItem("embedded.franchisorId", applicationModeData.franchisorId);
            localStorage.setItem("embedded.organizationInitialized", "true");

            let json = typeUtil.jsonStringify(globalState.catalogData);
            localStorage.setItem("embedded.catalogData", json);

            json = typeUtil.jsonStringify(globalState.orgData);
            localStorage.setItem("embedded.orgData", json);

            json = typeUtil.jsonStringify(globalState.orgStates);
            localStorage.setItem("embedded.orgStates", json);

            json = typeUtil.jsonStringify(globalState.propertyLocalizations);
            localStorage.setItem("embedded.propertyLocalizations", json);

            json = typeUtil.jsonStringify(globalState.propertyLicenseSubscriptions);
            localStorage.setItem("embedded.propertyLicenseSubscriptions", json);
        }
    }
}


const mapStateToProps = state => 
    {
        return {
            passport: state.passport,
        }
    }
    
export default connect(mapStateToProps)(EmbeddedApp)