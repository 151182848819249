import React from 'react';

import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../components/form/multiSelectComponent';
import FormManager from '../../../../utils/view/formManager';

const domainConstants = require('../../../../utils/domain/constants');

export default function ApplianceTemperatureProfile(props)
{
    props.data.temperatureProfile = props.data.temperatureProfile || { hazardTypes: [] }
    props.data.temperatureProfile.minTemperatureLogType = domainConstants.minTemperatureLogType.fixed

    const formManager = new FormManager();
    formManager.viewModel = props.data.temperatureProfile;

    const hazardTypes = domainConstants.getHazardTypeOptions();
    const minTemperatureLogTypes = domainConstants.getMinTemperatureLogTypes();

    const [state, setState] = React.useState({});

    formManager.onFieldChanged = (event) =>
    {
        if (props.onFieldChanged)
            props.onFieldChanged();

        setState({});
    }

    const getSelectedHazardTypes = () =>
    {
        const hazardTypeCodes = props.data.temperatureProfile.hazardTypes;
        return hazardTypes.filter(x => hazardTypeCodes.includes(x.value));
    }

    const onHazardTypesSelectionChanged = (selectedOptions) =>
    {
        let hazardTypeCodes = []

        if (selectedOptions.length > 0)
        {
            hazardTypeCodes = selectedOptions.map(option => option.value);
        }

        props.data.temperatureProfile.hazardTypes = hazardTypeCodes;

        setState({});
    }

    return (
        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            caption="Minimum Temperature"
                            hintText="Min temperature to maintain for this appliance"
                            fieldName="minTemperature"
                            placeholder="Enter minimum temperature"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>

                    <td>
                        <InputComponent inputType="number"
                            caption="Maximum Temperature"
                            hintText="Max temperature to maintain for this appliance"
                            fieldName="maxTemperature"
                            placeholder="Enter maximum temperature"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <SelectComponent
                            caption="Minimum Temperature Log Type"
                            fieldName="minTemperatureLogType"
                            optionFieldName="value"
                            optionDisplayFieldName="label"
                            optionValues={minTemperatureLogTypes}
                            hintText="Required"
                            clearable={false}
                            comboReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="number"
                            caption="Minimum Temperature Logs"
                            hintText="Minimum number of temperature logs required for the appliance"
                            fieldName="minTemperatureLogs"
                            placeholder="Enter the minimum temperature logs"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>
                <tr>

                    <td>
                        <InputComponent
                            inputType="number"
                            caption="Auto Correction Logs"
                            hintText="Minimum number of temperature logs to auto-correct appliance state"
                            fieldName="autoCorrectionLogs"
                            placeholder="Enter the minimum auto-correction logs"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                    <td>
                        <MultiSelectComponent
                            caption="Hazard Types"
                            valueFieldName="value"
                            displayFieldName="label"
                            options={hazardTypes}
                            selectedOptions={getSelectedHazardTypes()}
                            onSelectionChanged={onHazardTypesSelectionChanged}
                            hintText="Select Hazard Types"
                            comboReadOnly={props.isRowReadOnly} />
                    </td>
                </tr>

            </tbody>
        </table>
    );
}