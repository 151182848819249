import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import { getVatCaption, getNomenclature } from '../../../../reportUtils/helperUtils/viewUtils';

const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


export default function SaleItemOverviewDetails(props)
{
    const salesDetailData = props.data.salesDetailData;
    const columnDefs = getColumnDefs();

    return (
        <div className="ag-theme-alpine" style={{ height: "75vh" }}>
            <AgGridReact
                domLayout='normal'
                rowData={salesDetailData}
                pinnedBottomRowData={getPinnedRows(salesDetailData)}
                gridOptions={biUtils.gridOptions}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
            />
        </div>);
}

function onGridReady(params) 
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            e.api.setPinnedBottomRowData(getPinnedRows(rows));
        }
    });
}

function getColumnDefs()
{
    const columnDefinitions = [];

    columnDefinitions.push({ field: 'propertyName', headerName: "Store", type: 'rowGroupEnabled', hide: currentOrgNodeSelectors.isCurrentPropertySelected() });
    columnDefinitions.push({ field: 'storefrontType', headerName: "Storefront Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'venueCode', headerName: "Venue", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'dispatchType', headerName: "Dispatch Type", type: 'rowGroupEnabled', hide: false });

    columnDefinitions.push({ field: 'category', headerName: "Category", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'caption', headerName: "Item", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'reportTags', headerName: "Report Tags", type: 'rowGroupEnabled', hide: false });

    columnDefinitions.push({ field: 'totalQuantity', headerName: "Quantity", type: 'fixedNumber' });
    columnDefinitions.push({ field: 'totalRetailOrderPrice', headerName: getNomenclature("retailOrderPrice"), type: 'money' });
    columnDefinitions.push({ field: 'totalTax', headerName: getVatCaption(), type: 'money' });

    return columnDefinitions;
}

function getPinnedRows(rows)
{
    return [
        {
            totalQuantity: rows.reduce((previous, current) => previous + current.totalQuantity, 0),
            totalRetailOrderPrice: rows.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
            totalTax: rows.reduce((previous, current) => previous + current.totalTax, 0),
        }
    ];
}