import React from 'react'
import InputComponent from '../../../../../../components/form/inputComponent';
import InputStyleComponent from '../../../../../../components/form/inputStyleLabel';

const formatter = require ('../../../../../../utils/formatter/formatter');
const payrollRunUtils = require('../../utils/payrollRunUtils');


function EditStaffPayrollHolidayPay({ formManager })
{
    const isReadOnly = !payrollRunUtils.isEditingAllowed();

    return <table width="100%">
    <tr>

        <td width="50%">
            <InputComponent inputType="money"
                fieldName="holidayHourlyWage"
                caption="Holiday Hourly Wage"
                hintText="Hourly wage to calculate the holiday pay"
                inputReadOnly={isReadOnly}
                formManager={formManager}
                style={{ marginRight: '20px' }} 
            />
        </td>

        <td width="50%">
            <InputComponent inputType="time"
                fieldName="holidayHours"
                caption="Holiday Hours"
                hintText="Holiday hours claimed in the current payroll period"
                inputReadOnly={isReadOnly}
                formManager={formManager}
            />
        </td>
    </tr >

    <tr>

        <td width="50%">
            <InputStyleComponent inputType="money"
                value={formatter.convertToAmountFormat(formManager.getValue("holidayPay"))}
                caption="Holiday Pay"
                hintText="Hourly pay calculate from holiday wage and holiday hours"
                style={{ marginRight: '20px' }} 
            />
        </td>
    </tr >



</table>

}

export default EditStaffPayrollHolidayPay;