import React from 'react';

export default function PageNavigation(props)
{
    const { stepSize, stepIndex } = props;

    return (

        <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
            {
                stepSize &&
                [...Array(stepSize).keys()].map(index =>
                    <span
                        style={{
                            marginRight: "2px",
                            height: `${index === stepIndex ? "40%" : "30%"}`,
                            width: `${index === stepIndex ? "10%" : "7.5%"}`,
                            display: "inline-block",
                            borderRadius: `${index === stepIndex ? "23%" : "50%"}`,
                            backgroundColor: `${index === stepIndex ? "#1813a5" : "#757575"}`,
                        }} />)
            }
        </div>);
}