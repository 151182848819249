import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridView from '../../../../../components/gridView/gridView';
import Tag from './Tag';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../../components/gridView/gridViewOptions');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const typeUtil = require('../../../../../utils/type/typeUtil');

const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const orgLookupAction = require('../../../../../utils/state/actions/orgData/lookup/lookupAction');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const domainConstants = require('../../../../../utils/domain/constants');
const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');


class Tags extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadTags = this.loadTags.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Tags";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "rows";
        gridViewOptions.getRowData = this.loadTags;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        gridViewOptions.getNewRow = () => { const newRow = { franchisorId: currentOrgNode.franchisorId }; return newRow; };
        gridViewOptions.getComponent = (isNew, row) => <Tag data={row} />;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;

        gridViewOptions.getComponentTitle =
            (isNewRow, row, customButton) =>
                isNewRow ? "Add New Tag" : `Edit Tag: ${row.type}/${row.code}`;

        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;
        gridViewOptions.isReadOnly = this.props.isReadOnly;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new tag.";

            return "Please wait while updating tag.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New tag is added successfully";

            return "Tag is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new tag:";

            return "Following error occurred while updating tag:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    loadTags()
    {
        let tags = typeUtil.deepCloneObject(this.props.tags);
        if (tags == null) tags = [];
        return arraySort(tags, stringUtil.localeCompareProp("type"), stringUtil.localeCompareProp("code"));
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.type))
            return "Type cannot be left empty";

        if (!validator.isPresent(row.code))
            return "Code cannot be left empty";
        
        if(stringUtil.areStringSame(row.type, domainConstants.tagTypes.KDSCourseName))
        {
            if (!validator.isNumeric(row.sequence))
                return "Sequence is not valid";
        }

        //
        // Tag type+code must unique across a franchisor
        //

        if (isNew)
        {
            const existingTags = this.loadTags();
            const otherTag = existingTags.find(tag => tag.type == row.type && tag.code == row.code);
            if (otherTag != null) 
            {
                return "Tag with matching type and code already exists";
            }
        }

        return null;
    }

    async save(isNew, row)
    {
        try
        {
            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/tags`;
            const updatedTag = await rmsApiProxy.post(resourceUrl, row);

            let tags = this.loadTags();
            if (tags == null) tags = [];

            const existingIndex = tags.findIndex(tag => tag.id == updatedTag.id);

            if (existingIndex > -1)
            {
                tags.splice(existingIndex, 1, updatedTag);
            }
            else
            {
                tags.push(updatedTag);
            }

            const orgState = orgStateSelectors.selectCurrentOrgState();
            actionDispatcher.dispatch(orgLookupAction.createUpdateAction(orgState, "tags", tags));

            return updatedTag;

        } catch (error)
        {
            throw error
        }
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "type";
        header.headerName = "Type";
        header.pinned = true;
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Tag Type";
        headers.push(header);

        header = {};
        header.field = "code";
        header.headerName = "Code";
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Tag Code";
        headers.push(header);

        header = {};
        header.field = "sequence";
        header.headerName = "Sequence";
        header.width = columnWidthConstants.number;
        header.headerTooltip = "Sequence";
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

const mapStateToProps = state => 
{
    return {
        tags: orgStateSelectors.selectCurrentOrgLookupData().tags
    }
}

export default connect(mapStateToProps)(Tags)
