import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import TransactionAccount from './TransactionAccount';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const stringUtil = require('../../../../utils/string/stringUtil');
const validator = require('../../../../utils/validator/validator');
const apiProxy = require('../../../../utils/api/apiProxy');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

const orgContextUtil = require('../../../../utils/domain/org/orgContextUtil');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');


class TransactionAccounts extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadTransactionAccountsAsync = this.loadTransactionAccountsAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Transaction Accounts";
        gridViewOptions.messageContext = "Transaction Account";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadTransactionAccountsAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        gridViewOptions.getNewRow = () => { const newRow = { propertyId: currentOrgNode.propertyId }; return newRow; };
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.getComponent = (isNew, row) => <TransactionAccount data={row} />;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new transaction account.";

            return "Please wait while updating transaction account.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New transaction account is added successfully";

            return "Transaction Account is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new transaction account:";

            return "Following error occurred while updating transaction account:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    async loadTransactionAccountsAsync()
    {
        try
        {
            let transactionAccounts = await apiLoadFacade.loadTransactionAccounts();
            if (transactionAccounts == null) transactionAccounts = [];
            this.enrichData(transactionAccounts);
            return arraySort(transactionAccounts, stringUtil.localeCompareProp("code"));
        }
        catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.propertyId))
            return "Property cannot be left empty";

        if (!validator.isPresent(row.name))
            return "Account name cannot be left empty";

        if (!validator.isPresent(row.code))
            return "Account code cannot be left empty";

        return null;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const updatedTransactionAccount =
                await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/transactionAccounts`, row);
            this.enrichData(updatedTransactionAccount);

            if (!remoteData) remoteData = [];

            const existingIndex =
                remoteData.findIndex(transactionAccount => transactionAccount.id == updatedTransactionAccount.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedTransactionAccount);
            else
                remoteData.push(updatedTransactionAccount);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedTransactionAccount;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    enrichData(transactionAccounts)
    {
        if (!Array.isArray(transactionAccounts)) transactionAccounts = [transactionAccounts];

        const properties = catalogSelectors.selectProperties();
        transactionAccounts.forEach(transactionAccount => 
        {
            const property = properties.find(property => property.id == transactionAccount.propertyId);
            transactionAccount.propertyName = property ? property.name : "N/A";
        });
    }

    constructGridColumnHeaders()
    {
        var headers = [];

        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "code";
        header.headerName = "code";
        header.width = columnWidthConstants.code;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "propertyName";
        header.headerName = "Property";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;

        headers.push(header);

        return headers;
    }


}

export default TransactionAccounts;
