import React, { Component } from 'react';

export default class TemperatureRangeRenderer extends Component
{
    render()
    {    
        //
        // Append temperature range info in small fonts to existing cellValue
        // when showTemperatureInfo = true
        //

        const cellValue = this.props.valueFormatted ? this.props.valueFormatted : this.props.value;

        const minTemperature = this.props.data.minTemperature;
        const maxTemperature = this.props.data.maxTemperature;
        if (!(minTemperature && maxTemperature)) return cellValue;

        const tempRangeString = (minTemperature && maxTemperature)
            ? `min: ${minTemperature} ~ max: ${maxTemperature} \u2103`
            : "Not Available";

        const isTempOutOfRange = this.props.data.isTempOutOfRange;

        if(isTempOutOfRange)
            return (            
                <span className="text-danger fw-bold">
                    <span>{cellValue}</span>
                    <span className="small font-italic">
                        &nbsp;&nbsp;&nbsp;{`[Temp range: ${tempRangeString}]`}
                    </span>
                </span>);

        return (
            <>
                <span>{cellValue}</span>
                <span className="small font-italic">
                    &nbsp;&nbsp;&nbsp;{`[Temp range: ${tempRangeString}]`}
                </span>
            </>);
    }
}
