const actionTypes = require('../../../actionTypes');
const orgSelectors = require("../../../stateSelectors/orgSelectors");

const OrgData = require('./orgData');

module.exports.createAction = function (orgData)
{
    return (
        {
            type: actionTypes.orgData,
            payload: Object.assign(new OrgData(), orgData)
        }
    );
}

module.exports.changeState = function (previousState, payload)
{
    return Object.assign({}, previousState, { orgData: payload, });
}

module.exports.createOrganizationTaxonomyAction = function (organizationTaxonomyData)
{
    let organizationTaxonomy = orgSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, organizationTaxonomyData);

    return this.createAction(Object.assign({}, orgSelectors.selectOrgData(), { organizationTaxonomy }));
}


// Update actions

module.exports.createFranchisorsUpdateAction = function (franchisors)
{
    let organizationTaxonomy = orgSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { franchisors });

    return this.createAction(Object.assign({}, orgSelectors.selectOrgData(), { organizationTaxonomy }));
}

module.exports.createFranchiseesUpdateAction = function (franchisees)
{
    let organizationTaxonomy = orgSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { franchisees });

    return this.createAction(Object.assign({}, orgSelectors.selectOrgData(), { organizationTaxonomy }));
}

module.exports.createPropertiesUpdateAction = function (properties)
{
    let organizationTaxonomy = orgSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { properties });

    return this.createAction(Object.assign({}, orgSelectors.selectOrgData(), { organizationTaxonomy }));
}

module.exports.createPropertyTaxonomiesUpdateAction = function (propertyTaxonomies)
{
    let organizationTaxonomy = orgSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { propertyTaxonomies });

    return this.createAction(Object.assign({}, orgSelectors.selectOrgData(), { organizationTaxonomy }));
}
