import React from 'react';

import Select from 'react-select';

export default function GridPageSize(props)
{
    var options = [];
    var option = {}
    option.label = "10";
    option.value = 10;
    options.push(option);
    option = {}
    option.label = "20";
    option.value = 20;
    options.push(option);
    option = {}
    option.label = "50";
    option.value = 50;
    options.push(option);
    option = {}
    option.label = "100";
    option.value = 100;
    options.push(option);
    option = {}
    option.label = "150";
    option.value = 150;
    options.push(option);
    option = {}
    option.label = "200";
    option.value = 200;
    options.push(option);

    return (<table style={{ marginLeft: '10px', marginRight: props.marginRight }}>
        <tr>
            <td>
                Page Size
                </td>
            <td>
                <Select style={{ padding: '0px', margin: '0px', marginLeft: '5px' }}
                    value={props.gridPageSize}
                    options={options}
                    clearable={false}
                    onChange={(optionValue) =>
                    {
                        if (optionValue == null || optionValue.value == null)
                            return;

                        if(props.onChange)
                            props.onChange(optionValue.value);
                    }}
                />
            </td>
        </tr>
    </table>)
}