import React from 'react';
import InputStyleComponent from '../../../../../../components/form/inputStyleLabel';

const formatter = require ('../../../../../../utils/formatter/formatter');


function EditStaffPayrollSummary({ formManager })
{
    return <table width="100%">
        <tr>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    caption="Gross wage"
                    hintText="(Actual Wage) + (Bonus) + (Holiday Pay) - (Deductions)"
                    value={formatter.convertToAmountFormat(formManager.getValue("grossWage"))}
                    style={{ marginRight: '20px' }} 
                />
            </td>

            <td width="50%">
                <InputStyleComponent inputType="money"
                    caption="Net wage"
                    hintText="(Gross Pay) - (Employee NI) - (Employee Pension) - (PAYE)"
                    value={formatter.convertToAmountFormat(formManager.getValue("netWage"))}
                />
            </td>
        </tr >
        <tr>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    value={formatter.convertToAmountFormat(formManager.getValue("netWagePayment"))}
                    caption="Net wage to pay"
                    hintText="(Net Pay) - (Advance)"
                    style={{ marginRight: '20px' }} 
                />
            </td>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    value={formatter.convertToAmountFormat(formManager.getValue("totalNIContribution"))}
                    caption="Total NI Contribution"
                    hintText="(Employer NI) + (Employee NI)"
                />
            </td>
        </tr>
        <tr>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    value={formatter.convertToAmountFormat(formManager.getValue("totalHMRCPayment"))}
                    caption="Total HMRC Payment"
                    hintText="(Total NI) + (PAYE)"
                    style={{ marginRight: '20px' }} 
                />
            </td>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    fieldName="totalPensionPayment"
                    value={formatter.convertToAmountFormat(formManager.getValue("totalPensionPayment"))}
                    caption="Total Pension Contribution"
                    hintText="(Employer Contribution) + (Employee Contribution)"
                />
            </td>
        </tr>
        <tr>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    value={formatter.convertToAmountFormat(formManager.getValue("totalPayrollCost"))}
                    caption="Total payroll cost"
                    hintText="(Net wage) + (total HMRC payment) + (total pension payment)"
                    style={{ marginRight: '20px' }} 
                />
            </td>
        </tr>
    </table>
}

export default EditStaffPayrollSummary;