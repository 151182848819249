import { useEffect, useState } from "react";
import { loadStations } from "../networkDiagramUtil";
import CallToDiagramConverter from "../middleware/CallToDiagramConverter";

export const useGetStations = () => {
     const [data, setData] = useState();
     const [isLoading, setIsLoading] = useState(true);
     const [error, setError] = useState();

     useEffect(() => {
          (async function () {
               try {
                    const stations = await loadStations();
                    setData(CallToDiagramConverter(stations));
                    setIsLoading(false);
               } catch (e) {
                    setError(e);
               }
          })();
     }, []);

     return { data, error, isLoading };
};
