
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ComponentFacade from './Component';

import Spinner from '../../components/spinner/spinner.js';
import MainPortalBanner from '../../utils/view/banners/MainPortalBanner.jsx';

const stateManager = require('../../utils/state/stateManager');
const viewTaxonomies = require('../../utils/view/taxonomies');
const stringUtil = require('../../utils/string/stringUtil');
const configurationManager = require('../../utils/config/configurationManager');
const currentOrgNodeSelectors = require('../../utils/state/stateSelectors/currentOrgNodeSelectors');
const securityManager = require('../../utils/domain/security/securityManager');

const actionTypes = require('../../utils/state/actionTypes');
const passportAction = require('../../utils/state/actions/passport/passportAction');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const mainBarAction = require('../../utils/state/actions/views/container/mainBarAction');
const mainBarComponentAction = require('../../utils/state/actions/views/container/mainBarComponentAction');
const displayAccountSwitchAction = require('../../utils/state/actions/views/container/displayAccountSwitchAction');
const localizationUtils = require('../../utils/domain/localizationUtils');
const constants = require('../../utils/domain/constants');
const accessHelper = require('./helpers/accessHelper');
const menuGenerator = require('../../utils/view/menuGenerator');
const viewUtil = require('../../utils/view/viewUtil');
const logger = require('../../utils/logger/logger.js');
const rmsApiProxy = require('../../utils/api/rmsApiProxy.js');

class MainBarView extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.waitTitle = null;
        this.state.isMenuGenerating = false;
    }

    render()
    {
        if (this.state.isMenuGenerating)
            return this.renderWaitOptions();

        if (this.props.mainBarComponent == null)
            return this.renderMenuOptions();

        return <div style={{background:'white', minHeight:'100%', paddingTop:'20px', paddingLeft:'20px', paddingRight:'20px', borderLeft:'4px solid #F6F9FF'}}>
                    <ComponentFacade/>
                </div>
    }

    renderWaitOptions()
    {
        return <div style={{marginTop:'200px', textAlign:'center'}}>
                    <Spinner text={this.state.waitTitle} />
               </div>
    }

    renderMenuOptions()
    {
        var options = this.findMainBarOptions();
        
        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload`;

        var components = [];
        options.forEach (option =>
        {
            components.push(<div style={{border: '1px solid #C8C8C8', background:'white', marginRight:'20px', 
                                         marginBottom:'20px', minWidth:'400px', width:'25vw', minHeight:'100px', 
                                         paddingTop:'20px', paddingBottom:'20px', cursor:'default', userSelect:'none'}} onClick={()=>{this.onOptionClick(option)}}>

                                <table style={{width:'100%'}}>
                                    <tr>
                                        <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                            <img src={`${rootUrl}/${option.imageUrl}`} style={{width:'60px', marginRight:'20px', marginLeft:'20px'}}/>
                                        </td>
                                        <td style={{width:'99%'}}>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <h3 style={{margin:'0px'}}>{localizationUtils.changePlaceHolderText(option.title)}</h3>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <small>{localizationUtils.changePlaceHolderText(option.description)}</small>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>)
        });

        if (components.length > 0)
        {
            return  <div style={{background:'#F6F9FF', minHeight:'100%', paddingTop:'80px', paddingLeft:'50px', paddingRight:'50px'}}>
                        {this.getMainPortalBannerIfApplicable()}
                        <div className="d-flex flex-row flex-wrap w-100">
                                {components}
                        </div>
                    </div>
        }

        return  <div style={{background:'#F6F9FF', minHeight:'100%', paddingTop:'80px', paddingLeft:'50px', paddingRight:'50px', textAlign:'center'}}>
                        <h1 style={{color:'#A9A9A9'}}>No options are currently available at currently selected organization level</h1>
                </div>
    }

    async onOptionClick(option)
    {
        if (stringUtil.areStringSame(option.actionType, "menu") && !stringUtil.isStringNullOrEmpty(option.target))
        {
            actionDispatcher.dispatch(mainBarAction.createAction(option.target));
        }

        if (stringUtil.areStringSame(option.actionType, "action") && stringUtil.areStringSame(option.actionId, "switch"))
        {
            actionDispatcher.dispatch(displayAccountSwitchAction.createAction(true));
        }

        if (stringUtil.areStringSame(option.actionType, "action") && stringUtil.areStringSame(option.actionId, "logout"))
        {
            viewUtil.showConfirmDialogue("Logout", "Are you sure you want to logout?", async () => 
            {
                try {
                    viewUtil.showSystemModalSpinner("Logging out...");
                    await rmsApiProxy.post(`core/system/security/clearRMSCookie`);
                }
                catch (error) {
                    logger.warn(`Failed to clear rms cookie: ${error}`);
                }
                finally {
                    viewUtil.closeModalSpinner("Logging out...");
                }

                if (configurationManager.getConfig().environment !== "local")
                {
                    window.location.href = `${configurationManager.getConfig().singleSignOnUrl}/logout`;
                }                
                
                const logoutAction = stateManager.getStore().getState().logoutAction;
                if(logoutAction)
                    logoutAction();
            });
        }

        if (stringUtil.areStringSame(option.actionType, "component") && !stringUtil.isStringNullOrEmpty(option.targetComponent))
        {
            actionDispatcher.dispatch(mainBarComponentAction.createAction(
                {
                    targetComponent: option.targetComponent, 
                    componentParameter: option.actionParameter, 
                    readRoles: option.readRoles,
                    writeRoles: option.writeRoles,
                }
            ));
        }

        if (stringUtil.areStringSame(option.actionType, "menuGenerator") && option.actionParameter != null)
        {
            this.generateMenu(option);
        }
    }

    async generateMenu(option)
    {
        this.state.isMenuGenerating = true;
        this.state.waitTitle = "Please wait"
        if (!stringUtil.isStringNullOrEmpty(option.actionParameter.waitTitle))
            this.state.waitTitle = option.actionParameter.waitTitle;

        this.setState({});

        try
        {
            var menuOptions = await menuGenerator.generateMenu(option.actionParameter);

            this.state.isMenuGenerating = false;
            this.state.waitTitle = null;
            
            var params = {};
            params.requiredReloading = false;
            params.option = option;
            params.menuOptions = menuOptions;
            actionDispatcher.dispatch(mainBarAction.createAction(params));
        }
        catch(error)
        {
            this.state.isMenuGenerating = false;
            this.state.waitTitle = null;
            viewUtil.showErrorAlert(`Error occurred while generating menu ${error}`);
            this.setState({});
        }
    }

    findMainBarOptions()
    {

        if (this.props.mainBarOptions != null && typeof this.props.mainBarOptions == 'object')
        {
            return this.findDynamicMainBarOptions();
        }

        if (stringUtil.isStringNullOrEmpty(this.props.mainBarSearch) || this.props.mainBarSearch.length <= 3)
        {
            var optionName = null;
        
            if (this.props.mainBarOptions == null)
            {
                optionName = stateManager.getStore().getState().sideBarSelectedOption;
            }
            else
            {
                optionName = stateManager.getStore().getState().mainBarOptions;
            }
            return this.getApplicableOptions(optionName)
        }

        var allOptions = [];
        viewTaxonomies.options.forEach(option =>
        {
            var options = this.getApplicableOptions(option.name);

            options?.forEach(option =>
            {
                if (!stringUtil.isStringNullOrEmpty(option.meta) &&
                    localizationUtils.changePlaceHolderText(option.meta).toLowerCase().includes(this.props.mainBarSearch.toLowerCase()))
                    allOptions.push(option);    
            })
        })

        return allOptions;
        
    }

    findDynamicMainBarOptions()
    {
        
        if (this.props.mainBarOptions.requiredReloading)
        {
            this.generateMenu(this.props.mainBarOptions.option);
        }
        
        this.props.mainBarOptions.requiredReloading = true;


        var options = [];
        this.props.mainBarOptions.menuOptions.forEach(option =>
        {
            const propertyLicenseSubscriptions = stateManager.getStore().getState().propertyLicenseSubscriptions;
            if (!this.isOrgLevelAppropriate(option) || !this.isActionApplicable(option) || 
                !accessHelper.isAccessAllowed(option) || !accessHelper.isModuleSubscriptionAllowed(propertyLicenseSubscriptions, option))
                return;

            options.push(option);
        })

        return options;
    }

    getApplicableOptions(optionName)
    {
        var rootOption = viewTaxonomies.options.find(o => stringUtil.areStringSame(o.name, optionName));

        const propertyLicenseSubscriptions = stateManager.getStore().getState().propertyLicenseSubscriptions;
        
        var options = [];
        rootOption?.options.forEach(option =>
        {
            if (!this.isOrgLevelAppropriate(option) || !this.isActionApplicable(option) || 
                !accessHelper.isAccessAllowed(option) || !accessHelper.isModuleSubscriptionAllowed(propertyLicenseSubscriptions, option))
                return;

            options.push(option);
        })

        return options;
    }

    isOrgLevelAppropriate(option)
    {
        if (option.actionType == "menu")
        {
            var options = this.getApplicableOptions(option.target);
            return options.length > 0;
        }

        if (stringUtil.isStringNullOrEmpty(option.orgLevel))
            return true;

        if (option.orgLevel.toLowerCase().includes("franchisor") &&
            currentOrgNodeSelectors.isCurrentFranchisorSelected())
            return true;

        if (option.orgLevel.toLowerCase().includes("franchisee") &&
            currentOrgNodeSelectors.isCurrentFranchiseeSelected())
            return true;

        if (option.orgLevel.toLowerCase().includes("property") &&
            currentOrgNodeSelectors.isCurrentPropertySelected())
            return true;

        if (option.orgLevel.toLowerCase().includes("system") &&
            currentOrgNodeSelectors.isCurrentSystemSelected())
            return true;

        return false;
    }

    isActionApplicable(option)
    {
        if (stringUtil.areStringSame(option.actionType, "action") &&
            stringUtil.areStringSame(option.actionId, "switch"))
        {
            return securityManager.isSystemUser() || stateManager.getStore().getState().availableFranchisors.length > 0
        }
        return true;
    }

    getMainPortalBannerIfApplicable()
    {
        if(!configurationManager.getConfig().showMainPortalBanner || this.props.applicationMode === constants.applicationModes.embedded)
            return;
        
        let component = null;

        if(this.getMainPortalBannerConditions().some(condition => condition))
        {
            component =    <div className="mb-4" style={{ marginTop: "-60px" }}>
                                <MainPortalBanner/>
                            </div>
        }
        
        return component;
    }

    getMainPortalBannerConditions()
    {
        return [
            this.props.sideBarSelectedOption === "modules" && this.props.mainBarOptions === null,
        ];
    }
}

const mapStateToProps = state => 
{
    return {
        mainBarOptions: state.mainBarOptions,
        mainBarSearch: state.mainBarSearch,
        sideBarSelectedOption: state.sideBarSelectedOption,
        mainBarComponent: state.mainBarComponent,
        currentOrgNode: state.currentOrgNode,
        applicationMode: state.applicationMode,
    }
}

export default connect(mapStateToProps)(MainBarView)