import React, { Component } from 'react';

import ItemEditComponent from '../purchaseOrderItems/ItemEditComponent';
import PurchaseOrderActions from './PurchaseOrderActions';
import PurchaseOrderFooter from './PurchaseOrderFooter';
import PurchaseOrderHeader from './PurchaseOrderHeader';
import PurchaseOrderHeaderInfo from './PurchaseOrderHeaderInfo';

import StockItemComboBox from './StockItemComboBox';
import EmptyData from '../../../../../../components/empty/emptyData';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';
import inventoryUtil from '../../utils/inventoryUtil';



const FormManager = require('../../../../../../utils/view/formManager');
const viewUtil = require('../../../../../../utils/view/viewUtil');
const domainConstants = require('../../../../../../utils/domain/constants');
const guidUtil = require('../../../../../../utils/guid/guidUtil');
const centralKitchenUtils = require('../../../../../setup/centralKitchen/utils/centralKitchenUtils');

export default class PurchaseOrder extends Component
{
    constructor(props)
    {
        super(props);



        this.createNewOrderItem = this.createNewOrderItem.bind(this);
        this.onHeaderFieldChanged = this.onHeaderFieldChanged.bind(this);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.state.mode = "slides";
    }

    onHeaderFieldChanged(field)
    {
        if (field === "supplierId")
        {
            const purchaseOrder = this.props.data;
            const purchaseOrderContext = this.props.purchaseOrderContext;
            const purchaseOrders = this.props.purchaseOrders;
            const inventoryData = this.props.inventoryData;

            purchaseOrder.orderItems = [];

            const reorderItems =
                inventoryUtil.getReorderItems(purchaseOrder, purchaseOrders, inventoryData, this.props.lookupData, purchaseOrderContext);

            reorderItems.forEach(item => 
            {
                const stockItem = item.stockItem;
                const suggestedSupplierUnitType = item.suggestedSupplierUnitType;
                const suggestedQuantityInSupplierUnits = Math.ceil(item.suggestedQuantityInSupplierUnits);

                const orderItem = this.createNewOrderItem(stockItem);

                const itemPrice = this.isCentralKitchen()
                    ? centralKitchenUtils.getCentralKitchenStockItemPrice(suggestedSupplierUnitType.pluCode, this.getEffectiveSupplierStockItems())
                    : { price: suggestedSupplierUnitType.price, vat: suggestedSupplierUnitType.vat };

                orderItem.unitTypeId = suggestedSupplierUnitType.unitTypeId;
                orderItem.unitPrice = itemPrice.price;
                orderItem.vat = itemPrice.vat;
                orderItem.quantity = suggestedQuantityInSupplierUnits;

                purchaseOrder.orderItems.push(orderItem);
            });
        }

        this.setState({});
    }

    createNewOrderItem(stockItem)
    {
        const supplierId = this.props.data.supplierId;

        const unitTypes = stockItem.suppliers.find(supplierProfile => supplierProfile.supplierId == supplierId).unitTypes;

        let supplierUnitType;
        let itemPrice;
        if (this.isCentralKitchen())
        {
            supplierUnitType = centralKitchenUtils.getDefaultSupplierUnitType(unitTypes, this.getEffectiveSupplierStockItems());
            if (supplierUnitType)
            {
                itemPrice = centralKitchenUtils.getCentralKitchenStockItemPrice(supplierUnitType.pluCode, this.getEffectiveSupplierStockItems());
            }
        }
        else
        {
            supplierUnitType = unitTypes[0];
            itemPrice = { price: supplierUnitType.price, vat: supplierUnitType.vat };
        }

        const orderItem = {};
        orderItem.id = guidUtil.generateGuid();
        orderItem.stockItemId = stockItem.id;
        if (supplierUnitType)
        {
            orderItem.unitTypeId = supplierUnitType.unitTypeId;
        }

        if (itemPrice)
        {
            orderItem.unitPrice = itemPrice.price;
            orderItem.vat = itemPrice.vat;
        }
        orderItem.quantity = null;

        orderItem.isSupplierAdded = false;
        orderItem.supplierNotes = null;

        return orderItem;
    }

    onDeleteOrderItem(orderItem)
    {
        const stockItem = purchaseOrderUtil.getStockItem(orderItem, this.props.lookupData);

        viewUtil.showConfirmDialogue("Delete", `You are about to delete order for '${stockItem.name}'. Are you sure?`, () =>
        {
            this.props.data.orderItems = this.props.data.orderItems.filter(s => s.id != orderItem.id);
            this.setState({});
        });
    }

    render()
    {
        const fontSize = "1.0em";

        const purchaseOrder = this.state.formManager.viewModel;

        let supplierStockItems = purchaseOrderUtil.getSupplierStockItems(purchaseOrder.supplierId, this.props.lookupData);

        const showSupplierStockItems =
            !this.props.isReadOnly &&
            purchaseOrder.status === domainConstants.PurchaseOrderStatusType.draft &&
            supplierStockItems.length > 0;

        return (
            <div className="d-flex flex-column user-select-none bg-light" style={{ fontSize: fontSize }} >

                <div className='w-100'>

                    <div>
                        <PurchaseOrderActions {...this.props} />
                    </div>

                    <div className="w-100 h-100 d-flex flex-row align-items-center bg-dark p-2 border rounded">
                        <div className='h-100 flex-grow-1'><PurchaseOrderHeader {...this.props} onFieldChanged={this.onHeaderFieldChanged} /></div>
                        <div className='h-100'><PurchaseOrderHeaderInfo {...this.props} /></div>
                    </div>

                    {
                        showSupplierStockItems &&
                        <StockItemComboBox
                            {...this.props}
                            onStockItemClick={(stockItem) =>
                            {
                                this.props.data.orderItems.unshift(this.createNewOrderItem(stockItem));
                                this.setState({});
                            }} />
                    }

                    {
                        // Highlight when a submitted order has been marked as dispatched by the supplier (central kitchen)
                        // Order marked as dispatched by the supplier cannot be recalled
                        (purchaseOrder.status === domainConstants.PurchaseOrderStatusType.submitted && purchaseOrder.isDispatchedBySupplier) &&
                        <div className='py-3 my-1 bg-white text-danger fw-bold d-flex justify-content-center border rounded'>
                            This order has been marked as "Dispatched" by the supplier
                        </div>
                    }

                </div>

                <div className="h-100 w-100 flex-grow-1 px-1 d-flex flex-column overflow-auto">
                    {this.renderItems()}
                </div>

                <div className='p-1 bg-white border shadow-sm'>
                    <PurchaseOrderFooter {...this.props} />
                </div>

            </div>);
    }

    renderItems()
    {
        if (this.props.data.orderItems.length <= 0)
        {
            return (
                <div className='p-2'>
                    <EmptyData
                        title="No order items available"
                        text="Please select supplier and stock items from respective dropdown lists to add order items" />
                </div>);
        }

        const components = [];

        this.props.data.orderItems.forEach(orderItem =>
        {
            components.push(
                <div className='mb-3 border shadow-sm'>
                    <ItemEditComponent
                        key={orderItem.id}
                        data={orderItem}
                        purchaseOrder={this.props.data}
                        purchaseOrderContext={this.props.purchaseOrderContext}
                        purchaseOrders={this.props.purchaseOrders}
                        inventoryData={this.props.inventoryData}
                        onFieldChanged={() => this.setState({})}
                        onDelete={() => this.onDeleteOrderItem(orderItem)}
                        lookupData={this.props.lookupData} />
                </div>);
        });

        return components;
    }

    isCentralKitchen()
    {
        return this.props.lookupData.centralKitchensLookupData.some(kitchen => kitchen.id == this.props.data.supplierId);
    }

    getEffectiveSupplierStockItems()
    {
        const centralKitchen = this.props.lookupData.centralKitchensLookupData.find(kitchen => kitchen.id == this.props.data.supplierId);
        return centralKitchen.effectiveSupplierStockItems;
    }
}