
import React, { Component } from 'react';

import FormManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

const payrollRunUtils = require('../utils/payrollRunUtils');

class EditPayrollRun extends Component
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            formManager: new FormManager(),
        }
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
        this.state.formManager.onFieldChanged = (event) =>
        {
            if (this.props.onChange)
                this.props.onChange();
        }
    }

    get isReadOnly() { return !payrollRunUtils.isEditingAllowed(); }

    render()
    {
        return (
            <table width="40%" style={{ marginTop: '20px' }} >
                <tbody>
                    <tr>
                        <td>
                            <InputComponent inputType="money"
                                fieldName="employerNIAllowance"
                                caption="Employer NI Allowance"
                                inputReadOnly={this.isReadOnly}
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr >

                    <tr>
                        <td>
                            <InputComponent inputType="text"
                                fieldName="HMRCPaymentReference"
                                caption="HMRC Payment Reference"
                                inputReadOnly={this.isReadOnly}
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr >
                </tbody>
            </table>
        )
    }
}

export default EditPayrollRun;