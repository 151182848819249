import React, { Component } from 'react';
import { Input } from 'reactstrap';

const formatter = require('../../../../../utils/formatter/formatter');

class ProjectionOverrideItem extends Component
{
    constructor(props)
    {
        super(props);

        this.overrideItem = this.props.overrideItem;
        this.onSetDirty = this.props.onSetDirty;
    }

    onChange = (event) =>
    {
        const target = event.target;
        const value = target.value;

        this.overrideItem.totalOverriddenGrossSales = value;
        this.onSetDirty();

        this.setState({});
    }

    render()
    {
        const isDirty = this.overrideItem.totalOverriddenGrossSales !== this.overrideItem.originalTotalOverriddenGrossSales;

        return (
            <tr>

                <td style={{ padding: "2px" }}>
                    <div style={{ minWidth: "100px" }} className="text-center font-monospace">{formatter.convertToWeekdayMonthFormat(this.overrideItem.date)}</div>
                </td>

                <td style={{ padding: "2px" }}>
                    <div style={{ minWidth: "110px" }}>
                        <Input
                            type="text" disabled
                            className="form-control form-control-sm text-end"
                            value={this.overrideItem.totalProjectedGrossSales} />
                    </div>

                </td>

                <td style={{ padding: "2px" }}>
                    <div style={{ minWidth: "110px" }}>
                        <Input
                            type="text" disabled={this.overrideItem.isReadOnly}
                            className={`form-control form-control-sm text-end ${isDirty ? "bg-warning" : ""}`}
                            value={this.overrideItem.totalOverriddenGrossSales || ""}
                            onChange={this.onChange} />
                    </div>
                </td>

            </tr >
        );
    }
}

export default ProjectionOverrideItem;
