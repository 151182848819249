import React, { Component } from "react";
import { Badge } from 'reactstrap';

import GridView from '../../../../components/gridView/gridView';
import StaffDetails from './components/StaffDetails';

const arraySort = require('array-sort');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const validator = require('../../../../utils/validator/validator');
const stringUtil = require('../../../../utils/string/stringUtil');
const guidUtil = require('../../../../utils/guid/guidUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');
const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const externalIntegrationUtils = require('../../../../utils/domain/externalIntegrations/externalIntegrationUtils');
const securityManager = require('../../../../utils/domain/security/securityManager');

class Staff extends Component
{
    constructor(props)
    {
        super(props);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadStaffAsync = this.loadStaffAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);

        this.state = {};
        this.state.staffRoles = [];
    }

    render()
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        var gridViewOptions = new GridViewOptions();
        gridViewOptions.title = "Staff";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;        

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadStaffAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;


        gridViewOptions.getNewRow =  (rows) =>
        {
            var newRow = {};
            newRow.id = guidUtil.generateGuid();
            newRow.franchiseeId = currentOrgNode.franchiseeId;
            newRow.pinCode = this.generateUniqueRandomPin(rows);
            return newRow;
        };

        gridViewOptions.isReadOnly = this.props.isReadOnly;

        gridViewOptions.getComponent = (isNew, row) => <StaffDetails data={row} staffRoles={this.state.staffRoles} isReadOnly={this.props.isReadOnly} franchisorId={currentOrgNode.franchisorId} />

        gridViewOptions.getComponentTitle = (isNew, row) => { return isNew ? 'Staff - New' : `Staff - ${row.firstName} ${row.lastName}`}

        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew)
                return "Please wait while adding new staff.";

            return "Please wait while updating staff."
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew)
                return "New staff is added successfully.";

            return "Staff is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew)
                return "Following error occurred while adding new staff:";

            return "Following error occurred while updating staff:";
        }

        gridViewOptions.isAttachmentsReadOnly = () => this.props.isReadOnly;
        gridViewOptions.getAttachmentsParam =   (isNew, row) =>
        {
            var param = {};            
            param.objectType = 'staff';
            param.objectId = row.id;
            return param;
        }

        gridViewOptions.getExternalIntegrationLogsParam = (isNew, row, customButton) => 
        {
            if (row.id == null) return null;

            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

            return {
                ...currentOrgNode,
                systemType: externalIntegrationUtils.systemTypes.all.staff,
                systemId: row.id,
            };
        }

        gridViewOptions.selectFilterData = {
            doesFilterPass: (row, selectedValue) => 
            {
                row = row || {};
                if(selectedValue && selectedValue.value === "hideResigned") 
                {
                    if (stringUtil.isStringNullOrEmpty(row.resignationDate) 
                        || (dateUtil.isValidDate(row.resignationDate) && dateUtil.isSameOrAfter(row.resignationDate, dateUtil.getDateComponent(dateUtil.getNow()))))
                    {
                        return true;
                    }

                    return false;
                }
                return true;                
            },
            defaultValue: "hideResigned",
            props: {
                options: [
                    {"value": "hideResigned", label: "Hide Resigned"},
                ],    
                clearable: true,
                searchable: false,
                width: 250,
                placeholder: "Select Filter",
            }
        };
        
        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    generateRandomPin() 
    {
        return Math.floor(10000 + Math.random() * 90000).toString();
    }
    

    generateUniqueRandomPin(remoteData)
    {
        try
        {
            while (true)
            {
                const randomPin = this.generateRandomPin();
                const existingPin = remoteData.findIndex(t => t.pinCode === randomPin);
                if (existingPin < 0)
                {
                    return randomPin;
                }
            }
        }
        catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row, customButton, rows)
    {
        if (stringUtil.isStringNullOrEmpty(row.firstName))
            return "First Name cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(row.lastName))
            return "Last name cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(row.dateOfBirth) || !dateUtil.isValidDate(row.dateOfBirth))
            return "Date of birth cannot be left empty / invalid";

        if (stringUtil.isStringNullOrEmpty(row.nationality))
            return "Nationality cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(row.address))
            return "Address cannot be left empty";

        if (!validator.isValidPhoneNumber(row.phoneNumber))
            return "Phone Number is left empty or invalid";

        if (!validator.isValidEmailAddress(row.emailAddress))
            return "Email address is empty or invalid";

        if (stringUtil.isStringNullOrEmpty(row.joiningDate) || !dateUtil.isValidDate(row.joiningDate))
            return "Joining date cannot be left empty / invalid";

        if (stringUtil.isStringNullOrEmpty(row.hourlyWage) || !validator.isNumeric(row.hourlyWage))
            return "Hourly wage cannot be left empty / invalid";

        if (stringUtil.isStringNullOrEmpty(row.niNumber))
            return "NI number cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(row.roles))
            return "Role cannot be left empty";

        if (!stringUtil.isStringNullOrEmpty(row.staffMealsLimit))
        {
            if (!validator.isGreaterThanOrEqualToZero(row.staffMealsLimit))
            {
                return "Staff meals limit is invalid";
            }
        }

        if (validator.isPresent(row.voucherFixedDiscountLimit) && !validator.isGreaterThanOrEqualToZero(row.voucherFixedDiscountLimit))
        {
            return "Voucher Fixed Discount Limit is invalid";
        }

        if (validator.isPresent(row.voucherPercentageDiscountLimit) && !validator.isGreaterThanOrEqualToZero(row.voucherPercentageDiscountLimit))
        {
            return "Voucher Percentage Discount limit is invalid";
        } 

        row.guestMealsLimit = Math.abs(parseFloat(row.guestMealsLimit) || 0.0);
        row.freebiesLimit = Math.abs(parseFloat(row.freebiesLimit) || 0.0);                       

        if (!validator.isGreaterThanOrEqualToZero(row.guestMealsLimit))
        {
            return "Guest meals limit is invalid";
        }

        if (!stringUtil.isStringNullOrEmpty(row.pinCode))
        {
            if (!validator.isNumeric(row.pinCode) || stringUtil.trimString(row.pinCode).length < 5)
                return "Pin code is invalid. It must be 5 digit code";

            const existingPin = rows.findIndex(t => (t.pinCode == row.pinCode) && (t.id != row.id));
            if(existingPin > -1)
            {
                return "Staff with same pin code already exists";
            }
        }

        return null;
    }

    async loadStaffAsync()
    {
        
        const staff = await apiLoadFacade.loadStaff();                        
        const staffRoles = await apiLoadFacade.loadStaffRoles();
        this.state.staffRoles = staffRoles;        
        
        this.enrichStaff(staff, staffRoles);

        return arraySort(staff, [stringUtil.localeCompareProp("firstName"), stringUtil.localeCompareProp("lastName")]);        
    }

    enrichStaff(staff, staffRoles)
    {
        staff = Array.isArray(staff) ? staff : [staff];

        staff.forEach(staffDatum =>
            {
                staffDatum.enrichedRoles = staffRoles.filter(x => staffDatum.roles.includes(x.id)).map(x => x.name).join();
            });
    }

    async save(isNew, row, remoteData)
    {
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/staff`;

        try
        {
            const updatedStaff = await rmsApiProxy.post(resourceUrl, row);

            this.enrichStaff(updatedStaff, this.state.staffRoles);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(staff => staff.id == updatedStaff.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedStaff);
            else
                remoteData.push(updatedStaff);

            const response = {};
            response.remoteData = arraySort(remoteData, stringUtil.localeCompareProp("firstName"));
            response.addUpdatedRow = updatedStaff;

            return response;

        } catch (error)
        {
            throw error
        }
    }

    constructGridColumnHeaders()
    {
        var headers = [];
        var header = {};

        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header.field = "franchiseeId";
        header.headerName = "franchiseeId";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "firstName";
        header.headerName = "First Name";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "lastName";
        header.headerName = "Last Name";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        header.cellRendererFramework = function (params) 
        {

            var staff = params.node.data;
            if (!dateUtil.isValidDate(staff.resignationDate))
                return staff.lastName;

            return <span>{staff.lastName}<Badge color="secondary" style={{ marginLeft: '5px' }}>R</Badge></span>;
        };
        headers.push(header);

        header = {};
        header.field = "enrichedRoles";
        header.headerName = "Roles";
        header.type = dataTypeConstants.code;
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Staff roles which decides the staff access rights";
        headers.push(header);

        header = {};
        header.field = "dateOfBirth";
        header.headerName = "Date of birth";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        headers.push(header);

        header = {};
        header.field = "niNumber";
        header.headerName = "NI Number";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "nationality";
        header.headerName = "Nationality";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "phoneNumber";
        header.headerName = "Phone number";
        header.type = dataTypeConstants.phoneNumber;
        header.width = columnWidthConstants.phoneNumber;
        headers.push(header);

        header = {};
        header.field = "address";
        header.headerName = "Address";
        header.width = columnWidthConstants.address;
        headers.push(header);

        header = {};
        header.field = "emailAddress";
        header.headerName = "Email Address";
        header.width = columnWidthConstants.emailAddress;
        headers.push(header);

        header = {};
        header.field = "joiningDate";
        header.headerName = "Joining Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        header.headerTooltip = "Date when staff started working for the business.";
        headers.push(header);

        header = {};
        header.field = "hourlyWage";
        header.headerName = "Hourly Wage";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + 25;
        headers.push(header);

        header = {};
        header.field = "staffMealsLimit";
        header.headerName = "Staff Meals Limit";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + 25;
        headers.push(header);

        header = {};
        header.field = "guestMealsLimit";
        header.headerName = "Guest Meals Limit";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + 25;
        headers.push(header);

        header = {};
        header.field = "freebiesLimit";
        header.headerName = "Freebies Limit";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + 25;
        headers.push(header);

        header = {};
        header.field = "voucherFixedDiscountLimit";
        header.headerName = "Voucher Fixed Discount Limit";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + 100;
        headers.push(header);

        header = {};
        header.field = "voucherPercentageDiscountLimit";
        header.headerName = "Voucher Percentage Discount Limit";
        header.type = dataTypeConstants.percentage;
        header.width = columnWidthConstants.percentage + 100;
        headers.push(header);

        header = {};
        header.field = "resignationDate";
        header.headerName = "Resignation Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        header.headerTooltip = "Date when staff stopped working for the business";
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Staff;