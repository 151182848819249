import React, { Component } from "react";
import CdnImageUploader from "../../../../../../components/cdnImage/cdnImageUploader";

const currentOrgNodeSelectors = require("../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors");
const configurationManager = require('../../../../../../utils/config/configurationManager')

class BrandLogo extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {};
        this.franchisorId = currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId;
        this.imageFolder = `franchisors/${this.franchisorId}/brands/${this.props.formManager.viewModel.id}/logo`;
    }

    render()
    {
        return (
            <CdnImageUploader 
                cloudName={configurationManager.getConfig().cdnUsersCloudName} 
                imageFolder={this.imageFolder} 
                imageUrl={this.props.data.logoUrl} 
                isReadOnly={this.props.isReadOnly}
                onImageChange={(url) =>
                {
                    this.props.data.logoUrl = url;
                    this.setState({});
                }}>
                <h4 className="display-5">No Photo Found</h4>

                <p className="lead">Adding a Brand logo will help you distinguish between brands.</p>
            </CdnImageUploader >
        );
    }
}

export default BrandLogo;