import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { AiOutlineCopy } from "react-icons/ai";
import { Button } from 'reactstrap';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';


const constants = require('../../../../../../utils/domain/constants');

export default class PropertyDeliveryManagementConfigurationComponent extends Component
{
    get formManager() { return this.props.formManager; }
    get configuration() { return this.props.formManager.viewModel; }
    get isReadOnly() { return this.props.isReadOnly; }

    getAvailableDeliverManagementTypes = () =>
    {
        return [{ type: constants.deliveryManagement.shipDay, label: "Shipday Delivery Management" }];
    }

    render()
    {
        let webhookRow = null;
        if(this.formManager.viewModel.type === constants.deliveryManagement.shipDay)
        {
            webhookRow = <tr>
                <td colSpan="2">
                    <InputComponent
                            inputType="code"
                            caption="Shipday Webhook URL"
                            fieldName="Shipday Webhook URL"
                            value={this.props.url}
                            inputReadOnly={true}
                            formManager={this.formManager} />
                </td>
                <td>
                    <Button color="secondary" className="btn-secondary" style={{marginLeft:'5px', background:'#D3D3D3', marginTop: '12px'}}
                            onClick={()=>
                            {
                                navigator.clipboard.writeText(this.props.url);
                            }}>
                        <AiOutlineCopy style={{fontSize:'20px', color:'#484848'}}/>
                    </Button>
                </td>
            </tr>
        }

        return (
            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Delivery Management Type"
                                fieldName="type"
                                optionFieldName="type"
                                optionDisplayFieldName="label"
                                optionValues={this.getAvailableDeliverManagementTypes()}
                                hintText="Required"
                                clearable={true}
                                comboReadOnly={this.isReadOnly}
                                formManager={this.formManager}
                                style={{ marginRight: "20px" }} />
                        </td>

                        <td>
                            <InputComponent
                                inputType="code"
                                caption="Account API Key"
                                fieldName="accountApiKey"
                                placeholder="Account API Key"
                                hintText="Required"
                                inputReadOnly={this.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                    {webhookRow}

                </tbody>
            </table>);
    }
}
