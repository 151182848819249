
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js'
import BooleanSelectComponent from "../../../../../components/form/booleanSelectComponent";
import SelectComponent from '../../../../../components/form/selectComponent';
import SimpleToolTip from '../../../../../components/tooltip/simpleToolTip';

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');

const stringUtil = require('../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil.js');
const commonElementUtil = require('../../../../../utils/template/commonElementUtil');

class TextElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {

        var fontWeight = this.props.element.bold ? "bold" : "normal";

        var separatorLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.separatorLineCharacter))
        {
            var lineText = "";
            for (var index = 0; index < 100; index++)
                lineText = `${lineText}${this.props.element.separatorLineCharacter}`;

            separatorLineComponent = <div style={{width:'100%', whiteSpace:'nowrap', textOverflow:'', overflow:'hidden', padding:'0px', margin:'0px'}}> {lineText}</div>
        }

        var newLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.newLine))
        {
            newLineComponent = <div style={{width:'100%', minHeight:`${10 * this.props.element.newLine}px`, padding:'0px', margin:'0px'}}></div>
        }

        var fontSize = "14px";
        if (stringUtil.areStringSame(this.props.element.fontSize, "Large"))
            fontSize = fontSize = "18px"

        if (stringUtil.areStringSame(this.props.element.fontSize, "Extra Large"))
            fontSize = fontSize = "22px"

        var isReversed = Boolean(this.props.element.reversed);

        var suffix = this.props.suffix;

        if(this.props.isDate)
        {
            if(!this.props.element.dateFormat)
            {    
                suffix = dateUtil.formatDate(suffix, this.props.defaultFormat);
            }
            else
            {
                try 
                {
                    suffix = dateUtil.formatDate(suffix, this.props.element.dateFormat);    
                } 
                catch (error) 
                {
                    suffix = error.toString();
                }
            }
        }

        return <div style={{width:'100%', padding:'0px', margin:'0px', textAlign:this.props.element.align, fontWeight:fontWeight, fontSize:fontSize}}
                    onDoubleClick={()=>
                        {
                            var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                            viewUtil.openModalForm(`${this.props.element.title}`, ()=>
                            {
                                return <DialogueBox isDate={this.props.isDate} isJustifiable={this.props.isJustifiable} element={clonedElement}/>
                            }, 
                            ()=>
                            {
                                this.props.element.caption = clonedElement.caption;
                                this.props.element.align = clonedElement.align;
                                this.props.element.bold = clonedElement.bold;
                                this.props.element.fontSize = clonedElement.fontSize;
                                this.props.element.reversed = clonedElement.reversed;
                                this.props.element.separatorLineCharacter = clonedElement.separatorLineCharacter;
                                this.props.element.dateFormat = clonedElement.dateFormat;
                                this.props.element.newLine = stringUtil.isStringNullOrEmpty(clonedElement.newLine) ? 0 : clonedElement.newLine * 1;
                                this.setState({});
                            }, 
                            ()=>
                            {
                                return commonElementUtil.isValidElement(clonedElement);
                            })
                        }}>
                            <div style={{whiteSpace:'pre', backgroundColor: isReversed ? '#000000' : null, color: isReversed ? '#FFFFFF' : null}}>{this.props.element.caption}{suffix}</div>
                            {separatorLineComponent}
                            {newLineComponent}
               </div>;
    }
}


class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;

        this.state.alignTypes = [
                                    {
                                        align:"Left"
                                    },

                                    {
                                        align:"Center"
                                    },

                                    {
                                        align:"Right"
                                    }
                                ];

        this.state.fontSizes = [
                                    {
                                        fontSize:"Normal"
                                    },

                                    {
                                        fontSize:"Large"
                                    },

                                    {
                                        fontSize:"Extra Large"
                                    }
                                ];
    }

    render()
    {
        return <table style={{width:'100%'}}>
                    <tr>
                        <td colSpan={2}>
                            <InputComponent
                                inputType="text"
                                caption="Caption"
                                hintText="Caption"
                                fieldName="caption"
                                placeholder="Enter caption"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    {
                        this.props.isDate
                        ?   <tr>
                                <td>
                                    <>
                                        <InputComponent
                                            id={"dateFormat"}
                                            inputType="text"
                                            caption="Date Format"
                                            hintText="Date Format"
                                            fieldName="dateFormat"
                                            placeholder="Enter date format"
                                            formManager={this.state.formManager} />
                                        <SimpleToolTip
                                            target={"dateFormat"}
                                            type="dark"
                                            placement="right">
                                            <div className="text-start text-nowrap">
                                                DD = 01 02 ... 30 31<br />
                                                MM = 01 02 ... 11 12<br />
                                                YY = 70 71 ... 29 30<br />
                                                YYYY = 1970 1971 ... 2029 2030<br />
                                                ddd = Sun Mon ... Fri Sat<br />
                                                MMM = Jan  Feb ... Nov Dec<br />
                                                HH = 00 01 ... 22 23<br />
                                                hh = 01 02 ... 11 12<br />
                                                mm = 00 01 ... 58 59<br />
                                                a = am/pm<br />
                                            </div>
                                        </SimpleToolTip>
                                    </>
                                </td>
                            </tr>
                        :   null
                    }
                    <tr>
                        <td>
                            <SelectComponent
                                style={{marginRight:'5px'}}
                                optionValues={this.state.alignTypes}
                                optionFieldName="align"
                                optionDisplayFieldName="align"
                                caption="Alignment"
                                fieldName="align"      // target.id
                                hintText="Choose alignment (Required)"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                style={{marginLeft:'5px'}}
                                caption="Bold"
                                fieldName="bold"
                                hintText="Is Font Bold"
                                clearable={false}
                                formManager={this.state.formManager}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SelectComponent
                                style={{marginRight:'5px'}}
                                optionValues={this.state.fontSizes}
                                optionFieldName="fontSize"
                                optionDisplayFieldName="fontSize"
                                caption="Font Size"
                                fieldName="fontSize"      // target.id
                                hintText="Choose font size (Required)"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                style={{marginLeft:'5px'}}
                                caption="Reversed"
                                fieldName="reversed"
                                hintText="White text on black background"
                                clearable={false}
                                formManager={this.state.formManager}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                style={{marginRight:'5px'}}
                                inputType="text"
                                caption="Line Separator Character"
                                hintText="Line Separator Character"
                                fieldName="separatorLineCharacter"
                                placeholder="Enter line Separator Character"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                style={{marginLeft:'5px'}}
                                inputType="text"
                                caption="Number Of Empty Lines"
                                hintText="Number Of Empty Lines"
                                fieldName="newLine"
                                placeholder="Enter number Of Empty Lines"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
               </table>;
    }
}


export default TextElement;