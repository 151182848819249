const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const constants = require('../../../../../utils/domain/constants');

export async function load (storefront)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/businessHourOverrides`;
    const businessHourOverrides = await rmsApiProxy.get(resourceUrl);

    for (let index = 0; index < businessHourOverrides.length; index++) 
    {
        const businessHourOverride = businessHourOverrides[index];
        enrichBusinessHourOverride(businessHourOverride);
    }

    return businessHourOverrides;
}

function enrichBusinessHourOverride(businessHourOverride)
{
    businessHourOverride.formattedFromDateTime = dateUtil.convertToLocalStandard(businessHourOverride.fromDateTime);
    businessHourOverride.formattedToDateTime = dateUtil.convertToLocalStandard(businessHourOverride.toDateTime);
}

export async function save (storefront, businessHourOverride)
{
    enrichBusinessHourOverride(businessHourOverride);

    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/businessHourOverrides`;
    await  rmsApiProxy.post(resourceUrl, businessHourOverride);
}

export function deleteBusinessHourOverride (storefront, businessHourOverrideId)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/businessHourOverrides/${businessHourOverrideId}`;
    return rmsApiProxy.deleted(resourceUrl);
}

export function validateBusinessHourOverride (businessHourOverride)
{
    if (stringUtil.isStringNullOrEmpty(businessHourOverride.isOpen))
        return 'Open/Close Override cannot be left empty';

    if (stringUtil.isStringNullOrEmpty(businessHourOverride.fromDateTime))
        return 'Start Date Time cannot be left empty';

    if (stringUtil.isStringNullOrEmpty(businessHourOverride.toDateTime))
        return 'End Date Time cannot be left empty';

    if (!dateUtil.isValidDateTime(businessHourOverride.fromDateTime))
        return 'Start Date Time is not valid Date Time';

    if (!dateUtil.isValidDateTime(businessHourOverride.toDateTime))
        return 'End Date Time is not valid Date Time';

    if (dateUtil.isBefore(businessHourOverride.toDateTime, businessHourOverride.fromDateTime))
        return 'End Date Time cannot be before Start Date Time';

    if (dateUtil.isBefore(businessHourOverride.fromDateTime, dateUtil.getNow()))
        return 'Start Date Time cannot be before Current Date Time';

    return null;
}