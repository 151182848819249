
import React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import { FormGroup, FormText, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import * as inputStyleUtil from './inputStyleUtil';

const stringUtil = require('../../utils/string/stringUtil.js');
const dateUtil = require('../../utils/dateUtil/dateUtil.js');

export default function DateTimeInputComponent(props)
{
    const { mainStyle, backgroundColorStyle } = inputStyleUtil.getInputStyles(props);

    const addonIcon = "fa fa-calendar";

    var hintTextComponent = null;
    if (props.hintText)
    {
        hintTextComponent = <FormText color="muted">{props.hintText}</FormText>
    }

    const captionTextComponent = <Label>{props.caption}</Label>

    var currentValue = "";
    var currentDateValue = null;
    var currentHour = null;
    var currentMinute = null;

    var rawCurrentValue = props.formManager.getValue(props.fieldName);

    if (!stringUtil.isStringNullOrEmpty(rawCurrentValue))
    {
        currentValue = dateUtil.convertToLocalStandard(rawCurrentValue);
        currentDateValue = dateUtil.getDateComponent(currentValue);

        if (props.inputType !== "date")
        {
            currentHour = dateUtil.formatDate(currentValue, "HH")
            currentMinute = dateUtil.formatDate(currentValue, "mm")
        }
    }

    return (<FormGroup {...props} style={mainStyle}>

        {captionTextComponent}

        <InputGroup>

            <InputGroupText style={{ maxHeight: '35px' }}><i className={addonIcon}></i></InputGroupText>
            
            {renderDateComponent()}
            <Label style={{ paddingLeft: '10px', paddingRight: '5px', paddingTop: '10px' }}>Hrs:</Label>
            {renderHourComponent()}
            <Label style={{ paddingLeft: '10px', paddingRight: '5px', paddingTop: '10px' }}>Mins:</Label>
            {renderMinuteComponent()}
        </InputGroup>

        {hintTextComponent}

    </FormGroup>);

    function renderDateComponent()
    {
        return (
            <Input
                {...props}
                type="date"
                id={props.fieldName}
                readOnly={props.inputReadOnly}
                value={currentDateValue}
                onChange={(event) =>
                {
                    var target = {};
                    target.type = "date";
                    target.id = props.fieldName;


                    var value = event.target.value;
                    if (value == null || stringUtil.isStringNullOrEmpty(value) || currentValue == null)
                    {
                        target.value = null;
                    }
                    else
                    {
                        if (currentHour != null && currentMinute != null)
                        {
                            target.value = `${value} ${currentHour}:${currentMinute}:00`;
                        }
                        else
                        {
                            target.value = `${value} 00:00:00`;
                        }
                    }

                    var event = {};
                    event.target = target;
                    props.formManager.onValueChanged(event);
                }}
                 style={backgroundColorStyle} />)
    }

    function renderHourComponent()
    {
        return (
            <Select
                {...props}
                id={props.fieldName}
                value={currentHour}
                options={currentValue != null ? getPossibleHours() : []}
                disabled={props.inputReadOnly}
                placeholder="Hrs"
                clearable={false}
                style={Object.assign({}, backgroundColorStyle, { minWidth: '60px' })}

                onChange={(optionValue) =>
                {
                    var target = {};
                    target.type = "time";
                    target.id = props.fieldName;

                    if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value) || !currentValue)
                    {
                        target.value = null;
                    }
                    else
                    {
                        target.value = dateUtil.setHours(currentValue, optionValue.value);
                        target.value = dateUtil.setSeconds(target.value, 0);
                    }

                    var event = {};
                    event.target = target;
                    props.formManager.onValueChanged(event);
                }} />
        );
    }

    function renderMinuteComponent()
    {
        return (
            <Select
                {...props} 
                id={props.fieldName}
                value={currentMinute}
                options={currentValue != null ? getPossibleMinutes() : []}
                disabled={props.inputReadOnly}
                placeholder="Min"
                clearable={false}
                style={Object.assign({}, backgroundColorStyle, { minWidth: '60px' })}
                onChange={(optionValue) =>
                {
                    var target = {};
                    target.type = "time";
                    target.id = props.fieldName;

                    if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value) || !currentValue)
                    {
                        target.value = null;
                    }
                    else
                    {
                        target.value = dateUtil.setMinutes(currentValue, optionValue.value);
                        target.value = dateUtil.setSeconds(target.value, 0);
                    }

                    var event = {};
                    event.target = target;
                    props.formManager.onValueChanged(event);
                }} />
        );
    }
}

function getPossibleHours()
{
    var options = [];

    for (var i = 0; i <= 23; i++)
    {
        var formattedString = i + "";
        formattedString = formattedString.padStart(2, '0');

        let option = {};
        option.value = formattedString;
        option.label = formattedString;
        options.push(option);
    }
    return options;
}

function getPossibleMinutes()
{
    var options = [];

    for (var i = 0; i <= 59; i++)
    {
        var formattedString = i + "";
        formattedString = formattedString.padStart(2, '0');

        let option = {};
        option.value = formattedString;
        option.label = formattedString;
        options.push(option);
    }
    return options;
}