import React from "react";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import DetailsSectionContainer from "./DetailsSectionContainer";

const localizationUtils = require('../../../../../utils/domain/localizationUtils');

const formatter = require("../../../../../utils/formatter/formatter");
const dateUtil = require("../../../../../utils/dateUtil/dateUtil");
const backgroundColor = "#e0e0e0";

const SaleSummaryDetails = (props) => {
    const isSaleCancelled = props.sale.isCancelled;

    const brand = props.sale.brand;

    var minWidth = "230px";
    return (
        <CollapsibleCard caption="Sale Summary" isDefaultOpen={props.isDefaultOpen}>
            <table width="100%">
                <tr style={{ verticalAlign: "top" }}>
                    <td width="50%">
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Order Number</th>
                                <td width="49%">{props.sale.number}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Business Date</th>
                                <td width="49%">{dateUtil.convertToLocalStandard(props.sale.businessDate)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Sale Time</th>
                                <td width="49%">{dateUtil.convertToLocalStandard(props.sale.dateTime)}</td>
                            </tr>

                            {
                                brand 
                                && <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Brand</th>
                                        <td width="49%">{brand}</td>
                                    </tr>
                            }

                            <tr className="border">
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Storefront</th>
                                <td width="49%">{props.sale.storefrontName}</td>
                            </tr>
                            <tr className="border">
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Venue</th>
                                <td width="49%">{props.sale.venueCode}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Sale Type</th>
                                <td width="49%">{props.sale.saleType}</td>
                            </tr>

                            <tr className="border">
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Dispatch Type</th>
                                <td width="49%">{props.sale.dispatchType}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Operator</th>
                                <td width="49%">{props.sale.operator}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Channel</th>
                                <td width="49%">{props.sale.channel}</td>
                            </tr>
                        </DetailsSectionContainer>
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Paid Amount</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.paidAmount)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Total Refunds</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.totalRefunds)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Paid Amount</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.netPaidAmount)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Balance</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.balance)}</td>
                            </tr>
                        </DetailsSectionContainer>
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Retail Price</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.retailPrice)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Discount</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.discount)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Sale Refund</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.saleRefund)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Retail Price</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.netRetailPrice)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Charge</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netCharge)}
                                </td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Sale Price</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netSalePrice)}
                                </td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Sale {localizationUtils.getVatCaption()}</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netSaleTax)}
                                </td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Sale Price Inc {localizationUtils.getVatCaption()}</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netSalePriceIncTax)}
                                </td>
                            </tr>
                        </DetailsSectionContainer>
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Charge</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.charge)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Charge Refund</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.chargeRefund)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Charge</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netCharge)}
                                </td>
                            </tr>
                        </DetailsSectionContainer>


                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Fee Amount</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.feeAmount)}</td>
                            </tr>
                        </DetailsSectionContainer>
                    </td>
                    <td width="50%">
                    <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Table Number</th>
                                <td width="49%">{props.sale.tableNumber}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Server</th>
                                <td width="49%">{props.sale.server}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>No Of Guests</th>
                                <td width="49%">{props.sale.noOfGuests}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Linked Tables</th>
                                <td width="49%">{props.sale.linkedTables}</td>
                            </tr>
                        </DetailsSectionContainer>
                        <DetailsSectionContainer>
                            {isSaleCancelled ? (
                                <>
                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Is Cancelled</th>
                                        <td width="49%">{props.sale.isCancelled ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Cancelled By</th>
                                        <td width="49%" colSpan="3">
                                            {props.sale.cancelledBy}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Cancellation Reason</th>
                                        <td width="49%" colSpan="3">
                                            {props.sale.cancellationReason}
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Is Sale Fully Paid </th>
                                        <td width="49%">{props.sale.isSaleFullyPaid ? "Yes" : "No"}</td>
                                    </tr>

                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Is Sale Partially Paid </th>
                                        <td width="49%">{props.sale.isSalePartiallyPaid ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Is Sale Fully Refunded </th>
                                        <td width="49%">{props.sale.isSaleFullyRefunded ? "Yes" : "No"}</td>
                                    </tr>

                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Is Sale Partially Refunded </th>
                                        <td width="49%">{props.sale.isSalePartiallyRefunded ? "Yes" : "No"}</td>
                                    </tr>
                                </>
                            )}
                        </DetailsSectionContainer>

                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Retail {localizationUtils.getVatCaption()}</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.netRetailTax)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Charge {localizationUtils.getVatCaption()}</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.netChargeTax)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Sale {localizationUtils.getVatCaption()}</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netSaleTax)}
                                </td>
                            </tr>
                        </DetailsSectionContainer>

                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Cash Receipts</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.cashReceipts)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Cash Refunds</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.cashRefunds)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Cash Receipts</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netCashReceipts)}
                                </td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Credit Receipts</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.creditReceipts)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Credit Refunds</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.creditRefunds)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Credit Receipts</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netCreditReceipts)}
                                </td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Venue Paid Receipts</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.venuePaidReceipts)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Venue Paid Refunds</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.venuePaidRefunds)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Venue Paid Receipts</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netVenuePaidReceipts)}
                                </td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Other Receipts</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.otherReceipts)}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Other Refunds</th>
                                <td width="49%">{formatter.convertToCurrencyFormat(props.sale.otherRefunds)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Net Other Receipts</th>
                                <td width="49%" colSpan="3">
                                    {formatter.convertToCurrencyFormat(props.sale.netOtherReceipts)}
                                </td>
                            </tr>
                        </DetailsSectionContainer>

                    </td>
                </tr>
            </table>
        </CollapsibleCard>
    );
};

export default SaleSummaryDetails;
