import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input, Spinner } from "reactstrap";
import { Formik, Field } from "formik";
import ToggleSwitch from "../../../../../components/switch/ToggleSwitch";
import { post, getPropertyOrgContextUrl } from "../../../../../utils/api/rmsApiProxy";
import { RoleDataItem } from "../static/roleActions";
import { FormField } from "./FormField";
import { localeCompareStr } from "../../../../../utils/string/stringUtil";

export default function AddEditRole({ modal, toggle, isEditing, role, setRole, formId, isReadOnly })
{
    const [error, setError] = useState("");
    return (
        <Modal isOpen={modal} toggle={toggle} scrollable={true} keyboard={false} backdrop="static" >
            <Formik
                initialValues={role}
                onSubmit={async (values, formikActions) =>
                {
                    setError('');
                    const requestPayload = { ...values, actions: values.getDeflatedActions() };
                    try
                    {
                        const requestUrl = `${getPropertyOrgContextUrl()}/hr/staffRoles`;
                        const staffRoleData = await post(requestUrl, requestPayload);
                        const staffRole = new RoleDataItem(staffRoleData);
                        setRole(prevRoles => {
                            const updatedRoles = prevRoles.filter(role => role.id !== staffRole.id);
                            updatedRoles.push(staffRole);
                            updatedRoles.sort((roleA, roleB) => localeCompareStr(roleA.name, roleB.name));
                            return updatedRoles;
                        })
                        toggle();
                    }
                    catch (err)
                    {
                        formikActions.setSubmitting(false);
                        setError(err);
                    }
                }}
                render={({ values, handleChange, handleSubmit, dirty, isSubmitting }) => (
                    <>
                        <ModalHeader>{isEditing ? "Edit Staff Role" : "Add Staff Role"}</ModalHeader>
                        <Form onSubmit={handleSubmit} style={{ overflowY: "scroll" }} id={formId}>
                            <ModalBody>
                                <FormField
                                    label="Role Name"
                                    inputId="name"
                                >
                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        placeholder="e.g. Store Manager, Chef, Rider"
                                        required={true}
                                        pattern="^[\w ]+$"
                                        title="Only alphabets and numbers are allowed"
                                    />
                                </FormField>
                                <FormField
                                    label="Role Description"
                                    inputId="roleDesc"
                                >
                                    <Field
                                        id="roleDesc"
                                        name="description"
                                        component="textarea"
                                        required
                                        className="form-control"
                                        style={{ height: "100px" }}
                                        placeholder="Any further details about this role"
                                        pattern="^[\w ]+$"
                                    />
                                </FormField>
                                {role.actions.map((action, index) => (
                                    <FormField
                                        key={action.code}
                                        label={action.label}
                                        inputId={action.code}
                                        description={action.description}
                                    >
                                        {(action.type === "checkbox")
                                            ? <ToggleSwitch
                                                id={action.code}
                                                name={`actions.${index}.value`}
                                                value={
                                                    values.actions[`${index}`]["value"]
                                                }
                                                checked={
                                                    values.actions[`${index}`]["value"]
                                                        ? true
                                                        : false
                                                }
                                                onChange={handleChange}
                                            />
                                            : <Input
                                                id={action.code}
                                                name={`actions.${index}.value`}
                                                value={
                                                    values.actions[`${index}`]["value"]
                                                }
                                                type={action.type}
                                                onChange={handleChange}
                                                min={0}
                                                onWheel={(e) => { e.target.blur() }}
                                            />
                                        }
                                    </FormField>
                                ))}
                            </ModalBody>
                        </Form>
                        <ModalFooter>
                            <div className="d-flex justify-content-between align-items-center w-100" style={{ fontWeight: 500 }}>
                                <div className="text-danger">
                                    {error}
                                </div>
                                <div className="d-flex flex-row-reverse">
                                    <Button
                                        color="secondary"
                                        outline
                                        style={{ margin: "0 5px" }}
                                        onClick={toggle}
                                        disabled={isSubmitting}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        style={{
                                            margin: "0 5px",
                                        }}
                                        type="submit"
                                        form={formId}
                                        color="success"
                                        disabled={!dirty || isSubmitting || isReadOnly}
                                    >
                                        {isSubmitting ? <Spinner size={'sm'} /> : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </ModalFooter>
                    </>
                )}
            ></Formik>
        </Modal>
    )
}