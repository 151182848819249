const passportSelectors = require('../state/stateSelectors/passportSelectors');
const rmsApiProxy = require('../api/rmsApiProxy');
const logger = require('../logger/logger');
const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');
const auditConstants = require('../constants/auditConstants');
const configurationManager = require('../config/configurationManager');
const catalogSelectors = require("../state/stateSelectors/catalogSelectors");

export async function addFranchisorAuditLog (auditAction, message, auditBlob, franchisor = null)
{
    if(!franchisor)
    {
        const franchisorId = currentOrgNodeSelectors.selectCurrentOrgContext().franchisorId;
        franchisor = catalogSelectors.selectFranchisor(franchisorId);
    }
    
    const orgContext = { franchisorId: franchisor.id };

    await addAuditLog (orgContext, auditConstants.module.FranchisorManagement, 
                        auditAction, franchisor.id, franchisor.name, 
                        message, auditConstants.status.Success, auditBlob);

}

export async function addPropertyAuditLog (auditAction, message, auditBlob, property = null)
{
    property = property || currentOrgNodeSelectors.selectCurrentProperty();
    const orgContext = { franchisorId: property.franchisorId, franchiseeId: property.franchiseeId, propertyId: property.id }

    await addAuditLog (orgContext, auditConstants.module.PropertyManagement, 
                       auditAction, property.id, property.name, message, 
                       auditConstants.status.Success, auditBlob);
     
}

export async function addMenuLog (auditAction, message, objectId, objectIdentifier, objectVersion)
{
    const orgContext = { franchisorId: currentOrgNodeSelectors.selectCurrentOrgContext().franchisorId };

    await addAuditLog(orgContext, auditConstants.module.MenuManagement, auditAction, objectId, objectIdentifier, message, auditConstants.status.Success, undefined, objectVersion);
}

export async function addAuditLog (orgContext, module, auditAction, objectId, objectIdentifier, message, status, objectBlob, objectVersion, stackTrace)
{
    try 
    {
        const { franchisorId, franchiseeId, propertyId } = orgContext || currentOrgNodeSelectors.selectCurrentOrgContext();
        let auditEntity = {};
        auditEntity.franchisorId = franchisorId;
        auditEntity.franchiseeId = franchiseeId;
        auditEntity.propertyId = propertyId;
        auditEntity.user = passportSelectors.selectFullName();
        
        auditEntity.module = module;
        auditEntity.objectId = objectId;
        auditEntity.objectIdentifier = objectIdentifier;
        auditEntity.objectVersion = objectVersion;
        auditEntity.auditAction = auditAction;
        auditEntity.auditMessage = message;
        auditEntity.auditStatus = status;
    
        auditEntity.auditBlob = {
            service: logger.getService(),
            env: logger.getEnvironment(), 
            version: configurationManager.getConfig().version,
            data: objectBlob
        };
        auditEntity.stackTrace = stackTrace;

        await rmsApiProxy.post(`gateway/audits`, auditEntity)
    }
    catch(error)
    {
        logger.error(`Error occurred while adding an audit log message: ${error}`)
    }
    
}

