
module.exports = function()
{
    this.title = null;
    this.componentCreator = null;
    this.onSave = null;
    this.onValidate = null;
    this.isReadOnly = false;
    this.onDelete = null;
    this.onCancel = null;
}