export default class TableColumn
{
    #columnDefinition;
    #alignment;
    #isBold;

    constructor(columnDefinition, value, alignment, isBold)
    {
        this.#columnDefinition = columnDefinition;
        this.#alignment = alignment;
        this.#isBold = isBold;

        this.value = value ?? "";
    }

    get width() { return this.#columnDefinition.width; }

    get alignment() { return this.#alignment ?? this.#columnDefinition.alignment; }
    set alignment(value) { this.#alignment = value; }

    get isBold() { return this.#isBold ?? this.#columnDefinition.isBold; }
    set isBold(value) { this.#isBold = Boolean(value); }

    renderPayload()
    {
        return {

            value: (this.value && this.value.toString()) || "",     // Printing clients expect values to be always string

            // optional/undefined override fields
            alignment: this.#alignment,
            isBold: this.#isBold,
        };
    }
}