import React, { Component } from 'react';

import { getColumnCount, formatAmount } from '../../../utils/viewUtils';

export default class DiscountsByVenueCodeSummary extends Component
{
    render()
    {
        const { discountsByVenueCode } = this.props.data;

        const cols = getColumnCount(discountsByVenueCode.length);
        const rows = Math.ceil(discountsByVenueCode.length / cols);

        return (
            <div className='container p-0'>
                {
                    Array.from({ length: rows }, (_, index) => index).map(row => 
                    {
                        return (
                            <div key={row} className='row m-0'>
                                {
                                    Array.from({ length: cols }, (_, index) => index).map(col =>
                                    {
                                        const data = discountsByVenueCode[cols * row + col];
                                        return data === undefined
                                            ? null
                                            : <div key={data.venueCode} className='col border-bottom border-end py-3'>
                                                <div className=' d-flex flex-column justify-content-center align-items-center'>
                                                    <div className='fw-bold text-muted'>{data.venueCode}</div>
                                                    <div>{formatAmount(data.totalAmount)}</div>
                                                </div>
                                            </div>
                                    })
                                }
                            </div>)
                    })
                }
            </div>
        );
    }
}