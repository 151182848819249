
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import StockItemSupplierQuestion from './StockItemSupplierQuestion';

class StockItemSupplierQuestions extends Component
{
    render()
    {
        const questions = this.props.data.questions.map(question =>
        {
            return <StockItemSupplierQuestion
                data={question}
                isRowReadOnly={this.props.isRowReadOnly}
                onRemove={(item) =>
                {
                    const index = this.props.data.questions.findIndex(x => x === item);
                    this.props.data.questions.splice(index, 1);
                    this.setState({});
                }}>
            </StockItemSupplierQuestion>;
        });

        return <Card>
            <CardHeader>
                <table width="100%" style={{ minWidth: '800px' }}>
                    <tr>
                        <td align="right">
                            {
                                this.props.isRowReadOnly
                                    ? null
                                    : <Button color="primary" className="btn-primary" style={{ marginRight: '3px' }}
                                        onClick={() =>
                                        {
                                            this.props.data.questions.push({});
                                            this.setState({});
                                        }}>
                                        <i className="fa fa-plus"></i>&nbsp;Add
                                    </Button>
                            }
                        </td>
                    </tr>
                </table>
            </CardHeader>
            <CardBody>
                <Col>
                    {questions}
                </Col>
            </CardBody>
        </Card>
    }
}

export default StockItemSupplierQuestions