import React, { Component } from 'react';
import { Button } from 'reactstrap';



const viewUtil = require('../../utils/view/viewUtil');
const stringUtil = require('../../utils/string/stringUtil');
const rmsApiProxy = require('../../utils/api/rmsApiProxy');

const currentOrgNodeSelectors = require('../../utils/state/stateSelectors/currentOrgNodeSelectors');

class AttachmentButton extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            attachments: {
                status: "loading_required",
                files: []
            },
        }

        this.onAttachmentClick = this.onAttachmentClick.bind(this);
    }

    async componentDidMount()
    {
        if (this.props.fileParam == null)
            return;

        this.state.attachments.status = 'loading';
        this.loadFiles();
    }

    async componentDidUpdate(prevProps)
    {
        if (this.props.fileParam == null)
            return;

        if (prevProps.fileParam == null ||
            this.props.fileParam.objectType != prevProps.fileParam.objectType ||
            this.props.fileParam.objectId != prevProps.fileParam.objectId)
        {
            this.state.attachments.status = 'loading';
            this.loadFiles();
        }
    }

    loadFiles()
    {
        const { objectType, objectId } = this.props.fileParam;

        const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/filesystem/${objectType}/${objectId}`;

        rmsApiProxy.get(resourceUrl)
            .then(result =>
            {
                this.state.attachments.files = result;
                this.state.attachments.status = 'loaded';
                this.setState({});
            },
                error =>
                {
                    this.state.attachments.files = [];
                    this.state.attachments.status = 'loaded';
                    this.setState({});
                });
    }

    render()
    {
        const isAttachmentAllowed =
            this.props.fileParam != null &&
            !stringUtil.isStringNullOrEmpty(this.props.fileParam.objectType) &&
            !stringUtil.isStringNullOrEmpty(this.props.fileParam.objectId);

        if (!isAttachmentAllowed)
        {
            return (<Button color="primary" className="btn-primary" style={{ marginRight: '10px' }} onClick={this.onAttachmentClick}
                disabled={true}>
                <i className="fa fa-paperclip"></i>&nbsp;Attachments ({this.state.attachments.files.length})
            </Button>)
        }

        if (this.state.attachments.status == 'loaded')
        {
            return (<Button color="primary" className="btn-primary" style={{ marginRight: '10px' }} onClick={this.onAttachmentClick}>
                <i className="fa fa-paperclip"></i>&nbsp;Attachments ({this.state.attachments.files.length})
            </Button>);
        }

        return (<Button disabled color="primary" className="btn-primary" style={{ marginRight: '10px' }} onClick={this.onAttachmentClick}>
            <i className="fa fa-paperclip"></i>&nbsp;Attachments (<i className="fa fa-spinner" />)
        </Button>);
    }

    onAttachmentClick()
    {
        const { rootFolder, orgContexts } = this.getContextFolder();
        const { objectType, objectId } = this.props.fileParam;

        viewUtil.openAttachmentForm(
            `Attachments`,
            orgContexts, rootFolder,
            objectType, objectId,
            this.props.isReadOnly || false,
            (files) =>
            {
                this.state.attachments.files = files;
                this.setState({})
            },
            this.state.attachments.files);
    }

    getContextFolder()
    {
        let rootFolder;

        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        const orgContexts = [currentOrgNodeSelectors.selectCurrentOrgContext()];

        if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
        {
            rootFolder = `franchisors/${currentOrgNode.franchisorId}`;
        }
        else if (currentOrgNodeSelectors.isCurrentFranchiseeSelected())
        {
            rootFolder = `franchisors/${currentOrgNode.franchisorId}/franchisees/${currentOrgNode.franchiseeId}`;
        }
        else if (currentOrgNodeSelectors.isCurrentPropertySelected())
        {
            rootFolder = `franchisors/${currentOrgNode.franchisorId}/franchisees/${currentOrgNode.franchiseeId}`;
        }
        else
        {

            rootFolder = `system`;
        }

        return { rootFolder, orgContexts };
    }
}

export default AttachmentButton;