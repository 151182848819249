import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent';

const FormManager = require('../../../../../utils/view/formManager');

export default class CustomerSearch extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        const formManager = new FormManager();
        formManager.viewModel = this.props.data;
        formManager.onFieldChanged = (event) =>
        {
            if (this.props.onChange)
                this.props.onChange();
        }

        return (
            <div style={{ marginTop: "20px" }}>
                <table width="100%">
                    <tr>                        
                        <td width="33%" valign='top'>
                            {this.renderName(formManager)}
                        </td>
                        <td width="33%" valign='top'>
                            {this.renderPhoneNumber(formManager)}
                        </td>
                        <td width="33%" valign='top'>                            
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.renderAddress(formManager)}
                        </td>
                        <td>
                            {this.renderEmail(formManager)}
                        </td>
                        <td>
                            {this.renderLastOrderDate(formManager)}
                        </td>
                    </tr>
                </table>
            </div>
        )
    }

    renderName(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="name"
            caption="Name"
            fieldName="name"
            hintText="Search customers based on name"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderPhoneNumber(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="phone"
            caption="Phone Number"
            fieldName="phoneNumber"
            hintText="Search customers based on phone number"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderAddress(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="address"
            caption="Address"
            fieldName="address"
            hintText="Search customers based on address"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderEmail(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="email"
            caption="Email"
            fieldName="email"
            hintText="Search customers based on email"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderLastOrderDate(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="date"
            caption="Last Order Date"
            fieldName="lastOrderDate"
            hintText="Search customers who haven't ordered since this date"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }
}