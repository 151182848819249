import * as arraySort from 'array-sort';

import * as dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';

export function getCostSummaryData(props)
{
    const { inventoryData, payrollData, venueCommissionData, fixedCostData } = props.data;

    const cogs = inventoryData.map(data => data.cogs).reduce((previous, current) => previous + current, 0.0);
    const wages = payrollData.map(data => data.wagesCost).reduce((previous, current) => previous + current, 0.0);
    const venueCommissions = venueCommissionData.map(data => data.venueCommission).reduce((previous, current) => previous + current, 0.0);
    const fixedCosts = fixedCostData.map(data => data.amount).reduce((previous, current) => previous + current, 0.0);

    const rows = [];

    [['COGS', cogs], ['Wages', wages], ['Venue Commissions', venueCommissions], ['Fixed Costs', fixedCosts]].forEach(tuple => 
    {
        rows.push({
            'costType': tuple[0],
            'cost': tuple[1]
        });
    });

    return rows;
}

export function getOverviewInventoryDetails(props)
{
    const inventoryData = props.data.inventoryData;

    const rows = [];

    rows.push({ detail: 'Sale Cost', value: inventoryData.map(data => data.totalSalePrice).reduce((v1, v2) => v1 + v2, 0) });
    rows.push({ detail: 'Wastage Cost', value: inventoryData.map(data => data.totalWastagePrice).reduce((v1, v2) => v1 + v2, 0) });
    rows.push({ detail: 'Discrepancy Cost', value: inventoryData.map(data => data.totalDiscrepancyPrice).reduce((v1, v2) => v1 + v2, 0) });

    let header;
    const headers = [];

    header = {};
    header.field = "detail";
    header.headerName = "Detail";
    headers.push(header);

    header = {};
    header.field = "value";
    header.headerName = "Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return { headers, rows }
}

export function getOverviewPayrollDetails(props)
{
    const payrollData = props.data.payrollData;

    const rows = [];

    rows.push({ detail: 'Wages', value: payrollData.map(data => data.wagesCost).reduce((v1, v2) => v1 + v2, 0) });
    rows.push({ detail: 'Holidays', value: payrollData.map(data => data.holidayCost).reduce((v1, v2) => v1 + v2, 0) });
    rows.push({ detail: 'Bonus', value: payrollData.map(data => data.bonus).reduce((v1, v2) => v1 + v2, 0) });
    rows.push({ detail: 'Employer Pension & NI', value: payrollData.map(data => data.pensionAndNationalInsurance).reduce((v1, v2) => v1 + v2, 0) });

    let header;
    const headers = [];

    header = {};
    header.field = "detail";
    header.headerName = "Detail";
    headers.push(header);

    header = {};
    header.field = "value";
    header.headerName = "Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return { headers, rows }
}

export function getOverviewVenueCommissionDetails(props)
{
    const venueCommissionData = props.data.venueCommissionData;

    const venueCodes = [...new Set(venueCommissionData.map(data => data.venueCode))];

    const rows = [];

    venueCodes.forEach(venueCode =>
    {
        rows.push({
            detail: venueCode,
            value: venueCommissionData
                .filter(data => data.venueCode === venueCode)
                .reduce((value, data) => value + data.venueCommission, 0)
        });
    });

    arraySort(rows, 'value', { reverse: true });

    let header;
    const headers = [];

    header = {};
    header.field = "detail";
    header.headerName = "Venue";
    headers.push(header);

    header = {};
    header.field = "value";
    header.headerName = "Commission";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return { headers, rows }
}

export function getOverviewFixedCostDetails(props)
{
    const fixedCostData = props.data.fixedCostData;
    const categories = [...new Set(fixedCostData.map(data => data.accountCategory))];

    const rows = [];

    categories.forEach(category =>
    {
        rows.push({
            detail: category,
            value: fixedCostData
                .filter(data => data.accountCategory === category)
                .reduce((value, data) => value + data.amount, 0)
        });
    });

    arraySort(rows, 'value', { reverse: true });

    let header;
    const headers = [];

    header = {};
    header.field = "detail";
    header.headerName = "Category";
    headers.push(header);

    header = {};
    header.field = "value";
    header.headerName = "Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return { headers, rows }
}