import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import dataTypeConstants from '../../../../../../../utils/constants/dataTypeConstants';
import biUtils from '../../../../../../../utils/bi/biUtils';

import * as reportDataUtils from '../../utils/reportDataUtils';


class TimingTabularRenderer extends Component
{
    render()
    {
        const { timingData: rowData } = this.props;

        const columnDefs = this.getColumnDefs();

        const gridOptions = { ...biUtils.gridOptions };     // Some of the default gridOptions
        gridOptions.statusBar = null;                       // not suitable for this column display
        gridOptions.rowGroupPanelShow = 'never';            // are excluded

        return (
            <div className="ag-theme-alpine" style={{ height: "80vh" }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={rowData}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                />
            </div>);
    }

    getColumnDefs = () =>
    {
        const childDefaultColDef = {
            sortable: false,
            filter: false,
            menuTabs: [],
            suppressMovable: true,
            type: dataTypeConstants.number,
        };

        const { averageTimeCaption } = this.props;

        const columnDefs = [];

        columnDefs.push({
            headerName: "Hour",
            headerClass: 'ignis-bi-border-bottom',
            cellStyle: { backgroundColor: '#eaf2ff', fontWeight: 'bold', justifyContent: 'center' },
            pinned: 'left',
            lockPinned: true,
            width: 100,
            valueGetter: ({ data }) => data.hour
        });

        reportDataUtils.dayNames.forEach(dayName => 
        {
            columnDefs.push({
                headerName: `${dayName}`,
                children: [
                    {
                        ...childDefaultColDef,
                        headerName: 'Sales',
                        headerClass: 'ignis-bi-border-bottom',
                        cellStyle: params => this.cellStyleFunc(dayName, 'saleCount', params),
                        width: 125,
                        valueGetter: ({ data }) => data[`${dayName}`] ? data[`${dayName}`]['saleCount'] : 0
                    },
                    {
                        ...childDefaultColDef,
                        headerName: `${averageTimeCaption}`,
                        headerClass: 'ignis-bi-border-bottom',
                        cellStyle: params => this.cellStyleFunc(dayName, 'averageTime', params),
                        width: 150,
                        valueGetter: ({ data }) => data[`${dayName}`] ? data[`${dayName}`]['averageTime'] : 0
                    }
                ]
            })
        });

        return columnDefs;
    }
    cellStyleFunc = (dayName, dataType, params) => 
    {
        const { rowIndex } = params;

        const dayPalette = this.colorPalette[dayName];
        const backgroundColor = rowIndex % 2 ? dayPalette.even : dayPalette.odd;

        const style = {
            backgroundColor: backgroundColor,
            justifyContent: 'end',
            fontWeight: dataType === 'averageTime' ? 'bold' : undefined
        };

        return style;
    }

    colorPalette = {
        Mon: { odd: '#FFEAE9', even: '#FFD6D5' },
        Tue: { odd: '#FBF0B8', even: '#ECE1A7' },
        Wed: { odd: '#DEFBB8', even: '#C3F880' },
        Thu: { odd: '#B8FBF7', even: '#7CEBE4' },
        Fri: { odd: '#D9E8FF', even: '#C7D8F3' },
        Sat: { odd: '#E6DAFF', even: '#D7C7F9' },
        Sun: { odd: '#FFDDF6', even: '#E9C1DE' },
        Weekly: { even: '#CFCFCF', odd: '#E7E7E7' },
    }
}

export default TimingTabularRenderer;