
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux'

import { Modal } from 'react-responsive-modal';

import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

var viewUtil = require('../../utils/view/viewUtil.js');
var securityManager = require('../../utils/domain/security/securityManager');
var rmsApiProxy = require('../../utils/api/rmsApiProxy');
var configurationManager = require('../../utils/config/configurationManager.js');

class AttachmentForm extends Component
{
    constructor(props)
    {
        super(props);

        this.renderFileListingComponent = this.renderFileListingComponent.bind(this);
        this.renderFileUploadComponent = this.renderFileUploadComponent.bind(this);
        this.onCloseButton = this.onCloseButton.bind(this);

        this.onUploadFile = this.onUploadFile.bind(this);
        this.onUploadCompleted = this.onUploadCompleted.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);

        this.state = {};
        this.state.error = null;
    }

    render()
    {
        var errorComponent = null;
        if (this.state.error)
        {
            const hStyle = { color: '#FFA07A' };
            errorComponent = <div align="middle"><h5 style={ hStyle }>{this.state.error}</h5></div>
        }

        return(
                            
                <Modal open={true} onClose={this.onCloseButton} center 
                            classNames={{
                                            overlay: 'customOverlay',
                                            modal: 'customModal',
                                        }}>

                    <h2>{this.props.attachmentForm.title}</h2>
                    <hr></hr>
                    {errorComponent}
                    {this.renderFileUploadComponent()}
                    {this.renderFileListingComponent()}
                </Modal>
        )
    }

    renderFileListingComponent()
    {
        if (this.props.attachmentForm.files == null || this.props.attachmentForm.files.length <= 0)
            return null;

        var rows = [];

        this.props.attachmentForm.files.forEach (file =>
        {
            var deleteButton = !this.props.attachmentForm.isReadOnly 
                            ? <Button className="float-end" color="ghost-danger" onClick={()=>{this.onDeleteFile(file)}}><i className="fa fa-remove"></i></Button> 
                            : null;

            rows.push(<tr width="100%">
                        <td width="99%">
                            {file.originalFileName}
                        </td>
                        <td  style={{whiteSpace: "nowrap"}}>
                            <Button className="float-end" color="ghost-primary" onClick={()=>{this.onDownloadFile(file)}} ><i className="fa fa-cloud-download"></i></Button>
                        </td>
                        <td  style={{whiteSpace: "nowrap"}}>
                            {deleteButton}&nbsp;
                        </td>
                        </tr>)

            rows.push(<tr width="100%">
                        <td colSpan="3">
                            <hr></hr>
                        </td>
                    </tr>)
        });

        return (<div>
                    <hr></hr>
                    <table width="100%" style={{  border: "1px solid black",
  borderCollapse: "collapse"}}>
                        {rows}
                    </table>
                </div>);
    }

    renderFileUploadComponent()
    {
        if (this.props.attachmentForm.isReadOnly)
            return null;

        return (<FilePond
                    allowMultiple={false}
                    files={[]}
                    server={
                            {
                                process: this.onUploadFile,
                                revert: null,
                                restore: null,
                                load: null,
                                fetch: null,
                            }
                            }
                    labelIdle={'Drag & Drop your files or <span class="filepond--label-action">Browse</span> to upload'}
                />);
    }

    onUploadFile(fieldName, file, metadata, load, error, progress, abort)
    {
        this.filePondErrorObject = error;
      
        const formData = new FormData();
        formData.append("payload", file, file.name);

        var url = `${configurationManager.getConfig().apiServer}/${rmsApiProxy.getCurrentOrgNodeContextUrl()}/fileSystem/${this.props.attachmentForm.objectType}/${this.props.attachmentForm.objectId}`;
    
        this.uploadXmlHttpRequest = new XMLHttpRequest();
        this.uploadXmlHttpRequest.open('POST', url);
        this.uploadXmlHttpRequest.setRequestHeader("x-token", securityManager.getPassport().token);
        
        this.uploadXmlHttpRequest.upload.onprogress = (e) => 
                                    {
                                        progress(e.lengthComputable, e.loaded, e.total);
                                    };

        this.uploadXmlHttpRequest.onload = this.onUploadCompleted;

        this.uploadXmlHttpRequest.send(formData);

        return {
                abort: () => 
                {
                    this.uploadXmlHttpRequest.abort();
                    abort();
                }
            };
    }

    onUploadCompleted(response)
    {
        if (this.uploadXmlHttpRequest.status == 200) 
        {
            if (this.props.attachmentForm.files == null)
                this.props.attachmentForm.files = [];

            setTimeout(() => {  
                                this.props.attachmentForm.files.push(JSON.parse(this.uploadXmlHttpRequest.response));
                                this.setState({});
                             }, 500);
            return;
        }

        this.filePondErrorObject("Error occurred");
    }

  
    onCloseButton()
    {
        if (this.props.attachmentForm.onClose)
            this.props.attachmentForm.onClose(this.props.attachmentForm.files);

        viewUtil.closeAttachmentForm();
    }

    onDownloadFile(file)
    {
        //
        // Can't control headers with window.open, hence sending orgContexts as query parameter
        //
        let url = `${configurationManager.getConfig().apiServer}/${rmsApiProxy.getCurrentOrgNodeContextUrl()}/fileSystem/${this.props.attachmentForm.objectType}/${this.props.attachmentForm.objectId}/${file.id}/fileRawData`;
        window.open(url);
    }

    onDeleteFile(file)
    {
        if (window.confirm(`You are going to delete file: '${file.originalFileName}'`))
        {
            var resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/fileSystem/${this.props.attachmentForm.objectType}/${this.props.attachmentForm.objectId}/${file.id}`;
            rmsApiProxy.deleted(resourceUrl);
    
            this.props.attachmentForm.files = this.props.attachmentForm.files.filter (f => f.id != file.id);
            this.setState({});
        }
    }

}

const mapStateToProps = state => 
{
  return { 
            attachmentForm: state.attachmentForm
         }
}

export default connect(mapStateToProps)(AttachmentForm)
