import tableConstants from './TableConstants';
import * as validator from '../../../utils/validator/validator';

const { columnAlignments } = tableConstants;

export default class ColumnDefinition
{
    #width;
    #alignment;
    #isBold;

    constructor(width, alignment, isBold)
    {
        if (!validator.isBetween(width, 0, 100))
        {
            throw new Error("Invalid value for column width percentage, 0 < width < 100");
        }

        this.#width = width;
        this.#alignment = alignment;
        this.#isBold = isBold;
    }

    get width() 
    {
        return this.#width;
    }

    get alignment()
    {
        return this.#alignment ?? columnAlignments.alignLeft;
    }

    set alignment(value)
    {
        this.#alignment = value;
    }

    get isBold()
    {
        return this.#isBold ?? false;
    }

    set isBold(value)
    {
        this.#isBold = Boolean(value);
    }

    renderPayload()
    {
        return {

            width: this.#width,

            // optional/undefined fields
            alignment: this.#alignment,
            isBold: this.#isBold
        }
    }
}
