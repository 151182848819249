import React from 'react';

const flipdishTheme = require('../../../../utils/domain/flipdishTheme');

export default function GreetingMessage(props)
{
    return (
        <div className='w-100 align-self-center d-flex flex-column justify-content-center fw-bold'>

            <div className='text-center' style={{fontSize:"1.1em"}}>Welcome to</div>
            <div className='text-center' style={{fontSize:"1.1em"}}>Flipdish station setup wizard</div>

            {/* <div className='mt-5 text-center' style={{ color: `${flipdishTheme.color}` }}>Congratulations</div>
            <div className='text-center'>on your new Flipdish Restaurant Station</div> */}

            <div className='mt-5 p-3 rounded' style={{ textAlign: "center", background:'#606060', color: `#F8F8F8` }}>
                This wizard will guide you through the steps to activate your station
            </div>

        </div>
    );
}