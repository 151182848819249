import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView'
import * as reportStyles from '../../../reports/reportUtils/styles';
import SubCategory from './SubCategory'

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const stringUtil = require('../../../../utils/string/stringUtil');
const validator = require('../../../../utils/validator/validator');
const apiProxy = require('../../../../utils/api/apiProxy');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

class SubCategories extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadSubCategoriesAsync = this.loadSubCategoriesAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.isRowReadOnly = this.isRowReadOnly.bind(this);
        this.save = this.save.bind(this);

        this.state = { categories: [] }
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Sub Categories";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadSubCategoriesAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        
        gridViewOptions.getNewRow = () => { const newRow = {}; return newRow; };

        gridViewOptions.getComponent = (isNew, row) =>
            <SubCategory data={row} categories={this.state.categories} isRowReadOnly={this.props.isReadOnly} />;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = this.isRowReadOnly;
        gridViewOptions.getRowStyle = this.getRowStyle;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new sub category.";

            return "Please wait while updating sub category.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New sub category is added successfully";

            return "Sub Category is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new sub category:";

            return "Following error occurred while updating sub category:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly(row, customButton)
    {
        return this.props.isReadOnly;
    }

    getRowStyle = (params)=>
    {
        if (this.isRowReadOnly(params.data)) 
        {
            return { background: reportStyles.readOnlyRowBackgroundColor };
        }
    
        return null;
    }

    async loadSubCategoriesAsync()
    {
        const subCategories = [];

        if (this.state.categories.length == 0)
        {
            const categories = await apiLoadFacade.loadAccountCategoriesAndSubCategoriesForFranchisor();

            this.state.categories = categories;

            categories.forEach(category => {
                subCategories.push(...category.subCategories);

                category.subCategories.forEach(subCategory => subCategory.categoryName = category ? category.name : "N/A");
            });
        }

        return arraySort(subCategories, [stringUtil.localeCompareProp("name"), stringUtil.localeCompareProp("categoryName")]);
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.name))
            return "Sub Category name cannot be left empty";

        if (!validator.isPresent(row.categoryId))
            return "Category name cannot be left empty";

        return null;
    }

    async save(isNew, row, remoteData)
    {
        const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

        try
        {
            const updatedSubCategory = await rmsApiProxy.post(`core/organizations/${orgContext.franchisorId}/accounts/categories/${row.categoryId}`, row);
            updatedSubCategory.categoryName = row.categoryName;

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(subCategory => subCategory.id == updatedSubCategory.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedSubCategory);
            else
                remoteData.push(updatedSubCategory);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedSubCategory;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    constructGridColumnHeaders()
    {
        var headers = [];
        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "categoryName";
        header.headerName = "Category";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default SubCategories;
