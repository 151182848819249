import React, { Component } from 'react';
import { ButtonGroup, Button, Input, Label } from 'reactstrap'

const arraySort = require('array-sort');

const configurationManager = require('../../../../utils/config/configurationManager');
const flipdishTheme = require('../../../../utils/domain/flipdishTheme');

class StationSelection extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { includeNonActivatedStations: false }
    }

    render()
    {
        const { includeNonActivatedStations } = this.state;
        const { stations, selectedStation, onSelectStation, properties } = this.props;

        if (stations.length === 0) 
        {
            return (
                <div className='align-self-center p-4 d-flex flex-column bg-white rounded'>

                    <div className='text-danger'>Your selected store has no associated stations !!</div>
                    {
                        properties.length > 0 &&
                        <div className='mt-3'>
                            Please choose back button (top-left) to select another property.
                        </div>
                    }

                </div>
            );
        }

        const availableStations = stations.filter(station => station.isActivated === false);

        if (includeNonActivatedStations === true)
        {
            availableStations.push.apply(availableStations, stations.filter(station => station.isActivated === true));
        }

        arraySort(availableStations, ["type", "shell.name"]);

        return (
            <div className='w-100 mt-3 d-flex flex-column'>

                <div className='mb-3'>
                    Please choose station you want to activate from the list below and hit Continue
                </div>

                <div className='fw-bold mb-3'>
                    <Label check className='d-flex' style={{ fontSize: "0.90em", color: `${flipdishTheme.color}` }}>
                        <Input
                            className='mb-2 me-2'
                            style={{ fontSize: "18px" }}
                            type="checkbox"
                            onChange={e => this.setState({ includeNonActivatedStations: e.target.checked })} />
                        Include active stations in the list
                    </Label>
                </div>

                <div className='border rounded shadow-sm' style={{ maxHeight: "36vh", overflow: "auto" }}>
                    {
                        (availableStations.length === 0 && includeNonActivatedStations === false)
                            ? <div className='h-100 p-4 bg-white rounded'>
                                <div>
                                    You do not currently have any "Non-Active" stations at your selected store.
                                </div>
                                <div className='mt-3'>
                                    If you desire to re-activate an existing active station, please check
                                    "Include active stations in the list" option above to refresh the list
                                </div>
                            </div>
                            : <ButtonGroup vertical className='w-100 p-3'>
                                {
                                    stations.map(station =>
                                        <Button
                                            key={station.licenseKey}
                                            className='w-100 mb-2 p-2 text-start'
                                            outline color="primary" active={selectedStation != null && selectedStation.shell.licenseKey === station.shell.licenseKey}
                                            onClick={() => onSelectStation(station)}>
                                            <div className='w-100 d-flex align-items-center'>
                                                <img src={this.getImageUrl(station.type)} style={{ width: '30px' }} />
                                                <div className='d-flex flex-column' style={{ width: "calc(100% - 32px)" }}>
                                                    <div className='w-100 ms-2 text-nowrap text-truncate border-bottom'>{this.getDescriptiveStationType(station.type)}</div>
                                                    <div className='w-100 ms-2 text-nowrap text-truncate' style={{ fontSize: "0.75em" }}>{station.shell.name}</div>
                                                </div>
                                            </div>
                                        </Button>)
                                }
                            </ButtonGroup>
                    }
                </div>
            </div>
        );
    }

    getImageUrl = (stationType) =>
    {
        const rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;

        switch (stationType)
        {
            case "kds":
                return `${rootUrl}/app/back.office/menu/30.0.0/KitchenDisplaySystem.png`;
            case "kiosk":
                return `${rootUrl}/app/back.office/menu/30.0.0/KIOSK2.png`;
            case "kitchen":
                return `${rootUrl}/app/back.office/menu/30.0.0/CentralKitchenStation.png`;
            case "kitchenapp":
                return `${rootUrl}/app/back.office/menu/30.0.0/KitchenApp.png`;
            case "pos":
                return `${rootUrl}/app/back.office/menu/30.0.0/POS.png`;
            case "signage":
                return `${rootUrl}/app/back.office/menu/30.0.0/Signage2.png`;
            default:
                return null;
        }
    }

    getDescriptiveStationType = (stationType) =>
    {
        switch (stationType)
        {
            case "kds":
                return "Kitchen Display Station";
            case "kiosk":
                return "Kiosk Station";
            case "kitchen":
                return "Central Kitchen Station";
            case "kitchenapp":
                return "Kitchen App Tablet";
            case "pos":
                return "POS Station";
            case "signage":
                return "Signage Station";
            default:
                return "Unknown Station Type";
        }
    }
}

export default StationSelection;
