import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';

import * as reportDataUtils from '../../utils/reportDataUtils';
import * as reportViewUtils from '../../../../../../views/reports/reportUtils/helperUtils/viewUtils';

export default function DiscrepancySummaryData(props)
{
    // Master rows = aggregated discrepancy stockItems

    const masterRows = getMasterRows(props);

    const totalsRow = getMasterTotalsRow(masterRows);

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForMasterRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getMasterGridColumns(),
        masterDetail: true,
        isRowMaster: row => row.itemCount > 0,
        detailCellRendererParams: params => getFirstLevelDetailCellRendererParams(params, props),   // Renders aggregated item details
    }

    return (
        <div className="ag-theme-alpine">
            <AgGridReact
                rowData={masterRows}
                pinnedBottomRowData={[totalsRow]}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
            />
        </div>
    );
}

//#region Master Rows = aggregated discrepancy stockItems

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            const totalsRow = getMasterTotalsRow(rows);
            e.api.setPinnedBottomRowData([totalsRow]);
        }
    });
}

function getMasterRows(props)
{
    const { reportData } = props;
    return reportDataUtils.getAggregatedDiscrepancyItems(reportData);
}

function getMasterTotalsRow(rows) 
{
    const totalAdjustedCost = rows
        .filter(row => row != null)
        .reduce((sum, item) => sum + item.adjustedCost, 0);

    return { adjustedCost: totalAdjustedCost };
}

function getMasterGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "stockItemId";
    header.type = "id";
    headers.push(header);

    // header = {};
    // header.field = "categoryName";
    // header.headerName = "Category";
    // headers.push(header);

    header = {};
    header.field = "stockItemName";
    header.headerName = "Stock Item";
    header.cellRenderer = 'agGroupCellRenderer';
    headers.push(header);

    header = {};
    header.valueGetter = ({ data }) => reportViewUtils.getInventoryItemTypeForDisplay(data?.itemType);
    header.headerName = "Item Type";
    headers.push(header);

    header = {};
    header.field = "reportingUnitCode";
    header.headerName = "Units";
    headers.push(header);

    header = {};
    header.field = "adjustedQuantityInReportingUnits";
    header.headerName = "Discrepancy Quantity";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "adjustedCost";
    header.headerName = "Discrepancy Cost";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

//#endregion

//#region First Level Child Rows = item details for selected aggregatedItem

function getFirstLevelDetailCellRendererParams(params, props)
{
    const { data } = params;
    const { reportData } = props;

    const firstLevelDetailRows = reportDataUtils.getDiscrepancyDetailItems(data, reportData);

    const { pagination, paginationPageSize } = biUtils.getDetailGridPaginationSettings(firstLevelDetailRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForDetailRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getFirstLevelDetailGridColumns(),
    };

    return {
        detailGridOptions: gridOptions,
        getDetailRowData: params => params.successCallback(firstLevelDetailRows)
    };
}

function getFirstLevelDetailGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "businessDate";
    header.headerName = "Date";
    header.type = dataTypeConstants.date;
    headers.push(header);

    header = {};
    header.field = "currentInventoryInReportingUnits";
    header.headerName = "Expected Inventory";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "reportedInventoryInReportingUnits";
    header.headerName = "Reported Inventory";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "adjustedQuantityInReportingUnits";
    header.headerName = "Discrepancy Quantity";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "unitPriceInReportingUnits";
    header.headerName = "Unit Price";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "adjustedCost";
    header.headerName = "Discrepancy Cost";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

//#endregion
