import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import Spinner from '../../../../../../../components/spinner/spinner';
import customerSearchUtil from '../../../../customers/utils/customerSearchUtil';
import CampaignSummary from '../../../components/CampaignSummary';

const stringUtil = require('../../../../../../../utils/string/stringUtil');
const constants = require('../../../../../../../utils/domain/constants');

export default class CampaignReviewStep extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.calculatingTargetCount = false;
        this.state.calculationError = null;
        this.state.launchingCampaign = false;

        this.onLaunch = this.onLaunch.bind(this);
    }

    async onLaunch()
    {
        this.setState( { launchingCampaign : true });
        await this.props.onLaunch();
        this.setState( { launchingCampaign : false});
    }

    render() 
    {
        return <Card>
            <CardHeader>
                Review Campaign
            </CardHeader>
            <CardBody>
                {this.renderBody()}
            </CardBody>
            <CardFooter>
                {this.renderNavigation()}
            </CardFooter>
        </Card>;
    }

    renderNavigation()
    {
        let launchEnabled = !this.state.calculatingTargetCount && stringUtil.isStringNullOrEmpty(this.state.calculationError);
        if(launchEnabled)
            launchEnabled = this.props.canLaunch();

        return (<table width="100%">
            <tr>
                <td align="right">
                    <Button color="secondary" style={{ marginRight: '10px' }} onClick={this.props.previousStep}>Back</Button>
                    <Button color="success" disabled={!launchEnabled} onClick={this.onLaunch}>Launch Campaign</Button>
                </td>
            </tr>
        </table>);
    }

    renderBody()
    {
        if (this.state.calculatingTargetCount)
            return <Spinner text="Calculating target audience count" />
        
        if (this.state.launchingCampaign)
            return <Spinner text="Launching campaign" />

        if (!stringUtil.isStringNullOrEmpty(this.state.calculationError))
            return <div>{this.state.calculationError}</div>

        const campaign = this.props.data;
        const targetCriteria = campaign.profile.targetCriteria;

        if (campaign.projectedTargetCount == null && customerSearchUtil.isSearchCriteriaValid(targetCriteria))
        {
            customerSearchUtil.searchCustomers(targetCriteria)
                .then(result =>
                {
                    if (campaign.type == constants.campaignType.App)
                    {
                        result = result.filter(customer => customer.appNotificationEnabledForOffers && !stringUtil.isStringNullOrEmpty(customer.mobileMessagingToken))
                    }
                    else if (campaign.type == constants.campaignType.SMS)
                    {
                        result = result.filter(customer =>   customer.textNotificationEnabledForOffers);
                    }
                    campaign.projectedTargetCount = result.length;
                    this.setState({ calculatingTargetCount: false, calculationError: null });
                }, error =>
                {
                    this.setState({ calculatingTargetCount: false, calculationError: error });
                })

            this.setState({ calculatingTargetCount: true, calculationError: null });
        }

        return <CampaignSummary data={campaign} isNew={true} /> 
    }
}
