
import React, { Component } from 'react';
import PropertyFloatConfigurationComponent from './components/PropertyFloatConfigurationComponent';
import ErrorMessage from '../../../../../components/error/errorMessage';
import { Card, CardHeader, CardBody } from 'reactstrap';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const propertyFloatConfigurationModel = require('./propertyFloatConfigurationHelper');
const viewUtil = require('../../../../../utils/view/viewUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const apiLoadFacade = require('../../../../../utils/api/apiLoadFacade');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class PropertyFloatConfiguration extends Component
{

    constructor(props) 
    {

        super(props);
        this.state = {}
        this.state.isLoading = true;
        this.state.error = null;
        this.state.formManager = new FormManager();
        this.state.franchisorCategories = [];
        this.state.propertyTransactionAccounts = [];
        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.state.formManager.viewModel = propertyFloatConfigurationModel.createNew();
        this.state.formManager.view = this;
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");
        try
        {
            const promises = [];
            promises.push(propertyFloatConfigurationModel.load());
            promises.push(apiLoadFacade.loadAccountCategoriesAndSubCategories());
            promises.push(apiLoadFacade.loadTransactionAccounts());

            const results = await Promise.all(promises);

            const propertyFloatConfiguration = results[0];
            if (propertyFloatConfiguration)
            {
                propertyFloatConfiguration.isCashCountEnabledOnOpeningSession = Boolean(propertyFloatConfiguration.isCashCountEnabledOnOpeningSession);
                propertyFloatConfiguration.isCashCountEnabledOnClosingSession = Boolean(propertyFloatConfiguration.isCashCountEnabledOnClosingSession);
                propertyFloatConfiguration.displayClosingDiscrepancyWarning = Boolean(propertyFloatConfiguration.displayClosingDiscrepancyWarning);
                this.state.formManager.viewModel = propertyFloatConfiguration;
            }

            this.state.franchisorCategories = results[1] || [];
            this.state.propertyTransactionAccounts = results[2] || [];
        }
        catch (error)
        {
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    render() 
    {
        let component = null;
        if (this.state.isLoading)
        {
            component = null
        }
        else if (this.state.error)
        {
            component = <ErrorMessage message={ this.state.errorMessage } />;
        }
        else
        {
            component = <PropertyFloatConfigurationComponent
                franchisorCategories={ this.state.franchisorCategories }
                propertyTransactionAccounts={ this.state.propertyTransactionAccounts }
                formManager={ this.state.formManager } />
        }

        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader 
                        title="Property Float Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={true}
                        />
                </CardHeader>

                <CardBody>
                    { component }
                </CardBody>
            </Card>

        )
    }

    async onSaveClicked() 
    {

        const propertyFloatConfiguration = this.state.formManager.viewModel;
        const validationError = propertyFloatConfigurationModel.validate(propertyFloatConfiguration)
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }
        viewUtil.showSystemModalSpinner("Saving Property Float Configuration");

        try
        {
            await propertyFloatConfigurationModel.save(propertyFloatConfiguration);
            viewUtil.showSuccessAlert(`Property Float Configuration saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.configuration, 
                                                     'Property Float Configuration has been altered', 
                                                      propertyFloatConfiguration);
            
            const currentOptionAction = {
                type: actionTypes.mainBarComponent,
                payload: null
            };

            actionDispatcher.dispatch(currentOptionAction);
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

}

export default PropertyFloatConfiguration;

