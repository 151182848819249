import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, ModalBody, ModalFooter, Row } from "reactstrap";
import { useMemo } from "react";
import { deviceFieldsConfiguration } from "../../../../configuration/deviceFieldsConfiguration";
import { Form, Field, addFieldOptions } from "../../../Form";
import { deepCloneObject } from "../../../../../../../utils/type/typeUtil";
import { DeviceTypes } from "../../../../constants";
import { useNetworkDiagramContext } from "../../../../store";
import { validateFields } from "../../../Form/utils";

const constants = require("../../../../../../../utils/domain/constants");

const DeviceConfigurationForm = ({ deviceState, onSubmit = () => {}, onCancel = () => {} }) => {
     const {
          state: { kitchenStationProfiles, storefronts, isReadOnly, printingTemplates },
     } = useNetworkDiagramContext();
     const [fieldsState, setFieldsState] = useState([]);
     const [formFieldsValues, setFormFieldsValues] = useState({});

     const handleOnSubFields = useCallback((fieldName, subFields) => {
          setFieldsState((state) => {
               const newFieldsState = [];
               for (let i = 0; i < state.length; i++) {
                    const field = state[i];
                    if (field.parentFieldName !== fieldName) newFieldsState.push(field);
                    if (field.fieldName === fieldName) {
                         for (let j = 0; j < subFields.length; j++) {
                              const subField = { ...subFields[j], parentFieldName: field.fieldName };
                              newFieldsState.push(subField);
                         }
                    }
               }
               return newFieldsState;
          });
     }, []);

     const handleOnFormSubmit = useCallback((values) => onSubmit(values), [onSubmit]);

     const handleFieldValueChange = useCallback((value, fieldName) => {
          setFormFieldsValues((prevFields) => ({
               ...prevFields,
               [fieldName]: value,
          }));
     }, []);

     const { fields } = useMemo(() => {
          let fields = fieldsState;

          switch (deviceState.type) {
               case DeviceTypes.kotPrinter: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "kitchenStationId":
                                   addFieldOptions(
                                        field,
                                        kitchenStationProfiles.filter((ksp) => ksp.stationType === "Printer"),
                                        "id",
                                        "stationName"
                                   );
                                   break;
                              case "storefronts":
                                   addFieldOptions(
                                        field,
                                        storefronts,
                                        "id",
                                        "name"
                                   );
                                   break;
                              default:
                                   break;
                         }
                    });
                    break;
               }

               case DeviceTypes.labelPrinter:
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "itemLabelPrintingTemplate":
                                   addFieldOptions(
                                        field,
                                        printingTemplates.filter((ksp) => ksp.type === constants.printingTemplateTypes.zplItemLabelTemplate),
                                        "name",
                                        "name"
                                   );
                                   break;
                              case "orderLabelPrintingTemplate":
                                   addFieldOptions(
                                        field,
                                        printingTemplates.filter((ksp) => ksp.type === constants.printingTemplateTypes.zplOrderLabelTemplate),
                                        "name",
                                        "name"
                                   );
                                   break;
                              case "kotLabelPrintingTemplate":
                                   addFieldOptions(
                                        field,
                                        printingTemplates.filter((ksp) => ksp.type === constants.printingTemplateTypes.zplKotTemplate),
                                        "name",
                                        "name"
                                   );
                                   break;
                              case "expiryLabelPrintingTemplate":
                                   addFieldOptions(
                                        field,
                                        printingTemplates.filter((ksp) => ksp.type === constants.printingTemplateTypes.zplExpiryTemplate),
                                        "name",
                                        "name"
                                   );
                                   break;
                              default:
                                   break;
                         }
                    })
                    break;

               default:
                    break;
          }

          if (deviceState.id !== null) {
               fields.forEach((field) => {
                    if (field.fieldName === "deviceName") {
                         field["defaultValue"] = deviceState.deviceName;
                    } else {
                         field["defaultValue"] = deviceState.configuration[field.fieldName];
                    }
               });
          }
          if(isReadOnly){
               return {
                    fields: fields.map(fields=> {
                         return {
                              ...fields,
                              fieldProps: Object.assign({}, fields.fieldProps, {readOnly: true, disabled: true}),
                         }
                    })
               }
          }
          return {
               fields,
          };
     }, [fieldsState, deviceState, kitchenStationProfiles]);

     const isSaveButtonDisabled = useMemo(() => {
          let res = false;
          switch (deviceState.type) {
               case DeviceTypes.receiptPrinter:
                    if (!validateFields(deviceFieldsConfiguration[DeviceTypes.receiptPrinter], formFieldsValues)) return true;
                    break;
               case DeviceTypes.labelPrinter:
                    if (!validateFields(deviceFieldsConfiguration[DeviceTypes.labelPrinter], formFieldsValues)) return true;
                    break;
               case DeviceTypes.creditCardReader:
                    if (!validateFields(deviceFieldsConfiguration[DeviceTypes.creditCardReader], formFieldsValues)) return true;
                    break;
               case DeviceTypes.kotPrinter:
                    if (!validateFields(deviceFieldsConfiguration[DeviceTypes.kotPrinter], formFieldsValues)) return true;
                    break;
               case DeviceTypes.telephoneCli:
                    if (!validateFields(deviceFieldsConfiguration[DeviceTypes.telephoneCli], formFieldsValues)) return true;
                    break;

               default:
                    break;
          }
          return res;
     }, [deviceState.type, formFieldsValues]);

     useEffect(() => {
          const fieldsConf = deepCloneObject(deviceFieldsConfiguration);
          if (fieldsConf) setFieldsState(fieldsConf[deviceState.type]);
     }, [deviceState]);

     return (
          <Form onSubmit={handleOnFormSubmit}>
               <ModalBody>
                    <Row>
                         <Col>
                              {fields.map((field) => (
                                   <Field field={field} key={field.fieldName} onSubFields={handleOnSubFields} onValueChange={handleFieldValueChange} />
                              ))}
                         </Col>
                    </Row>
               </ModalBody>
               <ModalFooter>
                    <Button color="error" type="button" onClick={onCancel}>
                         Cancel
                    </Button>
                    <Button disabled={isSaveButtonDisabled || isReadOnly} color="success" type="submit">
                         {deviceState.id === null ? "Add" : "Update"}
                    </Button>
               </ModalFooter>
          </Form>
     );
};

export default DeviceConfigurationForm;
