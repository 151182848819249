const constants = require('./constants');

export function getInputStyles(props)
{
    //
    // Extracts style property from props
    // BackgroundColor style is applied only to the <Input/> component
    // All the remaining style (like margins, paddings etc) is applied only to the <FormGroup/> component
    //
    // this.style and this.backgroundColorStyle are declared as instance level variables since these are
    // referred to by render, renderCalendarComponent and render24HoursComponent methods
    //

    var style = props.style || {};    // Get style from props
    var backgroundColorStyle = { backgroundColor: style.backgroundColor };    // Extract backgroundColor into a separate style
    delete style.backgroundColor;      // Delete backgroundColor from style

    if (props.inputReadOnly)
    {
        // Implies readonly mode

        if (!backgroundColorStyle.backgroundColor)
        {
            // Implies backgroundColor not set explicitly in props
            // backgroundColor is overridden only when not explicitly set in props

            backgroundColorStyle.backgroundColor = constants.readOnlyBackgroundColor;
        }
    }

    return { style , backgroundColorStyle };
}