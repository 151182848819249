

import React, { Component } from "react";
import InputComponent from "../../../../../../components/form/inputComponent";
import CheckComponent from "../../../../../../components/form/checkComponent";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Jumbotron from "../../../../../../components/jumbotron/jumbotron";
var QRCode = require('qrcode.react');


const FormManager = require("../../../../../../utils/view/formManager.js");
const stringUtil = require("../../../../../../utils/string/stringUtil");
const tableOrderingUtil = require('../utils/tableOrderingUtil');

class StencilConfigurationDialogue extends Component
{
    constructor(props)
    {
        super(props);

        this.renderOverviewConfiguration = this.renderOverviewConfiguration.bind(this);
        this.renderAvailabilityComponent = this.renderAvailabilityComponent.bind(this);
        this.renderIntegrationComponent = this.renderIntegrationComponent.bind(this);
        this.renderQRCode = this.renderQRCode.bind(this);
   
        this.state = {
                        formManager: new FormManager(),
                    };
      
        this.state.formManager.viewModel = this.props.stencil;
        this.state.formManager.view = this;
    }

    render()
    {
        var tabHeaders = [];
        tabHeaders.push(<Tab>Overview</Tab>)
        tabHeaders.push(<Tab>QR Ordering APP</Tab>)
        tabHeaders.push(<Tab>Availability</Tab>)
      
        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderOverviewConfiguration()}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderIntegrationComponent()}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderAvailabilityComponent()}</TabPanel>);
      
        return (<Tabs>
                    <TabList>
                        {tabHeaders}
                    </TabList>

                    {tabContents}
                </Tabs>);
    }

    renderOverviewConfiguration()
    {
        return (<table width="100%">
                    <tr>
                        <td>  
                            <InputComponent
                                inputType="type"
                                fieldName="tableNumber"
                                placeholder="Enter Table Number"
                                caption="Table Number"
                                hintText = "Enter a unique table number."
                                formManager={this.state.formManager}
                                style={{marginRight:'10px'}}
                            />
                        </td>
                        <td>
                            <InputComponent
                                inputType="type"
                                fieldName="noOfSeating"
                                placeholder="Enter No Of Persons"
                                caption="Seating"
                                hintText = "No Of Persons can sit in this space."
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr>
                </table>)
    }

    

    renderAvailabilityComponent()
    {
        return <Jumbotron>
                <h3>Seating Availability!</h3>
                <p className="lead">Here you can set availability of this space. e.g. This space is only available on Weekend Evenings during busy hours</p>
                <hr className="my-2" />
                <p>This feature will be enabled in future release. </p>
            </Jumbotron>
    }

    renderIntegrationComponent()
    {
        return (<table width="100%">
                    <tr>
                        <td style={{textAlign:'center'}}>  
                            <CheckComponent
                            key="qrOrderingAppEnabled"
                            caption="QR Ordering APP Enabled"
                            fieldName="qrOrderingAppEnabled"
                            formManager={this.state.formManager} 
                            style={{marginLeft:'20px'}}/>
                        </td>
                    </tr>
                    <tr>
                            <td style={{textAlign:'center'}}>
                                {this.renderQRCode()}
                            </td>
                        </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="type"
                                fieldName="qrOrderingAppId"
                                placeholder="Enter QR Ordering App ID"
                                caption="QR Ordering App ID"
                                hintText = "Changing this field may break the QR ordering app."
                                formManager={this.state.formManager}/>
                        </td>
                    </tr>
                </table>)
    }

    renderQRCode()
    {
        if (!this.props.stencil.qrOrderingAppEnabled ||
            stringUtil.isStringNullOrEmpty(this.props.stencil.qrOrderingAppId))
        {
            return <div style={{marginTop:'20px', marginBottom:'20px'}}>
                        &nbsp;
                   </div>
        }

        var url = `${tableOrderingUtil.getTableOrderAppUrl(this.props.stencil.qrOrderingAppId, this.props.webAppConfiguration)}`
        return <div style={{marginTop:'20px', marginBottom:'20px'}}>
                    <QRCode value={url} size="80"/>
                </div>
        
    }

}

export default StencilConfigurationDialogue;