
import React, { Component } from 'react';

import ProjectionOverrideItem from './ProjectionOverrideItem';

class ProjectionOverrideList extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        const overrideData = this.props.overrideData;
        const onSetDirty = this.props.onSetDirty;

        const tables = [];

        let rows = [], index;

        for (index = 0; index < overrideData.length; index++)
        {
            if (index % 10 == 0)
            {
                // 10 rows per table

                if (rows.length > 0)
                {
                    tables.push(this.getTableFromRows(index, rows));
                }

                rows = [];
            }

            rows.push(
                // ProjectionOverrideItem = table row
                <ProjectionOverrideItem key={overrideData[index].date} overrideItem={overrideData[index]} onSetDirty={onSetDirty} />
            );
        }

        if (rows.length > 0)
        {
            // Push remaining rows as new table
            tables.push(this.getTableFromRows(index, rows));
        }

        return (
            <div style={{ display: "flex", flexFlow: "row nowrap", overflow: "auto", paddingBottom: "10px" }} >
                {
                    tables.map((table, index) => <div key={index} style={{ marginRight: "10px" }} >{table}</div>)
                }
            </div>
        );

    }

    getTableFromRows(key, rows)
    {
        return (
            <table key={key}>
                <thead>
                    <tr style={{ lineHeight: "32px" }}>
                        <th className="text-center text-nowrap fw-normal text-light bg-dark">Date</th>
                        <th className="text-center text-nowrap fw-normal text-light bg-dark">Projected Sales</th>
                        <th className="text-center text-nowrap fw-normal text-light bg-dark">Override Sales</th>
                    </tr>
                    <tr style={{ lineHeight: "8px" }}><td colSpan="3">&nbsp;</td></tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>);
    }
}

export default ProjectionOverrideList;