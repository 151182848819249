
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js'
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent'
import FormManager from '../../../../../utils/view/formManager';

class Customer extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {}
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }
    render() 
    {
        const formManager = this.state.formManager;

        return (
            <table width="100%">
                <tr>
                    <td width="50%" style={{ paddingRight: '20px' }}>
                        <InputComponent inputType="name" caption="Name" hintText="Optional"
                            fieldName="name" placeholder="Enter customer name"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={formManager} />
                    </td>

                    <td width="50%">
                        <InputComponent inputType="phoneNumber" caption="Phone Number" hintText="Required"
                            style={{ marginRight: '20px' }} fieldName="phoneNumber" placeholder="Enter customer phone number"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="address" caption="Address"
                            fieldName="address" placeholder="Enter customer's address"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="email" caption="Email Address"
                            fieldName="emailAddress" placeholder="Enter customer's email address"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td width="50%" style={{ paddingRight: '20px' }}>
                        <BooleanSelectComponent
                            caption="Registered in App"
                            fieldName="usingIgnisMobileApp"
                            hintText="Is Customer Registered in Mobile App"
                            clearable={false}
                            comboReadOnly={true}
                            formManager={formManager} />
                    </td>

                    <td width="50%">
                        <BooleanSelectComponent
                            caption="Customer Disabled"
                            fieldName="isDisabled"
                            hintText="Disable the customer"
                            clearable={false}
                            comboReadOnly={this.props.isReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="text" caption="Internal Notes"
                            fieldName="notes" placeholder="Enter customer's internal notes"
                            rows={4} multiLine={true}
                            inputReadOnly={this.props.isReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <fieldset className="form-group border">
                            <legend className="w-auto ignis-legend">&nbsp;&nbsp;App Notifications&nbsp;&nbsp;</legend>
                            <table width="100%">
                                <tr>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            style={{ marginRight: '20px' }}
                                            caption="Order Updates"
                                            fieldName="appNotificationEnabledForOrderUpdates"
                                            hintText="Subscribed to receive order updates in App"
                                            clearable={false}
                                            comboReadOnly={this.props.isReadOnly}
                                            formManager={formManager} />
                                    </td>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            caption="Offers"
                                            fieldName="appNotificationEnabledForOffers"
                                            hintText="Subscribed to receive offers in App"
                                            clearable={false}
                                            comboReadOnly={this.props.isReadOnly}
                                            formManager={formManager} />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                    </td>

                </tr>

                <tr>
                    <td colSpan="2">
                        <fieldset className="form-group border">
                            <legend className="w-auto ignis-legend">&nbsp;&nbsp;Text Notifications&nbsp;&nbsp;</legend>
                            <table width="100%">
                                <tr>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            style={{ marginRight: '20px' }}
                                            caption="Order Updates"
                                            fieldName="textNotificationEnabledForOrderUpdates"
                                            hintText="Subscribed to receive order updates as Text"
                                            clearable={false}
                                            comboReadOnly={this.props.isReadOnly}
                                            formManager={formManager} />
                                    </td>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            caption="Offers"
                                            fieldName="textNotificationEnabledForOffers"
                                            hintText="Subscribed to receive offers as Text"
                                            clearable={false}
                                            comboReadOnly={this.props.isReadOnly}
                                            formManager={formManager} />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                    </td>
                </tr>


            </table>
        )
    }
}

export default Customer;