const constants = require('./constants');
const staffActivities = require('./staffActivities');

module.exports.isStaffADriver = function (staff, staffRoles)
{
    return this.canStaffPerformActivity(staff, staffActivities.canDeliverAnOrder, staffRoles);
}

module.exports.canStaffPerformActivity = function (staff, activity, staffRoles)
{
    const roles = staff.roles.split(",");
    for (let index = 0; index < roles.length; index++) 
    {
        const roleId = roles[index];
        const staffRole = staffRoles.find(x => x.id === roleId);
        if (staffRole)
        {
            const roleAction = staffRole.actions.find(x => x.actionCode === activity);
            if (roleAction)
                return true;
        }
    }

    return false;
}