

module.exports = function ()
{
    this.ref = "";                                  // Any ref value used by the client as identity

    this.title = "";
    this.color = "";

    this.onRowSelect = true;
    this.actionType = "component";                  // component/action
    this.rowTransform = null;                       // (selectedRow, customButton) => {}
    
    this.actionConfirmationTitle = "";
    this.actionConfirmationMessage = "";
    this.onActionConfirmationMessage = null;        // (gridApi/selectedRow) => "confirmation message (Y/N) ?"
    
    this.onAction = null;                           // (selectedRow, remoteData, customButton) => Promise
    this.onActionWaitMessage = null;                // (selectedRow, customButton) => "wait message"
    this.onActionSuccessMessage = null;             // (selectedRow, customButton) => "success message"
    this.onActionFailMessage = null;                // (selectedRow, customButton, error) => "fail message"
    
    this.onClick = null;
}