
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';

import GridComponent from '../../../../../../components/grid/gridComponent';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';
import StockItemSections from '../StockItemSections';
import RecipeProfile from './RecipeProfile';
import formatter from '../../../../../../utils/formatter/formatter';

const arraySort = require('array-sort');

const unitUtil = require('../../../../../../utils/domain/unitUtil');
const typeUtil = require('../../../../../../utils/type/typeUtil');
const viewUtil = require('../../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../../utils/view/formManager');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const validator = require('../../../../../../utils/validator/validator');

const stockItemCostUtil = require('../../../../../../utils/domain/inventory/stockItem/stockItemCostUtil');
const stockItemNutritionUtil = require('../../../../../../utils/domain/inventory/stockItem/stockItemNutritionUtil');
const calorieCalculator = require('../../../../../../utils/domain/inventory/calorieCalculator');

const columnWidthConstants = require('../../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../../utils/constants/dataTypeConstants');

const catalogSelectors = require('../../../../../../utils/state/stateSelectors/catalogSelectors');
const localizationUtils = require('../../../../../../utils/domain/localizationUtils');

const domainConstants = require('../../../../../../utils/domain/constants');


class RecipeProfiles extends Component 
{
    constructor(props) 
    {
        super(props);

        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.getDataRows = this.getDataRows.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onAddIngredient = this.onAddIngredient.bind(this);
        this.onRemoveIngredient = this.onRemoveIngredient.bind(this);
        this.validateIngredient = this.validateIngredient.bind(this);
        this.addEditIngredient = this.addEditIngredient.bind(this);
        this.saveIngredient = this.saveIngredient.bind(this);

        this.props.data.recipeProfile = this.props.data.recipeProfile || { recipeItems: [] };

        this.state = {};
        this.state.selectedDispatchType = null;
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.data.recipeProfile;
        this.state.formManager.view = this;
    }

    get isRowReadOnly() { return this.props.isRowReadOnly; }

    render()
    {
        const selectedDispatchType = this.state.selectedDispatchType;
        const dispatchTypeOptions = domainConstants.getDispatchTypes().map(option => ({ label: option.dispatchType, value: option.dispatchType }));

        const optionsPanel =
            <div className='ms-2 my-2 d-flex justify-content-between align-items-center'>

                <div className='d-flex align-items-center'>
                    <div className='me-3 fw-bold'>Select Recipe by Dispatch Type:</div>
                    <div style={{ width: "300px" }}>
                        <Select
                            className="py-2"
                            value={selectedDispatchType}
                            options={dispatchTypeOptions}
                            disable={false}
                            onChange={option => 
                            {
                                this.setState(
                                    { selectedDispatchType: option && option.value },   // updater
                                    () =>                                               // callback
                                    {
                                        if (this.gridApi != null) 
                                        {
                                            const dataRows = [];
                                            this.gridApi.forEachNodeAfterFilter(rowNode => dataRows.push(rowNode.data));
                                            this.gridApi.setPinnedBottomRowData(this.getPinnedRows(dataRows));
                                            this.gridApi.redrawRows();
                                        }
                                    })
                            }}
                        />
                    </div>
                </div>

                {
                    this.isRowReadOnly
                        ? null
                        : <div className='d-flex'>
                            <Button color="primary" disabled={!this.props.data.recipeProfileEnabled} className="btn-primary" style={{ marginRight: '3px' }} onClick={this.onAddIngredient}>
                                <i className="fa fa-plus"></i>&nbsp;Add Ingredient
                            </Button>
                            <Button color="primary" disabled={this.findSelectedRow() == null} className="btn-danger" style={{ marginRight: '3px' }} onClick={this.onRemoveIngredient}>
                                <i className="fa fa-remove"></i>&nbsp;Remove Ingredient
                            </Button>
                        </div>
                }

            </div>

        let units = unitUtil
            .getPossibleUnitsForStockItem(this.props.data)
            .filter(unit => !unit.isSupplementalUnit);

        units = arraySort(units, stringUtil.localeCompareProp("code"));

        const dataRows = this.getDataRows();
        const pinnedRows = this.getPinnedRows(dataRows);

        return  <StockItemSections title="Recipe Profile">
                    <div style={{ marginRight: '20px' }}>
                        <table width="100%">
                            <tr>
                                <td width="100%">
                                    
                                    <table className="component-table">
                                        <tr>
                                            <td width="50%">
                                                <InputComponent inputReadOnly={this.isRowReadOnly} inputType="text" caption="Base Quantity" hintText="Base quantity used for following recipe (required)"
                                                    fieldName="baseQuantity" placeholder="Enter base quantity for this recipe" formManager={this.state.formManager} />
                                            </td>
                                            <td width="50%">
                                                <SelectComponent optionValues={units} optionFieldName="id" optionDisplayFieldName="code"
                                                    caption="Base Quantity Unit"
                                                    fieldName="unitTypeId"
                                                    formManager={this.state.formManager}
                                                    clearable={false}
                                                    comboReadOnly={this.isRowReadOnly}
                                                    searchable={false}
                                                    hintText="Required" />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="50%">
                                                <BooleanSelectComponent
                                                    fieldName="participateInBuildPlan"
                                                    caption="Participates in build plan"
                                                    clearable={false}
                                                    comboReadOnly={this.isRowReadOnly}
                                                    formManager={this.state.formManager}
                                                />
                                            </td>
                                        </tr>
                                    </table>
                                            
                                </td>
                            </tr>

                            <tr width="100%">
                                <td width="100%">
                                    {optionsPanel}
                                </td>
                            </tr>

                            <tr width="100%" >
                                <td width="100%">
                                    <GridComponent
                                        style={{ paddingBottom: '15px' }}
                                        headers={this.constructGridColumnHeaders()}
                                        rows={dataRows}
                                        pinnedRows={pinnedRows}
                                        onRowDoubleClicked={this.onRowDoubleClicked}
                                        onRowSelected={this.onRowSelected}
                                        shouldReArrangeHeaders={true}
                                        onGridReady={params =>
                                        {
                                            this.gridApi = params.api;
                                            this.gridColumnApi = params.columnApi;
                                        }}
                                        gridOptions={this.gridOptions} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div className='pt-4'>
                                        <InputComponent inputType="text" multiLine={true} caption="Notes" rows={10}
                                            fieldName="notes" inputReadOnly={this.isRowReadOnly} placeholder="Enter recipe details." formManager={this.state.formManager} style={{ resize: 'none' }} />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </StockItemSections>
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onAddIngredient()
    {
        const ingredient = {};
        this.addEditIngredient(ingredient);

    }

    onRemoveIngredient()
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        viewUtil.showConfirmDialogue('Ingredient', `You are going to delete ingredient '${selectedRow.ingredientCode}'. Are you sure?`,
            () =>
            {
                this.props.data.recipeProfile.recipeItems = this.props.data.recipeProfile.recipeItems.filter(ig => ig.ingredientStockItemId !== selectedRow.ingredientStockItemId);
                this.setState({});
            });
    }

    onRowDoubleClicked(eventData)
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const ingredient = this.props.data.recipeProfile.recipeItems.find(ig => ig.ingredientStockItemId === selectedRow.ingredientStockItemId);
        this.addEditIngredient(typeUtil.deepCloneObject(ingredient));
    }

    addEditIngredient(recipeLine)
    {
        const isExisting = !stringUtil.isStringNullOrEmpty(recipeLine.ingredientStockItemId);
        viewUtil.openModalForm("Ingredient", (onFieldChanged) =>
        {
            return (<RecipeProfile stockItem={this.props.data} data={recipeLine}
                lookupData={this.props.lookupData}
                units={this.props.units}
                onFieldChanged={() => onFieldChanged()}
                isRowReadOnly={this.isRowReadOnly}
                isExisting={isExisting} />);
        },
            () => { this.saveIngredient(recipeLine) },
            () => { return this.validateIngredient(recipeLine) },
            this.isRowReadOnly)

    }

    saveIngredient(recipeLine)
    {
        this.props.data.recipeProfile.recipeItems = this.props.data.recipeProfile.recipeItems.filter(sp => sp.ingredientStockItemId !== recipeLine.ingredientStockItemId);
        this.props.data.recipeProfile.recipeItems.push(recipeLine);
        this.setState({});
    }

    validateIngredient(recipeLine)
    {
        if (stringUtil.isStringNullOrEmpty(recipeLine.ingredientStockItemId))
            return "Ingredient cannot be empty";

        if (!validator.isNumeric(recipeLine.quantity))
            return "Quantity is not valid";

        if (stringUtil.isStringNullOrEmpty(recipeLine.unitTypeId))
            return "Unit cannot be empty";

        return null;
    }

    getDataRows()
    {
        // Return recipeItems enriched with price, vat and calorie info
        // Price, vat and calorie info for given recipeItem vary by dispatchType context as recipes
        // now vary by dispatch type

        const dispatchType = this.state.selectedDispatchType;

        const enrichedRecipeItems = this.props.data.recipeProfile.recipeItems.map(recipeItem => 
        {
            recipeItem = typeUtil.deepCloneObject(recipeItem);
            this.enrichRecipeItem(recipeItem, dispatchType);
            return recipeItem;
        });

        return arraySort(enrichedRecipeItems, stringUtil.localeCompareProp("ingredientCode"));
    }

    getPinnedRows = (recipeItems) =>
    {
        const dispatchType = this.state.selectedDispatchType;

        const dataRowsByDispatchType =
            recipeItems.filter(recipeItem =>
                recipeItem.dispatchTypes == null ||
                (dispatchType && recipeItem.dispatchTypes.includes(dispatchType)));

        const { totalPrice, totalVat, totalCalorie } = stockItemCostUtil.calculateReceiptTotal(dataRowsByDispatchType);

        return [{ ingredientCode: "Total Cost", price: totalPrice, vat: totalVat, calorie: totalCalorie }];
    }

    enrichRecipeItem(recipeItem, dispatchType)
    {
        const stockItemsLookupData = this.props.lookupData.stockItemsLookupData;

        const ingredientStockItem = stockItemsLookupData.find(s => s.id === recipeItem.ingredientStockItemId);
        recipeItem.ingredientCode = ingredientStockItem.name;

        const units = unitUtil.getPossibleUnitsForStockItemId(recipeItem.ingredientStockItemId, stockItemsLookupData);
        const unit = units.find(unit => unit.id === recipeItem.unitTypeId);

        if (unit.isSupplementalUnit)
        {
            recipeItem.formattedQuantity = "??";
            recipeItem.price = "N/A";
            recipeItem.vat = "N/A";
            recipeItem.calorie = "N/A";
        }
        else
        {
            recipeItem.formattedQuantity = recipeItem.quantity + " " + unit.code;

            const costProfile = stockItemCostUtil.getIngredientCost(ingredientStockItem, recipeItem, stockItemsLookupData, dispatchType);
            recipeItem.price = costProfile.price;
            recipeItem.vat = costProfile.vat;

            const nutritionQuantities =
                catalogSelectors.selectLookupData().nutritions.map(x =>
                ({
                    nutritionCode: x.code,
                    quantity: stockItemNutritionUtil.getIngredientNutritionQuantity(x.code, ingredientStockItem, recipeItem, stockItemsLookupData, dispatchType)
                }));

            const calories = calorieCalculator.calculateCalorie(nutritionQuantities);
            const totalCalories = isNaN(calories) ? "N/A" : formatter.roundUp(calories, 0);
            recipeItem.calorie = totalCalories;
        }
    }

    getCellStyle = params =>
    {
        const dispatchTypes = params.data && params.data.dispatchTypes;

        if (dispatchTypes) 
        {
            const selectedDispatchType = this.state.selectedDispatchType;

            if (selectedDispatchType == null || !dispatchTypes.includes(selectedDispatchType)) 
            {
                return {
                    color: "lightGray",
                    textDecoration: "line-through",
                }
            }
        }

        return null;
    }

    gridOptions = {
        getRowStyle: (params) =>
        {
            // Highlight summary rows
            if (params.node.rowPinned)
            {
                return { background: "lightGray", fontWeight: "bold", foreColor: "white", color: "white" };
            }

            return null;
        },
        onFilterChanged: event => 
        {
            // Update summary rows on filter
            const dataRows = [];
            event.api.forEachNodeAfterFilter(rowNode => dataRows.push(rowNode.data));
            event.api.setPinnedBottomRowData(this.getPinnedRows(dataRows));
        },
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];
        const vatCaption = localizationUtils.getVatCaption();

        header = {};
        header.field = "ingredientStockItemId";
        header.headerName = "ingredientStockItemId";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "ingredientCode";
        header.headerName = "Ingredient";
        header.pinned = true;
        header.width = columnWidthConstants.code;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        header = {};
        header.field = "formattedQuantity";
        header.headerName = "Quantity";
        header.width = columnWidthConstants.number;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        header = {};
        header.field = "price";
        header.headerName = "Cost";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        header = {};
        header.field = "vat";
        header.headerName = vatCaption;
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        header = {};
        header.field = "calorie";
        header.headerName = "Calorie";
        header.type = dataTypeConstants.calorie;
        header.width = columnWidthConstants.number;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        header = {};
        header.field = "dispatchTypes";
        header.headerName = "Dispatch Types";
        header.width = columnWidthConstants.code;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        header.cellStyle = this.getCellStyle;
        headers.push(header);

        return headers;
    }
}

export default RecipeProfiles;


