import arraySort from 'array-sort';

import * as sortingUtil from '../../../../../../../utils/sorting/sortingUtil';

export function getOptions(salesData)
{
    const options = [];

    salesData.forEach(data =>
    {
        const option = options.find(option => option.category === data.category && option.caption === data.caption);
        if (option == null) options.push({ category: data.category, caption: data.caption });
    });

    return arraySort(options, sortingUtil.localeCompareProp("caption"));
}

export function getMasterDataRows({ selectedOption, salesData })
{
    const masterRows = [];

    salesData
        .filter(data => data.category === selectedOption.category && data.caption === selectedOption.caption)
        .forEach(data =>
        {
            let row = masterRows.find(row => row.hour === data.hour);

            if (row == null)
            {
                masterRows.push({ ...data });
                return;
            }

            row.quantity += data.quantity;
            row.retailPrice += data.retailPrice;
        });

    return arraySort(masterRows, "hour");
}

export function getSalesByPropertyDetailDataRows(masterRow, { salesData })
{
    const { hour, category, caption } = masterRow;

    const detailRows = [];

    salesData
        .filter(data => data.hour === hour && data.category === category && data.caption === caption)
        .forEach(data => 
        {
            const row = detailRows.find(row => row.propertyId === data.propertyId);

            if (row == null)
            {
                detailRows.push({ ...data });
                return;
            }

            row.quantity += data.quantity;
            row.retailPrice += data.retailPrice;
        });

    return arraySort(detailRows, "propertyName");
}

export function getSalesByVenueDetailDataRows(masterRow, { salesData })
{
    const { propertyId, hour, category, caption } = masterRow;

    const detailRows = [];

    salesData
        .filter(data => data.propertyId === propertyId && data.hour === hour && data.category === category && data.caption === caption)
        .forEach(data => 
        {
            const row = detailRows.find(row => row.venueCode === data.venueCode);

            if (row == null)
            {
                detailRows.push({ ...data });
                return;
            }

            row.quantity += data.quantity;
            row.retailPrice += data.retailPrice;
        });

    return arraySort(detailRows, "venueCode");
}

export function getSalesByPropertyChartDataRows({ selectedOption, salesData })
{
    const chartDataRows = [];

    salesData
        .filter(data => data.category === selectedOption.category && data.caption === selectedOption.caption)
        .forEach(data =>
        {
            let row = chartDataRows.find(row => row.hour === data.hour);

            if (row == null)
            {
                row = { hour: data.hour }
                chartDataRows.push(row);
            }

            if (!row[data.propertyName])
            {
                row[data.propertyName] = { quantity: 0, retailPrice: 0 };
            }

            row[data.propertyName].quantity += data.quantity;
            row[data.propertyName].retailPrice += data.retailPrice;
        });

    return arraySort(chartDataRows, "hour");
}

export function getSalesByVenueChartDataRows({ selectedOption, salesData })
{
    const chartDataRows = [];

    salesData
        .filter(data => data.category === selectedOption.category && data.caption === selectedOption.caption)
        .forEach(data =>
        {
            let row = chartDataRows.find(row => row.hour === data.hour);

            if (row == null)
            {
                row = { hour: data.hour }
                chartDataRows.push(row);
            }

            if (!row[data.venueCode])
            {
                row[data.venueCode] = { quantity: 0, retailPrice: 0 };
            }

            row[data.venueCode].quantity += data.quantity;
            row[data.venueCode].retailPrice += data.retailPrice;
        });

    return arraySort(chartDataRows, "hour");
}

export function getDistinctPropertyNames({ selectedOption, salesData })
{
    const propertyNames = Array.from(new Set(
        salesData
            .filter(data => data.category === selectedOption.category && data.caption === selectedOption.caption)
            .map(data => data.propertyName)
    ));

    return arraySort(propertyNames);
}

export function getDistinctVenueCodes({ selectedOption, salesData })
{
    const venueCodes = Array.from(new Set(
        salesData
            .filter(data => data.category === selectedOption.category && data.caption === selectedOption.caption)
            .map(data => data.venueCode)
    ));

    return arraySort(venueCodes);
}
