
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';

import SelectComponent from '../../../../../components/form/selectComponent';
import InputComponent from '../../../../../components/form/inputComponent';
import CheckComponent from '../../../../../components/form/checkComponent';

const arraySort = require('array-sort');

const FormManager = require('../../../../../utils/view/formManager');
const stringUtil = require('../../../../../utils/string/stringUtil');
const unitUtil = require('../../../../../utils/domain/unitUtil');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

class StockItemSupplierUnitType extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            formManager: new FormManager()
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.state.isExistingData = this.props.data.unitTypeId !== undefined;
    }

    render()
    {
        const vatCaption = localizationUtils.getVatCaption();
        let units = unitUtil
            .getPossibleUnitsForStockItem(this.props.stockItem)
            .filter(unit => !unit.isSupplementalUnit);

        units = arraySort(units, stringUtil.localeCompareProp("code"));

        return <Row className="align-items-center">
            <Col lg="2">
                <SelectComponent
                    optionValues={units}
                    optionFieldName="id"
                    optionDisplayFieldName="code"
                    caption="Unit"
                    fieldName="unitTypeId"
                    hintText="Select Supply Unit"
                    clearable={true}
                    comboReadOnly={this.props.isRowReadOnly || this.state.isExistingData || this.state.formManager.viewModel.isDisabled}
                    formManager={this.state.formManager} />
            </Col>
            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={this.props.isRowReadOnly || this.state.formManager.viewModel.isDisabled}
                    inputType="text"
                    fieldName="pluCode"
                    caption="PLU Code"
                    placeholder=""
                    hintText="Supplier's PLU Code"
                />
            </Col>
            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={this.props.isRowReadOnly || this.state.formManager.viewModel.isDisabled}
                    inputType="money"
                    fieldName="price"
                    caption="Price"
                    placeholder=""
                    hintText="Supplier's Price"
                />
            </Col>
            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={this.props.isRowReadOnly || this.state.formManager.viewModel.isDisabled}
                    inputType="money"
                    fieldName="vat"
                    caption={vatCaption}
                    placeholder=""
                    hintText={`Enter ${vatCaption}`}
                />
            </Col>

            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={this.props.isRowReadOnly || this.state.formManager.viewModel.isDisabled}
                    inputType="number"
                    fieldName="minimumOrderQuantity"
                    caption="Min Order Qty"
                    placeholder=""
                    hintText="Enter Min Order Qty."
                />
            </Col>

            <Col>
                <CheckComponent
                    caption="Disable"
                    fieldName="isDisabled"
                    inputReadOnly={this.props.isRowReadOnly}
                    formManager={this.state.formManager} />
            </Col>

            {/* Supplier Unit cannot be deleted due to reference to unit type
            <Col lg="2">
                {
                    this.props.isRowReadOnly
                        ? null
                        : <Button color="primary" className="btn-danger" style={{ marginTop: '-10px' }}
                            onClick={() => { this.props.onRemove(this.props.data) }}>
                            <i className="fa fa-remove"></i>&nbsp;Remove
                        </Button>
                }
            </Col> */}
        </Row>
    }
}

export default StockItemSupplierUnitType;