
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import SelectComponent from '../../../../components/form/selectComponent';
import formManager from '../../../../utils/view/formManager';
import HandpointCreditCardSettingsComponent from './cardProviderComponents/handpointCreditCardSettingsComponent';
import PaymentSenseCreditCardSettingsComponent from './cardProviderComponents/paymentSenseCreditCardSettingsComponent';
import StripeReaderCreditCardSettingsComponent from './cardProviderComponents/stripeReaderCreditCardSettingsComponent';
import WorldPayCreditCardSettingsComponent from './cardProviderComponents/worldPayCreditCardSettingsComponent';

const constants = require('../../../../utils/domain/constants');
const viewUtil = require('../../../../utils/view/viewUtil');
const typeUtil = require('../../../../utils/type/typeUtil');
const validator = require('../../../../utils/validator/validator');
const stringUtil = require('../../../../utils/string/stringUtil');
const externalIntegrationUtils = require('../../../../utils/domain/externalIntegrations/externalIntegrationUtils');
const terminalTypeConstants = require('../../../../utils/constants/terminalTypeConstants');

const stationUtil = require('../stationUtil');
const stationState = require('../stationState');

const integrationType = externalIntegrationUtils.integrationTypes.cardPaymentManagement;
const cardPaymentManagementEntities = externalIntegrationUtils.externalEntities.cardPaymentManagement;

class CreditCardSettings extends Component 
{
    constructor(props)
    {
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onFieldChanged = this.onFieldChanged.bind(this);

        this.state = {};
        this.state.model = {} 
        this.state.model.externalEntity = null;

        const stationViewModel = stationState.currentStationViewModel;
        if (stationViewModel.stationType === terminalTypeConstants.pos)
        {
            this.state.clientTerminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.pos);
            this.state.model.externalEntity = this.state.clientTerminal.profile.settings.cardSettings.provider;
        }
        else if (stationViewModel.stationType === terminalTypeConstants.kiosk)
        {
            this.state.clientTerminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.kiosk);
            this.state.model.externalEntity = this.state.clientTerminal.profile.settings.cardProviderName;
        }

        this.state.model.creditCardSettings = {}

        let integrationTerminal;
        if (this.state.model.externalEntity === cardPaymentManagementEntities.worldPay)
        {
            integrationTerminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.hardware);
        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.paymentSense ||
            this.state.model.externalEntity === cardPaymentManagementEntities.handPoint ||
            this.state.model.externalEntity === cardPaymentManagementEntities.stripeReader)
        {
            integrationTerminal = this.state.clientTerminal;
        }

        if(integrationTerminal != null)
        {
            let terminalIntegrations = stationState.integrations.get(integrationTerminal.id);
            let creditCardIntegration = terminalIntegrations.find(k => k.integrationType === integrationType);
            if(creditCardIntegration != null)
                this.state.model.creditCardSettings = typeUtil.deepCloneObject(creditCardIntegration.jsonData);
        }


        this.formManager = new formManager();
        this.formManager.viewModel = this.state.model;
        this.formManager.view = this;
        this.formManager.onFieldChanged = this.onFieldChanged;
    }

    onFieldChanged(event)
    {
        const id = event.target.id;

        if (id === "externalEntity")
        {
            this.state.model.creditCardSettings = {};

            if (this.state.model.externalEntity === cardPaymentManagementEntities.worldPay)
            {
                this.state.model.creditCardSettings.ipcStatusPort = 8000;
                this.state.model.creditCardSettings.ipcMessagingPort = 10000;
                this.state.model.creditCardSettings.ipcHost = "localhost";
                this.state.model.creditCardSettings.merchantReceiptFileName = "C:\\YESEFT\\MainReceipt.txt";
            }

            const stationViewModel = stationState.currentStationViewModel;
            if (stationViewModel.stationType === terminalTypeConstants.pos)
            {
                this.state.clientTerminal.profile.settings.cardSettings.provider = this.state.model.externalEntity;
            }
            else
            {
                this.state.clientTerminal.profile.settings.cardProviderName = this.state.model.externalEntity;
            }

            this.setState({});
        }
    }

    async onSave()
    {
        var lastError = null;

        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            const shell = stationState.currentStationViewModel.shell;

            this.validateCardSettings();

            stationUtil.addUpdateTerminal(this.state.clientTerminal);

            if (this.state.model.externalEntity === cardPaymentManagementEntities.worldPay)
            {
                let hardwareTerminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.hardware);

                if (hardwareTerminal == null)
                {
                    hardwareTerminal = stationUtil.createNewHardwareTerminal(stationState.currentStationViewModel);
                    await stationUtil.addUpdateTerminal(hardwareTerminal);
                    await stationUtil.addUpdateShell(shell);
                }

                await this.saveTerminalIntegrations(hardwareTerminal);
                await this.removeClientTerminalIntegrationIfExists();
            }
            else if (this.state.model.externalEntity === cardPaymentManagementEntities.paymentSense ||
                this.state.model.externalEntity === cardPaymentManagementEntities.handPoint ||
                this.state.model.externalEntity === cardPaymentManagementEntities.stripeReader)
            {

                await this.saveTerminalIntegrations(this.state.clientTerminal);
                await this.removeHardwareTerminalIntegrationIfExists();
            }
            else
            {
                await this.removeClientTerminalIntegrationIfExists();
                await this.removeHardwareTerminalIntegrationIfExists();
            }
        }
        catch (error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }

    async saveTerminalIntegrations(terminal)
    {
        let terminalIntegrations = stationState.integrations.get(terminal.id);
        if (terminalIntegrations == null)
            terminalIntegrations = [];

        let creditCardIntegration = terminalIntegrations.find(k => k.integrationType === integrationType);
        if (creditCardIntegration == null)
            creditCardIntegration = {};

        creditCardIntegration.integrationType = integrationType;
        creditCardIntegration.externalEntity = this.state.model.externalEntity;
        creditCardIntegration.systemType = externalIntegrationUtils.systemTypes.cardPaymentManagement.terminal;
        creditCardIntegration.systemId = terminal.id;
        creditCardIntegration.jsonArray = Object.keys(this.state.model.creditCardSettings).map(key => ({ key, value: this.state.model.creditCardSettings[key] }));

        terminalIntegrations = terminalIntegrations.filter(k => k.integrationType !== integrationType);
        terminalIntegrations.push(creditCardIntegration);
        await stationUtil.updateTerminalIntegrations(terminal, terminalIntegrations);
        await stationState.loadIntegrations();
    }

    async removeClientTerminalIntegrationIfExists()
    {
        const terminal = this.state.clientTerminal;
        let terminalIntegrations = stationState.integrations.get(terminal.id);
        if (terminalIntegrations == null || !terminalIntegrations.some(k => k.integrationType === integrationType))
            return;

        terminalIntegrations = terminalIntegrations.filter(k => k.integrationType !== integrationType);
        await stationUtil.updateTerminalIntegrations(terminal, terminalIntegrations);
        await stationState.loadIntegrations();
    }

    async removeHardwareTerminalIntegrationIfExists()
    {
        let terminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.hardware);

        if (terminal == null)
            return;

        let terminalIntegrations = stationState.integrations.get(terminal.id);
        if (terminalIntegrations == null || !terminalIntegrations.some(k => k.integrationType === integrationType))
            return;

        terminalIntegrations = terminalIntegrations.filter(k => k.integrationType !== integrationType);
        await stationUtil.updateTerminalIntegrations(terminal, terminalIntegrations);
        await stationState.loadIntegrations();
    }

    validateCardSettings()
    {
        const creditCardSettings = this.state.model.creditCardSettings;

        if (this.state.model.externalEntity === cardPaymentManagementEntities.stripeReader)
        {
            if (stringUtil.isStringNullOrEmpty(creditCardSettings.locationId))
                throw Error('locationId is not specified');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.readerId))
                throw Error('readerId is not specified');

        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.handPoint)
        {
            if (stringUtil.isStringNullOrEmpty(creditCardSettings.url))
                throw Error('url is not specified');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.apiKey))
                throw Error('apiKey is not specified');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.terminalId))
                throw Error('terminalId is not specified');

        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.paymentSense)
        {
            if (stringUtil.isStringNullOrEmpty(creditCardSettings.url))
                throw Error('url is not specified');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.apiKey))
                throw Error('apiKey is not specified');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.terminalId))
                throw Error('terminalId is not specified');

        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.worldPay)
        {
            if (stringUtil.isStringNullOrEmpty(creditCardSettings.ipcStatusPort))
                throw Error('ipcStatusPort is not specified');

            if(!validator.isNumeric(creditCardSettings.ipcStatusPort) || !validator.isGreaterThanZero(creditCardSettings.ipcStatusPort))
                throw Error('ipcStatusPort is not a valid number');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.ipcMessagingPort))
                throw Error('ipcMessagingPort is not specified');

            if(!validator.isNumeric(creditCardSettings.ipcMessagingPort) || !validator.isGreaterThanZero(creditCardSettings.ipcMessagingPort))
                throw Error('ipcMessagingPort is not a valid number');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.ipcHost))
                throw Error('ipcHost is not specified');

            if (stringUtil.isStringNullOrEmpty(creditCardSettings.merchantReceiptFileName))
                throw Error('merchantReceiptFileName is not specified');

        }
    }


    render()
    {
        return (<Card>
            <CardHeader>
                {this.renderToolbar()}
            </CardHeader>
            <CardBody>
                <div style={{ minHeight: window.innerHeight * 0.50 }}>
                    {this.renderOptions()}
                </div>
            </CardBody>
        </Card>
        );
    }

    renderToolbar()
    {
        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Credit Card Terminal</div>
                    </td>
                    <td align="right">
                        {
                            !this.props.isReadOnly &&
                            <Button color="success" className="btn-success" style={{ marginRight: '10px' }} onClick={this.onSave}>
                                Save
                            </Button>
                        }
                        <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={() => { this.props.onClose() }}>
                            {this.props.isReadOnly ? "Close" : "Cancel"}
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }

    renderOptions()
    {
        if(this.state.clientTerminal == null)
        {
            return  <div style={{background:'#F6F9FF', minHeight:'100%', paddingTop:'80px', paddingLeft:'50px', paddingRight:'50px', textAlign:'center'}}>
                        <h1 style={{color:'#A9A9A9'}}>No credit card settings required for this station</h1>
                </div>
        }

        const creditTypes = [
            { value: cardPaymentManagementEntities.handPoint, label: "Hand Point"},
            { value: cardPaymentManagementEntities.stripeReader, label: "Stripe Reader"},
            { value: cardPaymentManagementEntities.paymentSense, label: "Payment Sense"},
            { value: cardPaymentManagementEntities.worldPay, label: "World Pay"},
            { value: cardPaymentManagementEntities.offline, label: "Offline"}
        ]

        let cardProviderSettingsComponent = null;
        if (this.state.model.externalEntity === cardPaymentManagementEntities.handPoint)
        {
            cardProviderSettingsComponent = <HandpointCreditCardSettingsComponent creditCardSettings={this.state.model.creditCardSettings} isReadOnly={this.props.isReadOnly} />
        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.paymentSense)
        {
            cardProviderSettingsComponent = <PaymentSenseCreditCardSettingsComponent creditCardSettings={this.state.model.creditCardSettings} isReadOnly={this.props.isReadOnly} />
        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.stripeReader)
        {
            cardProviderSettingsComponent = <StripeReaderCreditCardSettingsComponent creditCardSettings={this.state.model.creditCardSettings} isReadOnly={this.props.isReadOnly} />
        }
        else if (this.state.model.externalEntity === cardPaymentManagementEntities.worldPay)
        {
            cardProviderSettingsComponent = <WorldPayCreditCardSettingsComponent creditCardSettings={this.state.model.creditCardSettings} isReadOnly={this.props.isReadOnly} />
        }


        return <table className="component-table">
            <tr>
                <td width="50%">
                    <SelectComponent
                        caption="Credit card provider"
                        fieldName="externalEntity"
                        optionFieldName="value"
                        optionDisplayFieldName="label"
                        optionValues={creditTypes}
                        hintText="Required"
                        placeholder="Choose credit card provider"
                        clearable={false}
                        comboReadOnly={this.props.isReadOnly}
                        formManager={this.formManager} />
                </td>
                <td>

                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {cardProviderSettingsComponent}
                </td>
            </tr>
        </table>

    }
}

export default CreditCardSettings;