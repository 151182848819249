import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import { getVatCaption, getNomenclature } from '../../../../reportUtils/helperUtils/viewUtils';

const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


export default function SaleOverviewDetails(props)
{
    const salesData = props.data.salesData;
    const columnDefs = getColumnDefs();

    return (
        <div className="ag-theme-alpine" style={{ height: "75vh" }}>
            <AgGridReact
                domLayout='normal'
                rowData={salesData}
                pinnedBottomRowData={getPinnedRows(salesData)}
                gridOptions={biUtils.gridOptions}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
            />
        </div>);
}

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            e.api.setPinnedBottomRowData(getPinnedRows(rows));
        }
    });
}

function getColumnDefs()
{
    const columnDefinitions = [];

    columnDefinitions.push({ field: 'propertyName', headerName: "Store", type: 'rowGroupEnabled', hide: currentOrgNodeSelectors.isCurrentPropertySelected() });
    columnDefinitions.push({ field: 'storefrontType', headerName: "Storefront Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'venueCode', headerName: "Venue", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'dispatchType', headerName: "Dispatch Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'paymentMethod', headerName: "Payment Method", type: 'rowGroupEnabled', hide: true });

    columnDefinitions.push({ field: 'saleCount', headerName: "Sale Count", type: 'fixedNumber' });
    columnDefinitions.push({ field: 'totalRetailOrderPrice', headerName: getNomenclature("retailOrderPrice"), type: 'money' });
    columnDefinitions.push({ field: 'totalDiscounts', headerName: "Discount", type: 'money' });
    columnDefinitions.push({ field: 'totalRefunds', headerName: "Refund", type: 'money' });
    columnDefinitions.push({ field: 'totalNetOrderPrice', headerName: getNomenclature("netOrderPrice"), type: 'money' });
    columnDefinitions.push({ field: 'totalCharges', headerName: getNomenclature("saleCharges"), type: 'money' });
    columnDefinitions.push({ field: 'totalSalePrice', headerName: getNomenclature("salePrice"), type: 'money' });
    columnDefinitions.push({ field: 'totalTax', headerName: getVatCaption(), type: 'money' });
    columnDefinitions.push({ field: 'totalSalePriceIncTax', headerName: getNomenclature("salePriceIncTax"), type: 'money' });

    return columnDefinitions;
}

function getPinnedRows(rows)
{
    return [
        {
            saleCount: rows.reduce((previous, current) => previous + current.saleCount, 0),
            totalRetailOrderPrice: rows.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
            totalDiscounts: rows.reduce((previous, current) => previous + current.totalDiscounts, 0),
            totalRefunds: rows.reduce((previous, current) => previous + current.totalRefunds, 0),
            totalNetOrderPrice: rows.reduce((previous, current) => previous + current.totalNetOrderPrice, 0),
            totalCharges: rows.reduce((previous, current) => previous + current.totalCharges, 0),
            totalSalePrice: rows.reduce((previous, current) => previous + current.totalSalePrice, 0),
            totalTax: rows.reduce((previous, current) => previous + current.totalTax, 0),
            totalSalePriceIncTax: rows.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0),
        }
    ];
}
