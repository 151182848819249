import React, { memo, useMemo } from "react";
import { ModalHeader } from "reactstrap";
import { DeviceTypes } from "../../../../constants";

const DeviceConfigurationHeader = ({ state, onClose = () => {} }) => {
     const title = useMemo(() => {
          if (state.type === null) return "Choose Add Device Type";

          if (state.id === null) {
               if (state.type === DeviceTypes.receiptPrinter) return "Add Receipt Printer";
               if (state.type === DeviceTypes.labelPrinter) return "Add Label Printer";
               if (state.type === DeviceTypes.creditCardReader) return "Add Credit Card Reader";
               if (state.type === DeviceTypes.kotPrinter) return "Add KOT";
               if (state.type === DeviceTypes.telephoneCli) return "Add Telephone CLI";
          } else {
               if (state.type === DeviceTypes.receiptPrinter) return "Edit Receipt Printer";
               if (state.type === DeviceTypes.labelPrinter) return "Edit Label Printer";
               if (state.type === DeviceTypes.creditCardReader) return "Edit Credit Card Reader";
               if (state.type === DeviceTypes.kotPrinter) return "Edit KOT";
               if (state.type === DeviceTypes.telephoneCli) return "Edit Telephone CLI";

          }
          return "Add Device";
     }, [state.type, state.id]);

     return <ModalHeader toggle={onClose}>{title}</ModalHeader>;
};

export default memo(DeviceConfigurationHeader);
