import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { Field } from "../../../Form";
import { AiOutlineCopy } from "react-icons/ai";
import { generateStationCode, getAppIdForBrowser } from "../../../../networkDiagramUtil";
import { TerminalTypes } from "../../../../constants";
const viewUtil = require("../../../../../../../utils/view/viewUtil");
const configurationManager = require("../../../../../../../utils/config/configurationManager");
const logger = require("../../../../../../../utils/logger/logger.js");

const GenerateStationUrl = ({ station, visible, onClose = () => {} }) => {
     const [stationUrls, setStationUrls] = useState({
          resolvedUrlWithoutPrinter: "",
          resolvedUrlWithPrinter: "",
     });
     const handleOnClosePopup = () => {
          onClose();
     };

     useEffect(() => {
          (async function () {
               try {
                    const shellCode = station?.shell?.code ?? generateStationCode();
                    const terminalCode = `${shellCode}.${station.type}`;
                    var url = null;

                    if (station.type === TerminalTypes.pos) url = configurationManager.getConfig().appUrlPOS;
                    if (station.type === TerminalTypes.kiosk) url = configurationManager.getConfig().appUrlKiosk;
                    if (station.type === TerminalTypes.kds) url = configurationManager.getConfig().appUrlKDS;
                    if (station.type === TerminalTypes.kitchen) url = configurationManager.getConfig().appUrlKitchen;
                    if (station.type === TerminalTypes.centralKitchen) url = configurationManager.getConfig().appUrlCentralKitchen;
                    if (station.type === TerminalTypes.signage) url = configurationManager.getConfig().appUrlSignagePlayer;

                    viewUtil.showSystemModalSpinner("Please wait");
                    const result = await getAppIdForBrowser(terminalCode, 730);
                    viewUtil.closeModalSpinner();

                    const resolvedUrlWithoutPrinter = `${url}/#/?appId=${result.appId}`;
                    const resolvedUrlWithPrinter = `${resolvedUrlWithoutPrinter}&pdfPrinter=true`;
                    setStationUrls({
                         resolvedUrlWithoutPrinter,
                         resolvedUrlWithPrinter,
                    });
               } catch (error) {
                    logger.error("Error occurred while generating station url", null, error);
                    viewUtil.showCriticalAlert(error);
                    handleOnClosePopup();
               }
          })();
     }, []);
     return (
          <ModalWrapper isOpen={visible}>
               <ModalHeader toggle={handleOnClosePopup}>Station URL</ModalHeader>
               <ModalBody>
                    <div style={{ fontWeight: "bold", color: "#707070" }}>Station with printer</div>
                    <small style={{ display: "block", color: "#808080", marginBottom: "8px" }}>This station have an attached pdf printer which will be used to print sale receipts and kitchen tickets.</small>
                    <Row>
                         <Field
                              field={{
                                   label: null,
                                   hintText: null,
                                   fieldName: "stationWithPrinterUrl",
                                   type: "input:text",
                                   defaultValue: stationUrls.resolvedUrlWithPrinter,
                                   fieldProps: {
                                        readOnly: true,
                                        disabled: true,
                                   },
                              }}
                              hideLabel
                         />
                         <Button
                              color="secondary"
                              className="mb-3"
                              style={{ background: "#D3D3D3" }}
                              onClick={() => {
                                   viewUtil.showInfoAlert("URL Copied");
                                   navigator.clipboard.writeText(stationUrls.resolvedUrlWithPrinter);
                              }}>
                              <AiOutlineCopy style={{ fontSize: "20px", color: "#484848" }} />
                         </Button>
                    </Row>

                    <div style={{ fontWeight: "bold", color: "#707070" }}>Station without printer</div>
                    <small style={{ display: "block", color: "#808080", marginBottom: "8px" }}>This station does not have any attached printer.</small>
                    <Row>
                         <Field
                              field={{
                                   label: null,
                                   hintText: null,
                                   fieldName: "stationWithoutPrinterUrl",
                                   type: "input:text",
                                   defaultValue: stationUrls.resolvedUrlWithoutPrinter,
                                   fieldProps: {
                                        readOnly: true,
                                        disabled: true,
                                   },
                              }}
                              hideLabel
                         />
                         <Button
                              color="secondary"
                              className="mb-3"
                              style={{ background: "#D3D3D3" }}
                              onClick={() => {
                                   viewUtil.showInfoAlert("URL Copied");
                                   navigator.clipboard.writeText(stationUrls.resolvedUrlWithoutPrinter);
                              }}>
                              <AiOutlineCopy style={{ fontSize: "20px", color: "#484848" }} />
                         </Button>
                    </Row>
               </ModalBody>
               <ModalFooter>
                    <Button type="button" onClick={handleOnClosePopup}>
                         Close
                    </Button>
               </ModalFooter>
          </ModalWrapper>
     );
};

export default GenerateStationUrl;

const ModalWrapper = styled(Modal)`
     margin-top: 200px;
     max-width: 600px;
`;

const Row = styled.div`
     display: flex;
     flex-direction: row;
     align-items: center;
     gap: 8px;

     > div {
          flex: 1;
     }
`;
