
import React, { Component } from 'react';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import PropertyOverview from './components/PropertyOverview';
import BlockProperty from './components/BlockProperty';
import PropertyLogo from './components/PropertyLogo';
import { Card, CardHeader, Button, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil.js');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const typeUtil = require('../../../../../utils/type/typeUtil');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const constants = require('../../../../../utils/domain/constants');
const securityManager = require('../../../../../utils/domain/security/securityManager');
const propertyInfoHelper = require('./propertyBasicConfigurationHelper');
const propertyGeoConfigurationHelper = require('../propertyGeoConfiguration/propertyGeoConfigurationHelper');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class PropertyBasicConfiguration extends Component {

    constructor(props) {

        super(props);
        this.state = {}
        this.state.formManager = new FormManager();

        this.isFranchisorSelected = currentOrgNodeSelectors.isCurrentFranchisorSelected();

        if(this.isFranchisorSelected)
        {
            this.state.formManager.viewModel = { 
                id: guidUtil.generateGuid(),
                type: constants.serviceContextTypes.store,
                isEnabled: true
            }    
        }
        else
        {
            const property = typeUtil.deepCloneObject(orgSelectors.selectProperty(currentOrgNodeSelectors.selectCurrentOrgNode().propertyId));
            this.state.formManager.viewModel = property;
        }

        this.state.formManager.view = this;
        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.state.geoConfiguration = null;     // is initialized when user navigates to MapDialog component
    }

    render() {

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab>General</Tab>);
        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <PropertyOverview
                    isFranchisorSelected={this.isFranchisorSelected}
                    formManager={this.state.formManager}
                    geoConfiguration={this.state.geoConfiguration}
                    onLoadPropertyGeoConfiguration={this.onLoadPropertyGeoConfiguration}
                    onMapSave={(selectedAddress, selectedLatLng) =>  
                    {
                        this.state.formManager.viewModel.address = selectedAddress;

                        if (this.state.geoConfiguration != null) 
                        {
                            this.state.geoConfiguration.latitude = null;
                            this.state.geoConfiguration.longitude = null;

                            if (selectedLatLng != null)
                            {
                                this.state.geoConfiguration.latitude = selectedLatLng.lat;
                                this.state.geoConfiguration.longitude = selectedLatLng.lng;
                            }
                        }

                        this.setState({})
                    }} />
            </TabPanel>);

        if (!this.props.isReadOnly) 
        {
            tabHeaders.push(<Tab>Block Property</Tab>);
            tabContents.push(
                <TabPanel style={{ marginTop: '30px' }}>
                    <BlockProperty formManager={this.state.formManager} />
                </TabPanel>);
        }

        tabHeaders.push(<Tab>Logo</Tab>);
        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <PropertyLogo 
                formManager={this.state.formManager} 
                onImageUpdate={() => {
                    this.setState({})
                }}/>
            </TabPanel>);


        const contents = <Tabs>

            <TabList>
                {tabHeaders}
            </TabList>

            {tabContents}

        </Tabs>;


        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader
                        title="Property Basic Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={ true }
                    />
                </CardHeader>

                <CardBody>
                    {contents}
                </CardBody>
            </Card>

        )
    }

    async onSaveClicked() {
        const property = this.state.formManager.viewModel;
        const validationError = propertyInfoHelper.validate(property)
        if (!stringUtil.isStringNullOrEmpty(validationError)) {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Property");

        try {
            const updatedProperty = await propertyInfoHelper.save(this.isFranchisorSelected, property);
            
            if(this.state.geoConfiguration)
            {
                await propertyGeoConfigurationHelper.saveFromOrgContext(updatedProperty.franchisorId, updatedProperty.franchiseeId, updatedProperty.id, this.state.geoConfiguration);
            }

            viewUtil.showSuccessAlert(`Property ${updatedProperty.name} saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.configuration, 
                                                      'Property Basic Configuration has been altered', 
                                                      updatedProperty, updatedProperty);

            const currentOptionAction = {
                type: actionTypes.mainBarComponent,
                payload: null
            };

            actionDispatcher.dispatch(currentOptionAction);
        }
        catch (error) {
            viewUtil.showCriticalAlert(error);
        }
        finally {
            viewUtil.closeModalSpinner();
        }
    }

    onLoadPropertyGeoConfiguration = async () =>
    {
        if (this.state.geoConfiguration != null) return;     // Implies already loaded/initialized

        if (this.isFranchisorSelected)
        {
            // Implies a new property is being created
            this.setState({ geoConfiguration: {} });
            return;
        }

        try 
        {
            viewUtil.showSystemModalSpinner("Loading property geo configuration ...");
            const geoConfiguration = await propertyGeoConfigurationHelper.load();
            this.setState({ geoConfiguration: geoConfiguration || {} });
        }
        catch (error) 
        {
            console.log(`Failed to load property geoConfiguration, Error: ${error}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }
}

export default PropertyBasicConfiguration;

