
const stringUtil = require('../../string/stringUtil');
const rmsApiProxy = require('../../api/rmsApiProxy');
const dateUtil = require('../../dateUtil/dateUtil');
const orgContextUtil = require('../../domain/org/orgContextUtil');


export async function loadSaleHeaders (fromDate, toDate, propertyId, saleOrderState)
{
    const orgContexts = orgContextUtil.getOrgContextsFromPropertyIds(propertyId);
    const {franchisorId, franchiseeId } = orgContexts[0];
    
    var resourceUrl = `${rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId, propertyId)}/accounts/sales?headerOnly=true&fromDate=${fromDate}&toDate=${toDate}`;
    if(!stringUtil.isStringNullOrEmpty(saleOrderState))
        resourceUrl += `&saleOrderState=${saleOrderState}`;
        
    const results = await rmsApiProxy.get(resourceUrl);
    if (results == null)
        return [];

    const storefronts = await rmsApiProxy.get(`${rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId, propertyId)}/storefronts`);

    results.forEach(saleHeader =>
        {
            const storefront = storefronts.find(x => x.id === saleHeader.storefrontId);
            saleHeader.storefrontName = storefront != null ? storefront.name : "NA";
            saleHeader.originalPrepTime = Math.round(dateUtil.differenceInMinutes(saleHeader.originalPlannedDispatchDateTime, saleHeader.dateTime));
        });
    return results;
}

export async function loadSales(fromDate, toDate)
{
    let resourceUrl;

    const promises = [];

    resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/sales?fromDate=${fromDate}&toDate=${toDate}`;
    promises.push(rmsApiProxy.get(resourceUrl));

    resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts`;
    promises.push(rmsApiProxy.get(resourceUrl));

    const [sales, storefronts] = await Promise.all(promises);

    sales.forEach(sale => 
    {
        const storefront = storefronts.find(storefront => storefront.id === sale.storefrontId);
        sale.storefrontType = storefront.type;
        sale.storefrontBrand = storefront.brand;
    });

    return sales;
}