import * as arraySort from 'array-sort';

import * as dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';
import * as stringUtil from '../../../../../utils/string/stringUtil';

export function getPayrollSummaryData(props)
{
    const { payrollData } = props.data;

    const wages = payrollData.reduce((value, data) => value + data.adjustedWages, 0);
    const holidays = payrollData.reduce((value, data) => value + data.holidayCost, 0);
    const bonus = payrollData.reduce((value, data) => value + data.bonus, 0);
    const pensionAndNI = payrollData.reduce((value, data) => value + data.pensionAndNationalInsurance, 0);

    return [{ wages, holidays, bonus, pensionAndNI, total: wages + holidays + bonus + pensionAndNI }];
}

export function getPayrollSummaryStaffData(props)
{
    const payrollData = props.data.payrollData;

    const rows = [];

    payrollData.forEach(data => rows.push({
        staff: data.staff,
        wages: data.adjustedWages,
        holidays: data.holidayCost,
        bonus: data.bonus,
        pensionAndNI: data.pensionAndNationalInsurance,
        total: data.adjustedWages + data.holidayCost + data.bonus + data.pensionAndNationalInsurance
    }));

    return arraySort(rows, 'wages', { reverse: true });
}