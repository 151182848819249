
import React, { Component } from 'react';
import formManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

export default class WorldPayCreditCardSettingsComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.formManager = new formManager();
        this.formManager.viewModel = this.props.creditCardSettings;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>


                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="IPC Status Port"
                                fieldName="ipcStatusPort"
                                placeholder="Enter IPC Status Port"
                                hintText="Enter IPC Status Port(Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="IPC Messaging Port"
                                fieldName="ipcMessagingPort"
                                placeholder="Enter IPC Messaging Port"
                                hintText="Enter IPC Messaging (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>

                    </tr>

                    <tr>

                        <td>
                            <InputComponent
                                inputType="text"
                                caption="IPC Host"
                                fieldName="ipcHost"
                                placeholder="Enter IPC Host"
                                hintText="Enter IPC Host (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Merchant Receipt File Name"
                                fieldName="merchantReceiptFileName"
                                placeholder="Enter Terminal Id"
                                hintText="Enter full path of the Merchant Receipt file (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                </tbody>

            </ table>

        </div>
    }
}