import React, { memo } from "react";
import styled from "styled-components";

const ActionDropdownMenu = ({ title = "Actions", isOpen = false, options = [] }) => {
     return (
          <Container isOpen={isOpen}>
               <SmallArrow>
                    <svg width="100%" height="100%" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M1.49061e-07 11.5L18.75 0.674683L18.75 22.3253L1.49061e-07 11.5Z" fill="#313131" />
                    </svg>
               </SmallArrow>
               <Content>
                    <Title>{title}</Title>
                    {options.filter(option => option.enabled).map((option, idx) => (
                         <Item key={idx} option={option} />
                    ))}
               </Content>
          </Container>
     );
};

export default memo(ActionDropdownMenu);

const TopPositionMenu = "-50%";

const Container = styled.div`
     position: absolute;
     left: calc(100% + 20px);
     top: ${TopPositionMenu};
     border: 1px solid #313131;
     border-radius: 8px;
     background-color: white;
     box-shadow: 0 4px 20px #00000030;
     display: ${(props) => (props.isOpen ? "block" : "none")};
`;
const Content = styled.div`
     overflow: hidden;
     border-radius: 8px;
`;
const Title = styled.div`
     font-size: 20px;
     font-weight: 600;
     text-align: center;
     padding: 8px 30px;
     border-bottom: 1px solid #00000020;
`;
const SmallArrow = styled.div`
     position: absolute;
     top: 26px;
     right: 100%;
     width: 12px;
`;

const Item = memo(({ option }) => {
     const { title, titleColor = "#313131", bgColor = "#ffffff", Icon = null, props } = option;
     return (
          <ItemContainer titleColor={titleColor} bgColor={bgColor} {...props}>
               {Icon && Icon}
               <span>{title}</span>
          </ItemContainer>
     );
});

const ItemContainer = styled.div`
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 8px;
     white-space: nowrap;
     padding: 8px 15px;
     border-bottom: 1px solid #00000020;
     font-size: 16px;
     font-weight: 500;
     background-color: ${(props) => props.bgColor};
     color: ${(props) => props.titleColor};
     cursor: pointer;
     transition: 100ms ease;

     :last-child {
          border-bottom: none;
     }
     :hover {
          filter: brightness(0.95);
     }
`;
