import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';

import CampaignOverviewStep from './Steps/CampaignOverviewStep';
import CampaignPreviewMessageStep from './Steps/CampaignPreviewMessageStep';
import CampaignTargetStep from './Steps/CampaignTargetStep';
import CampaignMessageStep from './Steps/CampaignMessageStep';
import StepWizard from 'react-step-wizard';
import campaignUtil from '../../utils/campaignUtil'
import CampaignReviewStep from './Steps/CampaignReviewStep';

const stringUtil = require('../../../../../../utils/string/stringUtil');

export default class NewCampaign extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.stepIndex = 0;

        this.onLaunch = this.onLaunch.bind(this);
    }

    render()
    {
        var errorComponent = null;
        if (this.state.error)
        {
            const hStyle = { color: '#FFA07A' };
            errorComponent = <div align="middle"><h5 style={hStyle}>{this.state.error}</h5></div>
        }

        return <Modal open={true} onClose={this.props.onClose} center showCloseIcon={true}
            classNames={{
                overlay: 'customOverlay',
                modal: 'customModal',
            }}>

            <h2 className="text-muted">New Campaign</h2>
            <hr></hr>
            {errorComponent}
            {this.renderStep()}
        </Modal>
    }


    renderStep() 
    {
        const campaign = this.props.data;

        if (this.state.stepIndex == 0)
        {
            return <CampaignOverviewStep
                data={campaign}
                nextStep={() => { this.setState({ stepIndex: this.state.stepIndex + 1 }) }}
                onChange={() => 
                {
                    this.setState({ error: null })
                }} />
        }

        if (this.state.stepIndex == 1)
        {
            return <CampaignTargetStep
                data={campaign}
                nextStep={() => { this.setState({ stepIndex: this.state.stepIndex + 1 }) }}
                previousStep={() => { this.setState({ stepIndex: this.state.stepIndex - 1 }) }}
                onChange={() => 
                {
                    campaign.projectedTargetCount = null;
                    this.setState({ error: null })
                }} />
        }

        if (this.state.stepIndex == 2)
        {
            return <CampaignMessageStep
                data={campaign}
                nextStep={() => { this.setState({ stepIndex: this.state.stepIndex + 1 }) }}
                previousStep={() => { this.setState({ stepIndex: this.state.stepIndex - 1 }) }}
                onChange={() => 
                {
                    this.setState({ error: null })
                }}
            />
        }

        if (this.state.stepIndex == 3)
        {
            return <CampaignPreviewMessageStep
                data={campaign}
                nextStep={() => { this.setState({ stepIndex: this.state.stepIndex + 1 }) }}
                previousStep={() => { this.setState({ stepIndex: this.state.stepIndex - 1 }) }}
                />
        }

        return <CampaignReviewStep
            data={campaign}
            previousStep={() => { this.setState({ stepIndex: this.state.stepIndex - 1 }) }}
            canLaunch={() =>
            {
                const validation = campaignUtil.validateCampaign(campaign);
                return stringUtil.isStringNullOrEmpty(validation);
            }}
            onLaunch={this.onLaunch}
        />
    }


    renderWizard() 
    {
        const campaign = this.props.data;
        return (
            <StepWizard isLazyMount={true}>
                <CampaignOverviewStep
                    data={campaign}
                    onChange={() => 
                    {
                        this.setState({ error: null })
                    }} />
                <CampaignTargetStep
                    data={campaign}
                    onChange={() => 
                    {
                        campaign.projectedTargetCount = null;
                        this.setState({ error: null })
                    }} />
                <CampaignMessageStep
                    data={campaign}
                    onChange={() => 
                    {
                        this.setState({ error: null })
                    }}
                />
                <CampaignReviewStep
                    data={campaign}
                    canLaunch={() =>
                    {
                        const validation = campaignUtil.validateCampaign(campaign);
                        return stringUtil.isStringNullOrEmpty(validation);
                    }}
                    onLaunch={this.onLaunch}
                />
            </StepWizard>
        );
    }

    async onLaunch()
    {
        try 
        {
            const campaign = this.props.data;

            const updatedCampaign = await campaignUtil.saveCampaign(campaign);

            this.props.onLaunch(updatedCampaign);
        }
        catch (error) 
        {
            this.setState({ error: error })
        }
    }
}

