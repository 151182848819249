
import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Input } from 'reactstrap';
import { AiOutlineCopy } from "react-icons/ai";
import { Button } from 'reactstrap';
import ErrorMessageComponent from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import PropertyDeliveryManagementConfigurationComponent from './components/PropertyDeliveryManagementConfigurationComponent';
import * as currentOrgNodeSelectors from '../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';
const commonUtility = require('../../../../../utils/domain/commonUtility');

const FormManager = require('../../../../../utils/view/formManager.js');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const configurationManager = require('../../../../../utils/config/configurationManager');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

const propertyDeliveryManagementConfigurationHelper = require('./utils/propertyDeliveryManagementConfigurationHelper');

const shipdayBaseUrl = "integrations/shipday/orderStatus";

class PropertyDeliveryManagementConfiguration extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            webhookUrl: null
        }
    }

    async componentDidMount()
    {
        viewUtil.showSystemModalSpinner("Loading configuration, please wait ...");

        try
        {
            const configuration = await propertyDeliveryManagementConfigurationHelper.load();

            this.formManager = new FormManager();
            this.formManager.viewModel = configuration || propertyDeliveryManagementConfigurationHelper.createNew();
            this.formManager.view = this;
            this.formManager.onFieldChanged = this.onFieldChanged;


            this.setState({ isLoading: false, webhookUrl: this.getUrl() });
        }
        catch (error)
        {
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onFieldChanged = event =>
    {
        const formManager = this.formManager;
        const targetId = event.target.id;

        switch (targetId)
        {
            case "type":
                propertyDeliveryManagementConfigurationHelper.resetConfiguration(formManager.viewModel);
                break;

            default:
                break;
        }

        this.setState({});
    }

    getUrl = () => 
    {
        const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
        const propertyId = orgContext.propertyId;
        const franchisorId = orgContext.franchisorId;
        const franchiseeId = orgContext.franchiseeId;

        const url = `${configurationManager.getConfig().integrationServer}/${shipdayBaseUrl}/${franchisorId}/${franchiseeId}/${propertyId}`;

        return url;

    }

    onSaveClicked = async () =>
    {
        const configuration = this.formManager.viewModel;

        const validationError = propertyDeliveryManagementConfigurationHelper.validate(configuration)
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving configuration, please wait ...");

        try
        {
            await propertyDeliveryManagementConfigurationHelper.save(configuration);
            viewUtil.showSuccessAlert(`Configuration data saved successfully`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.deliveryManagement,
                                                      'Property Delivery Management Configuration has been altered', 
                                                      configuration);
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render() 
    {
        let component = null;
        
        const { error, isLoading } = this.state;

        if (isLoading)
        {
            component = null
        }
        else if (error)
        {
            component = <ErrorMessageComponent message={error} />;
        }
        else
        {
            component = <PropertyDeliveryManagementConfigurationComponent formManager={this.formManager} url={this.state.webhookUrl} />
        }

        return (
            <Card>

                <CardHeader>
                    <EditComponentHeader
                        title="Property Delivery Management Configuration"
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true}
                        isReadOnly={this.state.error != null || this.props.isReadOnly}
                    />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>);
    }
}

export default PropertyDeliveryManagementConfiguration;

