
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import GeneralConfiguration from './settings/GeneralConfiguration';
import POSConfiguration from './settings/POSConfiguration';
import KIOSKConfiguration from './settings/KIOSKConfiguration';
import KDSConfiguration from './settings/KDSConfiguration';
import KitchenConfiguration from './settings/KitchenConfiguration';
import SignageConfiguration from './settings/SignageConfiguration';

const terminalTypeConstants = require('../../../utils/constants/terminalTypeConstants');

const stationUtil = require('./stationUtil');
const stationState = require('./stationState');
const viewUtil = require('../../../utils/view/viewUtil');
const stringUtil = require('../../../utils/string/stringUtil');

class StationConfiguration extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = {};

        this.state.generalConfigurationModel = stationUtil.generateGeneralSettingsModel(stationState.currentStationViewModel);
        this.state.terminalConfigurationModel = stationUtil.generalTerminalSettingsModel(this.state.generalConfigurationModel.stationType, stationState.currentStationViewModel);
        this.state.lastStationType = this.state.generalConfigurationModel.stationType;
    }

    render()
    {
        return (<Card>
                    <CardHeader>
                        {this.renderToolbar()}
                    </CardHeader>
                    <CardBody>
                        <div style={{minHeight:window.innerHeight * 0.50}}>
                            {this.renderComponent()}
                        </div>
                    </CardBody>
                </Card>
                );
    }

    renderToolbar()
    {
        return (
                    <table width="100%">
                        <tr>
                            <td>
                                <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Configuration</div>
                            </td>
                            <td align="right">
                                {
                                    !this.props.isReadOnly &&
                                    <Button color="success" className="btn-success" style={{ marginLeft: '3px', marginRight: '10px' }} onClick={() => { this.onSave() }}>
                                        <i className="fa fa-save"></i>&nbsp;Save
                                    </Button>
                                }

                                <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={()=>{this.props.onClose(false)}}>
                                        {this.props.isReadOnly ? "Close" : "Cancel"}
                                </Button>
                            </td>
                        </tr>
                    </table>
                );
    }

    renderComponent()
    {
        var contents = [];
        this.generateGeneralTab(contents);
        this.generateStationTypeTabs(contents);

        const tabContents = [];
        const tabHeaders = [];

        contents.forEach(content =>
        {
            
            tabHeaders.push(<Tab>{content.caption}</Tab>);
            tabContents.push(
                <TabPanel style={{ marginTop: '30px' }}>
                   {content.component}
                </TabPanel>);
        })

        return <Tabs>
                    <TabList>
                        {tabHeaders}
                    </TabList>
                    {tabContents}
                </Tabs>
    }

    generateGeneralTab(contents)
    { 
        var content = {};
        content.caption = "Overview";
        content.component = <GeneralConfiguration isReadOnly={this.props.isReadOnly} model={this.state.generalConfigurationModel} onStationTypeChange={()=>
        {
            if (this.state.lastStationType == this.state.generalConfigurationModel.stationType)
                return;

            this.state.terminalConfigurationModel = stationUtil.generalTerminalSettingsModel(this.state.generalConfigurationModel.stationType, stationState.currentStationViewModel);
            this.state.lastStationType = this.state.generalConfigurationModel.stationType;
            this.setState({});
        }}/>

        contents.push(content);
    }

    generateStationTypeTabs(contents)
    {
        if (this.state.generalConfigurationModel.stationType == terminalTypeConstants.pos)
        {
            let content = {};
            content.caption = "POS Settings";
            content.component = <POSConfiguration isReadOnly={this.props.isReadOnly} model={this.state.terminalConfigurationModel}/>
            contents.push(content);
        }

        if (this.state.generalConfigurationModel.stationType == terminalTypeConstants.kiosk)
        {
            let content = {};
            content.caption = "KIOSK Settings";
            content.component = <KIOSKConfiguration isReadOnly={this.props.isReadOnly} model={this.state.terminalConfigurationModel}/>
            contents.push(content);
        }

        if (this.state.generalConfigurationModel.stationType == terminalTypeConstants.kds)
        {
            let content = {};
            content.caption = "KDS Settings";
            content.component = <KDSConfiguration isReadOnly={this.props.isReadOnly} model={this.state.terminalConfigurationModel}/>
            contents.push(content);
        }

        if (this.state.generalConfigurationModel.stationType == terminalTypeConstants.kitchen)
        {
            let content = {};
            content.caption = "Kitchen Settings";
            content.component = <KitchenConfiguration isReadOnly={this.props.isReadOnly} model={this.state.terminalConfigurationModel}/>
            contents.push(content);
        }

        if (this.state.generalConfigurationModel.stationType == terminalTypeConstants.signage)
        {
            let content = {};
            content.caption = "Signage Settings";
            content.component = <SignageConfiguration isReadOnly={this.props.isReadOnly} model={this.state.terminalConfigurationModel}/>
            contents.push(content);
        }
    }

    async onSave()
    {
        try
        {
            
            viewUtil.showSystemModalSpinner("Please wait");

            stationUtil.validateGeneralSettingsModel(this.state.generalConfigurationModel);
            stationUtil.validateTerminalSettingsModel(this.state.terminalConfigurationModel);

            stationUtil.updateShellWithModel(stationState.currentStationViewModel, this.state.generalConfigurationModel);
            stationUtil.updateTerminalWithModel(this.state.terminalConfigurationModel);

            var stationViewTerminals = stationState.currentStationViewModel.terminals;
            stationState.currentStationViewModel.terminals = [];
            stationState.currentStationViewModel.shell.terminals = [];

            var terminalToBeUpdated;
            var terminalsToBeRemoved = [];

            stationViewTerminals.forEach(terminal =>
            {
                if (terminal.type == terminalTypeConstants.hardware)
                {
                    stationState.currentStationViewModel.terminals.push(terminal);
                    stationState.currentStationViewModel.shell.terminals.push(terminal.id);
                    return;
                }

                if (stringUtil.areStringSame(this.state.terminalConfigurationModel.terminal.id, terminal.id))
                {
                    stationState.currentStationViewModel.terminals.push(terminal);
                    stationState.currentStationViewModel.shell.terminals.push(terminal.id);
                    terminalToBeUpdated = terminal;
                    return;
                }

                if (terminal.isExisting)
                    terminalsToBeRemoved.push(terminal);
            })

            await stationUtil.addUpdateTerminal(terminalToBeUpdated);
            await stationUtil.removeTerminals(terminalsToBeRemoved);
            await stationUtil.addUpdateShell(stationState.currentStationViewModel.shell);

            stationState.currentStationViewModel.terminals.forEach (terminal =>
            {
                terminal.isExisting = true;
            })

            viewUtil.closeModalSpinner();
            this.props.onClose(true);
        }
        catch(error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(error);
        }
        
    }
}

export default StationConfiguration;