
import React, { Component } from 'react';
import GridView from '../../../../../components/gridView/gridView'
import PropertyAddressConfigurationComponent from './components/PropertyAddressConfigurationComponent'
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js'

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../../components/gridView/gridViewOptions.js');
const GridViewButton = require('../../../../../components/gridView/gridViewButton');
const stringUtil = require('../../../../../utils/string/stringUtil.js');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');
const propertyAddressConfigurationHelper = require('./propertyAddressConfigurationHelper');

export default class PropertyAddressConfigurations extends Component
{

    constructor(props)
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadPropertyAddressOverridesAsync = this.loadPropertyAddressOverridesAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render()
    {
        var gridViewOptions = new GridViewOptions();
        gridViewOptions.title = "Property Address Overrides";

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadPropertyAddressOverridesAsync;

        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        
        gridViewOptions.getNewRow = () =>
        {
            return propertyAddressConfigurationHelper.createNew();
        };

        gridViewOptions.buttons = [this.getDeletePropertyAddressOverrideActionButton()];

        gridViewOptions.getComponent = (isNew, row) => <PropertyAddressConfigurationComponent data={ row } />;

        gridViewOptions.getComponentTitle = (isNew, row) => { return 'Add / Edit Property Address Override' }

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.messageContext = "Property Address Overrides";

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew)
                return "Please wait while adding new property address override.";

            return "Please wait while updating property address override."
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew)
                return "New property address override is added successfully.";

            return "Property address override is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew)
                return "Following error occurred while adding new property address override:";

            return "Following error occurred while updating property address override:";
        }

        return (<GridView gridViewOptions={ gridViewOptions } />);
    }

    validateRow(isNew, row)
    {
        return propertyAddressConfigurationHelper.validate(row);
    }

    getDeletePropertyAddressOverrideActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Delete Address Override";
        button.color = "danger";

        button.onRowSelect = true;
        button.actionType = "action";

        button.actionConfirmationTitle = "Delete Address Override";
        button.onActionConfirmationMessage = (selectedAddressOverride) => 
        {
            return `Do you wish to delete selected Address Override: "${selectedAddressOverride.address}" ?`
        }

        button.onActionWaitMessage = (selectedAddressOverride, customButton) => "Please wait while deleting Address Override ...";
        button.onActionSuccessMessage = (selectedAddressOverride, customButton) => "Selected Address Override deleted successfully";
        button.onActionFailMessage = (selectedAddressOverride, customButton, error) => error.toString();

        button.onAction = async (selectedAddressOverride, remoteData, customButton) =>
        {
            const addressOverrideId = selectedAddressOverride.id;

            try
            {
                await propertyAddressConfigurationHelper.deleteConfiguration(addressOverrideId)

                if (!remoteData) remoteData = [];

                // Delete addressOverride from the remoteData collection
                const existingIndex = remoteData.findIndex(addressOverride => addressOverride.id === addressOverrideId);
                remoteData.splice(existingIndex, 1);

                const response = {};
                response.remoteData = remoteData;
                response.addUpdatedRow = null;

                return response;

            } catch (error)
            {
                throw new Error(`Failed to delete Address Override with error: ${error}`);
            }
        }

        return button;
    }

    async save(isNew, row, remoteData)
    {

        try
        {
            const updatedPropertyAddressOverride = await propertyAddressConfigurationHelper.save(row);
            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.address,
                                                     'Property Address Configurations have been altered', 
                                                      row)

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(addressOverride => addressOverride.id == updatedPropertyAddressOverride.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedPropertyAddressOverride);
            else
                remoteData.push(updatedPropertyAddressOverride);

            const response = {};
            response.remoteData = arraySort(remoteData, stringUtil.localeCompareProp("address"));
            response.addUpdatedRow = updatedPropertyAddressOverride;

            return response;

        } catch (error)
        {
            row.paramsJson = null;
            throw error;
        }
    }

    async loadPropertyAddressOverridesAsync()
    {
        const propertyAddressOverrides = await propertyAddressConfigurationHelper.load() || [];
        return arraySort(propertyAddressOverrides, stringUtil.localeCompareProp("address"));
    }

    constructGridColumnHeaders()
    {
        var headers = [];
        var header = {};

        header = {};
        header.field = "address";
        header.headerName = "Address";
        header.suppressSizeToFit = false;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "isExcluded";
        header.headerName = "Is Excluded";
        headers.push(header);

        header = {};
        header.field = "deliveryCharges";
        header.headerName = "Delivery Charges";
        headers.push(header);

        return headers;
    }
}