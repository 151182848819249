

import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import LabelPrinter from './labelPrinter';
import Spinner from '../../../../../components/spinner/spinner.js';

const stationState = require('../../stationState');
const constants = require('../../../../../utils/domain/constants');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const configurationManager = require('../../../../../utils/config/configurationManager');
const apiLoadFacade = require("../../../../../utils/api/apiLoadFacade");
const viewUtil = require("../../../../../utils/view/viewUtil");
class LabelPrinters extends Component 
{
    constructor(props)
    {
        super(props);

        this.addNewPrinter = this.addNewPrinter.bind(this);
        this.loadPrintingTemplates = this.loadPrintingTemplates.bind(this)

        this.state = {};
        this.state.currentSelectedPrinter = null;
    }

    componentDidMount()
    {
        this.loadPrintingTemplates();
    }

    render()
    {
        if (this.state.currentSelectedPrinter != null)
        {
            return <LabelPrinter printer={this.state.currentSelectedPrinter} isReadOnly={this.props.isReadOnly} printingTemplates={this.state.printingTemplates} onClose={()=>
            {
                this.state.currentSelectedPrinter = null;
                this.setState({});
            }}/>
        }

        return (<Card>
                    <CardHeader>
                        {this.renderToolbar()}
                    </CardHeader>
                    <CardBody>
                        {
                            this.state.isLoading ? 
                            <div style={{marginTop:'100px', textAlign:'center'}}>
                                <Spinner text="Loading, Please wait" />
                            </div> : 
                            <div style={{minHeight:window.innerHeight * 0.50}}>
                                {this.renderOptions()}
                            </div>
                        }
                        
                    </CardBody>
                </Card>
                );
    }

    renderToolbar()
    {
        return (
                    <table width="100%">
                        <tr>
                            <td>
                                <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Label Printers</div>
                            </td>
                            <td align="right">
                                {
                                    !this.props.isReadOnly &&
                                    <Button color="success" className="btn-success" style={{ marginRight: '10px' }} onClick={this.addNewPrinter}>
                                        Add New Label Printer
                                    </Button>
                                }
                                <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={()=>{this.props.onClose()}}>
                                    Close
                                </Button>
                            </td>
                        </tr>
                    </table>
                );
    }

    renderOptions()
    {
        var printers = this.getPrinters();
        var components = [];

        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;
        printers.forEach(printer =>
        {
            components.push(<div style={{border: '1px solid #C8C8C8', background:'white', marginRight:'20px', 
                                        marginBottom:'30px', minWidth:'400px', width:'25vw', minHeight:'100px', 
                                        paddingTop:'20px', paddingBottom:'20px', cursor:'default', userSelect:'none'}} onClick={()=>
                                        {
                                            this.state.currentSelectedPrinter = printer;
                                            this.setState({});
                                        }}>

                                <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                        <img src={`${rootUrl}/app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.settings.label.printers.png`} style={{width:'80px', marginRight:'20px', marginLeft:'20px'}}/>
                                    </td>
                                    <td style={{width:'99%'}}>
                                        <table>
                                            <tr>
                                                <td>
                                                    <h3 style={{margin:'0px'}}>{printer.printerName}</h3>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <small>Label Printer ({printer.printerName})</small>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                </table>
                            </div>)    
        })

        if (components.length <= 0)
        {
            return <div style={{textAlign:'center', marginTop:'100px'}}> No Label Printer Found.</div>
        }

        return <div style={{background:'#F6F9FF', minHeight:'100%', marginTop:'-16px', marginLeft:'-16px', marginRight:'-16px', paddingTop:'40px', paddingLeft:'40px', paddingRight:'40px'}}>
                    <div className="d-flex flex-row flex-wrap w-100">
                            {components}
                    </div>
                </div>
        
    }

    addNewPrinter()
    {
        var printer = {};
            
        printer.id = guidUtil.generateGuid();
        printer.integrationType = "LabelPrinterManagement";
        printer.externalEntity = null;
        printer.systemType = "Terminal";
        printer.systemId = null;
        printer.isEnabled = true;
        
        printer.printerName = null;
        printer.systemName = null;
        printer.bluetoothAddress = null;
        printer.ipAddress = null;
        printer.serialNumber = null;
        printer.encodingType = null;
        printer.encodingInstructions = null;

        this.state.currentSelectedPrinter = printer;
        this.setState({});
    }

    getPrinters()
    {
        var printers = [];

        var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal == null)
            return printers;

        var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
        if (hardwareTerminalIntegrations == null)
            hardwareTerminalIntegrations = [];

        var printerIntegrations = hardwareTerminalIntegrations.filter (k => k.integrationType == "LabelPrinterManagement");
        if (printerIntegrations == null)
            return printers;

        printerIntegrations.forEach (printerIntegration =>
        {
            var printer = {};
            
            printer.id = printerIntegration.id;
            printer.integrationType = printerIntegration.integrationType;
            printer.externalEntity = printerIntegration.externalEntity;
            printer.systemType = printerIntegration.systemType;
            printer.systemId = printerIntegration.systemId;
            printer.isEnabled = printerIntegration.isEnabled;
            
            printer.printerName = printerIntegration.jsonData.printerName;
            printer.systemName = printerIntegration.jsonData.systemName;
            printer.bluetoothAddress = printerIntegration.jsonData.bluetoothAddress;
            printer.ipAddress = printerIntegration.jsonData.ipAddress;
            printer.serialNumber = printerIntegration.jsonData.serialNumber;
            printer.encodingType = printerIntegration.jsonData.encodingType;
            printer.encodingInstructions = printerIntegration.jsonData.encodingInstructions;
            printer.isLegacyPrinter = printerIntegration.jsonData.isLegacyPrinter || false;
            printer.itemLabelPrintingTemplate = printerIntegration.jsonData.itemLabelPrintingTemplate;
            printer.orderLabelPrintingTemplate = printerIntegration.jsonData.orderLabelPrintingTemplate;
            printer.kotLabelPrintingTemplate = printerIntegration.jsonData.kotLabelPrintingTemplate;
            printer.expiryLabelPrintingTemplate = printerIntegration.jsonData.expiryLabelPrintingTemplate;

            printer.normalFontAColumnSize = printerIntegration.jsonData.normalFontAColumnSize;
            printer.condensedFontBColumnSize = printerIntegration.jsonData.condensedFontBColumnSize;

            printers.push(printer);
        });

        return printers;
    }

    async loadPrintingTemplates()
    {
        try
        {
            this.setState({isLoading: true});

            const systemPrintingTemplates = await apiLoadFacade.loadSystemPrintingTemplates();

            const franchisorPrintingTemplates = await apiLoadFacade.loadPrintingTemplates();

            this.setState({printingTemplates: [...systemPrintingTemplates, ...franchisorPrintingTemplates]});
        }
        catch(error)
        {
            viewUtil.showErrorAlert(`Loading failed with error: ${error}`);
        }
        finally
        {
            this.setState({isLoading: false});
        }
    }

}

export default LabelPrinters;