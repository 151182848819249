import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js';

const FormManager = require('../../../../../utils/view/formManager.js');

class ProfileOverview extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    render() 
    {
        return (
            <table className="component-table">
                <tr>
                    <td>
                        <InputComponent
                            inputType="name"
                            caption="Name"
                            hintText="Required"
                            fieldName="name"
                            placeholder="Enter name that uniquely identifies Profile"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>                    
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="notes"
                            caption="Notes"
                            placeholder="Enter any notes."
                            multiLine={true} rows={4}
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>                    
                </tr>
            </table>
        )
    }
}

export default ProfileOverview;