import React, { Component } from "react";

import InputComponent from '../../../../../../components/form/inputComponent';
import FormManager from '../../../../../../utils/view/formManager';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';

const domainConstants = require('../../../../../../utils/domain/constants');

export default class PurchaseOrdersNotes extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = props.data;    // purchaseOrder
        this.formManager.view = this;
    }

    get isReadOnly() { return !purchaseOrderUtil.isUserAuthorizedForAnyAction(); }

    render()
    {
        const purchaseOrder = this.formManager.viewModel;
        const isOrderNotesReadOnly = purchaseOrder.status !== domainConstants.PurchaseOrderStatusType.draft || this.isReadOnly;
        const isReceiptNotesReadOnly = purchaseOrder.status !== domainConstants.PurchaseOrderStatusType.submitted || this.isReadOnly;

        return (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">

                <div className="w-100 mb-2">
                    <InputComponent
                        inputType="text"
                        caption="Instructions to the supplier"
                        fieldName="notes"
                        placeholder="Add your instructions to the supplier here. Your instructions will be relayed to the supplier when the order is submitted"
                        multiLine={true} rows={4}
                        inputReadOnly={isOrderNotesReadOnly}
                        formManager={this.formManager} />
                </div>

                {
                    this.props.data.status !== domainConstants.PurchaseOrderStatusType.draft &&
                    <div className="w-100 mt-2">
                        <InputComponent
                            inputType="text"
                            caption="Dispatch notes from the supplier"
                            fieldName="receiptNotes"
                            placeholder="Dispatch notes from the supplier are copied here. You may also add your own notes to record your receipt observations for reference"
                            multiLine={true} rows={4}
                            inputReadOnly={isReceiptNotesReadOnly}
                            formManager={this.formManager} />
                    </div>
                }

            </div>
        );
    }
}