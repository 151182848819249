const csv = require('csvtojson')
const ExcelWorkBook = require('../../../../components/button/excel/excelWorkBook');
const stringUtil = require('../../../../utils/string/stringUtil');
const logger = require('../../../../utils/logger/logger');
const parseUtil = require('../../../../utils/parsing/parseUtil');
const validator = require('../../../../utils/validator/validator');

export async function exportWorkbook(title, gridApi, exportOptions)
{
    try
    {
        var csvString = gridApi.getDataAsCsv();
        var rows = await csv().fromString(csvString)

        if (rows == null || rows.length <= 0)
            return null;

        let firstRow = rows[0];

        let excelWorkbook = new ExcelWorkBook();
        excelWorkbook.workBookName = "Exports";
        if (!stringUtil.isStringNullOrEmpty(title))
            excelWorkbook.workBookName = title;
            
        let excelWorkSheet = excelWorkbook.addWorksheet("Data");
        
        Object.keys(firstRow).forEach(key =>
        {
            excelWorkSheet.addColHeader(key);
        });
                
        exportOptions = exportOptions || {};
        const getExportCellStyle = exportOptions.getExportCellStyle ? exportOptions.getExportCellStyle : () => null;

        rows.forEach((row, rowIndex) =>
        {
            let excelRow = excelWorkSheet.addRow();
            Object.keys(firstRow).forEach(key =>
                {
                    var value = row[key] ?? "";
                    
                    if(validator.isNumeric(value))
                    {
                        try 
                        {
                            if (validator.isInteger(value)) 
                            {
                                value = parseUtil.parseIntElseThrow(value);
                            }
                            else
                            {
                                value = parseUtil.parseFloatElseThrow(value).toFixed(2);
                                value = parseFloat(value);
                            }
                        }
                        catch (error) 
                        {
                            logger.error(`Error parsing ${value} to float when exporting to excel. ${error}`,  {}, error);
                        }
                    }
                    
                    switch (getExportCellStyle(row, rowIndex, key)) {
                        case "rowHeader":
                            excelRow.addRowHeaderCell(value);
                            break;
                        case "info":
                            excelRow.addInfoCell(value);
                            break;
                        case "warning":
                            excelRow.addWarningCell(value);
                            break;
                        case "error":
                            excelRow.addErrorCell(value);
                            break;
                        case "data":
                            excelRow.addDataCell(value);
                            break;
                        default:
                            excelRow.addDataCell(value);
                            break;
                    } 
                })
        });
        return excelWorkbook;
    }
    catch(error)
    {
        logger.error(`Error occurred while exporting data for excel export: ${error}`,  {}, error);
        return null;
    }
}