import customerSearchUtil from '../../customers/utils/customerSearchUtil';

const stringUtil = require("../../../../../utils/string/stringUtil");
const rmsApiProxy = require("../../../../../utils/api/rmsApiProxy");
const dateUtil = require("../../../../../utils/dateUtil/dateUtil");
const constants = require('../../../../../utils/domain/constants');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const typeUtil = require('../../../../../utils/type/typeUtil');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');

export default {
    newCampaign,
    enrichCampaign,
    saveCampaign,
    abortCampaign,
    validateCampaign,
    validateCampaignOverview,
    validateTargetCriteria,
    validateMessage,
    previewMessage,
}

function newCampaign()
{
    const newCampaign = {};
    newCampaign.id = guidUtil.generateGuid();
    newCampaign.profile = {
        targetCriteria: customerSearchUtil.createSearchCriteria(),
        message: {}
    }

    const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    if(currentOrgNode.propertyId != null)
    {
        newCampaign.propertyId = currentOrgNode.propertyId;
    }

    if(currentOrgNode.franchiseeId != null)
    {
        newCampaign.franchiseeId = currentOrgNode.franchiseeId;
    }

    if(currentOrgNode.franchisorId != null)
    {
        newCampaign.franchisorId = currentOrgNode.franchisorId;
    }

    return newCampaign;
}

function enrichCampaign(campaign)
{
    if (!stringUtil.isStringNullOrEmpty(campaign.startDateTime))
    {
        campaign.localStartDateTime = dateUtil.convertToLocalStandard(campaign.startDateTime);
    }

    if (!stringUtil.isStringNullOrEmpty(campaign.endDateTime))
    {
        campaign.localEndDateTime = dateUtil.convertToLocalStandard(campaign.endDateTime);
    }

    if (!stringUtil.isStringNullOrEmpty(campaign.propertyId))
    {
        const properties = orgSelectors.selectProperties();
        const property = properties.find(x => x.id == campaign.propertyId);
        if (property != null)
            campaign.runBy = property.name;
        else
            campaign.runBy = "Property";
    }
    else if (!stringUtil.isStringNullOrEmpty(campaign.franchiseeId))
    {
        const franchisees = orgSelectors.selectFranchisees();
        const franchisee = franchisees.find(x => x.id == campaign.franchiseeId);
        if (franchisee != null)
            campaign.runBy = franchisee.name;
        else
            campaign.runBy = "Franchisee";
    }
    else if (!stringUtil.isStringNullOrEmpty(campaign.franchisorId))
    {
        const franchisors = orgSelectors.selectFranchisors()
        const franchisor = franchisors.find(x => x.id == campaign.franchisorId);
        if (franchisor != null)
            campaign.runBy = franchisor.name;
        else
            campaign.runBy = "Franchisor";
    }    

    if (campaign.profile == null && !stringUtil.isStringNullOrEmpty(campaign.profileJson))
        campaign.profile = JSON.parse(campaign.profileJson);
    else
        campaign.profile = {};

    const profile = campaign.profile;

    if (profile.targetCriteria == null)
        profile.targetCriteria = customerSearchUtil.createSearchCriteria();
    else
        customerSearchUtil.enrichSearchCriteria(profile.targetCriteria);

    if (profile.message == null)
        profile.message = {}
}


async function saveCampaign(campaign)
{
    if (campaign.franchisorId == null && campaign.franchiseeId == null && campaign.propertyId == null)
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        if(currentOrgNodeSelectors.isCurrentFranchisorSelected())
        {
            campaign.franchisorId = currentOrgNode.franchisorId;
        }

        else if(currentOrgNodeSelectors.isCurrentFranchiseeSelected())
        {
            campaign.franchiseeId = currentOrgNode.franchiseeId;
            campaign.franchisorId = currentOrgNode.franchisorId;
        }
        else if(currentOrgNodeSelectors.isCurrentPropertySelected())
        {
            campaign.propertyId = currentOrgNode.propertyId;
            campaign.franchiseeId = currentOrgNode.franchiseeId;
            campaign.franchisorId = currentOrgNode.franchisorId;
        }
    }

    campaign.profileJson = JSON.stringify(campaign.profile);

    const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/marketing/campaigns`;
    const result = await rmsApiProxy.post(resourceUrl, campaign);
    return result;
}


async function abortCampaign(campaign)
{
    const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/marketing/campaigns/${campaign.id}`;
    const result = await rmsApiProxy.deleted(resourceUrl);
    return result;
}


async function previewMessage(phoneNumber, campaign)
{
    let campaignType;
    if (campaign.type == constants.campaignType.App)
        campaignType = constants.campaignType.App;
    else if (campaign.type == constants.campaignType.SMS)
        campaignType = constants.campaignType.SMS;
    else
        throw `Preview is not supported for ${campaign.type}`

    const previewMessage = {};
    previewMessage.phoneNumber = phoneNumber;
    previewMessage.campaignType = campaignType;
    previewMessage.messageTemplate = typeUtil.deepCloneObject(campaign.profile.message)

    const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/marketing/campaigns/previewMessage`;
    await rmsApiProxy.post(resourceUrl, previewMessage);
}

function validateCampaign(campaign)
{
    let error = null;
    error = validateCampaignOverview(campaign);
    if (!stringUtil.isStringNullOrEmpty(error))
        return error;

    error = validateTargetCriteria(campaign);
    if (!stringUtil.isStringNullOrEmpty(error))
        return error;

    error = validateMessage(campaign);
    if (!stringUtil.isStringNullOrEmpty(error))
        return error;

}

function validateTargetCriteria(campaign)
{
    if (campaign.profile == null)
        return "Profile is empty";

    if (!customerSearchUtil.isSearchCriteriaValid(campaign.profile.targetCriteria))
        return "Target Criteria is not valid";

    return null;
}

function validateCampaignOverview(campaign)
{
    if (stringUtil.isStringNullOrEmpty(campaign.name))
        return "Name cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(campaign.type))
        return "Type cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(campaign.startDateTime))
        return "Start date cannot be left empty";

    if (!(dateUtil.isValidDateTime(campaign.startDateTime)))
        return "Start date is not valid";

    if (!stringUtil.isStringNullOrEmpty(campaign.endDateTime) && !dateUtil.isValidDateTime(campaign.endDateTime))
        return "End date is not valid";
}

function validateMessage(campaign)
{
    if (campaign.profile.message == null)
        return "Message not set";

    const message = campaign.profile.message;

    if (stringUtil.isStringNullOrEmpty(message.messageTemplate))
        return "Message not specified "

    if (campaign.type == constants.campaignType.App && stringUtil.isStringNullOrEmpty(message.titleTemplate))
        return "Title not specified "
}

