import React from "react";

const TelephoneCliIcon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 158 146" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_1_24972)">
                    <path
                         d="M131.683 124.142H26.319C10.9056 124.142 2.64265 119.45 1.0596 109.795C0.383053 105.654 0.966004 100.688 2.84054 94.6055C26.1345 16.1979 28.1588 11.9051 30.1162 7.75799L30.3355 7.29169C33.047 1.46592 35.0419 0 44.8718 0H45.3906C46.1928 0 46.8934 0.582869 47.1073 1.42512C47.5298 3.09504 47.9711 4.65421 48.3855 5.93069C48.4444 6.12304 48.4979 6.2775 48.5487 6.4203L48.5754 6.49608C48.9444 7.55981 49.268 8.44869 49.5087 9.09276H108.218C108.405 8.44577 108.651 7.58604 108.924 6.56311C108.946 6.48151 108.978 6.3591 109.012 6.23379L109.063 6.04144C109.397 4.74164 109.742 3.17955 110.055 1.54169C110.229 0.64407 110.956 0 111.799 0C121.514 0.0145717 124.207 1.4222 126.207 7.5161C126.859 9.49785 154.862 93.7137 155.146 94.5647C157.034 100.688 157.617 105.657 156.94 109.795C155.357 119.45 147.094 124.142 131.681 124.142H131.683ZM44.0482 3.88191C35.8815 3.95185 35.3467 5.10302 33.5096 9.04613L33.2823 9.527C31.4773 13.3506 29.4343 17.6872 6.22058 95.8179C4.5573 101.209 4.00109 105.677 4.56265 109.11C5.34883 113.896 8.45612 120.26 26.319 120.26H131.683C149.544 120.26 152.653 113.898 153.44 109.11C154.001 105.677 153.445 101.212 151.787 95.8383C151.517 95.031 123.517 10.8093 122.859 8.82172C121.744 5.4236 121.289 4.03928 113.227 3.89065C112.986 5.04182 112.735 6.12887 112.486 7.09935L112.347 7.61227C111.686 10.0778 111.208 11.5991 111.186 11.6632C110.94 12.4472 110.264 12.9718 109.504 12.9718H48.324C47.6181 12.9718 46.9763 12.5171 46.6928 11.8089C46.6661 11.7448 46.0671 10.2468 45.2408 7.85999L45.2141 7.78422C45.1499 7.5977 45.0804 7.39661 45.0189 7.18969C44.706 6.22796 44.3744 5.09719 44.0455 3.88191H44.0482Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.12087"
                    />
                    <path
                         d="M120.644 111.393H37.3624C28.3561 111.393 23.1149 109.764 20.3713 106.115C16.8415 101.417 17.8282 94.303 19.9835 84.7644C23.6015 68.7559 34.6161 30.8402 34.7231 30.4614C34.7445 30.3827 34.7739 30.304 34.806 30.2282C35.1269 29.4822 38.3652 22.9278 53.2117 22.9278H106.397C121.243 22.9278 124.481 29.4822 124.802 30.2282C124.842 30.3215 124.874 30.4177 124.899 30.5167C124.995 30.8985 134.547 68.8054 137.769 84.811C139.855 95.1744 140.871 101.79 137.403 106.29C134.68 109.822 129.514 111.396 120.649 111.396L120.644 111.393ZM38.079 31.7233C37.2421 34.6784 26.9094 70.3121 23.4465 85.6299C21.5853 93.8601 20.6306 100.426 23.163 103.795C25.1391 106.427 29.653 107.651 37.3624 107.651H120.644C128.233 107.651 132.677 106.468 134.632 103.929C137.098 100.732 136.092 94.5886 134.279 85.5833C131.201 70.2947 122.286 34.7833 121.505 31.6883C121.117 31.053 118.021 26.6698 106.389 26.6698H53.209C41.5687 26.6698 38.4775 31.0617 38.079 31.7233Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.12087"
                    />
                    <path
                         d="M111.986 146.001H46.0192C30.8064 146.001 26.0572 145.844 17.9253 142.285C6.76902 137.474 1.07323 111.014 0.837908 109.889C0.7149 109.312 0.843256 108.709 1.18019 108.246C1.51713 107.782 2.03055 107.514 2.57339 107.514H2.81406C3.67511 107.514 4.41583 108.187 4.56558 109.111C5.35176 113.897 8.45905 120.262 26.3219 120.262H131.686C149.546 120.262 152.656 113.9 153.443 109.111C153.595 108.187 154.333 107.514 155.194 107.514H155.437C155.98 107.514 156.491 107.782 156.831 108.246C157.168 108.709 157.293 109.312 157.173 109.889C156.935 111.014 151.239 137.474 140.075 142.285C131.951 145.841 127.202 145.998 111.989 145.998L111.986 146.001ZM7.36802 119.886C10.0635 127.737 14.1923 136.5 19.2463 138.68C26.8006 141.985 31.031 142.122 46.0219 142.122H111.989C126.98 142.122 131.21 141.985 138.756 138.686C143.821 136.503 147.953 127.737 150.645 119.886C146.418 122.739 140.123 124.146 131.686 124.146H26.3219C17.8905 124.146 11.5957 122.742 7.36802 119.889V119.886Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.12087"
                    />
                    <path d="M51.4268 67.0627H38.7142C37.7623 67.0627 36.9386 66.643 36.4573 65.9144C36.0482 65.2937 35.9252 64.5185 36.1043 63.7229L38.0938 55.8716C38.0965 55.8454 38.1019 55.8192 38.1072 55.7958C38.5083 54.1288 40.1582 52.8232 41.867 52.8232H54.6946C56.3632 52.8232 57.3178 53.9802 57.3178 55.9998C57.3178 56.0144 57.3178 56.0261 57.3178 56.0406L56.1065 63.9269C56.1065 63.9414 56.1065 63.956 56.1038 63.9706C55.9915 65.1247 55.2321 67.0627 51.4268 67.0627ZM39.3908 56.1689L37.4013 64.0143C37.3986 64.0405 37.3932 64.0668 37.3852 64.0901C37.3023 64.4398 37.3424 64.7429 37.5055 64.9906C37.7355 65.3374 38.1741 65.5385 38.7089 65.5385H51.4215C52.6088 65.5385 54.6197 65.3141 54.7828 63.822L55.9915 55.9794C55.9861 54.5456 55.3631 54.3474 54.6892 54.3474H41.8616C40.7679 54.3474 39.6448 55.178 39.3854 56.1689H39.3908Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M75.4907 67.0627H62.9546C60.096 67.0627 58.4247 65.9611 58.2455 63.9648C58.2455 63.9473 58.2455 63.9327 58.2429 63.9152L58.8124 56.029C58.8124 56.029 58.8124 56.0086 58.8124 55.9998C58.8124 53.9511 59.7323 52.8232 61.401 52.8232H74.3783C76.063 52.8232 77.6915 54.1288 78.0846 55.7958C78.0899 55.8162 78.0926 55.8366 78.0979 55.8541L78.0658 63.7141C78.2477 64.5126 78.1247 65.2937 77.7209 65.9144C77.2476 66.6459 76.4347 67.0627 75.4961 67.0627H75.4907ZM59.5451 63.8278C59.6976 65.3141 61.7432 65.5356 62.9546 65.5356H75.4907C76.0202 65.5356 76.4534 65.3374 76.678 64.9877C76.8384 64.74 76.8786 64.4369 76.7983 64.0872C76.793 64.0668 76.7903 64.0464 76.7876 64.026L76.8224 56.1718C76.571 55.178 75.4586 54.3445 74.3783 54.3445H61.401C60.5907 54.3445 60.1201 54.6126 60.1174 55.9824L59.5478 63.8278H59.5451Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M101.771 63.9706C101.771 63.956 101.771 63.9414 101.768 63.9269L100.557 56.0406C100.557 56.0406 100.557 56.0144 100.557 55.9998C100.557 53.9802 101.514 52.8232 103.18 52.8232H116.008C117.716 52.8232 119.366 54.1288 119.767 55.7958C119.773 55.8221 119.778 55.8454 119.781 55.8716L121.77 63.7229C121.952 64.5185 121.826 65.2937 121.417 65.9144C120.936 66.643 120.112 67.0627 119.16 67.0627H106.448C102.642 67.0627 101.883 65.1247 101.771 63.9706ZM116.008 54.3445H103.18C102.506 54.3445 101.883 54.5427 101.878 55.9765L103.086 63.819C103.252 65.3112 105.26 65.5356 106.448 65.5356H119.16C119.698 65.5356 120.136 65.3374 120.364 64.9877C120.527 64.74 120.57 64.434 120.484 64.0872C120.479 64.0609 120.473 64.0376 120.468 64.0114L118.478 56.166C118.219 55.1751 117.096 54.3445 116.002 54.3445H116.008Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M80.1569 65.9144C79.7531 65.2937 79.6301 64.5126 79.8119 63.7141L79.5392 55.8541C79.5419 55.8337 79.5472 55.8133 79.5526 55.7958C79.9456 54.1288 81.5742 52.8232 83.2588 52.8232H96.4768C98.1455 52.8232 99.0653 53.9511 99.0653 55.9998C99.0653 56.0086 99.0653 56.0202 99.0653 56.029L99.6349 63.9152C99.6349 63.9327 99.6349 63.9473 99.6322 63.9648C99.4531 65.964 97.7818 67.0627 94.9232 67.0627H82.3871C81.4458 67.0627 80.6356 66.643 80.1622 65.9144H80.1569ZM97.7577 55.9853C97.755 54.6155 97.2844 54.3474 96.4742 54.3474H83.2562C82.1758 54.3474 81.0634 55.1809 80.812 56.1747L81.0875 64.0289C81.0848 64.0493 81.0795 64.0697 81.0768 64.0901C80.9939 64.4398 81.034 64.7429 81.1971 64.9906C81.4217 65.3374 81.8549 65.5385 82.3844 65.5385H94.9205C96.1319 65.5385 98.1775 65.317 98.33 63.8307L97.7604 55.9853H97.7577Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M49.8758 83.482H36.4465C35.4384 83.482 34.5693 83.039 34.0613 82.2696C33.6307 81.6139 33.497 80.795 33.6896 79.9556L35.7914 71.6614C35.7941 71.6352 35.7994 71.606 35.8074 71.5798C36.2299 69.8195 37.9735 68.4381 39.7785 68.4381H53.328C55.0903 68.4381 56.1011 69.6622 56.1011 71.7955C56.1011 71.81 56.1011 71.8246 56.1011 71.8392L54.8229 80.1713C54.8229 80.1859 54.8229 80.2033 54.8202 80.2179C54.6998 81.4361 53.8976 83.482 49.8785 83.482H49.8758ZM37.1605 71.9732L35.0614 80.2616C35.0587 80.2879 35.0533 80.317 35.0453 80.3432C34.9571 80.7134 34.9999 81.0339 35.1737 81.2962C35.4144 81.6634 35.8796 81.8733 36.4465 81.8733H49.8758C51.1299 81.8733 53.2532 81.6372 53.427 80.0576L54.7052 71.7751C54.6998 70.2596 54.042 70.0527 53.3307 70.0527H39.7811C38.6259 70.0527 37.4386 70.9299 37.1659 71.9761L37.1605 71.9732Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M75.2966 83.4823H62.0545C59.0355 83.4823 57.2679 82.3194 57.0807 80.2095C57.0807 80.192 57.0807 80.1745 57.0781 80.157L57.6797 71.8249C57.6797 71.8249 57.6797 71.8045 57.6797 71.7928C57.6797 69.6275 58.6504 68.4355 60.4126 68.4355H74.12C75.8983 68.4355 77.6204 69.814 78.0349 71.5772C78.0402 71.5976 78.0429 71.618 78.0482 71.6413L78.0135 79.9443C78.206 80.7865 78.075 81.6113 77.6498 82.2699C77.1497 83.0422 76.2914 83.4823 75.2993 83.4823H75.2966ZM58.4525 80.0637C58.613 81.6346 60.7763 81.8677 62.0545 81.8677H75.2966C75.8555 81.8677 76.3128 81.6579 76.5507 81.2907C76.7219 81.0284 76.7647 80.7078 76.6764 80.3377C76.6711 80.3173 76.6684 80.2969 76.6631 80.2736L76.6978 71.9764C76.4331 70.9273 75.2565 70.0442 74.1173 70.0442H60.41C59.5543 70.0442 59.0569 70.3269 59.0542 71.7724L58.4525 80.0579V80.0637Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M103.058 80.2179C103.058 80.2033 103.056 80.1859 103.056 80.1713L101.778 71.8392C101.778 71.8246 101.778 71.81 101.778 71.7955C101.778 69.6622 102.788 68.4381 104.551 68.4381H118.1C119.905 68.4381 121.649 69.8166 122.071 71.5798C122.076 71.606 122.082 71.6323 122.087 71.6614L124.189 79.9556C124.379 80.795 124.248 81.6168 123.817 82.2696C123.309 83.0419 122.44 83.482 121.432 83.482H108.003C103.984 83.482 103.181 81.4361 103.061 80.2179H103.058ZM118.097 70.0498H104.548C103.837 70.0498 103.179 70.2596 103.173 71.7721L104.452 80.0547C104.625 81.6314 106.749 81.8704 108.003 81.8704H121.432C121.999 81.8704 122.462 81.6605 122.705 81.2933C122.879 81.031 122.922 80.7104 122.833 80.3403C122.828 80.3141 122.823 80.2879 122.817 80.2587L120.718 71.9703C120.445 70.9241 119.258 70.0469 118.103 70.0469L118.097 70.0498Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M80.2267 82.2699C79.7988 81.6142 79.6705 80.7894 79.863 79.9443L79.5742 71.6413C79.5769 71.6209 79.5822 71.5976 79.5876 71.5772C80.0047 69.8169 81.7242 68.4355 83.5024 68.4355H97.4638C99.2261 68.4355 100.197 69.6275 100.197 71.7928C100.197 71.8045 100.197 71.8132 100.197 71.8249L100.798 80.157C100.798 80.1745 100.798 80.192 100.796 80.2095C100.609 82.3194 98.841 83.4823 95.8219 83.4823H82.5799C81.5878 83.4823 80.7294 83.0393 80.2294 82.2699H80.2267ZM98.8196 71.7783C98.8169 70.3327 98.3195 70.0501 97.4638 70.0501H83.5024C82.3606 70.0501 81.1867 70.9331 80.922 71.9823L81.2108 80.2794C81.2081 80.2998 81.2027 80.3231 81.1974 80.3435C81.1091 80.7107 81.1519 81.0313 81.3231 81.2965C81.5611 81.6637 82.0183 81.8736 82.5772 81.8736H95.8193C97.0975 81.8736 99.2608 81.6375 99.4213 80.0696L98.8196 71.7841V71.7783Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M47.4034 101.999H32.8323C31.7386 101.999 30.7974 101.518 30.2465 100.682C29.7785 99.9709 29.6341 99.082 29.8427 98.1699L32.1237 89.1704C32.1291 89.1412 32.1344 89.1121 32.1398 89.0829C32.5997 87.1711 34.4903 85.6731 36.4477 85.6731H51.1525C53.0645 85.6731 54.1608 87.0021 54.1608 89.3161C54.1608 89.3306 54.1608 89.3481 54.1608 89.3627L52.773 98.403C52.773 98.4205 52.773 98.438 52.7703 98.4555C52.6393 99.7786 51.7702 101.999 47.4088 101.999H47.4034ZM33.6078 89.5113L31.3295 98.505C31.3242 98.5342 31.3188 98.5633 31.3135 98.5924C31.2172 98.9917 31.2653 99.3414 31.4525 99.627C31.7146 100.026 32.2173 100.254 32.8323 100.254H47.4034C48.7645 100.254 51.0669 99.9971 51.2568 98.2835L52.642 89.2957C52.6366 87.652 51.92 87.4247 51.1498 87.4247H36.445C35.1909 87.4247 33.902 88.3777 33.6052 89.5113H33.6078Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M74.9859 101.999H60.6153C57.3396 101.999 55.4223 100.737 55.2164 98.4467C55.2164 98.4292 55.2137 98.4088 55.2137 98.3913L55.8662 89.351C55.8662 89.351 55.8662 89.3277 55.8662 89.3161C55.8662 86.9671 56.9198 85.6731 58.8317 85.6731H73.7077C75.6383 85.6731 77.5049 87.1711 77.9568 89.0829C77.9621 89.1062 77.9675 89.1296 77.9701 89.15L77.9327 98.1582C78.1413 99.0733 77.9996 99.968 77.5369 100.682C76.9941 101.518 76.0635 101.999 74.9859 101.999ZM56.7085 98.2923C56.8823 99.9971 59.2302 100.251 60.618 100.251H74.9885C75.5956 100.251 76.0929 100.023 76.3496 99.6241C76.5342 99.3385 76.5823 98.9917 76.486 98.5924C76.4807 98.5691 76.4753 98.5458 76.4727 98.5225L76.5101 89.5201C76.2213 88.3806 74.9484 87.4247 73.7077 87.4247H58.8317C57.9038 87.4247 57.3637 87.7336 57.361 89.3015L56.7058 98.2952L56.7085 98.2923Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M105.114 98.4555C105.114 98.438 105.111 98.4205 105.111 98.403L103.723 89.3627C103.723 89.3481 103.723 89.3306 103.723 89.3161C103.723 87.0021 104.82 85.6731 106.732 85.6731H121.437C123.394 85.6731 125.287 87.1711 125.744 89.0829C125.752 89.1121 125.758 89.1412 125.761 89.1704L128.042 98.1699C128.247 99.082 128.106 99.9709 127.638 100.682C127.087 101.518 126.143 101.999 125.052 101.999H110.481C106.119 101.999 105.25 99.7786 105.119 98.4555H105.114ZM121.434 87.4217H106.729C105.956 87.4217 105.242 87.6491 105.237 89.2928L106.622 98.2806C106.812 99.9913 109.114 100.251 110.475 100.251H125.047C125.662 100.251 126.164 100.023 126.426 99.6241C126.614 99.3385 126.662 98.9917 126.565 98.5895C126.557 98.5604 126.552 98.5312 126.549 98.5021L124.271 89.5084C123.974 88.3718 122.685 87.4217 121.431 87.4217H121.434Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M80.3363 100.682C79.8737 99.968 79.732 99.0733 79.9406 98.1582L79.6277 89.15C79.6304 89.1266 79.6357 89.1033 79.6411 89.0829C80.093 87.1711 81.9595 85.6731 83.8902 85.6731H99.0415C100.954 85.6731 102.007 86.9671 102.007 89.3161C102.007 89.3277 102.007 89.3394 102.007 89.351L102.66 98.3913C102.66 98.4088 102.66 98.4292 102.657 98.4467C102.454 100.737 100.536 101.999 97.2579 101.999H82.8874C81.8097 101.999 80.8792 101.518 80.3363 100.682ZM100.512 89.2986C100.51 87.7307 99.9694 87.4217 99.0415 87.4217H83.8902C82.6521 87.4217 81.3765 88.3777 81.0877 89.5172L81.4033 98.5196C81.4006 98.5429 81.3953 98.5662 81.3899 98.5895C81.2963 98.9888 81.3418 99.3356 81.5263 99.6212C81.7857 100.02 82.2804 100.248 82.8874 100.248H97.2579C98.6458 100.248 100.994 99.9913 101.167 98.2893L100.512 89.2957V89.2986Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path d="M110.992 49.8844H45.3436C43.8541 49.8844 41.9448 49.2782 40.827 48.2232C39.8644 47.311 39.5809 46.1657 40.0328 44.9941L42.5411 33.6136C43.4316 31.1801 47.1031 29.2858 50.903 29.2858H107.04C110.843 29.2858 114.514 31.1801 115.397 33.599L117.76 45.0145C118.202 46.1628 117.921 47.3081 116.956 48.2203C115.838 49.2753 113.928 49.8815 110.992 49.8815V49.8844ZM50.9003 31.492C48.507 31.492 46.0388 32.7131 45.512 34.1615L42.9984 45.5566C42.7952 46.087 42.8887 46.5271 43.2685 46.8855C43.8006 47.3868 44.8194 47.6753 46.0656 47.6753H111.714C112.96 47.6753 113.979 47.3868 114.511 46.8855C114.891 46.5271 114.987 46.087 114.792 45.5828L112.423 34.1528C111.896 32.7131 109.428 31.492 107.037 31.492H50.9003Z" fill="#231F20" stroke="black" strokeWidth="1.12087" />
                    <path
                         d="M115.635 19.2086H42.1942C38.889 19.2086 36.3272 14.4669 36.3272 11.0338C36.3272 9.32603 36.9369 7.72022 38.044 6.51368C39.151 5.30714 40.6245 4.64267 42.1915 4.64267H46.7053C47.4728 4.64267 48.152 5.17891 48.3953 5.9687C48.4435 6.12607 48.497 6.27762 48.5451 6.42042L48.5718 6.49619C48.9409 7.56285 49.2644 8.44881 49.5051 9.09288H108.215C108.402 8.44589 108.648 7.58616 108.921 6.56322C108.942 6.48162 108.974 6.35922 109.009 6.2339L109.06 6.04156C109.279 5.21097 109.977 4.63976 110.768 4.63976H115.63C118.865 4.63976 121.497 7.50747 121.497 11.0338C121.497 14.5602 118.935 19.2086 115.63 19.2086H115.635ZM42.1942 8.51875C41.5764 8.51875 40.9988 8.78104 40.563 9.25317C40.1298 9.7282 39.8891 10.3577 39.8891 11.0309C39.8891 12.6571 41.1379 15.3267 42.1942 15.3267H115.635C116.691 15.3267 117.943 12.6571 117.943 11.0309C117.943 9.64369 116.908 8.51875 115.635 8.51875H112.103C111.571 10.448 111.207 11.608 111.188 11.6633C110.942 12.4473 110.266 12.9719 109.506 12.9719H48.3232C47.6172 12.9719 46.9754 12.5172 46.692 11.809C46.6679 11.7537 46.1759 10.518 45.4699 8.51875H42.1915H42.1942Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.12087"
                    />
               </g>
               <defs>
                    <clipPath id="clip0_1_24972">
                         <rect width="156.429" height="146" fill="white" transform="translate(0.785706)" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default TelephoneCliIcon;
