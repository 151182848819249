
import React, { Component } from 'react';
import SelectComponent from '../../../../components/form/selectComponent';

const FormManager = require('../../../../utils/view/formManager.js');
const constants = require('../../../../utils/domain/constants');
const stationState = require('../stationState');

class KIOSKConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.model;
        this.formManager.view = this;
    }

    render()
    {
        const printerOptions = [];
        stationState.receiptPrinters.forEach(printer =>
        {
            const printerUrl = printer.url;
            printerOptions.push({ value: printerUrl, label: printerUrl });
        });


        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td >
                                    <SelectComponent
                                        optionValues={printerOptions}
                                        optionFieldName="value"
                                        optionDisplayFieldName="label"
                                        caption="Choose Receipt Printer"
                                        fieldName="receiptPrinter"
                                        hintText="Select receipt printer"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager}/>
                                </td>
                                <td>
                                    <SelectComponent
                                        optionValues={stationState.kioskSkins}
                                        optionFieldName="id"
                                        optionDisplayFieldName="name"
                                        caption="Choose Skin"
                                        fieldName="skinId"
                                        hintText="Select the skin for this terminal (Required)"
                                        clearable={false}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <SelectComponent
                                        caption="Choose Storefront"
                                        fieldName="storeFrontId"
                                        hintText="Select storefront"
                                        optionFieldName="id"
                                        optionDisplayFieldName="name"
                                        optionValues={stationState.storefronts.filter(s => s.type == constants.storefrontTypes.KIOSK)}
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                            </tr>
                        </tbody>

                    </ table>

                </div>
    }
}

export default KIOSKConfiguration;