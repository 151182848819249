import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import ActionDropdown from "../components/ActionDropdown/ActionDropdown";
import { NetworkDiagramActionTypes, useNetworkDiagramContext } from "../../../../store";
import { DeviceTypes, TerminalTypes } from "../../../../constants";
import { stationFieldsConfiguration } from "../../../../configuration/stationFieldsConfiguration";
import { addFieldOptions } from "../../../Form";
import StationNodeHeader from "./StationNodeHeader";
import { showConfirmDialogue } from "../../../../../../../utils/view/viewUtil";
import ConnectDevicesBtn from "./ConnectDevicesBtn";
import ViewEditBtn from "../components/ViewEditBtn";
import StationActivation from "./StationActivation";
import GenerateStationUrl from "./GenerateStationUrl";
import ToggleCollapseBar from "./ToggleCollapseBar";
import securityManager from "../../../../../../../utils/domain/security/securityManager";

const StationNode = ({ data }) => {
     const { id, stationName, type, configuration, isExpanded = false } = data;
     const [isActivateStationModalOpen, setIsActivateStationModalOpen] = useState(false);
     const [isGenerateUrlStationModalOpen, setIsGenerateUrlStationModalOpen] = useState(false);
     const {
          state: { stations, devices, storefronts, signagePlayers, kioskSkins },
          dispatch,
     } = useNetworkDiagramContext();

     const fields = useMemo(() => {
          const { overviewTabFields, settingsTabFields } = stationFieldsConfiguration[type];

          const fields = [...overviewTabFields, ...settingsTabFields];

          switch (type) {
               case TerminalTypes.pos: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "signagePlayerId":
                                   addFieldOptions(field, signagePlayers, "id", "title");
                                   break;
                              case "storeFrontId":
                                   addFieldOptions(field, storefronts, "id", "title");

                                   break;

                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               case TerminalTypes.kds: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "labelPrinterId":
                                   const printers = [];

                                   const filteredDevices = devices.filter((device) => device.type === "labelPrinter");
                                   for (let idx = 0; idx < filteredDevices.length; idx++) {
                                        const device = filteredDevices[idx];
                                        const station = stations.find((station) => station.id === device.stationId);
                                        printers.push({
                                             label: `${station.type.toUpperCase()}::${station.stationName} / ${device.deviceName}`,
                                             value: device.id,
                                        });
                                   }
                                   addFieldOptions(field, printers, "value", "label");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });
                    break;
               }
               case TerminalTypes.kiosk: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "receiptPrinterId":
                                   const printers = [];

                                   const filteredDevices = devices.filter((device) => device.type === "receiptPrinter");
                                   for (let idx = 0; idx < filteredDevices.length; idx++) {
                                        const device = filteredDevices[idx];
                                        const station = stations.find((station) => station.id === device.stationId);
                                        printers.push({
                                             label: `${station.type.toUpperCase()}::${station.stationName} / ${device.deviceName}`,
                                             value: device.id,
                                        });
                                   }
                                   addFieldOptions(field, printers, "value", "label");
                                   break;
                              case "skinId":
                                   addFieldOptions(field, kioskSkins, "id", "title");
                                   break;
                              case "storeFrontId":
                                   addFieldOptions(field, storefronts, "id", "title");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               case TerminalTypes.signage: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "signagePlayerId":
                                   addFieldOptions(field, signagePlayers, "id", "title");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               case TerminalTypes.kitchen: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "labelPrinterId":
                                   const printers = [];

                                   const filteredDevices = devices.filter((device) => device.type === "labelPrinter");
                                   for (let idx = 0; idx < filteredDevices.length; idx++) {
                                        const device = filteredDevices[idx];
                                        const station = stations.find((station) => station.id === device.stationId);
                                        printers.push({
                                             label: `${station.type.toUpperCase()}::${station.stationName} / ${device.deviceName}`,
                                             value: device.id,
                                        });
                                   }
                                   addFieldOptions(field, printers, "value", "label");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               default:
                    break;
          }

          return fields.map((field) => {
               return {
                    ...field,
                    defaultValue: field.fieldName === "stationName" ? stationName : configuration[field.fieldName],
               };
          });
     }, [configuration, stationName, type, devices, storefronts, signagePlayers, kioskSkins]);

     const handleOptionEditClick = useCallback(() => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_STATION_POPUP,
               payload: {
                    id,
               },
          });
     }, [dispatch, id]);

     const handleOptionDeleteClick = useCallback(() => {
          showConfirmDialogue(`You are going to remove station ${stationName}`, "This action cannot be undone. Are you sure you want to continue", () => {
               dispatch({
                    type: NetworkDiagramActionTypes.DELETE_STATION,
                    payload: {
                         id,
                    },
               });
          });
     }, [dispatch, id, stationName]);

     const canGenerateStationURL = securityManager.isSystemUser();
     const canAddActivateOrDeleteNodes = securityManager.isSystemAdmin() || securityManager.isSupportAdmin() || securityManager.isSupportElevatedUser();

     const actionOptions = useMemo(
          () => [
               {
                    title: "Activate",
                    props: {
                         onClick: () => {
                              setIsActivateStationModalOpen(true);
                         },
                    },
                    enabled: canAddActivateOrDeleteNodes
               },
               {
                    title: "Generate Url",
                    props: {
                         onClick: () => {
                              setIsGenerateUrlStationModalOpen(true);
                         },
                    },
                    enabled: canGenerateStationURL
               },
               {
                    title: "Delete",
                    props: {
                         onClick: handleOptionDeleteClick,
                    },
                    titleColor: "#ff5757",
                    enabled: canAddActivateOrDeleteNodes
               },
          ],
          [handleOptionEditClick, handleOptionDeleteClick, stationName]
     );

     const handleOnAddReceiptPrinter = () => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_DEVICE_POPUP,
               payload: {
                    stationId: id,
                    type: DeviceTypes.receiptPrinter,
               },
          });
     };
     const handleOnAddLabelPrinter = () => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_DEVICE_POPUP,
               payload: {
                    stationId: id,
                    type: DeviceTypes.labelPrinter,
               },
          });
     };
     const handleOnAddCardReader = () => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_DEVICE_POPUP,
               payload: {
                    stationId: id,
                    type: DeviceTypes.creditCardReader,
               },
          });
     };
     const handleOnAddTelephoneCli = () => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_DEVICE_POPUP,
               payload: {
                    stationId: id,
                    type: DeviceTypes.telephoneCli,
               },
          });
     };

     const addOptions = useMemo(() => {
          const receiptPrinterAddOption = {
               title: "Receipt Printer",
               props: {
                    onClick: handleOnAddReceiptPrinter,
               },
               enabled: canAddActivateOrDeleteNodes
          };
          const labelPrinterAddOption = {
               title: "Label Printer",
               props: {
                    onClick: handleOnAddLabelPrinter,
               },
               enabled: canAddActivateOrDeleteNodes
          };
          const cardReaderAddOption = {
               title: "Card Reader",
               props: {
                    onClick: handleOnAddCardReader,
               },
               enabled: canAddActivateOrDeleteNodes
          };
          const telephoneCliAddOption = {
               title: "Telephone CLI",
               props: {
                    onClick: handleOnAddTelephoneCli,
               },
               enabled: canAddActivateOrDeleteNodes
          };
          const existingCardReader = devices.filter((device) => device.type === DeviceTypes.creditCardReader && device.stationId === id);
          const existingTelephoneCLI = devices.filter((device) => device.type === DeviceTypes.telephoneCli && device.stationId === id);
          switch (type) {
               case TerminalTypes.pos:
                    return [receiptPrinterAddOption, labelPrinterAddOption, ...(existingCardReader.length === 0 ? [cardReaderAddOption] : []), ...(existingTelephoneCLI.length === 0 ? [telephoneCliAddOption] : [])];
               case TerminalTypes.kds:
                    return [receiptPrinterAddOption, labelPrinterAddOption, ...(existingTelephoneCLI.length === 0 ? [telephoneCliAddOption] : [])];
               case TerminalTypes.kiosk:
                    return [receiptPrinterAddOption, ...(existingCardReader.length === 0 ? [cardReaderAddOption] : [])];
               case TerminalTypes.signage:
                    return [];
               case TerminalTypes.kitchen:
                    return [receiptPrinterAddOption, labelPrinterAddOption];
               case TerminalTypes.centralKitchen:
                    return [receiptPrinterAddOption, labelPrinterAddOption];

               default:
                    return [];
          }
     }, [devices, id, type]);

     const handleOnStationActivationModalClose = useCallback(() => {
          setIsActivateStationModalOpen(false);
     }, []);
     const handleOnGenerateStationUrlModalClose = useCallback(() => {
          setIsGenerateUrlStationModalOpen(false);
     }, []);

     const toggleStationExpansion = useCallback(() => {
          dispatch({
               type: NetworkDiagramActionTypes.TOGGLE_STATION_EXPANSION,
               payload: { id },
          });
     }, [id]);

     return (
          <Container>
               <Divider>{stationName}</Divider>
               {isActivateStationModalOpen && <StationActivation licenseKey={configuration.licenseKey} visible={isActivateStationModalOpen} onClose={handleOnStationActivationModalClose} />}
               {isGenerateUrlStationModalOpen && <GenerateStationUrl station={data} visible={isGenerateUrlStationModalOpen} onClose={handleOnGenerateStationUrlModalClose} />}
               <ViewEditBtn onClick={handleOptionEditClick} />
               {securityManager.isSystemUser() && <ActionDropdown options={actionOptions} /> }
               <StationNodeHeader fields={fields} stationName={stationName} stationType={type} />
               <ToggleCollapseBar isExpanded={isExpanded} onClick={toggleStationExpansion} />
               {addOptions.length > 0 && isExpanded && <ConnectDevicesBtn actionOptions={addOptions} />}
          </Container>
     );
};

export default StationNode;

const Container = styled.div`
     background-color: white;
     border-width: 3px;
     border-color: #0b75d7;
     border-style: solid;
     border-radius: 15px;
     width: 640px;
     cursor: auto;
     box-shadow: 0 5px 20px #00000020;
`;

const Divider = styled.div`
     height: 2px;
     background-color: #00000040;
     width: 2000px;
     position: absolute;
     top: -50px;
     left: 50%;
     transform: translateX(-50%);
     font-size: 26px;
     font-weight: 500;
     letter-spacing: 1px;
     text-transform: uppercase;
     line-height: 3em;
`;
