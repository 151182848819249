import { Component } from 'react';
import TableView from '../../../../../components/tableView/TableView/TableView';
import CardView from '../../../../../components/cardView/CardView';
import TableData, { ColumnDefinition, tableConstants } from '../../../../../components/tableView/TableData/TableData';
import { getColumnCount, formatAmount } from '../../../../reports/accounts/sales/salesAnalytics/utils/viewUtils';

const { columnAlignments } = tableConstants;
const stringUtil = require('../../../../../utils/string/stringUtil');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');
const dataUtils = require('../utils/dataUtils');

class DailyChargesSummary extends Component
{
    render()
    {
        const data = dataUtils.getAggregatedCharges(this.props.data);
        const dataTables = this.getDataTables(data);

        return (
            <div className='daily-summary-report-data'>
                {this.getSummaryData(data)}
                {
                    dataTables.map((tableData, index) =>
                        <div key={`${tableData.field}-${index}`} className='mb-4'>
                            <CardView title={tableData.title} footNotes={tableData.footNotes}>
                                <TableView tableData={tableData} />
                            </CardView>
                        </div>)
                }
            </div>
        )
    }

    getDataTables(data)
    {
        const dataTables = [];
        dataTables.push(...this.getChargesDataTables(data));
        return dataTables;
    }

    getSummaryData(data)
    {
        const charges = data;
        const chargesScheme = [...new Set(charges.map(charge => charge.scheme))];

        const cols = getColumnCount(chargesScheme.length);
        const rows = Math.ceil(chargesScheme.length / cols);

        return (
            <div style={{ width: "100%" }} className='mb-3 d-flex flex-row m-0 border rounded'>

                <div className='col text-white border-end rounded d-flex flex-column justify-content-center align-items-center' style={{ width: "20%", backgroundColor: "#0d6efd" }}>
                    <div className='fw-bold'>Total Charges</div>
                    <div>{formatAmount(dataUtils.getTotalChargesAmount(data))}</div>
                    <div style={{ fontSize: "12px" }}>{formatAmount(dataUtils.getTotalChargesVat(data))}</div>
                </div>
                <div className="p-0" style={{ width: "80%" }}>
                    {
                        Array.from({ length: rows }, (_, index) => index).map(row => 
                        {
                            return (
                                <div key={row} className='row m-0'>
                                    {
                                        Array.from({ length: cols }, (_, index) => index).map(col =>
                                        {
                                            const scheme = chargesScheme[cols * row + col];
                                            const data = charges.filter(charge => stringUtil.areStringSame(charge.scheme, scheme));
                                            return (data === undefined || data.length === 0)
                                                ? null
                                                : <div key={scheme} className='col border-bottom border-end py-3'>
                                                    <div className=' d-flex flex-column justify-content-center align-items-center'>
                                                        <div className='fw-bold text-muted'>{scheme}</div>
                                                        <div>{formatAmount(dataUtils.getTotalChargesAmount(data))}</div>
                                                        <div style={{ fontSize: "0.75em" }}>{formatAmount(dataUtils.getTotalChargesVat(data))}</div>
                                                    </div>
                                                </div>
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    getChargesDataTables(data)
    {
        const charges = data;
        const chargesScheme = [...new Set(charges.map(charge => charge.scheme))];

        const dataTables = [];

        chargesScheme.forEach(scheme => 
        {
            const chargesByScheme = charges.filter(charge => stringUtil.areStringSame(charge.scheme, scheme));
            const totalCharges = dataUtils.getTotalChargesAmount(chargesByScheme);
            const totalVat = dataUtils.getTotalChargesVat(chargesByScheme);

            const title = scheme;
            const columnDefinitions = [];
            columnDefinitions.push(new ColumnDefinition(34));
            columnDefinitions.push(new ColumnDefinition(33, columnAlignments.alignRight));
            columnDefinitions.push(new ColumnDefinition(33, columnAlignments.alignRight));

            const tableData = new TableData(scheme, title, ...columnDefinitions);
            tableData.setHeaderRow("Name", "Amount", localizationUtils.getVatCaption());
            tableData.setFooterRow("Total", formatAmount(totalCharges), formatAmount(totalVat));

            chargesByScheme.forEach(charge =>
            {
                tableData.addDataRow(charge.internalName, formatAmount(charge.amount, true), formatAmount(charge.tax, true));
            });

            dataTables.push(tableData);
        })

        return dataTables;
    }
}

export default DailyChargesSummary;