
const Str = require('string');
const replaceall = require('replaceall');

const typeUtil = require('../type/typeUtil');

const collator = new Intl.Collator("en-GB", { sensitivity: "base", numeric: true });

//
// collator info:
// collator object enables language sensitive string comparison
// locale               ->  en-GB = English UK
// sensitivity: "base"  ->  case-insensitive, diacritics ignored  "A" = "a", "a" = "ä"
// numeric: true        ->  "1" < "2" < "10", "2a" < "10a"  numeric strings (strings lead by numbers) are sorted by number value
//

module.exports.isStringNullOrEmpty = function (string)
{
    if (string == null)
        return true;

    return Str(string).isEmpty();
}

module.exports.trimString = function (string)
{
    if (string == null)
        return string;

    return Str(string).trim().s || null;
}

module.exports.areStringSame = function (string1, string2)
{
    string1 = this.trimString(string1);
    string2 = this.trimString(string2);

    if (string1 == null && string2 == null)
        return true;

    if (string1 == null || string2 == null)
        return false;

    return string1.toUpperCase() == string2.toUpperCase();
}

module.exports.localeCompareStr = function (str1, str2, reverse = false)
{
    //
    // Compares two strings using the defined collator
    // reverse = true sorts list in descending order
    //

    if (typeof reverse === 'boolean' && reverse === true)
    {
        return collator.compare(str2, str1);
    }
    else
    {
        return collator.compare(str1, str2);
    }
}

module.exports.localeCompareProp = function (prop, reverse = false)
{
    //
    // Returns a function that given two objects, compares their named 'prop' string property
    // using the defined collator
    //
    // reverse = true sorts list in descending order
    // prop can be dot separated (prop1.prop2.prop3...) nested property name
    //

    const getPropertyValue = typeUtil.getPropertyValue;

    return function (obj1, obj2)
    {
        if (typeof reverse === 'boolean' && reverse === true)
        {
            return collator.compare(getPropertyValue(obj2, prop), getPropertyValue(obj1, prop));
        }
        else
        {
            return collator.compare(getPropertyValue(obj1, prop), getPropertyValue(obj2, prop));
        }
    };
}

module.exports.replaceAll = function (string, searchString, replacedValue)
{
    return replaceall(searchString, replacedValue, string);
}

module.exports.capitalize = function (string)
{
    if (this.isStringNullOrEmpty(string))
        return string;

    return string.charAt(0).toUpperCase() + string.slice(1);
}

module.exports.padStart = function (value, length, padChar = '0')
{
    return ('' + value).padStart(length, padChar);
}

module.exports.isValidName = function (input)
{
    if(input == null)
        return false;
    
    return input === input.replace(/[^A-Za-z0-9@&,'\.\-\_\ \(\)\[\]]/g, "");
}

module.exports.truncateWithEllipsis = function (value, limit)
{
    if (this.isStringNullOrEmpty(value)) return value;

    if (value.length > limit)
    {
        value = value.slice(0, limit) + ' \u22EF';  // unicode for ellipsis
    }

    return value;
}

module.exports.sanitizeName = function (input)
{
    if(input == null)
        return null;

    return input.replace(/[^A-Za-z0-9@&,'\.\-\_\ \(\)\[\]]/g, "");
}