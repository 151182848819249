import styled from 'styled-components';

const constants = require('../../../../../components/form/constants');

const DroppableContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    maxHeight: 400px;
    background-color: ${props => (props.isRowReadOnly ? constants.readOnlyBackgroundColor : "white")};
    pointer-events: ${props => (props.isRowReadOnly ? "none" : "auto")};
`;

const DraggableContainer = styled.div`
    background-color: ${props => (props.isDragging ? 'SteelBlue' : 'White')};
    transition: background-color 0.5s ease;
`;

export
{
    DroppableContainer,
    DraggableContainer
}