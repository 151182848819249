import React, { memo } from "react";
import { Input } from "reactstrap";

const InputField = ({ type, onValueChange, ...props }) => {

     const handleValueChange = (e) => {
          onValueChange(e.target.value);
     };
     return (
          <div>
               <Input type={type} {...props} onChange={handleValueChange} />
          </div>
     );
};

export default memo(InputField);
