import React, { Component } from 'react';

const constants = require('../../../../../components/form/constants');

class ResponseListComponent extends Component
{
    render()
    {
        const taskResponses = this.props.taskResponses;
        const selectedTaskResponse = this.props.selectedTaskResponse;

        const rowStyle = { cursor: "pointer" };
        const selectedRowStyle = { ...rowStyle, backgroundColor: "GoldenRod", fontWeight: "bold" };

        return (
            <div className="d-flex flex-column flex-nowrap">

                <div className="d-flex flex-row flex-nowrap"
                    style={{ backgroundColor: "SlateGrey", color: "white", fontSize: "14px", fontWeight: "bold" }}>
                    <div style={{ width: "10%" }} className="border px-2 py-2"></div>
                    <div style={{ width: "60%" }} className="border px-2 py-2">Response Options</div>
                    <div style={{ width: "30%" }} className="border px-2 py-2">Hazard Types</div>
                </div>

                <div className="d-flex flex-column flex-nowrap overflow-auto"
                    style={{
                        maxHeight: "400px",
                        backgroundColor: this.props.isRowReadOnly ? constants.readOnlyBackgroundColor : null,
                        pointerEvents: this.props.isRowReadOnly ? "none" : null,
                    }}>
                    {
                        taskResponses.map(taskResponse =>
                            <div
                                className="d-flex flex-row flex-nowrap"
                                style={taskResponse == selectedTaskResponse ? selectedRowStyle : rowStyle}
                                onDoubleClick={() => this.props.onSelectTaskResponse(taskResponse)}>
                                <div
                                    className="border text-center px-2 py-1"
                                    style={{ width: "10%", fontSize: "18px", color: "red" }}
                                    onClick={(event) => { this.props.onDeleteTaskResponse(taskResponse); event.stopPropagation(); }}>
                                    <i className="fa fa-times" style={{ opacity: this.props.isRowReadOnly ? "0.25" : "1.0" }} />
                                </div>
                                <div style={{ width: "60%" }} className="border px-2 py-1">{taskResponse.response}</div>
                                <div style={{ width: "30%" }} className="border px-2 py-1">{taskResponse.hazardTypes.join(", ")}</div>
                            </div>)
                    }
                </div>
            </div>
        );
    }
}

export default ResponseListComponent;