

import * as apiLoadFacade from '../../../../../utils/api/apiLoadFacade';
import * as dateUtil from '../../../../../utils/dateUtil/dateUtil';
import * as unitUtil from '../../../../../utils/domain/unitUtil';

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

async function getInventoryData(stockItems)
{
    const context = "getInventoryData";

    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
        throw `${context}: Invalid currentOrgNode for requested operation`;

    try
    {
        const inventoryData = await apiLoadFacade.loadCurrentInventory();
        inventoryData.forEach(data => {
            data.quantity = unitUtil.convertToStockItemCoreUnitsFromDefaultUnitByStockItemId(data.stockItemId, data.quantity, stockItems)
        })
        return inventoryData;

    } catch (error)
    {
        throw `${context}: Failed to fetch inventory data, error: ${error}`;
    }
}

function getInventoryByStockItemId(stockItemId, inventoryData)
{
    return inventoryData?.find(data => data.stockItemId == stockItemId);
}

function getLastOrderItem(currentPurchaseOrder, purchaseOrders, stockItemId, unitTypeId)
{
    const currentOrderId = currentPurchaseOrder.id;
    const currentOrderDate = currentPurchaseOrder.date;
    const currentSupplierId = currentPurchaseOrder.supplierId;

    const previousPurchaseOrders =
        purchaseOrders.filter(order =>
            order.id != currentOrderId &&
            dateUtil.isSameOrBefore(order.date, currentOrderDate) &&
            order.supplierId == currentSupplierId);

    const previousOrderItems =
        previousPurchaseOrders
            .sort((order1, order2) => order2.date.localeCompare(order1.date))
            .map(order => order.orderItems).flat()
            .filter(orderItem => 
            {
                if (orderItem.stockItemId != stockItemId) return false;

                if (unitTypeId != null)
                {
                    if (orderItem.unitTypeId != unitTypeId) return false;
                }

                return true;
            });

    if (previousOrderItems.length > 0) return previousOrderItems[0];

    return null;
}

function getReorderItems(currentPurchaseOrder, purchaseOrders, inventoryData, lookupData, purchaseOrderContext)
{
    const stockItems = lookupData.stockItems;

    const reorderItems = [];

    const supplierId = currentPurchaseOrder.supplierId;

    const supplierStockItems =
        stockItems.filter(stockItem =>
            stockItem.suppliers.some(supplier => !supplier.isDisabled && supplier.supplierId == supplierId));

    supplierStockItems.forEach(stockItem => 
    {
        const stockItemId = stockItem.id;

        const inventory = getInventoryByStockItemId(stockItemId, inventoryData);
        if (inventory == null) return;  // no suggestion possible

        const supplierUnitTypes = unitUtil.getPossibleUnitForStockItemSupplier(stockItemId, supplierId, stockItems, !purchaseOrderContext.isNewOrder);


        // Select first supplier unit by default
        let suggestedSupplierUnitType = supplierUnitTypes[0];
        let suggestedQuantityInSupplierUnits = getSuggestedReorderQuantityInSupplierUnits(inventory, stockItem, suggestedSupplierUnitType);

        // Try to get supplier unit from last order
        const lastOrderItem = getLastOrderItem(currentPurchaseOrder, purchaseOrders, stockItemId, null);    // in any unit
        if (lastOrderItem != null)
        {
            const lastReorderUnitType = supplierUnitTypes.find(u => u.unitTypeId == lastOrderItem.unitTypeId);

            if (lastReorderUnitType != null)
            {
                suggestedSupplierUnitType = lastReorderUnitType;
                suggestedQuantityInSupplierUnits = getSuggestedReorderQuantityInSupplierUnits(inventory, stockItem, suggestedSupplierUnitType);
            }
        }

        if (!suggestedQuantityInSupplierUnits || !(suggestedQuantityInSupplierUnits > 0)) return;    // unlikely, no suggestion possible

        reorderItems.push(
            {
                stockItem: stockItem,
                suggestedSupplierUnitType: suggestedSupplierUnitType,
                suggestedQuantityInSupplierUnits: suggestedQuantityInSupplierUnits
            });
    });

    return reorderItems;
}

function getStockItemMinimumInventoryQuantity(stockItem)
{
    let minimumInventoryQuantity = stockItem.minimumInventoryQuantity;
    const property = currentOrgNodeSelectors.selectCurrentProperty();
    if(property)
    {
        const propertyLevelOverride = stockItem.propertyLevelOverrides.find(propertyLevelOverride => propertyLevelOverride.propertyId === property.id);
        if(propertyLevelOverride && propertyLevelOverride.minimumInventoryQuantity)
        {
            minimumInventoryQuantity = propertyLevelOverride.minimumInventoryQuantity;
        }
    }

    return minimumInventoryQuantity;
}

function getSuggestedReorderQuantityInSupplierUnits(inventory, stockItem, supplierUnit)
{
    // All quantities are stockItem coreUnits

    const quantity = inventory.quantity;
    const minimumInventoryQuantity = getStockItemMinimumInventoryQuantity(stockItem);

    if (quantity >= minimumInventoryQuantity)
    {
        return 0.0;
    }

    if(!supplierUnit) return null;
    const minimumOrderQuantity = supplierUnit.minimumOrderQuantity;

    let suggestedQuantity = minimumInventoryQuantity - quantity;

    const supplierUnitConversionFactor = unitUtil.getPossibleUnitsForStockItem(stockItem).find(unitType => unitType.id == supplierUnit.unitTypeId).coreUnitConversionFactor;
    let suggestedQuantityInSupplierUnits = suggestedQuantity / supplierUnitConversionFactor;

    if (minimumOrderQuantity > suggestedQuantityInSupplierUnits)
    {
        suggestedQuantityInSupplierUnits = minimumOrderQuantity;
    }

    return suggestedQuantityInSupplierUnits;
}

export default {
    getInventoryData,
    getInventoryByStockItemId,
    getStockItemMinimumInventoryQuantity,
    getSuggestedReorderQuantityInSupplierUnits,
    getLastOrderItem,
    getReorderItems
}
