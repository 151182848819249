
module.exports = function ()
{
    this.title = "";

    // GridView Excel Export Options
    this.onCustomExport = null;                 // (title, gridApi, exportOptions) => ExcelWorkBook
    this.exportOptions = {}

    this.closeEnabled = true;

    this.dateRangeSelectionEnabled = false;

    this.gridDataMode = "rows";                 //One of possible values are: rows, search, async
    this.onSearch = null;
    this.getRowData = null;
    this.getRowDataAsync = null;

    this.searchDisableDate = false;             //Indicates whether date level searching is allowed.

    this.getPrimaryKeyValue = null;
    this.getNewRow = null;
    this.getComponent = null;                   //(isNewRow, row, customButton) =>{}
    this.getComponentTitle = null;              //(isNewRow, row, customButton) =>{}
    this.isRowReadOnly = null;                  //(row, customButton) =>
    this.isAttachmentsReadOnly = null;          //(row) =>{}
    this.getPinnedRows = null;

    this.getAttachmentsParam = null;            //(isNewRow, row, customButton) =>{}

    this.messageContext = null;
    this.getWaitMessage = null;                 //(isNewRow, row, customButton) =>{}
    this.getSaveSuccessMessage = null;          //(isNewRow, row, customButton) =>{}
    this.getSaveErrorMessage = null;            //(isNewRow, row, customButton) =>{}

    this.validate = null;                       //(isNewRow, row, customButton) =>{}
    this.save = null;                           //(isNewRow, row, remoteData, customButton)

    this.getColumnDefinitions = null;
    this.shouldReArrangeHeaders = true;         // Default=true, works in conjunction with default header.suppressSizeToFit = true

    this.paginationEnabled = false;
    this.pageSize = null;
    this.displayPaginationControl = false;

    this.buttons = [];

    // -------------------------------------------------------------
    // New properties added to gridViewOptions to make grid stylable
    // -------------------------------------------------------------

    this.rowHeight = 40;
    this.headerHeight = 50;
    this.fontSize = 50;

    this.rowSelection = "single";
    this.enableCellTextSelection = true;

    this.rowClass = "ignis-grid-data-row";
    this.headerRowClass = "ignis-grid-header-row";      // Don't seem to be used now ? ref: defaultColDef.headerClass: "ignis-grid-header"

    this.rowClassRules = null;
    
    this.rowStyle = null;
    this.getRowStyle = null;
    this.getRowClassRules = null;

    this.selectFilterData = null;      //  { doesFilterPass: (rowData, selectedValue) => {}, props: {options: [], ...React Select Component Properties}

    this.copyPasteConfiguration = null; // {dataType, onCopy, onPaste}
}