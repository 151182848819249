import React from 'react';
import { Button } from 'reactstrap';
import Spinner from "react-spinners/ScaleLoader";

const flipdishTheme = require('../../../../utils/domain/flipdishTheme');

export default function ContinueButton(props)
{
    let buttonCaption;

    const { pageIndex, disabled, onClick } = props;

    switch (pageIndex)
    {
        case 0:
            buttonCaption = "Get Started";
            break;
        case 5:
        case 6:
            buttonCaption = "Activate";
            break;
        default:
            buttonCaption = "Continue";
            break;
    }

    return (
        <Button
            className='text-white'
            style={{ fontSize: "1em", width: "75%", backgroundColor: `${flipdishTheme.color}` }}
            disabled={disabled}
            onClick={onClick}>
            <div className='d-flex justify-content-between align-items-center'>
            {buttonCaption}
            {props.showSpinner &&
                <Spinner className="pl-3" color="white" height="10px" />
            }
            </div>

        </Button>);
}
