import React, { Component } from 'react';
import customerUtil from '../../../../utils/view/customers/utils';
import ImportView from '../components/ImportView';

const { constructGridColumnHeaders, getColumnNameFromFieldName, getFieldNameFromColumnName, transformImportedCustomerData } = customerUtil;

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');

export default class ImportCustomers extends Component {
    constructor(props) {
        super(props)
        this.onSave = this.onSave.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    //#region Render

    render() {
        return (
            <ImportView
                isReadOnly={this.props.isReadOnly}
                resourceName="Customers"
                columnHeaders={constructGridColumnHeaders()}
                onSave={this.onSave}
                enrichData={this.enrichData}
                validateData={this.validateData}
                requiredFields={["name", "phoneNumber"]}
                transformHeader={getFieldNameFromColumnName}    // transforms human readable columnName to api fieldName in the result
                transformData={transformImportedCustomerData}   // transforms imported data to api formats (e.g. dateOfBirth)
                sampleData={
                    [
                        {
                            [getColumnNameFromFieldName("name")]: "Rehan Arif",
                            [getColumnNameFromFieldName("phoneNumber")]: "7712345678",
                            [getColumnNameFromFieldName("address")]: "Ashburton Road,",
                            [getColumnNameFromFieldName("address2")]: "Croydon Surrey",
                            [getColumnNameFromFieldName("address3")]: "",
                            [getColumnNameFromFieldName("address4")]: "",
                            [getColumnNameFromFieldName("notes")]: "",
                            [getColumnNameFromFieldName("emailAddress")]: "rehan@example.com",
                            [getColumnNameFromFieldName("dateOfBirth")]: "1994-01-01",
                            [getColumnNameFromFieldName("isDisabled")]: "false",
                            [getColumnNameFromFieldName("source")]: "CSV",
                            [getColumnNameFromFieldName("usingIgnisMobileApp")]: "false",
                            [getColumnNameFromFieldName("appNotificationEnabledForOrderUpdates")]: "false",
                            [getColumnNameFromFieldName("textNotificationEnabledForOrderUpdates")]: "false",
                            [getColumnNameFromFieldName("appNotificationEnabledForOffers")]: "false",
                            [getColumnNameFromFieldName("textNotificationEnabledForOffers")]: "false"
                        },
                        {
                            [getColumnNameFromFieldName("name")]: "Adnan Mansoor",
                            [getColumnNameFromFieldName("phoneNumber")]: "7712348765",
                            [getColumnNameFromFieldName("address")]: "Upper Shirley Road,",
                            [getColumnNameFromFieldName("address2")]: "Croydon",
                            [getColumnNameFromFieldName("address3")]: "",
                            [getColumnNameFromFieldName("address4")]: "",
                            [getColumnNameFromFieldName("notes")]: "",
                            [getColumnNameFromFieldName("emailAddress")]: "adnan@example.com",
                            [getColumnNameFromFieldName("dateOfBirth")]: "1992-01-01",
                            [getColumnNameFromFieldName("isDisabled")]: "false",
                            [getColumnNameFromFieldName("source")]: "MobileApp",
                            [getColumnNameFromFieldName("usingIgnisMobileApp")]: "true",
                            [getColumnNameFromFieldName("appNotificationEnabledForOrderUpdates")]: "true",
                            [getColumnNameFromFieldName("textNotificationEnabledForOrderUpdates")]: "true",
                            [getColumnNameFromFieldName("appNotificationEnabledForOffers")]: "true",
                            [getColumnNameFromFieldName("textNotificationEnabledForOffers")]: "true"
                        },
                    ]
                }
            />
        );
    }

    enrichData(data)
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        data.franchisorId = currentOrgNode.franchisorId;

        data.isDisabled = data.isDisabled ?? false;
        data.source = data.source ?? "CSV";
        data.usingIgnisMobileApp = data.usingIgnisMobileApp ?? false;
        data.appNotificationEnabledForOrderUpdates = data.appNotificationEnabledForOrderUpdates ?? false;
        data.textNotificationEnabledForOrderUpdates = data.textNotificationEnabledForOrderUpdates ?? false;
        data.appNotificationEnabledForOffers = data.appNotificationEnabledForOffers ?? false;
        data.textNotificationEnabledForOffers = data.textNotificationEnabledForOffers ?? false;

        if (currentOrgNodeSelectors.isCurrentPropertySelected()) 
        {
            data.defaultPropertyId = currentOrgNode.propertyId;
        }

        return data;
    }

    validateData(customer, existingCustomers) {
        const franchisor = orgSelectors.selectFranchisor(currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId);
        return customerUtil.validateCustomer(customer, franchisor);
    }

    async onSave(customers) 
    {
        const customerMap = {};

        customers.forEach(customer => 
        {
            const existingCustomer = customerMap[customer.phoneNumber];

            if (existingCustomer == null)
            {
                customerMap[customer.phoneNumber] = customer;
                return;
            }

            existingCustomer.name = existingCustomer.name || customer.name;
            existingCustomer.phoneNumber = existingCustomer.phoneNumber || customer.phoneNumber;

            existingCustomer.address = existingCustomer.address || customer.address;
            existingCustomer.address2 = existingCustomer.address2 || customer.address2;
            existingCustomer.address3 = existingCustomer.address3 || customer.address3;
            existingCustomer.address4 = existingCustomer.address4 || customer.address4;
            existingCustomer.emailAddress = existingCustomer.emailAddress || customer.emailAddress;
            existingCustomer.dateOfBirth = existingCustomer.dateOfBirth || customer.dateOfBirth;
            existingCustomer.notes = existingCustomer.notes || customer.notes;

            existingCustomer.isDisabled = existingCustomer.isDisabled || customer.isDisabled;
            existingCustomer.source = existingCustomer.source || customer.source;
            existingCustomer.usingIgnisMobileApp = existingCustomer.usingIgnisMobileApp || customer.usingIgnisMobileApp;

            existingCustomer.appNotificationEnabledForOrderUpdates = existingCustomer.appNotificationEnabledForOrderUpdates || customer.appNotificationEnabledForOrderUpdates;
            existingCustomer.textNotificationEnabledForOrderUpdates = existingCustomer.textNotificationEnabledForOrderUpdates || customer.textNotificationEnabledForOrderUpdates;
            existingCustomer.appNotificationEnabledForOffers = existingCustomer.appNotificationEnabledForOffers || customer.appNotificationEnabledForOffers;
            existingCustomer.textNotificationEnabledForOffers = existingCustomer.textNotificationEnabledForOffers || customer.textNotificationEnabledForOffers;
        });

        const importCustomers = Object.keys(customerMap).map(phoneNumber => customerMap[phoneNumber]);
        await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/customers`, importCustomers);
    }
}