import arraySort from "array-sort";
import * as inventoryConstants from '../../../../../utils/domain/inventoryConstants';

export function getRawStockItemInventoryData(reportData)
{
    const { inventoryStatusData } = reportData;
    const rawStockItemInventoryStatusData = inventoryStatusData.filter(item => item.itemType === inventoryConstants.inventoryEventItemType.rawStockItem);
    return arraySort(rawStockItemInventoryStatusData, ['categoryName', 'stockItemName']);
}

export function getBuildStockItemInventoryData(reportData)
{
    const { inventoryStatusData } = reportData;
    const buildStockItemInventoryStatusData = inventoryStatusData.filter(item => item.itemType === inventoryConstants.inventoryEventItemType.buildItem);
    return arraySort(buildStockItemInventoryStatusData, ['categoryName', 'stockItemName']);
}

export function getDistinctEventTypes(reportData)
{
    const { inventoryStatusData } = reportData;

    const inclusionList = [...Object.keys(inventoryConstants.inventoryEventType)];

    return Object
        .keys(inventoryStatusData[0])
        .filter(key => inclusionList.includes(key));
}
