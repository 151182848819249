
import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';

import DateRangeComponent from './dateRangeComponent.js'

import * as dateUtil from '../../utils/dateUtil/dateUtil';
import * as stringUtil from '../../utils/string/stringUtil';

class SearchBarComponent extends Component 
{
    constructor(props) 
    {
        super(props);

        this.renderDateRange = this.renderDateRange.bind(this);
        this.renderSearchButton = this.renderSearchButton.bind(this);
        this.onSearchButtonClicked = this.onSearchButtonClicked.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {};
        this.state.searchCriteria = {};
        this.state.searchCriteria.startDate = null;
        this.state.searchCriteria.endDate = null;

        if (this.props.searchCriteria)
            this.state.searchCriteria = this.props.searchCriteria;
    }

    render()
    {
        return (
            <div className='d-flex align-items-center'>
                <div>{this.renderDateRange()}</div>
                <div className='ms-2'>{this.renderSearchButton()}</div>
            </div>
        )
    }

    renderSearchButton()
    {
        let buttonDisabled = this.props.disabled || false;

        if (!buttonDisabled)
        {
            if (this.props.searchDisableDate ||
                this.state.searchCriteria.startDate == null ||
                this.state.searchCriteria.endDate == null)
            {
                buttonDisabled = true;
            }
        }

        let searchButtonCaption = "Search";

        if (this.props.searchButtonCaption)
            searchButtonCaption = this.props.searchButtonCaption;

        return (
            <Button
                className={this.props.btnClassName ? this.props.btnClassName : "btn btn-dark"}
                disabled={buttonDisabled}
                onClick={this.onSearchButtonClicked}>
                <i className="fa fa-search"></i>&nbsp;{searchButtonCaption}
            </Button>);
    }

    renderDateRange()
    {
        if (this.props.searchDisableDate)
            return null;

        if (this.props.searchSingleDate)
        {
            //
            // Single date selection component
            // Today's date is selected by default if not specified otherwise
            //

            this.state.searchCriteria.startDate = this.state.searchCriteria.startDate;
            this.state.searchCriteria.endDate = this.state.searchCriteria.startDate;

            return (
                <div style={{ paddingRight: "10px" }}>
                    <Input
                        type="date"
                        style={{ fontSize: "medium" }}
                        disabled={this.props.disabled}
                        value={this.state.searchCriteria.startDate}
                        onChange={event => 
                        {
                            if (event != null)
                            {
                                let selectedDate = event.target.value;

                                if (!stringUtil.isStringNullOrEmpty(selectedDate))
                                    selectedDate = dateUtil.convertToUTCStandard(selectedDate);

                                this.state.searchCriteria.startDate = selectedDate;
                                this.state.searchCriteria.endDate = selectedDate;
                                this.onChange();
                                this.setState({});
                            }
                        }}
                    />
                </div>
            );
        }

        return (
            <DateRangeComponent
                maxDays={this.props.dateRangePickerMaxDays}
                disabled={this.props.disabled}
                startDate={this.state.searchCriteria.startDate}
                endDate={this.state.searchCriteria.endDate}
                onDateRangeChange={(startDate, endDate) =>
                {
                    this.state.searchCriteria.startDate = startDate;
                    this.state.searchCriteria.endDate = endDate;
                    this.onChange();
                    this.setState({});
                }} />
        );
    }

    onSearchButtonClicked()
    {
        if (this.props.onSearch != null)
            this.props.onSearch(this.state.searchCriteria);
    }

    onChange()
    {
        if (this.props.onChange)
            this.props.onChange(this.state.searchCriteria);
    }
}

export default SearchBarComponent;