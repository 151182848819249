
const stateManager = require('../state/stateManager.js');
const passportAction = require('../state/actions/passport/passportAction.js');

const stringUtil = require('../string/stringUtil.js');
const logger = require('../logger/logger.js');
const utils = require('../logger/utils');
const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');

const configurationManager = require('../config/configurationManager.js');

const waitingTime = 500;


module.exports.get = function (resourceUrls)
{
    const isArrayGiven = Array.isArray(resourceUrls);
    if (!isArrayGiven)
    {
        resourceUrls = [resourceUrls];
    }

    const allPromises = [];
    resourceUrls.forEach(resourceUrl =>
    {
        const promise = executeFetch(resourceUrl, 'GET', null);
        allPromises.push(promise);
    });

    return Promise.all(allPromises)
        .then(
            results =>
            {
                if (!isArrayGiven)
                    return results[0];
                else
                    return results;
            },
            error =>
            {
                return Promise.reject(error);
            });
}

module.exports.post = function (resourceUrl, body)
{
    return executeFetch(resourceUrl, 'POST', body);
}

module.exports.delete = function (resourceUrl, body)
{
    return executeFetch(resourceUrl, 'DELETE', body);
}

module.exports.getWithCurrentContext = function (resourceUrls, body)
{
    const isArrayGiven = Array.isArray(resourceUrls);

    let resources;
    if (Array.isArray(resourceUrls))
    {
        resources = resourceUrls.map(resourceUrl => ({ orgContexts: [currentOrgNodeSelectors.selectCurrentOrgNode()], resourceUrl: resourceUrl }));
    }
    else
    {
        resources = { orgContexts: [currentOrgNodeSelectors.selectCurrentOrgNode()], resourceUrl: resourceUrls };
    }

    return this.getWithContext(resources, body);
}

module.exports.getWithContext = function (resources, body)
{
    const isArrayGiven = Array.isArray(resources);

    if (!isArrayGiven)
    {
        resources = [resources];
    }

    const allPromises = [];

    resources.forEach(resource => 
    {
        const resourceUrl = resource.resourceUrl;
        const orgContexts = resource.orgContexts;

        const promise = executeFetch(resourceUrl, 'GET', body, orgContexts);

        allPromises.push(promise);
    });

    return Promise.all(allPromises)
        .then(
            results =>
            {
                if (!isArrayGiven)
                    return results[0];
                else
                    return results;
            },
            error =>
            {
                return Promise.reject(error);
            });
}

module.exports.deleteWithContext = function (resource, body)
{
    const { resourceUrl, orgContexts } = resource;

    return executeFetch(resourceUrl, 'DELETE', body, orgContexts);
}

module.exports.postWithContext = function (resource, body)
{
    const { resourceUrl, orgContexts } = resource;

    return executeFetch(resourceUrl, 'POST', body, orgContexts);
}

function executeFetch(resourceUrl, method, body, orgContexts)
{
    //
    // switch method: GET by POST, POST by PUT, DELETE remains unchanged    
    //

    switch (method.toLowerCase())
    {
        case "get":
            method = "POST";
            break;
        case "post":
            method = "PUT";
            break;
    }

    if (orgContexts != null)
    {
        return executeFetchWithContext(resourceUrl, method, body, orgContexts);
    } else
    {
        return executeFetchWithoutContext(resourceUrl, method, body);
    }
}

function executeFetchWithContext(resourceUrl, method, body, orgContexts)
{
    //
    // add orgContexts to body
    //

    body = encloseInObjectIfArray(body);

    body.orgContexts = JSON.stringify(orgContexts);

    const requestBody = {};
    requestBody.method = method;
    requestBody.headers = getHttpHeader(getCompleteUrl(resourceUrl), method);
    requestBody.body = JSON.stringify(body);

    const completeUrl = getCompleteUrl(resourceUrl);

    if (stringUtil.isStringNullOrEmpty(completeUrl))
        return Promise.reject("Resource URL is either null or empty");

    return doFetch(completeUrl, requestBody);
}

function encloseInObjectIfArray(body)
{
    body = body || {};

    if (Array.isArray(body) || typeof body !== "object")
    {
        const tempBody = {};
        tempBody.manipulatedPayload = body;
        tempBody.isManipulatedPayload = true;
        body = tempBody;
    }
    return body;
}

function executeFetchWithoutContext(resourceUrl, method, body)
{
    // No method switching

    body = encloseInObjectIfArray(body);

    const requestBody = {};
    requestBody.method = method;
    requestBody.headers = getHttpHeader(getCompleteUrl(resourceUrl), method);
    requestBody.body = JSON.stringify(body);

    const completeUrl = getCompleteUrl(resourceUrl);

    if (stringUtil.isStringNullOrEmpty(completeUrl))
        return Promise.reject("Resource URL is either null or empty");

    return doFetch(completeUrl, requestBody);
}

function doFetch(completeUrl, requestBody)
{
    var tracingContext = JSON.parse(requestBody.headers["tracing-context"]);
    logger.warn(`Sending request to URL: ${completeUrl} via deprecated api proxy`, tracingContext);

    let errorMessage;

    return fetch(completeUrl, requestBody)
        .then(
            response =>
            {
                if (response.status < 200 || response.status > 299)
                {
                    if (response.status == 404)
                    {
                        const errorMessage = `Resource URL '${completeUrl}' does not exist on server with method '${requestBody.method}'.`;
                        logger.error(errorMessage, tracingContext);
                        return Promise.reject(errorMessage);
                    }

                    errorMessage = `HTTP Error: Status Code = ${response.status}, Resource URL '${completeUrl}'`;
                    if (response.status == 401) {
                        removePassportOn401();
                    } else {
                        logger.error(errorMessage, tracingContext);
                    }

                    return response.text()
                        .then(text => Promise.reject(text || errorMessage));
                }

                logger.info(`Response received from URL: ${completeUrl}`, tracingContext);
                return response.json();
            },
            error =>
            {
                errorMessage = error.message;
                if (!errorMessage) errorMessage = error;
                logger.error(`Following error reported while accessing resource URL '${completeUrl}' with method '${requestBody.method}':  ${errorMessage}`, tracingContext, error);
                return Promise.reject(errorMessage);
            })
        .then(x => new Promise(resolve => setTimeout(() => resolve(x), waitingTime)), error => Promise.reject(error));
}

function getHttpHeader(url, verb)
{
    const state = stateManager.getStore().getState();

    if (state.passport == null)
    {
        return ({ 
                    "Content-Type": "application/json", 
                    "tracing-context": JSON.stringify(utils.createTracingContext(url, verb))
                });
    }

    return ({
        "Content-Type": "application/json",
        "x-token": state.passport.token,
        "tracing-context": JSON.stringify(utils.createTracingContext(url, verb))
    });
}

function getCompleteUrl(url)
{
    if (stringUtil.isStringNullOrEmpty(url))
        return url;

    var baseUrl = configurationManager.getConfig().apiServer;
    return `${baseUrl}/${url}`;
}

function removePassportOn401()
{
    var state = stateManager.getStore().getState();
    if (state.passport == null)
        return;

    stateManager.getStore().dispatch(passportAction.createAction(null));
}