import React from 'react';

import ItemComponent from './ItemComponent';

export default function ListComponent({ selector, selectedOption, options, displayFieldName, onSelectedOptionChanged })
{
    return (
        <div className='h-100 border py-1' style={{ overflow: 'auto' }}>
            {
                options.map((option, index) =>
                {
                    const key = `${index}::${option[displayFieldName]}`;    // index is prepended to displayFieldName as additional assurance for uniqueness

                    return (
                        <div key={key} onClick={e => onSelectedOptionChanged(option)}>
                            <ItemComponent
                                displayValue={option[displayFieldName]}
                                isSelected={selectedOption && selector(option, selectedOption)} />
                        </div>);
                })
            }
        </div>
    );
}