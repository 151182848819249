
const orgSelectors = require('../../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const configurationManager = require('../../../../../../utils/config/configurationManager');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const viewUtil = require('../../../../../../utils/view/viewUtil');

const ExcelWorkBook = require('../../../../../../components/button/excel/excelWorkBook');

module.exports.getTableOrderAppUrl = function (appId, webAppConfiguration)
{
    const catalogProperty = currentOrgNodeSelectors.selectCurrentProperty();
    if (catalogProperty == null)
        return "";

    let baseUrl = configurationManager.getConfig().appUrlWebOrdering;
    
    if (webAppConfiguration && !stringUtil.isStringNullOrEmpty(webAppConfiguration.customUrl))
    {
        baseUrl = webAppConfiguration.customUrl;
    }

    const url = `${baseUrl}/#/?applicationId=${appId}`;
    return url;
}

module.exports.getAllTableOrderingUrls = function (layoutConfiguration, webAppConfiguration)
{
    let tableUrls = [];
    if (layoutConfiguration.layout && layoutConfiguration.layout.floors)
    {
        layoutConfiguration.layout.floors.forEach(floor =>
            floor.stencils.forEach(stencil =>
            {
                const { tableNumber, qrOrderingAppId, qrOrderingAppEnabled } = stencil;
                if (qrOrderingAppEnabled && !stringUtil.isStringNullOrEmpty(tableNumber) && !stringUtil.isStringNullOrEmpty(qrOrderingAppId))
                {
                    tableUrls.push({ zone: floor.title, tableNumber, url: this.getTableOrderAppUrl(qrOrderingAppId, webAppConfiguration) });
                }
            }))

    }

    return tableUrls;
}

module.exports.excelExportTableOrderingUrls = function (layoutConfiguration, webAppConfiguration)
{
    const property = orgSelectors.selectProperty(layoutConfiguration.propertyId);

    try
    {
        let excelWorkbook = new ExcelWorkBook();
        excelWorkbook.workBookName = `${property.name}_TableQROrdering`;

        let excelWorkSheet = excelWorkbook.addWorksheet("Data");
        excelWorkSheet.addColHeader("Zone");
        excelWorkSheet.addColHeader("Table");
        excelWorkSheet.addColHeader("Url");

        const tableUrls = this.getAllTableOrderingUrls(layoutConfiguration, webAppConfiguration);

        tableUrls.forEach(tableUrl =>
        {
            const excelRow = excelWorkSheet.addRow();
            excelRow.addDataCell(tableUrl.zone);
            excelRow.addDataCell(tableUrl.tableNumber);
            excelRow.addDataCell(tableUrl.url);
        })

        return excelWorkbook;
    }
    catch (error)
    {
        viewUtil.showErrorAlert(error);
    }
}