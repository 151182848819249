
//
// Data type constants for GridView columns for any special formatting and handling
//

module.exports.id = "id";                       // hide in grid and column tools

module.exports.date = "date";
module.exports.dueDate = "dueDate";
module.exports.dateTime = "dateTime";

module.exports.calorie = "calorie";             // Calorie data columns
module.exports.money = "money";                 // Currency data columns
module.exports.number = "number";               // General number
module.exports.fixedNumber = "fixedNumber";     // Integer data columns
module.exports.floatNumber = "floatNumber";     // Float data columns, other than currency
module.exports.percentage = "percentage";       // Float as percentage
module.exports.hours = "hours";                 // Time duration in hours format
module.exports.color = "color";                 // color box

module.exports.phoneNumber = "phoneNumber";

module.exports.imageLogo = "imageLogo";         // Logo columns
module.exports.imagePic = "imagePic";           // Images other than logo columns

module.exports.boolean = "boolean";             // true/false columns

module.exports.notes = "notes";                 // Notes column