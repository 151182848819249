import React, { Component } from 'react';
import { Table, Input, Button, CloseButton } from "reactstrap";
import "../../organization/storefront/deliveryCharges/styles.css";

class OperativeVatBands extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            operativeVatBands: this.props.operativeVatBands || []
        }

        this.handleAddCustomVATBand = this.handleAddCustomVATBand.bind(this);
        this.closeBtnHandler = this.closeBtnHandler.bind(this);
        this.handlePercentageChange = this.handlePercentageChange.bind(this);
    }

    handleAddCustomVATBand () {
        this.setState((prevState) => {
            const updatedBands = [...prevState.operativeVatBands, ''];
            this.props.setVatBands(updatedBands);
            return { operativeVatBands: updatedBands };
        });
    };
 
    closeBtnHandler (index)
    {
        this.setState((prevState) => {
            const updatedBands = [...prevState.operativeVatBands];
            updatedBands.splice(index, 1); 
            this.props.setVatBands(updatedBands);
            return { operativeVatBands: updatedBands };
        });
    }

    handlePercentageChange (event, index)
    {
        this.setState((prevState) => {
            const updatedBands = [...prevState.operativeVatBands];
            updatedBands[index] = event.target.value.trim();
            this.props.setVatBands(updatedBands);
            return { operativeVatBands: updatedBands };
        });
    }; 
    
    render()
    {
        return(
            <div>
                <Table
                responsive
                bordered
                size="sm"
                responsiveTag="div"
                >
                    <thead>
                        <tr className="">
                            <th></th>
                            <th style={{fontWeight: 'normal'}}>{`${this.props.vatCaption} Bands (%)`}</th>
                            <th></th> 
                        </tr>
                    </thead>

                    <tbody>
                        { 
                            this.state.operativeVatBands.map((vatBand, index) => (
                                <tr>
                                    <td style={{width: '1%'}}>{index+1}</td>
                                    <td style={{width: '98%'}}>
                                        <Input
                                            value={vatBand}
                                            style={{margin: '5px'}}
                                            onChange={(e) => {this.handlePercentageChange(e, index)}}
                                        />
                                    </td>
                                    <td>
                                        <CloseButton
                                            onClick={() => { this.closeBtnHandler(index) }}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <div className="d-flex flex-row-reverse">
                    <Button
                        color="success"
                        size="sm"
                        outline
                        children="+ Add"
                        onClick={this.handleAddCustomVATBand}
                        style={{ marginTop: "5px" }}
                    />
                </div>
            </div>
        )
    }
} 

export default OperativeVatBands;