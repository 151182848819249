

import React, { Component } from 'react';
import SelectComponent from '../../../../components/form/selectComponent';

const stationState = require('../stationState');
const FormManager = require('../../../../utils/view/formManager.js');


class SignageConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.model;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td >
                                    <SelectComponent
                                    optionValues={stationState.signagePlayers}
                                    optionFieldName="id"
                                    optionDisplayFieldName="name"
                                    caption="Choose Signage Player"
                                    fieldName="signagePlayerId"
                                    hintText="Select the signage player for this terminal (Required)"
                                    clearable={false}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                                </td>

                                <td >
                                    
                                </td>
                            </tr>

                        </tbody>
                    </ table>
                </div>
    }
}

export default SignageConfiguration;