const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');

export async function save (data)
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/operatingConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/operatingConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{
    if(validator.isPresent(data.smsFrom) && !validator.isValidSMSSenderName(data.smsFrom))
       return "SMS Sender Name invalid. Please use only letters, numbers, and up to 11 characters.";

    if(validator.isPresent(data.staffMealLimit) && !validator.isGreaterThanOrEqualToZero(data.staffMealLimit))
       return "Staff Meal Limit is not valid";

    if(validator.isPresent(data.mealShiftHours) && !validator.isGreaterThanOrEqualToZero(data.mealShiftHours))
        return "Meal Shift Hours is not valid";

    if(validator.isPresent(data.royaltyPercentage) && !validator.isGreaterThanOrEqualToZero(data.royaltyPercentage))
        return "Royalty Percentage is not valid";

    if(validator.isPresent(data.marketingPercentage) && !validator.isGreaterThanOrEqualToZero(data.marketingPercentage))
        return "Marketing Percentage is not valid";

    if(validator.isPresent(data.rewardPointConversionRatio) && !validator.isGreaterThanOrEqualToZero(data.rewardPointConversionRatio))
        return "Reward Point Conversion Ratio is not valid";

    return null;
}