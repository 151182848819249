import React, { Component } from 'react';

import GridView from '../../../../../components/gridView/gridView';
import Guest from './Guest';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../../components/gridView/gridViewOptions');
const validator = require('../../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');


class Guests extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadGuestsAsync = this.loadGuestsAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Guests";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadGuestsAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        gridViewOptions.getNewRow = () => { const newRow = { franchisorId: currentOrgNode.franchisorId }; return newRow; };
        gridViewOptions.getComponent = (isNew, row) => <Guest data={row} />;

        gridViewOptions.getComponentTitle =
            (isNewRow, row, customButton) =>
                isNewRow ? "Add New Guest" : `Edit Guest: ${row.code}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new guest.";

            return "Please wait while updating guest.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New guest added successfully";

            return "Guest updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new guest:";

            return "Following error occurred while updating guest:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    async loadGuestsAsync()
    {
        try
        {            
            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/guests`;

            const guests = await rmsApiProxy.get(resourceUrl);

            return arraySort(guests, 'guest');

        } catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.code))
            return "Guest cannot be left empty";

        return null;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/guests`;
            const updatedGuest = await rmsApiProxy.post(resourceUrl, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(guest => guest.id === updatedGuest.id);
    
            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedGuest);
            else
                remoteData.push(updatedGuest);
    
            const response = {};
            response.remoteData =  arraySort(remoteData, 'guest');
            response.addUpdatedRow = updatedGuest;    
            return response;
    
        } catch (error)
        {
            throw error
        }
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "code";
        header.headerName = "Guest";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        header.headerTooltip = "Guests eligible for free meal";
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }
}

export default Guests;
