import React, { Component } from 'react';

class RotaHelpComponent extends Component 
{
    render()
    {
        return (<table style={{width:'100%'}}>
                    <tr>
                        <td>
                                <h2 className="text-muted">Actions</h2>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="text-muted"><strong>Adding a rota</strong></div>
                            <div className="text-muted"><small>To add a new rota, double click on desired date cell</small></div>

                            <div className="text-muted" style={{marginTop:'10px'}}><strong>Adding an attendance</strong></div>
                            <div className="text-muted"><small>To add a new attendance, SHIFT key + double click on desired date cell</small></div>

                            <div className="text-muted" style={{marginTop:'10px'}}><strong>Moving a rota</strong></div>
                            <div className="text-muted"><small>To move rota, drag the rota from source date to desired date cell</small></div>

                            <div className="text-muted" style={{marginTop:'10px'}}><strong>Copy a rota</strong></div>
                            <div className="text-muted"><small>To copy rota, ALT key + drag the rota from source date to desired date cell </small></div>
                        </td>
                    </tr>

        </table>)
    }
}

export default RotaHelpComponent;