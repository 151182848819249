const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');
const constants = require('../../../../../utils/domain/constants');

module.exports.save = function (storefront, orderIngest)
{
    var orderIngestResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/orderIngests`;
    return rmsApiProxy.post(orderIngestResourceUrl, orderIngest);
}

module.exports.loadStorefrontOrderIngest = function(storefront)
{
    var storefrontResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/orderIngests`;
    return rmsApiProxy.get(storefrontResourceUrl);
}

module.exports.delete = function (storefrontId)
{
    var orderIngestResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefrontId}/configurations/orderIngests`;
    return rmsApiProxy.deleted(orderIngestResourceUrl);
}

module.exports.validateOrderIngest = function (orderIngest)
{
    if(stringUtil.isStringNullOrEmpty(orderIngest.type))
        return 'Type is not selected!';

    if (stringUtil.isStringNullOrEmpty(orderIngest.referencePriceDispatchType))
        return 'referencePriceDispatchType is not selected!';

    if (!Object.values(constants.dispatchTypes).includes(orderIngest.referencePriceDispatchType))
        return 'referencePriceDispatchType is invalid';

        
    if(stringUtil.areStringSame(orderIngest.type, constants.channels.UrbanPiper))
    {
        if(stringUtil.isStringNullOrEmpty(orderIngest.bizId))
            return 'Urban Piper Biz ID cannot be left empty!';

        if(stringUtil.isStringNullOrEmpty(orderIngest.posId))
            return 'Urban Piper POS ID cannot be left empty!';

        if(stringUtil.isStringNullOrEmpty(orderIngest.apiKey))
            return 'Urban Piper API Key cannot be left empty!';
    }

    if(stringUtil.areStringSame(orderIngest.type, constants.channels.Deliverect))
    {
        if(stringUtil.isStringNullOrEmpty(orderIngest.accountId))
            return 'Deliverect Account ID cannot be left empty!';

        if(stringUtil.isStringNullOrEmpty(orderIngest.locationId))
            return 'Deliverect Location ID cannot be left empty!';
    }

    if(stringUtil.areStringSame(orderIngest.type, constants.channels.Flipdish))
    {
        if(stringUtil.isStringNullOrEmpty(orderIngest.appId))
            return 'Flipdish App ID cannot be left empty!';

        if(stringUtil.isStringNullOrEmpty(orderIngest.storeId))
            return 'Flipdish Store ID cannot be left empty!';
    }
    
    return null;
}