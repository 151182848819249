var actionTypes = require('../../../actionTypes.js');

module.exports.createAction = function(payload)
{
    return (
            {
                type: actionTypes.topBarOption,
                payload: payload
            }
           );
}

module.exports.changeState = function (previousState, payload)
{
    return Object.assign({}, previousState, {topBarSelectedOption: payload});
}