import React, { Component } from "react";
import InputComponent from '../../../../components/form/inputComponent';
import ColorPickerComponent from '../../../../components/form/colorPickerComponent';
import FormManager from '../../../../utils/view/formManager';

export default class Category extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = {};

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }
    render() 
    {
        return (

            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="name"
                                caption="Name"
                                fieldName="name"
                                placeholder="Enter category name"
                                hintText="Required"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            <ColorPickerComponent
                                fieldName="foregroundColor"
                                placeholder="Colour"
                                caption="Choose foreground color"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager}
                            />
                        </td>
                        <td>
                            <ColorPickerComponent
                                fieldName="backgroundColor"
                                placeholder="Colour"
                                caption="Choose background color"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                multiLine={true} rows={4}
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                </tbody>

            </table>
        );
    }
}
