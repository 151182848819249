import React, { useState } from "react";
import Select from "react-select";

const SelectField = ({ defaultValue, name, options = [], isClearable = false, required = false, onValueChange = () => {}, ...props }) => {
     const [selectedValue, setSelectedValue] = useState(options.find(({ value }) => value === defaultValue));

     const handleValueChange = (option) => {
          onValueChange(option?.value);
          setSelectedValue(option);
     };

     return (
          <div>
               <input type="hidden" name={name} value={selectedValue?.value ?? ""} required={required} />
               <Select options={options} required={required} {...props} onChange={handleValueChange} isClearable={isClearable} value={selectedValue} />
               {/* {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null} */}
          </div>
     );
};

export default SelectField;
