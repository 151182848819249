import React, { Component } from 'react';
import { Button } from 'reactstrap';

import PurchaseOrderNotes from './PurchaseOrderNotes';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';

const viewUtil = require('../../../../../../utils/view/viewUtil');
const formatter = require('../../../../../../utils/formatter/formatter');
const localizationUtils = require('../../../../../../utils/domain/localizationUtils');

export default class PurchaseOrderFooter extends Component
{
    constructor(props)
    {
        super(props);

        this.onShowNotes = this.onShowNotes.bind(this);
    }

    get isReadOnly() { return !purchaseOrderUtil.isUserAuthorizedForAnyAction(); }

    onShowNotes()
    {
        const purchaseOrder = this.props.data;

        const orderNotes = purchaseOrder.notes;
        const receiptNotes = purchaseOrder.receiptNotes;

        const onCancel = () => 
        {
            // Restore original values on cancel
            purchaseOrder.notes = orderNotes;
            purchaseOrder.receiptNotes = receiptNotes
        };

        const onSave = () =>
        {
            // Don't have to do anything
        };

        viewUtil.openModalForm(
            "Notes",
            () => <PurchaseOrderNotes data={purchaseOrder} lookupData={this.props.lookupData} />,   // componentCreator
            onSave, null, this.isReadOnly, null, onCancel
        );
    }

    render()
    {
        return (
            <div className="d-flex justify-content-between align-items-center">

                <div className="me-1">
                    <Button
                        color="success"
                        onClick={this.onShowNotes}>
                        <i className="fa fa-sticky-note-o"></i>&nbsp;Notes
                    </Button>
                </div>

                {this.renderOrderTotal()}
                
            </div>
        );
    }

    renderOrderTotal()
    {
        const purchaseOrder = this.props.data;
        const { totalPrice, totalAmount, totalVat } = purchaseOrderUtil.getApplicablePurchaseOrderTotalPrice(purchaseOrder);
        const grossTotalLine = formatter.convertToCurrencyFormat(totalPrice);
        const totalAmountLine = formatter.convertToCurrencyFormat(totalAmount);
        const totalVatLine = formatter.convertToCurrencyFormat(totalVat);
        const vatCaption = localizationUtils.getVatCaption();

        return (<table>
            <tr>
                <td>
                    Total Amount:&nbsp;
                </td>
                <td style={{ paddingRight: '20px' }}>
                    {totalAmountLine}
                </td>
                <td>
                    Total {vatCaption}:&nbsp;
                </td>
                <td style={{ paddingRight: '20px' }}>
                    {totalVatLine}
                </td>
                <td style={{ fontWeight: 'bold' }}>
                    Total Gross Amount:&nbsp;
                </td>
                <td style={{ paddingRight: '30px', fontWeight: 'bold' }}>
                    {grossTotalLine}
                </td>
            </tr>
        </table>);
    }
}