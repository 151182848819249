import React, { Component } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { DroppableContainer } from '../../utils/components/dndComponents';
import ActionItemComponent from './actionItemComponent';

class ActionListComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {}
    }

    render()
    {
        const taskActions = this.props.taskActions;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>

                <div className="d-flex flex-column flex-nowrap">

                    <div className="d-flex flex-row flex-nowrap"
                        style={{ backgroundColor: "SlateGrey", color: "white", fontSize: "14px", fontWeight: "bold" }}>
                        <div style={{ width: "10%" }} className="border px-2 py-2"></div>
                        <div style={{ width: "35%" }} className="border px-2 py-2">Action Names</div>
                        <div style={{ width: "20%" }} className="border px-2 py-2">Is Compulsory</div>
                        <div style={{ width: "35%" }} className="border px-2 py-2">Hazard Types</div>
                    </div>

                    <Droppable droppableId="tasks">
                        {
                            (provided, snapshot) =>
                            (
                                <DroppableContainer
                                    isRowReadOnly={this.props.isRowReadOnly}
                                    ref={provided.innerRef}
                                    isDraggingOver={snapshot.isDraggingOver}
                                    {...provided.droppableProps}>

                                    {
                                        taskActions.map((taskAction, index) =>
                                            <ActionItemComponent key={taskAction.name} taskAction={taskAction} index={index} {...this.props} />)
                                    }

                                    {provided.placeholder}

                                </DroppableContainer>
                            )
                        }
                    </Droppable>

                    <div style={{ fontSize: "12px", color: "darkgray", margin: "10px 0px", textAlign: "center" }}>
                        Use drag-n-drop to reorder task actions list &nbsp;&nbsp;
                    </div>

                </div>

            </DragDropContext >
        );
    }

    onDragEnd = result =>
    {
        const { source, destination, type, mode, draggableId, reason } = result;

        if (!destination)
        {
            // Implies drop outside of drop zone
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index)
        {
            // Implies no change
            return;
        }

        const taskActions = this.props.taskActions;

        const [taskAction] = taskActions.splice(source.index, 1);
        taskActions.splice(destination.index, 0, taskAction);

        this.setState({});
    };
}

export default ActionListComponent;