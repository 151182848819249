
const constants = require('../../../../../utils/domain/constants');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const brandApiFacade = require('../../../../../utils/api/brandApiFacade');
const stringUtil = require('../../../../../utils/string/stringUtil');

export function validateStorefront(storefront)
{
    if (stringUtil.isStringNullOrEmpty(storefront.name))
        return 'Name cannot be left empty!';

    if (stringUtil.isStringNullOrEmpty(storefront.type))
        return 'Type is not selected!';

    if (stringUtil.isStringNullOrEmpty(storefront.menuId))
        return 'Menu is not selected!';

    if (stringUtil.isStringNullOrEmpty(storefront.brandId))
        return 'Brand is not selected!';

    if (stringUtil.isStringNullOrEmpty(storefront.priceBandId))
        return 'Price Band is not selected!';

    return null;
}

export async function save(storefront)
{
    var storefrontResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts`;
    return await rmsApiProxy.post(storefrontResourceUrl, storefront);
}

export async function loadBrands()
{
    return await brandApiFacade.loadBrands();
}