import { generateGuid } from "../../../../utils/guid/guidUtil";
import { DeviceTypes, IntegrationTypes, LabelPrinterTypes, ReceiptPrinterTypes, TerminalTypes } from "../constants";
import { generateStationCode, getOrgContext } from "../networkDiagramUtil";
import { isInteger } from "../../../../utils/validator/validator";

function DiagramToCallConverter(diagramState) {
     const orgContext = getOrgContext();
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     const { stations, devices } = diagramState;
     const shells = [];
     const terminals = [];
     const terminalsIntegrations = {};

     for (let sIdx = 0; sIdx < stations.length; sIdx++) {
          const station = stations[sIdx];

          const shell = generateShell(orgContext, station?.shell, station);

          const terminal = generateTerminal(orgContext, station?.terminal, shell.code, station, devices, stations);

          shell["terminals"].push(terminal.id);

          const hardwareTerminal = generateHardwareTerminal(orgContext, station?.hardwareTerminal, shell.code);
          shell["terminals"].push(hardwareTerminal.id);

          const kotsDevices = devices.filter((device) => device.type === DeviceTypes.kotPrinter);
          for (let dIdx = 0; dIdx < devices.length; dIdx++) {
               const device = devices[dIdx];

               if (device.stationId === station.id) {
                    if (device.type === DeviceTypes.kotPrinter) continue;

                    if (device.type === DeviceTypes.telephoneCli) {
                         hardwareTerminal["profile"]["settings"]["displaySignage"] = device.configuration.displaySignage;
                         hardwareTerminal["profile"]["settings"]["telephoneLineEnabled"] = true;
                         continue;
                    }
                    const integration = generateIntegration(orgContext, device?.integration, device, terminal, hardwareTerminal);

                    switch (device.type) {
                         case DeviceTypes.receiptPrinter:
                              integration["externalEntity"] = device.configuration.receiptPrinterType;
                              integration["jsonArray"].push({
                                   key: "printerName",
                                   value: device.deviceName,
                              });
                              setIntegrationData(kotsDevices, integration, franchisorId, franchiseeId, propertyId, hardwareTerminal, device, terminalsIntegrations, "ReceiptPrinter");

                              switch (device.configuration.receiptPrinterType) {
                                   case ReceiptPrinterTypes.genericUSB: {
                                        integration["jsonArray"].push({
                                             key: "systemName",
                                             value: device.configuration.systemName,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];
                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });

                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        integration["jsonArray"].push({
                                             key: "noOfColumns",
                                             value: device.configuration.noOfColumns,
                                        });
                                        integration["jsonArray"].push({
                                             key: "logoRequireCentering",
                                             value: device.configuration.logoRequireCentering,
                                        });
                                        integration["jsonArray"].push({
                                             key: "logoSize",
                                             value: device.configuration.logoSize,
                                        });
                                        integration["jsonArray"].push({
                                             key: "logoHexCode",
                                             value: device.configuration.logoHexCode,
                                        });
                                        integration["jsonArray"].push({
                                             key: "logoFilePath",
                                             value: device.configuration.logoFilePath,
                                        });

                                        break;
                                   }
                                   case ReceiptPrinterTypes.genericBluetooth: {
                                        integration["jsonArray"].push({
                                             key: "bluetoothAddress",
                                             value: device.configuration.bluetoothAddress,
                                        });

                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];
                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });
                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        break;
                                   }
                                   case ReceiptPrinterTypes.genericNetwork: {
                                        integration["jsonArray"].push({
                                             key: "ipAddress",
                                             value: device.configuration.ipAddress,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];

                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });
                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        break;
                                   }
                                   case ReceiptPrinterTypes.sunmiCloudPrinter: {
                                        integration["jsonArray"].push({
                                             key: "serialNumber",
                                             value: device.configuration.serialNumber,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];

                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });
                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        break;
                                   }
                                   default:
                                        break;
                              }

                              break;
                         case DeviceTypes.labelPrinter: {

                              normalizeLabelPrinterColumnSizeProperties(device);

                              integration["externalEntity"] = device.configuration.labelPrinterType;

                              integration["jsonArray"].push({
                                   key: "printerName",
                                   value: device.deviceName,
                              });

                              setIntegrationData(kotsDevices, integration, franchisorId, franchiseeId, propertyId, hardwareTerminal, device, terminalsIntegrations, "LabelPrinter");

                              switch (device.configuration.labelPrinterType) {
                                   case LabelPrinterTypes.genericUSB: {
                                        integration["jsonArray"].push({
                                             key: "systemName",
                                             value: device.configuration.systemName,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];

                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });

                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        integration["jsonArray"].push({
                                             key: "normalFontAColumnSize",
                                             value: device.configuration.normalFontAColumnSize,
                                        });

                                        integration["jsonArray"].push({
                                             key: "condensedFontBColumnSize",
                                             value: device.configuration.condensedFontBColumnSize,
                                        });

                                        break;
                                   }
                                   case LabelPrinterTypes.zplGenericNetwork:
                                        integration["jsonArray"].push({
                                             key: "ipAddress",
                                             value: device.configuration.ipAddress,
                                        });
                                        integration["jsonArray"].push({
                                             key: "itemLabelPrintingTemplate",
                                             value: device.configuration.itemLabelPrintingTemplate
                                        })
                                        integration["jsonArray"].push({
                                             key: "orderLabelPrintingTemplate",
                                             value: device.configuration.orderLabelPrintingTemplate
                                        })
                                        integration["jsonArray"].push({
                                             key: "kotLabelPrintingTemplate",
                                             value: device.configuration.kotLabelPrintingTemplate
                                        })
                                        integration["jsonArray"].push({
                                             key: "expiryLabelPrintingTemplate",
                                             value: device.configuration.expiryLabelPrintingTemplate
                                        })
                                        break;
                                   case LabelPrinterTypes.zplGenericUSB:
                                        integration["jsonArray"].push({
                                             key: "systemName",
                                             value: device.configuration.systemName,
                                        });
                                        integration["jsonArray"].push({
                                             key: "itemLabelPrintingTemplate",
                                             value: device.configuration.itemLabelPrintingTemplate
                                        })
                                        integration["jsonArray"].push({
                                             key: "orderLabelPrintingTemplate",
                                             value: device.configuration.orderLabelPrintingTemplate
                                        })
                                        integration["jsonArray"].push({
                                             key: "kotLabelPrintingTemplate",
                                             value: device.configuration.kotLabelPrintingTemplate
                                        })
                                        integration["jsonArray"].push({
                                             key: "expiryLabelPrintingTemplate",
                                             value: device.configuration.expiryLabelPrintingTemplate
                                        })
                                        break;
                                   case LabelPrinterTypes.genericBluetooth: {
                                        integration["jsonArray"].push({
                                             key: "bluetoothAddress",
                                             value: device.configuration.bluetoothAddress,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];

                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });
                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        integration["jsonArray"].push({
                                             key: "normalFontAColumnSize",
                                             value: device.configuration.normalFontAColumnSize,
                                        });

                                        integration["jsonArray"].push({
                                             key: "condensedFontBColumnSize",
                                             value: device.configuration.condensedFontBColumnSize,
                                        });

                                        break;
                                   }
                                   case LabelPrinterTypes.genericNetwork: {
                                        integration["jsonArray"].push({
                                             key: "ipAddress",
                                             value: device.configuration.ipAddress,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];

                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });
                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        integration["jsonArray"].push({
                                             key: "normalFontAColumnSize",
                                             value: device.configuration.normalFontAColumnSize,
                                        });

                                        integration["jsonArray"].push({
                                             key: "condensedFontBColumnSize",
                                             value: device.configuration.condensedFontBColumnSize,
                                        });

                                        break;
                                   }
                                   case LabelPrinterTypes.sunmiCloudPrinter: {
                                        integration["jsonArray"].push({
                                             key: "serialNumber",
                                             value: device.configuration.serialNumber,
                                        });
                                        const [encodingType, encodingInstructions] = device.configuration.encodingType !== null ? device.configuration.encodingType.split(" / ") : [null, null];
                                        integration["jsonArray"].push({
                                             key: "encodingType",
                                             value: encodingType,
                                        });
                                        integration["jsonArray"].push({
                                             key: "encodingInstructions",
                                             value: encodingInstructions,
                                        });
                                        integration["jsonArray"].push({
                                             key: "isLegacyPrinter",
                                             value: device.configuration.isLegacyPrinter,
                                        });

                                        integration["jsonArray"].push({
                                             key: "normalFontAColumnSize",
                                             value: device.configuration.normalFontAColumnSize,
                                        });

                                        integration["jsonArray"].push({
                                             key: "condensedFontBColumnSize",
                                             value: device.configuration.condensedFontBColumnSize,
                                        });
                                        break;
                                   }

                                   case LabelPrinterTypes.brotherQL700Usb: {
                                        integration["jsonArray"].push({
                                             key: "systemName",
                                             value: device.configuration.systemName,
                                        });

                                        break;
                                   }
                                   case LabelPrinterTypes.brotherQL800Usb: {
                                        integration["jsonArray"].push({
                                             key: "systemName",
                                             value: device.configuration.systemName,
                                        });

                                        break;
                                   }
                                   case LabelPrinterTypes.brotherQL820Bluetooth: {
                                        integration["jsonArray"].push({
                                             key: "bluetoothAddress",
                                             value: device.configuration.bluetoothAddress,
                                        });

                                        break;
                                   }
                                   case LabelPrinterTypes.brotherQL820Network: {
                                        integration["jsonArray"].push({
                                             key: "ipAddress",
                                             value: device.configuration.ipAddress,
                                        });

                                        break;
                                   }
                                   default:
                                        break;
                              }
                              break;
                         }
                         case DeviceTypes.creditCardReader: {
                              integration["externalEntity"] = device.configuration.creditType;
                              if (station.type === TerminalTypes.pos)
                              {
                                   terminal.profile.settings.cardSettings = { ...terminal.profile.settings.cardSettings, provider: device.configuration.creditType };
                              }
                              else
                              {
                                   terminal.profile.settings.cardProviderName = device.configuration.creditType;
                              }
                              switch (device.configuration.creditType) {
                                   case "HandPoint":
                                        integration["jsonArray"].push({
                                             key: "url",
                                             value: device.configuration.url,
                                        });
                                        integration["jsonArray"].push({
                                             key: "apiKey",
                                             value: device.configuration.apiKey,
                                        });
                                        integration["jsonArray"].push({
                                             key: "terminalId",
                                             value: device.configuration.terminalId,
                                        });

                                        break;
                                   case "StripeReader":
                                        integration["jsonArray"].push({
                                             key: "locationId",
                                             value: device.configuration.locationId,
                                        });
                                        integration["jsonArray"].push({
                                             key: "readerId",
                                             value: device.configuration.readerId,
                                        });

                                        break;
                                   case "PaymentSense":
                                        integration["jsonArray"].push({
                                             key: "url",
                                             value: device.configuration.url,
                                        });
                                        integration["jsonArray"].push({
                                             key: "apiKey",
                                             value: device.configuration.apiKey,
                                        });
                                        integration["jsonArray"].push({
                                             key: "terminalId",
                                             value: device.configuration.terminalId,
                                        });

                                        break;
                                   case "WorldPay":
                                        integration["jsonArray"].push({
                                             key: "ipcStatusPort",
                                             value: device.configuration.ipcStatusPort,
                                        });
                                        integration["jsonArray"].push({
                                             key: "ipcMessagingPort",
                                             value: device.configuration.ipcMessagingPort,
                                        });
                                        integration["jsonArray"].push({
                                             key: "ipcHost",
                                             value: device.configuration.ipcHost,
                                        });
                                        integration["jsonArray"].push({
                                             key: "merchantReceiptFileName",
                                             value: device.configuration.merchantReceiptFileName,
                                        });

                                        break;
                                   case "Offline":
                                        break;

                                   default:
                                        break;
                              }

                              break;
                         }
                         default:
                              break;
                    }

                    if (!terminalsIntegrations[integration.systemId]) {
                         terminalsIntegrations[integration.systemId] = [];
                         terminalsIntegrations[integration.systemId].push(integration);
                    } else {
                         terminalsIntegrations[integration.systemId].push(integration);
                    }
               }
          }

          terminals.push(terminal);
          terminals.push(hardwareTerminal);
          shells.push(shell);
     }

     return {
          shells,
          terminals,
          terminalsIntegrations,
     };
}

export default DiagramToCallConverter;

function setIntegrationData(kotsDevices, integration, franchisorId, franchiseeId, propertyId, hardwareTerminal, device, terminalsIntegrations, externalEntity) 
{
     const connectedKotDevices = kotsDevices.filter((kotDevice) => kotDevice.stationId === integration.id);
     for (let cKDIdx = 0; cKDIdx < connectedKotDevices.length; cKDIdx++) {
          const kot = connectedKotDevices[cKDIdx];

          const integration = {
               ...kot?.integration,
               franchisorId,
               franchiseeId,
               propertyId,
               integrationType: generateIntegrationType(kot.type),
               isEnabled: true,
               id: kot.id,
               systemType: "Terminal",
               systemId: hardwareTerminal.id,
               jsonArray: [],
               externalEntity: externalEntity,
          };

          integration["jsonArray"].push({
               key: "copiesToPrint",
               value: kot.configuration.copiesToPrint,
          });
          integration["jsonArray"].push({
               key: "kitchenStation",
               value: kot.configuration.kitchenStationId,
          });
          integration["jsonArray"].push({
               key: "receiptPrinter",
               value: device.deviceName,
          });
          integration["jsonArray"].push({
               key: "storefronts",
               value: kot.configuration.storefronts,
          });
          integration["jsonArray"].push({
               key: "dispatchTypes",
               value: kot.configuration.dispatchTypes,
          });

          if (!terminalsIntegrations[integration.systemId]) {
               terminalsIntegrations[integration.systemId] = [];
               terminalsIntegrations[integration.systemId].push(integration);
          } else {
               terminalsIntegrations[integration.systemId].push(integration);
          }
     }
}

function generateShell(orgContext, existingShell, station) {
     const shell = { ...existingShell };
     const { franchisorId, franchiseeId, propertyId } = orgContext;

     shell.code = existingShell?.code ?? station.configuration.shellCode;
     shell.name = station.stationName;
     shell.licenseKey = station.configuration.licenseKey;
     shell.isEnabled = existingShell?.isEnabled ?? true;
     shell.terminals = [];

     shell.shellType = station.configuration.hostType;
     shell.shellRenderingMode = station.configuration.renderingMode;
     shell.shellScreenOrientation = station.configuration.screenOrientation;

     shell.machineIPAddress = existingShell?.machineIPAddress ?? "";
     shell.machineId = existingShell?.machineId ?? "";
     shell.operatingSystem = existingShell?.operatingSystem ?? "";
     shell.operatingSystemVersion = existingShell?.operatingSystemVersion ?? "";

     shell.franchisorId = franchisorId;
     shell.franchiseeId = franchiseeId;
     shell.propertyId = propertyId;

     return shell;
}

function generateHardwareTerminal(orgContext, existingHardwareTerminal, shellCode) {
     const hardwareTerminal = { ...existingHardwareTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     hardwareTerminal.code = `${shellCode}.hardware`;
     hardwareTerminal.enabled = true;
     hardwareTerminal.id = existingHardwareTerminal?.id ?? generateGuid();
     hardwareTerminal.type = "hardware";
     hardwareTerminal.userName = `${shellCode}.user`;
     hardwareTerminal.floatIdentifier = existingHardwareTerminal?.floatIdentifier ?? null;
     hardwareTerminal.franchisorId = franchisorId;
     hardwareTerminal.franchiseeId = franchiseeId;
     hardwareTerminal.propertyId = propertyId;

     hardwareTerminal.profile = existingHardwareTerminal?.profile ?? {};
     hardwareTerminal.profile.settings = existingHardwareTerminal?.profile?.settings ?? {};

     hardwareTerminal.profile.settings.displaySignage = true;
     hardwareTerminal.profile.settings.telephoneLineEnabled = false;
     hardwareTerminal.profile.settings.stationCode = null;

     return hardwareTerminal;
}

function generatePOSTerminal(orgContext, existingTerminal, shellCode, station, devices, stations) {
     const terminal = { ...existingTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     terminal.code = `${shellCode}.pos`;
     terminal.enabled = true;
     terminal.id = existingTerminal?.id ?? generateGuid();
     terminal.storefrontId = station.configuration.storeFrontId;
     terminal.type = station.type;
     terminal.userName = `${shellCode}.user`;
     terminal.floatIdentifier = station.configuration.floatIdentifier;
     terminal.franchisorId = franchisorId;
     terminal.franchiseeId = franchiseeId;
     terminal.propertyId = propertyId;

     terminal.profile = existingTerminal?.profile ?? {};

     terminal.profile.settings = existingTerminal?.profile?.settings ?? {};
     terminal.profile.settings.cardProviderName = null;

     terminal.profile.settings.displaySettings = existingTerminal?.profile?.settings?.displaySettings ?? {};
     terminal.profile.settings.displaySettings.signagePlayerId = station.configuration.signagePlayerId;

     return terminal;
}

function generateKIOSKTerminal(orgContext, existingTerminal, shellCode, station, devices, stations) {
     const terminal = { ...existingTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     terminal.code = `${shellCode}.kiosk`;
     terminal.enabled = true;
     terminal.id = existingTerminal?.id ?? generateGuid();
     terminal.storefrontId = station.configuration.storeFrontId;
     terminal.type = station.type;
     terminal.userName = `${shellCode}.user`;
     terminal.franchisorId = franchisorId;
     terminal.franchiseeId = franchiseeId;
     terminal.propertyId = propertyId;

     terminal.profile = existingTerminal?.profile ?? {};

     terminal.profile.settings = existingTerminal?.profile?.settings ?? {};
     terminal.profile.settings.cardProviderName = null;

     terminal.profile.settings.displaySettings = existingTerminal?.profile?.displaySettings ?? {};
     terminal.profile.settings.displaySettings.skinId = station.configuration.skinId;

     terminal.profile.settings.receiptPrinter = existingTerminal?.profile?.receiptPrinter ?? {};
     terminal.profile.settings.receiptPrinter.url = generateUrlThroughDeviceId(station.configuration.receiptPrinterId, stations, devices);

     return terminal;
}
function generateKDSTerminal(orgContext, existingTerminal, shellCode, station, devices, stations) {
     const terminal = { ...existingTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     terminal.code = `${shellCode}.kds`;
     terminal.enabled = true;
     terminal.id = existingTerminal?.id ?? generateGuid();
     terminal.storefrontId = station.configuration.storeFrontId;
     terminal.type = station.type;
     terminal.userName = `${shellCode}.user`;
     terminal.franchisorId = franchisorId;
     terminal.franchiseeId = franchiseeId;
     terminal.propertyId = propertyId;

     terminal.profile = existingTerminal?.profile ?? {};

     terminal.profile.settings = existingTerminal?.profile?.settings ?? {};
     terminal.profile.settings.defaultKitchenStation = existingTerminal?.profile?.settings?.defaultKitchenStation ?? null;
     terminal.profile.settings.fohOrderPrintFormat = station.configuration.labelFormat;
     terminal.profile.settings.fohPrinter = generateUrlThroughDeviceId(station.configuration.labelPrinterId, stations, devices);
     terminal.profile.settings.kitchenStations = existingTerminal?.profile.settings.kitchenStations ?? [];
     terminal.profile.settings.storefronts = existingTerminal?.profile.settings.storefronts ?? [];

     return terminal;
}
function generateKitchenTerminal(orgContext, existingTerminal, shellCode, station, devices, stations) {
     const terminal = { ...existingTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     terminal.code = `${shellCode}.kitchen`;
     terminal.enabled = true;
     terminal.id = existingTerminal?.id ?? generateGuid();
     terminal.storefrontId = station.configuration.storeFrontId;
     terminal.type = station.type;
     terminal.userName = `${shellCode}.kitchen`;
     terminal.franchisorId = franchisorId;
     terminal.franchiseeId = franchiseeId;
     terminal.propertyId = propertyId;

     terminal.profile = existingTerminal?.profile ?? {};

     terminal.profile.settings = existingTerminal?.profile?.settings ?? {};
     terminal.profile.settings.labelPrinter = existingTerminal?.profile?.settings?.labelPrinter ?? {};
     terminal.profile.settings.labelPrinter.url = generateUrlThroughDeviceId(station.configuration.labelPrinterId, stations, devices);

     return terminal;
}
function generateCentralKitchenTerminal(orgContext, existingTerminal, shellCode, station, devices) {
     const terminal = { ...existingTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     terminal.code = `${shellCode}.central.kitchen`;
     terminal.enabled = true;
     terminal.id = existingTerminal?.id ?? generateGuid();
     terminal.storefrontId = station.configuration.storeFrontId;
     terminal.type = station.type;
     terminal.userName = `${shellCode}.central.kitchen`;
     terminal.franchisorId = franchisorId;
     terminal.franchiseeId = franchiseeId;
     terminal.propertyId = propertyId;

     terminal.profile = existingTerminal?.profile ?? {};
     terminal.profile.settings = existingTerminal?.profile?.settings ?? {};

     return terminal;
}
function generateSignageTerminal(orgContext, existingTerminal, shellCode, station, devices) {
     const terminal = { ...existingTerminal };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     terminal.code = `${shellCode}.signage`;
     terminal.enabled = true;
     terminal.id = existingTerminal?.id ?? generateGuid();
     terminal.storefrontId = station.configuration.storeFrontId;
     terminal.type = station.type;
     terminal.userName = `${shellCode}.user`;
     terminal.franchisorId = franchisorId;
     terminal.franchiseeId = franchiseeId;
     terminal.propertyId = propertyId;

     terminal.profile = existingTerminal?.profile ?? {};

     terminal.profile.settings = existingTerminal?.profile?.settings ?? {};
     terminal.profile.settings.displaySettings = existingTerminal?.profile?.settings?.displaySettings ?? {};
     terminal.profile.settings.displaySettings.signagePlayerId = station.configuration.signagePlayerId;

     return terminal;
}

function generateTerminal(orgContext, existingTerminal, shellCode, station, devices, stations) {
     if (station.type === TerminalTypes.pos) return generatePOSTerminal(orgContext, existingTerminal, shellCode, station, devices, stations);

     if (station.type === TerminalTypes.kiosk) return generateKIOSKTerminal(orgContext, existingTerminal, shellCode, station, devices, stations);

     if (station.type === TerminalTypes.kds) return generateKDSTerminal(orgContext, existingTerminal, shellCode, station, devices, stations);

     if (station.type === TerminalTypes.kitchen) return generateKitchenTerminal(orgContext, existingTerminal, shellCode, station, devices, stations);

     if (station.type === TerminalTypes.centralKitchen) return generateCentralKitchenTerminal(orgContext,existingTerminal, shellCode, station, devices, stations);

     if (station.type === TerminalTypes.signage) return generateSignageTerminal(orgContext, existingTerminal, shellCode, station, devices, stations);
}

function generateUrlThroughDeviceId(deviceId, stations, devices) {
     if (!deviceId) return null;
     const foundedDevice = devices.find((device) => device.id === deviceId);
     if (!foundedDevice) return null;
     const deviceStation = stations.find((station) => station.id === foundedDevice.stationId);
     if (!deviceStation) return null;
     return `${deviceStation?.shell?.code ?? generateStationCode(deviceStation.configuration.licenseKey)}.hardware / ${foundedDevice.deviceName}`;
}

function generateIntegrationType(type) {
     if (type === DeviceTypes.receiptPrinter) return IntegrationTypes.ReceiptPrinterManagement;
     if (type === DeviceTypes.labelPrinter) return IntegrationTypes.LabelPrinterManagement;
     if (type === DeviceTypes.creditCardReader) return IntegrationTypes.CardPaymentManagement;
     if (type === DeviceTypes.kotPrinter) return IntegrationTypes.KitchenStationPrinterManagement;
     throw new Error("Integration Type Unknown");
}

function generateIntegration(orgContext, existingIntegration, device, terminal, hardwareTerminal) {
     const integration = { ...existingIntegration };
     const { franchisorId, franchiseeId, propertyId } = orgContext;
     integration.id = device.id;
     integration.franchisorId = franchisorId;
     integration.franchiseeId = franchiseeId;
     integration.propertyId = propertyId;

     integration.integrationType = existingIntegration?.integrationType ?? generateIntegrationType(device.type);
     integration.isEnabled = true;
     integration.systemType = "Terminal";
     integration.systemId = existingIntegration?.systemId ?? (device.type === DeviceTypes.creditCardReader && device.configuration.creditType !== "WorldPay" ? terminal.id : hardwareTerminal.id);

     integration.jsonArray = device.type === "UNKNOWN" ? existingIntegration?.jsonArray : [];

     return integration;
}

function normalizeLabelPrinterColumnSizeProperties(device)
{
    // Normalizes column size properties for generic ESC/POS label printers

    if (device.type !== DeviceTypes.labelPrinter) return;

    if (device.configuration.labelPrinterType !== LabelPrinterTypes.genericUSB &&
        device.configuration.labelPrinterType !== LabelPrinterTypes.genericBluetooth &&
        device.configuration.labelPrinterType !== LabelPrinterTypes.genericNetwork &&
        device.configuration.labelPrinterType !== LabelPrinterTypes.sunmiCloudPrinter)
    {
        return;
    }

    if (!isInteger(device.configuration.normalFontAColumnSize))
    {
        device.configuration.normalFontAColumnSize = null;
    }

    if (!isInteger(device.configuration.condensedFontBColumnSize))
    {
        device.configuration.condensedFontBColumnSize = null;
    }
}