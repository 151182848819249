import React, { Component } from 'react';

import InputComponent from '../../../../components/form/inputComponent.js'
import CheckComponent from '../../../../components/form/checkComponent.js'


var stringUtil = require('../../../../utils/string/stringUtil.js');
var dateUtil = require('../../../../utils/dateUtil/dateUtil.js');

var FormManager = require('../../../../utils/view/formManager.js');

const rotaUtil = require('./RotaUtil');

class AttendanceDialogue extends Component 
{

    constructor(props) 
    {
        super(props);

        this.renderSystemGeneratedAttendance = this.renderSystemGeneratedAttendance.bind(this);
        this.renderUserEnteredAttendance = this.renderUserEnteredAttendance.bind(this);

        this.state = 
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.state.formManager.onFieldChanged = (event) =>{
                                                                if (this.props.onChange)
                                                                    this.props.onChange();
                                                          }
    }

    get isReadOnly() { return !rotaUtil.isEditingAllowed(); }

    render() 
    {   
        if (this.props.data.isSystemGenerated)
            return this.renderSystemGeneratedAttendance();

        return this.renderUserEnteredAttendance();
    
    }

    renderSystemGeneratedAttendance()
    {
        this.state.formManager.viewModel.formattedActualStartDateTime = null;
        this.state.formManager.viewModel.formattedActualEndDateTime = null;

        //For system generated, we will always have start 
        this.state.formManager.viewModel.formattedActualStartDateTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(this.state.formManager.viewModel.start), "ddd DD/MMM HH:mm");

        if (!stringUtil.isStringNullOrEmpty(this.state.formManager.viewModel.end))
            this.state.formManager.viewModel.formattedActualEndDateTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(this.state.formManager.viewModel.end), "ddd DD/MMM HH:mm");


        var hintTextForActualTo = "Date / Time clocked-out by user";
        if (this.state.formManager.viewModel.hasSystemClockedOut)
            hintTextForActualTo = "Forced clock-out by system";

        return(
            <table width="100%">

                <tr>
                    <td width="50%">
                        <InputComponent inputType="time" caption="Actual From" hintText="Date / Time clocked-in by user" 
                                        style={{marginRight:'20px'}} 
                                        fieldName="formattedActualStartDateTime" 
                                        inputReadOnly={true}
                                        formManager={this.state.formManager}/>
                    </td>
                    <td width="50%">
                        <InputComponent inputType="time" caption="Actual To" hintText={hintTextForActualTo}
                                        style={{marginRight:'20px'}} 
                                        fieldName="formattedActualEndDateTime" 
                                        inputReadOnly={true}
                                        formManager={this.state.formManager}/>
                    </td>
                </tr>

                <tr>
                    <td width="50%">
                        <CheckComponent caption="Is Void" hintText="Indicates if this entry is voided." 
                                        style={{marginLeft:'20px'}} 
                                        fieldName="isVoided"
                                        inputReadOnly={this.isReadOnly} 
                                        formManager={this.state.formManager}/>
                    </td>
                </tr>
                <tr>
                    <td width="50%" valign="top">
                        <InputComponent inputReadOnly={this.state.formManager.viewModel.isVoided || this.isReadOnly} inputType="24Hours" multiLine={true} caption="Adjusted From" rows={4}
                                        fieldName="adjustedStart" formManager={this.state.formManager}/>
                    </td>

                    <td width="50%" valign="top">
                        <InputComponent  inputReadOnly={this.state.formManager.viewModel.isVoided || this.isReadOnly} inputType="24Hours" multiLine={true} caption="Adjusted To" rows={4}
                                        fieldName="adjustedEnd" formManager={this.state.formManager}/>
                    </td>
                </tr>

                <tr>
                    <td width="50%" valign="top">
                        <InputComponent inputType="money" caption="Hourly Wage" hintText="Enter code that uniquely identify product (required)" 
                                        style={{marginRight:'20px'}} 
                                        fieldName="hourlyWage" 
                                        placeholder="Hourly" 
                                        inputReadOnly={this.state.formManager.viewModel.isVoided || this.isReadOnly}
                                        formManager={this.state.formManager}/>
                    </td>
                </tr>

                <tr>
                    <td colSpan="2" valign="top">
                    <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                        fieldName="notes" placeholder="Enter any notes." inputReadOnly={this.isReadOnly} formManager={this.state.formManager}/>
                    </td>
                </tr>
            </table>
        )
    }

    renderUserEnteredAttendance()
    {
        return(
            <table width="100%">

                <tr>
                    <td width="50%" valign="top">
                        <InputComponent inputType="24Hours" multiLine={true} caption="From" rows={4}
                                        fieldName="adjustedStart" formManager={this.state.formManager}
                                        inputReadOnly={this.isReadOnly}/>
                    </td>

                    <td width="50%" valign="top">
                        <InputComponent inputType="24Hours" multiLine={true} caption="From" rows={4}
                                        fieldName="adjustedEnd" formManager={this.state.formManager}
                                        inputReadOnly={this.isReadOnly}/>
                    </td>
                </tr>

                <tr>
                    <td width="50%" valign="top">
                        <InputComponent inputType="money" caption="Hourly Wage" hintText="Enter code that uniquely identify product (required)" 
                                        style={{marginRight:'20px'}} 
                                        fieldName="hourlyWage" 
                                        placeholder="Hourly" 
                                        inputReadOnly={this.isReadOnly}
                                        formManager={this.state.formManager}/>
                    </td>
                </tr>

                <tr>
                    <td colSpan="2" valign="top">
                    <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                        fieldName="notes" placeholder="Enter any notes." formManager={this.state.formManager}
                                        inputReadOnly={this.isReadOnly}/>
                    </td>
                </tr>
            </table>
        )
    }
}

export default AttendanceDialogue;

