
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js';
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import * as previewUtils from './previewUtils';
import './previewElement.css';

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');
const stringUtil = require('../../../../../utils/string/stringUtil');
const commonElementUtil = require('../../../../../utils/template/commonElementUtil');

class KitchenStationOrderGridElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {

        var separatorLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.separatorLineCharacter))
        {
            var lineText = "";
            for (var index = 0; index < 100; index++)
                lineText = `${lineText}${this.props.element.separatorLineCharacter}`;

            separatorLineComponent = <div style={{width:'100%', whiteSpace:'nowrap', textOverflow:'', overflow:'hidden', padding:'0px', margin:'0px'}}> {lineText}</div>
        }

        var newLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.newLine))
        {
            newLineComponent = <div style={{width:'100%', minHeight:`${10 * this.props.element.newLine}px`, padding:'0px', margin:'0px'}}></div>
        }

        var gridItemNewLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.gridItemNewLine) && this.props.element.gridItemNewLine > 0)
        {
            gridItemNewLineComponent = <div style={{ width: '100%', minHeight: `${10 * this.props.element.gridItemNewLine}px`, padding: '0px', margin: '0px' }}></div>
        }

        var gridModifierNewLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.gridModifierNewLine) && this.props.element.gridModifierNewLine > 0)
        {
            gridModifierNewLineComponent = <div style={{ width: '100%', minHeight: `${10 * this.props.element.gridModifierNewLine}px`, padding: '0px', margin: '0px' }}></div>
        }

        this.props.element.printGroupByCategory = this.props.element.printGroupByCategory ?? false;
        this.props.element.excludePrice = this.props.element.excludePrice ?? false;

        const printGroupByCategory = this.props.element.printGroupByCategory;
        const excludePrice = this.props.element.excludePrice;
        
        let fontSize = "1.0em";

        if (this.props.element.fontSize === "Large") 
        {
            fontSize = "1.25em";
        }
        else if(this.props.element.fontSize === "Extra Large")
        {
            fontSize = "1.50em";
        }

        return <div style={{width:'100%', padding:'0px', margin:'0px'}}
                    onDoubleClick={()=>
                        {
                            var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                            viewUtil.openModalForm("Kitchen Station Order Grid", ()=>
                            {
                                return <DialogueBox element={clonedElement}/>
                            }, 
                            ()=>
                            {
                                this.props.element.printGroupByCategory = clonedElement.printGroupByCategory;
                                this.props.element.excludePrice = clonedElement.excludePrice;
                                this.props.element.separatorLineCharacter = clonedElement.separatorLineCharacter;
                                this.props.element.newLine = stringUtil.isStringNullOrEmpty(clonedElement.newLine) ? 0 : clonedElement.newLine * 1;
                                this.props.element.gridItemNewLine = stringUtil.isStringNullOrEmpty(clonedElement.gridItemNewLine) ? 0 : clonedElement.gridItemNewLine * 1;
                                this.props.element.gridModifierNewLine = stringUtil.isStringNullOrEmpty(clonedElement.gridModifierNewLine) ? 0 : clonedElement.gridModifierNewLine * 1;
                                this.props.element.fontSize = clonedElement.fontSize;
                                this.setState({});
                            }, 
                            ()=>
                            {
                                return commonElementUtil.isValidElement(clonedElement);
                            })
                        }}>
                            <div>
                                {previewUtils.getDashSeparator()}
                                    <div className="d-flex flex-row justify-content-start text-white bg-dark">
                                        <div>&nbsp;Qty</div>
                                        <div className="mx-2 text-start flex-grow-1">&nbsp;Title</div>
                                    </div> 
                                {previewUtils.getDashSeparator()}
                                
                                {
                                    printGroupByCategory
                                    ?   <div>
                                            <div className="d-flex flex-row justify-content-start">
                                                {printGroupByCategory && <div style={{ marginLeft: '2.3rem', marginRight: '4px', textDecoration: 'underline' }} className="text-start flex-grow-1">Starters</div>}
                                            </div>                                            
                                            <div className="d-flex flex-row justify-content-start">
                                                <div style={{ fontSize: fontSize }}>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1" style={{ fontSize: fontSize }}>Fries</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(1)}</div>}
                                            </div>
                                            
                                            {gridItemNewLineComponent}

                                            <div className="d-flex flex-row justify-content-start">
                                                {printGroupByCategory && <div style={{ marginLeft: '2.3rem', marginRight: '4px', textDecoration: 'underline' }} className="text-start flex-grow-1">Main</div>}
                                            </div>
                                            <div className="d-flex flex-row justify-content-start">
                                                <div style={{ fontSize: fontSize }}>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1" style={{ fontSize: fontSize }}>Chicken Gyro</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(3)}</div>}
                                            </div>
                                            {gridModifierNewLineComponent}
                                            <div className="d-flex flex-row justify-content-start">
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1">&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;Large&nbsp;*</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(1.5)}</div>}
                                            </div>

                                            {gridItemNewLineComponent}

                                            <div className="d-flex flex-row justify-content-start">
                                                <div style={{ fontSize: fontSize }}>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1" style={{ fontSize: fontSize }}>Chicken Burger</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(3)}</div>}
                                            </div>
                                            {gridModifierNewLineComponent}
                                            <div className="d-flex flex-row justify-content-start">
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1">&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;Large&nbsp;*</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(1.5)}</div>}
                                            </div>
                                            {gridModifierNewLineComponent}
                                            <div className="d-flex flex-row justify-content-start">
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1">&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;Cheese&nbsp;*</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(0.5)}</div>}
                                            </div>
                                        </div>
                                    :   <div>
                                            <div className="d-flex flex-row justify-content-start">
                                                <div style={{ fontSize: fontSize }}>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1" style={{ fontSize: fontSize }}>Chicken Burger</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(3)}</div>}
                                            </div>
                                            {gridModifierNewLineComponent}
                                            <div className="d-flex flex-row justify-content-start">
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1">&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;Large&nbsp;*</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(1.5)}</div>}
                                            </div>
                                            {gridModifierNewLineComponent}
                                            <div className="d-flex flex-row justify-content-start">
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1">&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;Cheese&nbsp;*</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(0.5)}</div>}
                                            </div>

                                            {gridItemNewLineComponent}

                                            <div className="d-flex flex-row justify-content-start">
                                                <div style={{ fontSize: fontSize }}>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1" style={{ fontSize: fontSize }}>Chicken Gyro</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(3)}</div>}
                                            </div>
                                            {gridModifierNewLineComponent}
                                            <div className="d-flex flex-row justify-content-start">
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1">&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;Large&nbsp;*</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(1.5)}</div>}
                                            </div>

                                            {gridItemNewLineComponent}

                                            <div className="d-flex flex-row justify-content-start">
                                                <div style={{ fontSize: fontSize }}>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="mx-2 text-start flex-grow-1" style={{ fontSize: fontSize }}>Fries</div>
                                                {!excludePrice && <div>{previewUtils.getLocalAmount(1)}</div>}
                                            </div>
                                        </div>
                                }
                            </div> 
                            {separatorLineComponent}
                            {newLineComponent}
               </div>;
    }

    
}


class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;

        this.state.fontSizes = [
            {
                fontSize:"Normal"
            },

            {
                fontSize:"Large"
            },

            {
                fontSize:"Extra Large"
            }
        ];
    }

    render()
    {
        return (
            <table className="component-table">

                <tbody>

                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption="Print By Category"
                                fieldName="printGroupByCategory"
                                hintText="Print category with menu items"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                caption="Exclude Price"
                                fieldName="excludePrice"
                                hintText="Exclude price when printing menu items"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Line Separator Character"
                                fieldName="separatorLineCharacter"
                                placeholder="Line separator character"
                                hintText="Line separator after KOT grid"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Blank Lines"
                                hintText="Blank lines after KOT Grid"
                                fieldName="newLine"
                                placeholder="Enter number of blank lines"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Blank Line After Item"
                                hintText="Number of blank lines after item"
                                fieldName="gridItemNewLine"
                                placeholder="Enter number of blank lines"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Blank Lines Before Modifier"
                                hintText="Number of blank lines before modifier"
                                fieldName="gridModifierNewLine"
                                placeholder="Enter number of blank lines"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Item Font Size"
                                fieldName="fontSize"
                                optionFieldName="fontSize"
                                optionDisplayFieldName="fontSize"
                                optionValues={this.state.fontSizes}
                                hintText="Choose font size for the item name (Required)"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td />
                    </tr>

                </tbody>

            </table>
        );
    }
}


export default KitchenStationOrderGridElement;