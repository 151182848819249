

const rmsApiProxy = require('../../utils/api/rmsApiProxy');
const currentOrgNodeSelectors = require('../../utils/state/stateSelectors/currentOrgNodeSelectors');
const configurationManager = require('../../utils/config/configurationManager');
const domainConstants = require('../../utils/domain/constants');
const constants = require('../../utils/domain/constants');
const stringUtil = require('../../utils/string/stringUtil');
const securityConstants = require('../domain/security/securityConstants');

export async function generateMenu(params)
{
    if (params.menuType == "storefronts")
    {
        return generateStorefrontsMenu(params);
    }

    if (params.menuType == "storefront")
    {
        return generateStorefrontMenu(params);
    }

    if (params.menuType == "configure.central.kitchen")
    {
        return generateCentralKitchenConfigurationMenu(params);
    }

    if (params.menuType == "modules.central.kitchen")
    {
        return generateCentralKitchenModulesMenu(params);
    }

    throw new Error(`Invalid menu type: ${params.menuType} encountered`);
}


async function generateStorefrontsMenu(params)
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        throw new Error("Property selection is required to generate store fonts menu");
    }

    const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();
    const resourceUrl = `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/storefronts`;
    const storefronts = await rmsApiProxy.get(resourceUrl);
    
    var menuOptions = [];

    var menuOption = {};
    menuOption.title = "Add Storefront";
    menuOption.description = "Add new storefront for selected property";
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.core.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontCore";
    menuOption.actionParameter = null;
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated];
    menuOptions.push(menuOption);

    storefronts.forEach(storefront =>
    {
        var imageUrl;

        if(storefront.logoImageUrl)
            imageUrl = storefront.logoImageUrl;
        else
        {
            switch (storefront.type) 
            {
                case constants.storefrontTypes.POS:
                    imageUrl = `app/back.office/storefronts/POS`;
                    break;

                case constants.storefrontTypes.Deliveroo:
                    imageUrl = `app/back.office/storefronts/Deliveroo`;
                    break;

                case constants.storefrontTypes.UberEats:
                    imageUrl = `app/back.office/storefronts/UberEats`;
                    break;

                case constants.storefrontTypes.JustEats:
                    imageUrl = `app/back.office/storefronts/JustEats`;
                    break;

                case constants.storefrontTypes.KIOSK:
                    imageUrl = `app/back.office/storefronts/KIOSK`; 
                    break;

                case constants.storefrontTypes.MobileApp:
                    imageUrl = `app/back.office/storefronts/MobileApp`;
                    break;

                case constants.storefrontTypes.WebApp:
                    imageUrl = `app/back.office/storefronts/WebApp`;
                    break;

                case constants.storefrontTypes.FlipdishKIOSK:
                    imageUrl = `app/back.office/storefronts/Flipdish`;
                    break;

                case constants.storefrontTypes.FlipdishMobileApp:
                    imageUrl = `app/back.office/storefronts/Flipdish`;
                    break;

                case constants.storefrontTypes.FlipdishWeb:
                    imageUrl = `app/back.office/storefronts/Flipdish`;
                    break;

                case constants.storefrontTypes.ExternalApp:
                    imageUrl = `app/back.office/storefronts/ExternalApp`;
                    break;
            
                default:
                    imageUrl = `app/back.office/storefronts/default`;
                    break;
            }
        }

        var menuOption = {};
        menuOption.title = `${storefront.name}`;
        menuOption.description =  `${storefront.type}`;
        menuOption.meta = "storefront";
        menuOption.imageUrl = imageUrl;
        
        menuOption.orgLevel = "property";
        menuOption.actionType = "menuGenerator";
        menuOption.actionParameter = { menuType: "storefront", waitTitle:`Please wait, loading storefront: ${storefront.name}}`, storefront: storefront };
        menuOption.readRoles = [securityConstants.roleCodes.Support, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager];
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
        menuOptions.push(menuOption);
    });

    return menuOptions;
}

async function generateStorefrontMenu(params)
{
    var storefront = params.storefront;
    
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        throw new Error("Property selection is required to generate store fonts menu");
    }

    var menuOptions = [];

    let menuOption = {};
    menuOption.title = "Basic Configuration";
    menuOption.description = `${storefront.name} - Basic Configuration`;
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.core.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontCore";
    menuOption.actionParameter = storefront;
    menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
    menuOptions.push(menuOption);

    menuOption = {};
    menuOption.title = "Operating Configuration";
    menuOption.description = `${storefront.name} - Operating Configuration`;
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.operating.configurations.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontOperatingConfigurations";
    menuOption.actionParameter = storefront;
    menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
    menuOptions.push(menuOption);

    if(storefront.type === domainConstants.storefrontTypes.POS)
    {
        menuOption = {};
        menuOption.title = "Payout Configuration";
        menuOption.description = `${storefront.name} - Payout Configuration`;
        menuOption.meta = "storefront, add";
        menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.operating.configurations.png`;

        menuOption.orgLevel = "property";
        menuOption.actionType = "component";
        menuOption.targetComponent = "StorefrontStorePayoutConfigurations";
        menuOption.actionParameter = storefront;
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated];
        menuOptions.push(menuOption);
    }

    menuOption = {};
    menuOption.title = "Opening Hours";
    menuOption.description = `${storefront.name} - Opening Hours`;
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.opening.hours.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontOpeningHours";
    menuOption.actionParameter = storefront;
    menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
    menuOptions.push(menuOption);

    menuOption = {};
    menuOption.title = "Business Hour Overrides";
    menuOption.description = `${storefront.name} - Business Hour Overrides`;
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.business.hour.overrides.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontBusinessHourOverrides";
    menuOption.actionParameter = storefront;
    menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
    menuOptions.push(menuOption);

    menuOption = {};
    menuOption.title = "Delivery Charges";
    menuOption.description = `${storefront.name} - Delivery Charges`;
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.delivery.charges.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontDeliveryCharges";
    menuOption.actionParameter = storefront;
    menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
    menuOptions.push(menuOption);

    if (storefront.type === domainConstants.storefrontTypes.POS)
    {
        menuOption = {};
        menuOption.title = "Tips Configuration";
        menuOption.description = `${storefront.name} - Tips Configuration`;
        menuOption.meta = "storefront, tips";
        menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.delivery.tips.png`;

        menuOption.orgLevel = "property";
        menuOption.actionType = "component";
        menuOption.targetComponent = "StorefrontTipConfiguration";
        menuOption.actionParameter = storefront;
        menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
        menuOptions.push(menuOption);
    }

    const marketplaceStorefrontsList = Object.values(domainConstants.MarketplaceStorefronts);
    const flipdishStorefrontsList = Object.values(domainConstants.FlipdishStorefronts);

    if (
        marketplaceStorefrontsList.includes(storefront.type) || 
        flipdishStorefrontsList.includes(storefront.type)
    )
    {
        menuOption = {};
        menuOption.title = "Order Ingest";
        menuOption.description = `${storefront.name} - Order Ingest`;
        menuOption.meta = "storefront, add";
        menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.order.ingest.png`;
        
        menuOption.orgLevel = "property";
        menuOption.actionType = "component";
        menuOption.targetComponent = "StorefrontOrderIngest";
        menuOption.actionParameter = storefront;
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated];
        menuOptions.push(menuOption);
    }

    menuOption = {};
    menuOption.title = "Timing Configuration";
    menuOption.description = `${storefront.name} - Timing Configuration`;
    menuOption.meta = "storefront, add";
    menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.timing.configurations.png`;
    
    menuOption.orgLevel = "property";
    menuOption.actionType = "component";
    menuOption.targetComponent = "StorefrontTimingConfigurations";
    menuOption.actionParameter = storefront;
    menuOption.readRoles = [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support];
    menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner];
    menuOptions.push(menuOption);

    if(storefront.type === domainConstants.storefrontTypes.POS)
    {
        menuOption = {};
        menuOption.title = "Over Phone Payment Configuration";
        menuOption.description = `${storefront.name} - Over Phone Payment`;
        menuOption.meta = "storefront, add";
        menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.web.app.png`;
        
        menuOption.orgLevel = "property";
        menuOption.actionType = "component";
        menuOption.targetComponent = "StorefrontOverPhonePaymentConfigurations";
        menuOption.actionParameter = storefront;
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated];
        menuOptions.push(menuOption);
    }

    if(stringUtil.areStringSame(params.storefront.type, constants.storefrontTypes.WebApp))
    {
        menuOption = {};
        menuOption.title = "Web App Configuration";
        menuOption.description = `${storefront.name} - Web App Configuration`;
        menuOption.meta = "storefront, add";
        menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.web.app.png`;
        
        menuOption.orgLevel = "property";
        menuOption.actionType = "component";
        menuOption.targetComponent = "StorefrontWebAppConfigurations";
        menuOption.actionParameter = storefront;
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated];
        menuOptions.push(menuOption);
    }

    if(stringUtil.areStringSame(params.storefront.type, constants.storefrontTypes.MobileApp))
    {
        menuOption = {};
        menuOption.title = "Mobile App Configuration";
        menuOption.description = `${storefront.name} - Mobile App Configuration`;
        menuOption.meta = "storefront, add";
        menuOption.imageUrl = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.mobile.app.png`;
        
        menuOption.orgLevel = "property";
        menuOption.actionType = "component";
        menuOption.targetComponent = "StorefrontMobileAppConfigurations";
        menuOption.actionParameter = storefront;
        menuOption.writeRoles = [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated];
        menuOptions.push(menuOption);
    }

    return menuOptions;
}

async function generateCentralKitchenModulesMenu(params)
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        throw new Error("Property selection is required to generate store fonts menu");
    }

    if(currentOrgNodeSelectors.selectCurrentProperty().type !== domainConstants.serviceContextTypes.centralKitchen)
        return [];

    return [
        {
            title: "Central Kitchen Sales",
            description: "Manage central kitchen sales",
            meta: "Central, Kitchens, Sales",
            imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/central.kitchen.sales.icon.png`,

            subscription: 'Central Kitchen',
            orgLevel: "property",
            actionType: "component",
            targetComponent: "CentralKitchenSales",
            readRoles: [securityConstants.roleCodes.FinanceManager],
            writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
        },
    ]
}
async function generateCentralKitchenConfigurationMenu(params)
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        throw new Error("Property selection is required to generate store fonts menu");
    }

    if(currentOrgNodeSelectors.selectCurrentProperty().type !== domainConstants.serviceContextTypes.centralKitchen)
        return [];

    return [

            {
                title: "Central Kitchen Setup",
                description: "Manage central kitchen setup",
                meta: "Central, Kitchens, Setup",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/central.kitchen.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "CentralKitchenSetup",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Central Kitchen Menus",
                description: "Manage central kitchen menus",
                meta: "Menu, Menus",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/menu.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "Menus",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Categories",
                description: "Manage central kitchen stock item categories",
                meta: "Inventory, Stock Items, Stock Item, Category, Categories",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.category.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "StockItemCategories",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Stock Items",
                description: "Manage central kitchen stock items",
                meta: "Inventory, Stock Items, Stock Item",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/inventory.item.icon.png`,

                orgLevel: "property", 
                actionType: "component",
                targetComponent: "StockItems",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Supplier",
                description: "Manage central kitchen suppliers",
                meta: "Supplier, Suppliers",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/inventory.supplier.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "Suppliers",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Tracking Profile",
                description: "Manage central kitchen inventory tracking profiles",
                meta: "Profile, Track, Stock Item, Inventory",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/purchase.order.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "InventoryTrackingProfiles",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },
        ]
    
}
