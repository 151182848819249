
const moment = require('moment');

var internalDateFormat = "YYYY-MM-DD";
var internalDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

function convertToUTCDateMoment (value)
{
    return moment.utc(value, internalDateFormat, true);
}

function convertToUTCDateTimeMoment (value)
{
    return moment.utc(value, internalDateTimeFormat, true);
}

function convertToUTCMoment (value)
{
    var convertedValue = convertToUTCDateMoment(value);
    if (convertedValue.isValid())
        return convertedValue;

    convertedValue = convertToUTCDateTimeMoment(value);
    if (!convertedValue.isValid())
        throw `${value} is not a correct date/time formatted value`;

    return convertedValue;
}

function alignMomentByStartOrEndOfMonth(sourceMoment, resultMoment)
{
    const source = sourceMoment.clone().startOf("day");   
    const result = resultMoment.clone().startOf("day");  

    if (source.clone().startOf("month").isSame(source))
    {
        return result.startOf("month");
    }

    else if (source.clone().endOf("month").startOf("day").isSame(source))
    {
        return result.endOf("month");
    }

    return resultMoment.clone();
}

module.exports.internalDateFormat = internalDateFormat;
module.exports.internalDateTimeFormat = internalDateTimeFormat;
module.exports.convertToUTCMoment = convertToUTCMoment;

/*******************************************************************************************/
module.exports.getDateFormat = function()
{
    return internalDateFormat;
}

module.exports.getDateTimeFormat = function()
{
    return internalDateTimeFormat;
}

module.exports.getLongDateFormat = function()
{
    // moment/js format tokens
    // https://momentjs.com/docs/#/displaying/
    
    return "dddd, MMMM Do YYYY";    // Sunday, February 14th 2010
}

module.exports.getHourMinuteFormat = function ()
{
    return "HH:mm";
}

/*******************************************************************************************/
module.exports.formatDate = function (dateValue, format)
{
    return convertToUTCMoment(dateValue).format(format);
}

module.exports.formatUtcMomentToDate = function (utcMoment)
{
    return utcMoment.format(internalDateFormat);
}

module.exports.formatUtcMomentToDateTime = function (utcMoment)
{
    return utcMoment.format(internalDateTimeFormat);
}

module.exports.convertFromISODateTimeFormat = function (value)
{
    value = value.replace("T", " ");
    value = value.replace("t", " ");
    value = value.replace("Z", "");
    var timeComponents = value.split(/[- :]/);

    return `${timeComponents[0]}-${timeComponents[1]}-${timeComponents[2]} ${timeComponents[3]}:${timeComponents[4]}:${timeComponents[5].split(".")[0]}`
}

module.exports.convertFromUnixEpochDateTimeFormat = function (value)
{
    return moment(value, 'x', true).utc().format(internalDateTimeFormat);
}

module.exports.convertUTCToISODateTimeFormat = function (value)
{
    if(!this.isValidDateTime(value))
        throw "Input value is not valid datetime format"
    
    value = value.replace(" ", "T");
    return `${value}.000Z`;
}

/*******************************************************************************************/
module.exports.getToday = function()
{
    return moment().utc().format(internalDateFormat);
}

module.exports.getNow = function()
{
    return moment().utc().format(internalDateTimeFormat);
}



/*******************************************************************************************/
module.exports.isValidDate = function(value)
{
    return convertToUTCDateMoment(value).isValid();
}

module.exports.isValidDateTime = function(value)
{
    return convertToUTCDateTimeMoment(value).isValid();
}

module.exports.isValidDateOrDateTime = function (value)
{
    return this.isValidDate(value) || this.isValidDateTime(value);
}



/*******************************************************************************************/
module.exports.isDateComponentSame = function (value1, value2)
{
    return  this.isSame(convertToUTCMoment(value1).format(internalDateFormat), convertToUTCMoment(value2).format(internalDateFormat));
}

module.exports.isSame = function (value1, value2)
{
    return convertToUTCMoment(value1).isSame(convertToUTCMoment(value2));
}

module.exports.isAfter = function (value1, value2)
{
    return convertToUTCMoment(value1).isAfter(convertToUTCMoment(value2));
}

module.exports.isBefore = function (value1, value2)
{
    return convertToUTCMoment(value1).isBefore(convertToUTCMoment(value2));
}

module.exports.isSameOrAfter = function (value1, value2)
{
    return convertToUTCMoment(value1).isSameOrAfter(convertToUTCMoment(value2));
}

module.exports.isSameOrBefore = function (value1, value2)
{
    return convertToUTCMoment(value1).isSameOrBefore(convertToUTCMoment(value2));
}


/*******************************************************************************************/
module.exports.differenceInMilliseconds = function (dateValue1, dateValue2)
{
    return moment.duration(convertToUTCMoment(dateValue1).diff(convertToUTCMoment(dateValue2))).asMilliseconds();
}

module.exports.differenceInSeconds = function (dateValue1, dateValue2)
{
    return moment.duration(convertToUTCMoment(dateValue1).diff(convertToUTCMoment(dateValue2))).asSeconds();
}

module.exports.differenceInMinutes = function (dateValue1, dateValue2)
{
    return moment.duration(convertToUTCMoment(dateValue1).diff(convertToUTCMoment(dateValue2))).asMinutes();
}

module.exports.differenceInHours = function (dateValue1, dateValue2)
{
    return moment.duration(convertToUTCMoment(dateValue1).diff(convertToUTCMoment(dateValue2))).asHours();
}

module.exports.differenceInDays = function (dateValue1, dateValue2)
{
    return moment.duration(convertToUTCMoment(dateValue1).diff(convertToUTCMoment(dateValue2))).asDays();
}




/*******************************************************************************************/
module.exports.addMinutes  = function(dateValue, noOfMinutes)
{
    return convertToUTCMoment(dateValue).add(noOfMinutes, 'minutes').format(internalDateTimeFormat);
}

module.exports.addHours = function (dateValue, noOfHours)
{
    return convertToUTCMoment(dateValue).add(noOfHours, 'hours').format(internalDateTimeFormat);
}

module.exports.addDays  = function(dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
        return convertToUTCMoment(dateValue).add(count, 'days').format(internalDateFormat);

    return convertToUTCMoment(dateValue).add(count, 'days').format(internalDateTimeFormat);
}

module.exports.addWeeks = function (dateValue, count = 1)
{

    if (this.isValidDate(dateValue))
        return convertToUTCMoment(dateValue).add(count, 'weeks').format(internalDateFormat);

    return convertToUTCMoment(dateValue).add(count, 'weeks').format(internalDateTimeFormat);
}

module.exports.addMonths = function (dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
    {
        var result = convertToUTCMoment(dateValue).add(count, 'months').format(internalDateFormat);
        return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateFormat);
    }
    
    var result = convertToUTCMoment(dateValue).add(count, 'months').format(internalDateTimeFormat);
    return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateTimeFormat);
}

module.exports.addQuarters = function (dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
    {
        var result = convertToUTCMoment(dateValue).add(count, 'quarters').format(internalDateFormat);
        return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateFormat);
    }
    
    var result = convertToUTCMoment(dateValue).add(count, 'quarters').format(internalDateTimeFormat);
    return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateTimeFormat);
}

module.exports.addSemiYears = function (dateValue, count = 1)
{
    return this.addMonths(dateValue, count * 6);
}

module.exports.addYears = function (dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
    {
        var result = convertToUTCMoment(dateValue).add(count, 'years').format(internalDateFormat);
        return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateFormat);
    }
    
    var result = convertToUTCMoment(dateValue).add(count, 'years').format(internalDateTimeFormat);
    return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateTimeFormat);
}



/*******************************************************************************************/
module.exports.subtractDays = function (dateValue, noOfDays = 1)
{
    if (this.isValidDate(dateValue))
        return convertToUTCMoment(dateValue).subtract(noOfDays, 'days').format(internalDateFormat);

    return convertToUTCMoment(dateValue).subtract(noOfDays, 'days').format(internalDateTimeFormat);
}

module.exports.subtractWeeks = function (dateValue, count = 1)
{

    if (this.isValidDate(dateValue))
        return convertToUTCMoment(dateValue).subtract(count, 'weeks').format(internalDateFormat);

    return convertToUTCMoment(dateValue).subtract(count, 'weeks').format(internalDateTimeFormat);
}

module.exports.subtractMonths = function (dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
    {
        var result = convertToUTCMoment(dateValue).subtract(count, 'months').format(internalDateFormat);
        return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateFormat);
    }
    
    var result = convertToUTCMoment(dateValue).subtract(count, 'months').format(internalDateTimeFormat);
    return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateTimeFormat);
}

module.exports.subtractQuarters = function (dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
    {
        var result = convertToUTCMoment(dateValue).subtract(count, 'quarters').format(internalDateFormat);
        return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateFormat);
    }
    
    var result = convertToUTCMoment(dateValue).subtract(count, 'quarters').format(internalDateTimeFormat);
    return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateTimeFormat);
}

module.exports.subtractSemiYears = function (dateValue, count = 1)
{
    return this.subtractMonths(dateValue, count * 6);
}

module.exports.subtractYears = function (dateValue, count = 1)
{
    if (this.isValidDate(dateValue))
    {
        var result = convertToUTCMoment(dateValue).subtract(count, 'years').format(internalDateFormat);
        return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateFormat);
    }
    
    var result = convertToUTCMoment(dateValue).subtract(count, 'years').format(internalDateTimeFormat);
    return alignMomentByStartOrEndOfMonth(convertToUTCMoment(dateValue), convertToUTCMoment(result)).format(internalDateTimeFormat);
}


/*******************************************************************************************/
module.exports.setDateComponent = function (dateValue, date, month, year)
{
    var format = internalDateTimeFormat;
    if (convertToUTCDateMoment(dateValue).isValid())
        format = internalDateFormat;
    

    return convertToUTCMoment(dateValue)
            .set("date", date)
            .set("month", month)
            .set("year",year)
            .format(format);
}

module.exports.setSeconds = function (dateValue, seconds)
{
    return convertToUTCMoment(dateValue).seconds(seconds).format(internalDateTimeFormat);
}

module.exports.setMinutes = function (dateValue, minutes)
{
    return convertToUTCMoment(dateValue).minute(minutes).format(internalDateTimeFormat);
}

module.exports.setHours = function (dateValue, hours)
{
    return convertToUTCMoment(dateValue).hour(hours).format(internalDateTimeFormat);
}

module.exports.setTimeComponent = function (dateValue, hours, minutes, seconds)
{
    if(hours < 0 || hours > 23)
        throw Error('Hours can only be set to value from 0 to 23');
    if(minutes < 0 || minutes > 59)
        throw Error('Minutes can only be set to value from 0 to 59');
    if(seconds < 0 || seconds > 59)
        throw Error('Seconds can only be set to value from 0 to 59');

    return convertToUTCMoment(dateValue)
            .hour(hours)
            .minute(minutes)
            .second(seconds)
            .format(internalDateTimeFormat);
}

/*******************************************************************************************/
module.exports.getDateComponent = function (dateValue)
{
    return convertToUTCMoment(dateValue).format(internalDateFormat);
}

module.exports.getMinutes = function (dateValue)
{
    return convertToUTCMoment(dateValue).minute();
}

module.exports.getHours = function (dateValue)
{
    return convertToUTCMoment(dateValue).hour();
}

module.exports.getDay = function(dateValue)
{
    return convertToUTCMoment(dateValue).day();
}

module.exports.getDayName = function (dateValue)
{
    return this.formatDate(dateValue, 'dddd');
}

module.exports.getMonth = function(dateValue)
{
    return convertToUTCMoment(dateValue).month();
}

module.exports.getYear = function(dateValue)
{
    return convertToUTCMoment(dateValue).year();
}

module.exports.getDate = function(dateValue)
{
    return convertToUTCMoment(dateValue).date();
}

module.exports.getStartOfDay = function (value)
{
    return this.convertToUTCMoment(value).startOf("day").format(internalDateTimeFormat);
}

module.exports.getEndOfDay = function (value)
{
    return this.convertToUTCMoment(value).endOf("day").format(internalDateTimeFormat);
}

module.exports.getStartOfMonth = function (value)
{
    var month = this.getMonth(value);
    var year = this.getYear(value);
    return this.setDateComponent(value, 1, month, year);
}

module.exports.getEndOfMonth = function (value)
{
    value = this.getStartOfMonth(value);
    value = this.addMonths(value, 1);
    value = this.subtractDays(value, 1);
    return value;
}


/*******************************************************************************************/
module.exports.convertToUTCStandard = function (value)
{
    var convertedValue = convertToUTCDateMoment(value);
    if (convertedValue.isValid())
    {
        return convertedValue.format(internalDateFormat);
    }

    return moment(value, internalDateTimeFormat, true).utc().format(internalDateTimeFormat);
}

module.exports.convertToLocalStandard = function(value)
{
    var convertedValue = convertToUTCDateMoment(value);
    if (convertedValue.isValid())
    {
        return convertedValue.format(internalDateFormat);
    }

    return convertToUTCDateTimeMoment(value).local().format(internalDateTimeFormat);
}

module.exports.monthNames =
    [
        "Invalid Index",
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
