import React, { Component } from 'react';
import InputComponent from '../../../components/form/inputComponent';


const FormManager = require('../../../utils/view/formManager.js');

export default class LinkShell extends Component
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = props.data;
        this.formManager.view = this;
    }

    render()
    {
        return <InputComponent
            inputType="code"
            caption="Installation Id"
            hintText="Provided by client during installation"
            fieldName="installationId"
            placeholder="Enter the installation ID provided by the client"
            formManager={this.formManager} />
    }
}