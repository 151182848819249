export const TerminalTypes = {
     pos: "pos",
     kds: "kds",
     hardware: "hardware",
     kiosk: "kiosk",
     kitchen: "kitchen",
     signage: "signage",
     centralKitchen: "central.kitchen.kds",
};

export const IntegrationTypes = {
     ReceiptPrinterManagement: "ReceiptPrinterManagement",
     LabelPrinterManagement: "LabelPrinterManagement",
     KitchenStationPrinterManagement: "KitchenStationPrinterManagement",
     CardPaymentManagement: "CardPaymentManagement",
};
export const DeviceTypes = {
     receiptPrinter: "receiptPrinter",
     labelPrinter: "labelPrinter",
     telephoneCli: "telephoneCli",
     creditCardReader: "creditCardReader",
     kotPrinter: "kotPrinter",
};

export const ReceiptPrinterTypes = {
     genericUSB: "escpos.generic.usb",
     genericBluetooth: "escpos.generic.bluetooth",
     genericNetwork: "escpos.generic.network",
     sunmiCloudPrinter: "escpos.sunmi.cloud",
};
export const LabelPrinterTypes = {
     genericUSB: "escpos.generic.usb",
     genericBluetooth: "escpos.generic.bluetooth",
     genericNetwork: "escpos.generic.network",

     brotherQL700Usb: "label.BrotherQL700.usb",
     brotherQL800Usb: "label.BrotherQL800.usb",
     brotherQL820Bluetooth: "label.BrotherQL820.bluetooth",
     brotherQL820Network: "label.BrotherQL820.network",

     zplGenericUSB: "zpl.generic.usb",
     zplGenericNetwork: "zpl.generic.network",

     // zebra410Usb: "label.Zebra410.usb",
     // zebra410Bluetooth: "label.Zebra410.bluetooth",
     // zebra410Network: "label.Zebra410.network",

     // zebra420Usb: "label.Zebra420.usb",
     // zebra420Bluetooth: "label.Zebra420.bluetooth",
     // zebra420Network: "label.Zebra420.network",

     sunmiV2Pro: "label.sunmi.v2.pro",
     sunmiCloudPrinter: "escpos.sunmi.cloud",
};

export const CreditTypes = {
     WorldPay: "World Pay",
     HandPoint: "Hand Point",
     PaymentSense: "Payment Sense",
     StripeReader: "Stripe Reader",
     Offline: "Offline",
};
