import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import Spinner from "../../../../components/spinner/spinner";
import AddEditRole from "./components/AddEditRole";
import ExistingRoles from "./components/ExistingRoles";
import { RoleDataItem } from "./static/roleActions";
import { generateGuid } from "../../../../utils/guid/guidUtil";
import CloseButton from "../../../../components/button/closeButton";
import { loadStaffRoles } from "../../../../utils/api/apiLoadFacade";
import { localeCompareStr } from "../../../../utils/string/stringUtil";
import "./styles/roles.css";

export default function Role(props)
{
    const [roles, setRoles] = useState([]);
    const [modal, setModal] = useState(false);
    const [currentRole, setCurrentRole] = useState({});
    const [isEditing, setIsEditing] = useState(true);
    const [loading, setLoading] = useState(false);
    const formId = generateGuid();

    useEffect(() =>
    {
        async function loadStaffRolesIntoState()
        {
            setLoading(true);
            try
            {
                const staffRolesData = await loadStaffRoles();
                const staffRoles = staffRolesData.map(data =>
                {
                    const staffRole = new RoleDataItem(data);
                    return staffRole;
                });
                staffRoles.sort((a, b) => localeCompareStr(a.name, b.name));
                setRoles(staffRoles);
            }
            catch (err)
            {
                setRoles([]);
            }
            finally
            {
                setLoading(false);
            }
        }
        loadStaffRolesIntoState();
    }, []);

    function handleNewRole()
    {
        setIsEditing(false);
        setCurrentRole(new RoleDataItem({}));
        setModal(true);
    }

    function toggle()
    {
        setModal((prev) => !prev);
    }

    function handleViewEdit(roleId)
    {
        const role = roles.find(role => role.id === roleId);
        if (role)
        {
            setIsEditing(true);
            setCurrentRole(role);
            setModal(true);
        }
    }

    return (
        <Card style={{minWidth: '750px'}}>
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <p className="page-heading">Staff Role</p>
                    </div>
                    <div id="header-btn-bar">
                        <Button
                            onClick={handleNewRole}
                            className="nav-bar-btns"
                            color="success"
                            disabled={props.isReadOnly}
                        >
                            <i className="fa fa-plus" />
                            &nbsp;New Staff Role
                        </Button>
                        <CloseButton />
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                {
                    loading
                        ? <Spinner text="Loading Staff Roles" />
                        : (<>
                            <ExistingRoles roles={roles} handleViewEdit={handleViewEdit} />
                            <AddEditRole
                                isReadOnly={props.isReadOnly}
                                modal={modal}
                                toggle={toggle}
                                isEditing={isEditing}
                                role={currentRole}
                                setRole={setRoles}
                                formId={formId}
                            />
                        </>)
                }
            </CardBody>
        </Card>
    );
}
