import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import arraySort from 'array-sort';
import { allBrandTotal } from '../constants';

class BrandWiseTabbedReport extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            selectedIndex: 0,
        }

        this.onBrandClick = this.onBrandClick.bind(this);
        this.getBrandTab = this.getBrandTab.bind(this);
        this.getReport = this.getReport.bind(this);
        this.renderTabbedReport = this.renderTabbedReport.bind(this);
    }

    onBrandClick(index)
    {
        this.state.selectedIndex = index;
    }

    getBrandTab(brand, tabIndex)
    {
        return <span style={{}} className='flex-grow-1 text-nowrap text-truncate py-1 px-2' onClick={() => this.setState({ selectedIndex: tabIndex })}>
            {brand}
        </span>
    }

    render()
    {
        let brands = [];
        let brandCaptions = [];

        if (this.props.brands && this.props.brands.length > 1)
        {
            brands = arraySort(this.props.brands);
            brands = [allBrandTotal, ...this.props.brands];
            brandCaptions = brands.map(brand => this.getBrandCaption(brand))

            return this.renderTabbedReport(brands, brandCaptions)
        }
        else
        {
            return this.getReport(allBrandTotal, true);
        }
    }

    getBrandCaption(brand)
    {
        if (brand === allBrandTotal)
            return "All Brands"
        else if (brand == null)
            return "Other Brands"
        else
            return brand;
    }

    getReport(brand, noPadding)
    {
        const report = this.props.getReport(brand)

        if(noPadding)
        {
            return report;
        }
        else
        {
            return <div className="m-3">
                {report}
            </div>
        }
    }

    renderTabbedReport(brands, brandCaptions)
    {
        return (
            <Card style={{ "margin-top": "-0.25rem" }} className='pt-0 rounded-0'>
                <CardHeader className='rounded-0' >
                    <div className="w-100 d-flex justify-content-center align-items-center fw-bold" >
                        <div style={{ fontSize: "0.8rem", fontWeight: 500 }} className='d-flex align-items-center flex-grow-1 ms-2 flex-wrap'>
                            {brandCaptions.map((brandCaption, index) => 
                            {
                                return this.state.selectedIndex === index ?
                                    <div style={{ width: "calc(20% - 0.25rem)" }} className="d-flex align-items-center justify-content-center border rounded-1 py-1 mb-1 me-1 shadow-sm bg-dark text-light">
                                        {this.getBrandTab(brandCaption, index)} </div>
                                    :
                                    <div style={{ width: "calc(20% - 0.25rem)" }} className="d-flex align-items-center justify-content-center border rounded-1 py-1  mb-1 me-1 shadow-sm bg-white text-dark">
                                        {this.getBrandTab(brandCaption, index)} </div>;
                            }
                            )}
                        </div>

                    </div>

                </CardHeader>
                <CardBody className='p-0'>
                        {this.getReport(brands[this.state.selectedIndex], this.props.noPadding)}
                </CardBody>
            </Card>
        );

    }
}

export default BrandWiseTabbedReport;