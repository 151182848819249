
const validator = require('validator');
const stringUtil = require('../string/stringUtil');
const dateUtil = require('../dateUtil/dateUtil');
const regexUtil = require('../regex/regexUtil');

module.exports.numericThresholdValue = 0.0009;
//#region Notes

//
// Dated: 2021-11-06
// This validator code was copied today from api which is master reference for validation codes
//
// validate-prefixed methods return error string instead of bool
//

//#endregion

//#region User particulars validation

module.exports.isEmptyOrValidEmailAddress = function (emailAddress)
{
    if (stringUtil.isStringNullOrEmpty(emailAddress))
        return true;

    return validator.default.isEmail(emailAddress);
}

module.exports.isValidEmailAddress = function (emailAddress)
{
    return !stringUtil.isStringNullOrEmpty(emailAddress);

    if (stringUtil.isStringNullOrEmpty(emailAddress))
        return false;

    return validator.default.isEmail(emailAddress);
}

module.exports.isValidPhoneNumber = function (phoneNumber)
{
    return !stringUtil.isStringNullOrEmpty(phoneNumber);
    const ukPhoneNumberRegex = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/;

    phoneNumber = stringUtil.trimString(phoneNumber);

    return phoneNumber.match(ukPhoneNumberRegex);
}

module.exports.isValidCode = function (code)
{
    return /^[a-zA-Z0-9.]*$/i.test(code); // JBY.amangani.01.pos
}

//#endregion

//#region Number validations

module.exports.isNumeric = function (value)
{
    value = value + "";
    if (stringUtil.isStringNullOrEmpty(value))
        return false;

    const options = {};
    options.force_decimal = false;
    return validator.default.isFloat(value, options)
}

module.exports.isOptionalNumeric = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return this.isNumeric(value);
}

module.exports.isAllNumeric = function (...values)
{
    for (const value of values)
    {
        if (!this.isNumeric(value))
            return false;
    }

    return true;
}

module.exports.isGreaterThanOrEqualToZero = function (value)
{
    return this.isNumeric(value) && Number(value) >= 0;
}

module.exports.isGreaterThanZero = function (value)
{
    return this.isNumeric(value) && Number(value) > module.exports.numericThresholdValue;
}

module.exports.isOptionalGreaterThanOrEqualToZero = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return this.isGreaterThanOrEqualToZero(value);
}

module.exports.isOptionalGreaterThanZero = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return this.isGreaterThanZero(value);
}

module.exports.isBetween = function (value, lower, upper)
{
    return (
        this.isNumeric(value) &&
        this.isNumeric(lower) &&
        this.isNumeric(upper) &&
        lower <= upper &&
        value >= lower &&
        value <= upper);
}

//#endregion

//#region Integer validations

module.exports.isInteger = function (value)
{
    value = value + "";
    if (stringUtil.isStringNullOrEmpty(value))
        return false;

    return validator.default.isInt(value)
}

module.exports.isOptionalInteger = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return this.isInteger(value);
}

//#endregion

//#region Object validations

module.exports.isObject = function (value)
{
    return value != null && typeof value === "object" && !Array.isArray(value);
}

module.exports.isEmptyObject = function (value)
{
    return this.isObject(value) && Object.keys(value).length === 0;
}

//#endregion

//#region Array validations

module.exports.isArray = function (value)
{
    if (!value) return false;
    return Array.isArray(value);
}

module.exports.isNonEmptyArray = function (value)
{
    if (!value) return false;
    if (!Array.isArray(value)) return false;
    return value.length > 0;
}

//#endregion

//#region Presence validations

module.exports.isPresent = function (value)
{
    return !stringUtil.isStringNullOrEmpty(value);
}

module.exports.isAnyPresent = function (...values)
{
    for (const value of values)
    {
        if (!stringUtil.isStringNullOrEmpty(value))
            return true;
    }

    return false;
}

module.exports.isAnyNotPresent = function (...values)
{
    for (const value of values)
    {
        if (stringUtil.isStringNullOrEmpty(value))
            return true;
    }

    return false;
}

module.exports.isAllPresent = function (...values)
{
    return !this.isAnyNotPresent(...values);
}

module.exports.isEitherAllPresentOrAllAbsent = function (...values)
{
    let allPresent = true;
    let allAbsent = true;

    values.forEach(value => 
    {
        if (stringUtil.isStringNullOrEmpty(value))
        {
            allPresent = false;
        }
        else
        {
            allAbsent = false;
        }
    });

    return allPresent || allAbsent;
}

module.exports.isOnlyOnePresent = function (...values)
{
    let result = false;

    for (const value of values)
    {
        if (this.isPresent(value))
        {
            if (result == true) return false;   // More than one present
            result = true;
        }
    }

    return result;
}

//#endregion

//#region Comparison validations

module.exports.isGreaterThan = function (value, greaterThan)
{
    return this.isNumeric(value) && Number(value) > greaterThan;
}

module.exports.isGreaterThanOrEqualTo = function (value, greaterThan)
{
    return this.isNumeric(value) && Number(value) >= greaterThan;
}

module.exports.isLessThan = function (value, lessThan)
{
    return this.isNumeric(value) && Number(value) < lessThan;
}

module.exports.isLessThanOrEqualTo = function (value, lessThan)
{
    return this.isNumeric(value) && Number(value) <= lessThan;
}

//#endregion

//#region Date/DateTime/Time validations

module.exports.isDate = function (value)
{
    return dateUtil.isValidDate(value);
}

module.exports.isDateTime = function (value)
{
    return dateUtil.isValidDateTime(value);
}

module.exports.isTime = function (value)
{
    return regexUtil.isValid24HourTimeFormat(value);
}

module.exports.isOptionalDateTime = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return dateUtil.isValidDateTime(value);
}

module.exports.isOptionalDate = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return dateUtil.isValidDate(value);
}

module.exports.isOptionalTime = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return regexUtil.isValid24HourTimeFormat(value);
}

module.exports.isDateRange = function (fromDate, toDate)
{
    return (
        dateUtil.isValidDate(fromDate) &&
        dateUtil.isValidDate(toDate) &&
        dateUtil.isSameOrBefore(fromDate, toDate));
}

//#endregion

//#region Domain validations

module.exports.validateEitherFranchisorOrFranchisee = function (model)
{
    // Either of franchisorId/franchiseeId node must be exclusive specified

    const franchisorId = stringUtil.trimString(model.franchisorId);
    const franchiseeId = stringUtil.trimString(model.franchiseeId);

    if (!franchisorId && !franchiseeId)
        return "Either of FranchisorId Or FranchiseeId is required";

    if (!(franchisorId ? !franchiseeId : franchiseeId))
        return "Only either one of Franchisor id Or Franchisee id can be specified";

    return null;
}

module.exports.validateWellFormedCreationNode = function (model)
{
    // Well formed creation node specifies all parent/grandparent nodes

    const franchisorId = stringUtil.trimString(model.franchisorId);
    const franchiseeId = stringUtil.trimString(model.franchiseeId);
    const propertyId = stringUtil.trimString(model.propertyId);

    if (franchisorId == null) return "franchisorId is missing";

    if (propertyId != null)
    {
        if (franchiseeId == null) return "franchiseeId is missing";
    }

    return null;
}

//#endregion

//#region Miscellaneous validations

module.exports.areAllItemsUnique = function (values, getKey)
{
    const keys = new Set(values.map(x => getKey(x)));
    return keys.size == values.length;
}

module.exports.isOptionalColorHexString = function (value)
{
    if (stringUtil.isStringNullOrEmpty(value))
        return true;

    return regexUtil.isValidColorHexString(value);
}

module.exports.isValidEnumeration = function (value, enumeration)
{
    for (const key of Object.keys(enumeration)) 
    {
        if (enumeration[key] === value) return true;
    }

    return false;
}

module.exports.isValidSMSSenderName = function (value)
{
    if(stringUtil.isStringNullOrEmpty(value) || typeof value !== 'string')
        return false;
    else if(value.length > 11)
        return false;
    else if(!/^[a-zA-Z0-9]+$/.test(value))
        return false;
    
    return true;
}

//#endregion
