
import React, { Component } from 'react';
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import PayrollRunWage from './wages/PayrollRunWage';
import PayrollRunTaxes from './taxes/PayrollRunTaxes';
import PayrollRunEmployerSummary from './employer/PayrollRunEmployerSummary';

var FormManager = require('../../../../../../utils/view/formManager.js');


class PayrollRunSummary extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            formManager: new FormManager(),
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    render()
    {
        return <Col>
            <Card>
                <CardHeader>Wages Summary</CardHeader>
                <CardBody>
                    <PayrollRunWage  data={this.props.data} />
                </CardBody>
            </Card>
            <Card>
                <CardHeader>Taxes Summary</CardHeader>
                <CardBody>
                    <PayrollRunTaxes data={this.props.data} />
                </CardBody>
            </Card>
            <Card>
                <CardHeader>Employer Summary</CardHeader>
                <CardBody>
                    <PayrollRunEmployerSummary data={this.props.data} />
                </CardBody>
            </Card>
        </Col>
    }
}

export default PayrollRunSummary;