import React, { useState } from 'react';

import InputComponent from '../../../../../../components/form/inputComponent';
import FormManager from '../../../../../../utils/view/formManager';
import StockItemSections from '../StockItemSections';

export default function PrepProfile(props)
{
    const [value, setValue] = useState(false);

    props.data.prepProfile = props.data.prepProfile || {};
    const prepProfile = props.data.prepProfile;
    const isReadOnly = props.isRowReadOnly;

    const formManager = new FormManager();
    formManager.viewModel = prepProfile;
    formManager.onFieldChanged = () => setValue(!value);

    return  <StockItemSections title="Prep Profile">
                <table className="component-table">

                    <tr>
                        <td>
                            <InputComponent
                                inputType="number"
                                fieldName="prepTime"
                                caption="Prep Time"
                                placeholder="Enter time in hours"
                                hintText="Prep time for the item in hours"
                                inputReadOnly={isReadOnly}
                                formManager={formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="number"
                                fieldName="expiryTime"
                                caption="Expiry Time"
                                placeholder="Enter time in hours"
                                hintText="Expiry time for the item in hours"
                                inputReadOnly={isReadOnly}
                                formManager={formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="number"
                                fieldName="minExpiryLabels"
                                caption="Minimum Expiry Labels Per Day"
                                placeholder="Enter minimum expiry labels per day"
                                hintText="Minimum number of expiry labels to be printed each day"
                                inputReadOnly={isReadOnly}
                                formManager={formManager} />
                        </td>
                    </tr>

                </table>
            </StockItemSections>
}
