import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent';
import OrgSelectComponent from '../../../../../components/form/orgSelectComponent';

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const FormManager = require('../../../../../utils/view/formManager');

export default class TargetCriteria extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        const formManager = new FormManager();
        formManager.viewModel = this.props.data;
        formManager.onFieldChanged = (event) =>
        {
            if (this.props.onChange)
                this.props.onChange();
        }

        return (
            <div style={{ marginTop: "20px" }}>
                <table width="100%">
                    <tr>
                        <td colSpan="2">
                            {this.renderOrgSelectionComponent(formManager)}
                        </td >
                    </tr>
                    <tr>
                        <td width="50%" valign='top'>
                            {this.renderName(formManager)}
                        </td>
                        <td width="50%" valign='top'>
                            {this.renderPhoneNumber(formManager)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            {this.renderAddress(formManager)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            {this.renderEmail(formManager)}
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            {this.renderLastOrderDate(formManager)}
                        </td>
                    </tr>‚
                </table>
            </div>
        )
    }



    renderOrgSelectionComponent()
    {
        var selectedOrgNodes = null;
        if (this.props.data != null && this.props.data.propertyIds != null)
        {
            const properties = orgSelectors.selectProperties();
            selectedOrgNodes = {};
            selectedOrgNodes.properties = this.props.data.propertyIds.map(id => properties.find(p => p.id === id));
            selectedOrgNodes.franchisors = [];
            selectedOrgNodes.franchisees = [];
        }
        return (
        
            <OrgSelectComponent disabled={this.props.disabled} style={{ marginLeft: '1px', minWidth: '300px' }}
                caption="Property"
                hintText="Search customers for property(s) or leave it blank to select all"    
                allowedOrgNodes={currentOrgNodeSelectors.selectAllowedOrgNodes()}
                selectedOrgNodes={selectedOrgNodes}
                allowedOrgLevels={["property"]}
                multiSelect={true}
                isReadOnly={this.props.isReadOnly}
                onOrgChange={selectedOrgNodes =>
                {
                    this.props.data.propertyIds = selectedOrgNodes.properties.map(p => p.id);
                    if (this.props.onChange)
                        this.props.onChange();
                }}
            />
        );
    }

    renderName(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="name"
            caption="Name"
            fieldName="name"
            hintText="Search customers based on name"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderPhoneNumber(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="phone"
            caption="Phone Number"
            fieldName="phoneNumber"
            hintText="Search customers based on phone number"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderAddress(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="address"
            caption="Address"
            fieldName="address"
            hintText="Search customers based on address"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderEmail(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="email"
            caption="Email"
            fieldName="email"
            hintText="Search customers based on email"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }

    renderLastOrderDate(formManager)
    {
        return <InputComponent style={{ marginLeft: '5px' }}
            inputType="date"
            caption="Last Order Date"
            fieldName="lastOrderDate"
            hintText="Search customers who haven't ordered since this date"
            inputReadOnly={this.props.isReadOnly}
            formManager={formManager} />
    }
}