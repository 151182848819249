
import React from 'react';

//TODO: Styling 

function CaptionLabel ({text}) 
{
    return <div>{text}</div>
}

export default CaptionLabel;