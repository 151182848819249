
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import PKSRBEndorsementComponent from './endorsementComponents/pbSRBEndorsementComponent';
import PKFBREndorsementComponent from './endorsementComponents/pkFBREndorsementComponent';
import KEKRAEndorsementComponent from './endorsementComponents/keKRAEndorsementComponent';

import SelectComponent from '../../../../components/form/selectComponent';
import formManager from '../../../../utils/view/formManager';

const stationState = require('../stationState');

const viewUtil = require('../../../../utils/view/viewUtil');
const stringUtil = require('../../../../utils/string/stringUtil');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const externalIntegrationUtils = require('../../../../utils/domain/externalIntegrations/externalIntegrationUtils');

const stationUtil = require('../stationUtil');

const integrationType = externalIntegrationUtils.integrationTypes.saleEndorsement;
const saleEndorsementEntities = externalIntegrationUtils.externalEntities.saleEndorsement;

class EndorsementSettings extends Component 
{
    constructor(props)
    {
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onFieldChanged = this.onFieldChanged.bind(this);

        this.state = {};
        this.state.model = {}
        this.state.model.saleEndorsementSettings = {}
        this.state.model.externalEntity = this.props.saleEndorsement.externalEntity;
        this.state.model.saleEndorsementSettings = this.props.saleEndorsement.jsonData;

        this.formManager = new formManager();
        this.formManager.viewModel = this.state.model;
        this.formManager.view = this;
        this.formManager.onFieldChanged = this.onFieldChanged;
    }

    onFieldChanged(event)
    {
        const id = event.target.id;

        if (id === "externalEntity")
        {
            this.state.model.saleEndorsementSettings = {};

            switch (this.state.model.externalEntity)
            {
                case saleEndorsementEntities.pkFBR:
                    this.state.model.saleEndorsementSettings.serviceUrl = "http://localhost:8524/api/IMSFiscal/GetInvoiceNumberByModel";
                    break;
                case saleEndorsementEntities.pkSRB:
                    this.state.model.saleEndorsementSettings.serviceUrl = "http://localhost:8282/SalesInvoiceService";
                    this.state.model.saleEndorsementSettings.TransType = "Live";
                    break;
                case saleEndorsementEntities.keKRA:
                    this.state.model.saleEndorsementSettings.selectedCurrency = "KSH";
                    this.state.model.saleEndorsementSettings.serviceUrl = "http://localhost:8089/api/sign?invoice";
                    break;
                default:
                    break;
            }

            this.setState({});
        }
    }

    async onSave()
    {
        var lastError = null;

        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            this.validateEndorsementSettings();

            const terminal = this.props.clientTerminal;
            let terminalIntegrations = stationState.integrations.get(terminal.id);
            if (terminalIntegrations == null)
                terminalIntegrations = [];

            const existingIntegration = terminalIntegrations.find(x => x.integrationType === integrationType && x.externalEntity === this.state.model.externalEntity);
            if(existingIntegration != null && this.props.saleEndorsement.id !== existingIntegration.id)
                throw Error(`Sale endorsement already defined for ${this.state.model.externalEntity}`);

            let endorsementIntegration = Object.assign({}, this.props.saleEndorsement);
            endorsementIntegration.externalEntity = this.state.model.externalEntity;
            endorsementIntegration.jsonArray = Object.keys(this.state.model.saleEndorsementSettings).map(key => ({ key, value: this.state.model.saleEndorsementSettings[key] }));

            terminalIntegrations = terminalIntegrations.filter(k => k.id !== this.props.saleEndorsement.id);
            terminalIntegrations.push(endorsementIntegration);
            await stationUtil.updateTerminalIntegrations(terminal, terminalIntegrations);
            await stationState.loadIntegrations();
        }
        catch (error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }

    validateEndorsementSettings()
    {
        const endorsementSettings = this.state.model.saleEndorsementSettings;

        if (this.state.model.externalEntity === saleEndorsementEntities.pkFBR)
        {
            if (stringUtil.isStringNullOrEmpty(endorsementSettings.POSID))
                throw Error('POSID is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.serviceUrl))
                throw Error('serviceUrl is not specified');

        }
        else if (this.state.model.externalEntity === saleEndorsementEntities.pkSRB)
        {
            if (stringUtil.isStringNullOrEmpty(endorsementSettings.posId))
                throw Error('posId is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.serviceUrl))
                throw Error('serviceUrl is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.ntn))
                throw Error('ntn is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.name))
                throw Error('Business name is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.TransType))
                throw Error('TransType is not specified');

        }
        else if (this.state.model.externalEntity === saleEndorsementEntities.keKRA)
        {
            if (stringUtil.isStringNullOrEmpty(endorsementSettings.invoicePin))
                throw Error('Invoice PIN is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.selectedCurrency))
                throw Error('Invoice currency is not specified');

            if (stringUtil.isStringNullOrEmpty(endorsementSettings.serviceUrl))
                throw Error('serviceUrl is not specified');
        }
    }

    render()
    {
        return (<Card>
            <CardHeader>
                {this.renderToolbar()}
            </CardHeader>
            <CardBody>
                <div style={{ minHeight: window.innerHeight * 0.50 }}>
                    {this.renderOptions()}
                </div>
            </CardBody>
        </Card>
        );
    }

    renderToolbar()
    {
        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Sale Endorsements</div>
                    </td>
                    <td align="right">
                        {
                            !this.props.isReadOnly &&
                            <Button color="success" className="btn-success" style={{ marginRight: '10px' }} onClick={this.onSave}>
                                Save
                            </Button>
                        }
                        <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={() => { this.props.onClose() }}>
                            {this.props.isReadOnly ? "Close" : "Cancel"}
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }

    renderOptions()
    {
        const franchisorId = currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId;
        const franchisor = orgSelectors.selectFranchisor(franchisorId);
        const country = franchisor.country;

        const endorsementTypes = [];

        switch (country)
        {
            case "Pakistan":
                endorsementTypes.push({ value: saleEndorsementEntities.pkFBR, label: "PK-FBR" });
                endorsementTypes.push({ value: saleEndorsementEntities.pkSRB, label: "PK-SRB" });
                break;
            case "Kenya":
                endorsementTypes.push({ value: saleEndorsementEntities.keKRA, label: "KE-KRA" });
                break;
            default:
                break;
        }

        let endorsementSettingsComponent = null;

        switch (this.state.model.externalEntity)
        {
            case saleEndorsementEntities.pkFBR:
                endorsementSettingsComponent =
                    <PKFBREndorsementComponent
                        saleEndorsementSettings={this.state.model.saleEndorsementSettings}
                        isReadOnly={this.props.isReadOnly} />
                break;
            case saleEndorsementEntities.pkSRB:
                endorsementSettingsComponent =
                    <PKSRBEndorsementComponent
                        saleEndorsementSettings={this.state.model.saleEndorsementSettings}
                        isReadOnly={this.props.isReadOnly} />
                break;
            case saleEndorsementEntities.keKRA:
                endorsementSettingsComponent =
                    <KEKRAEndorsementComponent
                        saleEndorsementSettings={this.state.model.saleEndorsementSettings}
                        isReadOnly={this.props.isReadOnly} />
                break;
            default:
                break;
        }

        return <table className="component-table">
            <tr>
                <td width="50%">
                    <SelectComponent
                        caption="Endorsement Agency"
                        fieldName="externalEntity"
                        optionFieldName="value"
                        optionDisplayFieldName="label"
                        optionValues={endorsementTypes}
                        hintText="Required"
                        placeholder="Choose endorsement agency"
                        clearable={false}
                        comboReadOnly={this.props.isReadOnly}
                        formManager={this.formManager} />
                </td>
                <td>

                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {endorsementSettingsComponent}
                </td>
            </tr>
        </table>
    }
}

export default EndorsementSettings;