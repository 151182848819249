const LookupData = require('../lookup/lookupData');

module.exports = function ()
{
    this.organizationTaxonomy = {
        franchisors: [],
        franchisees: [],
        properties: []
    };

    this.lookupData = new LookupData();
}