import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';

import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import ErrorMessageComponent from '../../../../../components/error/errorMessage';
import LayoutDesigner from './components/LayoutDesigner';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const domainConstants = require('../../../../../utils/domain/constants');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

export default class PropertyLayoutConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { isLoading: true, layoutConfiguration: null, webAppConfiguration: null, errorMessage: null };
    }

    onSave = async () => 
    {
        const validationMessage = this.validateLayoutConfiguration(this.state.layoutConfiguration);

        if (validationMessage != null) 
        {
            viewUtil.showErrorAlert(validationMessage);
            return;
        }

        viewUtil.showSystemModalSpinner('Saving data, please wait ...')

        try
        {
            const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/configurations/layoutConfiguration`;
            await rmsApiProxy.post(resourceUrl, this.state.layoutConfiguration);
            viewUtil.showSuccessAlert('Data saved successfully');

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.configuration, 
                                                      'Property Layout configuration has been altered', 
                                                       this.state.layoutConfiguration);
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(`Failed to save data: ${error.toString()}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onCancel = () => 
    {
        actionDispatcherUtil.showOptionsUI();
    }

    async componentDidMount()
    {
        viewUtil.showSystemModalSpinner("Loading property layout configuration, please wait");

        try
        {
            let resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/configurations/layoutConfiguration`;
            let layoutConfiguration = await rmsApiProxy.get(resourceUrl);

            if (layoutConfiguration == null)
            {
                const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

                layoutConfiguration = {
                    franchisorId: currentOrgNode.franchisorId,
                    franchiseeId: currentOrgNode.franchiseeId,
                    propertyId: currentOrgNode.propertyId,
                    layout: null
                };
            }

            const webAppConfiguration = await loadTableOrderingWebAppConfiguration();

            this.setState({ isLoading: false, layoutConfiguration, webAppConfiguration });
        }
        catch (error)
        {
            const errorMessage = 'Oops !! Following error occurred while loading localization settings:\n\n' + error.toString();
            this.setState({ isLoading: false, errorMessage: errorMessage });
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render()
    {
        const { isLoading, errorMessage } = this.state;

        if (isLoading === true) return null;

        if (errorMessage != null) 
        {
            return <ErrorMessageComponent message={errorMessage} />
        }

        const property = orgSelectors.selectProperty(this.state.layoutConfiguration.propertyId);
        const title = `Layout Configuration: ${property.name}`;

        return (
            <Card>

                <CardHeader className='py-2 px-4 d-flex justify-content-between align-items-center'>
                    <EditComponentHeader
                        title={title}
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSave}
                        onCancelClose={this.onCancel} />
                </CardHeader>

                <CardBody>
                    <LayoutDesigner data={this.state.layoutConfiguration} webAppConfiguration={this.state.webAppConfiguration} />
                </CardBody>

            </Card>
        );
    }

    validateLayoutConfiguration = (layoutConfiguration) =>
    {
        if (!validator.isPresent(layoutConfiguration.franchisorId))
            return "franchisorId is required";

        if (!validator.isPresent(layoutConfiguration.franchiseeId))
            return "franchiseeId is required";

        if (!validator.isPresent(layoutConfiguration.propertyId))
            return "propertyId is required";

        const layout = layoutConfiguration.layout;

        var tableNumbers = new Map();

        if (layout == null || layout.floors == null || layout.floors.length <= 0)
            return "No zone/floor layouts specified";

        for (var floorIndex = 0; floorIndex < layout.floors.length; floorIndex++)
        {
            var floor = layout.floors[floorIndex];
            if (stringUtil.isStringNullOrEmpty(floor.title))
                return "Floor Title cannot be left empty";

            if (floor.stencils == null)
                continue;

            for (var stencilIndex = 0; stencilIndex < floor.stencils.length; stencilIndex++)
            {
                var stencil = floor.stencils[stencilIndex];
                if (stencil.type != domainConstants.floorStencilType.Seating)
                    continue;

                if (stringUtil.isStringNullOrEmpty(stencil.title))
                    return "Floor Title cannot be left empty";

                if (stringUtil.isStringNullOrEmpty(stencil.tableNumber))
                    return "Table Number cannot be left empty";

                if (stringUtil.isStringNullOrEmpty(stencil.noOfSeating) || !validator.isInteger(stencil.noOfSeating))
                    return "No Of Seating is invalid";

                if (tableNumbers.has(stencil.tableNumber.toUpperCase()))
                    return `Duplicate table number '${stencil.tableNumber}' found in layout.`;

                tableNumbers.set(stencil.tableNumber.toUpperCase(), stencil.tableNumber.toUpperCase());
            }
        }
    }
}

async function loadTableOrderingWebAppConfiguration()
{
    let resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/storefronts`;
    const storefronts = await rmsApiProxy.get(resourceUrl);
    const webOrderingStorefronts = storefronts.filter(x => x.type === domainConstants.storefrontTypes.WebApp);
    for (let index = 0; index < webOrderingStorefronts.length; index++)
    {
        const webOrderingStorefront = webOrderingStorefronts[index];
        resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/storefronts/${webOrderingStorefront.id}/configurations/webAppConfigurations`;

        const configuration = await rmsApiProxy.get(resourceUrl);
        if (configuration != null && configuration.mode === domainConstants.webAppModes.TableApp)
        {
            return configuration;
        }

    }
    
    return null;
}
