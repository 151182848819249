import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView'
import * as reportStyles from '../../../reports/reportUtils/styles';
import Category from './Category'

const arraySort = require('array-sort');
const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const validator = require('../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');
const stringUtil = require('../../../../utils/string/stringUtil');


class Categories extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Account Categories";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadAccountCategories;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () => 
        {
            const newRow = {};

            newRow.franchisorId =
                currentOrgNodeSelectors.isCurrentFranchisorSelected()
                    ? currentOrgNode.franchisorId : null;

            newRow.franchiseeId =
                currentOrgNodeSelectors.isCurrentFranchiseeSelected() || currentOrgNodeSelectors.isCurrentPropertySelected()
                    ? currentOrgNode.franchiseeId : null;

            return newRow;
        };

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.getComponent = (isNew, row) => <Category data={row} isRowReadOnly={this.isRowReadOnly(row)} />;
        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Account Category - ${row.name}` : 'Account Category - New';

        gridViewOptions.getRowStyle = this.getRowStyle;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new category.";

            return "Please wait while updating category.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New category added successfully";

            return "Category updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new category:";

            return "Following error occurred while updating category:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly(row, customButton)
    {
        return this.props.isReadOnly;
    }

    getRowStyle = (params)=>
    {
        if (this.isRowReadOnly(params.data)) 
        {
            return { background: reportStyles.readOnlyRowBackgroundColor };
        }
    
        return null;
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.name))
            return "Category name cannot be left empty";

        if (!validator.isPresent(row.type))
            return "Category type cannot be left empty";

        if (!(row.franchisorId ? !row.franchiseeId : row.franchiseeId))
            return "Only either of franchisorId or franchiseeId should exist";

        return null;
    }

    async loadAccountCategories() 
    {
        const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
        

        const data =[];
        let result = await rmsApiProxy.get(`core/organizations/${orgContext.franchisorId}/accounts/categories`);
        data.push(...result);

        return arraySort(data, stringUtil.localeCompareProp("name"));
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
            const updatedCategory = await rmsApiProxy.post(`core/organizations/${orgContext.franchisorId}/accounts/categories`, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(category => category.id == updatedCategory.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedCategory);
            else
                remoteData.push(updatedCategory);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedCategory;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    constructGridColumnHeaders()
    {
        var headers = [];
        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "type";
        header.headerName = "Type";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Categories;
