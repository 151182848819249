import React from 'react';
import BIToolBaseComponent from '../common/regularBITools/BIToolBaseComponent';

const stringUtil = require('../../../utils/string/stringUtil');

const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const rmsApiProxyUtils = require('../../../utils/api/rmsApiProxyUtils');

class DailySalesByDriver extends BIToolBaseComponent
{
    constructor(props)
    {
        super(props);

        // this.state is initialized by base class

        this.state.stage = 0;
        this.state.entities = [];
        this.state.error = null;
    }

    render()
    {
        var resultComponent = null;

        if (this.state.stage == 0)
        {
            if (!stringUtil.isStringNullOrEmpty(this.state.error))
                resultComponent = this.renderErrorComponent(this.state.error)
            else
                resultComponent = this.renderCaptionComponent("BI (Business Intelligence) Tool :: Sales By Driver");
        }

        if (this.state.stage == 1)
        {
            this.loadData();
            resultComponent = this.renderSpinnerComponent("Please Wait");
        }

        if (this.state.stage == 2)
        {
            resultComponent = this.renderGrid(this.generateColumnDes(), this.state.entities);
        }

        return <div>
            {this.renderMultiDateSelectionHeaderComponent
                (
                    this.componentName || "Daily Sales By Driver",
                    this.state.stage === 1,
                    true,
                    (searchCriteria) =>
                    {
                        this.state.stage = 1;
                        this.state.dateCriteria = searchCriteria;
                        this.setState({});
                    },
                    () =>
                    {
                        this.state.error = null;
                        this.state.dateCriteria = null;
                        this.state.entities = [];
                        this.state.stage = 0;
                        this.setState({});
                    })}
            {resultComponent}
        </div>;
    }

    generateColumnDes()
    {
        var columnDefinitions = [];
        var uniquePropertyNames = [...new Set(this.state.entities.map(e => e.property))];

        if (uniquePropertyNames.length > 1)
        {
            columnDefinitions.push({ field: 'franchisor', type: 'rowGroup' });
            columnDefinitions.push({ field: 'property', type: 'rowGroup' });
        }
        else
        {
            columnDefinitions.push({ field: 'franchisor', type: 'rowGroupEnabled' });
            columnDefinitions.push({ field: 'property', type: 'rowGroup' });
        }

        columnDefinitions.push({ field: 'driver', type: 'rowGroup' });
        columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });

        columnDefinitions.push({ field: 'year', type: 'rowGroup' });
        columnDefinitions.push({ field: 'month', type: 'rowGroup, month' });
        columnDefinitions.push({ field: 'dayOfWeek', type: 'rowGroup' });

        columnDefinitions.push({ field: 'saleCount', type: 'number' });
        columnDefinitions.push({ field: 'cashPaidAmount', type: 'money' });

        return columnDefinitions;
    }

    async loadData()
    {
        const fromDate = this.state.dateCriteria.startDate;
        const toDate = this.state.dateCriteria.endDate;

        const resourceUrl =
            `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/sales/dailySalesByDriver` +
            `?fromDate=${fromDate}&toDate=${toDate}`;

        try 
        {
            const entities = await rmsApiProxy.get(resourceUrl);
            this.setState({ stage: 2, entities: entities });
        }
        catch (error) 
        {
            this.setState({ stage: 0, error: error });
        }
    }
}

export default DailySalesByDriver;