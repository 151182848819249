import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import GridViewOptions from '../../../../components/gridView/gridViewOptions';
import Appliance from './Appliance';

import * as clipboard from '../../../../components/clipboard/clipboard';

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const validator = require('../../../../utils/validator/validator');
const viewUtil = require('../../../../utils/view/viewUtil');
const configurationManager = require('../../../../utils/config/configurationManager');

const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');


class Appliances extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadAppliances = this.loadAppliances.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Appliances";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadAppliances;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        gridViewOptions.getNewRow = () => ({
            propertyId: currentOrgNode.propertyId,
            serviceProfiles: []
        });

        gridViewOptions.getComponent = (isNew, row) =>
            <Appliance data={row} isRowReadOnly={this.props.isReadOnly} />;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Appliance - ${row.name}` : 'Appliance - New';
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;
        gridViewOptions.messageContext = "Appliance";

        gridViewOptions.closeEnabled = true;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new appliance.";
            return "Please wait while updating appliance.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New appliance added successfully";
            return "Appliance updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new appliance:";
            return "Following error occurred while updating appliance:";
        }

        gridViewOptions.copyPasteConfiguration =
        {
            dataType: clipboard.dataTypes.appliances,
            onCopy: null,
            onPaste: async (appliances) => 
            {
                const propertyId = currentOrgNodeSelectors.selectCurrentProperty().id;

                appliances.forEach(appliance => 
                {
                    appliance.propertyId = propertyId;

                    delete appliance.id;

                    if (appliance.temperatureProfile)
                    {
                        delete appliance.temperatureProfile.applianceId;
                    }

                    if (appliance.serviceProfiles && appliance.serviceProfiles.length > 0)
                    {
                        appliance.serviceProfiles.forEach(serviceProfile => 
                        {
                            delete serviceProfile.id;
                            delete serviceProfile.applianceId;
                        });
                    }
                });

                try
                {
                    viewUtil.showSystemModalSpinner("Please wait while uploading data ...");

                    const updatedAppliances = [];

                    for (const appliance of appliances)
                    {
                        const updatedAppliance = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/foodsafety/appliances`, appliance);
                        updatedAppliances.push(updatedAppliance);
                    }

                    return updatedAppliances;

                } catch (error)
                {
                    viewUtil.showCriticalAlert(`Failed to save appliances from clipboard with error ${error}`);
                    return null;
                }
                finally
                {
                    viewUtil.closeModalSpinner();
                }
            }
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row) =>
    {
        if(this.props.isReadOnly) return true;

        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        return currentOrgNode.propertyId != row.propertyId;
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.propertyId))
            return "propertyId cannot be left empty";

        if (!validator.isPresent(row.name))
            return "Name cannot be left empty";

        if (!validator.isPresent(row.applianceTypeCode))
            return "Appliance type cannot be left empty";

        if (!validator.isPresent(row.manufacturer))
            return "Appliance manufacturer cannot be left empty";

        if (!validator.isPresent(row.modelNumber))
            return "Appliance model number cannot be left empty";

        const temperatureProfile = row.temperatureProfile || {}

        if (validator.isAnyPresent(
            temperatureProfile.minTemperature,
            temperatureProfile.maxTemperature,
            temperatureProfile.minTemperatureLogs,
            temperatureProfile.autoCorrectionLogs) ||
            validator.isNonEmptyArray(temperatureProfile.hazardTypes))
        {
            if (!validator.isNumeric(temperatureProfile.minTemperature))
                return "Min Temperature is invalid";

            if (!validator.isNumeric(temperatureProfile.maxTemperature))
                return "Max Temperature is invalid";

            if (temperatureProfile.minTemperature * 1.0 > temperatureProfile.maxTemperature)
                return "Min Temperature must be less than Max Temperature";

            if (!validator.isPresent(temperatureProfile.minTemperatureLogType))
                return "Min Temperature Log Type cannot be left empty";

            if (!(validator.isInteger(temperatureProfile.minTemperatureLogs) &&
                validator.isGreaterThanOrEqualToZero(temperatureProfile.minTemperatureLogs)))
                return "Min Temperature Logs is invalid";

            if (!(validator.isInteger(temperatureProfile.autoCorrectionLogs) &&
                validator.isGreaterThanOrEqualToZero(temperatureProfile.autoCorrectionLogs)))
                return "Auto Correction Logs is invalid";

            if (!validator.isArray(temperatureProfile.hazardTypes))
                return "hazard Types must be an array cannot be left empty";
        }

        if (!validator.isArray(row.serviceProfiles))
        {
            return "Service Profiles must be an array cannot be left empty";
        }

        return null;
    }

    async loadAppliances()
    {
        const appliances = await apiLoadFacade.loadAppliances();
        return appliances;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const updatedAppliance = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/foodsafety/appliances`, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(appliance => appliance.id == updatedAppliance.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedAppliance);
            else
                remoteData.push(updatedAppliance);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedAppliance;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);

        const cloudName = configurationManager.getConfig().cdnUsersCloudName;
        const defaultCloudName = configurationManager.getConfig().cdnAppCloudName;

        header = {};
        header.field = "imageUrl";
        header.headerName = "Image";
        header.type = dataTypeConstants.imageLogo;
        header.width = columnWidthConstants.imageLogo;
        header.cellRenderer = 'imageRenderer';
        header.cellRendererParams = {
            cloudName: cloudName,
            defaultImage: "app/back.office/icons/default.appliance.icon.png",
            defaultCloudName: defaultCloudName,
        }
        headers.push(header);

        header = {};
        header.field = "applianceTypeCode";
        header.headerName = "Appliance Type";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "manufacturer";
        header.headerName = "Manufacturer";
        header.type = dataTypeConstants.manufacturer;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "modelNumber";
        header.headerName = "Model";
        header.type = dataTypeConstants.modelNumber;
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Appliances;
