import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent';
import HintCard from '../../../../../components/hint/hintCard';

const FormManager = require('../../../../../utils/view/formManager');
class FreebieReason extends Component 
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    render() 
    {
        return (

            <div>

                <div style={{ margin: "0px 20px" }}>

                    <HintCard contextId="freebieReasons" isDefaultClosed={true}>

                        <div style={{ margin: "10px 20px" }}>

                            <b>Freebie Reason</b>
                            <br/>
                            <p>
                                Reason why freebie meal is offered
                            </p>

                        </div>

                    </HintCard>

                </div>

                <table className="component-table">
                    <tbody>

                        <tr>
                            <td>
                                <InputComponent
                                    inputType="text"
                                    caption="Freebie Reason"
                                    fieldName="code"
                                    hintText="Required"
                                    placeholder="Enter a unique freebie reason"
                                    formManager={this.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                <InputComponent
                                    inputType="text"
                                    caption="Notes"
                                    fieldName="notes"
                                    placeholder="Enter any notes."
                                    multiLine={true} rows={4}
                                    formManager={this.formManager} />
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        )
    }
}

export default FreebieReason;

