import React, {Component} from "react";
import FormManager from "../../../../../../utils/view/formManager";
import InputComponent from '../../../../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';


class PaymentType extends Component
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            formManager: new FormManager(),
            isExisting: !this.props.isNew
        };


        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    render()
    {
        return(
            <table className="component-table">
                <tbody>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="code"
                                caption="Code"
                                fieldName="code"
                                hintText="Unique Payment Type Code. This cannot be edited once saved (Required)"
                                placeholder="Enter Payment code"
                                inputReadOnly={this.state.isExisting} 
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="name"
                                caption="Name"
                                fieldName="name"
                                hintText="Payment Type Name (Required)"
                                placeholder="Enter Payment Type name"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputComponent
                                inputType="description"
                                caption="Description"
                                fieldName="description"
                                hintText="Payment Type Description (Required)"
                                placeholder="Enter description"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="type"
                                caption="Type"
                                fieldName="type"
                                hintText="Payment Method Type (Required)"
                                placeholder="Enter Payment Type"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="money"
                                caption="Maximum Amount"
                                fieldName="maxAmount"
                                hintText="Maximum amount for payment type (Required)"
                                placeholder="Enter maximum amount"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="date"
                                caption="Expiry Date"
                                fieldName="expiryDate"
                                hintText="Expiry Date for payment type (Required)"
                                placeholder="Enter expiry date"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                caption="Open Cash Drawer"
                                fieldName="openCashDrawer"
                                hintText="Should be able to open cash drawer? (Required)"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any Payment Type notes."
                                hintText="Enter any Payment Type notes"
                                multiLine={true} rows={2}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default PaymentType;