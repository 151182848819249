
import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody} from 'reactstrap';
import Spinner from '../../../../components/spinner/spinner.js';
import HolidayCalendar from './HolidayCalendar.js';
import CdnImage from '../../../../components/cdnImage/cdnImage.js';
import HolidayDialogue from './HolidayDialogue.js';
import NewHolidayDialogue from './NewHolidayDialogue.js';

const stringUtil = require('../../../../utils/string/stringUtil.js');
const viewUtil = require('../../../../utils/view/viewUtil.js');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
const configurationManager = require('../../../../utils/config/configurationManager.js');
const typeUtil = require('../../../../utils/type/typeUtil.js');
const guidUtil = require('../../../../utils/guid/guidUtil.js');
const commonUtility = require('../../../../utils/domain/commonUtility.js');
const constants = require('../../../../utils/domain/constants.js');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const actionTypes = require('../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../utils/state/actionDispatcher');

class HolidayPlanner extends Component 
{
    constructor(props)
    {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderCalendarBody = this.renderCalendarBody.bind(this);
        this.renderPendingRequestsBody = this.renderPendingRequestsBody.bind(this);
        this.renderHolidayDialogue = this.renderHolidayDialogue.bind(this);
        this.renderNewHolidayDialogue = this.renderNewHolidayDialogue.bind(this);

        this.loadInitialData = this.loadInitialData.bind(this);
        this.loadCalendarData = this.loadCalendarData.bind(this);
        this.getLocalToday = this.getLocalToday.bind(this);

        this.state = {};
        this.state.error = null;
        this.state.initialState = "loadingRequired";
        this.state.allStaffs = [];
        this.state.bookedComponentHtmlReferences = new Map();

        this.state.renderHolidayDialogue = false;
        this.state.bookedHolidayForDialogue = null;

        this.state.renderNewHolidayDialogue = false;
        this.state.holidayForNewHolidayDialogue = null;
    }

    render()
    {
        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                </CardBody>

                {this.renderHolidayDialogue()}
                {this.renderNewHolidayDialogue()}
            </Card>

        );

        
    }

    renderBody()
    {
        var height = window.innerHeight * 0.60;

        return (<table style={{width:'100%'}}>
                    <tr>
                        <td style={{width:'85%'}}>
                            <Card>
                                <CardHeader>Calendar</CardHeader>
                                <CardBody>
                                    <div style={{overflowY:'auto', height:height}}>
                                        {this.renderCalendarBody()}
                                    </div>
                                </CardBody>
                            </Card>
                        </td>

                        <td style={{width:'15%'}}>
                            <Card>
                                <CardHeader>Pending Requests</CardHeader>
                                <CardBody>
                                    <div style={{overflowY:'auto', height:height}}>
                                        {this.renderPendingRequestsBody()}
                                    </div>
                                </CardBody>
                            </Card>
                        </td>
                    </tr>
        </table>);
    }

    renderCalendarBody()
    {
        if (!stringUtil.isStringNullOrEmpty(this.state.error))
        {
            return (<div className="text-muted" style={{textAlign:'center'}}>
                        <div style={{color: '#FFA07A'}}>{this.state.error}</div> 
                    </div>)
        }

        if (stringUtil.areStringSame(this.state.initialState, "loadingRequired"))
        {
            this.loadInitialData();
            return <div style={{marginTop:'200px'}}><Spinner text="Loading Holidays Calendar" /></div>
        }

        if (stringUtil.areStringSame(this.state.initialState, "loaded"))
        {
            this.state.bookedComponentHtmlReferences = new Map();
            return <HolidayCalendar isReadOnly={this.props.isReadOnly} bookedComponentHtmlReferences={this.state.bookedComponentHtmlReferences} allStaffs={this.state.allStaffs} startDate={this.state.startDate} endDate={this.state.endDate} bookedHolidays={this.state.bookedHolidays}
                                 onPrevious = {()=>
                                {
                                    this.state.startDate = dateUtil.subtractMonths(this.state.startDate, 3);
                                    this.loadCalendarData();
                                }}
                                onNext = {()=>
                                {
                                    this.state.endDate = dateUtil.addMonths(this.state.endDate, 3);
                                    this.loadCalendarData();
                                }}
                                onBookedHolidayClick={(bookedHoliday) =>
                                {
                                    this.state.renderHolidayDialogue = true;
                                    this.state.bookedHolidayForDialogue = bookedHoliday;
                                    this.setState({});
                                }}
                                onDateClick = {(date)=>
                                    {
                                        this.state.holidayForNewHolidayDialogue = {};
                                        this.state.holidayForNewHolidayDialogue.id = guidUtil.generateGuid();
                                        this.state.holidayForNewHolidayDialogue.staffId = null;
                                        this.state.holidayForNewHolidayDialogue.propertyId = currentOrgNodeSelectors.selectCurrentProperty().id;
                                        this.state.holidayForNewHolidayDialogue.fromDate = date;
                                        this.state.holidayForNewHolidayDialogue.toDate = date;
                                        this.state.holidayForNewHolidayDialogue.status = "pending";
                                        this.state.holidayForNewHolidayDialogue.isFullDay = true;
                                                
                                        this.state.renderNewHolidayDialogue = true;
                                        this.setState({});
                                    }}/>
        }
    }

    renderPendingRequestsBody()
    {
        if (!stringUtil.isStringNullOrEmpty(this.state.error) || !stringUtil.areStringSame(this.state.initialState, "loaded"))
            return null;

        
        if (this.state.bookedHolidays.length <= 0)
        {
            return (<div style={{marginTop:'10px', textAlign:'center'}}><h6 className="text-muted">No Pending Requests</h6></div>);
        }

        var bookedHolidayComponents = [];

        this.state.bookedHolidays.forEach (bookedHoliday =>
        {
            if (!stringUtil.areStringSame(bookedHoliday.status,"pending"))
                return;

            var staff = this.state.allStaffs.find (s => s.id == bookedHoliday.staffId);
            var name = `${staff.firstName} ${staff.lastName}`;

            var caption = `Full Day (${dateUtil.formatDate(bookedHoliday.fromDate, "DD/MMM")} - ${dateUtil.formatDate(bookedHoliday.toDate, "DD/MMM")})`;
            if (!bookedHoliday.isFullDay)
                caption = `${dateUtil.formatDate(bookedHoliday.fromDate, "DD/MMM")} [${dateUtil.formatDate(dateUtil.convertToLocalStandard(bookedHoliday.fromDate), "HH:mm")} - ${dateUtil.formatDate(dateUtil.convertToLocalStandard(bookedHoliday.toDate), "HH:mm")}]`;

            var imageComponent = null;
            if (!stringUtil.isStringNullOrEmpty(staff.pictUrl))
                imageComponent = <div> <CdnImage  cloudName={configurationManager.getConfig().cdnUsersCloudName} publicId={staff.pictUrl} width="30" height="30" radius="0"/></div>;
            else
                imageComponent = <div> <CdnImage  cloudName={configurationManager.getConfig().cdnAppCloudName} publicId='app/back.office/icons/default.staff.picture.png' width="30" height="30" radius="0"/></div>;

            bookedHolidayComponents.push (<Card style={{marginTop:'0px', marginBottom:'5px'}}
                                                onClick={()=>
                                                {
                                                    if (this.state.bookedComponentHtmlReferences.has(bookedHoliday.id))
                                                        this.state.bookedComponentHtmlReferences.get(bookedHoliday.id).current.scrollIntoView({behavior: 'smooth'});
                                                }}>
                                            <CardHeader>
                                                <table style={{width:'100%'}}>
                                                    <tr>
                                                        <td style={{verticalAlign:'center'}}>
                                                            <div style={{marginLeft:'2px', marginRight:'2px'}}>
                                                                {imageComponent}
                                                            </div>
                                                        </td>
                                                        <td style={{width:'99%'}}>
                                                            <div style={{minWidth:'0px', margin:'0px', padding:'0px', textAlign:'center'}}>{name}</div> 
                                                            <div style={{textAlign:'center'}}>
                                                                <small>{caption}</small>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                                
                                            </CardHeader>
                                        </Card>);
        });

        return bookedHolidayComponents;
    }

    renderHeader()
    {
        return (
                <table width="100%">
                    <tr>
                        <td style={{ whiteSpace: "nowrap" }}>
                            <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>Holiday Calendar</div>
                        </td>

                        {
                            commonUtility.getApplicationMode() === constants.applicationModes.embedded
                            ?   null
                            :   <td width="99%" style={{textAlign:'right'}}>
                                    <Button disabled={this.state.mode == 'searching'} color="dark" className="btn-dark" 
                                            onClick={()=>
                                                    {
                                                        const currentOptionAction = {
                                                                                        type: actionTypes.mainBarComponent,
                                                                                        payload: null
                                                                                    };
                                                
                                                        actionDispatcher.dispatch(currentOptionAction);
                                                    }}>
                                        <i className="fa fa-sign-out"></i>&nbsp;Close
                                    </Button>
                                </td>
                        }
                    </tr>

                </table>
            );
    }
    renderHolidayDialogue()
    {
        if (!this.state.renderHolidayDialogue)
            return;

        var staff = this.state.allStaffs.find (s => s.id == this.state.bookedHolidayForDialogue.staffId);

        return (<HolidayDialogue isReadOnly={this.props.isReadOnly} staff={staff} bookedHoliday={typeUtil.deepCloneObject(this.state.bookedHolidayForDialogue)}
                                onClose = {()=>
                                            {
                                                this.state.renderHolidayDialogue = false;
                                                this.state.bookedHolidayForDialogue = null;
                                                this.setState({});
                                            }}
                                onApprove = {(bookedHoliday) =>
                                            {   
                                                this.onApproval(bookedHoliday);
                                            }}

                                onReject = {(bookedHoliday) =>
                                            {
                                                this.onReject(bookedHoliday);
                                            }}
                                
                                />);
                                        
    }

    renderNewHolidayDialogue()
    {
        if (!this.state.renderNewHolidayDialogue)
            return;

        return (<NewHolidayDialogue bookedHoliday={this.state.holidayForNewHolidayDialogue} allStaffs={this.state.allStaffs}
                                    onSave = {(newHoliday)=>
                                                {
                                                    this.onNewHoliday(newHoliday);
                                                }
                                            }
                                    onCancel={()=>{
                                                    this.state.renderNewHolidayDialogue = false;
                                                    this.state.holidayForNewHolidayDialogue = null;
                                                    this.setState({});
                                                }
                                            } />)
    }

    loadInitialData()
    {
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/staff`;

        rmsApiProxy.get(resourceUrl)
                .then(staff =>
                    {
                        this.state.allStaffs = staff;
                        this.loadPendingHolidayRequests();

                    }, error =>
                    {
                        this.state.error = error;
                        this.setState({});
                    });
    }

    loadPendingHolidayRequests()
    {
        var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/holidays?type=pending`;
        rmsApiProxy.get(resourceUrl)
            .then(results =>
            {
                if (results == null)
                    results = [];

                this.state.startDate = dateUtil.getStartOfMonth(this.getLocalToday());
                this.state.endDate = dateUtil.getEndOfMonth(dateUtil.addMonths(this.state.startDate, 6));
                
                results.forEach(r =>
                {
                    if (dateUtil.isAfter(this.state.startDate, dateUtil.getDateComponent(r.fromDate)))
                    {
                        this.state.startDate = dateUtil.getStartOfMonth(dateUtil.getDateComponent(r.fromDate));
                    } 
                    
                    if (dateUtil.isBefore(this.state.endDate, dateUtil.getDateComponent(r.toDate)))
                    {
                        this.state.endDate = dateUtil.getEndOfMonth(dateUtil.getDateComponent(r.toDate));
                    } 
                })

                this.loadCalendarData();
            }, error =>
            {
                this.state.error = error;
                this.setState({});
            });
    }

    loadCalendarData()
    {
        var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/holidays?fromDate=${this.state.startDate}&toDate=${this.state.endDate}`;
        rmsApiProxy.get(resourceUrl)
            .then(results =>
            {
                if (results == null)
                    results = [];

               
                this.state.bookedHolidays = results;
                this.state.initialState = "loaded";
                this.setState({});
            }, error =>
            {
                this.state.error = error;
                this.setState({});
            });

    }

    getLocalToday()
    {
        return dateUtil.getDateComponent(dateUtil.convertToLocalStandard(dateUtil.getNow()));
    }

    onApproval(bookedHoliday)
    {
        this.state.bookedHolidays = this.state.bookedHolidays.filter(b => !stringUtil.areStringSame(b.id, bookedHoliday.id));
        this.state.bookedHolidays.push(bookedHoliday);

        var payload = {};
        payload.action = "approve";
        payload.holiday = bookedHoliday;
        this.pushUpdateToServer(payload);

        this.state.renderHolidayDialogue = false;
        this.state.bookedHolidayForDialogue = null;

        this.state.renderNewHolidayDialogue = false;
        this.state.holidayForNewHolidayDialogue = null;

        this.setState({});
    }

    onReject(bookedHoliday)
    {
        this.state.bookedHolidays = this.state.bookedHolidays.filter(b => !stringUtil.areStringSame(b.id, bookedHoliday.id));
        this.state.bookedHolidays.push(bookedHoliday);
       
        var payload = {};
        payload.action = "reject";
        payload.holiday = bookedHoliday;
        this.pushUpdateToServer(payload);

        this.state.renderHolidayDialogue = false;
        this.state.bookedHolidayForDialogue = null;

        this.state.renderNewHolidayDialogue = false;
        this.state.holidayForNewHolidayDialogue = null;

        this.setState({});
    }

    onNewHoliday(bookedHoliday)
    {
        this.state.bookedHolidays = this.state.bookedHolidays.filter(b => !stringUtil.areStringSame(b.id, bookedHoliday.id));
        this.state.bookedHolidays.push(bookedHoliday);

        var payload = {};
        payload.action = "submit";
        payload.holiday = bookedHoliday;
        this.pushUpdateToServer(payload);

        this.state.renderHolidayDialogue = false;
        this.state.bookedHolidayForDialogue = null;

        this.state.renderNewHolidayDialogue = false;
        this.state.holidayForNewHolidayDialogue = null;

        this.setState({});
    }

    pushUpdateToServer(payload)
    {
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/holidays`;

        rmsApiProxy.post(resourceUrl, payload)
        .then(results =>
            {
            }, error =>
            {
                viewUtil.showErrorAlert(`Error occurred while submitting the holiday: ${error}`);
            });
    }
    
}


export default HolidayPlanner