const actionTypes = require('../../actionTypes.js');
const stateBuilder = require("../../stateBuilder.js")

module.exports.createAction = function (passport)
{
    return (
        {
            type: actionTypes.passport,
            payload: passport
        }
    );
}

module.exports.changeState = function (previousState, payload)
{
    if (payload == null)
        return stateBuilder.createState();
    else
        return Object.assign({}, previousState, { passport: payload });
}