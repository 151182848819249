import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import ChargesByVenueCodeSummary from './ChargesByVenueCodeSummary';
import ChargesByVenueCodeDetails from './ChargesByVenueCodeDetails';
import ChargesByVenueCodeCharts from './ChargesByVenueCodeCharts';

import { HeaderComponent, NoDataFoundComponent } from '../../../utils/viewUtils';

export default class ChargesByVenueCode extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { showDetails: false };
    }

    onShowDetails = () => this.setState({ showDetails: !this.state.showDetails })

    render()
    {
        const { showDetails } = this.state;
        const { chargesByVenueCode } = this.props.data;

        return (
            <div className='d-flex flex-column border rounded-top'>

                <HeaderComponent caption="Charges By Venue" showDetails={showDetails} onShowDetails={this.onShowDetails} index={this.props.index} />

                {
                    chargesByVenueCode.length === 0
                        ? <NoDataFoundComponent message='No sale charges data found' />
                        : <>
                            <Collapse isOpen={!showDetails}>
                                <ChargesByVenueCodeSummary data={this.props.data} />
                            </Collapse>

                            <Collapse isOpen={showDetails}>
                                <div className='d-flex flex-column'>
                                    <div>
                                        <ChargesByVenueCodeDetails data={this.props.data} showDetails={showDetails} />
                                    </div>
                                    <div className='m-3 border rounded'>
                                        <ChargesByVenueCodeCharts data={this.props.data} />
                                    </div>
                                </div>
                            </Collapse>
                        </>
                }

            </div>
        );
    }
}