import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import GridComponent from '../../../../components/grid/gridComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';

import campaignUtil from './utils/campaignUtil';
import ExistingCampaign from './components/existingCampaign/ExistingCampaign';
import NewCampaign from './components/newCampaign/NewCampaign';


const arraySort = require('array-sort');
const viewUtil = require('../../../../utils/view/viewUtil');
const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');

export default class Campaigns extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.newRow = null;
        this.state.searchCriteria = {};

        this.onNewCampaign = this.onNewCampaign.bind(this);

        this.onGridReady = this.onGridReady.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.renderRefreshButton = this.renderRefreshButton.bind(this);
        this.onRefreshButtonClicked = this.onRefreshButtonClicked.bind(this);
        this.onLaunchCampaign = this.onLaunchCampaign.bind(this);
    }

    async componentDidMount()
    {
        await this.loadCampaigns();
    }

    render() 
    {
        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                    {this.renderNewCampaign()}
                    {this.renderExistingCampaign()}
                </CardBody>
            </Card>

        );
    }

    renderHeader()
    {
        return (
            <table width="100%">
                <tr>
                    <td style={{ whiteSpace: "nowrap" }} align="left">
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{"Campaign Management"}</div>
                    </td>

                    <td style={{ whiteSpace: "nowrap" }} align="right">
                        {this.renderRefreshButton()}
                        <Button color="success" className="btn-success" style={{ marginLeft: '5px', marginRight: '5px' }} disabled={this.props.isReadOnly} onClick={this.onNewCampaign}>
                            Start Campaign
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }

    renderRefreshButton()
    {
        const disabled = this.state.isLoading;

        return <Button color="primary" className="btn-primary" disabled={disabled} onClick={this.onRefreshButtonClicked}>
            <i className="fa fa-refresh"></i>&nbsp;Refresh
        </Button>
    }

    renderBody()
    {
        const { isLoading, campaigns, isError, errorMessage } = this.state;

        if (isError)
        {
            return <ErrorMessage message={errorMessage} />;
        }

        if (isLoading)
        {
            return <Spinner text="Loading Campaigns" />
        }

        if (!campaigns)
        {
            return <EmptyData action="Hit Load button to load data" />;
        }

        return <GridComponent headers={this.constructGridColumnHeaders()}
            onGridReady={this.onGridReady}
            onRowDoubleClicked={this.onRowDoubleClicked}
            rows={campaigns}
            shouldReArrangeHeaders={true}
        />
    }

    renderNewCampaign()
    {
        if (this.state.newRow == null)
            return null;

        return <NewCampaign data={this.state.newRow}
            onLaunch={this.onLaunchCampaign}
            onClose={() =>
            {
                this.setState({ newRow: null })
            }} />
    }

    renderExistingCampaign()
    {
        if (this.state.existingRow == null)
            return null;

        return <ExistingCampaign {...this.props} data={this.state.existingRow}
            onClose={() =>
            {
                this.setState({ existingRow: null })
            }}
            onClone={(newRow) =>
            {
                this.setState({ newRow: newRow, existingRow: null })
            }}
            onAbortCampaign={() =>
            {
                this.setState({ existingRow: null })
                this.abortCampaign(this.state.existingRow);
            }}
        />
    }

    async abortCampaign(campaign)
    {
        viewUtil.showConfirmDialogue("Abort campaign", `Are you sure you want to abort the ${campaign.name} campaign`, async () =>
        {
            viewUtil.showSystemModalSpinner("Aborting Campaign");

            try 
            {
                const updatedCampaign = await campaignUtil.abortCampaign(campaign);

                const campaigns = this.state.campaigns;

                var existingIndex = campaigns.findIndex(r => r.id == updatedCampaign.id);
                
                campaigns.splice(existingIndex, 1, updatedCampaign);

                viewUtil.closeModalSpinner();
                this.setState({});
            }
            catch (error) 
            {
                viewUtil.closeModalSpinner();
                viewUtil.showCriticalAlert(error);
            }
        });
    }

    onNewCampaign()
    {
        this.setState({ newRow: campaignUtil.newCampaign() });
    }

    onLaunchCampaign(campaign)
    {
        delete campaign.profile;
        campaignUtil.enrichCampaign(campaign);

        const campaigns = this.state.campaigns;

        const newCampaigns = [];
        newCampaigns.push(campaign);
        newCampaigns.push(...campaigns);

        this.setState({ campaigns: newCampaigns, newRow: null })
    }

    async onRefreshButtonClicked()
    {
        await this.loadCampaigns();
    }

    //#region Grid related

    onRowDoubleClicked(eventData)
    {
        var selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        this.setState({ existingRow: selectedRow });
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    //#endregion

    async loadCampaigns()
    {
        this.setState(
            {
                isLoading: true, campaigns: null,
                isError: false, errorMessage: null,
            });

        try
        {
            const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/marketing/campaigns`;

            const results = await rmsApiProxy.get(resourceUrl);
            var campaigns = [];
            results.forEach(campaign => 
            {
                if (campaign == null) return;

                delete campaign.profile;
                campaignUtil.enrichCampaign(campaign);
                campaigns.push(campaign)            
            });

            campaigns = arraySort(campaigns, ['startDateTime', 'endDateTime'], { reverse: true });

            this.setState(
                {
                    isLoading: false,
                    campaigns: campaigns
                });

        }
        catch (error)
        {
            this.setState(
                {
                    isLoading: false, campaigns: null,
                    isError: true, errorMessage: `Failed to load campaign data with error: ${error}`,
                });
        }
    }

    constructGridColumnHeaders()
    {
        var headers = [];

        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "type";
        header.headerName = "Type";
        header.width = columnWidthConstants.type;
        headers.push(header);

        header = {};
        header.field = "localStartDateTime";
        header.headerName = "Start Date Time";
        header.type = dataTypeConstants.dateTime;
        header.width = columnWidthConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "localEndDateTime";
        header.headerName = "End Date Time";
        header.type = dataTypeConstants.dateTime;
        header.width = columnWidthConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "projectedTargetCount";
        header.headerName = "Projected Targeted Customers";
        header.type = dataTypeConstants.fixedNumber;
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "runBy";
        header.headerName = "Run By";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "aborted";
        header.headerName = "Aborted";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}
