import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent.js';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent.js';

const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils.js');

class OverPhonePaymentConfigurationOverview extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        return (            
            
            <table className='component-table'>
                <tr>                    
                    <td>
                        <InputComponent
                            inputType="name"
                            fieldName="title"
                            caption="Title"
                            placeholder="Enter title for the Web App"
                            hintText="Title is shown in the browser tab. (Required)"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="code"
                            fieldName="applicationId"
                            caption="Application ID"
                            placeholder="Enter the application ID for Web App"
                            inputReadOnly={true}
                            hintText="Application identifier for your web application"
                            formManager={this.state.formManager} />
                    </td>
                        
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="email"
                            fieldName="emailReplyToAddress"
                            caption="Reply-To Email"
                            placeholder="Email where customers replies should be sent"
                            hintText="This email will set as reply address on customer sales receipt email (Optional)"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Enabled"
                            fieldName="isEnabled"
                            hintText="To enable over phone payment option on your POS"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>              
            </table>
        );
    }    
}

export default OverPhonePaymentConfigurationOverview;