

import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import EndorsementSetting from './endorsementSetting';

import SelectComponent from '../../../../components/form/selectComponent';
import formManager from '../../../../utils/view/formManager';

const stationState = require('../stationState');

const constants = require('../../../../utils/domain/constants');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');
const viewUtil = require('../../../../utils/view/viewUtil');
const typeUtil = require('../../../../utils/type/typeUtil');
const validator = require('../../../../utils/validator/validator');
const stringUtil = require('../../../../utils/string/stringUtil');
const externalIntegrationUtils = require('../../../../utils/domain/externalIntegrations/externalIntegrationUtils');
const terminalTypeConstants = require('../../../../utils/constants/terminalTypeConstants');

const stationUtil = require('../stationUtil');

const integrationType = externalIntegrationUtils.integrationTypes.saleEndorsement;
const saleEndorsementEntities = externalIntegrationUtils.externalEntities.saleEndorsement;

const guidUtil = require('../../../../utils/guid/guidUtil');
const configurationManager = require('../../../../utils/config/configurationManager');
class EndorsementSettings extends Component 
{
    constructor(props)
    {
        super(props);

        this.addNewSalesEndorsement = this.addNewSalesEndorsement.bind(this);

        this.state = {};
        this.state.currentSelectedEndorsement = null;

        const franchisorId = currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId;
        const franchisor = orgSelectors.selectFranchisor(franchisorId);

        if (franchisor.country === "Pakistan" || franchisor.country === "Kenya")
        {
            const stationViewModel = stationState.currentStationViewModel;

            if (stationViewModel.stationType === terminalTypeConstants.pos)
            {
                this.state.clientTerminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.pos);
            }
            else if (stationViewModel.stationType === terminalTypeConstants.kiosk)
            {
                this.state.clientTerminal = stationState.currentStationViewModel.terminals.find(t => t.type === constants.TerminalTypes.kiosk);
            }
        }
    }

    render()
    {

        if (this.state.currentSelectedEndorsement != null)
        {
            return <EndorsementSetting saleEndorsement={this.state.currentSelectedEndorsement} 
                        clientTerminal={this.state.clientTerminal}
                        isReadOnly={this.props.isReadOnly}
                        onClose={() =>
                        {
                            this.state.currentSelectedEndorsement = null;
                            this.setState({});
                        }} />
        }

        return (<Card>
            <CardHeader>
                {this.renderToolbar()}
            </CardHeader>
            <CardBody>
                <div style={{ minHeight: window.innerHeight * 0.50 }}>
                    {this.renderOptions()}
                </div>
            </CardBody>
        </Card>
        );
    }

    renderToolbar()
    {

        let addNewDisable = false;
        if (this.state.clientTerminal == null)
        {
            addNewDisable = true
        }
        else
        {
            var salesEndorsements = this.getSalesEndorsements();

            if (salesEndorsements.some(x => x.externalEntity === saleEndorsementEntities.pkFBR) &&
                salesEndorsements.some(x => x.externalEntity === saleEndorsementEntities.pkSRB))
            {
                addNewDisable = true;
            }
        }

        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Sales Endorsements</div>
                    </td>
                    <td align="right">
                        {
                            !this.props.isReadOnly &&
                            <Button color="success" className="btn-success" style={{ marginRight: '10px' }} disabled={addNewDisable} onClick={this.addNewSalesEndorsement}>
                                Add New Sales Endorsement
                            </Button>
                        }
                        <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={() => { this.props.onClose() }}>
                            Close
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }

    renderOptions()
    {
        if (this.state.clientTerminal == null)
        {
            return <div style={{ background: '#F6F9FF', minHeight: '100%', paddingTop: '80px', paddingLeft: '50px', paddingRight: '50px', textAlign: 'center' }}>
                <h1 style={{ color: '#A9A9A9' }}>No sales endorsements settings required for this country / station</h1>
            </div>
        }

        var salesEndorsements = this.getSalesEndorsements();
        var components = [];

        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;
        salesEndorsements.forEach(saleEndorsement =>
        {
            components.push(<div style={{
                                        border: '1px solid #C8C8C8', background: 'white', marginRight: '20px',
                                        marginBottom: '30px', minWidth: '400px', width: '25vw', minHeight: '100px',
                                        paddingTop: '20px', paddingBottom: '20px', cursor: 'default', userSelect: 'none'
                                    }} 
                                    onClick={() =>
                                    {
                                        this.state.currentSelectedEndorsement = saleEndorsement;
                                        this.setState({});
                                    }}>

                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                                <img src={`${rootUrl}/app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.settings.endorsements.png`} style={{ width: '80px', marginRight: '20px', marginLeft: '20px' }} />
                                            </td>
                                            <td style={{ width: '99%' }}>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <h3 style={{ margin: '0px' }}>{saleEndorsement.externalEntity}</h3>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <small>{saleEndorsement.externalEntity} Sales Endorsement</small>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>)
        })

        if (components.length <= 0)
        {
            return <div style={{ textAlign: 'center', marginTop: '100px' }}> No Sales Endorsements Found.</div>
        }

        return <div style={{ background: '#F6F9FF', minHeight: '100%', marginTop: '-16px', marginLeft: '-16px', marginRight: '-16px', paddingTop: '40px', paddingLeft: '40px', paddingRight: '40px' }}>
            <div className="d-flex flex-row flex-wrap w-100">
                {components}
            </div>
        </div>

    }

    addNewSalesEndorsement()
    {
        var salesEndorsement = {};

        salesEndorsement.id = guidUtil.generateGuid();
        salesEndorsement.integrationType = integrationType;
        salesEndorsement.externalEntity = null;
        salesEndorsement.systemType = externalIntegrationUtils.systemTypes.saleEndorsement.terminal;
        salesEndorsement.systemId = this.state.clientTerminal.id;
        salesEndorsement.isEnabled = true;
        salesEndorsement.jsonData = {};



        this.state.currentSelectedEndorsement = salesEndorsement;
        this.setState({});
    }

    getSalesEndorsements()
    {
        var saleEndorsements = [];

        var terminal = this.state.clientTerminal;

        var integrations = stationState.integrations.get(terminal.id);
        if (integrations == null)
            integrations = [];

        var salesEndorsementIntegrations = integrations.filter(k => k.integrationType == integrationType);
        if (salesEndorsementIntegrations == null)
            return saleEndorsements;

        salesEndorsementIntegrations.forEach(salesEndorsement =>
        {
            var endorsement = {};

            endorsement.id = salesEndorsement.id;
            endorsement.integrationType = salesEndorsement.integrationType;
            endorsement.externalEntity = salesEndorsement.externalEntity;
            endorsement.systemType = salesEndorsement.systemType;
            endorsement.systemId = salesEndorsement.systemId;
            endorsement.isEnabled = salesEndorsement.isEnabled;

            endorsement.jsonData = typeUtil.deepCloneObject(salesEndorsement.jsonData);


            saleEndorsements.push(endorsement);
        });

        return saleEndorsements;
    }

}

export default EndorsementSettings;