const columnWidthConstants = require("../../../../../utils/constants/columnWidthConstants");
const rmsApiProxy = require("../../../../../utils/api/rmsApiProxy");
const brandApiFacade = require("../../../../../utils/api/brandApiFacade");
const constants = require("../../../../../utils/domain/constants");
const stringUtil = require("../../../../../utils/string/stringUtil");
const guidUtil = require("../../../../../utils/guid/guidUtil");
const securityManager = require("../../../../../utils/domain/security/securityManager");

export function getGridColumns()
{
    const cols = [
        {
            field: "name",
            headerName: "Name",
            pinned: true,
            suppressSizeToFit: false,
            width: columnWidthConstants.name,
        },
        {
            field: "country",
            headerName: "Country",
            pinned: true,
            width: columnWidthConstants.name,
        }
    ]
    if(securityManager.isSystemUser())
    {
        cols.push({
            field: "appId",
            headerName: "App Id",
            pinned: true,
            width: columnWidthConstants.name,
        })
    }

    return cols
}

export async function loadBrands()
{
    return await brandApiFacade.loadBrands();
}

export function getNewBrand()
{
    return {
        id: guidUtil.generateGuid(),
        name: "",
        country: "",
        logoUrl: "",
    }
}

export async function postBrand(brand)
{
    return await brandApiFacade.postBrand(brand);
}

export function validate(isNewRow, rowData)
{
    if (stringUtil.isStringNullOrEmpty(rowData.name))
        return "Brand name cannot be empty";

    if (stringUtil.isStringNullOrEmpty(rowData.appId))
        return "Brand App Id cannot be empty";

    return null;
}
