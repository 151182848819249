
import React, { Component, Fragment } from "react";
import { Card, CardText, CardBody } from 'reactstrap';
import { CloudinaryContext, Image } from 'cloudinary-react';
import Stencil from '../utils/Stencil.js'
import constants from '../../../../../../utils/domain/constants.js'


var configurationManager = require('../../../../../../utils/config/configurationManager.js');

class LayoutToolBar extends Component
{
    constructor(props)
    {
        super(props);
        this.stencils = this.constructStencils();
    }

    render()
    {
        var components = []
        this.stencils.forEach(stencil =>
            {
                components.push(this.renderStencilTool(stencil));
            });

        return (
                    <Fragment>
                        {components}
                    </Fragment>
               );
    }

    renderStencilTool(stencil)
    {
        return (<div style={{marginTop:'10px', marginBottom:'10px', userSelect:'none', cursor:'pointer'}} 
                     onClick={()=>
                                {
                                    if (this.props.onStencil == null)
                                        return;

                                    this.props.onStencil(stencil);
                                }}>
                    <Card className="text-center">
                        <CardBody>
                            <div align="center" style={{ fontSize: '1rem' }}>
                                {this.renderStencilImage(stencil)}
                                <div>
                                    <CardText>
                                        <div className="text-muted" style={{marginTop:'10px'}}>{stencil.title}</div>
                                    </CardText>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>);
    }

    renderStencilImage(stencil)
    {
        var cloudName = configurationManager.getConfig().cdnAppCloudName;
        return <CloudinaryContext cloudName={cloudName}>
                        <div>
                            <Image publicId={stencil.imageUrl} width={stencil.toolBarImageWidth} height={stencil.toolBarImageHeight}/>
                        </div>
                    </CloudinaryContext>;
    }

    constructStencils()
    {
        var stencils = [];

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "One Person";
        stencil.imageUrl = "app/back.office/stencils/01.person.01.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 1;
        stencil.width = 140;
        stencil.height = 140;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Two Person";
        stencil.imageUrl = "app/back.office/stencils/02.person.01.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 2;
        stencil.width = 140;
        stencil.height = 215;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Two Person";
        stencil.imageUrl = "app/back.office/stencils/02.person.02.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 2;
        stencil.width = 222;
        stencil.height = 115;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Three Person";
        stencil.imageUrl = "app/back.office/stencils/03.person.01.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 3;
        stencil.width = 323;
        stencil.height = 122;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Four Person";
        stencil.imageUrl = "app/back.office/stencils/04.person.01.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 4;
        stencil.width = 236;
        stencil.height = 184;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Four Person";
        stencil.imageUrl = "app/back.office/stencils/04.person.02.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 4;
        stencil.width = 160;
        stencil.height = 160;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Four Person";
        stencil.imageUrl = "app/back.office/stencils/04.person.03.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 4;
        stencil.width = 160;
        stencil.height = 160;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Six Person";
        stencil.imageUrl = "app/back.office/stencils/06.person.01.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.noOfSeating = 6;
        stencil.width = 324;
        stencil.height = 194;
        stencil.type = constants.floorStencilType.Seating;
        stencils.push(stencil);


        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Plant";
        stencil.imageUrl = "app/back.office/stencils/decoration.plant.01.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.width = 160;
        stencil.height = 160;
        stencil.type = constants.floorStencilType.Plants;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Plant";
        stencil.imageUrl = "app/back.office/stencils/decoration.plant.02.jpg";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.width = 160;
        stencil.height = 160;
        stencil.type = constants.floorStencilType.Plants;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Plant";
        stencil.imageUrl = "app/back.office/stencils/decoration.flower.01.jpg";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.width = 160;
        stencil.height = 160;
        stencil.type = constants.floorStencilType.Plants;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Plant";
        stencil.imageUrl = "app/back.office/stencils/decoration.flower.02.jpg";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.width = 160;
        stencil.height = 160;
        stencil.type = constants.floorStencilType.Plants;
        stencils.push(stencil);

        var stencil = new Stencil();
        stencil.id = "";
        stencil.floorId = "";
        stencil.title = "Plant";
        stencil.imageUrl = "app/back.office/stencils/decoration.plant.divider.png";
        stencil.toolBarImageWidth = 64;
        stencil.toolBarImageHeight = null;
        stencil.width = 300;
        stencil.height = 80;
        stencil.type = constants.floorStencilType.Plants;
        stencils.push(stencil);

        return stencils;

    }
}

export default LayoutToolBar;