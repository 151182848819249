const LookupData = require('./lookupData');
const actionTypes = require('../../../actionTypes');
const catalogSelectors = require('../../../stateSelectors/catalogSelectors');


module.exports.createAction = function (lookupData)
{
    return (
        {
            type: actionTypes.catalogLookupData,
            payload: Object.assign(new LookupData(), lookupData)
        }
    );
}

module.exports.changeState = function (previousState, payload)
{
    const catalogData = Object.assign({}, previousState.catalogData, { lookupData: payload });
    return Object.assign({}, previousState, { catalogData });
}

// Update actions

module.exports.createUpdateAction = function (lookupName, lookupValues)
{
    const lookupItem = {};
    lookupItem[lookupName] = lookupValues;

    const lookupData = Object.assign({}, catalogSelectors.selectLookupData(), lookupItem);

    return this.createAction(lookupData);
}

