import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import ErrorMessage from '../../../../../components/error/errorMessage';
import Spinner from '../../../../../components/spinner/spinner';
import GridComponent from '../../../../../components/grid/gridComponent';
import EmptyData from '../../../../../components/empty/emptyData';
import GridPageSize from '../../../../../components/gridView/gridPageSize';

const arraySort = require('array-sort');

const apiProxy = require('../../../../../utils/api/apiProxy');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');
const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');

export default class CreditAccountTransactions extends Component
{
    constructor(props)
    {
        super(props)

        this.onGridReady = this.onGridReady.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.renderRowsPerPageComponent = this.renderRowsPerPageComponent.bind(this);

        this.state = {};
        this.state.pageSize = 50;

        this.state.isLoading = false;
        this.state.data = null;
        this.state.error = null;
    }

    async componentDidMount()
    {
        this.state.isLoading = true;
        this.setState({});

        try 
        {
            const creditBookId = this.props.creditAccount.creditBookId;
            const creditAccountId = this.props.creditAccount.id;

            const creditAccountTransactions = await rmsApiProxy.get(
                `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks/${creditBookId}/${creditAccountId}`);

            arraySort(creditAccountTransactions, ['dateTime'], { reverse: true });

            this.state.isLoading = false;
            this.state.data = creditAccountTransactions;
            this.setState({});
        }
        catch (error) 
        {
            this.state.error = error;
            this.setState({});
        }
    }

    render() 
    {
        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                </CardBody>
            </Card>

        );
    }

    renderBody()
    {
        if ((this.state.isLoading))
            return <Spinner text='Loading Data'></Spinner>;

        if (this.state.error)
            return <ErrorMessage message={this.state.error} />;

        if (this.state.data == null || this.state.data.length == 0)
            return <EmptyData title="Transactions" text="No transactions for the credit account"></EmptyData>;

        return <GridComponent headers={this.constructGridColumnHeaders()}
            onGridReady={this.onGridReady}
            paginationEnabled={true}
            paginationSize={this.state.pageSize}
            rows={this.state.data}
            shouldReArrangeHeaders={true}
        />
    }


    renderHeader()
    {
        return (
            <div className='w-100 px-1 d-flex justify-content-between align-items-center'>

                <div className='fw-bold'>{this.props.creditAccount.name} Transactions</div>

                <div className='flex-grow-1 d-flex justify-content-end align-items-center'>
                    <div className='me-3 w-25'>{this.renderRowsPerPageComponent()}</div>
                    <div>
                        <Button
                            className="px-4" color="dark"
                            onClick={this.props.onClose}>
                            <span><i className="fa fa-sign-out" />&nbsp;Close</span>
                        </Button>
                    </div>
                </div>

            </div>
        );
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "dateTime";
        header.headerName = "Date / Time";
        header.type = dataTypeConstants.dateTime;
        header.minWidth = columnWidthConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "amount";
        header.headerName = "Amount";
        header.type = dataTypeConstants.money;
        header.minWidth = columnWidthConstants.money + 25;
        headers.push(header);

        header = {};
        header.field = "type";
        header.headerName = "Type";
        header.minWidth = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "reference";
        header.headerName = "Reference";
        header.minWidth = columnWidthConstants.description;
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }

    renderRowsPerPageComponent()
    {
        return <GridPageSize
            gridPageSize={this.state.pageSize}
            onChange={(value) => 
            {
                if (this.gridApi != null)
                {
                    this.gridApi.paginationSetPageSize(Number(value));
                }

                this.setState({ pageSize: value });
            }} />
    }
}

