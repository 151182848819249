import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import { Card, CardBody, CardHeader } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../components/form/selectComponent';
import * as arraySort from 'array-sort';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontMobileAppConfigurationHelper = require('./storefrontMobileAppConfigurationHelper');
const constants = require('../../../../../utils/domain/constants');
const FormManager = require('../../../../../utils/view/formManager.js');
const viewUtil = require('../../../../../utils/view/viewUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontMobileAppConfigurations extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {
            formManager: new FormManager(),
            isLoading: false,
            storefront: this.props.params
        };
        
        this.state.storefrontMobileAppConfigurations = {};
        this.state.formManager.viewModel = this.state.storefrontMobileAppConfigurations;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({isLoading: true});
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let storefrontMobileAppConfigurations = await storefrontMobileAppConfigurationHelper.loadStorefrontMobileAppConfigurations(this.state.storefront);
            
            this.state.storefrontMobileAppConfigurations = storefrontMobileAppConfigurations || {};
            this.state.formManager.viewModel = this.state.storefrontMobileAppConfigurations;
            
            this.setState({});
        }
        catch(error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({isLoading: false});
        }
    }

    async onSaveClicked()
    {
        const validationError = storefrontMobileAppConfigurationHelper.validateMobileAppConfiguration(this.state.storefrontMobileAppConfigurations)
        
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }
        
        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            await storefrontMobileAppConfigurationHelper.save(this.state.storefront, this.state.storefrontMobileAppConfigurations);
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration, 
                                                      `${this.state.storefront.name} storefront Mobile App configurations have been altered`, 
                                                      this.state.storefrontMobileAppConfigurations);
        }
        catch(error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
            actionDispatcherUtil.showOptionsUI();
        }
    }

    getPaymentProviders()
    {
        const options = Object.values(constants.mobileAppOnlinePaymentProviders).map(provider => ({label: provider}));

        return arraySort(options, "sortIndex", "label");
    }

    getEnvironments()
    {
        const options = Object.values(constants.brainTreeEnvironments).map(provider => ({label: provider}));

        return arraySort(options, "sortIndex", "label");
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }
        
        var component;

        <CardHeader>
            <EditComponentHeader
                title="Storefront"
                isReadOnly={this.props.isReadOnly}
                onSaveClicked={this.onSaveClicked}
                onCancelClose={true} />
        </CardHeader>

        if(stringUtil.areStringSame(this.state.formManager.viewModel.paymentProvider,  constants.mobileAppOnlinePaymentProviders.BrainTree))
        {
            component = 
                <>
                    <tr>
                        <td>
                            <InputComponent
                                key="merchantId"
                                fieldName="merchantId"
                                caption="Merchant ID"
                                placeholder="Enter Merchant ID"
                                hintText="Required"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                key="merchantAccountId"
                                fieldName="merchantAccountId"
                                caption="Merchant Account ID"
                                placeholder="Merchant Account ID"
                                hintText="Required"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                key="privateKey"
                                fieldName="privateKey"
                                caption="Private Key"
                                placeholder="Enter Private Key"
                                hintText="Required"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                key="publicKey"
                                fieldName="publicKey"
                                caption="Public Key"
                                placeholder="Enter Public Key"
                                hintText="Required"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>                        
                    </tr>
                    <tr>
                        <td>
                            <SelectComponent
                                optionValues={this.getEnvironments()}
                                optionFieldName="label"
                                optionDisplayFieldName="label"
                                caption="Environment"
                                fieldName="environment"
                                hintText="Required"
                                hintFieldName="Enter Environment"
                                clearable={true}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>

                        </td>                        
                    </tr>
                </>
        }

        return (            
            <Card>
                <CardHeader>
                    <EditComponentHeader title="Storefront" onSaveClicked={this.onSaveClicked} onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    <table className='component-table'>
                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={this.getPaymentProviders()}
                                    optionFieldName="label"
                                    optionDisplayFieldName="label"
                                    caption="Payment Provider"
                                    fieldName="paymentProvider"
                                    hintText="Required if payment method credit is selected"
                                    hintFieldName="Enter Payment Provider"
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.state.formManager} />
                            </td>
                            <td>
                                
                            </td>
                        </tr>
                        {component}                
                    </table>
                </CardBody>
            </Card>
        );
    }    
}

export default StorefrontMobileAppConfigurations;