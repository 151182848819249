import React, { Component } from 'react';

import InputComponent from '../../../../components/form/inputComponent'
import SelectComponent from '../../../../components/form/selectComponent'

const FormManager = require('../../../../utils/view/formManager');

class Category extends Component 
{

    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }


    render() 
    {
        const categoryTypes = [
            {
                name: 'Income',
                notes: 'Indicate an account to hold incoming'
            },

            {
                name: 'Expense',
                notes: 'Indicate an account to hold outgoing'
            },

            {
                name: 'Tax',
                notes: 'Indicate an account to indicate tax related outgoing (GST / VAT / Corporation Tax etc)'
            },
        ];

        return (
            <table className="component-table">

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Category Name"
                            hintText="Required"
                            fieldName="name"
                            placeholder="Enter name of accounts category"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.state.formManager} />
                    </td>

                    <td>
                        <SelectComponent
                            optionValues={categoryTypes}
                            optionFieldName="name"
                            optionDisplayFieldName="name"
                            caption="Category Type"
                            hintText="Select type of accounts category" hintFieldName="notes"
                            fieldName="type"
                            formManager={this.state.formManager}
                            clearable={false}
                            searchable={false}
                            comboReadOnly={this.props.isRowReadOnly} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            multiLine={true}
                            caption="Notes"
                            rows={4}
                            fieldName="notes"
                            placeholder="Enter any notes"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>

            </table>
        )
    }
}

export default Category;

