import React, { Component } from 'react';
import Spinner from '../../../../../components/spinner/spinner';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');

export default class CampaignExecutionReport extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            isLoading: false,
            executionSummary: null
        }
    }

    async componentDidMount()
    {
        const campaign = this.props.data;

        if (stringUtil.isStringNullOrEmpty(campaign.actualStartDateTime))
            return;

        this.setState({ isLoading: true });

        const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/marketing/campaigns/${campaign.id}/executionSummary`;
        const executionSummary = await rmsApiProxy.get(resourceUrl);

        this.setState({ isLoading: false, executionSummary: executionSummary });
    }

    render()
    {
        if (this.state.isLoading)
        {
            return <Spinner text="Loading execution summary" />
        }

        const campaign = this.props.data;

        if (this.state.executionSummary == null)
        {
            if (stringUtil.isStringNullOrEmpty(campaign.actualStartDateTime))
                return <div>Campaign has not been started</div>

            return null;
        }

        const executionSummary = this.state.executionSummary;

        return <div>
            <p><b>Published messages:</b>&nbsp;{executionSummary.published}</p>
            <p><b>In Progress:</b>&nbsp;{executionSummary.inProgress}</p>
            <p><b>Failed:</b>&nbsp;{executionSummary.failed}</p>
            <hr></hr>
            <div><b>Target Count:</b>{executionSummary.targetCount}</div>
        </div>
    }
}
