import React from "react";
import styled from "styled-components";

const ViewEditBtn = (props) => {
     const { color, ...rest } = props;
     return (
          <Btn color={color} {...rest}>
               View / Edit
          </Btn>
     );
};

export default ViewEditBtn;

const Btn = styled.div`
     position: absolute;
     top: 0;
     right: 0;
     padding: 8px 20px;
     border: 2px solid ${(props) => props.color ?? "#0b75d7"};
     border-bottom-left-radius: 10px;
     border-top-right-radius: 15px;
     color: #313131;
     font-weight: 600;
     transition: 250ms ease;
     cursor: pointer;

     :hover {
          background-color: ${(props) => props.color ?? "#0b75d7"};
          color: white;
     }
`;
