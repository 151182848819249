import React, { Component } from 'react';


import ActionInputComponent from '../taskUtils/actionInputComponent';
import ActionListComponent from '../taskUtils/actionListComponent';

import viewUtil from '../../../../../utils/view/viewUtil';
import typeUtil from '../../../../../utils/type/typeUtil';
import stringUtil from '../../../../../utils/string/stringUtil';


class TaskActions extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            taskActions: this.props.formManager.viewModel.taskActions,
            selectedTaskAction: {},
            clonedTaskAction: null
        }
    }

    validateTaskAction = (taskAction) =>
    {
        if (stringUtil.isStringNullOrEmpty(taskAction.name))
            return "Task action name is required";
    }

    onAddUpdateTaskAction = (selectedTaskAction) =>
    {
        let validationError = this.validateTaskAction(selectedTaskAction);
        if (validationError)
        {
            viewUtil.showErrorAlert(validationError);
            return;
        }

        const taskActions = this.state.taskActions;

        const tempTaskAction =
            taskActions.find(taskAction =>
                taskAction.name.toLowerCase() == selectedTaskAction.name.toLowerCase());

        if (tempTaskAction != null)
        {
            if (tempTaskAction != selectedTaskAction)
            {
                // Reference equality
                viewUtil.showErrorAlert("A matching task action name already exists");
                return;
            }
        }

        const index = taskActions.indexOf(selectedTaskAction);

        if (index > -1)
        {
            taskActions.splice(index, 1, selectedTaskAction);
        }
        else
        {
            taskActions.push(selectedTaskAction);
        }

        this.setState({ selectedTaskAction: {} });
    }

    onDeleteTaskAction = (selectedTaskAction) =>
    {
        const taskActions = this.state.taskActions;
        const index = taskActions.indexOf(selectedTaskAction);  // Reference equality

        if (index > -1)
        {
            taskActions.splice(index, 1);
        }

        this.setState({ selectedTaskAction: {} });
    }

    onCancelTaskAction = () => 
    {
        if (this.state.selectedTaskAction)
        {
            if (this.state.clonedTaskAction)
            {
                // Use clone to restore object back to its original state
                Object.assign(this.state.selectedTaskAction, this.state.clonedTaskAction);
            }
        }

        this.setState({ selectedTaskAction: {} });
    }

    onSelectTaskAction = (selectedTaskAction) =>
    {
        //
        // Implies an existing object selected for edit,
        // save a clone in the state to restore original object back upon Cancel
        //




        this.setState({
            selectedTaskAction: selectedTaskAction,
            clonedTaskAction: typeUtil.deepCloneObject(selectedTaskAction)
        });
    }

    render()
    {
        const taskActions = this.state.taskActions;
        const selectedTaskAction = this.state.selectedTaskAction;

        return (
            <div className="d-flex flex-row">

                <div className="border rounded me-2 w-50">
                    <ActionInputComponent
                        isRowReadOnly={this.props.isRowReadOnly}
                        selectedTaskAction={selectedTaskAction}
                        onAddUpdateTaskAction={this.onAddUpdateTaskAction}
                        onCancelTaskAction={this.onCancelTaskAction}
                    />
                </div>

                <div className="border rounded ms-2 w-100">
                    <ActionListComponent
                        isRowReadOnly={this.props.isRowReadOnly}
                        taskActions={taskActions}
                        selectedTaskAction={selectedTaskAction}
                        onSelectTaskAction={this.onSelectTaskAction}
                        onDeleteTaskAction={this.onDeleteTaskAction}
                    />
                </div>
                
            </div>);
    }
}

export default TaskActions;