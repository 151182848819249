import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as nomenclatureUtils from '../../../../../../utils/domain/nomenclatureUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';
import * as venueCommissionCostSummaryUtil from '../../viewUtils/venueCommissionCostSummaryUtil';

export default function TabularReport(props)
{
    const masterRows = getMasterRows(props);

    const totalRow = {
        venueCode: 'Total',
        netOrderPriceIncTax: masterRows.reduce((value, data) => value + data.netOrderPriceIncTax, 0),
        venueCommission: masterRows.reduce((value, data) => value + data.venueCommission, 0)
    };

    totalRow.venueCommissionPercentage = totalRow.venueCommission / totalRow.netOrderPriceIncTax * 100;

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    return (
        <div className="ag-theme-alpine">
            <AgGridReact
                rowData={masterRows}
                pinnedBottomRowData={[totalRow]}
                columnDefs={getMasterGridColumns(props)}
                gridOptions={biUtils.getGridOptionsForMasterRows()}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
            />
        </div>
    );
}

function getMasterRows(props)
{
    return venueCommissionCostSummaryUtil.getVenueCommissionCostSummaryData(props);
}

function getMasterGridColumns(props)
{
    let header;
    const headers = [];

    header = {};
    header.field = "venueCode";
    header.headerName = "Venue";
    headers.push(header);

    header = {};
    header.field = "venueCommissionPercentage";
    header.headerName = "Commission %";
    header.type = dataTypeConstants.percentage;
    headers.push(header);

    header = {};
    header.field = "netOrderPriceIncTax";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.netOrderPriceIncTax);
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "venueCommission";
    header.headerName = "Commission";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}
