import React, { Component } from 'react';
import { Button } from 'reactstrap';

import InputComponent from '../../../../../components/form/inputComponent';
import CheckComponent from '../../../../../components/form/checkComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import QuestionTypeOptionsComponent from './QuestionTypeOptionsComponent';
import FormManager from '../../../../../utils/view/formManager';

const domainConstants = require('../../../../../utils/domain/constants');
const stringUtil = require('../../../../../utils/string/stringUtil');
const constants = require('../../../../../utils/domain/constants');
class QuestionInputComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = this.getState(this.props.selectedIncidentTypeQuestion);
    }

    getState = (selectedIncidentTypeQuestion) => 
    {
        selectedIncidentTypeQuestion.question = selectedIncidentTypeQuestion.question || "";
        selectedIncidentTypeQuestion.isCompulsory = selectedIncidentTypeQuestion.isCompulsory || false;
        selectedIncidentTypeQuestion.responseType = selectedIncidentTypeQuestion.responseType || "";
        selectedIncidentTypeQuestion.responseOptions = selectedIncidentTypeQuestion.responseOptions || [];
        selectedIncidentTypeQuestion.selectedOption = selectedIncidentTypeQuestion.selectedOption || "";

        const formManager = new FormManager();
        formManager.viewModel = selectedIncidentTypeQuestion;
        formManager.view = this;

        formManager.onFieldChanged = this.onFieldChanged;

        return {
            isDirty: false,
            isAddNew: stringUtil.isStringNullOrEmpty(formManager.viewModel.question),
            isOptionAddNew : stringUtil.isStringNullOrEmpty(formManager.viewModel.selectedOption),
            formManager: formManager
        }
    }

    onFieldChanged = () => 
    {
        if (!this.state.isDirty)
        {
            this.setState({ isDirty: true });
        }
    }

    onAddUpdate = () => this.props.onAddUpdateIncidentTypeQuestion(this.state.formManager.viewModel);
    onCancel = () => this.props.onCancelIncidentTypeQuestion(this.state.formManager.viewModel);

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.selectedIncidentTypeQuestion != prevProps.selectedIncidentTypeQuestion)
        {
            this.setState(this.getState(this.props.selectedIncidentTypeQuestion));
        }
    }

    render()
    {
        const isDirty = this.state.isDirty;
        const isAddNew = this.state.isAddNew;
        const formManager = this.state.formManager;
        const selectedIncidentTypeQuestion = formManager.viewModel;
        const isReadOnly = this.props.isRowReadOnly;
        const optionValues = Object.values(constants.incidentTypeQuestionResponseTypes).map(responseType => ({"code": responseType}));
        const addDisabled = isReadOnly || !isDirty || stringUtil.isStringNullOrEmpty(selectedIncidentTypeQuestion.question);
        const cancelDisabled = isReadOnly;

        return (
            <div className="d-flex flex-column flex-nowrap">
                <div className="px-4 py-2">
                    <InputComponent
                        inputType="question"
                        caption="Question"
                        fieldName="question"
                        placeholder="Enter question" 
                        hintText="Enter question (Required)"
                        inputReadOnly={isReadOnly}
                        formManager={formManager} />
                </div>
                <div className="px-4 py-2">
                    <SelectComponent
                        optionValues={optionValues}
                        optionFieldName="code"
                        optionDisplayFieldName="code"
                        fieldName="responseType"
                        caption="Response Type"
                        hintText="Required"
                        clearable={false}
                        formManager={formManager} />
                </div>
                {this.getQuestionTypeOptionsComponent()}
                <div className="px-5 py-2">
                    <CheckComponent
                        caption="Is Compulsory"
                        fieldName="isCompulsory"
                        inputReadOnly={isReadOnly}
                        formManager={formManager} />
                </div>
                <div className="d-flex flex-row flex-nowrap justify-content-end">
                    <Button color="primary" style={{ width: "25%", margin: "0 10px 10px 0" }}
                        disabled={addDisabled}
                        onClick={this.onAddUpdate}>
                        {isAddNew ? "Add" : "Update"}
                    </Button>
                    <Button color="warning" style={{ width: "25%", margin: "0 25px 10px 0" }}
                        disabled={cancelDisabled}
                        onClick={this.onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }

    getQuestionTypeOptionsComponent()
    {
        if(stringUtil.areStringSame(this.state.formManager.viewModel.responseType, domainConstants.incidentTypeQuestionResponseTypes.options))
        {
            return  <QuestionTypeOptionsComponent
                        formManager={this.state.formManager}
                        isRowReadOnly={this.props.isRowReadOnly} />
        }
    }
}

export default QuestionInputComponent;