import React from 'react';

/**************** SALES BI Tool *************************************************/
const BITodaysStatusReport = React.lazy(() => import('../../bi/sales/TodaysStatusReport/TodaysStatusReport'));
const BIDailySales = React.lazy(() => import('../../bi/sales/DailySales'));
const BIDailySalesByVenue = React.lazy(() => import('../../bi/sales/DailySalesByVenue'));
const BIDailySalesByPaymentMethod = React.lazy(() => import('../../bi/sales/DailySalesByPaymentMethod'));
const BIHourlySales = React.lazy(() => import('../../bi/sales/HourlySales'));
const BIDailySalesByTable = React.lazy(() => import('../../bi/sales/DailySalesByTable'));
const BIHourlySalesByTable = React.lazy(() => import('../../bi/sales/HourlySalesByTable'));
const BISalesVsProjections = React.lazy(() => import('../../bi/sales/SalesVsProjections'));
const BIDailyCustomerRetention = React.lazy(() => import('../../bi/sales/DailyCustomerRetention'));
const BIDailySalesDiscounts = React.lazy(() => import('../../bi/sales/DailySalesDiscounts'));
const BIDailySalesRefunds = React.lazy(() => import('../../bi/sales/DailySalesRefunds'));
const BIDailySalesCharges = React.lazy(() => import('../../bi/sales/DailySalesChargesReport/DailySalesCharges'));
const BIDailySalesByVenueAndMileage = React.lazy(() => import('../../bi/sales/DailySalesByVenueAndMileage'));


/**************** MENU BI Tool *************************************************/
const SalesByMenuItem = React.lazy(() => import('../../reports/accounts/sales/menuSales/salesByMenuItem/SalesByMenuItem'));
const HourlySalesByMenuItem = React.lazy(() => import('../../reports/accounts/sales/menuSales/hourlySalesByMenuItem/HourlySalesByMenuItem'));
const SalesByReportingTag = React.lazy(() => import('../../reports/accounts/sales/menuSales/salesByReportingTag/SalesByReportingTag'));
const HourlySalesByReportingTag = React.lazy(() => import('../../reports/accounts/sales/menuSales/hourlySalesByReportingTag/HourlySalesByReportingTag'));
const RefundsByMenuItem = React.lazy(() => import('../../reports/accounts/sales/menuSales/refundsByMenuItem/RefundsByMenuItem'));

const BIDailyMenuItemSales = React.lazy(() => import('../../bi/menu/DailyMenuItemSales'));
const BIDailyMenuReportingTagSales = React.lazy(() => import('../../bi/menu/DailyMenuReportingTagSales'));
const BIHourlyMenuReportingTagSales = React.lazy(() => import('../../bi/menu/HourlyMenuReportingTagSales'));
const BIDailyMenuItemRefunds = React.lazy(() => import('../../bi/menu/DailyMenuItemRefunds'));

/**************** Inventory BI Tool *************************************************/
const SalesByProductReport = React.lazy(() => import('../../bi/inventory/salesByProduct/SalesByProductReport'));
const CogsSummaryReport = React.lazy(() => import('../../bi/inventory/cogs/CogsSummaryReport'));
const DiscrepanciesReport = React.lazy(() => import('../../bi/inventory/discrepancies/DiscrepanciesReport'));
const WastagesReport = React.lazy(() => import('../../bi/inventory/wastagesReport/WastagesReport'));
const InventoryStatusReport = React.lazy(() => import('../../bi/inventory/inventoryStatus/InventoryStatusReport'));
const InventoryPurchaseReport = React.lazy(() => import('../../bi/inventory/purchaseReport/InventoryPurchaseReport'));


/**************** Payroll BI Tool *************************************************/
const BIDailyUpsellingPoints = React.lazy(() => import('../../bi/payroll/DailyUpsellingPoints'));
const BIDailyPayrollCosts = React.lazy(() => import('../../bi/payroll/DailyPayrollCosts/DailyPayrollCosts'));
const BIDailySalesByDriver = React.lazy(() => import('../../bi/payroll/DailySalesByDriver'));
const BIDailyStaffMeals = React.lazy(() => import('../../bi/payroll/DailyStaffMeals'));
const BIDailyGuestMeals = React.lazy(() => import('../../bi/payroll/DailyGuestMeals'));
const BIDailyFreebiesMeals = React.lazy(() => import('../../bi/payroll/DailyFreebiesMeals'));


/**************** Accounts BI Tool *************************************************/
const BIDailyVenueCommissions = React.lazy(() => import('../../bi/accounts/DailyVenueCommissions'));
const BIDailySalesRoyalty = React.lazy(() => import('../../bi/accounts/DailySalesRoyalty'));
const BIFixedCosts = React.lazy(() => import('../../bi/accounts/FixedCosts'));
const BIDailySalesVsCosts = React.lazy(() => import('../../bi/accounts/DailySalesVsCosts'));
const BIDailySalesVAT = React.lazy(() => import('../../bi/accounts/DailySalesVAT'));

/**************** System BI Tool *************************************************/
const BIDailySystemSalesStats = React.lazy(() => import('../../bi/system/DailySystemSalesStats'));

/**************** General Tools *************************************************/
const BIPeriodicConsolidatedReport = React.lazy(() => import('../../bi/general/consolidatedReport/PeriodicConsolidatedReport'));
const BIPeriodicSalesSummaryReport = React.lazy(() => import('../../bi/general/salesSummaryReport/PeriodicSalesSummaryReport'));
const BIPeriodicCostsSummaryReport = React.lazy(() => import('../../bi/general/costsSummaryReport/PeriodicCostSummaryReport'));


export function getBIComponent(componentKey)
{
    // componentKey -> componentId::componentName   (where componentId must exist, componentName is optional)

    let component;

    const componentId = componentKey.split("::")[0];

    switch (componentId) 
    {
        /**************** SALES BI Tool *************************************************/

        case "BI.Tools.TodaysStatusReport":
            component = <BITodaysStatusReport componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailySales":
            component = <BIDailySales componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailySalesByVenue":
            component = <BIDailySalesByVenue componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailySalesByPaymentMethod":
            component = <BIDailySalesByPaymentMethod componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.HourlySales":
            component = <BIHourlySales componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailySalesByTable":
            component = <BIDailySalesByTable componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.HourlySalesByTable":
            component = <BIHourlySalesByTable componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.Projection.BISalesVsProjections":
            component = <BISalesVsProjections componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailyCustomerRetention":
            component = <BIDailyCustomerRetention componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailyDiscounts":
            component = <BIDailySalesDiscounts componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailyRefunds":
            component = <BIDailySalesRefunds componentKey={componentKey} />
            break;
        
        case "BI.Tools.Sales.DailyCharges":
            component = <BIDailySalesCharges componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailySalesByVenueAndMileage":
            component = <BIDailySalesByVenueAndMileage componentKey={componentKey} />
            break;

        /**************** MENU BI Tool *************************************************/

        case "BI.Tools.Menu.Item.Sales":
            component = <SalesByMenuItem componentKey={componentKey} />
            break;

        case "BI.Tools.Hourly.Menu.Item.Sales":
            component = <HourlySalesByMenuItem componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Daily.Tags.Sales":
            component = <SalesByReportingTag componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Hourly.Tags.Sales":
            component = <HourlySalesByReportingTag componentKey={componentKey} />
            break;
    
        case "BI.Tools.Menu.DailyMenuItemRefunds":
            component = <RefundsByMenuItem componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Item.Sales.Legacy":
            component = <BIDailyMenuItemSales componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Daily.Tags.Sales.Legacy":
            component = <BIDailyMenuReportingTagSales componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Hourly.Tags.Sales.Legacy":
            component = <BIHourlyMenuReportingTagSales componentKey={componentKey} />
            break;
    
        case "BI.Tools.Menu.DailyMenuItemRefunds.Legacy":
            component = <BIDailyMenuItemRefunds componentKey={componentKey} />
            break;

        /**************** INVENTORY BI Tool *************************************************/

        case "BI.Tools.Inventory.DailySalesByProduct":
            component = <SalesByProductReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.CogsSummary":
            component = <CogsSummaryReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.DailyDiscrepancies":
            component = <DiscrepanciesReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.WastagesReport":
            component = <WastagesReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.InventoryStatusReport":
            component = <InventoryStatusReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.InventoryPurchaseReport":
            component = <InventoryPurchaseReport componentKey={componentKey} />
            break;

        /**************** Payroll BI Tool *************************************************/

        case "BI.Tools.Payroll.DailySalesByDriver":
            component = <BIDailySalesByDriver componentKey={componentKey} />
            break;

        case "BI.Tools.Payroll.DailyStaffMeals":
            component = <BIDailyStaffMeals componentKey={componentKey} />
            break;

        case "BI.Tools.Payroll.DailyUpsellingPoints":
            component = <BIDailyUpsellingPoints componentKey={componentKey} />
            break;


        case "BI.Tools.Payroll.DailyPayrollCosts":
            component = <BIDailyPayrollCosts componentKey={componentKey} />
            break;

        /**************** Accounts BI Tool *************************************************/

        case "BI.Tools.Accounts.DailyVenueCommissions":
            component = <BIDailyVenueCommissions componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.DailySalesRoyalty":
            component = <BIDailySalesRoyalty componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.BIFixedCosts":
            component = <BIFixedCosts componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.DailySalesVsCosts":
            component = <BIDailySalesVsCosts componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.DailySalesVAT":
            component = <BIDailySalesVAT componentKey={componentKey} />
            break;

        /**************** General BI Tool *************************************************/

        case "BI.Tools.Accounts.PeriodicConsolidatedReport":
            component = <BIPeriodicConsolidatedReport componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.PeriodicSummaryReport":
            component = <BIPeriodicSalesSummaryReport componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.Costs.Periodic.Summary.Report":
            component = <BIPeriodicCostsSummaryReport componentKey={componentKey} />
            break;
        
        case "BI.Tools.Accounts.DailyGuestMeals":
            component = <BIDailyGuestMeals componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.DailyFreebiesMeals":
            component = <BIDailyFreebiesMeals componentKey={componentKey} />
            break;

        /**************** System BI Tool *************************************************/

        case "BI.Tools.System.DailySystemSalesStats":
            component = <BIDailySystemSalesStats componentKey={componentKey} />
            break;

        default:
            break;
    }

    return component;
}