import React, { Component } from 'react';
import { Row, Card, CardHeader, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, CardFooter, Table } from 'reactstrap';

import ExcelExportButton from '../../../../components/button/excelExportButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent.js'
import ScaleLoader from "react-spinners/ScaleLoader";

import RotaDialogue from './RotaDialogue.js'
import AttendanceDialogue from './AttendanceDialogue.js'

import Cell from './Cell.js'
import RotaCard from './RotaCard.js'
import AttendanceCard from './AttendanceCard.js'
import RotaHelpComponent from './RotaHelpComponent'


const arraySort = require('array-sort');

const logger = require('../../../../utils/logger/logger');
const apiProxy = require('../../../../utils/api/apiProxy.js');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const rmsApiProxyUtils = require('../../../../utils/api/rmsApiProxyUtils');
const dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
const stringUtil = require('../../../../utils/string/stringUtil.js');
const viewUtil = require('../../../../utils/view/viewUtil.js');
const guidUtil = require('../../../../utils/guid/guidUtil.js');
const typeUtil = require('../../../../utils/type/typeUtil.js');
const validator = require('../../../../utils/validator/validator.js');
const formatter = require('../../../../utils/formatter/formatter.js');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const rotaUtil = require('./RotaUtil.js');

const actionTypes = require('../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../utils/state/actionDispatcher');

const clipboard = require('../../../../components/clipboard/clipboard'); 

const ExcelWorkBook = require('../../../../components/button/excel/excelWorkBook');
const commonUtility  = require('../../../../utils/domain/commonUtility.js');
const constants  = require('../../../../utils/domain/constants.js');
class Rota extends Component 
{
    constructor(props)
    {
        super(props);

        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderBodyHeader = this.renderBodyHeader.bind(this);
        this.renderBodyContents = this.renderBodyContents.bind(this);
        this.renderBodyStaffRowHeader = this.renderBodyStaffRowHeader.bind(this);
        this.renderBodyStaffColumns = this.renderBodyStaffColumns.bind(this);
        this.renderBodyRotaAndAttendance = this.renderBodyRotaAndAttendance.bind(this);
        this.renderRotaCard = this.renderRotaCard.bind(this);
        this.renderAttendanceCard = this.renderAttendanceCard.bind(this);
        this.renderBodyFooter = this.renderBodyFooter.bind(this);

        this.onSchedulePeriodLoad = this.onSchedulePeriodLoad.bind(this);
        this.compileStaffs = this.compileStaffs.bind(this);

        this.onCalendarCellClicked = this.onCalendarCellClicked.bind(this);
        this.onAddRota = this.onAddRota.bind(this);
        this.onAddEditRota = this.onAddEditRota.bind(this);
        this.onAddAttendance = this.onAddAttendance.bind(this);
        this.onAddEditAttendance = this.onAddEditAttendance.bind(this);
        this.onRotaDelete = this.onRotaDelete.bind(this);
        this.onAttendanceDelete = this.onAttendanceDelete.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);

        this.onClearClick = this.onClearClick.bind(this);
        this.onReload = this.onReload.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.renderExportExcel = this.renderExportExcel.bind(this);
        this.extractExcelSheetForActualAttendance = this.extractExcelSheetForActualAttendance.bind(this);
        this.extractExcelSheetForAdjustedAttendance = this.extractExcelSheetForAdjustedAttendance.bind(this);
        this.extractExcelSheetForAttendanceCost = this.extractExcelSheetForAttendanceCost.bind(this);
        this.extractExcelSheetForRota = this.extractExcelSheetForRota.bind(this);
        this.extractExcelSheetForRotaCost = this.extractExcelSheetForRotaCost.bind(this);

        this.state = {};
        this.state.loadCriteria = null;

        this.state.mode = 'none';
        this.state.error = null;

        this.state.staffLoaded = false;
        this.state.allStaffs = [];
        this.state.staffs = [];
        this.state.staffRoles = [];
        this.state.selectedStaffRoleId = null;
        
        this.state.rotas = [];
        this.state.attendances = [];
        this.state.projectedSales = [];
        this.state.dailySales = [];
    }

    componentDidMount()
    {
        document.addEventListener("keydown", this.onKeyDown, false);
    }
      
    componentWillUnmount()
    {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    onKeyDown(event)
    {
        if (event.shiftKey && !event.ctrlKey && !event.altKey &&
            (event.key == "f1" || event.key == "F1"))
        {
            viewUtil.showHelpPanel("Rota and Attendance", ()=><RotaHelpComponent/>)
            event.stopPropagation();
        }
    }

    render()
    {
        return (<Card style={{userSelect:'none'}} >
                    <CardHeader>
                        {this.renderHeader()} 
                    </CardHeader>
                    
                    <CardBody>
                    <div style={{marginTop:'20px'}}>
                        {this.renderBody()}
                    </div>
                    </CardBody>

                    {this.renderBodyFooter()}
                </Card>);
    }

    renderHeader()
    {
        var doPeriodHaveLoaded = this.state.loadCriteria != null && this.state.mode == 'complete';
        var doHaveStaffs = doPeriodHaveLoaded && this.state.staffs != null && this.state.staffs.length > 0;
        var doHaveRotas = doPeriodHaveLoaded && doHaveStaffs && this.state.rotas != null && this.state.rotas.length > 0;
        var doHaveAttendances = doPeriodHaveLoaded && doHaveStaffs && this.state.attendances != null && this.state.attendances.length > 0;
        var doHaveCopiedPayload = doPeriodHaveLoaded && clipboard.hasData(clipboard.dataTypes.rota);

        return(
            <table width="100%">
                <tr width="100%">
                    <td style={{whiteSpace: "nowrap"}}>
                        <div style={{fontSize:'14px',color: '#606060', fontWeight:'bold'}}>Rota</div>
                    </td>

                    <td>
                        <SearchBarComponent disabled={this.state.mode == 'searching'} style={{marginLeft:'20px'}}
                                    searchCriteria={this.state.loadCriteria}
                                    searchButtonCaption = "Load"
                                    onSearch={this.onSchedulePeriodLoad}
                                    onChange= {()=>{
                                                        this.state.mode = 'none';
                                                        this.state.error = null;
                                                
                                                        this.state.staffs = [];
                                                        this.state.rotas = [];
                                                        this.state.attendances = [];

                                                        this.setState({});
                                                   }}/>
                    </td>

                    <td  style={{whiteSpace: "nowrap"}} align = "right">

                        <ButtonDropdown isOpen={this.state.isActionsButtonOpen} style={{marginRight:'30px'}}
                                    toggle={()=>{
                                                    this.state.isActionsButtonOpen = !this.state.isActionsButtonOpen;
                                                    this.setState({})
                                                }}>
                            <DropdownToggle caret color="primary">
                                Actions
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem disabled={!doHaveRotas || !doHaveStaffs || this.props.isReadOnly} onClick={this.onCopy}>
                                    <i className="fa fa-copy"></i>&nbsp;&nbsp;Copy
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem disabled={!doHaveCopiedPayload || this.props.isReadOnly} onClick={this.onPaste}>
                                    <i className="fa fa-paste"></i>&nbsp;&nbsp;Paste
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem disabled={!doHaveRotas || this.props.isReadOnly} onClick={this.onClearClick}>
                                    <i className="fa fa-cut"></i>&nbsp;&nbsp;Clear
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>

                        {this.renderStaffRoles()}

                        <Button disabled={! (doHaveRotas || doHaveAttendances)} color="dark" className="btn-dark" style={{marginRight:'10px'}} onClick={this.onPrint}>
                            <i className="fa fa-print"></i>&nbsp;Print
                        </Button>

                        <Button disabled={!doPeriodHaveLoaded || this.props.isReadOnly} color="success" className="btn-success" style={{marginRight:'3px'}} onClick={this.onPublishClick}>
                            <i className="fa fa-check-square"></i>&nbsp;Publish
                        </Button>

                        <Button disabled={!doPeriodHaveLoaded || this.props.isReadOnly} color="success" className="btn-success" style={{marginRight:'10px'}} onClick={this.onSaveClick}>
                            <i className="fa fa-save"></i>&nbsp;Save Draft
                        </Button>

                        {this.renderExportExcel(!(doHaveRotas || doHaveAttendances))}

                        <Button disabled={!doPeriodHaveLoaded} color="primary" className="btn-primary" style={{marginRight:'10px'}} onClick={this.onReload}>
                            <i className="fa fa-refresh"></i>&nbsp;Reload
                        </Button>

                        {
                            commonUtility.getApplicationMode() !== constants.applicationModes.embedded ? (
                                <Button disabled={this.state.mode == 'searching'} color="dark" className="btn-dark" 
                                onClick={()=>
                                {
                                    const currentOptionAction = {
                                                                    type: actionTypes.mainBarComponent,
                                                                    payload: null
                                                                };
                            
                                    actionDispatcher.dispatch(currentOptionAction);
                                }}>
                            <i className="fa fa-sign-out"></i>&nbsp;Close
                        </Button>
                            ) : null
                        }
                    </td>

                </tr>
            </table>
          );
    }

    renderStaffRoles()
    {
        let components = [];
        let selectedRole = null;
        let color = "secondary";

        if (!stringUtil.isStringNullOrEmpty(this.state.selectedStaffRoleId))
            selectedRole = this.state.staffRoles.find(s => stringUtil.areStringSame(s.id, this.state.selectedStaffRoleId));
    
        if (selectedRole != null)
        {
            color = "danger";
            components.push(<DropdownItem onClick={()=>
                                            {
                                                this.state.selectedStaffRoleId = null;
                                                this.setState({});
                                            }}>
                                                All Roles
                            </DropdownItem>);
        }

        this.state.staffRoles.forEach (staffRole =>
        {
            if (selectedRole == null || !stringUtil.areStringSame(staffRole.id, selectedRole.id))
                components.push(<DropdownItem onClick={()=>
                                                {
                                                    this.state.selectedStaffRoleId = staffRole.id;
                                                    this.setState({});
                                                }}>
                                                    {staffRole.name}
                                </DropdownItem>);
        })

        let caption = "All Roles";
        if (selectedRole != null)
            caption = selectedRole.name;

        
        return <ButtonDropdown isOpen={this.state.isStaffRolesButtonOpen} style={{marginRight:'10px'}}
                                toggle={()=>{
                                                this.state.isStaffRolesButtonOpen = !this.state.isStaffRolesButtonOpen;
                                                this.setState({})
                                            }}>
                    <DropdownToggle caret color={color}>
                        {caption}
                    </DropdownToggle>
                    <DropdownMenu>
                        {components}
                    </DropdownMenu>
                </ButtonDropdown>
    }

    renderBody()
    {
        if (this.state.mode == 'none')
        {
            return (
                    <div className="text-muted" style={{textAlign:'center'}}>
                        After selecting period, please press load button to load schedules.
                    </div>
                   );
        }
        if (this.state.mode == 'searching')
        {
            return (
                    <div>
                            <Row className="justify-content-center">
                                <ScaleLoader
                                    height={50}
                                    width={6}
                                    color={"#808080"}
                                    loading={true}/>
                            </Row>
                            <Row className="justify-content-center" style={{margin:'5px'}}>
                                <h4 className="text-muted">Loading schedules information</h4>
                            </Row>
                        </div>
                 );
        }

        if (this.state.mode == 'saving')
        {
            return (
                    <div>
                            <Row className="justify-content-center">
                                <ScaleLoader
                                    height={50}
                                    width={6}
                                    color={"#808080"}
                                    loading={true}/>
                            </Row>
                            <Row className="justify-content-center" style={{margin:'5px'}}>
                                <h4 className="text-muted">Saving schedules information</h4>
                            </Row>
                        </div>
                 );
        }

        if (this.state.mode == 'error')
        {
            return (
                    <div className="text-muted" style={{textAlign:'center'}}>
                        <div style={{color: '#FFA07A'}}>{this.state.error}</div> 
                    </div>
                   );
        }

        if (this.state.staffs == null || this.state.staffs.length <= 0)
        {
            return (
                <div className="text-muted" style={{textAlign:'center'}}>
                    No active staff found for this period.
                </div>
               );
        }

        return (<div style={{overflowX:'scroll'}}>
                    <Table bordered style={{whiteSpace:'nowrap', width:'100%'}}>
                        {this.renderBodyHeader()}
                        {this.renderBodyContents()}
                    </Table>
                </div>);
    }

    onSchedulePeriodLoad(loadCriteria)
    {
        this.state.loadCriteria = loadCriteria;
        this.state.mode = 'searching';
        this.state.error = null;
        this.state.staffs = [];
        this.state.rotas = [];
        this.state.attendances = [];

        if (!this.state.staffLoaded)
        {
            this.loadStaffs(loadCriteria);
            this.setState({});
            return;
        }
       
        const dailySalesUrl = `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/sales/dailySales?fromDate=${this.state.loadCriteria.startDate}&toDate=${this.state.loadCriteria.endDate}`;
        const projectedSalesUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/projections?fromDate=${this.state.loadCriteria.startDate}&toDate=${this.state.loadCriteria.endDate}`;
        const projectionOverridesUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/projections/overrides?fromDate=${this.state.loadCriteria.startDate}&toDate=${this.state.loadCriteria.endDate}`;
        const attendanceResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/attendance?start=${this.state.loadCriteria.startDate}&end=${this.state.loadCriteria.endDate}`;
        const holidaysUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/holidays?fromDate=${this.state.loadCriteria.startDate}&toDate=${this.state.loadCriteria.endDate}`;
        const rotaResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/rota?start=${this.state.loadCriteria.startDate}&end=${this.state.loadCriteria.endDate}`;
        
        rmsApiProxy.get(
            [
                dailySalesUrl,
                projectedSalesUrl,
                projectionOverridesUrl,
                attendanceResourceUrl,
                holidaysUrl,
                rotaResourceUrl
            ])
            .then(results =>
            {
                this.state.dailySales = results[0];
                this.state.projectedSales = results[1];
                this.state.projectionOverrides = results[2];
                this.state.attendances = results[3];
                this.state.holidays = results[4];
                this.state.rotas = results[5];
                this.compileStaffs();

                this.state.mode = 'complete';
                this.setState({});
            }, error =>
            {
                this.state.mode = 'error';
                this.state.error = error;
                this.setState({});
            });

        this.setState({});
    }

    loadStaffs(loadCriteria)
    {
        const staffResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/staff`;
        const staffRolesResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/staffRoles`;

        rmsApiProxy.get([staffResourceUrl, staffRolesResourceUrl])
                .then(results =>
                    {
                        this.state.allStaffs = results[0];
                        this.state.staffRoles = results[1];
                        this.state.staffLoaded = true;
                        this.onSchedulePeriodLoad(loadCriteria);

                    }, error =>
                    {
                        this.state.mode = 'error';
                        this.state.error = error;
                        this.setState({});
                    });

    }

    compileStaffs()
    {
        this.state.staffs = [];

        this.state.allStaffs.forEach (staff =>
        {
            if (this.state.rotas != null && this.state.rotas.find(l => l.staffId == staff.id) != null)
            {
                this.state.staffs.push(staff);
                return;
            }

            if (this.state.attendances != null && this.state.attendances.find(l => l.staffId == staff.id) != null)
            {
                this.state.staffs.push(staff);
                return;
            }

            if (!dateUtil.isValidDate(staff.joiningDate) ||
                dateUtil.isAfter(staff.joiningDate, this.state.loadCriteria.endDate))
                return;

            if (stringUtil.isStringNullOrEmpty(staff.resignationDate) ||
                 (dateUtil.isValidDate(staff.resignationDate) && dateUtil.isSameOrAfter(staff.resignationDate, this.state.loadCriteria.startDate)))
            {
                this.state.staffs.push(staff);
            }
            
        });

        this.state.staffs = arraySort(this.state.staffs, 'firstName');
    }

    renderBodyHeader()
    {
        var starting = this.state.loadCriteria.startDate;
        var columnHeaders = [];
        while (dateUtil.isSameOrBefore(starting, this.state.loadCriteria.endDate))
        {
            var dateSummary = rotaUtil.calculatedAggregatedCostForDate(starting, this.state.rotas, this.state.attendances);
            var rotaSummaryDiv = null;
            var actualAttendanceSummaryDiv = null;
            var adjustedAttendanceSummaryDiv = null;
            var salesDiv = null;
            var salesInfo;
            var wagesInfo;
            var saleWagePercentage;

            if (dateSummary.rotaHours > 0 || dateSummary.rotaCost > 0)
                rotaSummaryDiv = <div style={{textAlign:'center'}}><small className="text-muted">{`Scheduled ${formatter.roundUp(dateSummary.rotaHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.rotaCost)}`}</small></div>

            if (dateSummary.actualAttendanceHours > 0 || dateSummary.actualAttendanceCost > 0)
                actualAttendanceSummaryDiv = <div style={{textAlign:'center'}}><small className="text-muted">{`Actual ${formatter.roundUp(dateSummary.actualAttendanceHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.actualAttendanceCost)}`}</small></div>

            if (dateSummary.adjustedAttendanceHours > 0 || dateSummary.adjustedAttendanceCost > 0)
                adjustedAttendanceSummaryDiv = <div style={{textAlign:'center'}}><small className="text-muted">{`Adjusted ${formatter.roundUp(dateSummary.adjustedAttendanceHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.adjustedAttendanceCost)}`}</small></div>            
         
            if (dateUtil.isSameOrAfter(starting, dateUtil.getDateComponent(dateUtil.getNow())))
            {
                let projectedSales = rotaUtil.getProjectedSales(starting, this.state.projectedSales, this.state.projectionOverrides);

                if (!validator.isNumeric(projectedSales))
                {
                    return 'Projected Sales: N/A';
                }

                wagesInfo = '';

                if (projectedSales > 0)
                {
                    saleWagePercentage = (dateSummary.rotaCost / projectedSales) * 100;
                    wagesInfo = `(${formatter.roundUp(saleWagePercentage)}%)`;
                }

                salesInfo = `Projected Sales: ${formatter.convertToCurrencyFormat(projectedSales)} ${wagesInfo}`;
            }
            else
            {
                let actualSales = rotaUtil.getSales(starting, this.state.dailySales);

                wagesInfo = '';

                if (actualSales > 0)
                {
                    saleWagePercentage = (dateSummary.adjustedAttendanceCost / actualSales) * 100;
                    wagesInfo = `(${formatter.roundUp(saleWagePercentage)}%)`;
                }

                salesInfo = `Actual Sales: ${formatter.convertToCurrencyFormat(actualSales)} ${wagesInfo}`;
            }

            salesDiv = <div style={{textAlign:'center'}}><small className="text-muted">{salesInfo}</small></div>

            columnHeaders.push(<th style={{minWidth:'5vw'}}>
                                    <div className="text-muted">
                                        <div style={{textAlign:'center'}}>{dateUtil.formatDate(dateUtil.convertToLocalStandard(starting), 'ddd DD/MMM')}</div>
                                        {rotaSummaryDiv}
                                        {actualAttendanceSummaryDiv}
                                        {adjustedAttendanceSummaryDiv}
                                        {salesDiv}
                                    </div>
                                    
                               </th>);

            starting = dateUtil.addDays(starting, 1);
        }

        return (<thead>
                    <tr>
                        <th style={{minWidth:'5vw'}}></th>
                        {columnHeaders}
                    </tr>
                </thead>);
    }

    renderBodyContents()
    {
        var rowComponents = [];
        var rowIndex = 0;
        this.state.staffs.forEach(staff =>
        {
            let currentStaffRoles = [];

            if (!stringUtil.isStringNullOrEmpty(staff.roles))
            {
                let staffRoleIds = staff.roles.split(",");
                staffRoleIds = staffRoleIds.filter(id => this.state.staffRoles.find(s => stringUtil.areStringSame(s.id, id)) != null);

                staffRoleIds.forEach (id =>
                {
                    let currentStaffRole = this.state.staffRoles.find(staffRole => stringUtil.areStringSame(staffRole.id, id));
                    currentStaffRoles.push(currentStaffRole);    
                })

                if (!stringUtil.isStringNullOrEmpty(this.state.selectedStaffRoleId))
                {
                    if (!currentStaffRoles.some(staffRole => stringUtil.areStringSame(staffRole.id, this.state.selectedStaffRoleId)))
                        return;
                }
            }
            rowComponents.push(<tr>
                                    <td style={{height:'5vw'}}>{this.renderBodyStaffRowHeader(staff, currentStaffRoles)}</td>
                                    {this.renderBodyStaffColumns(staff, rowIndex)}
                                </tr>)

            rowIndex = rowIndex + 1;

        });

        return ( <tbody>{rowComponents}</tbody>)
        
    }

    renderBodyStaffRowHeader(staff, currentStaffRoles)
    {
        var dateSummary = rotaUtil.calculatedAggregatedCostForStaff(staff, this.state.rotas, this.state.attendances);

        var rotaSummaryDiv = null;
        var actualAttendanceSummaryDiv = null;
        var adjustedAttendanceSummaryDiv = null;
        var staffRolesDiv = null;
        
        if (currentStaffRoles != null && currentStaffRoles.length > 0)
        {
            let staffRoleNames = currentStaffRoles.map(role => `[${role.name}]`).join(" - ");
            staffRolesDiv = <div><small className="text-muted">{staffRoleNames}</small></div>
        }
        

        if (dateSummary.rotaHours > 0 || dateSummary.rotaCost > 0)
            rotaSummaryDiv = <div><small className="text-muted">{`Scheduled ${formatter.roundUp(dateSummary.rotaHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.rotaCost)}`}</small></div>

        if (dateSummary.actualAttendanceHours > 0 || dateSummary.actualAttendanceCost > 0)
            actualAttendanceSummaryDiv = <div><small className="text-muted">{`Actual ${formatter.roundUp(dateSummary.actualAttendanceHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.actualAttendanceCost)}`}</small></div>

        if (dateSummary.adjustedAttendanceHours > 0 || dateSummary.adjustedAttendanceCost > 0)
            adjustedAttendanceSummaryDiv = <div><small className="text-muted">{`Adjusted ${formatter.roundUp(dateSummary.adjustedAttendanceHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.adjustedAttendanceCost)}`}</small></div>

        return (<div>
                    <div>
                        <h5 className="text-muted">{staff.firstName} {staff.lastName}</h5>
                        {staffRolesDiv}
                        {rotaSummaryDiv}
                        {actualAttendanceSummaryDiv}
                        {adjustedAttendanceSummaryDiv}
                    </div>
                    
                </div>);
    }

    renderBodyStaffColumns(staff, rowIndex)
    {
        var staffRotaLinesColumns = [];
        var colIndex = 0;
        var starting = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(starting, this.state.loadCriteria.endDate))
        {
            let clonedDate = starting;
            var cell = <Cell onDoubleClick={!this.props.isReadOnly ? ((event)=>{this.onCalendarCellClicked(event, staff, clonedDate)}) : () => {}}
                             onDrop = {(dragInfo) =>
                             {
                                var rota = dragInfo.source;

                                if (dateUtil.isDateComponentSame(clonedDate, rota.start) && rota.staffId == staff.id)
                                    return;

                                 
                                 if (dragInfo.dragType == "copy")
                                 {
                                    rota = typeUtil.deepCloneObject(rota);
                                    rota.id = guidUtil.generateGuid();
                                 }

                                 rota.staffId = staff.id;
                                 rota.hourlyWage = staff.hourlyWage;

                                 rota.start = dateUtil.setDateComponent(rota.start, dateUtil.getDate(clonedDate), dateUtil.getMonth(clonedDate), dateUtil.getYear(clonedDate));
                                 rota.end = dateUtil.setDateComponent(rota.end, dateUtil.getDate(clonedDate), dateUtil.getMonth(clonedDate), dateUtil.getYear(clonedDate));
                                 rota.end = this.adjustEndDate(rota.start, rota.end);
                                 
                                 this.state.rotas = this.state.rotas.filter(r => r.id != rota.id);
                                 this.state.rotas.push(rota);
                                 this.setState({});
                             }}>
                            {this.renderBodyRotaAndAttendance(staff, clonedDate, rowIndex, colIndex)}
                        </Cell>
            
         

            staffRotaLinesColumns.push(cell);
            starting = dateUtil.addDays(starting, 1); 
            colIndex = colIndex + 1;
        }

        return staffRotaLinesColumns;
    }

    renderBodyRotaAndAttendance(staff, date, rowIndex, colIndex)
    {
        var staffColumns = [];

        this.state.holidays.forEach (holiday =>
        {
            if (holiday.staffId != staff.id || stringUtil.areStringSame(holiday.status, "rejected"))
                return;

            if (dateUtil.isBefore(date, dateUtil.getDateComponent(holiday.fromDate)) ||
                dateUtil.isAfter(date, dateUtil.getDateComponent(holiday.toDate)))
                return;
            
            var backgroundColor = "#228B22";
            var color = '#F8F8F8';
            var status = "Approved"

            if (stringUtil.areStringSame(holiday.status, "pending"))
            {
                backgroundColor = "#0F52BA";
                color = '#F8F8F8';
                status = "Pending";
            }

            var unAvailability = <span><span style={{marginRight:'5px'}}><i className="fa fa-calendar"></i></span>Unavailability: Whole Day</span>
            if (!holiday.isFullDay)
            {
                var localFromDate = dateUtil.convertToLocalStandard(holiday.fromDate);
                var localToDate = dateUtil.convertToLocalStandard(holiday.toDate);
                unAvailability = <span><span style={{marginRight:'5px'}}><i className="fa fa-clock-o"></i></span>Unavailability: {dateUtil.formatDate(localFromDate, "HH:mm")} - {dateUtil.formatDate(localToDate, "HH:mm")}</span>
            }

            staffColumns.push(<div style={{background:backgroundColor, color:color, paddingLeft:'20px', paddingRight:'10px', paddingTop:'20px', paddingBottom:'10px', borderRadius:'20px', marginBottom:'20px'}}>
                                <h6>Holiday Request - {status}</h6>
                                <h6>{unAvailability}</h6>
                            </div>)
        });

        if (this.state.rotas != null)
        {
            this.state.rotas.forEach(rota =>
            {
                if (rota.staffId != staff.id)
                    return;

                if (!dateUtil.isDateComponentSame(rota.start, date))
                    return;

                staffColumns.push(this.renderRotaCard(staff, rota, rowIndex, colIndex));
            });
        }
      
        if (this.state.attendances != null)
        {
            this.state.attendances.forEach(attendance =>
            {
                if (attendance.staffId != staff.id)
                    return;

                if (!dateUtil.isDateComponentSame(attendance.adjustedStart, date))
                    return;

                staffColumns.push(this.renderAttendanceCard(staff, attendance, rowIndex, colIndex));
            });
        }

        return staffColumns;
    }

    renderRotaCard(staff, rota, rowIndex, colIndex)
    {

        return (<RotaCard staff={staff} rota={rota} rowIndex={rowIndex} colIndex={colIndex}
                         onDoubleClick={()=>{
                                                this.onAddEditRota(typeUtil.deepCloneObject(rota));
                                            }}
                         onDeleteClick ={()=>
                            {
                                this.onRotaDelete(rota);
                            }}/>);
    }

    renderAttendanceCard(staff, attendance, rowIndex, colIndex)
    {
        return (<AttendanceCard staff={staff} attendance={attendance} rowIndex={rowIndex} colIndex={colIndex}
                                onDoubleClick={()=>{
                                                    this.onAddEditAttendance(typeUtil.deepCloneObject(attendance));
                                                }}
                                onDeleteClick ={()=>
                                {
                                    this.onAttendanceDelete(attendance);
                                }}/>);
    }

    renderBodyFooter()
    {
        var doPeriodHaveLoaded = this.state.loadCriteria != null && this.state.mode == 'complete';

        if (!doPeriodHaveLoaded)
            return null;

        var dateSummary = rotaUtil.calculatedAggregatedCostForPeriod(this.state.rotas, this.state.attendances);            

        let totalSales = 0;
        let totalCost = 0;

        let startDate = this.state.loadCriteria.startDate;
        let endDate = this.state.loadCriteria.endDate;

        while (dateUtil.isSameOrBefore(startDate, endDate))
        {
            const costForDate = rotaUtil.calculatedAggregatedCostForDate(startDate, this.state.rotas, this.state.attendances);
            if (dateUtil.isSameOrAfter(startDate, dateUtil.getNow()))
            {
                totalSales += rotaUtil.getProjectedSales(startDate, this.state.projectedSales, this.state.projectionOverrides);
                totalCost += costForDate.rotaCost;
            }
            else
            {
                totalSales += rotaUtil.getSales(startDate, this.state.dailySales);
                totalCost += costForDate.adjustedAttendanceCost;
            }

            startDate = dateUtil.addDays(startDate, 1);
        }

        const totalSalesWagesPercentage = (totalCost / totalSales) * 100;
        const wagesInfo = validator.isNumeric(totalSalesWagesPercentage) ? ` (${formatter.roundUp(totalSalesWagesPercentage)}%)` : " (N/A)";

        return (
            <CardFooter>
                <div style={{ display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "space-between" }}>
                    <div className="text-muted">{`Scheduled ${formatter.roundUp(dateSummary.rotaHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.rotaCost)}`}</div>
                    <div className="text-muted">{`Actual ${formatter.roundUp(dateSummary.actualAttendanceHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.actualAttendanceCost)}`}</div>
                    <div className="text-muted">{`Adjusted ${formatter.roundUp(dateSummary.adjustedAttendanceHours, 2)} hours / ${formatter.convertToCurrencyFormat(dateSummary.adjustedAttendanceCost)}`}</div>
                    <div className="text-muted">{`Total Sales: ${formatter.convertToCurrencyFormat(totalSales)}${wagesInfo}`}</div>
                </div>
            </CardFooter>
        );
    }

    onCalendarCellClicked(event, staff, date)
    {
        if (event.shiftKey)
        {
            this.onAddAttendance(staff, date);
            return;
        }

        this.onAddRota(staff, date);
    }

    onRotaDelete(rota)
    {
        this.state.rotas = this.state.rotas.filter (r => r.id != rota.id);
        this.setState({});
    }

    onAttendanceDelete(attendance)
    {
        this.state.attendances = this.state.attendances.filter (a => a.id != attendance.id);
        this.setState({});
    }

    onAddRota(staff, date)
    {
        var rota = {};
        rota.id = guidUtil.generateGuid();
        rota.propertyId = currentOrgNodeSelectors.selectCurrentProperty().id; 
        rota.staffId = staff.id;
        rota.hourlyWage = staff.hourlyWage;
        rota.start = dateUtil.formatDate(date, dateUtil.getDateTimeFormat());
        rota.end = dateUtil.formatDate(date, dateUtil.getDateTimeFormat());
        rota.isDraft = true;
        rota.description = null;
        this.onAddEditRota(rota);
    }

    onAddEditRota(rota)
    {
        var staff = this.state.staffs.find (s => s.id == rota.staffId);

        var staffName = staff.firstName + " " + staff.lastName;
        var rotaDateCaption = dateUtil.formatDate(dateUtil.convertToLocalStandard(rota.start), 'ddd DD/MMM');

        viewUtil.openModalForm(`Rota - ${staffName} - ${rotaDateCaption}`,(onFieldChanged) =>
        {
            return (<RotaDialogue data={rota}/>);
        }, 
        ()=>
        {
            this.state.rotas = this.state.rotas.filter (r => r.id != rota.id);
            rota.end = this.adjustEndDate(rota.start, rota.end);

            this.state.rotas.push(rota);
            this.setState({});
        }, 
        ()=>
        {
            if (stringUtil.isStringNullOrEmpty(rota.start) ||
                !dateUtil.isValidDateTime(rota.start))
                return "Start time is not valid";

            if (stringUtil.isStringNullOrEmpty(rota.end) ||
                !dateUtil.isValidDateTime(rota.end))
                return "End time is not valid";

            if (!validator.isNumeric(rota.hourlyWage))
                return "Hourly wage is not valid";

            var adjustedEndDate = this.adjustEndDate(rota.start, rota.end);
            if (dateUtil.differenceInMinutes(adjustedEndDate, rota.start) < 15)
                return "Rota must be atleast 15 minutes";
            
        }, this.props.isReadOnly);
    }

    adjustEndDate(start, end)
    {
        var sameDateEnd = dateUtil.setDateComponent(end, dateUtil.getDate(start), dateUtil.getMonth(start), dateUtil.getYear(start));
        if (dateUtil.isSameOrBefore(start, sameDateEnd))
            return sameDateEnd;

        return dateUtil.addDays(sameDateEnd, 1);
    }

    onAddAttendance(staff, date)
    {
        
        var attendance = {};
        attendance.id = guidUtil.generateGuid();
        attendance.propertyId = currentOrgNodeSelectors.selectCurrentProperty().id; 
        attendance.staffId = staff.id;
        attendance.hourlyWage = staff.hourlyWage;
        attendance.start = null;
        attendance.end = null;
        attendance.adjustedStart = dateUtil.formatDate(date, dateUtil.getDateTimeFormat());
        attendance.adjustedEnd = dateUtil.formatDate(date, dateUtil.getDateTimeFormat());
        attendance.isSystemGenerated = false;
        attendance.hasSystemClockedOut = false;
        attendance.isDraft = true;
        attendance.isVoided = false;

        this.onAddEditAttendance(attendance);
    }

    onAddEditAttendance(attendance)
    {

        var staff = this.state.staffs.find (s => s.id == attendance.staffId);

        var staffName = staff.firstName + " " + staff.lastName;
        var attendanceDateCaption = dateUtil.formatDate(dateUtil.convertToLocalStandard(attendance.adjustedStart), 'ddd DD/MMM');

        viewUtil.openModalForm(`Attendance - ${staffName} - ${attendanceDateCaption}`,(onFieldChanged) =>
        {
            return (<AttendanceDialogue data={attendance}/>);
        }, 
        ()=>
        {
            this.state.attendances = this.state.attendances.filter (a => a.id != attendance.id);
            attendance.adjustedEnd = this.adjustEndDate(attendance.adjustedStart, attendance.adjustedEnd);

            if (!attendance.isSystemGenerated)
            {
                attendance.start = attendance.adjustedStart;
                attendance.end = attendance.adjustedEnd;
            }

            this.state.attendances.push(attendance);
            this.setState({});
        }, 
        ()=>
        {
            if (stringUtil.isStringNullOrEmpty(attendance.adjustedStart) ||
                !dateUtil.isValidDateTime(attendance.adjustedStart))
                return "Start time is not valid";

            if (stringUtil.isStringNullOrEmpty(attendance.adjustedEnd) ||
                !dateUtil.isValidDateTime(attendance.adjustedEnd))
                return "End time is not valid";

            if (!validator.isNumeric(attendance.hourlyWage))
                return "Hourly wage is not valid";
        }, this.props.isReadOnly);
    }

    onClearClick()
    {
        viewUtil.showConfirmDialogue("Schedules", "You are going to clear all schedules. You may lose unsaved changed. Are you sure you want to proceed?", ()=>
        {
            this.state.rotas = [];
            this.setState({});
        })
    }

    onReload()
    {
        viewUtil.showConfirmDialogue("Schedules", "You are going to reload all schedules. You may lose unsaved changed. Are you sure you want to proceed?", ()=>
        {
            this.onSchedulePeriodLoad(this.state.loadCriteria);
        })
    }

    onCopy()
    {
        var copiedPayload = {};
        copiedPayload.startDate = this.state.loadCriteria.startDate;
        copiedPayload.endDate = this.state.loadCriteria.endDate;
        copiedPayload.propertyId = currentOrgNodeSelectors.selectCurrentProperty().id; 
        copiedPayload.copiedRotas = this.state.rotas;

        clipboard.setData(clipboard.dataTypes.rota, copiedPayload);
        this.setState({});
    }

    onPrint()
    {
        viewUtil.showErrorAlert("Printing is not yet supported.");
    }

    async onPublishClick()
    {
        this.state.rotas.forEach (rota =>
            {
                rota.isDraft = false;
            })

        this.state.attendances.forEach (attendance =>
            {
                attendance.isDraft = false;
            })

        this.onSaveClick();
    }

    async onSaveClick()
    {
        try
        {
            var rotaResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/rota?start=${this.state.loadCriteria.startDate}&end=${this.state.loadCriteria.endDate}`;
            var attendanceResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/attendance?start=${this.state.loadCriteria.startDate}&end=${this.state.loadCriteria.endDate}`;

            this.state.mode = "saving";
            this.state.error = null;
            this.setState({});

            await rmsApiProxy.post(rotaResourceUrl, this.state.rotas);
            await rmsApiProxy.post(attendanceResourceUrl, this.state.attendances)

            this.state.mode = "complete";
            this.setState({});
        }
        catch (error)
        {
            this.state.mode = "error";
            this.state.error = error;
            this.setState({});
        }

    }

    onPaste()
    {
        var copiedPayload = clipboard.getData(clipboard.dataTypes.rota);
        var targetStartingDate = this.state.loadCriteria.startDate;
        var sourceStartingDate = copiedPayload.startDate;

        while (dateUtil.isSameOrBefore(targetStartingDate, this.state.loadCriteria.endDate) &&
               dateUtil.isSameOrBefore(sourceStartingDate, copiedPayload.endDate))
        {

            this.state.staffs.forEach (staff =>
            {
                var sourceRotas = copiedPayload.copiedRotas.filter (r => r.staffId == staff.id &&
                                                                                        dateUtil.isDateComponentSame(r.start, sourceStartingDate) );

                sourceRotas.forEach(sourceRota =>
                {
                    var targetRota = typeUtil.deepCloneObject(sourceRota);
                    targetRota.id = guidUtil.generateGuid();
                    targetRota.propertyId = currentOrgNodeSelectors.selectCurrentProperty().id; 
                    targetRota.hourlyWage = staff.hourlyWage;
                    targetRota.start = dateUtil.setDateComponent(sourceRota.start, dateUtil.getDate(targetStartingDate), dateUtil.getMonth(targetStartingDate), dateUtil.getYear(targetStartingDate));
                    targetRota.end = dateUtil.setDateComponent(sourceRota.end, dateUtil.getDate(targetStartingDate), dateUtil.getMonth(targetStartingDate), dateUtil.getYear(targetStartingDate));
                    targetRota.end = this.adjustEndDate(targetRota.start, targetRota.end);
                    targetRota.isDraft = true;

                    this.state.rotas.push(targetRota);

                });


            });

            targetStartingDate = dateUtil.addDays(targetStartingDate, 1);
            sourceStartingDate = dateUtil.addDays(sourceStartingDate, 1);
        }

        this.setState({});
    }

    renderExportExcel(disabled)
    {
        return <span style={{marginRight:'10px'}}>
                    <ExcelExportButton  disabled={disabled}
                                        onExport={()=>
                                                    {
                                                        return this.extractWorkbook();
                                                    }} />
                </span>
    }

    extractWorkbook()
    {
        try
        {
            var excelWorkBook = new ExcelWorkBook();
            excelWorkBook.workBookName = "Rota";

            var rotaWorksheet = excelWorkBook.addWorksheet("Rota");
            this.extractExcelSheetForRota(rotaWorksheet);

            var rotaCostWorksheet = excelWorkBook.addWorksheet("Rota Cost");
            this.extractExcelSheetForRotaCost(rotaCostWorksheet);
            
            var rotaHoursWorksheet = excelWorkBook.addWorksheet("Rota Hours");
            this.extractExcelSheetForRotaHours(rotaHoursWorksheet);

            var actualAttendanceWorksheet = excelWorkBook.addWorksheet("Actual Attendance");
            this.extractExcelSheetForActualAttendance(actualAttendanceWorksheet);

            var adjustedAttendanceWorksheet = excelWorkBook.addWorksheet("Adjusted Attendance");
            this.extractExcelSheetForAdjustedAttendance(adjustedAttendanceWorksheet);

            var attendanceCostWorksheet = excelWorkBook.addWorksheet("Attendance Cost");
            this.extractExcelSheetForAttendanceCost(attendanceCostWorksheet);
            
            var attendanceHoursWorksheet = excelWorkBook.addWorksheet("Attendance Hours");
            this.extractExcelSheetForAttendanceHours(attendanceHoursWorksheet);

            return excelWorkBook;
        }
        catch(error)
        {
            logger.error(`Error occurred while exporting data for rota excel export: ${error}`,  {}, error);
            return null;
        }
    }

    extractExcelSheetForRota(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }

        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        let staffRotasForColDate = []
                        this.state.rotas.forEach(rota =>
                        {
                            if (rota.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(rota.start, colDate))
                                return;

                            staffRotasForColDate.push(rotaUtil.compileRotaDateCaption(rota))
                        });
                        excelWorksheetRow.addDataCell(staffRotasForColDate.sort().join(" | "));
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

    extractExcelSheetForRotaCost(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }
        
        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        let cost = 0.0;
                        this.state.rotas.forEach(rota =>
                        {
                            if (rota.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(rota.start, colDate))
                                return;

                            cost = cost + rotaUtil.calculateRotaCost(rota);
                        });
                        excelWorksheetRow.addDataCell(formatter.roundUp(cost, 2) || "N/A");
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

    extractExcelSheetForRotaHours(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }
        
        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        let hours = 0.0;
                        this.state.rotas.forEach(rota =>
                        {
                            if (rota.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(rota.start, colDate))
                                return;

                            hours = hours + rotaUtil.calculateRotaHours(rota);
                        });
                        excelWorksheetRow.addDataCell(formatter.roundUp(hours, 2) || "N/A");
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

    extractExcelSheetForActualAttendance(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }

        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        var hasSystemClockedOut = false;
                        let staffRotasForColDate = []
                        this.state.attendances.forEach(attendance =>
                        {
                            if (attendance.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(attendance.start, colDate))
                                return;

                            if (attendance.isVoided)
                                return;

                            if (attendance.hasSystemClockedOut)
                                hasSystemClockedOut = true;

                            staffRotasForColDate.push(rotaUtil.compileAttendanceActualDateCaption(attendance))
                        });

                        let formattedCellValue = staffRotasForColDate.sort().join(" | ");
                        if (hasSystemClockedOut)
                            excelWorksheetRow.addWarningCell(formattedCellValue);
                        else
                            excelWorksheetRow.addDataCell(formattedCellValue);
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

    extractExcelSheetForAdjustedAttendance(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }

        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        let staffRotasForColDate = []
                        this.state.attendances.forEach(attendance =>
                        {
                            if (attendance.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(attendance.start, colDate))
                                return;

                            if (attendance.isVoided)
                                return;

                            staffRotasForColDate.push(rotaUtil.compileAttendanceAdjustedDateCaption(attendance))
                        });
                        excelWorksheetRow.addDataCell(staffRotasForColDate.sort().join(" | "));
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

    extractExcelSheetForAttendanceCost(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }
        
        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        let cost = 0.0;
                        this.state.attendances.forEach(attendance =>
                        {
                            if (attendance.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(attendance.adjustedStart, colDate))
                                return;

                            if (attendance.isVoided)
                                return;

                            cost = cost + rotaUtil.calculateAttendanceAdjustedCost(attendance);
                        });
                        excelWorksheetRow.addDataCell(formatter.roundUp(cost, 2) || "N/A");
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

    extractExcelSheetForAttendanceHours(excelWorkSheet)
    {
        excelWorkSheet.addColHeader("Staff Member");
        let colDate = this.state.loadCriteria.startDate;
        while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
        {
            const dateKey = dateUtil.formatDate(dateUtil.convertToLocalStandard(colDate), 'ddd DD/MMM');
            excelWorkSheet.addColHeader(dateKey);
            colDate = dateUtil.addDays(colDate, 1);
        }
        
        this.state.staffs
            .sort((a, b) => stringUtil.localeCompareStr(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`))
            .forEach (staff =>
                {
                    let excelWorksheetRow = excelWorkSheet.addRow();
                    excelWorksheetRow.addRowHeaderCell(`${staff.firstName} ${staff.lastName}`);

                    let colDate = this.state.loadCriteria.startDate;
                    while (dateUtil.isSameOrBefore(colDate, this.state.loadCriteria.endDate))
                    {
                        let hours = 0.0;
                        this.state.attendances.forEach(attendance =>
                        {
                            if (attendance.staffId != staff.id)
                                return;

                            if (!dateUtil.isDateComponentSame(attendance.adjustedStart, colDate))
                                return;

                            if (attendance.isVoided)
                                return;

                            hours = hours + rotaUtil.calculateAttendanceAdjustedHours(attendance);
                        });
                        excelWorksheetRow.addDataCell(formatter.roundUp(hours, 2) || "N/A");
                        colDate = dateUtil.addDays(colDate, 1);
                    }

                });
    }

}


export default Rota
