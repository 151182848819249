import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';

import CdnImage from '../../../../components/cdnImage/cdnImage.js'

var configurationManager = require('../../../../utils/config/configurationManager.js');
var formatter = require('../../../../utils/formatter/formatter.js');
var stringUtil = require('../../../../utils/string/stringUtil.js');
var rotaUtil = require('./RotaUtil.js');

class RotaCard extends Component 
{
    constructor(props)
    {
        super(props);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);

        this.onDragStart = this.onDragStart.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);

        this.getImageComponent = this.getImageComponent.bind(this);
    }

    get isReadOnly() { return !rotaUtil.isEditingAllowed(); }

    render()
    {
        var colorPallets = ['bg-primary', 'bg-danger', 'bg-success', 'bg-warning'];
        var palletIndx = this.props.rowIndex % colorPallets.length;

        var timeCaption = rotaUtil.compileRotaDateCaption(this.props.rota);
        var costCaption = rotaUtil.compileRotaCostCaption(this.props.rota);
        var componentId = `rota-${this.props.rowIndex}-${this.props.colIndex}`

        var publishedRotaIcon = null;
        if (!this.props.rota.isDraft)
        {
            publishedRotaIcon =
                <>
                    <span id="publishedRotaIcon" className='me-2' style={{ fontSize: "1.10em" }}><i className='fa fa-check-square-o' /></span>
                    <UncontrolledTooltip target="publishedRotaIcon">Status=Published</UncontrolledTooltip>
                </>;
        }

        return (<Card draggable={!this.isReadOnly} onDragStart={this.onDragStart} id={componentId} className={`text-white ${colorPallets[palletIndx]} text-center`} onDoubleClick={this.onDoubleClick}>
                    <CardBody>
                        <table  cellPadding='0px' cellSpacing="0px" style={{width:'100%', padding:'0x', margin:'0px', borderCollapse:'collapse', border:'none', borderWidth:'0px'}}>
                            <tr>
                                <td style={{border:'none', padding:'0px'}}>
                                    <div style={{padding:'0px', margin:'0px'}}>
                                        {this.getImageComponent()}
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='d-flex align-self-end text-white' style={{ marginTop: '-15px' }}>
                                            {publishedRotaIcon}
                                            {
                                                this.isReadOnly ||
                                                <>
                                                    <span id="deleteRotaIcon" onClick={this.onDeleteClick} style={{ cursor: "pointer", fontSize: "1.10em" }}><i className='fa fa-window-close-o' /></span>
                                                    <UncontrolledTooltip target="deleteRotaIcon">Delete Rota</UncontrolledTooltip>
                                                </>
                                            }
                                        </div>
                                        <div>{timeCaption}</div>
                                        <div>{costCaption}</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        {this.renderTooltip(componentId)}
                    </CardBody>
                </Card>);
    }

    getImageComponent()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.staff.pictUrl))
            return null;

        return (<div style={{marginRight:'10px'}}> <CdnImage  cloudName={configurationManager.getConfig().cdnUsersCloudName} publicId={this.props.staff.pictUrl} width="60" height="60" radius="0"/></div>)
    }

    renderTooltip(componentId)
    {
        
        return (<UncontrolledTooltip placement="top" target={componentId} style={{background:'#484848'}}>
                            <div>
                                <div>
                                    {`${this.props.staff.firstName} ${this.props.staff.lastName}  - ${formatter.convertToCurrencyFormat(this.props.rota.hourlyWage)}/hour`}
                                </div>
                                <div><hr style={{padding:'0px', marginTop:'0px', marginBottom:'5px', borderColor:'#989898'}}/></div>
                                <div>
                                    <table>
                                        <tr>
                                        <td align="left">
                                                From
                                            </td>
                                            <td align="left">
                                                {`${rotaUtil.compileDateTooltip(this.props.rota.start)}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                To
                                            </td>
                                            <td align="left">
                                                {`${rotaUtil.compileDateTooltip(this.props.rota.end)}`}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                           </div>
                        </UncontrolledTooltip>);
    }

    onDragStart(event)
    {
        var dragInfo = {};
        dragInfo.dragType = "move";
        if (event.altKey)
            dragInfo.dragType = "copy";

        dragInfo.sourceType = "rota";
        dragInfo.source = this.props.rota;

        event.dataTransfer.setData("dragInfo", JSON.stringify(dragInfo));
    }

    onDoubleClick(event)
    {
        if (this.props.onDoubleClick)
        {
            this.props.onDoubleClick(this.props.rota);
            event.stopPropagation();
        }
    }

    onDeleteClick(event)
    {
        if (this.props.onDeleteClick)
        {
            this.props.onDeleteClick(this.props.rota);
        }
    }
}

export default RotaCard;