import React, { Component } from 'react';
import { Input } from 'reactstrap';

class StockItemInventoryProfiles extends Component
{
    constructor(props)
    {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.checked = this.checked.bind(this);
    }

    onChange(event, profileId)
    {
        const stockItem = this.props.data;

        if(event.target.checked)
        {            
            stockItem.inventoryProfiles.push({ inventoryTrackingProfileId: profileId});
            this.setState({});
        }

        else if(!event.target.checked)
        {
            stockItem.inventoryProfiles = stockItem.inventoryProfiles.filter(profile => profile.inventoryTrackingProfileId != profileId);
            this.setState({});
        }

    }

    checked(profileId)
    {        
        const stockItem = this.props.data;
        return stockItem.inventoryProfiles.some(profile => profile.inventoryTrackingProfileId == profileId);        
    }

    render()
    {            
        return (
            <div className="d-flex flex-column m-3">
                {
                    this.props.lookupData.inventoryTrackingProfiles.map(profile => (
                        <div className="mb-2 mx-2">
                            <Input type="checkbox" checked={this.checked(profile.id)} onChange={(event) => this.onChange(event, profile.id)} /> &nbsp;&nbsp;{profile.name}
                        </div>
                    ))
                }

            </div>
        );
    }
}

export default StockItemInventoryProfiles;