import React, { Component } from 'react'
import classNames from 'classnames';

import TableData from '../TableData/TableData';
import tableConstants from '../TableData/TableConstants';

const { columnAlignments } = tableConstants;

const textAlign = {
    [columnAlignments.alignLeft]: "text-start",
    [columnAlignments.alignRight]: "text-end",
    [columnAlignments.alignCenter]: "text-center"
}

class TableView extends Component
{
    constructor(props)
    {
        super(props);

        if (props.tableData == null || !(props.tableData instanceof TableData))
        {
            throw new Error("tableData is missing or invalid");
        }

        this.handleOnClickRow = this.handleOnClickRow.bind(this);
        this.state = {};

        this.state.expandedRowIndices = [];
    }

    // get columnDefinitions() { return this.props.tableData.columnDefinitions; }

    render()
    {
        const { headerRow, footerRow, dataRows } = this.props.tableData;

        return (
            <div className='w-100 h-100 d-flex flex-column' style={{ backgroundColor: "white" }}>

                {
                    headerRow &&
                    <div className='d-flex flex-row text-white border-top border-bottom' style={{ backgroundColor: "Gray" }}>
                        {
                            headerRow.columns.map((column, index) =>
                                <div key={index} className={this.getColumnClassName(column, index)} style={this.getColumnStyle(column, index)}>{column.value}</div>)
                        }
                    </div>
                }

                <div className='flex-grow-1'>
                    {
                        dataRows.length === 0
                            ? this.renderNoDataView()
                            : this.renderDataRows(dataRows)
                    }
                </div>

                {
                    footerRow &&
                    <div className='d-flex flex-row fw-bold border-top border-bottom' style={{ backgroundColor: "#FEFEFE" }}>
                        {
                            footerRow.columns.map((column, index) =>
                                <div key={index} className={this.getColumnClassName(column, index)} style={this.getColumnStyle(column, index)}>{column.value}</div>)
                        }
                    </div>
                }

            </div>
        );
    }

    renderNoDataView()
    {
        return (
            <div className='h-100 py-4 d-flex justify-content-center align-items-center'>
                <h3>No data available</h3>
            </div>
        );
    }
    
    handleOnClickRow(index) 
    {
        this.setState((prevState) => 
        {
            const expandedRowIndices = [...prevState.expandedRowIndices];
            const rowIndex = expandedRowIndices.indexOf(index);

            if (rowIndex > -1) 
            {
                expandedRowIndices.splice(rowIndex, 1); 
            } else 
            {
                expandedRowIndices.push(index);
            }

            return { expandedRowIndices };
        });
    }

    renderDataRows(dataRows)
    {
        const colors = ["White", "WhiteSmoke"];
        let i = 0;
        const getColor = (index) => colors[index % 2];

        return (
            <div className='h-100 d-flex flex-column'>
                {
                    dataRows.map((dataRow, index) =>
                    { const isExpanded = this.state.expandedRowIndices.includes(index);
                        return <>
                            {
                                dataRow.beforeBlankRow &&
                                <div key={`beforeBlankRow-${index}`} className={`w-100 d-flex flex-row}`} style={{ background: getColor(index) }}>
                                    {
                                        dataRow.columns.map((column, index) =>
                                            <div key={index} className={this.getColumnClassName(column, index)} style={this.getColumnStyle(column, index)} />)
                                    }
                                </div>

                            }
                            <div onClick={() => this.handleOnClickRow(index)} key={`dataRow-${index}`} className={`w-100 d-flex flex-row ${dataRows.length === index + 1 && "flex-grow-1"}`} style={{ background: getColor(i++) }}>
                                {
                                    dataRow.columns.map((column, index) =>
                                        <div key={index} className={this.getColumnClassName(column, index)} style={this.getColumnStyle(column, index)}>
                                            {
                                                column.value &&
                                                column.value.toString().split("\n").map(line => 
                                                    <span>
                                                        {line} 
                                                        {dataRow.childRows.length > 0 && index === dataRow.columns.length - 1 &&
                                                            <span>
                                                                <i className="fa fa-chevron-down" style={{ transform: `rotate(${!isExpanded ? 0 : 180}deg)`}} />
                                                            </span>
                                                        }   
                                                    </span>)
                                            }
                                        </div>)
                                }
                            </div>
                            {
                                dataRow.childRows.length > 0 && isExpanded && 
                                dataRow.childRows.map((childRow, index) => 
                                    {
                                    return <div key={`childDataRow-${index}`} className={`w-100 d-flex flex-row ${dataRow.childRows.length === index + 1 && "flex-grow-1"}`} style={{ background: getColor(i++) }}>
                                        {
                                            childRow.columns.map((column, index) =>
                                                {
                                                    return <div key={index} className={`${this.getColumnClassName(column, index)} d-flex justify-content-between align-items-end`} style={this.getColumnStyle(column, index)}>
                                                    {
                                                        column.value && 
                                                        Array.isArray(column.value) ? 
                                                        (

                                                            <table style={{width: 'auto'}}>
                                                            {
                                                                column.value.map(({ denomination, quantity, formattedAmount }, idx) => (
                                                                    
                                                                        <tr>
                                                                            <td>&nbsp;&nbsp;{denomination}</td>
                                                                            <td>&nbsp;&nbsp;x</td>
                                                                            <td>&nbsp;&nbsp;{quantity}</td>
                                                                            <td>&nbsp;&nbsp;=</td>
                                                                            <td>&nbsp;&nbsp;{formattedAmount}</td>
                                                                        </tr>
                                                                ))
                                                            }
                                                            </table>
                                                            ): (
                                                                column.value.toString().split("\n").map(line => <span style={{whiteSpace: 'pre'}}>{line}</span>)
                                                            )
                                                    }
                                                </div>
                                                })
                                        }
                                        </div>
                                    })
                                    
                            }
                            {
                                dataRow.afterBlankRow &&
                                <div key={`afterBlankRow-${index}`} className={`w-100 d-flex flex-row}`} style={{ background: getColor(index) }}>
                                    {
                                        dataRow.columns.map((column, index) =>
                                            <div key={column.index} className={this.getColumnClassName(column, index)} style={this.getColumnStyle(column, index)} />)
                                    }
                                </div>
                            }
                        </>
                    }
                )
                }
            </div>
        );
    }

    getColumnClassName(column, index)
    {
        const { alignment, isBold } = column;

        return classNames(
            "d-flex flex-column",
            "px-3", "py-2",
            "text-wrap",
            textAlign[alignment],
            "border-end",
            index === 0 && "border-start",
            isBold && "fw-bold"
        );
    }

    getColumnStyle(column, index)
    {
        return { width: `${column.width}%` }
    }
}

export default TableView;