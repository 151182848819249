import React from "react";
import * as chargeUtils from "../chargeUtils";
import { Col, Row } from "reactstrap";
import InputComponent from "../../../../../../components/form/inputComponent";
import SelectComponent from "../../../../../../components/form/selectComponent";
import BooleanSelectComponent from "../../../../../../components/form/booleanSelectComponent";

const constants = require("../../../../../../utils/domain/constants");

function ChargeConfig({ isNew, formManager }) 
{
    return (
        <div>
            <Row>
                <Col>
                    <InputComponent
                        fieldName="name"
                        caption="Name"
                        hintText="Specify name for charge"
                        placeholder="Enter name"
                        formManager={formManager}
                    />
                </Col>
                <Col>
                    <InputComponent
                        fieldName="displayName"
                        caption="Display Name"
                        hintText="Specify display name for charge"
                        placeholder="Enter display name"
                        formManager={formManager}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputComponent
                        fieldName="value"
                        caption="Value"
                        inputType="number"
                        hintText="Specify charge value"
                        placeholder="Enter value"
                        formManager={formManager}
                    />
                </Col>

                <Col>
                    <InputComponent
                        fieldName="vatPercentage"
                        caption="VAT Percentage"
                        inputType="number"
                        hintText="Specify VAT Percentage"
                        placeholder="Enter VAT percentage"
                        formManager={formManager}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SelectComponent
                        optionValues={chargeUtils.getChargeSchemeOptions()}
                        optionFieldName="value"
                        optionDisplayFieldName="label"
                        caption="Scheme"
                        fieldName="scheme"
                        formManager={formManager}
                        hintText="Select a scheme type"
                        clearable={false}
                    />
                </Col>

                <Col>
                    <SelectComponent
                        optionValues={chargeUtils.getChargeTypeOptions()}
                        optionFieldName="value"
                        optionDisplayFieldName="label"
                        caption="Type"
                        fieldName="type"
                        formManager={formManager}
                        hintText="Select a charge type"
                        clearable={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SelectComponent
                        optionValues={chargeUtils.getChargeTargetOptions()}
                        optionFieldName="value"
                        optionDisplayFieldName="label"
                        caption="Target"
                        fieldName="target"
                        formManager={formManager}
                        hintText="Select a charge target"
                        clearable={false}
                        disabled={formManager.getValue("scheme") === constants.Charges.schemes.depositReturn || !isNew}
                    />
                </Col>
                <Col>
                    <BooleanSelectComponent
                        caption="Disabled"
                        fieldName="isDisabled"
                        formManager={formManager}
                        hintText="Is charge disabled"
                        clearable={false}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ChargeConfig;