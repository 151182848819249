const stringUtil = require('../../../../../utils/string/stringUtil.js');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const passportSelectors = require('../../../../../utils/state/stateSelectors/passportSelectors');
const securityConstants = require('../../../../../utils/domain/security/securityConstants');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const orgDataAction = require('../../../../../utils/state/actions/orgData/orgData/orgDataAction');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const passportAction = require('../../../../../utils/state/actions/passport/passportAction');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const stateManager = require('../../../../../utils/state/stateManager');

export async function save (isNew, data)
{
    let resourceUrl = "";
    if(isNew)
    {
        resourceUrl = `core/organizations/franchisors`;        
    }
    else
    {
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/basicConfiguration`;
    }

    const updatedFranchisor = await rmsApiProxy.post(resourceUrl, data);

    let franchisors = orgSelectors.selectFranchisors();
    franchisors = franchisors.filter(f => f.id != updatedFranchisor.id);
    franchisors.push(updatedFranchisor);

    actionDispatcher.dispatch(orgDataAction.createFranchisorsUpdateAction(franchisors));

    let availableFranchisors = stateManager.getStore().getState().availableFranchisors

    if (availableFranchisors.some(f => f.id === updatedFranchisor.id))
    {
        availableFranchisors = availableFranchisors.filter(f => f.id != updatedFranchisor.id);
        availableFranchisors.push(updatedFranchisor);
        stateManager.getStore().getState().availableFranchisors = availableFranchisors;
    }

    const passport = passportSelectors.selectPassport();
    if(passport.roles.some(x => x.roleCode === securityConstants.builtInUserRoles.systemRoles.Support.roleCode))
    {
        const supportElevatedRole = {
            franchisorId: updatedFranchisor.id,
            emailAddress: passport.emailAddress,
            roleCode: securityConstants.roleCodes.SupportElevated,
            expiry: dateUtil.addHours(dateUtil.getNow(), 72)
        };

        passport.roles.push(supportElevatedRole);
        actionDispatcher.dispatch(passportAction.createAction(passport));
    }

    return updatedFranchisor;
}

export function validate (data)
{
    if (stringUtil.isStringNullOrEmpty(data.name))
        return "Franchisor name cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(data.country))
    {
        return "Franchisor country cannot be left empty";
    }

    if (currentOrgNodeSelectors.isCurrentSystemSelected() && stringUtil.isStringNullOrEmpty(data.appId))
    {
        return "AppId is required for the default brand to be created";
    }
    
    if (stringUtil.isStringNullOrEmpty(data.emailAddress))
        return "Franchisor emailAddress cannot be left empty";

    if (data.isBlocked && stringUtil.isStringNullOrEmpty(data.blockedReason))
        return "Block Reason cannot be empty for a blocked franchisor";

    if (!data.isBlocked && !stringUtil.isStringNullOrEmpty(data.blockedReason))
        return "Block Reason cannot be set for a non-blocked franchisor";

    return null;
}