
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import GridComponent from '../../../../../components/grid/gridComponent.js';
import ApiIntegration from './components/ApiIntegration';
import ErrorMessage from '../../../../../components/error/errorMessage';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const franchisorApiIntegrationHelper = require('./franchisorApiIntegrationHelper');
const typeUtil = require('../../../../../utils/type/typeUtil.js');
const viewUtil = require('../../../../../utils/view/viewUtil.js');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class FranchisorApiIntegrations extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = 
        {
            franchisorApiIntegrations: [],
        }
        this.state.isLoading = true;
        this.state.error = null;

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() 
    {
        this.loadData();
    }

    async loadData() 
    {
        this.state.isLoading = true;
        this.state.error = null;
        this.setState({});

        viewUtil.showSystemModalSpinner("Loading, please wait");
        
        try 
        {
            let franchisorApiIntegrations = await franchisorApiIntegrationHelper.load();
            this.state.franchisorApiIntegrations = franchisorApiIntegrations;
        }
        catch (error) 
        {
            this.state.error = error;
        }

        this.state.isLoading = false;
        this.setState({});
        viewUtil.closeModalSpinner();
    }

    render()
    {
        if (this.state.error)
            return <ErrorMessage message={ this.state.error } />;

        
        const deleteButtonDisabled = this.findSelectedRow() == null;

        const buttonPanels =   
                <table width="100%">
                    <tr>
                        <td align="right">
                        <Button color="primary" className="btn-primary" style={{ marginRight: '3px' }} onClick={this.onAdd} disabled={this.props.isReadOnly}>
                                <i className="fa fa-plus"></i>&nbsp;Add API Integration
                                                </Button>

                        <Button color="primary" disabled={deleteButtonDisabled || this.props.isReadOnly} className="btn-danger" style={{ marginRight: '3px' }} onClick={this.onRemove}>
                                <i className="fa fa-remove"></i>&nbsp;Remove API Integration
                                                </Button>
                        </td>
                    </tr>
                </table>

        return (
            <Card>
                <CardHeader>                
                    <EditComponentHeader title={`Franchisor API Integrations`} isReadOnly={this.props.isReadOnly} onCancelClose={true} />        
                </CardHeader>

                <CardBody>                
                    <table width="100%">
                        <tr width="100%">
                            <td style={{paddingTop: '8px', paddingBottom: '8px'}} width="100%">
                                {buttonPanels}
                            </td>
                        </tr>

                        <tr width="100%">
                            <td width="100%">
                                <GridComponent headers={this.constructGridColumnHeaders()}
                                    rows={this.state.franchisorApiIntegrations}
                                    onRowDoubleClicked={this.onRowDoubleClicked}
                                    onRowSelected={this.onRowSelected}
                                    shouldReArrangeHeaders={true}
                                    onGridReady={this.onGridReady} />
                            </td>
                        </tr>
                    </table>
                </CardBody>
            </Card>
        )
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onAdd()
    {
        const apiIntegration = {
            id: guidUtil.generateGuid(),
        };
        this.addEdit(apiIntegration);
    }

    onRemove()
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        viewUtil.showConfirmDialogue('API Integration', `You are going to delete an API Integration record. Are you sure?`,
            async () =>
            {
                viewUtil.showSystemModalSpinner("Deleting API Integration");
                try
                {
                    await franchisorApiIntegrationHelper.deleteApiIntegration(selectedRow.id);
                    await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.integration, 
                                                                'Franchisor API Integration has been deleted', 
                                                                selectedRow);

                    this.state.franchisorApiIntegrations = this.state.franchisorApiIntegrations.filter(ig => ig.id != selectedRow.id);
                    this.setState({});

                    viewUtil.showSuccessAlert(`API Integration deleted`);
                }
                catch(error)
                {
                    viewUtil.showCriticalAlert(error);
                }
                finally
                {
                    viewUtil.closeModalSpinner();
                }                
            });
    }

    onRowDoubleClicked(eventData)
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const apiIntegration = this.state.franchisorApiIntegrations.find(ig => ig.id == selectedRow.id);
        this.addEdit(apiIntegration);
    }

    addEdit(apiIntegration)
    {
        const cloneObject = typeUtil.deepCloneObject(apiIntegration)
        viewUtil.openModalForm("API Integration", (onFieldChanged) =>
        {
            return (<ApiIntegration data={ cloneObject }/>);
        },
            () => { this.save(cloneObject) },
            () => { return franchisorApiIntegrationHelper.validate(cloneObject) },
            false)

    }

    async save(apiIntegration)
    {
        viewUtil.showSystemModalSpinner("Saving API Integration");
        try
        {
            await franchisorApiIntegrationHelper.save(apiIntegration);
            await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.integration, 
                                                        'Franchisor API Integration has been altered', 
                                                        apiIntegration);
                                             
            this.state.franchisorApiIntegrations = this.state.franchisorApiIntegrations.filter(sp => sp.id != apiIntegration.id);
            this.state.franchisorApiIntegrations.push(apiIntegration);        
            this.setState({});

            viewUtil.showSuccessAlert("API Integration saved");
        }
        catch(error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    constructGridColumnHeaders()
    {
        const headers = [];

        var header = {};
        header.field = "audience";
        header.headerName = "Audience";
        header.width = columnWidthConstants.code;
        header.suppressSizeToFit = false;
        headers.push(header);

        header = {};
        header.field = "clientId";
        header.headerName = "Client ID";
        header.width = columnWidthConstants.dateTime;
        headers.push(header);


        header = {};
        header.field = "clientSecret";
        header.headerName = "Client Secret";
        header.width = columnWidthConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "entities";
        header.headerName = "Entities";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "grantType";
        header.headerName = "Grant Type";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "partner";
        header.headerName = "Partner";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "webHookSecretToken";
        header.headerName = "Webhook Secret Token";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "webHookUrl";
        header.headerName = "Webhook URL";
        header.width = columnWidthConstants.number;
        headers.push(header);

        return headers;
    }
}

export default FranchisorApiIntegrations;