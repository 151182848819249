import React, { Component } from 'react';

import { getColumnCount, formatAmount, getStorefrontTypeLabel } from '../../../utils/viewUtils';

export default class SalesByStorefrontTypeSummary extends Component
{
    render()
    {
        const { salesByStorefrontType } = this.props.data;

        const cols = getColumnCount(salesByStorefrontType.length);
        const rows = Math.ceil(salesByStorefrontType.length / cols);

        return (
            <div className='container p-0'>
                {
                    Array.from({ length: rows }, (_, index) => index).map(row => 
                    {
                        return (
                            <div key={row} className='row m-0'>
                                {
                                    Array.from({ length: cols }, (_, index) => index).map(col =>
                                    {
                                        const data = salesByStorefrontType[cols * row + col];
                                        return data === undefined
                                            ? null
                                            : <div key={data.storefrontType} className='col border-bottom border-end py-3'>
                                                <div className=' d-flex flex-column justify-content-center align-items-center'>
                                                    <div className='fw-bold text-muted'>{getStorefrontTypeLabel(data.storefrontType)}</div>
                                                    <div>{formatAmount(data.totalSalePriceIncTax)}</div>
                                                </div>
                                            </div>
                                    })
                                }
                            </div>)
                    })
                }
            </div>
        );
    }
}