const rmsApiProxy = require('../../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../../utils/validator/validator');

export function createNew() 
{
    return {}
}

export async function load()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/payrollConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function save(data)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/payrollConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export function validate(payrollConfiguration)
{
    if (!validator.isPresent(payrollConfiguration.payrollRunFrequency))
        return "payrollRunFrequency is missing";

    if (!validator.isPresent(payrollConfiguration.payrollRunFrequencyOption))
        return "payrollRunFrequencyOption is missing";

    return null;
}