import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Col } from 'reactstrap';
import AttachmentButton from '../../../../../components/attachment/attachmentButton';

import EditStaffPayrollTaxes from './taxes/EditStaffPayrollTaxes';
import EditStaffPayrollSummary from './summary/EditStaffPayrollSummary';
import EditStaffPayrollDetails from './details/EditStaffPayrollDetails';
import FormManager from '../../../../../utils/view/formManager'

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const payrollCalculatorUtils = require('../utils/payrollCalculatorUtils');

const payrollRunUtils = require('../utils/payrollRunUtils');

class EditStaffPayrollRun extends Component
{
    constructor(props)
    {
        super(props);


        this.state = {
            formManager: new FormManager(),
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
        this.state.formManager.onFieldChanged = () =>
        {
            payrollCalculatorUtils.updateCalculatedFieldsForStaff(this.props.data);
        }
    }

    get isReadOnly() { return !payrollRunUtils.isEditingAllowed(); }

    render()
    {
        var tabHeaders = [];
        tabHeaders.push(<Tab>Wages</Tab>)
        tabHeaders.push(<Tab>Taxes</Tab>)
        tabHeaders.push(<Tab>Summary</Tab>)

        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{<EditStaffPayrollDetails formManager={this.state.formManager}></EditStaffPayrollDetails>}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{<EditStaffPayrollTaxes formManager={this.state.formManager}></EditStaffPayrollTaxes>}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{<EditStaffPayrollSummary formManager={this.state.formManager}></EditStaffPayrollSummary>}</TabPanel>)

        return <div className='d-flex flex-column' style={{minWidth:"60vw"}}>
            {this.renderActions()}
            <Tabs>
                <TabList>
                    {tabHeaders}
                </TabList>
                {tabContents}
            </Tabs>
        </div>
    }

    renderActions()
    {
        const franchisorId = currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId;
        const franchiseeId = currentOrgNodeSelectors.selectCurrentOrgNode().franchiseeId;
        const payrollRunId = this.props.payrollRun.id;

        //TODO: It appears there is a limitation of number of subdirectories for storing the attachments.
        //const rootFolder = `franchisors/${franchisorId}/franchisees/${franchiseeId}/payrollRun/${payrollRunId}`;
        const rootFolder = `franchisors${franchisorId}/franchisees${franchiseeId}/payrollRun${payrollRunId}`;


        const fileParam = {
            orgContexts: [currentOrgNodeSelectors.selectCurrentOrgContext()],
            rootFolder: rootFolder,
            objectType: "payroll",
            objectId: this.props.data.staffId
        };

        return <table width="100%">
            <tr>
                <td align="right">
                    <AttachmentButton fileParam={fileParam} isReadOnly={this.isReadOnly}></AttachmentButton>
                </td>
            </tr>
        </table>
    }
}

export default EditStaffPayrollRun;