import React, { Component } from 'react';
import { Button } from 'reactstrap';

import domainConstants from '../../../../../../utils/domain/constants';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';



export default class ItemHeaderComponent extends Component
{
    get isReadOnly() { return !purchaseOrderUtil.isUserAuthorizedForAnyAction(); }

    get purchaseOrder()
    {
        return this.props.purchaseOrder;
    }

    get orderItem()
    {
        return this.props.data;
    }

    get stockItem()
    {
        return purchaseOrderUtil.getStockItem(this.orderItem, this.props.lookupData);
    }

    get canDelete()
    {
        return !this.isReadOnly && this.purchaseOrder.status === domainConstants.PurchaseOrderStatusType.draft;
    }

    render()
    {
        return (
            <div className="d-flex flex-row justify-content-between align-items-center">
                <h4 className="px-2">{this.stockItem.name}</h4>
                {this.canDelete &&
                    <div style={{ marginTop: "-12px" }}>
                        <Button
                            className="mx-1"
                            color="danger" outline
                            onClick={() => this.props.onDelete(this.orderItem)}>Delete</Button>
                    </div>
                }
            </div>
        );
    }
}