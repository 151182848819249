import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import arraySort from 'array-sort';

import SelectComponent from '../../../../../../components/form/selectComponent.js';
import InputComponent from '../../../../../../components/form/inputComponent.js';
import CheckComponent from '../../../../../../components/form/checkComponent.js';

import FormManager from '../../../../../../utils/view/formManager.js';
import * as stockItemAllergyUtil from '../../../../../../utils/domain/inventory/stockItem/stockItemAllergyUtil.js';
import StockItemSections from '../StockItemSections';

const domainConstants = require('../../../../../../utils/domain/constants.js');
const catalogSelectors = require('../../../../../../utils/state/stateSelectors/catalogSelectors.js');


export default function AllergyProfiles(props)
{
    if (!props.data.allergyProfile)
        props.data.allergyProfile = {};

    const allergyProfile = props.data.allergyProfile;
    if (allergyProfile.allergies == null)
        allergyProfile.allergies = [];

    const allergies = allergyProfile.allergies;

    const columns = [];
    const options = arraySort(catalogSelectors.selectLookupData().allergies, "code");

    options.forEach(option => 
    {
        let allergyOption = allergies.find(allergy => allergy.allergyCode == option.code);

        const inferredAllergyPresence = stockItemAllergyUtil.getAllergyPresence(option.code, props.data, props.lookupData.stockItemsLookupData);

        if (inferredAllergyPresence !== domainConstants.AllergyPresenceType.unknown)
        {
            allergyOption = { allergyCode: option.code, presenceType: inferredAllergyPresence };
        }

        if (allergyOption == null)
        {
            allergyOption = { allergyCode: option.code, presenceType: domainConstants.AllergyPresenceType.unknown }
        }

        columns.push(
            <Col xs="4" className="mb-1">
                <AllergyOptionComponent allergyOption={allergyOption} inferredAllergyPresence={inferredAllergyPresence} {...props} />
            </Col>
        );
    });

    const [value, setValue] = useState(false);

    const formManager = new FormManager();
    formManager.viewModel = props.data;
    formManager.onFieldChanged = () => setValue(!value);

    return  <StockItemSections title="Allergy Profile" {...props}>
                <div className="d-flex flex-column mx-3">
                    <Row>
                        <Col xs="4" className="mb-1">
                            <CheckComponent
                                caption="Is Allergy Profile Enabled"
                                fieldName="allergyProfileEnabled"
                                inputReadOnly={props.isReadOnly}
                                formManager={formManager} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="4" className="mb-1">
                            <AllergyLabelsComponent {...props} />
                        </Col>
                    </Row>
                    <Row >{columns}</Row>
                </div>
            </StockItemSections>
}

function AllergyOptionComponent(props)
{
    const [value, setValue] = useState(false);

    const isReadOnly = props.isRowReadOnly;

    const allergyOption = props.allergyOption;

    const formManager = new FormManager();
    formManager.viewModel = allergyOption;
    formManager.onFieldChanged = () => 
    {
        const allergies = props.data.allergyProfile.allergies.filter(allergy => allergy.allergyCode != allergyOption.allergyCode);

        if (allergyOption.presenceType && allergyOption.presenceType != domainConstants.AllergyPresenceType.unknown)
        {
            allergies.push(allergyOption);
        }

        props.data.allergyProfile.allergies = allergies;

        setValue(!value);
    }

    var comboBackground = '';

    const allergy = props.data.allergyProfile.allergies.find(allergy => allergy.allergyCode === allergyOption.allergyCode);
    if (allergy == null)
    {
        if (!props.inferredAllergyPresence)
            comboBackground = '#82EEFD';
        else if (props.inferredAllergyPresence != domainConstants.AllergyPresenceType.unknown)
            comboBackground = '#F9E79F';
    }

    return (
        <SelectComponent
            optionValues={selectOptions}
            optionFieldName="valueField"
            optionDisplayFieldName="displayField"
            caption={allergyOption.allergyCode}
            fieldName="presenceType"
            hintText={allergyOption.notes}
            formManager={formManager}
            comboReadOnly={isReadOnly || !props.data.allergyProfileEnabled}
            searchable={false}
            style={{ marginTop: '-5px', backgroundColor: comboBackground }} />
    );
}

function AllergyLabelsComponent(props)
{
    const [value, setValue] = useState(false);

    const allergyProfile = props.data.allergyProfile;
    const isReadOnly = props.isRowReadOnly;

    const formManager = new FormManager();
    formManager.viewModel = allergyProfile;
    formManager.onFieldChanged = () => setValue(!value);

    return (
        <InputComponent
            inputType="number"
            caption="Minimum Allergy Labels Per Day"
            placeholder="Enter minimum allergy labels per day"
            hintText="Minimum number of allergy labels to be printed each day"
            fieldName="minAllergyLabels"
            inputReadOnly={isReadOnly || !props.data.allergyProfileEnabled}
            formManager={formManager} />
    );
}

const selectOptions = [
    {
        valueField: domainConstants.AllergyPresenceType.mayBe,
        displayField: 'May Be'
    },
    {
        valueField: domainConstants.AllergyPresenceType.notPresent,
        displayField: 'Not Present'
    },
    {
        valueField: domainConstants.AllergyPresenceType.present,
        displayField: 'Present',
    },
    {
        valueField: domainConstants.AllergyPresenceType.unknown,
        displayField: 'Unknown',
        disabled: true
    },
];
