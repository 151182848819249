
import React, { Component } from 'react';
import { connect } from 'react-redux'

import SideBarView from './SideBarView';
import TopBarView from './TopBarView';
import MainBarView from './MainBarView';

import AccountSwitchView from '../accountSwitch/AccountSwitchView';

import { confirmAlert } from 'react-confirm-alert';
import ModalSpinner from '../../components/spinner/modalSpinner.js';
import ModalForm from '../../components/modalForm/modalForm.js';
import AttachmentForm from '../../components/attachment/attachmentForm.js';
import AlertContainer from '../../components/alert/alertContainer.js';
import ValidationModal from '../modules/menus/menu/common/validation/validationModal.jsx';

var viewUtil = require('../../utils/view/viewUtil.js');

class ContainerView extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {
        this.showConfirmationDialogue();

        return (
            <div style={{ width: "100%" }}>
                <div className='d-flex flex-column' style={{ width: "100%", height: '100%' }}>
                    <TopBarView />
                    <div className='d-flex flex-row' style={{ flex: 1, height: 0 }}>
                        <div style={{ whiteSpace: "nowrap", verticalAlign: "top", padding: "0px" }}>
                            <SideBarView />
                        </div>
                        <div style={{ verticalAlign: "top", padding: "0px", flex: 1, overflow: 'auto', height: '100%' }}><MainBarView /></div>
                    </div>
                </div>
                {this.renderAccountSwitchScreen()}
                <AlertContainer />
                <ModalSpinner />
                {this.showModalForm()}
                {this.showAttachmentForm()}
            </div>
        );
    }

    renderAccountSwitchScreen()
    {
        if (!this.props.displayAccountSwitchScreen)
            return null;

        return <AccountSwitchView />
    }


    showConfirmationDialogue()
    {
        if (this.props.confirmDialogue == null)
            return;

        var confirmOptions = {};
        confirmOptions.title = this.props.confirmDialogue.title;
        confirmOptions.message = this.props.confirmDialogue.message;
        confirmOptions.buttons = [
            {
                label: 'Yes',
                onClick: () =>
                {
                    viewUtil.closeConfirmDialogue();
                    this.props.confirmDialogue.onConfirm();
                }
            },
            {
                label: 'No',
                onClick: () =>
                {
                    viewUtil.closeConfirmDialogue();
                    if (this.props.confirmDialogue.onCancel != null)
                        this.props.confirmDialogue.onCancel();
                }
            }
        ];

        confirmOptions.afterClose = () => { viewUtil.closeConfirmDialogue(); };
        confirmOptions.overlayClassName = 'confirm-alert-overlay';
        confirmAlert(confirmOptions);
    }

    showModalForm()
    {
        var modalFormsComponents = [];

        var index = 0;
        this.props.modalForms.forEach(modalForm =>
        {
            if (modalForm != null && modalForm.componentCreator != null)
                modalFormsComponents.push(<ModalForm modalFormIndex={index}></ModalForm>);

            index = index + 1;
        })

        return modalFormsComponents;
    }

    showAttachmentForm()
    {

        if (this.props.attachmentForm == null || this.props.attachmentForm.objectType == null || this.props.attachmentForm.objectId == null)
            return;

        return (<AttachmentForm></AttachmentForm>);
    }
}

const mapStateToProps = state => 
{
    return {
        displayAccountSwitchScreen: state.displayAccountSwitchScreen,
        confirmDialogue: state.confirmDialogue,
        modalForms: state.modalForms,
        attachmentForm: state.attachmentForm,
    }
}

export default connect(mapStateToProps)(ContainerView)