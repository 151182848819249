import React, { Component } from 'react';
import { Card, CardHeader } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import SearchBarComponent from '../../../../../components/form/searchBarComponent';
import CloseButton from '../../../../../components/button/closeButton';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EmptyData from '../../../../../components/empty/emptyData';

import SalesOverviewSummary from './salesOverviewSummary/SalesOverviewSummary';
import SaleItemsOverviewSummary from './saleItemsOverviewSummary/SaleItemsOverviewSummary';
import SaleOverviewDetails from './salesOverviewDetails/SaleOverviewDetails';
import SaleItemOverviewDetails from './salesOverviewDetails/SaleItemOverviewDetails';
import DiscountOverviewDetails from './salesOverviewDetails/DiscountOverviewDetails';
import ChargeOverviewDetails from './salesOverviewDetails/ChargeOverviewDetails';
import RefundOverviewDetails from './salesOverviewDetails/RefundOverviewDetails';
import BrandWiseTabbedReport from '../../../reportUtils/components/BrandWiseTabbedReport';

import { getFormattedData } from './utils/dataUtils';

const viewUtil = require('../../../../../utils/view/viewUtil');
const reportsLoadingFacade = require('../../../../../utils/api/reportsLoadingFacade');

class SalesOverviewAnalytics extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            searchCriteria: null, data: null,
            showBrandWise: false,
            isLoading: false,
            error: null,
            brands: [],
        }

        this.getReport = this.getReport.bind(this);
        this.getBrands = this.getBrands.bind(this);
    }

    render()
    {
        let component;

        const { isLoading, error } = this.state;

        if (isLoading === true) 
        {
            component = null;
        }
        else if (error != null)
        {
            component = <ErrorMessage message={error} />
        }
        else
        {
            component = this.renderData();
        }

        return (
            <div className='d-flex flex-column'>

                <Card>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <div>
                            <SearchBarComponent
                                searchSingleDate={false}
                                onChange={this.onSearchCriteriaChanged}
                                onSearch={this.onSearch}
                            />
                        </div>
                        <div className='fw-bold'>Sales Overview Analytics (*New)</div>
                        <div><CloseButton disabled={false} /></div>
                    </CardHeader>
                </Card>

                <div>
                    {component}
                </div>

            </div>
        );
    }

    getBrands(data)
    {
        if (data == null) return;

        const [salesSummaryData] = data;
        if (salesSummaryData == null) return;

        let brands = [...new Set(salesSummaryData.map(d => d.storefrontBrand))];
        return brands;
    }

    onSearchCriteriaChanged = (searchCriteria) =>
    {
        this.setState({ searchCriteria: null, data: null, brands: null, error: null });
    }

    onSearch = async (searchCriteria) =>
    {
        try
        {
            this.setState({ isLoading: true, searchCriteria: null, data: null, brands: null, error: null });

            viewUtil.showSystemModalSpinner("Loading data, please wait ...");

            const { startDate: fromDate, endDate: toDate } = searchCriteria;

            const promises = [
                reportsLoadingFacade.getSalesSummary(fromDate, toDate),
                reportsLoadingFacade.getSalesDetails(fromDate, toDate),
                reportsLoadingFacade.getPaymentMethodsSummary(fromDate, toDate),
                reportsLoadingFacade.getDiscountsSummary(fromDate, toDate),
                reportsLoadingFacade.getRefundsSummary(fromDate, toDate),
                reportsLoadingFacade.getChargesSummary(fromDate, toDate),
            ];

            const data = await Promise.all(promises);
            this.enrichData(data);

            this.setState({ searchCriteria: searchCriteria, data: data, brands: this.getBrands(data) });

        }
        catch (error)
        {
            this.setState({ error: error });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    enrichData(data)
    {
        // Enrich data with additional properties
        const [salesSummaryData, salesDetailData, salesByPaymentMethodData, discountsData, refundsData, chargesData] = data;

        // Enriching chargesData below is a workaround required to populate pinned rows as
        // valueGetter columns don't seem to lend themselves to pinned row aggregation

        chargesData.forEach(data => 
        {
            data.netCharge = data.retailAmount - data.refund;
            data.netTax = data.tax - data.refundTax;
            data.totalCharges = data.netCharge + data.netTax;
        });
    }

    renderData()
    {
        return <BrandWiseTabbedReport brands={this.state.brands} getReport={this.getReport} />
    }

    getReport(brand)
    {
        const data = this.state.data ? getFormattedData(this.state.data, brand) : null;

        let searchCriteria = this.state.searchCriteria;
        let component;

        if (data == null)
        {
            let title = 'Data required';
            let text = 'Please choose date range and hit Search to get report data';
            let action = null;

            if (searchCriteria != null) 
            {
                // Implies search did not return any data

                title = 'No Data Found';
                text = 'No data was found for your selected date range !!'
                action = 'Please choose a different date range and hit Search to get report data';
            }

            component = <EmptyData title={title} text={text} action={action} />
        }
        else
        {
            component = <Tabs className="mt-2">

                <TabList>
                    <Tab>Sales Summary</Tab>
                    <Tab>Sale Items Summary</Tab>
                    <Tab>Sales Detail</Tab>
                    <Tab>Sale Items Detail</Tab>
                    <Tab>Discounts Detail</Tab>
                    <Tab>Charges Detail</Tab>
                    <Tab>Refunds Detail</Tab>
                </TabList>

                <TabPanel><SalesOverviewSummary data={data} /></TabPanel>
                <TabPanel><SaleItemsOverviewSummary data={data} /></TabPanel>
                <TabPanel><SaleOverviewDetails data={data} /></TabPanel>
                <TabPanel><SaleItemOverviewDetails data={data} /></TabPanel>
                <TabPanel><DiscountOverviewDetails data={data} /></TabPanel>
                <TabPanel><ChargeOverviewDetails data={data} /></TabPanel>
                <TabPanel><RefundOverviewDetails data={data} /></TabPanel>

            </Tabs>;
        }

        return component;
    }
}

export default SalesOverviewAnalytics;