import React, {Component} from 'react';

import * as stringUtil from '../../../../utils/string/stringUtil';
import * as dateUtil from '../../../../utils/dateUtil/dateUtil';
import PictureAttachment from '../../../../components/pictureAttachment/PictureAttachment';

class IncidentRow extends Component
{

    getIncident(incidentLog)
    {    
        const incidentTypeName = incidentLog.incidentTypeName;
    
        const dateTime = incidentLog.dateTime;
        const pictureAttachments = incidentLog.pictureAttachments;
    
        let createdUpdatedOnCaption = null;
        let createdUpdatedByCaption = null;
        let createdUpdatedOn = null;
        let createdUpdatedByName = null;
        if(incidentLog.lastUpdatedOn && incidentLog.lastUpdatedBy)
        {
            createdUpdatedOnCaption = "Last Updated On"; 
            createdUpdatedByCaption = "Last Updated By"; 
            createdUpdatedOn = incidentLog.lastUpdatedOn; 
            createdUpdatedByName = incidentLog.lastUpdatedByName; 
        }
        else
        {
            createdUpdatedOnCaption = "Created On"; 
            createdUpdatedByCaption = "Created By"; 
            createdUpdatedOn = incidentLog.createdOn; 
            createdUpdatedByName = incidentLog.createdByName; 
        }
    
        return (
            <div className='d-flex flex-column'>
                <div className="fw-bold">
                    <i className='fa fa-exclamation-triangle text-warning' />&nbsp;&nbsp;{incidentTypeName}
                </div>
                
                <div className='mt-2 d-flex flex-column'>
                    <span className="fw-bold text-muted">Occurred At</span>
                    <div className="fw-bold text-nowrap">
                        <i className='fa fa-clock-o' />&nbsp;{dateUtil.convertToLocalStandard(dateTime)}
                    </div>
                </div>
                <div className='mt-2 d-flex flex-column'>
                    <span className="fw-bold text-muted">{createdUpdatedByCaption}</span>
                    <div className="fw-bold text-nowrap">
                        <i className='fa fa-user-circle-o' />&nbsp;&nbsp;{createdUpdatedByName}
                    </div>
                </div>
                <div className='mt-2 d-flex flex-column'>
                    <span className="fw-bold text-muted">{createdUpdatedOnCaption}</span>
                    <div className="fw-bold text-nowrap">
                        <i className='fa fa-clock-o' />&nbsp;{dateUtil.convertToLocalStandard(createdUpdatedOn)}
                    </div>
                </div>
    
    
                {
                    pictureAttachments.length > 0 &&
                    <div className='mt-3 d-flex flex-wrap border-top'>
                        {
                            pictureAttachments.map(pictureAttachment =>
                                <div className='mt-2 me-3 h4'>
                                    <PictureAttachment title={`Incident Type: ${incidentTypeName}`} pictureAttachment={pictureAttachment} />
                                </div>)
                        }
                    </div>
                }
            </div>
        );
    }
    
    getIncidentQuestionResponse(responseLog)
    {
        const question = responseLog.incidentQuestion;
        const isCompulsory = responseLog.incidentQuestionIsCompulsory;
        const responseType = responseLog.incidentQuestionResponseType;
        const response = responseLog.response.length != 0 ? responseLog.response : ["No Response Provided"];
        let responseComponent = response.join(",  ");        
    
        if(stringUtil.areStringSame(responseType, "Boolean"))
        {
            switch(response[0])
            {
                case "true":
                    responseComponent = <span className="badge bg-success"><i className="fa fa-check-square-o"/>&nbsp;&nbsp;Yes</span>;
                    break;
                case "false":
                    responseComponent = <span className="badge bg-danger"><i className="fa fa-times-circle-o"/>&nbsp;&nbsp;No</span>;
                    break;
                default:
                    break;
            }
        }
        
        return (
            <div className='d-flex flex-column'>
                <div className='text-muted'>
                    {isCompulsory ? <span className='text-danger fw-bold'>*&nbsp;</span> : ""}
                    {question}
                </div>
                <div className='mt-1'>
                    {responseComponent}
                </div>            
            </div>);
    }
    
    render()
    {
        const backgroundColor = this.props.backgroundColor;
        const incidentLog = this.props.incidentLog;
        const incidentResponseLogs = this.props.incidentResponseLogs;

        return (
            <>
                <tr style={{ backgroundColor }}>
                    <td rowSpan={this.props.rowSpan}>{this.getIncident(incidentLog)}</td>
                </tr>
                {
                    incidentResponseLogs.length > 0 &&
                    incidentResponseLogs.map(responseLog => 
                    {
                        return (
                            <tr style={{ backgroundColor }}>
                                <td>{this.getIncidentQuestionResponse(responseLog)}</td>
                            </tr>
                        );
                    })
                }
            </>
        );
    }
}

export default IncidentRow;