import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import GridViewOptions from '../../../../components/gridView/gridViewOptions';
import GridViewButton from '../../../../components/gridView/gridViewButton';
import { EnumDays, EnumWeeks, EnumMonths } from '../../../../utils/enums/enumUtil';
import * as reportStyles from '../../../reports/reportUtils/styles';

import Task from './components/Task';

const arraySort = require('array-sort');

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

const stringUtil = require('../../../../utils/string/stringUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const typeUtil = require('../../../../utils/type/typeUtil');
const guidUtil = require('../../../../utils/guid/guidUtil');
const viewUtil = require('../../../../utils/view/viewUtil');

const validator = require('../../../../utils/validator/validator');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');
const passportSelectors = require('../../../../utils/state/stateSelectors/passportSelectors');

class Tasks extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadTasks = this.loadTasks.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);

        this.state = {
            lookupData: {
                taskTypes: []
            }
        }
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Tasks";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadTasks;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        gridViewOptions.getNewRow = () => ({
            // creation node
            franchisorId: currentOrgNode.franchisorId,
            franchiseeId: currentOrgNode.franchiseeId,
            propertyId: currentOrgNode.propertyId
        });

        gridViewOptions.getComponent = (isNew, row) =>
            <Task
                data={row}
                isRowReadOnly={this.isRowReadOnly(row)}
                lookupData={this.state.lookupData} />;

        gridViewOptions.getComponentTitle = (isNew, row) => isNew ? "Tasks - New" : `Tasks - ${row.name}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = this.isRowReadOnly;
        gridViewOptions.getRowStyle = this.getRowStyle;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.buttons = [this.getDuplicateTaskActionButton(), this.getDeleteTaskActionButton()];

        gridViewOptions.messageContext = "Tasks";

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new task.";
            return "Please wait while updating task.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New task added successfully";
            return "Task updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new task:";
            return "Following error occurred while updating task:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row) =>
    {
        if (this.props.isReadOnly) return true;
        // Only the creation node can edit task
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        return !(
            row.franchisorId == currentOrgNode.franchisorId &&
            row.franchiseeId == currentOrgNode.franchiseeId &&
            row.propertyId == currentOrgNode.propertyId);
    }

    getRowStyle = (params) =>
    {
        if (this.isRowReadOnly(params.data)) 
        {
            return { background: reportStyles.readOnlyRowBackgroundColor };
        }

        return null;
    }

    validateRow(isNew, row)
    {
        if (stringUtil.isStringNullOrEmpty(row.name))
            return "Task name cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(row.taskTypeId))
            return "Task type not selected";

        if (!validator.isInteger(row.days) || row.days === 0)
            return "Task days (atleast one) must be selected";

        if (!validator.isInteger(row.weeks) || row.weeks === 0)
            return "Task weeks (atleast one) must be selected";

        if (!validator.isInteger(row.months) || row.months === 0)
            return "Task months (atleast one) must be selected";

        if (!stringUtil.isStringNullOrEmpty(row.expiryDate))
        {
            if (!dateUtil.isValidDate(row.expiryDate))
                return "Expiry date is invalid";

            if (isNew && dateUtil.isBefore(row.expiryDate, dateUtil.getToday()))
                return "Expiry date is invalid";
        }

        if (!validator.isOptionalColorHexString(row.colorCode))
        {
            return "Invalid color code";
        }

        return null;
    }

    async loadTasks()
    {
        // Load lookups data
        this.state.lookupData.taskTypes = await apiLoadFacade.loadTaskTypes();

        // Load data
        const tasks = await rmsApiProxy.get(`${rmsApiProxy.getCurrentOrgNodeContextUrl()}/foodsafety/tasks`);
        this.enrichTasks(tasks);

        return arraySort(tasks, stringUtil.localeCompareProp("name"));
    }

    async save(isNew, row, remoteData)
    {
        if (isNew)
        {
            row.createdOn = dateUtil.getNow();
            row.lastUpdatedOn = null;
        }
        else
        {
            row.lastUpdatedOn = dateUtil.getNow();
        }

        try
        {
            const updatedTask = await rmsApiProxy.post(`${rmsApiProxy.getCurrentOrgNodeContextUrl()}/foodsafety/tasks`, row);
            this.enrichTasks(updatedTask);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(task => task.id == updatedTask.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedTask);
            else
                remoteData.push(updatedTask);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedTask;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    getDuplicateTaskActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Duplicate Task";
        button.color = "primary";

        button.onRowSelect = true;
        button.actionType = "action";

        button.actionConfirmationTitle = "Copy Task";
        button.onActionConfirmationMessage = (selectedTask) => 
        {
            return `Do you wish to create a duplicate row for the selected task "${selectedTask.name}" ?`
        }

        button.onActionWaitMessage = (selectedTask, customButton) => "Please wait while saving duplicate row data ...";
        button.onActionSuccessMessage = (selectedTask, customButton) => "Selected row duplicated successfully";
        button.onActionFailMessage = (selectedTask, customButton, error) => error.toString();

        button.onAction = async (selectedTask, remoteData, customButton) =>
        {
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

            const duplicateTask = typeUtil.deepCloneObject(selectedTask);

            duplicateTask.id = guidUtil.generateGuid();
            duplicateTask.name += ' (Copy)';

            duplicateTask.franchisorId = currentOrgNode.franchisorId;
            duplicateTask.franchiseeId = currentOrgNode.franchiseeId;
            duplicateTask.propertyId = currentOrgNode.propertyId;

            duplicateTask.createdOn = dateUtil.getNow();
            duplicateTask.lastUpdatedOn = null;

            // Filter out applicablePropertyIds of properties not allowed by currentOrgNode 

            const allowedPropertyIds =
                currentOrgNodeSelectors.selectAllProperties().map(property => property.id);

            duplicateTask.applicablePropertyIds =
                duplicateTask.applicablePropertyIds.filter(propertyId => allowedPropertyIds.includes(propertyId));

            try
            {
                const addUpdatedTask = await rmsApiProxy.post(`${rmsApiProxy.getCurrentOrgNodeContextUrl()}/foodsafety/tasks`, duplicateTask);
                this.enrichTasks(addUpdatedTask);

                if (!remoteData) remoteData = [];

                // Insert new row just after the source row
                const existingIndex = remoteData.findIndex(task => task.id == selectedTask.id);
                remoteData.splice(existingIndex + 1, 0, addUpdatedTask);

                const response = {};
                response.remoteData = remoteData;
                response.addUpdatedRow = addUpdatedTask;

                return response;

            } catch (error)
            {
                throw new Error(`Failed to save duplicate row with error: ${error}`);
            }
        }

        return button;
    }

    getDeleteTaskActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Delete Task";
        button.color = "danger";

        button.onRowSelect = true;
        button.actionType = "action";

        button.actionConfirmationTitle = "Delete Task";
        button.onActionConfirmationMessage = (selectedTask) => 
        {
            return `Do you wish to delete selected task "${selectedTask.name}" ?`
        }

        button.onActionWaitMessage = (selectedTask, customButton) => "Please wait while deleting task ...";
        button.onActionSuccessMessage = (selectedTask, customButton) => "Selected task deleted successfully";
        button.onActionFailMessage = (selectedTask, customButton, error) => error.toString();

        button.onAction = async (selectedTask, remoteData, customButton) =>
        {
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

            if (selectedTask.franchisorId !== currentOrgNode.franchisorId ||
                selectedTask.franchiseeId !== currentOrgNode.franchiseeId ||
                selectedTask.propertyId !== currentOrgNode.propertyId) 
            {
                // Implies current node is not the same as creation node
                // Franchisee cannot delete task created by franchisor
                throw new Error("Permission denied, you are not authorised to delete this task !");
            }

            const taskId = selectedTask.id;

            try
            {
                
                await rmsApiProxy.deleted(`${rmsApiProxy.getCurrentOrgNodeContextUrl()}/foodsafety/tasks?taskId=${taskId}`);

                if (!remoteData) remoteData = [];

                // Delete task from the remoteData collection
                const existingIndex = remoteData.findIndex(task => task.id === taskId);
                remoteData.splice(existingIndex, 1);

                const response = {};
                response.remoteData = remoteData;
                response.addUpdatedRow = null;

                return response;

            } catch (error)
            {
                throw new Error(`Failed to delete task with error: ${error}`);
            }
        }

        return button;
    }

    enrichTasks(tasks)
    {
        //
        // tasks are now sent by api already enriched with task.createdByName and task.lastUpdatedByName
        //

        const properties = catalogSelectors.selectProperties();
        const taskTypes = this.state.lookupData.taskTypes;

        const allDaysBitField = Object.keys(EnumDays).reduce((accumulator, key) => accumulator + EnumDays[key], 0);
        const allWeeksBitField = Object.keys(EnumWeeks).reduce((accumulator, key) => accumulator + EnumWeeks[key], 0);
        const allMonthsBitField = Object.keys(EnumMonths).reduce((accumulator, key) => accumulator + EnumMonths[key], 0);

        if (!Array.isArray(tasks)) tasks = [tasks];

        tasks.forEach(task => 
        {
            task.taskType = taskTypes.find(taskType => taskType.id == task.taskTypeId)?.name || "N/A";

            if (task.days === allDaysBitField)
            {
                task.daysOfWeek = "All days of the week";

            } else
            {
                task.daysOfWeek =
                    Object.keys(EnumDays)
                        .filter(key => (EnumDays[key] & task.days) === EnumDays[key])
                        .join();
            }

            if (task.weeks === allWeeksBitField)
            {
                task.weeksOfMonth = "All weeks of the month";

            } else
            {
                task.weeksOfMonth =
                    Object.keys(EnumWeeks)
                        .filter(key => (EnumWeeks[key] & task.weeks) === EnumWeeks[key])
                        .join();
            }

            if (task.months === allMonthsBitField)
            {
                task.monthsOfYear = "All months of the year";

            } else
            {
                task.monthsOfYear =
                    Object.keys(EnumMonths)
                        .filter(key => (EnumMonths[key] & task.months) === EnumMonths[key])
                        .join();
            }
        });
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Task Name";
        header.pinned = true;
        header.width = columnWidthConstants.name - 50;
        headers.push(header);

        header = {};
        header.field = "taskType";
        header.headerName = "Task Type";
        header.width = columnWidthConstants.name - 50;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "isCompulsory";
        header.headerName = "Is Compulsory";
        header.width = columnWidthConstants.boolean;
        header.type = dataTypeConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "colorCode";
        header.headerName = "Color Code";
        header.width = columnWidthConstants.color;
        header.type = dataTypeConstants.color;
        headers.push(header);

        header = {};
        header.colId = "hazardTypes";
        header.valueGetter = params => params.data.hazardTypes.join(", ");
        header.headerName = "Hazard Types";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "timeOfDay";
        header.headerName = "Time";
        header.width = columnWidthConstants.code - 150;
        headers.push(header);

        header = {};
        header.field = "daysOfWeek";
        header.headerName = "Days";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "weeksOfMonth";
        header.headerName = "Weeks";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "monthsOfYear";
        header.headerName = "Months";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.colId = "taskActions";
        header.valueGetter = params => params.data.taskActions.length;
        header.headerName = "Task Actions";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "expiryDate";
        header.headerName = "Expiry Date";
        header.width = columnWidthConstants.date;
        header.type = dataTypeConstants.date;
        headers.push(header);

        header = {};
        header.field = "createdOn";
        header.headerName = "Created On";
        header.width = columnWidthConstants.dateTime;
        header.type = dataTypeConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "lastUpdatedOn";
        header.headerName = "Last Updated On";
        header.width = columnWidthConstants.dateTime;
        header.type = dataTypeConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Tasks;