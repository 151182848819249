const configurationManager = require('../../utils/config/configurationManager');

module.exports.shellTypes = {
    windows: 'windows',
    android: 'android',
    iOS: 'iOS',
};


module.exports.shellTypeOptions =
    [
        //
        // list suitable for binding in SelectComponent
        //

        { value: this.shellTypes.iOS, label: 'iOS' },
        { value: this.shellTypes.android, label: 'Android' },
        { value: this.shellTypes.windows, label: 'Windows' },
    ];

module.exports.getShellTypeImage = function (type)
{
    switch (type)
    {
        case this.shellTypes.android:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/android.png`;

        case this.shellTypes.windows:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/windows.png`;
            
        case this.shellTypes.iOS:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/ios.png`;

        default:
            return null;
    }
}




module.exports.shellRenderingMode = {
    tablet: 'tablet',
    desktop: 'desktop',
    tv: 'tv'
};


module.exports.shellRenderingModeOptions =
    [
        //
        // list suitable for binding in SelectComponent
        //

        { value: this.shellRenderingMode.tablet, label: 'Tablet' },
        { value: this.shellRenderingMode.desktop, label: 'Desktop' },
        { value: this.shellRenderingMode.tv, label: 'TV' },
    ];

module.exports.getRenderingModelImage = function (type)
{
    switch (type)
    {
        case this.shellRenderingMode.tablet:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/tablet.png`;

        case this.shellRenderingMode.desktop:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/desktop.png`;
            
        case this.shellRenderingMode.tv:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/tv.png`;
            
        default:
            return null;
    }
}

module.exports.shellScreenOrientation = {
    landscapeLeft: 'landscapeLeft',
    landscapeRight: 'landscapeRight',
    portraitUp: 'portraitUp',
    portraitDown: 'portraitDown'
};


module.exports.shellScreenOrientationOptions =
    [
        //
        // list suitable for binding in SelectComponent
        //

        { value: this.shellScreenOrientation.landscapeLeft, label: 'Landscape Left' },
        { value: this.shellScreenOrientation.landscapeRight, label: 'Landscape Right' },
        { value: this.shellScreenOrientation.portraitUp, label: 'Portrait Up' },
        { value: this.shellScreenOrientation.portraitDown, label: 'Portrait Down' },
    ];

module.exports.getOrientationImage = function (type)
{
    switch (type)
    {
        case this.shellScreenOrientation.landscapeLeft:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/LandscapeLeft.png`;

        case this.shellScreenOrientation.landscapeRight:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/LandscapeRight.png`;

        case this.shellScreenOrientation.portraitUp:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/PortraitUp.png`;

        case this.shellScreenOrientation.portraitDown:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/PortraitDown.png`;
            
        default:
            return null;
    }
}

