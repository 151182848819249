import React from 'react';

import InputComponent from '../../../../../components/form/inputComponent';
import CheckComponent from '../../../../../components/form/checkComponent';

import FormManager from '../../../../../utils/view/formManager';

export default function SupplierOverview(props) 
{
    const formManager = new FormManager();
    formManager.viewModel = props.data;

    formManager.onFieldChanged = (event) =>
    {
        if (props.onFieldChanged)
            props.onFieldChanged();
    }

    return (
        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent
                            inputType="name"
                            caption="Name"
                            fieldName="name"
                            hintText="Required"
                            placeholder="Enter name of the supplier"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                    <td>
                        <CheckComponent
                            caption="Disable this supplier"
                            fieldName="isDisabled"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            caption="Address"
                            fieldName="address"
                            hintText="Required"
                            placeholder="Enter address of the supplier"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="phone"
                            caption="Phone Number"
                            fieldName="phoneNumber"
                            hintText="Required"
                            placeholder="Enter phone number of the supplier"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="email"
                            caption="Email Address"
                            fieldName="emailAddress"
                            hintText="Required"
                            placeholder="Enter email address of the supplier"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="web"
                            caption="Web Site"
                            fieldName="webSite"
                            hintText="Optional"
                            placeholder="Enter website of the supplier"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            caption="Notes"
                            fieldName="notes"
                            placeholder="Enter any notes."
                            multiLine={true} rows={4}
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

            </tbody>
        </table>
    );
}
