
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux'

import CloseButton from '../../components/button/closeButton';
import PulseSpinner from '../../components/spinner/pulseSpinner';

import * as biComponentHelper from './helpers/biComponentHelper';               // BI reports
import * as reportsComponentHelper from './helpers/reportsComponentHelper';     // Snowflake reports
import AuditLogs from '../modules/auditLogs/AuditLogs';


//Organization Module Component
const DocumentsExplorer = React.lazy(() => lazyRetry(() => import('../../views/modules/documents/DocumentsExplorer')));
const FranchisorOperatingConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/franchisor/franchisorOperatingConfiguration/FranchisorOperatingConfiguration')));
const FranchisorDatabaseConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/franchisor/franchisorDatabaseConfiguration/FranchisorDatabaseConfiguration')));
const FranchisorBasicConfiguration = React.lazy(() => lazyRetry(() => import('../setup/organization/franchisor/franchisorBasicConfiguration/FranchisorBasicConfiguration')));
const FranchisorApiIntegration = React.lazy(() => lazyRetry(() => import('../setup/organization/franchisor/franchisorApiIntegration/FranchisorApiIntegrations')));
const FranchisorChargesConfiguration = React.lazy(() => lazyRetry(() => import('../setup/organization/franchisor/chargesConfiguration/Charges')));
const PropertyBasicConfiguration = React.lazy(() => lazyRetry(() => import('../setup/organization/property/propertyBasicConfiguration/PropertyBasicConfiguration')));
const PropertyAddressConfigurations = React.lazy(() => lazyRetry(() => import('../setup/organization/property/propertyAddressConfiguration/PropertyAddressConfigurations')));
const PropertyPrintConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/property/propertyPrintConfiguration/PropertyPrintConfiguration')));
const PropertyGeoConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/property/propertyGeoConfiguration/PropertyGeoConfiguration')));
const PropertyLayoutConfiguration = React.lazy(() => lazyRetry(() => import('../setup/organization/property/propertyLayoutConfiguration/PropertyLayoutConfiguration')));
const PropertyFloatConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/property/propertyFloatConfiguration/PropertyFloatConfiguration')));
const PropertyOperatingConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/property/propertyOperatingConfiguration/PropertyOperatingConfiguration')));
const PropertyDeliveryManagementConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/property/propertyDeliveryManagementConfiguration/PropertyDeliveryManagementConfiguration')));
const PropertyLicenseSubscriptions = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/property/propertyLicenseSubscriptions/PropertyLicenseSubscriptions')));
const StaffPermissionsConfiguration = React.lazy(() => lazyRetry(() => import('../setup/organization/property/propertyOperatingConfiguration/StaffPermissionsConfiguration')));
const PropertyPayrollConfiguration = React.lazy(() => lazyRetry(() => import('../setup/organization/property/propertyPayrollConfiguration/PropertyPayrollConfiguration')));
const StorefrontCore = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/core/StorefrontCore')));
const StorefrontOpeningHours = React.lazy(() => lazyRetry(() => import('../setup/organization/storefront/openingHours/StorefrontOpeningHours')));
const StorefrontBusinessHourOverrides = React.lazy(() => lazyRetry(() => import('../setup/organization/storefront/businessHourOverrides/StorefrontBusinessHourOverrides')));
const StorefrontDeliveryCharges = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/deliveryCharges/StorefrontDeliveryCharges')));
const StorefrontTipConfiguration = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/tipConfiguration/StorefrontTipConfiguration')));
const StorefrontOperatingConfigurations = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/operatingConfiguration/StorefrontOperatingConfigurations')));
const StorefrontStorePayoutConfigurations = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/operatingConfiguration/StorefrontStorePayoutConfigurations')));
const StorefrontOrderIngest = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/orderIngest/StorefrontOrderIngest')));
const StorefrontWebAppConfigurations = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/webAppConfiguration/StorefrontWebAppConfigurations')));
const StorefrontOverPhonePaymentConfigurations = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/overPhonePaymentConfiguration/StorefrontOverPhonePaymentConfigurations')));
const StorefrontMobileAppConfigurations = React.lazy(() => lazyRetry(() => import('../../views/setup/organization/storefront/mobileAppConfiguration/StorefrontMobileAppConfigurations')));
const StorefrontTimingConfigurations = React.lazy(() => lazyRetry(() => import('../setup/organization/storefront/timingConfiguration/StorefrontTimingConfigurations')));
const Brands = React.lazy(() => lazyRetry(() => import('../setup/organization/franchisor/brands/Brands')));

// Localization
const LocalizationSettingOverrides = React.lazy(() => lazyRetry(() => import('../../views/setup/localization/LocalizationSettingOverrides')));


//Organization Module [Catalog Lookups] Component
const Lookups = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/Lookups')));
const Venues = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/venues/Venues')));

//Organization Module [Organization Lookups] Component
const Tags = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/tags/Tags')));
const KitchenStationProfiles = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/kitchenStationProfiles/KitchenStationProfiles')));
const Guests = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/guests/Guests')));
const FreebieReasons = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/freebieReasons/FreebieReasons')));
const PriceBands = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/priceBands/PriceBands')));
const PrintingTemplates = React.lazy(() => lazyRetry(() => import('../../views/setup/system/printingTemplates/PrintingTemplates')));
const Nomenclatures = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/nomenclatures/Nomenclatures')));
const AddressMapping = React.lazy(() => lazyRetry(() => import('../../views/setup/system/lookups/addressManagement/addressMapping/AddressMapping')));
const PaymentTypes = React.lazy(() => lazyRetry(() => import('../setup/system/lookups/paymentTypes/PaymentTypes')) );

//Security Module Component
const UsersRoles = React.lazy(() => lazyRetry(() => import('../security/users/UsersRoles')));

//Payroll Module Component
const Staff = React.lazy(() => lazyRetry(() => import('../../views/modules/payroll/staff/Staff')));
const Rota = React.lazy(() => lazyRetry(() => import('../../views/modules/payroll/rota/Rota')));
const HolidayPlanner = React.lazy(() => lazyRetry(() => import('../../views/modules/payroll/holiday/HolidayPlanner')));
const PayrollRun = React.lazy(() => lazyRetry(() => import('../../views/modules/payroll/run/PayrollRun')));
const Role = React.lazy(() => lazyRetry(() => import('../../views/modules/payroll/role/Role')));

//POS Module Component
const ViewSales = React.lazy(() => lazyRetry(() => import('../../views/modules/sales/viewSales/Sales')));
const ViewCancelledSales = React.lazy(() => lazyRetry(() => import('../../views/modules/sales/viewSales/CancelledSales')));

const SalesProjectionOverride = React.lazy(() => lazyRetry(() => import('../../views/modules/sales/salesProjectionOverride/SalesProjectionOverride')));
const StorefrontCommissionRuns = React.lazy(() => lazyRetry(() => import('../../views/modules/sales/storefrontCommissionRun/StorefrontCommissionRuns')));
const DriverSales = React.lazy(() => lazyRetry(() => import('../../views/modules/sales/driverSales/DriverSales')));

const Menus = React.lazy(() => lazyRetry(() => import('../../views/modules/menus/Menus')));

//Marketing Module Component
const VoucherCodes = React.lazy(() => lazyRetry(() => import('../../views/modules/marketing/voucherCodes/VoucherCodes')));
const Customers = React.lazy(() => lazyRetry(() => import('../../views/modules/marketing/customers/Customers')));
const Campaigns = React.lazy(() => lazyRetry(() => import('../../views/modules/marketing/campaign/Campaigns')));
const DiscountOffers = React.lazy(() => lazyRetry(() => import('../../views/modules/marketing/discountOffers/DiscountOffers')));
const KioskSkins = React.lazy(() => lazyRetry(() => import('../../views/setup/display/KIOSK/KioskSkins')));
const SignagePlayers = React.lazy(() => lazyRetry(() => import('../../views/setup/display/Signage/SignagePlayers')));

//Accounts Module Component
const AccountCategories = React.lazy(() => lazyRetry(() => import('../../views/setup/accounts/categories/Categories')));
const AccountSubCategories = React.lazy(() => lazyRetry(() => import('../../views/setup/accounts/subCategories/SubCategories')));
const TransactionAccounts = React.lazy(() => lazyRetry(() => import('../../views/setup/accounts/transactionAccounts/TransactionAccounts')));

//Setup CreditBooks and CreditAccounts
const CreditBooks = React.lazy(() => lazyRetry(() => import('../../views/setup/accounts/creditBooks/creditBooks/creditBooks')));
const CreditAccounts = React.lazy(() => lazyRetry(() => import('../../views/setup/accounts/creditBooks/creditAccounts/creditAccounts')));
const CreditAccountStatus = React.lazy(() => lazyRetry(() => import('../../views/modules/accounts/creditBooks/creditAccountStatus/CreditAccountStatus')));

const Expenses = React.lazy(() => lazyRetry(() => import('../../views/modules/accounts/expenses/Expenses')));
const Bills = React.lazy(() => lazyRetry(() => import('../../views/modules/accounts/bills/Bills')));

//Inventory Module Component
const Suppliers = React.lazy(() => lazyRetry(() => import('../../views/setup/inventory/suppliers/Suppliers')));
const StockItemCategories = React.lazy(() => lazyRetry(() => import('../../views/setup/inventory/categories/Categories')));
const StockItems = React.lazy(() => lazyRetry(() => import('../../views/setup/inventory/stockItems/StockItems')));
const PurchaseOrders = React.lazy(() => lazyRetry(() => import('../../views/modules/inventory/purchaseOrders/PurchaseOrders')));
const InventoryTrackingProfiles = React.lazy(() => lazyRetry(() => import('../../views/setup/inventory/inventoryTrackingProfiles/InventoryTrackingProfiles')));

//Food Safety Module Component
const IncidentLogs = React.lazy(() => lazyRetry(() => import('../../views/modules/foodSafety/incidentLogs/IncidentLogs')));
const TaskLogs = React.lazy(() => lazyRetry(() => import('../../views/modules/foodSafety/taskLogs/TaskLogs')));
const StockItemCookingTemperatureLogs = React.lazy(() => lazyRetry(() => import('../../views/modules/foodSafety/stockItems/cookingTemperatureLogs/TemperatureLogs')));
const StockItemStorageTemperatureLogs = React.lazy(() => lazyRetry(() => import('../../views/modules/foodSafety/stockItems/storageTemperatureLogs/TemperatureLogs')));
const ApplianceTemperatureLogs = React.lazy(() => lazyRetry(() => import('../../views/modules/foodSafety/appliances/temperatureLogs/TemperatureLogs')));

//Central Kitchen Module Component
const CentralKitchensSetup = React.lazy(() => lazyRetry(() => import('../setup/centralKitchen/CentralKitchens')));
const CentralKitchenSales = React.lazy(() => lazyRetry(() => import('../../views/modules/centralKitchens/CentralKitchens')));

//Service Desk Module Component
const ApprovePermissions = React.lazy(() => lazyRetry(() => import('../../views/modules/serviceDesk/permissions/approvePermissions/ApprovePermissions')));
const RequestPermissions = React.lazy(() => lazyRetry(() => import('../../views/modules/serviceDesk/permissions/requestPermissions/RequestPermissions')));

//Onboarding Wizard Component
const OnboardingWizard = React.lazy(() => lazyRetry(() => import('../../views/modules/onboarding/onboardingWizard/OnboardingWizard')));

//FoodSafety
const Appliances = React.lazy(() => lazyRetry(() => import('../../views/setup/foodSafety/appliances/Appliances')));
const TaskTypes = React.lazy(() => lazyRetry(() => import('../../views/setup/foodSafety/taskTypes/TaskTypes')));
const Tasks = React.lazy(() => lazyRetry(() => import('../../views/setup/foodSafety/tasks/Tasks')));
const IncidentTypes = React.lazy(() => lazyRetry(() => import('../../views/setup/foodSafety/incidents/IncidentTypes')));

const StationsDesigner = React.lazy(() => lazyRetry(() => import('../setup/stations/StationsDesigner')));

const NetworkDiagram = React.lazy(() => lazyRetry(() => import('../setup/networkDiagram/NetworkDiagram')));

//Imports
const ImportCustomers = React.lazy(() => lazyRetry(() => import('../../views/setup/imports/customers/ImportCustomers')));

const validator = require('../../utils/validator/validator');
const stringUtil = require('../../utils/string/stringUtil');
const logger = require('../../utils/logger/logger');
const accessHelper = require('./helpers/accessHelper');
const securityManager = require('../../utils/domain/security/securityManager');

class ComponentFacade extends Component
{
    render()
    {
        
        let component, componentName, componentParameter, componentReadRoles, componentWriteRoles;

        if (!stringUtil.isStringNullOrEmpty(this.props.componentName))
        {
            componentName = this.props.componentName;
            componentParameter = null;
        }
        else
        {
            componentName = this.props.currentOption.targetComponent;
            componentParameter = this.props.currentOption.componentParameter;
            componentReadRoles = this.props.currentOption.readRoles;
            componentWriteRoles = this.props.currentOption.writeRoles;
        }

        if (!validator.isPresent(componentName))
            throw new Error("ComponentContainer: componentName is required");

        const isReadOnly = accessHelper.isComponentReadOnlyForUser(componentReadRoles, componentWriteRoles);

        if (componentName.startsWith('Reports.'))
        {
            // Non-BI based report componentName should be prefixed with "Reports." so that we can use this info
            // to extract related switch-cases into reportsComponentHelper

            component = reportsComponentHelper.getReportComponent(componentName, componentParameter);

            if (component != null) 
            {
                return (
                    <Suspense fallback={<PulseSpinner />}>
                        {component}
                    </Suspense>
                );
            }
        }

        if (componentName.includes('BI.')) 
        {
            // BI tool componentKey is/must be by convention prefixed with "BI." and we use this info
            // to extract related switch-cases into biComponentHelper

            component = biComponentHelper.getBIComponent(componentName);

            if (component != null) 
            {
                return (
                    <Suspense fallback={<PulseSpinner />}>
                        {component}
                    </Suspense>
                );
            }
        }

        switch (componentName)
        {
            //#region Organization Module Component

            case "DocumentsExplorer":
                component = <DocumentsExplorer isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "Staff":
                component = <Staff isReadOnly={isReadOnly}/>
                break;

            case "Organization.Franchisor.BasicConfiguration":
                component = <FranchisorBasicConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Franchisor.OperatingConfiguration":
                component = <FranchisorOperatingConfiguration isReadOnly={isReadOnly}/>
                break;
    
            case "Organization.Franchisor.DatabaseConfiguration":
                component = <FranchisorDatabaseConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Franchisor.ApiIntegration":
                component = <FranchisorApiIntegration isReadOnly={isReadOnly}/>
                break;                

            case "Organization.Franchisor.ChargesConfiguration":
                component = <FranchisorChargesConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Franchisor.Brands":
                component = <Brands isReadOnly={isReadOnly} />
                break;

            case "Organization.Property.PropertyLicenseSubscriptions":
                component = <PropertyLicenseSubscriptions isReadOnly={isReadOnly}/>
                break;

            case "Organization.Property.BasicConfiguration":
                component = <PropertyBasicConfiguration isReadOnly={isReadOnly}/>
                break;
    
            case "Organization.Property.OperatingConfiguration":
                component = <PropertyOperatingConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Property.LayoutConfiguration":
                component = <PropertyLayoutConfiguration isReadOnly={isReadOnly}/>
                break;    
                
            case "Organization.Property.DeliveryManagementConfiguration":
                component = <PropertyDeliveryManagementConfiguration isReadOnly={isReadOnly}/>
                break;
    
            case "Organization.Property.StaffPermissionsConfiguration":
                component = <StaffPermissionsConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Property.PrintConfiguration":
                component = <PropertyPrintConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Property.GeoConfiguration":
                component = <PropertyGeoConfiguration isReadOnly={isReadOnly}/>
                break;

            case "Organization.Property.FloatConfiguration":
                component = <PropertyFloatConfiguration isReadOnly={isReadOnly}/>
                break;
                
            case "Organization.Property.AddressConfigurations":
                component = <PropertyAddressConfigurations isReadOnly={isReadOnly}/>
                break;

            case "Organization.Property.PayrollConfiguration":
                component = <PropertyPayrollConfiguration isReadOnly={isReadOnly}/>
                break;

            case "StorefrontCore":
                component = <StorefrontCore isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontOpeningHours":
                component = <StorefrontOpeningHours isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontBusinessHourOverrides":
                component = <StorefrontBusinessHourOverrides isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontDeliveryCharges":
                component = <StorefrontDeliveryCharges isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontTipConfiguration":
                component = <StorefrontTipConfiguration isReadOnly={isReadOnly} params={componentParameter}/>
                break;
    
            case "StorefrontOperatingConfigurations":
                component = <StorefrontOperatingConfigurations isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontStorePayoutConfigurations":
                component = <StorefrontStorePayoutConfigurations isReadOnly={isReadOnly} params={componentParameter}/>
                break;                    

            case "StorefrontOrderIngest":
                component = <StorefrontOrderIngest isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontWebAppConfigurations":
                component = <StorefrontWebAppConfigurations isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontOverPhonePaymentConfigurations":
                component = <StorefrontOverPhonePaymentConfigurations isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontMobileAppConfigurations":
                component = <StorefrontMobileAppConfigurations isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StorefrontTimingConfigurations":
                component = <StorefrontTimingConfigurations isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Organization Module [Catalog Lookups] Component
            case "LookupWastageReasons":
                component = <Lookups isReadOnly={isReadOnly} lookupTitle="Wastage Reasons" lookupName="wastageReasons" lookupResource="wastageReasons"  params={componentParameter}/>
                break;

            case "LookupPackageTypes":
                component = <Lookups isReadOnly={isReadOnly} lookupTitle="Package Types" lookupName="packageTypes" lookupResource="packageTypes"  params={componentParameter}/>
                break;

            case "LookupAllergies":
                component = <Lookups isReadOnly={isReadOnly} lookupTitle="Allergies" lookupName="allergies" lookupResource="allergies"  params={componentParameter}/>
                break;
            
            case "LookupNutritions":
                component = <Lookups isReadOnly={isReadOnly} lookupTitle="Nutritions" lookupName="nutritions" lookupResource="nutritions"  params={componentParameter}/>
                break;

            case "Venues":
                component = <Venues isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Organization Module [Organization Lookups] Component
            case "Tags":
                component = <Tags isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "KitchenStationProfiles":
                component = <KitchenStationProfiles isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case  "Guests":
                component = <Guests isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case  "FreebieReasons":
                component = <FreebieReasons isReadOnly={isReadOnly} params={componentParameter}/>
                break;
                    
            case "PriceBands":
                component = <PriceBands isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "Printing.Template.Receipt":
                component = <PrintingTemplates isReadOnly={isReadOnly} type="receipt" params={componentParameter}/>
                break;

            case "Printing.Template.KitchenStation":
                component = <PrintingTemplates isReadOnly={isReadOnly} type="kitchenStation" params={componentParameter}/>
                break;

            case "Printing.Template.Voucher":
                component = <PrintingTemplates isReadOnly={isReadOnly} type="voucher" params={componentParameter}/>
                break;

            case "Printing.Template.FloatSession":
                component = <PrintingTemplates isReadOnly={isReadOnly} type="floatSession" params={componentParameter}/>
                break;

            case "Printing.Template.DailySummaryReport":
                component = <PrintingTemplates isReadOnly={isReadOnly} type="dailySummaryReport" params={componentParameter} />
                break;

            case "Nomenclatures":
                component = <Nomenclatures isReadOnly={isReadOnly} params={componentParameter}/>
                break;
                
            case "AddressMapping":
                component = <AddressMapping isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StationsDesigner":
                component = <StationsDesigner isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "NetworkDiagram":
                component = <NetworkDiagram isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "AuditLogs":
                component = <AuditLogs isReadOnly={isReadOnly} params={componentParameter}/>
                break;

        case "PaymentTypes":
                component = <PaymentTypes isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            //#endregion

            case "UsersRoles":
                component = <UsersRoles isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            //#region Payroll Module Component

            case "Rota":
                component = <Rota isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "HolidayPlanner":
                component = <HolidayPlanner isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "PayrollRun":
                component = <PayrollRun isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "StaffRole":
                component = <Role isReadOnly={isReadOnly} params={componentParameter} />
                break;
            //#endregion

            //#region POS Module Component
            case "ViewSales":
                component = <ViewSales isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "ViewCancelledSales":
                component = <ViewCancelledSales isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "SalesProjectionOverride":
                component = <SalesProjectionOverride isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "StorefrontCommissionRuns":
                component = <StorefrontCommissionRuns isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "DriverSales":
                component = <DriverSales isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "Menus":
                component = <Menus isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Marketing Module Component
            case "VoucherCodes":
                component = <VoucherCodes isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "DiscountOffers":
                component = <DiscountOffers isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "Customers":
                component = <Customers isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "Campaigns":
                component = <Campaigns isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "KioskSkins":
                component = <KioskSkins isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "SignagePlayers":
                component = <SignagePlayers isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Accounts Module Component
            case "AccountCategories":
                component = <AccountCategories isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "AccountSubCategories":
                component = <AccountSubCategories isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "TransactionAccounts":
                component = <TransactionAccounts isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            //#region Setup Credit Books and Credit Accounts
            case "CreditBooks":
                component = <CreditBooks isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "CreditAccounts":
                component = <CreditAccounts isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "CreditAccountsStatus":
                component = <CreditAccountStatus isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "Expenses":
                component = <Expenses isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "Bills":
                component = <Bills isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Inventory Module Component
            case "Suppliers":
                component = <Suppliers isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "StockItemCategories":
                component = <StockItemCategories isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "StockItems":
                component = <StockItems isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "PurchaseOrders":
                component = <PurchaseOrders isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "InventoryTrackingProfiles":
                component = <InventoryTrackingProfiles isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Food Safety Module Component
            case "TaskLogs":
                component = <TaskLogs isReadOnly={isReadOnly} params={componentParameter}/>
                break;
                
            case "StockItemCookingTemperatureLogs":
                component = <StockItemCookingTemperatureLogs isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "StockItemStorageTemperatureLogs":
                component = <StockItemStorageTemperatureLogs isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            
            case "ApplianceTemperatureLogs":
                component = <ApplianceTemperatureLogs isReadOnly={isReadOnly} params={componentParameter}/>
                break;

            case "IncidentLogs":
                component = <IncidentLogs isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Central Kitchen Module Component
            case "CentralKitchenSetup":
                component = <CentralKitchensSetup isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "CentralKitchenSales":
                component = <CentralKitchenSales isReadOnly={isReadOnly} params={componentParameter}/>
                break;
                //#endregion

            //#region Service Desk Module Component
            case "RequestPermissions":
                component = <RequestPermissions isReadOnly={isReadOnly} params={componentParameter} />
                break
            
            case "ApprovePermissions":
                component = <ApprovePermissions isReadOnly={isReadOnly} params={componentParameter} />
                break

            //#region Onboarding Wizard Component
            case "OnboardingWizard":
                component = <OnboardingWizard isReadOnly={isReadOnly} params={componentParameter} />
                break

            //#endregion

            //#region FoodSafety
            case "Appliances":
                component = <Appliances isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "TaskTypes":
                component = <TaskTypes isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "Tasks":
                component = <Tasks isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            case "IncidentTypes":
                component = <IncidentTypes isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Imports
            case "Import.Customers":
                component = <ImportCustomers isReadOnly={isReadOnly} params={componentParameter}/>
                break;
            //#endregion

            //#region Localization
            case "LocalizationSettingOverrides":
                component = <LocalizationSettingOverrides isReadOnly={isReadOnly} params={componentParameter} />
                break;
            //#endregion

            default:
                component = <div className="d-flex flex-column justify-content-center align-items-center h-100">
                    <h3 className="mb-4">{`Component not found matching name '${componentName}'`}</h3>
                    <CloseButton />
                </div>;
                break;
        }


        return (
            <Suspense fallback={<PulseSpinner />}>
                {component}
            </Suspense>
        );
    }

    
}

const lazyRetry = function(componentImport) 
{
    return new Promise((resolve, reject) => 
    {
        componentImport()
        .then((component) => 
        {
            resolve(component);
        }).
        catch((error) => 
        {
            logger.error("Error occurred while lazy loading react component", null, error);
            return window.location.reload();
        });
    });
};

const mapStateToProps = state => 
{
    return {
        currentOption: state.mainBarComponent,
    }
}

export default connect(mapStateToProps)(ComponentFacade)