
const stringUtil = require('../../../../utils/string/stringUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const domainConstants = require('../../../../utils/domain/constants');

const dailySummaryReportTemplateUtils = require('./utils/dailySummaryReportTemplateUtils')

const defaultDateTimeFormat = "yyyy-MM-DD HH:mm";

module.exports.getElements = function (type)
{
    var elements = [];

    //#region common
    elements.push({
        title: "Image",
        type: "image",
        url: "",
        tooltip: "Displays an image"
    });

    elements.push({
        title: "Empty Line",
        type: "emptyLine",
        count: 1,
        tooltip: "Adds one or multiple empty lines"
    });

    elements.push({
        title: "Separator",
        type: "separator",
        character: "*",
        tooltip: "Adds separator line with selected character"
    });

    elements.push({
        title: "Text",
        type: "text",
        caption: "This is text",
        align: 'Left',
        bold: false,
        fontSize: "Normal",
        separatorLineCharacter: null,
        newLine: 0,
        tooltip: "Adds text line"
    });

    elements.push({
        title: "Variable",
        type: "variable",
        caption: "Variable Name",
        align: 'Left',
        bold: false,
        fontSize: "Normal",
        separatorLineCharacter: null,
        newLine: 0,
        tooltip: "Adds a value of variable as text"
    });
    //#endregion

    //#region common between receipts and kitchen staton
    if (stringUtil.areStringSame(type, "Receipt") || stringUtil.areStringSame(type, "kitchenStation"))
    {
        elements.push({
            title: "Driver",
            type: "field",
            field: "driver",
            caption: "Driver: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Operator",
            type: "field",
            field: "operator",
            caption: "Operator: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Server",
            type: "field",
            field: "server",
            caption: "Server: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Order Number",
            type: "field",
            field: "orderNumber",
            caption: "Order Number: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Table Number",
            type: "field",
            field: "tableNumber",
            caption: "Table Number: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Table and Order Number",
            type: "field",
            field: "orderAndTableNumber",
            caption: "Order Number: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Venue Code",
            type: "field",
            field: "venueCode",
            caption: "Venue: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Dispatch Type",
            type: "field",
            field: "dispatchType",
            caption: "Dispatch Type: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Venue Code and Dispatch Type",
            type: "field",
            field: "venueAndDispatchType",
            caption: "Order Type: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Storefront",
            type: "field",
            field: "storefront",
            caption: "",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Payment Status",
            type: "field",
            field: "paymentStatus",
            paidCaption: "***PAID***",
            unpaidCaption: "***UNPAID***",
            align: 'Center',
            bold: true,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Print Time",
            type: "field",
            field: "printTime",
            caption: "Print Time: ",
            dateFormat: defaultDateTimeFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Order Date",
            type: "field",
            field: "orderDate",
            caption: "Order Date: ",
            dateFormat: dateUtil.internalDateFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Number of Covers",
            type: "field",
            field: "covers",
            caption: "Number of Covers: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Customer Name",
            type: "field",
            field: "customerName",
            caption: "Customer Name: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Customer Phone",
            type: "field",
            field: "customerPhoneNumber",
            caption: "Customer Phone: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Delivery Address",
            type: "field",
            field: "deliveryAddress",
            caption: "Delivery Address: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Sale Receipt Header",
            type: "field",
            field: "title",
            caption: "",
            align: 'Center',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });



        elements.push({
            title: "Retail Order Price",
            type: "field",
            field: "totalRetailOrderPrice",
            caption: "Retail Order Price: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Order Price",
            type: "field",
            field: "totalOrderPrice",
            caption: "Order Price: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Discount",
            type: "field",
            field: "totalDiscount",
            caption: "Discount: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Service Charges",
            type: "field",
            field: "totalServiceCharges",
            caption: "Service Charges: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Delivery Charges",
            type: "field",
            field: "totalDeliveryCharges",
            caption: "Delivery Charges: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });
        
        elements.push({
            title: "Deposit Return Charges",
            type: "field",
            field: "totalDepositReturnCharges",
            caption: "Deposit Return Charges: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Tip",
            type: "field",
            field: "totalTipCharges",
            caption: "Tip: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Net Price",
            type: "field",
            field: "totalPayableAmount",
            caption: "Net Price: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Payment Mode",
            type: "field",
            field: "paymentMode",
            caption: "Payment Mode: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Paid Amount",
            type: "field",
            field: "totalPaidAmount",
            caption: "Paid Amount: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Outstanding",
            type: "field",
            field: "totalOutstanding",
            caption: "Outstanding: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total Refunds",
            type: "field",
            field: "totalRefund",
            caption: "Total Refunds: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Refunded Receipt Caption",
            type: "refundedCaption",
            caption: "**** Refunded ****",
            align: 'Center',
            bold: true,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Receipt Caption",
            type: "cancelledCaption",
            caption: "**** Cancelled ****",
            align: 'Center',
            bold: true,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Sale Receipt Footer",
            type: "field",
            field: "footer",
            caption: "",
            align: 'Center',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Charges Grid",
            type: "field",
            field: "serviceChargesGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });
    }
    //#endregion

    if(stringUtil.areStringSame(type, "Receipt"))
    {
        elements.push({
            title: "Order Grid",
            type: "field",
            field: "orderGrid",
            separatorLineCharacter: null,
            newLine: 0,
            gridItemNewLine: 0,
        });

        elements.push({
            title: "Discounts Grid",
            type: "field",
            field: "receiptDiscountsGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Receipts Grid",
            type: "field",
            field: "chargesGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cash Change Grid",
            type: "field",
            field: "cashChangeGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Payment Receipt Lines Grid",
            type: "field",
            field: "paymentReceiptLinesGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Refund Grid",
            type: "field",
            field: "refundsGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Tax Grid",
            type: "field",
            field: "vatGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Delivery Grid",
            type: "field",
            field: "deliveryGrid",
            separatorLineCharacter: null,
            newLine: 0,
            gridItemNewLine: 0,
        });

        elements.push({
            title: "Endorsement Grid",
            type: "field",
            field: "endorsementGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });
    }
    
    if (stringUtil.areStringSame(type, "kitchenStation"))
    {
        elements.push({
            title: "Kitchen Station",
            type: "field",
            field: "kitchenStation",
            caption: "",
            align: 'Center',
            bold: true,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Pre Order Status",
            type: "field",
            field: "showPreOrderStatus",
            caption: "",
            align: 'Center',
            bold: true,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Expected Delivery Time",
            type: "field",
            field: "plannedDeliveryDateTime",
            caption: "Expected Delivery Time: ",
            dateFormat: defaultDateTimeFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Expected Kitchen Time",
            type: "field",
            field: "expectedTime",
            caption: "Expected Kitchen Time: ",
            dateFormat: defaultDateTimeFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Requested For",
            type: "field",
            field: "expectedPlatformDateTime",
            caption: "Requested For: ",
            dateFormat: defaultDateTimeFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Kitchen Station Order Grid",
            type: "field",
            field: "kitchenStationOrderGrid",
            printGroupByCategory: false,
            excludePrice: false,
            separatorLineCharacter: null,
            newLine: 0,
            gridItemNewLine: 1,
            gridModifierNewLine: 1,
            fontSize: "Extra Large",
            bold: false,    // Not used
        });
    }

    //#region float session
    if (stringUtil.areStringSame(type, "floatSession"))
    {
        elements.push({
            title: "Started By",
            type: "field",
            field: "startedBy",
            caption: "Started By: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Closed By",
            type: "field",
            field: "closedBy",
            caption: "Closed By: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Status",
            type: "field",
            field: "status",
            caption: "Status: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Opening Balance",
            type: "field",
            field: "openingBalance",
            caption: "Opening Balance: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total Cash In",
            type: "field",
            field: "totalCashIn",
            caption: "Total Cash In: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total Cash Out",
            type: "field",
            field: "totalCashOut",
            caption: "Total Cash Out: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total Expense",
            type: "field",
            field: "totalExpenses",
            caption: "Total Expense: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Net Change Payment",
            type: "field",
            field: "netChangePayment",
            caption: "Net Change Payment: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Available Cash",
            type: "field",
            field: "availableCash",
            caption: "Available Cash: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Outstanding (Current Session)",
            type: "field",
            field: "totalCurrentSessionOutstandingAmount",
            caption: "Outstanding (Current Session): ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Number Of Outstanding Sales (Current Session)",
            type: "field",
            field: "totalCurrentSessionOutstandingQuantity",
            caption: "Number Of Outstanding Sales (Current Session): ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Outstanding Summary (Current Session)",
            type: "field",
            field: "totalCurrentSessionOutstandingQuantityAndAmount",
            caption: "Outstanding Summary (Current Session): ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Outstanding (Previous Session)",
            type: "field",
            field: "totalPreviousSessionOutstandingAmount",
            caption: "Outstanding (Previous Session): ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Number Of Outstanding Sales (Previous Session)",
            type: "field",
            field: "totalPreviousSessionOutstandingQuantity",
            caption: "Previous Session Number Of Outstanding Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Outstanding Summary (Previous Session)",
            type: "field",
            field: "totalPreviousSessionOutstandingQuantityAndAmount",
            caption: "Outstanding Summary (Previous Session): ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });


        elements.push({
            title: "Total Number Of Sales",
            type: "field",
            field: "totalSalesQuantity",
            caption: "Total Number Of Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total Sales",
            type: "field",
            field: "totalSalesAmount",
            caption: "Total Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total Sales Summary",
            type: "field",
            field: "totalSalesQtyAndAmount",
            caption: "Total Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Number Of Cancelled Sales",
            type: "field",
            field: "totalCancelledSalesQuantity",
            caption: "Number Of Cancelled Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Sales",
            type: "field",
            field: "totalCancelledSalesAmount",
            caption: "Cancelled Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Sales Summary",
            type: "field",
            field: "totalCancelledSalesQuantityAndAmount",
            caption: "Cancelled Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Number Of Cancelled Sale Items",
            type: "field",
            field: "totalCancelledSaleItemsQuantity",
            caption: "Number Of Cancelled Sale Items: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Sales Items",
            type: "field",
            field: "totalCancelledSaleItemsAmount",
            caption: "Cancelled Sales Items: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Sales Items Summary",
            type: "field",
            field: "totalCancelledSaleItemsQuantityAndAmount",
            caption: "Cancelled Sales Items: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Number Of Refunded Sales",
            type: "field",
            field: "totalRefundedSalesQuantity",
            caption: "Number Of Refunded Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Refunded Sales",
            type: "field",
            field: "totalRefundedSalesAmount",
            caption: "Refunded Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Refunded Sales Summary",
            type: "field",
            field: "totalRefundedSalesQuantityAndAmount",
            caption: "Refunded Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Gross Sales",
            type: "field", 
            field: "totalGrossSalesAmount",
            caption: "Gross Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Tax",
            type: "field",
            field: "totalTax",
            caption: "Tax: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Net Sales",
            type: "field", 
            field: "totalNetSalesAmount",
            caption: "Net Sales: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Average Revenue Per Cover",
            type: "field",
            field: "averageRevenuePerCover",
            caption: "Average Revenue Per Cover: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Total No of Covers",
            type: "field",
            field: "totalNoOfCovers",
            caption: "Total No of Covers: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Discounts Summary",
            type: "field",
            field: "discountsSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Refunds Summary",
            type: "field",
            field: "refundsSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Charges Summary",
            type: "field",
            field: "chargesSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Payments Summary",
            type: "field",
            field: "paymentsSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Dispatch Types Summary",
            type: "field",
            field: "dispatchTypesSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Dispatch Type Payments Summary",
            type: "field",
            field: "dispatchTypePaymentsSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Venues Summary",
            type: "field",
            field: "venuesSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Venue Payments Summary",
            type: "field",
            field: "venuePaymentsSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Menu Sales Summary",
            type: "field",
            field: "menuSalesSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Category Sales Summary",
            type: "field",
            field: "menuCategoriesSummaryGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Session Summary",
            type: "field",
            field: "journalGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cash Drawer Logs",
            type: "field",
            field: "cashDrawerLogsGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Sales List",
            type: "field",
            field: "cancelledSalesGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Cancelled Sale Items List",
            type: "field",
            field: "cancelledSaleItemsGrid",
            separatorLineCharacter: null,
            newLine: 0,
        });
    }

    if (stringUtil.areStringSame(type, "Voucher"))
    {
        elements.push({
            title: "Voucher Issue Date",
            type: "field",
            field: "voucherIssueDate",
            caption: "Issue Date: ",
            dateFormat: dateUtil.internalDateFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Voucher Expiry Date",
            type: "field",
            field: "voucherExpiryDate",
            caption: "Expiry Date: ",
            dateFormat: dateUtil.internalDateFormat,
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Voucher Detail",
            type: "field",
            field: "voucherDetail",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Voucher Code",
            type: "field",
            field: "voucherCode",
            caption: "Voucher Code: ",
            align: 'Left',
            bold: false,
            fontSize: "Normal",
            separatorLineCharacter: null,
            newLine: 0,
        });

        elements.push({
            title: "Voucher QR",
            type: "field",
            field: "voucherQR",
            separatorLineCharacter: null,
            newLine: 0,
        });
    }

    //#endregion

    if (stringUtil.areStringSame(type, domainConstants.printingTemplateTypes.dailySummaryReport)) 
    {
        elements.push.apply(elements, dailySummaryReportTemplateUtils.getTemplateElements());
    }

    return elements;

}

