import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as biUtils from '../../../../../utils/bi/biUtils';
import * as dateUtil from '../../../../../utils/dateUtil/dateUtil';

const { formatCurrencyValue: formatCurrency } = biUtils;

export default function ChartReport(props)
{
    const entities = getAggregatedEntities(props);

    const options = getOptions(entities);

    return <AgChartsReact options={options} />;
}

function getAggregatedEntities(props)
{
    const aggregatedEntities = [];

    props.entities.forEach(entity =>
    {
        const date = dateUtil.getDateComponent(dateUtil.convertFromISODateTimeFormat(entity.date));  // strip ISO date

        let aggEntity = aggregatedEntities.find(aggEntity => aggEntity.date === date);

        if (aggEntity == null)
        {
            aggEntity = {
                date: date,
                xLabel: dateUtil.formatDate(date, 'DD-MMM'),
                scheduledCost: 0.0,
                adjustedCost: 0.0
            }

            aggregatedEntities.push(aggEntity);
        }

        aggEntity.scheduledCost += entity.scheduledCost;
        aggEntity.adjustedCost += entity.adjustedCost;
    });

    return aggregatedEntities;
}

function getOptions(entities)
{
    const options = {
        autoSize: true,
        data: entities,
        theme: {
            overrides: {
                line: {
                    series: {
                        highlightStyle: {
                            series: {
                                dimOpacity: 0.2,
                            },
                        },
                    },
                },
            },
        },
        title: {
            text: 'Scheduled vs Adjusted Wages Cost',
            fontSize: 18,
        },
        subtitle: {
            text: 'Comparison of scheduled wages cost and adjusted wages cost',
        },
        series: [
            {
                id: 'scheduledCost',
                type: 'line',
                title: 'Scheduled Wages Cost',
                xKey: 'xLabel',
                yKey: 'scheduledCost',
                yName: 'Scheduled Cost',
                stroke: 'Green',
                shadow: { enabled: true },
                marker: {
                    stroke: 'DarkGreen',
                    fill: 'LightGreen',

                },
                tooltip: {
                    renderer: params => ({ title: params.yName, content: `${params.xValue}: ${formatCurrency(params.yValue)}` })
                },
            },
            {
                id: 'adjustedCost',
                type: 'line',
                title: 'Adjusted Wages Cost',
                xKey: 'xLabel',
                yKey: 'adjustedCost',
                yName: 'Adjusted Cost',
                stroke: 'Red',
                shadow: { enabled: true },
                marker: {
                    stroke: 'DarkRed',
                    fill: 'Pink',
                },
                tooltip: {
                    renderer: params => ({ title: params.yName, content: `${params.xValue}: ${formatCurrency(params.yValue)}` })
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                title: {
                    text: 'Date',
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    format: '#{,.2f}'
                },
                title: {
                    text: 'Wages',
                },
            },
        ],
        legend: {
            position: 'bottom'
        }
    };

    return options;
}
