import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import Select from 'react-select';

import ErrorMessage from '../../../../../components/error/errorMessage';
import Spinner from '../../../../../components/spinner/spinner';
import GridComponent from '../../../../../components/grid/gridComponent';
import EmptyData from '../../../../../components/empty/emptyData';
import CloseButton from '../../../../../components/button/closeButton';

import CreditAccountPaymentView from '../components/CreditAccountPaymentView';
import CreditAccountTransactions from '../components/CreditAccountTransactions';
import ButtonCellRenderer from '../../../../../components/grid/cellRenderers/buttonCellRenderer';

const arraySort = require('array-sort');

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');
const viewUtil = require('../../../../../utils/view/viewUtil');
const typeUtil = require('../../../../../utils/type/typeUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const validator = require('../../../../../utils/validator/validator');

const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');

export default class CreditAccountStatus extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.isLoading = false;
        this.state.error = null;

        this.state.creditBookId = null;
        this.state.creditAccount = null;

        this.state.creditBooks = [];
        this.state.creditAccounts = [];

        this.state.viewType = "creditBooks";
    }

    async componentDidMount()
    {
        this.state.isLoading = true;
        this.setState({});

        try 
        {
            const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks`;

            const data = await rmsApiProxy.get(resourceUrl);
            const creditBooks = arraySort(data, stringUtil.localeCompareProp("name"));

            this.state.creditBooks = creditBooks;
            this.state.isLoading = false;
            this.state.viewType = "creditBooks";
            this.setState({});
        }
        catch (error) 
        {
            this.state.error = error;
            this.setState({});
        }
    }

    loadCreditAccountsStatus = async (creditBookId) =>
    {
        if (!stringUtil.isStringNullOrEmpty(creditBookId))
        {
            this.setState({ isLoading: true, creditBookId: creditBookId, creditAccounts: [] });
            try
            {
                const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks/${creditBookId}/status`;

                const data = await rmsApiProxy.get(resourceUrl);
                const creditAccounts = arraySort(data, stringUtil.localeCompareProp("name"));
                this.setState({ creditAccounts, isLoading: false, error: null });
            }
            catch (error)
            {
                this.setState({ creditAccounts: [], isLoading: false, error: error });
            }
        }
        else
        {
            this.setState({ isLoading: true, creditBookId: creditBookId, creditAccounts: [] });
        }
    }

    onDebit = (creditAccountId) =>
    {
        const creditAccount = this.state.creditAccounts.find(creditAccount => creditAccount.id === creditAccountId);

        const payment = {
            creditBookId: creditAccount.creditBookId,
            creditAccountId: creditAccount.id,
            amount: null,
            reference: "",
            type: 'Debit'
        };

        viewUtil.openModalForm(
            creditAccount.name,
            (onFieldChanged) =>
            {
                return (<CreditAccountPaymentView data={payment} />);
            },
            async () =>
            {
                viewUtil.showSystemModalSpinner("Debiting account ...");

                payment.amount = payment.amount * 1;
                payment.dateTime = dateUtil.getNow();
                const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks/${payment.creditBookId}/${payment.creditAccountId}`;

                try
                {
                    await rmsApiProxy.post(resourceUrl, payment);

                    const index = this.state.creditAccounts.findIndex(creditAccount =>
                        creditAccount.creditBookId === payment.creditBookId &&
                        creditAccount.id === payment.creditAccountId);

                    const updatedAccount = typeUtil.deepCloneObject(this.state.creditAccounts[index]);
                    updatedAccount.paymentDue = updatedAccount.paymentDue - payment.amount;

                    this.state.creditAccounts.splice(index, 1, updatedAccount);
                    this.setState({});

                    viewUtil.showSuccessAlert('Account has been debited successfully')
                    viewUtil.closeModalSpinner();

                } catch (error)
                {
                    viewUtil.closeModalSpinner();
                    viewUtil.showErrorAlert(error);
                    this.setState({});
                }
            },
            () => 
            {
                if (!validator.isGreaterThanZero(payment.amount))
                    return 'Payment amount should be greater than zero';

                if (payment.amount * 1 > creditAccount.paymentDue)
                    return 'Payment amount cannot be greater than payment due';

                if (!validator.isPresent(payment.reference))
                    return 'Payment reference is required';

                return null;
            },
            false
        );
    }

    onRowDoubleClicked = (event) =>
    {
        this.setState({
            creditAccount: event.data,
            viewType: "transactions"
        });
    }

    getCreditBookOptions()
    {
        if (this.state.creditBooks == null) return [];
        return this.state.creditBooks.map(x => ({ value: x.id, label: x.name }));
    }

    render()
    {
        if (stringUtil.areStringSame(this.state.viewType, "transactions"))
        {
            return <CreditAccountTransactions
                creditAccount={this.state.creditAccount}
                onClose={() => { this.setState({ viewType: 'creditBooks' }) }}
            />
        }

        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                </CardBody>
            </Card>
        );

    }

    renderHeader()
    {
        return (
            <div className='w-100 py-1 d-flex justify-content-between align-items-center' >

                <div className='w-75 ps-2 d-flex align-items-center'>
                    <div className='me-2 fw-bold'>Credit Book:</div>
                    <div className='w-50'>
                        <Select
                            disabled={this.state.isLoading}
                            value={this.state.creditBookId}
                            options={this.getCreditBookOptions()}
                            clearable={false}
                            onChange={async (optionValue) =>
                            {
                                const creditBookId = optionValue == null ? null : optionValue.value;
                                this.loadCreditAccountsStatus(creditBookId);
                            }}
                        />
                    </div>
                </div>

                <CloseButton disabled={this.state.isLoading} />

            </div>
        );
    }

    renderBody()
    {
        if (this.state.isLoading)
            return <Spinner text='Loading Data'></Spinner>;

        if (this.state.error)
            return <ErrorMessage message={this.state.error} />;

        if (this.state.creditBookId == null)
            return <EmptyData title="Credit Accounts" text="Select the credit book to view the accounts"></EmptyData>;


        if (this.state.creditAccounts.length === 0)
            return <EmptyData title="Credit Accounts" text="No accounts available for the credit book"></EmptyData>;

        return <GridComponent key={this.state.creditBookId} headers={this.constructGridColumnHeaders()}
            rows={this.state.creditAccounts}
            shouldReArrangeHeaders={true}
            onRowDoubleClicked={this.onRowDoubleClicked}
        />
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.cellRenderer = ButtonCellRenderer;
        header.cellRendererParams = {
            caption: 'Debit',
            clicked: creditAccountId => this.onDebit(creditAccountId),
            isReadOnly: this.props.isReadOnly
        }

        header.headerName = "Action";
        header.minWidth = "200";
        headers.push(header);


        header = {};
        header.field = "name";
        header.headerName = "Account";
        header.minWidth = "200";
        header.suppressSizeToFit = false;
        headers.push(header);

        header = {};
        header.field = "creditLimit";
        header.headerName = "Credit Limit";
        header.minWidth = "200";
        header.type = dataTypeConstants.money;
        headers.push(header);

        header = {};
        header.field = "paymentDue";
        header.headerName = "Payment Due";
        header.minWidth = "200";
        header.type = dataTypeConstants.money;
        headers.push(header);

        return headers;
    }

    //#endregion
}