const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const constants = require('../../../../../utils/domain/constants');
const stringUtil = require('../../../../../utils/string/stringUtil');

module.exports.save = function(storefront, storefrontMobileAppConfigurations)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/mobileAppConfigurations`;
    return rmsApiProxy.post(resourceUrl, storefrontMobileAppConfigurations);
}

module.exports.loadStorefrontMobileAppConfigurations = function(storefront)
{
    var storefrontResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/mobileAppConfigurations`;
    return rmsApiProxy.get(storefrontResourceUrl);
}

module.exports.validateMobileAppConfiguration = function(mobileAppConfiguration)
{
    if(stringUtil.isStringNullOrEmpty(mobileAppConfiguration.paymentProvider))
        return 'paymentProvider cannot be left empty';

    if(stringUtil.areStringSame(mobileAppConfiguration.paymentProvider, constants.mobileAppOnlinePaymentProviders.BrainTree))
    {
        if(stringUtil.isStringNullOrEmpty(mobileAppConfiguration.merchantId))
            return 'merchantId cannot be left empty';

        if(stringUtil.isStringNullOrEmpty(mobileAppConfiguration.merchantAccountId))
            return 'merchantAccountId cannot be left empty';

        if(stringUtil.isStringNullOrEmpty(mobileAppConfiguration.privateKey))
            return 'privateKey cannot be left empty';

        if(stringUtil.isStringNullOrEmpty(mobileAppConfiguration.publicKey))
            return 'publicKey cannot be left empty';

        if(stringUtil.isStringNullOrEmpty(mobileAppConfiguration.environment))
            return 'environment cannot be left empty';
    }

    return null;
}