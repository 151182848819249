import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent';
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent';
import HintCard from '../../../../../components/hint/hintCard';

const FormManager = require('../../../../../utils/view/formManager');
const stringUtil = require('../../../../../utils/string/stringUtil');
const domainConstants = require('../../../../../utils/domain/constants');
const validator = require('../../../../../utils/validator/validator');
const kitchenStationProfileUtil = require('./utils/kitchenStationProfileUtil');

class KitchenStationProfile extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onDispatchTypeSelectionChanged = this.onDispatchTypeSelectionChanged.bind(this);
        this.onStationTagSelectionChanged = this.onStationTagSelectionChanged.bind(this);
        this.onFieldChanged = this.onFieldChanged.bind(this);
        this.onDependentStationsSelectionChanged = this.onDependentStationsSelectionChanged.bind(this);
        this.getSelectedDependentStations = this.getSelectedDependentStations.bind(this);
        this.getAllowedKitchenStations = this.getAllowedKitchenStations.bind(this);

        this.formManager = new FormManager();
        this.formManager.onFieldChanged = this.onFieldChanged;
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;

        // Station tags from lookup data
        const tags = kitchenStationProfileUtil.getAllKdsTags();

        this.kitchenStationTagsLookupData = tags.map(tag => ({ value: tag }));
    }

    onFieldChanged(event)
    {
        if (event.target.id == "stationType")
        {
            // StationType = Printer cannot be final station

            const stationType = this.formManager.viewModel.stationType;

            if (stationType == "Printer")
            {
                this.formManager.viewModel.isFinalStation = false;
            }
        }
    }

    onDispatchTypeSelectionChanged(selectedOptions)
    {
        let dispatchTypes = "";

        if (selectedOptions.length > 0)
        {
            dispatchTypes = selectedOptions.map(option => option.dispatchType).join();  // comma separated dispatch types
        }

        this.formManager.viewModel.dispatchTypes = dispatchTypes;

        this.setState({});
    }

    onStationTagSelectionChanged(selectedOptions)
    {
        let stationTags = "";

        if (selectedOptions.length > 0)
        {
            stationTags = selectedOptions.map(option => option.value).join();  // comma separated dispatch types
        }

        this.formManager.viewModel.stationTags = stationTags;

        this.setState({});
    }

    onDependentStationsSelectionChanged(selectedOptions)
    {
        this.formManager.viewModel.dependentStations = selectedOptions.map(option => option.id);

        this.setState({});
    }

    getSelectedDispatchTypes()
    {
        const selectedDispatchTypes = [];

        if (!stringUtil.isStringNullOrEmpty(this.formManager.viewModel.dispatchTypes))
        {
            const dispatchTypes = domainConstants.getDispatchTypes();                           // dispatch types lookup data
            const dispatchTypeTokens = this.formManager.viewModel.dispatchTypes.split(",");     // dispatch type tokens

            dispatchTypeTokens.forEach(token => 
            {
                const dispatchType = dispatchTypes.find(dispatchType => dispatchType.dispatchType == token);

                if (dispatchType != null)
                {
                    selectedDispatchTypes.push(dispatchType);
                }
            });
        }

        return selectedDispatchTypes;
    }

    getSelectedStationTags()
    {
        const selectedStationTags = [];

        if (!stringUtil.isStringNullOrEmpty(this.formManager.viewModel.stationTags))
        {
            const stationTagTokens = this.formManager.viewModel.stationTags.split(",");     // station tag tokens

            stationTagTokens.forEach(token => 
            {
                const stationTag = this.kitchenStationTagsLookupData.find(stationTag => stationTag.value == token);

                if (stationTag != null)
                {
                    selectedStationTags.push(stationTag);
                }
            });
        }

        return selectedStationTags;
    }

    getSelectedDependentStations()
    {
        var selectedDependentStations = []
        const dependentStations = this.formManager.viewModel.dependentStations;

        if(validator.isPresent(dependentStations))
        {            
            dependentStations.forEach(station => {
                const profile = this.props.kitchenStationProfiles.find(profile => profile.id == station);
                if(profile) selectedDependentStations.push(profile);
            })            
        }        
        
        return selectedDependentStations;
    }

    getAllowedKitchenStations()
    {        
        var profiles = this.props.kitchenStationProfiles.filter(profile => profile.id != this.props.data.id);

        profiles = profiles.filter(profile => !this.hasCircularDependency(profile));

        return profiles;
    }

    hasCircularDependency(profile)
    {                
        if(profile.id == this.props.data.id) return true;

        return  profile.dependentStations.some(stationId => 
        {
            const stationProfile = this.props.kitchenStationProfiles.find(k => k.id == stationId);                
            return this.hasCircularDependency(stationProfile);
        });            
    }

    render() 
    {
        return (

            <div>

                <div style={{ margin: "0px 20px" }}>

                    <HintCard contextId="kitchenStationProfiles" isDefaultClosed={true}>

                        <div style={{ margin: "10px 20px" }}>

                            <b>Station Type</b>
                            <ul>
                                <li>Monitor: Kitchen station with display screen</li>
                                <li>Printer: Kitchen station with printer only</li>
                            </ul>

                            <b>Station Tags</b>
                            <p>
                                A collection of menu item tags to be associated with the station.
                            </p>

                            <b>Dispatch Types</b>
                            <p>
                                Order dispatch types handled by the station.
                            </p>

                            <b>Dependent Stations</b>
                            <p>
                                Order item will only show up in the station when it has been bumped at the dependent stations.
                                For instance you could configure drinks as dependent for pack station so that drink order items
                                do not show up in pack until bumped at drinks.
                            </p>

                            <b>Final Station</b>
                            <p>
                                A Final Station serves as the final bumping station in the prep/bump flow of an order.
                            </p>

                        </div>

                    </HintCard>

                </div>

                <table className="component-table">
                    <tbody>

                        <tr>
                            <td>
                                <InputComponent
                                    inputType="text"
                                    caption="Station Name"
                                    fieldName="stationName"
                                    hintText="Required"
                                    placeholder="Enter unique name of the station"
                                    formManager={this.formManager} />
                            </td>
                            <td>
                                <SelectComponent
                                    caption="Station Type"
                                    optionValues={domainConstants.getKitchenStationTypes()}
                                    optionDisplayFieldName="displayFieldName"
                                    optionFieldName="valueFieldName"
                                    fieldName="stationType"
                                    hintText="Required"
                                    clearable={false}
                                    formManager={this.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <MultiSelectComponent
                                    caption="Station Tags"
                                    valueFieldName="value"
                                    displayFieldName="value"
                                    options={this.kitchenStationTagsLookupData}
                                    selectedOptions={this.getSelectedStationTags()}
                                    onSelectionChanged={this.onStationTagSelectionChanged}
                                    hintText="Select tags associated with the station" />
                            </td>
                            <td>
                                <MultiSelectComponent
                                    caption="Dispatch Types"
                                    valueFieldName="dispatchType"
                                    displayFieldName="dispatchType"
                                    options={domainConstants.getDispatchTypes()}
                                    selectedOptions={this.getSelectedDispatchTypes()}
                                    onSelectionChanged={this.onDispatchTypeSelectionChanged}
                                    hintText="Select dispatch types for the station" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <MultiSelectComponent
                                        caption="Dependent Stations"
                                        valueFieldName="id"
                                        displayFieldName="stationName"
                                        options={this.getAllowedKitchenStations()}
                                        selectedOptions={this.getSelectedDependentStations()}
                                        onSelectionChanged={this.onDependentStationsSelectionChanged}
                                        hintText="Select the stations on which the station is dependent" />
                            </td>
                            <td>
                                <BooleanSelectComponent
                                    caption="Is Unlinked"
                                    fieldName="isUnlinked"
                                    comboReadOnly={false}
                                    hintText="Enable/disable bumping an order from a final station should NOT bump from this station"
                                    clearable={false}
                                    formManager={this.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <BooleanSelectComponent
                                    caption="Is Final Station"
                                    fieldName="isFinalStation"
                                    comboReadOnly={false}
                                    hintText="Enable/disable bumping an order from a this station should bump from all stations"
                                    clearable={false}
                                    formManager={this.formManager} />
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                <InputComponent
                                    inputType="text"
                                    caption="Notes"
                                    fieldName="notes"
                                    placeholder="Enter any notes."
                                    multiLine={true} rows={4}
                                    formManager={this.formManager} />
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        )
    }
}

export default KitchenStationProfile;

