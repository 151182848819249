import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import arraySort from 'array-sort';

import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';
import * as rmsApiProxy from '../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../utils/api/rmsApiProxyUtils';

import RawStockItemStatus from './components/RawStockItemStatus';
import BuildSockItemStatus from './components/BuildSockItemStatus';

const unitUtil = require('../../../../utils/domain/unitUtil');
const domainConstants = require('../../../../utils/domain/constants');
const inventoryConstants = require('../../../../utils/domain/inventoryConstants');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

class InventoryStatusReport extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            inventoryStatusData: null,
            searchCriteria: null,
            error: null,
            isLoading: false,
        }
    }

    get reportData()
    {
        return {
            categories: this.categories,
            stockItems: this.stockItems,
            inventoryStatusData: this.state.inventoryStatusData
        }
    }

    render()
    {
        let component;

        const { isLoading, error, inventoryStatusData } = this.state;

        if (isLoading === true) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error != null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (inventoryStatusData == null)
        {
            component = <div className='m-3'>
                <EmptyData
                    title="Data required"
                    text="Please choose date range and hit Search to get report data"
                    action={null}
                />
            </div>;
        }
        else
        {
            component = this.renderReportData(inventoryStatusData);
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>Inventory Status Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData(inventoryStatusData)
    {
        if (inventoryStatusData.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Data Found"
                        text="No inventory status data found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key='Raw_Stock_Items'>Raw Stock Items</Tab>);
        tabContents.push(<TabPanel key='Raw_Stock_Items' className='bg-white mt-n1'><RawStockItemStatus reportData={this.reportData} /></TabPanel>);

        tabHeaders.push(<Tab key='Build_Stock_Items'>Build Stock Items</Tab>);
        tabContents.push(<TabPanel key='Build_Stock_Items' className='bg-white mt-n1'><BuildSockItemStatus reportData={this.reportData} /></TabPanel>);

        return (
            <div className='p-2 mt-n3 bg-white'>
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ inventoryStatusData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, inventoryStatusData: null, searchCriteria: searchCriteria, isLoading: true });

        try 
        {
            // Reference data is loaded only once during the lifetime of the component

            if (this.categories == null)
            {
                const categories = await apiLoadFacade.loadInventoryCategories();
                this.categories = arraySort(categories, "id");
            }

            if (this.stockItems == null)
            {
                const stockItems = await apiLoadFacade.loadStockItems('unitTypesOnly=true');
                this.stockItems = arraySort(stockItems, "id");
            }

            // Load state data

            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/inventory/inventoryStatus` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const inventoryStatusData = await rmsApiProxy.get(resourceUrl);

            this.enrichData(inventoryStatusData);

            this.setState({ inventoryStatusData, isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }

    enrichData = (inventoryStatusData) =>
    {
        inventoryStatusData.forEach(item => 
        {
            const stockItem = this.stockItems.find(stockItem => stockItem.id === item.stockItemId);
            const category = this.categories.find(category => category.id === stockItem.categoryId);

            item.categoryName = category.name;
            item.stockItemName = stockItem.name;

            const reportingUnitType = unitUtil.getUnitTypeByReportingType(stockItem, domainConstants.stockItemOperationTypes.inventoryTracking);

            item.unitCode = reportingUnitType.code;
            item.openingQuantity = this.getQuantityInReportingUnits(stockItem, reportingUnitType, item.openingQuantity);
            item.closingQuantity = this.getQuantityInReportingUnits(stockItem, reportingUnitType, item.closingQuantity);

            Object
                .keys(inventoryConstants.inventoryEventType)
                .forEach(eventType =>
                {
                    if (item[eventType] != null) item[eventType] = this.getQuantityInReportingUnits(stockItem, reportingUnitType, item[eventType]);
                });
        });
    }

    getQuantityInReportingUnits = (stockItem, reportingUnitType, quantityInDefaultUnits) =>
    {
        if (quantityInDefaultUnits === 0) return 0;
        const quantityInCoreUnits = unitUtil.convertToStockItemCoreUnitsFromDefaultUnits(stockItem, quantityInDefaultUnits);
        return unitUtil.convertFromStockItemCoreUnits(stockItem, reportingUnitType.id, quantityInCoreUnits);
    }
}

export default InventoryStatusReport;