import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

export default function PropertyOperatingConfigurationComponent(props)
{
    


    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent
                            inputType="money"
                            fieldName="staffMealLimit"
                            placeholder="Enter Staff Meal Limits"
                            caption="Staff Meal Limits"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>

                    <td>
                        <InputComponent
                            inputType="24HourTime"
                            fieldName="operatingHours"
                            placeholder="Enter Property Opening Hours"
                            caption="Property Opening Hours"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="email"
                            fieldName="forcedClockOutEmail"
                            placeholder="Enter Forced Clockout Email Address"
                            caption="Email to notify when a staff member is forced clocked out"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>

                    <td>
                        <InputComponent
                            inputType="email"
                            fieldName="purchaseOrderEmails"
                            placeholder="Enter Purchase Order Emails"
                            caption="Email to notify for purchase order alerts"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
