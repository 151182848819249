import React, { Component } from 'react';
import SelectComponent from '../../../../../../components/form/selectComponent';

const domainConstants = require('../../../../../../utils/domain/constants');
const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class OperatingConfigurationKIOSKOptions extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        return (

            <table className='component-table'>
                <tr>
                    <td>
                        <SelectComponent
                            optionValues={ domainConstants.getTriStateFlags() }
                            optionFieldName="key"
                            optionDisplayFieldName="value"
                            clearable={ false }
                            comboReadOnly={this.isReadOnly}
                            fieldName="dineInRequiresOrderReference"
                            caption="Requires Number for Dine In order"
                            hintText="Taking order reference (table number, token number)"
                            formManager={ this.state.formManager } />
                    </td>
                </tr>
            </table>
        );
    }
}

export default OperatingConfigurationKIOSKOptions;