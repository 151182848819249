import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';

export default function PropertyAdvancedConfigurationComponent(props)
{
    return (

        <table className="component-table">
            <tbody>
                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="defaultTimeoutInSeconds"
                            placeholder="Enter time in seconds for requests"
                            caption="Timeout for requests to backend (in Seconds)"
                            hintText="Useful for slow internet connection (Optional)"
                            formManager={ props.formManager } />
                    </td>
                    <td/>
                </tr>

            </tbody>
        </table>
    )
}
