import { generateGuid } from "../../../../utils/guid/guidUtil";
import { DeviceTypes, IntegrationTypes, LabelPrinterTypes, ReceiptPrinterTypes, TerminalTypes } from "../constants";

function getFieldValue(value) {
     if (value === null || value === undefined || value === "") return null;
     return value;
}

function CallToDiagramConverter(res) {
     const { shells, terminals, storefronts, terminalsIntegrations, kioskSkins, signagePlayers, kitchenStationProfiles, printingTemplates } = res;

     const stations = [];
     const devices = [];

     for (let sIdx = 0; sIdx < shells.length; sIdx++) {
          const shell = shells[sIdx];
          const configuration = {};

          //  STATION:SHELL CONFIGURATION
          configuration["renderingMode"] = getFieldValue(shell.shellRenderingMode);
          configuration["licenseKey"] = getFieldValue(shell.licenseKey);
          configuration["hostType"] = getFieldValue(shell.shellType);
          configuration["screenOrientation"] = getFieldValue(shell.shellScreenOrientation);
          configuration["shellCode"] = getFieldValue(shell.code);

          // IDENTIFYING TERMINAL BY ITS TYPE
          let shellTerminalId;
          let shellHardwareTerminalId;
          const foundShellTerminalLength = shell.terminals.length;
          const foundShellTerminalsIds = shell.terminals;

          for (let tidx = 0; tidx < terminals.length; tidx++) {
               const terminal = terminals[tidx];
               if (foundShellTerminalLength === 1 && shellTerminalId) break;
               if (foundShellTerminalLength === 2 && shellTerminalId && shellHardwareTerminalId) break;

               if (foundShellTerminalsIds.includes(terminal.id)) {
                    terminal.type === TerminalTypes.hardware ? (shellHardwareTerminalId = terminal.id) : (shellTerminalId = terminal.id);
               }
          }

          const shellTerminal = terminals.find((terminal) => terminal.id === shellTerminalId);
          if (!shellTerminal) continue;

          const shellHardwareTerminal = terminals.find((terminal) => terminal.id === shellHardwareTerminalId);

          //  STATION:TERMINAL CONFIGURATION
          switch (shellTerminal.type) {
               case TerminalTypes.pos:
                    configuration["signagePlayerId"] = getFieldValue(shellTerminal.profile.settings.displaySettings?.signagePlayerId);
                    configuration["storeFrontId"] = getFieldValue(shellTerminal.storefrontId);
                    configuration["floatIdentifier"] = getFieldValue(shellTerminal.floatIdentifier);
                    configuration["terminalCode"] = getFieldValue(shellTerminal.code);
                    break;

               case TerminalTypes.kds:
                    configuration["labelPrinterId"] = getFieldValue(getDeviceIdThroughUrl(shellTerminal.profile.settings.fohPrinter, terminals, terminalsIntegrations));
                    configuration["labelFormat"] = getFieldValue(shellTerminal.profile.settings.fohOrderPrintFormat);
                    configuration["terminalCode"] = getFieldValue(shellTerminal.code);

                    break;

               case TerminalTypes.kiosk:
                    configuration["receiptPrinterId"] = getFieldValue(getDeviceIdThroughUrl(shellTerminal.profile.settings.receiptPrinter.url, terminals, terminalsIntegrations));
                    configuration["skinId"] = getFieldValue(shellTerminal.profile.settings.displaySettings.skinId);
                    configuration["storeFrontId"] = getFieldValue(shellTerminal.storefrontId);
                    configuration["terminalCode"] = getFieldValue(shellTerminal.code);

                    break;

               case TerminalTypes.kitchen:
                    configuration["labelPrinterId"] = getFieldValue(getDeviceIdThroughUrl(shellTerminal.profile.settings.labelPrinter.url, terminals, terminalsIntegrations));
                    configuration["terminalCode"] = getFieldValue(shellTerminal.code);

                    break;

               case TerminalTypes.signage:
                    configuration["signagePlayerId"] = getFieldValue(shellTerminal.profile.settings.displaySettings?.signagePlayerId);
                    configuration["terminalCode"] = getFieldValue(shellTerminal.code);

                    break;

               case TerminalTypes.centralKitchen:
                    configuration["terminalCode"] = getFieldValue(shellTerminal.code);

                    break;

               default:
                    break;
          }

          const station = {
               id: shell.licenseKey,
               stationName: shell.name,
               type: shellTerminal.type,
               shell: {
                    ...shell,
               },
               terminal: {
                    ...shellTerminal,
               },
               ...(shellHardwareTerminal
                    ? {
                           hardwareTerminal: {
                                ...shellHardwareTerminal,
                           },
                      }
                    : {}),

               configuration,
          };

          // SHELL TERMINAL CONNECTED DEVICES
          if (shellTerminal) {
               const integrations = terminalsIntegrations[shellTerminal.id];
               const attachedDevices = integrations.filter((integration) => integration.systemId === shellTerminal.id);

               for (let index = 0; index < attachedDevices.length; index++) {
                    const device = attachedDevices[index];
                    //  DEVICE CONFIGURATION
                    const configuration = {};

                    const deviceType = getDeviceType(device.integrationType);

                    if (deviceType === DeviceTypes.creditCardReader) {
                         configuration["creditType"] = getFieldValue(device.externalEntity);

                         switch (device.externalEntity) {
                              case "HandPoint":
                                   configuration["url"] = getFieldValue(device.jsonData.url);
                                   configuration["apiKey"] = getFieldValue(device.jsonData.apiKey);
                                   configuration["terminalId"] = getFieldValue(device.jsonData.terminalId);

                                   break;
                              case "StripeReader":
                                   configuration["locationId"] = getFieldValue(device.jsonData.locationId);
                                   configuration["readerId"] = getFieldValue(device.jsonData.readerId);

                                   break;
                              case "PaymentSense":
                                   configuration["url"] = getFieldValue(device.jsonData.url);
                                   configuration["apiKey"] = getFieldValue(device.jsonData.apiKey);
                                   configuration["terminalId"] = getFieldValue(device.jsonData.terminalId);

                                   break;
                              case "WorldPay":
                                   configuration["ipcStatusPort"] = getFieldValue(device.jsonData.ipcStatusPort);
                                   configuration["ipcMessagingPort"] = getFieldValue(device.jsonData.ipcMessagingPort);
                                   configuration["ipcHost"] = getFieldValue(device.jsonData.ipcHost);
                                   configuration["merchantReceiptFileName"] = getFieldValue(device.jsonData.merchantReceiptFileName);

                                   break;
                              case "Offline":
                                   break;

                              default:
                                   break;
                         }
                    }

                    devices.push({
                         id: device.id,
                         deviceName: undefined,
                         type: deviceType,
                         stationId: station.id,
                         configuration,
                         connectedTerminalType: "terminal",
                         integration: {
                              ...device,
                         },
                    });
               }
          }
          // SHELL HARDWARE TERMINAL CONNECTED DEVICES
          if (shellHardwareTerminal) {
               // MAKE TELEPHONE LINE
               if (shellHardwareTerminal.profile.settings.telephoneLineEnabled) {
                    devices.push({
                         id: generateGuid(),
                         deviceName: "Telephone Cli",
                         type: DeviceTypes.telephoneCli,
                         stationId: station.id,
                         configuration: {
                              displaySignage: getFieldValue(shellHardwareTerminal.profile.settings.displaySignage),
                         },
                         connectedTerminalType: "hardwareTerminal",
                    });
               }

               // MAKE DEVICES
               const integrations = terminalsIntegrations[shellHardwareTerminal.id];
               const attachedDevices = integrations.filter((integration) => integration.systemId === shellHardwareTerminal.id);

               for (let index = 0; index < attachedDevices.length; index++) {
                    const device = attachedDevices[index];

                    let parentId = null;
                    //  DEVICE CONFIGURATION
                    const configuration = {};

                    const deviceType = getDeviceType(device.integrationType);

                    if (deviceType === DeviceTypes.receiptPrinter) {
                         configuration["receiptPrinterType"] = getFieldValue(device.externalEntity);

                         switch (device.externalEntity) {
                              case ReceiptPrinterTypes.genericUSB:
                                   configuration["systemName"] = getFieldValue(device.jsonData.systemName);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);
                                   configuration["noOfColumns"] = getFieldValue(device.jsonData.noOfColumns);
                                   configuration["logoRequireCentering"] = getFieldValue(device.jsonData.logoRequireCentering);
                                   configuration["logoSize"] = getFieldValue(device.jsonData.logoSize);
                                   configuration["logoHexCode"] = getFieldValue(device.jsonData.logoHexCode);
                                   configuration["logoFilePath"] = getFieldValue(device.jsonData.logoFilePath);
                                   configuration["logoFilePath"] = getFieldValue(device.jsonData.logoFilePath);
                                   break;
                              case ReceiptPrinterTypes.genericBluetooth:
                                   configuration["bluetoothAddress"] = getFieldValue(device.jsonData.bluetoothAddress);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);

                                   break;
                              case ReceiptPrinterTypes.genericNetwork:
                                   configuration["ipAddress"] = getFieldValue(device.jsonData.ipAddress);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);

                                   break;
                              case ReceiptPrinterTypes.sunmiCloudPrinter:
                                   configuration["serialNumber"] = getFieldValue(device.jsonData.serialNumber);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);

                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);

                                   break;

                              default:
                                   break;
                         }
                    } else if (deviceType === DeviceTypes.labelPrinter) {
                         configuration["labelPrinterType"] = getFieldValue(device.externalEntity);

                         switch (device.externalEntity) {
                              case LabelPrinterTypes.genericUSB:
                                   configuration["systemName"] = getFieldValue(device.jsonData.systemName);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);
                                   configuration["normalFontAColumnSize"] = getFieldValue(device.jsonData.normalFontAColumnSize);
                                   configuration["condensedFontBColumnSize"] = getFieldValue(device.jsonData.condensedFontBColumnSize);

                                   break;
                              case LabelPrinterTypes.genericBluetooth:
                                   configuration["bluetoothAddress"] = getFieldValue(device.jsonData.bluetoothAddress);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);
                                   configuration["normalFontAColumnSize"] = getFieldValue(device.jsonData.normalFontAColumnSize);
                                   configuration["condensedFontBColumnSize"] = getFieldValue(device.jsonData.condensedFontBColumnSize);

                                   break;
                              case LabelPrinterTypes.genericNetwork:
                                   configuration["ipAddress"] = getFieldValue(device.jsonData.ipAddress);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);
                                   configuration["normalFontAColumnSize"] = getFieldValue(device.jsonData.normalFontAColumnSize);
                                   configuration["condensedFontBColumnSize"] = getFieldValue(device.jsonData.condensedFontBColumnSize);

                                   break;
                              case LabelPrinterTypes.sunmiCloudPrinter:
                                   configuration["serialNumber"] = getFieldValue(device.jsonData.serialNumber);
                                   configuration["encodingType"] = getFieldValue(device.jsonData.encodingType) === null || getFieldValue(device.jsonData.encodingInstructions) === null ? null : getFieldValue(`${device.jsonData.encodingType} / ${device.jsonData.encodingInstructions}`);
                                   configuration["isLegacyPrinter"] = getFieldValue(device.jsonData.isLegacyPrinter);
                                   configuration["normalFontAColumnSize"] = getFieldValue(device.jsonData.normalFontAColumnSize);
                                   configuration["condensedFontBColumnSize"] = getFieldValue(device.jsonData.condensedFontBColumnSize);

                                   break;
                              case LabelPrinterTypes.brotherQL700Usb:
                                   configuration["systemName"] = getFieldValue(device.jsonData.systemName);
                                   break;
                              case LabelPrinterTypes.brotherQL800Usb:
                                   configuration["systemName"] = getFieldValue(device.jsonData.systemName);
                                   break;
                              case LabelPrinterTypes.brotherQL820Bluetooth:
                                   configuration["bluetoothAddress"] = getFieldValue(device.jsonData.bluetoothAddress);
                                   break;
                              case LabelPrinterTypes.brotherQL820Network:
                                   configuration["ipAddress"] = getFieldValue(device.jsonData.ipAddress);
                                   break;

                              case LabelPrinterTypes.zplGenericNetwork:
                                   configuration["ipAddress"] = getFieldValue(device.jsonData.ipAddress);
                                   configuration["itemLabelPrintingTemplate"] = getFieldValue(device.jsonData.itemLabelPrintingTemplate);
                                   configuration["orderLabelPrintingTemplate"] = getFieldValue(device.jsonData.orderLabelPrintingTemplate);
                                   configuration["kotLabelPrintingTemplate"] = getFieldValue(device.jsonData.kotLabelPrintingTemplate);
                                   configuration["expiryLabelPrintingTemplate"] = getFieldValue(device.jsonData.expiryLabelPrintingTemplate);
                                   break;

                              case LabelPrinterTypes.zplGenericUSB:
                                   configuration["systemName"] = getFieldValue(device.jsonData.systemName);
                                   configuration["itemLabelPrintingTemplate"] = getFieldValue(device.jsonData.itemLabelPrintingTemplate);
                                   configuration["orderLabelPrintingTemplate"] = getFieldValue(device.jsonData.orderLabelPrintingTemplate);
                                   configuration["kotLabelPrintingTemplate"] = getFieldValue(device.jsonData.kotLabelPrintingTemplate);
                                   configuration["expiryLabelPrintingTemplate"] = getFieldValue(device.jsonData.expiryLabelPrintingTemplate);
                                   break;

                              default:
                                   break;
                         }
                    } else if (deviceType === DeviceTypes.creditCardReader) {
                         configuration["creditType"] = getFieldValue(device.externalEntity);

                         switch (device.externalEntity) {
                              case "WorldPay":
                                   configuration["ipcStatusPort"] = getFieldValue(device.jsonData.ipcStatusPort);
                                   configuration["ipcMessagingPort"] = getFieldValue(device.jsonData.ipcMessagingPort);
                                   configuration["ipcHost"] = getFieldValue(device.jsonData.ipcHost);
                                   configuration["merchantReceiptFileName"] = getFieldValue(device.jsonData.merchantReceiptFileName);

                                   break;
                              default:
                                   break;
                         }
                    } else if (deviceType === DeviceTypes.kotPrinter) {
                         configuration["copiesToPrint"] = getFieldValue(device.jsonData.copiesToPrint);
                         configuration["kitchenStationId"] = getFieldValue(device.jsonData.kitchenStation);
                         configuration["storefronts"] = getFieldValue(device.jsonData.storefronts);
                         configuration["dispatchTypes"] = getFieldValue(device.jsonData.dispatchTypes);

                         switch (device.externalEntity) {
                              case "ReceiptPrinter" : case "LabelPrinter": {
                                   parentId = getFieldValue(getDeviceIdThroughName(device.jsonData.receiptPrinter, shellHardwareTerminal.id, terminalsIntegrations));
                                   break;
                              }
                              default:
                                   break;
                         }
                    }

                    devices.push({
                         id: device.id,
                         deviceName: device.jsonData.printerName,
                         type: deviceType,
                         stationId: deviceType === DeviceTypes.kotPrinter ? parentId : station.id,
                         configuration,
                         connectedTerminalType: "hardwareTerminal",
                         integration: {
                              ...device,
                         },
                    });
               }
          }

          stations.push(station);
     }

     return {
          stations,
          devices,
          storefronts: storefronts.map((storefront) => ({ ...storefront, title: storefront.name })),
          kioskSkins: kioskSkins.map((kioskSkin) => ({ ...kioskSkin, title: kioskSkin.name })),
          signagePlayers: signagePlayers.map((signagePlayer) => ({ ...signagePlayer, title: signagePlayer.name })),
          kitchenStationProfiles,
          printingTemplates
     };
}

export default CallToDiagramConverter;

function getDeviceIdThroughName(name, terminalId, integrationOfTerminals) {
     if (name === "" || name === null || name === undefined) return;
     const device = integrationOfTerminals[terminalId].find((device) => device.jsonData.printerName === name);
     if (!device) return;
     return device.id;
}

function getDeviceIdThroughUrl(url, terminals, integrationOfTerminals) {
     if (url === "" || url === null || url === undefined) return;
     const [terminalCode, deviceName] = url.split(" / ");
     const terminal = terminals.find((terminal) => terminal.code === terminalCode);
     if (!terminal) return;
     if (!integrationOfTerminals[terminal.id]) return;
     const device = integrationOfTerminals[terminal.id].find((device) => device.jsonData.printerName === deviceName);
     if (!device) return;
     return device.id;
}

function getDeviceType(integrationType) {
     if (integrationType === IntegrationTypes.ReceiptPrinterManagement) return DeviceTypes.receiptPrinter;
     if (integrationType === IntegrationTypes.LabelPrinterManagement) return DeviceTypes.labelPrinter;
     if (integrationType === IntegrationTypes.KitchenStationPrinterManagement) return DeviceTypes.kotPrinter;
     if (integrationType === IntegrationTypes.CardPaymentManagement) return DeviceTypes.creditCardReader;

     return "UNKNOWN";
}
