import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import SalesByReportTagsSummary from './SalesByReportTagsSummary';
import SalesByReportTagsDetails from './SalesByReportTagsDetails';
import SalesByReportTagsCharts from './SalesByReportTagsCharts';

import { HeaderComponent } from '../../../utils/viewUtils';

export default class SalesByReportTags extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { showDetails: false };
    }

    onShowDetails = () => this.setState({ showDetails: !this.state.showDetails })

    render()
    {
        const { showDetails } = this.state;

        return (
            <div className='d-flex flex-column border rounded-top'>

                <HeaderComponent caption="Sales By Report Tags" showDetails={showDetails} onShowDetails={this.onShowDetails} index={this.props.index} />

                <Collapse isOpen={!showDetails}>
                    <SalesByReportTagsSummary data={this.props.data} />
                </Collapse>

                <Collapse isOpen={showDetails}>
                    <div className='d-flex flex-column'>
                        <div>
                            <SalesByReportTagsDetails data={this.props.data} showDetails={showDetails} />
                        </div>
                        <div className='m-3 border rounded'>
                            <SalesByReportTagsCharts data={this.props.data} />
                        </div>
                    </div>
                </Collapse>

            </div>
        );
    }
}