const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const stringUtil = require('../../../../../utils/string/stringUtil');


export async function save (data)
{        
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/apiIntegration/${data.id}`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/apiIntegration`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function deleteApiIntegration (apiIntegrationId)
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/apiIntegration/${apiIntegrationId}`;
    return await rmsApiProxy.deleted(resourceUrl);
}

export function validate (franchisorApiIntegration)
{

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.id))
        return "FranchisorApiIntegration id cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.audience))
        return "FranchisorApiIntegration audience cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.clientId))
        return "FranchisorApiIntegration clientId cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.clientSecret))
        return "FranchisorApiIntegration clientSecret cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.grantType))
        return "FranchisorApiIntegration grantType cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.partner))
        return "FranchisorApiIntegration partner cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.webHookSecretToken))
        return "FranchisorApiIntegration webHookSecretToken cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.webHookUrl))
        return "FranchisorApiIntegration webHookUrl cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(franchisorApiIntegration.entities))
        return "FranchisorApiIntegration entities cannot be left empty";

    return null;
}