import React, { Component } from 'react';
import { ButtonGroup, Button } from 'reactstrap'

const configurationManager = require('../../../../utils/config/configurationManager');

class PropertySelection extends Component
{
    render()
    {
        const { properties, selectedProperty, onSelectProperty } = this.props;

        return (
            <div className='w-100 mt-3 d-flex flex-column'>

                <div className='mb-3'>
                    Please choose your store from the list below where the station is installed, and hit Continue
                </div>

                <div className='w-100 border rounded shadow-sm' style={{ maxHeight: "35vh", overflow: "auto" }}>
                    <ButtonGroup vertical className='w-100 p-3'>
                        {
                            properties.map(property =>
                                <Button
                                    key={property.id}
                                    className='w-100 mb-2 p-2 text-start d-flex align-items-center'
                                    outline color="primary" active={selectedProperty != null && selectedProperty.id === property.id}
                                    onClick={() => onSelectProperty(property)}>
                                    <div className='w-100 d-flex align-items-center'>
                                        <img src={this.getImageUrl(property)} style={{ width: '30px' }} />
                                        <div className='d-flex flex-column' style={{ width: "calc(100% - 32px)" }}>
                                            <div className='w-100 ms-2 text-nowrap text-truncate border-bottom'>{property.name}</div>
                                            <div className='w-100 ms-2 text-nowrap text-truncate' style={{ fontSize: "0.75em" }}>{property.address}</div>
                                        </div>
                                    </div>
                                </Button>)
                        }
                    </ButtonGroup>
                </div>
            </div>
        );
    }

    getImageUrl = (property) =>
    {
        // Using menu icon instead of property logo for consistent look
        const rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;
        return `${rootUrl}/app/back.office/menu/30.0.0/Property.png`;
    }
}

export default PropertySelection;
