
import React from 'react'
import CaptionLabel from '../../../../../../../components/form/captionLabel';
import AmountLabel from '../../../../../../../components/form/amountLabel';

const PayrollRunSummaryUtils = require('../utils/PayrollRunSummaryUtils');

const LabelWidth = PayrollRunSummaryUtils.SummaryLabelWidth;
const AmountWidth = PayrollRunSummaryUtils.SummaryAmountWidth;

function PayrollRunEmployerSummary({ data })
{
    return <table width="100%">
        <tbody>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Net Wage"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.netWage} />
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total HMRC Payment"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.totalHMRCPayment} />
                </td>
            </tr>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total Pension Contribution"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.totalPensionPayment} />
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total payroll cost"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.totalPayrollCost} />
                </td>
            </tr>
        </tbody>
    </table>
}

export default PayrollRunEmployerSummary;