import React, { Component } from 'react';

import TimingTabularRenderer from './helperComponents/TimingTabularRenderer';
import TimingChartRenderer from './helperComponents/TimingChartRenderer';

import * as reportDataUtils from '../utils/reportDataUtils';


class DeliveryTimeAnalytics extends Component
{
    render()
    {
        const { timingData } = this.props;

        const normalizedTimingData = reportDataUtils.getNormalizedTimingData(timingData);

        return (
            <div className='d-flex flex-column'>

                <div>
                    <TimingTabularRenderer
                        averageTimeCaption={"Delivery Time"}
                        timingData={normalizedTimingData} />
                </div>

                <div className='mt-2 border'>

                    <TimingChartRenderer
                        title="Delivery Time"
                        subtitle="Average order delivery time in minutes"
                        timingData={normalizedTimingData} />
                </div>

            </div>
        );
    }
}

export default DeliveryTimeAnalytics;