module.exports.inventoryEventItemType =
{
    rawStockItem: "rawStockItem",
    buildItem: "buildItem",
    prepItem: "prepItem",
}

module.exports.inventoryEventType =
{
    sale: "sale",
    build: "build",
    wastage: "wastage",
    purchase: "purchase",
    stockTake: "stockTake",
    centralKitchenSale: "centalKitchenSale"
}