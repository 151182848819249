import React, { Component } from 'react';

import { formatAmount } from '../../../utils/viewUtils';
import { getVatCaption, getNomenclature } from '../../../../../../reportUtils/helperUtils/viewUtils';

export default class SaleItemsSummary extends Component
{
    render()
    {
        const { saleItemsSummary } = this.props.data;

        return (
            <div className='row m-0 border rounded'>

                <div className='py-4 col text-white border-end rounded d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "#0d6efd" }}>
                    <div className='fw-bold'>Total Quantity</div>
                    <div>{saleItemsSummary.totalQuantity}</div>
                </div>

                <div className='py-4 col border-end rounded-end d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "WhiteSmoke" }}>
                    <div className='fw-bold text-muted'>{getNomenclature("retailOrderPrice")}</div>
                    <div>{formatAmount(saleItemsSummary.totalRetailOrderPrice)}</div>
                </div>

            </div>
        );
    }
}