
import React, { Component, Fragment } from 'react';
import { FormGroup, InputGroup, InputGroupText, Input, FormText, Label } from 'reactstrap';
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

import Select from 'react-select';
import CalendarInputComponent from './inputComponents/calendarInputComponent';
import TwentyFourHourTimeInputComponent from './inputComponents/twentyFourHourTimeInputComponent';

var stringUtil = require('../../utils/string/stringUtil.js');
var dateUtil = require('../../utils/dateUtil/dateUtil.js');

var configurationManager = require('../../utils/config/configurationManager');

const constants = require('./constants');

class InputComponent extends Component 
{
    constructor(props) 
    {
        super(props);

        this.renderCalendarComponent = this.renderCalendarComponent.bind(this);

        this.state = {};
    }

    render()
    {
        //#region style overrides

        //
        // Extracts style property from props
        // BackgroundColor style is applied only to the <Input/> component
        // All the remaining style (like margins, paddings etc) is applied only to the <FormGroup/> component
        //
        // this.style and this.backgroundColorStyle are declared as instance level variables since these are
        // referred to by render, renderCalendarComponent and render24HoursComponent methods
        //

        this.style = this.props.style || {};    // Get style from props
        this.backgroundColorStyle = { backgroundColor: this.style.backgroundColor };    // Extract backgroundColor into a separate style
        delete this.style.backgroundColor;      // Delete backgroundColor from style

        if (this.props.inputReadOnly)
        {
            // Implies readonly mode

            if (!this.backgroundColorStyle.backgroundColor)
            {
                // Implies backgroundColor not set explicitly in props
                // backgroundColor is overridden only when not explicitly set in props

                this.backgroundColorStyle.backgroundColor = constants.readOnlyBackgroundColor;
            }
        }

        //
        // backgroundColor=undefined is observed to render as transparent
        //

        //#endregion

        // CalendarInputComponent/TwentyFourHourTimeInputComponent components ported from foodsafety during migration

        if (this.props.inputType == "date")
            return <CalendarInputComponent {...this.props} mainStyle={this.style} backgroundColorStyle={this.backgroundColorStyle} />;

        if (this.props.inputType == "24HourTime")
            return <TwentyFourHourTimeInputComponent {...this.props} mainStyle={this.style} backgroundColorStyle={this.backgroundColorStyle} />;


        // This might break existing UI using inputType="date"
        // if (this.props.inputType == "date")
        //     return this.renderCalendarComponent();

        // This should also be commented after necessary changes and validation
        if (this.props.inputType == "24Hours")
            return this.render24HoursComponent();


        var hintTextComponent = null;
        var captionTextComponent = null;
        var addonIcon = null;

        var underlyingType = "text";

        switch (this.props.inputType)
        {
            case "name":
                addonIcon = "fa fa-mortar-board";
                break;

            case "code":
                addonIcon = "fa fa-key";
                break;

            case "type":
                addonIcon = "fa fa-file-code-o";
                break;

            case "phone":
                addonIcon = "fa fa-phone";
                break;

            case "address":
                addonIcon = "fa fa-map-o";
                break;

            case "email":
                addonIcon = "fa fa-envelope-o";
                break;

            case "web":
                addonIcon = "cui-globe icons";
                break;

            case "identity":
                addonIcon = "fa fa-bookmark-o";
                break;

            case "person":
                addonIcon = "fa fa-bookmark-o";
                break;

            case "money":
                addonIcon = "fa fa-money";
                break;

            case "time":
                addonIcon = "fa fa-clock-o";
                break;

            case "percentage":
                addonIcon = "fa fa-percent opacity-75";
                break;

            case "password":
                addonIcon = "icon-lock";
                underlyingType = "password";
                break;

            case "reviews":
                addonIcon = "fa fa-star-half-empty";
                break;

            case "ratings":
                addonIcon = "fa fa-line-chart";
                break;

            case "mileage":
                addonIcon = "fa fa-road";   // fa fa-truck/fa fa-motorcycle
                break;

            case "question":
                addonIcon = "fa fa-question-circle-o";   // fa fa-truck/fa fa-motorcycle
                break;
                
            default:
                break;
        }

        if (this.props.hintText)
        {
            hintTextComponent = <FormText color="muted">{this.props.hintText}</FormText>
        }

        captionTextComponent = <Label>{this.props.caption}</Label>

        if (this.props.multiLine)
            underlyingType = "textarea";

        if (addonIcon == null)
        {
            return (

                // 
                // style after props overrides any style defined in props
                //

                <FormGroup {...this.props} style={this.style}>

                    {captionTextComponent}
                    {
                        this.wrapComponentWithGrammarly
                        (
                            null, 
                            <Input
                                type={underlyingType}
                                id={this.props.fieldName}
                                value={this.props.formManager.getValue(this.props.fieldName)}
                                readOnly={this.props.inputReadOnly}
                                onChange={this.props.formManager.onValueChanged}
                                {...this.props} style={this.backgroundColorStyle} />
                        )
                    }
                    {hintTextComponent}
                </FormGroup>
            );
        }
        else
        {
            return (

                // 
                // style after props overrides any style defined in props
                //

                
                <FormGroup  {...this.props} style={this.style}>

                    {captionTextComponent}
                    <InputGroup>
                        {
                            this.wrapComponentWithGrammarly
                            (
                                <InputGroupText><i className={addonIcon}></i></InputGroupText>,
                                <Input
                                    type={underlyingType}
                                    id={this.props.fieldName}
                                    value={this.props.formManager.getValue(this.props.fieldName)}
                                    readOnly={this.props.inputReadOnly}
                                    onChange={this.props.formManager.onValueChanged}
                                    {...this.props} style={this.backgroundColorStyle} />
                                )
                        }
                    </InputGroup>
                    {hintTextComponent}
                </FormGroup>
                

            );
        }
    }

    wrapComponentWithGrammarly(iconComponent, inputComponent)
    {
        if (stringUtil.areStringSame(configurationManager.getConfig().grammarlyEnabled, "false"))
            return <Fragment>
                        {iconComponent}
                        {inputComponent}
                    </Fragment>;
        
        return <GrammarlyEditorPlugin config={{activation: "immediate"}} clientId={configurationManager.getConfig().grammarlyApiKey} style={{width:'100%'}}>
                    {inputComponent}
                </GrammarlyEditorPlugin>
    }

    renderCalendarComponent()
    {
        var addonIcon = "fa fa-calendar";
        var hintTextComponent = null;
        var captionTextComponent = null;

        if (this.props.hintText)
        {
            hintTextComponent = <FormText color="muted">{this.props.hintText}</FormText>
        }

        captionTextComponent = <Label>{this.props.caption}</Label>

        var rawCurrentValue = this.props.formManager.getValue(this.props.fieldName);
        var currentValue = "";
        if (!stringUtil.isStringNullOrEmpty(rawCurrentValue))
            currentValue = dateUtil.convertToLocalStandard(rawCurrentValue);

        return (

            // 
            // style after props overrides any style defined in props
            //

            <FormGroup {...this.props} style={this.style}>

                {captionTextComponent}

                <InputGroup>

                    <InputGroupText><i className={addonIcon}></i></InputGroupText>
                    
                    <Input
                        type="date"
                        id={this.props.fieldName}
                        readOnly={this.props.inputReadOnly}
                        value={currentValue}
                        onChange={this.props.formManager.onValueChanged}
                        {...this.props} style={this.backgroundColorStyle} />

                </InputGroup>

                {hintTextComponent}

            </FormGroup>);
    }

    render24HoursComponent()
    {
        var addonIcon = "fa fa-clock-o";
        var hintTextComponent = null;
        var captionTextComponent = null;

        if (this.props.hintText)
        {
            hintTextComponent = <FormText color="muted">{this.props.hintText}</FormText>
        }

        captionTextComponent = <Label>{this.props.caption}</Label>


        localDateTime = null;
        var currentHour = null;
        var currentMinute = null;

        var utcDateTime = this.props.formManager.getValue(this.props.fieldName);

        if (!stringUtil.isStringNullOrEmpty(utcDateTime))
        {
            var localDateTime = dateUtil.convertToLocalStandard(utcDateTime);
            var currentHour = dateUtil.formatDate(localDateTime, "HH");
            var currentMinute = dateUtil.formatDate(localDateTime, "mm");
        }

        var timeClearable = false;
        if (this.props.timeClearable)
            timeClearable = this.props.timeClearable

        return (

            <FormGroup {...this.props} style={this.style}>

                {captionTextComponent}

                <InputGroup>

                    <InputGroupText><i className={addonIcon}></i></InputGroupText>
                    
                    <table width="80%">

                        <tbody>

                            <tr width="100%">

                                <td width="50%">

                                    <Select
                                        id={this.props.fieldName}
                                        value={currentHour}
                                        options={this.getPossibleHours()}
                                        disabled={this.props.inputReadOnly}
                                        placeholder="Hours"
                                        clearable={timeClearable}

                                        {...this.props} style={this.backgroundColorStyle}

                                        onChange={(optionValue) =>
                                        {
                                            var target = {};
                                            target.type = "time";
                                            target.id = this.props.fieldName;

                                            if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value))
                                                target.value = null;
                                            else
                                            {
                                                if (localDateTime == null)
                                                {
                                                    utcDateTime = dateUtil.getToday();
                                                    localDateTime = dateUtil.convertToLocalStandard(utcDateTime);
                                                }



                                                target.value = dateUtil.setHours(localDateTime, optionValue.value);
                                                target.value = dateUtil.setSeconds(target.value, 0);
                                            }

                                            var event = {};
                                            event.target = target;
                                            this.props.formManager.onValueChanged(event);
                                        }}
                                    />
                                </td>

                                <td width="50%">

                                    <Select
                                        id={this.props.fieldName}
                                        value={currentMinute}
                                        options={this.getPossibleMinutes()}
                                        disabled={this.props.inputReadOnly}
                                        placeholder="Minutes"
                                        clearable={timeClearable}

                                        {...this.props} style={this.backgroundColorStyle}

                                        onChange={(optionValue) =>
                                        {
                                            var target = {};
                                            target.type = "time";
                                            target.id = this.props.fieldName;

                                            if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value))
                                                target.value = null;
                                            else
                                            {
                                                if (localDateTime == null)
                                                {
                                                    utcDateTime = dateUtil.getToday();
                                                    localDateTime = dateUtil.convertToLocalStandard(utcDateTime);
                                                }

                                                target.value = dateUtil.setMinutes(localDateTime, optionValue.value);
                                                target.value = dateUtil.setSeconds(target.value, 0);
                                            }

                                            var event = {};
                                            event.target = target;
                                            this.props.formManager.onValueChanged(event);
                                        }}
                                    />
                                </td>

                            </tr>

                        </tbody>

                    </table>

                </InputGroup>

                {hintTextComponent}

            </FormGroup>);
    }

    getPossibleHours()
    {
        var options = [];

        for (var i = 0; i <= 23; i++)
        {
            var formattedString = i + "";
            formattedString = formattedString.padStart(2, '0');

            let option = {};
            option.value = formattedString;
            option.label = formattedString;
            options.push(option);
        }
        return options;
    }

    getPossibleMinutes()
    {
        var options = [];

        for (var i = 0; i <= 59; i++)
        {
            var formattedString = i + "";
            formattedString = formattedString.padStart(2, '0');

            let option = {};
            option.value = formattedString;
            option.label = formattedString;
            options.push(option);
        }
        return options;
    }
}

export default InputComponent;