
import * as arraySort from 'array-sort';

import * as stringUtil from '../../../../../utils/string/stringUtil';


export function getFixedCostSummaryData(props)
{
    const { fixedCostData } = props.data;

    const categories = [...new Set(fixedCostData.map(data => data.accountCategory))];

    const summaryData = [];

    categories.forEach(category => 
    {
        const filteredData = fixedCostData.filter(data => data.accountCategory === category);

        summaryData.push({
            category: category,
            amount: filteredData.reduce((value, data) => value + data.amount, 0)
        });
    });

    return arraySort(summaryData, 'amount', { reverse: true });
}