import React, { Component } from 'react';
import { ModalBody, Button, ModalFooter } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import TabbedBodyContents from './tabbedBodyContents';
import PdfContents from './pdfContents';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { generateUniqueCode } from '../../../../../../utils/guid/guidUtil';

class ValidationModal extends Component {

    constructor(props)
    {
        super(props);
        this.onCloseButton = this.onCloseButton.bind(this);
    }
    getStyles() {
        return {
            modal: {
                width: "80vw",
                maxWidth: "80vw",
                maxHeight: "calc(90vh - 100px)",
                marginTop: "100px"
            }
        }
    }

    render() {
        return [this.renderModal(this.props.title, this.props.logs), this.renderPDFLogs(this.props.title, this.props.logs)]
    }

    onCloseButton() {
        this.props.onClose();
    }

    renderModal(title, logs)
    {
        
        return (
            <div onClick={(e)=>e.stopPropagation()} onDoubleClick={(e)=>e.stopPropagation()}>
            <Modal styles={this.getStyles()} open={logs && logs.length > 0} onClose={this.onCloseButton} showCloseIcon={false} classNames={{ overlay: 'customOverlay', modal: 'customModal' }}>

                    <div className="d-flex flex-row justify-content-between align-items-center text-muted w-100">
                        <div style={{fontWeight:600, fontSize:"1.1rem"}} className='text-muted'>{title}</div>
                        {
                            (this.props.type == "offline") ? 
                            <div>
                                <ReactToPrint content={() => this.componentRef}>
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <Button color='primary' onClick={handlePrint}>Print Pdf Report</Button>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>
                            </div> : 
                            null
                        }
                        
                    </div>
                <ModalBody>
                    <TabbedBodyContents logs={logs} isDefaultOpen={true} type={this.props.type} publish={this.props.publish}/>
                </ModalBody>
                <ModalFooter >
                    <hr></hr>
                    <div className="d-flex flex-row justify-content-end">
                        {this.props.continueButton}
                        <div className="mx-1"></div>
                        <Button color="primary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={this.onCloseButton}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>
            </Modal> 
            </div>   
        )
    }

    renderPDFLogs(title, logs)
    {
        return (
            <div hidden="hidden">
                <div key={generateUniqueCode()} ref={el => (this.componentRef = el)} >
                    <PdfContents title={title} logs={logs} isDefaultOpen={true}/>
                </div>
            </div>
        )
    }

}

export default ValidationModal