import React, { Component } from 'react';
import * as arraySort from 'array-sort';

import PieChartReport from '../../../../../../reportUtils/chartComponents/PieChartReport';
import { getNomenclature } from '../../../../../../reportUtils/helperUtils/viewUtils';

const biUtils = require('../../../../../../../../utils/bi/biUtils');
const { formatCurrencyValue: currencyFormatter, formatAmount: numberFormatter, formatPercentage: percentageFormatter } = biUtils;


export default class SalesByReportTagsCharts extends Component
{
    get maxLabelCount() { return 8 }    // 8 data items + Others

    render()
    {
        const { salesByReportTags } = this.props.data;

        // salesByReportTags contains sales data by reportTags and storefrontType.
        // Since this report only requires sales by reportTags data, we summarize data by reportTags below
        // before feeding it to the report

        const salesByReportTagsSummary = [];

        salesByReportTags.forEach(data => 
        {
            let dataRow = salesByReportTagsSummary.find(dataRow => dataRow.reportTags === data.reportTags);

            if (dataRow == null)
            {
                dataRow = {
                    reportTags: data.reportTags,
                    totalQuantity: 0,
                    totalRetailOrderPrice: 0.0,
                    totalTax: 0.0
                }

                salesByReportTagsSummary.push(dataRow);
            }

            dataRow.totalQuantity += data.totalQuantity;
            dataRow.totalRetailOrderPrice += data.totalRetailOrderPrice;
            dataRow.totalTax += data.totalTax;
        });

        return (
            <div className='d-flex align-items-center' style={{ backgroundColor: "WhiteSmoke" }}>

                <div className='w-50 border-end d-flex justify-content-center align-items-center'>
                    {this.renderQuantityChart(salesByReportTagsSummary)}
                </div>

                <div className='w-50 d-flex justify-content-center align-items-center'>
                    {this.renderRetailOrderPriceChart(salesByReportTagsSummary)}
                </div>

            </div>
        );
    }

    renderQuantityChart(salesByReportTags)
    {
        const title = "Sale By Quantity";
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const totalQuantity = salesByReportTags.reduce((previous, current) => previous + current.totalQuantity, 0);

        if (totalQuantity === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{numberFormatter(0)}</div>
                </div>
            );
        }

        const data = salesByReportTags.map(data => ({
            label: data.reportTags,
            value: data.totalQuantity,
            percentage: data.totalQuantity / totalQuantity * 100
        }));

        if (data.length > this.maxLabelCount + 1)
        {
            // Too many labels may not be handled by pie chart neatly, so we club 
            // excess labels trailing by totalQuantity into Others

            arraySort(data, "value", { reverse: true });
            const otherData = data.slice(this.maxLabelCount);
            const otherDataTotal = otherData.reduce((previous, current) => previous + current.value, 0);
            data.splice(this.maxLabelCount, Infinity, {
                label: `Others (${otherData.length})`,
                value: otherDataTotal,
                percentage: otherDataTotal / totalQuantity * 100
            });
        }

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={numberFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }

    renderRetailOrderPriceChart(salesByReportTags)
    {
        const title = `Sale By ${getNomenclature("retailOrderPrice")}`;
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const totalRetailOrderPrice = salesByReportTags.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);

        if (totalRetailOrderPrice === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{currencyFormatter(0)}</div>
                </div>
            );
        }

        const data = salesByReportTags.map(data => ({
            label: data.reportTags,
            value: data.totalRetailOrderPrice,
            percentage: data.totalRetailOrderPrice / totalRetailOrderPrice * 100
        }));

        if (data.length > this.maxLabelCount + 1)
        {
            // Too many labels may not be handled by pie chart neatly, so we club 
            // excess labels trailing by totalQuantity into Others

            arraySort(data, "value", { reverse: true });
            const otherData = data.slice(this.maxLabelCount);
            const otherDataTotal = otherData.reduce((previous, current) => previous + current.value, 0);
            data.splice(this.maxLabelCount, Infinity, {
                label: `Others (${otherData.length})`,
                value: otherDataTotal,
                percentage: otherDataTotal / totalRetailOrderPrice * 100
            });
        }

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={currencyFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }
}