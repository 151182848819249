import React, { Component } from 'react';


import QuestionInputComponent from '../incidentTypeUtils/questionInputComponent';
import QuestionListComponent from '../incidentTypeUtils/questionListComponent';

import viewUtil from '../../../../../utils/view/viewUtil';
import typeUtil from '../../../../../utils/type/typeUtil';
import stringUtil from '../../../../../utils/string/stringUtil';

const validator = require('../../../../../utils/validator/validator');
const constants = require('../../../../../utils/domain/constants');

class IncidentTypeQuestions extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            incidentTypeQuestions: this.props.formManager.viewModel.incidentTypeQuestions,
            selectedIncidentTypeQuestion: {},
            clonedIncidentTypeQuestion: null
        }
    }

    validateIncidentTypeQuestion = (incidentTypeQuestion) =>
    {
        if (stringUtil.isStringNullOrEmpty(incidentTypeQuestion.question))
            return "Question is required";

        if (stringUtil.isStringNullOrEmpty(incidentTypeQuestion.responseType))
            return "Please Select a Response Type";

        if(stringUtil.areStringSame(incidentTypeQuestion.responseType, constants.incidentTypeQuestionResponseTypes.options) 
            && !validator.isNonEmptyArray(incidentTypeQuestion.responseOptions))
        {
            return `Response Options cannot be empty for Response Type: Options`;
        }
    }

    onAddUpdateIncidentTypeQuestion = (selectedIncidentTypeQuestion) =>
    {
        let validationError = this.validateIncidentTypeQuestion(selectedIncidentTypeQuestion);
        if (validationError)
        {
            viewUtil.showErrorAlert(validationError);
            return;
        }

        const incidentTypeQuestions = this.state.incidentTypeQuestions;

        const tempIncidentTypeQuestion =
            incidentTypeQuestions.find(incidentTypeQuestion =>
                incidentTypeQuestion.question.toLowerCase() == selectedIncidentTypeQuestion.question.toLowerCase());

        if (tempIncidentTypeQuestion != null)
        {
            if (tempIncidentTypeQuestion != selectedIncidentTypeQuestion)
            {
                // Reference equality
                viewUtil.showErrorAlert("A matching question already exists");
                return;
            }
        }

        const index = incidentTypeQuestions.indexOf(selectedIncidentTypeQuestion);

        if (index > -1)
        {
            incidentTypeQuestions.splice(index, 1, selectedIncidentTypeQuestion);
        }
        else
        {
            incidentTypeQuestions.push(selectedIncidentTypeQuestion);
        }

        this.setState({ selectedIncidentTypeQuestion: {} });
    }

    onDeleteIncidentTypeQuestion = (selectedIncidentTypeQuestion) =>
    {
        const incidentTypeQuestions = this.state.incidentTypeQuestions;
        const index = incidentTypeQuestions.indexOf(selectedIncidentTypeQuestion);  // Reference equality

        if (index > -1)
        {
            incidentTypeQuestions.splice(index, 1);
        }

        this.setState({ selectedIncidentTypeQuestion: {} });
    }

    onCancelIncidentTypeQuestion = () => 
    {
        if (this.state.selectedIncidentTypeQuestion)
        {
            if (this.state.clonedIncidentTypeQuestion)
            {
                // Use clone to restore object back to its original state
                Object.assign(this.state.selectedIncidentTypeQuestion, this.state.clonedIncidentTypeQuestion);
            }
        }

        this.setState({ selectedIncidentTypeQuestion: {} });
    }

    onSelectIncidentTypeQuestion = (selectedIncidentTypeQuestion) =>
    {
        //
        // Implies an existing object selected for edit,
        // save a clone in the state to restore original object back upon Cancel
        //




        this.setState({
            selectedIncidentTypeQuestion: selectedIncidentTypeQuestion,
            clonedIncidentTypeQuestion: typeUtil.deepCloneObject(selectedIncidentTypeQuestion)
        });
    }

    render()
    {
        const incidentTypeQuestions = this.state.incidentTypeQuestions;
        const selectedIncidentTypeQuestion = this.state.selectedIncidentTypeQuestion;

        return (
            <div className="d-flex flex-row">

                <div className="border rounded me-2 w-50">
                    <QuestionInputComponent
                        isRowReadOnly={this.props.isRowReadOnly}
                        selectedIncidentTypeQuestion={selectedIncidentTypeQuestion}
                        onAddUpdateIncidentTypeQuestion={this.onAddUpdateIncidentTypeQuestion}
                        onCancelIncidentTypeQuestion={this.onCancelIncidentTypeQuestion}
                    />
                </div>

                <div className="border rounded ms-2 w-100">
                    <QuestionListComponent
                        isRowReadOnly={this.props.isRowReadOnly}
                        incidentTypeQuestions={incidentTypeQuestions}
                        selectedIncidentTypeQuestion={selectedIncidentTypeQuestion}
                        onSelectIncidentTypeQuestion={this.onSelectIncidentTypeQuestion}
                        onDeleteIncidentTypeQuestion={this.onDeleteIncidentTypeQuestion}
                    />
                </div>
                
            </div>);
    }
}

export default IncidentTypeQuestions;