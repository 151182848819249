import React, { Component } from "react";
import { FormText } from 'reactstrap';

import InputComponent from '../../../../../components/form/inputComponent';
import CheckComponent from '../../../../../components/form/checkComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent';
import ColorPickerComponent from '../../../../../components/form/colorPickerComponent';

const domainConstants = require('../../../../../utils/domain/constants');

export default class TaskOverview extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = this.props.formManager;
    }

    getSelectedHazardTypes = () =>
    {
        const viewModel = this.formManager.viewModel;
        const hazardTypes = viewModel.hazardTypes;
        const options = domainConstants.getHazardTypeOptions();
        return options.filter(option => hazardTypes.includes(option.value));
    }

    onSelectedHazardTypesChanged = (selectedOptions) =>
    {
        const viewModel = this.formManager.viewModel;
        viewModel.hazardTypes = selectedOptions.map(option => option.value);
        this.setState({});
    }

    render() 
    {
        const taskTypes = this.props.lookupData.taskTypes;

        return (
            <table className="component-table">

                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="name"
                                caption="Task Name"
                                fieldName="name"
                                placeholder="Enter task name"
                                hintText="Enter name of the task (Required)"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <SelectComponent
                                caption="Task Type"
                                fieldName="taskTypeId"
                                optionFieldName="id"
                                optionDisplayFieldName="name"
                                optionValues={taskTypes}
                                hintText="Select type of this task (Required)"
                                clearable={false}
                                comboReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <MultiSelectComponent
                                caption="Hazard Types"
                                valueFieldName="value"
                                displayFieldName="label"
                                options={domainConstants.getHazardTypeOptions()}
                                selectedOptions={this.getSelectedHazardTypes()}
                                onSelectionChanged={this.onSelectedHazardTypesChanged}
                                hintText="Select potential hazard types on failing this task"
                                comboReadOnly={this.props.isRowReadOnly} />
                        </td>
                        <td>
                            <div>
                                <InputComponent
                                    inputType="24HourTime"
                                    caption="Time of Day"
                                    fieldName="timeOfDay"
                                    clearable={true}
                                    hintText="Specify time when this task should be performed (Optional)"
                                    inputReadOnly={this.props.isRowReadOnly}
                                    formManager={this.formManager} />
                            </div>
                        </td>

                    </tr>

                    <tr>
                        <td>
                            <ColorPickerComponent
                                caption="Color Code"
                                fieldName="colorCode"
                                placeholder="Choose task color code"
                                hintText="Choose task color code for easy identification (Optional)"
                                clearable={true}
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="date"
                                caption="Expiry Date"
                                hintText="Enter date when this task expires (Optional)"
                                fieldName="expiryDate"
                                placeholder="Enter expiry date"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="ms-0 mb-3">
                                <CheckComponent
                                    caption="Is this a compulsory task ?"
                                    fieldName="isCompulsory"
                                    inputReadOnly={this.props.isRowReadOnly}
                                    formManager={this.formManager} />
                                <FormText color="muted" style={{ margin: "-15px 0px 0px 15px" }}>
                                    Compulsory task will be enforced in kitchen app Task List
                                </FormText>
                            </div>
                        </td>
                        <td>
                            <div className="ms-0 mb-3">
                                <CheckComponent
                                    caption="Disable this task"
                                    fieldName="isDisabled"
                                    inputReadOnly={this.props.isRowReadOnly}
                                    formManager={this.formManager} />
                                <FormText color="muted" style={{ margin: "-15px 0px 0px 15px" }}>
                                    Disabled task will be excluded from kitchen app Task List
                                </FormText>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                multiLine={true} rows={4}
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                </tbody>

            </table>
        );
    }
}