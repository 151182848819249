
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js'
import FormManager from '../../../../../utils/view/formManager';

export default class CreditAccountPaymentView extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {}
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    render() 
    {
        const formManager = this.state.formManager;

        return (
            <div className='w-100 px-3 d-flex flex-column'>

                <div className='w-50'>
                    <InputComponent
                        inputType="money"
                        caption="Amount"
                        fieldName="amount"
                        hintText="Required"
                        placeholder="Enter debit amount"
                        inputReadOnly={this.props.isReadOnly}
                        formManager={formManager} />
                </div>

                <div className='w-100'>
                    <InputComponent
                        inputType="text"
                        caption="Reference"
                        fieldName="reference"
                        hintText="Required"
                        placeholder="Enter text for reference"
                        inputReadOnly={this.props.isReadOnly}
                        formManager={formManager} />
                </div>

            </div>
        );
    }
}