import React from 'react';
import { Button } from 'reactstrap';

import SearchBarComponent from '../../../../../components/form/searchBarComponent';
import CloseButton from '../../../../../components/button/closeButton';



const stringUtil = require('../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const formatter = require('../../../../../utils/formatter/formatter');

const regexComma = /,/g;    // Use to remove comma from number string

export default {

    SaveButton: SaveButton,
    CancelButton: CancelButton,
    SearchComponent: SearchComponent,

    getProjectionOverrideData: getProjectionOverrideData,

    validateProjectionOverrideData: validateProjectionOverrideData,
    saveProjectionOverrideData: saveProjectionOverrideData,

    restoreProjectionOverrideData: restoreProjectionOverrideData
}

async function getProjectionOverrideData(loadCriteria)
{
    const fromDate = loadCriteria.startDate;
    const toDate = loadCriteria.endDate;    

    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/projections/overrides?fromDate=${fromDate}&toDate=${toDate}`;

    const overrideData = await rmsApiProxy.get(resourceUrl);
    enrichData(fromDate, toDate, overrideData);

    return overrideData;
}

async function saveProjectionOverrideData(loadCriteria, overrideData)
{
    // Assumption: overrideData already passed by validateProjectionOverrideData

    const overrides =
        overrideData
            .filter(overrideItem => !stringUtil.isStringNullOrEmpty(overrideItem.totalOverriddenGrossSales))
            .map(overrideItem => (
                {
                    date: overrideItem.date,
                    totalGrossSales: parseFloat(overrideItem.totalOverriddenGrossSales.replace(regexComma, ""))
                }));

  // 0 length array is possible, serves to delete all existing overrides


    const fromDate = loadCriteria.startDate;
    const toDate = loadCriteria.endDate;    

    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/projections/overrides?fromDate=${fromDate}&toDate=${toDate}`;

    await rmsApiProxy.post(resourceUrl, overrides);
}

function validateProjectionOverrideData(overrideData)
{
    overrideData.forEach(overrideItem =>
    {
        if (!stringUtil.isStringNullOrEmpty(overrideItem.totalOverriddenGrossSales))
        {
            const totalOverriddenGrossSales = parseFloat(overrideItem.totalOverriddenGrossSales.replace(regexComma, ""));

            if (isNaN(totalOverriddenGrossSales) || totalOverriddenGrossSales <= 0)
            {
                throw `Invalid override value for date ${overrideItem.date}`;
            }
        }
    });
}

function restoreProjectionOverrideData(overrideData)
{
    // Used after overrideData is successfully saved to the server

    overrideData.forEach(overrideItem =>
    {
        overrideItem.totalOverriddenGrossSales =
            formatter.convertToAmountFormat(overrideItem.totalOverriddenGrossSales.replace(regexComma, ""), false, 2);
        overrideItem.originalTotalOverriddenGrossSales = overrideItem.totalOverriddenGrossSales;
    });
}

function SearchComponent(props)
{
    const disabled = props.isLoading || props.isDirty;
    const onSearch = props.onSearch;
    const onChange = props.onChange;
    const searchCriteria = props.searchCriteria;

    return (
        <SearchBarComponent
            searchButtonCaption="Load"
            disabled={disabled}
            onSearch={onSearch}
            onChange={onChange}
            searchCriteria={searchCriteria}/>
    );
}

function SaveButton(props)
{
    const disabled = props.isLoading || !props.isDirty;
    const onClick = props.onClick;

    return (
        <Button
            className="btn-success px-4 py-2 me-2" color="success"
            style={{ marginRight: '3px' }}
            disabled={disabled}
            onClick={onClick}>
            <i className="fa fa-save"></i>&nbsp;Save
        </Button>
    );
}

function CancelButton(props)
{
    const disabled = props.isLoading;

    if (!props.isDirty) return <CloseButton disabled={disabled} />

    return (
        <Button
            className="btn-secondary px-4 py-2" color="secondary"
            style={{ marginRight: '3px' }}
            disabled={disabled}
            onClick={props.onClick}>
            Cancel
        </Button>
    );
}

//
// Private helper methods
//

function enrichData(fromDate, toDate, overrideData)
{
    const today = dateUtil.getToday();  // UTC today

    for (let date = fromDate; dateUtil.isSameOrBefore(date, toDate); date = dateUtil.addDays(date))
    {
        let overrideItem = overrideData.find(x => x.date == date);

        if (overrideItem == undefined)
        {
            // Server only sends data rows that has atleast either of projected/override value

            overrideItem = {
                date: date,
                totalProjectedGrossSales: null,
                totalOverriddenGrossSales: null
            };

            overrideData.push(overrideItem);
        }

        overrideItem.totalProjectedGrossSales = formatter.convertToAmountFormat(overrideItem.totalProjectedGrossSales, false, 2);
        overrideItem.totalOverriddenGrossSales = formatter.convertToAmountFormat(overrideItem.totalOverriddenGrossSales, false, 2);

        overrideItem.originalTotalOverriddenGrossSales = overrideItem.totalOverriddenGrossSales;    // Used to compare and mark item dirty
        overrideItem.isReadOnly = dateUtil.isBefore(overrideItem.date, today);
    }

    overrideData.sort((v1, v2) => v1.date.localeCompare(v2.date));
}

