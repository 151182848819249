import React from "react";

const LabelPrinterIcon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 141 170" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M25.1393 102.022H115.857C129.128 102.022 136.242 98.3048 137.605 90.6556C138.381 86.2965 137.207 80.6306 134.014 73.3301C131.824 68.3291 128.513 60.7169 124.942 52.4882C124.604 51.7101 123.701 51.3545 122.926 51.6916C122.15 52.031 121.795 52.9361 122.131 53.7142C125.704 61.9452 129.018 69.5644 131.207 74.5653C134.15 81.2887 135.255 86.3727 134.589 90.113C133.912 93.9042 131.237 98.9467 115.857 98.9467H25.1393C9.76173 98.9467 7.08409 93.9065 6.4072 90.113C5.74181 86.3727 6.84694 81.2864 9.78937 74.5653C10.1301 73.7872 9.77785 72.8822 9.00195 72.5405C8.22835 72.1988 7.32353 72.552 6.98279 73.3301C3.78709 80.6283 2.61287 86.2965 3.39108 90.6556C4.75407 98.3025 11.8684 102.022 25.1393 102.022Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M11.5714 68.1947C12.1631 68.1947 12.7249 67.8507 12.9758 67.2735C21.6166 47.429 37.0149 11.8152 37.1692 11.4573C37.1876 11.4158 37.2037 11.3742 37.2152 11.3326C37.2935 11.1156 39.3979 5.62057 49.9634 5.62057H91.0331C101.601 5.62057 103.703 11.1156 103.788 11.3488C103.8 11.3788 103.818 11.4273 103.83 11.4573C103.83 11.4573 109.325 24.1698 115.616 38.6901C115.952 39.4682 116.856 39.826 117.632 39.4866C118.408 39.1495 118.765 38.2422 118.427 37.4641C112.346 23.4286 107.007 11.0833 106.659 10.2752C106.33 9.3909 103.372 2.5452 91.0331 2.5452H49.9634C37.6273 2.5452 34.6688 9.3886 34.3372 10.2729C33.7271 11.6859 18.6719 46.5055 10.1647 66.0406C9.82622 66.8187 10.1808 67.7237 10.9567 68.0631C11.157 68.1509 11.3642 68.1924 11.5691 68.1924L11.5714 68.1947Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path
                    d="M34.6433 91.9212H106.351C114.616 91.9212 119.856 90.0648 122.375 86.2483C124.997 82.2748 124.56 76.512 121.003 68.1147C115.337 54.7442 99.9615 18.0106 99.3836 16.6276C99.0889 15.8241 96.6461 10.1259 86.7298 10.1259H54.2687C44.3524 10.1259 41.9096 15.8218 41.6149 16.6276C41.037 18.0106 25.6617 54.7442 19.9956 68.1147C16.4361 76.5143 16.001 82.2748 18.6234 86.2483C21.1422 90.0648 26.3847 91.9212 34.6479 91.9212H34.6433ZM86.7275 13.2013C94.818 13.2013 96.4366 17.4957 96.5034 17.6781C96.5126 17.7058 96.531 17.7543 96.5425 17.7797C96.7014 18.1584 112.433 55.7509 118.182 69.3153C121.258 76.5766 121.795 81.5591 119.819 84.5513C117.938 87.4004 113.407 88.8458 106.351 88.8458H34.6433C27.5865 88.8458 23.0555 87.4004 21.1744 84.5513C19.2013 81.5591 19.7354 76.5743 22.8114 69.3153C28.5604 55.7509 44.2948 18.156 44.4514 17.7797C44.4675 17.7405 44.4836 17.6989 44.4974 17.6573C44.555 17.4957 46.1736 13.2013 54.2664 13.2013H86.7275Z"
                    fill="#231F20"
                    stroke="black"
                    strokeWidth="3.74999"
               />
               <path
                    d="M21.9745 152.182L22.4373 149.697C23.6345 143.281 25.6445 136.294 28.4142 128.934L28.5132 128.666C30.4656 123.492 32.2661 119.56 33.0466 117.911H107.947C108.742 119.588 110.59 123.626 112.587 128.934C115.352 136.306 117.364 143.292 118.564 149.697L119.029 152.179L121.013 150.625C121.814 149.997 122.648 149.279 123.557 148.432C132.85 139.892 137.634 92.5488 137.832 90.5378L138 88.85H134.816L134.59 90.1153C133.913 93.9064 131.238 98.9489 115.858 98.9489H25.1402C9.76271 98.9489 7.08507 93.9087 6.40816 90.1153L6.18253 88.85H2.99835L3.16643 90.5378C3.36443 92.5488 8.14876 139.892 17.448 148.439C18.3506 149.279 19.184 149.997 19.9852 150.625L21.9722 152.182H21.9745ZM133.985 97.3073C131.979 113.439 127.474 140.665 121.481 146.174C121.338 146.308 121.197 146.437 121.059 146.562C119.8 140.702 117.919 134.42 115.455 127.851C112.755 120.666 110.379 115.887 110.28 115.688L109.856 114.838H31.1402L30.7166 115.688C30.6176 115.889 28.2415 120.668 25.5478 127.846L25.4488 128.114C23.029 134.579 21.1825 140.771 19.9415 146.562C19.8056 146.439 19.6675 146.312 19.5294 146.181C13.5294 140.667 9.02596 113.439 7.01828 97.3073C10.5616 100.47 16.5662 102.022 25.1425 102.022H115.861C124.437 102.022 130.441 100.47 133.985 97.3073Z"
                    fill="#231F20"
                    stroke="black"
                    strokeWidth="3.74999"
               />
               <path d="M112.489 128.391H115.553L115.535 115.72C115.182 113.428 112.79 109.089 106.256 109.089H70.5002V112.162H106.256C111.411 112.162 112.355 115.471 112.489 116.092V128.389V128.391Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M25.4495 128.386H28.514V116.101C28.6637 115.42 29.6422 112.164 34.7419 112.164H70.5023V109.091H34.7419C28.2078 109.091 25.8179 113.43 25.468 115.725L25.4495 115.958V128.386Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path
                    d="M19.5321 167.545C19.5321 167.545 19.539 167.545 19.5436 167.545H121.464C122.313 167.545 123 166.808 123 165.897C123 161.616 122.551 156.878 121.665 151.818C120.429 144.763 118.367 137.086 115.533 129C112.828 121.296 110.448 116.172 110.349 115.959C110.088 115.402 109.558 115.048 108.977 115.048H32.0269C31.4457 115.048 30.9153 115.4 30.6547 115.959C30.5555 116.174 28.1756 121.299 25.4774 128.996C25.4681 129.023 25.4589 129.05 25.452 129.077C22.6293 137.123 20.5745 144.775 19.3476 151.82C18.4759 156.799 18.0285 161.463 18.0123 165.686C18.0031 165.756 18.0008 165.827 18.0008 165.899C18.0008 166.81 18.688 167.548 19.5367 167.548L19.5321 167.545ZM119.905 164.25H21.1026C21.2018 160.614 21.6238 156.645 22.3618 152.427C23.561 145.547 25.5765 138.056 28.3485 130.164C28.3578 130.137 28.367 130.11 28.3762 130.082C30.3595 124.433 32.1975 120.135 32.9909 118.346H108.013C108.808 120.143 110.66 124.473 112.66 130.164C115.429 138.069 117.445 145.56 118.646 152.427C119.384 156.643 119.806 160.611 119.905 164.25Z"
                    fill="#231F20"
                    stroke="black"
                    strokeWidth="3.74999"
               />
               <path d="M107.06 144.296C107.262 145.277 107.458 146.247 107.624 147.195C108.28 150.832 108.727 154.309 108.948 157.564H111.78C111.552 154.184 111.094 150.564 110.419 146.798C110.265 145.974 110.106 145.136 109.929 144.296H107.06Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M95.6455 144.296C95.855 145.277 96.0438 146.247 96.2165 147.195C96.8726 150.832 97.3147 154.309 97.5334 157.564H101.39C101.169 154.311 100.727 150.832 100.066 147.195C99.9003 146.247 99.7046 145.277 99.5019 144.296H95.6455Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M86.6393 144.296C86.8442 145.277 87.0353 146.249 87.2057 147.198C87.8618 150.83 88.3085 154.309 88.5272 157.564H91.3615C91.1358 154.184 90.6753 150.566 89.9984 146.796C89.8511 145.974 89.6876 145.138 89.5126 144.296H86.6439H86.6393Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M77.3185 144.296C77.5234 145.277 77.7145 146.249 77.8918 147.198C78.541 150.83 78.9946 154.309 79.2064 157.564H82.0383C81.8196 154.184 81.3591 150.566 80.6753 146.796C80.5326 145.974 80.3714 145.138 80.1941 144.296H77.3185Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M64.5356 138.383H108.276C109.123 138.383 109.809 137.695 109.809 136.845C109.809 135.996 109.123 135.308 108.276 135.308H64.5356C63.6884 135.308 63.0023 135.996 63.0023 136.845C63.0023 137.695 63.6884 138.383 64.5356 138.383Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M72.0344 129.929H101.093C101.94 129.929 102.626 129.241 102.626 128.391C102.626 127.541 101.94 126.853 101.093 126.853H72.0344C71.1871 126.853 70.501 127.541 70.501 128.391C70.501 129.241 71.1871 129.929 72.0344 129.929Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M32.6198 148.74H54.3795C55.2267 148.74 55.9128 148.052 55.9128 147.202C55.9128 146.353 55.2267 145.665 54.3795 145.665H32.6198C31.7725 145.665 31.0864 146.353 31.0864 147.202C31.0864 148.052 31.7725 148.74 32.6198 148.74Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
               <path d="M33.1096 138.383H54.8693C55.7166 138.383 56.4027 137.695 56.4027 136.845C56.4027 135.996 55.7166 135.308 54.8693 135.308H33.1096C32.2624 135.308 31.5763 135.996 31.5763 136.845C31.5763 137.695 32.2624 138.383 33.1096 138.383Z" fill="#231F20" stroke="black" strokeWidth="3.74999" />
          </svg>
     );
};

export default LabelPrinterIcon;
