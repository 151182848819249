import React, { Component } from 'react';

import Spinner from '../spinner/spinner';
import EmptyData from '../empty/emptyData';
import ErrorMessage from '../error/errorMessage';

export default class DefaultView extends Component
{
    render()
    {
        const { isLoading, error, data } = this.props;

        if (isLoading === true) 
        {
            return (
                <div className='my-3 d-flex justify-content-center align-items-center'>
                    <Spinner text="Loading data, please wait ..." />
                </div>);
        }
        else if (error != null)
        {
            return (
                <div className='my-3 d-flex justify-content-center align-items-center'>
                    <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
                </div>);
        }
        else if (data == null)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="Data required"
                        text="Please choose date range and hit Search to get report data"
                        action={null}
                    />
                </div>);
        }
        else if (Array.isArray(data) && data.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Data Found"
                        text="No report data found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }
        else
        {
            return null;
        }
    }

    static isDefaultView = function (isLoading, error, data)
    {
        return isLoading === true || error != null || data == null || (Array.isArray(data) && data.length === 0);
    }
}