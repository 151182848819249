import React from 'react';
import BIToolBaseComponent from '../common/regularBITools/BIToolBaseComponent';
import BrandWiseTabbedReport from '../../../views/reports/reportUtils/components/BrandWiseTabbedReport';
import { allBrandTotal } from '../../../views/reports/reportUtils/constants';

const stringUtil = require('../../../utils/string/stringUtil');
const reportsLoadingFacade = require('../../../utils/api/reportsLoadingFacade');

class DailySalesRefunds extends BIToolBaseComponent
{
    constructor(props)
    {
        super(props);

        // this.state is initialized by base class

        this.state.stage = 0;
        this.state.entities = [];
        this.state.brands = [];
        this.state.error = null;

        this.getReport = this.getReport.bind(this);
        this.getBrands = this.getBrands.bind(this);
    }

    render()
    {
        var resultComponent = null;

        if (this.state.stage == 0)
        {
            if (!stringUtil.isStringNullOrEmpty(this.state.error))
                resultComponent = this.renderErrorComponent(this.state.error)
            else
                resultComponent = this.renderCaptionComponent("Sales Refunds");
        }

        if (this.state.stage == 1)
        {
            this.loadData();
            resultComponent = this.renderSpinnerComponent("Please Wait");
        }

        if (this.state.stage == 2)
        {
            resultComponent = <BrandWiseTabbedReport brands={this.state.brands} getReport={this.getReport} />
        }

        return <div>
            {this.renderMultiDateSelectionHeaderComponent
                (
                    this.componentName || "Daily Sales Refunds",
                    this.state.stage === 1,
                    true,
                    (searchCriteria) =>
                    {
                        this.state.stage = 1;
                        this.state.dateCriteria = searchCriteria;
                        this.setState({});
                    },
                    () =>
                    {
                        this.state.error = null;
                        this.state.dateCriteria = null;
                        this.state.entities = [];
                        this.state.stage = 0;
                        this.setState({});
                    })}
            {resultComponent}
        </div>;
    }

    getReport(brand)
    {
        var data = this.state.entities;

        if (brand == null)
        {
            data = this.state.entities.filter(e => stringUtil.isStringNullOrEmpty(e.storefrontBrand));
        }
        else if (!stringUtil.areStringSame(brand, allBrandTotal))
        {
            data = this.state.entities.filter(e => stringUtil.areStringSame(e.storefrontBrand, brand));
        }

        return this.renderGrid(this.generateColumnDes(), data);
    }

    generateColumnDes()
    {

        var uniquePropertyNames = [...new Set(this.state.entities.map(e => e.propertyName))];
        var columnDefinitions = [];

        if (uniquePropertyNames.length > 1)
        {
            columnDefinitions.push({ field: 'franchisorName', type: 'rowGroup' });
            columnDefinitions.push({ field: 'propertyName', type: 'rowGroup' });
            columnDefinitions.push({ field: 'refundCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'operator', type: 'rowGroup' });
        }
        else
        {
            columnDefinitions.push({ field: 'propertyName', type: 'rowGroup' });
            columnDefinitions.push({ field: 'refundCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'operator', type: 'rowGroup' });
        }

        columnDefinitions.push({ headerName: 'Total Refund', field: 'totalRefundAmount', type: 'money' });

        columnDefinitions.push({ field: 'saleRefund', type: 'money', });
        columnDefinitions.push({ headerName: 'Tax Rebate', field: 'saleRefundTax', type: 'money', });

        columnDefinitions.push({ field: 'chargeRefund', type: 'money', });
        columnDefinitions.push({ headerName: 'Tax Rebate', field: 'chargeRefundTax', type: 'money', });

        columnDefinitions.push({ field: 'year', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'month', type: 'rowGroupEnabled, month' });
        columnDefinitions.push({ field: 'week', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfMonth', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfWeek', type: 'rowGroupEnabled' });

        return columnDefinitions;
    }

    getBrands(data)
    {
        if (data == null) return;

        let brands = [...new Set(data.map(d => d.storefrontBrand))];
        return brands;
    }

    async loadData()
    {
        const fromDate = this.state.dateCriteria.startDate;
        const toDate = this.state.dateCriteria.endDate;

        try 
        {
            const entities = await reportsLoadingFacade.getRefundsSummary(fromDate, toDate);
            this.setState({ stage: 2, entities: entities, brands: this.getBrands(entities) });
        }
        catch (error) 
        {
            this.setState({ stage: 0, error: error });
        }
    }
}

export default DailySalesRefunds;