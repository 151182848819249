import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

function BlockFranchisor(props)
{

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Blocked"
                            fieldName="isBlocked"
                            comboReadOnly={ false }
                            hintText="Block Franchisor"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                    <td />
                </tr>

                <tr>
                    <td colSpan={2}>
                        <InputComponent inputType="text" multiLine={ true } caption="Blocked Reason" rows={ 4 }
                            fieldName="blockedReason" placeholder="Enter the reason why franchisor is blocked."
                            inputReadOnly={ false }
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>

    )

}
export default BlockFranchisor;