const actionTypes = require('../../actionTypes');

// orgStates

module.exports.createOrgStatesAction = function (orgStates)
{
    return { type: actionTypes.orgStates, payload: new Map(orgStates) };
}

module.exports.changeOrgStatesState = function (previousState, payload)
{
    return Object.assign({}, previousState, { orgStates: payload });
}


// currentOrgNode

module.exports.createCurrentOrgNodeAction = function (selectedOrgNode)
{
    return {
        type: actionTypes.currentOrgNode,
        payload: selectedOrgNode == null
            ? null
            : Object.assign({}, selectedOrgNode)
    };
}

module.exports.changeCurrentOrgNodeState = function (previousState, payload)
{
    return Object.assign({}, previousState, { currentOrgNode: payload });
}