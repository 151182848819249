import React, { Component } from 'react';

import InputComponent from '../../../../components/form/inputComponent.js'
var FormManager = require('../../../../utils/view/formManager.js');

const rotaUtil = require('./RotaUtil');

class RotaDialogue extends Component 
{

    constructor(props) 
    {
        super(props);

        this.state = 
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.state.formManager.onFieldChanged = (event) =>{
                                                                if (this.props.onChange)
                                                                    this.props.onChange();
                                                          }
    }

    get isReadOnly() { return !rotaUtil.isEditingAllowed(); }

    render() 
    {   
            return(
                <table width="100%">

                    <tr>
                        <td width="50%" valign="top">
                            <InputComponent inputType="24Hours" multiLine={true} caption="From" rows={4}
                                            fieldName="start" inputReadOnly={this.isReadOnly} formManager={this.state.formManager}/>
                        </td>

                        <td width="50%" valign="top">
                            <InputComponent inputType="24Hours" multiLine={true} caption="To" rows={4}
                                            fieldName="end" inputReadOnly={this.isReadOnly} formManager={this.state.formManager}/>
                        </td>
                    </tr>

                    <tr>
                        <td width="50%" valign="top">
                            <InputComponent inputType="money" caption="Hourly Wage" hintText="Enter hourly wage (required)" 
                                            style={{marginRight:'20px'}} 
                                            fieldName="hourlyWage" 
                                            placeholder="Hourly" 
                                            inputReadOnly={true}
                                            formManager={this.state.formManager}/>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" valign="top">
                        <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                            fieldName="description" placeholder="Enter any notes."
                                            inputReadOnly={this.isReadOnly} formManager={this.state.formManager}/>
                        </td>
                    </tr>
                </table>
            )
    }
}

export default RotaDialogue;

