import React, { Component } from 'react';
import { Label } from 'reactstrap';
import Select from 'react-select';

const constants = require('../../../../utils/domain/constants');

const separator = " / "
const possibleEncodings = [{
    encodingType:   constants.printerEncodings.sunmi.encodingType,
    encodingInstructions: constants.printerEncodings.sunmi.encodingInstructions,
}]

class SelectEncodingView extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = {};
        this.onEncodingChanged = this.onEncodingChanged.bind(this);
    }

    render()
    {
        let value = "";

        if(this.props.formManager.viewModel.encodingType && this.props.formManager.viewModel.encodingInstructions)
        {
            value = this.getValue(this.props.formManager.viewModel.encodingType, this.props.formManager.viewModel.encodingInstructions);
        }

        const encodingTypeOptions = possibleEncodings.map(possibleEncoding => ({
            label: this.getValue(possibleEncoding.encodingType, possibleEncoding.encodingInstructions),
            value: this.getValue(possibleEncoding.encodingType, possibleEncoding.encodingInstructions)
        }))

        return <div className='w-100'>
            <Label className='pt-1 me-2 text-nowrap'>Select Encoding Type:</Label>
            <Select
                className="w-100"
                clearable={!this.props.inputReadOnly}
                value={value}
                disabled={this.props.inputReadOnly}
                options={encodingTypeOptions}
                onChange={option => this.onEncodingChanged(option)} />
        </div>
    }

    getValue(encodingType, encodingInstructions)
    {
        return `${encodingType}${separator}${encodingInstructions}`;
    }

    onEncodingChanged(option)
    {
        const encoding = option ? option.value : "";
        if(encoding)
        {
            const encodingSplit = encoding.split(separator);
            this.props.formManager.viewModel.encodingType = encodingSplit[0];
            this.props.formManager.viewModel.encodingInstructions = encodingSplit[1];
        }
        else
        {
            this.props.formManager.viewModel.encodingType = "";
            this.props.formManager.viewModel.encodingInstructions = "";
        }

        this.setState({});
    }
}

export default SelectEncodingView;