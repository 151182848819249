import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent'
import SelectComponent from '../../../../../components/form/selectComponent'
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent'

import * as FormManager from '../../../../../utils/view/formManager';

class CreditAccount extends Component 
{
    constructor(props) 
    {
        super(props);

        this.props.data.isDisabled = this.props.data.isDisabled || false;

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    render() 
    {
        const creditBooks = this.props.creditBooks.map(creditBook => ({ name: creditBook.name, id: creditBook.id }));

        return (
            <table className="component-table">

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Credit Account Name"
                            hintText="Required"
                            fieldName="name"
                            placeholder="Enter name of the credit account"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                    <td>
                        <SelectComponent
                            caption="Credit Book"
                            fieldName="creditBookId"
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            optionValues={creditBooks}
                            hintText="Select credit book for the credit account (Required)"
                            clearable={false}
                            comboReadOnly={this.props.isRowReadOnly || !this.props.isNew}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <InputComponent
                            inputType="text"
                            caption="Credit Account Description"
                            hintText="Required"
                            fieldName="description"
                            placeholder="Enter text describing the credit account"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="money"
                            caption="Credit Limit"
                            hintText="Required"
                            fieldName="creditLimit"
                            placeholder="Enter credit limit for the account"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Disabled"
                            fieldName="isDisabled"
                            hintText="Disable credit account"
                            clearable={false}
                            comboReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            multiLine={true}
                            caption="Notes"
                            rows={4}
                            fieldName="notes"
                            placeholder="Enter any notes"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

            </table>
        )
    }
}

export default CreditAccount;