import React from 'react';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

const domainConstants = require('../../../../../../utils/domain/constants');

export default function PropertyOperatingConfigurationComponent(props)
{

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Can Staff Login Via Pin"
                            fieldName="canStaffLoginViaPin"
                            comboReadOnly={ false }
                            hintText="Optional"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Can Staff ClockIn Via Pin"
                            fieldName="canStaffClockInViaPin"
                            comboReadOnly={ false }
                            hintText="Optional"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Can Staff Issue Discount Via Pin"
                            fieldName="canStaffIssueDiscountViaPin"
                            comboReadOnly={ false }
                            hintText="Optional"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Can Staff Refund Via Pin"
                            fieldName="canStaffRefundViaPin"
                            comboReadOnly={ false }
                            hintText="Optional"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                    
                </tr>

                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Can Staff Cancel Order Via Pin"
                            fieldName="canStaffCancelOrderViaPin"
                            comboReadOnly={ false }
                            hintText="Optional"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                    <td>
                        <SelectComponent
                            optionValues={domainConstants.getAttendanceAccessFlags()}
                            optionFieldName="key"
                            optionDisplayFieldName="value"
                            clearable={false}
                            comboReadOnly={false}
                            fieldName="attendanceAccess"
                            caption="When attendance is accessible to the staff"
                            hintText="Choose when attendance is accessible to the staff"
                            formManager={props.formManager}
                        />
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
