import React from 'react';
import { Button } from 'reactstrap';

const actionTypes = require('../../utils/state/actionTypes');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const commonUtility = require('../../utils/domain/commonUtility');
const constants = require('../../utils/domain/constants');

export default function CloseButton(props)
{
    const onClick = () =>
    {
        const currentOptionAction = {
            type: actionTypes.mainBarComponent,
            payload: null
        };

        actionDispatcher.dispatch(currentOptionAction);
    };

    return (
        commonUtility.getApplicationMode() === constants.applicationModes.embedded 
        ?   null
        :   <Button
                className="px-4" color="dark"
                disabled={props.disabled}
                onClick={onClick}>
                <span><i className="fa fa-sign-out" />&nbsp;Close</span>
            </Button>
    );
}