import React, {Component} from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

const FormManager = require('../../../../../../utils/view/formManager.js');

export default class PropertyAddressConfigurationComponent extends Component
{
    
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager()
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    render()
    {
        return (

            <table className="component-table">
                <tbody>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                fieldName="address"
                                caption="Address"
                                placeholder="Enter Address."
                                multiLine={true} rows={2}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption="Is Excluded"
                                fieldName="isExcluded"
                                hintText="Optional"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent
                                inputType="number"
                                fieldName="deliveryCharges"
                                placeholder="Enter Delivery Charges"
                                caption="Delivery Charges"
                                hintText="Optional"
                                formManager={ this.state.formManager} />
                        </td>
                    </tr>

                </tbody>
            </table>
        )
    }

}
