import React from 'react';
import { Button } from 'reactstrap';

import ExternalIntegrationLogsForm from './externalIntegrationLogsForm';

import * as arraySort from 'array-sort';
import * as rmsApiProxy from '../../utils/api/rmsApiProxy';
import * as viewUtil from '../../utils/view/viewUtil';
import * as currentOrgNodeSelectors from '../../utils/state/stateSelectors/currentOrgNodeSelectors';

export default function ExternalIntegrationLogsButton(props)
{
    const onClick = async () => 
    {
        try
        {
            // Fetch integrations logs by props.params

            if (props.params == null) return;

            viewUtil.showSystemModalSpinner("Loading external integration logs ...");

            const resourceUrl = `integrations/logs`;
            const integrationLogs = await rmsApiProxy.put(resourceUrl, props.params);

            viewUtil.closeModalSpinner();

            // Show ExternalIntegrationLogsForm component through readonly model form

            viewUtil.openModalForm(
                "External Integration Logs",
                () => <ExternalIntegrationLogsForm integrationLogs={arraySort(integrationLogs, "dateTime", { reverse: true })} />,
                null, null, true, null);
        }
        catch (error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(error.toString());
        }
    }

    return (
        <Button color="primary" disabled={props.disabled} onClick={onClick}>
            <i className="fa fa-list"></i>&nbsp;External Integration Logs
        </Button>);
}