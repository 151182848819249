
import React, { Component } from 'react';
import {Button } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import InputComponent from "../../../../../../components/form/inputComponent";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ErrorMessage from '../../../../../../components/error/errorMessage';
import Jumbotron from "../../../../../../components/jumbotron/jumbotron";

const guidUtil = require('../../../../../../utils/guid/guidUtil.js');
const stringUtil = require('../../../../../../utils/string/stringUtil.js');
const FormManager = require("../../../../../../utils/view/formManager.js");

class FloorManagementDialogue extends Component
{
    constructor(props)
    {
        super(props);
        this.renderFloorList = this.renderFloorList.bind(this);
        this.renderFloorConfiguration = this.renderFloorConfiguration.bind(this);
        this.renderOverviewConfiguration = this.renderOverviewConfiguration.bind(this);
        this.renderAvailabilityComponent = this.renderAvailabilityComponent.bind(this);
        this.renderAlertsComponent = this.renderAlertsComponent.bind(this);

        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onMoveDown = this.onMoveDown.bind(this);
        this.onMoveUp = this.onMoveUp.bind(this);

        this.validateFloors = this.validateFloors.bind(this);

        this.state = {};
        this.state.selectedFloorId = this.props.selectedFloorId;
        this.state.errorMessage = null;
    }

    render()
    {
        var deleteButtonDisabled = false;
        if (this.props.layout.floors.length <= 1)
            deleteButtonDisabled = true;

        var errorComponent = null;
        if (this.state.errorMessage != null)
        {
            errorComponent = <ErrorMessage message={this.state.errorMessage} />
        }

        return(<Modal open={true} onClose={this.onClose} center closeOnOverlayClick={false} showCloseIcon={false} closeOnEsc={false}
                      classNames={{
                                    overlay: 'customOverlay',
                                    modal: 'customModal',
                                }}>


                    <table width="100%">
                        <tr>
                            <td>
                                {errorComponent}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                            {this.renderFloorList()}
                                        </td>
                                   
                                        <td>
                                            <div style={{marginLeft:'1px', marginRight:'5px'}}>
                                                <Button style={{marginLeft:'2px', marginRight:'5px'}} onClick={this.onAdd}><i className="fa fa-plus" aria-hidden="true"></i></Button>
                                            </div>
                                            <div style={{marginLeft:'1px', marginRight:'20px', marginTop:'2px'}}>
                                                <Button disabled={deleteButtonDisabled} style={{marginLeft:'2px', marginRight:'5px'}} onClick={this.onDelete}><i className="fa fa-minus" aria-hidden="true"></i></Button>
                                            </div>
                                            <div style={{marginLeft:'1px', marginRight:'20px', marginTop:'20px'}}>
                                                <Button style={{marginLeft:'2px', marginRight:'5px'}} onClick={this.onMoveUp}>▲</Button>
                                            </div>
                                            <div style={{marginLeft:'1px', marginRight:'20px', marginTop:'2px'}}>
                                                <Button style={{marginLeft:'2px', marginRight:'5px'}} onClick={this.onMoveDown}>▼</Button>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{border:'1px solid #D0D0D0', height:'400px', minWidth:'600px', padding:'5px'}}>
                                                {this.renderFloorConfiguration()}
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td align="right">
                                <Button color="primary" className="btn-primary" style={{marginTop:'0px', minWidth:'100px'}} onClick={()=>
                                    {
                                        this.validateFloors();
                                        if (this.state.errorMessage != null)
                                            return;

                                        this.props.onSave();
                                    }
                                    }>
                                        Save
                                </Button>

                            
                            </td>
                        </tr>
                    </table>

                
                </Modal>);
    }

    renderFloorList()
    {
        var components = [];

        this.props.layout.floors.forEach (floor =>
        {
            var backgroundColor = '#E8E8E8';
            if (this.state.selectedFloorId == floor.id)
                backgroundColor = '#A0CFEC';

            components.push(<div style={{margin:'auto',background:backgroundColor, marginBottom:'5px', marginLeft:'5px', marginRight:'5px', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'5px', paddingRight:'5px'}}
                                onClick={()=>{
                                                this.state.selectedFloorId = floor.id;
                                                this.setState({});
                                             }}>
                                {floor.title}
                            </div>)
        })

        return (<div style={{border:'1px solid #D0D0D0', height:'400px', width:'300px', paddingTop:'5px', overflowY:'auto'}}>
                    {components}
                </div>);
    }

    renderFloorConfiguration()
    {
        var tabHeaders = [];
        tabHeaders.push(<Tab>Overview</Tab>)
        tabHeaders.push(<Tab>Availability</Tab>)
      
        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderOverviewConfiguration()}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderAvailabilityComponent()}</TabPanel>);
      
        return (<Tabs>
                    <TabList>
                        {tabHeaders}
                    </TabList>

                    {tabContents}
                </Tabs>);
    }

    renderOverviewConfiguration()
    {
        var layout = this.props.layout;
        var floor = layout.floors.find(f =>{return f.id == this.state.selectedFloorId});

        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = floor;
        this.state.formManager.view = this;
        this.state.formManager.onFieldChanged = ()=>{
                                                        this.state.errorMessage = null;
                                                        this.setState({});
                                                    }
    
        return (<table width="100%">
                    <tr>
                        <td>  
                            <InputComponent
                                inputType="name"
                                fieldName="title"
                                placeholder="Enter Zone/Floor Name"
                                caption="Zone Name"
                                hintText = "Enter name you want to identify this zone / floor"
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr>
                </table>)
    }

    

    renderAvailabilityComponent()
    {
        return <Jumbotron>
                <h3>Seating Availability!</h3>
                <p className="lead">Here you can set availability of this zone. e.g. This zone is only available on Weekend Evenings during busy hours</p>
                <hr className="my-2" />
                <p>This feature will be enabled in future release. </p>
            </Jumbotron>
    }

    renderAlertsComponent()
    {
        return <Jumbotron>
                    <h3>Alert Configuration!</h3>
                    <p className="lead">Here you can set various different alerts for this zone</p>
                    <hr className="my-2" />
                    <p>This feature will be enabled in future release. </p>
                </Jumbotron>
    }

    onDelete()
    {
        var layout = this.props.layout;
        if (layout.floors.length <= 1)
            return;

        var selectedIndex = layout.floors.findIndex(f =>{return f.id == this.state.selectedFloorId});
        layout.floors = layout.floors.filter(f => this.state.selectedFloorId != f.id);
        
        if (selectedIndex >= layout.floors.length)
            selectedIndex = selectedIndex -1;
        
        this.state.selectedFloorId = layout.floors[selectedIndex].id;
        this.setState({});
    }

    onAdd()
    {
        var layout = this.props.layout;

        var floor = {};
        floor.id = guidUtil.generateGuid();
        floor.title = "New Zone " + (layout.floors.length + 1);
        floor.stencils = [];
        layout.floors.push(floor);

        this.state.selectedFloorId = floor.id;
        this.setState({});
    }

    onMoveUp()
    {
        var layout = this.props.layout;
        var selectedIndex = layout.floors.findIndex(f =>{return f.id == this.state.selectedFloorId});
        if (selectedIndex-1 < 0)
            return;
        
        
        var copy = layout.floors[selectedIndex-1];
        layout.floors[selectedIndex-1] = layout.floors[selectedIndex];
        layout.floors[selectedIndex] = copy;
        this.setState({});
        
    }

    onMoveDown()
    {
        var layout = this.props.layout;
        var selectedIndex = layout.floors.findIndex(f =>{return f.id == this.state.selectedFloorId});
        if (selectedIndex+1 >= layout.floors.length)
            return;
        
        var copy = layout.floors[selectedIndex+1];
        layout.floors[selectedIndex+1] = layout.floors[selectedIndex];
        layout.floors[selectedIndex] = copy;
        this.setState({});
    }

    validateFloors()
    {
        this.state.errorMessage = null;

        var layout = this.props.layout;  
        layout.floors.forEach(floor =>
        {
            if (this.state.errorMessage == null && stringUtil.isStringNullOrEmpty(floor.title))
            {
                this.state.errorMessage = "Floor Name cannot be left empty";
                this.state.selectedFloorId = floor.id;
            }
        });      

        this.setState({});
    }
}

export default FloorManagementDialogue;