import React, { useState } from "react";
import CdnImageUploader from '../../../../../components/cdnImage/cdnImageUploader'

const configurationManager = require('../../../../../utils/config/configurationManager');

export default function SupplierLogoProfile(props)
{
    const supplierId = props.data.id;

    const franchisorId = props.data.franchisorId;
    const franchiseeId = props.data.franchiseeId;
    const isReadOnly = props.isRowReadOnly;

    var logoImageFolder;

    if(franchiseeId)
        logoImageFolder = `franchisors/${franchisorId}/franchisees/${franchisorId}/suppliers/${supplierId}/logo`;
    else 
        logoImageFolder = `franchisors/${franchisorId}/suppliers/${supplierId}/logo`;

    //
    // Uses react hooks
    // Following line creates a new state variable "logoImageUrl" and a reference to 
    // a method "setLogoImageUrl" that can be used to change this state variable
    //

    const [logoImageUrl, setLogoImageUrl] = useState(props.data.logoImageUrl);

    return (

        <CdnImageUploader
            cloudName={configurationManager.getConfig().cdnUsersCloudName}
            imageFolder={logoImageFolder}
            imageUrl={logoImageUrl}
            isReadOnly={isReadOnly}
            onImageChange={url =>
            {
                props.data.logoImageUrl = url;
                setLogoImageUrl(url);   // changes state
            }}>

            <h4 className="display-5">No Image Found</h4>
            <p className="lead">A logo is much more than just an image; it is a point of recognition for clients and
                an important foundation for the branding of your property.</p>
            <hr className="my-2" />
            <p>A well-designed logo is an easy way to convey to potential customers that your business is professional,
                trustworthy, and provides quality goods or services.</p>

        </CdnImageUploader>

    );
}