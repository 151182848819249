import React, { Component } from 'react';
import SelectComponent from '../../../../../../components/form/selectComponent';

const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class OperatingConfigurationRewards extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        return (            
            
            <table className='component-table'>
                <tr>
                    <td>
                        <SelectComponent
                            caption="Rewards Menu"
                            fieldName="rewardMenuId"
                            optionFieldName="menuId"
                            optionDisplayFieldName="name"
                            optionValues={this.props.menuHeaders}
                            placeholder="Choose Reward Menu"
                            clearable={!this.isReadOnly}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <SelectComponent
                            caption="Price Band"
                            fieldName="rewardPriceBandId"
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            optionValues={this.props.priceBands}
                            placeholder="Choose Reward Menu Price Band"
                            clearable={!this.isReadOnly}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
            </table>
        );
    }    
}

export default OperatingConfigurationRewards;