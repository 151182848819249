
const formatter = require('../formatter/formatter');
const localizationUtils = require('../domain/localizationUtils.js');
const stringUtil = require('../string/stringUtil');
const validator = require('../validator/validator');

module.exports.tables =
{
    /**************** SALES BI Tool *************************************************/
    dailySystemSalesStats: 'DailySystemSalesStats',
    dailySales: 'DailySales',
    dailySalesByVenueAndPaymentMethod: 'DailySalesByVenueAndPaymentMethod',
    hourlySales: 'HourlySales',
    hourlySalesByVenue: 'HourlySalesByVenue',
    dailySalesByTable: "DailySalesByTable",
    hourlySalesByTable: "HourlySalesByTable",
    dailySalesVsProjections: "DailySalesVsProjections",
    dailyCustomerRetention: "DailyCustomerRetention",
    dailyDiscountsByVenue: "DailyDiscountsByVenue",
    dailyRefundsByVenue: "DailyRefundsByVenue",
    dailySalesByVenueAndMileage: "DailySalesByVenueAndMileage",


    /**************** MENU BI Tool *************************************************/
    dailySalesByVenueAndMenuItem: "DailySalesByVenueAndMenuItem",
    dailySalesByVenueAndReportingTag: "DailySalesByVenueAndReportingTag",
    dailyRefundsByVenueAndMenuItem: "DailyRefundsByVenueAndMenuItem",




    /**************** INVENTORY BI Tool *************************************************/
    dailySalesByProduct: "DailySalesByProduct",
    dailyCogs: "DailyCogs",
    stockItemWastages: "StockItemWastages",


    /**************** Payroll BI Tool *************************************************/
    dailyUpsellingPoints: "DailyUpsellingPoints",
    dailyPayrollCosts: "DailyPayrollCosts",
    dailySalesByDriver: "DailySalesByDriver",
    dailyStaffMeals: "DailyStaffMeal",
    dailyGuestMeals: "DailyGuestMeal",
    dailyFreebiesMeals: "DailyFreebiesMeal",


    /**************** Accounts BI Tool *************************************************/
    dailyFixedCostsTable: "DailyFixedCosts",
    dailyRoyaltyByVenue: "DailyRoyaltyByVenue",
}

module.exports.roundTo = function (value, digits = 2)
{
    return formatter.roundUp(value, digits);
}

module.exports.formatAmount = function (param)
{
    var resolvedValue = extractValueFromParams(param);
    if (resolvedValue == null)
        return null;

    return formatter.convertToAmountFormat(resolvedValue, true);
}

module.exports.formatCurrencyValue = function (param)
{
    var resolvedValue = extractValueFromParams(param);
    if (resolvedValue == null)
        return null;

    return formatter.convertToCurrencyFormat(resolvedValue, true);
}

module.exports.formatPercentage = function (param)
{
    var resolvedValue = extractValueFromParams(param);
    if (resolvedValue == null)
        return null;

    // We use percentage = -1 for various flags
    // e.g. to display vat percentage for complimentary saleItems where saleItem.unitPrice = 0 but saleItem.vat > 0

    if (validator.isNumeric(resolvedValue) && resolvedValue === -1)
        return "Others";

    return `${module.exports.formatAmount(resolvedValue)}%`
}

module.exports.formatAmountNAValue = function (value)
{
    if (value == null || isNaN(value) || !isFinite(value)) return 'N/A';

    return formatter.convertToAmountFormat(value, true);
}

module.exports.formatCurrencyNAValue = function (value)
{
    if (value == null || isNaN(value) || !isFinite(value)) return 'N/A';

    return formatter.convertToCurrencyFormat(value, true);
}

module.exports.formatPercentageNAValue = function (value)
{
    if (value == null || isNaN(value) || !isFinite(value)) return 'N/A';

    return `${formatter.convertToAmountFormat(value, true)}%`;
}

module.exports.formatGSTCaption = function ()
{
    return localizationUtils.getVatCaption();
}

module.exports.formatMonth = function (param)
{
    var resolvedValue = extractValueFromParams(param);
    if (resolvedValue == null)
        return null;

    var month = resolvedValue;
    if (stringUtil.isStringNullOrEmpty(month))
        return null;

    month = month * 1;

    if (month == 0)
        return "Jan";

    if (month == 1)
        return "Feb";

    if (month == 2)
        return "Mar";

    if (month == 3)
        return "Apr";

    if (month == 4)
        return "May";

    if (month == 5)
        return "Jun";

    if (month == 6)
        return "Jul";

    if (month == 7)
        return "Aug";

    if (month == 8)
        return "Sep";

    if (month == 9)
        return "Oct";

    if (month == 10)
        return "Nov";

    if (month == 11)
        return "Dec";

    return null;
}

function extractValueFromParams(param)
{
    if (param == null)
        return null;

    if (param instanceof Object)
        return extractValueFromParams(param.value);

    return param;
}

module.exports.columnTypes = {

    // Use colDef.type = 'comma separated list' to apply multiple columnTypes to a colDef

    id: { hide: true, suppressColumnsToolPanel: true },   // hide column from grid and column menu

    rowGroup: {
        chartDataType: 'category',
        enablePivot: true,
        enableRowGroup: true,
        rowGroup: true,
        hide: true
    },

    rowGroupEnabled: {
        chartDataType: 'category',
        enablePivot: true,
        enableRowGroup: true,
        hide: true
    },

    month: {
        chartDataType: 'category',
        valueFormatter: params => this.formatMonth(params),
        cellStyle: () => ({ justifyContent: "center" })
    },

    date: {
        chartDataType: 'category',
        filter: 'agDateColumnFilter',
        cellStyle: () => ({ justifyContent: "center" }),
    },

    money: {
        chartDataType: 'series',
        valueFormatter: params => this.formatCurrencyValue(params),
        cellStyle: () => ({ justifyContent: "end" }),
        filter: 'agNumberColumnFilter',
        aggFunc: 'sum'
    },

    number: {
        chartDataType: 'series',
        valueFormatter: params => this.formatAmount(params),
        cellStyle: () => ({ justifyContent: "end" }),
        filter: 'agNumberColumnFilter',
        aggFunc: 'sum'
    },

    floatNumber: {
        chartDataType: 'series',
        valueFormatter: params => this.formatAmount(params),
        cellStyle: () => ({ justifyContent: "end" }),
        filter: 'agNumberColumnFilter',
        aggFunc: 'sum'
    },

    fixedNumber: {
        chartDataType: 'series',
        valueFormatter: params => this.formatAmount(params),
        cellStyle: () => ({ justifyContent: "end" }),
        filter: 'agNumberColumnFilter',
        aggFunc: 'sum'
    },

    percentage: {
        chartDataType: 'series',
        valueFormatter: params => this.formatPercentage(params),
        cellStyle: () => ({ justifyContent: "center" }),
        filter: 'agNumberColumnFilter',
        aggFunc: 'avg'
    },

    notes: {
        minWidth: 500,
        suppressSizeToFit: false    // TODO: not working ??
    },
}

module.exports.defaultColDef = {
    sortable: true,
    filter: true,
    filterParams: { buttons: ['reset'] },
    menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab'],
    resizable: true,
    suppressSizeToFit: true,
    headerComponentParams: {
        menuIcon: 'fa-bars'
    },
    cellClassRules: {
        'ignis-bi-pinned-row': params => params.node.rowPinned
    }
}

module.exports.autoGroupColumnDef = {
    headerName: 'Group',
    minWidth: 300,
    cellRendererParams: {
        suppressCount: true,
    }
};

module.exports.gridOptions = {

    animateRows: true,

    showOpenedGroup: true,
    rowGroupPanelShow: 'always',
    suppressAggFuncInHeader: true,

    groupDisplayType: 'singleColumn',
    autoGroupColumnDef: this.autoGroupColumnDef,

    enableCharts: true,
    enableRangeSelection: true,

    domLayout: 'autoHeight',
    rowSelection: "multiple",
    defaultColDef: this.defaultColDef,
    columnTypes: this.columnTypes,

    popupParent: document.body,

    statusBar: {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'center',
            },
            {
                statusPanel: 'agAggregationComponent',
                align: 'right',
            }
        ]
    },

    sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                minWidth: 225,
                width: 225,
                maxWidth: 225,
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                minWidth: 180,
                maxWidth: 400,
                width: 250,
            },
        ],
        position: 'right'
    },
}

module.exports.validateComponentName = (componentName) =>
{
    if (stringUtil.isStringNullOrEmpty(componentName))
        return "Please use a valid name";

    if (!/^[0-9a-zA-Z+-.,\s]+$/.test(componentName))
        return "Please use only alphanumeric characters (a-z 0-9 -+.,)";

    if (componentName.length > 40)
        return "Please restrict name to not more than 40 characters";

    return null;
}

module.exports.createChartContainer = (chartRef) =>
{
    //
    // Ag-grid chart container rendered below the grid by pattern
    //

    const eParent = document.querySelector('#grid_chart_container');

    if (eParent == null)
    {
        // Pattern requires that the grid be wrapped in a div with id=grid_chart_container
        throw new Error('DOM div element with id=grid_chart_container is required');
    }

    const localNow = new Date();

    const chartTitle = `Created at: ${localNow.toLocaleDateString()} ${localNow.toLocaleTimeString()}`;

    const chartPanelTemplate =
        '<div class="chart-wrapper ag-theme-alpine pt-2 bg-light">' +
        '   <div class="chart-wrapper-top bg-white d-flex justify-content-between pt-3 px-3">' +
        '       <span class="chart-wrapper-title fw-bold"></span>' +
        '       <button type="button" class="chart-wrapper-close btn btn-dark">Close Chart</button>' +
        '   </div>' +
        '   <div class="chart-wrapper-body"></div>' +
        '</div>';

    const eChart = chartRef.chartElement;

    const eTemp = document.createElement('div');
    eTemp.innerHTML = chartPanelTemplate;

    const eChartWrapper = eTemp.firstChild;

    eParent.appendChild(eChartWrapper);

    eChartWrapper.querySelector('.chart-wrapper-body').appendChild(eChart);
    eChartWrapper.querySelector('.chart-wrapper-title').innerText = chartTitle;
    eChartWrapper
        .querySelector('.chart-wrapper-close')
        .addEventListener('click', function ()
        {
            chartRef.destroyChart();
            eParent.removeChild(eChartWrapper);
        });
}

module.exports.getGridOptionsForMasterRows = () => ({

    animateRows: true,
    domLayout: 'autoHeight',
    popupParent: document.body,

    keepDetailRows: true,
    detailRowAutoHeight: true,
    embedFullWidthRows: false,

    enableCharts: true,
    enableRangeSelection: true,

    rowHeight: 40,
    rowSelection: "multiple",

    pagination: false,
    paginationAutoPageSize: false,
    paginationPageSize: undefined,

    columnTypes: this.columnTypes,

    defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        filterParams: { buttons: ['reset'] },
        menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab'],
        resizable: true,
        suppressSizeToFit: true,
        cellClassRules: {
            'ignis-bi-pinned-row': params => params.node.rowPinned
        }
    },

    statusBar: {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'center',
            },
            {
                statusPanel: 'agAggregationComponent',
                align: 'right',
            }
        ]
    },
});

module.exports.getGridOptionsForDetailRows = () => ({

    animateRows: true,
    popupParent: document.body,

    enableCharts: true,
    enableRangeSelection: true,

    rowHeight: 40,
    rowSelection: "multiple",

    pagination: false,
    paginationAutoPageSize: false,
    paginationPageSize: undefined,

    columnTypes: this.columnTypes,

    defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClassRules: {
            'ignis-bi-pinned-row': params => params.node.rowPinned
        }
    },
});

module.exports.cellStyleHelper = params => validator.isNumeric(params.value) ? { justifyContent: "end" } : null;
module.exports.valueFormatterHelper = params =>
{
    if (validator.isNumeric(params.value))
    {
        if (params.data.dataType != null)
        {
            if (params.data.dataType === 'money') return this.formatCurrencyValue(params.value);
            if (params.data.dataType === 'percentage') return this.formatPercentage(params.value);
        }
        else if (params.colDef != null && params.colDef.type != null)
        {
            if (params.colDef.type === 'money') return this.formatCurrencyValue(params.value);
            if (params.colDef.type === 'percentage') return this.formatPercentage(params.value);
        }
    }

    return params.value;
};

module.exports.defaultMasterGridPageSize = 10;
module.exports.defaultDetailGridPageSize = 10;

module.exports.getMasterGridPaginationSettings = (dataSize) =>
{
    let pagination = false, paginationPageSize = undefined;

    if (dataSize > this.defaultMasterGridPageSize)
    {
        pagination = true;
        paginationPageSize = this.defaultMasterGridPageSize;
    }

    return { pagination, paginationPageSize };
}

module.exports.getDetailGridPaginationSettings = (dataSize) =>
{
    let pagination = false, paginationPageSize = undefined;

    if (dataSize > this.defaultDetailGridPageSize)
    {
        pagination = true;
        paginationPageSize = this.defaultDetailGridPageSize;
    }

    return { pagination, paginationPageSize };
}

module.exports.getKPIIndex = (dataType) =>
{
    const wages = 15;
    const cogs = 10;
    const venueCommission = 10;
    const fixedCost = 10;
    const discount = 10;
    const refund = 10;
    const cost = wages + cogs + venueCommission + fixedCost;
    const profit = 100 - cost;

    switch (dataType)
    {
        case 'wages':
            return wages;
        case 'cogs':
            return cogs;
        case 'venueCommission':
            return venueCommission;
        case 'fixedCost':
            return fixedCost;
        case 'discount':
            return discount;
        case 'refund':
            return refund;
        case 'profit':
            return profit;
        case 'cost':
            return cost;
        default:
            throw new Error(`Invalid dataType '${dataType}'`);
    }
}

