import React, { Component } from "react";
import Select from 'react-select';

import MultiSelectComponent from '../../../../components/form/multiSelectComponent';

const securityConstants = require('../../../../utils/domain/security/securityConstants');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const helperUtils = require('../utils/helperUtils');

export default class UserRole extends Component
{
    get aggregatedRoles() { return this.props.aggregatedRoles; }
    get aggregatedRole() { return this.props.aggregatedRole; }
    get isRowReadOnly() { return this.props.isRowReadOnly; }

    onOrganizationChanged = (selectedOption) =>
    {
        const { orgName, orgContext } = selectedOption.orgNode;

        if (this.aggregatedRole.orgName === orgName && helperUtils.areSameOrgContexts(this.aggregatedRole.orgContext, orgContext))
        {
            return; // No change, ignore
        }

        this.aggregatedRole.orgName = orgName;
        this.aggregatedRole.orgContext = orgContext;

        // Reset roles on org change
        this.aggregatedRole.roleItems.length = 0;

        this.props.onChange();
    }

    onRolesChanged = (selectedRoleItems) =>
    {
        if (selectedRoleItems.length > 0)
        {
            // Exclude sub roles when one of the super exclusive role is selected in selectedRoleItems

            const { systemRoles, franchisorRoles, propertyRoles } = securityConstants.builtInUserRoles;

            if (selectedRoleItems.some(roleItem => roleItem.roleCode === systemRoles.SystemAdmin.roleCode)) 
            {
                selectedRoleItems = [systemRoles.SystemAdmin];
            }
            else if (selectedRoleItems.some(roleItem => roleItem.roleCode === systemRoles.SupportAdmin.roleCode)) 
            {
                selectedRoleItems = [systemRoles.SupportAdmin];
            }
            else if (selectedRoleItems.some(roleItem => roleItem.roleCode === franchisorRoles.Owner.roleCode)) 
            {
                selectedRoleItems = [franchisorRoles.Owner];
            }
            else if (selectedRoleItems.some(roleItem => roleItem.roleCode === propertyRoles.StoreOwner.roleCode)) 
            {
                selectedRoleItems = [propertyRoles.StoreOwner];
            }
        }

        this.aggregatedRole.roleItems = selectedRoleItems;

        this.props.onChange();
    }

    render()
    {
        return (
            <div className="d-flex flex-row flex-nowrap">

                <div className="w-50 me-3">
                    <div className="d-flex flex-column">
                        <div className="mb-2">Select Organization</div>
                        <div>
                            <Select
                                value={this.aggregatedRole.orgName}
                                onChange={this.onOrganizationChanged}
                                clearable={false}
                                disabled={this.isRowReadOnly}
                                options={helperUtils
                                    .getAvailableOrgNodes(this.aggregatedRoles, this.aggregatedRole)
                                    .map(orgNode => ({
                                        label: orgNode.orgName,
                                        value: orgNode.orgName,
                                        orgNode: orgNode
                                    }))}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-50 me-3">
                    <MultiSelectComponent
                        caption="Select Roles"
                        valueFieldName="roleCode"
                        displayFieldName="displayName"
                        options={this.getAvailableRoles()}
                        selectedOptions={this.aggregatedRole.roleItems}
                        onSelectionChanged={this.onRolesChanged}
                        clearable={false}
                        comboReadOnly={this.isRowReadOnly}
                    />
                </div>

                <div className={`d-flex align-items-center opacity-${this.isRowReadOnly ? "50" : "100"}`}>
                    <span
                        className="h4 text-danger"
                        style={{ cursor: this.isRowReadOnly ? null : "pointer" }}
                        onClick={this.isRowReadOnly ? null : () => this.props.onDelete(this.aggregatedRole)}>
                        <i className="pt-3 fa fa-remove" />
                    </span>
                </div>

            </div>
        );
    }

    getAvailableRoles = () =>
    {
        // Returns roleItems array = [{roleCode, displayName},...]

        if (this.aggregatedRole.orgContext == null) return [];  // No available roles can be inferred until orgContext is selected

        const passport = helperUtils.getPassport();
        const { franchisorId, propertyId } = this.aggregatedRole.orgContext;
        const { systemRoles, franchisorRoles, propertyRoles } = securityConstants.builtInUserRoles;

        const availableRoles = [];

        if (propertyId)
        {
            // Implies property orgContext selected, return property roles

            availableRoles.push(...this.getAllowedPropertyRolesByPassport(passport));

            if (this.aggregatedRole.roleItems.some(roleItem => roleItem.roleCode === propertyRoles.StoreOwner.roleCode)) 
            {
                availableRoles.length = 0;  // propertyAdmin is exclusive
            }
        }
        else if (franchisorId)
        {
            // Implies franchisor orgContext selected, return franchisor roles

            availableRoles.push(...this.getAllowedFranchisorRolesByPassport(passport));

            if (this.aggregatedRole.roleItems.some(roleItem => roleItem.roleCode === franchisorRoles.Owner.roleCode)) 
            {
                availableRoles.length = 0;  // franchisorAdmin is exclusive
            }
        }
        else if (currentOrgNodeSelectors.isCurrentSystemSelected())
        {
            // Implies system orgContext selected, return system roles

            availableRoles.push(...this.getAllowedSystemRolesByPassport(passport));

            if (this.aggregatedRole.roleItems.some(roleItem =>
                roleItem.roleCode === systemRoles.SystemAdmin.roleCode ||
                roleItem.roleCode === systemRoles.SupportAdmin.roleCode))
            {
                availableRoles.length = 0;  // system roles are exclusive
            }
        }

        return availableRoles;
    }

    getAllowedSystemRolesByPassport = (passport) =>
    {
        const { systemRoles } = securityConstants.builtInUserRoles;

        if (passport.roles.some(role => role.roleCode === systemRoles.SystemAdmin.roleCode))
        {
            return [systemRoles.SystemAdmin, systemRoles.SupportAdmin, systemRoles.Support, systemRoles.MenuSupport];
        }
        else if (passport.roles.some(role => role.roleCode === systemRoles.SupportAdmin.roleCode))
        {
            return [systemRoles.SupportAdmin, systemRoles.Support, systemRoles.MenuSupport];
        }
        else
        {
            return [];
        }
    }

    getAllowedFranchisorRolesByPassport = (passport) =>
    {
        const { systemRoles, franchisorRoles } = securityConstants.builtInUserRoles;

        if (passport.roles.some(role =>
            role.roleCode === securityConstants.roleCodes.SupportElevated ||
            role.roleCode === systemRoles.SystemAdmin.roleCode ||
            role.roleCode === systemRoles.SupportAdmin.roleCode ||
            role.roleCode === franchisorRoles.Owner.roleCode))
        {
            return [franchisorRoles.Owner, franchisorRoles.ManagedOwner, franchisorRoles.FinanceManager];
        }
        else
        {
            return [];
        }
    }

    getAllowedPropertyRolesByPassport = (passport) =>
    {
        const { systemRoles, franchisorRoles, propertyRoles } = securityConstants.builtInUserRoles;

        if (passport.roles.some(role =>
            role.roleCode === securityConstants.roleCodes.SupportElevated ||
            role.roleCode === systemRoles.SystemAdmin.roleCode ||
            role.roleCode === systemRoles.SupportAdmin.roleCode ||
            role.roleCode === franchisorRoles.Owner.roleCode))
        {
            return [propertyRoles.StoreOwner, propertyRoles.StoreManager, propertyRoles.FinanceManager];
        }
        else
        {
            return [];
        }
    }
}