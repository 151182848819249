
//
// Standard column width constants for GridView columns
//

module.exports.name = 350;
module.exports.code = 250;
module.exports.description = 350;
module.exports.type = 250;

module.exports.address = 300;
module.exports.phoneNumber = 150;
module.exports.emailAddress = 150;
module.exports.webSite = 150;

module.exports.date = 125;
module.exports.dueDate = 250;
module.exports.dateTime = 200;
module.exports.time = 125;

module.exports.money = 125;         // currency
module.exports.number = 125;        // fixed/float number other than currency    
module.exports.boolean = 125;       // true/false
module.exports.percentage = 125;    // percentage

module.exports.vatNumber = 125;
module.exports.companyHouseNumber = 125;

module.exports.frequency = 125;
module.exports.frequencyOption = 125;

module.exports.frequencyOption = 125;

module.exports.imageLogo = 75;      // logo
module.exports.imagePic = 100;      // images other than logo

module.exports.notes = 400;     // min width
