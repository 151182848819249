import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../../components/form/selectComponent';
import * as arraySort from 'array-sort';

const catalogSelectors = require('../../../../../../utils/state/stateSelectors/catalogSelectors');
const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class OperatingConfigurationOverview extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
        this.getFrequencyOptions = this.getFrequencyOptions.bind(this);
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    getFrequencyOptions()
    {       
        const storefrontOperatingConfigurations = this.props.data;
        const commissionRunFrequency = storefrontOperatingConfigurations.commissionRunFrequency;
        if (!commissionRunFrequency) return [];

        const options = catalogSelectors.selectLookupData().frequencies.find(f => f.code == commissionRunFrequency).options;

        return arraySort(options, "sortIndex", "code");
    }

    render()
    {
        return (            
            
            <table className='component-table'>
                <tr>
                    <td>
                        <SelectComponent
                            optionValues={catalogSelectors.selectLookupData().frequencies}
                            optionFieldName="code"
                            optionDisplayFieldName="code"
                            caption="Commission Run Schedule"
                            fieldName="commissionRunFrequency"
                            hintText="Required"
                            hintFieldName="notes"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <SelectComponent
                            optionValues={this.getFrequencyOptions()}
                            optionFieldName="code"
                            optionDisplayFieldName="code"
                            caption="Commission Run Schedule Option"
                            fieldName="commissionRunFrequencyOption"
                            hintText="Required"
                            hintFieldName="notes"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="commissionRunPercentage"
                            caption="Commission Rate (%)"
                            placeholder="Enter the commission rate"
                            hintText="Required"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="orderActivationThresholdInMinutes"
                            caption="Active queue activation  time (in minutes)."
                            placeholder="Enter time in minutes"
                            hintText="Any orders scheduled outside of activation time are put in pending queue (Required)"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
            </table>
        );
    }    
}

export default OperatingConfigurationOverview;