
import React, { Component } from 'react';
import PropertyOperatingConfigurationOveriewComponent from './components/PropertyOperatingConfigurationOveriewComponent';
import PropertySMSConfigurationComponent from './components/PropertySMSConfigurationComponent';
import PropertyAdvancedConfigurationComponent from './components/PropertyAdvancedConfigurationComponent';
import ErrorMessage from '../../../../../components/error/errorMessage';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const propertyOperatingConfigurationHelper = require('./propertyOperatingConfigurationHelper');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class PropertyOperatingConfiguration extends Component
{

    constructor(props) 
    {
        super(props);
        this.state = {}
        this.state.isLoading = true;
        this.state.error = null;
        this.state.formManager = new FormManager();

        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.state.formManager.viewModel = propertyOperatingConfigurationHelper.createNew();
        this.state.formManager.view = this;
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");
        try
        {
            let propertyOperatingConfiguration = await propertyOperatingConfigurationHelper.load();
            if (propertyOperatingConfiguration)
            {
                this.state.formManager.viewModel = propertyOperatingConfiguration;
            }
        }
        catch (error)
        {
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    render() 
    {
        let component = null;
        if (this.state.isLoading)
        {
            component = null
        }
        else if (this.state.error)
        {
            component = <ErrorMessage message={ this.state.errorMessage } />;
        }
        else
        {
            const tabHeaders = [];
            const tabContents = [];
    
            tabHeaders.push(<Tab>Overview</Tab>);
            tabContents.push(
                <TabPanel>
                    <PropertyOperatingConfigurationOveriewComponent formManager={ this.state.formManager } />
                </TabPanel>);
    
            tabHeaders.push(<Tab>SMS Configuration</Tab>);
            tabContents.push(
                <TabPanel>
                    <PropertySMSConfigurationComponent formManager={this.state.formManager} />
                </TabPanel>);

            tabHeaders.push(<Tab>Advanced Configuration</Tab>);
            tabContents.push(
                <TabPanel>
                    <PropertyAdvancedConfigurationComponent formManager={this.state.formManager} />
                </TabPanel>);
    
            component = <Tabs>
                <TabList>{ tabHeaders }</TabList>
                { tabContents }
            </Tabs>
        }

        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader 
                        title="Property Operating Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={true}
                        />
                </CardHeader>

                <CardBody>
                    { component }
                </CardBody>
            </Card>

        )
    }

    async onSaveClicked() 
    {
        const propertyOperatingConfiguration = this.state.formManager.viewModel;
        const validationError = propertyOperatingConfigurationHelper.validate(propertyOperatingConfiguration)
        if (!stringUtil.isStringNullOrEmpty(validationError)) {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Property Operating Configuration");

        try
        {
            await propertyOperatingConfigurationHelper.save(propertyOperatingConfiguration);
            viewUtil.showSuccessAlert(`Property Operating Configuration saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.configuration, 
                                                      'Property Operating Configuration has been altered', 
                                                      propertyOperatingConfiguration);

            const currentOptionAction = {
                type: actionTypes.mainBarComponent,
                payload: null
            };

            actionDispatcher.dispatch(currentOptionAction);
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

}

export default PropertyOperatingConfiguration;

