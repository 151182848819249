import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView'
import GridViewOptions from '../../../../components/gridView/gridViewOptions';

import * as supplierServiceTypeUtil from '../../../../utils/domain/supplierServiceTypeUtil';
import StockItem from './StockItem';
import exportUtils from './utils/exportUtils';
import * as stockItemUtils from './utils/stockItemUtils';

const arraySort = require('array-sort');
const stringUtil = require('../../../../utils/string/stringUtil');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const guidUtil = require('../../../../utils/guid/guidUtil');
const configurationManager = require('../../../../utils/config/configurationManager');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');
const domainConstants = require('../../../../utils/domain/constants');
const commonUtility = require('../../../../utils/domain/commonUtility');

// In the context of centralKitchen, stockItem (franchiseeId = null) can now be manipulated at property node. So the code must not
// assume that a franchisor node is always currently selected

class StockItems extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadStockItemsAsync = this.loadStockItemsAsync.bind(this);
        this.save = this.save.bind(this);

        this.state = {
            lookupData: {
                categoriesLookupData: [],
                suppliersLookupData: [],
                stockItemsLookupData: [],
                inventoryTrackingProfiles: [],
                centralKitchensLookupData: []
            }
        }
    }

    get isCentralKitchenContext() 
    {
        return commonUtility.isCentralKitchenPropertySelected();
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Stock Items";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadStockItemsAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () =>
        {
            // For franchisor created stockItems, franchisorId != null, franchiseeId == null
            // and for franchisee created stockItems, franchisorId != null, franchiseeId != null
            
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

            const newRow = {
                id: guidUtil.generateGuid(),
                franchisorId: currentOrgNode.franchisorId,
                franchiseeId: this.isCentralKitchenContext ? null : currentOrgNode.franchiseeId,
                type: this.isCentralKitchenContext
                    ? domainConstants.serviceContextTypes.centralKitchen
                    : domainConstants.serviceContextTypes.store,
                unitTypes: [],
                suppliers: [],
                operationalUnitTypes: [],
                inventoryProfiles: [],
                propertyLevelOverrides: []
            };

            return newRow;
        };

        gridViewOptions.getComponent = (isNew, row) =>
            <StockItem isNew={isNew} data={row} isRowReadOnly={this.props.isReadOnly} lookupData={this.state.lookupData} />;

        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Stock Item - ${row.name}` : 'Stock Item - New';
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;
        gridViewOptions.messageContext = "Stock Item";

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new stock item";
            return "Please wait while updating stock item";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New stock item is added successfully";
            return "stock item is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new stock item:";
            return "Following error occurred while updating stock item:";
        }

        gridViewOptions.selectFilterData = {
            doesFilterPass: (row, selectedValue) => {
                row = row || {};
                if(selectedValue && selectedValue.value === "supplier") {
                    return !(row.prepProfile || row.recipeProfile);
                } else if(selectedValue && selectedValue.value === "prep") {
                    return (row.prepProfile || row.recipeProfile) && !row.isProduct
                } else if(selectedValue && selectedValue.value === "product") {
                    return row.isProduct;
                }
                return true;
            },
            props: {
                options: [
                    {"value": "supplier", label: "Supplier Stock Items"},
                    {"value": "prep", label: "Prep Stock Items"},
                    {"value": "product", label: "Products"},
                ],    
                className: "basic-single",
                classNamePrefix: "select",
                clearable: true,
                searchable: false,
                placeholder: "Select Stock Item Type",
                name: "stockItemType",    
                width: 250,
            }
        };

        gridViewOptions.exportOptions = null;
        gridViewOptions.onCustomExport = (title, gridApi, exportOptions) =>
            exportUtils.exportData(
                title, gridApi, exportOptions,
                this.state.lookupData.stockItemsLookupData,
                this.state.lookupData.categoriesLookupData,
                this.state.lookupData.suppliersLookupData);

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    async loadStockItemsAsync()
    {
        const type = this.isCentralKitchenContext
            ? domainConstants.serviceContextTypes.centralKitchen
            : domainConstants.serviceContextTypes.store;

        try
        {
            let categories = await apiLoadFacade.loadInventoryCategories();
            if (categories == null) categories = [];
            categories = categories.filter(category => category.type === type);
            this.state.lookupData.categoriesLookupData = categories;

            let suppliers = await apiLoadFacade.loadSuppliers()
            if (suppliers == null) suppliers = [];
            suppliers = suppliers.filter(supplier => supplier.type === type);
            this.state.lookupData.suppliersLookupData = supplierServiceTypeUtil.filterStockItemSuppliers(suppliers);

            let stockItems = await apiLoadFacade.loadStockItems();
            if (stockItems == null) stockItems = [];
            stockItems = stockItems.filter(stockItem => stockItem.type === type);
            this.state.lookupData.stockItemsLookupData = stockItems;

            let inventoryTrackingProfiles = await apiLoadFacade.loadInventoryTrackingProfiles();
            if (inventoryTrackingProfiles == null) inventoryTrackingProfiles = [];
            inventoryTrackingProfiles = inventoryTrackingProfiles.filter(trackingProfile => trackingProfile.type === type);
            this.state.lookupData.inventoryTrackingProfiles = arraySort(inventoryTrackingProfiles, stringUtil.localeCompareProp("name"));

            if (this.isCentralKitchenContext)
            {
                // centralKitchensLookupData does not apply in the context of centralKitchen
                this.state.lookupData.centralKitchensLookupData = [];
            }
            else
            {
                // fetch centralKitchensLookupData which is used when centralKitchen is selected as supplier
                let centralKitchens = await apiLoadFacade.loadCentralKitchenEffectiveStockList();
                if (centralKitchens == null) centralKitchens = [];
                this.state.lookupData.centralKitchensLookupData = arraySort(centralKitchens, stringUtil.localeCompareProp("name"));
            }

            stockItemUtils.enrich(stockItems, this.state.lookupData);

            return arraySort(stockItems, stringUtil.localeCompareProp("name"));
        }
        catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        return stockItemUtils.validateStockItem(row);
    }

    cleanRow(row)
    {
        if(!row.recipeProfileEnabled) 
        {
            row.recipeProfile = null;
            row.prepProfile = null;
        }
        else
            row.suppliers = [];        

        if(!row.isInventoryTracked)
            row.inventoryProfiles = [];

        if(!row.cookingTemperatureProfileEnabled) 
            row.cookingTemperatureProfile = null;

        if(!row.storageTemperatureProfileEnabled)
            row.storageTemperatureProfile = null;

        if(!row.allergyProfileEnabled)
            row.allergyProfile = null;

        if(!row.nutritionProfileEnabled)
            row.nutritionProfile = null;

        return row;
    }

    async save(isNew, row, stockItems)
    {
        try
        {
            // Use orgContext from the stock item to construct the resourceUrl
            // This is necessary because the stock item may have been created at a franchisor node
            // and the currentOrgNode may be a franchisor node or a franchisee node

            row = this.cleanRow(row);

            const { franchisorId, franchiseeId } = row;

            const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId);
            const resourceUrl = `${baseUrl}/inventory/stockItems`;
            
            const updatedStockItem = await rmsApiProxy.post(resourceUrl, row);
            stockItemUtils.enrich(updatedStockItem, this.state.lookupData);

            if (stockItems == null) stockItems = [];
            stockItems = stockItems.filter(stockItem => stockItem.id != updatedStockItem.id);
            stockItems.push(updatedStockItem);

            this.state.lookupData.stockItemsLookupData = stockItems;

            const response = {};
            response.remoteData = arraySort(stockItems, stringUtil.localeCompareProp("name"));
            response.addUpdatedRow = updatedStockItem;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    constructGridColumnHeaders()
    {

        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "code";
        header.headerName = "Code";
        header.pinned = true;
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);

        const cloudName = configurationManager.getConfig().cdnUsersCloudName;
        const defaultCloudName = configurationManager.getConfig().cdnAppCloudName;

        header = {};
        header.field = "imageUrl";
        header.headerName = "Image";
        header.type = dataTypeConstants.imageLogo;
        header.width = columnWidthConstants.imageLogo + 10;
        header.cellRenderer = 'imageRenderer';
        header.cellRendererParams = {
            cloudName: cloudName,
            defaultImage: "app/back.office/icons/default.stockItem.image.png",
            defaultCloudName: defaultCloudName,
        }
        headers.push(header);

        header = {};
        header.field = "category";
        header.headerName = "Category";
        header.type = dataTypeConstants.name;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "isProduct";
        header.headerName = "Product";
        header.width = columnWidthConstants.boolean;
        header.type = dataTypeConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "unitTypeCode";
        header.headerName = "Measurement Type";
        header.type = dataTypeConstants.code;
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "coreUnitCode";
        header.headerName = "Measurement Unit";
        header.type = dataTypeConstants.code;
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "markupRate";
        header.headerName = "Markup Rate";
        header.type = dataTypeConstants.percentage;
        header.width = columnWidthConstants.percentage;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default StockItems;
