import React, { memo } from 'react';
import { Input } from 'reactstrap';

function InputComponent({ searchString, searchPlaceholder, onSearchStringChanged })
{
    return (
        <div className='d-flex align-items-center'>
            <div className='px-2 border-end text-muted' style={{ zIndex: '10' }}><i className='fa fa-search' /></div>
            <div className='flex-grow-1' style={{ marginLeft: '-2em' }}>
                <Input
                    autoFocus
                    className='ps-5'
                    type='search'
                    placeholder={searchPlaceholder || 'Enter search string'}
                    value={searchString}
                    onChange={e => onSearchStringChanged?.(e.target.value)} />
            </div>
        </div>
    );
};

export default memo(InputComponent);    // InputComponent is a pure component