
import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import ImageRender from './cellRenderers/imageRenderer';
import DueDateRender from './cellRenderers/dueDateRenderer';
import ColorRenderer from './cellRenderers/colorRenderer';
import TemperatureRangeRenderer from './cellRenderers/temperatureRangeRenderer';
import TemperatureColorRenderer from './cellRenderers/temperatureColorRenderer';
import NoRowsOverlayComponent from './cellRenderers/noRowsOverlayComponent';
import stringUtil  from '../../utils/string/stringUtil';
import dateUtil  from '../../utils//dateUtil/dateUtil';

const formatter = require('../../utils/formatter/formatter');
const GridColumnComparators = require('./gridColumnComparators') 

// Grid properties: https://www.ag-grid.com/react-data-grid/grid-properties/
// Grid callbacks:  https://www.ag-grid.com/react-data-grid/grid-callbacks/
// Grid events:     https://www.ag-grid.com/react-data-grid/grid-events/
// Grid row:        https://www.ag-grid.com/react-data-grid/row-object/
// Grid column:     https://www.ag-grid.com/react-data-grid/column-object/
// Grid api:        https://www.ag-grid.com/react-data-grid/grid-api/
// Grid column api: https://www.ag-grid.com/react-data-grid/column-api/

class GridComponent extends Component 
{
  constructor(props) 
  {
      super(props);
      this.onGridReady = this.onGridReady.bind(this);
      this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
      this.onRowSelected = this.onRowSelected.bind(this);
      this.getHeaders = this.getHeaders.bind(this)
      this.gridApi = null;
  }

  componentWillReceiveProps(nextProps) 
  {
    if (this.gridApi && this.isControlled()) 
    {
        this.gridApi.setRowData(this.props.rows);
    }
  }

    getHeaders() 
    {
        return this.props.headers;
    }

  render() 
  {

    //Grid Options 
    //1 - Default
    //2 - props.gridOptions
    //3 - props (named properties)

      let gridOptions = {

          animateRows: true,

          domLayout: 'autoHeight',
          onGridReady: this.onGridReady,

          rowHeight: 40,
          headerHeight: 50,
          fontSize: 50,

          rowSelection: "single",
          onRowSelected: this.onRowSelected,
          onRowDoubleClicked: this.onRowDoubleClicked,

          enableRangeSelection: true,

          pagination: false,
          paginationPageSize: 10,

          rowClass: null,
          headerRowClass: null,

          rowClassRules: null,
          getRowClassRules: null,

          rowStyle: null,
          getRowStyle: null,

          noRowsOverlayComponent: "noRowsOverlayComponent",

          onGridSizeChanged: () =>
          {
              if (this.props.shouldReArrangeHeaders)
                  this.gridApi.sizeColumnsToFit();
          },

          frameworkComponents: {
              imageRenderer: ImageRender,
              dueDateRenderer: DueDateRender,
              colorRenderer: ColorRenderer,
              temperatureRangeRenderer: TemperatureRangeRenderer,
              temperatureColorRenderer: TemperatureColorRenderer,
              noRowsOverlayComponent: NoRowsOverlayComponent
          },

          popupParent: document.querySelector('body'),

          defaultColDef: {
              sortable: true,
              filter: true,
              filterParams: { buttons: ['reset'] },
              menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab'],
              resizable: true,
              suppressSizeToFit: true,
              headerClass: "ignis-grid-header",
              headerComponentParams: {
                  menuIcon: 'fa-bars'
              }
          },

          columnTypes: {

              id: { hide: true, suppressColumnsToolPanel: true },   // hide column from grid and column menu

              notes: { minWidth: 400, sortable: false, filter: false, suppressSizeToFit: false },

              money: {
                  cellStyle: () => ({ justifyContent: "end" }),
                  filter: 'agNumberColumnFilter',
                  valueFormatter: function (params)
                  {
                      return isNaN(params.value) ? "N/A" : formatter.convertToCurrencyFormat(params.value)
                  },
              },

              floatNumber: {
                  cellStyle: () => ({ justifyContent: "end" }),
                  filter: 'agNumberColumnFilter',
                  valueFormatter: function (params) 
                  {
                      return isNaN(params.value) ? "N/A" : formatter.roundUp(params.value, 2)
                  },
              },

              optionalFloatNumber: {
                  cellStyle: () => ({ justifyContent: "end" }),
                  filter: 'agNumberColumnFilter',
                  valueFormatter: function (params) 
                  {
                      return isNaN(params.value) ? "" : formatter.roundUp(params.value, 2)
                  },
              },

              fixedNumber: {
                  cellStyle: () => ({ justifyContent: "end" }),
                  filter: 'agNumberColumnFilter'
              },

              date: {
                  filter: 'agDateColumnFilter',
                  filterParams: { comparator: GridColumnComparators.dateComparator },
                  valueFormatter: params =>
                  {
                      if (stringUtil.isStringNullOrEmpty(params.value)) return null;
                      return dateUtil.isValidDate(params.value)
                          ? dateUtil.formatDate(dateUtil.convertToLocalStandard(params.value), "YYYY-MMM-DD")
                          : params.value;
                  }
              },

              dateTime: {
                  filter: 'agDateColumnFilter',
                  filterParams: { comparator: GridColumnComparators.dateComparator },
                  valueFormatter: function (params) 
                  {
                      if (stringUtil.isStringNullOrEmpty(params.value)) return null;
                      return dateUtil.isValidDateTime(params.value)
                          ? dateUtil.convertToLocalStandard(params.value)
                          : params.value;
                  }
              },

              dueDate: {
                  filter: 'agDateColumnFilter',
                  filterParams: { comparator: GridColumnComparators.dateComparator },
                  cellRenderer: 'dueDateRenderer'
              },

              boolean: {
                  valueFormatter: params =>
                  {
                      if (stringUtil.isStringNullOrEmpty(params.value)) return null;
                      return params.value === true ? "Yes" : "No";
                  }
              },

              calorie: {
                  valueFormatter: params => isNaN(params.value) ? "N/A" : formatter.roundUp(this.props.value, 0)
              },

              percentage: {
                  cellStyle: () => ({ justifyContent: "end" }),
                  valueFormatter: params =>
                  {
                      const value = params.value;
                      if (stringUtil.isStringNullOrEmpty(value)) return "N/A";
                      if (isNaN(value)) return "N/A";
                      if (!isFinite(value)) return "N/A";
                      return formatter.roundUp(value, 2) + " %";
                  }
              },

              color: { cellRenderer: 'colorRenderer' },

              hours: {
                  valueFormatter: function (params) 
                  {
                      return formatter.roundUp(params.value, 2);
                  }
              }
          },

          pinnedBottomRowData: this.props.pinnedRows,

          statusBar: {
              statusPanels: [
                  {
                      statusPanel: 'agTotalRowCountComponent',
                      align: 'left',
                  },
                  {
                      statusPanel: 'agTotalAndFilteredRowCountComponent',
                      align: 'left',
                  },
                  {
                      statusPanel: 'agSelectedRowCountComponent',
                      align: 'center',
                  },
                  {
                      statusPanel: 'agAggregationComponent',
                      align: 'right',
                  }
              ]
          }
      };

    if (this.props.gridOptions) 
    {
        gridOptions = Object.assign(gridOptions, this.props.gridOptions);
    }

    if(this.props.paginationEnabled !== undefined)
    {
      gridOptions.pagination = this.props.paginationEnabled;
    }

    if(this.props.paginationSize !== undefined)
    {
      gridOptions.paginationPageSize = this.props.paginationSize;
    }

    if(this.props.rowClassRules !== undefined)
    {
      gridOptions.rowClassRules = this.props.rowClassRules;
    }

      return (
          <div className="ag-theme-alpine" style={{ width: '100%' }}>
              <AgGridReact
                  rowData={!this.isControlled() ? this.props.rows : null}
                  columnDefs={this.getHeaders()}
                  gridOptions={gridOptions}
              >

              </AgGridReact>
          </div>
      );
  }

  isControlled()
  {
    //Controlled indicates data rows are updated, selection is managed by composer
    return (this.props.gridMode ||  "uncontrolled") !== "uncontrolled";
  }

  onGridReady(params)
  {
    this.gridApi = params.api;

    if (this.props.rows && this.isControlled()) 
    {
      this.gridApi.setRowData(this.props.rows)
    }

    if (this.props.onGridReady)
      this.props.onGridReady(params);
  }

  onRowDoubleClicked(eventData)
  {
    if (this.props.onRowDoubleClicked)
      this.props.onRowDoubleClicked(eventData);
  }

  onRowSelected(eventData)
  {
    if (this.props.onRowSelected)
      this.props.onRowSelected(eventData);
  }
}

export default GridComponent;