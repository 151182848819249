import React, { Component } from 'react';

import SearchBarComponent from '../../../../components/form/searchBarComponent';
import { Card, CardHeader, CardBody } from 'reactstrap';
import CloseButton from '../../../../components/button/closeButton';
import ErrorMessage from '../../../../components/error/errorMessage';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ExcelExportButton from '../../../../components/button/excelExportButton';
import PictureAttachment from '../../../../components/pictureAttachment/PictureAttachment';

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const reportStyleUtils = require('../../../../utils/reports/styleUtils');
const ExcelWorkBook = require('../../../../components/button/excel/excelWorkBook');


class TaskLogs extends Component
{
    constructor(props) 
    {
        super(props);
        this.getTaskLogsRows = this.getTaskLogsRows.bind(this);
        this.state = {
            isLoading: false,
            error: null,
            taskLogs: [],
            taskTypes: []
        }
    }

    render() 
    {
        let contents = null;
        if (this.state.isLoading)
        {
            contents = <Spinner text="Loading TaskLogs..." />
        }
        else if (this.state.error)
        {
            contents = <ErrorMessage message={this.state.error} />
        }
        else if (this.state.taskLogs.length == 0)
        {
            contents = <EmptyData title="No Task Logs" text="No Task Logs Available. Please select a valid date range and hit Search" />
        }
        else 
        {
            contents = <div className='overflow-auto'>
                <table style={reportStyleUtils.tableDefaultStyle} className={reportStyleUtils.pureTableClassNames}>
                    <thead>
                        <tr>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Task</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Actions</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Hazards</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Completed On</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Completed By</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Last Updated On</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Last Updated By</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Response</th>
                            <th style={reportStyleUtils.thCenterAlignedStyle}>Comments</th>
                        </tr >
                    </thead>
                    <tbody>
                        {this.getTaskLogsRows()}
                    </tbody>
                </table></div>;
        }

        return (
            <Card>
                <CardHeader>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold', marginRight: '10px' }}>Task Logs</div>
                            <SearchBarComponent
                                searchCriteria={this.state.searchCriteria}
                                searchSingleDate={true}
                                disabled={this.state.isLoading}
                                onSearch={async (searchCriteria) =>
                                {
                                    this.state.searchCriteria = searchCriteria;
                                    this.state.isLoading = true;
                                    this.state.reportData = {};
                                    this.setState({});
                                    try
                                    {
                                        this.state.taskLogs = await this.onSearchTaskLogs(searchCriteria)
                                    }
                                    catch (error)
                                    {
                                        this.state.error = error;
                                    }
                                    this.state.isLoading = false;
                                    this.setState({});
                                }}
                                onChange={(searchCriteria) =>
                                {
                                    this.state.searchCriteria = searchCriteria;
                                    this.state.taskLogs = [];
                                    this.setState({});
                                }}
                            />
                        </div>

                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div className="mx-2">
                                <ExcelExportButton label="Excel Export" disabled={this.state.isLoading} onExport={() => this.exportToExcel(this.state.searchCriteria.startDate, this.state.taskLogs)} />
                            </div>
                            <CloseButton disabled={this.state.isLoading} />
                        </div>
                    </div>
                </CardHeader>

                <CardBody>
                    {contents}
                </CardBody>
            </Card>

        );
    }

    wrapTextWithMaxWidth(text, maxWidth = 300)
    {
        return <span style={{ "text-align": "left", "display": "block", "max-width": `${maxWidth}px`, "word-wrap": "break-word", "white-space": "normal" }}>{text}</span>
    }

    exportToExcel(businessDate, taskLogs) 
    {
        let excelWorkbook = new ExcelWorkBook();
        excelWorkbook.workBookName = `Task Logs ${businessDate}`;

        const taskLogsWorkSheet = excelWorkbook.addWorksheet("Task Logs");
        taskLogsWorkSheet.addColHeader("Task Name", 60);
        taskLogsWorkSheet.addColHeader("Task Action", 60);
        taskLogsWorkSheet.addColHeader("Task Action Status");
        taskLogsWorkSheet.addColHeader("Task Action Hazards", 40);
        taskLogsWorkSheet.addColHeader("Task Type");
        taskLogsWorkSheet.addColHeader("Task Due By");
        taskLogsWorkSheet.addColHeader("Task Status");
        taskLogsWorkSheet.addColHeader("Task Hazards", 40);
        taskLogsWorkSheet.addColHeader("Completed On");
        taskLogsWorkSheet.addColHeader("Completed By");
        taskLogsWorkSheet.addColHeader("Last Updated On");
        taskLogsWorkSheet.addColHeader("Last Updated By");
        taskLogsWorkSheet.addColHeader("Response", 60);
        taskLogsWorkSheet.addColHeader("Response Hazards", 40);
        taskLogsWorkSheet.addColHeader("Comments", 80);

        taskLogs.forEach((taskLog, index) =>
        {
            let taskActionLogs = [{}];
            if(taskLog.taskActionLogs && taskLog.taskActionLogs.length > 0) {
                taskActionLogs = taskLog.taskActionLogs;
            };

            const bgColor = index % 2 === 0 ? "E6E6E6" : "C6C6C6"; 

            const taskLogRow = taskLogsWorkSheet.addRow();
            taskLogRow.addDataCell(taskLog.name, bgColor);
            taskLogRow.addDataCell(taskActionLogs[0].name || "", bgColor);
            taskLogRow.addDataCell(taskActionLogs[0].status || "", bgColor);
            taskLogRow.addDataCell(taskActionLogs[0].hazardTypes ? taskActionLogs[0].hazardTypes.join(", ") : "", bgColor);
            taskLogRow.addDataCell(taskLog.taskTypeName, bgColor);
            taskLogRow.addDataCell(taskLog.timeOfDay, bgColor);
            taskLogRow.addDataCell(taskLog.status, bgColor);
            taskLogRow.addDataCell(taskLog.hazardTypes.join(", "), bgColor);
            taskLogRow.addDataCell(taskLog.completedOn, bgColor);
            taskLogRow.addDataCell(taskLog.completedByName, bgColor);
            taskLogRow.addDataCell(taskLog.lastUpdatedOn, bgColor);
            taskLogRow.addDataCell(taskLog.lastUpdatedByName, bgColor);
            taskLogRow.addDataCell((taskLog.taskResponseLog || {}).response || "", bgColor);
            taskLogRow.addDataCell((taskLog.taskResponseLog || { hazardTypes: [] }).hazardTypes.join(", "), bgColor);
            taskLogRow.addDataCell(taskLog.comments, bgColor);

            taskActionLogs.slice(1).forEach(taskActionLog => {
                const taskLogRow = taskLogsWorkSheet.addRow();
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell(taskActionLog.name, bgColor);
                taskLogRow.addDataCell(taskActionLog.status, bgColor);
                taskLogRow.addDataCell(taskActionLog.hazardTypes.join(", "), bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
                taskLogRow.addDataCell("", bgColor);
            });
        });

        return excelWorkbook;
    }

    getStatusIcon(status)
    {
        switch (status)
        {
            case "completed":
                return <div style={{"font-size": "15px"}}> <i className="fa fa-check-square-o text-success" aria-hidden="true" /> </div>;
            default:
                return <div style={{"font-size": "15px"}}> <i className="fa fa-window-close-o text-danger" aria-hidden="true" /> </div>;
        }
    }

    getTaskLogNameColumnDetails(taskLog)
    {

        let dueByTimeClassName = "text-info"
        if (this.state.searchCriteria.startDate && taskLog.timeOfDay && taskLog.completedOn)
        {
            if (dateUtil.isSameOrBefore(`${this.state.searchCriteria.startDate} ${taskLog.completedOn}`, `${this.state.searchCriteria.startDate} ${taskLog.timeOfDay}`))
            {
                dueByTimeClassName = "text-success"
            } else
            {
                dueByTimeClassName = "text-danger fw-bold"
            }
        }

        return <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-start">{this.getStatusIcon(taskLog.status)} <div className="ms-2">{this.wrapTextWithMaxWidth(taskLog.name)}</div></div>
            <div className="text-muted">
                TaskType: {taskLog.taskTypeName}
            </div>
            <div className={dueByTimeClassName}>
                DueBy: {taskLog.timeOfDay ? taskLog.timeOfDay : "N/A"}
            </div>
            <div>
                {this.getAttachments(taskLog)}
            </div>
        </div>

    }

    getTaskLogResponse(taskLog)
    {

        if (taskLog.taskResponseLog)
        {
            return <div className="d-flex flex-column align-items-start">
                {this.wrapTextWithMaxWidth(taskLog.taskResponseLog.response)}
                <div className="text-danger fw-bold">
                    {taskLog.taskResponseLog.hazardTypes.length > 0 && `Hazards: ${taskLog.taskResponseLog.hazardTypes.join(", ")}`}
                </div>
            </div>
        }

        return "";
    }

    getTaskActionLogs(taskLog)
    {
        if (taskLog.taskActionLogs && taskLog.taskActionLogs.length > 0)
        {
            return <table style={reportStyleUtils.tableDefaultStyle} className={reportStyleUtils.pureTableClassNames}>
                <thead>
                    <tr>
                        <th style={reportStyleUtils.thCenterAlignedStyle}>Action</th>
                        <th style={reportStyleUtils.thCenterAlignedStyle}>Hazards</th>
                    </tr>
                </thead>
                <tbody>
                    {taskLog.taskActionLogs.map(taskActionLog => <tr>
                        <td style={reportStyleUtils.tdCenterAlignedStyle} className="bg-white">
                        <div className="d-flex align-items-start">{this.getStatusIcon(taskActionLog.status)} <div className="ms-2">{this.wrapTextWithMaxWidth(taskActionLog.name)}</div></div>
                        </td>
                        <td style={reportStyleUtils.tdCenterAlignedStyle} className="text-danger fw-bold bg-white">
                            {taskActionLog.hazardTypes.join(", ")}
                        </td>
                    </tr>)}
                </tbody>
            </table>;
        }

        return null;
    }

    getAttachments(taskLog)
    {
        if (taskLog.pictureAttachments && taskLog.pictureAttachments.length > 0)
        {
            return <div className='mt-2 d-flex flex-wrap border-top'>
            {
                taskLog.pictureAttachments.map(pictureAttachment =>
                    <div className='mt-1 me-3 h4'>
                        <PictureAttachment title={`Task: ${taskLog.name}`} pictureAttachment={pictureAttachment} />
                    </div>)
            }
            </div>;
        }

        return null;
    }

    getTaskLogsRows()
    {
        const rows = [];

        this.state.taskLogs.forEach(taskLog =>
        {
            rows.push(<tr>
                <td style={reportStyleUtils.tdCenterAlignedStyle} >
                    {this.getTaskLogNameColumnDetails(taskLog)}
                </td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} >{this.getTaskActionLogs(taskLog)}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} className="text-danger fw-bold">{taskLog.hazardTypes.join(", ")}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} className="fw-bold" >{taskLog.completedOn || "N/A"}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} className="text-muted fw-bold">{taskLog.completedByName}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} className="fw-bold" >{taskLog.lastUpdatedOn || "N/A"}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} className="text-muted fw-bold">{taskLog.lastUpdatedByName}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} >{this.getTaskLogResponse(taskLog)}</td>
                <td style={reportStyleUtils.tdCenterAlignedStyle} className="text-muted">{this.wrapTextWithMaxWidth(taskLog.comments)}</td>
            </tr>);
        });

        return rows;
    }

    async onSearchTaskLogs(searchCriteria)
    {
        const taskLogs = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/foodsafety/tasks/taskLogs?businessDate=${searchCriteria.startDate}`);
        this.enrichTaskLogs(taskLogs);

        return taskLogs;
    }

    enrichTaskLogs(taskLogs)
    {
        taskLogs.forEach(taskLog => 
        {
            taskLog.completedByName = taskLog.completedByName || "N/A";
            taskLog.lastUpdatedByName = taskLog.lastUpdatedByName || "N/A";
            taskLog.taskTypeName = taskLog.taskTypeName || "N/A";
            taskLog.timeOfDay = taskLog.timeOfDay || "";
            taskLog.comments = taskLog.commentOptions || taskLog.comments || ""; 
            if (taskLog.completedOn)
            {
                taskLog.completedOn = `${dateUtil.formatDate(taskLog.completedOn, dateUtil.getHourMinuteFormat())}:00`;
            } 
            else
            {
                taskLog.completedOn = "";
            }
            
            if (taskLog.lastUpdatedOn)
            {
                taskLog.lastUpdatedOn = `${dateUtil.formatDate(taskLog.lastUpdatedOn, dateUtil.getHourMinuteFormat())}:00`;
            }
            else
            {
                taskLog.lastUpdatedOn = "";
            }

            // Hazard Type Adjustments
            if(taskLog.status === "completed" || !taskLog.isCompulsory) {
                taskLog.hazardTypes = [];
            }
            if(taskLog.taskActionLogs) {
                taskLog.taskActionLogs.forEach(taskActionLog => {
                    if(taskActionLog.status === "completed" || !taskActionLog.isCompulsory) {
                        taskActionLog.hazardTypes = [];
                    }
                })
            }
        });
    }
}

export default TaskLogs;