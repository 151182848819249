import React, { Component } from "react";
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent.js';

const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');

class PropertyDiscountOfferOverrides extends Component
{
    get discountOffer() { return this.props.data; }
    get propertyDiscountOfferOverrides() { return this.discountOffer.propertyDiscountOfferOverrides; }
    get isReadOnly() { return this.props.isReadOnly; }

    getAvailablePropertyOptions = () =>
    {
        return orgSelectors.selectProperties().map(property => ({ id: property.id, code: property.name }));
    }

    getSelectedPropertyOptions = () =>
    {
        return this.propertyDiscountOfferOverrides.map(override => 
        {
            const property = orgSelectors.selectProperty(override.propertyId);
            return { id: property.id, code: property.name };
        });
    }

    onSelectionChanged = (selectedOptions) =>
    {
        this.propertyDiscountOfferOverrides.length = 0;

        selectedOptions.forEach(option => 
        {
            this.propertyDiscountOfferOverrides.push({
                discountOfferCode: this.discountOffer.code,
                propertyId: option.id,
                isEnabled: !this.discountOffer.isEnabled
            });
        });

        this.setState({});
    }

    render()
    {
        const caption = this.discountOffer.isEnabled
            ? 'Select properties where this discount is "Disabled"'
            : 'Select properties where this discount is "Enabled"';

        return (
            <MultiSelectComponent
                caption={caption}
                valueFieldName="id"
                displayFieldName="code"
                options={this.getAvailablePropertyOptions()}
                selectedOptions={this.getSelectedPropertyOptions()}
                onSelectionChanged={this.onSelectionChanged}
                hintText="Select property override options"
                clearable={true}
                comboReadOnly={this.isReadOnly} />
        );
    }
}

export default PropertyDiscountOfferOverrides;
