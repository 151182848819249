
import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';

const FormManager = require('../../../../../../utils/view/formManager');
const storefrontUtils = require('../../utils/storefrontUtils');

export default class StorefrontTimingConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    componentDidUpdate()
    {
        if (this.state.formManager.viewModel != this.props.data)
        {
            this.state.formManager.viewModel = this.props.data;
            this.setState({})
        }
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        const paddingLeft = { paddingLeft: "10px" };
        const paddingRight = { paddingRight: "10px" };

        return <table className='component-table'>
            <tbody>
                <tr>
                    <td style={paddingRight}>
                        <InputComponent
                            inputType="number"
                            fieldName="defaultPrepTime"
                            caption="Default Prep Time"
                            placeholder="Enter default prep time"
                            hintText="Required"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td style={paddingLeft}>
                        <InputComponent
                            inputType="number"
                            fieldName="defaultDeliveryTime"
                            caption="Default Delivery Time"
                            placeholder="Enter default delivery time"
                            hintText="Required"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div>
                           
                            <div className='pb-2 d-flex flex-column border border-top-0'>

                                <div className='pt-1 text-left'>Order Interval</div>
                                <div className='pt-1 text-left text-muted' style={{ fontSize: "0.65em" }}>(Values must be in increasing order from 1 to 3)</div>

                                <div className='pb-2 d-flex align-items-center'>
                                    <div className='w-25 px-2'>
                                        <InputComponent
                                            inputType="money"
                                            fieldName="orderInterval1"
                                            inputReadOnly={this.isReadOnly}
                                            formManager={this.state.formManager} />
                                    </div>
                                    <div className='w-25 px-2'>
                                        <InputComponent
                                            inputType="money"
                                            fieldName="orderInterval2"
                                            inputReadOnly={this.isReadOnly}
                                            formManager={this.state.formManager} />
                                    </div>
                                    <div className='w-25 px-2'>
                                        <InputComponent
                                            inputType="money"
                                            fieldName="orderInterval3"
                                            inputReadOnly={this.isReadOnly}
                                            formManager={this.state.formManager} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    }
}