import React, { useState } from "react";
import Select from "react-select";

const MultiSelectField = ({
    defaultValue,
    name,
    options = [],
    isClearable = false,
    required = false,
    onValueChange = () => {},
    ...props
}) => {
    const getSelectedOption = () => {
        if (!defaultValue) return undefined;

        const selectedOptions = [];
        defaultValue.split(",").forEach((value) => {
            const foundOption = options.find((option) => option.value === value);
            if (foundOption) selectedOptions.push(foundOption);
        });
        return selectedOptions;
    };

    const [selectedOption, setSelectedOption] = useState(getSelectedOption());

    const handleValueChange = (options) => {
        const value = options.map((option) => option.value).join(",");

        onValueChange(value);
        setSelectedOption(options);
    };

    const getInputValue = () => {
        if (Array.isArray(selectedOption)) {
            return selectedOption.map((option) => option.value).join(",");
        }
        return selectedOption;
    };

    return (
        <div>
            <input type="hidden" name={name} value={getInputValue()} required={required} />
            <Select
                options={options}
                required={required}
                multi
                {...props}
                onChange={handleValueChange}
                isClearable={isClearable}
                value={selectedOption}
            />
        </div>
    );
};

export default MultiSelectField;
