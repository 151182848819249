import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

export default function PropertySMSConfigurationComponent(props)
{

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="smsFrom"
                            placeholder="SMS Sender name"
                            caption="SMS Sender name"
                            hintText="Sender name to use when sending SMS (Optional)"
                            formManager={ props.formManager } />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Send Staff Holiday Updates"
                            fieldName="sendStaffHolidayUpdates"
                            comboReadOnly={ false }
                            hintText="Send Staff Holiday Updates (Optional)"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>

                </tr>

                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Send Order Updates"
                            fieldName="sendOrderUpdates"
                            comboReadOnly={ false }
                            hintText="Send Order Updates (Optional)"
                            clearable={ true }
                            formManager={ props.formManager } />
                    </td>
                    <td />
                </tr>

                <tr>
                   <td colSpan={2} ><div style={{fontWeight:'bold', fontSize:'20px', marginTop:'20px'}} >Order Status Update Message Template</div></td> 
                </tr>
                <tr>
                   <td colSpan={2} ><div style={{width:'100%',borderBottom:'1px solid #00000040', marginBottom:'10px'}} /></td> 
                </tr>
                <tr>
                   <td colSpan={2}><div >You can use the following tags in the message template:</div></td> 
                </tr>

                <tr>
                    <td colSpan={2}><div style={{color: '#000000d0', marginBottom: '4px', marginTop:'4px'}}>
                        <div className='px-1' style={{background:'#00000005', border:'1px solid #00000010', borderRadius:'4px', width:'fit-content'}}>[order-no]</div></div>
                    </td>
                </tr>
                <tr>
                   <td colSpan={2} ><div style={{marginBottom: '16px'}}><span style={{fontWeight:'bold'}}>Example:</span> “Hi, Your order [order-no] is ready for collection. Please swing by to collect the order”</div></td> 
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            fieldName="orderReadyMessage"
                            caption="Order Ready Message"
                            placeholder="Order Ready Message"
                            hintText="Order Ready Message (Optional)"
                            formManager={ props.formManager } />
                    </td>
                </tr>
                
                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            fieldName="orderOnTheWayMessage"
                            caption="Order On The Way Message"
                            placeholder="Order On The Way Message"
                            hintText="Order On The Way Message (Optional)"
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
