import React from 'react';
import { Label } from 'reactstrap';
import InputComponent from '../../../../../../components/form/inputComponent';
import MultiSelectComponent from "../../../../../../components/form/multiSelectComponent";

const catalogSelectors = require('../../../../../../utils/state/stateSelectors/catalogSelectors');
const stringUtil = require('../../../../../../utils/string/stringUtil');

export default function PropertyGeoConfigurationComponent(props)
{

    const lookupAmenities = catalogSelectors.selectLookupData().propertyAmenities || [];
    const amenities = lookupAmenities.map(f => ({ value: f.code }));

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="rating"
                            placeholder="Enter Property Rating"
                            caption="Rating"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>

                    <td />
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="latitude"
                            placeholder="Enter Latitude"
                            caption="Latitude"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>

                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="longitude"
                            caption="Longitude"
                            placeholder="Enter Longitude"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td >
                        <Label>Property Amenities</Label>
                        <MultiSelectComponent
                            caption=""
                            valueFieldName="value"
                            displayFieldName="value"
                            options={ amenities }
                            selectedOptions={ getSelectedAmenities(props, lookupAmenities) }
                            onSelectionChanged={ (selectedOptions) => onAmenitySelectionChanged(selectedOptions, props) }
                            hintText="Select amenities available at the property (Optional)" />
                    </td>
                    <td />
                </tr>

            </tbody>
        </table>
    )

}

function getSelectedAmenities(props, lookupAmenities)
{
    const selectedAmenities = [];

    const amenitiesString = props.formManager.viewModel.amenities;

    if (!stringUtil.isStringNullOrEmpty(amenitiesString))
    {
        const amenities = amenitiesString.split(",");     // station tag tokens

        amenities.forEach(code => 
        {
            const amenity = lookupAmenities.find(f => f.code == code);

            if (amenity != null)
            {
                selectedAmenities.push(({ value: amenity.code }));
            }
        });
    }

    return selectedAmenities;
}

function onAmenitySelectionChanged(selectedOptions, props)
{
    let amenities = "";

    if (selectedOptions.length > 0)
    {
        amenities = selectedOptions.map(option => option.value).join();  // comma separated amenities
    }

    props.formManager.viewModel.amenities = amenities;

    if (props.onAmenitiesChanged)
        props.onAmenitiesChanged();
}