import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import arraySort from 'array-sort';

import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';
import * as rmsApiProxy from '../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../utils/api/rmsApiProxyUtils';

import DiscrepancySummaryData from './components/summaryData/DiscrepancySummaryData';
import DiscrepancyRawData from './components/rawData/DiscrepancyRawData';

const unitUtil = require('../../../../utils/domain/unitUtil');
const domainConstants = require('../../../../utils/domain/constants');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

class DiscrepanciesReport extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            discrepancyData: null,
            searchCriteria: null,
            error: null,
            isLoading: false,
        }
    }

    get reportData()
    {
        return {
            categories: this.categories,
            stockItems: this.stockItems,
            staff: this.staff,
            discrepancyData: this.state.discrepancyData
        }
    }

    render()
    {
        let component;

        const { isLoading, error, discrepancyData } = this.state;

        if (isLoading === true) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error != null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (discrepancyData == null)
        {
            component = <div className='m-3'>
                <EmptyData
                    title="Data required"
                    text="Please choose date range and hit Search to get report data"
                    action={null}
                />
            </div>;
        }
        else
        {
            component = this.renderReportData(discrepancyData);
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>Discrepancies Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData(discrepancyData)
    {
        if (discrepancyData.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Data Found"
                        text="No inventory discrepancy data found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key='Discrepancy_Items'>Discrepancy Items</Tab>);
        tabContents.push(<TabPanel key='Discrepancy_Items' className='bg-white mt-n1'><DiscrepancySummaryData reportData={this.reportData} /></TabPanel>);

        tabHeaders.push(<Tab key='Discrepancy_Raw_Data'>Discrepancy Raw Data</Tab>);
        tabContents.push(<TabPanel key='Discrepancy_Raw_Data' className='bg-white mt-n1'><DiscrepancyRawData reportData={this.reportData} /></TabPanel>);

        return (
            <div className='p-2 mt-n3 bg-white'>
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ discrepancyData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, discrepancyData: null, searchCriteria: searchCriteria, isLoading: true });

        try 
        {
            // Reference data is loaded only once during the lifetime of the component

            if (this.categories == null)
            {
                const categories = await apiLoadFacade.loadInventoryCategories();
                this.categories = arraySort(categories, "id");
            }

            if (this.stockItems == null)
            {
                const stockItems = await apiLoadFacade.loadStockItems('unitTypesOnly=true');
                this.stockItems = arraySort(stockItems, "id");
            }

            if (this.staff == null)
            {
                const staff = await apiLoadFacade.loadStaff();
                this.staff = arraySort(staff, "id");
            }

            // Load state data

            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/inventory/stockItemDiscrepancies` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const discrepancyData = await rmsApiProxy.get(resourceUrl);

            this.enrichData(discrepancyData);

            this.setState({ discrepancyData, isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }

    enrichData = (discrepancyData) =>
    {
        discrepancyData.forEach(discrepancyItem => 
        {
            this.enrichDiscrepancyItem(discrepancyItem);
        });
    }

    enrichDiscrepancyItem = (discrepancyItem) =>
    {
        let quantityInDefaultUnits, quantityInCoreUnits, quantityInReportingUnits;

        const stockItem = this.stockItems.find(stockItem => stockItem.id === discrepancyItem.stockItemId);
        const category = this.categories.find(category => category.id === stockItem.categoryId);
        const staff = this.staff.find(staff => staff.id === discrepancyItem.staffId);

        const reportingUnitType = unitUtil.getUnitTypeByReportingType(stockItem, domainConstants.stockItemOperationTypes.discrepancyReporting);

        discrepancyItem.categoryName = category.name;
        discrepancyItem.stockItemName = stockItem.name;
        discrepancyItem.staffName = staff && `${staff.firstName} ${staff.lastName}`;

        discrepancyItem.reportingUnitCode = reportingUnitType.code;

        // discrepancyItem.adjustedQuantity in reportingUnits

        quantityInDefaultUnits = Math.abs(discrepancyItem.adjustedQuantity);
        quantityInCoreUnits = unitUtil.convertToStockItemCoreUnitsFromDefaultUnits(stockItem, quantityInDefaultUnits);
        quantityInReportingUnits = unitUtil.convertFromStockItemCoreUnits(stockItem, reportingUnitType.id, quantityInCoreUnits);

        discrepancyItem.adjustedQuantityInReportingUnits = Math.sign(discrepancyItem.adjustedQuantity) * quantityInReportingUnits;
        discrepancyItem.unitPriceInReportingUnits = Math.abs(discrepancyItem.unitPrice * discrepancyItem.adjustedQuantity) / quantityInReportingUnits;
        discrepancyItem.adjustedCost = discrepancyItem.adjustedQuantityInReportingUnits * discrepancyItem.unitPriceInReportingUnits;

        // discrepancyItem.currentInventory in reportingUnits

        quantityInDefaultUnits = Math.abs(discrepancyItem.currentInventory);
        quantityInCoreUnits = unitUtil.convertToStockItemCoreUnitsFromDefaultUnits(stockItem, quantityInDefaultUnits);
        quantityInReportingUnits = unitUtil.convertFromStockItemCoreUnits(stockItem, reportingUnitType.id, quantityInCoreUnits);

        discrepancyItem.currentInventoryInReportingUnits = Math.sign(discrepancyItem.currentInventory) * quantityInReportingUnits;

        // discrepancyItem.reportedInventory in reportingUnits

        quantityInDefaultUnits = Math.abs(discrepancyItem.reportedInventory);
        quantityInCoreUnits = unitUtil.convertToStockItemCoreUnitsFromDefaultUnits(stockItem, quantityInDefaultUnits);
        quantityInReportingUnits = unitUtil.convertFromStockItemCoreUnits(stockItem, reportingUnitType.id, quantityInCoreUnits);

        discrepancyItem.reportedInventoryInReportingUnits = Math.sign(discrepancyItem.reportedInventory) * quantityInReportingUnits;
    }
}

export default DiscrepanciesReport;