
const stringUtil =  require("../string/stringUtil");
const parseUtil = require("../parsing/parseUtil");
const validator = require("../validator/validator");

module.exports.numberFieldValidation = function (inputValue, fieldName, required, lowerBound, equalToVal, equalToFieldName)
{

    if (stringUtil.isStringNullOrEmpty(inputValue) && required)
    {
        return `${fieldName} is a required field`;
    }

    if (!stringUtil.isStringNullOrEmpty(inputValue) && !validator.isNumeric(inputValue))
    {
        return `${fieldName} must be a number`;
    }
    const parsedValue = parseUtil.parseFloatElseZero(inputValue);
    
    if (parsedValue < 0)
    {
        return `${fieldName} cannot be a negative number`
    }
    
    if (!stringUtil.isStringNullOrEmpty(lowerBound)) {
        lowerBound = parseUtil.parseFloatElseZero(lowerBound);
        if (!(parsedValue > lowerBound))
        {
            return `${fieldName} must be greater than ${lowerBound}`;
        }
    }

    if (!stringUtil.isStringNullOrEmpty(equalToVal))
    {
        equalToVal = parseUtil.parseFloatElseZero(equalToVal);
        if (parsedValue !== equalToVal)
        {
            return `${fieldName} must be equal to ${equalToFieldName || equalToVal}`;
        }
    }
    return;
}