import React, { memo } from "react";
import NetworkDiagramProvider from "./store";
import styled from "styled-components";
import Diagram from "./components/Diagram/Diagram";
import TopHeader from "./components/TopHeader/TopHeader";
import { Card } from "reactstrap";
import Popups from "./components/Popups/Popups";
import { useGetStations } from "./hooks/use-get";
import Spinner from "../../../components/spinner/spinner";
const viewUtil = require('../../../utils/view/viewUtil');

const NetworkDiagram = () => {
     return (
          <Container>
               <Popups />
               <TopHeader />
               <Diagram />
          </Container>
     );
};

const NetworkDiagramWrapper = (props) => {
     const { data, error, isLoading } = useGetStations();

     if (error) viewUtil.showErrorAlert(error);

     if (isLoading)
          return (
               <div style={{ marginTop: "200px", textAlign: "center" }}>
                    <Spinner text="Please wait, loading stations" />
               </div>
          );
     return (
          <NetworkDiagramProvider data={data} isReadOnly={props.isReadOnly}>
               <NetworkDiagram />
          </NetworkDiagramProvider>
     );
};
export default memo(NetworkDiagramWrapper);

const Container = styled(Card)`
     height: 100%;
`;
