import React, { Component } from 'react';

import { Button, Card, CardBody, CardHeader, Input} from 'reactstrap';
import CloseButton from '../../../components/button/closeButton';
import Spinner from '../../../components/spinner/spinner';
import UserRoles from './components/UserRoles';
import SaveButton from '../../../components/button/saveButton';
import Jumbotron from '../../../components/jumbotron/jumbotron';


const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const guidUtil = require('../../../utils/guid/guidUtil');
const viewUtil = require('../../../utils/view/viewUtil');
const typeUtil = require('../../../utils/type/typeUtil');
const currentOrgNodeSelectors = require('../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const validator = require("../../../utils/validator/validator");

const securityConstants = require('../../../utils/domain/security/securityConstants');

const helperUtils = require('./utils/helperUtils');


class UsersRoles extends Component 
{
    constructor(props)
    {
        super(props)

        this.state = {};
        this.state.userEmail = null;
        this.state.userRoles = null;
        this.state.supportElevatedRoles = null;
        this.state.aggregatedRoles = null;
        this.state.updatedRoles = null;
        this.state.isLoading = false;
        this.state.isLoaded = false;

        this.validateUserRoles = this.validateUserRoles.bind(this);
    }


    render() 
    {
        let title, component;

        const { franchisorId } = currentOrgNodeSelectors.selectCurrentOrgNode();
        let additionalInfo = null;

        if (franchisorId != null)
        {
            title = `User Roles`;
            additionalInfo = "User roles define the access levels associated with an email address. Assign roles to a user's email address to grant access at the Franchisor or Property level."
        }
        else
        {
            title = "System User Roles";
            additionalInfo = "User roles define the access levels associated with an email address. Assign roles to a user's email address to grant access at the system level."
        }

        if (this.state.isLoading) 
        {
            component = <div className='my-4 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (!this.state.isLoaded)
        {
            component = null
        }
        else
        {
            component = this.renderUserRoles();
        }

        return <div className="d-flex flex-column">
            <Card>
                <CardHeader className="p-3">
                    <div className="d-flex justify-content-between align-items-start mb-2">
                        <h4>{title}</h4>
                        <div className='d-flex'>
                            {this.state.isLoaded ?
                                <div style={{ marginRight: '11px' }}>
                                    <SaveButton onClick={() => this.save()} />
                                </div> : null}
                            <CloseButton disabled={this.state.isLoading} />
                        </div>
                    </div>
                    {additionalInfo}
                    <hr className="my-2" />
                    <p>Enter the user email you want to search for, then hit "Search."</p>
                    <div className='d-flex justify-content-start align-items-center'>
                        <div>
                            <Input
                                type="identity"
                                value={this.state.userEmail}
                                style={{ textAlign: 'left', minWidth: '20vw' }}
                                placeholder="Email Address"
                                disabled={this.state.isLoading}
                                onChange={(event) =>
                                {
                                    const target = event.target;
                                    this.state.userEmail = target.value;

                                    if (this.state.isLoaded)
                                        this.state.isLoaded = false;

                                    this.setState({});

                                }} />
                        </div>

                        <div style={{ marginLeft: '10px' }}>
                            <Button
                                className={this.props.btnClassName ? this.props.btnClassName : "btn btn-dark"}
                                disabled={this.state.isLoading}
                                onClick={() => 
                                {
                                    if (validator.isValidEmailAddress(this.state.userEmail))
                                        this.loadUserRoles();

                                    else this.setState({ isLoaded: false })
                                }}>
                                <i className="fa fa-search"></i>&nbsp;Search
                            </Button>
                        </div>
                    </div>


                </CardHeader>


                <CardBody className='flex-grow-1 h-100 p-0'>
                    {component}
                </CardBody>

            </Card>
        </div>
    }

    renderUserRoles()
    {
        if (this.state.errorMessage)
            return <div className='pt-4 pb-2 text-danger' style={{width:'99%', marginLeft: '8px', textAlign: 'center'}}>
                {this.state.errorMessage}
            </div>

        return <div className='pt-4 pb-2' style={{width:'99%', marginLeft: '8px'}}>
            {this.state.aggregatedRoles == null || this.state.aggregatedRoles.length == 0 ? <center> <p><b>No Roles available</b></p></center> : null}
            <UserRoles aggregatedRoles={this.state.aggregatedRoles} setAggregatedRoles={roles => {this.setState({aggregatedRoles: roles})}}/>
        </div>
    }

    loadUserRoles = async () =>
    {
        try
        {
            const currentFranchisorId = currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId;
            let errorMessage = null;
            this.setState({isLoading: true, userRoles: null, errorMessage: null});

            let userRoles = await rmsApiProxy.get(`core/system/security/users/${this.state.userEmail}/roles`);

            this.state.supportElevatedRoles = userRoles.filter(x => x.roleCode === securityConstants.roleCodes.SupportElevated);
            userRoles = userRoles.filter(x => x.roleCode !== securityConstants.roleCodes.SupportElevated);

            const franchisors = new Set(userRoles.filter(role => role.franchisorId != null).map(role => role.franchisorId));

            if (franchisors.size > 1) 
            {
                throw new Error('Invalid email, an email may not have roles across multiple franchisors');
            }

            if(currentFranchisorId)
            {
                if (userRoles.some(role =>
                    role.roleCode === securityConstants.builtInUserRoles.systemRoles.SystemAdmin.roleCode ||
                    role.roleCode === securityConstants.builtInUserRoles.systemRoles.SupportAdmin.roleCode ||
                    role.roleCode === securityConstants.builtInUserRoles.systemRoles.Support.roleCode ||
                    (role.franchisorId && role.franchisorId !== currentFranchisorId)))
                {
                    errorMessage = 'Email is already registered with another franchisor or at system level';
                    this.setState({isLoading: false, isLoaded: true, errorMessage: errorMessage});
                    return;
                }
            }
            else
            {
                if(userRoles.some(role => role.franchisorId))
                {
                    errorMessage = 'Email is already registered with franchisor roles';
                    this.setState({isLoading: false, isLoaded: true, errorMessage: errorMessage});
                    return;
                }
            }

            this.state.aggregatedRoles = this.getAggregatedRoles(userRoles);

            this.setState({ isLoading: false, isLoaded: true});
        }
        catch (error)
        {
            const errorMessage =
                `Oops! Following error occurred while loading user roles:` +
                `\n${error}` +
                `\n\nPlease cancel and try reloading User`;

            this.setState({ errorMessage: errorMessage });
        }
        finally
        {
            this.setState({isLoading: false});
        }
    }

    validateUserRoles = (aggregatedRoles) =>
    {
        let errorMessage;

        const invalidRoles = aggregatedRoles.filter(userRole =>
            userRole.orgName == null ||
            userRole.orgContext == null ||
            userRole.roleItems.length === 0
        );

        if (invalidRoles.length > 0)
        {
            return "Missing Organization / Roles in one of the enteries";
        }

        const updatedRoles = [];

        aggregatedRoles.forEach(aggregatedRole =>
        {
            aggregatedRole.roleItems.forEach(roleItem =>
                updatedRoles.push({
                    franchisorId: aggregatedRole.orgContext.franchisorId,
                    franchiseeId: aggregatedRole.orgContext.franchiseeId,
                    propertyId: aggregatedRole.orgContext.propertyId,
                    emailAddress: this.state.userEmail,
                    roleCode: roleItem.roleCode,
                    expiry: null
                }));
        });


        if ((errorMessage = helperUtils.validateUserRoles(updatedRoles)) != null)
        {
            return errorMessage;
        }

        this.state.updatedRoles = updatedRoles;

        return null;
    }

    save = async () =>
    {
        const errorMessage = this.validateUserRoles(this.state.aggregatedRoles);
        if(errorMessage)
        {
            viewUtil.showErrorAlert(errorMessage);
            return;
        }
        const userRoles = this.state.updatedRoles ? typeUtil.deepCloneObject(this.state.updatedRoles) : [];
        userRoles.push(...this.state.supportElevatedRoles);

        try
        {
            viewUtil.showSystemModalSpinner("Please wait while updating user");

            await rmsApiProxy.post(`core/system/security/users/${this.state.userEmail}/roles`, userRoles);

            viewUtil.closeModalSpinner();

            viewUtil.showSuccessAlert("User roles updated successfully");

        } catch (error)
        {
            viewUtil.closeModalSpinner();
            throw error;
        }
    }

    getAggregatedRoles = (userRoles) =>
    {
        // Aggregate user.roles by orgContext so that we can edit them using MultiSelectComponent

        const aggregatedRoles = [];

        userRoles.forEach(userRole => 
        {
            const orgContext = { franchisorId: userRole.franchisorId, franchiseeId: userRole.franchiseeId, propertyId: userRole.propertyId };

            let aggregatedRole = aggregatedRoles.find(aggregatedRole => helperUtils.areSameOrgContexts(aggregatedRole.orgContext, orgContext));

            if (!aggregatedRole)
            {
                aggregatedRole = {
                    orgName: helperUtils.getOrgName(orgContext),
                    orgContext: orgContext,
                    roleItems: [],
                };

                aggregatedRoles.push(aggregatedRole);
            }
            
            aggregatedRole.roleItems.push(helperUtils.getBuiltInRoleFromRoleName(userRole.roleCode));


        });

        return aggregatedRoles;
    }
}

export default UsersRoles;

