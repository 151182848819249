import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const arraySort = require('array-sort');

const storefrontOrderIngestHelper = require('./orderIngestHelper');
const FormManager = require('../../../../../utils/view/formManager.js');
const domainConstants = require('../../../../../utils/domain/constants');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const brandApiFacade = require('../../../../../utils/api/brandApiFacade.js');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const logger = require('../../../../../utils/logger/logger.js');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontOrderIngest extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {
            storefrontOrderIngest: {},
            formManager: new FormManager(),
            storefront: this.props.params
        };
        
        this.state.formManager.viewModel = this.state.storefrontOrderIngest;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({isLoading: true});
        viewUtil.showSystemModalSpinner("Loading, please wait");
        try
        {
            let storefrontOrderIngest = await storefrontOrderIngestHelper.loadStorefrontOrderIngest(this.state.storefront);
            
            this.state.storefrontOrderIngest = storefrontOrderIngest || {autoAcceptOrder: true, isEnabled: true};
            this.state.formManager.viewModel = this.state.storefrontOrderIngest;

            this.setState({});
        }
        catch(error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({isLoading: false});
        }
    }

    async onSaveClicked()
    {
        const validationError = storefrontOrderIngestHelper.validateOrderIngest(this.state.storefrontOrderIngest)
        
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }
        
        viewUtil.showSystemModalSpinner("Saving Order Ingest");

        try
        {
            await storefrontOrderIngestHelper.save(this.state.storefront, this.state.storefrontOrderIngest);
            viewUtil.showSuccessAlert(`Order Ingest saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.orderIngest, 
                                                      `${this.state.storefront.name} order ingest has been altered`, 
                                                      this.state.storefrontOrderIngest);

            actionDispatcherUtil.showOptionsUI();
        }
        catch(error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();            
        }
    }

    onDeleteClicked()
    {
        viewUtil.showConfirmDialogue('Delete Order Ingest', `Are you sure you want to delete the order ingest?`,
                        async () =>
                        {
                            viewUtil.showSystemModalSpinner("Saving Order Ingest");

                            try
                            {
                                await storefrontOrderIngestHelper.delete(this.state.storefront.id);
                                viewUtil.showSuccessAlert(`Order Ingest deleted`);

                                await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.orderIngest, 
                                                                          `${this.state.storefront.name} storefront order ingest has been deleted`, 
                                                                          this.state.storefrontOrderIngest);
                                actionDispatcherUtil.showOptionsUI();
                            }
                            catch(error)
                            {
                                viewUtil.showCriticalAlert(error);
                            }
                            finally
                            {
                                viewUtil.closeModalSpinner();            
                            }
                        });
    }


    renderToolbar()
    {
        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>Order Ingest</div>
                    </td>
                    <td align="right">

                        {
                            !this.props.isReadOnly &&
                            <>
                                <Button color="danger" className="btn-danger" style={{ marginRight: '30px' }} onClick={this.onDeleteClicked}>
                                    Delete Order Ingest
                                </Button>
                                <Button color="success" className="btn-success" style={{ marginRight: '5px' }} onClick={this.onSaveClicked}>
                                    Save
                                </Button>
                            </>
                        }

                        <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={() => { actionDispatcherUtil.showOptionsUI()}}>
                            {this.props.isReadOnly ? "Close": "Cancel"}
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }

    render()
    {
        const orderIngestTypes = [];

        const marketplaceStorefrontsList = Object.values(domainConstants.MarketplaceStorefronts);
        const flipdishStorefrontsList = Object.values(domainConstants.FlipdishStorefronts);

        if (marketplaceStorefrontsList.includes(this.state.storefront.type))
        {
            orderIngestTypes.push({value: domainConstants.channels.UrbanPiper});
            orderIngestTypes.push({value: domainConstants.channels.Deliverect});
        }

        if (flipdishStorefrontsList.includes(this.state.storefront.type))
        {
            orderIngestTypes.push({value: domainConstants.channels.Flipdish});
        }

        let orderIngestTypeConfigurations = null;

        if(stringUtil.areStringSame(this.state.formManager.viewModel.type, domainConstants.channels.UrbanPiper))
        {
            orderIngestTypeConfigurations = 
                <>
                    <tr>
                        <td>
                            <InputComponent
                                key="bizId" 
                                inputType="Biz ID" 
                                caption="Urban Piper Biz ID" 
                                hintText="Required"
                                fieldName="bizId"
                                placeholder="Enter Urban Piper Biz ID"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent 
                                key="posId"
                                inputType="POS ID" 
                                caption="Urban Piper POS ID" 
                                hintText="Required"
                                fieldName="posId"
                                placeholder="Enter Urban Piper POS ID"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent 
                                key="apiKey"
                                inputType="API Key" 
                                caption="Urban Piper API Key" 
                                hintText="Required"
                                fieldName="apiKey"
                                placeholder="Enter Urban Piper API Key"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <BooleanSelectComponent
                                fieldName="isEnabled"
                                caption="Is Enabled"
                                hintText="Is order ingest enabled"
                                clearable={false}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr>
                </>
        }

        if(stringUtil.areStringSame(this.state.formManager.viewModel.type, domainConstants.channels.Deliverect))
        {
            orderIngestTypeConfigurations = 
                <>
                    <tr>
                        <td>
                            <InputComponent 
                                key="accountId"
                                inputType="Account ID" 
                                caption="Deliverect Account ID" 
                                hintText="Required"
                                fieldName="accountId"
                                placeholder="Enter Account ID"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent 
                                key="locationId"
                                inputType="Location ID"
                                caption="Deliverect Location ID" 
                                hintText="Required"
                                fieldName="locationId"
                                placeholder="Enter Deliverect Location ID"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <BooleanSelectComponent
                                fieldName="isEnabled"
                                caption="Is Enabled"
                                hintText="Is order ingest enabled"
                                clearable={false}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager}
                            />
                        </td>
                        <td>

                        </td>
                    </tr>
                </>
        }

        if(stringUtil.areStringSame(this.state.formManager.viewModel.type, domainConstants.channels.Flipdish))
        {
            orderIngestTypeConfigurations = 
                <>
                    <tr>
                        <td>
                            <InputComponent 
                                key="appId"
                                inputType="App ID" 
                                caption="Flipdish App ID" 
                                hintText="Required"
                                fieldName="appId"
                                placeholder="Enter Flipdish App ID"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent 
                                key="storeId"
                                inputType="Store ID" 
                                caption="Flipdish Store ID" 
                                hintText="Required"
                                fieldName="storeId"
                                placeholder="Enter Flipdish Store ID"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption="Auto Accept Order"
                                fieldName="autoAcceptOrder"
                                hintText="Should the orders be auto accepted"
                                clearable={false}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <BooleanSelectComponent
                                fieldName="isEnabled"
                                caption="Is Enabled"
                                hintText="Is order ingest enabled"
                                clearable={false}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr>
                </>
        }

        return (            
            <Card>
                <CardHeader>
                    {this.renderToolbar()}
                </CardHeader>

                <CardBody>
                    <table className="component-table">
                        <tr>                            
                            <td>
                                <SelectComponent
                                    caption="Type"
                                    fieldName="type"
                                    optionFieldName="value"
                                    optionDisplayFieldName="value"
                                    optionValues={orderIngestTypes}
                                    hintText="Required"
                                    placeholder="Choose Order Ingest type"
                                    clearable={false}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.state.formManager} />
                            </td>
                            <td>
                                <div className={`${orderIngestTypeConfigurations == null ? 'd-none' : ""}`}>
                                    <SelectComponent
                                        caption="Reference Price Dispatch Type"
                                        fieldName="referencePriceDispatchType"
                                        optionFieldName="value"
                                        optionDisplayFieldName="value"
                                        optionValues={arraySort(Object.values(domainConstants.dispatchTypes)).map(value => ({ value: value }))}
                                        hintText="Specify which dispatch type in the price band to be used to determine price (required)"
                                        placeholder="Choose Reference Price Dispatch Type"
                                        clearable={false}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.state.formManager} />
                                </div>
                            </td>
                        </tr>                        

                        {orderIngestTypeConfigurations}

                    </table>
                </CardBody>
            </Card>

        );
    }
}

export default StorefrontOrderIngest;