
import React, { Component } from 'react';
import { Row, Col, Card, CardTitle, CardText, CardHeader, Button, CardBody, CardFooter, CardImg, Collapse } from 'reactstrap';

class CollapsibleCard extends Component
{

    constructor(props)
    {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {};
        this.state.isOpen = this.props.isDefaultOpen;

    }

    render()
    {
        var refreshButton = null;
        if (this.props.onRefresh)
        {
            refreshButton = <a className="card-header-action btn btn-setting" onClick={(event)=>
                                                                                        {
                                                                                            this.props.onRefresh();
                                                                                            event.stopPropagation();
                                                                                        }}>
                                <i className="icon-reload"></i>
                            </a>
        }

        var rotation = 180;
        if (!this.state.isOpen)
            rotation = 90;

        return (
                <Card>
                    <CardHeader onClick={this.toggle}>
                        <table width="100%">
                            <tr>
                                <td>
                                    {this.props.caption}
                                </td>
                                <td>
                                    <div className="card-header-actions" align="right">
                                        {refreshButton}
                                        <a className="card-header-action btn btn-minimize">
                                            <i className="icon-arrow-up" style={{transform: `rotate(${rotation}deg)`}}></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        
                        
                    </CardHeader>
                    <Collapse isOpen={this.state.isOpen}>
                        <CardBody {...this.props}>
                            {this.props.children}
                        </CardBody>
                    </Collapse>
                </Card>
            );
    }

    toggle()
    {
        this.state.isOpen = !this.state.isOpen;
        this.setState({});
    }

}

export default CollapsibleCard;