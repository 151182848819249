
import React, { Component } from "react";
import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent'
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent';

import DiscountTagsComponent from './DiscountTagsComponent'
import PropertyDiscountOfferOverridesComponent from './PropertyDiscountOfferOverrides'

const FormManager = require('../../../../../utils/view/formManager');


export default class DiscountOffer extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
            isExisting: !this.props.isNew
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
        this.state.formManager.onFieldChanged = this.onFieldChanged;

        //
        // Ensure required paramsJson object property for DiscountTagsComponent to work properly
        // Incoming row.paramsJson is stringified json
        // This is a deeply cloned object, so we can manipulate it freely

        this.state.discountOfferTypeValues = [
            {
                code: 'Fixed',
                notes: 'Indicates this current discount offer has a fixed amount.'
            },

            {
                code: 'Percentage',
                notes: 'Indicates percentage is used to calculate the discount.'
            }
        ]

        const viewModel = this.state.formManager.viewModel;

        if (viewModel.paramsJson)
        {
            // Convert stringified paramsJson is parsed json

            try
            {
                viewModel.paramsJson = JSON.parse(viewModel.paramsJson);

            } catch
            {
                viewModel.paramsJson = null;
            }
        }

        if (!viewModel.paramsJson) viewModel.paramsJson = {};
        if (!viewModel.paramsJson.tags) viewModel.paramsJson.tags = {};
    }

    onFieldChanged = (event) =>
    {
        const id = event.target.id;

        if (id === "isEnabled") 
        {
            const discountOffer = this.props.data;
            const propertyDiscountOfferOverrides = discountOffer.propertyDiscountOfferOverrides;
            propertyDiscountOfferOverrides.length = 0;
        }

        if (id === "code") 
        {
            const discountOffer = this.props.data;
            const propertyDiscountOfferOverrides = discountOffer.propertyDiscountOfferOverrides;
            propertyDiscountOfferOverrides.length = 0;
        }
    }

    render()
    {
        const isReadOnly = this.props.isReadOnly;

        return (
            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="code"
                                caption="Code"
                                fieldName="code"
                                hintText="Unique discount code. This cannot be edited once saved. (Required)"
                                placeholder="Enter discount code"
                                inputReadOnly={isReadOnly || this.state.isExisting} //Code can only be edited for new discount offer
                                formManager={this.state.formManager} />
                        </td>
                        <td />
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Discount Offer Type"
                                fieldName="discountOfferType"
                                optionFieldName="code"
                                optionDisplayFieldName="code"
                                optionValues={this.state.discountOfferTypeValues}
                                hintText="Select current discount offer has fixed or percentage value. (Required)"
                                hintFieldName="notes"
                                clearable={false}
                                comboReadOnly={isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="rate"
                                caption="Discount Value"
                                fieldName="value"
                                hintText="Discount value offered of total bill amount (Required)"
                                placeholder="Enter discount value to be applied"
                                inputReadOnly={isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="money"
                                caption="Minimum Order Amount"
                                fieldName="minimumOrderAmount"
                                hintText="Minimum order amount for discount to be applicable (Required)"
                                placeholder="Enter minimum order amount"
                                inputReadOnly={isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <DiscountTagsComponent
                                inputReadOnly={isReadOnly}
                                paramsJson={this.state.formManager.viewModel.paramsJson}
                                style={{ marginRight: '20px' }} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption="Is this discount enabled for all properties by default ?"
                                fieldName="isEnabled"
                                hintText="Choose default for all properties (Required)"
                                clearable={false}
                                comboReadOnly={isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <PropertyDiscountOfferOverridesComponent {...this.props} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption="Discount Available"
                                fieldName="isAvailable"
                                hintText="Only available discounts can be redeemed (Required)"
                                clearable={false}
                                comboReadOnly={isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td />
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any discount notes."
                                multiLine={true} rows={4}
                                inputReadOnly={isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                </tbody>
            </table>);
    }
}