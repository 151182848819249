import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../../utils/constants/dataTypeConstants';
import * as currentOrgNodeSelectors from '../../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';

import * as reportViewUtils from '../../../../../../../views/reports/reportUtils/helperUtils/viewUtils';

export default function TabularReport(props)
{
    const { getMasterDataRows } = props;

    const masterRows = getMasterDataRows();

    const totalsRow = getMasterTotalsRow(masterRows);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForMasterRows(),
        rowGroupPanelShow: 'always',
        columnDefs: getMasterGridColumns(),
        masterDetail: true,
        isRowMaster: row => row.quantity > 0,
        detailCellRendererParams: currentOrgNodeSelectors.isCurrentFranchisorSelected()
            ? params => getSalesByPropertyDetailCellRendererParams(params, props)
            : params => getSalesByVenueDetailCellRendererParams(params, props)
    }

    return (
        <div className="ag-theme-alpine" style={{ height: "80vh" }}>
            <AgGridReact
                domLayout='normal'
                rowData={masterRows}
                pinnedBottomRowData={[totalsRow]}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
            />
        </div>
    );
}

//#region Master Rows

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        const rows = [];
        e.api.forEachNodeAfterFilter(rowNode => rows.push(rowNode.data));

        const totalsRow = this.getTotalsRow(rows);
        e.api.setPinnedBottomRowData([totalsRow]);
    });
}

function getMasterTotalsRow(rows) 
{
    return {
        quantity: rows.reduce((sum, row) => sum + row.quantity, 0),
        retailPrice: rows.reduce((sum, row) => sum + row.retailPrice, 0),
    };
}

function getMasterGridColumns()
{
    const columnDefs = [];

    columnDefs.push({ field: 'category', type: 'rowGroupEnabled', hide: false });
    columnDefs.push({ field: 'caption', headerName: 'Menu Item', cellRenderer: 'agGroupCellRenderer' });
    columnDefs.push({ field: 'quantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'retailPrice', type: `${dataTypeConstants.money}` });

    return columnDefs;
}

//#endregion

//#region SalesByProperty Detail Rows

function getSalesByPropertyDetailCellRendererParams(params, props)
{
    const { getSalesByPropertyDetailDataRows } = props;
    const { data: masterRow } = params;

    const detailRows = getSalesByPropertyDetailDataRows(masterRow);

    const { pagination, paginationPageSize } = biUtils.getDetailGridPaginationSettings(detailRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForDetailRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getSalesByPropertyDetailGridColumns(),
        masterDetail: true,
        isRowMaster: row => row.quantity > 0,
        detailCellRendererParams: params => getSalesByVenueDetailCellRendererParams(params, props)
    };

    return {
        detailGridOptions: gridOptions,
        getDetailRowData: params => params.successCallback(detailRows)
    };
}

function getSalesByPropertyDetailGridColumns()
{
    const columnDefs = [];

    columnDefs.push({ field: 'propertyName', headerName: 'Store', cellRenderer: 'agGroupCellRenderer' });
    columnDefs.push({ field: 'quantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'retailPrice', type: `${dataTypeConstants.money}` });

    return columnDefs;
}

//#endregion

//#region SalesByVenue Detail Rows

function getSalesByVenueDetailCellRendererParams(params, props)
{
    const { getSalesByVenueDetailDataRows } = props;
    const { data: masterRow } = params;

    const detailRows = getSalesByVenueDetailDataRows(masterRow);

    const { pagination, paginationPageSize } = biUtils.getDetailGridPaginationSettings(detailRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForDetailRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getSalesByVenueDetailGridColumns(),
    };

    return {
        detailGridOptions: gridOptions,
        getDetailRowData: params => params.successCallback(detailRows)
    };
}

function getSalesByVenueDetailGridColumns()
{
    const columnDefs = [];

    columnDefs.push({ field: 'venueCode', headerName: 'Venue' });
    columnDefs.push({ field: 'quantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'retailPrice', type: `${dataTypeConstants.money}` });

    return columnDefs;
}

//#endregion
