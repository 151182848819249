import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import GridViewOptions from '../../../../components/gridView/gridViewOptions';

import TaskType from './TaskType';



const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const stringUtil = require('../../../../utils/string/stringUtil');
const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


class TaskTypes extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadTaskTypes = this.loadTaskTypes.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "TaskTypes";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadTaskTypes;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        gridViewOptions.getNewRow = () => ({ franchisorId: currentOrgNode.franchisorId });

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.getComponent = (isNew, row) => <TaskType data={row} isRowReadOnly={this.isRowReadOnly(row)} />
        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `TaskType - ${row.name}` : 'TaskType - New';
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;
        gridViewOptions.messageContext = "Task Type";

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new task type.";
            return "Please wait while updating task type.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New task type added successfully";
            return "Task type updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new task type:";
            return "Following error occurred while updating task type:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row) =>
    {
        return this.props.isReadOnly;
    }

    validateRow(isNew, row)
    {


        if (stringUtil.isStringNullOrEmpty(row.name))
            return "Task type name cannot be left empty";

        return null;
    }

    async loadTaskTypes()
    {
        const taskTypes = await apiLoadFacade.loadTaskTypes();
        return taskTypes;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const updatedTaskType = await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/foodsafety/tasks/taskTypes`, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(taskType => taskType.id == updatedTaskType.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedTaskType);
            else
                remoteData.push(updatedTaskType);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedTaskType;

            return response;

        } catch (error)
        {
            throw error;
        }
    }
    
    constructGridColumnHeaders()
    {

        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.type = columnWidthConstants.name;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default TaskTypes;