import React from 'react';

import TabularReport from './overviewSummary/TabularReport';
import ChartReport from './overviewSummary/ChartReport';

export default function OverviewSummary(props)
{
    return (
        <div className='p-3 d-flex align-items-start'>
            <div className='ignis-bi-tabular-report'>
                <TabularReport data={props.data} vatCaption={props.vatCaption} />
            </div>
            <div className='ms-2 ignis-bi-chart-report'>
                <ChartReport data={props.data} />
            </div>
        </div>
    );
}
