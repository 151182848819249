import React, { Component } from 'react';
import Select from 'react-select';
import { Row, Card, CardHeader, Button, CardBody } from 'reactstrap';
import ScaleLoader from "react-spinners/ScaleLoader";
import { sentenceCase } from "sentence-case";
import CloseButton from '../../components/button/closeButton';
import GridComponent from '../../components/grid/gridComponent.js';
import SearchBarComponent from '../../components/form/searchBarComponent.js';
import AttachmentButton from '../../components/attachment/attachmentButton';
import ExternalIntegrationLogsButton from '../../components/externalIntegrationLogs/externalIntegrationLogsButton';
import GridPageSize from './gridPageSize.js';
import GridViewAddEditContainerComponent from './gridViewAddEditContainerComponent.js'

import ClipboardComponent from '../clipboard/clipboardComponent';

const viewUtil = require('../../utils/view/viewUtil');
const typeUtil = require('../../utils/type/typeUtil');
const stringUtil = require('../../utils/string/stringUtil');

class GridView extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderHeaderOnGridMode = this.renderHeaderOnGridMode.bind(this);
        this.renderCustomButtonsOnGridMode = this.renderCustomButtonsOnGridMode.bind(this);
        this.renderHeaderOnComponentMode = this.renderHeaderOnComponentMode.bind(this);
        this.renderAttachmentButton = this.renderAttachmentButton.bind(this);
        this.renderExternalIntegrationLogsButton = this.renderExternalIntegrationLogsButton.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderBodyOnGridMode = this.renderBodyOnGridMode.bind(this);
        this.renderBodyOnComponentMode = this.renderBodyOnComponentMode.bind(this);
        this.renderBodyOnGridModeWithData = this.renderBodyOnGridModeWithData.bind(this);
        this.renderRowsPerPageComponent = this.renderRowsPerPageComponent.bind(this);
        this.renderCustomSelectOnGridMode = this.renderCustomSelectOnGridMode.bind(this);
        this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onAddClicked = this.onAddClicked.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.onCustomButtonClick = this.onCustomButtonClick.bind(this);
        this.onCustomComponentButtonClick = this.onCustomComponentButtonClick.bind(this);
        this.onCustomActionButtonClick = this.onCustomActionButtonClick.bind(this);
        this.onCustomGridActionButtonClick = this.onCustomGridActionButtonClick.bind(this);

        this.findSelectedRow = this.findSelectedRow.bind(this);
        this.selectLastSelectedRow = this.selectLastSelectedRow.bind(this);

        this.getWaitMessage = this.getWaitMessage.bind(this);
        this.getSuccessSaveMessage = this.getSuccessSaveMessage.bind(this);
        this.getFailedSaveMessage = this.getFailedSaveMessage.bind(this);

        // this.exportWorkbook = this.exportWorkbook.bind(this);

        this.state =
        {
            viewState: 'grid',
            lastSelectedRowId: null,
            searchCriteria: this.props.gridViewOptions.fromSearchDate != null && this.props.gridViewOptions.toSearchDate != null
            ?   {
                    startDate: this.props.gridViewOptions.fromSearchDate,
                    endDate: this.props.gridViewOptions.toSearchDate
                } 
            :   null,
            customButton: null,
            selectFilterValue: null,

            remoteDataStatus: "none",
            remoteDataError: null,
            remoteData: [],

            addEditContainerInstructions:
            {
                onUnloadHideAlert: true
            },

            gridPageSize: this.props.gridViewOptions.pageSize != null ? this.props.gridViewOptions.pageSize : 10,

            displayPrimaryKeyInHeader: false,
        };
    }

    render() 
    {
        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                </CardBody>
            </Card>

        );
    }

    componentWillUnmount()
    {
        viewUtil.clearAlert();
    }

    async componentDidMount()
    {
        if(this.state.searchCriteria != null)
            this.onSearch(this.state.searchCriteria);
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.restoreGridState();

        this.selectLastSelectedRow();

        this.gridApi.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay && this.props.gridViewOptions.getPinnedRows)
            {
                const pinnedRows = this.props.gridViewOptions.getPinnedRows(e.api.rowModel.rowsToDisplay.map(node => node.data));
                e.api.setPinnedBottomRowData(pinnedRows);
            }
        });
    }

    saveGridState = () =>
    {
        if (this.gridApi && this.columnApi) 
        {
            const columnState = this.columnApi.getColumnState();
            const filterModel = this.gridApi.getFilterModel();
            this.gridState = { columnState, filterModel }
        }
    }

    restoreGridState = () =>
    {
        if (this.gridApi && this.columnApi && this.gridState) 
        {
            const { columnState, filterModel } = this.gridState;

            columnState && this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
            filterModel && this.gridApi.setFilterModel(filterModel);
        }
    }

    renderHeader()
    {

        if (this.state.viewState == 'grid')
            return this.renderHeaderOnGridMode();
        else
            return this.renderHeaderOnComponentMode();
    }


    renderHeaderOnGridMode()
    {
        var addButton = null;
        var closeButton = null;

        var disabled = this.state.remoteDataStatus == 'searching';

        if (!this.props.gridViewOptions.isReadOnly && (this.props.gridViewOptions.getComponent && this.props.gridViewOptions.getNewRow))
        {
            addButton =
                <Button
                    className='me-2'
                    disabled={disabled}
                    color="success"
                    onClick={this.onAddClicked}>
                    <i className="fa fa-plus"></i>&nbsp;Add
                </Button>;
        }

        if (this.props.gridViewOptions.closeEnabled !== false)
        {
            closeButton = <CloseButton disabled={disabled} />
        }

        var title = this.props.gridViewOptions.title;

        return (
            <table width="100%">
                <tbody>
                <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{title}</div>
                    </td>

                    <td width="99%">
                        <table width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    {this.renderSearchBar()}
                                </td>
                                <td align="right">
                                    {this.renderRowsPerPageComponent()}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </td>

                    <td>
                        {this.renderCustomSelectOnGridMode(disabled)}
                    </td>

                    <td>
                        <div className='text-nowrap d-flex justify-content-end align-items-center'>
                            {this.renderCopyPasteComponentOnGridMode(disabled)}
                            {this.renderCustomButtonsOnGridMode(disabled)}
                            {addButton}
                            {closeButton}
                        </div>
                    </td>

                </tr>
                </tbody>
            </table>
        );
    }

    renderRowsPerPageComponent()
    {
        if (!this.props.gridViewOptions.paginationEnabled || !this.props.gridViewOptions.displayPaginationControl)
            return null;

        return <GridPageSize marginRight='50px' gridPageSize={this.state.gridPageSize}
            onChange={(value) => 
            {
                this.state.gridPageSize = value;

                if (this.gridApi != null)
                {
                    this.gridApi.paginationSetPageSize(Number(value));
                }
                this.setState({});
            }}></GridPageSize>
    }

    renderCustomSelectOnGridMode(disabled)
    {
        if (this.props.gridViewOptions.selectFilterData)
        {
            const selectFilterProps = this.props.gridViewOptions.selectFilterData.props || {};
            return <div style={{ fontSize: '15px', width: selectFilterProps.width ? `${selectFilterProps.width}px` : `100px`, marginRight: '5px' }}>
                <Select
                    {...selectFilterProps}
                    disabled={disabled}
                    value={this.state.selectFilterValue}
                    onChange={(value) =>
                    {
                        this.state.selectFilterValue = value;
                        this.setState({});
                    }} />
            </div>;
        }

        return null;
    }

    renderCustomButtonsOnGridMode(disabled)
    {
        if(this.props.gridViewOptions.isReadOnly) return null;

        var buttons = [];
        
        this.props.gridViewOptions.buttons.forEach(customButton =>
        {
            buttons.push(
                <Button
                    key={customButton.title}
                    className='me-1'
                    color={customButton.color}
                    disabled={disabled}
                    onClick={
                        () => this.onCustomButtonClick(customButton)
                    }>
                    {customButton.title}
                </Button>);
        });

        if (buttons.length > 0) 
        {
            return <div className='me-3'>{buttons}</div>
        }

        return null;
    }

    renderCopyPasteComponentOnGridMode(disabled)
    {
        if (this.props.gridViewOptions.copyPasteConfiguration == null || this.props.gridViewOptions.isReadOnly) return null;

        const copyPasteConfiguration = this.props.gridViewOptions.copyPasteConfiguration;

        return (
            <div className='me-3'>

                <ClipboardComponent
                    disabled={disabled}
                    dataType={copyPasteConfiguration.dataType}
                    onCopy={() =>
                    {
                        if (this.gridApi == null) return null;

                        let dataRows = [];

                        this.gridApi.forEachNodeAfterFilterAndSort((node, index) => { dataRows.push(node.data) });

                        if (copyPasteConfiguration.onCopy)
                        {
                            // Allow client to negotiate/process data before copying to the clipboard
                            dataRows = copyPasteConfiguration.onCopy(dataRows);
                        }

                        if (dataRows == null || dataRows.length === 0) return null;

                        viewUtil.showInfoAlert(`Copying ${dataRows.length} rows to clipboard ...`);

                        return dataRows;
                    }}
                    onPaste={async (dataRows) =>
                    {
                        if (this.gridApi == null) return;

                        if (copyPasteConfiguration.onPaste) 
                        {
                            // Allow client to negotiate/process data before adding to the grid
                            dataRows = await copyPasteConfiguration.onPaste(dataRows);
                        }

                        if (dataRows == null || dataRows.length === 0) return;

                        this.gridApi.updateRowData({ add: dataRows });
                        this.state.remoteData.push(...dataRows);
                    }}
                />
            </div>
        );
    }

    onCustomButtonClick(customButton)
    {
        if(customButton.onRowSelect) {
            var selectedRow = this.findSelectedRow();
            if (selectedRow == null && customButton.onRowSelect)
                return null;
        }

        if (customButton.actionType == "grid")
        {
            this.onCustomGridActionButtonClick(this.gridApi, customButton, true)
            return
        }

        if (customButton.actionType == "component")
        {
            this.onCustomComponentButtonClick(selectedRow, customButton);
            return;
        }

        if (customButton.actionType == "action")
        {
            this.onCustomActionButtonClick(selectedRow, customButton, true);
            return;
        }

        if (customButton.actionType === "user")
        {
            this.onCustomUserActionButtonClick(this.gridApi, customButton, false);
            return;
        }
    }

    onCustomComponentButtonClick(selectedRow, customButton)
    {
        if (customButton.rowTransform)
            this.state.addEditRow = customButton.rowTransform(selectedRow, customButton)
        else
            this.state.addEditRow = typeUtil.deepCloneObject(selectedRow);

        this.state.viewState = 'custom.edit';
        this.state.customButton = customButton;
        this.state.lastSelectedRowId = null;

        if (this.props.gridViewOptions.getPrimaryKeyValue)
            this.state.lastSelectedRowId = this.props.gridViewOptions.getPrimaryKeyValue(selectedRow);

        this.saveGridState();   // save grid state

        this.setState({});

    }

    onCustomActionButtonClick(selectedRow, customButton, doNeedConfirmation)
    {
        if (!customButton.onAction)
            return;

        if (doNeedConfirmation)
        {
            if (customButton.onActionConfirmationMessage)
            {
                viewUtil.showConfirmDialogue(customButton.actionConfirmationTitle, customButton.onActionConfirmationMessage(selectedRow), () =>
                {
                    this.onCustomActionButtonClick(selectedRow, customButton, false);
                });

                return;
            }
            else if (!stringUtil.isStringNullOrEmpty(customButton.actionConfirmationMessage))
            {
                viewUtil.showConfirmDialogue(customButton.actionConfirmationTitle, customButton.actionConfirmationMessage, () =>
                {
                    this.onCustomActionButtonClick(selectedRow, customButton, false);
                });

                return;
            }
        }

        //
        // Setup action defaults to avoid error
        //
        const onActionWaitMessage = customButton.onActionWaitMessage || (() => "Please wait");
        const onActionSuccessMessage = customButton.onActionSuccessMessage || (() => "Action succeeded");
        const onActionFailMessage = customButton.onActionFailMessage || ((_1, _2, error) => `Action failed: ${error}`);

        var remoteData = null;
        if (this.props.gridViewOptions.gridDataMode != "rows")
            remoteData = this.state.remoteData;

        viewUtil.showSystemModalSpinner(onActionWaitMessage(selectedRow, customButton));

        customButton.onAction(selectedRow, remoteData, customButton)
            .then((response) =>
            {
                if (this.props.gridViewOptions.gridDataMode != "rows")
                {
                    if (response != null && response.addUpdatedRow != null && this.props.gridViewOptions.getPrimaryKeyValue != null)
                        this.state.lastSelectedRowId = this.props.gridViewOptions.getPrimaryKeyValue(response.addUpdatedRow);

                    if (response != null && response.remoteData != null)
                        this.state.remoteData = response.remoteData;
                }
                else
                {
                    if (response != null && this.props.gridViewOptions.getPrimaryKeyValue != null)
                        this.state.lastSelectedRowId = this.props.gridViewOptions.getPrimaryKeyValue(response);
                }

                viewUtil.closeModalSpinner();
                this.state.addEditContainerInstructions.onUnloadHideAlert = false;
                viewUtil.showSuccessAlert(onActionSuccessMessage(selectedRow, customButton));

                this.setState({});
            },
                (error) =>
                {
                    viewUtil.closeModalSpinner();
                    viewUtil.showCriticalAlert(`${onActionFailMessage(selectedRow, customButton, error)}`);
                });
    }

    onCustomGridActionButtonClick(gridApi, customButton, doNeedConfirmation)
    {
        if (!customButton.onAction)
            return;

        if (doNeedConfirmation)
        {
            if (customButton.onActionConfirmationMessage)
            {
                viewUtil.showConfirmDialogue(customButton.actionConfirmationTitle, customButton.onActionConfirmationMessage(gridApi), () =>
                {
                    this.onCustomGridActionButtonClick(gridApi, customButton, false);
                });

                return;
            }
        }

        //
        // Setup action defaults to avoid error
        //
        const onActionWaitMessage = customButton.onActionWaitMessage || (() => "Please wait");
        const onActionSuccessMessage = customButton.onActionSuccessMessage || (() => "Action succeeded");
        const onActionFailMessage = customButton.onActionFailMessage || ((_1, _2, error) => `Action failed: ${error}`);

        viewUtil.showSystemModalSpinner(onActionWaitMessage());

        customButton.onAction(gridApi, customButton)
        .then((response) =>
        {
            viewUtil.closeModalSpinner();
            viewUtil.showSuccessAlert(onActionSuccessMessage(gridApi, customButton));
        },
        (error) =>
        {
            viewUtil.closeModalSpinner();
            viewUtil.showCriticalAlert(`${onActionFailMessage(gridApi, customButton, error)}`);
        });
    }

    onCustomUserActionButtonClick = async (gridApi, customButton, doNeedConfirmation) =>
    {
        if (!gridApi) return;
        if (!customButton.onAction) return;

        if (doNeedConfirmation)
        {
            if (customButton.onActionConfirmationMessage)
            {
                viewUtil.showConfirmDialogue(customButton.actionConfirmationTitle, customButton.onActionConfirmationMessage(gridApi), () =>
                {
                    this.onCustomUserActionButtonClick(gridApi, customButton, false);
                });

                return;
            }
        }

        await customButton.onAction(gridApi, customButton);
    }

    renderSearchBar()
    {
        var disabled = this.state.remoteDataStatus == 'searching';

        if (this.props.gridViewOptions.gridDataMode == "search")
        {
            return (
                <SearchBarComponent
                    style={{ marginLeft: '20px' }}
                    disabled={disabled}
                    searchDisableDate={this.props.gridViewOptions.searchDisableDate}
                    searchCriteria={this.state.searchCriteria}
                    onSearch={this.onSearch} 
                />
            )
        }
    }

    async onSearch(searchCriteria)
    {
        this.saveGridState();   // save grid state

        this.state.searchCriteria = searchCriteria;

        if (this.props.gridViewOptions.onSearch)
            this.props.gridViewOptions.onSearch(searchCriteria)
                .then(results =>
                {
                    this.state.remoteData = results;
                    this.state.remoteDataStatus = 'complete';
                    this.setState({});
                }, error =>
                {
                    this.state.remoteDataStatus = 'error';
                    this.state.remoteDataError = error;
                    this.setState({});
                });

        this.state.remoteData = [];
        this.state.remoteDataError = null;
        this.state.remoteDataStatus = 'searching';
        this.setState({});
    }

    renderHeaderOnComponentMode()
    {
        var saveButton = null;
        var cancelButton = null;

        var isNewRow = this.state.viewState == 'add';
        var customButton = this.state.viewState == 'custom.edit' ? this.state.customButton : null;

        if (isNewRow)
        {
            saveButton = <Button color="success" className="btn-success" style={{ marginRight: '3px' }} onClick={this.onSaveClicked}>
                <i className="fa fa-save"></i>&nbsp;Save
            </Button>;

            cancelButton = <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={this.onCancelClicked}>
                Cancel
            </Button>;
        }
        else
        {
            if (this.props.gridViewOptions.isReadOnly || (this.props.gridViewOptions.isRowReadOnly && this.props.gridViewOptions.isRowReadOnly(this.state.addEditRow, customButton)))
            {
                saveButton = null;
                cancelButton = <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={this.onCancelClicked}>
                    Close
                </Button>;
            }
            else
            {

                saveButton = <Button color="success" className="btn-success" style={{ marginRight: '3px' }} onClick={this.onSaveClicked}>
                    <i className="fa fa-save"></i>&nbsp;Save
                </Button>;

                cancelButton = <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={this.onCancelClicked}>
                    Cancel
                </Button>;
            }
        }



        var title = this.props.gridViewOptions.title;

        if (this.props.gridViewOptions.getComponentTitle != null)
            title = this.props.gridViewOptions.getComponentTitle(isNewRow, this.state.addEditRow, customButton);

        if (this.state.displayPrimaryKeyInHeader && this.props.gridViewOptions.getPrimaryKeyValue)
        {
            if (this.props.gridViewOptions.getPrimaryKeyValue)
            title = `${title}  -  [${this.props.gridViewOptions.getPrimaryKeyValue(this.state.addEditRow)}]`;
        }
        
        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }} onDoubleClick={(event)=>
                        {
                            if (event.shiftKey)
                            {
                                this.state.displayPrimaryKeyInHeader = !this.state.displayPrimaryKeyInHeader;
                                this.setState({});
                            }
                        }}>{title}</div>
                    </td>
                    <td align="right">

                        {this.renderExternalIntegrationLogsButton(isNewRow, this.state.addEditRow, customButton)}
                        {this.renderAttachmentButton(isNewRow, this.state.addEditRow, customButton)}
                        {saveButton}
                        {cancelButton}

                    </td>
                </tr>

            </table>
        );
    }

    renderAttachmentButton(isNew, row, customButton)
    {
        if (this.props.gridViewOptions.getAttachmentsParam == null)
            return null;

        let isReadOnly = false;
        if (this.props.gridViewOptions.isAttachmentsReadOnly != null) 
        {
            isReadOnly = this.props.gridViewOptions.isAttachmentsReadOnly(row);
        }

        return <AttachmentButton isReadOnly={isReadOnly} fileParam={this.props.gridViewOptions.getAttachmentsParam(isNew, row, customButton)} />;
    }

    renderExternalIntegrationLogsButton(isNew, row, customButton)
    {
        if (this.props.gridViewOptions.getExternalIntegrationLogsParam == null)
            return null;

        const params = this.props.gridViewOptions.getExternalIntegrationLogsParam(isNew, row, customButton);

        if (params != null) 
        {
            return <span className='me-2'><ExternalIntegrationLogsButton params={params} /></span>;
        }
    }

    renderBody()
    {

        var dataComponent = null;

        if (this.state.viewState == 'grid')
            dataComponent = this.renderBodyOnGridMode();
        else
            dataComponent = this.renderBodyOnComponentMode();

        return (
            <div>
                {dataComponent}
            </div>
        );
    }

    renderBodyOnGridMode()
    {
        if (this.props.gridViewOptions.gridDataMode == "rows")
            return this.renderBodyOnGridModeWithData(this.props.gridViewOptions.getRowData());

        if (this.props.gridViewOptions.gridDataMode == "search")
        {
            if (this.state.remoteDataStatus == 'searching')
                return (<div>
                    <Row className="justify-content-center">
                        <ScaleLoader
                            height={50}
                            width={6}
                            color={"#808080"}
                            loading={true} />
                    </Row>
                    <Row className="justify-content-center" style={{ margin: '5px' }}>
                        <h4 className="text-muted">Loading Data</h4>
                    </Row>
                </div>)

            if (this.state.remoteDataStatus == 'error')
                return (<div>
                    <Row className="justify-content-center" style={{ margin: '5px' }}>
                        <h5 className="text-muted">{this.state.remoteDataError}</h5>
                    </Row>
                </div>);

            return this.renderBodyOnGridModeWithData(this.state.remoteData);
        }

        if (this.props.gridViewOptions.gridDataMode == "async")
        {
            if (this.state.remoteDataStatus == 'searching')
                return (<div>
                    <Row className="justify-content-center">
                        <ScaleLoader
                            height={50}
                            width={6}
                            color={"#808080"}
                            loading={true} />
                    </Row>
                    <Row className="justify-content-center" style={{ margin: '5px' }}>
                        <h4 className="text-muted">Loading Data</h4>
                    </Row>
                </div>)

            if (this.state.remoteDataStatus == 'error')
                return (<div>
                    <Row className="justify-content-center" style={{ margin: '5px' }}>
                        <h5 className="text-muted">{this.state.remoteDataError}</h5>
                    </Row>
                </div>);


            if (this.state.remoteDataStatus == 'none')
            {
                this.props.gridViewOptions.getRowDataAsync()
                    .then(results =>
                    {
                        this.state.remoteData = results;
                        this.state.remoteDataStatus = 'complete';
                        this.setState({});
                    }, error =>
                    {
                        this.state.remoteDataStatus = 'error';
                        this.state.remoteDataError = error.toString();
                        this.setState({});
                    });

                this.state.remoteData = [];
                this.state.remoteDataError = null;
                this.state.remoteDataStatus = 'searching';
                this.setState({});
                return;
            }

            return this.renderBodyOnGridModeWithData(this.state.remoteData);
        }
    }

    renderBodyOnGridModeWithData(rows)
    {
        var pinnedRows = null;
        if (this.props.gridViewOptions.getPinnedRows)
            pinnedRows = this.props.gridViewOptions.getPinnedRows(rows);

        return (<GridComponent headers={this.props.gridViewOptions.getColumnDefinitions()} rows={rows}
            gridMode="controlled" //Controlled indicates data rows are updated, selection is managed by composer ... gridView
            shouldReArrangeHeaders={this.props.gridViewOptions.shouldReArrangeHeaders}
            pinnedRows={pinnedRows}
            onGridReady={this.onGridReady}
            onRowDoubleClicked={this.onRowDoubleClicked}
            gridOptions={this.getGridOptions()} />);
    }

    getGridOptions = () =>
    {
        const gridViewOptions = this.props.gridViewOptions;

        if (gridViewOptions == null)
            return null;

        const gridOptions = {};

        if (gridViewOptions.rowHeight)
            gridOptions.rowHeight = gridViewOptions.rowHeight;

        if (gridViewOptions.headerHeight)
            gridOptions.headerHeight = gridViewOptions.headerHeight;

        if (gridViewOptions.fontSize)
            gridOptions.fontSize = gridViewOptions.fontSize;

        if (gridViewOptions.rowSelection)
            gridOptions.rowSelection = gridViewOptions.rowSelection;

        if (gridViewOptions.paginationEnabled)
            gridOptions.pagination = gridViewOptions.paginationEnabled;

        if (gridViewOptions.pageSize)
            gridOptions.paginationPageSize = gridViewOptions.pageSize;

        if (gridViewOptions.rowClass)
            gridOptions.rowClass = gridViewOptions.rowClass;

        if (gridViewOptions.headerRowClass)
            gridOptions.headerRowClass = gridViewOptions.headerRowClass;

        if (gridViewOptions.rowClassRules)
            gridOptions.rowClassRules = gridViewOptions.rowClassRules;

        if (gridViewOptions.getRowClassRules)
            gridOptions.getRowClassRules = gridViewOptions.getRowClassRules;

        if (gridViewOptions.rowStyle)
            gridOptions.rowStyle = gridViewOptions.rowStyle;

        if (gridViewOptions.getRowStyle)
            gridOptions.getRowStyle = gridViewOptions.getRowStyle;

        if (gridViewOptions.noRowsOverlayComponent)
            gridOptions.noRowsOverlayComponent = gridViewOptions.noRowsOverlayComponent;

        gridOptions.animateRows = true;
        if (gridViewOptions.animateRows != null)
            gridOptions.animateRows = gridViewOptions.animateRows;

        // External filter in Ag-Grid Docs: 
        gridOptions.isExternalFilterPresent = () => gridViewOptions.selectFilterData;
        gridOptions.doesExternalFilterPass = this.doesExternalFilterPass;
    
        return gridOptions;
    }

    doesExternalFilterPass(node) {
        return this.props.gridViewOptions.selectFilterData.doesFilterPass(node.data, this.state.selectFilterValue);
    }

    renderBodyOnComponentMode()
    {
        var isNewRow = this.state.viewState == 'add';
        var customButton = this.state.viewState == 'custom.edit' ? this.state.customButton : null;

        return (<GridViewAddEditContainerComponent instructions={this.state.addEditContainerInstructions} childComponent={this.props.gridViewOptions.getComponent(isNewRow, this.state.addEditRow, customButton)}></GridViewAddEditContainerComponent>);
    }

    onAddClicked()
    {
        let rows;
        if (this.props.gridViewOptions.gridDataMode != "rows")
            rows = this.state.remoteData;
        else
            rows = this.props.gridViewOptions.getRowData();

        var newRow = this.props.gridViewOptions.getNewRow(rows);
        this.state.addEditRow = newRow;
        this.state.viewState = 'add';
        this.state.displayPrimaryKeyInHeader = false;
        this.saveGridState();   // save grid state
        this.setState({});
    }

    onRowDoubleClicked(eventData)
    {
        var selectedRow = this.findSelectedRow();
        if (this.props.gridViewOptions.getComponent == null || this.state.viewState != 'grid' || selectedRow == null)
            return;

        this.state.addEditRow = typeUtil.deepCloneObject(selectedRow);
        this.state.viewState = 'edit';
        this.state.displayPrimaryKeyInHeader = false;
        this.state.lastSelectedRowId = null;

        if (this.props.gridViewOptions.getPrimaryKeyValue)
            this.state.lastSelectedRowId = this.props.gridViewOptions.getPrimaryKeyValue(selectedRow);

        this.saveGridState();   // save grid state

        this.setState({});
    }

    onCancelClicked()
    {
        this.state.addEditRow = null;
        this.state.viewState = 'grid';
        this.state.displayPrimaryKeyInHeader = false;
        this.state.customButton = null;

        this.state.addEditContainerInstructions.onUnloadHideAlert = true;

        this.setState({});
    }

    onSaveClicked()
    {
        var isNewRow = true;
        var error = null;
        var customButton = null;

        if (!this.props.gridViewOptions.validate)
        {
            viewUtil.showCriticalAlert("No validate method defined to validate.");
            return;
        }

        if (this.state.viewState == "edit" || this.state.viewState == "custom.edit")
            isNewRow = false;

        if (this.state.viewState == "custom.edit")
            customButton = this.state.customButton;

        let rows;
        if (this.props.gridViewOptions.gridDataMode != "rows")
            rows = this.state.remoteData;
        else
            rows = this.props.gridViewOptions.getRowData();

        error = this.props.gridViewOptions.validate(isNewRow, this.state.addEditRow, customButton, rows);

        if (error)
        {
            viewUtil.showErrorAlert(error);
            return;
        }

        if (!this.props.gridViewOptions.save)
        {
            viewUtil.showCriticalAlert("No save method defined to save.");
            return;
        }

        viewUtil.showSystemModalSpinner(this.getWaitMessage(isNewRow, this.state.addEditRow, customButton));

        var remoteData = null;
        if (this.props.gridViewOptions.gridDataMode != "rows")
            remoteData = this.state.remoteData;


        this.props.gridViewOptions.save(isNewRow, this.state.addEditRow, remoteData, customButton)
            .then((response) =>
            {

                if (this.props.gridViewOptions.gridDataMode != "rows")
                {
                    if (response != null && response.addUpdatedRow != null && this.props.gridViewOptions.getPrimaryKeyValue != null)
                        this.state.lastSelectedRowId = this.props.gridViewOptions.getPrimaryKeyValue(response.addUpdatedRow);

                    if (response != null && response.remoteData != null)
                        this.state.remoteData = response.remoteData;
                }
                else
                {
                    if (response != null && this.props.gridViewOptions.getPrimaryKeyValue != null)
                        this.state.lastSelectedRowId = this.props.gridViewOptions.getPrimaryKeyValue(response);
                }

                viewUtil.closeModalSpinner();
                this.state.addEditContainerInstructions.onUnloadHideAlert = false;
                viewUtil.showSuccessAlert(this.getSuccessSaveMessage(isNewRow, this.state.addEditRow, customButton));

                this.state.addEditRow = null;
                this.state.viewState = 'grid';
                this.state.customButton = null;
                this.setState({});
            },
                (error) =>
                {
                    viewUtil.closeModalSpinner();
                    viewUtil.showCriticalAlert(`${this.getFailedSaveMessage(isNewRow, this.state.addEditRow, customButton)} ${error}`);
                });
    }

    findSelectedRow()
    {
        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    selectLastSelectedRow()
    {
        if (this.state.lastSelectedRowId == null)
            return;

        if (!this.props.gridViewOptions.getPrimaryKeyValue)
        {
            this.state.lastSelectedRowId = null;
            return;
        }

        var selectedIndex = -1;
        this.gridApi.forEachNode((node, index) =>
        {
            if (this.props.gridViewOptions.getPrimaryKeyValue(node.data) == this.state.lastSelectedRowId)
            {
                node.setSelected(true);
                selectedIndex = index;
            }
        })

        if (selectedIndex >= 0)
            this.gridApi.ensureIndexVisible(selectedIndex, 'middle');
    }


    getWaitMessage(isNew, addEditRow, customButton)
    {
        var message = null;
        if (this.props.gridViewOptions.getWaitMessage)
            message = this.props.gridViewOptions.getWaitMessage(isNew, addEditRow, customButton);
        else if (this.props.gridViewOptions.messageContext != null)
        {
            const context = this.props.gridViewOptions.messageContext;
            if (isNew)
                message = `Please wait while adding new ${context}.`;

            message = `Please wait while updating ${context}.`;
        }

        if (stringUtil.isStringNullOrEmpty(message))
            message = isNew ? "Please wait, while adding record" : "Please wait, while updating record";

        return sentenceCase(message);
    }

    getSuccessSaveMessage(isNew, addEditRow, customButton)
    {
        var message = null;
        if (this.props.gridViewOptions.getSaveSuccessMessage)
            message = this.props.gridViewOptions.getSaveSuccessMessage(isNew, addEditRow, customButton);
        else if (this.props.gridViewOptions.messageContext != null)
        {
            const context = this.props.gridViewOptions.messageContext;
            if (isNew)
                message = `New ${context} is added successfully.`;

            message = `${context} is updated successfully.`;
        }

        if (stringUtil.isStringNullOrEmpty(message))
            message = isNew ? "Record is addedd successfully" : "Record is updated successfully";

        return sentenceCase(message);
    }

    getFailedSaveMessage(isNew, addEditRow, customButton)
    {
        var message = null;
        if (this.props.gridViewOptions.getSaveErrorMessage)
            message = this.props.gridViewOptions.getSaveErrorMessage(isNew, addEditRow, customButton);
        else if (this.props.gridViewOptions.messageContext != null)
        {
            const context = this.props.gridViewOptions.messageContext;
            if (isNew)
                message = `Following error occurred while adding new ${context}:`;

            message = `Following error occurred while updating ${context}:`;
        }

        if (stringUtil.isStringNullOrEmpty(message))
            message = isNew ? "Following error occurred while adding new record:" : "Following error occurred while updating new record:";

        return sentenceCase(message);
    }
}

export default GridView;
