
import React, { Component, Fragment } from 'react';

import { getEnvironmentLoadingStages } from './utils/loadingUtils';
import { CloudinaryContext, Image } from 'cloudinary-react';

const stringUtil = require('../../utils/string/stringUtil');
const actionTypes = require('../../utils/state/actionTypes');
const actionDispatcher = require('../../utils/state/actionDispatcher');

const configurationManager = require('../../utils/config/configurationManager');
const cloudName = configurationManager.getConfig().cdnAppCloudName;

const flipdishTheme = require('../../utils/domain/flipdishTheme');

class LoadingView extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            loadingStages: getEnvironmentLoadingStages(),
            error: null
        }
    }

    render()
    {
        if (!stringUtil.isStringNullOrEmpty(this.state.error))
            return this.renderErrorStage();

        const loadingStage = this.state.loadingStages.find(stage => !stage.isLoaded);

        if (loadingStage != null)
        {
            return this.renderLoadingComponent(loadingStage);
        }

        
        actionDispatcher.dispatch({ type: actionTypes.environmentInitialized, payload: true });
        return <Fragment/>
        
    }

    renderLoadingComponent(loadingStage)
    {
        this.loadStage(loadingStage);

        const imageUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}/loadng.gif`;

        return (
            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100%", width: '100%' }}>

                <div
                    className='d-flex justify-content-center align-items-center border rounded-circle'
                    style={{ width: '220px', height: '220px' }}>
                    <img src={imageUrl} alt="" style={{ width: '60%', height: '60%' }} />
                </div>

                <div className='mt-2 fs-3 fw-bold' style={{ color: flipdishTheme.color}}>
                    Hold On
                </div>

                <div className='text-muted'>
                    <small>Checking for updates...</small>
                </div>

            </div>
        );
    }


    async loadStage(stage)
    {
        try
        {
            await stage.loadData();
            stage.isLoaded = true;
            this.setState({});
        } catch (error)
        {
            this.setState({ error: `${stage.label} failed with following error:\n\n"${error}"` });
        }
    }

    renderErrorStage()
    {
        return (
            <div className="d-flex flex-column align-items-center" style={{ backgroundColor: '#F6F9FF', height: "100%", width: '100%' }} >
                <CloudinaryContext cloudName={cloudName}>
                    <div className='mt-5 pt-5'>
                        <Image publicId={flipdishTheme.logoImageUrl} height="150px" />
                    </div>
                </CloudinaryContext>
                <div className='flex-grow-1 d-flex justify-content-center align-items-center'>
                    <h3 style={{ color: "#CA0030", whiteSpace: "pre-line" }}>{this.state.error}</h3>
                </div>
            </div>
        );
    }
}

export default LoadingView;