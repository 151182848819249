import React, { Component } from 'react';

import PieChartReport from '../../../../../../reportUtils/chartComponents/PieChartReport';

const biUtils = require('../../../../../../../../utils/bi/biUtils');
const { formatCurrencyValue: currencyFormatter, formatPercentage: percentageFormatter } = biUtils;

export default class SalesByPaymentMethodCharts extends Component
{
    render()
    {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: "WhiteSmoke" }}>

                <div className='w-50 d-flex justify-content-center align-items-center'>
                    {this.renderSalesByPaymentMethodChart()}
                </div>

            </div>
        );
    }

    renderSalesByPaymentMethodChart()
    {
        const title = "Sales By Payment Method";
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const { salesByPaymentMethod } = this.props.data;

        const sumTotalAmount = salesByPaymentMethod.reduce((previous, current) => previous + current.netAmount, 0);

        if (sumTotalAmount === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{currencyFormatter(0)}</div>
                </div>
            );
        }

        const data = salesByPaymentMethod.map(data => ({
            label: data.paymentMethod,
            value: data.netAmount,
            percentage: data.netAmount / sumTotalAmount * 100
        }));

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={currencyFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }
}