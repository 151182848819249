import React from "react";

const Form = ({ onSubmit = () => {}, ...props }) => {
     const handleFormSubmit = (e) => {
          e.preventDefault();
          const formValues = {};

          e.target.querySelectorAll("input, select, textarea").forEach((element) => {
               const name = element.name;
               if (name === "") return;

               let value;
               if (element.type === "checkbox") {
                    value = element.checked;
               } else if (element.type === "number") {
                    value = parseFloat(element.value);
               } else {
                    value = element.value;
               }
              
               switch (value) {
                    case "":
                         formValues[name] = null;
                         break;
                    case "true":
                         formValues[name] = true;
                         break;
                    case "false":
                         formValues[name] = false;
                         break;
                    default:
                         formValues[name] = value;
               }
          });
          onSubmit(formValues);
     };

     return <form onSubmit={handleFormSubmit}>{props.children}</form>;
};

export default Form;
