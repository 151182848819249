import React, { Component } from 'react';
import CdnImage from '../../cdnImage/cdnImage';

const stringUtil = require('../../../utils/string/stringUtil');

export default class ImageRenderer extends Component
{
  render()
  {
    let imageId;
    let cloudName;

    if (!stringUtil.isStringNullOrEmpty(this.props.value))
    {
      imageId = this.props.value;
      cloudName = this.props.cloudName;
    }
    else
    {
      imageId = this.props.defaultImage;
      cloudName = this.props.defaultCloudName;
    }

    if(stringUtil.isStringNullOrEmpty(cloudName) || stringUtil.isStringNullOrEmpty(imageId))
      return null;

    return (<CdnImage
      publicId={imageId}
      cloudName={cloudName}
      width={30} height={30} radius={0} />);
  }
}