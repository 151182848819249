
import React, { Component } from 'react';
import { connect } from 'react-redux'

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var stringUtil = require('../../utils/string/stringUtil.js');

class AlertContainer extends Component 
{
    constructor(props) 
    {
        super(props);
        this.handleToast = this.handleToast.bind(this);
    }

    render()
    {
        const containerStyle = 
        {
            zIndex: 1999,
            width:'400px',
            marginLeft: '-200px'
            
        };

        return (<ToastContainer position="top-center" autoClose={5000} style={containerStyle}/>);

    }

    componentDidUpdate ()
    {
        this.handleToast();
    }

    handleToast()
    {
        toast.dismiss();
        if (this.props.alertDialogue == null || this.props.alertDialogue.type == null || stringUtil.isStringNullOrEmpty(this.props.alertDialogue.message))
            return;


        var options = {};
        var alertIconClass = "";
        options.autoClose = 6000;

        if (this.props.alertDialogue.type == "critical")
        {
            options.type = toast.TYPE.ERROR;
            options.autoClose = false;
            alertIconClass = "fa fa-exclamation-triangle";
        }

        if (this.props.alertDialogue.type == "error")
        {
            options.type = toast.TYPE.ERROR;
            alertIconClass = "fa fa-exclamation-circle fa-lg";
        }

        if (this.props.alertDialogue.type == "warning")
        {
            options.type = toast.TYPE.WARNING;
            alertIconClass = "fa fa-exclamation fa-lg";
        }

        if (this.props.alertDialogue.type == "success")
        {
            options.type = toast.TYPE.SUCCESS;
            alertIconClass = "fa fa-check fa-lg";
        }

        if (this.props.alertDialogue.type == "info")
        {
            options.type = toast.TYPE.INFO;
            alertIconClass = "fa fa-info fa-lg";
        }


        var component = <table>
                            <tr>
                            <td>
                                <i className={alertIconClass} style={{marginRight:'5px'}}></i>
                            </td>
                            <td>
                                {this.props.alertDialogue.message}
                            </td>  
                            </tr>
                        </table>

        toast(component, options);
    }
}

const mapStateToProps = state => 
{
    return { 
                alertDialogue: state.alertDialogue
           }
}

export default connect(mapStateToProps)(AlertContainer)