import React from "react";

const CreditCardReaderIcon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 120 181" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_1_24942)">
                    <path
                         d="M99.8086 100.474H19.4671C7.7142 100.474 1.41362 97.0992 0.206524 90.1542C-0.480626 86.1965 0.559274 81.0502 3.3874 74.4239C3.68917 73.7174 4.48847 73.3967 5.17562 73.7069C5.86277 74.0172 6.17474 74.8389 5.87296 75.5454C3.26709 81.6497 2.28836 86.2678 2.87764 89.6637C3.47711 93.1058 5.84646 97.6819 19.4671 97.6819H99.8086C113.427 97.6819 115.799 93.1058 116.398 89.6637C116.987 86.2678 116.009 81.6497 113.403 75.5454C106.962 60.4648 89.3342 18.6673 89.1568 18.246C89.1405 18.2082 89.1262 18.1705 89.114 18.1328C89.0446 17.9357 87.181 12.9466 77.8239 12.9466H41.4518C31.9541 12.9466 30.1781 18.0804 30.1618 18.1328C30.1495 18.1705 30.1353 18.2082 30.119 18.246C30.015 18.4954 19.5426 43.3257 11.8249 61.5402C11.5272 62.2466 10.7279 62.5716 10.0387 62.2613C9.35153 61.9532 9.03752 61.1314 9.33726 60.425C16.9143 42.5459 27.1461 18.2858 27.615 17.1727C27.9066 16.3698 30.5288 10.1565 41.4539 10.1565H77.8259C88.751 10.1565 91.3711 16.3698 91.6648 17.1727C92.2867 18.6485 109.537 59.5445 115.89 74.4218C118.721 81.0481 119.76 86.1944 119.071 90.1522C117.864 97.0971 111.564 100.472 99.8107 100.472L99.8086 100.474Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.53905"
                    />
                    <path d="M88.2109 35.244C88.2109 35.244 88.2048 35.244 88.2007 35.244H31.0735C30.6188 35.244 30.1947 35.0092 29.9439 34.6214C29.6931 34.2315 29.6462 33.7389 29.8236 33.3092C32.827 25.9618 33.9261 23.2618 34.0566 22.9431C34.3155 22.2178 36.4769 17.04 45.261 17.04H74.0112C82.7994 17.04 84.9628 22.2199 85.2217 22.941C85.3502 23.2555 86.4329 25.9198 89.3915 33.154C89.5057 33.3574 89.571 33.5964 89.571 33.8479C89.571 34.6193 88.9633 35.244 88.213 35.244H88.2109ZM33.1145 32.4539H86.1576C83.562 26.1043 82.7015 23.985 82.7015 23.985C82.6872 23.9493 82.675 23.9137 82.6628 23.8781C82.6118 23.7313 81.1743 19.8323 74.0091 19.8323H45.259C38.0959 19.8323 36.6624 23.7313 36.6053 23.8969C36.5972 23.92 36.5809 23.964 36.5727 23.9871C36.5727 23.9871 35.7123 26.1085 33.1166 32.456L33.1145 32.4539Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path
                         d="M91.3873 91.3051H27.884C20.5639 91.3051 15.9211 89.6197 13.6904 86.1545C11.3679 82.5469 11.7553 77.3146 14.9097 69.6863C17.5197 63.3681 22.2461 51.8324 24.8316 45.5184C26.0795 42.4704 28.6996 36.06 29.8231 33.3076C30.0352 32.7899 30.5286 32.4524 31.0751 32.4524H88.2023C88.7488 32.4524 89.2422 32.7899 89.4543 33.3076C90.5778 36.0579 93.1979 42.4704 94.4458 45.5184C98.791 56.1318 102.408 64.9403 104.368 69.6863C107.518 77.3146 107.903 82.5469 105.581 86.1545C103.35 89.6197 98.7074 91.3051 91.3873 91.3051ZM31.9763 35.2446C30.7264 38.303 28.4651 43.8372 27.3314 46.6022C24.7459 52.9161 20.0175 64.454 17.4075 70.7763C14.6813 77.3712 14.2062 81.897 15.9537 84.6138C17.6196 87.2006 21.6324 88.5149 27.882 88.5149H91.3873C97.6369 88.5149 101.652 87.2027 103.318 84.6138C105.067 81.897 104.592 77.3712 101.87 70.7763C99.1845 64.2758 94.4988 52.8407 91.9439 46.6022C90.8102 43.8372 88.549 38.303 87.299 35.2446H31.9763Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.53905"
                    />
                    <path
                         d="M104.586 144.01C104.476 144.01 104.366 143.995 104.256 143.968C103.832 143.859 103.483 143.544 103.322 143.125C99.2419 132.47 94.8335 120.97 93.5958 117.746H25.674C24.4383 120.97 20.0279 132.47 15.9479 143.125C15.7868 143.549 15.434 143.863 15.0038 143.97C14.5736 144.075 14.1209 143.962 13.7885 143.662C13.4215 143.331 13.0361 142.981 12.6467 142.609C4.42127 134.857 0.184182 91.8735 0.00882572 90.0477C-0.0299157 89.6557 0.0965036 89.2658 0.35342 88.9744C0.610337 88.683 0.975322 88.5153 1.35866 88.5153H1.54421C2.20077 88.5153 2.76354 88.9995 2.87977 89.6661C3.47924 93.1082 5.84858 97.6844 19.4692 97.6844H99.8107C113.429 97.6844 115.801 93.1082 116.4 89.6661C116.516 89.0016 117.079 88.5153 117.736 88.5153H117.921C118.305 88.5153 118.67 88.6809 118.927 88.9744C119.183 89.2658 119.308 89.6557 119.271 90.0477C119.096 91.8735 114.859 134.857 106.623 142.618C106.225 142.993 105.842 143.349 105.467 143.679C105.218 143.897 104.906 144.012 104.588 144.012L104.586 144.01ZM24.7503 114.958H94.5236C95.0802 114.958 95.5818 115.308 95.7857 115.84C95.7857 115.84 100.533 128.219 105.126 140.213C110.265 134.512 114.123 110.526 115.862 96.1918C112.724 99.0637 107.406 100.472 99.8107 100.472H19.4692C11.8739 100.472 6.55612 99.0616 3.41807 96.1918C5.15735 110.522 9.01314 134.497 14.1494 140.209C18.7433 128.216 23.4882 115.84 23.4882 115.84C23.6921 115.308 24.1937 114.958 24.7503 114.958Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.53905"
                    />
                    <path d="M21.0935 127.284C20.3431 127.284 19.7355 126.659 19.7355 125.888V113.123C19.7355 113.052 19.7416 112.981 19.7518 112.912C20.0638 110.83 22.1803 106.891 27.969 106.891H59.635C60.3854 106.891 60.993 107.516 60.993 108.287C60.993 109.059 60.3854 109.683 59.635 109.683H27.969C23.4037 109.683 22.5677 112.687 22.4494 113.251V125.89C22.4494 126.661 21.8418 127.286 21.0914 127.286L21.0935 127.284Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M98.1794 127.426C97.4291 127.426 96.8214 126.802 96.8214 126.03V113.256C96.6889 112.637 95.8203 109.683 91.3018 109.683H59.6358C58.8855 109.683 58.2778 109.059 58.2778 108.287C58.2778 107.516 58.8855 106.891 59.6358 106.891H91.3018C97.0886 106.891 99.2071 110.828 99.5191 112.912C99.5293 112.983 99.5354 113.052 99.5354 113.123V126.03C99.5354 126.802 98.9278 127.426 98.1774 127.426H98.1794Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M12.1899 58.5739C12.0247 58.5739 11.8575 58.5425 11.6964 58.4775C10.9991 58.1966 10.6545 57.3874 10.9277 56.6705C10.9848 56.5217 16.5799 41.6927 15.9763 33.0456C15.3075 23.453 21.4327 8.39136 23.1394 5.20503C25.2416 1.27243 26.5629 -1.90735e-06 34.2011 -1.90735e-06H49.9566C50.707 -1.90735e-06 51.3146 0.624686 51.3146 1.39611C51.3146 2.16754 50.707 2.79223 49.9566 2.79223H34.2011C27.5253 2.79223 27.0992 3.58881 25.5149 6.55084C23.953 9.46674 18.0643 24.0002 18.6821 32.8464C19.3305 42.1224 13.6947 57.0562 13.454 57.6872C13.244 58.2385 12.7322 58.5739 12.1899 58.5739Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M107.084 58.5739C106.541 58.5739 106.03 58.2364 105.82 57.6872C105.579 57.0562 99.9431 42.1224 100.592 32.8464C101.209 24.0002 95.3186 9.46674 93.7588 6.55084C92.1724 3.58671 91.7463 2.79223 85.0726 2.79223H62.627C61.8767 2.79223 61.269 2.16754 61.269 1.39611C61.269 0.624686 61.8767 -1.90735e-06 62.627 -1.90735e-06H85.0726C92.7107 -1.90735e-06 94.032 1.27243 96.1383 5.20503C97.843 8.39136 103.97 23.4509 103.299 33.0456C102.696 41.6927 108.291 56.5217 108.348 56.6705C108.621 57.3874 108.277 58.1987 107.579 58.4775C107.416 58.5425 107.249 58.5739 107.086 58.5739H107.084Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M63.5538 46.7936H55.1285C52.4085 46.7936 51.0138 45.7098 50.865 43.4794C50.865 43.4521 50.8629 43.4249 50.8629 43.3955C50.8629 43.3557 50.8629 43.2676 50.869 43.2257C51.0892 40.968 52.8979 37.8425 55.7219 37.8425H62.9584C65.7661 37.8425 67.5829 40.9492 67.8153 43.1943C67.8215 43.2614 67.8235 43.3263 67.8194 43.3934C67.691 45.6804 66.2963 46.7915 63.5558 46.7915L63.5538 46.7936ZM53.5789 43.3913C53.6176 43.9594 53.6666 44.1858 55.1285 44.1858H63.5538C64.9791 44.1858 65.0627 43.9909 65.1014 43.362C64.9158 41.9575 63.8168 40.4544 62.9564 40.4544H55.7199C54.8512 40.4544 53.7502 41.9763 53.5769 43.3913H53.5789Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M64.3091 58.3411H54.375C51.2778 58.3411 49.7566 57.1714 49.5894 54.6643C49.5894 54.637 49.5874 54.6098 49.5874 54.5804V54.5238C49.5874 54.484 49.5874 54.4441 49.5935 54.4022C49.8443 51.8385 51.8874 48.2853 55.0744 48.2853H63.6077C66.7764 48.2853 68.8296 51.8175 69.0927 54.3666C69.0988 54.4316 69.1008 54.4987 69.0968 54.5657C68.952 57.142 67.4309 58.3411 64.3112 58.3411H64.3091ZM52.3034 54.5699C52.3564 55.3497 52.44 55.7396 54.375 55.7396H64.3091C66.2319 55.7396 66.3298 55.3602 66.3787 54.5301C66.1687 52.8992 64.8495 50.8868 63.6057 50.8868H55.0724C53.8163 50.8868 52.4971 52.9265 52.3034 54.5699Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M65.2341 70.62H53.4506C49.8905 70.62 48.2185 69.402 48.0268 66.6685C48.0268 66.6412 48.0248 66.6161 48.0248 66.5888V66.5239C48.0248 66.4861 48.0248 66.4463 48.0309 66.4086C48.3123 63.6604 50.6979 59.6837 54.2805 59.6837H64.4002C67.6606 59.6837 70.3133 63.2076 70.6538 66.3687C70.66 66.4316 70.662 66.4945 70.6579 66.5574C70.4928 69.3643 68.8208 70.6179 65.2341 70.6179V70.62ZM50.7408 66.5721C50.808 67.5154 50.9059 68.1338 53.4506 68.1338H65.2341C67.7645 68.1338 67.8808 67.5301 67.9419 66.526C67.6871 64.5429 66.0293 62.1699 64.4002 62.1699H54.2805C52.635 62.1699 50.9773 64.5722 50.7428 66.5721H50.7408Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M66.4911 85.7605H52.1894C48.0563 85.7605 46.1152 84.3204 45.895 81.0922C45.895 81.0649 45.8929 81.0377 45.8929 81.0104V80.9307C45.8929 80.8909 45.8929 80.8511 45.8991 80.8113C46.2294 77.4887 49.0188 72.6798 53.1947 72.6798H65.4798C69.2805 72.6798 72.3798 76.9394 72.7835 80.763C72.7897 80.828 72.7917 80.893 72.7876 80.958C72.596 84.2785 70.6548 85.7605 66.4932 85.7605H66.4911ZM48.6089 80.9831C48.6946 82.2304 48.8108 83.2094 52.1894 83.2094H66.4911C69.8535 83.2094 69.9901 82.2556 70.0696 80.9224C69.7536 78.3209 67.6636 75.231 65.4777 75.231H53.1926C50.9885 75.231 48.8964 78.3607 48.6069 80.9831H48.6089Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M36.8355 70.3991H27.3112C26.1633 70.3991 24.5484 70.2692 23.7695 69.1414C22.9008 67.8836 23.6655 66.412 24.475 64.8524L24.7135 64.3912C25.8472 62.1881 27.0217 59.9073 31.4484 59.9073H40.9727C42.1206 59.9073 43.7355 60.0373 44.5145 61.1651C45.3831 62.4228 44.6184 63.8944 43.809 65.454L43.5724 65.911C42.4387 68.1163 41.2642 70.3991 36.8355 70.3991ZM26.1082 67.7893C26.2652 67.8396 26.6159 67.913 27.3112 67.913H36.8355C39.5433 67.913 40.0673 66.89 41.1154 64.8524L41.356 64.387C41.6659 63.7875 42.1206 62.9155 42.1737 62.5151C42.0167 62.4648 41.6659 62.3914 40.9706 62.3914H31.4464C28.7406 62.3914 28.2145 63.4144 27.1665 65.4498L26.9259 65.9173C26.6159 66.5168 26.1612 67.391 26.1082 67.7893Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M32.8904 85.4042H21.5188C20.2118 85.4042 18.3726 85.2553 17.5141 83.9787C16.5558 82.5532 17.4367 80.8071 18.3705 78.9582L18.654 78.3943C19.9732 75.7613 21.3373 73.0404 26.4573 73.0404H37.8289C39.1359 73.0404 40.9751 73.1892 41.8356 74.4679C42.7939 75.8934 41.9131 77.6396 40.9772 79.4885L40.6958 80.0482C39.3765 82.6832 38.0124 85.4063 32.8904 85.4063V85.4042ZM19.8142 82.6098C19.908 82.6664 20.3076 82.853 21.5188 82.853H32.8904C36.2894 82.853 36.9827 81.4695 38.2408 78.9602L38.5263 78.3922C38.932 77.5872 39.6049 76.2561 39.5335 75.8326C39.4397 75.776 39.0401 75.5894 37.8289 75.5894H26.4573C23.0582 75.5894 22.367 76.9709 21.1089 79.4801L20.8214 80.0503C20.4157 80.8553 19.7428 82.1864 19.8142 82.6078V82.6098Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M40.3932 58.3309H31.9475C30.8934 58.3309 29.4069 58.203 28.6769 57.0962C27.8613 55.8615 28.5893 54.3941 29.2927 52.9728L29.5027 52.5473C30.4815 50.5579 31.5907 48.3023 35.6137 48.3023H44.0593C45.1135 48.3023 46.5999 48.4302 47.3299 49.5391C48.1455 50.7738 47.4176 52.2433 46.7121 53.6625L46.5021 54.0859C45.5233 56.0774 44.4141 58.333 40.3891 58.333L40.3932 58.3309ZM31.0606 55.6581C31.2196 55.6917 31.4949 55.7294 31.9496 55.7294H40.3952C42.6993 55.7294 43.1234 54.8657 44.0512 52.9749L44.2653 52.5431C44.5079 52.0546 44.8525 51.3608 44.9524 50.9709C44.7934 50.9373 44.5161 50.8996 44.0634 50.8996H35.6178C33.3157 50.8996 32.8916 51.7633 31.9618 53.652L31.7477 54.0859C31.5051 54.5744 31.1625 55.2682 31.0606 55.656V55.6581Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M43.1997 46.4892H36.0631C35.1231 46.4892 33.7937 46.3718 33.1208 45.3467C32.3704 44.2042 33.0107 42.9087 33.6305 41.6573L33.8059 41.2988C34.6602 39.5568 35.6267 37.5821 39.1624 37.5821H46.301C47.241 37.5821 48.5704 37.6995 49.2433 38.7246C49.9937 39.865 49.3534 41.1605 48.7356 42.4119L48.5582 42.7725C47.7039 44.5145 46.7374 46.4892 43.2017 46.4892H43.1997ZM35.5839 43.8584C35.7022 43.8709 35.8592 43.8793 36.0631 43.8793H43.1997C45.0123 43.8793 45.3325 43.2295 46.1012 41.6594L46.2827 41.2904C46.4437 40.9655 46.6599 40.5274 46.7781 40.2088C46.6599 40.1962 46.5029 40.1878 46.299 40.1878H39.1604C37.3477 40.1878 37.0296 40.8376 36.2609 42.4077L36.0794 42.7746C35.9183 43.0995 35.7022 43.5397 35.5819 43.8563L35.5839 43.8584Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M92.3939 70.3991H82.8696C78.4409 70.3991 77.2684 68.1184 76.1327 65.911L75.8962 65.454C75.0867 63.8944 74.3241 62.4207 75.1907 61.1651C75.9696 60.0373 77.5845 59.9073 78.7324 59.9073H88.2567C92.6834 59.9073 93.8579 62.186 94.9916 64.3912L95.2302 64.8524C96.0397 66.412 96.8022 67.8857 95.9357 69.1414C95.1568 70.2692 93.5419 70.3991 92.3939 70.3991ZM77.5315 62.5151C77.5865 62.9155 78.0392 63.7896 78.3491 64.387L78.5897 64.8524C79.6378 66.89 80.1638 67.913 82.8696 67.913H92.3939C93.0892 67.913 93.4399 67.8396 93.5969 67.7893C93.5439 67.3889 93.0892 66.5147 92.7793 65.9173L92.5366 65.4498C91.4886 63.4144 90.9625 62.3914 88.2567 62.3914H78.7324C78.0371 62.3914 77.6864 62.4648 77.5294 62.5151H77.5315Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M98.1853 85.4042H86.8137C81.6917 85.4042 80.3276 82.6812 79.0084 80.0462L78.727 79.4865C77.7931 77.6376 76.9102 75.8914 77.8685 74.4659C78.729 73.1872 80.5662 73.0384 81.8752 73.0384H93.2468C98.3668 73.0384 99.7309 75.7614 101.05 78.3922L101.334 78.9561C102.267 80.805 103.15 82.5512 102.19 83.9767C101.33 85.2554 99.4924 85.4021 98.1833 85.4021L98.1853 85.4042ZM80.1706 75.8327C80.0992 76.2561 80.7721 77.5872 81.1799 78.3922L81.4654 78.9603C82.7235 81.4716 83.4147 82.8531 86.8158 82.8531H98.1874C99.3986 82.8531 99.7982 82.6665 99.892 82.6099C99.9634 82.1865 99.2905 80.8553 98.8847 80.0525L98.5972 79.4823C97.3392 76.973 96.6479 75.5916 93.2489 75.5916H81.8773C80.6661 75.5916 80.2644 75.7782 80.1726 75.8348L80.1706 75.8327Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M87.7573 58.3309H79.3117C75.2867 58.3309 74.1774 56.0753 73.1987 54.0839L72.9887 53.6604C72.2852 52.2413 71.5573 50.7718 72.3709 49.5371C73.1008 48.4282 74.5873 48.3003 75.6415 48.3003H84.0871C88.1101 48.3003 89.2193 50.5559 90.198 52.5452L90.4081 52.9708C91.1115 54.3899 91.8394 55.8573 91.0238 57.0941C90.2918 58.201 88.8074 58.3288 87.7532 58.3288L87.7573 58.3309ZM74.7565 50.973C74.8564 51.3608 75.201 52.0568 75.4437 52.5452L75.6578 52.9771C76.5876 54.8679 77.0117 55.7316 79.3137 55.7316H87.7594C88.212 55.7316 88.4893 55.6938 88.6484 55.6603C88.5485 55.2725 88.2039 54.5765 87.9633 54.0902L87.7492 53.6563C86.8194 51.7654 86.3953 50.9039 84.0932 50.9039H75.6476C75.1949 50.9039 74.9176 50.9416 74.7586 50.9751L74.7565 50.973Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M83.642 46.4892H76.5034C72.9677 46.4892 71.9991 44.5145 71.1468 42.7725L70.9694 42.4119C70.3496 41.1605 69.7114 39.865 70.4617 38.7246C71.1346 37.7016 72.464 37.5821 73.404 37.5821H80.5427C84.0783 37.5821 85.0448 39.5568 85.8992 41.2988L86.0765 41.6573C86.6964 42.9087 87.3367 44.2063 86.5863 45.3467C85.9134 46.3697 84.584 46.4892 83.644 46.4892H83.642ZM72.9269 40.2129C73.0452 40.5295 73.2633 40.9697 73.4224 41.2946L73.6039 41.6636C74.3726 43.2337 74.6907 43.8835 76.5054 43.8835H83.644C83.8479 43.8835 84.0029 43.8751 84.1232 43.8626C84.0029 43.546 83.7867 43.1058 83.6256 42.7809L83.4442 42.414C82.6755 40.8439 82.3574 40.1941 80.5447 40.1941H73.4061C73.2042 40.1941 73.0472 40.2025 72.9289 40.215L72.9269 40.2129Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path
                         d="M105.704 180.838H13.567C9.26061 180.838 6.38763 179.735 4.78292 177.469C2.3993 174.103 3.57378 168.967 4.86244 164.869C5.4823 162.9 6.24286 160.863 6.83825 159.305C8.14118 155.884 10.3861 150.025 12.8126 143.696L13.4243 142.103C18.2282 129.557 23.4889 115.839 23.4889 115.839C23.6928 115.307 24.1944 114.957 24.7511 114.957H94.5223C95.0789 114.957 95.5805 115.307 95.7844 115.839C95.7844 115.839 101.045 129.557 105.849 142.103C108.504 149.029 111.018 155.599 112.435 159.305C115.151 166.418 117.361 173.145 114.603 177.306C113.026 179.683 110.115 180.838 105.706 180.838H105.704ZM25.6727 117.747C24.437 120.971 20.0266 132.471 15.9466 143.126L15.3349 144.722C12.9105 151.048 10.6635 156.905 9.36052 160.326C8.78144 161.846 8.03719 163.833 7.4418 165.73C5.84321 170.814 5.69436 174.021 6.97486 175.83C8.0168 177.302 10.2353 178.05 13.565 178.05H105.702C109.099 178.05 111.338 177.272 112.356 175.736C114.366 172.705 112.143 166.194 109.905 160.328C108.49 156.622 105.975 150.054 103.321 143.128C99.2406 132.473 94.8322 120.973 93.5945 117.749H25.6727V117.747Z"
                         fill="#231F20"
                         stroke="black"
                         strokeWidth="1.53905"
                    />
                    <path d="M84.846 166.328C84.3036 166.328 83.7918 165.992 83.5818 165.443C82.9252 163.726 80.5641 156.744 77.8277 148.661C74.7345 139.519 71.2294 129.159 70.1712 126.4C69.8959 125.683 70.2385 124.872 70.9358 124.591C71.6331 124.31 72.4202 124.66 72.6955 125.377C73.7741 128.188 77.1385 138.131 80.3907 147.744C83.0048 155.465 85.472 162.758 86.1082 164.424C86.3814 165.141 86.0388 165.953 85.3415 166.234C85.1784 166.299 85.0112 166.33 84.846 166.33V166.328Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M18.4579 155.984C18.2928 155.984 18.1256 155.952 17.9604 155.885C17.2631 155.602 16.9205 154.793 17.1937 154.076L28.9568 123.349C29.2321 122.632 30.0192 122.28 30.7165 122.563C31.4139 122.846 31.7564 123.655 31.4832 124.372L19.7201 155.099C19.5101 155.648 18.9983 155.984 18.4579 155.984Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M99.9907 168.756C99.4442 168.756 98.9304 168.417 98.7244 167.861L95.2071 158.457C94.938 157.738 95.2867 156.931 95.986 156.654C96.6854 156.376 97.4704 156.736 97.7396 157.455L101.257 166.859C101.526 167.578 101.177 168.385 100.478 168.662C100.317 168.725 100.154 168.756 99.9907 168.756Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M93.2971 150.86C92.7506 150.86 92.2368 150.521 92.0308 149.965L88.5135 140.561C88.2444 139.842 88.593 139.035 89.2924 138.758C89.9918 138.48 90.7768 138.84 91.046 139.559L94.5633 148.963C94.8324 149.682 94.4838 150.489 93.7844 150.766C93.6233 150.829 93.4602 150.86 93.2971 150.86Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
                    <path d="M86.6036 132.966C86.0592 132.966 85.5433 132.626 85.3374 132.071L81.82 122.667C81.5509 121.948 81.8996 121.141 82.5989 120.864C83.2983 120.585 84.0834 120.946 84.3525 121.665L87.8698 131.069C88.139 131.788 87.7903 132.595 87.0909 132.872C86.9298 132.935 86.7667 132.966 86.6036 132.966Z" fill="#231F20" stroke="black" strokeWidth="1.53905" />
               </g>
               <defs>
                    <clipPath id="clip0_1_24942">
                         <rect width="119.277" height="180.839" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default CreditCardReaderIcon;
