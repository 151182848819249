
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js'

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');

const stringUtil = require('../../../../../utils/string/stringUtil');

class SeparatorElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {
        var separatorCharacter = this.props.element.character;
        var lineText = "";
        for (var index = 0; index < 100; index++)
            lineText = `${lineText}${separatorCharacter}`;
       
        return <div style={{width:'100%', whiteSpace:'nowrap', textOverflow:'', overflow:'hidden', padding:'0px', margin:'0px'}}
                    onDoubleClick={()=>
                        {
                            var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                            viewUtil.openModalForm("Separator Line", ()=>
                            {
                                return <DialogueBox element={clonedElement}/>
                            }, 
                            ()=>
                            {
                                this.props.element.character = clonedElement.character;
                                this.setState({});
                            }, 
                            ()=>
                            {
                                if (stringUtil.isStringNullOrEmpty(clonedElement.character) || clonedElement.character.length > 1)
                                    return "Separator line character is not valid";
                            })
                        }}>
                            {lineText}
                </div>;
    }
}

class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;
    }

    render()
    {
        return <table style={{width:'100%'}}>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Line Separator Character"
                                hintText="Line Separator Character"
                                fieldName="character"
                                placeholder="Enter line Separator Character"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
               </table>;
    }
}

export default SeparatorElement;