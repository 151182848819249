
import React, { Component } from 'react';
import { Button, Input} from 'reactstrap';
import { connect } from 'react-redux'
import Select from 'react-select';
import { AiOutlineShop } from "react-icons/ai";
import { BiBuildings } from "react-icons/bi";
import { GrSystem } from "react-icons/gr";
import { MdOpenInNew } from "react-icons/md";

const actionDispatcher = require('../../utils/state/actionDispatcher');
const topBarAction = require('../../utils/state/actions/views/container/topBarAction');
const orgStatesAction = require('../../utils/state/actions/orgStates/orgStatesAction');
const mainBarSearchAction = require('../../utils/state/actions/views/container/mainBarSearchAction');
const configurationManager = require('../../utils/config/configurationManager');
const stateManager = require('../../utils/state/stateManager');
const stringUtil = require('../../utils/string/stringUtil');
const currentOrgNodeSelectors = require('../../utils/state/stateSelectors/currentOrgNodeSelectors');
const securityManager = require('../../utils/domain/security/securityManager');
const orgSelectors = require('../../utils/state/stateSelectors/orgSelectors');
const utils = require('../../utils/logger/utils');
const logger = require('../../utils/logger/logger');

class TopBarView extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    handleOnClickOpenMainPortalBtn() {
        logger.info(`User clicked the 'Open Main Portal' button`, utils.createTracingContext());
        window.open(configurationManager.getConfig().mainPortalURL);
    }

    render()
    {
        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}`;
        return <div style={{paddingLeft:'10px', paddingTop:'5px', paddingBottom:'0px', paddingRight:'10px'}}>
                    <table>
                            <tr>
                                <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                    <img src={`${rootUrl}/topbar.option.png`} style={{marginRight:'10px', width:'30px'}} onClick={this.onToggleSideBarExpansion}></img>
                                </td>
                                <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                   <div style={{marginLeft:'5px'}}>
                                        <img src={`${rootUrl}/topbar.icon.png`} style={{width:'50px'}}></img>
                                    </div>
                                </td>
                                <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                    Flipdish
                                    Portal
                                </td>

                                <td style={{width:'96%', whiteSpace:'nowrap'}}>
                                    <Input type="search" value={this.props.mainBarSearch} placeholder="Search" 
                                           style={{maxWidth:'50vw', marginLeft:'60px', background:'#E8E8E8', border:'none'}}  onChange={(event)=>
                                           {
                                                var value = stringUtil.trimString(event.target.value);
                                                if (stringUtil.isStringNullOrEmpty(value))
                                                {
                                                    value = null;
                                                }
                                                actionDispatcher.dispatch(mainBarSearchAction.createAction(value));
                                           }}/>
                                </td>

                                <td style={{width:'1%'}}>
                                    <div className='d-flex flex-row'> 
                                        {this.renderOrgContextName()}
                                        <Button onClick={this.handleOnClickOpenMainPortalBtn} className="ms-4" outline style={{whiteSpace:'nowrap'}}>
                                            <MdOpenInNew size={"1.2rem"} className='me-2'/>
                                            Open Main Portal
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                    </table>
                    <hr style={{marginTop:'5px', marginBottom:'0px', padding:'0px'}}/>
                </div>
    }

    onToggleSideBarExpansion()
    {
        if (stateManager.getStore().getState().topBarSelectedOption == "expanded")
            actionDispatcher.dispatch(topBarAction.createAction("collapsed"));
        else
            actionDispatcher.dispatch(topBarAction.createAction("expanded"));
    }

    renderOrgContextName()
    {
        var options = [];
        
        
        if (orgSelectors.selectFranchisors().length <= 0)
        {
            let option = {};
            option.value = "system";
            option.type = "system";
            option.label = <div><GrSystem style={{fontSize:'20px', marginTop:'-3px'}}/><span style={{marginLeft:'10px'}}>System</span></div>

            options.push(option);
        }
        else
        {
            var franchisor = orgSelectors.selectFranchisors()[0];
            var hasAccessAtFranchisorLevel = false;
            if (securityManager.doesHaveAnyAccessAtOrgLevel(franchisor.id, null, null))
            {
                let option = {};
                option.value = franchisor.id;
                option.type = "franchisor";
                option.label = <div><BiBuildings style={{fontSize:'20px', marginTop:'-3px'}}/><span style={{marginLeft:'10px'}}>{franchisor.name}</span></div>
                option.franchisor = franchisor;

                options.push(option);
                hasAccessAtFranchisorLevel = true;
            }

            orgSelectors.selectFranchisees().forEach(franchisee =>
            {
                if (!stringUtil.areStringSame(franchisee.franchisorId, franchisor.id))
                    return;

                orgSelectors.selectProperties().forEach (property =>
                {
                    if (!stringUtil.areStringSame(property.franchiseeId, franchisee.id))
                        return;

                    let option = {};
                    option.value = `${franchisor.id}.${franchisee.id}.${property.id}`;
                    option.type = "property";
                    option.label = <div><AiOutlineShop style={{fontSize:'20px', marginTop:'-3px'}}/><span style={{marginLeft:'10px'}}>{property.name}</span></div>
                    option.franchisor = franchisor;
                    option.franchisee = franchisee;
                    option.property = property;
    
                    options.push(option);
                });
            })
        }

        var currentOrgNode = stateManager.getStore().getState().currentOrgNode;
        var currentValue = null;
        if (currentOrgNodeSelectors.isCurrentSystemSelected())
            currentValue = "system";

        else if (currentOrgNodeSelectors.isCurrentPropertySelected())
            currentValue = `${currentOrgNode.franchisorId}.${currentOrgNode.franchiseeId}.${currentOrgNode.propertyId}`
        else
            currentValue = `${currentOrgNode.franchisorId}`;

        return <div style={{minWidth:'20vw'}}>
                    <Select
                        id={this.props.fieldName}
                        value={currentValue}
                        options={options}
                        clearable={false}
                        isSearchable = {true}

                onChange={ (optionValue) =>
                {
                    const selectedOrgNode = {};
                    selectedOrgNode.franchisorId = optionValue.franchisor != null ? optionValue.franchisor.id : null;
                    selectedOrgNode.franchiseeId = optionValue.franchisee != null ? optionValue.franchisee.id : null;
                    selectedOrgNode.propertyId = optionValue.property != null ? optionValue.property.id : null;

                    stateManager.getStore().getState().mainBarOptions = null;
                    stateManager.getStore().getState().mainBarSearch = null;

                    stateManager.getStore().getState().mainBarComponent = null;
                    actionDispatcher.dispatch(orgStatesAction.createCurrentOrgNodeAction(selectedOrgNode));

                } } />
                </div>
        
    }
}

const mapStateToProps = state => 
{
    return {
        mainBarSearch: stringUtil.isStringNullOrEmpty(state.mainBarSearch) ? "" : state.mainBarSearch,
        currentOrgNode: state.currentOrgNode,
        orgData: state.orgData
    }
}

export default connect(mapStateToProps)(TopBarView)