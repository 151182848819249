
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardTitle, CardText, CardHeader, Button, CardBody, CardFooter, CardImg, Table, Badge } from 'reactstrap';

import ScaleLoader from "react-spinners/ScaleLoader";
import { CloudinaryContext, Image } from 'cloudinary-react';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const constants = require('../../../../../utils/domain/constants.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
var configurationManager = require('../../../../../utils/config/configurationManager.js');

class SignagePlayersListComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.onLoadSignage = this.onLoadSignage.bind(this);
        this.onNewSignage = this.onNewSignage.bind(this);
        this.renderPlayer = this.renderPlayer.bind(this);
        this.renderPlayerImage = this.renderPlayerImage.bind(this);
    }

    async componentDidMount()
    {
        if(!this.props.listViewState.listOfPlayers)
            await this.onLoadSignage();
    }

    render()
    {
        return (<Card style={{userSelect:'none'}} >
                    <CardHeader>
                        {this.renderHeader()} 
                    </CardHeader>
                    
                    <CardBody>
                        <div style={{marginTop:'20px'}}>
                            {this.renderBody()}
                        </div>
                    </CardBody>
                </Card>);
    }

    renderHeader()
    {
        return(
            <table width="100%">
                <tr width="100%">
                    
                    <td style={{whiteSpace: "nowrap"}}>
                        <div style={{fontSize:'14px',color: '#606060', fontWeight:'bold'}}>Signage Players</div>
                    </td>

                    <td  style={{whiteSpace: "nowrap", width:'99%'}} align = "right">
                        <Button disabled={this.props.listViewState.mode != 'loaded' || this.props.isReadOnly} 
                                color="success" className="btn-success" style={{ marginRight: '3px' }} onClick={this.onNewSignage}>
                            <i className="fa fa-plus"></i>&nbsp;New Signage Player
                        </Button>
                    </td>

                </tr>
            </table>
          );
    }

    onLoadSignage()
    {
        var signageUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs?type=${constants.blogTypes.SignagePlayers}`;

        rmsApiProxy.get(signageUrl)
                .then(blobs =>
                    {

                        var players = [];

                        if (blobs != null && blobs.length > 0)
                        {
                            blobs.forEach (blob =>
                            {
                                players.push(JSON.parse(blob.document))
                            });
                        }

                        this.props.listViewState.mode = "loaded";
                        this.props.listViewState.listOfPlayers = players;
                        this.setState({});
                    }, error =>
                    {
                        this.props.listViewState.mode = 'error';
                        this.props.listViewState.error = error;
                        this.setState({});
                    });

        this.props.listViewState.mode = 'searching';
        this.setState({});
    }

    onNewSignage()
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        const franchisorId = currentOrgNode.franchisorId;
        this.props.onNewPlayer(franchisorId);
    }

    renderBody()
    {
        if (this.props.listViewState.mode == null || this.props.listViewState.mode == 'none')
        {
            return (
                    <div className="text-muted" style={{textAlign:'center'}}>
                        Please select account and press load to fetch all signage players
                    </div>
                   );
        }

        if (this.props.listViewState.mode == 'error')
        {
            return (
                    <div className="text-muted" style={{textAlign:'center'}}>
                        <div style={{color: '#FFA07A'}}>{this.props.listViewState.error}</div> 
                    </div>
                   );
        }

        if (this.props.listViewState.mode == 'searching')
        {
            return (
                <div>
                        <Row className="justify-content-center">
                            <ScaleLoader
                                height={50}
                                width={6}
                                color={"#808080"}
                                loading={true}/>
                        </Row>
                        <Row className="justify-content-center" style={{margin:'5px'}}>
                            <h4 className="text-muted">Please wait, while loading Signage Players</h4>
                        </Row>
                    </div>
             );
        }

        if (this.props.listViewState.mode == 'loaded' && (this.props.listViewState.listOfPlayers == null || this.props.listViewState.listOfPlayers.length <=0))
        {
            return (
                <div className="text-muted" style={{textAlign:'center'}}>
                    No Signage players found.
                </div>
               );
        }

        var playerComponents = [];
        this.props.listViewState.listOfPlayers.forEach (player =>
            {
                playerComponents.push(this.renderPlayer(player));
            })

        return (<div>
                    {playerComponents}
                </div>);
    }

    renderPlayer(player)
    {
        var badges = [];

        badges.push(<Badge className="bg-danger text-white" pill style={{marginRight:'5px'}}>Total Slides {player.slides.length}</Badge>);
        badges.push(<Badge className="bg-success text-white" pill style={{marginRight:'5px'}}>Total Image Slides {player.slides.filter(s => s.type == constants.signageSlideTypes.Image).length}</Badge>);
        badges.push(<Badge className="bg-warning text-white" pill style={{marginRight:'5px'}}>Total Weather Slides {player.slides.filter(s => s.type == constants.signageSlideTypes.Weather).length}</Badge>);
     
        return (<Card style={{userSelect:'none', marginBottom:'10px', marginRight:'10px'}}>
                    <CardBody>
                        <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{whiteSpace: "nowrap", borderRight:'1px solid #C8C8C8'}}>

                                        <div style={{marginRight:'5px'}}>
                                            {this.renderPlayerImage(player)}
                                        </div>
                                    </td>
                                    <td style={{whiteSpace: "nowrap", width:'99%', verticalAlign:'top'}}>
                                        <div style={{marginLeft:'5px'}}>
                                        <table style={{width:'100%'}}>
                                                <tr>
                                                    <td style={{whiteSpace: "nowrap", width:'99%', borderRight:'1px solid #C8C8C8', verticalAlign:'top'}}>    
                                                        <table style={{width:'100%'}}>
                                                            <tr>
                                                                <td>
                                                                    {player.name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <small className="text-muted">{player.notes ?? 'No Descriptions'}</small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {badges}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style={{whiteSpace: "nowrap"}}>
                                                        <Button color="primary" outline block style={{marginLeft:'5px'}} onClick={()=>{this.props.onEditPlayer(player);}}>Edit</Button>
                                                    </td>
                                                </tr>
                                        </table>
                                        </div>
                                    </td>
                                </tr>
                        </table>
                    </CardBody>
                </Card>);
    }

    renderPlayerImage(player)
    {
        var imageUrl = 'app/back.office/icons/slide.image.png';
        var cloudName = configurationManager.getConfig().cdnAppCloudName;

        var imagesSlides = player.slides.filter(s => s.type == constants.signageSlideTypes.Image);
        var activeOrderSlides = player.slides.filter(s => s.type == constants.signageSlideTypes.ActiveOrders);
        
        if (imagesSlides != null && imagesSlides.length > 0)
        {
            imageUrl = imagesSlides[0].image;
            cloudName = configurationManager.getConfig().cdnUsersCloudName;
        }
        else if (activeOrderSlides != null && activeOrderSlides.length > 0)
        {
            imageUrl = "app/back.office/icons/slide.active.orders.png"
        }

        
        return (<CloudinaryContext cloudName={cloudName}>
                        <Image publicId={imageUrl} responsive width="auto" crop="scale" />
                </CloudinaryContext>);
    }
}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(SignagePlayersListComponent);