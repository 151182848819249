
import * as arraySort from 'array-sort';
import * as validator from '../../../../../utils/validator/validator';

export function getInventorySummaryData(props)
{
    const { inventoryData } = props.data;

    const masterRow = {
        detail: '',
        totalSalePrice: inventoryData.reduce((value, data) => value + data.totalSalePrice, 0),
        totalWastagePrice: inventoryData.reduce((value, data) => value + data.totalWastagePrice, 0),
        totalDiscrepancyPrice: inventoryData.reduce((value, data) => value + data.totalDiscrepancyPrice, 0),
        cogs: inventoryData.reduce((value, data) => value + data.cogs, 0),
    };

    return [masterRow];
}

export function getInventorySummaryCategoryData(props)
{
    const { inventoryData } = props.data;

    const categoryData = inventoryData.filter(x => validator.isGreaterThanZero(x.cogs));
    return arraySort(categoryData, 'cogs', { reverse: true});
}
