import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import arraySort from 'array-sort';

import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';
import * as rmsApiProxy from '../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../utils/api/rmsApiProxyUtils';

import WastagesBySourceItems from './components/wastagesBySourceItems/WastagesBySourceItems';
import WastagesByRawData from './components/wastagesByRawData/WastagesByRawData';

const unitUtil = require('../../../../utils/domain/unitUtil');
const domainConstants = require('../../../../utils/domain/constants');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

class WastagesReport extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            wastagesData: null,
            searchCriteria: null,
            error: null,
            isLoading: false,
        }
    }

    get reportData()
    {
        return {
            categories: this.categories,
            stockItems: this.stockItems,
            staff: this.staff,
            wastagesData: this.state.wastagesData
        }
    }

    render()
    {
        let component;

        const { isLoading, error, wastagesData } = this.state;

        if (isLoading === true) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error != null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (wastagesData == null)
        {
            component = <div className='m-3'>
                <EmptyData
                    title="Data required"
                    text="Please choose date range and hit Search to get report data"
                    action={null}
                />
            </div>;
        }
        else
        {
            component = this.renderReportData(wastagesData);
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>Wastages Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData(wastagesData)
    {
        if (wastagesData.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Data Found"
                        text="No inventory wastage data found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key='Wastages_By_Source_Items'>Wastages By Source Items</Tab>);
        tabContents.push(<TabPanel key='Wastages_By_Source_Items' className='bg-white mt-n1'><WastagesBySourceItems reportData={this.reportData} /></TabPanel>);

        tabHeaders.push(<Tab key='Wastages_By_Raw_Data'>Raw Data</Tab>);
        tabContents.push(<TabPanel key='Wastages_By_Raw_Data' className='bg-white mt-n1'><WastagesByRawData reportData={this.reportData} /></TabPanel>);

        return (
            <div className='p-2 mt-n3 bg-white'>
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ wastagesData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, wastagesData: null, searchCriteria: searchCriteria, isLoading: true });

        try 
        {
            // Reference data is loaded only once during the lifetime of the component

            if (this.categories == null)
            {
                const categories = await apiLoadFacade.loadInventoryCategories();
                this.categories = arraySort(categories, "id");
            }

            if (this.stockItems == null)
            {
                const stockItems = await apiLoadFacade.loadStockItems('unitTypesOnly=true');
                this.stockItems = arraySort(stockItems, "id");
            }

            if (this.staff == null)
            {
                const staff = await apiLoadFacade.loadStaff();
                this.staff = arraySort(staff, "id");
            }

            // Load state data

            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/inventory/stockItemWastages` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const wastagesData = await rmsApiProxy.get(resourceUrl);

            this.enrichData(wastagesData);

            this.setState({ wastagesData, isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }

    enrichData = (wastagesData) =>
    {
        wastagesData.forEach(wastageItem => 
        {
            this.enrichWastageItem(wastageItem);
        });
    }

    enrichWastageItem = (wastageItem) =>
    {
        const stockItem = this.stockItems.find(stockItem => stockItem.id === wastageItem.stockItemId);
        const category = this.categories.find(category => category.id === stockItem.categoryId);
        const staff = this.staff.find(staff => staff.id === wastageItem.staffId);

        let sourceStockItem = null;
        if (wastageItem.sourceStockItemId !== wastageItem.stockItemId)
        {
            sourceStockItem = this.stockItems.find(stockItem => stockItem.id === wastageItem.sourceStockItemId);
        }

        wastageItem.categoryName = category.name;
        wastageItem.stockItemName = stockItem.name;
        wastageItem.sourceStockItemName = sourceStockItem?.name;
        wastageItem.staffName = staff && `${staff.firstName} ${staff.lastName}`;

        const reportingUnitType = unitUtil.getUnitTypeByReportingType(stockItem, domainConstants.stockItemOperationTypes.wastage);
        const quantityInDefaultUnits = wastageItem.quantity;
        const quantityInCoreUnits = unitUtil.convertToStockItemCoreUnitsFromDefaultUnits(stockItem, quantityInDefaultUnits);
        const quantityInReportingUnits = unitUtil.convertFromStockItemCoreUnits(stockItem, reportingUnitType.id, quantityInCoreUnits);

        wastageItem.quantityInReportingUnits = quantityInReportingUnits;
        wastageItem.reportingUnitCode = reportingUnitType.code;
        wastageItem.unitPriceInReportingUnits = (wastageItem.quantity * wastageItem.unitPrice) / quantityInReportingUnits;

        if (wastageItem.ingredientItems != null && wastageItem.ingredientItems.length > 0)
        {
            for (const ingredientItem of wastageItem.ingredientItems) 
            {
                this.enrichWastageItem(ingredientItem);      // recursion
            }
        }
    }
}

export default WastagesReport;