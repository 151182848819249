


const unitUtil = require('../../../domain/unitUtil')


export function getIngredientCost(ingredientStockItem, recipeItem, stockItemsLookupData, dispatchType = null)
{
    // stockItem.recipeProfile is now sensitive to dispatchType



    const quantityInCoreUnits = unitUtil.convertToStockItemCoreUnits(ingredientStockItem, recipeItem.unitTypeId, recipeItem.quantity);

    let supplierCost = getSupplierCost(ingredientStockItem);
    if (supplierCost != null)
    {
        //Price for ingredient in core units
        const cost = { price: supplierCost.price * quantityInCoreUnits, vat: supplierCost.vat * quantityInCoreUnits };
        return cost;
    }

    if (ingredientStockItem.recipeProfile != null)
    {
        let recipeCost = deriveCostFromRecipe(ingredientStockItem, stockItemsLookupData, dispatchType);

        //Price for ingredient in core units
        const cost = { price: recipeCost.price * quantityInCoreUnits, vat: recipeCost.vat * quantityInCoreUnits };
        return cost;
    }

    return { price: NaN, vat: NaN };
}

function getSupplierCost(stockItem)
{
    if (stockItem.suppliers.length > 0)
    {
        const supplier = stockItem.suppliers[0];
        if (supplier.unitTypes.length > 0)
        {
            const unitType = supplier.unitTypes[0];
            const quantityInCoreUnits = unitUtil.convertToStockItemCoreUnits(stockItem, unitType.unitTypeId, 1);
            //Price per core unit = price in supplier unit / quantity in core units
            const cost = { price: unitType.price / quantityInCoreUnits, vat: unitType.vat / quantityInCoreUnits };
            return cost;
        }
    }

    return null;
}

function deriveCostFromRecipe(stockItem, stockItemsLookupData, dispatchType = null)
{
    // stockItem.recipeProfile is now sensitive to dispatchType
    


    if (!stockItem.recipeProfile || stockItem.recipeProfile.recipeItems.length == 0)
    {
        return { price: NaN, vat: NaN };
    }

    const recipeItemsByDispatchType =
        stockItem.recipeProfile.recipeItems.filter(recipeItem =>
            recipeItem.dispatchTypes == null ||
            (dispatchType && recipeItem.dispatchTypes.includes(dispatchType)));

    if (recipeItemsByDispatchType.length === 0) 
    {
        return { price: NaN, vat: NaN };
    }

    let price = 0, vat = 0;
    
    recipeItemsByDispatchType.forEach(recipeItem => 
    {
        const ingredientStockItem = stockItemsLookupData.find(x => x.id === recipeItem.ingredientStockItemId);
        const ingredientCost = getIngredientCost(ingredientStockItem, recipeItem, stockItemsLookupData, dispatchType);
        price += ingredientCost.price;
        vat += ingredientCost.vat;
    })

    const quantityInCoreUnits = unitUtil.convertToStockItemCoreUnits(stockItem, stockItem.recipeProfile.unitTypeId, stockItem.recipeProfile.baseQuantity);
    return { price: price / quantityInCoreUnits, vat: vat / quantityInCoreUnits };
}

export function calculateReceiptTotal(recipeItems)
{
    let totalPrice = 0.0;
    let totalVat = 0.0;
    let totalCalorie = 0.0;
    for (const recipeItem of recipeItems)
    {
        totalPrice = totalPrice + recipeItem.price;
        totalVat = totalVat + recipeItem.vat;
        totalCalorie = totalCalorie + recipeItem.calorie;
    }
    return { totalPrice, totalVat, totalCalorie };
}