
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, Button, CardBody,Badge} from 'reactstrap';
import { CloudinaryContext, Image } from 'cloudinary-react';

var stringUtil = require('../../../../../../../utils/string/stringUtil.js');
var configurationManager = require('../../../../../../../utils/config/configurationManager.js');
var constants = require('../../../../../../../utils/domain/constants.js');

class ImageSlideTileComponent extends Component
{
    render()
    {
        var badges = [];

        badges.push(<Badge className="bg-danger text-white" pill style={{marginRight:'5px'}}>Image</Badge>);
        badges.push(<Badge className="bg-primary text-white" pill style={{marginRight:'5px'}}>{this.props.slide.timings} sec</Badge>);

        if (this.props.slide.visibility == constants.signageSlideVisibility.Always)
            badges.push(<Badge className="bg-success text-white" pill style={{marginRight:'5px'}}>Always Visible</Badge>);
        else if (this.props.slide.visibility == constants.signageSlideVisibility.WithActiveOrder)
            badges.push(<Badge className="bg-success text-white" pill style={{marginRight:'5px'}}>Visible When Active Order</Badge>);
        else if (this.props.slide.visibility == constants.signageSlideVisibility.WithoutActiveOrder)
            badges.push(<Badge className="bg-success text-white" pill style={{marginRight:'5px'}}>Visible When No Active Order</Badge>);
        else
            badges.push(<Badge className="bg-secondary text-white" pill style={{marginRight:'5px'}}>Hidden</Badge>);
        
        if (this.props.slide.overrides.length > 0)
            badges.push(<Badge className="bg-warning text-white" pill style={{marginRight:'5px'}}>Has Overrides</Badge>);

        
        return (<Card style={{userSelect:'none', marginBottom:'10px', marginRight:'10px'}}>
                    <CardBody>
                           <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{whiteSpace: "nowrap", borderRight:'1px solid #C8C8C8'}}>

                                        <div style={{marginRight:'5px'}}>
                                            {this.renderImage()}
                                        </div>
                                    </td>
                                    <td style={{whiteSpace: "nowrap", width:'99%', verticalAlign:'top'}}>
                                        <div style={{marginLeft:'5px'}}>
                                           <table style={{width:'100%'}}>
                                                <tr>
                                                    <td style={{whiteSpace: "nowrap", width:'99%', borderRight:'1px solid #C8C8C8', verticalAlign:'top'}}>    
                                                        <table style={{width:'100%'}}>
                                                            <tr>
                                                                <td>
                                                                    {this.props.slide.name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <small className="text-muted">{this.props.slide.notes ?? 'No Descriptions'}</small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {badges}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style={{whiteSpace: "nowrap"}}>
                                                        <Button color="primary" outline block style={{marginLeft:'5px'}} onClick={()=>{this.props.onEdit();}}>Edit</Button>
                                                        <Button color="danger" outline block style={{marginLeft:'5px'}} onClick={()=>{this.props.onDelete();}}>Delete</Button>
                                                    </td>
                                                </tr>
                                           </table>
                                        </div>
                                    </td>
                                </tr>
                           </table>
                    </CardBody>
                </Card>);
    }

    

    renderImage()
    {
        var imageUrl = 'app/back.office/icons/slide.image.png';
        var cloudName = configurationManager.getConfig().cdnAppCloudName;

        if (!stringUtil.isStringNullOrEmpty(this.props.slide.image))
        {
            imageUrl = this.props.slide.image;
            cloudName = configurationManager.getConfig().cdnUsersCloudName;
        }

        
        return (<CloudinaryContext cloudName={cloudName}>
                        <Image publicId={this.props.slide.image} responsive width="auto" crop="scale" />
                </CloudinaryContext>);

    }

    renderDetails()
    {

    }
}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(ImageSlideTileComponent);
