import { Component } from "react";
import { Card, CardHeader, CardBody } from 'reactstrap';

class StockItemSections extends Component 
{
    render()
    {
        return  <Card style={{ border: '1px solid ##EAF2FF' }}>
                    <CardHeader>
                        <table style={{ fontSize: "14px", fontWeight: "bold" }}>
                            <td>{this.props.title}</td>
                        </table>
                    </CardHeader>
                    <CardBody>
                        {this.props.children}
                    </CardBody>
                </Card>
    }
}

export default StockItemSections;