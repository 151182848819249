const domainConstants = require('../../../../../utils/domain/constants');
const validator = require('../../../../../utils/validator/validator');

export function validateInventoryProfile(row)
{
    if (!validator.isPresent(row.franchisorId))
        return 'Franchisor Id cannot be left empty';

    if (!validator.isPresent(row.name))
        return 'Name cannot be left empty';

    if (!validator.isValidEnumeration(row.type, domainConstants.serviceContextTypes))
        return 'Invalid inventory tracking profile type';
}