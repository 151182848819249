const stringUtil = require('../string/stringUtil');
const validator = require('../validator/validator');
const constants = require('../domain/constants');
const localizationUtils = require('../domain/localizationUtils')

module.exports.convertToCurrencyFormat = function (value, treatEmptyAsZero)
{
    value = value + "";
    if (stringUtil.isStringNullOrEmpty(value))
    {
        if (!treatEmptyAsZero)
            return "";

        value = "0";
    }

    if (!validator.isNumeric(value))
        return "";

    let numericValue = 1 * value;

    if (Math.abs(numericValue) < constants.numericThresholdValue)
        numericValue = 0.0;

    return localizationUtils.getCurrentCurrencyFormatter().format(numericValue);
}

module.exports.convertToPointsFormat = function (value, treatEmptyAsZero)
{
    value = value + "";
    if (stringUtil.isStringNullOrEmpty(value))
    {
        if (!treatEmptyAsZero)
            return "";

        value = "0 points";
    }

    if (!validator.isNumeric(value))
        return "";

    let numericValue = 1 * value;

    if (Math.abs(numericValue) < constants.numericThresholdValue)
        numericValue = 0.0;

    return numericValue.toFixed(0) + " points";
}

module.exports.convertToAmountFormat = function (value, treatEmptyAsZero, minimumFractionDigits = 0, maximumFractionDigits = 2)
{
    value = value + "";
    if (stringUtil.isStringNullOrEmpty(value))
    {
        if (!treatEmptyAsZero)
            return "";

        value = "0";
    }

    if (!validator.isNumeric(value))
        return "";

    let numericValue = 1 * value;

    if (Math.abs(numericValue) < constants.numericThresholdValue)
        numericValue = 0.0;

    return numericValue.toLocaleString(undefined,
        { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits });
}

module.exports.formatToAmount = function (value, treatEmptyAsZero = true)
{
    return this.convertToAmountFormat(value, treatEmptyAsZero, 2, 2);
}

module.exports.roundUp = function (value, decimalPoints)
{
    // Rounds value and returns result as a number (not a formatted string)

    if (!validator.isNumeric(value))
        return null;

    return (value * 1).toFixed(decimalPoints) * 1;
}

//#region
// -----------------------------------------------------------------------------
// Date formatting
// -----------------------------------------------------------------------------

const weekdayMonthFormatter = new Intl.DateTimeFormat("en-GB", {
    // Output = Sat, 01 May
    weekday: "short",
    month: "short",
    day: "2-digit",
});

const weekdayMonthYearFormatter = new Intl.DateTimeFormat("en-GB", {
    // Output = Sat, 01 May
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
});

const weekdayMonthTimeFormatter = new Intl.DateTimeFormat("en-GB", {
    // Output = Sat, 01 May, 08:17
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "2-digit", minute: "2-digit", hour12: false

});

module.exports.convertToWeekdayMonthFormat = function (date)
{
    if (stringUtil.isStringNullOrEmpty(date))
        return null;

    if (typeof date !== 'Date')
    {
        date = Date.parse(date);
    }

    return weekdayMonthFormatter.format(date);
}

module.exports.convertToWeekdayMonthYearFormat = function (date)
{
    if (stringUtil.isStringNullOrEmpty(date))
        return null;

    if (typeof date !== 'Date')
    {
        date = Date.parse(date);
    }

    return weekdayMonthYearFormatter.format(date);
}

module.exports.convertToWeekdayMonthTimeFormat = function (date)
{
    if (stringUtil.isStringNullOrEmpty(date))
        return null;

    if (typeof date !== 'Date')
    {
        date = Date.parse(date);
    }

    return weekdayMonthTimeFormatter.format(date);
}


//#endregion