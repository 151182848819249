import React from "react";
import { Input } from "reactstrap";

export default function DeliveryZoneTableData({ onChange, disabled, ...inputFieldData })
{
    return (
        <td>
            <Input
                value={inputFieldData.value}
                onChange={(e) => { onChange(e, inputFieldData.id) }}
                invalid={!!inputFieldData.errorMsg}
                disabled={disabled}
            />
            {inputFieldData.errorMsg && <p className="text-danger font-bold"><span className="fa fa-warning"></span>&nbsp;{inputFieldData.errorMsg}</p>}
        </td>
    )
}