const stringUtil = require("../../../../../utils/string/stringUtil");
const rmsApiProxy = require("../../../../../utils/api/rmsApiProxy");
const catalogSelectors = require('../../../../../utils/state/stateSelectors/catalogSelectors');


export default {
    searchCustomers,
    createSearchCriteria,
    enrichSearchCriteria,
    isSearchCriteriaValid,
    buildSearchDescriptions
}


async function searchCustomers(searchCriteria)
{
    return await rmsApiProxy.put(`${rmsApiProxy.getFranchisorOrgContextUrl()}/customers`, searchCriteria);
}

function createSearchCriteria(propertyIds) 
{
    const searchCriteria = {};
    searchCriteria.propertyIds = propertyIds ? propertyIds : [];
    searchCriteria.lastOrderDate = null;
    searchCriteria.name = null;
    searchCriteria.phoneNumber = null;
    searchCriteria.address = null;
    searchCriteria.email = null;

    return searchCriteria;
}

function enrichSearchCriteria(searchCriteria)
{
    if (searchCriteria.propertyIds == null)
        searchCriteria.propertyIds = [];
}

function isSearchCriteriaValid(searchCriteria)
{
    if ((!stringUtil.isStringNullOrEmpty(searchCriteria.name)) ||
        (!stringUtil.isStringNullOrEmpty(searchCriteria.address)) ||
        (!stringUtil.isStringNullOrEmpty(searchCriteria.email)) ||
        (!stringUtil.isStringNullOrEmpty(searchCriteria.phoneNumber)) ||
        (!stringUtil.isStringNullOrEmpty(searchCriteria.lastOrderDate)) ||
        (searchCriteria.propertyIds != null))
    {
        return true;
    }

    return false;
}

function buildSearchDescriptions(searchCriteria)
{
    let descriptions = [];

    if(!searchCriteria) 
        return descriptions;
    
    const propertyIds = searchCriteria.propertyIds;

    if(Array.isArray(searchCriteria.propertyIds) && propertyIds.length > 0)
    {
        const properties = catalogSelectors.selectProperties(); 
        const description = "Properties : " + propertyIds.map(id => {
            const property = properties.find(p => p.id === id);
            return property.name;
        }).join(", ");

        descriptions.push(description);
    }

    if(!stringUtil.isStringNullOrEmpty(searchCriteria.phoneNumber))
    {
        descriptions.push(`Phone number contains ${searchCriteria.phoneNumber}`)
    }

    if(!stringUtil.isStringNullOrEmpty(searchCriteria.name))
    {
        descriptions.push(`Name contains ${searchCriteria.name}`)
    }

    if(!stringUtil.isStringNullOrEmpty(searchCriteria.address))
    {
        descriptions.push(`Address contains ${searchCriteria.address}`)
    }

    if(!stringUtil.isStringNullOrEmpty(searchCriteria.email))
    {
        descriptions.push(`Email contains ${searchCriteria.email}`)
    }

    if(!stringUtil.isStringNullOrEmpty(searchCriteria.lastOrderDate))
    {
        descriptions.push(`Last ordered before  ${searchCriteria.lastOrderDate}`)
    }
    
    return descriptions;
}