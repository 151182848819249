
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');
const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const formatter = require('../../../../../utils/formatter/formatter');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');
const nomenclatureUtils = require('../../../../../utils/domain/nomenclatureUtils');

module.exports.constructGridColumnHeaders = function (showBrand)
{
    let header;
    const headers = [];

    header = {};
    header.field = "id";
    header.headerName = "Id";
    header.type = dataTypeConstants.id;
    headers.push(header);
    
    header = {};
    header.field = "businessDate";
    header.headerName = "Sale Date";
    header.minWidth = "200";
    header.type = dataTypeConstants.dateTime;
    header.pinned = true;
    headers.push(header);

    header = {};
    header.field = "dateTime";
    header.headerName = "Sale Time";
    header.minWidth = "200";
    header.type = dataTypeConstants.dateTime;
    header.pinned = true;
    headers.push(header);

    if(showBrand)
    {
        header = {};
        header.field = "brand";
        header.headerName = "Brand";
        header.minWidth = "100";
        headers.push(header);
    }

    header = {};
    header.field = "storefrontName";
    header.headerName = "Storefront";
    header.minWidth = "200";
    headers.push(header);

    header = {};
    header.field = "venueCode";
    header.headerName = "Venue Code";
    header.minWidth = "200";
    headers.push(header);

    header = {};
    header.field = "dispatchType";
    header.headerName = "Dispatch Type";
    header.minWidth = "200";
    headers.push(header);

    header = {};
    header.field = "operator";
    header.headerName = "Operator";
    header.minWidth = "200";
    headers.push(header);

    header = {};
    header.field = "channel";
    header.headerName = "Channel";
    header.minWidth = "200";
    headers.push(header);

    header = {};
    header.field = "number";
    header.headerName = "Order Number";
    header.minWidth = "200";
    header.pinned = true;
    headers.push(header);

    header = {};
    header.field = "driverName";
    header.headerName = "Driver";
    header.minWidth = "200";
    headers.push(header);

    header = {};
    header.field = "retailPrice";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.retailOrderPrice);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "discount";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.totalDiscounts);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "saleRefund";
    header.headerName = "Sale Refunds";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netRetailPrice";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.netOrderPrice);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "charge";
    header.headerName = "Charge";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "chargeRefund";
    header.headerName = "Charge Refund";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netCharge";
    header.headerName = "Net Charge";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netSalePrice";
    header.headerName = "Net Sale Price";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netRetailTax";
    header.headerName = `Net Retail ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netChargeTax";
    header.headerName = `Net Charge ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netSaleTax";
    header.headerName = `Net Sale ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netSalePriceIncTax";
    header.headerName = `Net Sale Price Incl. ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "cashReceipts";
    header.headerName = "Cash Receipts"
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "cashRefunds";
    header.headerName = "Cash Refunds";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "netCashReceipts";
    header.headerName = "Net Cash Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "creditReceipts";
    header.headerName = "Credit Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "creditRefunds";
    header.headerName = "Credit Refunds";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "netCreditReceipts";
    header.headerName = "Net Credit Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);
    
    header = {}
    header.field = "venuePaidReceipts";
    header.headerName = "Venue Paid Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);
    
    header = {}
    header.field = "venuePaidRefunds";
    header.headerName = "Venue Paid Refunds";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);
    
    header = {}
    header.field = "netVenuePaidReceipts";
    header.headerName = "Net Venue Paid Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);
    
    header = {}
    header.field = "otherReceipts";
    header.headerName = "Other Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);
    
    header = {}
    header.field = "otherRefunds";
    header.headerName = "Other Refunds";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);
    
    header = {}
    header.field = "netOtherReceipts";
    header.headerName = "Net Other Receipts";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header)

    header = {};
    header.field = "paidAmount";
    header.headerName = "Paid Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "totalRefunds";
    header.headerName = "Total Refunds";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netPaidAmount";
    header.headerName = "Net Paid Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "balance";
    header.headerName = "Balance";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "feeAmount";
    header.headerName = "Fee Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

module.exports.constructRowHighlightRules = function () 
{
    return {
        'cancelled': function (params)
        {
            if (params.node.rowPinned === 'bottom')
                return false;

            return params.data.isCancelled;
        },
        'refunded': function (params)
        {
            if (params.node.rowPinned === 'bottom')
                return false;

            if (params.data.isCancelled)
                return false;

            return params.data.totalRefunds > 0;
        },
    }
}

module.exports.getPinnedRows = function (rows)
{
    if (rows == null || rows.length <= 0)
        return null;

    var retailPrice = 0;
    var discount = 0;
    var saleRefund = 0;
    var netRetailPrice = 0;
    var charge = 0;
    var chargeRefund = 0;
    var netCharge = 0;
    var netSalePrice = 0;
    var netRetailTax = 0;
    var chargeTax = 0;
    var netSaleTax = 0;
    var netSalePriceIncTax = 0;
    var cashReceipts = 0;
    var cashRefunds = 0;
    var netCashReceipts = 0;
    var creditReceipts = 0;
    var creditRefunds = 0;
    var netCreditReceipts = 0;
    var venuePaidReceipts = 0;
    var venuePaidRefunds = 0;
    var netVenuePaidReceipts = 0;
    var otherReceipts = 0;
    var otherRefunds = 0;
    var netOtherReceipts = 0;
    var paidAmount = 0;
    var totalRefunds = 0;
    var netPaidAmount = 0;
    var balance = 0;
    var feeAmount = 0;

    rows.forEach(row =>
    {
        retailPrice += row.retailPrice;
        discount += row.discount;
        saleRefund += row.saleRefund;
        netRetailPrice += row.netRetailPrice;
        charge += row.charge;
        chargeRefund += row.chargeRefund;
        netCharge += row.netCharge;
        netSalePrice += row.netSalePrice;
        netRetailTax += row.netRetailTax;
        chargeTax += row.chargeTax;
        netSaleTax += row.netSaleTax;
        netSalePriceIncTax += row.netSalePriceIncTax;
        cashReceipts += row.cashReceipts;
        cashRefunds += row.cashRefunds;
        netCashReceipts += row.netCashReceipts;
        creditReceipts += row.creditReceipts;
        creditRefunds += row.creditRefunds;
        netCreditReceipts += row.netCreditReceipts;
        venuePaidReceipts += row.venuePaidReceipts;
        venuePaidRefunds += row.venuePaidRefunds;
        netVenuePaidReceipts += row.netVenuePaidReceipts;
        otherReceipts += row.otherReceipts;
        otherRefunds += row.otherRefunds;
        netOtherReceipts += row.netOtherReceipts;
        paidAmount += row.paidAmount;
        totalRefunds += row.totalRefunds;
        netPaidAmount += row.netPaidAmount;
        balance += row.balance;
        feeAmount += row.feeAmount;
    })

    var row = 
    {
        retailPrice: retailPrice,
        discount: discount,
        saleRefund: saleRefund,
        netRetailPrice: netRetailPrice,
        charge: charge,
        chargeRefund: chargeRefund,
        netCharge: netCharge,
        netSalePrice: netSalePrice,
        netRetailTax: netRetailTax,
        chargeTax: chargeTax,
        netSaleTax: netSaleTax,
        netSalePriceIncTax: netSalePriceIncTax,
        cashReceipts: cashReceipts,
        cashRefunds: cashRefunds,
        netCashReceipts: netCashReceipts,
        creditReceipts: creditReceipts,
        creditRefunds: creditRefunds,
        netCreditReceipts: netCreditReceipts,
        venuePaidReceipts: venuePaidReceipts,
        venuePaidRefunds: venuePaidRefunds,
        netVenuePaidReceipts: netVenuePaidReceipts,
        otherReceipts: otherReceipts,
        otherRefunds: otherRefunds,
        netOtherReceipts: netOtherReceipts,
        paidAmount: paidAmount,
        totalRefunds: totalRefunds,
        netPaidAmount: netPaidAmount,
        balance: balance,
        feeAmount: feeAmount
    }

    return [row];
}