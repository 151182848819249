
import React, { Component, useState } from 'react';
import { Row, Col } from 'reactstrap';

import arraySort from 'array-sort';

import InputComponent from '../../../../../../components/form/inputComponent'
import SelectComponent from '../../../../../../components/form/selectComponent';
import CheckComponent from '../../../../../../components/form/checkComponent.js';
import FormManager from '../../../../../../utils/view/formManager';
import StockItemSections from '../StockItemSections';

import * as calorieCalculator from '../../../../../../utils/domain/inventory/calorieCalculator';
import * as stockItemNutritionUtil from '../../../../../../utils/domain/inventory/stockItem/stockItemNutritionUtil';

const unitUtil = require('../../../../../../utils/domain/unitUtil');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const validator = require('../../../../../../utils/validator/validator');
const formatter = require('../../../../../../utils/formatter/formatter');

const catalogSelectors = require('../../../../../../utils/state/stateSelectors/catalogSelectors');


export default class NutritionProfiles extends Component 
{
    constructor(props) 
    {
        super(props);

        if (!props.data.nutritionProfile)
            props.data.nutritionProfile = {};

        const nutritionProfile = props.data.nutritionProfile;
        if (nutritionProfile.nutritions == null)
            nutritionProfile.nutritions = [];

        this.state = { baseQuantity: nutritionProfile.baseQuantity, unitTypeId: nutritionProfile.unitTypeId };
    }

    onBaseQuantityUpdate = (baseQuantity) =>
    {
        this.props.data.nutritionProfile.baseQuantity = baseQuantity;
        this.setState({});
    }

    onNutritionQuantityUpdate = () => 
    {
        // Required primarily to update calories display
        this.setState({});
    }

    render()
    {
        const nutritionLookupData = catalogSelectors.selectLookupData().nutritions;

        const baseQuantity = this.props.data.nutritionProfile.baseQuantity;
        const unitTypeId = this.props.data.nutritionProfile.unitTypeId;

        let nutritions = this.props.data.nutritionProfile.nutritions;

        const inferredNutritionQuantities = (baseQuantity && unitTypeId) ? 
            nutritionLookupData.map(x =>
            ({
                nutritionCode: x.code,
                quantity: stockItemNutritionUtil.getNutritionQuantity(x.code, this.props.data, this.props.lookupData.stockItemsLookupData)
            })) : [];

        const calories = calorieCalculator.calculateCalorie(inferredNutritionQuantities);
        const totalCalories = isNaN(calories) ? "N/A" : formatter.roundUp(calories, 0);

        const columns = [];
        const options = arraySort(nutritionLookupData, "code");

        options.forEach(option => 
        {
            let nutritionOption = nutritions.find(nutrition => nutrition.nutritionCode == option.code);

            if (nutritionOption == null)
            {
                nutritionOption = {
                    nutritionCode: option.code,
                    coreUnitCode: 'gms',            //Hard coding to gms
                    quantity: ""
                }

                const inferredNutritionQuantity = inferredNutritionQuantities.find(x => x.nutritionCode == option.code);
                if (inferredNutritionQuantity != null && inferredNutritionQuantity.quantity)
                {
                    nutritionOption.quantity = inferredNutritionQuantity.quantity;
                    nutritionOption.inferred = true;
                }
            }

            columns.push(
                <Col xs="4" className="mb-1">
                    <NutritionOptionComponent
                        nutritionOption={nutritionOption}
                        onUpdate={this.onNutritionQuantityUpdate}
                        {...this.props} />
                </Col>
            );
        });

        let value = false;
        const formManager = new FormManager();
        formManager.viewModel = this.props.data;
        formManager.onFieldChanged = () => this.setState({value: !value});

        return  <StockItemSections title="Nutrition Profile" {...this.props}>
                    <div className="d-flex flex-column mx-3">
                        <Row>
                            <Col xs="4" className="mb-1">
                                <CheckComponent
                                    caption="Is Nutrition Profile Enabled"
                                    fieldName="nutritionProfileEnabled"
                                    inputReadOnly={this.props.isReadOnly}
                                    formManager={formManager} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="4" className="mb-1">
                                <NutritionLabelsComponent {...this.props} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="4" className="mb-1">
                                <BaseQuantityComponent baseQuantity={baseQuantity} onUpdate={this.onBaseQuantityUpdate} {...this.props} />
                            </Col>
                            <Col xs="4" className="mb-1">
                                <BaseQuantityUnitComponent onUpdate={() => { this.setState({}) }} {...this.props} />
                            </Col>
                            <Col xs="4" className="mb-1">
                                <div className="d-flex h5 justify-content-center align-items-center border h-75 rounded">
                                    {`Total Calories = ${totalCalories}`}
                                </div>
                            </Col>
                        </Row>

                        <Row >{columns}</Row>

                    </div>
                </StockItemSections>
    }
}

function BaseQuantityComponent(props)
{
    const onUpdate = props.onUpdate;
    const isReadOnly = props.isRowReadOnly;
    const baseQuantity = props.baseQuantity;

    const formManager = new FormManager();
    formManager.viewModel = { baseQuantity: baseQuantity };
    formManager.onFieldChanged = () => onUpdate(formManager.viewModel.baseQuantity);

    return (
        <InputComponent
            inputType="number"
            caption="Base Quantity"
            placeholder="Enter base quantity"
            hintText="Enter Base quantity for all nutrition options"
            fieldName="baseQuantity"
            inputReadOnly={isReadOnly || !props.data.nutritionProfileEnabled}
            formManager={formManager} />
    );
}

function BaseQuantityUnitComponent(props)
{
    const onUpdate = props.onUpdate;
    const isReadOnly = props.isRowReadOnly;

    const formManager = new FormManager();
    formManager.viewModel = props.data.nutritionProfile;
    formManager.onFieldChanged = () => onUpdate();

    let units = unitUtil
        .getPossibleUnitsForStockItem(props.data)
        .filter(unit => !unit.isSupplementalUnit);

    units = arraySort(units, stringUtil.localeCompareProp("code"));

    return (
        <SelectComponent
            optionValues={units}
            optionFieldName="id"
            optionDisplayFieldName="code"
            fieldName="unitTypeId"
            caption="Base Quantity Unit"
            hintText="Select base quantity unit"
            clearable={false}
            comboReadOnly={isReadOnly || !props.data.nutritionProfileEnabled}
            searchable={false}
            formManager={formManager} />
    );
}

function NutritionOptionComponent(props)
{
    const isReadOnly = props.isRowReadOnly;

    const onUpdate = props.onUpdate;
    const nutritionOption = props.nutritionOption;

    const formManager = new FormManager();
    formManager.viewModel = nutritionOption;
    formManager.onFieldChanged = () => 
    {
        nutritionOption.inferred = false;
        const nutritions = props.data.nutritionProfile.nutritions.filter(nutrition => nutrition.nutritionCode != nutritionOption.nutritionCode);

        if (validator.isPresent(nutritionOption.quantity) && validator.isGreaterThanOrEqualToZero(nutritionOption.quantity))
        {
            nutritions.push(nutritionOption);
        }

        props.data.nutritionProfile.nutritions = nutritions;

        onUpdate();
    }
    var quantityBackgroundColor = '';
    if (nutritionOption.inferred)
        quantityBackgroundColor = '#F9E79F';

    return (
        <InputComponent
            style={{ backgroundColor: quantityBackgroundColor }}
            inputType="number"
            fieldName="quantity"
            caption={`${nutritionOption.nutritionCode} (gms)`}
            placeholder="Enter nutrition value"
            inputReadOnly={isReadOnly || !props.data.nutritionProfileEnabled}
            formManager={formManager} />
    );
}

function  NutritionLabelsComponent(props)
{
    const [value, setValue] = useState(false);

    const nutritionProfile = props.data.nutritionProfile;
    const isReadOnly = props.isRowReadOnly;

    const formManager = new FormManager();
    formManager.viewModel = nutritionProfile;
    formManager.onFieldChanged = () => setValue(!value);

    return (
        <InputComponent
            inputType="number"
            caption="Minimum Nutrition Labels Per Day"
            placeholder="Enter minimum nutrition labels per day"
            hintText="Minimum number of nutrition labels to be printed each day"
            fieldName="minNutritionLabels"
            inputReadOnly={isReadOnly || !props.data.nutritionProfileEnabled}
            formManager={formManager} />
    );
}