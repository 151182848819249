import { string } from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';

import purchaseOrderUtil from '../../utils/purchaseOrderUtil';

const stringUtil = require('../../../../../../utils/string/stringUtil');
const centralKitchenUtils = require('../../../../../setup/centralKitchen/utils/centralKitchenUtils');


class StockItemComboBox extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            value: null,
            label: null
        }
    }

    onChange = (selectedOption) =>
    {
        if (selectedOption)
        {
            this.props.onStockItemClick(selectedOption.stockItem);
            this.setState({ value: selectedOption.value, label: selectedOption.label });
        }
    }

    getOptions = () =>
    {
        const purchaseOrder = this.props.data;
        const orderItems = purchaseOrder.orderItems;
        const supplierId = purchaseOrder.supplierId;
        const supplierStockItems = purchaseOrderUtil.getSupplierStockItems(supplierId, this.props.lookupData);

        const options = [];

        supplierStockItems.forEach(stockItem =>
        {
            
            let disableReason = ''
            let disabled  = orderItems.some(orderItem => orderItem.stockItemId == stockItem.id)

            if (!disabled && this.isCentralKitchen())
            {
                const centralKitchenStockItems = this.getEffectiveSupplierStockItems();
                const stockItemSupplier = stockItem.suppliers.find(supplier => supplier.supplierId == purchaseOrder.supplierId);

                disableReason = centralKitchenUtils.getStockItemDisabledReason(stockItemSupplier, centralKitchenStockItems);
                disabled = !stringUtil.isStringNullOrEmpty(disableReason);
            }
                

            options.push({
                value: stockItem.id,
                label: this.getOptionLabel(stockItem, disableReason),
                stockItem: stockItem,
                disabled: disabled
            });
        });

        return options;
    }

    render()
    {
        const selectedOption = this.state;

        return (
            <div className='w-100 bg-dark p-2 border rounded'>
                <Select
                    id={selectedOption.id}
                    value={selectedOption.value}
                    options={this.getOptions()}
                    onChange={this.onChange}
                    clearable={false}
                    placeholder="Select stock item ..."
                />
            </div>
        );
    }

    isCentralKitchen()
    {
        return this.props.lookupData.centralKitchensLookupData.some(kitchen => kitchen.id == this.props.data.supplierId);
    }

    getEffectiveSupplierStockItems()
    {
        return this.props.lookupData.centralKitchensLookupData.find(kitchen => kitchen.id == this.props.data.supplierId).effectiveSupplierStockItems;
    }

    getOptionLabel(stockItem, disabledReason)
    {
        if (stringUtil.isStringNullOrEmpty(disabledReason))
        {
            return stockItem.name;
        }
        return `${stockItem.name} - (${disabledReason})`;
    }
}

export default StockItemComboBox;