
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import KOTConfiguration from './kotConfiguration';

const guidUtil = require('../../../../utils/guid/guidUtil');
const constants = require('../../../../utils/domain/constants');
const configurationManager = require('../../../../utils/config/configurationManager');
const externalIntegrationUtils = require('../../../../utils/domain/externalIntegrations/externalIntegrationUtils');
const orgStateSelectors = require('../../../../utils/state/stateSelectors/orgStateSelectors');

const stationState = require('../stationState');
export default class KOTConfigurations extends Component 
{
    constructor(props)
    {
        super(props);

        this.addNewConfiguration = this.addNewConfiguration.bind(this);

        this.state = {};
        this.state.currentSelectedConfiguration = null;

        this.state.kitchenStationProfiles = orgStateSelectors.selectCurrentOrgLookupData().kitchenStationProfiles || [];
        this.state.kitchenStationProfiles = this.state.kitchenStationProfiles.filter(k => k.stationType == constants.kitchenStationTypes.stationTypePrinter);

        this.state.receiptPrinters = [];
        this.state.storefronts = stationState.storefronts;
        
        var hardwareTerminal = stationState.currentStationViewModel.terminals.find(t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal != null)
        {
            const hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);

            var printerIntegrations = hardwareTerminalIntegrations.filter(k => k.integrationType === externalIntegrationUtils.integrationTypes.receiptPrinterManagement || 
                                                                                    k.integrationType === externalIntegrationUtils.integrationTypes.labelPrinterManagement);
            printerIntegrations.forEach(integration => 
                {
                    var printer = {};
                    printer.name = integration.jsonData.printerName;
                    printer.integrationType = integration.integrationType === externalIntegrationUtils.integrationTypes.labelPrinterManagement ? 
                                                                            externalIntegrationUtils.externalEntities.kitchenStationPrinterManagement.labelPrinter : 
                                                                            externalIntegrationUtils.externalEntities.kitchenStationPrinterManagement.receiptPrinter;
                    this.state.receiptPrinters.push(printer);
                }
            );
        }

    }

    render()
    {

        if (this.state.currentSelectedConfiguration != null)
        {
            return <KOTConfiguration configuration={this.state.currentSelectedConfiguration}
                receiptPrinters={this.state.receiptPrinters}
                kitchenStationProfiles={this.state.kitchenStationProfiles}
                storefronts={this.state.storefronts}
                isReadOnly={this.props.isReadOnly}
                onClose={() =>
                {
                    this.state.currentSelectedConfiguration = null;
                    this.setState({});
                }} />
        }

        return (<Card>
            <CardHeader>
                {this.renderToolbar()}
            </CardHeader>
            <CardBody>
                <div style={{ minHeight: '100%' }}>
                    {this.renderOptions()}
                </div>
            </CardBody>
        </Card>
        );
    }


    renderToolbar()
    {
        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - KOT Configurations</div>
                    </td>
                    <td align="right">
                        {
                            !this.props.isReadOnly &&
                            <Button color="success" className="btn-success" style={{ marginRight: '10px' }} onClick={this.addNewConfiguration}>
                                Add New KOT Configuration
                            </Button>
                        }
                        <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={() => { this.props.onClose() }}>
                            Close
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }


    renderOptions()
    {
        var configurations = this.getConfigurations();
        var components = [];

        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;
        configurations.forEach(configuration =>
        {
            components.push(<div style={{
                border: '1px solid #C8C8C8', background: 'white', marginRight: '20px',
                marginBottom: '30px', minWidth: '400px', width: '25vw', minHeight: '100px',
                paddingTop: '20px', paddingBottom: '20px', cursor: 'default', userSelect: 'none'
            }} onClick={() =>
            {
                this.state.currentSelectedConfiguration = configuration;
                this.setState({});
            }}>

                <table style={{ width: '100%' }}>
                    <tr>
                        <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                            <img src={`${rootUrl}/app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.settings.kot.png`} style={{ width: '80px', marginRight: '20px', marginLeft: '20px' }} />
                        </td>
                        <td style={{ width: '99%' }}>
                            <table>
                                <tr>
                                    <td>
                                        <h3 style={{ margin: '0px' }}>{this.getStationName(configuration.kitchenStation)}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>KOT printing for using '{configuration.receiptPrinter}' printer</small>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>)
        })

        if (components.length <= 0)
        {
            return <div style={{ textAlign: 'center', marginTop: '100px' }}> No Receipt Printer Found.</div>
        }

        return <div style={{ background: '#F6F9FF', minHeight: '100%', marginTop: '-16px', marginLeft: '-16px', marginRight: '-16px', paddingTop: '40px', paddingLeft: '40px', paddingRight: '40px' }}>
            <div className="d-flex flex-row flex-wrap w-100">
                {components}
            </div>
        </div>

    }

    getStationName(kitchenProfileId)
    {
        const kitchenStationProfile = this.state.kitchenStationProfiles.find(profile => profile.id === kitchenProfileId);

        return kitchenStationProfile.stationName;
    }

    addNewConfiguration()
    {
        var configuration = {};

        configuration.id = guidUtil.generateGuid();
        configuration.integrationType = externalIntegrationUtils.integrationTypes.kitchenStationPrinterManagement;
        configuration.externalEntity = null; //set null because now the externalEntity could be labelPrinter or ReceiptPrinter

        configuration.kitchenStation = null;
        configuration.receiptPrinter = null;
        configuration.copiesToPrint = "1";
        configuration.storefronts = null;
        configuration.dispatchTypes = null;

        this.state.currentSelectedConfiguration = configuration;
        this.setState({});
    }


    getConfigurations()
    {
        var configurations = [];

        var hardwareTerminal = stationState.currentStationViewModel.terminals.find(t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal == null)
            return configurations;

        var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
        if (hardwareTerminalIntegrations == null)
            hardwareTerminalIntegrations = [];

        var kotIntegrations = hardwareTerminalIntegrations.filter(k => k.integrationType == externalIntegrationUtils.integrationTypes.kitchenStationPrinterManagement);
        if (kotIntegrations == null)
            return configurations;

        kotIntegrations.forEach(kotIntegration =>
        {
            var configuration = {};

            configuration.id = kotIntegration.id;
            configuration.integrationType = kotIntegration.integrationType;
            configuration.externalEntity = kotIntegration.externalEntity;
            configuration.systemType = kotIntegration.systemType;
            configuration.systemId = kotIntegration.systemId;
            configuration.isEnabled = kotIntegration.isEnabled;

            configuration.kitchenStation = kotIntegration.jsonData.kitchenStation;
            configuration.receiptPrinter = kotIntegration.jsonData.receiptPrinter;
            configuration.copiesToPrint = kotIntegration.jsonData.copiesToPrint;
            configuration.storefronts = kotIntegration.jsonData.storefronts;
            configuration.dispatchTypes = kotIntegration.jsonData.dispatchTypes;

            configurations.push(configuration);
        });

        return configurations;
    }
}