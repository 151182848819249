import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableContainer } from '../../utils/components/dndComponents';

export default class ActionItemComponent extends Component
{
    render()
    {
        const taskAction = this.props.taskAction;
        const isRowReadOnly = this.props.isRowReadOnly;
        const selectedTaskAction = this.props.selectedTaskAction;
        const onSelectTaskAction = this.props.onSelectTaskAction;
        const onDeleteTaskAction = this.props.onDeleteTaskAction;

        const rowStyle = { cursor: "pointer" };
        const selectedRowStyle = { ...rowStyle, backgroundColor: "GoldenRod", fontWeight: "bold" };

        return (
            <Draggable draggableId={taskAction.name} isDragDisabled={isRowReadOnly} index={this.props.index}>
                {
                    (provided, snapshot) =>
                    (
                        <DraggableContainer
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>

                            <div
                                className="d-flex flex-row flex-nowrap"
                                style={taskAction == selectedTaskAction ? selectedRowStyle : rowStyle}
                                onDoubleClick={() => onSelectTaskAction(taskAction)}>
                                <div
                                    className="border text-center px-2 py-1"
                                    style={{ width: "10%", fontSize: "18px", color: "red" }}
                                    onClick={(event) => { onDeleteTaskAction(taskAction); event.stopPropagation(); }}>
                                    <i className="fa fa-times" style={{ opacity: isRowReadOnly ? "0.25" : "1.0" }} />
                                </div>
                                <div style={{ width: "35%" }} className="border px-2 py-1">{taskAction.name}</div>
                                <div style={{ width: "20%" }} className="border px-2 py-1">{taskAction.isCompulsory ? "Yes" : "No"}</div>
                                <div style={{ width: "35%" }} className="border px-2 py-1">{taskAction.hazardTypes.join(", ")}</div>
                            </div>

                        </DraggableContainer>
                    )

                }

            </Draggable>
        );
    }
}