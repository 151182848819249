import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';
import * as fixedCostSummaryViewUtil from '../../viewUtils/fixedCostSummaryViewUtil';

export default function TabularReport(props)
{
    const masterRows = getMasterRows(props);

    const totalRow = {
        category: 'Total',
        amount: masterRows.reduce((value, data) => value + data.amount, 0),
    };

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    return (
        <div className="ag-theme-alpine">
            <AgGridReact
                rowData={masterRows}
                pinnedBottomRowData={[totalRow]}
                columnDefs={getMasterGridColumns(props)}
                gridOptions={biUtils.getGridOptionsForMasterRows()}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
            />
        </div>
    );
}

function getMasterRows(props)
{
    return fixedCostSummaryViewUtil.getFixedCostSummaryData(props);
}

function getMasterGridColumns(props)
{
    let header;
    const headers = [];

    header = {};
    header.field = "category";
    header.headerName = "Category";
    headers.push(header);

    header = {};
    header.field = "amount";
    header.headerName = "Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}
