
import React, { Component } from 'react';

const configurationManager = require('../../utils/config/configurationManager');
const logger = require('../../utils/logger/logger');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const passportAction = require('../../utils/state/actions/passport/passportAction');
const stateManager = require('../../utils/state/stateManager');
const rmsApiProxy = require('../../utils/api/rmsApiProxy');
const securityManager = require('../../utils/domain/security/securityManager');

class SingleSignOnView extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onLogin = this.onLogin.bind(this);
        
        this.state = {};
    }

    isLocalEnvironment = () =>
    {
        return configurationManager.getConfig().environment === "local";
    }
    
    async componentDidMount()
    {
        this.state.mode = "verifying";
        this.state.error = null;
        this.setState({});

        try
        {
            let passport = null;
            if(this.isLocalEnvironment())   
            {
                passport = await rmsApiProxy.get(`core/system/security/authenticateRMSCookie`);
            }
            else
            {
                passport = await rmsApiProxy.get(`core/system/security/authenticateCookie`);
            }
            this.onLogin(passport);

        }
        catch (error)
        {
            if(this.isLocalEnvironment())   
            {
                this.state.error = "Failed to authenticate user. Please login to http://localhost:5391 first";
            }
            else
            {
                const currentUrl = window.location.href;
                window.location.href = `${configurationManager.getConfig().singleSignOnUrl}/login?redirectUrl=${encodeURIComponent(currentUrl)}`;
        
                this.state.error = "";
            }        
        }
        finally
        {
            this.setState({});
        }   
    }

    render() 
    {
        if (this.state.error != null)
            return <div className='w-100 d-flex flex-column justify-content-center align-items-center m-3 fw-bolder text-danger' style={{ fontSize: '1.2rem' }}>
                {this.state.error}
            </div>

        var imageUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}/loadng.gif`;

        return <div style={{ position: 'absolute', left: '50%', top: '40%', width: '400px', height: '400px', marginTop: '-200px', marginLeft: '-200px' }}>

            <div style={{ borderRadius: '50%', width: '220px', height: '220px', border: '1px solid #808080', marginLeft: '90px', marginTop: '90px' }}>
                <img src={imageUrl} style={{ width: '140px', height: '140px', marginLeft: '40px', marginTop: '40px' }} />
            </div>

            <div style={{ textAlign: 'center', color: '#05149E', marginTop: '10px', fontSize: '2em', fontWeight: 'bold' }}>
                Verifying Identity...
            </div>

            <div style={{ textAlign: 'center', color: '#707070', marginTop: '0px', fontSize: '1em' }}>
                Please wait while we verify your identity
            </div>
        </div>

    }

    onLogin(passport)
    { 
        if (!securityManager.isValidPassport(passport))
            throw `Invalid passport encountered`;

        stateManager.getStore().getState().sideBarSelectedOption = "modules";
        stateManager.getStore().getState().topBarSelectedOption = "expanded";
        stateManager.getStore().getState().mainBarOptions = null;
        stateManager.getStore().getState().environmentInitialized = false;
        actionDispatcher.dispatch(passportAction.createAction(passport));

        logger.setLoggedInUser(passport.emailAddress, passport.fullName);
    }
}
export default SingleSignOnView;