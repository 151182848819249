import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import PosIcon from "../../../../assets/icons/PosIcon";
import { TerminalTypes } from "../../../../constants";
import KdsIcon from "../../../../assets/icons/KdsIcon";
import SignageIcon from "../../../../assets/icons/SignageIcon";
import KioskIcon from "../../../../assets/icons/KioskIcon";
import KitchenIcon from "../../../../assets/icons/KitchenIcon";
import { makeFieldConfigurationValue } from "../utils";

const title = {
     [TerminalTypes.centralKitchen]: "CENTRAL KITCHEN",
     [TerminalTypes.kds]: "KDS",
     [TerminalTypes.kiosk]: "KIOSK",
     [TerminalTypes.kitchen]: "KITCHEN",
     [TerminalTypes.pos]: "POS",
     [TerminalTypes.signage]: "SIGNAGE",
};

const StationNodeHeader = ({ stationName, stationType, fields = [] }) => {
     const Icon = useCallback(() => {
          switch (stationType) {
               case TerminalTypes.pos:
                    return <PosIcon />;
               case TerminalTypes.kds:
                    return <KdsIcon />;
               case TerminalTypes.signage:
                    return <SignageIcon />;
               case TerminalTypes.kiosk:
                    return <KioskIcon />;
               case TerminalTypes.centralKitchen:
                    return <KitchenIcon />;
               case TerminalTypes.kitchen:
                    return <KitchenIcon />;
               default:
                    return <PosIcon />;
          }
     }, [stationType]);

     const importantFields = useMemo(() => {
          let res = [];
          for (let field of fields) {
               if (field.fieldName === "terminalCode") {
                const splitDefaultValue = field.defaultValue.split('.');
                field.defaultValue = splitDefaultValue.map((value,idx)=> {
                    if(idx < 2) {
                        value = "xxxx"
                    }
                    return value;
                }).join('.');
                res.push(field)
               };
               if (stationType === TerminalTypes.pos && (field.fieldName === "hostType" || field.fieldName === "storeFrontId")) res.push(field);
               if (stationType === TerminalTypes.kds && field.fieldName === "hostType") res.push(field);
               if (stationType === TerminalTypes.signage && (field.fieldName === "hostType" || field.fieldName === "signagePlayerId")) res.push(field);
               if (stationType === TerminalTypes.kiosk && (field.fieldName === "hostType" || field.fieldName === "skinId" || field.fieldName === "storeFrontId")) res.push(field);
               if (stationType === TerminalTypes.centralKitchen && field.fieldName === "hostType") res.push(field);
               if (stationType === TerminalTypes.kitchen && (field.fieldName === "hostType" || field.fieldName === "labelPrinterId")) res.push(field);
          }
          return res;
     }, [stationType, fields]);

     return (
          <Container>
               <IconWrapper>
                    <Icon />
               </IconWrapper>
               <Content>
                    <Title>{stationName ?? title[stationType]}</Title>
                    <SomeConf>
                         {importantFields.map((field) => (
                              <FieldContainer key={"imp-" + field.fieldName}>
                                   <FieldText>{`${field.label}:`}</FieldText>
                                   <FieldText>{`${makeFieldConfigurationValue(field)}`} </FieldText>
                              </FieldContainer>
                         ))}
                    </SomeConf>
               </Content>
          </Container>
     );
};

export default StationNodeHeader;

const Title = styled.div`
     font-size: 28px;
     font-weight: 600;
`;
const Content = styled.div``;
const FieldContainer = styled.div`
     display: flex;
     flex-direction: row;
     gap: 8px;

     div:first-child {
          font-weight: 600;
          color: black;
     }
`;
const FieldText = styled.div`
     font-weight: 500;
     font-size: 16px;
     color: #000000c0;
`;
const IconWrapper = styled.div`
     aspect-ratio: 1;
     width: 90px;
     height: 100px;
`;
const SomeConf = styled.div`
     font-size: 14px;
     color: #313131;

     p {
          margin-bottom: 3px;
     }
`;
const Container = styled.div`
     display: flex;
     flex-direction: row;
     gap: 26px;
     padding: 30px;
`;
