import { allBrandTotal } from '../../../../reportUtils/constants';
import { getPaymentMethodForDisplay } from '../../../../reportUtils/helperUtils/viewUtils';

const domainConstants = require('../../../../../../utils/domain/constants');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const arraySort = require('array-sort');

export function getFormattedData(data, brand)
{
    let [salesSummaryData, salesDetailData, salesByPaymentMethodData, discountsData, refundsData, chargesData] = data;

    if (salesSummaryData.length === 0) return null;

    if (brand == null)
    {
        salesSummaryData = salesSummaryData && salesSummaryData.length > 0 ? salesSummaryData.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand)) : [];
        salesDetailData = salesDetailData && salesDetailData.length > 0 ? salesDetailData.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand)) : [];
        salesByPaymentMethodData = salesByPaymentMethodData && salesByPaymentMethodData.length > 0 ? salesByPaymentMethodData.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand)) : [];
        discountsData = discountsData && discountsData.length > 0 ? discountsData.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand)) : [];
        refundsData = refundsData && refundsData.length > 0 ? refundsData.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand)) : [];
        chargesData = chargesData && chargesData.length > 0 ? chargesData.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand)) : [];
    }
    else if (brand !== allBrandTotal)
    {
        salesSummaryData = salesSummaryData && salesSummaryData.length > 0 ? salesSummaryData.filter(data => stringUtil.areStringSame(data.storefrontBrand, brand)) : [];
        salesDetailData = salesDetailData && salesDetailData.length > 0 ? salesDetailData.filter(data => stringUtil.areStringSame(data.storefrontBrand, brand)) : [];
        salesByPaymentMethodData = salesByPaymentMethodData && salesByPaymentMethodData.length > 0 ? salesByPaymentMethodData.filter(data => stringUtil.areStringSame(data.storefrontBrand, brand)) : [];
        discountsData = discountsData && discountsData.length > 0 ? discountsData.filter(data => stringUtil.areStringSame(data.storefrontBrand, brand)) : [];
        refundsData = refundsData && refundsData.length > 0 ? refundsData.filter(data => stringUtil.areStringSame(data.storefrontBrand, brand)) : [];
        chargesData = chargesData && chargesData.length > 0 ? chargesData.filter(data => stringUtil.areStringSame(data.storefrontBrand, brand)) : [];
    }

    const formattedData =
    {
        salesData: salesSummaryData,

        salesDetailData, salesByPaymentMethodData, discountsData, chargesData, refundsData,

        salesSummary: getSalesSummary(salesSummaryData),
        salesByProperty: getSalesByProperty(salesSummaryData),
        salesByStorefrontType: getSalesByStorefrontType(salesSummaryData),
        salesByVenueCode: getSalesByVenueCode(salesSummaryData),
        salesByDispatchType: getSalesByDispatchType(salesSummaryData),

        salesByPaymentMethod: getSalesByPaymentMethod(salesSummaryData, salesByPaymentMethodData),

        saleItemsSummary: getSaleItemsSummary(salesDetailData),
        salesByReportTags: getSalesByReportTags(salesDetailData),
        salesByCategories: getSalesByCategories(salesDetailData),

        discountTypes: getDistinctDiscountTypes(discountsData),
        discountsByVenueCode: getDiscountsByVenueCode(discountsData),

        refundCodes: getDistinctRefundCodes(refundsData),
        refundsByVenueCode: getRefundsByVenueCode(refundsData),

        chargeSchemes: getDistinctChargeSchemes(chargesData),
        chargesByVenueCode: getChargesByVenueCode(chargesData)
    };

    return formattedData;
}

export function getDistinctProperties(salesData)
{
    const propertyMap = new Map(salesData.map(data => [data.propertyId, data.propertyName]));
    const propertyArray = Array.from(propertyMap.entries(), ([propertyId, propertyName]) => ({ propertyId, propertyName }));
    return arraySort(propertyArray, "propertyName");
}

export function getDistinctStorefrontTypes(salesData)
{
    const storefrontTypeSet = new Set(salesData.map(data => data.storefrontType));
    return arraySort([...storefrontTypeSet]);
}

export function getDistinctVenueCodes(salesData)
{
    const venueCodeSet = new Set(salesData.map(data => data.venueCode));
    return arraySort([...venueCodeSet]);
}

export function getDistinctDispatchTypes(salesData)
{
    const dispatchTypeSet = new Set(salesData.map(data => data.dispatchType));
    return arraySort([...dispatchTypeSet]);
}

export function getDistinctPaymentMethods(salesData)
{
    const paymentMethodSet = new Set(salesData.map(data => data.paymentMethod));
    return arraySort([...paymentMethodSet]);
}

export function getDistinctDiscountTypes(discountsData)
{
    const discountTypeSet = new Set(discountsData.map(data => data.discountType));
    return arraySort([...discountTypeSet]);
}

export function getDistinctRefundCodes(refundsData)
{
    const refundCodeSet = new Set(refundsData.map(data => data.refundCode));
    return arraySort([...refundCodeSet]);
}

export function getDistinctChargeSchemes(chargesData)
{
    const chargeSchemeSet = new Set(chargesData.map(data => data.scheme));
    return arraySort([...chargeSchemeSet]);
}

export function getDistinctReportTags(saleData)
{
    // Returns distinct saleItem.reportTags
    const reportTagsSet = new Set(saleData.map(data => data.reportTags));
    return arraySort([...reportTagsSet]);
}

export function getDistinctCategories(saleData)
{
    // Returns distinct saleItem.category
    const categorySet = new Set(saleData.map(data => data.category));
    return arraySort([...categorySet]);
}

function getSalesSummary(salesData)
{
    const salesSummary =
    {
        saleCount: salesData.reduce((previous, current) => previous + current.saleCount, 0),
        totalRetailOrderPrice: salesData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
        totalDiscounts: salesData.reduce((previous, current) => previous + current.totalDiscounts, 0),
        totalRefunds: salesData.reduce((previous, current) => previous + current.totalRefunds, 0),
        totalNetOrderPrice: salesData.reduce((previous, current) => previous + current.totalNetOrderPrice, 0),
        totalCharges: salesData.reduce((previous, current) => previous + current.totalCharges, 0),
        totalSalePrice: salesData.reduce((previous, current) => previous + current.totalSalePrice, 0),
        totalTax: salesData.reduce((previous, current) => previous + current.totalTax, 0),
        totalSalePriceIncTax: salesData.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0),
    };

    return salesSummary;
}

function getSalesByProperty(salesData)
{
    const salesByProperty = [];

    getDistinctProperties(salesData)
        .forEach(property => 
        {
            const propertyData = salesData.filter(data => data.propertyId === property.propertyId);

            salesByProperty.push({
                propertyId: property.propertyId,
                propertyName: property.propertyName,
                saleCount: propertyData.reduce((previous, current) => previous + current.saleCount, 0),
                totalRetailOrderPrice: propertyData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
                totalDiscounts: propertyData.reduce((previous, current) => previous + current.totalDiscounts, 0),
                totalRefunds: propertyData.reduce((previous, current) => previous + current.totalRefunds, 0),
                totalNetOrderPrice: propertyData.reduce((previous, current) => previous + current.totalNetOrderPrice, 0),
                totalCharges: propertyData.reduce((previous, current) => previous + current.totalCharges, 0),
                totalSalePrice: propertyData.reduce((previous, current) => previous + current.totalSalePrice, 0),
                totalTax: propertyData.reduce((previous, current) => previous + current.totalTax, 0),
                totalSalePriceIncTax: propertyData.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0),
            });
        });

    return salesByProperty;
}

function getSalesByStorefrontType(salesData)
{
    const salesByStorefrontType = [];

    getDistinctStorefrontTypes(salesData)
        .forEach(storefrontType => 
        {
            const storefrontData = salesData.filter(data => data.storefrontType === storefrontType);

            salesByStorefrontType.push({
                storefrontType: storefrontType,
                saleCount: storefrontData.reduce((previous, current) => previous + current.saleCount, 0),
                totalRetailOrderPrice: storefrontData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
                totalDiscounts: storefrontData.reduce((previous, current) => previous + current.totalDiscounts, 0),
                totalRefunds: storefrontData.reduce((previous, current) => previous + current.totalRefunds, 0),
                totalNetOrderPrice: storefrontData.reduce((previous, current) => previous + current.totalNetOrderPrice, 0),
                totalCharges: storefrontData.reduce((previous, current) => previous + current.totalCharges, 0),
                totalSalePrice: storefrontData.reduce((previous, current) => previous + current.totalSalePrice, 0),
                totalTax: storefrontData.reduce((previous, current) => previous + current.totalTax, 0),
                totalSalePriceIncTax: storefrontData.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0),
            });
        });

    return salesByStorefrontType;
}

function getSalesByVenueCode(salesData)
{
    const salesByVenueCode = [];

    getDistinctVenueCodes(salesData)
        .forEach(venueCode => 
        {
            const venueCodeData = salesData.filter(data => data.venueCode === venueCode);

            salesByVenueCode.push({
                venueCode: venueCode,
                saleCount: venueCodeData.reduce((previous, current) => previous + current.saleCount, 0),
                totalRetailOrderPrice: venueCodeData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
                totalDiscounts: venueCodeData.reduce((previous, current) => previous + current.totalDiscounts, 0),
                totalRefunds: venueCodeData.reduce((previous, current) => previous + current.totalRefunds, 0),
                totalNetOrderPrice: venueCodeData.reduce((previous, current) => previous + current.totalNetOrderPrice, 0),
                totalCharges: venueCodeData.reduce((previous, current) => previous + current.totalCharges, 0),
                totalSalePrice: venueCodeData.reduce((previous, current) => previous + current.totalSalePrice, 0),
                totalTax: venueCodeData.reduce((previous, current) => previous + current.totalTax, 0),
                totalSalePriceIncTax: venueCodeData.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0),
            });
        });

    return salesByVenueCode;
}

function getSalesByDispatchType(salesData)
{
    const salesByDispatchType = [];

    getDistinctDispatchTypes(salesData)
        .forEach(dispatchType => 
        {
            const dispatchTypeData = salesData.filter(data => data.dispatchType === dispatchType);

            salesByDispatchType.push({
                dispatchType: dispatchType,
                saleCount: dispatchTypeData.reduce((previous, current) => previous + current.saleCount, 0),
                totalRetailOrderPrice: dispatchTypeData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
                totalDiscounts: dispatchTypeData.reduce((previous, current) => previous + current.totalDiscounts, 0),
                totalRefunds: dispatchTypeData.reduce((previous, current) => previous + current.totalRefunds, 0),
                totalNetOrderPrice: dispatchTypeData.reduce((previous, current) => previous + current.totalNetOrderPrice, 0),
                totalCharges: dispatchTypeData.reduce((previous, current) => previous + current.totalCharges, 0),
                totalSalePrice: dispatchTypeData.reduce((previous, current) => previous + current.totalSalePrice, 0),
                totalTax: dispatchTypeData.reduce((previous, current) => previous + current.totalTax, 0),
                totalSalePriceIncTax: dispatchTypeData.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0),
            });
        });

    return salesByDispatchType;
}

function getSalesByPaymentMethod(salesSummaryData, salesByPaymentMethodData)
{
    const salesByPaymentMethod = [];

    salesByPaymentMethodData.forEach(saleData => 
    {
        const paymentMethod = getPaymentMethodForDisplay(saleData.paymentMethod, saleData.venueCode);

        let saleByPaymentMethod = salesByPaymentMethod.find(x => x.paymentMethod === paymentMethod);

        if (saleByPaymentMethod == null)
        {
            saleByPaymentMethod = { paymentMethod: paymentMethod, chargeAmount: 0.0, refundAmount: 0.0 };
            salesByPaymentMethod.push(saleByPaymentMethod);
        }

        saleByPaymentMethod.chargeAmount += saleData.chargeAmount;
        saleByPaymentMethod.refundAmount += saleData.refundAmount;
    });

    salesByPaymentMethod.forEach(x => x.netAmount = x.chargeAmount - x.refundAmount);

    arraySort(salesByPaymentMethod, "paymentMethod");

    // Add item for outstanding amount

    const totalNetReceivable = salesSummaryData.reduce((sum, data) => sum + data.totalSalePriceIncTax, 0);
    const totalNetReceipts = salesByPaymentMethod.reduce((sum, data) => sum + data.netAmount, 0);
    const totalOutStandingAmount = totalNetReceivable - totalNetReceipts;

    if (totalOutStandingAmount > domainConstants.numericThresholdValue)
    {
        salesByPaymentMethod.push({ paymentMethod: "Unpaid Sales", chargeAmount: 0.0, refundAmount: 0.0, netAmount: totalOutStandingAmount });
    }

    return salesByPaymentMethod;
}

function getDiscountsByVenueCode(discountsData)
{
    const discountsByVenueCode = [];

    const discountTypes = getDistinctDiscountTypes(discountsData);

    getDistinctVenueCodes(discountsData)
        .forEach(venueCode =>
        {
            const venueCodeData = discountsData.filter(data => data.venueCode === venueCode);

            let totalAmount = 0;
            const venueCodeDiscounts = { venueCode };

            discountTypes.forEach(discountType => 
            {
                venueCodeDiscounts[discountType] =
                    venueCodeData
                        .filter(data => data.discountType === discountType)
                        .reduce((previous, current) => previous + current.totalAmount, 0);
                totalAmount += venueCodeDiscounts[discountType];
            });

            venueCodeDiscounts["totalAmount"] = totalAmount;

            discountsByVenueCode.push(venueCodeDiscounts);
        });

    return discountsByVenueCode;
}

function getRefundsByVenueCode(refundsData)
{
    const refundsByVenueCode = [];

    const refundCodes = getDistinctRefundCodes(refundsData);

    getDistinctVenueCodes(refundsData)
        .forEach(venueCode =>
        {
            const venueCodeData = refundsData.filter(data => data.venueCode === venueCode);

            let totalRefundAmount = 0;
            const venueCodeRefunds = { venueCode };

            refundCodes.forEach(refundCode => 
            {
                venueCodeRefunds[refundCode] =
                    venueCodeData
                        .filter(data => data.refundCode === refundCode)
                        .reduce((previous, current) => previous + current.totalRefundAmount, 0);
                totalRefundAmount += venueCodeRefunds[refundCode];
            });

            venueCodeRefunds["totalRefundAmount"] = totalRefundAmount;

            refundsByVenueCode.push(venueCodeRefunds);
        });

    return refundsByVenueCode;
}

function getChargesByVenueCode(chargesData)
{
    const chargesByVenueCode = [];

    const chargeSchemes = getDistinctChargeSchemes(chargesData);

    getDistinctVenueCodes(chargesData)
        .forEach(venueCode =>
        {
            const venueCodeData = chargesData.filter(data => data.venueCode === venueCode);

            let totalChargeAmount = 0;
            const venueCodeCharges = { venueCode };

            chargeSchemes.forEach(scheme => 
            {
                venueCodeCharges[scheme] =
                    venueCodeData
                        .filter(data => data.scheme === scheme)
                        .reduce((previous, current) => previous + current.netCharge, 0);
                totalChargeAmount += venueCodeCharges[scheme];
            });

            venueCodeCharges["totalChargeAmount"] = totalChargeAmount;

            chargesByVenueCode.push(venueCodeCharges);
        });

    return chargesByVenueCode;
}

function getSaleItemsSummary(salesDetailData)
{
    const salesSummary =
    {
        totalQuantity: salesDetailData.reduce((previous, current) => previous + current.totalQuantity, 0),
        totalRetailOrderPrice: salesDetailData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
        totalTax: salesDetailData.reduce((previous, current) => previous + current.totalTax, 0)
    };

    return salesSummary;
}

function getSalesByReportTags(salesDetailData)
{
    // Returns sales by saleItem reportTags and storefrontType

    const salesByReportTags = [];

    getDistinctReportTags(salesDetailData)
        .forEach(reportTags => 
        {
            const reportTagsData = salesDetailData.filter(data => data.reportTags === reportTags);

            reportTagsData.forEach(data => 
            {
                let itemRow = salesByReportTags.find(itemRow =>
                    itemRow.reportTags === data.reportTags &&
                    itemRow.storefrontType === data.storefrontType);

                if (itemRow == null) 
                {
                    itemRow = {
                        reportTags: data.reportTags,
                        storefrontType: data.storefrontType,
                        totalQuantity: 0,
                        totalRetailOrderPrice: 0.0,
                        totalTax: 0.0
                    }

                    salesByReportTags.push(itemRow);
                }

                itemRow.totalQuantity += data.totalQuantity;
                itemRow.totalRetailOrderPrice += data.totalRetailOrderPrice;
                itemRow.totalTax += data.totalTax;
            });
        });

    return arraySort(salesByReportTags, ["reportTags", "storefrontType"]);
}

function getSalesByCategories(salesDetailData)
{
    // Returns sales by saleItem category and storefrontType

    const salesByCategories = [];

    getDistinctCategories(salesDetailData)
        .forEach(category => 
        {
            const categoryData = salesDetailData.filter(data => data.category === category);

            categoryData.forEach(data => 
            {
                let itemRow = salesByCategories.find(itemRow =>
                    itemRow.category === data.category &&
                    itemRow.storefrontType === data.storefrontType);

                if (itemRow == null) 
                {
                    itemRow = {
                        category: data.category,
                        storefrontType: data.storefrontType,
                        totalQuantity: 0,
                        totalRetailOrderPrice: 0.0,
                        totalTax: 0.0
                    }

                    salesByCategories.push(itemRow);
                }

                itemRow.totalQuantity += data.totalQuantity;
                itemRow.totalRetailOrderPrice += data.totalRetailOrderPrice;
                itemRow.totalTax += data.totalTax;
            });
        });

    return arraySort(salesByCategories, ["category", "storefrontType"]);
}