import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';

import * as reportDataUtils from '../../utils/reportDataUtils';
import * as reportViewUtils from '../../../../../../views/reports/reportUtils/helperUtils/viewUtils';

class WastagesByRawData extends Component
{
    render()
    {
        const { reportData } = this.props;
        const rowData = reportDataUtils.getFlattenedWastageItems(reportData);
        const totalsRow = this.getTotalsRow(rowData);

        return (
            <div className="ag-theme-alpine" style={{ height: "80vh" }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={rowData}
                    columnDefs={this.getColumnDefs()}
                    pinnedBottomRowData={[totalsRow]}
                    gridOptions={biUtils.gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    onGridReady = (params) =>
    {
        params.api.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay)
            {
                const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
                const totalsRow = this.getTotalsRow(rows);
                e.api.setPinnedBottomRowData([totalsRow]);
            }
        });
    }

    getTotalsRow = (rows) =>
    {
        const totalCost = rows
            .filter(row => row != null)
            .reduce((sum, item) => sum + item.cost, 0);

        return { cost: totalCost };
    }

    getColumnDefs = () =>
    {
        const columnDefs = [];

        columnDefs.push({ field: 'businessDate', type: `${dataTypeConstants.date}, rowGroupEnabled`, hide: false });
        // columnDefs.push({ field: 'categoryName', type: 'rowGroupEnabled', hide: false });

        columnDefs.push({ field: 'sourceStockItemName', headerName: 'Source Stock Item', type: 'rowGroupEnabled', hide: false });
        columnDefs.push({ field: 'stockItemName', headerName: 'Stock Item', type: 'rowGroupEnabled', hide: false });

        columnDefs.push({
            headerName: 'Item Type',
            valueGetter: ({ data }) => reportViewUtils.getInventoryItemTypeForDisplay(data?.itemType),
            type: 'rowGroupEnabled', hide: false
        });

        columnDefs.push({ field: 'cost', headerName: 'Wastage Cost', type: `${dataTypeConstants.money}` });

        columnDefs.push({ field: 'quantityInReportingUnits', headerName: 'Quantity', type: `${dataTypeConstants.number}` });
        columnDefs.push({ field: 'reportingUnitCode', headerName: 'Units' });
        columnDefs.push({ field: 'unitPriceInReportingUnits', headerName: 'Unit Price', type: `${dataTypeConstants.money}` });

        columnDefs.push({ field: 'staffName', headerName: 'Staff Name', type: 'rowGroupEnabled', hide: false });

        return columnDefs;
    }
}

export default WastagesByRawData;