import { Component } from 'react';
import { Card, CardHeader,  CardBody, CardFooter, Button, Modal } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent';
import FormManager from '../../../../../utils/view/formManager';

const validator = require('../../../../../utils/validator/validator');
const viewUtil = require('../../../../../utils/view/viewUtil');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');

class GiftRewardPointsDialogue extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager()
        this.formManager.viewModel = {};
        this.formManager.view = this;
    }

    render()
    {
        return(
            <Modal isOpen={true} centered animation={false} fade={false}  size="md" style={{maxWidth: '600px', width: '40%'}}>
                <Card>
                    <CardHeader>
                        Gift Reward Points to: {this.props.data.name ?? 'Customer'}
                    </CardHeader>

                    <CardBody>
                        <table width={'100%'}>
                            <tr>
                                <td colSpan={2}>
                                    <InputComponent
                                        inputType="text"
                                        caption="Reward Points"
                                        hintText="Enter the number of reward points to be gifted to this customer"
                                        fieldName="points"
                                        placeholder="Enter number of reward points"
                                        formManager={this.formManager} 
                                    />

                                </td> 
                            </tr>
                        </table>
                    </CardBody>

                    <CardFooter>
                            <table width="100%">
                                <tr>
                                    <td align="right">
                                        <div>
                                            <Button size="md" color="secondary" className="btn-secondary" style={{marginRight:'6px'}} onClick={()=>{this.props.onClose()}}>
                                                    Close
                                            </Button>
                                            <Button size="md" color="success" className="btn-success" style={{paddingInline:'1.3rem', marginRight:'3px'}} onClick={()=>{this.onSave()}}>
                                                    Gift
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                    </CardFooter>
                </Card>
            </Modal>);
    }

    async onSave()
    {
        if (!validator.isGreaterThanZero(this.formManager.viewModel.points))
        {
            viewUtil.showErrorAlert(`Reward Points cannot be null or zero`);
            return;
        }

        const title = 'Gift Reward Points';
        const message = `Are you sure you want to gift ${this.formManager.viewModel.points} reward points to ${this.props.data.name}`;

        const onConfirm = async () =>
        {
            try 
            {
                viewUtil.showSystemModalSpinner("Gifting Reward Points...");

                const rewardsTransferPayload = 
                {
                    customerId: this.props.data.id,
                    points: this.formManager.viewModel.points,
                    senderType: 'franchisor'
                }

                const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/marketing/rewardPoints`
                await rmsApiProxy.post(resourceUrl, rewardsTransferPayload);

                viewUtil.closeModalSpinner();
                viewUtil.showSuccessAlert(`You have successfully gifted ${this.formManager.viewModel.points} points to ${this.props.data.name}`);
                this.props.onClose(true);       // true is flag to indicate exit on successful save
            } 
            catch (error) 
            {
                viewUtil.closeModalSpinner();
                viewUtil.showErrorAlert('Error occurred while gifting reward points: ' + error)   
            }
        }

        viewUtil.showConfirmDialogue(title, message, onConfirm);
    }
}

export default GiftRewardPointsDialogue;