var rmsApiProxy = require('../../../../../../utils/api/rmsApiProxy');
var stringUtil = require('../../../../../../utils/string/stringUtil');
var constants = require('../../../../../../utils/domain/constants');

export async function loadDepositReturns(fromDate, toDate)
{
    var depositReturns = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/depositReturn?fromDate=${fromDate}&toDate=${toDate}`);
    return depositReturns;
}

export async function loadDepositSales(fromDate, toDate)
{
    var sales = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/sales?fromDate=${fromDate}&toDate=${toDate}`);

    const depositSales = sales.filter(sale => sale.charges.some(charge => stringUtil.areStringSame(charge.scheme, constants.Charges.schemes.depositReturn)));

    return depositSales;
}