import React, { memo, useCallback } from "react";
import { FormGroup, FormText, Label } from "reactstrap";
import InputField from "./InputField/InputField";
import SelectField from "./SelectField/SelectField";
import { useEffect } from "react";
import MultiSelectField from "./MultiSelectField/MultiSelectField";

const Field = ({ field, hideLabel = false, onSubFields = () => {}, onValueChange = (value, fieldName) => {} }) => {
     const { label, defaultValue, hintText, fieldName, type, fieldProps, subFields = [] } = field;

     const handleValueChange = useCallback(
          (value) => {
               onValueChange(value, fieldName);
               onSubFields(fieldName, subFields[value] || []);
          },
          [onSubFields, subFields, fieldName,onValueChange]
     );

     const RenderFieldType = useCallback(() => {
          const props = {
               name: fieldName,
               onValueChange:handleValueChange,
               defaultValue,
               ...fieldProps,
          };
          switch (type) {
               case "input:text":
                    return (
                         <FormGroup>
                              {!hideLabel && (
                                   <Label>
                                        {label}
                                        {fieldProps.required && <span style={{ color: "red" }}> *</span>}
                                   </Label>
                              )}
                              <InputField type="text" {...props} />
                              {hintText && <FormText style={{whiteSpace: 'pre-line'}}>{hintText}</FormText>}
                         </FormGroup>
                    );
               case "input:hidden":
                    return (
                        <InputField type="hidden" {...props} />
                    );
               case "input:number":
                    return (
                         <FormGroup>
                              {!hideLabel && (
                                   <Label>
                                        {label}
                                        {fieldProps.required && <span style={{ color: "red" }}> *</span>}
                                   </Label>
                              )}
                              <InputField type="number" {...props} />
                              {hintText && <FormText style={{whiteSpace: 'pre-line'}}>{hintText}</FormText>}
                         </FormGroup>
                    );
               case "input:checkbox":
                    return (
                         <FormGroup check>
                              <InputField type="checkbox" {...props} />
                              {!hideLabel && (
                                   <Label>
                                        {label}
                                        {fieldProps.required && <span style={{ color: "red" }}> *</span>}
                                   </Label>
                              )}
                              {hintText && <FormText style={{whiteSpace: 'pre-line'}}>{hintText}</FormText>}
                         </FormGroup>
                    );
               case "select":
                    return (
                         <FormGroup>
                              {!hideLabel && (
                                   <Label>
                                        {label}
                                        {fieldProps.required && <span style={{ color: "red" }}> *</span>}
                                   </Label>
                              )}
                              <SelectField {...props} />
                              {hintText && <FormText style={{whiteSpace: 'pre-line'}}>{hintText}</FormText>}
                         </FormGroup>
                    );
               case "multiselect":
                    return (
                         <FormGroup>
                              {!hideLabel && (
                                   <Label>
                                        {label}
                                        {fieldProps.required && <span style={{ color: "red" }}> *</span>}
                                   </Label>
                              )}
                              <MultiSelectField {...props} />
                              {hintText && <FormText style={{whiteSpace: 'pre-line'}}>{hintText}</FormText>}
                         </FormGroup>
                    );
               default:
                    return null;
          }
     }, [type, fieldProps, fieldName, hideLabel, hintText, label, handleValueChange, defaultValue]);

     useEffect(() => {
          handleValueChange(defaultValue);
     }, [defaultValue, handleValueChange]);

     return <RenderFieldType />;
};

export default memo(Field);
