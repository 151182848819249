import React from "react";
import { Handle, Position } from "reactflow";
import styled from "styled-components";

const AddKotBtn = ({onClick = ()=>{}}) => {
     return (
          <Container onClick={onClick}>
               <IconWrapper>
                    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                         <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
               </IconWrapper>
               <Label>KOT</Label>
               <Handle type="source" position={Position.Bottom}/>
          </Container>
     );
};

export default AddKotBtn;

const Container = styled.div`
     position: absolute;
     top: calc(100% + 20px);
     left: 50%;
     transform: translateX(-50%);
     gap: 4px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: white;
     border: 2px solid #fc5555;
     border-radius: 15px;
     padding: 8px 16px;
     transition: 250ms ease;
     cursor: pointer;
     
     :hover {
          filter: brightness(0.95);
          box-shadow: 0 5px 20px #00000020;
     }
`;

const Label = styled.div`
     font-size: 16px;
     font-weight: 600;
     line-height: 20px;
     color: black;
     
`;
const IconWrapper = styled.div`
     display: grid;
     place-items: center;
     width: 20px;
     height: 20px;
     svg {
          fill: black;
     }
`;
