import TableRow from './TableRow';

export { default as ColumnDefinition } from './ColumnDefinition';
export { default as tableConstants } from './TableConstants';
export { default as TableColumn } from './TableColumn';
export { default as TableRow } from './TableRow';

export default class TableData 
{
    #headerRow;
    #footerRow;
    #dataRows;
    #columnDefinitions;
    #footNotes;

    constructor(field, title, ...columnDefinitions)
    {
        if (columnDefinitions.length === 0)
        {
            throw new Error("Invalid columnDefinitions, must be non-empty array of ColumnDefinition objects")
        }

        if (columnDefinitions.reduce((previous, current) => previous + current.width, 0) !== 100)
        {
            throw new Error("Invalid columnDefinitions, column width percentage must add up to 100")
        }

        this.field = field;
        this.title = title ?? "";
        this.#columnDefinitions = columnDefinitions;
        this.#dataRows = [];
    }

    setHeaderRow(...values)
    {
        const tableRow = new TableRow(this.#columnDefinitions);
        tableRow.setColumnValues(...values);
        this.#headerRow = tableRow;
        return tableRow;
    }

    setFooterRow(...values)
    {
        const tableRow = new TableRow(this.#columnDefinitions);
        tableRow.setColumnValues(...values);
        this.#footerRow = tableRow;
        return tableRow;
    }

    addDataRow(...values)
    {
        const tableRow = new TableRow(this.#columnDefinitions);
        tableRow.setColumnValues(...values);
        this.#dataRows.push(tableRow);
        return tableRow;
    }

    get headerRow() { return this.#headerRow; }
    get footerRow() { return this.#footerRow; }
    get dataRows() { return this.#dataRows; }

    get footNotes() { return this.#footNotes ?? []; }
    set footNotes(values) { this.#footNotes = Array.isArray(values) ? values : [values]; }

    renderPayload()
    {
        return {

            field: this.field || "",
            title: this.title || "",
            columnDefinitions: this.#columnDefinitions.map(columnDefinition => columnDefinition.renderPayload()),
            dataRows: this.#dataRows.map(dataRow => dataRow.renderPayload()),

            // optional/undefined fields
            headerRow: this.#headerRow && this.#headerRow.renderPayload(),
            footerRow: this.#footerRow && this.#footerRow.renderPayload(),
            footNotes: this.#footNotes && this.#footNotes.length > 0 && this.#footNotes.map(footNote => footNote),
        }
    }
}