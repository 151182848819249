const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const formFieldValidators = require("../../../../../utils/validator/formFieldValidators");

export function save(storefront, storefrontDeliveryChargesPayload)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/deliveryCharges`;
    return rmsApiProxy.post(resourceUrl, storefrontDeliveryChargesPayload);
}

export function loadStorefrontDeliveryCharges(storefront)
{
    var storefrontResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/deliveryCharges`;
    return rmsApiProxy.get(storefrontResourceUrl);
}

export function validateDeliveryZonesData(deliveryZonesData, maxDistance)
{
    let hasErrors = false;
    const updatedDeliveryZoneData = [];
    let previousDeliveryZone;
    deliveryZonesData.forEach((currentDeliveryZone, index) =>
    {
        const currDeliveryZoneCopy = { ...currentDeliveryZone };
        updatedDeliveryZoneData.push(currDeliveryZoneCopy);

        let deliveryZoneDistance = currDeliveryZoneCopy.distance;
        let deliveryZoneCharges = currDeliveryZoneCopy.charges;
        const isLastItem = index === deliveryZonesData.length - 1;
        let deliveryZoneDistanceError = formFieldValidators.numberFieldValidation(
            deliveryZoneDistance,
            'Staggered distance',
            true,
            previousDeliveryZone?.distance,
            isLastItem ? maxDistance : null,
            isLastItem ? "Max distance" : null
        );
        let deliveryZoneChargesError = formFieldValidators.numberFieldValidation(
            deliveryZoneCharges,
            "Staggered charges",
            true,
            previousDeliveryZone?.charges
        )
        hasErrors = deliveryZoneDistanceError || deliveryZoneChargesError;
        currDeliveryZoneCopy.errorDistance = deliveryZoneDistanceError;
        currDeliveryZoneCopy.errorCharges = deliveryZoneChargesError;
        previousDeliveryZone = currDeliveryZoneCopy;

    });
    return [hasErrors, updatedDeliveryZoneData];
}



export function validateStoreFrontDeliveryCharges(deliveryCharges)
{
    let errorMessage;

    let minimumDeliveryOrderAmount = deliveryCharges.minimumDeliveryOrderAmount;
    errorMessage = formFieldValidators.numberFieldValidation(minimumDeliveryOrderAmount, "Minimum Delivery Order Amount", false);
    if (errorMessage)
    {
        return errorMessage
    }

    minimumDeliveryOrderAmount = stringUtil.isStringNullOrEmpty(minimumDeliveryOrderAmount) ? minimumDeliveryOrderAmount : Number(minimumDeliveryOrderAmount);

    let maxDeliveryDistance = deliveryCharges.maxDeliveryDistance;
    errorMessage = formFieldValidators.numberFieldValidation(maxDeliveryDistance, "Max Delivery Distance", true, 0);
    if (errorMessage)
    {
        return errorMessage;
    }
    maxDeliveryDistance = Number(maxDeliveryDistance);

    let vatPercentage = deliveryCharges.vatPercentage;
    errorMessage = formFieldValidators.numberFieldValidation(vatPercentage, "VAT Percentage", false);
    if (errorMessage)
    {
        return errorMessage;
    }

    vatPercentage = stringUtil.isStringNullOrEmpty(vatPercentage) ? vatPercentage : Number(vatPercentage);

    let fixedDeliveryCharges = deliveryCharges.fixedDeliveryCharges;
    errorMessage = formFieldValidators.numberFieldValidation(fixedDeliveryCharges, "Fixed Delivery Charges", false);
    if (errorMessage)
    {
        return errorMessage
    }

    fixedDeliveryCharges = stringUtil.isStringNullOrEmpty(fixedDeliveryCharges) ? fixedDeliveryCharges : Number(fixedDeliveryCharges);

    deliveryCharges.minimumDeliveryOrderAmount = minimumDeliveryOrderAmount;
    deliveryCharges.maxDeliveryDistance = maxDeliveryDistance;
    deliveryCharges.fixedDeliveryCharges = fixedDeliveryCharges;
    return null;
}

export function formatStaggeredDeliveryChargesData(staggeredDeliveryCharges)
{
    return staggeredDeliveryCharges.map(item => ({
        distance: Number(item.distance),
        charges: Number(item.charges)
    }));
}

export function DeliveryZoneDataItem(id, distance, charges) {
    return {
          id: id || guidUtil.generateGuid(),
          distance:  stringUtil.isStringNullOrEmpty(distance) ? "" : distance,
          errorDistance: "",
          charges: stringUtil.isStringNullOrEmpty(charges) ? "" : charges,
          errorCharges: "",
     };
};
