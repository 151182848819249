
export function getDefaultPieChartOptions()
{
    return {

        title: null,
        subtitle: null,
        data: null,
        background: { fill: 'WhiteSmoke', visible: false },

        series: [
            {
                type: 'pie',

                strokeWidth: 0,
                showInLegend: true,

                calloutLabelKey: null,
                sectorLabelKey: null,
                angleKey: null,

                calloutLabel: { minAngle: 0 },
                calloutLine: { strokeWidth: 2 },
                shadow: { enabled: true },
                tooltip: { renderer: null },

                sectorLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    formatter: null,
                },

                highlightStyle: {
                    item: {
                        fillOpacity: 0,
                        stroke: '#535455',
                        strokeWidth: 1,
                    },
                },
            },
        ],

        legend: {
            enabled: true,
            position: 'bottom'
        },
    };
}

export function getDefaultLineChartOptions()
{
    return {

        autoSize: true,

        title: null,
        subtitle: null,
        data: null,
        background: { fill: 'WhiteSmoke', visible: false },

        series: [],

        legend: {
            enabled: true,
            position: 'bottom'
        },
    };
}

export const palette = {
    green: '#57b757',
    red: '#f3622d',
    orange: '#fba71b',
    lightBlue: '#41a9c9',
    blue: '#4258c9',
    purple: '#BA55D3',
    burlyWood: '#DEB887',
    cadetBlue: '#5F9EA0',
    Gold: '#FFD700',
    brown: '#DAA520',
    salmon: '#FA8072'
}

export const colors = {
    profit: palette.green,
    wages: palette.red,
    venueCommission: palette.orange,
    cogs: palette.lightBlue,
    fixedCost: palette.blue
}

