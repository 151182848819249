import React from 'react';

import Expense from '../components/Expense';
import validator from '../../../../../utils/validator/validator';

const localizationUtils = require('../../../../../utils/domain/localizationUtils');
const stringUtil = require('../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const passportSelectors = require('../../../../../utils/state/stateSelectors/passportSelectors');

//
// ExpenseUtils is used by Expenses and Bills components
//

export function getComponent(row, props)
{
    return <Expense data={row} {...props} />;
}

export function validateRow(isNew, row)
{
    const vatCaption = localizationUtils.getVatCaption();

    if (stringUtil.isStringNullOrEmpty(row.propertyId))
        return "Property cannot be left empty;"

    if (stringUtil.isStringNullOrEmpty(row.supplierId))
        return "Supplier cannot be left empty;"

    if (stringUtil.isStringNullOrEmpty(row.categoryId))
        return "Category cannot be left empty;"

    if (stringUtil.isStringNullOrEmpty(row.subCategoryId))
        return "Sub Category cannot be left empty;"

    if (stringUtil.isStringNullOrEmpty(row.accountId))
        return "Account cannot be left empty;"

    if (!dateUtil.isValidDate(row.expenseDate))
        return "Expense date is not valid";

    if (!dateUtil.isValidDate(row.fromDate))
        return "From date is not valid";

    if (!dateUtil.isValidDate(row.toDate))
        return "To date is not valid";

    if (dateUtil.isAfter(row.fromDate, row.toDate))
        return "From date cannot be after To date";

    if (!validator.isNumeric(row.amount))
        return "Amount is missing or invalid";

    if (!validator.isNumeric(row.vat))
        return `${vatCaption} is missing or invalid`;

    return null;
}

export async function save(row)
{
    row.enteredBy = passportSelectors.selectFullName();

    try
    {
        return await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/expenses`, row);
        
    } catch (error)
    {
        throw error;
    }
}

export function getAttachmentsParam(isNew, row) 
{
    const param = {};
    param.objectType = 'expense';
    param.objectId = row.id;
    return param;
}
