import React, { Component } from 'react';
import { Row, Button, Card, CardHeader } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import InputComponent from '../../../../../components/form/inputComponent.js'
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent.js'
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent.js'
import SelectComponent from '../../../../../components/form/selectComponent.js'
import OrgSelectComponent from '../../../../../components/form/orgSelectComponent.js'
import constants from '../../../../../utils/domain/constants.js'
import guidUtil from '../../../../../utils/guid/guidUtil.js'

var QRCode = require('qrcode.react');

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../../../../../utils/state/stateSelectors/catalogSelectors');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const FormManager = require('../../../../../utils/view/formManager.js');
class VoucherCode extends Component 
{

    constructor(props) 
    {
        super(props);

        this.state = 
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }


    render() 
    {

        var maxRedeemDisabled = false;

        if (this.state.formManager.viewModel.claimType == null || 
            this.state.formManager.viewModel.claimType == constants.voucherCodeClaimType.oneTimeOnlyGuest ||
            this.state.formManager.viewModel.claimType == constants.voucherCodeClaimType.oneTimeOnlyCustomer ||
            this.state.formManager.viewModel.claimType == constants.voucherCodeClaimType.oneTimeOnlyNewCustomer ||
            this.state.formManager.viewModel.claimType == constants.voucherCodeClaimType.multipleTimesGuest)
            {
                maxRedeemDisabled = true;
                this.state.formManager.viewModel.claimMaxLimit = "";
            }

        return (
            <Tabs>
                <TabList>
                    <Tab>General</Tab>
                    <Tab>Venues</Tab>
                    <Tab>Channels</Tab>
                    <Tab>Dispatch Types</Tab>
                    <Tab>Properties</Tab>
                    <Tab>QR</Tab>
                </TabList>

                <TabPanel style={{ marginTop: '30px' }}>
                    <table width="100%">
                        <tr>
                            <td width="50%">
                                <table width="100%">
                                    <tr>
                                        <td width="99%">
                                            <InputComponent inputType="code" caption="Voucher Code" hintText="Required (Must be unique)" 
                                                style={{marginRight:'2px'}} 
                                                fieldName="code" placeholder="Enter voucher code" 
                                                inputReadOnly={this.props.isReadOnly}
                                                formManager={this.state.formManager}/>
                                        </td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                            <Button color="primary" disabled={this.state.isReadOnly} outline style={{ marginRight: '20px', marginTop:'-5px', marginBottom:'2px' }} 
                                                    onClick={() => 
                                                            {
                                                                this.state.formManager.viewModel.code = guidUtil.generateUniqueCode();
                                                                this.setState({});
                                                            }}>
                                                <i className="fa fa-lightbulb-o"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td width="50%">
                                <BooleanSelectComponent
                                    caption="Is Disabled"
                                    fieldName="isDisabled"
                                    hintText="Disable to mark this voucher code unavailable"
                                    comboReadOnly={this.props.isReadOnly}
                                    clearable={false}
                                    formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <InputComponent inputReadOnly={this.props.isReadOnly} inputType="date" caption="Start Date" hintText="Required (Specify date since when this voucher will be available)" style={{ marginRight: '20px' }} fieldName="startDate" placeholder="Enter start date of voucher" formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputReadOnly={this.props.isReadOnly} inputType="date" caption="Expiry Date" hintText="Optional (Specify date when this voucher will be expired)" fieldName="expiryDate" placeholder="Enter expiry date of voucher" formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <SelectComponent
                                    optionValues={constants.getVoucherTypes()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="label"
                                    fieldName="type"
                                    caption="Voucher Type"
                                    hintText="Indicates if voucher value is fixed or percentage based"
                                    comboReadOnly={this.props.isReadOnly}
                                    clearable={false}
                                    style={{ marginRight: '20px' }}
                                    formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputReadOnly={this.props.isReadOnly} inputType="text" caption="Redeem Value" hintText="Required (voucher value(s) with comma separated)" fieldName="enrichedValue" placeholder="Voucher Value" formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <SelectComponent
                                    optionValues={constants.getVoucherClaimTypes()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="label"
                                    fieldName="claimType"
                                    caption="Redeem Type"
                                    hintText="Indicates how voucher can be redeemed"
                                    comboReadOnly={this.props.isReadOnly}
                                    clearable={false}
                                    style={{ marginRight: '20px' }}
                                    formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                                <InputComponent inputType="text" caption="Max Redeem Limit" disabled={maxRedeemDisabled} hintText="Optional (Indicates how many times a customer can redeem the current voucher)" fieldName="claimMaxLimit" placeholder="Max Number Of Redeem" formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <InputComponent inputReadOnly={this.props.isReadOnly} inputType="text" caption="Minimum Order Value" style={{ marginRight: '20px' }} hintText="Optional (Indicates minimum order value required for this voucher)" fieldName="minimumOrderValue" placeholder="Minimum Order Value" formManager={this.state.formManager} />
                            </td>

                            <td width="50%">
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                <InputComponent inputReadOnly={this.props.isReadOnly} inputType="text" caption="Marketing Campaign Title" hintText="Optional (Indicates title for marketing this voucher)" fieldName="marketingCampaignCaption" placeholder="Campaign Title" formManager={this.state.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                            <InputComponent inputReadOnly={this.props.isReadOnly} inputType="text" multiLine={true} caption="Notes" rows={4}
                                                fieldName="notes" placeholder="Enter voucher code notes." formManager={this.state.formManager}/>
                            </td>
                        </tr>

                    </table>
                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>
                    <table width="100%">
                        <tr>
                            <td>
                                <MultiSelectComponent
                                    options={constants.getVenueCodes()}
                                    displayFieldName="venueCode"
                                    valueFieldName="venueCode"
                                    selectedOptions={this.state.formManager.viewModel.inclusiveVenueCodes == null ? [] : this.state.formManager.viewModel.inclusiveVenueCodes.map(x => ({ venueCode: x }))}
                                    onSelectionChanged={(selectedOptions) => 
                                                        {
                                                            let inclusiveVenueCodes = [];

                                                            if (selectedOptions.length > 0)
                                                            {
                                                                inclusiveVenueCodes = selectedOptions.map(option => option.venueCode);
                                                            }

                                                            this.state.formManager.viewModel.inclusiveVenueCodes = inclusiveVenueCodes;
                                                            this.setState({});
                                                            
                                                        }}
                                    comboReadOnly={this.props.isReadOnly}
                                    clearable={true}
                                    fieldName="inclusiveVenueCodes"
                                    caption="Applicable Venues"
                                    hintText="Choose applicable venues for current voucher code. If none is selected, all venues will become available."
                                />
                            </td>
                        </tr>
                    </table>
                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>
                    <table width="100%">
                        <tr>
                            <td>
                                <MultiSelectComponent
                                    options={constants.getChannels()}
                                    displayFieldName="channel"
                                    valueFieldName="channel"
                                    selectedOptions={this.state.formManager.viewModel.inclusiveChannels == null ? [] : this.state.formManager.viewModel.inclusiveChannels.map(x => ({ channel: x }))}
                                    onSelectionChanged={(selectedOptions) => 
                                                        {
                                                            let inclusiveChannels = [];

                                                            if (selectedOptions.length > 0)
                                                            {
                                                                inclusiveChannels = selectedOptions.map(option => option.channel);
                                                            }

                                                            this.state.formManager.viewModel.inclusiveChannels = inclusiveChannels;
                                                            this.setState({});
                                                            
                                                        }}
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    fieldName="inclusiveChannels"
                                    caption="Applicable Channels"
                                    hintText="Choose applicable channels for current voucher code. If none is selected, all channels will become available."
                                />
                            </td>
                        </tr>
                    </table>
                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>
                    <table width="100%">
                        <tr>
                            <td>
                                <MultiSelectComponent
                                    options={constants.getDispatchTypes()}
                                    displayFieldName="dispatchType"
                                    valueFieldName="dispatchType"
                                    selectedOptions={this.state.formManager.viewModel.inclusiveDispatchTypes == null ? [] : this.state.formManager.viewModel.inclusiveDispatchTypes.map(x => ({ dispatchType: x }))}
                                    onSelectionChanged={(selectedOptions) => 
                                                        {
                                                            let inclusiveDispatchTypes = [];

                                                            if (selectedOptions.length > 0)
                                                            {
                                                                inclusiveDispatchTypes = selectedOptions.map(option => option.dispatchType);
                                                            }

                                                            this.state.formManager.viewModel.inclusiveDispatchTypes = inclusiveDispatchTypes;
                                                            this.setState({});
                                                            
                                                        }}
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    fieldName="inclusiveDispatchTypes"
                                    caption="Applicable Dispatch Methods"
                                    hintText="Choose applicable dispatch methods for current voucher code. If none is selected, all dispatch methods will become available."
                                />
                            </td>
                        </tr>
                    </table>
                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>
                    <table width="100%">
                        <tr>
                            <td>
                                {this.renderOrgComponentForInclusiveProperties()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {this.renderOrgComponentForExclusiveProperties()}
                            </td>
                        </tr>
                    </table>
                </TabPanel>

                <TabPanel style={{ marginTop: '30px' }}>
                    <table width="100%">
                        <tr>
                            <td style={{textAlign:'center'}}>
                                {this.renderQRImage()}
                            </td>
                        </tr>
                    </table>
                </TabPanel>

            </Tabs>);
    }

    renderOrgComponentForInclusiveProperties()
    {
        let selectedOrgNodes = null;
        if (this.state.formManager.viewModel.inclusivePropertyIds != null && this.state.formManager.viewModel.inclusivePropertyIds.length > 0)
        {
            var properties = catalogSelectors.selectProperties();
            selectedOrgNodes = {};
            properties = this.state.formManager.viewModel.inclusivePropertyIds.map(id => properties.find(p => p.id === id));
            selectedOrgNodes.properties = properties.filter(property => property != null);
            selectedOrgNodes.franchisors = [];
            selectedOrgNodes.franchisees = [];
        }
        
        return (

            <OrgSelectComponent key={guidUtil.generateGuid()} style={{ marginLeft: '1px', minWidth: '300px' }}

                caption="Inclusive Properties"
                hintText="Include properties which are participating for voucher. If none, all properties will be included by default."
                allowedOrgNodes={currentOrgNodeSelectors.selectAllowedOrgNodes()}
                selectedOrgNodes={selectedOrgNodes}
                allowedOrgLevels={["property"]}                    
                multiSelect={true}                    
                isReadOnly={this.props.isReadOnly}                    
                onChange={selectedOrgNodes =>
                {
                    this.state.formManager.viewModel.inclusivePropertyIds = selectedOrgNodes.properties.map(p => p.id);
                    this.state.formManager.viewModel.exclusivePropertyIds = [];
                    this.setState({});
                }}
            />
        );
    }

    renderOrgComponentForExclusiveProperties()
    {
        let selectedOrgNodes = null;
        if (this.state.formManager.viewModel.exclusivePropertyIds != null && this.state.formManager.viewModel.exclusivePropertyIds.length > 0)
        {
            var properties = catalogSelectors.selectProperties();
            selectedOrgNodes = {};
            properties = this.state.formManager.viewModel.exclusivePropertyIds.map(id => properties.find(p => p.id === id));
            selectedOrgNodes.properties = properties.filter(property => property != null);
            selectedOrgNodes.franchisors = [];
            selectedOrgNodes.franchisees = [];
        }

        return (<OrgSelectComponent key={guidUtil.generateGuid()} style={{ marginLeft: '1px', minWidth: '300px' }}
                    
                    caption="Exclusive Properties"
                    hintText="Exclude properties which are NOT participating for voucher. If none, no properties will be excluded by default."
                    allowedOrgNodes={currentOrgNodeSelectors.selectAllowedOrgNodes()}
                    selectedOrgNodes={selectedOrgNodes}
                    allowedOrgLevels={["property"]}                    
                    multiSelect={true}                    
                    isReadOnly={this.props.isReadOnly}                 
                    onChange={selectedOrgNodes =>
                    {
                        this.state.formManager.viewModel.exclusivePropertyIds = selectedOrgNodes.properties.map(p => p.id);
                        this.state.formManager.viewModel.inclusivePropertyIds = [];
                        this.setState({});
                    }}
                />);
    }

    renderQRImage()
    {

        var orgName = "";
        if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
            orgName = orgSelectors.selectFranchisor(currentOrgNodeSelectors.selectCurrentFranchisor().id).name;
        else if (currentOrgNodeSelectors.isCurrentFranchiseeSelected())
            orgName = orgSelectors.selectFranchisee(currentOrgNodeSelectors.selectCurrentFranchisee().id).name;
        else
            orgName = orgSelectors.selectProperty(currentOrgNodeSelectors.selectCurrentProperty().id).name;

        var voucherValue = `com.flipdish.qr.voucher.${this.state.formManager.viewModel.id}`;

        return <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
                    <Card style={{width:'500px'}}>
                        <CardHeader>
                            <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{background:'#383838', color:'white'}}>
                                        <h2>{orgName}</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <hr/>
                                    </td>
                                </tr>
                                <tr>
                                    <td onClick={()=>{this.downloadQRImage()}}>
                                        <QRCode id="qrCodeEl" value={voucherValue} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small className="text-muted">{`Voucher QR Code: ${voucherValue}`}</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small className="text-muted">NOTE: Click on QR to download the QR image</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <hr/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{background:'#383838', color:'white'}}>
                                        <h2>Voucher Card</h2>
                                    </td>
                                </tr>
                            </table>
                        </CardHeader>
                    </Card>
               </div>
    }

    downloadQRImage()
    {
        const qrCodeURL = document.getElementById('qrCodeEl')
                                .toDataURL("image/png")
                                .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QR_Code.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }
}

export default VoucherCode;
