

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardHeader, CardText, CardBody} from 'reactstrap';
import { CloudinaryContext, Image } from 'cloudinary-react';

var configurationManager = require('../../../../../utils/config/configurationManager.js');
var constants = require('../../../../../utils/domain/constants.js');

class SignagePlayerToolBarComponent extends Component
{
    render()
    {
        return (<Card style={{borderWidth:'0px'}}>
                    <CardHeader>
                        <div style={{textAlign:'center'}}>Signage Tool Bar</div>
                    </CardHeader>
                    <CardBody>
                        {this.renderToolBarButton("Image Slide",   'app/back.office/icons/slide.image.png', constants.signageSlideTypes.Image)}
                        {this.renderToolBarButton("Video Slide", 'app/back.office/icons/slide.video.png', constants.signageSlideTypes.Video)}
                        {this.renderToolBarButton("Active Orders", 'app/back.office/icons/slide.active.orders.png', constants.signageSlideTypes.ActiveOrders)}
                        {this.renderToolBarButton("Weather Slide", 'app/back.office/icons/slide.weather.png', constants.signageSlideTypes.Weather)}
                    </CardBody>
                </Card>);
    }

    renderToolBarButton(title, icon, type)
    {
        return (<Card className="text-center" style={{ width: '12rem'}} onClick={()=>
                                                                                    {
                                                                                        this.props.onClick(type)
                                                                                    }}>
                    
                    <CardBody>
                        <div align="center" style={{ fontSize: '1rem' }}>
                            {this.renderIcon(icon)}
                            <div>
                                <CardText>
                                    <div className="text-muted" style={{marginTop:'10px'}}>{title}</div>
                                </CardText>
                            </div>
                        </div>
                    </CardBody>
                </Card>);
    }

    renderIcon(icon)
    {
        var cloudName = configurationManager.getConfig().cdnAppCloudName;
        return (<CloudinaryContext cloudName={cloudName}>
                    <div>
                        <Image publicId={icon} width="64" height="64" />
                    </div>
                </CloudinaryContext>);

    }
}


const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(SignagePlayerToolBarComponent);