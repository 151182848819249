import React, { Component } from 'react';

import SearchBarComponent from '../../../../components/form/searchBarComponent';
import { Card, CardHeader, CardBody } from 'reactstrap';
import CloseButton from '../../../../components/button/closeButton';
import ErrorMessage from '../../../../components/error/errorMessage';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ExcelExportButton from '../../../../components/button/excelExportButton';
import IncidentRow from './IncidentRow';

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../utils/string/stringUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const reportStyleUtils = require('../../../../utils/reports/styleUtils');
const ExcelWorkBook = require('../../../../components/button/excel/excelWorkBook');


class IncidentLogs extends Component
{
    constructor(props) 
    {
        super(props);
        this.state = {
            isLoading: false,
            error: null,
            incidentLogs: [],
            incidentTypes: []
        }
    }

    render() 
    {
        let contents = null;
        if (this.state.isLoading)
        {
            contents = <Spinner text="Loading IncidentLogs..." />
        }
        else if (this.state.error)
        {
            contents = <ErrorMessage message={this.state.error} />
        }
        else if (this.state.incidentLogs.length == 0)
        {
            contents = <EmptyData title="No Incident Logs" text="No Incident Logs Available. Please select a valid date range and hit Search" />
        }
        else 
        {
            contents = <div className='overflow-auto'>
                <table className='table table-bordered'>

                    <thead className='thead-light' style={{ fontSize: "1.1em" }}>
                        <tr>
                            <th>Incident</th>
                            <th style={{...reportStyleUtils.tdCenterAlignedStyle, "width":"80%"}}><i className='fa fa-list-ol' />&nbsp;Responses</th>
                        </tr>
                    </thead>

                    <tbody style={{ fontSize: "1.0em" }}>
                        {
                            this.state.incidentLogs.map((incidentLog, index) => 
                            {
                                const backgroundColor = index % 2 ? "GhostWhite" : "White";

                                const incidentResponseLogs = incidentLog.incidentResponseLogs
                                const rowSpan = incidentResponseLogs.length === 0 ? 1 : incidentResponseLogs.length + 1;

                                return <IncidentRow incidentLog={incidentLog}
                                    rowSpan={rowSpan}
                                    incidentResponseLogs={incidentResponseLogs}
                                    backgroundColor={backgroundColor} />;
                            })
                        }
                    </tbody>
                </table>
            </div>;
        }

        return (
            <Card>
                <CardHeader>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold', marginRight: '10px' }}>Incident Logs</div>
                            <SearchBarComponent
                                searchCriteria={this.state.searchCriteria}
                                disabled={this.state.isLoading}
                                searchSingleDate={true}
                                onSearch={async (searchCriteria) =>
                                {
                                    this.state.searchCriteria = searchCriteria;
                                    this.state.isLoading = true;
                                    this.state.reportData = {};
                                    this.setState({});
                                    try
                                    {
                                        const incidentLogs = await this.onSearchIncidentLogs(searchCriteria);
                                        this.state.incidentLogs = incidentLogs;
                                    }
                                    catch (error)
                                    {
                                        this.state.error = error;
                                    }
                                    this.state.isLoading = false;
                                    this.setState({});
                                }}
                                onChange={(searchCriteria) =>
                                {
                                    this.state.searchCriteria = searchCriteria;
                                    this.state.incidentLogs = [];
                                    this.setState({});
                                }}
                            />
                        </div>

                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div className="mx-2">
                                <ExcelExportButton label="Excel Export" disabled={this.state.isLoading} onExport={() => this.exportToExcel(this.state.searchCriteria.startDate, this.state.incidentLogs)} />
                            </div>
                            <CloseButton disabled={this.state.isLoading} />
                        </div>
                    </div>
                </CardHeader>

                <CardBody>
                    {contents}
                </CardBody>
            </Card>

        );
    }

    exportToExcel(businessDate, incidentLogs) 
    {
        let excelWorkbook = new ExcelWorkBook();
        excelWorkbook.workBookName = `Incident Logs ${businessDate}`;

        const incidentLogsWorkSheet = excelWorkbook.addWorksheet("Incident Logs");
        incidentLogsWorkSheet.addColHeader("Incident", 30);
        incidentLogsWorkSheet.addColHeader("Incident Question", 60);
        incidentLogsWorkSheet.addColHeader("Incident Response", 60);
        incidentLogsWorkSheet.addColHeader("Occurred At");
        incidentLogsWorkSheet.addColHeader("Created On");
        incidentLogsWorkSheet.addColHeader("Created By");
        incidentLogsWorkSheet.addColHeader("Last Updated On");
        incidentLogsWorkSheet.addColHeader("Last Updated By");

        incidentLogs.forEach((incidentLog, index) =>
        {
            let incidentResponseLogs = [{}];
            if(incidentLog.incidentResponseLogs && incidentLog.incidentResponseLogs.length > 0) {
                incidentResponseLogs = incidentLog.incidentResponseLogs;
            };

            const bgColor = index % 2 === 0 ? "E6E6E6" : "C6C6C6"; 

            const incidentLogRow = incidentLogsWorkSheet.addRow();
            incidentLogRow.addDataCell(incidentLog.incidentTypeName, bgColor);
            incidentLogRow.addDataCell(incidentResponseLogs[0].incidentQuestion, bgColor);
            incidentLogRow.addDataCell(this.getResponseForWorkSheet(incidentResponseLogs[0]), bgColor);
            incidentLogRow.addDataCell(incidentLog.dateTime, bgColor);
            incidentLogRow.addDataCell(incidentLog.createdOn, bgColor);
            incidentLogRow.addDataCell(incidentLog.createdByName, bgColor);
            incidentLogRow.addDataCell(incidentLog.lastUpdatedOn || "", bgColor);
            incidentLogRow.addDataCell(incidentLog.lastUpdatedByName || "", bgColor);

            incidentResponseLogs.slice(1).forEach(incidentResponseLog => {
                const incidentLogRow = incidentLogsWorkSheet.addRow();
                incidentLogRow.addDataCell("", bgColor);
                incidentLogRow.addDataCell(incidentResponseLog.incidentQuestion, bgColor);
                incidentLogRow.addDataCell(this.getResponseForWorkSheet(incidentResponseLog), bgColor);
                incidentLogRow.addDataCell("", bgColor);
                incidentLogRow.addDataCell("", bgColor);
                incidentLogRow.addDataCell("", bgColor);
                incidentLogRow.addDataCell("", bgColor);
                incidentLogRow.addDataCell("", bgColor);
            });
        });

        return excelWorkbook;
    }

    getResponseForWorkSheet(incidentResponseLog)
    {
        if(!incidentResponseLog.response)
            return "";

        switch (incidentResponseLog.incidentQuestionResponseType)
        {
            case "Boolean":
                if(stringUtil.areStringSame(incidentResponseLog.response, "true"))
                    return "Yes";
                else if(stringUtil.areStringSame(incidentResponseLog.response, "false"))
                    return "No";
                else
                    return "";
            default:
                return incidentResponseLog.response;
        }
    }

    async onSearchIncidentLogs(searchCriteria)
    {
        const incidentLogs = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/foodsafety/incidents/incidentLogs?businessDate=${searchCriteria.startDate}`);
        this.enrichIncidentLogs(incidentLogs);

        return incidentLogs;
    }

    enrichIncidentLogs(incidentLogs)
    {
        incidentLogs.forEach(incidentLog => 
        {
            incidentLog.createdByName = incidentLog.createdByName || "N/A";
            incidentLog.lastUpdatedByName = incidentLog.lastUpdatedByName || "N/A";
        });
    }
}

export default IncidentLogs;