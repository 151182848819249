import React, { Component } from 'react';

import StockItemSections from '../StockItemSections';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';
import FormManager from '../../../../../../utils/view/formManager';

const arraySort = require('array-sort');

const stringUtil = require('../../../../../../utils/string/stringUtil');
const unitUtil = require('../../../../../../utils/domain/unitUtil');

// A stockItem can now be
// wastage tracked (stockItem.isWastageTracked=true)
// irrespective of whether it is raw or recipe stock item

class Details extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.onFieldChanged = this.onFieldChanged;
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        //Not editable if it is an existing item and (temporary) units fields are not set
        this.unitsReadonly = !this.props.isNew && (!stringUtil.isStringNullOrEmpty(this.props.data.unitTypeCode) && !stringUtil.isStringNullOrEmpty(this.props.data.coreUnitCode));
    }

    onFieldChanged = (event) =>
    {
        // Tab: Tracking Profiles shows/hides depending upon stockItem.isInventoryTracked

        const id = event.target.id;

        if (id === "isInventoryTracked")
        {
            this.props.onFieldChanged();
        }

        if (id === "recipeProfileEnabled")
        {
            if(event.target.value === true)
            {
                this.props.data.recipeProfile = this.props.data.recipeProfile || { recipeItems: [] };
                this.props.data.prepProfile = this.props.data.prepProfile || {};
            }

            this.props.onFieldChanged();
        }
    }

    render() 
    {
        const categoriesLookupData = arraySort(this.props.lookupData.categoriesLookupData, stringUtil.localeCompareProp("name"));

        return  <StockItemSections title="Details" isReadOnly={this.props.isReadOnly}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td>
                                    <InputComponent inputType="text"
                                        caption="Stock Item Code"
                                        hintText="Enter code that uniquely identify stock item (required)"
                                        style={{ marginRight: '20px' }}
                                        fieldName="code"
                                        placeholder="Stock item code"
                                        inputReadOnly={this.props.isRowReadOnly}
                                        formManager={this.state.formManager} />
                                </td>

                                <td>
                                    <InputComponent inputType="text"
                                        inputReadOnly={this.props.isRowReadOnly}
                                        caption="Stock Item Name"
                                        hintText="Enter name of stock item"
                                        fieldName="name"
                                        placeholder="Stock item name"
                                        formManager={this.state.formManager} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <SelectComponent
                                        optionValues={categoriesLookupData}
                                        optionFieldName="id"
                                        optionDisplayFieldName="name"
                                        caption="Category"
                                        hintText="Select category of stock item (required)" hintFieldName="notes"
                                        fieldName="categoryId"
                                        formManager={this.state.formManager}
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        style={{ marginRight: '20px' }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <SelectComponent optionValues={unitUtil.getUnitTypes()} optionFieldName="code" optionDisplayFieldName="code"
                                        caption="Measurement Type"
                                        hintText="Select measurement unit type for this stock item (required)" hintFieldName="notes"
                                        fieldName="unitTypeCode"
                                        formManager={this.state.formManager}
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        style={{ marginRight: '20px' }} />
                                </td>

                                <td>
                                    <SelectComponent optionValues={unitUtil.getCoreUnitCodes(this.props.data.unitTypeCode)} optionFieldName="code" optionDisplayFieldName="code"
                                        caption="Measurement Unit"
                                        hintText="Select the primary measurement unit for this item (required)" hintFieldName="notes"
                                        fieldName="coreUnitCode"
                                        formManager={this.state.formManager}
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly} />
                                </td>
                            </tr>

                            <tr>
                                <td>                        
                                    <InputComponent inputType="number"
                                        inputReadOnly={this.props.isRowReadOnly}
                                        caption="Minimum Inventory Quantity"
                                        hintText="Enter minimum quantity to be maintained in the inventory"
                                        fieldName="minimumInventoryQuantity"
                                        placeholder="Minimum inventory quantity"
                                        formManager={this.state.formManager} 
                                        style={{ marginRight: '20px' }} />                        
                                </td>
                                <td>
                                    <InputComponent inputType="number"
                                        inputReadOnly={this.props.isRowReadOnly}
                                        caption="Markup Rate Percentage (%)"
                                        hintText="Enter markup rate percentage applied to cost price"
                                        fieldName="markupRate"
                                        placeholder="Markup rate percentage"
                                        formManager={this.state.formManager} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <BooleanSelectComponent
                                        fieldName="recipeProfileEnabled"
                                        caption="Is Prep Item"
                                        hintText="Is this a prep item ?"
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        formManager={this.state.formManager} 
                                        style={{ marginRight: '20px' }} />
                                </td>

                                <td>
                                    <BooleanSelectComponent
                                        fieldName="isProduct"
                                        caption="Is Product"
                                        hintText="Is this a product stock item ?"
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        formManager={this.state.formManager} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <BooleanSelectComponent
                                        fieldName="isWastageTracked"
                                        caption="Is Wastage Tracked"
                                        hintText="Is this stock item tracked for wastage ?"
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        formManager={this.state.formManager}
                                        style={{ marginRight: '20px' }} />
                                </td>

                                <td>
                                    <BooleanSelectComponent
                                        fieldName="isInventoryTracked"
                                        caption="Is Inventory Tracked"
                                        hintText="Is this stock item tracked for inventory ?"
                                        clearable={false}
                                        comboReadOnly={this.props.isRowReadOnly}
                                        formManager={this.state.formManager} />
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2">
                                    <InputComponent inputType="text"
                                        multiLine={true}
                                        caption="Notes"
                                        rows={4}
                                        fieldName="notes"
                                        placeholder="Enter any notes."
                                        formManager={this.state.formManager}
                                        inputReadOnly={this.props.isRowReadOnly} />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </StockItemSections>
    }
}

export default Details;

