import arraySort from 'array-sort';
import dateUtil from '../../../../../utils/dateUtil/dateUtil';

//
// PurchasesBySupplier report data methods
//

export function getSupplierOrderSummary(reportData)
{
    const { suppliers, purchaseOrders } = reportData;

    const data = [];

    suppliers.forEach(supplier =>
    {
        const supplierOrders = purchaseOrders.filter(x => x.supplierId === supplier.id);

        if (supplierOrders.length === 0) return;

        const supplierOrderSummary = {
            supplierId: supplier.id,
            supplierName: supplier.name,
            supplierAddress: supplier.address,
            orderCount: 0,
            orderAmount: 0, orderVatAmount: 0,
            receiptAmount: 0, receiptVatAmount: 0
        }

        data.push(supplierOrderSummary);

        supplierOrders.forEach(order => 
        {
            const orderSummary = getPurchaseOrderSummaryData(order);

            supplierOrderSummary.orderCount += 1;
            supplierOrderSummary.orderAmount += orderSummary.orderAmount;
            supplierOrderSummary.orderVatAmount += orderSummary.orderVatAmount;
            supplierOrderSummary.receiptAmount += orderSummary.receiptAmount;
            supplierOrderSummary.receiptVatAmount += orderSummary.receiptVatAmount;
        });
    });

    return arraySort(data, "supplierName");
}

export function getOrderDetailsBySupplierId(supplierId, reportData)
{
    const { purchaseOrders } = reportData;

    const data = [];

    purchaseOrders
        .filter(order => order.supplierId === supplierId)
        .forEach(order => 
        {
            const orderSummary = getPurchaseOrderSummaryData(order);

            data.push(
                {
                    orderId: order.id,
                    orderNumber: order.number,
                    submissionDate: order.submissionDate,
                    receiptDate: order.receiptDate,
                    ...orderSummary
                });
        });

    return arraySort(data, "receiptDate", { reverse: true });;
}

export function getOrderDetailsByOrderId(orderId, reportData)
{
    const { categories, stockItems, purchaseOrders } = reportData;

    const purchaseOrder = purchaseOrders.find(order => order.id === orderId);

    const data = [];

    purchaseOrder.orderItems.forEach(orderItem => 
    {
        let stockItem, category, orderUnitCode, receiptUnitCode;

        stockItem = stockItems.find(item => item.id === orderItem.stockItemId);
        category = categories.find(category => category.id === stockItem.categoryId);
        orderUnitCode = stockItem.unitTypes.find(unitType => unitType.id === orderItem.unitTypeId)?.code || 'N/A';
        receiptUnitCode = stockItem.unitTypes.find(unitType => unitType.id === orderItem.receiptUnitTypeId)?.code || 'N/A';

        data.push({

            categoryName: category.name,
            stockItemName: stockItem.name,

            orderUnitPrice: orderItem.unitPrice,
            orderUnitVat: orderItem.vat,
            orderQuantity: orderItem.quantity,
            orderUnitCode: orderUnitCode,
            orderAmount: orderItem.unitPrice * orderItem.quantity,
            orderVatAmount: orderItem.vat * orderItem.quantity,

            receiptUnitPrice: orderItem.receiptUnitPrice,
            receiptUnitVat: orderItem.receiptVat,
            receiptQuantity: orderItem.receiptQuantity,
            receiptUnitCode: receiptUnitCode,
            receiptAmount: orderItem.receiptUnitPrice * orderItem.receiptQuantity,
            receiptVatAmount: orderItem.receiptVat * orderItem.receiptQuantity
        });
    });

    return arraySort(data, ["categoryName", "stockItemName", "receiptUnitCode"]);
}

//
// PurchasesByStockItem report data methods
//

export function getCategoryOrderSummary(reportData)
{
    const { categories, stockItems, purchaseOrders } = reportData;

    const data = [];

    purchaseOrders.forEach(order => 
    {
        order.orderItems.forEach(orderItem => 
        {
            let stockItem, category, categoryId, categoryName, categoryData;

            stockItem = stockItems.find(item => item.id === orderItem.stockItemId);
            category = categories.find(category => category.id === stockItem.categoryId);

            categoryId = category.id;
            categoryName = category.name;

            categoryData = data.find(categoryData => categoryData.categoryName === categoryName);

            if (categoryData == null)
            {
                categoryData = { categoryId, categoryName, itemCount: 0, orderAmount: 0, orderVatAmount: 0, receiptAmount: 0, receiptVatAmount: 0 };
                data.push(categoryData);
            }

            categoryData.itemCount += 1;
            categoryData.orderAmount += orderItem.unitPrice * orderItem.quantity;
            categoryData.orderVatAmount += orderItem.vat * orderItem.quantity;
            categoryData.receiptAmount += orderItem.receiptUnitPrice * orderItem.receiptQuantity;
            categoryData.receiptVatAmount += orderItem.receiptVat * orderItem.receiptQuantity;
        });
    });

    return arraySort(data, "categoryName");
}

export function getOrderDetailsByCategoryId(categoryId, reportData)
{
    const { stockItems, purchaseOrders } = reportData;

    const data = [];

    purchaseOrders.forEach(order => 
    {
        order.orderItems.forEach(orderItem => 
        {
            const stockItem = stockItems.find(stockItem => stockItem.id === orderItem.stockItemId);
            if (stockItem.categoryId !== categoryId) return;

            let stockItemData = data.find(stockItemData => stockItemData.stockItemId === stockItem.id);

            if (stockItemData == null)
            {
                stockItemData = {
                    stockItemId: stockItem.id, stockItemName: stockItem.name,
                    orderCount: 0,
                    orderAmount: 0, orderVatAmount: 0,
                    receiptAmount: 0, receiptVatAmount: 0,
                    lastOrder: order
                }

                data.push(stockItemData);
            }

            stockItemData.orderCount += 1;
            stockItemData.orderAmount += orderItem.unitPrice * orderItem.quantity;
            stockItemData.orderVatAmount += orderItem.vat * orderItem.quantity;
            stockItemData.receiptAmount += orderItem.receiptUnitPrice * orderItem.receiptQuantity;
            stockItemData.receiptVatAmount += orderItem.receiptVat * orderItem.receiptQuantity;

            if (dateUtil.isAfter(order.receiptDate, stockItemData.lastOrder.receiptDate))
            {
                stockItemData.lastOrder = order;
            }
        });
    });

    data.forEach(stockItemData => 
    {
        const lastOrder = stockItemData.lastOrder;
        const stockItemId = stockItemData.stockItemId;
        const stockItem = stockItems.find(stockItem => stockItem.id === stockItemId);

        const lastOrderItem = lastOrder.orderItems.find(orderItem => orderItem.stockItemId === stockItemId);
        const lastReceiptQuantity = lastOrderItem.receiptQuantity;
        const lastReceiptUnitCode = stockItem.unitTypes.find(unitType => unitType.id === lastOrderItem.receiptUnitTypeId)?.code || '?';

        stockItemData.lastOrder = `${lastReceiptQuantity} ${lastReceiptUnitCode} received on ${lastOrder.receiptDate}`;
    });

    return arraySort(data, "stockItemName");
}

export function getOrderDetailsByStockItemId(stockItemId, reportData)
{
    const { stockItems, suppliers, purchaseOrders } = reportData;

    const data = [];

    purchaseOrders.forEach(order => 
    {
        order.orderItems.forEach(orderItem => 
        {
            if (orderItem.stockItemId !== stockItemId) return;

            const stockItem = stockItems.find(stockItem => stockItem.id === orderItem.stockItemId);

            data.push({
                orderNumber: order.number,
                receiptDate: order.receiptDate,
                supplierName: suppliers.find(supplier => supplier.id === order.supplierId).name,

                orderUnitPrice: orderItem.unitPrice,
                orderUnitVat: orderItem.vat,
                orderQuantity: orderItem.quantity,
                orderUnitCode: stockItem.unitTypes.find(unitType => unitType.id === orderItem.unitTypeId)?.code || 'N/A',
                orderAmount: orderItem.unitPrice * orderItem.quantity,
                orderVatAmount: orderItem.vat * orderItem.quantity,

                receiptUnitPrice: orderItem.receiptUnitPrice,
                receiptUnitVat: orderItem.receiptVat,
                receiptQuantity: orderItem.receiptQuantity,
                receiptUnitCode: stockItem.unitTypes.find(unitType => unitType.id === orderItem.receiptUnitTypeId)?.code || 'N/A',
                receiptAmount: orderItem.receiptUnitPrice * orderItem.receiptQuantity,
                receiptVatAmount: orderItem.receiptVat * orderItem.receiptQuantity,
            });
        });
    });

    return arraySort(data, "receiptDate", { reverse: true });
}

export function getPurchasesByRawData(reportData)
{
    const { categories, stockItems, suppliers, purchaseOrders } = reportData;

    const rawData = [];

    purchaseOrders.forEach(order => 
    {
        const supplier = suppliers.find(supplier => supplier.id === order.supplierId);

        order.orderItems.forEach(orderItem => 
        {
            const stockItem = stockItems.find(stockItem => stockItem.id === orderItem.stockItemId);
            const category = categories.find(category => category.id === stockItem.categoryId);
            const orderUnitCode = stockItem.unitTypes.find(unitType => unitType.id === orderItem.unitTypeId)?.code || 'N/A';
            const receiptUnitCode = stockItem.unitTypes.find(unitType => unitType.id === orderItem.receiptUnitTypeId)?.code || 'N/A';

            rawData.push({
                category: category.name,
                stockItem: stockItem.name,

                supplier: supplier.name,

                orderNumber: order.number,
                submissionDate: order.submissionDate,
                receiptDate: order.receiptDate,

                orderQuantity: orderItem.quantity,
                orderUnitCode: orderUnitCode,
                orderUnitPrice: orderItem.unitPrice,
                orderUnitVat: orderItem.vat,
                orderAmount: orderItem.unitPrice * orderItem.quantity,
                orderVatAmount: orderItem.vat * orderItem.quantity,

                receiptQuantity: orderItem.receiptQuantity,
                receiptUnitCode: receiptUnitCode,
                receiptUnitPrice: orderItem.receiptUnitPrice,
                receiptUnitVat: orderItem.receiptVat,
                receiptAmount: orderItem.receiptUnitPrice * orderItem.receiptQuantity,
                receiptVatAmount: orderItem.receiptVat * orderItem.receiptQuantity,
            });
        });
    });

    return arraySort(rawData, ["category", "stockItem", "submissionDate", "orderNumber"]);
}

function getPurchaseOrderSummaryData(purchaseOrder)
{
    let itemCount = 0, orderAmount = 0, orderVatAmount = 0, receiptAmount = 0, receiptVatAmount = 0;

    purchaseOrder.orderItems.forEach(item => 
    {
        itemCount += 1;
        orderAmount += item.unitPrice * item.quantity;
        orderVatAmount += item.vat * item.quantity;
        receiptAmount += item.receiptUnitPrice * item.receiptQuantity;
        receiptVatAmount += item.receiptVat * item.receiptQuantity;
    });

    return { itemCount, orderAmount, orderVatAmount, receiptAmount, receiptVatAmount };
}