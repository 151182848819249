
const actionTypes = require('../actionTypes');
const actionDispatcher = require('../actionDispatcher');

module.exports.showOptionsUI = function()
{

    const currentOptionAction = {
        type: actionTypes.mainBarComponent,
        payload: null
    };

    actionDispatcher.dispatch(currentOptionAction);
}