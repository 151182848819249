const stateManager = require('../state/stateManager');
const orgSelectors = require('../state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');

const domainConstants = require('../domain/constants');

module.exports.getCompleteName = function (staff)
{
    return (`${staff.firstName} ${staff.lastName}`).trim();
}

module.exports.getProperty = function (propertyId)
{
    return orgSelectors.selectProperties().find(p => p.id === propertyId);
}

module.exports.isCentralKitchenPropertySelected = function ()
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
        return false;

    return currentOrgNodeSelectors.selectCurrentProperty().type === domainConstants.serviceContextTypes.centralKitchen;
}

module.exports.getApplicationMode = function ()
{
    return stateManager.getStore().getState().applicationMode;
}
