const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const validator = require('../../../../../utils/validator/validator');
const domainConstants = require('../../../../../utils/domain/constants');

module.exports.save = function(storefront, storefrontOperatingConfigurations)
{
    var storefrontResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/operatingConfigurations`;
    return rmsApiProxy.post(storefrontResourceUrl, storefrontOperatingConfigurations);
}

module.exports.loadStorefrontOperatingConfiguration = function(storefront)
{
    var storefrontResourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/operatingConfigurations`;
    return rmsApiProxy.get(storefrontResourceUrl);
}

module.exports.validateOperatingConfigurations = function(storefront, storefrontOperatingConfigurations)
{
    if(validator.isPresent(storefrontOperatingConfigurations.smsFrom) && !validator.isValidSMSSenderName(storefrontOperatingConfigurations.smsFrom))
       return "SMS Sender Name invalid. Please use only letters, numbers, and up to 11 characters.";

    if(!validator.isOptionalNumeric(storefrontOperatingConfigurations.commissionRunPercentage))
        return 'Commission rate should be a number!';

    if(!validator.isPresent(storefrontOperatingConfigurations.orderActivationThresholdInMinutes))
        return 'Active queue activation time is not specified!';
    
    if(!validator.isGreaterThanZero(storefrontOperatingConfigurations.orderActivationThresholdInMinutes))
        return 'Active queue activation time should be a number greater than zero!';
    
    if(!validator.isEitherAllPresentOrAllAbsent(storefrontOperatingConfigurations.rewardMenuId, storefrontOperatingConfigurations.rewardPriceBandId))
        return 'Both Rewards Menu and Price Band are required to be selected!';

    if(storefrontOperatingConfigurations.payoutIntegrationType === domainConstants.storePayoutIntegrationTypes.flipdish)
    {
        if (!validator.isPresent(storefrontOperatingConfigurations.payoutStoreId))
            return `Store Id cannot be left empty if payout integration: ${storefrontOperatingConfigurations.payoutIntegrationType} selected`;

        if (!validator.isPresent(storefrontOperatingConfigurations.appId))
            return `App Id cannot be left empty if payout integration: ${storefrontOperatingConfigurations.payoutIntegrationType} selected`;
    }

    return null;
}