import React, { Component, Fragment } from "react";
import ResizableRect from 'react-resizable-rotatable-draggable'
import { CloudinaryContext, Image } from 'cloudinary-react';

import StencilConfigurationDialogue from "./StencilConfigurationDialogue.js";

var viewUtil = require('../../../../../../utils/view/viewUtil.js');
var configurationManager = require('../../../../../../utils/config/configurationManager.js');
var typeUtil = require('../../../../../../utils/type/typeUtil.js');
var stringUtil = require('../../../../../../utils/string/stringUtil.js');
var constants = require('../../../../../../utils/domain/constants.js');
var validator = require('../../../../../../utils/validator/validator.js');

class StencilView extends Component
{
    constructor(props)
    {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleRotate = this.handleRotate.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.renderStencilImage = this.renderStencilImage.bind(this);

        this.onStencilClick = this.onStencilClick.bind(this);
        this.onStencilDoubleClick = this.onStencilDoubleClick.bind(this);

        this.state = {};
        this.state.minWidth = 50;
        this.state.minHeight = 50;
    }

    handleResize (style, isShiftKey, type) 
    {
        var stencil = this.props.stencil;
        if (style.width <= this.state.minWidth || style.height <= this.state.minHeight)
            return;

        stencil.width = style.width;
        stencil.height = style.height;
        this.setState({});

        if (this.props.onChange)
            this.props.onChange();
    }

    handleRotate(rotateAngle)
    {
        var stencil = this.props.stencil;
        stencil.rotationAngle = rotateAngle;
        this.setState({});

        if (this.props.onChange)
            this.props.onChange();
    }

    handleDrag(deltaX, deltaY)
    {
        var stencil = this.props.stencil;
        var left = stencil.left + deltaX;
        var top = stencil.top + deltaY;

        if (left <= 0 || top <= 0)
            return;

        stencil.left = left;
        stencil.top = top;
        this.setState({});
 
        if (this.props.onChange)
            this.props.onChange();
    }

    render()
    {
        var stencil = this.props.stencil;
        
        var component = null;
        if (this.props.isSelected)
        {
            component = <ResizableRect
                            left = {stencil.left}
                            top = {stencil.top}
                            rotateAngle = {stencil.rotationAngle}
                            width = {stencil.width+5}
                            height = {stencil.height+5}
                            rotatable = {true}
                            //aspectRatio={1}
                            minWidth = {10}
                            minHeight = {10}
                            
                            onDrag = {this.handleDrag}
                            onRotate = {this.handleRotate}
                            onResize = {this.handleResize}
                            zoomable="nw, ne, se, sw"
                            
                        />;
        }

        return (<Fragment>
                    <div style={{left:stencil.left, top:stencil.top, 
                                width:stencil.width, height:stencil.height, 
                                position:'absolute'}}
                                onClick={(e)=>{this.onStencilClick(e)}}
                                onDoubleClick={(e)=>{this.onStencilDoubleClick(e)}}>

                        <div style={{transform:`rotate(${stencil.rotationAngle}deg)`}}>
                            {this.renderStencilImage()}
                        </div>
                        {this.renderStencilCaption()}
                    </div>
                    {component}
                </Fragment>);
    }

    renderStencilImage()
    {
        var stencil = this.props.stencil;
        var cloudName = configurationManager.getConfig().cdnAppCloudName;
        return <CloudinaryContext cloudName={cloudName}>
                        <div>
                            <Image style={{userSelect:'none'}} publicId={stencil.imageUrl} width={stencil.width} height={stencil.height}/>
                        </div>
            </CloudinaryContext>;
    }

    renderStencilCaption()
    {
        if (this.props.stencil.type != constants.floorStencilType.Seating)
            return;

        var tableNumberCaption = "Table: N/A";
        if (!stringUtil.isStringNullOrEmpty(this.props.stencil.tableNumber))
        {
            tableNumberCaption = `Table: ${this.props.stencil.tableNumber}`;
        }

        var tableSeatingCaption = "Seating: N/A";
        if (!stringUtil.isStringNullOrEmpty(this.props.stencil.noOfSeating))
        {
            tableSeatingCaption = `Seating: ${this.props.stencil.noOfSeating}`;
        }   

        return <div className="user-select-none" 
                        style={{background:'#2980B9', color:'white', 
                                position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)',
                                padding:'2px'}}>

                    <div style={{textAlign:'center', whiteSpace:'nowrap', paddingLeft:'5px', paddingRight:'5px'}}>{tableNumberCaption}</div>
                    <div style={{textAlign:'center', whiteSpace:'nowrap', paddingLeft:'5px', paddingRight:'5px'}}>{tableSeatingCaption}</div>
                </div>
    }

    onStencilClick(e)
    {
        if (this.props.isSelected || this.props.onSelect == null)
            return;

        this.props.onSelect();
        e.stopPropagation();
    }

    onStencilDoubleClick(e)
    {
        if (this.props.stencil.type != constants.floorStencilType.Seating)
            return;
            
        this.props.onDialogueOpenClose(true);
        var clonedStencil = typeUtil.deepCloneObject(this.props.stencil);
        viewUtil.openModalForm("Seating Configuration", (onFieldChanged) =>
                                            {
                                                return (<StencilConfigurationDialogue stencil={clonedStencil} webAppConfiguration={this.props.webAppConfiguration}/>);
                                            }, 
                                            ()=>
                                            {
                                                this.props.onDialogueOpenClose(false);
                                                this.props.stencil.noOfSeating = clonedStencil.noOfSeating;
                                                this.props.stencil.tableNumber = stringUtil.trimString(clonedStencil.tableNumber);
                                                this.props.stencil.qrOrderingAppEnabled = clonedStencil.qrOrderingAppEnabled;
                                                this.props.stencil.qrOrderingAppId = clonedStencil.qrOrderingAppId;

                                                this.setState({});
                                                if (this.props.onChange)
                                                    this.props.onChange();

                                            },
                                            ()=>
                                            {
                                                if (!validator.isInteger(clonedStencil.noOfSeating))
                                                    return "No Of Seating is not valid";

                                                if (stringUtil.isStringNullOrEmpty(clonedStencil.tableNumber))
                                                    return "Table Number cannot be left empty";

                                                if (stringUtil.isStringNullOrEmpty(clonedStencil.qrOrderingAppId))
                                                    return "QR Ordering App ID cannot be left empty";

                                            }, false,
                                            null,
                                            ()=>
                                            {
                                                this.props.onDialogueOpenClose(false);
                                            }
                              );
    }
}

export default StencilView;