import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import CampaignOverview from '../../../components/CampaignOverview';
import campaignUtil from '../../../utils/campaignUtil';

const FormManager = require('../../../../../../../utils/view/formManager');
const stringUtil = require('../../../../../../../utils/string/stringUtil');


export default class CampaignOverviewStep extends Component 
{
    render() 
    {
        const campaign = this.props.data;

        const formManager = new FormManager();
        formManager.viewModel = campaign;
        formManager.onFieldChanged = (event) =>
        {
            if (this.props.onChange)
                this.props.onChange();
        }

        return <Card>
            <CardHeader>
                Campaign Setup
            </CardHeader>
            <CardBody>
                <CampaignOverview formManager={formManager} />
            </CardBody>
            <CardFooter>
                {this.renderNavigation()}
            </CardFooter>
        </Card>
    }

    renderNavigation()
    {
        const campaign = this.props.data;
        const nextEnabled = stringUtil.isStringNullOrEmpty(campaignUtil.validateCampaignOverview(campaign));

        return (<table width="100%">
            <tr>
                <td align="right">
                    <Button color="primary" disabled={!nextEnabled} onClick={this.props.nextStep}>Next</Button>
                </td>
            </tr>
        </table>);
    }
}
