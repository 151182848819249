
/*
    Util class shared with the client
 */

module.exports.updateCalculatedFields = function (payrollRun)
{
    payrollRun.staff.forEach(payrollRunStaff =>
    {
        this.updateCalculatedFieldsForStaff(payrollRunStaff)
    });

    this.updateCalculatedFieldsForPayrollFromStaff(payrollRun);
    this.updateCalculatedFieldsForPayroll(payrollRun);
}

module.exports.updateCalculatedFieldsForStaff = function (staffPayroll)
{
    staffPayroll.holidayPay = Number(staffPayroll.holidayHours) * (staffPayroll.holidayHourlyWage);
    staffPayroll.grossWage = Number(staffPayroll.actualWage) + Number(staffPayroll.bonus) + Number(staffPayroll.holidayPay) - Number(staffPayroll.deduction);
    staffPayroll.netWage = Number(staffPayroll.grossWage) - Number(staffPayroll.employeeNIContribution) - Number(staffPayroll.employeePensionContribution) - Number(staffPayroll.paye);
    staffPayroll.netWagePayment = Number(staffPayroll.netWage) - Number(staffPayroll.advancePayment);
    staffPayroll.totalNIContribution = Number(staffPayroll.employeeNIContribution) + Number(staffPayroll.employerNIContribution);
    staffPayroll.totalHMRCPayment = Number(staffPayroll.totalNIContribution) + Number(staffPayroll.paye);
    staffPayroll.totalPensionPayment = Number(staffPayroll.employeePensionContribution) + Number(staffPayroll.employerPensionContribution);
    staffPayroll.totalPayrollCost = Number(staffPayroll.netWage) + Number(staffPayroll.totalHMRCPayment) + Number(staffPayroll.totalPensionPayment);
}

module.exports.updateCalculatedFieldsForPayrollFromStaff = function (payrollRun)
{
    payrollRun.plannedHours = 0;
    payrollRun.plannedWage = 0;
    payrollRun.actualHours = 0;
    payrollRun.actualWage = 0;

    payrollRun.employerNIContribution = 0;
    payrollRun.employerPensionContribution = 0;
    payrollRun.employeeNIContribution = 0;
    payrollRun.employeePensionContribution = 0;
    payrollRun.paye = 0;
    payrollRun.bonus = 0;
    payrollRun.deduction = 0;
    payrollRun.advancePayment = 0;
    payrollRun.holidayHours = 0;
    payrollRun.holidayPay = 0;

    payrollRun.staff.forEach(staff =>
    {

        payrollRun.plannedHours += Number(staff.plannedHours);
        payrollRun.plannedWage += Number(staff.plannedWage);
        payrollRun.actualHours += Number(staff.actualHours);
        payrollRun.actualWage += Number(staff.actualWage);

        payrollRun.employerNIContribution += Number(staff.employerNIContribution);
        payrollRun.employerPensionContribution += Number(staff.employerPensionContribution);
        payrollRun.employeeNIContribution += Number(staff.employeeNIContribution);
        payrollRun.employeePensionContribution += Number(staff.employeePensionContribution);
        payrollRun.paye += Number(staff.paye);
        payrollRun.bonus += Number(staff.bonus);
        payrollRun.deduction += Number(staff.deduction);
        payrollRun.advancePayment += Number(staff.advancePayment);
        payrollRun.holidayHours += Number(staff.holidayHours); 
        payrollRun.holidayPay += Number(staff.holidayPay);
    })
}

module.exports.updateCalculatedFieldsForPayroll = function (payroll)
{
    payroll.grossWage = Number(payroll.actualWage) + Number(payroll.bonus) + Number(payroll.holidayPay) - Number(payroll.deduction);
    payroll.netWage = Number(payroll.grossWage) - Number(payroll.employeeNIContribution) - Number(payroll.employeePensionContribution) - Number(payroll.paye);
    payroll.netWagePayment = Number(payroll.netWage) - Number(payroll.advancePayment);
    //For employee, reduce NI allowance
    payroll.totalNIContribution = Number(payroll.employeeNIContribution) + Number(payroll.employerNIContribution);
    payroll.netNIPayment = Number(payroll.totalNIContribution) - Number(payroll.employerNIAllowance);
    payroll.totalHMRCPayment = Number(payroll.netNIPayment) + Number(payroll.paye);
    payroll.totalPensionPayment = Number(payroll.employeePensionContribution) + Number(payroll.employerPensionContribution);
    payroll.totalPayrollCost = Number(payroll.netWage) + Number(payroll.totalHMRCPayment) + Number(payroll.totalPensionPayment);

    if (Number(payroll.actualHours) != 0)
    {
        payroll.averageHourlyWage = Number(payroll.actualWage) / Number(payroll.actualHours);
    }
}