import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import arraySort from 'array-sort';

import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';
import * as rmsApiProxy from '../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../utils/api/rmsApiProxyUtils';

import CogsSummaryData from './components/CogsSummaryData';
import CogsRawData from './components/CogsRawData';

const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

class CogsSummaryReport extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            cogsData: null,
            searchCriteria: null,
            error: null,
            isLoading: false,
        }
    }

    get reportData()
    {
        return {
            categories: this.categories,
            stockItems: this.stockItems,
            cogsData: this.state.cogsData
        }
    }

    render()
    {
        let component;

        const { isLoading, error, cogsData } = this.state;

        if (isLoading === true) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error != null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (cogsData == null)
        {
            component = <div className='m-3'>
                <EmptyData
                    title="Data required"
                    text="Please choose date range and hit Search to get report data"
                    action={null}
                />
            </div>;
        }
        else
        {
            component = this.renderReportData(cogsData);
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>COGs Summary Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData(cogsData)
    {
        if (cogsData.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Data Found"
                        text="No COGs data found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key='COGs Summary'>COGs Summary</Tab>);
        tabContents.push(<TabPanel key='COGs Summary' className='bg-white mt-n1'><CogsSummaryData reportData={this.reportData} /></TabPanel>);

        tabHeaders.push(<Tab key='Wastages_By_Raw_Data'>Raw Data</Tab>);
        tabContents.push(<TabPanel key='Wastages_By_Raw_Data' className='bg-white mt-n1'><CogsRawData reportData={this.reportData} /></TabPanel>);

        return (
            <div className='p-2 mt-n3 bg-white'>
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ cogsData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, cogsData: null, searchCriteria: searchCriteria, isLoading: true });

        try 
        {
            // Reference data is loaded only once during the lifetime of the component

            if (this.categories == null)
            {
                const categories = await apiLoadFacade.loadInventoryCategories();
                this.categories = arraySort(categories, "id");
            }

            if (this.stockItems == null)
            {
                const stockItems = await apiLoadFacade.loadStockItems('unitTypesOnly=true');
                this.stockItems = arraySort(stockItems, "id");
            }

            // Load state data

            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/inventory/cogsStatus` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const cogsData = await rmsApiProxy.get(resourceUrl);

            this.setState({ cogsData, isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }
}

export default CogsSummaryReport;