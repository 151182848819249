import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

const domainConstants = require('../../../../../../utils/domain/constants');
const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class WebAppConfigurationOverview extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.viewModel.isEnabled = this.state.formManager.viewModel.isEnabled || true;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        const webAppModes = Object.values(domainConstants.getWebAppModes()).map(mode => ({label: mode.label, value: mode.value}));

        return (            
            
            <table className='component-table'>
                <tr>                    
                    <td>
                        <InputComponent
                            inputType="name"
                            fieldName="title"
                            caption="Title"
                            placeholder="Enter title for the Web App"
                            hintText="Title is shown in the browser tab. (Required)"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                        <SelectComponent
                            caption="Mode"
                            fieldName="mode"
                            optionFieldName="value"
                            optionDisplayFieldName="label"
                            optionValues={webAppModes}
                            hintText="Required"
                            placeholder="Choose Web App Mode"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />                                       
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="code"
                            fieldName="applicationId"
                            caption="Application ID"
                            placeholder="Enter the application ID for Web App"
                            inputReadOnly={this.isReadOnly}
                            hintText="Required"
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="code"
                            fieldName="themeId"
                            caption="Theme ID"
                            placeholder="Enter the theme ID for Web App"
                            hintText="Required"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="web"
                            fieldName="customUrl"
                            caption="Custom URL"
                            placeholder="Custom URL"
                            hintText="Custom URL for the web app"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="email"
                            fieldName="emailReplyToAddress"
                            caption="Reply-To Email"
                            placeholder="Email where customers replies should be sent"
                            hintText="(Optional)"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Enabled"
                            fieldName="isEnabled"
                            hintText="Required"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        
                    </td>
                </tr>
            </table>
        );
    }    
}

export default WebAppConfigurationOverview;