
const EnumDays = Object.freeze({
    "Mon": 0x01,
    "Tue": 0x02,
    "Wed": 0x04,
    "Thu": 0x08,
    "Fri": 0x10,
    "Sat": 0X20,
    "Sun": 0x40,
});

const EnumWeeks = Object.freeze({
    "1st Week": 0x01,
    "2nd Week": 0x02,
    "3rd Week": 0x04,
    "4th Week": 0x08,
    "5th Week": 0x10,
});

const EnumMonths = Object.freeze({
    "Jan": 0x001,
    "Feb": 0x002,
    "Mar": 0x004,
    "Apr": 0x008,
    "May": 0x010,
    "Jun": 0X020,
    "Jul": 0x040,
    "Aug": 0x080,
    "Sep": 0x100,
    "Oct": 0x200,
    "Nov": 0x400,
    "Dec": 0x800,
});

const allEnumDays =
    Object.keys(EnumDays)
        .reduce((accumulator, key) => accumulator + EnumDays[key], 0);

const allEnumWeeks =
    Object.keys(EnumWeeks)
        .reduce((accumulator, key) => accumulator + EnumWeeks[key], 0);

const allEnumMonths =
    Object.keys(EnumMonths)
        .reduce((accumulator, key) => accumulator + EnumMonths[key], 0);

module.exports.EnumDays = EnumDays;
module.exports.EnumWeeks = EnumWeeks;
module.exports.EnumMonths = EnumMonths;

module.exports.allEnumDays = allEnumDays;
module.exports.allEnumWeeks = allEnumWeeks;
module.exports.allEnumMonths = allEnumMonths;

