import React, { Component } from 'react';


import GridView from '../../../../../components/gridView/gridView';
import CreditAccount from './creditAccount';

import * as  GridViewOptions from '../../../../../components/gridView/gridViewOptions';
import * as  apiLoadFacade from '../../../../../utils/api/apiLoadFacade';
import * as  currentOrgNodeSelectors from '../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';

import * as stringUtil from '../../../../../utils/string/stringUtil';
import * as  validator from '../../../../../utils/validator/validator';
import * as  rmsApiProxy from '../../../../../utils/api/rmsApiProxy';

import * as  columnWidthConstants from '../../../../../utils/constants/columnWidthConstants';
import * as  dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';

const arraySort = require('array-sort');


class CreditAccounts extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = { creditBooks: [] }
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();





        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Credit Accounts";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadData;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () => 
        {
            const newRow = {};
            newRow.franchisorId = currentOrgNode.franchisorId;
            newRow.franchiseeId = currentOrgNode.franchiseeId;
            newRow.propertyId = currentOrgNode.propertyId;
            return newRow;
        };

        gridViewOptions.getComponent = (isNew, row) =>
            <CreditAccount
                data={row} isNew={isNew} creditBooks={this.state.creditBooks}
                isRowReadOnly={this.props.isReadOnly} />;

        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Credit Account - ${row.name}` : 'Credit Account - New';

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = this.isRowReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new Credit Account";

            return "Please wait while updating Credit Account";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New Credit Account added successfully";

            return "Credit Account updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new Credit Account:";

            return "Following error occurred while updating Credit Account:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row, customButton) => this.props.isReadOnly;

    validateRow = (isNew, row) =>
    {
        if (!validator.isPresent(row.creditBookId))
            return "No Credit Book is associated with the Credit Account";

        if (!validator.isPresent(row.name))
            return "Credit Account name cannot be left empty";

        if (!validator.isPresent(row.description))
            return "Credit Account description cannot be left empty";

        if (!validator.isGreaterThanZero(row.creditLimit))
            return "Credit Limit must be greater than 0";

        return null;
    }

    loadData = async () =>
    {
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks/chartOfAccounts`;
        const data = await rmsApiProxy.get(resourceUrl);
        const creditBooks = arraySort(data, stringUtil.localeCompareProp("name"));

        const creditAccounts = [];
        creditBooks.forEach(creditBook => {
            creditAccounts.push(...creditBook.accounts);
        })

        this.state.creditBooks = creditBooks;
        this.enrichData(creditAccounts);
        return creditAccounts;
    }

    enrichData = (creditAccounts) =>
    {
        if (!Array.isArray(creditAccounts)) creditAccounts = [creditAccounts];

        const creditBooks = this.state.creditBooks;

        creditAccounts.forEach(creditAccount =>
        {
            const creditBook = creditBooks.find(creditBook => creditBook.id === creditAccount.creditBookId);
            creditAccount.creditBookName = creditBook && creditBook.name;
        });
    }

    save = async (isNew, row, remoteData) =>
    {
        const creditBookId = row.creditBookId;
        const updatedCreditAccount = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks/${creditBookId}`, row);

        this.enrichData(updatedCreditAccount);

        if (!remoteData) remoteData = [];

        const existingIndex = remoteData.findIndex(creditAccount => creditAccount.id === updatedCreditAccount.id);

        if (existingIndex > -1)
            remoteData.splice(existingIndex, 1, updatedCreditAccount);
        else
            remoteData.push(updatedCreditAccount);

        const response = {};
        response.remoteData = remoteData;
        response.addUpdatedRow = updatedCreditAccount;

        return response;
    }

    constructGridColumnHeaders = () =>
    {
        let header = {};
        const headers = [];

        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "creditBookName";
        header.headerName = "Credit Book";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Credit Account";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "description";
        header.headerName = "Description";
        header.width = columnWidthConstants.description;
        headers.push(header);

        header = {};
        header.field = "creditLimit";
        header.headerName = "Credit Limit";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + 25;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default CreditAccounts;
