
const domainConstants = require('../../domain/constants');
const stringUtil = require('../../string/stringUtil');

const isStringNullOrEmpty = stringUtil.isStringNullOrEmpty;
const numericThresholdValue = domainConstants.numericThresholdValue;
const ReceiptLineTypes = domainConstants.ReceiptLineTypes;
const PaymentMethods = domainConstants.paymentMethods;

module.exports.populateAggregatedSaleFields = function (sale, operativeVatBands)
{
    // This method transforms incoming azure sale record for persistence in snowflake

    const saleItems = this.flattenSaleItems(sale.saleItems);

    const discounts = sale.discounts;
    const refunds = sale.refunds;
    const charges = sale.charges;
    const receiptLines = sale.receiptLines;

    initializeSaleFields(sale);
    initializeSaleItemFields(saleItems);
    initializeRefundFields(refunds);
    initializeChargesFields(charges, sale.isTaxInclusive);

    const isTaxInclusive = sale.isTaxInclusive;

    populateAggregatedSaleItemFields(saleItems, discounts, charges, refunds, isTaxInclusive, operativeVatBands);

    sale.retailPrice = saleItems.reduce((sum, item) => sum + item.retailPrice, 0);         // displayed retailPrice
    sale.retailTax = saleItems.reduce((sum, item) => sum + item.retailTax, 0);             // displayed retailTax

    sale.discount = saleItems.reduce((sum, item) => sum + item.discount, 0);
    sale.discountTax = saleItems.reduce((sum, item) => sum + item.discountTax, 0);
    sale.totalDiscounts = sale.discount + sale.discountTax;

    sale.saleRefund = saleItems.reduce((sum, item) => sum + item.refund, 0);
    sale.saleRefundTax = saleItems.reduce((sum, item) => sum + item.refundTax, 0);

    sale.netRetailPrice = saleItems.reduce((sum, item) => sum + item.netRetailPrice, 0);        // discount + refund adjusted retailPrice
    sale.netRetailTax = saleItems.reduce((sum, item) => sum + item.netRetailTax, 0);            // discount + refund adjusted retailTax

    sale.charge = charges.reduce((sum, item) => sum + item.retailAmount, 0);
    sale.chargeTax = charges.reduce((sum, item) => sum + item.tax, 0);
    sale.totalCharges = sale.charge + sale.chargeTax;

    sale.chargeRefund = charges.reduce((sum, item) => sum + item.refund, 0);
    sale.chargeRefundTax = charges.reduce((sum, item) => sum + item.refundTax, 0);

    sale.totalRefunds = sale.saleRefund + sale.saleRefundTax + sale.chargeRefund + sale.chargeRefundTax;

    sale.netSalePrice = sale.netRetailPrice + sale.charge - sale.chargeRefund
    sale.netSaleTax = sale.netRetailTax + sale.chargeTax - sale.chargeRefundTax

    sale.netSalePriceIncTax = sale.netSalePrice + sale.netSaleTax

    sale.paidAmount = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Charge)
        .reduce((sum, item) => sum + item.amount, 0);

    sale.netPaidAmount = sale.paidAmount - sale.totalRefunds;       // netPaidAmount = 0 for fully refunded sales

    sale.balance = sale.netSalePriceIncTax - sale.netPaidAmount;    // netSalePriceIncTax is discount/refund/charge adjusted, hence must match netPaidAmount for fully paid sale

    sale.cashReceipts = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Charge && line.paymentMethod === PaymentMethods.Cash)
        .reduce((sum, item) => sum + item.amount, 0);
    sale.cashRefunds = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Refund && line.paymentMethod === PaymentMethods.Cash)
        .reduce((sum, item) => sum + item.amount, 0);
    sale.netCashReceipts = sale.cashReceipts - sale.cashRefunds;

    sale.creditReceipts = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Charge && line.paymentMethod === PaymentMethods.Credit)
        .reduce((sum, item) => sum + item.amount, 0);
    sale.creditRefunds = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Refund && line.paymentMethod === PaymentMethods.Credit)
        .reduce((sum, item) => sum + item.amount, 0);
    sale.netCreditReceipts = sale.creditReceipts - sale.creditRefunds;

    sale.venuePaidReceipts = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Charge && line.paymentMethod === PaymentMethods.VenuePaid)
        .reduce((sum, item) => sum + item.amount, 0);
    sale.venuePaidRefunds = receiptLines
        .filter(line => line.type === ReceiptLineTypes.Refund && line.paymentMethod === PaymentMethods.VenuePaid)
        .reduce((sum, item) => sum + item.amount, 0);
    sale.netVenuePaidReceipts = sale.venuePaidReceipts - sale.venuePaidRefunds;

    sale.otherReceipts = sale.paidAmount - (sale.cashReceipts + sale.creditReceipts + sale.venuePaidReceipts);
    sale.otherRefunds = sale.totalRefunds - (sale.cashRefunds + sale.creditRefunds + sale.venuePaidRefunds);
    sale.netOtherReceipts = sale.otherReceipts - sale.otherRefunds;

    sale.isSaleFullyPaid = sale.balance < numericThresholdValue;
    sale.isSalePartiallyPaid = sale.paidAmount > numericThresholdValue && !sale.isSaleFullyPaid;
    sale.isPartiallyOrFullyPaid = sale.isSaleFullyPaid || sale.isSalePartiallyPaid;

    sale.isSaleFullyRefunded = sale.totalRefunds > numericThresholdValue && sale.netPaidAmount < numericThresholdValue;
    sale.isSalePartiallyRefunded = sale.totalRefunds > numericThresholdValue && !sale.isSaleFullyRefunded;
    sale.isPartiallyOrFullyRefunded = sale.isSaleFullyRefunded || sale.isSalePartiallyRefunded;
}

module.exports.flattenSaleItems = function (saleItems)
{
    // flattens saleItems while retaining nesting structure

    const flatSaleItems = [];

    (function parseSaleItems(saleItems)
    {
        saleItems.forEach(saleItem => 
        {
            flatSaleItems.push(saleItem);
            if (saleItem.saleItems.length > 0) parseSaleItems(saleItem.saleItems);      // recursion
        });

    })(saleItems); // IIF

    return flatSaleItems;
}

//
// Private methods
//

function populateAggregatedSaleItemFields(saleItems, discounts, charges, refunds, isTaxInclusive, operativeVatBands)
{
    saleItems.forEach(saleItem => 
    {
        const unitPrice = saleItem.unitPrice;
        const unitTax = saleItem.unitTax;
        const quantity = saleItem.quantity;

        const unitRetailPrice = isTaxInclusive ? unitPrice - unitTax : unitPrice;

        const retailPrice = unitRetailPrice * quantity;
        const retailTax = unitTax * quantity;

        const displayedTaxRatio = (unitRetailPrice > 0 && unitTax > 0) ? unitTax / unitRetailPrice : 0;

        saleItem.displayedTaxRatio = displayedTaxRatio;     // not part of the schema but anyway append displayedTaxRatio to saleItem as it will be used in later calculations

        saleItem.retailPrice = retailPrice;     // displayed retailPrice
        saleItem.retailTax = retailTax;         // displayed retailTax

        saleItem.discount = 0;                  // discount proportioned (w/o tax)
        saleItem.discountTax = 0;               // saleItem.discount * saleItem.displayedTaxRatio

        saleItem.refund = 0;                    // refund proportioned (w/o tax) after discount
        saleItem.refundTax = 0;                 // saleItem.refund * saleItem.displayedTaxRatio

        // Populate taxPercentage

        let taxPercentage = 0;

        if (saleItem.displayedTaxRatio > 0)
        {
            taxPercentage = saleItem.displayedTaxRatio * 100;

            if (operativeVatBands.length > 0)
            {
                // TODO: We should limit the difference between actual vat and banded vat beyond 1 or 1.25
                taxPercentage = operativeVatBands.reduce((a, b) => Math.abs(b - taxPercentage) < Math.abs(a - taxPercentage) ? b : a);
            }
        }

        saleItem.taxPercentage = taxPercentage;

    });

    // order of update below is significant

    updateSaleItemsWithDiscount(saleItems, discounts);

    updateSaleItemsWithRefund(saleItems, charges, refunds);

    saleItems.forEach(saleItem => updateAggregatedFieldsOnSaleItem(saleItem));
}


function updateSaleItemsWithDiscount(saleItems, discounts)
{
    // Case: saleItems with item level discount

    const saleItemsWithItemLevelDiscount = saleItems.filter(item => discounts.some(discount => discount.saleItemId === item.id && discount.amount > 0));
    let processedSaleItems = new Map();
    saleItemsWithItemLevelDiscount.forEach(saleItem => 
    {
        if (processedSaleItems.has(saleItem.id))
            return;

        const totalDiscounts = discounts.find(discount => discount.saleItemId === saleItem.id).amount;
        const totalSaleAmount = getRecursiveSaleAmount(saleItem);

        if (totalSaleAmount > 0 && totalDiscounts > 0)
        {
            const discountRatio = totalDiscounts / totalSaleAmount;
            updateRecursiveSaleItemDiscount(saleItem, discountRatio, processedSaleItems);
        }
    });


    // Case: saleItems with sale level discount

    const saleItemsWithSaleLevelDiscount = saleItems.filter(item => !processedSaleItems.has(item.id) && !discounts.some(discount => discount.saleItemId === item.id));

    const totalSaleAmount = saleItemsWithSaleLevelDiscount.reduce((sum, item) => sum + item.retailPrice + item.retailTax, 0);

    const totalDiscounts = discounts
        .filter(discount => isStringNullOrEmpty(discount.saleItemId))
        .reduce((sum, item) => sum + item.amount, 0);

    if (totalSaleAmount > 0 && totalDiscounts > 0)
    {
        const discountRatio = totalDiscounts / totalSaleAmount;
        saleItemsWithSaleLevelDiscount.forEach(saleItem => updateSaleItemDiscount(saleItem, discountRatio));
    }
}

function getRecursiveSaleAmount(saleItem)
{
    let saleAmount = saleItem.retailPrice + saleItem.retailTax;
    if (saleItem.saleItems != null && saleItem.saleItems.length > 0)
    {
        saleItem.saleItems.forEach (subSaleItem =>
        {
            saleAmount = saleAmount + getRecursiveSaleAmount(subSaleItem);    
        })
    }

    return saleAmount;
}

function updateRecursiveSaleItemDiscount(saleItem, discountRatio, processedSaleItems)
{
    updateSaleItemDiscount(saleItem, discountRatio);
    processedSaleItems.set(saleItem.id, saleItem.id)

    if (saleItem.saleItems != null && saleItem.saleItems.length > 0)
    {
        saleItem.saleItems.forEach (subSaleItem =>
        {
            updateRecursiveSaleItemDiscount(subSaleItem, discountRatio, processedSaleItems);    
        })
    }
}

function updateSaleItemDiscount(saleItem, discountRatio)
{
    saleItem.discount = saleItem.retailPrice * discountRatio;
    saleItem.discountTax = saleItem.retailTax * discountRatio;
}

function updateSaleItemsWithRefund(saleItems, charges, refunds)
{
    // saleItem.netRetailPrice and tax by now is discount adjusted
    // totalRefund exceeding sale amount is adjusted in parts against sale and charges

    const totalRefund = refunds.reduce((sum, item) => sum + item.amount, 0);
    if (totalRefund < numericThresholdValue) return;        // Implies nil refund

    const totalSaleAmount = saleItems.reduce((sum, item) => sum + item.retailPrice + item.retailTax - item.discount - item.discountTax, 0);
    const totalCharges = charges.reduce((sum, item) => sum + (item.retailAmount + item.tax), 0);

    let refundAgainstSaleAmount = totalRefund;
    let refundAgainstCharges = 0;

    if (totalRefund - totalSaleAmount > numericThresholdValue)
    {
        refundAgainstSaleAmount = totalSaleAmount;
        refundAgainstCharges = totalRefund - refundAgainstSaleAmount;
    }

    const saleRefundRatio = refundAgainstSaleAmount / totalSaleAmount;
    const chargeRefundRatio = isNaN(refundAgainstCharges / totalCharges) ? 0 : refundAgainstCharges / totalCharges;

    saleItems.forEach(saleItem => 
    {
        saleItem.refund = (saleItem.retailPrice - saleItem.discount) * saleRefundRatio;
        saleItem.refundTax = (saleItem.retailTax - saleItem.discountTax) * saleRefundRatio;
    });

    charges.forEach(charge => 
    {
        charge.refund = charge.retailAmount * chargeRefundRatio;
        charge.refundTax = charge.tax * chargeRefundRatio;
    })
}

function updateAggregatedFieldsOnSaleItem(saleItem)
{
    saleItem.netRetailPrice = saleItem.retailPrice - saleItem.discount - saleItem.refund;
    saleItem.netRetailTax = saleItem.retailTax - saleItem.discountTax - saleItem.refundTax;
}

function initializeSaleFields(sale)
{
    sale.isTaxInclusive = sale.isTaxInclusive ?? sale.isVatInclusive;

    delete sale.isVatInclusive;
    delete sale.totalRetailOrderPrice
    delete sale.netOrderPrice
    delete sale.salePrice
    delete sale.totalTax
    delete sale.salePriceIncTax
}

function initializeSaleItemFields(saleItems)
{
    // Enrich saleItem with new/renamed fields
    // Delete fields that we are no longer persisting in snowflake

    saleItems.forEach(saleItem => 
    {
        saleItem.unitTax = saleItem.unitTax ?? saleItem.vat;

        delete saleItem.vat;
        delete saleItem.isVatInclusive;
        delete saleItem.retailOrderPrice;
        delete saleItem.totalTax;
    });
}

function initializeRefundFields(refunds)
{
    // Enrich refund with new/renamed fields
    // Delete fields that we are no longer persisting in snowflake

    refunds.forEach(refund => 
    {
        refund.type = refund.type ?? refund.refundCode;
        refund.amount = refund.amount ?? refund.refundAmount;

        delete refund.refundCode;
        delete refund.refundAmount;
    });
}

function initializeChargesFields(charges, isTaxInclusive)
{
    // Enrich charges with new/renamed fields
    // Delete fields that we are no longer persisting in snowflake

    charges.forEach(charge => 
    {
        charge.tax = charge.tax ?? charge.vat;

        delete charge.vat;

        charge.retailAmount = isTaxInclusive ? charge.amount - charge.tax : charge.amount;
        charge.refund = 0;
        charge.refundTax = 0;
    });
}