import React, { Component } from 'react';

import * as arraySort from 'array-sort';
import PieChartReport from '../../../../../../reportUtils/chartComponents/PieChartReport';

const biUtils = require('../../../../../../../../utils/bi/biUtils');
const { formatCurrencyValue: currencyFormatter, formatAmount: numberFormatter, formatPercentage: percentageFormatter } = biUtils;


export default class DiscountsByVenueCodeCharts extends Component
{
    get maxLabelCount() { return 7 }    // 7 data items + Others

    render()
    {
        return (
            <div className='d-flex align-items-center' style={{ backgroundColor: "WhiteSmoke" }}>

                <div className='w-50 border-end d-flex justify-content-center align-items-center'>
                    {this.renderDiscountByVenueCodeChart()}
                </div>

                <div className='w-50 d-flex justify-content-center align-items-center'>
                    {this.renderDiscountByDiscountTypeChart()}
                </div>

            </div>
        );
    }

    renderDiscountByVenueCodeChart()
    {
        const title = "Discounts By Venue";
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const { discountsByVenueCode } = this.props.data;

        const totalAmount = discountsByVenueCode.reduce((previous, current) => previous + current.totalAmount, 0);

        if (totalAmount === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{currencyFormatter(0)}</div>
                </div>
            );
        }

        const data = discountsByVenueCode.map(data => ({
            label: data.venueCode,
            value: data.totalAmount,
            percentage: data.totalAmount / totalAmount * 100
        }));

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={numberFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }

    renderDiscountByDiscountTypeChart()
    {
        const title = "Discounts By Type";
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const { discountTypes, discountsByVenueCode } = this.props.data;

        const totalAmount = discountsByVenueCode.reduce((previous, current) => previous + current.totalAmount, 0);

        if (totalAmount === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{currencyFormatter(0)}</div>
                </div>
            );
        }

        const data = discountTypes.map((discountType, index) =>
        {
            const totalDiscount =
                discountsByVenueCode
                    .map(data => data[discountType] || 0.0)
                    .reduce((previous, current) => previous + current, 0);
            return {
                label: discountType.substring(0, 30),
                value: totalDiscount,
                percentage: totalDiscount / totalAmount * 100
            };
        });

        if (data.length > this.maxLabelCount + 1)
        {
            // Too many labels may not be handled by pie chart neatly, so we club 
            // excess labels trailing by amount into Others

            arraySort(data, "value", { reverse: true });
            const otherData = data.slice(this.maxLabelCount);
            const otherDataTotal = otherData.reduce((previous, current) => previous + current.value, 0);
            data.splice(this.maxLabelCount, Infinity, {
                label: `Others (${otherData.length})`,
                value: otherDataTotal,
                percentage: otherDataTotal / totalAmount * 100
            });
        }

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={currencyFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }
}