
const arraySort = require('array-sort');

const domainConstants = require('../../../../../../utils/domain/constants');
const numericThresholdValue = domainConstants.numericThresholdValue;


export function getCategorySales(sales)
{
    const categorySales = [];

    sales.forEach(sale => populateCategorySales(sale.saleItems));

    arraySort(categorySales, "category");

    return categorySales;


    //#region Nested function

    function populateCategorySales(saleItems)
    {
        // Iterate of root saleItems

        saleItems.forEach(saleItem => 
        {
            const category = saleItem.category || "N/A";
            const quantity = saleItem.quantity;

            let amount = saleItem.netRetailPrice;

            if (saleItem.saleItems && saleItem.saleItems.length > 0)
            {
                // Aggregate amount over child saleItems
                amount += computeChildItemAmounts(saleItem.saleItems);
            }

            if (amount > numericThresholdValue)
            {
                let categorySale = categorySales.find(x => x.category === category);

                if (categorySale == null) 
                {
                    categorySale = { category: category, count: 0, amount: 0 };
                    categorySales.push(categorySale);
                }

                categorySale.count += quantity;
                categorySale.amount += amount;
            }
        });
    }

    function computeChildItemAmounts(saleItems)
    {
        let amount = 0;

        for (const saleItem of saleItems) 
        {
            amount += saleItem.netRetailPrice;

            if (saleItem.saleItems && saleItem.saleItems.length > 0)
            {
                amount += computeChildItemAmounts(saleItem.saleItems);      // recursion
            }
        }

        return amount;
    }

    //#endregion
}