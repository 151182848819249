import React from 'react';
import { Button } from 'reactstrap';

export default function SaveButton(props)
{
    const { disabled, onClick, className = "px-4" } = props;

    return (
        <Button
            className={className}
            color="success"
            disabled={disabled}
            onClick={() => onClick?.()}>
            <i className="fa fa-save" />&nbsp;Save
        </Button>
    );
}
