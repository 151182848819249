import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../../../utils/bi/biUtils';
import { NoDataFoundComponent } from '../../../utils/viewUtils';


export default class ChargesByVenueCodeDetails extends Component
{
    componentDidUpdate()
    {
        if (this.props.showDetails === true)
        {
            if (this.gridApi != null) this.gridApi.sizeColumnsToFit();
        }
    }

    render()
    {
        // Using same gridOptions object across multiple grids in the same component results
        // in stack overflow error in ag-grid        

        const rowData = this.getRowData();

        if (rowData.length === 0)
        {
            return <NoDataFoundComponent message='No sales charges data found' />
        }

        const gridOptions = { ...biUtils.gridOptions };

        return (
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={rowData}
                    pinnedBottomRowData={[this.getTotalRow(rowData)]}
                    columnDefs={this.getColumnDefs(rowData)}
                    sideBar={null}
                    rowGroupPanelShow="never"
                    domLayout='autoHeight'
                    gridOptions={gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>);
    }

    getTotalRow(rowData)
    {
        const totalRow = {};

        rowData.forEach(row => 
        {
            Object.keys(row).forEach(key => 
            {
                if (key !== "caption" && key !== "dataType")
                {
                    let value = totalRow[key] || 0;
                    value += row[key];
                    totalRow[key] = value;
                }
            });
        });

        return totalRow;
    }

    onGridReady = (params) =>
    {
        this.gridApi = params.api;

        this.gridApi.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay)
            {
                const rowData = e.api.rowModel.rowsToDisplay.map(node => node.data);
                const pinnedRows = [this.getTotalRow(rowData)];

                e.api.setPinnedBottomRowData(pinnedRows);
            }
        });

        if (this.props.showDetails === true)
        {
            this.gridApi.sizeColumnsToFit();
        }
    }

    getRowData()
    {
        const { chargeSchemes, chargesByVenueCode } = this.props.data;

        const rowData = chargeSchemes.map(scheme => ({ caption: scheme, dataType: "money", total: 0 }));

        chargesByVenueCode.forEach(data =>
        {
            rowData.forEach(row => 
            {
                row[data.venueCode] = data[row.caption];
                row.total += data[row.caption];
            });
        });

        return rowData;
    }

    getColumnDefs(rowData)
    {
        return Object.keys(rowData[0])
            .filter(key => key !== 'dataType')
            .map(key => (
                {
                    field: key,
                    headerName: this.getHeaderName(key),
                    filter: this.getFilter(key),
                    type: this.getColumnType(key),
                    cellStyle: biUtils.cellStyleHelper,
                    valueFormatter: biUtils.valueFormatterHelper,
                    suppressSizeToFit: key !== "caption",
                    minWidth: key === "caption" ? 150 : undefined
                }));
    }

    getHeaderName(key)
    {
        if (key === "caption") return "";
        if (key === "total") return "Total";
        return key;
    }

    getFilter(key)
    {
        if (key === "caption") return undefined;
        return "agNumberColumnFilter";
    }

    getColumnType(key)
    {
        if (key === "caption") return undefined;
        return "money";
    }
}