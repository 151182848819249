const fieldTypes = {
    salesSummary: "salesSummary",
    salesByVenueCode: "salesByVenueCode",
    salesByDispatchType: "salesByDispatchType",
    salesByPaymentMethod: "salesByPaymentMethod",
    salesByMenuCategory: "salesByMenuCategory",
    salesByCovers: "salesByCovers",
    saleDiscounts: "saleDiscounts",
    saleCharges: "saleCharges",
    saleRefunds: "saleRefunds",
    saleCancellations: "saleCancellations",
    saleVats: "saleVats",
    floatSession: "floatSession",
    floatSessionJournal: "floatSessionJournal",
    saleDeletedItems: "saleDeletedItems"
}

module.exports.getTemplateElements = function ()
{
    return [
        {
            type: "table",
            field: fieldTypes.salesSummary,
            title: "Sales Summary",
            tooltip: "Sales summary",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.salesByVenueCode,
            title: "Venue Sales",
            tooltip: "Sales by venue code",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.salesByDispatchType,
            title: "Dispatch Sales",
            tooltip: "Sales by dispatch type",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.salesByPaymentMethod,
            title: "Sale Receipts Summary",
            tooltip: "Sale receipts by payment method",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.salesByMenuCategory,
            title: "Menu Categories Summary",
            tooltip: "Sales by menu categories",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.salesByCovers,
            title: "Covers Sales Summary",
            tooltip: "DinIn covers sales summary",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.saleDiscounts,
            title: "Gross Sale Discounts",
            tooltip: "Sale discounts",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.saleCharges,
            title: "Sale Charges",
            tooltip: "Sale charges",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.saleRefunds,
            title: "Gross Sale Refunds",
            tooltip: "Sale refunds",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.saleCancellations,
            title: "Sale Cancellations",
            tooltip: "Value of cancelled sales and sale items",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.saleVats,
            title: "Sales VAT",
            tooltip: "Sales vat by percentage",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.floatSession,
            title: "Float Session",
            tooltip: "Float sessions summary",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.floatSessionJournal,
            title: "Float Session Journal",
            tooltip: "Float session journals summary",
            newLine: 0,
            separatorLineCharacter: null
        },
        {
            type: "table",
            field: fieldTypes.saleDeletedItems,
            title: "Sale Cancelled Items",
            tooltip: "Cancelled Sale Items Summary",
            newLine: 0,
            separatorLineCharacter: null
        }
    ];
}

module.exports.getTableData = function (fieldName)
{
    let columnStyles, headerRow, footerRow, dataRows, footNotes;

    const twoColumns = [
        { width: "50%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
        { width: "50%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" }];

    const threeColumns = [
        { width: "50%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
        { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" },
        { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" }];

    const fourColumns = [
        { width: "25%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
        { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" },
        { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" },
        { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" }];

    switch (fieldName)
    {
        case fieldTypes.salesSummary:
            columnStyles = twoColumns;
            dataRows = [
                { values: ["Sale Count", "10"] },
                { values: ["Average Ticket Size", "16.50"] },
                { values: ["Retail Price", "80.82"] },
                { values: ["Discount", "0.00"] },
                { values: ["Refund", "0.00"] },
                { values: ["Net Retail Price", "80.82"] },
                { values: ["Net Charges", "2.00"] },
                { values: ["Sale Price", "82.82"] },
                { values: ["Tax", "16.18"] },
                { values: ["Sale Price Inclusive of Tax", "99.00"], isBold: true, afterBlankRow: true },
                { values: ["Paid Amount", "99.00"] },
                { values: ["Net Paid Amount", "99.00"] },
                { values: ["Balance", "0.00"], isBold: true, afterBlankRow: true },
                { values: ["Outstanding Sales Count", "2"] },
                { values: ["Outstanding Sales Amount", "48.00"] }
            ];
            break;

        case fieldTypes.salesByVenueCode:
            columnStyles = threeColumns;
            headerRow = { values: ["Venue", "Count", "Amount"] };
            dataRows = [
                { values: ["Property", "5", "100.00"] },
                { values: ["Deliveroo", "5", "100.00"] }
            ];
            footerRow = { values: ["Total", "10", "200.00"] };
            break;

        case fieldTypes.salesByDispatchType:
            columnStyles = threeColumns;
            headerRow = { values: ["Dispatch Type", "Count", "Amount"] };
            dataRows = [
                { values: ["Collection", "5", "100.00"] },
                { values: ["TakeAway", "5", "100.00"] },
                { values: ["Delivery", "5", "100.00"] }
            ];
            footerRow = { values: ["Total", "15", "300.00"] };
            break;

        case fieldTypes.salesByPaymentMethod:
            columnStyles = fourColumns;
            headerRow = { values: ["Pay Method", "Receipts", "Refunds", "Net"] };
            dataRows = [
                { values: ["Cash", "100.00", "20.00", "80.00"] },
                { values: ["Card", "100.00", "20.00", "80.00"] },
                { values: ["Venue Paid", "100.00", "20.00", "80.00"] },
            ];
            footerRow = { values: ["Total", "300.00", "60.00", "240.00"] };
            break;

        case fieldTypes.salesByMenuCategory:
            columnStyles = threeColumns;
            headerRow = { values: ["Menu Category", "Count", "Amount"] };
            dataRows = [
                { values: ["Burgers", "5", "100.00"] },
                { values: ["Fries", "5", "100.00"] },
                { values: ["Drinks", "5", "100.00"] }
            ];
            footerRow = { values: ["Total", "15", "300.00"] };
            break;

        case fieldTypes.salesByCovers:
            columnStyles = twoColumns;
            dataRows = [
                { values: ["Total number of covers", "20"] },
                { values: ["Average revenue per cover", "15.00",] },
            ];
            break;

        case fieldTypes.saleDiscounts:
            columnStyles = threeColumns;
            headerRow = { values: ["Discount Type", "Count", "Amount"] };
            dataRows = [
                { values: ["Discount Type-1", "5", "25.00"] },
                { values: ["Discount Type-1", "5", "25.00"] }
            ];
            footerRow = { values: ["Total", "10", "50.00"] };
            break;

        case fieldTypes.saleCharges:
            columnStyles = fourColumns;
            headerRow = { values: ["Scheme", "Count", "Amount", "Vat"] };
            dataRows = [
                { values: ["Delivery Chgs", "5", "150.00", "15.00"] },
                { values: ["Service Chgs", "5", "50.00", "5.00"] }
            ];
            footerRow = { values: ["Total", "10", "150.00", "20.00"] };
            break;

        case fieldTypes.saleRefunds:
            columnStyles = threeColumns;
            headerRow = { values: ["Refund Code", "Count", "Amount"] };
            dataRows = [
                { values: ["Late Order", "5", "20.00"] },
                { values: ["Wrong Order", "5", "20.00"] }
            ];
            footerRow = { values: ["Total", "10", "40.00"] };
            break;

        case fieldTypes.saleCancellations:
            columnStyles = threeColumns;
            headerRow = { values: ["Sale Cancellation", "Count", "Sale Amount"] };
            dataRows = [
                { values: ["Customer Did Not Collect", "2", "50.00"] },
                { values: ["Customer Cancelled Order", "3", "75.00"] }
            ];
            footerRow = { values: ["Total", "5", "125.00"] };
            break;

        case fieldTypes.saleVats:
            columnStyles = fourColumns;
            headerRow = { values: ["VAT%", "Item Count", "Vat Amount", "Sale Amount"] };
            dataRows = [
                { values: ["10%", "2", "10.00", "100.00"] },
                { values: ["20%", "2", "20.00", "100.00"] }
            ];
            footerRow = { values: ["", "4", "30.00", "200.00"] };
            break;

        case fieldTypes.floatSession:
            columnStyles = twoColumns;
            dataRows = [
                { values: ["Started At", "Friday, 10:00 am"] },
                { values: ["Started By", "John"] },
                { values: ["Closed At", "Friday, 11:00 pm"] },
                { values: ["Closed By", "Michael"] },
                { values: ["Opening Cash", "150.00"] },
                { values: ["Cash Transfers *", "50.00"] },
                { values: ["Cash Changed *", "25.00"] },
                { values: ["Cash Sales **", "200.00"] },
                { values: ["Cash Refunds", "25.00"] },
            ];
            footerRow = { values: ["Closing Balance", "250.00"] };
            footNotes = ["* Read from cash out perspective", "** Only sales belonging to the selected date are included"];
            break;

        case fieldTypes.floatSessionJournal:
            columnStyles = [
                { width: "25%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
                { width: "50%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
                { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" }];
            dataRows = [

                { values: ["Expense", "Miscellaneous\nBy:John @ 12:25 pm", "25.00"] },
                { values: ["Expense", "Milk\nBy:Michael @ 02:30 pm", "25.00"] },
                { values: ["", "Total", "50.00"], isBold: true, afterBlankRow: true },

                { values: ["Driver", "Rahul", "25.00"] },
                { values: ["Driver", "Saleem", "25.00"] },
                { values: ["", "Total", "50.00"], isBold: true, afterBlankRow: true },

                { values: ["Deposits", "Till Deposits", "100.00"] },
                { values: ["Withdrawals", "Till Withdrawals", "25.00"] },
                { values: ["", "Total", "75.00"], isBold: true, afterBlankRow: true },
            ];
            break;

        case fieldTypes.saleDeletedItems:
            columnStyles = [
                { width: "20%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
                { width: "40%", display: "flex", justifyContent: "flex-start", padding: "0 5px", textWrap: "wrap" },
                { width: "15%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" },
                { width: "25%", display: "flex", justifyContent: "flex-end", padding: "0 5px", textWrap: "wrap" },
            ];
            headerRow = { values: ["Sale", "Item", "Qty", "Amount"] };
            dataRows = [

                {values: ["3 / 381", "Fries\nBy: John\nCode: Wrong Order","13",  "10.00"]},
                {values: ["5 / 381", "Chicken Burger\nBy:Michael\nCode: Not cooked properly","1",  "10.00"]},
            ];
            footerRow = { values: ["", "", "", "Total", "20.00"] };
            break;

        default:
            throw new Error(`dailySummaryReportTemplateUtils.getTableData: Unknown fieldName '${fieldName}'`);
    }

    return { columnStyles, headerRow, dataRows, footerRow, footNotes };
}