import { React, useState } from 'react';
import { Button } from 'reactstrap';
import MapDialogue from '../../../../../../components/map/MapDialogue';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';
import localizationUtils from '../../../../../../utils/domain/localizationUtils';
const constants = require('../../../../../../utils/domain/constants');
const securityManager = require('../../../../../../utils/domain/security/securityManager');

function PropertyOverview(props)
{
    const [ showMapDialogue, setShowMapDialogue ] = useState(false);

    const propertyType = [
        { value: constants.serviceContextTypes.store, label: "Store" },
        { value: constants.serviceContextTypes.centralKitchen, label: "Central Kitchen" },
    ]
    let systemUserOptions = null;
    if (securityManager.isSystemAdmin() || securityManager.isSupportAdmin() || securityManager.isSupportElevatedUser())
    {
        systemUserOptions = <>
            <tr>
                <td>
                    <BooleanSelectComponent
                        caption="Is Enabled"
                        fieldName="isEnabled"
                        comboReadOnly={ false }
                        hintText="Is Property Enabled"
                        clearable={ false }
                        formManager={ props.formManager } />
                </td>
                <td />
            </tr>
        </>;
    }
    const vatCaption = localizationUtils.getVatCaption();

    return (
        <div>
            <table className="component-table">
                <tbody>
                    <tr>
                        <td>
                            <InputComponent inputType="name" caption="Property Name" hintText="Required"
                                inputReadOnly={securityManager.isSupportUser() ? !securityManager.isSupportElevatedUser() : false}
                                fieldName="name" placeholder="Enter name of property"
                                formManager={props.formManager} />
                        </td>
                        <td>
                            <SelectComponent
                                optionValues={propertyType}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                caption="Property Type"
                                fieldName="type"
                                hintText="Enter the Property Type (Required)"
                                clearable={false}
                                comboReadOnly={!props.isFranchisorSelected}
                                formManager={props.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Country"
                                fieldName="country"
                                optionFieldName="name"
                                optionDisplayFieldName="name"
                                optionValues={localizationUtils.getCountries()}
                                hintText="Optional, required only if different from the franchisor country"
                                clearable={true}
                                comboReadOnly={!props.isFranchisorSelected}     // readOnly for existing property
                                formManager={props.formManager} />
                        </td>
                        <td>
                            <SelectComponent
                                optionValues={constants.TimeZones}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                caption="Time Zone"
                                fieldName="timeZone"
                                hintText="Enter property local time zone (Required)"
                                clearable={false}
                                formManager={props.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent inputType="phone" caption="Phone Number" hintText="Optional"
                                fieldName="phoneNumber" placeholder="Enter property's phone number"
                                formManager={props.formManager} />
                        </td>
                        <td>
                            <InputComponent inputType="email" caption="Email Address" hintText="Required"
                                fieldName="emailAddress" placeholder="Enter property's email address"
                                formManager={props.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='flex-grow-1'>
                                    <InputComponent
                                        inputType="address"
                                        caption="Address"
                                        hintText="Press the Map button on the right to enter property address (Required)"
                                        fieldName="address"
                                        placeholder="Press the map button on the right"
                                        inputReadOnly={true}
                                        formManager={props.formManager} />
                                </div>
                                <div className='ms-2 pb-2'>
                                    <Button color="primary" outline
                                        onClick={async () => 
                                        {
                                            await props.onLoadPropertyGeoConfiguration();
                                            setShowMapDialogue(true);
                                        }}>
                                        <i className="fa fa-map-marker"></i>
                                    </Button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <InputComponent
                                inputType="identity"
                                caption={ `${vatCaption} Registration Number` }
                                fieldName="vatRegistrationNumber"
                                placeholder={ `Enter ${vatCaption} registration number` }
                                hintText="Optional"
                                formManager={props.formManager} />
                        </td>
                    </tr>

                    {systemUserOptions}

                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                fieldName="notes" placeholder="Enter any notes."
                                formManager={props.formManager} />
                        </td>
                    </tr>

                </tbody>
            </table>
            {
                showMapDialogue && getMapDialogComponent(props, setShowMapDialogue)
            }
        </div>
    )
}

function getMapDialogComponent(props, setShowMapDialogue)
{
    let selectedAddress = null, selectedLatLng = null;

    if (props.formManager.viewModel.address != null) 
    {
        selectedAddress = props.formManager.viewModel.address;
    }

    if (props.geoConfiguration != null)
    {
        const { latitude: lat, longitude: lng } = props.geoConfiguration;

        if (lat != null && lng != null) 
        {
            selectedLatLng = { lat, lng };
        }
    }

    return <MapDialogue
        selectedAddress={selectedAddress}
        selectedLatLng={selectedLatLng}
        country={localizationUtils.getCountry()}
        onSave={props.onMapSave}
        onClose={() => setShowMapDialogue(false)} />;
}

export default PropertyOverview;