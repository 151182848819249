
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

const stringUtil = require('../../utils/string/stringUtil');
const constants = require('../form/constants');


class ColorPickerComponent extends Component 
{
    constructor(props)
    {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearColor = this.clearColor.bind(this);

        this.state = { displayColorPicker: false };
    }

    render()
    {
        const backgroundColor = this.props.inputReadOnly ? constants.readOnlyBackgroundColor : null;
        const pointerEvents = this.props.inputReadOnly ? "none" : null;

        const colorDiv =
            <div className="d-flex align-items-center h-100 w-100">
                {
                    stringUtil.isStringNullOrEmpty(this.props.color)
                        ? <div className="text-muted ps-1">No Color </div>
                        : <div className="w-100 h-100" style={{ backgroundColor: this.props.color }} />
                }
            </div>

        let colorPickerDefaultColor = '#fff';
        if (!stringUtil.isStringNullOrEmpty(this.props.color))
            colorPickerDefaultColor = this.props.color;

        return (
            <div
                className="d-flex flex-row align-items-center"
                style={{ position: "relative", disabled: true, cursor: "pointer", pointerEvents: pointerEvents }}>

                <div
                    style={{ width: "100%", height: "40px", padding: "6px", border: "1px solid lightGray", borderRadius: "3px", backgroundColor: backgroundColor }}
                    onClick={() => 
                    {
                        this.state.displayColorPicker = true;
                        this.setState({});
                    }}>
                    {colorDiv}
                </div>

                {
                    this.state.displayColorPicker
                        ? <div style={{ position: 'absolute', zIndex: '2', top: "40px" }}>
                            <div
                                style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}}
                                onClick={this.handleClose} />
                            <SketchPicker color={colorPickerDefaultColor} onChange={this.handleChange} />
                        </div>
                        : null
                }

                <div
                    style={{ marginLeft: '10px', fontSize: "20px" }}
                    onClick={this.clearColor}>
                    <i className="fa fa-times" style={{ opacity: this.props.inputReadOnly ? "0.25" : "1.0" }} />
                </div>

            </div>
        );
    }

    handleClose()
    {
        this.state.displayColorPicker = false;
        this.setState({});
    }

    handleChange(color)
    {
        this.props.onColorChange(color.hex);
    }

    clearColor()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.color))
            return;

        this.props.onColorChange(null);
    }
}

export default ColorPickerComponent;