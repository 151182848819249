import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import arraySort from 'array-sort';

import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';

import PurchasesBySupplier from './components/purchasesBySupplier/PurchasesBySupplier';
import PurchasesByStockItem from './components/purchasesByStockItem/PurchasesByStockItem';
import PurchasesByRawData from './components/purchasesByRawData/PurchasesByRawData';

const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

class InventoryPurchaseReport extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            purchaseOrders: null,
            searchCriteria: null,
            error: null,
            isLoading: false,
        }
    }

    get reportData()
    {
        return {
            categories: this.categories,
            stockItems: this.stockItems,
            suppliers: this.suppliers,
            purchaseOrders: this.state.purchaseOrders
        }
    }

    render()
    {
        let component;

        const { isLoading, error, purchaseOrders } = this.state;

        if (isLoading === true) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error != null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (purchaseOrders == null)
        {
            component = <div className='m-3'>
                <EmptyData
                    title="Data required"
                    text="Please choose date range and hit Search to get report data"
                    action={null}
                />
            </div>;
        }
        else
        {
            component = this.renderReportData(purchaseOrders);
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>Inventory Purchase Receipts Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData(purchaseOrders)
    {
        if (purchaseOrders.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Data Found"
                        text="No inventory purchase data found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key='Supplier'>Purchases By Supplier</Tab>);
        tabContents.push(<TabPanel key='Supplier' className='bg-white mt-n1'><PurchasesBySupplier reportData={this.reportData} /></TabPanel>);

        tabHeaders.push(<Tab key='Item'>Purchases By Item</Tab>);
        tabContents.push(<TabPanel key='Item' className='bg-white mt-n1'><PurchasesByStockItem reportData={this.reportData} /></TabPanel>);

        tabHeaders.push(<Tab key='rawData'>Purchases Raw Data</Tab>);
        tabContents.push(<TabPanel key='rawData' className='bg-white mt-n1'><PurchasesByRawData reportData={this.reportData} /></TabPanel>);

        return (
            <div className='p-2 mt-n3 bg-white'>
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ purchaseOrders: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, purchaseOrders: null, searchCriteria: searchCriteria, isLoading: true });

        const fromDate = searchCriteria.startDate;
        const toDate = searchCriteria.endDate;

        try 
        {
            // Reference data is loaded only once during the lifetime of the component

            if (this.categories == null)
            {
                const categories = await apiLoadFacade.loadInventoryCategories();
                this.categories = arraySort(categories, "name");
            }

            if (this.stockItems == null)
            {
                const stockItems = await apiLoadFacade.loadStockItems('unitTypesOnly=true');
                this.stockItems = arraySort(stockItems, "name");
            }

            if (this.suppliers == null)
            {
                const suppliers = await apiLoadFacade.loadSuppliers();
                this.suppliers = arraySort(suppliers, "name");
            }

            // Load state data

            const purchaseOrders = await apiLoadFacade.loadPurchaseOrderReceipts(fromDate, toDate);

            this.setState({ purchaseOrders: arraySort(purchaseOrders, "receiptDate", { reverse: true }), isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }
}

export default InventoryPurchaseReport;