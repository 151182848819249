import * as apiLoadFacade from '../../../../../utils/api/apiLoadFacade';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');
const domainConstants = require('../../../../../utils/domain/constants');
const formatter = require('../../../../../utils/formatter/formatter');
const securityManager = require('../../../../../utils/domain/security/securityManager');

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

async function getPurchaseOrders()
{
    const context = "getPurchaseOrders";

    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
        throw `${context}: Invalid currentOrgNode for requested operation`;

    try
    {
        return await apiLoadFacade.loadPurchaseOrders();

    } catch (error)
    {
        throw `${context}: Failed to fetch purchase orders, error: ${error}`;
    }
}

async function savePurchaseOrder(purchaseOrder)
{
    const context = "savePurchaseOrder";

    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
        throw `${context}: Invalid currentOrgNode for requested operation`;

    try
    {
        const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
        const resourceUrl = `${baseUrl}/inventory/purchaseOrders`;

        const updatedPurchaseOrder = await rmsApiProxy.post(resourceUrl, purchaseOrder);
        return updatedPurchaseOrder;

    } catch (error)
    {
        throw `${context}: Failed to save purchase order, error: ${error}`;
    }
}

function validatePurchaseOrder(purchaseOrder)
{
    if (!validator.isPresent(purchaseOrder.supplierId))
        return "Supplier cannot be left empty";

    if (!validator.isDate(purchaseOrder.date))
        return "Order date is not valid";

    if (!validator.isPresent(purchaseOrder.number))
        return "Order Number is not set";

    if (purchaseOrder.orderItems.length <= 0)
        return "There are not items in the orders";

    for (const orderItem of purchaseOrder.orderItems) 
    {
        const error = validatePurchaseOrderItem(purchaseOrder, orderItem);
        if (!stringUtil.isStringNullOrEmpty(error))
            return error;
    }
}

function validatePurchaseOrderItem(purchaseOrder, purchaseOrderItem)
{
    if (!validator.isPresent(purchaseOrderItem.unitTypeId))
        return 'Order Item unit cannot be left empty';

    if (!validator.isGreaterThanZero(purchaseOrderItem.quantity))
        return 'Order Item quantity must be greater than 0';

    if (purchaseOrder.status == domainConstants.PurchaseOrderStatusType.received)
    {
        if (!validator.isGreaterThanOrEqualToZero(purchaseOrderItem.receiptUnitPrice))
            return 'Invalid receiptUnitPrice';

        if (!validator.isGreaterThanOrEqualToZero(purchaseOrderItem.receiptVat))
            return 'Invalid receiptVat';

        if (!validator.isGreaterThanOrEqualToZero(purchaseOrderItem.receiptQuantity))
            return 'Invalid receiptQuantity';
    }
}

function getApplicablePurchaseOrderTotalPrice(purchaseOrder)
{
    let orderTotalVat = 0;
    let orderTotalAmount = 0;

    purchaseOrder.orderItems.forEach(orderItem =>
    {
        const { totalAmount, totalVat } = getApplicableOrderItemTotalPrice(purchaseOrder, orderItem);
        orderTotalAmount += totalAmount;
        orderTotalVat += totalVat;
    });

    const orderTotalPrice = orderTotalAmount + orderTotalVat;
    return { totalPrice: orderTotalPrice, totalAmount: orderTotalAmount, totalVat: orderTotalVat }
}

function getApplicableOrderItemTotalPrice(purchaseOrder, orderItem)
{
    if (purchaseOrder.status == domainConstants.PurchaseOrderStatusType.submitted)
        return getReceiptItemPrice(orderItem);
    else
        return getOrderItemPrice(orderItem);
}

function getReceiptItemPrice(orderItem)
{
    const totalVat = (1.0 * orderItem.receiptVat || 0) * (1.0 * orderItem.receiptQuantity || 0);
    const totalAmount = (1.0 * orderItem.receiptUnitPrice || 0) * (1.0 * orderItem.receiptQuantity || 0);

    const totalPrice = totalAmount + totalVat;
    return { totalPrice, totalAmount, totalVat }
}

function getOrderItemPrice(orderItem)
{
    const totalVat = (1.0 * orderItem.vat || 0) * (1.0 * orderItem.quantity || 0);
    const totalAmount = (1.0 * orderItem.unitPrice || 0) * (1.0 * orderItem.quantity || 0);

    const totalPrice = totalAmount + totalVat;
    return { totalPrice, totalAmount, totalVat }
}

function getStockItem(orderItem, lookupData)
{



    const stockItems = lookupData.stockItems;
    const stockItem = stockItems.find(stockItem => stockItem.id === orderItem.stockItemId);
    return stockItem;
}

function getSupplierStockItems(supplierId, lookupData)
{



    if (stringUtil.isStringNullOrEmpty(supplierId))
        return [];

    const stockItems = lookupData.stockItems;

    const supplierStockItems = stockItems.filter(si => si.suppliers.some(supplierProfile => !supplierProfile.isDisabled && supplierProfile.supplierId === supplierId));
    supplierStockItems.sort((s1, s2) => s1.name.localeCompare(s2.name));

    return supplierStockItems;
}

function getCreatedOn(purchaseOrder)
{
    return formatter.convertToWeekdayMonthYearFormat(purchaseOrder.date);
}

function getSubmittedOn(purchaseOrder)
{
    return purchaseOrder.status == domainConstants.PurchaseOrderStatusType.submitted ||
           purchaseOrder.status == domainConstants.PurchaseOrderStatusType.received
            ? formatter.convertToWeekdayMonthYearFormat(purchaseOrder.submissionDate)
            : null;
}

function getReceivedOn(purchaseOrder)
{
    return purchaseOrder.status == domainConstants.PurchaseOrderStatusType.received
        ? formatter.convertToWeekdayMonthYearFormat(purchaseOrder.receiptDate)
        : null;
}

function getNumberOfItems(purchaseOrder)
{
    return purchaseOrder.orderItems.length;
}

function renderItemsOnSubmit(purchaseOrder)
{
    const orderItems = purchaseOrder.orderItems;
    orderItems.forEach(orderItem =>
    {
        orderItem.receiptUnitTypeId = orderItem.unitTypeId;
        orderItem.receiptUnitPrice = orderItem.unitPrice;
        orderItem.receiptQuantity = orderItem.quantity;
        orderItem.receiptVat = orderItem.vat;
    });
}

function renderItemsOnRecall(purchaseOrder)
{
    const orderItems = purchaseOrder.orderItems;
    orderItems.forEach(orderItem =>
    {
        orderItem.receiptUnitTypeId = null;
        orderItem.receiptUnitPrice = null;
        orderItem.receiptQuantity = null;
        orderItem.receiptVat = null;
    });
}

function getOrderItemBackgroundColor(orderItem)
{
    // Background color for the order side of the orderItem
    if (orderItem.isSupplierAdded) return "#e6f2ff";        // bluish
    return "transparent";
}

function getReceiptItemBackgroundColor(orderItem)
{
    // Background color for the receipt side of the orderItem
    if (orderItem.isSupplierAdded) return "#e6f2ff";        // bluish
    if (orderItem.receiptQuantity === 0) return "#ffe6e6";  // reddish
    return "transparent";
}

function isUserAuthorizedForAnyAction()
{
    if (securityManager.isSupportUser()) 
    {
        return securityManager.isSupportElevatedUser();
    }

    return true;
}

export default {
    getPurchaseOrders,
    savePurchaseOrder,
    validatePurchaseOrder,
    getApplicablePurchaseOrderTotalPrice,
    getApplicableOrderItemTotalPrice,
    getOrderItemPrice,
    getReceiptItemPrice,
    getStockItem,
    getSupplierStockItems,
    getCreatedOn,
    getSubmittedOn,
    getReceivedOn,
    getNumberOfItems,
    renderItemsOnSubmit,
    renderItemsOnRecall,
    getOrderItemBackgroundColor,
    getReceiptItemBackgroundColor,
    isUserAuthorizedForAnyAction,
}