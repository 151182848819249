
import React from 'react';

import 'react-select/dist/react-select.min.css';
import { FormGroup, FormText, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

const stringUtil = require('../../../utils/string/stringUtil');
const dateUtil = require('../../../utils/dateUtil/dateUtil');

export default function CalendarInputComponent(props)
{
    var addonIcon = "fa fa-calendar";
    var hintTextComponent = null;
    var captionTextComponent = null;

    if (props.hintText)
    {
        hintTextComponent = <FormText color="muted">{props.hintText}</FormText>
    }

    captionTextComponent = <Label>{props.caption}</Label>

    var rawCurrentValue = props.formManager.getValue(props.fieldName);
    var currentValue = "";
    if (!stringUtil.isStringNullOrEmpty(rawCurrentValue))
        currentValue = dateUtil.convertToLocalStandard(rawCurrentValue);

    return (

        // 
        // style after props overrides any style defined in props
        //

        <FormGroup {...props} style={props.mainStyle}>

            {captionTextComponent}

            <InputGroup>

                <InputGroupText><i className={addonIcon}></i></InputGroupText>
                
                <Input
                    type="date"
                    id={props.fieldName}
                    readOnly={props.inputReadOnly}
                    value={currentValue}
                    onChange={props.formManager.onValueChanged}
                    {...props} style={props.backgroundColorStyle} />

            </InputGroup>

            {hintTextComponent}

        </FormGroup>);
}