import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';

export default function FranchisorOperatingConfigurationOverviewComponent(props)
{

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent
                            inputType="money"
                            fieldName="staffMealLimit"
                            placeholder="Enter Staff Meal Limits"
                            caption="Staff Meal Limits"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>

                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="mealShiftHours"
                            placeholder="Enter Staff Meal Shift Hours"
                            caption="Staff Meal Shift Hours"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="percentage"
                            fieldName="royaltyPercentage"
                            placeholder="Enter Royalty Percentage"
                            caption="Royalty Percentage"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                    <td>
                        <InputComponent
                            inputType="percentage"
                            fieldName="marketingPercentage"
                            placeholder="Enter Marketing Percentage"
                            caption="Marketing Percentage"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>

                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="rewardPointConversionRatio"
                            placeholder="Enter Reward Point Conversion Ratio"
                            caption="Reward Point Conversion Ratio"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
