import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { DeviceTypes, LabelPrinterTypes, ReceiptPrinterTypes } from "../../../../constants";
import ReceiptPrinterIcon from "../../../../assets/icons/ReceiptPrinterIcon";
import LabelPrinterIcon from "../../../../assets/icons/LabelPrinterIcon";
import CreditCardReaderIcon from "../../../../assets/icons/CreditCardReaderIcon";
import TelephoneCliIcon from "../../../../assets/icons/TelephoneCliIcon";
import KotIcon from "../../../../assets/icons/KotIcon";
import { makeFieldConfigurationValue } from "../utils";

const title = {
     [DeviceTypes.receiptPrinter]: "Receipt Printer",
     [DeviceTypes.labelPrinter]: "Label Printer",
     [DeviceTypes.creditCardReader]: "Credit Card Reader",
     [DeviceTypes.telephoneCli]: "Telephone CLI",
     [DeviceTypes.kotPrinter]: "KOT",
};

const DeviceNodeHeader = ({ deviceName, deviceType, fields = [] }) => {
     const Icon = useCallback(() => {
          switch (deviceType) {
               case DeviceTypes.receiptPrinter:
                    return <ReceiptPrinterIcon />;
               case DeviceTypes.labelPrinter:
                    return <LabelPrinterIcon />;
               case DeviceTypes.creditCardReader:
                    return <CreditCardReaderIcon />;
               case DeviceTypes.telephoneCli:
                    return <TelephoneCliIcon />;
               case DeviceTypes.kotPrinter:
                    return <KotIcon />;

               default:
                    return <ReceiptPrinterIcon />;
          }
     }, [deviceType]);

     const importantFields = useMemo(() => {
          let res = [];
          let selectedType = "";

          if (deviceType === DeviceTypes.receiptPrinter) selectedType = fields.find((field) => field.fieldName === "receiptPrinterType")?.defaultValue;
          if (deviceType === DeviceTypes.labelPrinter) selectedType = fields.find((field) => field.fieldName === "labelPrinterType")?.defaultValue;

          for (let field of fields) {
               switch (deviceType) {
                    case DeviceTypes.receiptPrinter:
                         switch (selectedType) {
                              case ReceiptPrinterTypes.genericUSB:
                                   if (field.fieldName === "systemName") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case ReceiptPrinterTypes.genericBluetooth:
                                   if (field.fieldName === "bluetoothAddress") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case ReceiptPrinterTypes.genericNetwork:
                                   if (field.fieldName === "ipAddress") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case ReceiptPrinterTypes.sunmiCloudPrinter:
                                   if (field.fieldName === "serialNumber") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;

                              default:
                                   break;
                         }
                         break;
                    case DeviceTypes.labelPrinter:
                         switch (selectedType) {
                              case LabelPrinterTypes.genericUSB:
                                   if (field.fieldName === "systemName") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case LabelPrinterTypes.genericBluetooth:
                                   if (field.fieldName === "bluetoothAddress") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case LabelPrinterTypes.genericNetwork:
                                   if (field.fieldName === "ipAddress") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case LabelPrinterTypes.sunmiCloudPrinter:
                                   if (field.fieldName === "serialNumber") res.push(field);
                                   if (field.fieldName === "encodingType") res.push(field);
                                   break;
                              case LabelPrinterTypes.brotherQL700Usb:
                                   if (field.fieldName === "systemName") res.push(field);
                                   break;
                              case LabelPrinterTypes.brotherQL800Usb:
                                   if (field.fieldName === "systemName") res.push(field);
                                   break;
                              case LabelPrinterTypes.brotherQL820Bluetooth:
                                   if (field.fieldName === "bluetoothAddress") res.push(field);
                                   break;
                              case LabelPrinterTypes.brotherQL820Network:
                                   if (field.fieldName === "ipAddress") res.push(field);
                                   break;
                              case LabelPrinterTypes.zplGenericNetwork:
                                   if (field.fieldName === "ipAddress") res.push(field);
                                   break;
                              case LabelPrinterTypes.zplGenericUSB:
                                   if (field.fieldName === "systemName") res.push(field);
                                   break;

                              default:
                                   break;
                         }
                         break;
                    case DeviceTypes.creditCardReader:
                         if (field.fieldName === "creditType") res.push(field);
                         break;
                    case DeviceTypes.kotPrinter:
                         if (field.fieldName === "kitchenStationId") res.push(field);
                         if (field.fieldName === "copiesToPrint") res.push(field);
                         break;
                    default:
                         break;
               }
          }
          return res;
     }, [deviceType, fields]);

     return (
          <Container>
               <IconWrapper>
                    <Icon />
               </IconWrapper>
               <Content>
                    <Title>{deviceName ?? title[deviceType]}</Title>
                    <SomeConf>
                         {importantFields.map((field) => (
                              <FieldContainer key={"imp-" + field.fieldName}>
                                   <FieldText>{`${field.label}:`}</FieldText>
                                   <FieldText>{`${makeFieldConfigurationValue(field)}`} </FieldText>
                              </FieldContainer>
                         ))}
                    </SomeConf>
               </Content>
          </Container>
     );
};

export default DeviceNodeHeader;

const Title = styled.div`
     font-size: 28px;
     font-weight: 600;
`;
const Content = styled.div``;
const FieldContainer = styled.div`
     display: flex;
     flex-direction: row;
     gap: 8px;

     div:first-child {
          font-weight: 600;
          color: black;
     }
`;
const FieldText = styled.div`
     font-weight: 500;
     font-size: 16px;
     color: #000000c0;
`;
const IconWrapper = styled.div`
     aspect-ratio: 1;
     width: 90px;
     height: 100px;
`;
const SomeConf = styled.div`
     font-size: 14px;
     color: #313131;

     p {
          margin-bottom: 3px;
     }
`;
const Container = styled.div`
     display: flex;
     flex-direction: row;
     gap: 26px;
     padding: 30px;
`;
