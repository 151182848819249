import { CloudinaryContext, Image } from 'cloudinary-react';
import React from 'react'

const constants = require('../../../../../../utils/domain/constants');
const configurationManager = require('../../../../../../utils/config/configurationManager');

const colors = {
    error: "#dc3545",
    warning: "#ff9400",
}

export default function LogRow(props)
{

    const log = props.log;

    return <>
        <div className="d-flex flex-column gap-1 py-2 px-2 border-bottom rounded" style={{ pageBreakInside: "avoid", background: log.severity === "error" ? colors.error + '07' : colors.warning + '07' }}>
            <div className="d-flex flex-row">
                <div className="p-0 text-muted">{log.description}</div>
            </div>

            <div className="d-inline-flex flex-row align-align-items-start">
                {
                    (log.menuContext) ?
                        <div className="d-inline-flex flex-row flex-wrap gap-1" style={{ flex: 1 }}> {getTagChips(log.menuContext)} </div> : null
                }
                {
                    log.storefrontTypes && log.storefrontTypes.length > 0
                        ? getStorefronts(log.storefrontTypes)
                        : null
                }
                {
                    log.channelContext ? 
                    <div className="d-inline-flex flex-row flex-wrap gap-1" style={{ flex: 1 }}> {getPropertyStorefrontTags(log.channelContext)} </div>
                    : null
                }
            </div>


        </div>
    </>;

    function getStorefronts(storefrontTypes)
    {
        const components = [];
        storefrontTypes.forEach(type => components.push(renderIcon(getStorefrontUrl(type))));
        return <div className="d-flex flex-row">
            {/* <div className="p-0 text-muted">{`[${log.storefrontTypes.join(", ")}]`}</div> */}
            {components}
        </div>;
    }

    function renderIcon(logoImageUrl)
    {
        let cloudName = configurationManager.getConfig().cdnAppCloudName;

        return (

            <CloudinaryContext cloudName={cloudName}>
                <div className='p-1'>
                    <Image publicId={logoImageUrl} width="16" height="16" />
                </div>
            </CloudinaryContext>);
    }

}



function getStorefrontUrl(storefrontType)
{
    let imageUrl;
    switch (storefrontType) 
    {
        case constants.storefrontTypes.POS:
            imageUrl = `app/back.office/storefronts/POS`;
            break;

        case constants.storefrontTypes.Deliveroo:
            imageUrl = `app/back.office/storefronts/Deliveroo`;
            break;

        case constants.storefrontTypes.UberEats:
            imageUrl = `app/back.office/storefronts/UberEats`;
            break;

        case constants.storefrontTypes.JustEats:
            imageUrl = `app/back.office/storefronts/JustEats`;
            break;

        case constants.storefrontTypes.KIOSK:
            imageUrl = `app/back.office/storefronts/KIOSK`;
            break;

        case constants.storefrontTypes.MobileApp:
            imageUrl = `app/back.office/storefronts/MobileApp`;
            break;

        case constants.storefrontTypes.WebApp:
            imageUrl = `app/back.office/storefronts/WebApp`;
            break;

        case constants.storefrontTypes.FlipdishKIOSK:
            imageUrl = `app/back.office/storefronts/Flipdish`;
            break;

        case constants.storefrontTypes.FlipdishMobileApp:
            imageUrl = `app/back.office/storefronts/Flipdish`;
            break;

        case constants.storefrontTypes.FlipdishWeb:
            imageUrl = `app/back.office/storefronts/Flipdish`;
            break;

        case constants.storefrontTypes.ExternalApp:
            imageUrl = `app/back.office/storefronts/ExternalApp`;
            break;

        default:
            imageUrl = `app/back.office/storefronts/default`;
            break;
    }

    return imageUrl;
}

function getTagChips(tags)
{
    if (!tags)
        return [];

    const keys = Object.keys(tags).filter(key => !key.includes("Id") && !key.includes("meta"));
    return keys.map(key =>
    {
        return <div style={{ marginRight: "10px" }}>{getBadge(key, tags[key])}</div>
    });
}

function getPropertyStorefrontTags(channelContext)
{
    return channelContext.properties.map(property =>
    {
        const storefronts = property.storefronts.map(sf => sf.name).join(', ');

        return <div style={{ marginRight: "10px" }}>{getBadge(property.name, storefronts)}</div>
    });
}

function getBadge(key, value)
{
    return <span className="badge" style={{ fontSize: ".7rem", background: '#00000010', color: '#000000b0', fontWeight: 500 }}>
        <div className="d-inline-flex p-1">
            <div style={{ textTransform: 'uppercase' }} >{key}</div>
            <div className="mx-2 opacity-50" >{"|"}</div>
            <div>{value}</div>
        </div>
    </span>
}