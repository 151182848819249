
import React from 'react';
import BIToolBaseComponent from '../../common/regularBITools/BIToolBaseComponent';

import ChartReport from './components/ChartReport';

const stringUtil = require('../../../../utils/string/stringUtil');

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const rmsApiProxyUtils = require('../../../../utils/api/rmsApiProxyUtils');
const dateUtilExt = require('../../../../utils/dateUtil/dateUtilExt');

class DailyPayrollCosts extends BIToolBaseComponent
{
    constructor(props)
    {
        super(props);

        // this.state is initialized by base class

        this.state.stage = 0;
        this.state.entities = [];
        this.state.error = null;
    }

    render()
    {
        var resultComponent = null;

        if (this.state.stage == 0)
        {
            if (!stringUtil.isStringNullOrEmpty(this.state.error))
                resultComponent = this.renderErrorComponent(this.state.error)
            else
                resultComponent = this.renderCaptionComponent("BI (Business Intelligence) Tool :: Daily Payroll Costs");
        }

        if (this.state.stage == 1)
        {
            this.loadData();
            resultComponent = this.renderSpinnerComponent("Please Wait");
        }

        if (this.state.stage == 2)
        {
            resultComponent =
                <div className='p-2 d-flex flex-column bg-white'>
                    <div>{this.renderGrid(this.generateColumnDes(), this.state.entities)}</div>
                    <div className='mt-2 py-3 border'><ChartReport entities={this.state.entities} /></div>
                </div>
        }

        return <div>
            {this.renderMultiDateSelectionHeaderComponent
                (
                    this.componentName || "Daily Payroll Costs",
                    this.state.stage === 1,
                    true,
                    (searchCriteria) =>
                    {
                        this.state.stage = 1;
                        this.state.dateCriteria = searchCriteria;
                        this.setState({});
                    },
                    () =>
                    {
                        this.state.error = null;
                        this.state.dateCriteria = null;
                        this.state.entities = [];
                        this.state.stage = 0;
                        this.setState({});
                    })
            }
            {resultComponent}
        </div>;
    }

    generateColumnDes()
    {
        var columnDefinitions = [];

        columnDefinitions.push({ field: 'property', type: 'rowGroup' });

        var uniqueYears = [...new Set(this.state.entities.map(e => e.year))];

        if (uniqueYears.length > 1)
        {
            columnDefinitions.push({ field: 'year', type: 'rowGroup' });
            columnDefinitions.push({ field: 'month', type: 'rowGroup, month' });
        }
        else
        {
            columnDefinitions.push({ field: 'year', type: 'rowGroupEnabled' });

            var uniqueMonths = [...new Set(this.state.entities.map(e => e.month))];

            if (uniqueMonths.length > 1)
            {
                columnDefinitions.push({ field: 'month', type: 'rowGroup, month' });
            }
            else
            {
                columnDefinitions.push({ field: 'month', type: 'rowGroupEnabled, month' });
            }
        }

        columnDefinitions.push({ field: 'staff', type: 'rowGroup' });

        columnDefinitions.push({ field: 'wagesCost', type: 'money' });

        columnDefinitions.push({ field: 'adjustedCost', type: 'money' });
        columnDefinitions.push({ field: 'scheduledCost', type: 'money' });
        columnDefinitions.push({ field: 'actualCost', type: 'money' });

        columnDefinitions.push({ field: 'bonus', type: 'money' });
        columnDefinitions.push({ field: 'employerNIContribution', type: 'money' });
        columnDefinitions.push({ field: 'employerNIAllowance', type: 'money' });
        columnDefinitions.push({ field: 'employerPensionContribution', type: 'money' });

        columnDefinitions.push({
            field: 'holidayHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6), //value * 3.6e+6 is conversion from hour to milliseconds
            type: 'number'
        });
        columnDefinitions.push({ field: 'holidayCost', type: 'money' });

        columnDefinitions.push({
            field: 'adjustedHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6), 
            type: 'number'
        });
        columnDefinitions.push({
            field: 'scheduledHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6),
            type: 'number'
        });
        columnDefinitions.push({
            field: 'actualHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6),
            type: 'number'
        });

        columnDefinitions.push({ field: 'deduction', type: 'money' });
        columnDefinitions.push({ field: 'employeeNetPayment', type: 'money' });

        columnDefinitions.push({ field: 'scheduledHourlyWage', type: 'money', aggFunc: 'avg', hide: true });
        columnDefinitions.push({
            field: 'scheduledHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6),
            type: 'number', hide: true
        });

        columnDefinitions.push({ field: 'actualHourlyWage', type: 'money', aggFunc: 'avg', hide: true });
        columnDefinitions.push({
            field: 'actualHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6),
            type: 'number', hide: true
        });
        columnDefinitions.push({
            field: 'adjustedHours',
            valueFormatter: ({ value }) => dateUtilExt.convertMilliSecondsToHoursAndMinutes(value * 3.6e+6),
            type: 'number', hide: true
        });

        columnDefinitions.push({ field: 'holidayHourlyWage', type: 'money', aggFunc: 'avg', hide: true });

        columnDefinitions.push({ field: 'advancePayment', type: 'money', hide: true });

        columnDefinitions.push({ field: 'employeeNIContribution', type: 'money', hide: true });
        columnDefinitions.push({ field: 'employeePensionContribution', type: 'money', hide: true });
        columnDefinitions.push({ field: 'paye', type: 'money', hide: true });

        columnDefinitions.push({ field: 'franchisor', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'week', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfMonth', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfWeek', type: 'rowGroupEnabled' });

        return columnDefinitions;
    }

    async loadData()
    {
        const fromDate = this.state.dateCriteria.startDate;
        const toDate = this.state.dateCriteria.endDate;

        const resourceUrl =
            `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/accounts/dailyPayrollCosts` +
            `?fromDate=${fromDate}&toDate=${toDate}`;

        try 
        {
            const entities = await rmsApiProxy.get(resourceUrl);

            entities.forEach(entity =>
            {
                entity.scheduledCost = entity.scheduledHours * entity.scheduledHourlyWage;
                entity.actualCost = entity.actualHours * entity.actualHourlyWage;
                entity.adjustedCost = entity.adjustedHours * entity.actualHourlyWage;

                entity.holidayCost = entity.holidayHours * entity.holidayHourlyWage;
                entity.employeeNetPayment = entity.adjustedCost + entity.bonus - entity.deduction - entity.employeeNIContribution - entity.employeePensionContribution - entity.paye;
            });

            this.setState({ stage: 2, entities: entities });
        }
        catch (error) 
        {
            this.setState({ stage: 0, error: error });
        }
    }
}

export default DailyPayrollCosts;