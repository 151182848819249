
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js'

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');
const validator = require('../../../../../utils/validator/validator');

class EmptyLineElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {
        return <div style={{width:'100%', minHeight:`${10 * this.props.element.count}px`, padding:'0px', margin:'0px'}}
                onDoubleClick={()=>
                {
                    var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                    viewUtil.openModalForm("Empty Line", ()=>
                    {
                        return <DialogueBox element={clonedElement}/>
                    }, 
                    ()=>
                    {
                        this.props.element.count = clonedElement.count*1;
                        this.setState({});
                    }, 
                    ()=>
                    {
                        if (!validator.isInteger(clonedElement.count) || clonedElement.count <= 0)
                            return "Count is not a valid number";
                    })
                }}></div>;
    }
}

class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;
    }

    render()
    {
        return <table style={{width:'100%'}}>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Number Of Empty Lines"
                                hintText="Number Of Empty Lines"
                                fieldName="count"
                                placeholder="Enter number Of Empty Lines"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
               </table>;
    }
}

export default EmptyLineElement;