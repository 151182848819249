import React, { Component } from 'react';
import GridViewEmbedded from '../../../../../../components/gridViewEmbedded/gridViewEmbedded';
import GridViewOptions from '../../../../../../components/gridView/gridViewOptions';
import StockItemUnitType from './StockItemUnitType';
import StockItemSections from '../StockItemSections';

const arraySort = require('array-sort');

const guidUtil = require('../../../../../../utils/guid/guidUtil')
const stringUtil = require('../../../../../../utils/string/stringUtil');
const validator = require('../../../../../../utils/validator/validator');
const columnWidthConstants = require('../../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../../utils/constants/dataTypeConstants');

export default class StockItemUnitTypes extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.getUnitTypes = this.getUnitTypes.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.validateData = this.validateData.bind(this);
        this.save = this.save.bind(this);

        props.data.unitTypes = props.data.unitTypes || [];
    }

    render()
    {
        return  <StockItemSections title="Stock Units">
                    {this.renderGrid()}
                </StockItemSections>
    }

    renderGrid() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Stock Units";
        gridViewOptions.messageContext = "Stock Units";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;
        gridViewOptions.getRowData = this.getUnitTypes;

        // let getNewRow;
        // if (!this.props.isRowReadOnly)
        // {
        //     if (!stringUtil.isStringNullOrEmpty(this.props.data.coreUnitCode)) //Core unit is defined
        //         getNewRow = () => { const newRow = {}; newRow.id = guidUtil.generateGuid(); return newRow; }
        // }

        let getNewRow;
        if (!stringUtil.isStringNullOrEmpty(this.props.data.coreUnitCode)) //Core unit is defined
            getNewRow = () => { const newRow = {}; newRow.id = guidUtil.generateGuid(); return newRow; }

        gridViewOptions.getNewRow = getNewRow;
        gridViewOptions.getComponent = (isNew, row, onFieldChanged) =>
        {
            return <StockItemUnitType
                data={row}
                coreUnitCode={this.props.data.coreUnitCode}
                isRowReadOnly={this.props.isRowReadOnly}
                onFieldChanged={onFieldChanged} />
        };
        gridViewOptions.getComponentTitle = (isNew, row) => "Stock Units";

        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        return (<GridViewEmbedded isRowReadOnly={this.props.isRowReadOnly} gridViewOptions={gridViewOptions} />);
    }

    getUnitTypes()
    {
        const rows = arraySort(this.props.data.unitTypes, stringUtil.localeCompareProp("code"));
        this.enrich(rows);
        return rows;
    }

    validateRow(isNewRow, row)
    {
        if (stringUtil.isStringNullOrEmpty(row.code))
            return "Code cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(row.coreUnitConversionFactor))
            return "Conversion factor cannot be left empty";

        if (!validator.isGreaterThanZero(row.coreUnitConversionFactor))
            return "Conversion factor is invalid";

        return this.validateData(isNewRow, row);
    }

    validateData(isNewRow, row)
    {
        const existingRow = this.props.data.unitTypes.find(x => x.code === row.code);
        if (existingRow != null && row.id != existingRow.id)
        {
            return `${row.code} already exists`;
        }

        return null;
    }

    async save(isNew, row)
    {
        const unitTypes = [...this.props.data.unitTypes];
        if (isNew)
        {
            unitTypes.push(row);
        }
        else
        {
            const index = unitTypes.findIndex(x => x.id === row.id);
            if (index != -1) unitTypes.splice(index, 1, row);
        }
        this.enrich(unitTypes);
        this.props.data.unitTypes = unitTypes;
        this.setState({});
    }

    enrich(rows)
    {
        rows.forEach(row =>
        {
            const conversionHintText = `1x ${row.code} = ${row.coreUnitConversionFactor} ${this.props.data.coreUnitCode}`;
            row.conversionHintText = conversionHintText;
        })
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "code";
        header.headerName = "Unit";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "conversionHintText";
        header.headerName = `Conversion`;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}
