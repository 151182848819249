import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';
import * as inventoryConstants from '../../../../../utils/domain/inventoryConstants';

import * as reportDataUtils from '../utils/reportDataUtils';
import * as reportViewUtils from '../../../../../views/reports/reportUtils/helperUtils/viewUtils';

class BuildSockItemStatus extends Component
{
    render()
    {
        const { reportData } = this.props;
        const rowData = reportDataUtils.getBuildStockItemInventoryData(reportData);

        return (
            <div className="ag-theme-alpine" style={{ height: "75vh" }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={rowData}
                    columnDefs={this.getColumnDefs(reportData)}
                    gridOptions={biUtils.gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    onGridReady = (params) =>
    {
        params.api.sizeColumnsToFit();
    }

    getColumnDefs = (reportData) =>
    {
        // inventoryEventType.purchase does not apply to buildStockItems

        const distinctEventTypes = reportDataUtils
            .getDistinctEventTypes(reportData)
            .filter(eventType => eventType !== inventoryConstants.inventoryEventType.purchase);

        const columnDefs = [];

        columnDefs.push({ field: 'categoryName', type: 'rowGroupEnabled', hide: false });

        columnDefs.push({ field: 'stockItemName', headerName: 'Stock Item' });

        columnDefs.push({ field: 'unitCode', headerName: 'Units' });

        columnDefs.push({ field: 'openingQuantity', headerName: 'Opening', type: `${dataTypeConstants.number}` });

        distinctEventTypes.forEach(eventType => columnDefs.push({
            headerName: reportViewUtils.getInventoryEventTypeForDisplay(eventType, inventoryConstants.inventoryEventItemType.buildItem),
            field: eventType,
            type: `${dataTypeConstants.number}`
        }));

        columnDefs.push({ field: 'closingQuantity', headerName: 'Closing', type: `${dataTypeConstants.number}` });

        // Override biUtils.gridOptions defaults
        columnDefs.forEach(columnDef => columnDef.suppressSizeToFit = false);

        return columnDefs;
    }
}

export default BuildSockItemStatus;