import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import validator from '../../../../utils/validator/validator';

import Bill from './components/Bill'
import * as expenseUtils from '../expenses/utils/expenseUtils';



const typeUtil = require('../../../../utils/type/typeUtil');
const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const GridViewButton = require('../../../../components/gridView/gridViewButton');

const stringUtil = require('../../../../utils/string/stringUtil');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const guidUtil = require('../../../../utils/guid/guidUtil');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');


class Bills extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadBillsAsync = this.loadBillsAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);

        this.state = {
            isReferenceDataLoaded: false,
            suppliers: [],
            categories: [],
            transactionAccounts: [],
            periods: []
        }
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Bills";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;
        gridViewOptions.messageContext = "Bill";

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadBillsAsync;

        gridViewOptions.isReadOnly = this.props.isReadOnly;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () =>
        {
            const newRow = {};
            newRow.id = guidUtil.generateGuid();
            newRow.propertyId = currentOrgNode.propertyId;
            return newRow;
        };

        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;

        gridViewOptions.getComponentTitle = (isNewRow, row, customButton) => 
        {
            if (!customButton)
            {
                // Implies bill component
                return isNewRow ? "Bill - New" : "Bill - Edit";
            }

            // Implies expense component, committing bill to expense

            
            

            return `Expense: Committing Bill`;
        }

        gridViewOptions.getComponent = (isNewRow, row, customButton) =>
        {
            // Caution: this method is invoked twice in succession with same row object

            if (!customButton)
            {
                // Implies bill add/edit
                return <Bill data={row} {...this.state} {...this.props} />;
            }

            // Implies commit button selected, row holds the bill to be committed
            // Render bill row as expense row and pass Expense component

            
            
            

            return expenseUtils.getComponent(row, { ...this.state, ...this.props });
        }

        gridViewOptions.validate = (isNewRow, row, customButton) =>
        {
            if (!customButton)
            {
                // Implies bill row being validated
                return this.validateRow(isNewRow, row);
            }

            // Implies expense row being validated
            return expenseUtils.validateRow(isNewRow, row);
        }

        gridViewOptions.save = async (isNewRow, row, remoteData, customButton) =>
        {
            if (!customButton)
            {
                // Implies bill row being saved
                return await this.save(isNewRow, row, remoteData);
            }

            // Implies expense row being saved on account of bill commit
            // Save expense row, and update bill with newNextDueDate

            await expenseUtils.save(row);

            let billRow = remoteData.find(x => x.id == row.billId);
            billRow = typeUtil.deepCloneObject(billRow);
            billRow.nextDueDate = billRow.newNextDueDate;

            return await this.save(isNewRow, billRow, remoteData);
        }

        gridViewOptions.getAttachmentsParam = (isNewRow, row, customButton) =>
        {
            if (!customButton)
            {
                // Implies bill row
                let param = {};
                param.objectType = 'bill';
                param.objectId = row.id;
                return param;
            }

            // Implies expense row
            
            return expenseUtils.getAttachmentsParam(isNewRow, row);
        }

        //#region Custom Buttons

        let customButton;

        //#region Commit button

        customButton = new GridViewButton();
        customButton.ref = "commit";
        customButton.actionType = "component";
        customButton.color = "danger";
        customButton.title = "Commit";
        customButton.onRowSelect = true;

        customButton.rowTransform = (selectedRow, customButton) =>
        {
            //
            // transform bill selectedRow to expense row
            //

            

            
            

            const expenseRow = typeUtil.deepCloneObject(selectedRow);

            expenseRow.billId = selectedRow.id;
            expenseRow.id = guidUtil.generateGuid();
            expenseRow.expenseDate = selectedRow.nextDueDate;
            expenseRow.vat = 0;

            return expenseRow;
        }

        gridViewOptions.buttons.push(customButton);

        //#endregion

        //#region Skip button

        customButton = new GridViewButton();
        customButton.ref = "skip";
        customButton.actionType = "action";
        customButton.color = "warning";
        customButton.title = <span style={{ margin: "0.8rem" }}>Skip</span>;
        customButton.onRowSelect = true;

        customButton.actionConfirmationTitle = "Skip Bill";
        customButton.actionConfirmationMessage = "Do you wish to roll the bill to the next due date without committing ?";

        customButton.onActionWaitMessage = (selectedRow, customButton) => "Updating bill";
        customButton.onActionSuccessMessage = (selectedRow, customButton) => "Bill updated successfully";
        customButton.onActionFailMessage = (selectedRow, customButton, error) => `Failed to update bill due to error: ${error}`;

        customButton.onAction = async (selectedRow, remoteData, customButton) =>
        {
            //
            // Update bill selectedRow nextDueDate to newNextDueDate
            //

            
            

            const isNewRow = false;
            selectedRow.nextDueDate = selectedRow.newNextDueDate;

            const validationError = this.validateRow(isNewRow, selectedRow);
            if (validationError != null) throw validationError;

            return await this.save(isNewRow, selectedRow, remoteData);
        }

        gridViewOptions.buttons.push(customButton);

        //#endregion

        //#endregion

        return <GridView gridViewOptions={gridViewOptions} />;
    }

    async loadBillsAsync()
    {
        try
        {
            if (!this.state.isReferenceDataLoaded)
            {
                const suppliers = await apiLoadFacade.loadSuppliers();
                const categories = await apiLoadFacade.loadAccountCategoriesAndSubCategories();
                const transactionAccounts = await apiLoadFacade.loadTransactionAccounts();

                this.state.suppliers = suppliers;
                this.state.categories = categories;
                this.state.transactionAccounts = transactionAccounts;

                const periods = catalogSelectors.selectLookupData().periods;
                this.state.periods = arraySort(periods, ['sortIndex', 'code']);

                this.state.isReferenceDataLoaded = true;
            }

            const result = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/bills`);
            this.enrichData(result);

            return arraySort(result, stringUtil.localeCompareProp("nextDueDate"));
        }
        catch (error)
        {
            throw error;
        }
    }

    validateRow(isNewRow, row)
    {
        if (stringUtil.isStringNullOrEmpty(row.propertyId))
            return "Property cannot be left empty;"

        if (stringUtil.isStringNullOrEmpty(row.supplierId))
            return "Supplier cannot be left empty;"

        if (stringUtil.isStringNullOrEmpty(row.categoryId))
            return "Category cannot be left empty;"

        if (stringUtil.isStringNullOrEmpty(row.subCategoryId))
            return "Sub Category cannot be left empty;"

        if (stringUtil.isStringNullOrEmpty(row.periodCode))
            return "Bill period cannot be left empty;"

        if (!dateUtil.isValidDate(row.nextDueDate))
            return "Bill due date is not valid";

        if (stringUtil.isStringNullOrEmpty(row.accountId))
            return "Account cannot be left empty;"

        if (!validator.isNumeric(row.amount))
            return "Amount is missing or invalid";

        if (Number(row.amount) <= 0)
            return "Amount is invalid";

        return null;
    }

    async save(isNewRow, row, remoteData)
    {
        try
        {
            const updatedBill = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/bills`, row);
            this.enrichData(updatedBill);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(x => x.id == updatedBill.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedBill);
            else
                remoteData.push(updatedBill);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedBill;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    enrichData = (bills) =>
    {
        const properties = catalogSelectors.selectProperties();

        const suppliers = this.state.suppliers;
        const categories = this.state.categories;
        const transactionAccounts = this.state.transactionAccounts;

        if (!Array.isArray(bills)) bills = [bills];

        bills.forEach(bill => 
        {
            const property = properties.find(x => x.id === bill.propertyId);
            bill.propertyName = property.name;

            const supplier = suppliers.find(x => x.id === bill.supplierId);
            bill.supplierName = supplier.name;

            const category = categories.find(x => x.subCategories.some(s => s.id === bill.subCategoryId));
            bill.categoryId = category.id;
            bill.categoryName = category.name;

            const subCategory = category.subCategories.find(x => x.id === bill.subCategoryId);
            bill.subCategoryName = subCategory.name;

            const transactionAccount = transactionAccounts.find(x => x.id === bill.accountId);
            bill.accountCode = transactionAccount.code;
        });
    }

    constructGridColumnHeaders()
    {
        var headers = [];

        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "nextDueDate";
        header.headerName = "Due Date";
        header.type = dataTypeConstants.dueDate;
        header.width = columnWidthConstants.dueDate;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "propertyName";
        header.headerName = "Property";
        header.width = columnWidthConstants.code;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "categoryName";
        header.headerName = "Category";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "subCategoryName";
        header.headerName = "Sub Category";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "supplierName";
        header.headerName = "Supplier";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "accountCode";
        header.headerName = "Account";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "amount";
        header.headerName = "Amount";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "periodCode";
        header.headerName = "Period";
        header.width = columnWidthConstants.period;
        headers.push(header);

        header = {};
        header.field = "isBillPaidInAdvance";
        header.headerName = "Paid In Advance";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Bills;

