import React, { Component } from 'react';

export default class TemperatureColorRenderer extends Component
{
    render()
    {
        //
        // Highlights temperature cellValue when its not within min/max range for the stockItem
        //

        if(!this.props.value) return null;

        const cellValue = this.props.valueFormatted ? this.props.valueFormatted : this.props.value;        

        const value = this.props.value;
        const minTemperature = this.props.data.minTemperature;
        const maxTemperature = this.props.data.maxTemperature;

        if (value < minTemperature || value > maxTemperature)
        {
            return <span className="text-danger fw-bold">{cellValue}</span>;
        }

        return cellValue;
    }
}