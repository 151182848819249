import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { NetworkDiagramActionTypes, useNetworkDiagramContext } from "../../../store";
import StationConfigurationHeader from "./StationConfigurationHeader/StationConfigurationHeader";
import ChooseStationTypePopup from "./ChooseStationTypePopup/ChooseStationTypePopup";
import StationConfigurationForm from "./StationConfigurationForm/StationConfigurationForm";

const StationConfigurationPopup = ({ data }) => {
     const {
          state: { stations },
          dispatch,
     } = useNetworkDiagramContext();

     const [stationState, setStationState] = useState({
          id: null,
          type: null,
     });

     const changeStationType = useCallback((type) => {
          setStationState((prev) => ({
               ...prev,
               type,
          }));
     }, []);

     const closeStationPopup = useCallback(() => {
          dispatch({
               type: NetworkDiagramActionTypes.CLOSE_STATION_POPUP,
               payload: {
                    id: null,
               },
          });
     }, [dispatch]);

     const handleFormSubmit = useCallback(
          (values) => {
               const { stationName, ...configuration } = values;
               if (stationState.id === null) {
                    // ADD
                    dispatch({
                         type: NetworkDiagramActionTypes.ADD_STATION,
                         payload: {
                              stationName,
                              type: stationState.type,
                              configuration,
                         },
                    });
               } else {
                    // UPDATE
                    dispatch({
                         type: NetworkDiagramActionTypes.UPDATE_STATION,
                         payload: {
                              id: stationState.id,
                              stationName,
                              configuration,
                         },
                    });
               }
               closeStationPopup();
          },
          [dispatch, stationState, closeStationPopup]
     );

     const handleFormCancel = useCallback(() => {
          closeStationPopup();
     }, [closeStationPopup]);

     const handleStationTypeChange = useCallback(
          (type) => {
               changeStationType(type);
          },
          [changeStationType]
     );

     useEffect(() => {
          if (data.id) {
               const editStation = stations.find((station) => station.id === data.id);
               if (editStation) setStationState(editStation);
          } else {
               setStationState({
                    id: null,
                    type: null,
               });
          }
     }, [data, stations]);
     return (
          <Modal isOpen={data.visible}>
               <StationConfigurationHeader state={stationState} onClose={handleFormCancel} />
               {stationState.type === null && stationState.id === null ? <ChooseStationTypePopup onClick={handleStationTypeChange} /> : <StationConfigurationForm stationState={stationState} onSubmit={handleFormSubmit} onCancel={handleFormCancel} />}
          </Modal>
     );
};

export default StationConfigurationPopup;
