import React from 'react'
import InputComponent from '../../../../../../components/form/inputComponent';
import InputStyleComponent from '../../../../../../components/form/inputStyleLabel';

const formatter = require('../../../../../../utils/formatter/formatter');
const payrollRunUtils = require('../../utils/payrollRunUtils');

function EditStaffPayrollWage({ formManager })
{
    const isReadOnly = !payrollRunUtils.isEditingAllowed();

    return <table width="100%">
        <tr>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    value={formatter.convertToAmountFormat(formManager.getValue("plannedWage"))}
                    caption="Planned Wage"
                    hintText="Wages based on planned hours"
                    style={{ marginRight: '20px' }}
                />
            </td>
            <td width="50%">
                <InputStyleComponent inputType="time"
                    value={formatter.roundUp(formManager.getValue("plannedHours"), 2)}
                    caption="Planned Hours"
                    hintText="Hours planned for the staff member as per the rota"
                />
            </td>
        </tr >
        <tr>
            <td width="50%">
                <InputStyleComponent inputType="money"
                    value={formatter.convertToAmountFormat(formManager.getValue("actualWage"))}
                    caption="Actual Wage"
                    hintText="Wages based on actual hours"
                    style={{ marginRight: '20px' }}
                />
            </td>
            <td width="50%">
                <InputStyleComponent inputType="time"
                    value={formatter.roundUp(formManager.getValue("actualHours"), 2)}
                    caption="Actual Hours"
                    hintText="Actual Hours clocked in by the staff as per the attendance"
                />
            </td>
        </tr >


        <tr>
            <td width="50%">
                <InputComponent inputType="money"
                    fieldName="bonus"
                    caption="Bonus"
                    hintText="Bonus paid the staff for the current payroll run"
                    inputReadOnly={isReadOnly}
                    formManager={formManager}
                    style={{ marginRight: '20px' }}
                />
            </td>

            <td width="50%">
                <InputComponent inputType="money"
                    fieldName="advancePayment"
                    caption="Advance Payment"
                    hintText="Advance payment deductions against the current payroll run"
                    inputReadOnly={isReadOnly}
                    formManager={formManager}
                />
            </td>
        </tr >

        <tr>
            <td width="50%">
                <InputComponent inputType="money"
                    fieldName="deduction"
                    caption="Deduction"
                    hintText="Any deductions from the wage for the current payroll run"
                    inputReadOnly={isReadOnly}
                    formManager={formManager}
                    style={{ marginRight: '20px' }}
                />
            </td>
        </tr >
    </table>

}

export default EditStaffPayrollWage;