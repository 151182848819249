import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import purchaseOrderUtil from '../../utils/purchaseOrderUtil';

export default class SupplierAddedOrderItemComponent extends Component
{
    // props = data (supplier added orderItem), others

    get orderItem()
    {
        return this.props.data;
    }

    render()
    {
        return (
            <Card className='h-100'>
                <CardHeader className='text-center'>
                    This is a supplier added order item
                </CardHeader>
                <CardBody style={{ padding: "5px 10px", backgroundColor: purchaseOrderUtil.getOrderItemBackgroundColor(this.orderItem) }}>
                    <div className='h-100 d-flex justify-content-center align-items-center'>
                        {this.orderItem.supplierNotes}
                    </div>
                </CardBody>
            </Card>
        );
    }
}