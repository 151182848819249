
import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import FormManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';
import InputStyleComponent from '../../../../../components/form/inputStyleLabel';

const formatter = require('../../../../../utils/formatter/formatter');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

class EditStorefrontCommissionRun extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        }
        this.state.formManager.view = this;
        this.state.formManager.onFieldChanged = (event) =>
        {
            if (this.props.onChange)
                this.props.onChange();
        }
    }

    render()
    {
        this.state.formManager.viewModel = this.props.data;
        const vatCaption = localizationUtils.getVatCaption();

        return (
            <table className="component-table">
                <tbody>
                    <tr>
                        <td>
                            <InputComponent inputType="percentage"
                                fieldName="commissionRate"
                                caption="Commission Rate"
                                hintText="Commission rate as agreed with the storefront (Required)"
                                formManager={this.state.formManager}
                            />
                        </td>
                        <td>
                            <InputStyleComponent inputType="money"
                                value={formatter.convertToAmountFormat(this.state.formManager.getValue("totalSales"))}
                                caption="Total Sales"
                                hintText="Gross amount of sales"
                            />
                        </td>
                    </tr >
                    <tr>
                        <td>
                            <InputStyleComponent inputType="money"
                                value={formatter.convertToAmountFormat(this.state.formManager.getValue("grossCommissionCharges"))}
                                caption="Gross Commission Charge"
                                hintText="Gross total of commission charges from the sales"
                            />
                        </td>
                        <td>
                            <InputStyleComponent inputType="money"
                                value={formatter.convertToAmountFormat(this.state.formManager.getValue("grossCommissionVAT"))}
                                caption={`Gross Commission ${vatCaption}`}
                                hintText={`Gross total of ${vatCaption} from the sales`}
                            />
                        </td>
                    </tr >
                    <tr>
                        <td>
                            <InputComponent inputType="money"
                                fieldName="additionalCharges"
                                caption="Additional Charge"
                                hintText="Additional charge added by the storefront for the refunds etc"
                                formManager={this.state.formManager}
                            />
                        </td>
                        <td>
                            <InputComponent inputType="money"
                                fieldName="rebate"
                                caption="Rebate"
                                hintText="Any rebate offered by the storefront"
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr >
                    <tr>
                        <td>
                            <InputComponent inputType="money"
                                fieldName="adjustedVAT"
                                caption={`Adjusted ${vatCaption}`}
                                hintText={`Total ${vatCaption} charged by the storefront`}
                                formManager={this.state.formManager}
                            />
                        </td>
                        <td>
                            <InputStyleComponent inputType="money"
                                value={formatter.convertToAmountFormat(this.state.formManager.getValue("netCommissionCharges"))}
                                caption="Net Commission Charges"
                                hintText="Total commission charge after remove removing rebate and adding additional charges"
                            />
                        </td>
                    </tr >
                    <tr>
                        <td>
                            {this.renderHighlight()}
                            <InputStyleComponent inputType="percentage"
                                value={formatter.convertToAmountFormat(this.state.formManager.getValue("netCommissionRate"))}
                                caption="Net Commission Rate"
                                hintText="Commission rate calculates from net commission charge against the total sales"
                            />
                        </td>
                        <td>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    renderHighlight()
    {
        if (this.props.data.flagHighCommissionRate)
            return <Badge color="danger">High</Badge>
        return null;
    }
}

export default EditStorefrontCommissionRun;