const orgStateSelectors = require('../state/stateSelectors/orgStateSelectors');
const stringUtil = require('../string/stringUtil');

module.exports.attributes = {
    retailOrderPrice: "retailOrderPrice",
    averageTicketSize: "averageTicketSize",
    netOrderPrice: "netOrderPrice",
    netOrderPriceIncTax: "netOrderPriceIncTax",
    saleCharges: "saleCharges",
    salePrice: "salePrice",
    salePriceIncTax: "salePriceIncTax",
    netReceipts: "netReceipts",
    netChangeDue: "netChangeDue",
    totalExpenses: "totalExpenses",
    outstandingSales: "outstandingSales",
}

function getNomenclatures()
{
    const nomenclatures = orgStateSelectors.selectCurrentOrgLookupData().nomenclatures;
    if (nomenclatures == null) throw new Error("nomenclatures is missing in org lookupData");
    return nomenclatures;
}

module.exports.getValue = function(code)
{
    const nomenclature = getNomenclatures().find(nomenclature => stringUtil.areStringSame(nomenclature.code, code));

    let caption = code;
    if(nomenclature)
    {
        caption = nomenclature.caption; 
    }

    return caption;
}