const actionTypes = require('../../../actionTypes');
const LookupData = require('./lookupData');

//
// Note: Organization lookupData is loaded by orgState
//

const orgStateError = "Target orgState is required for org lookupAction";

module.exports.createAction = function (orgState, lookupData)
{
    if (orgState == null) throw orgStateError;

    return (
        {
            orgState: orgState,
            type: actionTypes.orgLookupData,
            payload: Object.assign(new LookupData(), lookupData)
        }
    );
}

module.exports.changeState = function (orgState, previousState, payload)
{
    // payload = lookupData

    if (orgState == null) throw orgStateError;

    orgState.lookupData = payload;
    previousState.orgStates.set(orgState.franchisorId, orgState);

    return Object.assign({}, previousState);
}

// Update actions

module.exports.createUpdateAction = function (orgState, lookupName, lookupValues)
{
    if (orgState == null) throw orgStateError;

    const lookupItem = {};
    lookupItem[lookupName] = lookupValues;

    const lookupData = Object.assign({}, orgState.lookupData, lookupItem);

    return this.createAction(orgState, lookupData);
}

