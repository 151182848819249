import TableData, { ColumnDefinition, TableRow, tableConstants } from '../../../../../../components/tableView/TableData/TableData';

const arraySort = require('array-sort');

const rmsApiProxy = require('../../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../../utils/dateUtil/dateUtil');
const formatter = require('../../../../../../utils/formatter/formatter');
const domainConstants = require('../../../../../../utils/domain/constants');
const reportViewUtils = require('../../../../../../views/reports/reportUtils/helperUtils/viewUtils');
const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const localizationUtils = require('../../../../../../utils/domain/localizationUtils');
const saleLoaderUtils = require('../../../../../../utils/domain/sale/saleLoaderUtil');
const snowflakeSaleAggregatorUtils = require('../../../../../../utils/domain/sale/snowflakeSaleAggregatorUtils');

const categorySalesUtil = require('./categorySalesUtil');

const { columnAlignments } = tableConstants;

const numericThresholdValue = domainConstants.numericThresholdValue;

export const availableFields = {
    salesSummary: "salesSummary",
    salesByVenueCode: "salesByVenueCode",
    salesByDispatchType: "salesByDispatchType",
    salesByPaymentMethod: "salesByPaymentMethod",
    salesByMenuCategory: "salesByMenuCategory",
    salesByCovers: "salesByCovers",
    saleDiscounts: "saleDiscounts",
    saleCharges: "saleCharges",
    saleRefunds: "saleRefunds",
    saleCancellations: "saleCancellations",
    saleVats: "saleVats",
    floatSession: "floatSession",
    floatSessionJournal: "floatSessionJournal",
    saleDeletedItems: "saleDeletedItems",
}

export async function loadReportData(searchCriteria)
{
    const currentDate = searchCriteria.startDate;

    const promises = [
        saleLoaderUtils.loadSales(currentDate, currentDate),
        rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/floats?fromDate=${dateUtil.subtractDays(currentDate, 1)}&toDate=${dateUtil.addDays(currentDate, 1)}`)
    ];

    const [currentSales, floatSessions] = await Promise.all(promises);

    // Enrich azure transaction sale with snowflake sale schema
    const franchisorOrgNode = { franchisorId: currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId };
    const operativeVatBands = localizationUtils.getOperativeVatBands(franchisorOrgNode);
    currentSales.forEach(sale => snowflakeSaleAggregatorUtils.populateAggregatedSaleFields(sale, operativeVatBands));

    const currentFloatSessions = floatSessions.filter(floatSession => currentSales.some(sale => sale.floatSessionId === floatSession.id));

    arraySort(currentFloatSessions, "startDateTime");

    return { currentSales, currentFloatSessions };
}

export async function getTemplateElements()
{
    const templateType = domainConstants.printingTemplateTypes.dailySummaryReport;
    const printingTemplates = await rmsApiProxy.get(`${rmsApiProxy.getFranchisorOrgContextUrl()}/templates/printing/${templateType}`);

    if (printingTemplates.length === 0) return null;

    let template = null;
    template = printingTemplates.find(template => !template.isDisabled);    // template.isDefault is not always populated ?
    if (!template) template = printingTemplates[0];

    const templateJson = JSON.parse(template.json);

    return templateJson.elements;
}

export function getSalesSummaryTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Sales Summary";

    let totalSalesCount = 0;
    let retailPrice = 0;
    let totalDiscounts = 0;
    let totalSaleRefunds = 0;
    let netRetailPrice = 0;
    let totalRetailChargesAfterRefund = 0;
    let netSalePrice = 0;
    let netSaleTax = 0;
    let netSalePriceIncTax = 0;
    let paidAmount = 0;
    let netPaidAmount = 0;
    let balance = 0;
    let averageTicketSize = 0;

    let netCashReceipts = 0;
    let netCreditReceipts = 0;
    let netVenuePaidReceipts = 0;
    let netOtherReceipts = 0;

    let outstandingSalesCount = 0;
    let outstandingSalesAmount = 0;

    sales.forEach(sale => 
    {
        totalSalesCount++;

        retailPrice += sale.retailPrice;
        totalDiscounts += sale.discount;                // vat adjusted discount
        totalSaleRefunds += sale.saleRefund;            // vat adjusted refund
        netRetailPrice += sale.netRetailPrice;
        totalRetailChargesAfterRefund += (sale.charge - sale.chargeRefund);     // refund adjusted retail charges
        netSalePrice += sale.netSalePrice;
        netSaleTax += sale.netSaleTax;
        netSalePriceIncTax += sale.netSalePriceIncTax;

        paidAmount += sale.paidAmount;
        netPaidAmount += sale.netPaidAmount;
        balance += sale.balance;

        netCashReceipts += sale.netCashReceipts;
        netCreditReceipts += sale.netCreditReceipts;
        netVenuePaidReceipts += sale.netVenuePaidReceipts;
        netOtherReceipts += sale.netOtherReceipts;

        if (sale.balance > numericThresholdValue)
        {
            outstandingSalesCount++;
            outstandingSalesAmount += sale.balance;
        }
    });

    if (totalSalesCount > 0) 
    {
        averageTicketSize = netSalePriceIncTax / totalSalesCount;
    }

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(75));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("salesSummary", title, ...columnDefinitions);

    let tableRow;

    tableData.addDataRow("Sales Count", totalSalesCount.toString());
    tableRow = tableData.addDataRow("Average Ticket Size", formatToAmount(averageTicketSize));
    tableRow.afterBlankRow = true;

    tableData.addDataRow("Retail Price", formatToAmount(retailPrice));
    tableData.addDataRow("Discount *", formatToAmount(totalDiscounts));
    tableData.addDataRow("Refund **", formatToAmount(totalSaleRefunds));
    tableData.addDataRow("Net Retail Price", formatToAmount(netRetailPrice));
    tableData.addDataRow("Net Charges †", formatToAmount(totalRetailChargesAfterRefund));
    tableData.addDataRow("Sale Price", formatToAmount(netSalePrice));
    tableData.addDataRow("Tax ‡", formatToAmount(netSaleTax));

    tableRow = tableData.addDataRow("Sale Price Inclusive of Tax", formatToAmount(netSalePriceIncTax));
    tableRow.afterBlankRow = true;
    tableRow.columns[0].isBold = true;
    tableRow.columns[1].isBold = true;

    tableData.addDataRow("Paid Amount", formatToAmount(paidAmount));
    tableData.addDataRow("Net Paid Amount", formatToAmount(netPaidAmount));

    tableRow = tableData.addDataRow("Balance", formatToAmount(balance));
    tableRow.afterBlankRow = true;
    tableRow.columns[0].isBold = true;
    tableRow.columns[1].isBold = true;

    tableData.addDataRow("Net Cash Receipts", formatToAmount(netCashReceipts));
    tableData.addDataRow("Net Credit Receipts", formatToAmount(netCreditReceipts));
    tableData.addDataRow("Net VenuePaid Receipts", formatToAmount(netVenuePaidReceipts));
    tableRow = tableData.addDataRow("Net Other Receipts", formatToAmount(netOtherReceipts));
    tableRow.afterBlankRow = true;

    tableData.addDataRow("Unpaid Sales Count", outstandingSalesCount.toString());
    tableData.addDataRow("Unpaid Sales Amount", formatToAmount(outstandingSalesAmount));

    tableData.footNotes = [
        "* Discount less discount tax rebate",
        "** Refund less refund tax rebate (refund against sale amount)",
        "† Charges less charges refund (refund against charges)",
        "‡ Retail tax plus Charges tax less all applicable tax rebates accruing from discount, sale refund and charges refund",
    ];

    return tableData;
}

export function getSalesByVenueCodeTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Venue Sales";

    const venueSales = [];

    sales.forEach(sale =>
    {
        let venueSale = venueSales.find(x => x.venueCode === sale.venueCode);

        if (venueSale == null) 
        {
            venueSale = { venueCode: sale.venueCode, count: 0, amount: 0.0 };
            venueSales.push(venueSale);
        }

        venueSale.count += 1;
        venueSale.amount += sale.netSalePriceIncTax;
    });

    const totalCount = venueSales.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = venueSales.reduce((previous, current) => previous + current.amount, 0);

    arraySort(venueSales, "venueCode");

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("salesByVenueCode", title, ...columnDefinitions);

    tableData.setHeaderRow("Venue", "Count", "Amount");
    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount));

    venueSales.forEach(sale => tableData.addDataRow(sale.venueCode, sale.count.toString(), formatToAmount(sale.amount)));

    return tableData;
}

export function getSalesByDispatchTypeTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Dispatch Sales";

    const dispatchSales = [];

    sales.forEach(sale =>
    {
        let dispatchSale = dispatchSales.find(x => x.dispatchType === sale.dispatchType);

        if (dispatchSale == null) 
        {
            dispatchSale = { dispatchType: sale.dispatchType, count: 0, amount: 0.0 };
            dispatchSales.push(dispatchSale);
        }

        dispatchSale.count += 1;
        dispatchSale.amount += sale.netSalePriceIncTax;
    });

    const totalCount = dispatchSales.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = dispatchSales.reduce((previous, current) => previous + current.amount, 0);

    arraySort(dispatchSales, "dispatchType");

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("salesByDispatchType", title, ...columnDefinitions);

    tableData.setHeaderRow("Dispatch Type", "Count", "Amount");
    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount));

    dispatchSales.forEach(sale => tableData.addDataRow(sale.dispatchType, sale.count.toString(), formatToAmount(sale.amount)));

    return tableData;
}

export function getSalesByPaymentMethodTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Sale Receipts Summary";

    const receiptLineTypes = domainConstants.ReceiptLineTypes;

    const saleReceipts = [];      // saleReceipts by paymentMethod

    sales.forEach(sale =>
    {
        const receiptLines = sale.receiptLines;

        if (receiptLines.length > 0)
        {
            receiptLines.forEach(line => 
            {
                const paymentMethod = reportViewUtils.getPaymentMethodForDisplay(line.paymentMethod, sale.venueCode);

                let saleReceipt = saleReceipts.find(x => x.paymentMethod === paymentMethod);

                if (saleReceipt == null) 
                {
                    saleReceipt = { paymentMethod: paymentMethod, chargeAmount: 0.0, refundAmount: 0.0 };
                    saleReceipts.push(saleReceipt);
                }

                switch (line.type) 
                {
                    case receiptLineTypes.Charge:
                        saleReceipt.chargeAmount += line.amount;
                        break;

                    case receiptLineTypes.Refund:
                        saleReceipt.refundAmount += line.amount;
                        break;

                    default:
                        break;
                }
            });
        }

        saleReceipts.forEach(saleReceipt => saleReceipt.netAmount = saleReceipt.chargeAmount - saleReceipt.refundAmount);
    });

    arraySort(saleReceipts, "paymentMethod");

    const totalChargeAmount = saleReceipts.reduce((previous, current) => previous + current.chargeAmount, 0);
    const totalRefundAmount = saleReceipts.reduce((previous, current) => previous + current.refundAmount, 0);
    const totalNetAmount = saleReceipts.reduce((previous, current) => previous + current.netAmount, 0);

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(46));
    columnDefinitions.push(new ColumnDefinition(18, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(18, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(18, columnAlignments.alignRight));

    const tableData = new TableData("salesByPaymentMethod", title, ...columnDefinitions);

    tableData.setHeaderRow("Payment Method", "Receipts", "Refunds", "Net Receipts");
    tableData.setFooterRow("Total", formatToAmount(totalChargeAmount), formatToAmount(totalRefundAmount), formatToAmount(totalNetAmount));

    saleReceipts.forEach(row => tableData.addDataRow(row.paymentMethod, formatToAmount(row.chargeAmount), formatToAmount(row.refundAmount), formatToAmount(row.netAmount)));

    return tableData;
}

export function getSalesByCoversTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Covers Sales Summary";

    sales = sales.filter(sale => sale.dispatchType === domainConstants.dispatchTypes.dineIn && sale.noOfGuests > 0);

    const totalSalePriceIncTax = sales.reduce((previous, current) => previous + current.netSalePriceIncTax, 0);
    const totalNumberOfGuests = sales.reduce((previous, current) => previous + current.noOfGuests, 0);

    const averageRevenuePerCover = totalNumberOfGuests > 0 ? (totalSalePriceIncTax / totalNumberOfGuests) : 0;

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(75));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("salesByCovers", title, ...columnDefinitions);

    tableData.addDataRow("Total number of covers", totalNumberOfGuests.toString());
    tableData.addDataRow("Average revenue per cover", formatToAmount(averageRevenuePerCover));

    return tableData;
}

export function getSaleDiscountsTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Gross Sale Discounts";

    const saleDiscounts = [];

    sales.forEach(sale =>
    {
        if (sale.discounts.length === 0) return;

        sale.discounts.forEach(discount =>
        {
            let saleDiscount = saleDiscounts.find(x => x.type === discount.type);

            if (saleDiscount == null) 
            {
                saleDiscount = { type: discount.type, count: 0, amount: 0.0 };
                saleDiscounts.push(saleDiscount);
            }

            saleDiscount.count += 1;
            saleDiscount.amount += discount.amount;
        });
    });

    const totalCount = saleDiscounts.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = saleDiscounts.reduce((previous, current) => previous + current.amount, 0);

    arraySort(saleDiscounts, "type");

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("saleDiscounts", title, ...columnDefinitions);

    tableData.setHeaderRow("Discount Type", "Count", "Amount");
    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount));

    saleDiscounts.forEach(sale => tableData.addDataRow(sale.type, sale.count.toString(), formatToAmount(sale.amount)));

    return tableData;
}

export function getSaleChargesTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Sale Charges";

    const saleCharges = [];

    sales.forEach(sale =>
    {
        if (sale.charges.length === 0) return;

        sale.charges.forEach(charge =>
        {
            let saleCharge = saleCharges.find(x => x.scheme === charge.scheme);

            if (saleCharge == null) 
            {
                saleCharge = { scheme: charge.scheme, count: 0, amount: 0.0, tax: 0.0 };
                saleCharges.push(saleCharge);
            }

            saleCharge.count += 1;
            saleCharge.amount += charge.retailAmount - charge.refund;
            saleCharge.tax += charge.tax - charge.refundTax;
        });
    });

    const totalCount = saleCharges.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = saleCharges.reduce((previous, current) => previous + current.amount, 0);
    const totalTax = saleCharges.reduce((previous, current) => previous + current.tax, 0);

    arraySort(saleCharges, "scheme");

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(40));
    columnDefinitions.push(new ColumnDefinition(10, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("saleCharges", title, ...columnDefinitions);

    tableData.setHeaderRow("Scheme", "Count", "Amount", "Tax");
    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount), formatToAmount(totalTax));

    saleCharges.forEach(sale => tableData.addDataRow(sale.scheme, sale.count.toString(), formatToAmount(sale.amount), formatToAmount(sale.tax)));

    return tableData;
}

export function getSaleRefundsTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Gross Sale Refunds";

    const saleRefunds = [];

    sales.forEach(sale =>
    {
        if (!sale.refunds || sale.refunds.length === 0) return;

        sale.refunds.forEach(refund => 
        {
            let saleRefund = saleRefunds.find(x => x.type === refund.type);

            if (saleRefund == null) 
            {
                saleRefund = { type: refund.type, count: 0, amount: 0.0 };
                saleRefunds.push(saleRefund);
            }

            saleRefund.count += 1;
            saleRefund.amount += refund.amount;
        });
    });

    const totalCount = saleRefunds.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = saleRefunds.reduce((previous, current) => previous + current.amount, 0);

    arraySort(saleRefunds, "type");

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("saleRefunds", title, ...columnDefinitions);

    tableData.setHeaderRow("Refund Type", "Count", "Amount");
    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount));

    saleRefunds.forEach(refund => tableData.addDataRow(refund.type, refund.count.toString(), formatToAmount(refund.amount)));

    return tableData;
}

export function getSaleCancellationTableData(cancelledSales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Sale Cancellations";

    const saleCancellations = [];

    cancelledSales.forEach(sale =>
    {
        if (!sale.isCancelled) return;

        const cancellationReason = sale.cancellationReason || "N/A";

        let cancellation = saleCancellations.find(x => x.cancellationReason === cancellationReason);

        if (cancellation == null) 
        {
            cancellation = { cancellationReason: cancellationReason, count: 0, amount: 0.0 };
            saleCancellations.push(cancellation);
        }

        cancellation.count++;
        cancellation.amount += sale.netSalePriceIncTax;
    });

    const totalCount = saleCancellations.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = saleCancellations.reduce((previous, current) => previous + current.amount, 0);

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("saleCancellations", title, ...columnDefinitions);

    tableData.setHeaderRow("Cancellation Code", "Count", "Amount");
    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount));

    saleCancellations.forEach(cancellation => tableData.addDataRow(
        cancellation.cancellationReason, cancellation.count.toString(), formatToAmount(cancellation.amount)));

    return tableData;
}

export function getSaleVatTableData(sales, templateElement)
{
    // Overwrite title from template
    const title = templateElement.title || "Sales Tax";

    const saleVatPercentages = [];

    sales.forEach(sale =>
    {
        (function getSaleItemsVat(saleItems)
        {
            for (const saleItem of saleItems) 
            {
                const saleItemVatData = getSaleItemVatData(saleItem);

                if (saleItemVatData != null)
                {
                    let saleVatPercentage = saleVatPercentages.find(x => x.vatPercentage === saleItemVatData.vatPercentage);

                    if (saleVatPercentage == null)
                    {
                        saleVatPercentage = { vatPercentage: saleItemVatData.vatPercentage, count: 0, saleAmount: 0, vatAmount: 0 }
                        saleVatPercentages.push(saleVatPercentage);
                    }

                    saleVatPercentage.count += saleItemVatData.quantity;
                    saleVatPercentage.saleAmount += saleItemVatData.saleAmount;
                    saleVatPercentage.vatAmount += saleItemVatData.vatAmount;
                }

                if (saleItem.saleItems.length > 0) 
                {
                    getSaleItemsVat(saleItem.saleItems);    // recursion
                }
            }
        })(sale.saleItems); // IIF
    });

    const totalCount = saleVatPercentages.reduce((previous, current) => previous + current.count, 0);
    const totalSaleAmount = saleVatPercentages.reduce((previous, current) => previous + current.saleAmount, 0);
    const totalVatAmount = saleVatPercentages.reduce((previous, current) => previous + current.vatAmount, 0);

    arraySort(saleVatPercentages, (p1, p2) =>
    {
        // Sort by numeric value of vatPercentage in ascending order,
        // "Others" if exists to be the last item

        const v1 = parseFloat(p1.vatPercentage);
        if (isNaN(v1)) return 1;

        const v2 = parseFloat(p2.vatPercentage);
        if (isNaN(v2)) return -1;

        return v1 - v2;
    });

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(20));
    columnDefinitions.push(new ColumnDefinition(20, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(40, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(20, columnAlignments.alignRight));

    const tableData = new TableData("saleVats", title, ...columnDefinitions);

    tableData.setHeaderRow("Tax %", "Count", "Sale", "Tax");
    tableData.setFooterRow(
        "Total",
        totalCount.toString(),
        formatToAmount(totalSaleAmount),
        formatToAmount(totalVatAmount));

    saleVatPercentages.forEach(sale =>
        tableData.addDataRow(
            sale.vatPercentage,
            sale.count.toString(),
            formatToAmount(sale.saleAmount),
            formatToAmount(sale.vatAmount)));

    return tableData;
}

export function getSalesByMenuCategoryTableData(sales, templateElement)
{
    const categorySales = categorySalesUtil.getCategorySales(sales);

    return mapToMenuCategoryTableData(categorySales, templateElement);
}

export function mapToMenuCategoryTableData(categorySales, templateElement)
{
    
    // Overwrite title from template
    const title = templateElement.title || "Menu Categories Summary";

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("salesByMenuCategory", title, ...columnDefinitions);

    tableData.setHeaderRow("Menu Category", "Count", "Amount");

    const totalCount = categorySales.reduce((previous, current) => previous + current.count, 0);
    const totalAmount = categorySales.reduce((previous, current) => previous + current.amount, 0);

    tableData.setFooterRow("Total", totalCount.toString(), formatToAmount(totalAmount));

    categorySales.forEach(sale => tableData.addDataRow(sale.category, sale.count.toString(), formatToAmount(sale.amount)));

    return tableData;
}


export function getDeletedItemsTableData(sales, templateElement)
{
    const title = templateElement.title || "Sale Cancelled Items";

    sales = Array.from(sales);
    arraySort(sales, "number");

    const saleDeletedItems = [];

    sales.forEach(sale =>
    {
        sale.deletedSaleItems.forEach(deletedSaleItem => 
        {
            const subItemCaption = getCaption(deletedSaleItem.saleItems, 1);
            const saleDeletedItem = {
                saleNumber: sale.number,
                Item: deletedSaleItem.caption + (stringUtil.isStringNullOrEmpty(subItemCaption) ? "" : ("\n" + subItemCaption)),
                quantity: deletedSaleItem.quantity,
                cancellationStaff: deletedSaleItem.cancellationStaff,
                cancellationReason: deletedSaleItem.cancellationReason,
                amount: (deletedSaleItem.unitPrice * deletedSaleItem.quantity) + getAmount(deletedSaleItem.saleItems)
            }

            saleDeletedItems.push(saleDeletedItem);
        });

        function getCaption(saleItems, level)
        {
            let caption = "";
            for (const deletedSaleItem of saleItems)
            {
                if (stringUtil.isStringNullOrEmpty(caption))
                    caption = `${getIndentation(level)} ${deletedSaleItem.caption}`;
                else
                    caption = `${caption}\n${getIndentation(level)} ${deletedSaleItem.caption}`;

                const subItemCaption = getCaption(deletedSaleItem.saleItems, level + 1);
                if (!stringUtil.isStringNullOrEmpty(subItemCaption))
                    caption += `\n${subItemCaption}`;
            }

            return caption;
        }

        function getAmount(saleItems)
        {
            let amount = 0;
            for (const deletedSaleItem of saleItems)
            {
                amount += deletedSaleItem.unitPrice * deletedSaleItem.quantity;
                amount += getAmount(deletedSaleItem.saleItems);
            }

            return amount;
        }

        function getIndentation(level)
        {
            let indent = "";
            for (let index = 0; index < level; index++) indent += "+";
            return indent;
        }
    });

    const totalAmount = saleDeletedItems.reduce((previous, current) => previous + current.amount, 0);

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(15));
    columnDefinitions.push(new ColumnDefinition(20, columnAlignments.alignLeft));
    columnDefinitions.push(new ColumnDefinition(15, columnAlignments.alignRight));
    columnDefinitions.push(new ColumnDefinition(20, columnAlignments.alignLeft));
    columnDefinitions.push(new ColumnDefinition(15, columnAlignments.alignLeft));
    columnDefinitions.push(new ColumnDefinition(15, columnAlignments.alignRight));

    const tableData = new TableData("saleDeletedItems", title, ...columnDefinitions);
    tableData.setHeaderRow("Sale", "Item", "Quantity", "Cancellation Reason", "Staff", "Amount");
    tableData.setFooterRow(null, null, null, null, "Total", formatToAmount(totalAmount));

    saleDeletedItems.forEach(deletedSaleItem => tableData.addDataRow(deletedSaleItem.saleNumber, deletedSaleItem.Item, deletedSaleItem.quantity, deletedSaleItem.cancellationReason, deletedSaleItem.cancellationStaff, formatToAmount(deletedSaleItem.amount)));

    return tableData;
}

export function getFloatSessionTableData(floatSession, index, allSales, templateElement, staffData)
{
    let cashIn, cashOut;

    const dateTimeFormat = "dddd, hh:mm a";
    const entryType = domainConstants.floatManagementJournalEntryType;
    const entrySubType = domainConstants.floatManagementJournalEntrySubType;

    const sales = allSales.filter(sale => sale.floatSessionId === floatSession.id);

    const startDateTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(floatSession.startDateTime), dateTimeFormat);
    const startedByStaff = getStaff(floatSession.startedBy, staffData);

    const endDateTime = floatSession.endDateTime && dateUtil.formatDate(dateUtil.convertToLocalStandard(floatSession.endDateTime), dateTimeFormat);
    const endedByStaff = floatSession.endedBy && getStaff(floatSession.endedBy, staffData);

    const openingCash = floatSession.journals
        .filter(journal => journal.type === entryType.Deposit && journal.subType === entrySubType.OpeningBalance && !journal.isVoided)
        .reduce((previous, current) => previous + current["amount"], 0);

    const cashExpense = floatSession.journals
        .filter(journal => journal.type === entryType.Withdraw && journal.subType === entrySubType.Expense && !journal.isVoided)
        .reduce((previous, current) => previous + current["amount"], 0);

    const cashSales = sales.reduce((previous, current) => previous + current.cashReceipts, 0);
    const cashRefunds = sales.reduce((previous, current) => previous + current.cashRefunds, 0);

    // Net cash transfer
    cashOut = floatSession.journals
        .filter(journal => journal.type === entryType.Withdraw && journal.subType === entrySubType.TransferToSafe && !journal.isVoided)
        .reduce((previous, current) => previous + current["amount"], 0);

    cashIn = floatSession.journals
        .filter(journal => journal.type === entryType.Deposit && journal.subType === entrySubType.TransferFromSafe && !journal.isVoided)
        .reduce((previous, current) => previous + current["amount"], 0);

    // cashOut perspective
    const netCashTransfer = cashOut - cashIn;

    // Net cash driver change (cashOut perspective)
    cashOut = floatSession.journals
        .filter(journal => journal.type === entryType.Withdraw && journal.subType === entrySubType.ChangeGiven && !journal.isVoided)
        .reduce((previous, current) => previous + current["amount"], 0);

    cashIn = floatSession.journals
        .filter(journal => journal.type === entryType.Deposit && journal.subType === entrySubType.ChangeReturned && !journal.isVoided)
        .reduce((previous, current) => previous + current["amount"], 0);

    // cashOut perspective
    const netCashChange = cashOut - cashIn;

    const cashBalance = openingCash + cashSales - cashRefunds - cashExpense - netCashTransfer - netCashChange;
    const closingBalanceDiscrepancy = floatSession.closingBalance - cashBalance;

    // Draw table data
    // Overwrite title from template

    const floatIdentifier = floatSession.floatIdentifier ? `(Float ID: ${floatSession.floatIdentifier}) ` : "";
    const title = `${templateElement.title || "Float Session"}: ${floatIdentifier} ${startDateTime}`;

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(70));
    columnDefinitions.push(new ColumnDefinition(30, columnAlignments.alignRight));

    const tableData = new TableData("floatSession", title, ...columnDefinitions);

    tableData.addDataRow("Started at", startDateTime);
    tableData.addDataRow("Started by", getStaffFullName(startedByStaff));

    tableData.addDataRow("Closed at", endDateTime || "---");
    tableData.addDataRow("Closed by", endedByStaff ? getStaffFullName(endedByStaff) : "---");

    const openingCashRow = tableData.addDataRow("Opening Cash", formatToAmount(openingCash));
    if (floatSession.openingContext != null && floatSession.openingContext.cashCountEntries && floatSession.openingContext.cashCountEntries.length > 0)
    {
        openingCashRow.childRows = getChildRowsForFloatSession(floatSession.openingContext, columnDefinitions);
    }

    tableData.addDataRow("Cash Expenses", formatToAmount(cashExpense));
    tableData.addDataRow("Cash Transfers *", formatToAmount(netCashTransfer));
    tableData.addDataRow("Cash Changed *", formatToAmount(netCashChange));
    tableData.addDataRow("Cash Sales **", formatToAmount(cashSales));
    tableData.addDataRow("Cash Refunds", formatToAmount(cashRefunds));

    
    if(floatSession.closingBalance != null)
    {
        const closingCashRow = tableData.addDataRow("Closing Cash", formatToAmount(floatSession.closingBalance));
        if (floatSession.closingContext != null && floatSession.closingContext.cashCountEntries && floatSession.closingContext.cashCountEntries.length > 0)
        {
            closingCashRow.childRows = getChildRowsForFloatSession(floatSession.closingContext, columnDefinitions);
        }
    }
    tableData.addDataRow("Estimated Closing Balance", formatToAmount(cashBalance));
    if(floatSession.closingBalance != null)
    {
        const closingBalanceDiscrepancy = floatSession.closingBalance - cashBalance;
        tableData.setFooterRow("Discrepancy in Closing Balance", formatToAmount(closingBalanceDiscrepancy));
    }

    tableData.footNotes = [
        "* Read from net cash out perspective",
        "** Only sales belonging to the selected date are included in the float session"
    ];

    return tableData;
}

export function getFloatSessionJournalTableData(floatSession, index, allSales, templateElement, staffData, categoriesData)
{
    let tableRow, totalAmount;

    const entrySubType = domainConstants.floatManagementJournalEntrySubType;

    const journals = floatSession.journals.filter(entry => !entry.isVoided);

    // transferEntries
    const transferEntries = { deposits: 0, withdrawals: 0 };

    transferEntries.deposits += journals
        .filter(entry => entry.subType === entrySubType.TransferFromSafe)
        .reduce((previous, current) => previous + current.amount, 0);

    transferEntries.withdrawals += journals
        .filter(entry => entry.subType === entrySubType.TransferToSafe)
        .reduce((previous, current) => previous + current.amount, 0);

    // driverEntries
    const driverEntries = [];

    journals
        .filter(entry => entry.subType === entrySubType.ChangeGiven || entry.subType === entrySubType.ChangeReturned)
        .forEach(entry => 
        {
            const driverId = entry.subTypeReferenceId;
            const amount = entry.subType === entrySubType.ChangeGiven ? entry.amount : -entry.amount;

            let driverEntry = driverEntries.find(entry => entry.driverId === driverId);
            if (driverEntry == null) 
            {
                driverEntry = { driverId: driverId, amount: 0.0 };
                driverEntries.push(driverEntry);
            }

            driverEntry.amount += amount;
        });

    // expenseEntries
    const expenseEntries = [];

    journals
        .filter(entry => entry.subType === entrySubType.Expense)
        .forEach(entry => 
        {
            const creationDateTime = entry.creationDateTime;
            const createdByStaffId = entry.createdByStaffId;
            const subCategoryId = entry.subTypeReferenceId;
            const amount = entry.amount;

            expenseEntries.push({ creationDateTime, createdByStaffId, subCategoryId, amount });
        });

    // Draw table data
    // Overwrite title from template

    const dateTimeFormat = "dddd, hh:mm a";
    const startDateTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(floatSession.startDateTime), dateTimeFormat);

    const floatIdentifier = floatSession.floatIdentifier ? `(Float ID: ${floatSession.floatIdentifier}) ` : "";
    const title = `${templateElement.title || "Float Session Journal"}: ${floatIdentifier} ${startDateTime}`;

    const columnDefinitions = [];
    columnDefinitions.push(new ColumnDefinition(25));
    columnDefinitions.push(new ColumnDefinition(50));
    columnDefinitions.push(new ColumnDefinition(25, columnAlignments.alignRight));

    const tableData = new TableData("floatSessionJournal", title, ...columnDefinitions);

    // Expense entries

    if (expenseEntries.length > 0) 
    {
        totalAmount = 0;

        expenseEntries.forEach(entry => 
        {
            const createdAt = dateUtil.formatDate(dateUtil.convertToLocalStandard(entry.creationDateTime), "hh:mm a");
            const createdBy = getStaffFullName(getStaff(entry.createdByStaffId, staffData));
            const subCategory = getAccountSubCategory(entry.subCategoryId, categoriesData) || 'N/A';

            totalAmount += entry.amount;

            tableData.addDataRow("Expense", `${subCategory.name}\nBy:${createdBy} @${createdAt}`, formatToAmount(entry.amount));
        });

        tableRow = tableData.addDataRow("", "Total", formatToAmount(totalAmount));
        tableRow.afterBlankRow = true;
        tableRow.columns[1].isBold = true;
        tableRow.columns[1].alignment = tableConstants.columnAlignments.alignRight;
        tableRow.columns[2].isBold = true;
    }

    // Driver entries

    if (driverEntries.length > 0) 
    {
        totalAmount = 0;

        driverEntries.forEach(entry => 
        {
            const driverName = getStaffFullName(getStaff(entry.driverId, staffData));

            totalAmount += entry.amount;

            tableData.addDataRow("Driver", driverName, formatToAmount(entry.amount));
        });

        tableRow = tableData.addDataRow("", "Total", formatToAmount(totalAmount));
        tableRow.afterBlankRow = true;
        tableRow.columns[1].isBold = true;
        tableRow.columns[1].alignment = tableConstants.columnAlignments.alignRight;
        tableRow.columns[2].isBold = true;
    }

    // Transfer entries

    tableData.addDataRow("Deposits", "Till Deposits", formatToAmount(transferEntries.deposits));
    tableData.addDataRow("Withdrawals", "Till Withdrawals", formatToAmount(transferEntries.withdrawals));

    totalAmount = transferEntries.withdrawals - transferEntries.deposits;

    tableRow = tableData.addDataRow("", "Total", formatToAmount(totalAmount));
    tableRow.columns[1].isBold = true;
    tableRow.columns[1].alignment = tableConstants.columnAlignments.alignRight;
    tableRow.columns[2].isBold = true;

    return tableData;
}

//
// Private helpers
//

function formatToAmount(value) { return formatter.formatToAmount(value); }

function getSaleItemVatData(saleItem)
{
    if (saleItem.unitPrice === 0 && saleItem.unitTax === 0)
    {
        return null;
    }

    if (saleItem.unitPrice === 0 && saleItem.unitTax > 0) 
    {
        return {
            vatPercentage: "Others",
            quantity: saleItem.quantity,
            saleAmount: saleItem.netRetailPrice,
            vatAmount: saleItem.netRetailTax
        };
    }

    return {
        vatPercentage: `${saleItem.taxPercentage.toFixed(2)} %`,
        quantity: saleItem.quantity,
        saleAmount: saleItem.netRetailPrice,
        vatAmount: saleItem.netRetailTax,
    };
}

function getStaff(staffId, staffData)
{
    return staffData.find(staff => staff.id === staffId);
}

function getStaffFullName(staff) 
{
    return staff == null
        ? 'N/A'
        : `${staff.firstName} ${staff.lastName}`;

}

function getAccountSubCategory(subCategoryId, categoriesData)
{
    const accountCategory = categoriesData.find(category => category.subCategories.some(subCategory => subCategory.id === subCategoryId));

    if (accountCategory != null)
    {
        return accountCategory.subCategories.find(subCategory => subCategory.id === subCategoryId);
    }

    return null;
}

function getChildRowsForFloatSession(sessionContext, columnDefinitions)
{
    const filteredEntries = sessionContext.cashCountEntries.filter(entry => entry.quantity > 0);
    const formattedSessionContext = filteredEntries.map(({ denomination, quantity, amount }) => {
        const formattedAmount = `${formatter.convertToCurrencyFormat(amount)}`;
        return { denomination, quantity, formattedAmount};
    });
    
    const contextRow = new TableRow(columnDefinitions);
    contextRow.setColumnValues("", formattedSessionContext);

    return [contextRow];
}