import React from 'react';

import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';
import CheckComponent from '../../../../components/form/checkComponent';

import FormManager from '../../../../utils/view/formManager';

const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');

export default function ApplianceOverview(props) 
{
    const applianceTypes = catalogSelectors.selectLookupData().applianceTypes;

    const formManager = new FormManager();

    formManager.viewModel = props.data;

    formManager.onFieldChanged = (event) =>
    {
        if (props.onFieldChanged) props.onFieldChanged();
    }

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <SelectComponent
                            caption="Appliance Type"
                            fieldName="applianceTypeCode"
                            optionFieldName="code"
                            optionDisplayFieldName="code"
                            optionValues={applianceTypes}
                            hintText="Required"
                            clearable={false}
                            comboReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                    <td>
                        <InputComponent inputType="name" caption="Name" hintText="Required"
                            fieldName="name" placeholder="Enter name of the appliance"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <InputComponent inputType="identity" caption="Manufacturer" hintText="Required"
                            fieldName="manufacturer" placeholder="Enter manufacturer of the appliance"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>

                    <td>
                        <InputComponent inputType="number" caption="Model Number" hintText="Required"
                            fieldName="modelNumber" placeholder="Enter model number of the appliance"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <CheckComponent
                            caption="Is Disabled"
                            fieldName="isDisabled"
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                            fieldName="notes" placeholder="Enter any notes."
                            inputReadOnly={props.isRowReadOnly}
                            formManager={formManager} />
                    </td>
                </tr>

            </tbody>
        </table>
    )
}