import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { NetworkDiagramActionTypes, useNetworkDiagramContext } from "../../../store";
import DeviceConfigurationHeader from "./DeviceConfigurationHeader/DeviceConfigurationHeader";
import ChooseDeviceTypePopup from "./ChooseDeviceTypePopup/ChooseDeviceTypePopup";
import DeviceConfigurationForm from "./DeviceConfigurationForm/DeviceConfigurationForm";

const DeviceConfigurationPopup = ({ data }) => {
     const {
          state: { devices },
          dispatch,
     } = useNetworkDiagramContext();

     const [deviceState, setDeviceState] = useState({
          id: null,
          type: null,
          stationId: null,
     });
     const changeDeviceType = useCallback((type) => {
          setDeviceState((prev) => ({
               ...prev,
               type,
          }));
     }, []);

     const closeDevicePopup = useCallback(() => {
          dispatch({
               type: NetworkDiagramActionTypes.CLOSE_DEVICE_POPUP,
               payload: {
                    id: null,
               },
          });
     }, [dispatch]);

     const handleFormSubmit = useCallback(
          (values) => {
               const { deviceName, ...configuration } = values;
               if (deviceState.id === null) {
                    // ADD
                    dispatch({
                         type: NetworkDiagramActionTypes.ADD_DEVICE,
                         payload: {
                              deviceName,
                              type: deviceState.type,
                              stationId: deviceState.stationId,
                              configuration,
                         },
                    });
               } else {
                    // UPDATE
                    dispatch({
                         type: NetworkDiagramActionTypes.UPDATE_DEVICE,
                         payload: {
                              id: deviceState.id,
                              deviceName,
                              configuration,
                         },
                    });
               }
               closeDevicePopup();
          },
          [dispatch, deviceState, closeDevicePopup]
     );

     const handleFormCancel = useCallback(() => {
          closeDevicePopup();
     }, [closeDevicePopup]);

     const handleDeviceTypeChange = useCallback(
          (type) => {
               changeDeviceType(type);
          },
          [changeDeviceType]
     );

     useEffect(() => {
          if (data.id) {
               const editDevice = devices.find((device) => device.id === data.id);
               if (editDevice) setDeviceState(editDevice);
          } else {
               setDeviceState({
                    id: null,
                    type: data.type,
                    stationId: data.stationId,
               });
          }
     }, [data, devices]);

     return (
          <Modal isOpen={data.visible}>
               <DeviceConfigurationHeader state={deviceState} onClose={handleFormCancel} />
               {deviceState.type === null && deviceState.id === null ? <ChooseDeviceTypePopup onClick={handleDeviceTypeChange} /> : <DeviceConfigurationForm deviceState={deviceState} onSubmit={handleFormSubmit} onCancel={handleFormCancel} />}
          </Modal>
     );
};

export default DeviceConfigurationPopup;
