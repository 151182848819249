import React, { Component } from 'react'

class CardView extends Component
{
    render()
    {
        const titleBackground = "#EFF6FF";
        const footNoteBackground = "Snow";

        const { title, subTitle, footNotes, children } = this.props;

        return (
            <div className='d-flex flex-column shadow-sm'>

                {
                    (title || subTitle) &&
                    <div className='p-2 border rounded-top d-flex justify-content-between align-items-center' style={{ backgroundColor: titleBackground }}>
                        <div className='fs-6 fw-bold'>{title}</div>
                        <div><small>{subTitle}</small></div>
                    </div>
                }


                {children}

                {
                    footNotes && footNotes.length > 0 &&
                    <div className='p-2 d-flex flex-column align-items-end border' style={{ backgroundColor: footNoteBackground }}>
                        {
                            // Each footnote on a separate line, right aligned, small font
                            footNotes.map(footNote => <span><small>{footNote}</small></span>)
                        }
                    </div>
                }

            </div>);
    }
}

export default CardView;