import React, { Component } from "react";

import SelectEnumDaysComponent from '../taskUtils/selectEnumDaysComponent';
import SelectEnumWeeksComponent from '../taskUtils/selectEnumWeeksComponent';
import SelectEnumMonthsComponent from '../taskUtils/selectEnumMonthsComponent';

export default class TaskConstraints extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = this.props.formManager;
    }

    render() 
    {
        return (
            <table className="component-table">

                <tbody>

                    <tr>
                        <td>
                            <SelectEnumDaysComponent
                                isRowReadOnly={this.props.isRowReadOnly}
                                hintText="Select which days of week to perform this task (Required)"
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <SelectEnumWeeksComponent
                                isRowReadOnly={this.props.isRowReadOnly}
                                hintText="Select which weeks of the month to perform this task (Required)"
                                formManager={this.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <div style={{ margin: "30px 0px" }}>
                                <SelectEnumMonthsComponent
                                    isRowReadOnly={this.props.isRowReadOnly}
                                    hintText="Select which months of the year to perform this task (Required)"
                                    formManager={this.formManager} />
                            </div>
                        </td>
                    </tr>

                </tbody>

            </table>
        );
    }
}