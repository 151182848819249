export const makeFieldConfigurationValue = (field) => {
    const { defaultValue, fieldProps = {}, type } = field;

    if (typeof defaultValue === "boolean") return defaultValue === false ? "false" : "true";
    if (type === "select") {
         if (fieldProps.options) {
              const selectedOption = fieldProps.options.find((option) => option.value === defaultValue);
              if (selectedOption) return selectedOption.label;
         }
    }
    if (defaultValue) return defaultValue;
    return "-";
};
