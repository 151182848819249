import React, { Component } from 'react';

import BISpinnerComponent from '../components/BISpinnerComponent';
import BIErrorComponent from '../components/BIErrorComponent';
import BICaptionComponent from '../components/BICaptionComponent';
import BIDateSelectionComponent from '../components/BIDateSelectionComponent';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

const biUtils = require('../../../../utils/bi/biUtils');

class BIToolBaseComponent extends Component
{
    constructor(props)
    {
        super(props);

        // componentKey is updated when user saves and renames report with a new state

        this.state = {};
        this.state.componentKey = this.props.componentKey;
    }

    renderCaptionComponent(caption)
    {
        return <BICaptionComponent caption={caption} />;
    }

    renderErrorComponent(errorMessage)
    {
        return <BIErrorComponent error={errorMessage} />;
    }

    renderSpinnerComponent(message)
    {
        return <BISpinnerComponent text={message} />;
    }

    renderMultiDateSelectionHeaderComponent(title, disabled, closeEnabled, onLoad, onChange)
    {
        // Header components shows date selection, title, save as and close buttons

        return <BIDateSelectionComponent
            title={title}
            disabled={disabled}
            closeEnabled={closeEnabled}

            onLoadReport={(searchCriteria) =>
            {
                // Implies grid is about to be destroyed and re-mounted
                // Try grab latest grid state and discard existing grid api references

                if (this.gridApi && this.columnApi)
                {
                    this.saveGridState();

                    this.gridApi = null;
                    this.columnApi = null;
                }

                onLoad(searchCriteria);
            }}

            onCriteriaChanged={() =>
            {
                // Implies grid is about to be destroyed
                // Try grab latest grid state and discard existing grid api references

                if (this.gridApi && this.columnApi) 
                {
                    this.saveGridState();

                    this.gridApi = null;
                    this.columnApi = null;
                }

                onChange();
            }}
        />
    }

    renderGrid(columnDefs, entities, pinnedBottomRowData)
    {
        if (this.gridApi)
        {
            this.gridApi.setRowData(entities)
        }

        return (
            <div className='w-100' id="grid_chart_container">
                <div className="ag-theme-alpine" style={{ height: 'calc(100% - 25px)' }}>
                    <AgGridReact
                        gridOptions={biUtils.gridOptions}
                        columnDefs={columnDefs}
                        createChartContainer={biUtils.createChartContainer}
                        onGridReady={(params) =>
                        {
                            this.gridApi = params.api;
                            this.columnApi = params.columnApi;

                            this.restoreGridState();    // Restore grid state whenever grid is recreated

                            this.gridApi.setRowData(entities)

                            if (pinnedBottomRowData) 
                            {
                                this.gridApi.setPinnedBottomRowData(pinnedBottomRowData)
                            }
                        }}
                    />
                </div>
            </div>);
    }

    get componentKey()
    {
        return this.state.componentKey;
    }

    set componentKey(newKey)
    {
        this.setState({ componentKey: newKey });
    }

    get componentId()
    {
        if (this.componentKey)
        {
            return this.componentKey.split("::")[0];
        }

        return null;
    }

    get componentName()
    {
        if (this.componentKey && this.componentKey.includes("::"))
        {
            return this.componentKey.split("::")[1];
        }

        return null;
    }

    saveGridState = () =>
    {
        // Soft save, saves in instance property

        if (this.gridApi && this.columnApi) 
        {
            const columnState = this.columnApi.getColumnState();
            const filterModel = this.gridApi.getFilterModel();
            this.gridState = { columnState, filterModel }
        }
    }

    restoreGridState = () =>
    {
        if (this.gridApi && this.columnApi)
        {
            if (!this.gridState) return;

            const { columnState, filterModel } = this.gridState;

            columnState && this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
            filterModel && this.gridApi.setFilterModel(filterModel);
        }
    }
}

export default BIToolBaseComponent;