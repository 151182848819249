import React, { useCallback, useState } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { useMemo } from "react";
import { useNetworkDiagramContext } from "../../../../store";
import NavigationTabs from "./NavigationTabs/NavigationTabs";
import Tab from "./Tab";
import { stationFieldsConfiguration } from "../../../../configuration/stationFieldsConfiguration";
import { Form, addFieldOptions } from "../../../Form";
import { useEffect } from "react";
import { deepCloneObject } from "../../../../../../../utils/type/typeUtil";
import { TerminalTypes } from "../../../../constants";
import { generateGuid } from "../../../../../../../utils/guid/guidUtil";
import { validateFields } from "../../../Form/utils";
import { generateStationCode } from "../../../../networkDiagramUtil";

const StationConfigurationForm = ({ stationState, onSubmit = () => {}, onCancel = () => {} }) => {
     const {
          state: { stations, devices, storefronts, signagePlayers, kioskSkins, isReadOnly},
     } = useNetworkDiagramContext();
     const [formFieldsValues, setFormFieldsValues] = useState({});
     const [state, setState] = useState({
          selectedTab: 0,
     });
     const [fieldsState, setFieldsState] = useState({
          overview: [],
          settings: [],
     });

     const handleOnFormSubmit = useCallback((values) => onSubmit(values), [onSubmit]);

     const handleTabChange = useCallback((value) => {
          setState((prev) => ({
               ...prev,
               selectedTab: value,
          }));
     }, []);

     const handleFieldValueChange = useCallback((value, fieldName) => {
          setFormFieldsValues((prevFields) => ({
               ...prevFields,
               [fieldName]: value,
          }));
     }, []);

     const { overviewTabFields, settingsTabFields } = useMemo(() => {
          let overviewTabFields = fieldsState.overview;
          let settingsTabFields = fieldsState.settings;

          switch (stationState.type) {
               case TerminalTypes.pos: {
                    settingsTabFields = settingsTabFields.map((field) => {
                         switch (field.fieldName) {
                              case "signagePlayerId":
                                   addFieldOptions(field, signagePlayers, "id", "title");
                                   break;
                              case "storeFrontId":
                                   addFieldOptions(
                                        field,
                                        storefronts.filter((storefront) => storefront.type === "POS"),
                                        "id",
                                        "title"
                                   );

                                   break;

                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               case TerminalTypes.kds: {
                    settingsTabFields = settingsTabFields.map((field) => {
                         switch (field.fieldName) {
                              case "labelPrinterId":
                                   const printers = [];

                                   const filteredDevices = devices.filter((device) => device.type === "labelPrinter");
                                   for (let idx = 0; idx < filteredDevices.length; idx++) {
                                        const device = filteredDevices[idx];
                                        const station = stations.find((station) => station.id === device.stationId);
                                        printers.push({
                                             label: `${station.type.toUpperCase()}::${station.stationName} / ${device.deviceName}`,
                                             value: device.id,
                                        });
                                   }
                                   addFieldOptions(field, printers, "value", "label");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });
                    break;
               }
               case TerminalTypes.kiosk: {
                    settingsTabFields = settingsTabFields.map((field) => {
                         switch (field.fieldName) {
                              case "receiptPrinterId":
                                   const printers = [];

                                   const filteredDevices = devices.filter((device) => device.type === "receiptPrinter");
                                   for (let idx = 0; idx < filteredDevices.length; idx++) {
                                        const device = filteredDevices[idx];
                                        const station = stations.find((station) => station.id === device.stationId);
                                        printers.push({
                                             label: `${station.type.toUpperCase()}::${station.stationName} / ${device.deviceName}`,
                                             value: device.id,
                                        });
                                   }
                                   addFieldOptions(field, printers, "value", "label");

                                   break;
                              case "skinId":
                                   addFieldOptions(field, kioskSkins, "id", "title");
                                   break;
                              case "storeFrontId":
                                   addFieldOptions(
                                        field,
                                        storefronts.filter((storefront) => storefront.type === "KIOSK"),
                                        "id",
                                        "title"
                                   );
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               case TerminalTypes.signage: {
                    settingsTabFields = settingsTabFields.map((field) => {
                         switch (field.fieldName) {
                              case "signagePlayerId":
                                   addFieldOptions(field, signagePlayers, "id", "title");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               case TerminalTypes.kitchen: {
                    settingsTabFields = settingsTabFields.map((field) => {
                         switch (field.fieldName) {
                              case "labelPrinterId":
                                   const printers = [];

                                   const filteredDevices = devices.filter((device) => device.type === "labelPrinter");
                                   for (let idx = 0; idx < filteredDevices.length; idx++) {
                                        const device = filteredDevices[idx];
                                        const station = stations.find((station) => station.id === device.stationId);
                                        printers.push({
                                             label: `${station.type.toUpperCase()}::${station.stationName} / ${device.deviceName}`,
                                             value: device.id,
                                        });
                                   }
                                   addFieldOptions(field, printers, "value", "label");
                                   break;
                              default:
                                   break;
                         }
                         return field;
                    });

                    break;
               }
               default:
                    break;
          }

          if (stationState.id !== null) {
               overviewTabFields.forEach((field) => {
                    if (field.fieldName === "stationName") {
                         field["defaultValue"] = stationState.stationName;
                    } else {
                         field["defaultValue"] = stationState.configuration[field.fieldName];
                    }
               });
               settingsTabFields.forEach((field) => {
                    field["defaultValue"] = stationState.configuration[field.fieldName];
               });
          } else {
               const shellCode = generateStationCode();

               overviewTabFields.forEach((field) => {
                    if (field.fieldName === "licenseKey") {
                         field["defaultValue"] = generateGuid();
                    }

                    if(field.fieldName === "shellCode"){
                        field["defaultValue"] = shellCode;
                    }

                    if(field.fieldName === "terminalCode"){
                        field["defaultValue"] = `${shellCode}.${stationState.type.toLowerCase()}`;
                    }
               });
          }
          if(isReadOnly){
               return {
                    overviewTabFields: overviewTabFields.map(fields=> {
                         return {
                              ...fields,
                              fieldProps: Object.assign({}, fields.fieldProps, {readOnly: true, disabled: true}),
                         }
                    }),
                    settingsTabFields: settingsTabFields.map(fields=> {
                         return {
                              ...fields,
                              fieldProps: Object.assign({}, fields.fieldProps, {readOnly: true, disabled: true}),
                         }
                    })
               }
          }
          return {
               overviewTabFields,
               settingsTabFields,
          };
     }, [fieldsState, devices, signagePlayers, storefronts, kioskSkins, stationState]);

     const isSaveButtonDisabled = useMemo(() => {
          let res = false;
          switch (stationState.type) {
               case TerminalTypes.pos:
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.pos].overviewTabFields, formFieldsValues)) return true;
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.pos].settingsTabFields, formFieldsValues)) return true;
                    break;
               case TerminalTypes.kds:
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.kds].overviewTabFields, formFieldsValues)) return true;
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.kds].settingsTabFields, formFieldsValues)) return true;
                    break;
               case TerminalTypes.kiosk:
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.kiosk].overviewTabFields, formFieldsValues)) return true;
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.kiosk].settingsTabFields, formFieldsValues)) return true;
                    break;
               case TerminalTypes.kitchen:
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.kitchen].overviewTabFields, formFieldsValues)) return true;
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.kitchen].settingsTabFields, formFieldsValues)) return true;
                    break;
               case TerminalTypes.centralKitchen:
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.centralKitchen].overviewTabFields, formFieldsValues)) return true;
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.centralKitchen].settingsTabFields, formFieldsValues)) return true;
                    break;
               case TerminalTypes.signage:
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.signage].overviewTabFields, formFieldsValues)) return true;
                    if (!validateFields(stationFieldsConfiguration[TerminalTypes.signage].settingsTabFields, formFieldsValues)) return true;
                    break;
               default:
                    break;
          }
          return res;
     }, [stationState.type, formFieldsValues]);

     useEffect(() => {
          const fieldsConf = deepCloneObject(stationFieldsConfiguration)[stationState.type];
          if (fieldsConf)
               setFieldsState({
                    overview: fieldsConf.overviewTabFields,
                    settings: fieldsConf.settingsTabFields,
               });
     }, [stationState]);

     return (
          <Form onSubmit={handleOnFormSubmit}>
               <ModalBody>
                    {settingsTabFields.length > 0 && <NavigationTabs selectedTab={state.selectedTab} onChange={handleTabChange} />}

                    <Tab fields={overviewTabFields} active={state.selectedTab === 0} onFieldValueChange={handleFieldValueChange} />
                    <Tab fields={settingsTabFields} active={state.selectedTab === 1} onFieldValueChange={handleFieldValueChange} />
               </ModalBody>
               <ModalFooter>
                    <Button color="error" type="button" onClick={onCancel}>
                         Cancel
                    </Button>
                    <Button disabled={isSaveButtonDisabled || isReadOnly} color="success" type="submit">
                         {stationState.id === null ? "Add" : "Update"}
                    </Button>
               </ModalFooter>
          </Form>
     );
};

export default StationConfigurationForm;
