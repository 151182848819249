import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import { Card, CardBody, CardHeader } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent.js';
import DeliveryZoneTable from './components/DeliveryZoneTable';
import "./styles.css";
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontDeliveryChargesHelper = require('./storefrontDeliveryChargesHelper');
const menuLoadFacade = require('../../../../../utils/api/menuLoadFacade.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const FormManager = require('../../../../../utils/view/formManager.js');
const viewUtil = require('../../../../../utils/view/viewUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontDeliveryCharges extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            priceBands: orgStateSelectors.selectOrgLookupData(currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId).priceBands,
            menuHeaders: [],
            formManager: new FormManager(),
            isLoading: false,
            storefront: this.props.params
        };

        this.state.deliveryZones = [storefrontDeliveryChargesHelper.DeliveryZoneDataItem()];
        this.state.storefrontDeliveryCharges = {};
        this.state.formManager.viewModel = this.state.storefrontDeliveryCharges;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.setDeliveryZones = this.setDeliveryZones.bind(this);
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let menuHeaders = await menuLoadFacade.loadMenuHeadersExceptCentralKitchen();
            const { staggeredCharges, ...storefrontDeliveryCharges } = await storefrontDeliveryChargesHelper.loadStorefrontDeliveryCharges(this.state.storefront);

            this.state.storefrontDeliveryCharges = storefrontDeliveryCharges || {};
            this.state.formManager.viewModel = this.state.storefrontDeliveryCharges;
            this.state.menuHeaders = menuHeaders;

            if (Array.isArray(staggeredCharges) && staggeredCharges.length > 0)
            {
                const formattedStaggeredCharges = staggeredCharges.map(item =>
                {
                    return storefrontDeliveryChargesHelper.DeliveryZoneDataItem(null, item.distance, item.charges)
                })
                this.setState({ deliveryZones: formattedStaggeredCharges });
            }

        }
        catch (error)
        {
            logger.error(error, {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    async onSaveClicked()
    {
        const validationError = storefrontDeliveryChargesHelper.validateStoreFrontDeliveryCharges(this.state.storefrontDeliveryCharges);

        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        let staggeredDeliveryCharges;

        if (stringUtil.isStringNullOrEmpty(this.state.storefrontDeliveryCharges.fixedDeliveryCharges))
        {
            const [
                hasErrors,
                updatedDeliveryZoneData
            ] = storefrontDeliveryChargesHelper.validateDeliveryZonesData(this.state.deliveryZones,
                this.state.storefrontDeliveryCharges.maxDeliveryDistance,
            );

            this.setDeliveryZones(updatedDeliveryZoneData);
            if (hasErrors) return;
            staggeredDeliveryCharges = storefrontDeliveryChargesHelper.formatStaggeredDeliveryChargesData(updatedDeliveryZoneData)
        }

        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            const requestPayload = {
                ...this.state.storefrontDeliveryCharges,
                staggeredCharges: staggeredDeliveryCharges || null
            }
            await storefrontDeliveryChargesHelper.save(this.state.storefront, requestPayload);
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.deliveryCharges, 
                                                      `${this.state.storefront.name} storefront delivery charges have been altered`, 
                                                       requestPayload);
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    setDeliveryZones(updatedDeliveryZoneData)
    {
        this.setState(prevstate => ({ ...prevstate, deliveryZones: updatedDeliveryZoneData }));
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }

        return (
            <Card>
                <CardHeader>
                    <EditComponentHeader
                        title="Storefront"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    <table width="100%">
                        <tbody>
                            <tr>

                                <td style={{ paddingRight: "20px", width: "50%", verticalAlign: "Top" }}>

                                    <div style={{ display: "flex", flexFlow: "column" }}>

                                        <InputComponent
                                            inputType="money"
                                            fieldName="minimumDeliveryOrderAmount"
                                            caption="Minimum Delivery Order Amount"
                                            placeholder="Enter minimum order amount"
                                            hintText="Minimum order amount required for delivery  (Required)"
                                            inputReadOnly={this.props.isReadOnly}
                                            formManager={this.state.formManager} />

                                        <InputComponent
                                            inputType="mileage"
                                            fieldName="maxDeliveryDistance"
                                            caption="Max Delivery Distance (in miles)"
                                            placeholder="Enter max deliverable distance"
                                            hintText="Maximum deliverable distance supported (Required)"
                                            inputReadOnly={this.props.isReadOnly}
                                            formManager={this.state.formManager} />
                                        
                                        <InputComponent
                                            inputType="percentage"
                                            fieldName="vatPercentage"
                                            caption="VAT Percentage"
                                            placeholder="Enter percentage VAT applied on delivery charge"
                                            hintText="VAT applied on delivery charges (Optional)"
                                            inputReadOnly={this.props.isReadOnly}
                                            formManager={this.state.formManager} />

                                        <InputComponent
                                            inputType="money"
                                            fieldName="fixedDeliveryCharges"
                                            caption="Fixed Delivery Charges"
                                            placeholder="Enter fixed delivery charges"
                                            hintText="Fixed delivery charges for all deliveries (Optional)"
                                            inputReadOnly={this.props.isReadOnly}
                                            formManager={this.state.formManager} />

                                        <span style={{ fontSize: "medium", width: "75%" }}>Note: You can only set either of Fixed Delivery Charges or Staggered Delivery Charges.</span>

                                    </div>

                                </td>

                                <td style={{ paddingLeft: "20px", width: "50%", verticalAlign: "Top" }}>

                                    <div style={{ display: "flex", flexFlow: "column", position: "relative" }} >

                                        <div style={{ textAlign: "center", padding: "10px", marginTop: "-15px" }}>Staggered Delivery Charges</div>

                                        <DeliveryZoneTable
                                            deliveryZones={this.state.deliveryZones}
                                            setDeliveryZones={this.setDeliveryZones}
                                            fixedDeliveryCharges={this.state.storefrontDeliveryCharges.fixedDeliveryCharges}
                                        />
                                    </div>

                                </td>

                            </tr>

                        </tbody>

                    </table>
                </CardBody>
            </Card>
        );
    }
}

export default StorefrontDeliveryCharges;