import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import SaveButton from '../../../../../components/button/saveButton';
import CloseButton from '../../../../../components/button/closeButton';
import ErrorMessage from '../../../../../components/error/errorMessage';

import DispatchTypeSelector from './components/DispatchTypeSelector';
import DayOpeningHours from './components/DayOpeningHours';

import * as viewUtil from '../../../../../utils/view/viewUtil';

import * as dataUtils from './utils/openingHourDataUtils';
import * as validationUtils from './utils/openingHourValidationUtils';

class StorefrontOpeningHours extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            isLoading: false,
            error: null
        }
    }

    get storefront() { return this.props.params; }

    onDispatchTypeSelectionChanged = (dispatchType, value) =>
    {
        const { dispatchTypes } = this.state;

        dispatchTypes[dispatchType] = value;

        dispatchTypes[dataUtils.allDispatchTypes] =
            Object.keys(dispatchTypes)
                .filter(dispatchType => dispatchType !== dataUtils.allDispatchTypes)
                .every(dispatchType => dispatchTypes[dispatchType] === false);

        this.setState({ dispatchTypes: { ...dispatchTypes } });
    };

    componentDidMount()
    {
        this.loadData();
    }

    render()
    {
        const { isLoading, error, openingHoursData } = this.state;

        if (isLoading) return null;

        return (
            <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <div className='fw-bold'>Opening Hours: {this.storefront.name}</div>
                    <div className='d-flex'>
                        <div className='me-2'><SaveButton disabled={isLoading || error} onClick={this.onSave} /></div>
                        <div><CloseButton disabled={isLoading} /></div>
                    </div>
                </CardHeader>
                <CardBody className='p-4'>
                    {
                        error && this.renderError()
                    }
                    {
                        openingHoursData && this.renderData()
                    }
                </CardBody>
            </Card>
        );
    }

    renderError()
    {
        return (
            <div className='py-5 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={this.state.error} />
            </div>
        )
    }

    renderData()
    {
        const { openingHoursData, dispatchTypes } = this.state;

        return (
            <div className='d-flex flex-column'>

                <div className='mb-2 pb-1 d-flex flex-column small text-muted border-bottom'>
                    <div className='mb-1'>Choose dispatch types only if you desire to specify opening hours by dispatch</div>
                    <div className='mb-1'>Use free text to set the opening hours, such as: 8:00 AM-1:00 PM | 08:00-13:00 | all day | closed</div>
                    <div className='mb-1'>Use comma to separate multiple time slots for a given day</div>
                    <div className='mb-1'>Note: User input will be normalized and converted to 24hr time format on save</div>
                </div>

                {
                    Object.keys(dispatchTypes).length === 1
                        ? null
                        : <div className='mb-3 pb-1 border-bottom'>
                            <DispatchTypeSelector
                                dispatchTypes={dispatchTypes}
                                onChange={this.onDispatchTypeSelectionChanged} />
                        </div>
                }

                <div className='my-3 d-flex'>

                    <div className='w-100 d-flex small text-muted text-center' style={{ marginLeft: "75px" }}>
                        {
                            Object.keys(dispatchTypes).map(dispatchType =>
                                dispatchTypes[dispatchType] &&
                                <div key={dispatchType} className='w-50 px-4'>
                                    <div>{dispatchType} Hours</div>
                                </div>
                            )
                        }
                    </div>

                </div>

                <div>
                    {
                        dataUtils.days.map(day =>
                            <div key={day} className='mb-3'>
                                <DayOpeningHours day={day} dispatchTypes={dispatchTypes} openingHoursData={openingHoursData} />
                            </div>)
                    }
                </div>
            </div>
        );
    }

    onSave = async () => 
    {
        const { openingHoursData, dispatchTypes } = this.state;

        if (!validationUtils.isOpeningHoursDataValid(dispatchTypes, openingHoursData))
        {
            confirmAlert({
                title: 'Invalid data error',
                message: 'You have invalid opening hour data, please remove error and try again',
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });

            return;
        }

        try 
        {
            viewUtil.showSystemModalSpinner('Saving data, please wait ...');

            const data = await dataUtils.save(this.storefront, dispatchTypes, openingHoursData);

            this.setState({ ...data });

            viewUtil.showSuccessAlert('Storefront opening hours saved successfully');
        }
        catch (error) 
        {
            viewUtil.showErrorAlert(`Failed to save storefront opening hours, Error: ${error}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    loadData = async () =>
    {
        this.setState({ isLoading: true, error: null, openingHoursData: null });

        try 
        {
            viewUtil.showSystemModalSpinner("Loading storefront opening hours ...");
            const data = await dataUtils.loadData(this.storefront);
            this.setState({ ...data });
        }
        catch (error)
        {
            this.setState({ error });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }
}

export default StorefrontOpeningHours;