

import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import { string } from 'prop-types';

import Jumbotron from "../jumbotron/jumbotron";

import CdnImage from './cdnImage.js'

var viewUtil = require('../../utils/view/viewUtil.js');
var stringUtil = require('../../utils/string/stringUtil.js');
var configurationManager = require('../../utils/config/configurationManager.js');

class CdnImageUploader extends Component 
{
    constructor (props)
    {
        super(props);

        this.onUpload = this.onUpload.bind(this);
        this.onRemoveImage = this.onRemoveImage.bind(this);
        this.renderHelp = this.renderHelp.bind(this);

    }

    render()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.imageUrl))
            return this.renderHelp();

        var defaultWidth = 400;
        var defaultHeight = 400;
        var defaultRadius = 20;

        if (this.props.width)
            defaultWidth = this.props.width;

        if (this.props.height)
            defaultHeight = this.props.height;

        if (this.props.radius)
            defaultRadius = this.props.radius;

        return (<div>
                    <div className='mb-2' align="right">
                        <Button color="danger" onClick={this.onRemoveImage} disabled={this.props.isReadOnly}>Remove Image</Button>
                    </div>
                    <div>
                        <CdnImage publicId={this.props.imageUrl} cloudName={this.props.cloudName} width={defaultWidth} height={defaultHeight} radius="0"/>
                    </div>
                </div>);

    }

    renderHelp()
    {
        return (<Jumbotron>
                  {this.props.children}
                  <p className="lead">
                    <Button color="primary" onClick={this.onUpload} disabled={this.props.isReadOnly}>Upload</Button>
                  </p>
                </Jumbotron>);
    }

    onUpload()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.imageFolder))
            return;

        
        viewUtil.showImageUploadWidget(this.props.cloudName, this.props.imageFolder, (urlId)=>
        {  
            if (this.props.onImageChange)
                this.props.onImageChange(urlId);
        }, this.props.maxImageSize);
    }

    onRemoveImage()
    {
        if (this.props.onImageChange)
                this.props.onImageChange(null);

    }
}

export default CdnImageUploader;
