import React from "react";
import { Button, ButtonGroup, ModalBody } from "reactstrap";
import styled from "styled-components";
import { DeviceTypes } from "../../../../constants";

const stationType = [
     {
          label: "Receipt Printer",
          value: DeviceTypes.receiptPrinter,
     },
     {
          label: "Label Printer",
          value: DeviceTypes.labelPrinter,
     },
     {
          label: "Credit Card Reader",
          value: DeviceTypes.creditCardReader,
     },
    
];

const ChooseDeviceTypePopup = ({ onClick = () => {} }) => {
     return (
          <ModalBody>
               <ButtonGroup vertical style={{width:'100%'}}>
                    {stationType.map((station) => (
                         <TypeButton key={station.value} color="primary" size="lg" outline onClick={() => onClick(station.value)}>
                              <span>{station.label}</span>
                              <svg width={30} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                              </svg>
                         </TypeButton>
                    ))}
               </ButtonGroup>
          </ModalBody>
     );
};

export default ChooseDeviceTypePopup;

const TypeButton = styled(Button)`
     display: flex;
     justify-content: space-between;
`;
