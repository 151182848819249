import React, { memo } from 'react';
import classNames from 'classnames';

function ItemComponent({ displayValue, isSelected })
{
    return (
        <div className={classNames('fs-6 mx-1 px-2 py-1 rounded', isSelected && 'bg-light fw-bold')}>
            {displayValue}
        </div>
    );
}

export default memo(ItemComponent);     // ItemComponent is a pure component