
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardHeader, Button, CardBody, FormText} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import InputComponent from '../../../../../components/form/inputComponent'
import SelectComponent from '../../../../../components/form/selectComponent.js'
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent'
import ColorPickerComponent from '../../../../../components/form/colorPickerComponent.js'
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard.js';

import CdnImageUploader from '../../../../../components/cdnImage/cdnImageUploader.js';

var configurationManager = require('../../../../../utils/config/configurationManager.js');
var FormManager = require('../../../../../utils/view/formManager.js');
var constants = require('../../../../../utils/domain/constants.js');
var viewUtil = require('../../../../../utils/view/viewUtil.js');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
var stringUtil = require('../../../../../utils/string/stringUtil.js');
var validator = require('../../../../../utils/validator/validator.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


class KioskSkin extends Component
{
    constructor(props)
    {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderHomePageDesigner = this.renderHomePageDesigner.bind(this);

        this.renderGeneralTab = this.renderGeneralTab.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.validateSkin = this.validateSkin.bind(this);
        this.getImageFolder = this.getImageFolder.bind(this);
        
        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.skin;
        this.state.formManager.view = this;

        this.props.skin.dispatchDineInDisabled = this.props.skin.dispatchDineInDisabled || false;
        this.props.skin.dispatchTakeAwayDisabled = this.props.skin.dispatchTakeAwayDisabled || false;
    }

    render()
    {
        return (<Card style={{userSelect:'none'}} >
                        <CardHeader>
                            {this.renderHeader()} 
                        </CardHeader>
                    
                    <CardBody>
                        <div style={{marginTop:'20px'}}>
                            {this.renderBody()}
                        </div>
                    </CardBody>
                </Card>);
    }

    renderHeader()
    {
        return(
            <table width="100%">
                <tr width="100%">
                    
                    <td style={{whiteSpace: "nowrap"}}>
                        <div style={{fontSize:'14px',color: '#606060', fontWeight:'bold'}}>Kiosk Skin Designer</div>
                    </td>

                    <td  style={{whiteSpace: "nowrap", width:'99%'}} align = "right">
                        <Button disabled={this.props.isReadOnly} color="success" className="btn-success" style={{ marginRight: '3px' }} onClick={this.onSaveClicked}>
                            <i className="fa fa-save"></i>&nbsp;Save
                        </Button>

                        <Button color="secondary" className="btn-secondary" onClick={this.props.onCancel}>
                            Cancel
                        </Button>

                       
                    </td>
                </tr>
            </table>
          );
    }

    renderBody()
    {

        return (
                <Tabs>
                    <TabList>
                        <Tab>General</Tab>
                        <Tab>Home Page</Tab>
                        <Tab>Rewards Page</Tab>
                        <Tab>Sale Page</Tab>
                        <Tab>Payment Confirmation Page</Tab>
                    </TabList>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderGeneralTab()}
                    </TabPanel>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderHomePageDesigner()}
                    </TabPanel>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderRewardsPageDesigner()}
                    </TabPanel>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderSalePageDesigner()}
                    </TabPanel>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderPaymentConfirmationPageDesigner()}
                    </TabPanel>
                </Tabs>);
    }

    renderGeneralTab()
    {
        var playerOptions = [];
        this.props.players.forEach (player =>
        {
            var playerOption = {};
            playerOption.label = player.name;
            playerOption.value = player.id;
            playerOptions.push(playerOption);
        })

        return (<table width="100%">
                    <tr>
                        <td style={{width:'50%'}}>
                            <InputComponent inputType="text" caption="Skin Name" hintText="Required" style={{ marginRight: '20px' }} fieldName="name" placeholder="Enter skin name" formManager={this.state.formManager} />
                        </td>
                        <td style={{width:'50%'}}>
                            <SelectComponent
                                optionValues={playerOptions}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                caption="Signage Players"
                                fieldName="playerId"
                                formManager={this.state.formManager}
                                clearable={false}
                                hintText = "Choose signage player of this skin (required)"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td style={{width:'50%'}}>
                            <InputComponent inputType="text" caption="Screen Width" hintText="Required" style={{ marginRight: '20px' }} fieldName="width" placeholder="Enter screen width resolution" formManager={this.state.formManager} />
                        </td>
                        <td style={{width:'50%'}}>
                            <InputComponent inputType="text" caption="Screen Height" hintText="Required" fieldName="height" placeholder="Enter screen height resolution" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td style={{verticalAlign:'top', width:'50%'}}>
                            <InputComponent inputType="text" caption="Font Name" hintText="Required" style={{ marginRight: '20px' }} fieldName="fontName" placeholder="Enter font name" formManager={this.state.formManager} />
                        </td>
                        <td style={{width:'50%'}}>
                            <ColorPickerComponent
                                caption="Background Color"
                                fieldName="backgroundColor"
                                hintText="Choose background color for skin (required)"
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text"  multiLine={true} caption="Notes" rows={4}
                                fieldName="notes" placeholder="Enter any notes for kiosk skin." hintText="Required" formManager={this.state.formManager} />
                        </td>
                    </tr>

                </table>);
    }

    renderHomePageDesigner()
    {
        if (!this.props.skin.home)
            this.props.skin.home = {};

        return (<table width="100%">
                    <tr>
                        <td style={{width:'50%'}}>
                            <CollapsibleCard caption="Overview">
                                <ColorPickerComponent
                                    caption="Background Color"
                                    fieldName="home.backgroundColor"
                                    hintText="Choose background color for skin (required)"
                                    formManager={this.state.formManager}
                                />
                            </CollapsibleCard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <CollapsibleCard caption="Order Here Button">
                                <table width="100%">
                                    <tr>
                                        <td style={{width:'50%', verticalAlign:'top'}}>
                                            <table width="100%">
                                                <tr>
                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" caption="Button Width" hintText="Required" style={{ marginRight: '10px' }} fieldName="home.orderHereButtonWidth" placeholder="Enter width for button" formManager={this.state.formManager} />
                                                    </td>
                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" caption="Button Height" hintText="Required" style={{ marginRight: '10px' }} fieldName="home.orderHereButtonHeight" placeholder="Enter height for button" formManager={this.state.formManager} />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" caption="Button Left Position" hintText="Required" style={{ marginRight: '10px' }} fieldName="home.orderHereButtonLeft" placeholder="Enter left position for button" formManager={this.state.formManager} />
                                                    </td>
                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" caption="Button Top Position" hintText="Required" style={{ marginRight: '10px' }} fieldName="home.orderHereButtonTop" placeholder="Enter top position for button" formManager={this.state.formManager} />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        
                                        <td style={{width:'50%'}}>
                                            <div style={{borderStyle:'solid', borderWidth:'2px', borderColor:'#D0D0D0', padding:'5px'}}>
                                                <CdnImageUploader cloudName={configurationManager.getConfig().cdnUsersCloudName}
                                                    imageFolder={this.getImageFolder()}
                                                    imageUrl={this.props.skin.home.orderHereButtonImage}
                                                    maxImageSize = {5000000}
                                                    radius="0"
                                                    width="400"
                                                    height="100"
                                                    onImageChange={(url)=>
                                                        {
                                                            this.props.skin.home.orderHereButtonImage = url;
                                                            this.setState({});
                                                        }}>
                                                    <h4 className="display-5">No Image Found</h4>
                                                    <FormText color="muted">Please upload an image for Order Here Button.</FormText>
                                                </CdnImageUploader>
                                            </div>
                                        </td>
                                    </tr>

                                </table>
                            </CollapsibleCard>
    
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <CollapsibleCard caption="Dispatch Buttons">
                                <table width="100%">
                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Dialogue Width (%)" hintText="Required" style={{ marginRight: '20px' }} fieldName="home.dispatchDialogueWidth" placeholder="Enter width for dispatch dialogue (in percentage)" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Dialogue Padding" hintText="Required" fieldName="home.dispatchDialoguePadding" placeholder="Enter padding for dispatch dialogue" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Button Width" hintText="Required" style={{ marginRight: '20px' }} fieldName="home.dispatchButtonWidth" placeholder="Enter width for dispatch button" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Button Height" hintText="Required" fieldName="home.dispatchButtonHeight" placeholder="Enter height for dispatch button" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{marginRight:'20px'}}>
                                                <ColorPickerComponent
                                                    caption="DineIn Background Color"
                                                    fieldName="home.dispatchDineInButtonBackgroundColor"
                                                    hintText="Choose background color for Dine In button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                                
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <ColorPickerComponent
                                                    caption="TakeAway Background Color"
                                                    fieldName="home.dispatchTakeAwayButtonBackgroundColor"
                                                    hintText="Choose background color for takeaway button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                           
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{marginRight:'20px'}}>
                                                <ColorPickerComponent
                                                    caption="DineIn Foreground Color"
                                                    fieldName="home.dispatchDineInButtonForegroundColor"
                                                    hintText="Choose foreground color for Dine In button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <ColorPickerComponent
                                                    caption="TakeAway Foreground Color"
                                                    fieldName="home.dispatchTakeAwayButtonForegroundColor"
                                                    hintText="Choose foreground color for Take Away button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{ marginRight: '20px' }}>
                                                <BooleanSelectComponent
                                                    caption="Dine In Disabled"
                                                    fieldName="home.dispatchDineInDisabled"
                                                    hintText="Disable the Dine In option on the kiosk"
                                                    clearable={false}
                                                    formManager={this.state.formManager} />
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <BooleanSelectComponent
                                                        caption="Take Away Disabled"
                                                        fieldName="home.dispatchTakeAwayDisabled"
                                                        hintText="Disable the Take Away option on the kiosk"
                                                        clearable={false}
                                                        formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Button Font Name" hintText="Required" style={{ marginRight: '20px' }} fieldName="home.dispatchButtonFontFamily" placeholder="Enter font for dispatch button" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Button Font Size" hintText="Required" fieldName="home.dispatchButtonFontSize" placeholder="Enter font size for dispatch button" formManager={this.state.formManager} />
                                        </td>
                                    </tr>
                                </table>
                            </CollapsibleCard>
    
                        </td>
                    </tr>
                </table>);
    }

    renderRewardsPageDesigner()
    {
        if (!this.props.skin.rewards)
            this.props.skin.rewards = {};

        return (<table width="100%">
            <tr>
                <td>
                    <CollapsibleCard caption="Rewards Background">
                        <div style={{ borderStyle: 'solid', borderWidth: '2px', borderColor: '#D0D0D0', padding: '5px' }}>
                            <CdnImageUploader cloudName={configurationManager.getConfig().cdnUsersCloudName}
                                imageFolder={this.getImageFolder()}
                                imageUrl={this.props.skin.rewards.background}
                                maxImageSize={5000000}
                                radius="0"
                                width="300"
                                height="600"
                                onImageChange={(url) =>
                                {
                                    this.props.skin.rewards.background = url;
                                    this.setState({});
                                }}>
                                <h4 className="display-5">No Image Found</h4>
                                <FormText color="muted">Please upload an image for Rewards Page Background.</FormText>
                            </CdnImageUploader>
                        </div>
                    </CollapsibleCard>
                </td>
            </tr>
            <tr>
                <td>
                    <CollapsibleCard caption="Skip Rewards Button">
                        <table width="100%">
                            <tr>
                                <td style={{ width: '50%', verticalAlign: 'top' }}>
                                    <table width="100%">
                                        <tr>
                                            <td style={{ width: '50%' }}>
                                                <InputComponent inputType="text" caption="Button Width" hintText="Required" style={{ marginRight: '10px' }} fieldName="rewards.skipButtonWidth" placeholder="Enter width for button" formManager={this.state.formManager} />
                                            </td>
                                            <td style={{ width: '50%' }}>
                                                <InputComponent inputType="text" caption="Button Height" hintText="Required" style={{ marginRight: '10px' }} fieldName="rewards.skipButtonHeight" placeholder="Enter height for button" formManager={this.state.formManager} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: '50%' }}>
                                                <InputComponent inputType="text" caption="Button Left Position" hintText="Required" style={{ marginRight: '10px' }} fieldName="rewards.skipButtonLeft" placeholder="Enter left position for button" formManager={this.state.formManager} />
                                            </td>
                                            <td style={{ width: '50%' }}>
                                                <InputComponent inputType="text" caption="Button Top Position" hintText="Required" style={{ marginRight: '10px' }} fieldName="rewards.skipButtonTop" placeholder="Enter top position for button" formManager={this.state.formManager} />
                                            </td>
                                        </tr>
                                    </table>
                                </td>

                                <td style={{ width: '50%' }}>
                                    <div style={{ borderStyle: 'solid', borderWidth: '2px', borderColor: '#D0D0D0', padding: '5px' }}>
                                        <CdnImageUploader cloudName={configurationManager.getConfig().cdnUsersCloudName}
                                            imageFolder={this.getImageFolder()}
                                            imageUrl={this.props.skin.rewards.skipButtonImage}
                                            maxImageSize={5000000}
                                            radius="0"
                                            width="400"
                                            height="100"
                                            onImageChange={(url) =>
                                            {
                                                this.props.skin.rewards.skipButtonImage = url;
                                                this.setState({});
                                            }}>
                                            <h4 className="display-5">No Image Found</h4>
                                            <FormText color="muted">Please upload an image for Skip Rewards Button.</FormText>
                                        </CdnImageUploader>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </CollapsibleCard>

                </td>
            </tr>
        </table>);
    }

    renderSalePageDesigner()
    {
        if (!this.props.skin.sale)
            this.props.skin.sale = {};

        return (<table width="100%">
                    <tr>
                        <td style={{width:'50%'}}>
                            <CollapsibleCard caption="Overview">
                                <table width="100%">
                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{marginRight:'20px'}}>
                                                <ColorPickerComponent
                                                    caption="Background Color"
                                                    fieldName="sale.backgroundColor"
                                                    hintText="Choose background color for sale page (optional)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Content Panel Height" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.contentPanelHeight" placeholder="Enter height for content panel (required)" formManager={this.state.formManager} />
                                        </td>
                                    </tr>
                                </table>
                                
                            </CollapsibleCard>
                        </td>
                    </tr>

                    <tr>
                        <td >
                            <CollapsibleCard caption="Category Panel">
                                <table width="100%">
                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Panel Width" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.categoryPanelWidth" placeholder="Enter width for category panel (required)" formManager={this.state.formManager} />
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Top Padding" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.categoryPanelTopPadding" placeholder="Enter top padding for category panel (required)" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Side Padding" hintText="Required" fieldName="sale.categoryPanelSidePadding" placeholder="Enter side padding for category panel (required)" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{verticalAlign:'top'}}>
                                            <table width="100%">
                                                <tr>
                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" style={{ marginRight: '10px' }} caption="Logo Image Width" hintText="Required" fieldName="sale.categoryPanelLogoImageWidth" placeholder="Enter width for logo image (required)" formManager={this.state.formManager} />
                                                    </td>

                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" style={{ marginRight: '20px' }} caption="Logo Image Height" hintText="Required" fieldName="sale.categoryPanelLogoImageHeight" placeholder="Enter height for logo image (required)" formManager={this.state.formManager} />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{width:'50%'}}>
                                                        <InputComponent inputType="text" style={{ marginRight: '10px' }} caption="Logo Image Bottom Padding" hintText="Required" fieldName="sale.categoryPanelLogoImageBottomPadding" placeholder="Enter bottom padding for logo image (required)" formManager={this.state.formManager} />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style={{width:'50%',verticalAlign:'top'}}>
                                            <div style={{borderStyle:'solid', borderWidth:'2px', borderColor:'#D0D0D0', padding:'5px'}}>
                                                <CdnImageUploader cloudName={configurationManager.getConfig().cdnUsersCloudName}
                                                    imageFolder={this.getImageFolder()}
                                                    imageUrl={this.props.skin.sale.categoryPanelLogoImage}
                                                    maxImageSize = {5000000}
                                                    radius="0"
                                                    width="400"
                                                    height="100"
                                                    onImageChange={(url)=>
                                                    {
                                                        this.props.skin.sale.categoryPanelLogoImage = url;
                                                        this.setState({});
                                                    }}>
                                                    <h4 className="display-5">No Image Found</h4>
                                                    <FormText color="muted">Please upload an logo image for panel.</FormText>
                                                </CdnImageUploader>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Font Name" hintText="Optional" style={{ marginRight: '20px' }} fieldName="sale.categoryPanelCaptionFontName" placeholder="Enter font name for category panel" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Font Size" hintText="Required" fieldName="sale.categoryPanelCaptionFontSize" placeholder="Enter font size for category panel (required)" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{marginRight:'20px'}}>
                                                <ColorPickerComponent
                                                    caption="Foreground Color"
                                                    fieldName="sale.categoryPanelCaptionForegroundColor"
                                                    hintText="Choose foreground color for panel (required)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Panel Heading" hintText="Required" fieldName="sale.categoryPanelCaption" placeholder="Enter text for panel heading (required)" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" style={{marginRight:'20px'}} caption="Heading Bottom Padding" hintText="Required" fieldName="sale.categoryPanelCaptionBottomPadding" placeholder="Enter bottom padding of heading (required)" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    
                                </table>
                            </CollapsibleCard>
                        </td>
                    </tr>

                    <tr>
                        <CollapsibleCard caption="Menu Category">
                            <table width="100%">
                                <tr>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" caption="Font Name" hintText="Optional" style={{ marginRight: '20px' }} fieldName="sale.menuCategoryFontName" placeholder="Enter font name for menu category" formManager={this.state.formManager} />
                                    </td>
                                    <td style={{width:'50%'}}>
                                    <InputComponent inputType="text" caption="Font Size" hintText="Optional" fieldName="sale.menuCategoryFontSize" placeholder="Enter font size for menu category (required)" formManager={this.state.formManager} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'50%'}}>
                                        <div style={{marginRight:'20px'}}>
                                            <ColorPickerComponent
                                                    caption="Background Color"
                                                    fieldName="sale.menuCategoryBackgroundColor"
                                                    hintText="Choose background color for menu category (Optional)"
                                                    formManager={this.state.formManager}
                                                />
                                        </div>
                                    </td>
                                    <td style={{width:'50%'}}>
                                        <div>
                                            <ColorPickerComponent
                                                    caption="Foreground Color"
                                                    fieldName="sale.menuCategoryForegroundColor"
                                                    hintText="Choose foreground color for menu category (required)"
                                                    formManager={this.state.formManager}
                                                />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'50%'}}>
                                        <div style={{marginRight:'20px'}}>
                                            <ColorPickerComponent
                                                    caption="Selected Color"
                                                    fieldName="sale.menuCategorySelectedCategoryColor"
                                                    hintText="Choose selected color for menu category (required)"
                                                    formManager={this.state.formManager}
                                                />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </CollapsibleCard>
                    </tr>

                    <tr>
                        <CollapsibleCard caption="Category Items Panel">
                            <table width="100%">
                                <tr>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" caption="Top Padding" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.categoryItemsPanelTopPadding" placeholder="Enter top padding for category items panel (required)" formManager={this.state.formManager} />
                                    </td>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" caption="Side Padding" hintText="Required" fieldName="sale.categoryItemsPanelSidePadding" placeholder="Enter side padding for category items panel (required)" formManager={this.state.formManager} />
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" caption="Font Name" hintText="Optional" style={{ marginRight: '20px' }} fieldName="sale.categoryItemsPanelCaptionFontName" placeholder="Enter font name for category item caption panel" formManager={this.state.formManager} />
                                    </td>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" caption="Font Size" hintText="Required" fieldName="sale.categoryItemsPanelCaptionFontSize" placeholder="Enter font size for category items caption panel" formManager={this.state.formManager} />
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" caption="Panel Heading" hintText="Optional" style={{ marginRight: '20px' }}  fieldName="sale.categoryItemsPanelCaption" placeholder="Enter text for items panel heading" formManager={this.state.formManager} />
                                    </td>

                                    <td style={{width:'50%'}}>
                                        <div>
                                            <ColorPickerComponent
                                                    caption="Foreground Color"
                                                    fieldName="sale.categoryItemsPanelCaptionForegroundColor"
                                                    hintText="Choose foreground color for category items caption panel (required)"
                                                    formManager={this.state.formManager}
                                                />
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{width:'50%'}}>
                                        <InputComponent inputType="text" style={{marginRight:'20px'}} caption="Heading Bottom Padding" hintText="Required" fieldName="sale.categoryItemsPanelCaptionBottomPadding" placeholder="Enter bottom padding of heading" formManager={this.state.formManager} />
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                            </table>
                        </CollapsibleCard>
                    </tr>

                    <tr>
                        <td>
                            <CollapsibleCard caption="Category Item">
                                <table width="100%">
                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Item Bottom Padding" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.categoryItemBottomPadding" placeholder="Enter bottom padding for item" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Item Image Radius" hintText="Required" fieldName="sale.categoryItemImageRadius" placeholder="Enter radius for item image" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Item Image Width" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.categoryItemImageWidth" placeholder="Enter item image width" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Item Image Height" hintText="Required" fieldName="sale.categoryItemImageHeight" placeholder="Enter item image height" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Title Font Name" hintText="Optional" style={{ marginRight: '20px' }} fieldName="sale.categoryItemTitleFontName" placeholder="Enter font name for item title" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Title Font Size" hintText="Required" fieldName="sale.categoryItemTitleFontSize" placeholder="Enter font size for item title" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Notes Font Name" hintText="Optional" style={{ marginRight: '20px' }} fieldName="sale.categoryItemNotesFontName" placeholder="Enter font name for item notes" formManager={this.state.formManager} />
                                        </td>
                                        <td>
                                            <InputComponent inputType="text" caption="Notes Font Size" hintText="Required" fieldName="sale.categoryItemNotesFontSize" placeholder="Enter font size for item notes" formManager={this.state.formManager} />
                                        </td>
                                    </tr>
                                
                                </table>
                            </CollapsibleCard>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <CollapsibleCard caption="Bottom Panel Panel">
                                <table width="100%">
                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Top Padding" hintText="Required" style={{ marginRight: '20px' }} fieldName="sale.bottomPanelTopPadding" placeholder="Enter top padding for bottom panel" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Side Padding" hintText="Required" fieldName="sale.bottomPanelSidePadding" placeholder="Enter side padding for bottom panel" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Font Name" hintText="Optional" style={{ marginRight: '20px' }} fieldName="sale.bottomPanelFontName" placeholder="Enter font name for bottom panel" formManager={this.state.formManager} />
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <InputComponent inputType="text" caption="Font Size" hintText="Required" fieldName="sale.bottomPanelFontSize" placeholder="Enter font size for bottom panel" formManager={this.state.formManager} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{marginRight:'20px'}}>
                                                <ColorPickerComponent
                                                    caption="Start Button Background Color"
                                                    fieldName="sale.bottomPanelStartButtonBackgroundColor"
                                                    hintText="Choose background color for Start Again Button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <div>
                                                <ColorPickerComponent
                                                    caption="Start Button Foreground Color"
                                                    fieldName="sale.bottomPanelStartButtonForegroundColor"
                                                    hintText="Choose foreground color for Start Again Button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{width:'50%'}}>
                                            <div style={{marginRight:'20px'}}>
                                                <ColorPickerComponent
                                                    caption="Payment Button Background Color"
                                                    fieldName="sale.bottomPanelPaymentBackgroundColor"
                                                    hintText="Choose background color for Payment Button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                        <td style={{width:'50%'}}>
                                            <div>
                                                <ColorPickerComponent
                                                    caption="Payment Button Foreground Color"
                                                    fieldName="sale.bottomPanelPaymentForegroundColor"
                                                    hintText="Choose foreground color for Payment Button (required)"
                                                    formManager={this.state.formManager}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                </table>
                            </CollapsibleCard>
                        </td>
                    </tr>
                </table>);
    }

    renderPaymentConfirmationPageDesigner()
    {
        if (!this.props.skin.paymentConfirmation)
            this.props.skin.paymentConfirmation = {};

        return (<table width="100%">

            <tr>
                <CollapsibleCard caption="Captions">
                    <table width="100%">
                        <tr>
                            <td style={{ width: '50%' }}>
                                <InputComponent inputType="text"
                                    caption="Dine In Payment Caption"
                                    hintText="Optional"
                                    style={{ marginRight: '20px' }}
                                    fieldName="paymentConfirmation.dineInCaption"
                                    placeholder="Enter Dine In Payment Caption"
                                    formManager={this.state.formManager} />
                            </td>
                            <td style={{ width: '50%' }}>
                                <InputComponent inputType="text"
                                    caption="Take Away Payment Caption"
                                    hintText="Optional"
                                    fieldName="paymentConfirmation.takeAwayCaption"
                                    placeholder="Enter Take Away Payment Caption"
                                    formManager={this.state.formManager} />
                            </td>
                        </tr>
                    </table>
                </CollapsibleCard>
            </tr>
        </table>);
    }

    getImageFolder()
    {
        return `franchisors/${this.props.skin.franchisorId}/signage/images`
    }

    

    onSaveClicked()
    {
        if (!this.validateSkin())
            return;


        viewUtil.showConfirmDialogue("Publish", "You are about to publish KIOSK skin. Are you sure you want to continue?", ()=>
        {
            var blob = {};
            blob.id = this.props.skin.id;               // PK
            blob.franchisorId = this.props.skin.franchisorId;
            blob.name = this.props.skin.name;    
            blob.notes = this.props.skin.notes;    
            blob.type = constants.blogTypes.KioskSkins;
            blob.document = JSON.stringify(this.props.skin);

            viewUtil.showSystemModalSpinner("Please wait, publishing");
            rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs`, blob)
                    .then((updatedSkin) => 
                            {
                                viewUtil.closeModalSpinner();
                                viewUtil.showSuccessAlert(`'${blob.name}' published successfully`);
                                this.props.onSaved(this.props.skin);
                            }, error =>
                            {
                                viewUtil.closeModalSpinner();
                                viewUtil.showErrorAlert(`Failed to publish: ${error}`);
                                this.props.onCancel();
                            });

        })

    }

    validateSkin()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.skin.name))
        {
            viewUtil.showErrorAlert("Skin name cannot be left empty");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.playerId))
        {
            viewUtil.showErrorAlert("Skin signage player name cannot be left empty.");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.width) || !validator.isInteger(this.props.skin.width))
        {
            viewUtil.showErrorAlert("Skin screen width is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.height) || !validator.isInteger(this.props.skin.height))
        {
            viewUtil.showErrorAlert("Skin screen height is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.fontName))
        {
            viewUtil.showErrorAlert("Skin font name cannot be left empty.");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.backgroundColor))
        {
            viewUtil.showErrorAlert("Skin background cannot be left empty");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.notes))
        {
            viewUtil.showErrorAlert("Skin notes cannot be left empty");
            return false;
        }

        if (!this.validateHomePageSkin())
            return false;

        if (!this.validateRewardsPageSkin())
            return false;

        if (!this.validateSalePageSkin())
            return false;

        return true;

    }

    validateRewardsPageSkin()
    {
        if(this.props.skin.rewards.skipButtonImage)
        {
            if (stringUtil.isStringNullOrEmpty(this.props.skin.rewards.skipButtonWidth) || !validator.isInteger(this.props.skin.rewards.skipButtonWidth))
            {
                viewUtil.showErrorAlert("Rewards Page: 'Skip Rewards Button' button width is missing or invalid");
                return false;
            }
    
            if (stringUtil.isStringNullOrEmpty(this.props.skin.rewards.skipButtonHeight) || !validator.isInteger(this.props.skin.rewards.skipButtonHeight))
            {
                viewUtil.showErrorAlert("Rewards Page: 'Skip Rewards Button' button height is missing or invalid");
                return false;
            }
    
            if (stringUtil.isStringNullOrEmpty(this.props.skin.rewards.skipButtonLeft) || !validator.isInteger(this.props.skin.rewards.skipButtonLeft))
            {
                viewUtil.showErrorAlert("Rewards Page: 'Skip Rewards Button' button left is either missing or invalid");
                return false;
            }
    
            if (stringUtil.isStringNullOrEmpty(this.props.skin.rewards.skipButtonTop) || !validator.isInteger(this.props.skin.rewards.skipButtonTop))
            {
                viewUtil.showErrorAlert("Rewards Page: 'Skip Rewards Button' button top is either missing or invalid");
                return false;
            }    
        }

        return true;
    }

    validateHomePageSkin()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.orderHereButtonImage))
        {
            viewUtil.showErrorAlert("Home Page: 'Order Here' button image cannot be left empty");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.orderHereButtonWidth) || !validator.isInteger(this.props.skin.home.orderHereButtonWidth))
        {
            viewUtil.showErrorAlert("Home Page: 'Order Here' button width is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.orderHereButtonHeight) || !validator.isInteger(this.props.skin.home.orderHereButtonHeight))
        {
            viewUtil.showErrorAlert("Home Page: 'Order Here' button height is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.orderHereButtonLeft) || !validator.isInteger(this.props.skin.home.orderHereButtonLeft))
        {
            viewUtil.showErrorAlert("Home Page: 'Order Here' button left is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.orderHereButtonTop) || !validator.isInteger(this.props.skin.home.orderHereButtonTop))
        {
            viewUtil.showErrorAlert("Home Page: 'Order Here' button top is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchDialogueWidth) || !validator.isInteger(this.props.skin.home.dispatchDialogueWidth))
        {
            viewUtil.showErrorAlert("Home Page: Dispatch Dialogue Width is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchDialoguePadding) || !validator.isInteger(this.props.skin.home.dispatchDialoguePadding))
        {
            viewUtil.showErrorAlert("Home Page: Dispatch Dialogue Padding is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchButtonWidth) || !validator.isInteger(this.props.skin.home.dispatchButtonWidth))
        {
            viewUtil.showErrorAlert("Home Page: Dispatch Dialogue Button Width is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchButtonHeight) || !validator.isInteger(this.props.skin.home.dispatchButtonHeight))
        {
            viewUtil.showErrorAlert("Home Page: Dispatch Dialogue Button Height is either missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchTakeAwayButtonBackgroundColor))
        {
            viewUtil.showErrorAlert("Home Page: TakeAway button background color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchTakeAwayButtonForegroundColor))
        {
            viewUtil.showErrorAlert("Home Page: TakeAway button foreground color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchDineInButtonBackgroundColor))
        {
            viewUtil.showErrorAlert("Home Page: DineIn button background color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchDineInButtonForegroundColor))
        {
            viewUtil.showErrorAlert("Home Page: DineIn button foreground color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.home.dispatchButtonFontSize) || !validator.isInteger(this.props.skin.home.dispatchButtonFontSize))
        {
            viewUtil.showErrorAlert("Home Page: Dispatch Button font size is either missing or invalid");
            return false;
        }

        if(this.props.skin.home.dispatchDineInDisabled && this.props.skin.home.dispatchTakeAwayDisabled)
        {
            viewUtil.showErrorAlert("Home Page: Both the dispatch options - Dine In and Take Away cannot be disabled");
            return false;
        }

        return true;
    }

    validateSalePageSkin()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.contentPanelHeight) || !validator.isInteger(this.props.skin.sale.contentPanelHeight))
        {
            viewUtil.showErrorAlert("Sale Page: Content panel height is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelWidth) || !validator.isInteger(this.props.skin.sale.categoryPanelWidth))
        {
            viewUtil.showErrorAlert("Sale Page: Category panel width is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelTopPadding) || !validator.isInteger(this.props.skin.sale.categoryPanelTopPadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category panel top padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelSidePadding) || !validator.isInteger(this.props.skin.sale.categoryPanelSidePadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category panel side padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelLogoImage) )
        {
            viewUtil.showErrorAlert("Sale Page: Category panel logo image is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelLogoImageWidth) || !validator.isInteger(this.props.skin.sale.categoryPanelLogoImageWidth))
        {
            viewUtil.showErrorAlert("Sale Page: Category panel logo image width is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelLogoImageHeight) || !validator.isInteger(this.props.skin.sale.categoryPanelLogoImageHeight))
        {
            viewUtil.showErrorAlert("Sale Page: Category panel logo image height is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelLogoImageBottomPadding) || !validator.isInteger(this.props.skin.sale.categoryPanelLogoImageBottomPadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category panel logo image bottom padding is missing or invalid");
            return false;
        }

        if (!stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelCaption))
        {
            if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelCaptionFontSize) || !validator.isInteger(this.props.skin.sale.categoryPanelCaptionFontSize))
            {
                viewUtil.showErrorAlert("Sale Page: Category panel caption font size is missing or invalid");
                return false;
            }

            if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelCaptionForegroundColor) )
            {
                viewUtil.showErrorAlert("Sale Page: Category panel caption foreground color is missing");
                return false;
            }

            if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryPanelCaptionBottomPadding) || !validator.isInteger(this.props.skin.sale.categoryPanelCaptionBottomPadding))
            {
                viewUtil.showErrorAlert("Sale Page: Category panel caption bottom padding is missing or invalid");
                return false;
            }

        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.menuCategoryFontSize) || !validator.isInteger(this.props.skin.sale.menuCategoryFontSize))
        {
            viewUtil.showErrorAlert("Sale Page: Menu Category Font Size is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.menuCategoryForegroundColor))
        {
            viewUtil.showErrorAlert("Sale Page: Menu Category Foreground color cannot be left empty");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.menuCategorySelectedCategoryColor))
        {
            viewUtil.showErrorAlert("Sale Page: Selected Menu Category color cannot be left empty");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemsPanelTopPadding) || !validator.isInteger(this.props.skin.sale.categoryItemsPanelTopPadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category items panel top padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemsPanelSidePadding) || !validator.isInteger(this.props.skin.sale.categoryItemsPanelSidePadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category items panel side padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemsPanelCaptionFontSize) || !validator.isInteger(this.props.skin.sale.categoryItemsPanelCaptionFontSize))
        {
            viewUtil.showErrorAlert("Sale Page: Category items panel caption font size is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemsPanelCaptionForegroundColor))
        {
            viewUtil.showErrorAlert("Sale Page: Category items panel caption foreground color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemsPanelCaptionBottomPadding) || !validator.isInteger(this.props.skin.sale.categoryItemsPanelCaptionBottomPadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category items heading panel bottom padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemBottomPadding) || !validator.isInteger(this.props.skin.sale.categoryItemBottomPadding))
        {
            viewUtil.showErrorAlert("Sale Page: Category Item bottom padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemImageRadius) || !validator.isInteger(this.props.skin.sale.categoryItemImageRadius))
        {
            viewUtil.showErrorAlert("Sale Page: Category item image radius is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemImageWidth) || !validator.isInteger(this.props.skin.sale.categoryItemImageWidth))
        {
            viewUtil.showErrorAlert("Sale Page: Category Item image width is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemImageHeight) || !validator.isInteger(this.props.skin.sale.categoryItemImageHeight))
        {
            viewUtil.showErrorAlert("Sale Page: Category item image height is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemTitleFontSize) || !validator.isInteger(this.props.skin.sale.categoryItemTitleFontSize))
        {
            viewUtil.showErrorAlert("Sale Page: Category Item title font size is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.categoryItemNotesFontSize) || !validator.isInteger(this.props.skin.sale.categoryItemNotesFontSize))
        {
            viewUtil.showErrorAlert("Sale Page: Category Item notes font size is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelTopPadding) || !validator.isInteger(this.props.skin.sale.bottomPanelTopPadding))
        {
            viewUtil.showErrorAlert("Sale Page: Bottom panel top padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelSidePadding) || !validator.isInteger(this.props.skin.sale.bottomPanelSidePadding))
        {
            viewUtil.showErrorAlert("Sale Page: Bottom panel side padding is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelFontSize) || !validator.isInteger(this.props.skin.sale.bottomPanelFontSize))
        {
            viewUtil.showErrorAlert("Sale Page: Sale Page bottom font size is missing or invalid");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelStartButtonBackgroundColor))
        {
            viewUtil.showErrorAlert("Sale Page: Start Again button background color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelStartButtonForegroundColor))
        {
            viewUtil.showErrorAlert("Sale Page: Start Again button foreground color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelPaymentBackgroundColor))
        {
            viewUtil.showErrorAlert("Sale Page: Payment button background color is missing");
            return false;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.skin.sale.bottomPanelPaymentForegroundColor))
        {
            viewUtil.showErrorAlert("Sale Page: Payment button foreground color is missing");
            return false;
        }

        

        return true;
    }

}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(KioskSkin);