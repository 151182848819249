import React, { Component } from 'react';

import GridComponent from '../grid/gridComponent';
import ExcelExportButton from '../button/excelExportButton';
import ExternalIntegrationLogDataForm from './externalIntegrationLogDataForm';

import * as viewUtil from '../../utils/view/viewUtil';
import * as columnWidthConstants from '../../utils/constants/columnWidthConstants';
import * as dataTypeConstants from '../../utils/constants/dataTypeConstants';
import * as reportExcelExport from '../../views/reports/reportUtils/components/reportExcelExport';

export default class ExternalIntegrationLogsForm extends Component
{
    render()
    {
        return (
            <div className='d-flex flex-column'>

                <div className='align-self-end mb-3 me-1' style={{ marginTop: "-60px"}}>
                    <ExcelExportButton
                        onExport=
                        {
                            async () => reportExcelExport.exportWorkbook("External Integration Logs", this.gridApi)
                        } />
                </div>

                <div style={{ width: "850px", height: "300px" }}>
                    <GridComponent
                        rows={this.props.integrationLogs}
                        headers={this.getColumnHeaders()}
                        onRowDoubleClicked={this.onRowDoubleClicked}
                        shouldReArrangeHeaders={false}
                        paginationEnabled={true}
                        paginationSize={5}
                        onGridReady={this.onGridReady} />
                </div>
            </div>
        );
    }

    onGridReady = (params) =>
    {
        this.gridApi = params.api;
    }

    onRowDoubleClicked = (event) =>
    {
        const integrationLog = event.data;
        const logData = integrationLog.jsonArray;

        // Show ExternalIntegrationLogDataForm component through readonly model form

        viewUtil.openModalForm(
            "Integration Log Data",
            () => <ExternalIntegrationLogDataForm logData={logData} />,
            null, null, true, null);
    }

    getColumnHeaders = () =>
    {
        let header;

        const headers = [];

        header = {};
        header.field = "dateTime";
        header.headerName = "Time";
        header.pinned = true;
        header.type = dataTypeConstants.dateTime;
        header.width = columnWidthConstants.dateTime;
        headers.push(header);

        header = {};
        header.field = "integrationType";
        header.headerName = "Integration Type";
        header.width = columnWidthConstants.code - 25;
        headers.push(header);

        header = {};
        header.field = "externalEntity";
        header.headerName = "External Entity";
        header.width = columnWidthConstants.code - 25;
        headers.push(header);

        header = {};
        header.field = "message";
        header.headerName = "Message";
        header.width = columnWidthConstants.notes + 200;
        headers.push(header);

        return headers;
    }
}
