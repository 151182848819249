export const borderStyle = { margin: "0px 0px 50px 0px" };
export const contentStyle = { margin: "2px 0px 0px 0px", overflow: "auto", borderStyle: "groove" };
export const headerStyle =
{
    textAlign: "left",
    paddingTop: "15px", paddingBottom: "15px", paddingLeft: "15px",
    fontSize: "large", fontWeight: "bold",
    color: "white", backgroundColor: "CadetBlue"
};

export const readOnlyRowBackgroundColor = "LightGray";

