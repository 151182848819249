import React, { Component } from "react";
import { Button } from 'reactstrap';

import UserRole from "./UserRole";

const securityConstants = require('../../../../utils/domain/security/securityConstants');

const helperUtils = require('../utils/helperUtils');

export default class UserRoles extends Component
{
    constructor(props)
    {
        super(props);
        this.setAggregatedRoles = this.setAggregatedRoles.bind(this);
    }
    
    get isRowReadOnly() { return this.props.isRowReadOnly; }
    get aggregatedRoles() { return this.props.aggregatedRoles; }

    setAggregatedRoles(roles)
    {
        this.props.setAggregatedRoles(roles);
    }

    enableAdd = () =>
    {
        const { systemRoles, franchisorRoles } = securityConstants.builtInUserRoles;

        // Cannot Add when data is readOnly
        if (this.isRowReadOnly) return false;

        // Cannot Add when roles are pending completion
        if (this.aggregatedRoles.some(role =>
            role.orgContext == null ||
            role.roleItems == null ||
            role.roleItems.length === 0))
            return false;

        // Cannot Add when any of the exclusive roles is selected
        if (this.aggregatedRoles.some(role =>
            role.roleItems.some(roleItem =>
                roleItem.roleCode === systemRoles.SystemAdmin.roleCode ||
                roleItem.roleCode === systemRoles.SupportAdmin.roleCode ||
                roleItem.roleCode === franchisorRoles.Owner.roleCode)))
            return false;

        // Cannot Add when there are no available orgNodes to select from
        if (helperUtils.getAvailableOrgNodes(this.aggregatedRoles).length === 0) return false;

        return true;
    }

    onAdd = () =>
    {
        const aggregatedRole = {
            orgName: null,
            orgContext: null,
            roleItems: [],
        };

        this.setAggregatedRoles([...this.aggregatedRoles, aggregatedRole]);
        this.setState({});
    }

    onChange = () =>
    {
        // Invoked by UserRole component when orgNode or roleItem changes in any of the aggregatedRoles

        let aggregatedRole;

        const { systemRoles, franchisorRoles } = securityConstants.builtInUserRoles;

        aggregatedRole = this.aggregatedRoles.find(aggregatedRole =>
            aggregatedRole.roleItems.some(roleItem =>
                roleItem.roleCode === systemRoles.SystemAdmin.roleCode ||
                roleItem.roleCode === systemRoles.SupportAdmin.roleCode ||
                roleItem.roleCode === franchisorRoles.Owner.roleCode));

        if (aggregatedRole != null)
        {
            // This role is exclusive and cannot co-exist with any other role
            this.setAggregatedRoles([aggregatedRole]);
        }

        this.setState({});
    }

    onDelete = (aggregatedRole) =>
    {
        this.setAggregatedRoles(this.aggregatedRoles.filter(role => role !== aggregatedRole));
        
        this.setState({});
    }

    render()
    {
        return (
            <div className="d-flex flex-column px-2">

                <div className="d-flex flex-column" style={{ fontSize: "0.90em" }}>
                    {
                        this.aggregatedRoles.map(aggregatedRole =>
                            <div key={aggregatedRole.orgName} className="w-100">
                                <UserRole aggregatedRole={aggregatedRole} onChange={this.onChange} onDelete={this.onDelete} {...this.props} />
                            </div>)
                    }
                </div>

                <div className="border-top d-flex justify-content-end" style={{ marginRight: "32px", paddingTop: "10px" }}>
                    <Button color="primary" disabled={!this.enableAdd()} onClick={this.onAdd}>
                        <i className="fa fa-plus"></i>&nbsp;Add New Role
                    </Button>
                </div>

            </div>
        );
    }
}