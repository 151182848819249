import React, { Component } from 'react';

import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../components/form/multiSelectComponent';
import BooleanSelectComponent from '../../../../components/form/booleanSelectComponent';
import FormManager from '../../../../utils/view/formManager';

import catalogSelectors from '../../../../utils/state/stateSelectors/catalogSelectors';

const domainConstants = require('../../../../utils/domain/constants');

class ApplianceServiceProfile extends Component
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.getSelectedHazardTypes = this.getSelectedHazardTypes.bind(this);
        this.onHazardTypesSelectionChanged = this.onHazardTypesSelectionChanged.bind(this);

        this.hazardTypes = domainConstants.getHazardTypeOptions();
        this.serviceTypes = catalogSelectors.selectLookupData().serviceTypes;
        this.frequencies = catalogSelectors.selectLookupData().frequencies;
    }

    getSelectedHazardTypes()
    {
        const hazardTypeCodes = this.props.data.hazardTypes || [];
        return this.hazardTypes.filter(x => hazardTypeCodes.includes(x.value));
    }

    onHazardTypesSelectionChanged(selectedOptions)
    {
        let hazardTypeCodes = [];

        if (selectedOptions.length > 0)
        {
            hazardTypeCodes = selectedOptions.map(option => option.value);
        }

        this.props.data.hazardTypes = hazardTypeCodes;

        this.setState({});
    }

    render()
    {
        return (
            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Name"
                                hintText="Enter name of the service (required)"
                                fieldName="name"
                                placeholder="Enter name"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <SelectComponent
                                optionValues={this.serviceTypes}
                                optionFieldName="id"
                                optionDisplayFieldName="name"
                                caption="Service Type"
                                hintText="Select service type (required)"
                                fieldName="serviceTypeId"
                                clearable={false}
                                comboReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Frequency"
                                fieldName="frequencyCode"
                                optionFieldName="code"
                                optionDisplayFieldName="code"
                                optionValues={this.frequencies}
                                hintText="Enter frequency of the service (required)"
                                comboReadOnly={this.props.isRowReadOnly}
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                fieldName="isMandatory"
                                caption="Is Mandatory"
                                hintText="Is service compulsory as per regulation or company policy"
                                clearable={false}
                                comboReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="date"
                                caption="Next Due Date"
                                fieldName="nextDueDate"
                                hintText="Enter Next Service Due Date (required)"
                                placeholder="Enter the next due date for appliance service"
                                inputReadOnly={this.props.isRowReadOnly || !this.props.data.isNew}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Notify Duration (Days)"
                                hintText="Enter the number of days before one must be notified for service"
                                fieldName="notifyDays"
                                placeholder="Enter Days"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <MultiSelectComponent
                                caption="Hazard Types"
                                valueFieldName="value"
                                displayFieldName="label"
                                options={this.hazardTypes}
                                selectedOptions={this.getSelectedHazardTypes()}
                                onSelectionChanged={this.onHazardTypesSelectionChanged}
                                hintText="Select Hazard Types"
                                comboReadOnly={this.props.isRowReadOnly} />

                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" >
                            <InputComponent
                                inputType="text"
                                multiLine={true}
                                caption="Notes"
                                rows={4}
                                fieldName="notes"
                                placeholder="Enter any notes."
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                </tbody>
            </table>
        )
    }
}

export default ApplianceServiceProfile;