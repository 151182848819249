import React, { Component } from 'react';
import { connect } from 'react-redux'

import GridView from '../../../../components/gridView/gridView.js'
import VoucherCode from './components/VoucherCode.jsx'

const validator = require('../../../../utils/validator/validator');
const commonUtility = require('../../../../utils/domain/commonUtility');
const dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
const constants = require('../../../../utils/domain/constants.js');
const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions.js');
const stringUtil = require('../../../../utils/string/stringUtil.js');
const apiProxy = require('../../../../utils/api/apiProxy.js');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const guidUtil = require('../../../../utils/guid/guidUtil.js');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');


class VoucherCodes extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadVoucherCodesAsync = this.loadVoucherCodesAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Voucher Codes";
        gridViewOptions.messageContext = "Voucher Code";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadVoucherCodesAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        
        gridViewOptions.getNewRow = () =>
        {
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
            const franchisorId = currentOrgNode.franchisorId;
            const franchiseeId = currentOrgNode.franchiseeId;

            var newRow = {};

            newRow.id = guidUtil.generateGuid();
            newRow.code = "";

            newRow.franchisorId = franchisorId;

            if (franchiseeId != null)
                newRow.franchiseeId = franchiseeId;

            newRow.type = constants.voucherCodeType.percentage;
            newRow.value = [0.0];

            newRow.startDate = dateUtil.convertToLocalStandard(dateUtil.getToday());
            newRow.expiryDate = null;
            newRow.isDisabled = false;

            newRow.minimumOrderValue = 15.0;
            newRow.inclusiveVenueCodes = [];
            newRow.inclusiveChannels = [];
            newRow.inclusiveDispatchTypes = [];

            newRow.inclusivePropertyIds = [];
            newRow.exclusivePropertyIds = [];

            newRow.claimType = constants.voucherCodeClaimType.oneTimeOnlyGuest;
            this.claimMaxLimit = null;

            newRow.marketingCampaignCaption = "";
            newRow.notes = "";

            return this.enrichRowForDisplay(newRow);
        };

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.getComponentTitle = (isNew, row) => isNew ? "Add Voucher Code" : "Edit Voucher Code";
        gridViewOptions.getComponent = (isNew, row) => <VoucherCode isReadOnly={this.props.isReadOnly} data={row} />;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;

        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;        

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly(row)
    {
        if(this.props.isReadOnly) return true;

        if(currentOrgNodeSelectors.isCurrentFranchiseeSelected() && !stringUtil.isStringNullOrEmpty(row.franchiseeId))
            return false;

        return true;
    }

    getUrlPrefix(orgContext)
    {
        if(!stringUtil.isStringNullOrEmpty(orgContext.franchiseeId))
            return rmsApiProxy.getFranchiseeOrgContextUrl();

        return rmsApiProxy.getFranchisorOrgContextUrl();
    }

    async loadVoucherCodesAsync()
    {
        try
        {            
            const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
            const results = await rmsApiProxy.get(`${this.getUrlPrefix(orgContext)}/promotions/vouchers`);
            const voucherCodes = [];
            
            results.forEach(voucherCode =>
            {
                voucherCodes.push(this.enrichRowForDisplay(voucherCode));
            });            
            
            return arraySort(voucherCodes, 'codes');

        } catch (error)
        {
            throw error;
        }
    }

    enrichRowForDisplay(voucherCode)
    {
        voucherCode.enrichedIncludedProperties = "ALL";
        voucherCode.enrichedExcludedProperties = "NONE";
        voucherCode.enrichedInclusiveVenueCodes = "ALL";
        voucherCode.enrichedInclusiveChannels = "ALL";
        voucherCode.enrichedValue = "";

        if (voucherCode.inclusivePropertyIds.length > 0)
        {
            var properties = [];

            voucherCode.inclusivePropertyIds.forEach(inclusivePropertyId =>
            {
                var property = commonUtility.getProperty(inclusivePropertyId);
                if (!property) return;
                properties.push(property);
            });

            voucherCode.enrichedIncludedProperties = properties.map(p => p.code).join(",")
            voucherCode.enrichedExcludedProperties = "Others";
        }

        if (voucherCode.exclusivePropertyIds.length > 0)
        {
            properties = [];

            voucherCode.exclusivePropertyIds.forEach(exclusivePropertyId =>
            {
                var property = commonUtility.getProperty(exclusivePropertyId);
                if (!property) return;
                properties.push(property);
            });

            voucherCode.enrichedExcludedProperties = properties.map(p => p.code).join(",");
            voucherCode.enrichedIncludedProperties = "Others";
        }

        if (voucherCode.inclusiveVenueCodes != null && voucherCode.inclusiveVenueCodes.length > 0)
        {
            voucherCode.enrichedInclusiveVenueCodes = voucherCode.inclusiveVenueCodes.join(",")
        }

        if (voucherCode.inclusiveChannels != null && voucherCode.inclusiveChannels.length > 0)
        {
            voucherCode.enrichedInclusiveChannels = voucherCode.inclusiveChannels.join(",")
        }

        if (voucherCode.inclusiveDispatchTypes != null && voucherCode.inclusiveDispatchTypes.length > 0)
        {
            voucherCode.enrichedInclusiveDispatchTypes = voucherCode.inclusiveDispatchTypes.join(",")
        }

        if (voucherCode.value != null && voucherCode.value.length > 0)
        {
            voucherCode.enrichedValue = voucherCode.value.map(v => v + "").join(",")
        }

        return voucherCode;
    }

    validateRow(isNew, row)
    {

        if (stringUtil.isStringNullOrEmpty(row.id))
            return "Voucher ID cannot be left empty or null";

        if (stringUtil.isStringNullOrEmpty(row.code))
            return "Voucher code cannot be left empty or null";

        if (stringUtil.isStringNullOrEmpty(row.type))
            return "Voucher type cannot be left empty or null";

        if (stringUtil.isStringNullOrEmpty(row.enrichedValue))
            return "Voucher value cannot be left empty or null";

        if (row.enrichedValue.split(",").some(value => !validator.isNumeric(value)))
            return "Voucher value must be value numeric values";

        if (stringUtil.isStringNullOrEmpty(row.startDate) || !dateUtil.isValidDate(row.startDate))
            return "Voucher start date is not valid";

        if (!stringUtil.isStringNullOrEmpty(row.expiryDate) && !dateUtil.isValidDate(row.expiryDate))
            return "Voucher expiry date is not valid";

        if (!stringUtil.isStringNullOrEmpty(row.minimumOrderValue) && !validator.isNumeric(row.minimumOrderValue))
            return "Minimum order value is not valid";

        if (stringUtil.isStringNullOrEmpty(row.claimType))
            return "Voucher redeem type cannot be left empty or null";

        if (!stringUtil.isStringNullOrEmpty(row.claimMaxLimit) && !validator.isNumeric(row.claimMaxLimit))
            return "Redeem max limit is invalid";

        return null;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            row.value = row.enrichedValue.split(",").map (v => v * 1.0);
            const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
            let updatedVoucher = await rmsApiProxy.post(`${this.getUrlPrefix(orgContext)}/promotions/vouchers`, row);

            if (!remoteData)
                remoteData = [];

            var existingIndex = remoteData.findIndex(u => u.id == updatedVoucher.id);
            updatedVoucher = this.enrichRowForDisplay(updatedVoucher);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedVoucher);
            else
                remoteData.push(updatedVoucher);

            var response = {};
            response.remoteData = arraySort(remoteData, 'codes');;
            response.addUpdatedRow = updatedVoucher;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    constructGridColumnHeaders()
    {
        let header;

        const headers = [];

        header = {};
        header.field = "code";
        header.headerName = "Code";
        header.width = columnWidthConstants.code;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "type";
        header.headerName = "Voucher Type";
        header.width = columnWidthConstants.type;
        header.valueFormatter = (params) => params.value ? constants.getVoucherTypes().find(x => x.value === params.value).label : "";
        headers.push(header);

        header = {};
        header.field = "enrichedValue";
        header.headerName = "Voucher Value";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "minimumOrderValue";
        header.headerName = "Min Order Value";
        header.type = dataTypeConstants.floatNumber;
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "claimType";
        header.headerName = "Claim Method";
        header.width = columnWidthConstants.type;
        header.valueFormatter = (params) => params.value ? constants.getVoucherClaimTypes().find(x => x.value === params.value).label : "";
        headers.push(header);

        header = {};
        header.field = "claimMaxLimit";
        header.headerName = "Claim Limit";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "startDate";
        header.headerName = "Start Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        headers.push(header);

        header = {};
        header.field = "expiryDate";
        header.headerName = "Expiry Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "enrichedInclusiveVenueCodes";
        header.headerName = "Venue Codes";
        header.width = columnWidthConstants.notes;
        headers.push(header);

        header = {};
        header.field = "enrichedInclusiveChannels";
        header.headerName = "Channels";
        header.width = columnWidthConstants.notes;
        headers.push(header);

        header = {};
        header.field = "enrichedInclusiveDispatchTypes";
        header.headerName = "Dispatch Types";
        header.width = columnWidthConstants.notes;
        headers.push(header);

        header = {};
        header.field = "enrichedIncludedProperties";
        header.headerName = "Participating Properties";
        header.width = columnWidthConstants.notes;
        headers.push(header);

        header = {};
        header.field = "enrichedExcludedProperties";
        header.headerName = "Non Participating Properties";
        header.width = columnWidthConstants.notes;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.width = columnWidthConstants.notes;
        headers.push(header);

        return headers;
    }
}

const mapStateToProps = state => 
{
    return {
        franchisors: catalogSelectors.selectFranchisors,
        franchisees: catalogSelectors.selectFranchisees,
        properties: catalogSelectors.selectProperties
    }
}

export default connect(mapStateToProps)(VoucherCodes)
