import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import CampaignTarget from '../../../components/CampaignTarget';

export default class CampaignOverviewStep extends Component 
{
    render() 
    {
        const campaign = this.props.data;

        return <Card>
            <CardHeader>
                Choose Campaign Target Audience
            </CardHeader>
            <CardBody>
                <CampaignTarget {...this.props} data={campaign.profile}
                    onChange={() =>
                    {
                        if (this.props.onChange)
                            this.props.onChange()
                    }} />
            </CardBody>
            <CardFooter>
                {this.renderNavigation()}
            </CardFooter>
        </Card>;
    }

    renderNavigation()
    {
        return (<table width="100%">
            <tr>
                <td align="right">
                    <Button color="secondary" style={{ marginRight: '10px' }} onClick={this.props.previousStep}>Back</Button>
                    <Button color="primary" onClick={this.props.nextStep}>Next</Button>
                </td>
            </tr>
        </table>);
    }
}
