import React from "react";
import DeliveryZoneTableRow from "./DeliveryZoneTableRow";
import { Table, Button } from "reactstrap";
const stringUtil = require('../../../../../../utils/string/stringUtil');
const storefrontDeliveryChargesHelper = require('../storefrontDeliveryChargesHelper');

export default function DeliveryZoneTable({ fixedDeliveryCharges, deliveryZones, setDeliveryZones })
{
    const disabled = !stringUtil.isStringNullOrEmpty(fixedDeliveryCharges);

    const onDistanceChange = (event, deliveryZoneItemId) =>
    {
        const deliveryZoneItemIndex = deliveryZones.findIndex(item => item.id === deliveryZoneItemId);
        if (deliveryZoneItemIndex === -1) return;
        const newDeliveryZone = Object.assign({}, deliveryZones[deliveryZoneItemIndex]);
        newDeliveryZone.distance = event.target.value.trim();
        const updatedDeliveryZones = deliveryZones.map((item) => item.id === newDeliveryZone.id ? newDeliveryZone : item);
        setDeliveryZones(updatedDeliveryZones);
    }

    const onChargesChange = (event, deliveryZoneItemId) =>
    {
        const deliveryZoneItemIndex = deliveryZones.findIndex(item => item.id === deliveryZoneItemId);
        if (deliveryZoneItemIndex === -1) return;
        const newDeliveryZone = Object.assign({}, deliveryZones[deliveryZoneItemIndex]);
        newDeliveryZone.charges = event.target.value.trim();
        const updatedDeliveryZones = deliveryZones.map((item) => item.id === newDeliveryZone.id ? newDeliveryZone : item);
        setDeliveryZones(updatedDeliveryZones);
    }

    const addNewBtnHandler = () =>
    {
        const newDeliveryZone = storefrontDeliveryChargesHelper.DeliveryZoneDataItem();
        const updatedDeliveryZones = [...deliveryZones];
        updatedDeliveryZones.push(newDeliveryZone);
        setDeliveryZones(updatedDeliveryZones);
    }

    const closeBtnHandler = (deliveryZoneItemId) =>
    {
        const updatedDeliveryZones = deliveryZones.filter(item => item.id !== deliveryZoneItemId);
        setDeliveryZones(updatedDeliveryZones);
    }

    return (
        <div>
            <Table
                responsive
                bordered
                size="sm"
                responsiveTag="div"
            >
                <thead>
                    <tr className="">
                        <th>#</th>
                        <th>Upto Distance (miles)</th>
                        <th>Charges</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {disabled ? <></> : (

                        deliveryZones.map((deliveryZone, index) => (
                            <DeliveryZoneTableRow
                                rowNumber={index + 1}
                                key={deliveryZone.id}
                                deliveryZone={deliveryZone}
                                closeBtnHandler={closeBtnHandler}
                                onDistanceChange={onDistanceChange}
                                onChargesChange={onChargesChange}
                            />
                        ))
                    )
                    }
                </tbody>
            </Table>
            <div className="d-flex flex-row-reverse">
                <Button
                    color="success"
                    size="sm"
                    outline
                    children="+ Add"
                    onClick={addNewBtnHandler}
                    style={{ marginTop: "5px" }}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

