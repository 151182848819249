import React, { useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, Controls, MiniMap, ReactFlowProvider } from "reactflow";
import { RootNode, StationNode, DeviceNode } from "./Nodes";
import { generateGuid } from "../../../../../utils/guid/guidUtil";
import { useNetworkDiagramContext } from "../../store";
import "reactflow/dist/style.css";
import styled from "styled-components";
import "../../networkDiagram.css";
import { DeviceTypes, TerminalTypes } from "../../constants";

const proOptions = { hideAttribution: true };

function generateNodePositions(nodes, edges, width, height, spacing) {
     function getChildrens(parentId) {
          let childrens = [];

          edges.filter((edge) => edge.source === parentId).forEach((edge) => {
               childrens.push(nodes.find((node) => node.id === edge.target));
          });

          return childrens;
     }

     let nodePositions = [];

     function positionStation(stations) {
          let maxH = height + spacing;
          for (let station of stations) {
               nodePositions.push({
                    ...station,
                    position: {
                         x: 0,
                         y: maxH,
                    },
               });

               maxH = positionChildren(0, getChildrens(station.id), maxH);
          }
     }

     function positionChildren(parentX, childrens, parentH) {
          if (childrens.length === 0) return parentH + height + spacing;
          const numChildren = childrens.length;
          const totalWidth = numChildren * width;
          let startX = parentX - totalWidth / 2;
          let startY = parentH + height + spacing;
          let retH = startY + spacing;

          for (const child of childrens) {
               nodePositions.push({
                    ...child,
                    position: {
                         x: startX + width / 2,
                         y: startY,
                    },
               });

               const childrenH = positionChildren(startX + width / 2, getChildrens(child.id), startY);
               retH = childrenH > retH ? childrenH : retH;

               startX += width;
          }
          return retH;
     }

     const rootNode = nodes.find((node) => node.type === "root");

     nodePositions.push({
          ...rootNode,
     });

     positionStation(getChildrens(rootNode.id));

     return nodePositions;
}

const customStationOrder = [TerminalTypes.pos, TerminalTypes.kds, TerminalTypes.kiosk, TerminalTypes.signage, TerminalTypes.kitchen, TerminalTypes.centralKitchen];

const Diagram = () => {
     const { state } = useNetworkDiagramContext();
     const [networkDiagram, setNetworkDiagram] = useState({
          nodes: [],
          edges: [],
     });
     const nodeTypes = useMemo(() => ({ root: RootNode, station: StationNode, device: DeviceNode }), []);

     useEffect(() => {
          const rootNodeId = generateGuid();
          const nodesArr = [
               {
                    id: rootNodeId,
                    position: { x: 0, y: 0 },
                    type: "root",
               },
          ];
          const edgesArr = [];

          const kotPrinters = state.devices.filter((device) => device.type === DeviceTypes.kotPrinter);

          const sortedStations = state.stations.sort((stationA, stationB) => {
               const indexA = customStationOrder.indexOf(stationA.type);
               const indexB = customStationOrder.indexOf(stationB.type);
               return indexA - indexB;
          });

          sortedStations.forEach((station) => {
               const stationNodeId = generateGuid();
               nodesArr.push({
                    id: stationNodeId,
                    position: { x: 0, y: 0 },
                    data: {
                         ...station,
                    },
                    type: "station",
               });
               edgesArr.push({ id: generateGuid(), source: rootNodeId, target: stationNodeId, animated: false, type: "step" });

               if (station.isExpanded) {
                    state.devices.forEach((device) => {
                         if (device.type === DeviceTypes.kotPrinter || device.type === "UNKNOWN") return;
                         if (device.stationId === station.id) {
                              const deviceNodeId = generateGuid();

                              nodesArr.push({
                                   id: deviceNodeId,
                                   position: { x: 0, y: 0 },
                                   data: {
                                        ...device,
                                   },
                                   type: "device",
                              });
                              edgesArr.push({ id: generateGuid(), source: stationNodeId, target: deviceNodeId, animated: true, type: "step" });

                              const deviceKots = kotPrinters.filter((kot) => kot.stationId === device.id);

                              deviceKots.forEach((kot) => {
                                   const kotNodeId = generateGuid();

                                   nodesArr.push({
                                        id: kotNodeId,
                                        position: { x: 0, y: 0 },
                                        data: {
                                             ...kot,
                                        },
                                        type: "device",
                                   });
                                   edgesArr.push({ id: generateGuid(), source: deviceNodeId, target: kotNodeId, animated: true, type: "step" });
                              });
                         }
                    });
               }
          });

          setNetworkDiagram({
               nodes: generateNodePositions(nodesArr, edgesArr, 700, 400, 40),
               edges: edgesArr,
          });
     }, [state.stations, state.devices]);

     return (
          <ReactFlowProvider>
               <Wrapper>
                    <ReactFlow minZoom={0.2} fitView draggable={false} nodesConnectable={false} nodesDraggable={false} nodes={networkDiagram.nodes} edges={networkDiagram.edges} nodeTypes={nodeTypes} proOptions={proOptions} panOnScroll zoomOnDoubleClick={false}>
                         <Controls />
                         <Background variant="dots" gap={12} size={1} />
                         <MiniMap />
                    </ReactFlow>
               </Wrapper>
          </ReactFlowProvider>
     );
};

export default Diagram;

const Wrapper = styled.div`
     height: calc(100vh - 200px);
`;
