import React, { Component } from 'react';

import SelectComponent from '../../../../../../components/form/selectComponent';
import InputComponent from '../../../../../../components/form/inputComponent';
import FormManager from '../../../../../../utils/view/formManager';
const orgSelectors = require('../../../../../../utils/state/stateSelectors/orgSelectors');

export default class PropertyLevelOverride extends Component
{
    constructor(props) 
    {
        //props = {isNewRow:bool, data:{operationalUnitType} stockItem:{stockItem} isRowReadOnly:bool}

        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
        this.formManager.onFieldChanged = (() =>
        {
            if (this.props.onFieldChanged)
                this.props.onFieldChanged();
        })
    }

    get stockItem()
    {
        return this.props.stockItem;
    }

    render()
    {
        return (
            <div style={{ minHeight: "200px" }}>
                {this.renderTable()}
            </div>
        );
    }

    renderTable()
    {
        const isNewRow = this.props.isNewRow;
        const isRowReadOnly = this.props.isRowReadOnly;
        const formManager = this.formManager;

        return (

            <table className="component-table">

                <tr>
                    <td>
                        <SelectComponent
                            optionValues={orgSelectors.selectProperties()}
                            optionFieldName="id"
                            optionDisplayFieldName="code"
                            fieldName="propertyId"
                            caption="Property"
                            hintText="Select property for override"
                            clearable={false}
                            comboReadOnly={isRowReadOnly || !isNewRow}
                            searchable={false}
                            formManager={formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            inputReadOnly={this.props.isRowReadOnly}
                            caption={`Minimum Inventory Quantity ${this.stockItem.coreUnitCode ? "(" + this.stockItem.coreUnitCode + ")" : ""}`}
                            hintText="Enter minimum quantity to be maintained in the inventory"
                            fieldName="minimumInventoryQuantity"
                            placeholder={`Minimum Inventory Quantity ${this.stockItem.coreUnitCode ? "(" + this.stockItem.coreUnitCode + ")" : ""}`}
                            formManager={this.formManager}
                            style={{ marginRight: '20px' }} />
                    </td>
                </tr>

            </table>
        )
    }
}
