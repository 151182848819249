
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import CloseButton from '../../../../components/button/closeButton';

class BIDateSelectionComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.stage = 0;
    }

    render()
    {
        return (
            <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <div>{this.renderSearchBar()}</div>
                    <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>{this.props.title}</div>
                    <div className='d-flex'>
                        {
                            this.props.closeEnabled &&
                            <div className='ms-2'><CloseButton disabled={this.props.disabled} /></div>
                        }
                    </div>
                </CardHeader>
            </Card>
        );
    }

    renderHeader()
    {
        return (
            <div className="d-flex flex-row align-items-center">
                <span className="mx-2" style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }} >{this.props.title}</span>
                {this.renderSearchBar()}
            </div>);
    }

    renderSearchBar = () =>
    {
        let searchSingleDate = false;           // Default = Date range selection

        if (this.props.searchSingleDate != undefined)
        {
            searchSingleDate = this.props.searchSingleDate;
        }

        return (
            <SearchBarComponent
                searchSingleDate={searchSingleDate}
                disabled={this.props.disabled}
                onSearch={(searchCriteria) =>
                {
                    this.props.onLoadReport(searchCriteria);
                }}
                onChange={(searchCriteria) =>
                {
                    this.props.onCriteriaChanged(searchCriteria);
                }}
            />);
    }
}

export default BIDateSelectionComponent;