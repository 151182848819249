module.exports.roleCodes =
{
    SystemAdmin: 'system_admin',
    SupportAdmin: 'support_admin',
    Support: 'support',
    MenuSupport: 'menu_support',
    SupportElevated: 'support_elevated',
    Owner: "10",
    StoreOwner: "12",
    ManagedOwner: "20",
    StoreManager: "30",
    FinanceManager: "50",
}

module.exports.builtInUserRoles = {

    systemRoles:
    {
        SystemAdmin: { roleCode: module.exports.roleCodes.SystemAdmin, displayName: 'System Administrator' },
        SupportAdmin: { roleCode: module.exports.roleCodes.SupportAdmin, displayName: 'Support Administrator' },
        Support: { roleCode: module.exports.roleCodes.Support, displayName: 'Support Staff' },
        MenuSupport: { roleCode: module.exports.roleCodes.MenuSupport, displayName: 'Menu Support'}
    },

    franchisorRoles:
    {
        Owner: { roleCode: module.exports.roleCodes.Owner, displayName: 'Owner' },
        ManagedOwner: { roleCode: module.exports.roleCodes.ManagedOwner, displayName: 'Managed Owner' },
        FinanceManager: { roleCode: module.exports.roleCodes.FinanceManager, displayName: 'Finance Manager' },
    },

    propertyRoles:
    {
        StoreOwner: { roleCode: module.exports.roleCodes.StoreOwner, displayName: 'Store Owner' },
        StoreManager: { roleCode: module.exports.roleCodes.StoreManager, displayName: 'Store Manager' },
        FinanceManager: { roleCode: module.exports.roleCodes.FinanceManager, displayName: 'Finance Manager' },
    },
}