
import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';

import Spinner from '../../../../../components/spinner/spinner';
import CloseButton from '../../../../../components/button/closeButton';
import EmptyData from '../../../../../components/empty/emptyData';
import ErrorMessage from '../../../../../components/error/errorMessage';
import GridComponent from '../../../../../components/grid/gridComponent';

import SearchBarComponent from '../../../../../components/form/searchBarComponent';

const depositReturnReportDataUtils = require('./utils/depositReturnReportDataUtils');
const depositReturnLoader = require('./utils/depositReturnLoader');


const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');

class DepositTransactionReport extends Component 
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            isLoading: false,
            isError: false, errorMessage: null,
            depositReturnsSummary: [],
        };

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onCriteriaChanged = () =>
    {
        this.setState(
            {
                isLoading: false,
                isError: false, errorMessage: null,
            });
    }

    onLoadReport = async (searchCriteria) =>
    {
        this.setState(
            {
                isLoading: true,
                isError: false, errorMessage: null
            });

        try
        {
            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const depositSales = await depositReturnLoader.loadDepositSales(fromDate, toDate);

            const depositReturns = await depositReturnLoader.loadDepositReturns(fromDate, toDate);

            let depositReturnsSummary = depositReturnReportDataUtils.getDepositReturnsSummary(depositReturns, depositSales);
            depositReturnsSummary = depositReturnsSummary.filter(summary => (summary.soldQuantity > 0 || summary.returnedQuantity > 0));

            this.setState(
                {
                    isLoading: false,
                    depositReturnsSummary: depositReturnsSummary
                });

        } catch (error)
        {
            this.setState(
                {
                    depositReturnsSummary: [],
                    isLoading: false,
                    isError: true, 
                    errorMessage: `Failed to load report data with error: ${error}`,
                });
        }
    }

    render() 
    {
        return (

            <Card>

                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderReportContents()}
                </CardBody>

            </Card>);
    }

    renderHeader()
    {
        var exportButton = null;
        var closeButton = null;

        var disabled = this.state.isLoading;

        exportButton = <Button disabled={disabled} color="primary" className="btn-primary" style={{ marginRight: '3px' }} onClick={() => this.gridApi.exportDataAsCsv()}>
            <i className="fa fa-download"></i>&nbsp;Export
        </Button>;

        closeButton = <CloseButton disabled={disabled} />

        return (
            <table width="100%">
                <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>Deposit Transactions &nbsp;</div>
                    </td>

                    <td width="99%">
                        {this.renderSearchBar()}
                    </td>

                    <td style={{ whiteSpace: "nowrap" }} align="right">

                        {exportButton}
                        {closeButton}

                    </td>
                </tr>

            </table>)
    }

    renderSearchBar()
    {
        return (
            <SearchBarComponent
                searchButtonCaption="Load"
                disabled={this.state.isLoading}
                onSearch={this.onLoadReport}
                onChange={this.onCriteriaChanged}
            />);
    }

    renderReportContents()
    {
        const { isLoading, depositReturnsSummary, isError, errorMessage } = this.state;

        if (isError)
        {
            return <ErrorMessage message={errorMessage} />;
        }

        if (isLoading)
        {
            return <Spinner text="Loading Data" />
        }

        if (depositReturnsSummary.length === 0)
            return <EmptyData action="Select or change criteria and hit Load button to load data" />;
        
        return (
            <GridComponent
                headers={this.constructGridColumnHeaders()}
                onGridReady={this.onGridReady}
                paginationEnabled={true}
                paginationSize={25}
                gridMode="controlled"
                rows={depositReturnsSummary}
                shouldReArrangeHeaders={true}
            />);
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {}
        header.field = "itemCaption";
        header.headerName = "Item";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {}
        header.field = "soldQuantity";
        header.headerName = "Sold Quantity";
        header.type = dataTypeConstants.fixedNumber;
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "returnedQuantity";
        header.headerName = "Returned Quantity";
        header.type = dataTypeConstants.fixedNumber;
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "totalAmountDeposited";
        header.headerName = "Deposited Amount";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "totalAmountReturned";
        header.headerName = "Returned Amount";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.code;
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }
}

export default DepositTransactionReport;