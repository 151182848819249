import React, { Component } from 'react';

import { formatAmount } from '../../../utils/viewUtils';
import { getVatCaption, getNomenclature } from '../../../../../../reportUtils/helperUtils/viewUtils';

export default class SalesSummary extends Component
{

    render()
    {
        const { salesSummary } = this.props.data;

        return (
            <div className='row m-0 border rounded'>

                <div className='col text-white border-end rounded d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "#0d6efd" }}>
                    <div className='fw-bold'>Total Sales</div>
                    <div>{salesSummary.saleCount}</div>
                </div>

                <div className='col border-end rounded-end d-flex flex-column justify-content-center align-items-center' style={{ backgroundColor: "WhiteSmoke" }}>
                    <div className='fw-bold text-muted'>{getNomenclature("salePriceIncTax")}</div>
                    <div>{formatAmount(salesSummary.totalSalePriceIncTax)}</div>
                </div>

                <div className='col border-end d-flex flex-column justify-content-center align-items-center'>
                    <div className='w-100 py-2 border-bottom'>
                        <div className='fw-bold text-muted'>{getNomenclature("salePrice")}</div>
                        <div>{formatAmount(salesSummary.totalSalePrice)}</div>
                    </div>
                    <div className='py-2'>
                        <div className='fw-bold text-muted'>{getVatCaption()}</div>
                        <div>{formatAmount(salesSummary.totalTax)}</div>
                    </div>
                </div>

                <div className='col border-end d-flex flex-column justify-content-center align-items-center'>
                    <div className='w-100 py-2 border-bottom'>
                        <div className='fw-bold text-muted'>{getNomenclature("netOrderPrice")}</div>
                        <div>{formatAmount(salesSummary.totalNetOrderPrice)}</div>
                    </div>
                    <div className='py-2'>
                        <div className='fw-bold text-muted'>Charges</div>
                        <div>{formatAmount(salesSummary.totalCharges)}</div>
                    </div>
                </div>

                <div className='col border-end d-flex flex-column justify-content-center align-items-center'>
                    <div className='w-100 py-2 border-bottom'>
                        <div className='fw-bold text-muted'>{getNomenclature("retailOrderPrice")}</div>
                        <div>{formatAmount(salesSummary.totalRetailOrderPrice)}</div>
                    </div>
                    <div className='w-100 py-2 border-bottom'>
                        <div className='fw-bold text-muted'>Discounts</div>
                        <div>{formatAmount(salesSummary.totalDiscounts)}</div>
                    </div>
                    <div className='py-2'>
                        <div className='fw-bold text-muted'>Refunds</div>
                        <div>{formatAmount(salesSummary.totalRefunds)}</div>
                    </div>
                </div>

            </div>
        );
    }
}