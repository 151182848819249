import * as phoneNumberParser from 'libphonenumber-js'
const localizationUtils = require('../domain/localizationUtils')
const stringUtil = require('../string/stringUtil');
const logger = require('../logger/logger');


export function formatPhoneNumberForDisplay(phoneNumber)
{
    try
    {
        phoneNumber = stringUtil.trimString(phoneNumber);
        if (stringUtil.isStringNullOrEmpty(phoneNumber))
            return phoneNumber;

        const persistencePhoneNumber = formatPhoneNumberForPersistence(phoneNumber);
        if (stringUtil.isStringNullOrEmpty(persistencePhoneNumber))
            return phoneNumber;

        const parsedPhoneNumber = phoneNumberParser.parsePhoneNumber(`+${persistencePhoneNumber}`);

        if (parsedPhoneNumber == null)
            return phoneNumber;

        let internationalFormat = parsedPhoneNumber.formatInternational();
        if (stringUtil.isStringNullOrEmpty(internationalFormat))
            return phoneNumber;

        return internationalFormat;
    }
    catch (error)
    {
        logger.error(`Error occurred while parsing phone number: ${phoneNumber}. ${error}`);
        return phoneNumber;
    }
}

export function formatPhoneNumberForPersistence(phoneNumber)
{
    phoneNumber = stringUtil.trimString(phoneNumber);
    if (stringUtil.isStringNullOrEmpty(phoneNumber))
        return phoneNumber;

    let parsedPhoneNumber = formatPhoneNumberForPersistenceForGivenCountry(phoneNumber);
    if (!stringUtil.isStringNullOrEmpty(parsedPhoneNumber))
        return parsedPhoneNumber;

    phoneNumber = `+${phoneNumber}`;
    return formatPhoneNumberForPersistenceForGivenCountry(phoneNumber);
}


function formatPhoneNumberForPersistenceForGivenCountry(phoneNumber)
{
    try
    {
        phoneNumber = stringUtil.trimString(phoneNumber);
        if (stringUtil.isStringNullOrEmpty(phoneNumber))
            return '';

        if (!phoneNumberParser.isSupportedCountry(getCountryCode()) || !phoneNumberParser.isPossiblePhoneNumber(phoneNumber, getCountryCode()))
            return '';

        const parsedPhoneNumber = phoneNumberParser.parsePhoneNumber(phoneNumber, getCountryCode());
        if (parsedPhoneNumber == null)
            return '';

        let internationalFormat = parsedPhoneNumber.formatInternational();
        if (stringUtil.isStringNullOrEmpty(internationalFormat))
            return '';

        return internationalFormat.replace(/[\s()+]/g, '');
    }
    catch (error)
    {
        logger.error(`Error occurred while parsing phone number: ${phoneNumber}. ${error}`);
        return '';
    }
}

function getCountryCode()
{
    return localizationUtils.getISO3166Alpha2Code();
}