import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import CloseButton from '../../../../../../components/button/closeButton';
import DefaultView from '../../../../../../components/defaultView/defaultView';
import SearchBarComponent from '../../../../../../components/form/searchBarComponent';
import * as rmsApiProxy from '../../../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../../../utils/api/rmsApiProxyUtils';

import TabularReport from './components/TabularReport';

class RefundsByMenuItem extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            refundsData: null,
            error: null,
            isLoading: false,
        }
    }

    render()
    {
        let component;

        const { isLoading, error, refundsData } = this.state;

        if (DefaultView.isDefaultView(isLoading, error, refundsData))
        {
            component = <DefaultView {...{ isLoading, error, data: refundsData }} />
        }
        else
        {
            component = this.renderReportData();
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold flex-grow-1 text-center'>Refunds By Menu Item</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData()
    {
        const { refundsData } = this.state;

        return (
            <div className='d-flex flex-column'>
                <div><TabularReport refundsData={refundsData} /></div>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ refundsData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, refundsData: null, isLoading: true });

        try 
        {
            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportsContextUrl()}/accounts/sales/refundsByMenuItem` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const refundsData = await rmsApiProxy.get(resourceUrl);

            this.setState({ refundsData });
        }
        catch (error) 
        {
            this.setState({ error: error });
        }
        finally
        {
            this.setState({ isLoading: false })
        }
    }
}

export default RefundsByMenuItem;