import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import CloseButton from '../../../../../../components/button/closeButton';
import ErrorMessage from '../../../../../../components/error/errorMessage';
import * as viewUtil from '../../../../../../utils/view/viewUtil';
import * as editableGridUtils from '../../../../../../utils/grid/editableGridUtils';

import * as addressMappingModel from './addressMappingModel';

export default class AddressMapping extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            addressMappings: null,
            isLoading: true,
            isDirty: false,
            error: null
        }
    }

    componentDidMount()
    {
        this.loadData();
    }

    loadData = async () =>
    {
        this.gridApi = null;

        this.setState({
            addressMappings: null,
            isLoading: true,
            isDirty: false,
            error: null
        });

        try
        {
            viewUtil.showSystemModalSpinner("Loading, please wait ...");
            const addressMappings = await addressMappingModel.loadAddressMappings();
            this.setState({
                addressMappings: addressMappings,
                isLoading: false
            });

        } catch (error)
        {
            this.setState({ error: error, isLoading: false });

        } finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onCancel = () =>
    {
        this.loadData();
    }

    onSave = async () =>
    {
        try
        {
            viewUtil.showSystemModalSpinner("Saving, please wait ...");
            const updatedAddressMappings = await addressMappingModel.saveAddressMappings(this.state.addressMappings);
            this.setState({ addressMappings: updatedAddressMappings, isDirty: false });
            this.gridApi.setRowData(updatedAddressMappings);

        } catch (error)
        {
            viewUtil.showCriticalAlert('Failed to save data with error:' + error.toString());

        } finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onGridReady = (event) =>
    {
        this.gridApi = event.api;
    }

    onCellValueChanged = (event) =>
    {
        if (!this.state.isDirty) 
        {
            this.setState({ isDirty: true });
        }
    }

    onRowSelected = (event) =>
    {
        this.setState({});
    }

    onAddRow = () =>
    {
        const rowData = { areaCodes: '', areaName: '' };
        const transaction = { add: [rowData], addIndex: undefined };
        const result = this.gridApi.applyTransaction(transaction);

        const rowNode = result.add[0];
        rowNode.setSelected(true, true);
        this.gridApi.ensureNodeVisible(rowNode, 'bottom');

        this.state.addressMappings.push(rowData);
        this.setState({ isDirty: true });

        setTimeout(() =>
        {
            if (!rowNode.isSelected) return;

            this.gridApi.setFocusedCell(rowNode.rowIndex, 'areaCodes', undefined);

            this.gridApi.startEditingCell({
                rowIndex: rowNode.rowIndex,
                colKey: 'areaCodes',
                rowPinned: undefined,
                key: 'Enter',
            });
        }, 250);
    }

    onDeleteRow = () =>
    {
        const selectedRow = this.gridApi.getSelectedRows()[0];
        const transaction = { remove: [selectedRow] };
        this.gridApi.applyTransaction(transaction);

        const addressMappings = this.state.addressMappings.filter(map => map !== selectedRow);
        this.setState({ addressMappings: addressMappings, isDirty: true });
    }

    render()
    {
        let component = null;

        const { addressMappings, error, isLoading, isDirty } = this.state;

        if (error)
        {
            component = <ErrorMessage message={this.state.error} />;
        }
        else if (addressMappings)
        {
            component = this.renderAddressMappings(addressMappings);
        }

        return (
            <Card>

                <CardHeader className="d-flex flex-row justify-content-between align-items-center">
                    <div className='h5'>Address Mappings</div>
                    <div className='d-flex'>
                        <div>
                            <Button className='px-3' color='primary' disabled={!isDirty || this.props.isReadOnly} onClick={this.onSave}><i className="fa fa-save" />&nbsp;Save</Button>
                            <Button className='ms-2 px-3' color='warning' disabled={!isDirty} onClick={this.onCancel}><i className="fa fa-trash" />&nbsp;Cancel</Button>
                        </div>
                        <div className='ms-2'><CloseButton disabled={isLoading} /></div>
                    </div>
                </CardHeader>

                <CardBody>
                    {
                        component
                    }
                </CardBody>

            </Card>
        );
    }

    renderAddressMappings(addressMappings)
    {
        const isRowSelected = this.gridApi && this.gridApi.getSelectedRows().length > 0;

        return (
            <div className='d-flex flex-column'>

                <div className='d-flex justify-content-between align-items-center'>

                    <div className='ms-2 text-wrap text-muted'><small>Separate multiple area codes with comma when mapping to the same area name</small></div>

                    <div className='ms-2 p-1 d-flex'>
                        <Button className='px-3 py-1' disabled={this.props.isReadOnly} color='success' onClick={this.onAddRow}><i className='fa fa-plus' /></Button>
                        <Button className='ms-2 px-3 py-1' disabled={!isRowSelected || this.props.isReadOnly} color='danger' onClick={this.onDeleteRow}><i className='fa fa-minus' /></Button>
                    </div>

                </div>

                <div className="ag-theme-alpine" style={{ width: '100%', height: '60vh' }}>
                    <AgGridReact
                        rowData={addressMappings}
                        columnDefs={this.getColumnDefs()}
                        onGridReady={this.onGridReady}
                        onCellValueChanged={this.onCellValueChanged}
                        onRowSelected={this.onRowSelected}
                        stopEditingWhenCellsLoseFocus={true}
                        gridOptions={editableGridUtils.getDefaultGridOptions()}
                    />
                </div>
            </div>
        );
    }

    getColumnDefs = () =>
    {
        let header;
        const headers = [];

        header = {};
        header.flex = 1;
        header.field = "areaCodes";
        header.headerName = "Area Codes";
        headers.push(header);

        header = {};
        header.flex = 1;
        header.field = "areaName";
        header.headerName = "Area Name";
        headers.push(header);

        return headers;
    }
}