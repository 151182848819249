import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../../../utils/bi/biUtils';
import { NoDataFoundComponent } from '../../../utils/viewUtils';

export default class SalesByPaymentMethodDetails extends Component
{
    componentDidUpdate()
    {
        if (this.props.showDetails === true)
        {
            if (this.gridApi != null) this.gridApi.sizeColumnsToFit();
        }
    }

    render()
    {
        // Using same gridOptions object across multiple grids in the same component results
        // in stack overflow error in ag-grid        

        const rowData = this.getRowData();

        if(rowData.length === 0)
        {
            return <NoDataFoundComponent message='No sale payment data found' />
        }

        const gridOptions = { ...biUtils.gridOptions };

        return (
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={rowData}
                    pinnedBottomRowData={[this.getTotalRow(rowData)]}
                    columnDefs={this.getColumnDefs(rowData)}
                    sideBar={null}
                    rowGroupPanelShow="never"
                    domLayout='autoHeight'
                    gridOptions={{ ...gridOptions, getRowStyle: this.getRowStyle }}
                    onGridReady={this.onGridReady}
                />
            </div>);
    }

    onGridReady = (params) =>
    {
        this.gridApi = params.api;

        this.gridApi.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay)
            {
                const rowData = e.api.rowModel.rowsToDisplay.map(node => node.data);

                const pinnedRows = [this.getTotalRow(rowData)];

                e.api.setPinnedBottomRowData(pinnedRows);
            }
        });

        if (this.props.showDetails === true)
        {
            this.gridApi.sizeColumnsToFit();
        }
    }

    getRowStyle = ({ data }) =>
    {
        if (data.paymentMethod === "Unpaid Sales") 
        {
            return { background: 'rgb(250, 185, 180)', fontWeight: "bold" };
        }
    }

    getRowData()
    {
        const { salesByPaymentMethod } = this.props.data;   // sorted by paymentMethod
        return salesByPaymentMethod;
    }

    getTotalRow(rowData)
    {
        return {
            chargeAmount: rowData.reduce((sum, data) => sum + data.chargeAmount, 0),
            refundAmount: rowData.reduce((sum, data) => sum + data.refundAmount, 0),
            netAmount: rowData.reduce((sum, data) => sum + data.netAmount, 0),
        }
    }

    getColumnDefs(rowData)
    {
        return Object.keys(rowData[0])
            .map(key => (
                {
                    field: key,
                    headerName: this.getHeaderName(key),
                    filter: this.getFilter(key),
                    type: this.getColumnType(key),
                    cellStyle: biUtils.cellStyleHelper,
                    valueFormatter: biUtils.valueFormatterHelper,
                    suppressSizeToFit: key !== "paymentMethod",
                    minWidth: key === "paymentMethod" ? 150 : undefined
                }));
    }

    getHeaderName(key)
    {
        switch (key)
        {
            case "paymentMethod": return "Payment Method";
            case "chargeAmount": return "Charge Amount";
            case "refundAmount": return "Refund Amount";
            case "netAmount": return "Net Amount";
            default: return "N/A";
        }
    }

    getColumnType(key)
    {
        if (key === "paymentMethod") return undefined;
        return "money";
    }

    getFilter(key)
    {
        if (key === "paymentMethod") return undefined;
        return "agNumberColumnFilter";
    }
}