
import React from 'react';
import { Button } from 'reactstrap';

import purchaseOrderUtil from '../../utils/purchaseOrderUtil';

const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


export default function PurchaseOrdersHeader(props)
{
    const property = currentOrgNodeSelectors.selectCurrentProperty();
    const propertyName = property ? property.name : "N/A";

    return (
        <div className="d-flex justify-content-between align-items-center">
            <span className="fw-bold text-muted ms-1">{`${propertyName}: Purchase Orders`}</span>
            <Button
                color="primary"
                className="me-2"
                disabled={props.viewType !== 'list' || props.isReadOnly}    // Disabled until list is loaded (if authorized)
                onClick={props.onNewPurchaseOrder}>
                <i className="fa fa-plus"></i>&nbsp;Create
            </Button>
        </div>
    );

}