import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import SupplierOverview from './components/SupplierOverview';
import SupplierServiceProfiles from './components/SupplierServiceProfiles';
import SupplierLogoProfile from './components/SupplierLogoProfile';


export default class Supplier extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = {};
    }

    render() 
    {
        const tabHeaders = [];

        tabHeaders.push(<Tab>General</Tab>);
        tabHeaders.push(<Tab>Service Profile</Tab>);
        tabHeaders.push(<Tab>Logo</Tab>);

        const tabContents = [];

        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>
            <SupplierOverview onFieldChanged={() => this.setState({})} {...this.props}></SupplierOverview>
        </TabPanel>);
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>
            <SupplierServiceProfiles {...this.props} onFieldChanged={() => this.setState({})}></SupplierServiceProfiles>
        </TabPanel>);
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>
                <SupplierLogoProfile {...this.props}/>
            </TabPanel>
        )

        return (
            <Tabs>

                <TabList>
                    {tabHeaders}
                </TabList>

                {tabContents}

            </Tabs>
        );
    }
}