import { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import DailyChargesSummary from "./DailyChargesSummary";
import DailyChargesDetails from "./DailyChargesDetails";

class DailyCharges extends Component
{
    render()
    {
        const data = this.props.data;

        var tabHeaders = [];
        tabHeaders.push(<Tab>Summary</Tab>)
        tabHeaders.push(<Tab>Details</Tab>)

        var tabContents = [];

        tabContents.push(
            <TabPanel style={{ marginTop: '15px' }}>
                <DailyChargesSummary data={data} />
            </TabPanel>
        );
        tabContents.push(
            <TabPanel style={{ marginTop: '15px' }}>
                <DailyChargesDetails data={data} />
            </TabPanel>
        );

        return (
            <Tabs>
                <TabList>
                    {tabHeaders}
                </TabList>

                {tabContents}
            </Tabs>
        );
    }
}

export default DailyCharges;