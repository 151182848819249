
import React from 'react';

export default function NoDataRows({message})
{
    //
    // Display centered bold message
    //

    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block"}}>
                <b>{message || "No Data Rows"}</b>
            </div>
        </div>);
}
