import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import OverPhonePaymentConfigurationOverview from './components/OverPhonePaymentConfigurationOverview';
import WebAppFavIcon from './components/OverPhonePaymentConfigurationFavIcon';
import Jumbotron from "../../../../../components/jumbotron/jumbotron";
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontOverPhonePaymentConfigurationHelper = require('./storefrontOverPhonePaymentConfigurationHelper');
const storefrontOperatingConfigurationsHelper = require('../operatingConfiguration/storefrontOperatingConfigurationsHelper');
const FormManager = require('../../../../../utils/view/formManager.js');
const viewUtil = require('../../../../../utils/view/viewUtil');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const logger = require('../../../../../utils/logger/logger.js');
const constants = require('../../../../../utils/domain/constants');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontOverPhonePaymentConfigurations extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
            storefront: this.props.params,
            isLoading: false,
            shouldDisplayDetails: true
        };

        this.state.storefrontOverPhonePaymentConfigurations = {};
        this.state.formManager.viewModel = this.state.storefrontOverPhonePaymentConfigurations;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let storefrontOperatingConfiguration = await storefrontOperatingConfigurationsHelper.loadStorefrontOperatingConfiguration(this.state.storefront);

            if (!stringUtil.areStringSame(storefrontOperatingConfiguration.payoutIntegrationType, constants.storePayoutIntegrationTypes.flipdish))
            {
                this.state.shouldDisplayDetails = false;
            }
            else
            {
                let storefrontOverPhonePaymentConfigurations = await storefrontOverPhonePaymentConfigurationHelper.loadStorefrontWebAppConfigurations(this.state.storefront);
                this.state.storefrontOverPhonePaymentConfigurations = storefrontOverPhonePaymentConfigurations || { applicationId: guidUtil.generateGuid(), themeId: "flipdish.theme", isEnabled: false };
                this.state.formManager.viewModel = this.state.storefrontOverPhonePaymentConfigurations;
            }

            this.setState({});
        }
        catch (error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    async onSaveClicked()
    {
        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            await storefrontOverPhonePaymentConfigurationHelper.save(this.state.storefront, this.state.storefrontOverPhonePaymentConfigurations);
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration, 
                                                      `${this.state.storefront.name} storefront Over the Phone Payment Configurations have been altered`, 
                                                      this.state.storefrontOverPhonePaymentConfigurations);
                                                      
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }



        return (
            <Card>

                <CardHeader>
                    <EditComponentHeader
                        title="Storefront Over Phone Payment Configurations"
                        isReadOnly={!this.state.shouldDisplayDetails || this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    {
                        !this.state.shouldDisplayDetails
                            ? this.renderOverPhonePaymentNotAvailable()
                            : this.renderOverPhoneConfiguration()
                    }
                </CardBody>

            </Card>
        );
    }

    renderOverPhonePaymentNotAvailable()
    {
        const title = 'Over phone payment is only supported with Flipdish payment';
        // const text = 'Over phone payment is only supported with Flipdish payment';
        const action = 'Please contact support to find out more about Flipdish payment';

        return <div>
            <Jumbotron>
                <h4>{title}</h4>
                {/* <p className="lead">{text}</p> */}
                <hr className="my-2" />
                <p>{action}</p>
            </Jumbotron>
        </div>;
    }

    renderOverPhoneConfiguration()
    {
        const tabHeaders = [];
        const tabPanels = [];

        tabHeaders.push(<Tab>Overview</Tab>);
        tabPanels.push(
            <TabPanel className='pt-1'>
                <OverPhonePaymentConfigurationOverview data={this.state.storefrontOverPhonePaymentConfigurations} />
            </TabPanel>);

        tabHeaders.push(<Tab>Favicon</Tab>);
        tabPanels.push(<TabPanel>
            <WebAppFavIcon
                data={this.state.storefrontOverPhonePaymentConfigurations}
                storefront={this.state.storefront}
                onImageUpdate={() =>
                {
                    this.setState({})
                }} />
        </TabPanel>);

        return <Tabs>
            <TabList>{tabHeaders}</TabList>
            {tabPanels}
        </Tabs>
    }

}

export default StorefrontOverPhonePaymentConfigurations;