//
// System lookupData
//

module.exports = function LookupData()
{
    this.allergies = [];
    this.nutritions = [];
    this.packageTypes = [];
    this.frequencies = [];
    this.periods = [];
    this.venues = [];
    this.wastageReasons = [];
    this.propertyAmenities = [];
    this.serviceTypes = [];                 // Memory based lookup
    this.applianceTypes = [];               // Memory based lookup
    this.localizationSettings = null;
}