import GridComponent from "../../../../../components/grid/gridComponent.js";

const biUtils = require('../../../../../utils/bi/biUtils.js');
const typeUtil = require('../../../../../utils/type/typeUtil.js');

const SalesDataGrid = (props) => {

    return <GridComponent headers={props.getColumnDefs()}
    rows={props.getData()}
    shouldReArrangeHeaders={true}/>
}
 
export default SalesDataGrid;