import arraySort from 'array-sort';

import * as stringUtil from '../../../../../../utils/string/stringUtil';
import * as regexUtil from '../../../../../../utils/regex/regexUtil';

const regexAllDayOpen = /^open$|^opened$|^all day$|^all day open$|^all day opened$/i;
const regexAllDayClose = /^close$|^closed$|^all day close$|^all day closed$/i;
const regex12hrRange = /^(0?[1-9]|1[012])(:[0-5]\d)\s?[ap][m]\s?-\s?(0?[1-9]|1[012])(:[0-5]\d)\s?[ap][m]$/i;
const regex24hrRange = /^([01][0-9]|2[0-3]):([0-5][0-9])\s?-\s?([01][0-9]|2[0-3]):([0-5][0-9])$/;

const maxTimeSlotsAllowed = 3;  // For now, this is hardcoded and does not change for storefront type

export function isOpeningHourAllDayOpen(inputText)
{
    return regexAllDayOpen.test(inputText);
}

export function isOpeningHourAllDayClose(inputText)
{
    return stringUtil.isStringNullOrEmpty(inputText) || regexAllDayClose.test(inputText);
}

export function isOpeningHourTimeSlots(inputText)
{
    return inputText
        .split(regexUtil.regexSplitByComma)
        .every(openingHour => regex12hrRange.test(openingHour) || regex24hrRange.test(openingHour));
}

export function getTimeSlotsFromOpeningHour(inputText)
{
    // openingHour string must be comma separated time ranges

    const timeSlots = [];

    inputText
        .split(regexUtil.regexSplitByComma)
        .forEach(timeRange => 
        {
            timeRange = stringUtil.trimString(timeRange);

            if (regex12hrRange.test(timeRange))
            {
                timeSlots.push(getTimeSlotFrom12hrRange(timeRange))
            }
            else
            {
                // Implies 24hr timeRange
                timeSlots.push(getTimeSlotFrom24hrRange(timeRange));
            }
        });

    return normalizeTimeSlots(timeSlots);
}

export function isValidOpeningHour(inputText)
{
    inputText = stringUtil.trimString(inputText);

    if (stringUtil.isStringNullOrEmpty(inputText)) return true;   // null openingHour = Closed
    if (regexAllDayClose.test(inputText)) return true;
    if (regexAllDayOpen.test(inputText)) return true;

    // Implies openingHour is time range format

    const timeSlots = inputText.split(regexUtil.regexSplitByComma);

    if (timeSlots.some(slot => stringUtil.isStringNullOrEmpty(slot))) return false;

    if (timeSlots.some(slot => !(regex12hrRange.test(slot) || regex24hrRange.test(slot)))) return false;

    if (timeSlots.length > maxTimeSlotsAllowed) return false;

    return true;
}

export function isOpeningHoursDataValid(dispatchTypes, openingHoursData)
{
    if (openingHoursData == null) return false;

    for (const dispatchType of Object.keys(dispatchTypes)) 
    {
        if (dispatchTypes[dispatchType] === false)
            continue;

        if (openingHoursData.some(dayOpeningHours => !isValidOpeningHour(dayOpeningHours[dispatchType])))
            return false;
    }

    return true;
}

// private methods

function getTimeSlotFrom12hrRange(timeRange)
{
    const [startTime, endTime] = timeRange.split('-').map(x => stringUtil.trimString(x)).filter(x => !stringUtil.isStringNullOrEmpty(x));

    const { hours: fromHour, minutes: fromMinute } = getHoursMinutesFrom12hrTime(startTime);
    const { hours: toHour, minutes: toMinute } = getHoursMinutesFrom12hrTime(endTime);

    return { fromHour, fromMinute, toHour, toMinute }
}

function getHoursMinutesFrom12hrTime(timeString)
{
    const timeRegex = /^(\d+):(\d+)\s?(AM|PM)$/i;

    const match = timeString.match(timeRegex);

    let hours = parseInt(match[1]);
    const minutes = parseInt(match[2]);
    const period = match[3].toUpperCase();

    // Adjust hours for PM times (except for 12 PM which remains 12)

    if (period === 'PM' && hours !== 12)
    {
        hours += 12;
    }
    else if (period === 'AM' && hours === 12)
    {
        hours = 0;
    }

    return { hours, minutes };
}

function getTimeSlotFrom24hrRange(timeRange)
{
    const [startTime, endTime] = timeRange.split('-').map(x => stringUtil.trimString(x)).filter(x => !stringUtil.isStringNullOrEmpty(x));

    const { hours: fromHour, minutes: fromMinute } = getHoursMinutesFrom24hrTime(startTime);
    const { hours: toHour, minutes: toMinute } = getHoursMinutesFrom24hrTime(endTime);

    return { fromHour, fromMinute, toHour, toMinute }
}

function getHoursMinutesFrom24hrTime(timeString)
{
    const timeRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

    const match = timeString.match(timeRegex);
    const hours = parseInt(match[1]);
    const minutes = parseInt(match[2]);

    return { hours, minutes };
}

function normalizeTimeSlots(timeSlots) 
{
    // Normalizes timeSlots by removing overlapping timeSlots

    const normalizedTimeSlots = [];

    // Sort timeSlots by start time ascending and process

    arraySort(timeSlots, (slot1, slot2) => (slot1.fromHour - slot2.fromHour) || (slot1.fromMinute - slot2.fromMinute))
        .forEach(currentTimeSlot => 
        {
            let fromHour, fromMinute, toHour, toMinute, currentStartTime, currentEndTime, lastEndTime;

            const lastTimeSlot = normalizedTimeSlots[normalizedTimeSlots.length - 1];

            if (lastTimeSlot == null)
            {
                normalizedTimeSlots.push(currentTimeSlot);
                return;
            }

            ({ fromHour, fromMinute } = currentTimeSlot);
            ({ toHour, toMinute } = lastTimeSlot);

            currentStartTime = `${fromHour.toString().padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}`;
            lastEndTime = `${toHour.toString().padStart(2, '0')}:${toMinute.toString().padStart(2, '0')}`;

            if (lastEndTime > currentStartTime)
            {
                // Implies lastTimeSlot overlaps currentTimeSlot
                // Assign currentTimeSlot toHour/toMinute to lastTimeSlot (unless lastTimeSlot fully spans currentTimeSlot)

                ({ toHour, toMinute } = currentTimeSlot);
                currentEndTime = `${toHour.toString().padStart(2, '0')}:${toMinute.toString().padStart(2, '0')}`;

                if (lastEndTime < currentEndTime)
                {
                    lastTimeSlot.toHour = currentTimeSlot.toHour;
                    lastTimeSlot.toMinute = currentTimeSlot.toMinute;
                }

                return;
            }

            normalizedTimeSlots.push(currentTimeSlot);
        });

    return normalizedTimeSlots;
}
