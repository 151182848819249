import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';
import BrandWiseTabbedReport from '../../../reports/reportUtils/components/BrandWiseTabbedReport';
import { allBrandTotal } from '../../../reports/reportUtils/constants';
import DailyCharges from './components/DailyCharges';

const stringUtil = require('../../../../utils/string/stringUtil');
const reportsLoadingFacade = require('../../../../utils/api/reportsLoadingFacade');

class DailySalesCharges extends Component
{
    constructor(props)
    {
        super(props);

        // this.state is initialized by base class
        this.state = {};
        this.state.reportData = [];
        this.state.brands = [];
        this.state.error = null;
        this.state.searchCriteria = null;
        this.state.isLoading = false;

        this.onSearch = this.onSearch.bind(this);
        this.getReport = this.getReport.bind(this);
        this.getBrands = this.getBrands.bind(this);
    }

    render()
    {
        let component;

        const { isLoading, error } = this.state;

        if (isLoading) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else
        {
            component = this.renderReportData();
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>Daily Charges Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody className='flex-grow-1 h-100 p-0'>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData()
    {
        return <BrandWiseTabbedReport noPadding={false} brands={this.state.brands} getReport={this.getReport} />
    }

    getReport(brand)
    {
        let component;

        const { searchCriteria } = this.state;
        let reportData = this.state.reportData;

        if (brand == null)
        {
            reportData = this.state.reportData.filter(e => stringUtil.isStringNullOrEmpty(e.storefrontBrand));
        }
        else if (!stringUtil.areStringSame(brand, allBrandTotal))
        {
            reportData = this.state.reportData.filter(e => stringUtil.areStringSame(e.storefrontBrand, brand));
        }

        if (reportData.length === 0)
        {
            let title = 'Data required';
            let text = 'Please choose date range and hit Search to get report data';
            let action = null;

            if (searchCriteria != null) 
            {
                // Implies search did not return any data

                title = 'No Data Found';
                text = 'No data was found for your selected date range !!'
                action = 'Please choose a different date range and hit Search to get report data';
            }

            component = <div className='m-3'><EmptyData title={title} text={text} action={action} /></div>;
        }
        else
        {
            component = <DailyCharges data={reportData} />;
        }

        return <div className="m-3">{component}</div>;
    }

    onSearch(searchCriteria)
    {
        this.loadData(searchCriteria);
    }

    getBrands(data)
    {
        if (data == null) return;

        let brands = [...new Set(data.map(d => d.storefrontBrand))];
        return brands;
    }

    async loadData(searchCriteria)
    {
        this.setState({ searchCriteria: searchCriteria, isLoading: true });

        const fromDate = searchCriteria.startDate;
        const toDate = searchCriteria.endDate;

        try 
        {
            const reportData = await reportsLoadingFacade.getChargesSummary(fromDate, toDate);
            this.setState({ reportData: reportData, brands: this.getBrands(reportData), isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }
}

export default DailySalesCharges;