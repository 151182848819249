import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';
import * as wagesCostSummaryViewUtil from '../../viewUtils/wagesCostSummaryViewUtil';

export default function TabularReport(props)
{
    const masterRows = getMasterRows(props);

    const totalRow = {
        staff: 'Total',
        total: masterRows.reduce((value, data) => value + data.total, 0),
        wages: masterRows.reduce((value, data) => value + data.wages, 0),
        holidays: masterRows.reduce((value, data) => value + data.holidays, 0),
        bonus: masterRows.reduce((value, data) => value + data.bonus, 0),
        pensionAndNI: masterRows.reduce((value, data) => value + data.pensionAndNI, 0),
    }

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    return (
        <div className="ag-theme-alpine">
            <AgGridReact
                rowData={masterRows}
                pinnedBottomRowData={[totalRow]}
                columnDefs={getMasterGridColumns()}
                gridOptions={biUtils.getGridOptionsForMasterRows()}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
            />
        </div>
    );
}

function getMasterRows(props)
{
    return wagesCostSummaryViewUtil.getPayrollSummaryStaffData(props);
}

function getMasterGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "staff";
    header.headerName = "Staff";
    headers.push(header);

    header = {};
    header.field = "total";
    header.headerName = "Total";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "wages";
    header.headerName = "Wages";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "holidays";
    header.headerName = "Holidays";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "bonus";
    header.headerName = "Bonus";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "pensionAndNI";
    header.headerName = "Employer Pension & NI";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}
