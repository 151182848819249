import React, { useState } from 'react'
import { Card, CardHeader, CardBody, ListGroup, Badge, Collapse } from 'reactstrap';
import LogRow from './logRow';

export default function LogGroup(props) {

    const [isOpen, setIsOpen] = useState(props.isDefaultOpen);

    const logs = props.logs.map((captionLog,idx) => {
        return <LogRow log={captionLog} showBottomBorder={idx !== props.logs.length - 1} />;
    });

    const onCollapseClick = () => setIsOpen(!isOpen);
    const collapseBtnIcon = isOpen ? "fa-chevron-down" : "fa-chevron-right";

    return <div className={`card  mt-1`} style={{border: `1px solid ${props.color === "danger" ? "#dc35451e" : "#ff94001e"}`}}>
        <div  className={`card-header border-bottom-1 text-center px-3 py-2 rounded`} style={{border: 'none', background:`${props.color === "danger" ? "#dc35451e" : "#ff94001e"}` }} onClick={onCollapseClick}>
            <div className="d-flex justify-content-between align-items-center">
                <span style={{ textAlign: 'left', fontWeight: 400 }}>{props.caption}</span>
                <div className="d-flex justify-content-start align-items-center">
                    <span className={`badge bg-${props.color} px-2 py-1`} style={{ marginRight: '10px' }}>{logs.length}</span>
                    <i className={`fa ${collapseBtnIcon}`}></i>
                </div>
            </div>
        </div>
        <Collapse isOpen={isOpen}>
            <div className="card-body p-2 pt-2" id="collapseExample">
                <div className='d-flex flex-column gap-2 overflow-hidden rounded'>
                    {logs}
                </div>
            </div>
        </Collapse>
    </div>;
}
