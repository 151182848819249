const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const stringUtil = require('../../../../../utils/string/stringUtil');

export function createNew () 
{
    return {}
}

export async function save (data)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/operatingConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/operatingConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{
    if(validator.isPresent(data.smsFrom) && !validator.isValidSMSSenderName(data.smsFrom))
       return "SMS Sender Name invalid. Please use only letters, numbers, and up to 11 characters.";

    if (!stringUtil.isStringNullOrEmpty(data.staffMealLimit) && !validator.isGreaterThanOrEqualToZero(data.staffMealLimit))
        return "Staff Meal Limits must be a valid number greater than or equal to zero";

    return null;
}