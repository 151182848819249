import React, { Component } from 'react';
import { Button } from 'reactstrap';

import PurchaseOrderStatus from './PurchaseOrderStatus';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';
import inventoryUtil from '../../utils/inventoryUtil';



const stringUtil = require('../../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../../utils/dateUtil/dateUtil');
const viewUtil = require('../../../../../../utils/view/viewUtil');
const domainConstants = require('../../../../../../utils/domain/constants');
const validator = require('../../../../../../utils/validator/validator');

export default class PurchaseOrderActions extends Component
{
    constructor(props)
    {
        super(props);

        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onSubmitClicked = this.onSubmitClicked.bind(this);
        this.onRecallClicked = this.onRecallClicked.bind(this);
        this.onReceivedClicked = this.onReceivedClicked.bind(this);
        this.onVoidClicked = this.onVoidClicked.bind(this);
    }

    async postPurchaseOrder(purchaseOrder)
    {
        const error = purchaseOrderUtil.validatePurchaseOrder(purchaseOrder);
        if (!stringUtil.isStringNullOrEmpty(error))
        {
            viewUtil.showErrorAlert(error);
            return null;
        }

        viewUtil.showSystemModalSpinner("Saving purchase order");

        let updatedOrder = null;

        try 
        {
            updatedOrder = await purchaseOrderUtil.savePurchaseOrder(purchaseOrder);
        }
        catch (err) 
        {
            viewUtil.showErrorAlert(err);
        }

        viewUtil.closeModalSpinner();

        return updatedOrder;
    }

    async onSaveClicked()
    {
        const updatedOrder = await this.postPurchaseOrder(this.props.data);

        if (updatedOrder != null)
        {
            this.props.onSaved(updatedOrder);
        }
    }

    onSubmitClicked()
    {
        const title = "Submit Order ?";
        const message = "Please confirm action (Y/N)";

        viewUtil.showConfirmDialogue(title, message, async () =>
        {
            const purchaseOrder = this.props.data;

            purchaseOrder.submissionDate = dateUtil.getToday();
            purchaseOrder.status = domainConstants.PurchaseOrderStatusType.submitted;
            purchaseOrderUtil.renderItemsOnSubmit(purchaseOrder);

            const updatedOrder = await this.postPurchaseOrder(purchaseOrder);

            if (updatedOrder != null)
            {
                this.props.onSaved(updatedOrder);
            }
            else
            {
                // Revert changes
                purchaseOrder.submissionDate = null;
                purchaseOrder.status = domainConstants.PurchaseOrderStatusType.draft;
                purchaseOrderUtil.renderItemsOnRecall(purchaseOrder);
            }
        });
    }

    onRecallClicked()
    {
        const title = "Recall Order ?";
        const message = "Please confirm action (Y/N)";

        viewUtil.showConfirmDialogue(title, message, async () =>
        {
            const purchaseOrder = this.props.data;

            const submissionDate = purchaseOrder.submissionDate;

            purchaseOrder.submissionDate = null;
            purchaseOrder.status = domainConstants.PurchaseOrderStatusType.draft;
            purchaseOrderUtil.renderItemsOnRecall(purchaseOrder);

            const updatedOrder = await this.postPurchaseOrder(purchaseOrder);

            if (updatedOrder != null)
            {
                this.props.onSaved(updatedOrder);
            }
            else
            {
                // Revert changes
                purchaseOrder.submissionDate = submissionDate;
                purchaseOrder.status = domainConstants.PurchaseOrderStatusType.submitted;
                purchaseOrderUtil.renderItemsOnSubmit(purchaseOrder);
            }
        });
    }

    onReceivedClicked()
    {
        const title = "Receive Order ?";
        const message = "Note: You may not be able to undo an order receipt later.\nPlease confirm action (Y/N)";

        viewUtil.showConfirmDialogue(title, message, async () =>
        {
            const purchaseOrder = this.props.data;

            purchaseOrder.receiptDate = dateUtil.getToday();
            purchaseOrder.status = domainConstants.PurchaseOrderStatusType.received;

            const updatedOrder = await this.postPurchaseOrder(purchaseOrder);

            if (updatedOrder != null)
            {
                // Implies post succeeded

                this.props.onSaved(updatedOrder);

                // Update supplier prices in the state on receipt same as it is updated in the server

                const stockItems = this.props.lookupData.stockItems;

                const supplierId = updatedOrder.supplierId;
                const orderItems = updatedOrder.orderItems;

                orderItems.forEach(orderItem => 
                {
                    if (orderItem.receiptQuantity > 0)
                    {
                        const unitTypeId = orderItem.receiptUnitTypeId;
                        const stockItem = stockItems.find(x => x.id == orderItem.stockItemId);

                        const stockItemSupplier = stockItem.suppliers.find(x => x.supplierId == supplierId);
                        const supplierUnitType = stockItemSupplier.unitTypes.find(x => x.unitTypeId == unitTypeId);

                        supplierUnitType.price = orderItem.receiptUnitPrice;
                        supplierUnitType.vat = orderItem.receiptVat;
                    }
                });

                return;
            }

            // Implies post failed, revert changes
            purchaseOrder.receiptDate = null;
            purchaseOrder.status = domainConstants.PurchaseOrderStatusType.submitted;
        });
    }

    onVoidClicked()
    {
        const title = "Cancel Order ?";
        const message = "Note: You may not be able to undo a cancelled order later.\nPlease confirm action (Y/N)";

        viewUtil.showConfirmDialogue(title, message, async () =>
        {
            this.props.data.status = domainConstants.PurchaseOrderStatusType.voided;

            const updatedOrder = await this.postPurchaseOrder(this.props.data);

            if (updatedOrder != null)
            {
                this.props.onSaved(updatedOrder);
            }
            else
            {
                this.props.data.status = domainConstants.PurchaseOrderStatusType.draft;
            }
        });
    }

    get isNew()
    {
        return this.props.viewType == "new";
    }

    get isValid()
    {
        // Is order valid (without regard to orderItems)

        const purchaseOrder = this.props.data;
        return validator.isPresent(purchaseOrder.id) &&
            validator.isPresent(purchaseOrder.franchisorId) &&
            validator.isPresent(purchaseOrder.franchiseeId) &&
            validator.isPresent(purchaseOrder.propertyId) &&
            validator.isPresent(purchaseOrder.supplierId) &&
            validator.isDate(purchaseOrder.date) &&
            validator.isPresent(purchaseOrder.number) &&
            ((purchaseOrder.status == domainConstants.PurchaseOrderStatusType.submitted ||
                purchaseOrder.status == domainConstants.PurchaseOrderStatusType.received)
                ? validator.isDate(purchaseOrder.submissionDate)
                : purchaseOrder.submissionDate == null);
    }

    get isValidForSave()
    {
        const purchaseOrder = this.props.data;
        return this.isValid &&
            this.hasValidOrderItems &&
            purchaseOrder.status == domainConstants.PurchaseOrderStatusType.draft;
    }

    get isValidForVoid()
    {
        return this.isValidForSave && !this.isNew;
    }

    get isValidForSubmit()
    {
        return this.isValidForSave;
    }

    get isValidForRecall()
    {
        const purchaseOrder = this.props.data;
        return purchaseOrder.status === domainConstants.PurchaseOrderStatusType.submitted && !purchaseOrder.isDispatchedBySupplier;
    }

    get isValidForReceipt()
    {
        const purchaseOrder = this.props.data;
        return this.isValid &&
            this.hasValidReceiptOrderItems &&
            purchaseOrder.status == domainConstants.PurchaseOrderStatusType.submitted;
    }

    get hasValidOrderItems()
    {
        // Are orderItems valid for save or submit

        const purchaseOrder = this.props.data;
        const orderItems = purchaseOrder.orderItems;
        return orderItems.length > 0 &&
            orderItems.every(orderItem =>
                validator.isPresent(orderItem.id) &&
                validator.isPresent(orderItem.stockItemId) &&
                validator.isPresent(orderItem.unitTypeId) &&
                validator.isGreaterThanOrEqualToZero(orderItem.unitPrice) &&
                validator.isGreaterThanOrEqualToZero(orderItem.vat) &&
                validator.isGreaterThanZero(orderItem.quantity));
    }

    get hasValidReceiptOrderItems()
    {
        // Are orderItems valid for receipt

        const purchaseOrder = this.props.data;
        const orderItems = purchaseOrder.orderItems;
        return this.hasValidOrderItems &&
            orderItems.every(orderItem =>
                validator.isPresent(orderItem.receiptUnitTypeId) &&
                validator.isGreaterThanOrEqualToZero(orderItem.receiptUnitPrice) &&
                validator.isGreaterThanOrEqualToZero(orderItem.receiptVat) &&
                validator.isGreaterThanOrEqualToZero(orderItem.receiptQuantity));
    }

    render()
    {
        const purchaseOrder = this.props.data;

        let saveButton = null;
        let submitButton = null;
        let recallButton = null;
        let receiptButton = null;
        let voidButton = null;

        if (!this.props.isReadOnly)
        {
            if (purchaseOrder.status === domainConstants.PurchaseOrderStatusType.draft)
            {
                const disabled = !this.isValidForSave;

                saveButton =
                    <Button
                        disabled={disabled}
                        color="primary" className="me-2"
                        onClick={this.onSaveClicked}>
                        <i className="fa fa-save"></i>&nbsp;Save
                    </Button>
            }

            if ((purchaseOrder.status === domainConstants.PurchaseOrderStatusType.draft) &&
                purchaseOrderUtil.isUserAuthorizedForAnyAction("Approve Purchase Order"))
            {
                const disabled = !this.isValidForSubmit;

                submitButton =
                    <Button
                        disabled={disabled}
                        color="success" className="me-2"
                        onClick={this.onSubmitClicked}>
                        <i className="fa fa-send"></i>&nbsp;Submit Order
                    </Button>
            }

            if (purchaseOrder.status === domainConstants.PurchaseOrderStatusType.submitted &&
                purchaseOrderUtil.isUserAuthorizedForAnyAction("Approve Purchase Order"))
            {
                const disabled = !this.isValidForRecall;

                recallButton = <Button
                    disabled={disabled}
                    color="warning" className="me-2"
                    onClick={this.onRecallClicked}>
                    <i className="fa fa-reply"></i>&nbsp;Recall Order
                </Button>
            }

            if (purchaseOrder.status === domainConstants.PurchaseOrderStatusType.submitted &&
                purchaseOrderUtil.isUserAuthorizedForAnyAction("Approve Purchase Order"))
            {
                const disabled = !this.isValidForReceipt;

                receiptButton =
                    <Button
                        disabled={disabled}
                        color="success" className="me-2"
                        onClick={this.onReceivedClicked}>
                        <i className="fa fa-truck"></i>&nbsp;Receive Order
                    </Button>
            }

            if (
                purchaseOrder.status === domainConstants.PurchaseOrderStatusType.draft &&
                purchaseOrderUtil.isUserAuthorizedForAnyAction("Create Purchase Order", "Approve Purchase Order"))
            {
                const disabled = !this.isValidForVoid;

                voidButton =
                    <Button
                        disabled={disabled}
                        color="danger" className="me-2" style={{ marginRight: '3px' }}
                        onClick={this.onVoidClicked}>
                        <i className="fa fa-close"></i>&nbsp;Cancel Order
                    </Button>
            }
        }

        return (
            <div className="bg-secondary px-1 py-2 d-flex flex-row justify-content-between align-items-center border rounded">

                <div className='ps-1' style={{ fontSize: "1.25em" }}>
                    <span style={{ color: "#FFFFFF" }}>Order#:&nbsp;&nbsp;</span>
                    <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{purchaseOrder.number}</span>
                </div>

                <div style={{ fontSize: "1.25em" }}>
                    <PurchaseOrderStatus data={purchaseOrder} lookupData={this.props.lookupData} />
                </div>

                <div className="d-flex flex-row align-items-center">
                    {saveButton}
                    {submitButton}
                    {recallButton}
                    {receiptButton}
                    {voidButton}
                    <Button style={{ fontSize: "1.0em" }} color="dark" className="mx-2" onClick={this.props.onCancel}>
                        &nbsp;Close
                    </Button>
                </div>

            </div>);
    }
}