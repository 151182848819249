const validator = require('../../../../utils/validator/validator');
const domainConstants = require('../../../../utils/domain/constants');
const localizationUtils = require('../../../../utils/domain/localizationUtils');
const nomenclatureUtils = require('../../../../utils/domain/nomenclatureUtils');
const biUtils = require('../../../../utils/bi/biUtils');
const inventoryConstants = require('../../../../utils/domain/inventoryConstants');


export function getVatCaption()
{
    return localizationUtils.getVatCaption();
}

export function getNomenclature(code) 
{
    return nomenclatureUtils.getValue(code);
}

export function getColorByIndex(index)
{
    const palette = [
        { background: "#05149E", foreground: "white" },
        { background: "#5560C4", foreground: "white" },
        { background: "#9199E0", foreground: "white" },
        { background: "#05149E", foreground: "white" },
        { background: "#5560C4", foreground: "white" },
        { background: "#9199E0", foreground: "white" },
        { background: "#05149E", foreground: "white" },
        { background: "#5560C4", foreground: "white" },
    ]

    if (!validator.isNumeric(index)) return palette[0];

    return palette[index % palette.length];
}

export function getPaymentMethodForDisplay(paymentMethod, venueCode)
{
    if (paymentMethod !== domainConstants.paymentMethods.VenuePaid)
        return paymentMethod;

    if (venueCode === domainConstants.venueCodes.Property)
        return "Online";

    return `${paymentMethod} / ${venueCode}`;
}

export function getInventoryItemTypeForDisplay(itemType)
{
    if (itemType == null) return null;

    const inventoryEventItemType = inventoryConstants.inventoryEventItemType;

    switch (itemType)
    {
        case inventoryEventItemType.rawStockItem:
            return "Raw Item";
        case inventoryEventItemType.buildItem:
            return "Build Item";
        case inventoryEventItemType.prepItem:
            return "Prep Item";
        default:
            throw new Error(`Unknown Inventory Item Type '${itemType}'`);
    }
}

export function getInventoryEventTypeForDisplay(eventType, itemType)
{
    if (eventType == null) return null;

    const inventoryEventType = inventoryConstants.inventoryEventType;

    switch (eventType)
    {
        case inventoryEventType.purchase:
            return "Purchases";
        case inventoryEventType.build:
            return itemType === inventoryConstants.inventoryEventItemType.rawStockItem ? "Prep-Out" : "Prep-In";
        case inventoryEventType.wastage:
            return "Wastages";
        case inventoryEventType.stockTake:
            return "Stock Taking";
        case inventoryEventType.sale:
            return "Sales";
        case inventoryEventType.centralKitchenSale:
            return "Central Kitchen Sales";

        default:
            throw new Error(`Unknown Inventory Event Type '${eventType}'`);
    }
}

export function getGridOptionsForMasterRows(minWidth = 150)
{
    const gridOptions = biUtils.getGridOptionsForMasterRows();
    gridOptions.defaultColDef.minWidth = minWidth;
    return gridOptions;
}

export function getGridOptionsForDetailRows(minWidth = 150)
{
    const gridOptions = biUtils.getGridOptionsForDetailRows();
    gridOptions.defaultColDef.minWidth = minWidth;
    return gridOptions;
}
