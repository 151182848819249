
const stateManager = require('../../state/stateManager.js');
const stringUtil = require('../../string/stringUtil');
const currentOrgNodeSelectors = require('../../state/stateSelectors/currentOrgNodeSelectors');
const securityConstants = require('./securityConstants.js');
const configurationManager = require('../../config/configurationManager.js');

module.exports.getPassport = function ()
{
    var state = stateManager.getStore().getState();
    if (state == null)
        return null;

    return state.passport;
}

module.exports.isUserAuthenticated = function ()
{
    var passport = this.getPassport();
    return passport != null;
}

module.exports.isSystemAdmin = function ()
{
    var passport = this.getPassport();

    if (passport == null || passport.roles == null)
        return false;

    for (var index = 0; index < passport.roles.length; index++)
    {
        if (stringUtil.areStringSame(passport.roles[index].roleCode, securityConstants.roleCodes.SystemAdmin))
            return true;
    }

    return false;
}

module.exports.isSupportAdmin = function ()
{
    var passport = this.getPassport();

    if (passport == null || passport.roles == null)
        return false;

    for (var index = 0; index < passport.roles.length; index++)
    {
        if (stringUtil.areStringSame(passport.roles[index].roleCode, securityConstants.roleCodes.SupportAdmin))
            return true;
    }

    return false;
}

module.exports.isSupportUser = function ()
{
    var passport = this.getPassport();

    if (passport == null || passport.roles == null)
        return false;

    for (var index = 0; index < passport.roles.length; index++)
    {
        if (stringUtil.areStringSame(passport.roles[index].roleCode, securityConstants.roleCodes.Support))
            return true;
    }

    return false;
}

module.exports.isMenuSupportUser = function ()
{
    var passport = this.getPassport();

    if (passport == null || passport.roles == null)
        return false;

    for (var index = 0; index < passport.roles.length; index++)
    {
        if (stringUtil.areStringSame(passport.roles[index].roleCode, securityConstants.roleCodes.MenuSupport))
            return true;
    }

    return false;
}

module.exports.isOwner = function()
{
    return this.isRoleAllowed(securityConstants.roleCodes.Owner);
}

module.exports.isSupportElevatedUser = function()
{
    return this.isRoleAllowed(securityConstants.roleCodes.SupportElevated);
}

module.exports.isSystemUser = function ()
{
    return this.isSystemAdmin() || this.isSupportAdmin() || this.isSupportUser() || this.isMenuSupportUser();
}

module.exports.doesHaveAnyAccessAtOrgLevel = function(franchisorId, franchiseeId, propertyId)
{
    if (!this.isUserAuthenticated())
        return false;

    if (this.isSystemUser())
        return true;

    var passport = this.getPassport();
    
    for (var index = 0; index < passport.roles.length; index++)
    {
        var role = passport.roles[index];
        if (stringUtil.areStringSame(franchisorId, role.franchisorId) &&
            stringUtil.areStringSame(franchiseeId, role.franchiseeId) &&
            stringUtil.areStringSame(propertyId, role.propertyId))
            return true;
    }

    return false;
}

module.exports.isRoleAllowed = function(roleCode)
{
    if (!this.isUserAuthenticated())
        return false;

    if (stringUtil.areStringSame(roleCode, securityConstants.roleCodes.SystemAdmin) && this.isSystemAdmin())
        return true;

    if (stringUtil.areStringSame(roleCode, securityConstants.roleCodes.SupportAdmin) && this.isSupportAdmin())
        return true;

    if (stringUtil.areStringSame(roleCode, securityConstants.roleCodes.Support) && this.isSupportUser())
        return true;
    
    if (stringUtil.areStringSame(roleCode, securityConstants.roleCodes.MenuSupport) && this.isMenuSupportUser())
        return true;

    var orgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    var passport = this.getPassport();
    
    for (var index = 0; index < passport.roles.length; index++)
    {
        var passportRole = passport.roles[index];
        if (!stringUtil.areStringSame(passportRole.roleCode, roleCode))
            continue;

        if ( (stringUtil.areStringSame(roleCode, securityConstants.roleCodes.Owner) || 
              stringUtil.areStringSame(roleCode, securityConstants.roleCodes.SupportElevated) ||
              stringUtil.areStringSame(roleCode, securityConstants.roleCodes.ManagedOwner) || 
              stringUtil.areStringSame(roleCode, securityConstants.roleCodes.FinanceManager)
             ) && 
            stringUtil.areStringSame(orgNode.franchisorId, passportRole.franchisorId))
            return true;
       
        if ( (stringUtil.areStringSame(roleCode, securityConstants.roleCodes.StoreOwner) || 
              stringUtil.areStringSame(roleCode, securityConstants.roleCodes.StoreManager) || 
              stringUtil.areStringSame(roleCode, securityConstants.roleCodes.FinanceManager)) && 
            stringUtil.areStringSame(orgNode.franchisorId, passportRole.franchisorId) && 
            stringUtil.areStringSame(orgNode.franchiseeId, passportRole.franchiseeId) && 
            stringUtil.areStringSame(orgNode.propertyId, passportRole.propertyId))
          return true;
    }

    return false;
}

module.exports.getUserRoleCodes = function()
{
    var passport = this.getPassport();
    if (passport == null)
        return [];

    return passport.roles.map(role => role.roleCode);
}

module.exports.isValidPassport = function(passport)
{
    if (passport == null)
        return false;

    if (passport.roles == null || passport.roles.length <= 0)
        return false;

    return true;
}