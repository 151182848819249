
import React, { Component } from 'react';

import Select from 'react-select';
import { FormGroup, FormText, InputGroup, InputGroupText, Label } from 'reactstrap';

const stringUtil = require('../../../utils/string/stringUtil');

export default class TwentyFourHourTimeInputComponent extends Component
{
    constructor(props) 
    {
        super(props);

        this.currentHour = null;
        this.currentMinute = null;

        const initialTime = this.props.formManager.getValue(this.props.fieldName);
        if (!stringUtil.isStringNullOrEmpty(initialTime))
        {
            const values = initialTime.split(":");

            if (values.length == 3)
            {
                this.currentHour = getPossibleHours().find(x => x.value == values[0]).value;
                this.currentMinute = getPossibleMinutes().find(x => x.value == values[1]).value;
            }
        }
    }

    on24HourTimeChanged = () =>
    {
        let value = null;

        if (this.currentHour && this.currentMinute)
        {
            value = `${this.currentHour}:${this.currentMinute}:00`;
        }

        const event = {
            target: {
                id: this.props.fieldName,
                value: value,
                type: "24HourTime",
            }
        }

        this.props.formManager.onValueChanged(event);
    }

    onHoursChanged = (optionValue) =>
    {
        this.currentHour = optionValue.value;
        this.currentMinute = stringUtil.isStringNullOrEmpty(this.currentHour) ? null : this.currentMinute || "00";
        this.on24HourTimeChanged();
    }

    onMinutesChanged = (optionValue) =>
    {
        this.currentMinute = optionValue.value;
        this.currentHour = stringUtil.isStringNullOrEmpty(this.currentMinute) ? null : this.currentHour || "00";
        this.on24HourTimeChanged();
    }

    onClearButtonClick = () =>
    {
        this.currentHour = null;
        this.currentMinute = null;
        this.on24HourTimeChanged();
    }

    render()
    {
        const addonIcon = "fa fa-clock-o";
        const captionTextComponent = <Label>{this.props.caption}</Label>
        const hintTextComponent = <FormText color="muted">{this.props.hintText}</FormText>;

        return (

            <FormGroup {...this.props} style={this.props.mainStyle}>

                {captionTextComponent}

                <InputGroup>

                    <InputGroupText><i className={addonIcon}></i></InputGroupText>
                    
                    <div style={{
                        display: "inline-flex",
                        flexFlow: "row nowrap",
                        alignItems: "center",
                        fontSize: "14px"
                    }}>

                        <div style={{ width: "75px" }}>
                            <Select
                                id={this.props.fieldName}
                                value={this.currentHour}
                                options={getPossibleHours()}
                                disabled={this.props.inputReadOnly}
                                {...this.props}
                                placeholder={<span style={{ fontSize: 14, color: "lightgrey" }}>Hrs</span>}
                                clearable={false}
                                onChange={this.onHoursChanged}
                                style={this.props.backgroundColorStyle}
                            />
                        </div>

                        <div style={{ width: "75px", margin: "0px 0px 0px 0px" }}>
                            <Select
                                id={this.props.fieldName}
                                value={this.currentMinute}
                                options={getPossibleMinutes()}
                                disabled={this.props.inputReadOnly}
                                {...this.props}
                                placeholder={<span style={{ fontSize: 14, color: "lightgrey" }}>Mins</span>}
                                clearable={false}
                                onChange={this.onMinutesChanged}
                                style={this.props.backgroundColorStyle}
                            />
                        </div>

                        {this.props.clearable && !this.props.inputReadOnly
                            ? <div
                                style={{ margin: "0px 10px" }}
                                onClick={this.onClearButtonClick}>
                                <i className="fa fa-times" style={{ fontSize: "20px", color: "grey" }}></i>
                            </div>
                            : null}

                    </div>

                </InputGroup>

                {hintTextComponent}

            </FormGroup>);
    }
}

function getPossibleHours()
{
    const options = [];

    for (let i = 0; i <= 23; i++)
    {
        let formattedString = i + "";
        formattedString = formattedString.padStart(2, '0');

        let option = {};
        option.value = formattedString;
        option.label =
            <span>{formattedString}
                <span style={{ margin: "0px 0px 0px 2px", fontSize: "8px" }}>hrs</span>
            </span>;
        options.push(option);
    }
    return options;
}

function getPossibleMinutes()
{
    var options = [];

    for (var i = 0; i <= 59; i++)
    {
        var formattedString = i + "";
        formattedString = formattedString.padStart(2, '0');

        let option = {};
        option.value = formattedString;
        option.label =
            <span>{formattedString}
                <span style={{ margin: "0px 0px 0px 2px", fontSize: "8px" }}>mins</span>
            </span>;
        options.push(option);
    }
    return options;
}