import React, { Component } from "react";
import { connect } from "react-redux";

import { confirmAlert } from "react-confirm-alert";
import AlertContainer from "./components/alert/alertContainer.js";
import AttachmentForm from "./components/attachment/attachmentForm.js";
import ModalForm from "./components/modalForm/modalForm.js";
import ModalSpinner from "./components/spinner/modalSpinner.js";
import EmbeddedRouter from './EmbeddedRouter.js';

var viewUtil = require("./utils/view/viewUtil.js");

class EmbeddedView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        this.showConfirmationDialogue();
        return (
            <div style={{ verticalAlign: "top", padding: "0px", flex: 1, overflow: 'auto', height: '100%' }}>
                <EmbeddedRouter/>
                <AlertContainer />
                <ModalSpinner />
                {this.showModalForm()}
                {this.showAttachmentForm()}
            </div>
        );
    }

    showConfirmationDialogue() {
        if (this.props.confirmDialogue == null) return;

        var confirmOptions = {};
        confirmOptions.title = this.props.confirmDialogue.title;
        confirmOptions.message = this.props.confirmDialogue.message;
        confirmOptions.buttons = [
            {
                label: "Yes",
                onClick: () => {
                    viewUtil.closeConfirmDialogue();
                    this.props.confirmDialogue.onConfirm();
                },
            },
            {
                label: "No",
                onClick: () => {
                    viewUtil.closeConfirmDialogue();
                    if (this.props.confirmDialogue.onCancel != null) this.props.confirmDialogue.onCancel();
                },
            },
        ];

        confirmOptions.afterClose = () => {
            viewUtil.closeConfirmDialogue();
        };
        confirmOptions.overlayClassName = "confirm-alert-overlay";
        confirmAlert(confirmOptions);
    }

    showModalForm() {
        var modalFormsComponents = [];

        var index = 0;
        this.props.modalForms.forEach((modalForm) => {
            if (modalForm != null && modalForm.componentCreator != null) modalFormsComponents.push(<ModalForm modalFormIndex={index}></ModalForm>);

            index = index + 1;
        });

        return modalFormsComponents;
    }

    showAttachmentForm() {
        if (this.props.attachmentForm == null || this.props.attachmentForm.objectType == null || this.props.attachmentForm.objectId == null) return;

        return <AttachmentForm />;
    }
}

const mapStateToProps = (state) => {
    return {
        confirmDialogue: state.confirmDialogue,
        modalForms: state.modalForms,
        attachmentForm: state.attachmentForm,
    };
};

export default connect(mapStateToProps)(EmbeddedView);
