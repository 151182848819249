
module.exports.isValid24HourTimeFormat = function (value)
{
    return /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/.test(value); // hh:mm:00 24hr format
}

module.exports.isValidColorHexString = function (value)
{
    return /^#[0-9a-f]{6}/i.test(value); // #XXXXXXXX
}

// ignores spaces before/after comma in string.split(regexSplitByComma)
module.exports.regexSplitByComma = /\s*(?:,|$)\s*/;
