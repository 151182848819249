import * as stringUtil from '../string/stringUtil';

export function getNumericComparer(propertyName, isReverse = false)
{
    // Returns a number comparer function

    if (propertyName != null)
    {
        return (a, b) => isReverse
            ? a[propertyName] - b[propertyName]
            : b[propertyName] - a[propertyName];
    }
    else
    {
        return (a, b) => isReverse
            ? a - b
            : b - a;
    }
}

export function localeCompareStr(str1, str2, reverse = false)
{
    return stringUtil.localeCompareStr(str1, str2, reverse);
}

export function localeCompareProp(prop, reverse = false)
{
    return stringUtil.localeCompareProp(prop, reverse)
}
