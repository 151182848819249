import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as biChartUtils from '../../../../utils/bi/chartUtils';

export default function PieChartReport(props)
{
    return <AgChartsReact options={getOptions(props)} />;
}

function getOptions(props)
{
    const options = biChartUtils.getDefaultPieChartOptions();

    options.legend = { enabled: false };

    options.title = props.title && { text: props.title, fontSize: 12, fontWeight: 'bold' };
    options.subtitle = props.subTitle && { text: props.subTitle, fontSize: 10 };

    options.autoSize = true;
    options.padding = { top: 0, bottom: 0, left: 0, right: 0 };      // default: 20px each

    options.series[0].data = props.data;
    options.series[0].calloutLabelKey = 'label';
    options.series[0].sectorLabelKey = 'percentage';
    options.series[0].angleKey = 'percentage';

    options.series[0].calloutLabel.enabled = false;
    options.series[0].sectorLabel.formatter = ({ datum, sectorLabelKey }) => props.percentageFormatter(datum.percentage);

    options.series[0].tooltip.renderer = ({ datum, color, calloutLabelKey, sectorLabelKey }) => [
        `<div style="background-color: ${color}; padding: 4px 8px; border-top-left-radius: 5px; border-top-right-radius: 5px; font-weight: bold; color: white;">${datum.label}</div>`,
        `<div style="padding: 4px 8px">${props.valueFormatter(datum.value)} (${props.percentageFormatter(datum.percentage)})</div>`,
    ].join('\n');

    if (props.options != null) Object.assign(options, props.options);

    return options;
}