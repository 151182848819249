
import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import ProjectionOverrideContents from './components/ProjectionOverrideContents';
import projectionOverrideUtils from './utils/projectionOverrideUtils';

const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const viewUtil = require('../../../../utils/view/viewUtil');

class SalesProjectionOverride extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            loadCriteria: { startDate: dateUtil.getDateComponent(dateUtil.subtractDays(dateUtil.getNow(), 7)), endDate: dateUtil.getDateComponent(dateUtil.getNow()) },
            isLoading: false, overrideData: null,
            isError: false, errorMessage: null,
            isDirty: false
        };
    }

    componentDidMount()
    {
        this.onLoadData(this.state.loadCriteria);
    }

    onCriteriaChanged = () =>
    {
        this.setState(
            {
                loadCriteria: null,
                isLoading: false, overrideData: null,
                isError: false, errorMessage: null,
                isDirty: false
            });
    }

    onLoadData = async (loadCriteria) =>
    {
        this.setState(
            {
                loadCriteria: null,
                isLoading: true, overrideData: null,
                isError: false, errorMessage: null,
                isDirty: false
            });

        try
        {
            const overrideData = await projectionOverrideUtils.getProjectionOverrideData(loadCriteria);

            this.setState(
                {
                    loadCriteria: loadCriteria,
                    isLoading: false, overrideData: overrideData,
                });

        } catch (error)
        {
            this.setState(
                {
                    isLoading: false, overrideData: null,
                    isError: true, errorMessage: `Failed to load report data with error: ${error}`,
                });
        }
    }

    onSaveClick = async () =>
    {
        try
        {
            projectionOverrideUtils.validateProjectionOverrideData(this.state.overrideData);

            viewUtil.showSystemModalSpinner("Saving data, please wait ...");
            await projectionOverrideUtils.saveProjectionOverrideData(this.state.loadCriteria, this.state.overrideData);
            viewUtil.closeModalSpinner();

            projectionOverrideUtils.restoreProjectionOverrideData(this.state.overrideData);

            this.setState({ isDirty: false });

        } catch (error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(error);
            return;
        }
    }

    onCancelClick = () =>
    {
        if (this.state.loadCriteria != null)
        {
            this.onLoadData(this.state.loadCriteria);
        }
    }

    onSetDirty = () => 
    {
        // Called by ProjectionOverrideItem, when isDirty = true, 
        // SearchComponent is disabled, Save/Cancel is enabled

        if (!this.state.isDirty)
        {
            this.setState({ isDirty: true });
        }
    }

    render() 
    {
        return (

            <Card>

                <CardHeader>
                    {this.renderSearchBar()}
                </CardHeader>

                <CardBody>
                    {this.renderSearchResults()}
                </CardBody>

            </Card>);
    }

    renderSearchBar = () =>
    {
        return (
            <div style={{ display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <projectionOverrideUtils.SearchComponent {...this.state}
                        searchCriteria={this.state.loadCriteria}
                        onSearch={this.onLoadData}
                        onChange={this.onCriteriaChanged} />
                </div>
                <div style={{ display: "flex", flexFlow: "row nowrap" }}>
                    <div>
                        <projectionOverrideUtils.SaveButton {...this.state}
                            onClick={this.onSaveClick} />
                    </div>
                    <div>
                        <projectionOverrideUtils.CancelButton {...this.state}
                            onClick={this.onCancelClick} />
                    </div>
                </div>

            </div>
        );
    }

    renderSearchResults = () =>
    {
        return <ProjectionOverrideContents {...this.state} onSetDirty={this.onSetDirty} />;
    }
}

export default SalesProjectionOverride;