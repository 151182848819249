import arraySort from "array-sort";
import * as inventoryConstants from '../../../../../utils/domain/inventoryConstants';

export function getAggregatedSourceWastageItems(reportData)
{
    // Aggregates wastages by source stockItems, including source prep stockItems
    // Ingredients of prep stockItems are excluded

    const { wastagesData } = reportData;

    const sourceWastageItems = [];

    wastagesData.forEach(wastageItem => 
    {
        let sourceWastageItem = sourceWastageItems.find(item => item.stockItemId === wastageItem.stockItemId);

        if (sourceWastageItem == null)
        {
            sourceWastageItem = {
                stockItemId: wastageItem.stockItemId,
                stockItemName: wastageItem.stockItemName,
                itemType: wastageItem.itemType,
                unitCode: wastageItem.reportingUnitCode,
                quantity: 0,
                cost: 0
            };

            sourceWastageItems.push(sourceWastageItem);
        }

        sourceWastageItem.quantity += wastageItem.quantityInReportingUnits;
        sourceWastageItem.cost += wastageItem.cost;
    });

    sourceWastageItems.forEach(sourceWastageItem => 
    {
        sourceWastageItem.unitPrice = sourceWastageItem.cost / sourceWastageItem.quantity;  // average unitPrice per reportingUnit
    });

    return arraySort(sourceWastageItems, "stockItemName");
}

export function getAggregatedIngredientWastageItems(prepItem, reportData)
{
    // Aggregates ingredientItems by prepItem.stockItemId

    const { wastagesData } = reportData;

    const ingredientItems = [];

    wastagesData
        .filter(wastageItem => wastageItem.stockItemId === prepItem.stockItemId)
        .forEach(prepWastageItem => 
        {
            if (prepWastageItem.ingredientItems == null || prepWastageItem.ingredientItems.length === 0) return;

            prepWastageItem.ingredientItems.forEach(ingredientWastageItem => 
            {
                let ingredientItem = ingredientItems.find(ingredientItem => ingredientItem.stockItemId === ingredientWastageItem.stockItemId);

                if (ingredientItem == null)
                {
                    ingredientItem = {
                        stockItemId: ingredientWastageItem.stockItemId,
                        stockItemName: ingredientWastageItem.stockItemName,
                        itemType: ingredientWastageItem.itemType,
                        unitCode: ingredientWastageItem.reportingUnitCode,
                        quantity: 0,
                        cost: 0
                    };

                    ingredientItems.push(ingredientItem);
                }

                ingredientItem.quantity += ingredientWastageItem.quantityInReportingUnits;
                ingredientItem.cost += ingredientWastageItem.cost;
            });
        });

    ingredientItems.forEach(ingredientItem => 
    {
        ingredientItem.unitPrice = ingredientItem.cost / ingredientItem.quantity;   // average unitPrice per reportingUnit
    });

    return arraySort(ingredientItems, "stockItemName");
}

export function getFlattenedWastageItems(reportData)
{
    const { wastagesData } = reportData;

    let flattenedWastageItems = [];

    (function populateFlattenedWastageItems(wastageItems)
    {
        for (const wastageItem of wastageItems) 
        {
            flattenedWastageItems.push(wastageItem);

            if (wastageItem.ingredientItems != null && wastageItem.ingredientItems.length > 0)
            {
                populateFlattenedWastageItems(wastageItem.ingredientItems);     // recursion
            }
        }
    })(wastagesData);   // IIF

    // prepItems are excluded from the flattened list to avoid double entry as ingredients of the prepItem are also included in the list
    
    flattenedWastageItems = flattenedWastageItems.filter(wastageItem => wastageItem.itemType !== inventoryConstants.inventoryEventItemType.prepItem);

    return arraySort(flattenedWastageItems, (a, b) =>
    {
        // custom compare function to sort by businessDate desc, stockItemName asc

        if (b.businessDate > a.businessDate) return 1;
        if (b.businessDate < a.businessDate) return -1;
        return a.stockItemName.localeCompare(b.stockItemName);
    });
}

