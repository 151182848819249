import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as biUtils from '../../../../../../../utils/bi/biUtils';
import * as currentOrgNodeSelectors from '../../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';

const { formatCurrencyValue: formatCurrency, formatAmount, formatPercentage } = biUtils;


export default function ChartReport(props)
{
    // Bar Chart

    let dataRows, dataLabels;

    const { getSalesByPropertyChartDataRows, getSalesByVenueChartDataRows, getDistinctPropertyNames, getDistinctVenueCodes } = props;

    if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
    {
        dataRows = getSalesByPropertyChartDataRows();
        dataLabels = getDistinctPropertyNames();
    }
    else
    {
        dataRows = getSalesByVenueChartDataRows();
        dataLabels = getDistinctVenueCodes();
    }

    return (
        <div className='d-flex'>
            <div className='me-1 p-1 border rounded bg-light shadow-sm' style={{ width: '50%' }}>
                <AgChartsReact options={getQuantityChartOptions(dataRows, dataLabels)} />
            </div>
            <div className='ms-1 p-1 border rounded bg-light shadow-sm' style={{ width: '50%' }}>
                <AgChartsReact options={getPriceChartOptions(dataRows, dataLabels)} />
            </div>
        </div>
    );
}

function getQuantityChartOptions(dataRows, dataLabels)
{
    // total quantity for the entire fromDate/toDate period in case percentage is required off the entire period total
    // const totalQuantity = dataRows.reduce((sum, data) => sum + Object.keys(data).filter(key => key !== 'hour').reduce((sum, key) => sum + data[key].quantity, 0), 0);

    const chartData = [];

    dataRows.forEach(data => 
    {
        // total quantity for the data.hour
        const totalQuantity = Object.keys(data).filter(key => key !== 'hour').reduce((sum, key) => sum + data[key].quantity, 0);

        if (totalQuantity > 0)
        {
            const chartItem = { hour: data.hour };

            dataLabels.forEach(dataLabel =>
            {
                chartItem[dataLabel] = data[dataLabel]?.quantity || 0;
                chartItem[`${dataLabel}Percent`] = chartItem[dataLabel] / totalQuantity * 100;
            });

            chartData.push(chartItem);
        }
    });

    const series = dataLabels.map(dataLabel => ({
        type: 'bar',
        stacked: true,
        xKey: 'hour',
        yKey: dataLabel,
        yName: dataLabel,
        tooltip: { renderer: params => tooltipRenderer(params, formatAmount) },
        shadow: { enabled: true },
    }));

    return {
        title: { text: 'Comparison by Quantity' },
        autoSize: true,
        data: chartData,
        series: series,
        axes: [
            {
                type: 'category',
                position: 'left',
            },
            {
                type: 'number',
                position: 'bottom',
            },
        ],
        legend: {
            enabled: true,
            position: 'top'
        },
    };
}

function getPriceChartOptions(dataRows, dataLabels)
{
    // total price for the entire fromDate/toDate period in case percentage is required off the entire period total
    // const totalPrice = dataRows.reduce((sum, data) => sum + Object.keys(data).filter(key => key !== 'hour').reduce((sum, key) => sum + data[key].retailPrice, 0), 0);

    const chartData = [];

    dataRows.forEach(data => 
    {
        // total price for the data.hour
        const totalPrice = Object.keys(data).filter(key => key !== 'hour').reduce((sum, key) => sum + data[key].retailPrice, 0);

        if (totalPrice > 0)
        {
            const chartItem = { hour: data.hour };

            dataLabels.forEach(dataLabel =>
            {
                chartItem[dataLabel] = data[dataLabel]?.retailPrice || 0;
                chartItem[`${dataLabel}Percent`] = chartItem[dataLabel] / totalPrice * 100;
            });

            chartData.push(chartItem);
        }
    });

    const series = dataLabels.map(dataLabel => ({
        type: 'bar',
        stacked: true,
        xKey: 'hour',
        yKey: dataLabel,
        yName: dataLabel,
        tooltip: { renderer: params => tooltipRenderer(params, formatCurrency) },
        shadow: { enabled: true },
    }));

    return {
        title: { text: 'Comparison by Retail Price' },
        autoSize: true,
        data: chartData,
        series: series,
        axes: [
            {
                type: 'category',
                position: 'left',
            },
            {
                type: 'number',
                position: 'bottom',
                label: { formatter: params => formatCurrency(params.value) },
            },
        ],
        legend: {
            enabled: true,
            position: 'top'
        },
    };
}

function tooltipRenderer(params, formatter)
{
    const { xValue, yValue, yName } = params;

    return {
        title: `${xValue} ${yName}`,
        content: `${formatter(yValue)} (${formatPercentage(params.datum[`${yName}Percent`])})`
    }
}
