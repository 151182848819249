import React, { Component } from 'react';

import TimingTabularRenderer from './helperComponents/TimingTabularRenderer';
import TimingChartRenderer from './helperComponents/TimingChartRenderer';

import * as reportDataUtils from '../utils/reportDataUtils';

class PrepTimeAnalytics extends Component
{
    render()
    {
        const { timingData } = this.props;

        const normalizedTimingData = reportDataUtils.getNormalizedTimingData(timingData);

        return (
            <div className='d-flex flex-column'>

                <div>
                    <TimingTabularRenderer
                        averageTimeCaption={"Prep Time"}
                        timingData={normalizedTimingData} />
                </div>

                <div className='mt-2 border'>

                    <TimingChartRenderer
                        title="Prep Time"
                        subtitle="Average order prep time in minutes"
                        timingData={normalizedTimingData} />
                </div>

            </div>
        );
    }
}

export default PrepTimeAnalytics;