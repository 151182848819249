import React from "react";
import { Table } from "reactstrap";
import EmptyRolesBanner from "./EmptyRolesBanner";

export default function ExistingRoles({ roles, handleViewEdit })
{
    if (roles.length)
    {
        return (
            <>
                <h3>Existing Staff Roles</h3>
                <Table className="role-table" size="md" responsive={true} bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            roles.map(
                                role => <RoleTableRow key={role.rowKey} role={role} dbClickHandler={() => handleViewEdit(role.id)} />
                            )
                        }
                    </tbody>
                </Table>
            </>
        )
    }
    return (
        <EmptyRolesBanner />
    )
}

function RoleTableRow({ role, dbClickHandler })
{
    return (
        <tr onDoubleClick={dbClickHandler}>
            <td className="role-name-td">{role.name}</td>
            <td className="role-desc-td">{role.description}</td>
        </tr>
    )
}