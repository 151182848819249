import { Component } from 'react';
import StockItemUnitTypes from './StockItemUnitTypes';
import StockItemOperationalUnitTypes from './StockItemOperationalUnitTypes';

class StockItemUnits extends Component
{
    render()
    {
        return  <div>
                    <div style={{ paddingBottom: "20px" }}>
                        <StockItemUnitTypes {...this.props} />
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <StockItemOperationalUnitTypes {...this.props} />
                    </div>
                </div>
    }
}

export default StockItemUnits;