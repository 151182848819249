import React, { useCallback, useMemo, useState } from "react";
import { NetworkDiagramActionTypes, useNetworkDiagramContext } from "../../../../store";
import { deviceFieldsConfiguration } from "../../../../configuration/deviceFieldsConfiguration";
import { DeviceTypes, LabelPrinterTypes, ReceiptPrinterTypes } from "../../../../constants";
import { showConfirmDialogue } from "../../../../../../../utils/view/viewUtil";
import ActionDropdown from "../components/ActionDropdown/ActionDropdown";
import DeviceNodeHeader from "./DeviceNodeHeader";
import styled from "styled-components";
import { Handle, Position } from "reactflow";
import ConnectedTypeLabel from "./ConnectedTypeLabel";
import AddKotBtn from "./AddKotBtn";
import { addFieldOptions } from "../../../Form";
import ViewEditBtn from "../components/ViewEditBtn";
import securityManager from "../../../../../../../utils/domain/security/securityManager";

const ConnectedTypeColor = {
     usb: "#56E200",
     bluetooth: "#8EC6FF",
     network: "#F7941D",
     cloud: "#11DDC4",
};

const DeviceNode = ({ data }) => {
     const {
          dispatch,
          state: { kitchenStationProfiles },
     } = useNetworkDiagramContext();
     const { id, stationId, deviceName, type, configuration } = data;
     const [isExpanded, setIsExpanded] = useState(false);

     const canDeleteNode = securityManager.isSystemAdmin() || securityManager.isSupportAdmin() || securityManager.isSupportElevatedUser();

     const generateFields = useCallback((fieldsConf, fieldsValue, configuration) => {
          const fieldsData = [];
          function recursive(fieldsArr) {
               if (!fieldsArr) return;
               fieldsArr.forEach((field) => {
                    fieldsData.push({
                         ...field,
                         defaultValue: fieldsValue[field.fieldName] ? fieldsValue[field.fieldName] : configuration[field.fieldName],
                    });
                    if (field.subFields) {
                         recursive(field.subFields[configuration[field.fieldName]]);
                    }
               });
          }
          recursive(fieldsConf);

          return fieldsData;
     }, []);

     const title = useMemo(() => {
          if (type === DeviceTypes.creditCardReader) return "Credit Card Reader";
          if (type === DeviceTypes.telephoneCli) return "Telephone CLI";
          if (type === DeviceTypes.kotPrinter) return "KOT";
          return deviceName;
     }, [deviceName, type]);

     const fields = useMemo(() => {
          const fieldsConf = deviceFieldsConfiguration[type];

          const fieldsValue = {
               deviceName: title,
          };

          const fields = generateFields(fieldsConf, fieldsValue, configuration);

          switch (type) {
               case DeviceTypes.kotPrinter: {
                    fields.forEach((field) => {
                         switch (field.fieldName) {
                              case "kitchenStationId":
                                   addFieldOptions(
                                        field,
                                        kitchenStationProfiles.filter((ksp) => ksp.stationType === "Printer"),
                                        "id",
                                        "stationName"
                                   );
                                   break;
                              default:
                                   break;
                         }
                    });
                    break;
               }
               default:
                    break;
          }

          return fields;
     }, [configuration, title, type, generateFields, kitchenStationProfiles]);

     const handleOptionEditClick = useCallback(() => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_DEVICE_POPUP,
               payload: {
                    id,
                    stationId,
               },
          });
     }, [dispatch, id, stationId]);

     const handleOptionDeleteClick = useCallback(() => {
          showConfirmDialogue(`You are going to remove device ${title}`, "This action cannot be undone. Are you sure you want to continue", () => {
               dispatch({
                    type: NetworkDiagramActionTypes.DELETE_DEVICE,
                    payload: {
                         id,
                    },
               });
          });
     }, [dispatch, id, title]);

     const actionOptions = useMemo(() => {
          switch (type) {
               case DeviceTypes.receiptPrinter:
               case DeviceTypes.labelPrinter:
                    return [
                         {
                              title: "Delete",
                              props: {
                                   onClick: handleOptionDeleteClick,
                              },
                              titleColor: "#ff5757",
                              enabled: canDeleteNode
                         },
                    ];

               default:
                    return [
                         {
                              title: "Delete",
                              props: {
                                   onClick: handleOptionDeleteClick,
                              },
                              titleColor: "#ff5757",
                              enabled: canDeleteNode
                         },
                    ];
          }
     }, [handleOptionEditClick, handleOptionDeleteClick, type]);

     const colorThemeAndLabel = useMemo(() => {
          if (type === DeviceTypes.receiptPrinter) {
               if (configuration.receiptPrinterType === ReceiptPrinterTypes.genericUSB) return { label: "USB", color: ConnectedTypeColor.usb };
               if (configuration.receiptPrinterType === ReceiptPrinterTypes.genericBluetooth) return { label: "Bluetooth", color: ConnectedTypeColor.bluetooth };
               if (configuration.receiptPrinterType === ReceiptPrinterTypes.genericNetwork) return { label: "Network", color: ConnectedTypeColor.network };
               if (configuration.receiptPrinterType === ReceiptPrinterTypes.sunmiCloudPrinter) return { label: "Cloud", color: ConnectedTypeColor.cloud };
          } else if (type === DeviceTypes.labelPrinter) {
               if (configuration.labelPrinterType === LabelPrinterTypes.genericUSB) return { label: "USB", color: ConnectedTypeColor.usb };
               if (configuration.labelPrinterType === LabelPrinterTypes.genericBluetooth) return { label: "Bluetooth", color: ConnectedTypeColor.bluetooth };
               if (configuration.labelPrinterType === LabelPrinterTypes.genericNetwork) return { label: "Network", color: ConnectedTypeColor.network };
               if (configuration.labelPrinterType === LabelPrinterTypes.sunmiCloudPrinter) return { label: "Cloud", color: ConnectedTypeColor.cloud };
               if (configuration.labelPrinterType === LabelPrinterTypes.brotherQL700Usb) return { label: "USB", color: ConnectedTypeColor.usb };
               if (configuration.labelPrinterType === LabelPrinterTypes.brotherQL800Usb) return { label: "USB", color: ConnectedTypeColor.usb };
               if (configuration.labelPrinterType === LabelPrinterTypes.brotherQL820Bluetooth) return { label: "Bluetooth", color: ConnectedTypeColor.bluetooth };
               if (configuration.labelPrinterType === LabelPrinterTypes.brotherQL820Network) return { label: "Network", color: ConnectedTypeColor.network };
               if (configuration.labelPrinterType === LabelPrinterTypes.sunmiV2Pro) return { label: "Network", color: ConnectedTypeColor.network };
               if (configuration.labelPrinterType === LabelPrinterTypes.zplGenericNetwork) return { label: "Network", color: ConnectedTypeColor.network };
               if (configuration.labelPrinterType === LabelPrinterTypes.zplGenericUSB) return { label: "USB", color: ConnectedTypeColor.usb };
          } else if (type === DeviceTypes.kotPrinter) {
               return { color: ConnectedTypeColor.network };
          } else if (type === DeviceTypes.telephoneCli) {
               return { color: ConnectedTypeColor.network };
          }

          return null;
     }, [configuration, type]);

     const handleAddKot = useCallback(() => {
          dispatch({
               type: NetworkDiagramActionTypes.OPEN_DEVICE_POPUP,
               payload: {
                    stationId: id,
                    type: DeviceTypes.kotPrinter,
               },
          });
     }, []);

     return (
          <Container color={colorThemeAndLabel?.color}>
               <Handle type="target" position={Position.Top} />
               {(type === DeviceTypes.receiptPrinter || type === DeviceTypes.labelPrinter) && <AddKotBtn onClick={handleAddKot} />}
               <ConnectedTypeLabel data={colorThemeAndLabel} />
               <ViewEditBtn color={colorThemeAndLabel?.color} onClick={handleOptionEditClick} />
               {securityManager.isSystemUser() && <ActionDropdown options={actionOptions} />}
               <DeviceNodeHeader deviceName={deviceName} deviceType={type} fields={fields} />
          </Container>
     );
};

export default DeviceNode;

const Container = styled.div`
     background-color: white;
     border-width: 3px;
     border-color: ${(props) => props.color ?? "#0b75d7"};
     border-style: solid;
     border-radius: 15px;
     width: 550px;
     cursor: auto;
     box-shadow: 0 5px 20px #00000020;
`;
