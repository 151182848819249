import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import CloseButton from '../../../../../../components/button/closeButton';
import DefaultView from '../../../../../../components/defaultView/defaultView';
import SearchBarComponent from '../../../../../../components/form/searchBarComponent';

import * as rmsApiProxy from '../../../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../../../utils/api/rmsApiProxyUtils';

import TabularReport from './components/TabularReport';
import ChartReport from './components/ChartReport';

import * as reportDataUtils from './utils/reportDataUtils';

class SalesByReportingTag extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            salesData: null,
            error: null,
            isLoading: false,
        }
    }

    render()
    {
        let component;

        const { isLoading, error, salesData } = this.state;

        if (DefaultView.isDefaultView(isLoading, error, salesData))
        {
            component = <DefaultView {...{ isLoading, error, data: salesData }} />
        }
        else
        {
            component = this.renderReportData();
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold flex-grow-1 text-center'>Sales By Reporting Tags</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData()
    {
        return (
            <div className='d-flex flex-column'>
                <div>
                    <TabularReport
                        getMasterDataRows={this.getMasterDataRows}
                        getSalesByPropertyDetailDataRows={this.getSalesByPropertyDetailDataRows}
                        getSalesByVenueDetailDataRows={this.getSalesByVenueDetailDataRows} />
                </div>
                <div className='mt-2'><ChartReport getChartDataRows={this.getChartDataRows} /></div>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ salesData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, salesData: null, isLoading: true });

        try 
        {
            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportsContextUrl()}/accounts/sales/salesByReportingTag` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const salesData = await rmsApiProxy.get(resourceUrl);

            this.setState({ salesData });
        }
        catch (error) 
        {
            this.setState({ error: error });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    getMasterDataRows = () => reportDataUtils.getMasterDataRows(this.state);

    getSalesByPropertyDetailDataRows = (masterRow) => reportDataUtils.getSalesByPropertyDetailDataRows(masterRow, this.state);

    getSalesByVenueDetailDataRows = (masterRow) => reportDataUtils.getSalesByVenueDetailDataRows(masterRow, this.state);

    getChartDataRows = () => reportDataUtils.getChartDataRows(this.state);
}

export default SalesByReportingTag;