import React, { Component } from 'react';

import BISpinnerComponent from '../components/BISpinnerComponent';
import BIErrorComponent from '../components/BIErrorComponent';
import BICaptionComponent from '../components/BICaptionComponent';
import BIDateSelectionComponent from '../components/BIDateSelectionComponent';

class CustomBIToolBaseComponent extends Component
{
    //
    // CustomBIToolBaseComponent is meant to serve as base class for BI tool components that do not require
    // ag-gid customization and state management
    //

    constructor(props)
    {
        super(props);

        // Note: state is initialized in the base class

        this.state = {
            stage: 0,
            error: null,
            searchCriteria: null,
            data: null
        }
    }

    renderCaptionComponent(caption)
    {
        return <BICaptionComponent caption={caption} />;
    }

    renderErrorComponent(errorMessage)
    {
        return <BIErrorComponent error={errorMessage} />;
    }

    renderSpinnerComponent(message)
    {
        return <BISpinnerComponent text={message} />;
    }

    renderMultiDateSelectionHeaderComponent(title, disabled, closeEnabled, onLoad, onChange)
    {
        // BIDateSelectionComponent does not show "Save As..." button that handles
        // ag-grid state management

        return <BIDateSelectionComponent
            title={title}
            disabled={disabled}
            closeEnabled={closeEnabled}
            onLoadReport={(searchCriteria) => onLoad(searchCriteria)}
            onCriteriaChanged={() => onChange()}
        />
    }

    render()
    {
        return (
            <div className='h-100 d-flex justify-content-center align-items-center'>
                <h3>CustomBIToolBaseComponent: Please override render and provide your custom component</h3>
            </div>
        );
    }
}

export default CustomBIToolBaseComponent;