
import React from 'react';
import BIToolBaseComponent from '../common/regularBITools/BIToolBaseComponent';

const stringUtil = require('../../../utils/string/stringUtil');
const localizationUtils = require('../../../utils/domain/localizationUtils.js');
const nomenclatureUtils = require('../../../utils/domain/nomenclatureUtils.js');

const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const rmsApiProxyUtils = require('../../../utils/api/rmsApiProxyUtils');

class DailyVenueCommissions extends BIToolBaseComponent
{
    constructor(props)
    {
        super(props);

        // this.state is initialized by base class

        this.state.stage = 0;
        this.state.entities = [];
        this.state.error = null;
    }

    render()
    {
        var resultComponent = null;

        if (this.state.stage == 0)
        {
            if (!stringUtil.isStringNullOrEmpty(this.state.error))
                resultComponent = this.renderErrorComponent(this.state.error)
            else
                resultComponent = this.renderCaptionComponent("BI (Business Intelligence) Tool :: Venue Commissions");
        }

        if (this.state.stage == 1)
        {
            this.loadData();
            resultComponent = this.renderSpinnerComponent("Please Wait");
        }

        if (this.state.stage == 2)
        {
            resultComponent = this.renderGrid(this.generateColumnDes(), this.state.entities);
        }

        return <div>
            {this.renderMultiDateSelectionHeaderComponent
                (
                    this.componentName || "Daily Venue Commissions",
                    this.state.stage === 1,
                    true,
                    (searchCriteria) =>
                    {
                        this.state.stage = 1;
                        this.state.dateCriteria = searchCriteria;
                        this.setState({});
                    },
                    () =>
                    {
                        this.state.error = null;
                        this.state.dateCriteria = null;
                        this.state.entities = [];
                        this.state.stage = 0;
                        this.setState({});
                    })}
            {resultComponent}
        </div>;
    }

    generateColumnDes()
    {
        var uniquePropertyNames = [...new Set(this.state.entities.map(e => e.property))];
        var columnDefinitions = [];

        if (uniquePropertyNames.length > 1)
        {
            columnDefinitions.push({ field: 'franchisor', type: 'rowGroup' });
            columnDefinitions.push({ field: 'property', type: 'rowGroup' });
            columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'storefront', type: 'rowGroup' });
        }
        else
        {
            columnDefinitions.push({ field: 'franchisor', type: 'rowGroupEnabled' });
            columnDefinitions.push({ field: 'property', type: 'rowGroup' });
            columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'storefront', type: 'rowGroup' });
        }

        columnDefinitions.push({
            field: 'netOrderPriceIncTax', headerName: nomenclatureUtils.getValue(nomenclatureUtils.attributes.netOrderPriceIncTax), type: 'money',
            headerTooltip: `${nomenclatureUtils.getValue(nomenclatureUtils.attributes.retailOrderPrice)} - Discounts (Including ${localizationUtils.getVatCaption()})`});

        columnDefinitions.push({ field: 'venueCommission', type: 'money' });

        columnDefinitions.push({ field: 'year', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'month', type: 'rowGroupEnabled, month' });
        columnDefinitions.push({ field: 'week', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfMonth', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfWeek', type: 'rowGroupEnabled' });

        columnDefinitions.push({ field: 'retailOrderPrice', headerName: nomenclatureUtils.getValue(nomenclatureUtils.attributes.retailOrderPrice), type: 'money', hide: true, });
        columnDefinitions.push({ field: 'discount', type: 'money', hide: true, });
        columnDefinitions.push({ field: 'netOrderPrice', headerName: nomenclatureUtils.getValue(nomenclatureUtils.attributes.netOrderPrice), type: 'money', hide: true, });
        columnDefinitions.push({ field: 'tax', type: 'money', hide: true, });
        columnDefinitions.push({ field: 'venueCommissionPercentage', type: 'percentage', headerName: 'Commission %'});

        return columnDefinitions;
    }

    async loadData()
    {
        const fromDate = this.state.dateCriteria.startDate;
        const toDate = this.state.dateCriteria.endDate;

        const resourceUrl =
            `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/accounts/dailyRoyaltyByVenue` +
            `?fromDate=${fromDate}&toDate=${toDate}`;

        try 
        {
            const entities = await rmsApiProxy.get(resourceUrl);
            this.setState({ stage: 2, entities: entities });
        }
        catch (error) 
        {
            this.setState({ stage: 0, error: error });
        }
    }
}

export default DailyVenueCommissions;