import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard.js';
import DetailsSectionContainer from './DetailsSectionContainer.jsx';

const backgroundColor = "#e0e0e0";

const SaleDispatchDetails = (props) => {

    const minWidth = '230px';

    return (
        <CollapsibleCard caption={`Dispatch Details`} isDefaultOpen={props.isDefaultOpen}>
            <table width="100%">
                <tr style={{ verticalAlign: "top" }}>
                    <td width="50%">
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Driver Name</th>
                                <td width="49%">{props.sale.driverName}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Delivery Address</th>
                                <td width="49%">{props.sale.deliveryAddress}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Delivery Distance</th>
                                <td width="49%">{props.sale.deliveryDistance}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Delivery Order Status</th>
                                <td width="49%">{props.sale.deliveryStatus}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Delivery Status Notes</th>
                                <td width="49%">{props.sale.deliveryStatusNotes}</td>
                            </tr>
                        </DetailsSectionContainer>
                    </td>

                    <td width="50%">
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Customer Name</th>
                                <td width="49%">{props.sale.customerName}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Customer Phone Number</th>
                                <td width="49%">{props.sale.customerPhoneNumber}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Customer Address</th>
                                <td width="49%">{props.sale.deliveryAddress}</td>
                            </tr>
                        </DetailsSectionContainer>
                    </td>
                </tr>
            </table>
        </CollapsibleCard>
    );

}
 
export default SaleDispatchDetails;