import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import SalesByCategoriesSummary from './SalesByCategoriesSummary';
import SalesByCategoriesDetails from './SalesByCategoriesDetails';
import SalesByCategoriesCharts from './SalesByCategoriesCharts';

import { HeaderComponent } from '../../../utils/viewUtils';

export default class SalesByCategories extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { showDetails: false };
    }

    onShowDetails = () => this.setState({ showDetails: !this.state.showDetails })

    render()
    {
        const { showDetails } = this.state;

        return (
            <div className='d-flex flex-column border rounded-top'>

                <HeaderComponent caption="Sales By Categories" showDetails={showDetails} onShowDetails={this.onShowDetails} index={this.props.index} />

                <Collapse isOpen={!showDetails}>
                    <SalesByCategoriesSummary data={this.props.data} />
                </Collapse>

                <Collapse isOpen={showDetails}>
                    <div className='d-flex flex-column'>
                        <div>
                            <SalesByCategoriesDetails data={this.props.data} showDetails={showDetails} />
                        </div>
                        <div className='m-3 border rounded'>
                            <SalesByCategoriesCharts data={this.props.data} />
                        </div>
                    </div>
                </Collapse>

            </div>
        );
    }
}