import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../../components/form/selectComponent';

const domainConstants = require('../../../../../../utils/domain/constants');
const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class StorefrontOverview extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        const storefrontTypes = Object.values(domainConstants.getStorefrontTypes()).map(type => ({label: type.label, value: type.value}));

        return (            
            
            <table className="component-table">
                <tr>
                    <td>
                        <InputComponent 
                            inputType="Name" 
                            caption="Storefront Name" 
                            hintText="Required"
                            fieldName="name"
                            placeholder="Enter name for the storefront"
                            clearable={true}
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <SelectComponent 
                            caption="Brand"
                            fieldName="brandId"
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            optionValues={this.props.brands}
                            hintText="Required"
                            placeholder="Choose Brand"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} 
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        <SelectComponent
                            caption="Menu"
                            fieldName="menuId"
                            optionFieldName="menuId"
                            optionDisplayFieldName="name"
                            optionValues={this.props.menuHeaders}
                            hintText="Required"
                            placeholder="Choose Menu"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <SelectComponent
                            caption="Price Band"
                            fieldName="priceBandId"
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            optionValues={this.props.priceBands}
                            hintText="Required"
                            placeholder="Choose Price Band"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <SelectComponent
                            caption="Type"
                            fieldName="type"
                            optionFieldName="value"
                            optionDisplayFieldName="label"
                            optionValues={storefrontTypes}
                            hintText="Required"
                            placeholder="Choose Storefront Type"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>

                    </td>
                </tr>
            </table>
        );
    }    
}

export default StorefrontOverview;