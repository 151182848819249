import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as biUtils from '../../../../../../../utils/bi/biUtils';
import * as biChartUtils from '../../../../../../../utils/bi/chartUtils';

import * as reportHelperUtils from '../../utils/reportDataUtils';

const { formatCurrencyValue: formatCurrency, formatAmount, formatPercentage } = biUtils;

export default function ChartReport(props)
{
    const { getChartDataRows } = props;

    const chartData = getChartDataRows();

    const quantityChartData = chartData.filter(data => data.quantity > 0);
    const priceChartData = chartData.filter(data => data.retailPrice > 0);

    const topQuantityChartOptions = getOptions(
        reportHelperUtils.getQuantityChartData(quantityChartData), formatAmount,
        `Top 10 Menu Items By Quantity`, `10 Top Selling Menu Items Compared by Quantity`);

    const topPriceChartOptions = getOptions(
        reportHelperUtils.getPriceChartData(priceChartData), formatCurrency,
        `Top 10 Menu Items By Price`, `10 Top Selling Menu Items Compared by Price`);

    const bottomQuantityChartOptions = getOptions(
        reportHelperUtils.getQuantityChartData(quantityChartData, true), formatAmount,
        `Bottom 10 Menu Items By Quantity`, `10 Least Selling Menu Items Compared by Quantity`);

    const bottomPriceChartOptions = getOptions(
        reportHelperUtils.getPriceChartData(priceChartData, true), formatCurrency,
        `Bottom 10 Menu Items By Price`, `10 Least Selling Menu Items Compared by Price`);

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-center' style={{ height: '40vh' }}>
                <div className='w-50 m-1 p-1 border rounded'><AgChartsReact options={topQuantityChartOptions} /></div>
                <div className='w-50 m-1 p-1 border rounded'><AgChartsReact options={topPriceChartOptions} /></div>
            </div>
            <div className='d-flex justify-content-center' style={{ height: '40vh' }}>
                <div className='w-50 m-1 p-1 border rounded'><AgChartsReact options={bottomQuantityChartOptions} /></div>
                <div className='w-50 m-1 p-1 border rounded'><AgChartsReact options={bottomPriceChartOptions} /></div>
            </div>
        </div>
    );
}

function getOptions(chartData, valueFormatter, title, subTitle)
{
    const options = biChartUtils.getDefaultPieChartOptions();

    options.title = { text: title, fontSize: 18 };
    options.subtitle = { text: subTitle };

    options.autoSize = true;
    options.padding = { top: 30 };

    options.series[0].data = chartData;
    options.series[0].calloutLabelKey = 'label';
    options.series[0].sectorLabelKey = 'percentage';
    options.series[0].angleKey = 'percentage';

    options.series[0].calloutLabel.enabled = false;
    options.series[0].sectorLabel.formatter = ({ datum, sectorLabelKey }) => formatPercentage(datum.percentage);

    options.series[0].tooltip.renderer = ({ datum, color, calloutLabelKey, sectorLabelKey }) => [
        `<div style="background-color: ${color}; padding: 4px 8px; border-top-left-radius: 5px; border-top-right-radius: 5px; font-weight: bold; color: white;">${datum.label}</div>`,
        `<div style="padding: 4px 8px">${valueFormatter(datum.value)} (${formatPercentage(datum.percentage)})</div>`,
    ].join('\n');

    options.series[0].fills = [...Object.values(biChartUtils.palette)];

    options.legend.position = 'right';

    return options;
}
