import React, { Component } from 'react';
import { FormGroup, FormText, Label } from 'reactstrap';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';



const validator = require('../../utils/validator/validator');

// react-dropdown-tree-select
// https://www.npmjs.com/package/react-hierarchy-select#clearsearchonchange\

//
// Only mode=hierarchical and mode=radioSelect are supported OrgSelectComponent
// Other modes and features of the DropdownTreeSelect are not used at the moment
//

class OrgSelectComponent extends Component
{
    //
    // Documentation:
    // props:
    // allowedOrgNodes  = {franchisors:[], franchisees:[], properties:[]}
    // selectedOrgNodes = {franchisors:[], franchisees:[], properties:[]}
    // allowedOrgLevels = ["franchisor", "franchisee", "property"]
    // multiSelect      = true/false
    // callBack         = onChange(selectedOrgNodes)
    //

    constructor(props)
    {
        super(props);

        this.state = {
            data: this.getData()
        }
    }

    onChange = (currentNode, selectedNodes) =>
    {
        let dataNode;
        const data = this.state.data;

        // Reset all nodes as unselected

        (function resetNodes(nodes)
        {
            nodes.forEach(node => 
            {
                node.checked = false;

                if (node.children && node.children.length > 0)
                {
                    resetNodes(node.children);
                }
            });
        })(data);   // IIF


        // Update state and compile selectedOrgNodes

        const selectedOrgNodes = {
            franchisors: [],
            franchisees: [],
            properties: []
        }

        selectedNodes.forEach(node => 
        {
            switch (node.orgType)
            {
                case "franchisor":
                    selectedOrgNodes.franchisors.push(node.value);
                    dataNode = this.getNode(data, node);

                    dataNode.checked = true;
                    break;

                case "franchisee":
                    selectedOrgNodes.franchisees.push(node.value);
                    dataNode = this.getNode(data, node);

                    dataNode.checked = true;
                    break;

                case "property":
                    selectedOrgNodes.properties.push(node.value);
                    dataNode = this.getNode(data, node);

                    dataNode.checked = true;
                    break;
            }
        });

        // Notify parent

        if (this.props.onChange)
            this.props.onChange(selectedOrgNodes);
    }

    render()
    {
        return (
            <FormGroup>

                {
                    this.props.caption
                        ? <Label>{this.props.caption}</Label>
                        : null
                }

                <DropdownTreeSelect
                    data={this.state.data}
                    onChange={this.onChange}
                    mode={this.props.multiSelect ? "hierarchical" : "radioSelect"}
                    keepTreeOnSearch={true}
                    keepOpenOnSelect={false}
                    inlineSearchInput={true}
                    clearSearchOnChange={true}
                    texts={{ placeholder: "Select organisation ..." }}
                    disabled={this.props.isReadOnly}
                />

                {
                    this.props.hintText
                        ? <FormText color="muted">{this.props.hintText}</FormText>
                        : null
                }

            </FormGroup>
        );
    }

    getData = () =>
    {
        let allowedOrgNodes = this.props.allowedOrgNodes;
        let allowedOrgLevels = this.props.allowedOrgLevels;
        let selectedOrgNodes = this.props.selectedOrgNodes;

        //#region Validations

        if (!validator.isArray(allowedOrgLevels)) allowedOrgLevels = [];
        if (!validator.isObject(allowedOrgNodes)) allowedOrgNodes = { franchisors: [], franchisees: [], properties: [] };
        if (!validator.isObject(selectedOrgNodes)) selectedOrgNodes = { franchisors: [], franchisees: [], properties: [] };

        allowedOrgNodes.franchisors = allowedOrgNodes.franchisors || [];
        allowedOrgNodes.franchisees = allowedOrgNodes.franchisees || [];
        allowedOrgNodes.properties = allowedOrgNodes.properties || [];

        selectedOrgNodes.franchisors = selectedOrgNodes.franchisors || [];
        selectedOrgNodes.franchisees = selectedOrgNodes.franchisees || [];
        selectedOrgNodes.properties = selectedOrgNodes.properties || [];

        //#endregion

        // Root nodes in "data" may start at any of franchisor/franchisee/property level depending
        // upon allowedOrgNodes (i.e. not necessarily always at franchisor level)

        const data = [];

        if (allowedOrgNodes.franchisors.length > 0)
        {
            allowedOrgNodes.franchisors.forEach(franchisor => 
            {
                const franchisorNode = {
                    orgType: "franchisor",
                    label: franchisor.name,
                    value: franchisor,
                    checked: selectedOrgNodes.franchisors.some(x => x.id == franchisor.id),
                    disabled: !allowedOrgLevels.includes("franchisor"),
                    children: []
                };

                data.push(franchisorNode);
            });
        }

        if (allowedOrgNodes.franchisees.length > 0)
        {
            allowedOrgNodes.franchisees.forEach(franchisee => 
            {
                const franchiseeNode = {
                    orgType: "franchisee",
                    label: franchisee.code,
                    value: franchisee,
                    checked: selectedOrgNodes.franchisees.some(x => x.id == franchisee.id),
                    disabled: !allowedOrgLevels.includes("franchisee"),
                    children: []
                };

                const franchisorNode = data.find(franchisorNode => franchisorNode.value.id == franchisee.franchisorId);

                if (franchisorNode != null)
                {
                    // Implies franchisor at root node
                    franchisorNode.children.push(franchiseeNode);
                }
                else
                {
                    // Implies franchisee at root node
                    data.push(franchiseeNode);
                }
            });
        }

        if (allowedOrgNodes.properties.length > 0)
        {
            allowedOrgNodes.properties.forEach(property => 
            {
                let franchiseeNode = null;

                const propertyNode = {
                    orgType: "property",
                    label: property.name,
                    value: property,
                    checked: selectedOrgNodes.properties.some(x => x.id == property.id),
                    disabled: !allowedOrgLevels.includes("property"),
                };

                const franchisorNode = data.find(franchisorNode => franchisorNode.value.id == property.franchisorId);

                if (franchisorNode != null)
                {
                    // Implies franchisor at root node
                    franchiseeNode = franchisorNode.children.find(franchiseeNode => franchiseeNode.value.id == property.franchiseeId);
                }

                if (franchiseeNode == null)
                {
                    if (franchisorNode != null)
                    {
                        throw `OrgSelectComponent/getData: franchiseeNode is missing for propertyNode '${propertyNode.label}'`;
                    }

                    // Assume and check for franchisee at root node
                    franchiseeNode = data.find(franchiseeNode => franchiseeNode.value.id == property.franchiseeId);
                }

                if (franchiseeNode != null)
                {
                    // Implies franchisee at root node
                    franchiseeNode.children.push(propertyNode);
                }
                else
                {
                    // Implies property at root node
                    data.push(propertyNode);
                }
            });
        }

        return data;
    }

    getNode = (nodes, targetNode) =>
    {
        // Searches for a node in 'nodes' matching 'targetNode' in orgType and id

        let resultNode = null;

        for (const node of nodes)
        {
            if (node.orgType == targetNode.orgType && node.value.id == targetNode.value.id)
            {
                resultNode = node;
                break;
            }

            if (node.children && node.children.length > 0)
            {
                resultNode = this.getNode(node.children, targetNode);
                if (resultNode != null) break;
            }
        }

        return resultNode;
    }
}

export default OrgSelectComponent;