import React from "react";
import formatHighlight from "json-format-highlight";
import styled from "styled-components";
import { VscCopy } from "react-icons/vsc";
const viewUtil = require("../../../utils/view/viewUtil");

const dateUtil = require("../../../utils/dateUtil/dateUtil");

const AuditLogDetailContent = ({ logDetails }) => {
    if (!logDetails) return null;

    const blob = formatHighlight(logDetails.auditBlob, {
        keyColor: "##000000b5",
        numberColor: "#7a6af6",
        stringColor: "#67a4ff",
        trueColor: "#4dde1d",
        falseColor: "#d40b0b",
        nullColor: "#d4680b",
    });

    const handleOnCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(logDetails.auditBlob));
        viewUtil.showSuccessAlert("Copied");
    };

    return (
        <div className="py-4 px-4 d-flex flex-column overflow-auto" style={{ height: "98vh" }}>
            <div className="fs-5 fw-semibold mb-4">Details</div>
            <div className="mb-4">
                <LogDetailItem label="Message" value={logDetails.auditMessage} />
                <LogDetailItem label="Action" value={logDetails.auditAction} />
                <LogDetailItem label="Status" value={logDetails.auditStatus} />
                <LogDetailItem label="Date Time" value={dateUtil.convertFromISODateTimeFormat(logDetails.timestamp)} />
                <LogDetailItem label="User" value={logDetails.user} />
            </div>
            {logDetails.auditBlob && (
                <div style={{ display: "flex", flex: 1, height: 0, position: "relative" }}>
                    <CopyBtn onClick={handleOnCopy}><VscCopy/></CopyBtn>
                    <div style={{ flex: 1 }} className="border p-3 rounded overflow-auto">
                        <pre style={{marginBottom: 0}} dangerouslySetInnerHTML={{ __html: blob }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AuditLogDetailContent;

const LogDetailItem = ({ label, value }) => {
    return (
        <div className="d-flex flex-row border-bottom py-2">
            <div className="me-4" style={{ width: "100px" }}>
                {label}
            </div>
            <div style={{ flex: 1 }}>{value}</div>
        </div>
    );
};

const CopyBtn = styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
    padding: 4px 8px;
    cursor: pointer;
    transition: 200ms ease-in-out;
    :hover {
        background-color: #00000010;
    }
`;
