import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import SearchBarComponent from '../../../../../components/form/searchBarComponent';
import CloseButton from '../../../../../components/button/closeButton';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EmptyData from '../../../../../components/empty/emptyData';
import Spinner from '../../../../../components/spinner/spinner';

import PrepTimeAnalytics from './components/PrepTimeAnalytics';
import DispatchTimeAnalytics from './components/DispatchTimeAnalytics';
import DeliveryTimeAnalytics from './components/DeliveryTimeAnalytics';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const rmsApiProxyUtils = require('../../../../../utils/api/rmsApiProxyUtils');

class SaleTimingAnalytics extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            reportData: null,
            searchCriteria: null,
            error: null,
            isLoading: false,
        }
    }

    render()
    {
        let component;

        const { isLoading, error, reportData } = this.state;

        if (isLoading === true) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error != null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (reportData == null)
        {
            component = <div className='m-3'>
                <EmptyData
                    title="Data required"
                    text="Please choose date range and hit Search to get report data"
                    action={null}
                />
            </div>;
        }
        else
        {
            component = this.renderReportData(reportData);
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold'>Kitchen Timings Report</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData(reportData)
    {
        const { prepTimeData, dispatchTimeData, deliveryTimeData } = reportData;

        if (prepTimeData.length === 0 && dispatchTimeData.length === 0 && deliveryTimeData.length === 0)
        {
            return (
                <div className='m-3'>
                    <EmptyData
                        title="No Kitchen Timings Report Data Found"
                        text="No sales data to source kitchen timings analytics found for your selected date range"
                        action="Please choose a different date range and hit Search to get report data"
                    />
                </div>
            );
        }

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key='prepTime'>Prep Time</Tab>);
        tabContents.push(<TabPanel key='prepTime' className='bg-white mt-n1'><PrepTimeAnalytics timingData={prepTimeData} /></TabPanel>);

        tabHeaders.push(<Tab key='dispatchTime'>Dispatch Time</Tab>);
        tabContents.push(<TabPanel key='dispatchTime' className='bg-white mt-n1'><DispatchTimeAnalytics timingData={dispatchTimeData} /></TabPanel>);

        tabHeaders.push(<Tab key='deliveryTime'>Delivery Time</Tab>);
        tabContents.push(<TabPanel key='deliveryTime' className='bg-white mt-n1'><DeliveryTimeAnalytics timingData={deliveryTimeData} /></TabPanel>);

        return (
            <div className='mt-2 d-flex flex-column'>
                <div className='text-muted align-self-end' style={{ marginBottom: "-33px", marginRight: "33px" }}><small>All timing data are averaged time in minutes per sale</small></div>
                <div>
                    <Tabs>
                        <TabList>{tabHeaders}</TabList>
                        {tabContents}
                    </Tabs>
                </div>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ reportData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, reportData: null, searchCriteria: searchCriteria, isLoading: true });

        const fromDate = searchCriteria.startDate;
        const toDate = searchCriteria.endDate;

        try 
        {
            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportsContextUrl()}/accounts/sales/timingAnalytics` +
                `?fromDate=${fromDate}&toDate=${toDate}`

            const reportData = await rmsApiProxy.get(resourceUrl);

            this.setState({ reportData, isLoading: false });
        }
        catch (error) 
        {
            this.setState({ error: error, isLoading: false });
        }
    }
}

export default SaleTimingAnalytics;