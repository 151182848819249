import React, { Component } from 'react';
import { Input, Label } from 'reactstrap';

import * as dataUtils from '../utils/openingHourDataUtils';

class DispatchTypeSelector extends Component
{
    render()
    {
        const { dispatchTypes, onChange } = this.props;

        return (
            <div className='d-flex pt-1 justify-content-center'>
                {
                    Object.keys(dispatchTypes)
                        .filter(dispatchType => dispatchType !== dataUtils.allDispatchTypes)
                        .map(dispatchType =>
                            <Label key={dispatchType} className='me-4'>
                                <Input
                                    type='checkbox'
                                    checked={dispatchTypes[dispatchType]}
                                    onChange={(e) => onChange(dispatchType, e.target.checked)} />
                                &nbsp;{dispatchType}
                            </Label>)
                }
            </div>
        );
    }
}

export default DispatchTypeSelector;