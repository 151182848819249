
import React, { Component } from 'react';
import { FormGroup, FormText, Label } from 'reactstrap';

import Select from 'react-select';

const constants = require('./constants');


class SelectComponent extends Component 
{
    constructor(props) 
    {
        super(props);
        this.renderHintText = this.renderHintText.bind(this);
    }

    render()
    {

        //#region style overrides

        //
        // Extracts style property from props
        // BackgroundColor style is applied only to the <Select/> component
        // All the remaining style (like margins, paddings etc) is applied only to the <FormGroup/> component
        //

        const style = this.props.style || {};    // Get style from props
        const backgroundColorStyle = { backgroundColor: style.backgroundColor };    // Extract backgroundColor into a separate style
        delete style.backgroundColor;      // Delete backgroundColor from style

        if (this.props.comboReadOnly)
        {
            // Implies readonly mode

            if (!backgroundColorStyle.backgroundColor)
            {
                // Implies backgroundColor not set explicitly in props
                // backgroundColor is overridden only when not explicitly set in props

                backgroundColorStyle.backgroundColor = constants.readOnlyBackgroundColor;
            }
        }

        //
        // backgroundColor=undefined is observed to render as transparent
        //

        //#endregion


        var options = [];
        this.props.optionValues.forEach(optionValue =>
        {
            var option = {};
            option.value = optionValue[this.props.optionFieldName];
            option.label = optionValue[this.props.optionDisplayFieldName];
            option.underlyingObject = optionValue;
            option.disabled = optionValue.disabled;
            options.push(option);
        })

        var captionTextComponent = <Label>{this.props.caption}</Label>;

        return (

            // 
            // style after props overrides any style defined in props
            //

            <FormGroup {...this.props} style={style}>

                {captionTextComponent}

                <Select
                    id={this.props.fieldName}
                    value={this.props.formManager.getValue(this.props.fieldName)}
                    options={options}
                    disabled={this.props.comboReadOnly}

                    {...this.props} style={backgroundColorStyle}
                    
                    onChange={(optionValue) =>
                    {
                        var target = {};
                        target.type = "select";
                        target.id = this.props.fieldName;
                        target.value = optionValue == null || optionValue.underlyingObject == null ? null : optionValue.underlyingObject[this.props.optionFieldName];

                        var event = {};
                        event.target = target;
                        this.props.formManager.onValueChanged(event);
                    }}
                />

                {this.renderHintText()}

            </FormGroup>
        );

    }

    renderHintText()
    {
        var hintText = this.props.hintText;
        if (this.props.hintFieldName)
        {
            var fieldValue = this.props.formManager.getValue(this.props.fieldName);
            if (fieldValue)
            {
                var selectedOption = this.props.optionValues.find(oV => oV[this.props.optionFieldName] == fieldValue);
                if (selectedOption && selectedOption[this.props.hintFieldName])
                    hintText = selectedOption[this.props.hintFieldName];
            }
        }


        if (hintText)
            return (<FormText color="muted">{hintText}</FormText>);

        return null;
    }

}

export default SelectComponent;