import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import CdnImageUploader from '../../../../components/cdnImage/cdnImageUploader';

import ApplianceOverview from './ApplianceOverview';
import ApplianceServiceProfiles from './ApplianceServiceProfiles';
import ApplianceTemperatureProfile from './ApplianceTemperatureProfile';

const configurationManager = require('../../../../utils/config/configurationManager');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

export default class Appliance extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = {};
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();


        const imageFolder =
            `franchisors/${currentOrgNode.franchisorId}/franchisees/${currentOrgNode.franchiseeId}/properties/${currentOrgNode.propertyId}/appliances`;

        const tabHeaders = [];

        tabHeaders.push(<Tab>General</Tab>);
        tabHeaders.push(<Tab>Temperature Profile</Tab>);
        tabHeaders.push(<Tab>Service Profile</Tab>);
        tabHeaders.push(<Tab>Image</Tab>);

        const tabContents = [];

        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <ApplianceOverview onFieldChanged={() => this.setState({})} {...this.props} />
            </TabPanel>);

        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <ApplianceTemperatureProfile onFieldChanged={() => this.setState({})} {...this.props} />
            </TabPanel>);

        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <ApplianceServiceProfiles {...this.props} />
            </TabPanel>);

        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <CdnImageUploader
                    isReadOnly={this.props.isRowReadOnly}
                    cloudName={configurationManager.getConfig().cdnUsersCloudName}
                    imageFolder={imageFolder}
                    imageUrl={this.props.data.imageUrl}
                    onImageChange={(url) =>
                    {
                        this.props.data.imageUrl = url;
                        this.setState({});
                    }}>
                    <h4 className="display-5">No Image Found</h4>

                    <p className="lead">A icon is much more than just an image; it is start of settings standards and following conventions.</p>
                    <hr className="my-2" />
                    <p>A well-designed icon effectively conveys the message.</p>
                </CdnImageUploader>
            </TabPanel>);

        return (
            <Tabs>

                <TabList>
                    {tabHeaders}
                </TabList>

                {tabContents}

            </Tabs>
        );
    }
}