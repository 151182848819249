const stringUtil = require("../../string/stringUtil");
const dateUtil = require("../../dateUtil/dateUtil");
const validator = require("../../validator/validator");
const rmsApiProxy = require("../../api/rmsApiProxy");
const columnWidthConstants = require('../../constants/columnWidthConstants');
const dataTypeConstants = require('../../constants/dataTypeConstants');
const formatter = require('../../formatter/phoneNumberFormatter');

export default {
    saveCustomer,
    validateCustomer,
    validateMessage,
    constructGridColumnHeaders,
    getColumnNameFromFieldName,
    getFieldNameFromColumnName,
    transformImportedCustomerData
}

function validateMessage(message)
{
    if (stringUtil.isStringNullOrEmpty(message.type))
        return "Message type not specified";

    if (stringUtil.isStringNullOrEmpty(message.messageTemplate))
        return "Message not specified "

    if (stringUtil.isStringNullOrEmpty(message.titleTemplate))
        return "Title not specified "
}

async function saveCustomer(customer, franchisor)
{
    customer.phoneNumber = formatter.formatPhoneNumberForPersistence(customer.phoneNumber);

    return await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/customers`, customer);
}

function validateCustomer(customer, franchisor)
{
    if (stringUtil.isStringNullOrEmpty(customer.phoneNumber))
        return "Phone number cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(formatter.formatPhoneNumberForPersistence(customer.phoneNumber)))
        return "Phone number is not valid";

    if(validator.isPresent(customer.dateOfBirth) && !validator.isDate(customer.dateOfBirth))
    {
        return "Date of Birth is not valid. Required format is YYYY-MM-DD";
    }
}

function constructGridColumnHeaders()
{
    let header;

    var headers = [];

    header = {};
    header.field = "name";
    header.headerName = getColumnNameFromFieldName("name");
    header.width = columnWidthConstants.name;
    headers.push(header);

    header = {};
    header.field = "phoneNumber";
    header.headerName = getColumnNameFromFieldName("phoneNumber");
    header.width = columnWidthConstants.phoneNumber;
    header.type = dataTypeConstants.phoneNumber;
    headers.push(header);

    header = {};
    header.field = "emailAddress";
    header.headerName = getColumnNameFromFieldName("emailAddress");
    header.width = columnWidthConstants.emailAddress;
    headers.push(header);

    header = {};
    header.field = "dateOfBirth";
    header.headerName = getColumnNameFromFieldName("dateOfBirth");
    header.width = columnWidthConstants.date;
    headers.push(header);

    header = {};
    header.field = "address";
    header.headerName = getColumnNameFromFieldName("address");
    header.width = columnWidthConstants.address;
    headers.push(header);

    header = {};
    header.field = "address2";
    header.headerName = getColumnNameFromFieldName("address2");
    header.width = columnWidthConstants.address;
    headers.push(header);

    header = {};
    header.field = "address3";
    header.headerName = getColumnNameFromFieldName("address3");
    header.width = columnWidthConstants.address;
    headers.push(header);

    header = {};
    header.field = "address4";
    header.headerName = getColumnNameFromFieldName("address4");
    header.width = columnWidthConstants.address;
    headers.push(header);

    header = {};
    header.field = "notes";
    header.headerName = getColumnNameFromFieldName("notes");
    header.width = columnWidthConstants.address;
    headers.push(header);

    header = {};
    header.field = "isDisabled";
    header.headerName = getColumnNameFromFieldName("isDisabled");
    header.width = columnWidthConstants.boolean;
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "source";
    header.headerName = getColumnNameFromFieldName("source");
    header.width = columnWidthConstants.code;
    headers.push(header);

    header = {};
    header.field = "usingIgnisMobileApp";
    header.headerName = getColumnNameFromFieldName("usingIgnisMobileApp");
    header.width = columnWidthConstants.code;
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "appNotificationEnabledForOrderUpdates";
    header.headerName = getColumnNameFromFieldName("appNotificationEnabledForOrderUpdates");
    header.width = columnWidthConstants.code;
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "textNotificationEnabledForOrderUpdates";
    header.headerName = getColumnNameFromFieldName("textNotificationEnabledForOrderUpdates");
    header.width = columnWidthConstants.code;
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "appNotificationEnabledForOffers";
    header.headerName = getColumnNameFromFieldName("appNotificationEnabledForOffers");
    header.width = columnWidthConstants.code;
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "textNotificationEnabledForOffers";
    header.headerName = getColumnNameFromFieldName("textNotificationEnabledForOffers");
    header.width = columnWidthConstants.code;
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    return headers;
}

const columnFieldNameMappings = [

    // Import/export CSV files must use below given descriptive columnNames
    // Conversion between api fieldName and display columnName will be handled by code

    { fieldName: "name", columnName: "Name" },
    { fieldName: "phoneNumber", columnName: "Phone Number" },
    { fieldName: "address", columnName: "Address 1" },
    { fieldName: "address2", columnName: "Address 2" },
    { fieldName: "address3", columnName: "Address 3" },
    { fieldName: "address4", columnName: "Address 4" },
    { fieldName: "emailAddress", columnName: "Email Address" },
    { fieldName: "notes", columnName: "Internal Notes"},
    { fieldName: "dateOfBirth", columnName: "Date of Birth" },
    { fieldName: "isDisabled", columnName: "Disabled" },
    { fieldName: "source", columnName: "Source of Data" },
    { fieldName: "usingIgnisMobileApp", columnName: "Registered in App" },
    { fieldName: "appNotificationEnabledForOrderUpdates", columnName: "Receive App Order Updates" },
    { fieldName: "textNotificationEnabledForOrderUpdates", columnName: "Receive SMS Order Updates" },
    { fieldName: "appNotificationEnabledForOffers", columnName: "Receive App Offers" },
    { fieldName: "textNotificationEnabledForOffers", columnName: "Receive SMS Offers" },
]

function getColumnNameFromFieldName(fieldName)
{
    // Returns columnName mapped to fieldName

    if(stringUtil.isStringNullOrEmpty(fieldName)) return fieldName;

    fieldName = fieldName.trim().toLowerCase();
    const nameMapping = columnFieldNameMappings.find(nameMapping => nameMapping.fieldName.toLowerCase() === fieldName || nameMapping.columnName.toLowerCase() === fieldName );
    return (nameMapping && nameMapping.columnName) || fieldName;
}

function getFieldNameFromColumnName(columnName)
{
    // Returns fieldName mapped to columnName

    if(stringUtil.isStringNullOrEmpty(columnName)) return columnName;

    columnName = columnName.trim().toLowerCase();
    const nameMapping = columnFieldNameMappings.find(nameMapping => nameMapping.columnName.toLowerCase() === columnName || nameMapping.fieldName.toLowerCase() === columnName );
    return (nameMapping && nameMapping.fieldName) || columnName;
}

function transformImportedCustomerData(value, fieldName)
{
    // Handle any transformation required to save imported customer data to the database

    if (fieldName === "dateOfBirth")
    {
        if (!dateUtil.isValidDate(value)) return null;
    }

    return value;
}


