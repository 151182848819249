import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';

const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


export default function DiscountOverviewDetails(props)
{
    const discountsData = props.data.discountsData;
    const columnDefs = getColumnDefs();

    return (
        <div className="ag-theme-alpine" style={{ height: "75vh" }}>
            <AgGridReact
                domLayout='normal'
                rowData={discountsData}
                pinnedBottomRowData={getPinnedRows(discountsData)}
                gridOptions={biUtils.gridOptions}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
            />
        </div>);
}

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            e.api.setPinnedBottomRowData(getPinnedRows(rows));
        }
    });
}

function getColumnDefs()
{
    const columnDefinitions = [];

    columnDefinitions.push({ field: 'propertyName', headerName: "Store", type: 'rowGroupEnabled', hide: currentOrgNodeSelectors.isCurrentPropertySelected() });
    columnDefinitions.push({ field: 'storefrontType', headerName: "Storefront Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'venueCode', headerName: "Venue", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'dispatchType', headerName: "Dispatch Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'discountType', headerName: "Discount Type", type: 'rowGroupEnabled', hide: false });

    columnDefinitions.push({ field: 'totalAmount', headerName: "Amount", type: 'money' });

    return columnDefinitions;
}

function getPinnedRows(rows)
{
    return [
        {
            totalAmount: rows.reduce((previous, current) => previous + current.totalAmount, 0)
        }
    ];
}
