import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import StorefrontOverview from './components/StorefrontOverview';
import StorefrontLogo from './components/StorefrontLogo';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontCoreHelper = require('./storefrontCoreHelper');
const menuLoadFacade = require('../../../../../utils/api/menuLoadFacade.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const viewUtil = require('../../../../../utils/view/viewUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const constants = require('../../../../../utils/domain/constants');
const typeUtil = require('../../../../../utils/type/typeUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontCore extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {
            storefront: typeUtil.deepCloneObject(this.props.params) || {},
            priceBands: orgStateSelectors.selectOrgLookupData(currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId).priceBands,
            menuHeaders: [],
            brands: [],
            isLoading: false
        };

        this.lastStoreFrontType = this.state.storefront != null ? this.state.storefront.type : null;
        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({isLoading: true});
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let menuHeaders = await menuLoadFacade.loadMenuHeadersExceptCentralKitchen();
            const brands = await storefrontCoreHelper.loadBrands();
            this.setState({menuHeaders: menuHeaders, brands: brands});
        }
        catch(error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({isLoading: false});
        }
    }

    async onSaveClicked()
    {
        const { storefront } = this.state;
        const validationError = storefrontCoreHelper.validateStorefront(storefront)
        
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        if (this.lastStoreFrontType != storefront.type)
        {
            let warnMessage = null;
            if (
                Object.values(constants.MarketplaceStorefronts).includes(this.lastStoreFrontType) &&
                !Object.values(constants.MarketplaceStorefronts).includes(storefront.type)
            )
            {
                warnMessage = `Changing the storefront type to '${storefront.type}' will delete any existing Order Ingest Configurations. Do you want to proceed?`;
            }

            else if (
                Object.values(constants.FlipdishStorefronts).includes(this.lastStoreFrontType) &&
                !Object.values(constants.FlipdishStorefronts).includes(storefront.type)
            )
            {
                warnMessage = `Changing the storefront type to '${storefront.type}' will delete any existing Order Ingest Configurations. Do you want to proceed?`;
            }

            else if (
                this.lastStoreFrontType === constants.PropertyStorefronts.POS &&
                storefront.type !== constants.PropertyStorefronts.POS
            )
            {
                warnMessage = `Changing the storefront type to '${storefront.type}' will delete any existing Payment Over Phone / Payout / Tip Configurations. Do you want to proceed?`;
            }

            else if (
                this.lastStoreFrontType === constants.PropertyStorefronts.WebApp &&
                storefront.type !== constants.PropertyStorefronts.WebApp
            )
            {
                warnMessage = `Changing the storefront type to '${storefront.type}' will delete any existing Web App Configurations. Do you want to proceed?`;
            }

            else if (
                this.lastStoreFrontType === constants.PropertyStorefronts.MobileApp &&
                storefront.type !== constants.PropertyStorefronts.MobileApp
            )
            {
                warnMessage = `Changing the storefront type to '${storefront.type}' will delete any existing Mobile App Configurations. Do you want to proceed?`;
            }

            if (warnMessage != null)
            {
                viewUtil.showConfirmDialogue("Warning: Storefront Type Updated", warnMessage, async () =>
                {
                    await this.saveStorefront(storefront);
                }, () => { })
            }
            else
            {
                await this.saveStorefront(storefront);
            }
        }
        else
        {
            await this.saveStorefront(storefront);
        }
    }

    async saveStorefront(storefront)
    {
        viewUtil.showSystemModalSpinner("Saving Storefront");

        try
        {
            await storefrontCoreHelper.save(storefront);

            if (this.props.params)
                Object.assign(this.props.params, storefront);

            viewUtil.showSuccessAlert(`Storefront ${storefront.name} saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration,
                `${storefront.name} storefront basic configuration has been altered`,
                storefront);
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }


    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }

        const title = this.state.storefront != null ? 'Storefront' : 'Add Storefront';
        const tabHeaders = [];
        const tabPanels = [];

        tabHeaders.push(<Tab>Overview</Tab>);
        tabPanels.push(
            <TabPanel className='pt-1'>
                <StorefrontOverview data={ this.state.storefront } menuHeaders={this.state.menuHeaders} brands={this.state.brands} priceBands={this.state.priceBands} />
            </TabPanel>
        );

        tabHeaders.push(<Tab>Logo</Tab>);
        tabPanels.push(
            <TabPanel>
                <StorefrontLogo 
                    data={ this.state.storefront } 
                    onImageUpdate={() =>
                    {
                        this.setState({})
                    }} />
            </TabPanel>
        );

        return (            
            <Card>
                <CardHeader>
                    <EditComponentHeader
                        title={title}
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true}
                        saveButtonProps={{id: 'storefront-core-add-button'}}
                        closeButtonProps={{id: 'storefront-core-cancel-button'}} />
                </CardHeader>

                <CardBody>
                    <Tabs>
                        <TabList>{tabHeaders}</TabList>
                        {tabPanels}
                    </Tabs>
                </CardBody>
            </Card>
        );
    }
}

export default StorefrontCore;