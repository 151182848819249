import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent';

const stringUtil = require('../../../../../utils/string/stringUtil');
const FormManager = require('../../../../../utils/view/formManager');
const domainConstants = require('../../../../../utils/domain/constants');

class Tag extends Component 
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    render() 
    {
        return (

            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Type"
                                fieldName="type"
                                optionValues={domainConstants.getTagTypes()}
                                optionDisplayFieldName="displayFieldName"
                                optionFieldName="valueFieldName"
                                hintText="Required"
                                clearable={false}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Code"
                                fieldName="code"
                                hintText="Required"
                                placeholder="Enter tag code"
                                formManager={this.formManager} />
                        </td>
                    </tr>
                    {
                        stringUtil.areStringSame(this.formManager.viewModel.type, domainConstants.tagTypes.KDSCourseName) &&
                        <tr>
                            <td>
                                <InputComponent
                                    inputType="text"
                                    caption="Sequence"
                                    fieldName="sequence"
                                    hintText="Enter sequence of the Course Name. Smaller number will be printed first. (Required)"
                                    placeholder="Enter sequence of the Course Name."
                                    formManager={this.formManager} />
                            </td>
                        </tr>
                    }
                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                multiLine={true} rows={4}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                </tbody>
            </table>
        )
    }
}

export default Tag;
