var compareVersions = require('compare-versions');

const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const stateManager = require('../../../utils/state/stateManager');
const orgSelectors = require('../../../utils/state/stateSelectors/orgSelectors');
const configurationManager = require('../../../utils/config/configurationManager');
const logger = require('../../../utils/logger/logger')
const stringUtil = require('../../../utils/string/stringUtil')
const securityManager = require('../../../utils/domain/security/securityManager');
const OrgState = require('../../../utils/state/actions/orgStates/orgState');
const OrgData = require('../../../utils/state/actions/orgData/orgData/orgData');
const CatalogData = require('../../../utils/state/actions/catalogData/catalogData/catalogData');

export function getEnvironmentLoadingStages()
{
    return [
        {
            label: "Loading Environment",
            loadData: loadEnvironment,
            isLoaded: false
        },
        {
            label: "Loading Configuration",
            loadData: loadConfiguration,
            isLoaded: false
        },
        {
            label: "Verifying Version",
            loadData: verifyingVersion,
            isLoaded: false
        }
    ]

    async function loadEnvironment()
    {
        var completeUrl = `/environment.json`;

        let response = await fetch(completeUrl, {cache: "no-store"});
        if (response.status != 200)
        {
            var errorMessage = `Error occurred while loading environment configuration. The returned code is ${response.status}`;
            throw errorMessage;
        }

        var environmentConfiguration = await response.json();
        configurationManager.setEnvironment(environmentConfiguration.environment, environmentConfiguration.environmentConfigServiceUrl);
    }

    async function loadConfiguration()
    {
        await configurationManager.loadConfiguration();
        logger.resetGlobalContext();
    }

    async function verifyingVersion()
    {
        var config = configurationManager.getConfig();
        if (stringUtil.isStringNullOrEmpty(config.appBackOfficeVersion))
            return;

        if (!config.version.endsWith("xxx") && compareVersions(config.appBackOfficeVersion, config.version) == 1)
        {
            logger.warn(`Remote version (${config.appBackOfficeVersion}) is higher than local version (${config.version}). Needs to reload app without cache`);
            window.location.reload();
            return;
        }
    }

}

export function getOrganizationLoadingStages()
{
    return[
                {
                    label: "Loading Available Franchisors",
                    loadData: loadAvailableFranchisors,
                    isLoaded: false
                },

                {
                    label: "Loading Org Taxonomy",
                    loadData: loadOrgTaxonomy,
                    isLoaded: false
                },

                

                {
                    label: "Loading Catalog Data",
                    loadData: loadCatalogData,
                    isLoaded: false
                },

                {
                    label: "Loading Org Data",
                    loadData: loadOrgData,
                    isLoaded: false
                },

                {
                    label: "Loading Property Localizations",
                    loadData: loadPropertyLocalizations,
                    isLoaded: false
                },

                {
                    label: "Loading Property Subscriptions",
                    loadData: loadPropertySubscriptions,
                    isLoaded: false
                }
    ]

    async function loadAvailableFranchisors(loadingState)
    {
        if (stateManager.getStore().getState().availableFranchisorsLoaded)
            return;

        stateManager.getStore().getState().availableFranchisorsLoaded = true;

        var availableFranchisors = [];
        if (!securityManager.isSystemUser())
        {
            var organization = await rmsApiProxy.get(`core/organizations`);
            if (organization.franchisors.length > 1)
                availableFranchisors = organization.franchisors;

            if (organization.franchisors.length > 0 && stateManager.getStore().getState().currentFranchisorId == null)
                stateManager.getStore().getState().currentFranchisorId = organization.franchisors[0].id;
        }

        stateManager.getStore().getState().availableFranchisors = availableFranchisors;
    }

    async function loadOrgTaxonomy(loadingState)
    {
        loadingState.organizationTaxonomy = {
                                                franchisors: [],
                                                franchisees: [],
                                                properties: []
                                            };

        if (stateManager.getStore().getState().currentFranchisorId == null)
            return;

        var organization = await rmsApiProxy.get(`core/organizations?franchisorId=${stateManager.getStore().getState().currentFranchisorId}`);
        loadingState.organizationTaxonomy.franchisors = organization.franchisors;
        loadingState.organizationTaxonomy.franchisees = organization.franchisees;
        loadingState.organizationTaxonomy.properties = organization.properties;
    }

    

    async function loadCatalogData(loadingState)
    {
        stateManager.getStore().getState().catalogData = new CatalogData();
        stateManager.getStore().getState().catalogData.organizationTaxonomy = loadingState.organizationTaxonomy;

        const lookups = [
            "allergies", "nutritions",
            "venues", "periods", "wastageReasons", "packageTypes", "frequencies", "propertyAmenities",
            "serviceTypes", "applianceTypes"
        ];

        const promises = [];
        for (let index = 0; index < lookups.length; index++) {
            const lookup = lookups[index];
            promises.push(rmsApiProxy.get(`core/system/lookups/${lookup}`));
        }

        const results = await Promise.all(promises);

        const lookupData = {};

        for (let index = 0; index < lookups.length; index++) {
            const lookup = lookups[index];
            lookupData[lookup] = results[index];
        }

        lookupData.countrySpecs = await rmsApiProxy.get('core/system/configurations/countrySpecs');

        stateManager.getStore().getState().catalogData.lookupData = lookupData;
    }

    async function loadOrgData(loadingState)
    {
        stateManager.getStore().getState().orgData = new OrgData();
        stateManager.getStore().getState().orgData.organizationTaxonomy = loadingState.organizationTaxonomy;
        stateManager.getStore().getState().orgStates = new Map();

        const franchisors = orgSelectors.selectFranchisors();
        if (franchisors.length == 0) return;

        const lookups = [
            // orgLookups constants defined in api
            "tags", "kitchenStationProfiles", "priceBands", "nomenclatures"
        ];


        const orgStates = new Map();
        for(const franchisor of franchisors)
        {
            const promises = [];
            lookups.forEach(lookup => 
            {
                promises.push(rmsApiProxy.get(`core/organizations/${franchisor.id}/lookups/${lookup}`));
            })

            const results = await Promise.all(promises);

            const lookupData = {};

            for (let index = 0; index < lookups.length; index++) {
                const lookup = lookups[index];
                lookupData[lookup] = results[index];
            }
            
            const orgState = new OrgState(franchisor.id);
            orgState.lookupData = lookupData;
            orgStates.set(franchisor.id, orgState);
        }

        var promises = [];
        franchisors.forEach (franchisor =>
            {
                var url = `core/organizations/${franchisor.id}/localizations`;
                promises.push(rmsApiProxy.get(url));
            });

        const localizationResults = await Promise.all(promises);
        
        for(let index = 0; index < localizationResults.length; index++)
        {
            const franchisor = franchisors[index];
            const orgState = orgStates.get(franchisor.id);
            orgState.franchisorLocalization = localizationResults[index];
        }

        stateManager.getStore().getState().orgStates = orgStates;
    }

    async function loadPropertyLocalizations(loadingState)
    {
        var propertyLocalizations = new Map();
        stateManager.getStore().getState().propertyLocalizations = propertyLocalizations;
        var properties = stateManager.getStore().getState().orgData.organizationTaxonomy.properties;
        if (properties.length <= 0)
            return;

        var promises = [];
        properties.forEach (property =>
            {
                var url = `core/organizations/${property.franchisorId}/${property.franchiseeId}/${property.id}/localizations`;
                promises.push(rmsApiProxy.get(url));
            })

        var results = await Promise.all(promises);

        for(let index = 0; index < results.length; index++)
        {
            const property = properties[index];
            propertyLocalizations.set(property.id, results[index]);
        }
    }

    async function loadPropertySubscriptions(loadingState)
    {
        var propertyLicenseSubscriptions = new Map();
        stateManager.getStore().getState().propertyLicenseSubscriptions = propertyLicenseSubscriptions;
        var properties = stateManager.getStore().getState().orgData.organizationTaxonomy.properties;
        if (properties.length <= 0)
            return;

        var promises = [];
        properties.forEach (property =>
            {
                var url = `core/organizations/${property.franchisorId}/${property.franchiseeId}/${property.id}/licenseSubscriptions`;
                promises.push(rmsApiProxy.get(url));
            })

        var results = await Promise.all(promises);
        results.forEach(subscriptions =>
        {
            subscriptions.forEach(subscription =>
            {
                if (!propertyLicenseSubscriptions.has(subscription.propertyId))    
                    propertyLicenseSubscriptions.set(subscription.propertyId, []);

                propertyLicenseSubscriptions.get(subscription.propertyId).push(subscription);
            })
        })
    }
}

