const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../../utils/string/stringUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const validator = require('../../../../../utils/validator/validator');
const constants = require('../../../../../utils/domain/constants');

export async function load(storefront)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/timingConfigurations`;
    const timingConfiguration = await rmsApiProxy.get(resourceUrl);

    if (timingConfiguration != null)
    {
        for (let index = 0; index < timingConfiguration.orderIntervals.length; index++) 
        {
            const orderInterval = timingConfiguration.orderIntervals[index];
            if (index === 0)
                timingConfiguration.orderInterval1 = orderInterval;
            if (index === 1)
                timingConfiguration.orderInterval2 = orderInterval;
            if (index === 2)
                timingConfiguration.orderInterval3 = orderInterval;
        }

        const overrides = timingConfiguration.overrides;

        for (let index = 0; index < overrides.length; index++) 
        {
            const timingOverride = overrides[index];
            enrichTimingOverride(timingOverride);
        }
    }

    return timingConfiguration;
}

function enrichTimingOverride(timingOverride)
{
    if (!stringUtil.isStringNullOrEmpty(timingOverride.fromHour) && !stringUtil.isStringNullOrEmpty(timingOverride.fromMinute))
    {
        let fromDateTime = dateUtil.convertToLocalStandard(dateUtil.getToday());
        fromDateTime = dateUtil.setHours(fromDateTime, timingOverride.fromHour);
        fromDateTime = dateUtil.setMinutes(fromDateTime, timingOverride.fromMinute);
        fromDateTime = dateUtil.convertToUTCStandard(fromDateTime);

        timingOverride.fromDateTime = fromDateTime;
        timingOverride.formattedFromTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(fromDateTime), "HH:mm");

    }
    if (!stringUtil.isStringNullOrEmpty(timingOverride.toHour) && !stringUtil.isStringNullOrEmpty(timingOverride.toMinute))
    {
        let toDateTime = dateUtil.convertToLocalStandard(dateUtil.getToday());
        toDateTime = dateUtil.setHours(toDateTime, timingOverride.toHour);
        toDateTime = dateUtil.setMinutes(toDateTime, timingOverride.toMinute);
        toDateTime = dateUtil.convertToUTCStandard(toDateTime);

        timingOverride.toDateTime = toDateTime;
        timingOverride.formattedToTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(toDateTime), "HH:mm");
    }
}

export async function save(storefront, timingConfiguration)
{
    const orderIntervals = convertToOrderIntervalArray(timingConfiguration);
    timingConfiguration.orderIntervals = orderIntervals;

    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/timingConfigurations`;
    return await rmsApiProxy.post(resourceUrl, timingConfiguration);
}

function convertToOrderIntervalArray(timingConfiguration)
{
    const orderIntervals = [];
    if (!stringUtil.isStringNullOrEmpty(timingConfiguration.orderInterval1))
        orderIntervals.push((1 * timingConfiguration.orderInterval1));
    if (!stringUtil.isStringNullOrEmpty(timingConfiguration.orderInterval2))
        orderIntervals.push((1 * timingConfiguration.orderInterval2));
    if (!stringUtil.isStringNullOrEmpty(timingConfiguration.orderInterval3))
        orderIntervals.push((1 * timingConfiguration.orderInterval3));
    return orderIntervals;
}

export function validateTimingConfiguration(timingConfiguration)
{
    if (stringUtil.isStringNullOrEmpty(timingConfiguration.defaultPrepTime))
        return 'Default Prep Time cannot be left empty';

    if (stringUtil.isStringNullOrEmpty(timingConfiguration.defaultDeliveryTime))
        return 'Default Delivery Time cannot be left empty';

    const orderIntervals = convertToOrderIntervalArray(timingConfiguration);;
    if (!Array.isArray(orderIntervals))
        return `Order Intervals is not specified`;

    if (orderIntervals.length <= 0)
        return `At least one orderIntervals needs to be specified`;

    for (let index = 0; index < orderIntervals.length; index++) 
    {
        const orderInterval = orderIntervals[index];
        if (!validator.isInteger(orderInterval) || !validator.isGreaterThanOrEqualToZero(orderInterval) || !validator.isLessThan(orderInterval, 60))
            return `Order Interval is not valid`;
    }

    if (orderIntervals.length > 2 && !validator.isGreaterThan(orderIntervals[2], orderIntervals[1]))
        return "Order interval 3 should be greater thane 2";

    if (orderIntervals.length > 1 && !validator.isGreaterThan(orderIntervals[1], orderIntervals[0]))
        return "Order interval 2 should be greater thane 1";

    return null;
}

export function validateTimingOverride(timingOverride)
{
    if (stringUtil.areStringSame(timingOverride.dispatchType, constants.dispatchTypes.delivery) && stringUtil.isStringNullOrEmpty(timingOverride.deliveryTime))
        return 'Delivery Time cannot be left empty';

    if (stringUtil.isStringNullOrEmpty(timingOverride.prepTime))
        return 'Prep Time cannot be left empty';

    if (!stringUtil.isStringNullOrEmpty(timingOverride.fromDateTime) && !dateUtil.isValidDateTime(timingOverride.fromDateTime))
        return "From time is not valid";

    if (!stringUtil.isStringNullOrEmpty(timingOverride.toDateTime) && !dateUtil.isValidDateTime(timingOverride.toDateTime))
        return "To time is not valid";

    if (validator.isAnyPresent(timingOverride.fromDateTime, timingOverride.toDateTime))
    {
        if (!validator.isAllPresent(timingOverride.fromDateTime, timingOverride.toDateTime))
            return "To / From Time is not valid"
    }

    if (validator.isPresent(timingOverride.orderPrice) && !validator.isGreaterThanOrEqualToZero(timingOverride.orderPrice))
        return "Order price is not valid";

    if ((stringUtil.isStringNullOrEmpty(timingOverride.fromDateTime)) &&
        stringUtil.isStringNullOrEmpty(timingOverride.dispatchType) &&
        stringUtil.isStringNullOrEmpty(timingOverride.dayOfTheWeek) &&
        stringUtil.isStringNullOrEmpty(timingOverride.orderPrice))
        return "Timing override is invalid";

    return null;
}