const stateBuilder = require('./stateBuilder');
const actionTypes = require('./actionTypes');

const passportAction = require('./actions/passport/passportAction');

var sideBarAction = require('./actions/views/container/sideBarAction');
var topBarAction = require('./actions/views/container/topBarAction');
var mainBarAction = require('./actions/views/container/mainBarAction');
var mainBarSearchAction = require('./actions/views/container/mainBarSearchAction');
var mainBarComponentAction = require('./actions/views/container/mainBarComponentAction');
var alertAction = require('./actions/views/alertDialogue/alertAction.js');
var modalSpinnerAction = require('./actions/views/modalSpinnerDialogue/modalSpinnerAction.js');
var confirmAction = require('./actions/views/confirmDialogue/confirmAction.js');
var modalFormAction = require('./actions/views/modalForm/modalFormAction.js');
var attachmentFormAction = require('./actions/views/attachmentForm/attachmentFormAction.js');
var displayAccountSwitchAction = require('./actions/views/container/displayAccountSwitchAction');

const catalogDataAction = require('./actions/catalogData/catalogData/catalogDataAction');
const orgDataAction = require('./actions/orgData/orgData/orgDataAction');
const catalogLookupAction = require('./actions/catalogData/lookup/lookupAction');
const orgLookupAction = require('./actions/orgData/lookup/lookupAction');
const orgStatesAction = require('./actions/orgStates/orgStatesAction');

module.exports.reducer = function (previousState, action)
{
    if (!previousState) previousState = stateBuilder.createState();

    if (action.type == actionTypes.environmentInitialized)
    {
        return Object.assign({}, previousState, { environmentInitialized: action.payload });
    }
    
    if (action.type == actionTypes.organizationInitialized)
    {
        return Object.assign({}, previousState, { organizationInitialized: action.payload });
    }

    if (action.type == actionTypes.passport)
    {
        return passportAction.changeState(previousState, action.payload);
    }

    
    if (action.type == actionTypes.sideBarOption)
    {
        return sideBarAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.topBarOption)
    {
        return topBarAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.mainBarOption)
    {
        return mainBarAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.mainBarSearch)
    {
        return mainBarSearchAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.mainBarComponent)
    {
        return mainBarComponentAction.changeState(previousState, action.payload);
    }
    
    if (action.type == actionTypes.alertDialogueUI)
    {
        return alertAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.confirmDialogueUI)
    {
        return confirmAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.modalSpinnerUI)
    {
        return modalSpinnerAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.modalFormUI)
    {
        return modalFormAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.attachmentFormUI)
    {
        return attachmentFormAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.displayAccountSwitch)
    {
        return displayAccountSwitchAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.catalogData)
    {
        return catalogDataAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.catalogLookupData)
    {
        return catalogLookupAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.orgData)
    {
        return orgDataAction.changeState(previousState, action.payload);
    }

    if (action.type == actionTypes.orgStates) 
    {
        return orgStatesAction.changeOrgStatesState(previousState, action.payload);
    }

    if (action.type == actionTypes.orgLookupData)
    {
        // orgLookupData is loaded by orgState
        return orgLookupAction.changeState(action.orgState, previousState, action.payload);
    }

    if (action.type == actionTypes.currentOrgNode) 
    {
        return orgStatesAction.changeCurrentOrgNodeState(previousState, action.payload);
    }

    return previousState;
}
