import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent';

const FormManager = require('../../../../../utils/view/formManager');

class PriceBand extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    render() 
    {
        return (
            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="code"
                                caption="Price Band Code"
                                fieldName="code"
                                hintText="Required"
                                placeholder="Enter code that uniquely identify Price Band"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="name"
                                caption="Price Band Name"
                                fieldName="name"
                                hintText="Required"
                                placeholder="Enter name of price band"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                multiLine={true} rows={4}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default PriceBand;

