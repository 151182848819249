import React from "react";
import DeliveryZoneTableData from "./DeliveryZoneTableData";
import { CloseButton } from "reactstrap";

export default function DeliveryZoneTableRow({ rowNumber, deliveryZone, closeBtnHandler, ...inputEventHandlers })
{

    return (
        <tr>
            <td>{rowNumber}</td>
            <DeliveryZoneTableData
                id={deliveryZone.id}
                value={deliveryZone.distance}
                onChange={inputEventHandlers.onDistanceChange}
                errorMsg={deliveryZone.errorDistance}
            />
            <DeliveryZoneTableData
                id={deliveryZone.id}
                value={deliveryZone.charges}
                onChange={inputEventHandlers.onChargesChange}
                errorMsg={deliveryZone.errorCharges}
            />
            <td>
                <CloseButton
                    onClick={() => { closeBtnHandler(deliveryZone.id) }}
                />
            </td>
        </tr>

    )
}