import React from "react";
import "./toggleSwitch.css"

export default function ToggleSwitch({ styles, onChange, ...dataprops }) {

    return (
        <label className="toggle-switch">
            <input
                type="checkbox"
                style={styles}
                onChange={onChange}
                {...dataprops}
            />
            <span className="slider"></span>
        </label>
    )
}