
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js'
import BooleanSelectComponent from "../../../../../components/form/booleanSelectComponent.js";
import SelectComponent from '../../../../../components/form/selectComponent.js';

const viewUtil = require('../../../../../utils/view/viewUtil.js');
const FormManager = require('../../../../../utils/view/formManager.js');

const stringUtil = require('../../../../../utils/string/stringUtil.js');
const commonElementUtil = require('../../../../../utils/template/commonElementUtil.js');

class PaymentStatusElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {

        var fontWeight = this.props.element.bold ? "bold" : "normal";

        var separatorLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.separatorLineCharacter))
        {
            var lineText = "";
            for (var index = 0; index < 100; index++)
                lineText = `${lineText}${this.props.element.separatorLineCharacter}`;

            separatorLineComponent = <div style={{width:'100%', whiteSpace:'nowrap', textOverflow:'', overflow:'hidden', padding:'0px', margin:'0px'}}> {lineText}</div>
        }

        var newLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.newLine))
        {
            newLineComponent = <div style={{width:'100%', minHeight:`${10 * this.props.element.newLine}px`, padding:'0px', margin:'0px'}}></div>
        }

        var fontSize = "14px";
        if (stringUtil.areStringSame(this.props.element.fontSize, "Large"))
            fontSize = fontSize = "18px"

        if (stringUtil.areStringSame(this.props.element.fontSize, "Extra Large"))
            fontSize = fontSize = "22px"

        var isReversed = Boolean(this.props.element.reversed);

        return <div style={{width:'100%', padding:'0px', margin:'0px', textAlign:this.props.element.align, fontWeight:fontWeight, fontSize:fontSize,
                            backgroundColor: isReversed ? '#000000' : null, color: isReversed ? '#FFFFFF' : null}}
                    onDoubleClick={()=>
                        {
                            var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                            viewUtil.openModalForm(`${this.props.element.title}`, ()=>
                            {
                                return <DialogueBox element={clonedElement}/>
                            }, 
                            ()=>
                            {
                                this.props.element.paidCaption = clonedElement.paidCaption;
                                this.props.element.unpaidCaption = clonedElement.unpaidCaption;
                                this.props.element.align = clonedElement.align;
                                this.props.element.bold = clonedElement.bold;
                                this.props.element.fontSize = clonedElement.fontSize;
                                this.props.element.reversed = clonedElement.reversed;
                                this.props.element.separatorLineCharacter = clonedElement.separatorLineCharacter;
                                this.props.element.newLine = stringUtil.isStringNullOrEmpty(clonedElement.newLine) ? 0 : clonedElement.newLine * 1;
                                this.setState({});
                            }, 
                            ()=>
                            {
                                return commonElementUtil.isValidElement(clonedElement);
                            })
                        }}>
                            <div style={{whiteSpace:'pre'}}>
                                {
                                    !stringUtil.isStringNullOrEmpty(this.props.element.paidCaption) 
                                    ? this.props.element.paidCaption 
                                    : this.props.element.unpaidCaption
                                }
                            </div>
                            {separatorLineComponent}
                            {newLineComponent}
               </div>;
    }
}


class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;

        this.state.alignTypes = [
                                    {
                                        align:"Left"
                                    },

                                    {
                                        align:"Center"
                                    },

                                    {
                                        align:"Right"
                                    }
                                ];

        this.state.fontSizes = [
                                    {
                                        fontSize:"Normal"
                                    },

                                    {
                                        fontSize:"Large"
                                    },

                                    {
                                        fontSize:"Extra Large"
                                    }
                                ];
    }

    render()
    {
    
        return <table style={{width:'100%'}}>
                    <tr>
                        <td>
                            <InputComponent
                                style={{marginRight:'5px'}}
                                inputType="text"
                                caption="Paid Caption"
                                hintText="Paid Caption"
                                fieldName="paidCaption"
                                placeholder="Enter Paid Caption"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                style={{marginLeft:'5px'}}
                                inputType="text"
                                caption="Unpaid Caption"
                                hintText="Unpaid Caption"
                                fieldName="unpaidCaption"
                                placeholder="Enter Unpaid Caption"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SelectComponent
                                style={{marginRight:'5px'}}
                                optionValues={this.state.alignTypes}
                                optionFieldName="align"
                                optionDisplayFieldName="align"
                                caption="Alignment"
                                fieldName="align"      // target.id
                                hintText="Choose alignment (Required)"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                style={{marginLeft:'5px'}}
                                caption="Bold"
                                fieldName="bold"
                                hintText="Is Font Bold"
                                clearable={false}
                                formManager={this.state.formManager}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SelectComponent
                                style={{marginRight:'5px'}}
                                optionValues={this.state.fontSizes}
                                optionFieldName="fontSize"
                                optionDisplayFieldName="fontSize"
                                caption="Font Size"
                                fieldName="fontSize"      // target.id
                                hintText="Choose font size (Required)"
                                clearable={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                style={{marginLeft:'5px'}}
                                caption="Reversed"
                                fieldName="reversed"
                                hintText="Is Font Reversed"
                                clearable={false}
                                formManager={this.state.formManager}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                style={{marginRight:'5px'}}
                                inputType="text"
                                caption="Line Separator Character"
                                hintText="Line Separator Character"
                                fieldName="separatorLineCharacter"
                                placeholder="Enter line Separator Character"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                style={{marginLeft:'5px'}}
                                inputType="text"
                                caption="Number Of Empty Lines"
                                hintText="Number Of Empty Lines"
                                fieldName="newLine"
                                placeholder="Enter number Of Empty Lines"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
               </table>;
    }
}


export default PaymentStatusElement;