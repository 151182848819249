const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const stringUtil = require('../../../../../utils/string/stringUtil');

export function createNew () 
{
    return {}
}

export async function save (data)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/floatConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/floatConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{
    if (!stringUtil.isStringNullOrEmpty(data.maxFloatSessionDurationInHours) && !validator.isGreaterThanZero(data.maxFloatSessionDurationInHours))
        return "Max Float Session Duration must be a valid number greater than zero";

    return null;
}