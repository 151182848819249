import React from 'react';

import SaleItemsSummary from './components/saleItemsSummary/SaleItemsSummary';
import SalesByCategories from './components/salesByCategories/SalesByCategories';
import SalesByReportTags from './components/salesByReportTags/SalesByReportTags';

export default function SaleItemsOverviewSummary(props)
{
    const data = props.data;

    return (
        <div className='mt-2 d-flex flex-column'>
            <div className='mb-4 shadow-sm'><SaleItemsSummary data={data} /></div>
            <div className='mb-4 shadow-sm'><SalesByCategories data={data} index={0} /></div>
            <div className='mb-4 shadow-sm'><SalesByReportTags data={data} index={1} /></div>
        </div>
    );
}