
import React from 'react'
import CaptionLabel from '../../../../../../../components/form/captionLabel';
import AmountLabel from '../../../../../../../components/form/amountLabel';

const PayrollRunSummaryUtils = require('../utils/PayrollRunSummaryUtils');

const LabelWidth = PayrollRunSummaryUtils.SummaryLabelWidth;
const AmountWidth = PayrollRunSummaryUtils.SummaryAmountWidth;

function PayrollRunTaxes({ data })
{
    return <table width="100%">
        <tbody>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total Employee NI Contribution"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.employeeNIContribution} />
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total Employee Pension Contribution"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.employeePensionContribution} />
                </td>
            </tr>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total Employer NI Contribution"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.employerNIContribution} />
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total Employer Pension Contribution"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.employerPensionContribution} />
                </td>
            </tr>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Total NI Contribution"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.totalNIContribution} />
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"PAYE"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.paye} />
                </td>
            </tr>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Net NI Payment"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.netNIPayment} />
                </td>
            </tr>
        </tbody>
    </table>
}

export default PayrollRunTaxes;