import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import EmptyData from '../../../../components/empty/emptyData';
import GridComponent from '../../../../components/grid/gridComponent';
import CloseButton from '../../../../components/button/closeButton';
import * as fileDownload from 'js-file-download';
import Papa from "papaparse";

const viewUtil = require('../../../../utils/view/viewUtil');
const typeUtil = require('../../../../utils/type/typeUtil');
const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

export default class ImportView extends Component {
    constructor(props) {
        super(props)

        this.state = {};
        this.state.pageSize = 50;
        this.state.data = null;
        this.state.importFailedCount = 0;
        this.state.saveEnabled = false;

        this.handleImportCSVFile = this.handleImportCSVFile.bind(this);
        this.inputFileRef = React.createRef();
        this.onImportComplete = this.onImportComplete.bind(this);
        this.onImportStep = this.onImportStep.bind(this);
        this.onImportClick = this.onImportClick.bind(this);
    }

    //#region Render

    async handleImportCSVFile(e) {
        const { files } = e.target;
        if (files == null || files.length <= 0)
            return;

        var file = files[0];

        try {
            viewUtil.showSystemModalSpinner(`Please wait, importing ${this.props.resourceName}`);
            this.state.saveEnabled = true;
            this.state.importFailedCount = 0;
            this.state.data = [];

            Papa.parse(file, {
                header: true,
                transformHeader: this.props.transformHeader,
                transform: this.props.transformData,
                dynamicTyping: true,
                skipEmptyLines: true,
                comments: "#",
                step: this.onImportStep,
                complete: this.onImportComplete,
            });
        }
        catch (importError) {
            this.state.saveEnabled = false;
            this.state.importFailedCount = 0;
            this.state.data = null;
            this.inputFileRef.current.value = null;
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(`Error occurred while importing customers: ${importError}`);
        }

        this.setState({});
    }

    onImportStep(results, parser) {
        if (results.error) {
            viewUtil.closeModalSpinner();
            this.state.saveEnabled = false;
            this.state.importFailed = 0;
            this.state.data = null;
            this.inputFileRef.current.value = null;
            viewUtil.showCriticalAlert(`Importing ${this.props.resourceName} failed with Error: ${results.error}`);
            parser.abort();
            this.setState({});
        }
        else {
            let data = results.data;
            if (this.props.enrichData) {
                data = this.props.enrichData(data);
            }
            const importError = this.props.validateData(data, this.state.data);

            if (importError) {
                data.importError = importError;
                this.state.importFailedCount++;
            }

            this.state.data.push(data);
        }

    }

    onImportComplete() {
        viewUtil.closeModalSpinner();
        this.inputFileRef.current.value = null;
        if (this.state.importFailedCount > 0) {
            this.state.saveEnabled = false;
            viewUtil.showErrorAlert(`Importing ${this.props.resourceName} Completed With Errors`);
        }
        else {
            this.state.saveEnabled = true;
            viewUtil.showSuccessAlert(`${this.props.resourceName} Imported Successfully`);
        }

        this.setState({});
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                </CardBody>
            </Card>

        );
    }

    getRowStyle = (params) => {
        if (params.data.importError) {
            return { background: 'rgb(250, 185, 180)', fontWeight: "bold", foreColor: "black" };
        }

        return null;
    }

    async onImportClick() {
        this.inputFileRef.current.click();
    }

    renderHeader() {
        return (
            <div className="d-flex flex-row align-items-center">
                <div className="d-flex flex-column align-items-start flex-grow-1">
                    <div style={{ fontSize: '16px', color: '#606060', fontWeight: 'bold' }}>{`Import ${this.props.resourceName}`}</div>
                    <div style={{ marginTop: "1px", fontSize: '10px', color: 'red', fontWeight: 'bold' }}>{`Following Fields are required: ${this.props.requiredFields.join(", ")}`}</div>
                </div>

                {this.state.importFailedCount > 0 &&
                    <div className="mx-1">
                        <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'red' }}>{`${this.props.resourceName} failing import: ${this.state.importFailedCount}`}</div>
                    </div>}
                <div className="mx-1">
                    <Button color="info" className="btn-info" style={{ marginLeft: '3px' }}
                        onClick={() => {
                            let csv = Papa.unparse(this.props.sampleData,
                                {
                                    quotes: true,
                                    quoteChar: '"',
                                    delimiter: ",",
                                    header: true,
                                    newline: "\r\n",
                                    skipEmptyLines: false,
                                });
                            fileDownload(csv, `sample.csv`);
                        }}>
                        <i className="fa fa-download"></i>&nbsp;&nbsp;Download Sample CSV
                    </Button>
                </div>
                <input style={{ display: "none" }} accept=".csv" ref={this.inputFileRef} onChange={this.handleImportCSVFile} type="file" />
                <div className="mx-1">
                    <Button color="warning" className="btn-warning" style={{ marginLeft: '3px' }} disabled={this.props.isReadOnly}
                        onClick={this.onImportClick}>
                        <i className="fa fa-upload"></i>&nbsp;&nbsp;Import CSV
                    </Button>
                </div>
                <div className="mx-1">
                    <Button disabled={!this.state.saveEnabled || this.props.isReadOnly} color="success" className="btn-success" style={{ marginLeft: '3px' }}
                        onClick={async () => {
                            const resources = typeUtil.deepCloneObject(this.state.data);
                            resources.forEach(resource => delete resource.importError);
                            try {
                                viewUtil.showSystemModalSpinner(`Please wait, saving ${this.props.resourceName}`);
                                await this.props.onSave(resources);
                                this.state.data = null;
                                viewUtil.showSuccessAlert(`${this.props.resourceName} saved successfully`);
                            }
                            catch (error) {
                                viewUtil.showCriticalAlert(`Failed to Save ${this.props.resourceName}: ${error}`);
                            }

                            viewUtil.closeModalSpinner();
                            this.setState({});
                        }}>
                        <i className="fa fa-save"></i>&nbsp;&nbsp;Save
                    </Button>
                </div>
                <div className="mx-1">
                    <CloseButton />
                </div>
            </div>
        );
    }

    renderBody() {
        if (this.state.data == null)
            return <EmptyData title="Import Customers" text={`Import ${this.props.resourceName} from CSV File (Following Fields are required: ${this.props.requiredFields.join(", ")})`}></EmptyData>;

        const columnHeaders = [...this.props.columnHeaders];
        const header = {};
        header.field = "importError";
        header.headerName = "Import Errors";
        header.width = columnWidthConstants.notes;
        columnHeaders.push(header);

        return <GridComponent headers={columnHeaders}
            onGridReady={this.onGridReady}
            paginationEnabled={true}
            paginationSize={this.state.pageSize}
            gridMode="controlled"
            rows={this.state.data}
            shouldReArrangeHeaders={true}
            gridOptions={{ getRowStyle: this.getRowStyle }}
        />
    }
}