
import React, { Component } from 'react';
import { connect } from 'react-redux'

const actionDispatcher = require('../../utils/state/actionDispatcher');
const sideBarAction = require('../../utils/state/actions/views/container/sideBarAction');
const mainBarAction = require('../../utils/state/actions/views/container/mainBarAction');
const mainBarSearchAction = require('../../utils/state/actions/views/container/mainBarSearchAction');
const mainBarComponentAction = require('../../utils/state/actions/views/container/mainBarComponentAction');
const configurationManager = require('../../utils/config/configurationManager');
class SideBarView extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {
        if (this.props.topBarSelectedOption == "expanded")
            return  this.renderExpandedSideBar();
        
        return this.renderSideBar();

    }

    renderExpandedSideBar()
    {
        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582/app/back.office/menu/${configurationManager.getConfig().themeVersion}`;
        
        var biImage = `${rootUrl}/sidebar.bi.png`;
        var biBackgroundColor = null;

        var modulesImage = `${rootUrl}/sidebar.modules.png`;
        var modulesBackgroundColor = null;

        var configurationImage = `${rootUrl}/sidebar.configuration.png`;
        var configurationBackgroundColor = null;

        var settingsOptionImage = `${rootUrl}/sidebar.settings.png`;
        var settingsOptionBackgroundColor = null;


        if (this.props.sideBarSelectedOption == "reports")
        {
            biImage = `${rootUrl}/sidebar.bi.selected.png`;
            biBackgroundColor = "#eaf2ff";
        }

        if (this.props.sideBarSelectedOption == "modules")
        {
            modulesImage = `${rootUrl}/sidebar.modules.selected.png`;
            modulesBackgroundColor = "#eaf2ff";
        }

        if (this.props.sideBarSelectedOption == "configurations")
        {
            configurationImage = `${rootUrl}/sidebar.configuration.selected.png`;
            configurationBackgroundColor = "#eaf2ff";
        }

        if (this.props.sideBarSelectedOption == "settings")
        {
            settingsOptionImage = `${rootUrl}/sidebar.settings.selected.png`;
            settingsOptionBackgroundColor = "#eaf2ff";
        }

        
        return <div style={{ paddingTop:'30px', userSelect:'none'}}>
                    <div onClick={()=>{this.onSideBarOptionClick("reports")}} style={{cursor:'default', paddingLeft:'30px', paddingRight:'50px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: biBackgroundColor}}>
                        <img src={biImage} style={{marginRight:'10px', width:'30px'}}></img>Reports
                    </div>

                    <div onClick={()=>{this.onSideBarOptionClick("modules")}} style={{cursor:'default', paddingLeft:'30px', paddingRight:'50px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: modulesBackgroundColor}}>
                        <img src={modulesImage} style={{marginRight:'10px', width:'30px'}}></img>Modules
                    </div>

                    <div onClick={()=>{this.onSideBarOptionClick("configurations")}} style={{cursor:'default', paddingLeft:'30px', paddingRight:'50px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: configurationBackgroundColor}}>
                        <img src={configurationImage} style={{marginRight:'10px', width:'30px'}}></img>Configurations
                    </div>

                    <div onClick={()=>{this.onSideBarOptionClick("settings")}} style={{cursor:'default', paddingLeft:'30px', paddingRight:'50px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: settingsOptionBackgroundColor}}>
                        <img src={settingsOptionImage} style={{marginRight:'10px', width:'30px'}}></img>Settings
                    </div>
                </div>
    }

    renderSideBar()
    {
        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}`

        var biImage = `${rootUrl}/sidebar.bi.png`;
        var biBackgroundColor = null;

        var modulesImage = `${rootUrl}/sidebar.modules.png`;
        var modulesBackgroundColor = null;

        var configurationImage = `${rootUrl}/sidebar.configuration.png`;
        var configurationBackgroundColor = null;

        var settingsOptionImage = `${rootUrl}/sidebar.settings.png`;
        var settingsOptionBackgroundColor = null;


        if (this.props.sideBarSelectedOption == "reports")
        {
            biImage = `${rootUrl}/sidebar.bi.selected.png`;
            biBackgroundColor = "#eaf2ff";
        }

        if (this.props.sideBarSelectedOption == "modules")
        {
            modulesImage = `${rootUrl}/sidebar.modules.selected.png`;
            modulesBackgroundColor = "#eaf2ff";
        }

        if (this.props.sideBarSelectedOption == "configurations")
        {
            configurationImage = `${rootUrl}/sidebar.configuration.selected.png`;
            configurationBackgroundColor = "#eaf2ff";
        }

        if (this.props.sideBarSelectedOption == "settings")
        {
            settingsOptionImage = `${rootUrl}/sidebar.settings.selected.png`;
            settingsOptionBackgroundColor = "#eaf2ff";
        }

        
        return <div style={{ paddingTop:'20px'}}>
                    <div onClick={()=>{this.onSideBarOptionClick("reports")}} style={{cursor:'default', paddingLeft:'20px', paddingRight:'20px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: biBackgroundColor}}>
                        <img src={biImage} style={{marginRight:'10px', width:'30px'}}></img>
                    </div>

                    <div onClick={()=>{this.onSideBarOptionClick("modules")}} style={{cursor:'default', paddingLeft:'20px', paddingRight:'20px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: modulesBackgroundColor}}>
                        <img src={modulesImage} style={{marginRight:'10px', width:'30px'}}></img>
                    </div>

                    <div onClick={()=>{this.onSideBarOptionClick("configurations")}} style={{cursor:'default', paddingLeft:'20px', paddingRight:'20px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: configurationBackgroundColor}}>
                        <img src={configurationImage} style={{marginRight:'10px', width:'30px'}}></img>
                    </div>

                    <div onClick={()=>{this.onSideBarOptionClick("settings")}} style={{cursor:'default', paddingLeft:'20px', paddingRight:'20px', paddingBottom:'10px', paddingTop:'10px', marginBottom:'20px', background: settingsOptionBackgroundColor}}>
                        <img src={settingsOptionImage} style={{marginRight:'10px', width:'30px'}}></img>
                    </div>
                </div>
    }

    onSideBarOptionClick(option)
    {
        actionDispatcher.dispatch(mainBarAction.createAction(null));
        actionDispatcher.dispatch(mainBarSearchAction.createAction(null));
        actionDispatcher.dispatch(mainBarComponentAction.createAction(null));
        if (this.props.sideBarSelectedOption == option)
            return;
      
        actionDispatcher.dispatch(sideBarAction.createAction(option));
        
    }

}

const mapStateToProps = state => 
{
    return {
        sideBarSelectedOption: state.sideBarSelectedOption,
        topBarSelectedOption: state.topBarSelectedOption
    }
}

export default connect(mapStateToProps)(SideBarView)