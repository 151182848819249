import * as arraySort from 'array-sort';

import * as rmsApiProxy from '../../../../../../utils/api/rmsApiProxy';
import * as stringUtil from '../../../../../../utils/string/stringUtil';
import * as regexUtil from '../../../../../../utils/regex/regexUtil';
import * as currentOrgNodeSelectors from '../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';

export async function loadAddressMappings()
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected()) 
    {
        throw new Error('loadAddressMappings: property context is required');
    }

    const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();

    const resourceUrl = `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/geo/mapping`;

    const addressMappings = await rmsApiProxy.get(resourceUrl);

    return getAddressMappingsMergedByArea(addressMappings);
}

export async function saveAddressMappings(mergedAddressMappings)
{
    const addressMappings = [];

    mergedAddressMappings
        .map(map => ({ areaCodes: stringUtil.trimString(map.areaCodes), areaName: stringUtil.trimString(map.areaName) }))
        .filter(map => !stringUtil.isStringNullOrEmpty(map.areaCodes) && !stringUtil.isStringNullOrEmpty(map.areaName))
        .forEach(map =>
        {
            const areaCodes = map.areaCodes.split(regexUtil.regexSplitByComma);
            const areaName = map.areaName;

            areaCodes
                .filter(areaCode => !stringUtil.isStringNullOrEmpty(areaCode))
                .forEach(areaCode => addressMappings.push({ areaCode: areaCode, areaName: areaName }));
        });

    const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();

    const resourceUrl = `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/geo/mapping`;

    const updatedAddressMappings = await rmsApiProxy.post(resourceUrl, addressMappings);

    return getAddressMappingsMergedByArea(updatedAddressMappings);
}

function getAddressMappingsMergedByArea(addressMappings)
{
    arraySort(addressMappings, stringUtil.localeCompareProp('areaCode'));

    const mergedAddressMappings = [];

    addressMappings.forEach(source =>
    {
        let addressMap = mergedAddressMappings.find(target => target.areaName === source.areaName);

        if (addressMap == null) 
        {
            addressMap = { areaCodes: '', areaName: source.areaName }
            mergedAddressMappings.push(addressMap);
        }

        addressMap.areaCodes += `${source.areaCode}, `;
    });

    return arraySort(mergedAddressMappings, stringUtil.localeCompareProp('areaName'));
}