
import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import Spinner from '../../../../components/spinner/spinner';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';

import CloseButton from '../../../../components/button/closeButton';
import SearchBarComponent from '../../../../components/form/searchBarComponent';
import CdnImage from '../../../../components/cdnImage/cdnImage';

const configurationManager = require('../../../../utils/config/configurationManager')

class Report extends Component
{
    constructor(props)
    {
        // Set up reportUtils.getLookupData to trigger one time loading of lookupData

        super(props);

        this.state =
        {
            isLoading: false,
            lookupData: null, reportData: null,
            isError: false, errorMessage: null,
        };
    }

    onCriteriaChanged = (searchCriteria) =>
    {
        this.setState(
            {
                isLoading: false,
                reportData: null,   // lookupData is not reset
                isError: false, errorMessage: null,
            });
    }

    onLoadReport = async (searchCriteria) =>
    {
        //
        // Set up reportUtils.getLookupData to trigger lookupData loading
        // lookupData is loaded along with reportData only if its not already loaded
        //

        this.setState(
            {
                isLoading: true,
                reportData: null,   // lookupData is not reset
                isError: false, errorMessage: null,
            });

        try
        {
            let lookupData = this.state.lookupData;

            if (lookupData == null && this.props.reportUtils.getLookupData != null)
            {
                // Fetch lookupData for once only
                lookupData = await this.props.reportUtils.getLookupData();
            }

            const reportData = await this.props.reportUtils.getReportData(searchCriteria);

            this.setState(
                {
                    isLoading: false,
                    lookupData: lookupData,
                    reportData: reportData
                });

        } catch (error)
        {
            this.setState(
                {
                    isLoading: false,
                    reportData: null,   // lookupData is not reset despite error to preserve successful fetch
                    isError: true, errorMessage: `Failed to load report data with error: ${error}`,
                });
        }
    }

    async componentDidMount()
    {
        if (this.props.hideSearchBar)
        {
            await this.onLoadReport();
        }
    }

    render() 
    {
        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    {this.renderHeader()}
                    {this.props.closeEnabled && <CloseButton disabled={this.state.isLoading} />}
                </CardHeader>

                <CardBody>
                    {this.renderReportContents()}
                </CardBody>

            </Card>
        );
    }

    renderHeader()
    {
        const title = this.props.reportUtils.getReportTitle(this.props.dataReportType);
        const imageUrl = this.props.reportUtils.getReportImageUrl(this.props.dataReportType);

        return (
            <div className="d-flex flex-row align-items-center">
                <CdnImage
                    width="25" height="25" radius="0"
                    publicId={imageUrl}
                    cloudName={configurationManager.getConfig().cdnAppCloudName} />
                <span className="mx-2" style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }} >{title}</span>
                {!this.props.hideSearchBar && this.renderSearchBar()}
            </div>);
    }

    renderSearchBar = () =>
    {
        let searchSingleDate = false;           // Default = Date range selection

        if (this.props.searchSingleDate != undefined)
        {
            searchSingleDate = this.props.searchSingleDate;
        }

        return (
            <SearchBarComponent
                searchSingleDate={searchSingleDate}
                disabled={this.state.isLoading}
                onSearch={(searchCriteria) =>
                {
                    this.onLoadReport(searchCriteria);
                }}
                onChange={(searchCriteria) =>
                {
                    this.onCriteriaChanged(searchCriteria);
                }}
            />);
    }

    renderReportContents = () =>
    {
        if (this.state.isError)
        {
            return <ErrorMessage message={this.state.errorMessage} />;
        }

        if (this.state.isLoading)
        {
            return <Spinner text="Loading Data" />
        }

        if (!this.state.reportData)
        {
            return <EmptyData action="Select or change criteria and hit Load button to load data" />;
        }

        // props = all the props that were originally passed to this (Report) component
        // state = lookupData, reportData

        return this.props.reportUtils.getReportContents({ ...this.props, ...this.state });
    }
}

export default Report;