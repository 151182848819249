const arraySort = require('array-sort');

const validator = require('../../../../utils/validator/validator');
const securityManager = require('../../../../utils/domain/security/securityManager');
const stringUtil = require('../../../../utils/string/stringUtil');
const securityConstants = require('../../../../utils/domain/security/securityConstants');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

export function getPassport()
{
    return securityManager.getPassport();
}

export function areSameOrgContexts(orgContext1, orgContext2)
{
    return orgContext1.franchisorId == orgContext2.franchisorId &&
        orgContext1.franchiseeId == orgContext2.franchiseeId &&
        orgContext1.propertyId == orgContext2.propertyId;
}

export function getOrgName(orgContext)
{
    let orgName = null;

    const { franchisorId, franchiseeId, propertyId } = orgContext;

    if ((validator.isAnyPresent(franchisorId, franchiseeId, propertyId)))
    {
        if (propertyId != null) 
        {
            const property = orgSelectors.selectProperty(propertyId);
            orgName = `Property:${property.name}`;
        }
        else
        {
            const franchisor = orgSelectors.selectFranchisor(franchisorId);
            orgName = `Franchisor:${franchisor.name}`;
        }
    }
    else
    {
        if (currentOrgNodeSelectors.isCurrentSystemSelected()) 
        {
            orgName = 'System';
        }
    }

    return orgName;
}

export function getBuiltInRoleFromRoleName(roleCode)
{
    let builtInRole;

    const { systemRoles, franchisorRoles, propertyRoles } = securityConstants.builtInUserRoles;

    builtInRole = Object.values(systemRoles).find(role => role.roleCode === roleCode);
    if (builtInRole != null) return builtInRole;

    builtInRole = Object.values(franchisorRoles).find(role => role.roleCode === roleCode);
    if (builtInRole != null) return builtInRole;

    builtInRole = Object.values(propertyRoles).find(role => role.roleCode === roleCode);
    if (builtInRole != null) return builtInRole;

    throw new Error(`getBuiltInRoleFromRoleName: Invalid roleCode, no matching built-in role found matching roleCode = ${roleCode}`);
}

export function getAvailableOrgNodes(aggregatedRoles, aggregatedRole)
{
    // aggregatedRole = null when invoked to determine whether 'Add New Role' button is enabled
    // aggregatedRole != null when invoked by an aggregated role to determine available orgNodes

    const availableOrgNodes = [];

    aggregatedRoles = aggregatedRoles.filter(role => role.orgContext != null);

    if (currentOrgNodeSelectors.isCurrentSystemSelected())
    {
        const orgContext = { franchisorId: null, franchiseeId: null, propertyId: null };

        if (!aggregatedRoles.some(role => this.areSameOrgContexts(role.orgContext, orgContext)))
        {
            // Not selected in any of the aggregated roles, hence this orgNode is available for selection
            availableOrgNodes.push({
                orgName: this.getOrgName(orgContext),
                orgContext: orgContext
            });
        }
    }
    else
    {
        const franchisors = arraySort(orgSelectors.selectFranchisors(), stringUtil.localeCompareProp("name"));
        const properties = arraySort(orgSelectors.selectProperties(), stringUtil.localeCompareProp("name"));

        franchisors.forEach(franchisor =>
        {
            const orgContext = { franchisorId: franchisor.id, franchiseeId: null, propertyId: null };

            if (!aggregatedRoles.some(role => this.areSameOrgContexts(role.orgContext, orgContext)))
            {
                // Not selected in any of the aggregated roles, hence this orgNode is available for selection
                availableOrgNodes.push({
                    orgName: this.getOrgName(orgContext),
                    orgContext: orgContext
                });
            }
        });

        properties.forEach(property =>
        {
            const orgContext = { franchisorId: property.franchisorId, franchiseeId: property.franchiseeId, propertyId: property.id };

            if (!aggregatedRoles.some(role => this.areSameOrgContexts(role.orgContext, orgContext)))
            {
                // Not selected in any of the aggregated roles, hence this orgNode is available for selection
                availableOrgNodes.push({
                    orgName: this.getOrgName(orgContext),
                    orgContext: orgContext
                });
            }
        });
    }

    if (aggregatedRole != null) 
    {
        // Implies invoked by aggregatedRole to determine available orgNodes

        if (aggregatedRole.orgName != null && aggregatedRole.orgContext != null) 
        {
            if (!availableOrgNodes.some(orgNode => orgNode.orgName === aggregatedRole.orgName && this.areSameOrgContexts(orgNode.orgContext, aggregatedRole.orgContext)))
            {
                availableOrgNodes.push({
                    orgName: aggregatedRole.orgName,
                    orgContext: aggregatedRole.orgContext
                });
            }
        }
    }

    return availableOrgNodes;
}

export function doesLoggedInUserHaveSufficientRolesToEditSelectedUser(user)
{
    const passport = getPassport();
    const { systemRoles, franchisorRoles, propertyRoles } = securityConstants.builtInUserRoles;

    const allowedRoles = [];

    if (passport.roles.some(role => role.roleCode === systemRoles.SystemAdmin.roleCode))
    {
        allowedRoles.push.apply(allowedRoles, Object.values(systemRoles).map(role => role.roleCode));
        allowedRoles.push.apply(allowedRoles, Object.values(franchisorRoles).map(role => role.roleCode));
        allowedRoles.push.apply(allowedRoles, Object.values(propertyRoles).map(role => role.roleCode));
    }
    else if (passport.roles.some(role => role.roleCode === systemRoles.SupportAdmin.roleCode))
    {
        allowedRoles.push(systemRoles.SupportAdmin.roleCode, systemRoles.Support.roleCode);
        allowedRoles.push.apply(allowedRoles, Object.values(franchisorRoles).map(role => role.roleCode));
        allowedRoles.push.apply(allowedRoles, Object.values(propertyRoles).map(role => role.roleCode));
    }
    else if (passport.roles.some(role => role.roleCode === franchisorRoles.Owner.roleCode))
    {
        allowedRoles.push.apply(allowedRoles, Object.values(franchisorRoles).map(role => role.roleCode));
        allowedRoles.push.apply(allowedRoles, Object.values(propertyRoles).map(role => role.roleCode));
    }

    if (user.roles.some(role => !allowedRoles.includes(role.roleCode)))
    {
        return false;
    }

    return true;
}

export function validateUserRoles(userRoles)
{
    if(!userRoles || userRoles.length === 0) 
        return;

    if (!validator.isArray(userRoles))
    {
        return "Invalid roles";
    }
    
    const { systemRoles, franchisorRoles, propertyRoles } = securityConstants.builtInUserRoles;

    for (const userRole of userRoles)
    {
        if (!validator.isPresent(userRole.emailAddress)) return 'emailAddress is missing in one of the roles';
        if (!validator.isPresent(userRole.roleCode)) return 'roleCode is missing in one of the roles';
        if (userRole.expiry != null) return 'role expiry is not null in one of the roles';
    }

    for (const userRole of userRoles)
    {
        const duplicateRoles = userRoles.filter(role =>
            role.franchisorId === userRole.franchisorId &&
            role.franchiseeId === userRole.franchiseeId &&
            role.propertyId === userRole.propertyId &&
            role.roleCode === userRole.roleCode);

        if (duplicateRoles.length > 1)
            return 'Duplicate roles found';
    }

    if (userRoles.some(userRole =>
        userRole.roleCode === systemRoles.SystemAdmin.roleCode ||
        userRole.roleCode === systemRoles.SupportAdmin.roleCode ||
        userRole.roleCode === franchisorRoles.Owner.roleCode))
    {
        if (userRoles.length > 1) 
        {
            return 'Invalid roles, roles collection contains an exclusive role with other roles';
        }
    }

    const propertyAdminRoles = userRoles.filter(userRole => userRole.roleCode === propertyRoles.StoreOwner.roleCode);

    for (const propertyAdminRole of propertyAdminRoles)
    {
        const propertyRoles = userRoles.filter(userRole =>
            userRole.franchisorId === propertyAdminRole.franchisorId &&
            userRole.franchiseeId === propertyAdminRole.franchiseeId &&
            userRole.propertyId === propertyAdminRole.propertyId);

        if (propertyRoles.length > 1)
            return 'Property admin role cannot co-exist with other roles for the same property';
    }

    if(userRoles.length === 1 && userRoles.some(role => role.roleCode === systemRoles.MenuSupport.roleCode))
        return "Menu Support Role can only be assigned wih Support Staff Role";

    return null;
}