const stateManager = require('../stateManager');

module.exports.selectPassport = function ()
{
    return stateManager.getStore().getState().passport;
}

module.exports.selectFullName = function ()
{
    const passport = this.selectPassport();
    return passport ? `${passport.fullName}` : "N/A";
}

module.exports.selectEmailAddress = function ()
{
    const passport = this.selectPassport();
    return passport?.emailAddress;
}
