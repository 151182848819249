
var stringUtil = require('../string/stringUtil');
var defaultConfig = require('./config').getConfig();

export function setEnvironment(environment, environmentConfigServiceUrl)
{
    defaultConfig.environment = environment;
    defaultConfig.environmentConfigServiceUrl = environmentConfigServiceUrl;
}

export async function loadConfiguration()
{
    if (stringUtil.isStringNullOrEmpty(defaultConfig.environmentConfigServiceUrl))
        return;

    var requestBody = {};
    requestBody.method = 'GET';
    var response = await fetch(defaultConfig.environmentConfigServiceUrl, requestBody);
    if (response.status != 200)
        throw `Error occurred while loading configuration with status: ${response.status} `;

    var configurationsResponse = await response.json();
    if (!configurationsResponse.found)
        return;

    
    var remoteConfigurations = configurationsResponse.configurations;
    defaultConfig.appBackOfficeVersion = "";
    for(var property in defaultConfig)
    {
        if (stringUtil.areStringSame(property, "environment") || stringUtil.areStringSame(property, "environmentConfigServiceUrl") ||
            stringUtil.areStringSame(property, "version"))
            continue;
            
        if (!remoteConfigurations.hasOwnProperty(property))
            throw `Failed to find configuration for environment: ${defaultConfig.environment} with key: ${property}`
        
        defaultConfig[property] = remoteConfigurations[property];
    }

    defaultConfig.showMainPortalBanner = stringUtil.areStringSame(defaultConfig.showMainPortalBanner, "true");
}

export function getConfig()
{
    return defaultConfig;
}

export function setConfig(newDefaultConfig)
{
    defaultConfig = newDefaultConfig;
}



