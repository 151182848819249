const validator = require('../validator/validator');
const stringUtil = require('../string/stringUtil');

module.exports.isValidElement = function (element) 
{
    if (!stringUtil.isStringNullOrEmpty(element.separatorLineCharacter) && element.separatorLineCharacter.length > 1)
        return "Separator line is not valid";

    if ((!stringUtil.isStringNullOrEmpty(element.newLine) && (!validator.isInteger(element.newLine))) || element.newLine < 0 )
        return "Number of empty lines is invalid";

    if(!stringUtil.isStringNullOrEmpty(element.gridItemNewLine) && (!validator.isInteger(element.gridItemNewLine) || element.gridItemNewLine < 0))
        return "Number of empty lines between items is invalid";

    if (!stringUtil.isStringNullOrEmpty(element.gridModifierNewLine) && (!validator.isInteger(element.gridModifierNewLine) || element.gridModifierNewLine < 0))
        return "Number of empty lines between modifiers is invalid";
}