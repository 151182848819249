import React, { Component } from "react";
import GridView from '../../../../../components/gridView/gridView';
import PaymentType from './component/PaymentType.jsx'

const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');
const GridViewOptions = require('../../../../../components/gridView/gridViewOptions.js');
const stringUtil = require('../../../../../utils/string/stringUtil.js');
const validator = require('../../../../../utils/validator/validator.js');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const apiLoadFacade = require('../../../../../utils/api/apiLoadFacade');

class PaymentTypes extends Component 
{
    constructor(props) 
    {
        super(props);

        this.loadPaymentTypes = this.loadPaymentTypes.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.save = this.save.bind(this);
    }


    render()
    {
        var gridViewOptions = new GridViewOptions();
        gridViewOptions.title = "Payment Methods";

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadPaymentTypes;

        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.getPrimaryKeyValue = (row) => row.code;


        gridViewOptions.getNewRow = () =>
            {
                const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
                var newRow = {};
                newRow.franchisorId = currentOrgNode.franchisorId;
                newRow.openCashDrawer = true;
                newRow.maxAmount = 0;
                return newRow;
            };
            
        gridViewOptions.getComponent = (isNew, row) => 
            <PaymentType
                data={row}
                isNew={isNew}
            />
        
        gridViewOptions.isReadOnly = this.props.isReadOnly;

        gridViewOptions.getComponentTitle = (isNew, row) => { return isNew ? 'Payment Type - New' : `Payment Type - ${row.code}` }

        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew)
                return "Please wait while adding new Payment Type.";

            return "Please wait while updating Payment Type."
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew)
                return "New Payment Type is added successfully.";

            return "Payment Type is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew)
                return "Following error occurred while adding new Payment Type:";

            return "Following error occurred while updating Payment Type:";
        }

        return (<GridView gridViewOptions={gridViewOptions}/>);
    };

    
    validateRow(isNew, paymentType)
    {
        if (stringUtil.isStringNullOrEmpty(paymentType.code))
            return "Code cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(paymentType.name))
            return "Name cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(paymentType.type))
            return "Payment Type cannot be left empty";

        if (!validator.isGreaterThanZero(paymentType.maxAmount))
            return "Max Amount is not specified or is less than zero";

        if (!validator.isDate(paymentType.expiryDate))
            return "Expiry Date is not valid";

        if (stringUtil.isStringNullOrEmpty(paymentType.openCashDrawer))
            return "Open Cash Drawer cannot be left empty";           
        
        return null;
    }

    async save(isNew, row, remoteData)
    {
        try
        {

            const updatedPaymentType = await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/promotions/paymentTypes`, row);
            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(type => type.code == updatedPaymentType.code);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedPaymentType);
            else
                remoteData.push(updatedPaymentType);

            const response = {};
            response.addUpdatedRow = updatedPaymentType;

            return response;

        } catch (error)
        {
            throw error;
        }

    }

    async loadPaymentTypes()
    {
        const paymentTypes = await apiLoadFacade.loadAllPaymentTypes();
        return paymentTypes;
    
    }

    constructGridColumnHeaders() 
    {
        var headers = [];
        var header = {};

        header = {};
        header.field = "code";
        header.headerName = "Code";
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        headers.push(header);

        header = {};
        header.field = "description";
        header.headerName = "Description";
        headers.push(header);

        header = {};
        header.field = "type";
        header.headerName = "Type";
        headers.push(header);

        header = {};
        header.field = "maxAmount";
        header.headerName = "Maximum Amount";
        header.type = dataTypeConstants.money;
        headers.push(header);

        header = {};
        header.field = "expiryDate";
        header.headerName = "Expiry Date";
        header.type = dataTypeConstants.date;
        headers.push(header);

        header = {};
        header.field = "openCashDrawer";
        header.headerName = "Open Cash Drawer";
        header.type = dataTypeConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }

}

export default PaymentTypes;
