import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import arraySort from 'array-sort';

import CloseButton from '../../../../../../components/button/closeButton';
import DefaultView from '../../../../../../components/defaultView/defaultView';
import SearchBarComponent from '../../../../../../components/form/searchBarComponent';
import SearchableListComponent from '../../../../../../components/searchableListComponent/SearchableListComponent';

import * as rmsApiProxy from '../../../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../../../utils/api/rmsApiProxyUtils';
import * as sortingUtil from '../../../../../../utils/sorting/sortingUtil';

import TabularReport from './components/TabularReport';
import ChartReport from './components/ChartReport';

import * as reportDataUtils from './utils/reportDataUtils';

class HourlySalesByReportingTag extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            salesData: null,
            selectedOption: null,
            error: null,
            isLoading: false,
        }
    }

    render()
    {
        let component;

        const { isLoading, error, salesData } = this.state;

        if (DefaultView.isDefaultView(isLoading, error, salesData))
        {
            component = <DefaultView {...{ isLoading, error, data: salesData }} />
        }
        else
        {
            component = this.renderReportData();
        }

        return (
            <Card>

                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <SearchBarComponent
                        searchSingleDate={false}
                        disabled={isLoading}
                        onChange={this.onSearchCriteriaChanged}
                        onSearch={this.onSearch} />
                    <div className='fs-5 fw-bold flex-grow-1 text-center'>Hourly Sales By Reporting Tags</div>
                    <CloseButton disabled={isLoading} />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>

            </Card>
        );
    }

    renderReportData()
    {
        const { salesData, selectedOption } = this.state;

        const options = reportDataUtils.getOptions(salesData);

        return (
            <div className='d-flex flex-column'>
                <div className='d-flex' style={{ height: '75vh' }}>
                    <div className='me-2' style={{ width: '35%' }}>
                        <SearchableListComponent
                            options={options}
                            initialSelectedOption={selectedOption}
                            displayFieldName="caption"
                            searchPlaceholder='Search reporting tag'
                            selector={(a, b) => a.caption === b.caption}
                            onSelectedOptionChanged={selectedOption => this.setState({ selectedOption })} />
                    </div>
                    <div className='d-flex flex-column' style={{ width: '65%' }}>
                        <div className='mb-1 px-2 py-2 text-center text-truncate fs-6 fw-bold border rounded bg-light'>
                            {selectedOption.caption}
                        </div>
                        <TabularReport
                            getMasterDataRows={this.getMasterDataRows}
                            getSalesByPropertyDetailDataRows={this.getSalesByPropertyDetailDataRows}
                            getSalesByVenueDetailDataRows={this.getSalesByVenueDetailDataRows} />
                    </div>
                </div>
                <div className='mt-2'>
                    <ChartReport
                        getSalesByPropertyChartDataRows={this.getSalesByPropertyChartDataRows}
                        getSalesByVenueChartDataRows={this.getSalesByVenueChartDataRows}
                        getDistinctPropertyNames={this.getDistinctPropertyNames}
                        getDistinctVenueCodes={this.getDistinctVenueCodes} />

                </div>
            </div>
        );
    }

    onSearchCriteriaChanged = () =>
    {
        this.setState({ salesData: null, error: null })
    }

    onSearch = (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    async loadData(searchCriteria)
    {
        this.setState({ error: null, salesData: null, selectedOption: null, isLoading: true });

        try 
        {
            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const resourceUrl =
                `${rmsApiProxyUtils.getCurrentReportsContextUrl()}/accounts/sales/hourlySalesByReportingTag` +
                `?fromDate=${fromDate}&toDate=${toDate}`;

            const salesData = await rmsApiProxy.get(resourceUrl);

            let selectedOption = null;

            if (salesData.length > 0)
            {
                // Pre-select first item
                arraySort(salesData, sortingUtil.localeCompareProp("caption"));
                const { caption } = salesData[0];
                selectedOption = { caption }
            }

            this.setState({ salesData, selectedOption });
        }
        catch (error) 
        {
            this.setState({ error: error });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    getMasterDataRows = () => reportDataUtils.getMasterDataRows(this.state);

    getSalesByPropertyDetailDataRows = (masterRow) => reportDataUtils.getSalesByPropertyDetailDataRows(masterRow, this.state);

    getSalesByVenueDetailDataRows = (masterRow) => reportDataUtils.getSalesByVenueDetailDataRows(masterRow, this.state);

    getSalesByPropertyChartDataRows = () => reportDataUtils.getSalesByPropertyChartDataRows(this.state);

    getSalesByVenueChartDataRows = () => reportDataUtils.getSalesByVenueChartDataRows(this.state);

    getDistinctPropertyNames = () => reportDataUtils.getDistinctPropertyNames(this.state);

    getDistinctVenueCodes = () => reportDataUtils.getDistinctVenueCodes(this.state);
}

export default HourlySalesByReportingTag;