
import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

const FormManager = require('../../../../../../utils/view/formManager');
const storefrontUtils = require('../../utils/storefrontUtils');

export default class StorefrontTimingOverride extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        }

        if (typeof this.props.data.autoAcceptOrder === 'undefined') 
        {
            this.props.data.autoAcceptOrder = true;  // default
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        return (
            <div className='p-2 d-flex flex-column' style={{ width: "60vw" }}>

                <div className='row'>
                    <div className='col'>
                        <InputComponent
                            inputType="number"
                            fieldName="prepTime"
                            caption="Prep Time"
                            placeholder="Enter prep time"
                            hintText="Required"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>
                    <div className='col'>
                        <InputComponent
                            inputType="number"
                            fieldName="deliveryTime"
                            caption="Delivery Time"
                            placeholder="Enter delivery time"
                            hintText="Required"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>
                    <div className='col'>
                        <BooleanSelectComponent
                            caption="Auto Accept Order"
                            fieldName="autoAcceptOrder"
                            hintText="Choose whether to auto accept order"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>
                </div>

                <div className='mb-3 border' />

                <div className='row'>
                    <div className='col'>
                        <InputComponent
                            inputType="money"
                            fieldName="orderPrice"
                            caption="Starting Order Price"
                            placeholder="Enter starting order price"
                            hintText="Optional"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>
                    <div className='col'>
                        <SelectComponent
                            optionValues={this.props.dispatchTypes}
                            optionFieldName="dispatchType"
                            optionDisplayFieldName="dispatchType"
                            caption="Dispatch Type"
                            fieldName="dispatchType"
                            hintText="Select the dispatch type"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />

                    </div>
                    <div className='col'>
                        <SelectComponent
                            optionValues={this.props.weekDays}
                            optionFieldName="code"
                            optionDisplayFieldName="code"
                            caption="Day of Week"
                            fieldName="dayOfTheWeek"
                            hintText="Select day of the week"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col'>
                        <InputComponent
                            inputType="24Hours"
                            multiLine={true}
                            caption="From"
                            rows={4}
                            timeClearable={true}
                            fieldName="fromDateTime"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>

                    <div className='col'>
                        <InputComponent
                            inputType="24Hours"
                            multiLine={true}
                            caption="To"
                            rows={4}
                            timeClearable={true}
                            fieldName="toDateTime"
                            inputReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </div>
                    <div className='col' />
                </div>

            </div>
        );
    }
}