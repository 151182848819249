

import typeUtil from '../../utils/type/typeUtil';

const sessionMap = new Map();

export const dataTypes = {
    appliances: "appliances",
    rota: "rota"
}

export function hasData(dataType)
{


    return sessionMap.has(dataType);
}

export function getData(dataType)
{


    const data = sessionMap.get(dataType);
    if (data == null) return null;

    return typeUtil.deepCloneObject(data);
}

export function setData(dataType, data)
{
    sessionMap.set(dataType, typeUtil.deepCloneObject(data));
}

export function getAllDataTypes()
{
    return [...sessionMap.keys()];
}

export function getAllData()
{
    const allData = {};

    for (const [dataType, data] of sessionMap.entries)
    {
        allData[dataType] = typeUtil.deepCloneObject(data);
    }

    return allData;
}
