import React from 'react'
import { Badge } from 'reactstrap'

const domainConstants = require('../../../../../../utils/domain/constants')

export default function PurchaseOrderStatus(props)
{
    const status = props.data.status;

    if (status == domainConstants.PurchaseOrderStatusType.draft)
        return <Badge className="bg-dark text-white px-3 py-2" pill>Draft</Badge>;

    if (status == domainConstants.PurchaseOrderStatusType.submitted)
        return <Badge className="bg-primary text-white px-3 py-2" pill>Submitted</Badge>;

    if (status == domainConstants.PurchaseOrderStatusType.received)
        return <Badge className="bg-success text-white px-3 py-2" pill>Received</Badge>;

    if (status == domainConstants.PurchaseOrderStatusType.voided)
        return <Badge className="bg-danger text-white px-3 py-2" pill>Voided</Badge>;

    return null;
}