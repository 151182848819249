import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import WebAppConfigurationOverview from './components/WebAppConfigurationOverview';
import WebAppConfigurationDispatchPaymentMethods from './components/WebAppConfigurationDispatchPaymentMethods';
import WebAppConfigurationPaymentSetup from './components/WebAppConfigurationPaymentSetup';
import WebAppFavIcon from './components/WebAppFavIcon';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontWebAppConfigurationHelper = require('./storefrontWebAppConfigurationHelper');
const FormManager = require('../../../../../utils/view/formManager.js');
const viewUtil = require('../../../../../utils/view/viewUtil');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontWebAppConfigurations extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
            storefront: this.props.params,
            isLoading: false
        };
        
        this.state.storefrontWebAppConfigurations = { applicationId: guidUtil.generateGuid() };
        this.state.formManager.viewModel = this.state.storefrontWebAppConfigurations;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let storefrontWebAppConfigurations = await storefrontWebAppConfigurationHelper.loadStorefrontWebAppConfigurations(this.state.storefront);
            
            this.state.storefrontWebAppConfigurations = storefrontWebAppConfigurations || { applicationId: guidUtil.generateGuid() };
            
            this.state.formManager.viewModel = this.state.storefrontWebAppConfigurations;

            this.setState({});
        }
        catch (error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    async onSaveClicked()
    {
        const validationError = storefrontWebAppConfigurationHelper.validateWebAppConfigurations(this.state.storefrontWebAppConfigurations)

        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            await storefrontWebAppConfigurationHelper.save(this.state.storefront, this.state.storefrontWebAppConfigurations);
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration, 
                                                      `${this.state.storefront.name} storefront Web App configurations have been altered`, 
                                                      this.state.storefrontWebAppConfigurations);
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }
        const tabHeaders = [];
        const tabPanels = [];

        tabHeaders.push(<Tab>Overview</Tab>);
        tabPanels.push(
            <TabPanel className='pt-1'>
                <WebAppConfigurationOverview data={this.state.storefrontWebAppConfigurations} />
            </TabPanel>);

        tabHeaders.push(<Tab>Payment Methods</Tab>);
        tabPanels.push(
            <TabPanel>
                <WebAppConfigurationDispatchPaymentMethods data={this.state.storefrontWebAppConfigurations} />
            </TabPanel>);

        tabHeaders.push(<Tab>Payment Providers</Tab>);
        tabPanels.push(
            <TabPanel>
                <WebAppConfigurationPaymentSetup data={this.state.storefrontWebAppConfigurations} storefront={this.state.storefront}/>
            </TabPanel>);

        tabHeaders.push(<Tab>Favicon</Tab>);
        tabPanels.push(<TabPanel>
            <WebAppFavIcon
                data={this.state.storefrontWebAppConfigurations}
                storefront={this.state.storefront}
                onImageUpdate={() =>
                {
                    this.setState({})
                }} />
        </TabPanel>);

        return (
            <Card>

                <CardHeader>
                    <EditComponentHeader
                        title="Storefront Web App Configurations"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    <Tabs>
                        <TabList>{tabHeaders}</TabList>
                        {tabPanels}
                    </Tabs>
                </CardBody>

            </Card>
        );
    }
}

export default StorefrontWebAppConfigurations;