import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import TaskOverview from './TaskOverview';
import TaskProperties from './TaskProperties';
import TaskConstraints from './TaskConstraints';
import TaskActions from './TaskActions';
import TaskResponses from './TaskResponses';
import TaskTokens from './TaskTokens';

import CreatedUpdatedHintsComponent from '../../utils/components/CreatedUpdatedHintsComponent';

import FormManager from '../../../../../utils/view/formManager';

export default class Task extends Component
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;

        // Initialize non-simple properties in case of Add New (blank new object)

        const task = this.formManager.viewModel;
        task.hazardTypes = task.hazardTypes || [];
        task.taskActions = task.taskActions || [];
        task.taskResponses = task.taskResponses || [];
    }

    render() 
    {
        const tabHeaders = [];

        tabHeaders.push(<Tab>General</Tab>);
        tabHeaders.push(<Tab>Properties</Tab>);
        tabHeaders.push(<Tab>Constraints</Tab>);
        tabHeaders.push(<Tab>Actions</Tab>);
        tabHeaders.push(<Tab>Responses</Tab>);
        tabHeaders.push(<Tab>Comments</Tab>);

        const tabContents = [];

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <TaskOverview isRowReadOnly={this.props.isRowReadOnly} lookupData={this.props.lookupData} formManager={this.formManager} />
        </TabPanel>)

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <TaskProperties data={this.props.data} isRowReadOnly={this.props.isRowReadOnly} />
        </TabPanel>)

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <TaskConstraints isRowReadOnly={this.props.isRowReadOnly} lookupData={this.props.lookupData} formManager={this.formManager} />
        </TabPanel>)

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <TaskActions isRowReadOnly={this.props.isRowReadOnly} lookupData={this.props.lookupData} formManager={this.formManager} />
        </TabPanel>)

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <TaskResponses isRowReadOnly={this.props.isRowReadOnly} lookupData={this.props.lookupData} formManager={this.formManager} />
        </TabPanel>)

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <TaskTokens isRowReadOnly={this.props.isRowReadOnly} lookupData={this.props.lookupData} formManager={this.formManager} />
        </TabPanel>)

        const task = this.formManager.viewModel;

        return (
            <div>
                <CreatedUpdatedHintsComponent data={task} lookupData={this.props.lookupData} />
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }
}

