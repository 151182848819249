import * as currentOrgNodeSelectors from '../state/stateSelectors/currentOrgNodeSelectors';

export function getCurrentReportingContextUrl()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

    if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
    {
        return `reporting/${orgContext.franchisorId}`;
    }
    else if (currentOrgNodeSelectors.isCurrentFranchiseeSelected())
    {
        return `reporting/${orgContext.franchisorId}/${orgContext.franchiseeId}`;
    }
    else if (currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        return `reporting/${orgContext.franchisorId}/${orgContext.franchiseeId}/${orgContext.propertyId}`;
    }
    else
    {
        return 'reporting/system';
    }
}

export function getFranchisorReportingContextUrl()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

    if (orgContext == null) throw new Error('Invalid orgContext');

    return `reporting/${orgContext.franchisorId}`;
}

export function getCurrentReportsContextUrl()
{
    // Routes starting with api/reports

    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

    if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
    {
        return `reports/${orgContext.franchisorId}`;
    }
    else if (currentOrgNodeSelectors.isCurrentFranchiseeSelected())
    {
        return `reports/${orgContext.franchisorId}/${orgContext.franchiseeId}`;
    }
    else if (currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        return `reports/${orgContext.franchisorId}/${orgContext.franchiseeId}/${orgContext.propertyId}`;
    }
    else
    {
        return 'reports/system';
    }
}

export function getFranchisorReportsContextUrl()
{
    // Routes starting with api/reports
    
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

    if (orgContext == null) throw new Error('Invalid orgContext');

    return `reports/${orgContext.franchisorId}`;
}
