var actionTypes = require('../../../actionTypes.js');
var stateManager = require("../../../stateManager.js");

module.exports.createAction = function(payload)
{
    var modalForms = cloneModalForms(stateManager.getStore().getState().modalForms);
    modalForms.push(payload)

    return (
            {
                type: actionTypes.modalFormUI,
                payload: modalForms
            }
           );
}

module.exports.createCloseAction = function(modalFormIndex)
{
    var modalForms = cloneModalForms(stateManager.getStore().getState().modalForms);

    var modalFormPayload = modalForms[modalFormIndex]
    modalFormPayload.title = null;
    modalFormPayload.componentCreator = null;
    modalFormPayload.onSave = null;
    modalFormPayload.onValidate = null;
    modalFormPayload.isReadOnly = false;

    return (
            {
                type: actionTypes.modalFormUI,
                payload: modalForms
            }
           );
}

function cloneModalForms(originalModalForms)
{
    var clonedModalForms = [];

    originalModalForms.forEach (m =>
        {
            clonedModalForms.push(m)
        });

    return clonedModalForms;
}

module.exports.changeState = function (previousState, payload)
{
    return Object.assign({}, previousState, {modalForms: payload});
}