const validator = require('../../validator/validator');
const catalogSelectors = require('../../state/stateSelectors/catalogSelectors');

module.exports.getOrgContextsFromPropertyIds = function (propertyIds)
{
    if (!Array.isArray(propertyIds))
        propertyIds = [propertyIds];

    const franchisees = catalogSelectors.selectFranchisees();
    const properties = catalogSelectors.selectProperties();

    const orgContexts = propertyIds.map(propertyId => 
    {
        const franchiseeId = properties.find(x => x.id === propertyId).franchiseeId;
        const franchisorId = franchisees.find(x => x.id === franchiseeId).franchisorId;

        const orgContext = new OrgContext(franchisorId, franchiseeId, propertyId)
        return orgContext;
    });

    return orgContexts;
}

module.exports.getOrgContextsFromFranchiseeIds = function (franchiseeIds)
{
    if (!Array.isArray(franchiseeIds))
        franchiseeIds = [franchiseeIds];

    const franchisees = catalogSelectors.selectFranchisees();

    const orgContexts = franchiseeIds.map(franchiseeId => 
    {
        const franchisorId = franchisees.find(x => x.id === franchiseeId).franchisorId;

        const orgContext = new OrgContext(franchisorId, franchiseeId, null)

        return orgContext;
    });

    return orgContexts;
}

module.exports.getOrgContextsFromFranchisorIds = function (franchisorIds)
{
    if (!Array.isArray(franchisorIds)) franchisorIds = [franchisorIds];

    const orgContexts = franchisorIds.map(franchisorId => new OrgContext(franchisorId, null, null));

    return orgContexts;
}

module.exports.getOrgContextFromModel = function (model)
{
    if (validator.isPresent(model.propertyId))
        return this.getOrgContextsFromPropertyIds(model.propertyId);

    if (validator.isPresent(model.franchiseeId))
        return this.getOrgContextsFromFranchiseeIds(model.franchiseeId);

    if (validator.isPresent(model.franchisorId))
        return this.getOrgContextsFromFranchisorIds(model.franchisorId);

    return null;
}

function OrgContext(franchisorId, franchiseeId, propertyId)
{
    this.franchisorId = franchisorId;
    this.franchiseeId = franchiseeId;
    this.propertyId = propertyId;
}

module.exports.OrgContext = OrgContext;