import React, { Component } from 'react';
import SelectComponent from '../../../../../../components/form/selectComponent';

const arraySort = require('array-sort');

const FormManager = require('../../../../../../utils/view/formManager');
const catalogSelectors = require('../../../../../../utils/state/stateSelectors/catalogSelectors');


export default class PropertyFloatConfigurationComponent extends Component 
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = props.data;
        this.formManager.onFieldChanged = this.onFieldChanged;
        this.formManager.view = this;
    }

    getFrequencies = () =>
    {
        const frequencies = arraySort(catalogSelectors.selectLookupData().frequencies, ["sortIndex"]);
        return frequencies.map(frequency => ({ value: frequency.code, label: frequency.notes }));
    }

    getFrequencyOptions = () =>
    {
        const payrollRunFrequency = this.formManager.viewModel.payrollRunFrequency;
        if (payrollRunFrequency == null) return [];

        const frequencies = catalogSelectors.selectLookupData().frequencies;
        const frequency = frequencies.find(frequency => frequency.code === payrollRunFrequency);
        const frequencyOptions = arraySort(frequency.options, ["sortIndex"]);

        return frequencyOptions.map(option => ({ value: option.code, label: option.notes }));
    }

    onFieldChanged = (event) =>
    {
        if (event.target.id === "payrollRunFrequency") 
        {
            this.formManager.viewModel.payrollRunFrequencyOption = null;
        }
    }

    render()
    {
        return (
            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Payroll Run Frequency"
                                fieldName="payrollRunFrequency"
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                optionValues={this.getFrequencies()}
                                hintText="Required"
                                clearable={false}
                                formManager={this.formManager}
                            />
                        </td>
                        <td />
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Payroll Run Frequency Option"
                                fieldName="payrollRunFrequencyOption"
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                optionValues={this.getFrequencyOptions()}
                                hintText="Required"
                                clearable={false}
                                formManager={this.formManager}
                            />
                        </td>
                        <td />
                    </tr>

                </tbody>
            </table>
        );
    }
}
