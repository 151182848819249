import React, { Component } from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as biChartUtils from '../../../../../../../utils/bi/chartUtils';

class TimingChartRenderer extends Component
{
    render()
    {
        return (
            <div className='border' style={{ height: "50vh" }}>
                <AgChartsReact options={this.getOptions(this.props)} />
            </div>
        );
    }

    getOptions = (props) =>
    {
        const { title, subtitle, timingData } = props;

        const options = biChartUtils.getDefaultLineChartOptions();

        options.background = {
            fill: 'WhiteSmoke',
        };

        options.title = title && { text: title, fontSize: 12, fontWeight: 'bold' };
        options.subtitle = subtitle && { text: subtitle, fontSize: 10 };

        options.data = timingData.map(rowData => 
        {
            // chartRowData = [{hour:"01:00", Mon:15, Tue:20, ...Weekly:15}]

            const chartRowData = { hour: rowData.hour };

            Object.keys(rowData)
                .filter(key => key !== "hour")
                .forEach(key => chartRowData[key] = rowData[key]["averageTime"]);

            return chartRowData;
        });

        options.series = [
            { type: "line", xKey: "hour", yKey: "Mon", yName: "Mon", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Tue", yName: "Tue", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Wed", yName: "Wed", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Thu", yName: "Thu", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Fri", yName: "Fri", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Sat", yName: "Sat", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Sun", yName: "Sun", tooltip: this.tooltip },
            { type: "line", xKey: "hour", yKey: "Weekly", yName: "Weekly", tooltip: this.tooltip },
        ];

        options.axes = [
            {
                position: "bottom",
                type: "category",
                title: { text: "Hours" },
            },
            {
                position: "left",
                type: "number",
                title: { text: "Average Time In Minutes" },
            },
        ];

        return options;
    }

    tooltip = {
        renderer: ({ title, datum, xKey, yKey }) =>
        {
            const timingDataRow = this.props.timingData.find(row => row[xKey] === datum[xKey]);
            const saleCount = timingDataRow[yKey]["saleCount"];

            return {
                title: `${title}, Total Sales: ${saleCount}`,
                content: `${datum[xKey]}: ${datum[yKey]} mins`,
            }
        }
    };
}

export default TimingChartRenderer;