const stringUtil = require('../../../../../utils/string/stringUtil.js');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgDataAction = require('../../../../../utils/state/actions/orgData/orgData/orgDataAction');
const catalogDataAction = require('../../../../../utils/state/actions/catalogData/catalogData/catalogDataAction');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const stateManager = require('../../../../../utils/state/stateManager');

export async function save (isNew, data)
{
    let resourceUrl = "";
    if(isNew)
    {
        const franchisee = currentOrgNodeSelectors.selectFirstAvailableFranchisee();
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/${franchisee.id}/properties`;
    }
    else
    {
        resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/basicConfiguration`;
    }

    const updatedProperty = await rmsApiProxy.post(resourceUrl, data);

    let properties = orgSelectors.selectProperties();
    properties = properties.filter(f => f.id != updatedProperty.id);
    properties.push(updatedProperty);

    await updateStatePropertySubscriptions(updatedProperty);
    await updateStatePropertyLocalizationSettings(updatedProperty);

    actionDispatcher.dispatch(orgDataAction.createPropertiesUpdateAction(properties));
    actionDispatcher.dispatch(catalogDataAction.createPropertiesUpdateAction(properties));
    return updatedProperty;
}

async function updateStatePropertySubscriptions(property)
{
    const resourceUrl = `core/organizations/${property.franchisorId}/${property.franchiseeId}/${property.id}/licenseSubscriptions`;
    var currentPropertySubscriptions = await rmsApiProxy.get(resourceUrl);

    var propertyLicenseSubscriptions = stateManager.getStore().getState().propertyLicenseSubscriptions;
    propertyLicenseSubscriptions.set(property.id, currentPropertySubscriptions);
}

async function updateStatePropertyLocalizationSettings(property)
{
    // Updates resolved property localization settings in the state

    const resourceUrl = `core/organizations/${property.franchisorId}/${property.franchiseeId}/${property.id}/localizations`;
    const resolvedLocalizationSettings = await rmsApiProxy.get(resourceUrl);

    const propertyLocalizations = stateManager.getStore().getState().propertyLocalizations
    propertyLocalizations.set(property.id, resolvedLocalizationSettings);
}

export function validate (data)
{
    if (stringUtil.isStringNullOrEmpty(data.id))
        return "id cannot be left empty";

    if (!validator.isPresent(data.type))
        return "type cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(data.name))
        return "name cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(data.address))
        return "address cannot be left empty";

    if (!stringUtil.isStringNullOrEmpty(data.phoneNumber) && !validator.isValidPhoneNumber(data.phoneNumber))
        return "phone number is not valid";
    
    if (stringUtil.isStringNullOrEmpty(data.timeZone))
        return "timeZone cannot be left empty";
    
    if (stringUtil.isStringNullOrEmpty(data.emailAddress))
        return "emailAddress cannot be left empty";

    if (data.isBlocked && stringUtil.isStringNullOrEmpty(data.blockedReason))
        return "Block Reason cannot be empty for a blocked property";

    if (!data.isBlocked && !stringUtil.isStringNullOrEmpty(data.blockedReason))
        return "Block Reason cannot be set for a non-blocked property";

    return null;
}