
const guidUtil = require('../../../../../utils/guid/guidUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');
const storefrontCommissionCalculatorUtil = require('./storefrontCommissionCalculatorUtil');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

module.exports.enrichViewModel = function (storefrontCommissionRun)
{
    if (stringUtil.isStringNullOrEmpty(storefrontCommissionRun))
    {
        storefrontCommissionRun.id = guidUtil.generateGuid();
    }

    this.updateCalculatedFields(storefrontCommissionRun);
}

module.exports.updateCalculatedFields = function (storefrontCommissionRun)
{
    storefrontCommissionCalculatorUtil.updateComputedProperties(storefrontCommissionRun);
}

module.exports.validate = function (storefrontCommissionRun)
{
    const vatCaption = localizationUtils.getVatCaption();

    if (!validator.isGreaterThanOrEqualToZero(storefrontCommissionRun.commissionRate))
        return "Commission Rate is not valid";

    if (!validator.isGreaterThanOrEqualToZero(storefrontCommissionRun.rebate))
        return "Rebate is not valid";

    if (!validator.isGreaterThanOrEqualToZero(storefrontCommissionRun.additionalCharges))
        return "Additional Charge is not valid";

    if (!validator.isGreaterThanOrEqualToZero(storefrontCommissionRun.adjustedVAT))
        return `Adjusted ${vatCaption} is not valid`;

    return null;
}