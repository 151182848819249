import React from "react";
import styled from "styled-components";
import AddStationButton from "./AddStationButton";
import ToolbarButtons from "./ToolbarButtons";
import securityManager from "../../../../../utils/domain/security/securityManager";

const TopHeader = () => {
     return (
          <Wrapper>
               <Heading2>Network Diagram</Heading2>
               <CenterOptionContainer>
                    {securityManager.isSystemUser() && <AddStationButton />}
               </CenterOptionContainer>
               <OptionContainer>
                    {/* <SearchBox /> */}
                    <ToolbarButtons />
                   
               </OptionContainer>
          </Wrapper>
     );
};

export default TopHeader;

const Wrapper = styled.div`
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     padding: 12px 20px;
     border-bottom: 4px solid rgb(246, 249, 255);
`;
const OptionContainer = styled.div`
     display: flex;
     gap: 12px;
`;
const CenterOptionContainer = styled.div`
     display: flex;
     gap: 12px;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
`;
const Heading2 = styled.h2`
     font-size: 1.3rem;
`;
