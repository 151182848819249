import 'bootstrap/dist/css/bootstrap.css';

import 'react-select/dist/react-select.min.css'
import 'react-tabs/style/react-tabs.css';
import 'react-responsive-modal/styles.css';
import 'react-contexify/dist/ReactContexify.min.css';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './components/grid/gridComponent.css' 
import './component.css';
import './index.css';
import './App.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import EmbeddedApp from './EmbeddedApp.js';
import { Provider } from "react-redux";
import {LicenseManager} from "ag-grid-enterprise";

var stateManager = require('./utils/state/stateManager.js');
const configurationManager = require('./utils/config/configurationManager');
const domainConstants = require('./utils/domain/constants');


LicenseManager.setLicenseKey(configurationManager.getConfig().agGridLicenseKey);

initializeApplicationMode();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={stateManager.getStore()}>
        {loadAppComponent()}
    </Provider>
)

function initializeApplicationMode()
{
    const url = new window.URL(window.location);
    const urlSearchParams = url.searchParams;

    if (urlSearchParams.size === 0) return;

    const stationActivationCode = urlSearchParams.get("stationActivationCode");
    const stationType = urlSearchParams.get("stationType");
    const embedded = urlSearchParams.get("embedded");


    if (stationActivationCode && stationType)
    {
        const state = stateManager.getStore().getState();
        state.applicationMode = domainConstants.applicationModes.stationActivationWizard;
        state.applicationModeData = { stationActivationCode, stationType }
        return;
    }

    if (embedded)
    {
        const state = stateManager.getStore().getState();
        state.applicationMode = domainConstants.applicationModes.embedded;
        state.applicationModeData = {
                                       sessionId: urlSearchParams.get("sessionId"),
                                       franchisorId: urlSearchParams.get("franchisorId"),
                                       propertyId: urlSearchParams.get("propertyId"),
                                       storefrontId: urlSearchParams.get("storefrontId"),
                                       rumSessionId: urlSearchParams.get("rumSessionId"),
                                    }
        return;  
    }
}

function loadAppComponent()
{
    const state = stateManager.getStore().getState();
    if (state.applicationMode == domainConstants.applicationModes.embedded)
    {
        return <EmbeddedApp/>
    }

    return <App/>
}
