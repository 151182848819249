import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard.js';
import SalesDataGrid from './SaleDataGrid.jsx';

const formatter = require('../../../../../utils/formatter/formatter.js');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants.js');
const nomenclatureUtils = require('../../../../../utils/domain/nomenclatureUtils.js');

const SaleRefunds = (props) => {

    const totalRefunds = props.sale.refunds.reduce((previous, refund) => previous + refund.amount, 0);

    return <CollapsibleCard caption={`Sale Refunds - ${formatter.convertToCurrencyFormat(totalRefunds)}`} isDefaultOpen={props.isDefaultOpen}>
            <SalesDataGrid sale={props.sale} getColumnDefs={getColumnDefs} getData={() => getData(props.sale)} />
    </CollapsibleCard>
}
 
function getData(sale)
{
    return sale.refunds;
}

function getColumnDefs()
{
    let header;
    const headers = [];

    header = {};
    header.field = "type";
    header.headerName = "Refund Type";
    headers.push(header);

    header = {};
    header.field = "operator";
    header.headerName = "Operator";
    headers.push(header);

    header = {};
    header.field = "refundReason";
    header.headerName = "Refund Reason";
    header.suppressSizeToFit = false;
    headers.push(header);

    header = {};
    header.field = "amount";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.refundAmount);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;

}

export default SaleRefunds;