import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Row } from 'reactstrap';


export default function Spinner({ text = 'Loading' })
{
    return (
        <div>
            <Row className="justify-content-center">
                <ScaleLoader
                    height={50}
                    width={6}
                    color={"#808080"}
                    loading={true} />
            </Row>
            <Row className="justify-content-center" style={{ margin: '5px' }}>
                <h4 className="text-muted">{text}</h4>
            </Row>
        </div>
    );
}