const arraySort = require('array-sort');
const stringUtil = require('../string/stringUtil');
const stateManager = require('../state/stateManager');
const catalogSelectors = require('../state/stateSelectors/catalogSelectors');
const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');

const placeHolderReplacements = [
    {
        placeholder: "%VAT%",
        defaultValue: "VAT",
        getValue: () => module.exports.getVatCaption()
    }
]

module.exports.distanceMeasurementSystem = {
    metric: "metrics",
    imperial: "imperial"
}

module.exports.attributes = {
    code: "code",
    name: "name",
    language: "language",
    currency: "currency",
    currencySymbol: "currencySymbol",
    currencyDisplay: "currencyDisplay",
    vatCaption: "vatCaption",
    vatPercentage: "vatPercentage",
    isVatInclusive: "isVatInclusive",
    isVatApplicable: "isVatApplicable",
    phoneNumberValidator: "phoneNumberValidator",
    supportEmail: "supportEmail",
    supportPhone: "supportPhone",
    supportWhatsapp: "supportWhatsapp",
    distanceMeasurementSystem: "distanceMeasurementSystem",
    operativeVatBands: "operativeVatBands",
    iso3166Alpha2Code: 'iso3166Alpha2Code',
}

module.exports.getCountries = function ()
{
    const countrySpecs = catalogSelectors.selectLookupData().countrySpecs;
    const countries = [];
    countrySpecs.forEach(countrySpec => {
        countries.push(
            {code: `${countrySpec.code}`, name: `${countrySpec.name}`});
    })
    return countries;
}

module.exports.getCurrentCurrencyFormatter = function (orgNode = null)
{
    const language = getLocalValue(this.attributes.language, orgNode) || "en-GB";
    const currency = getLocalValue(this.attributes.currency, orgNode) || "GBP";
    const currencyDisplay = getLocalValue(this.attributes.currencyDisplay, orgNode) || "symbol";

    const options = {
        style: "currency",
        currency: currency,
        currencyDisplay: currencyDisplay,
        minimumFractionDigits: 2
    };

    return new Intl.NumberFormat(language, options);
}

module.exports.getVatCaption = function (orgNode = null)
{
    return getLocalValue(this.attributes.vatCaption, orgNode)
}

module.exports.getCurrency = function()
{
    return getLocalValue(this.attributes.currency);
}

module.exports.getCurrencySymbol = function (orgNode = null)
{
    return getLocalValue(this.attributes.currencySymbol, orgNode)
}

module.exports.getCountry = function (orgNode = null)
{
    return getLocalValue(this.attributes.name, orgNode)
}

module.exports.isVatInclusive = function(orgNode = null)
{
    return getLocalValue(this.attributes.isVatInclusive, orgNode);
}

module.exports.getVatPercentage = function(orgNode = null)
{
    return getLocalValue(this.attributes.vatPercentage, orgNode);
}

module.exports.getDistanceMeasurementSystem = function(orgNode = null)
{
    return getLocalValue(this.attributes.distanceMeasurementSystem, orgNode);
}

module.exports.getOperativeVatBands = function (orgNode = null)
{
    return getLocalValue(this.attributes.operativeVatBands, orgNode) || [];
}

module.exports.getISO3166Alpha2Code = function (orgNode = null)
{
    return getLocalValue(this.attributes.iso3166Alpha2Code, orgNode)
}

module.exports.getCurrencyDenominations = function (orgNode = null)
{
    return getLocalValue(this.attributes.currencyDenominations, orgNode);
}

function getLocalValue(attribute, orgNode = null)
{
    orgNode = orgNode || currentOrgNodeSelectors.selectCurrentOrgNode();

    let localization;
    if(!stringUtil.isStringNullOrEmpty(orgNode.propertyId))
    {
        localization = stateManager.getStore().getState().propertyLocalizations.get(orgNode.propertyId);
    }
    else if(!stringUtil.isStringNullOrEmpty(orgNode.franchisorId))
    {
        const orgState = stateManager.getStore().getState().orgStates.get(orgNode.franchisorId);
        localization = orgState.franchisorLocalization;
    }

    return localization != null ? localization[attribute] : null;
}

module.exports.changePlaceHolderText = function(text)
{
    placeHolderReplacements.forEach(replacement => {
        const value = replacement.getValue() || replacement.defaultValue;
        text = stringUtil.replaceAll(text, replacement.placeholder, value);
    });

    return text;
}

module.exports.getColdItemTaxes = function ()
{

    let vatBands = [];

    switch (this.getCountry()) 
    {
        case "Ireland":
            vatBands = [23];
            break;
        case "UK":
            vatBands = [20];
            break;
        case "Pakistan":
            vatBands = [];
            break;
        case "Kenya":
            vatBands = [];
            break;
        default:
            break;
    }

    return arraySort(vatBands);
}
