import React, { Component } from 'react';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../../../components/form/multiSelectComponent';

const domainConstants = require('../../../../../../utils/domain/constants');
const paymentMethods = require('../../../../../../utils/constants/paymentMethodConstants');
const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class OperatingConfigurationPOSOptions extends Component
{
    constructor(props)
    {
        super(props);

        this.onDispatchTypeSelectionChanged = this.onDispatchTypeSelectionChanged.bind(this);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        this.state.formManager.viewModel.unpaidDispatchTypes = this.state.formManager.viewModel.unpaidDispatchTypes || [];  
        const selectedUnpaidDispatchTypes = this.state.formManager.viewModel.unpaidDispatchTypes.map(x => ({ dispatchType: x }));

        this.state.formManager.viewModel.openCashDrawerPaymentMethods = this.state.formManager.viewModel.openCashDrawerPaymentMethods || [];
        const selectedOpenCashDrawerPaymentMethods = this.state.formManager.viewModel.openCashDrawerPaymentMethods
            .map(x => (x == paymentMethods.cash ? { paymentMethod: x, disabled: true } : { paymentMethod: x }));

        return (

            <table className='component-table'>
                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Pin Entry Optional"
                            fieldName="isPinEntryOptional"
                            comboReadOnly={this.isReadOnly}
                            hintText="Is Pin Entry Optional"
                            clearable={false}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Can Order Without Customer Phone Number"
                            fieldName="canOrderWithoutCustomerPhoneNumber"
                            comboReadOnly={this.isReadOnly}
                            hintText="Can Order Without Customer Phone Number"
                            clearable={false}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <SelectComponent
                            optionValues={domainConstants.getTriStateFlags()}
                            optionFieldName="key"
                            optionDisplayFieldName="value"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            fieldName="dineInRequiresOrderReference"
                            caption="Requires Number for Dine In order"
                            hintText="Taking order reference (table number, token number)"
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <SelectComponent
                            optionValues={domainConstants.getTriStateFlags()}
                            optionFieldName="key"
                            optionDisplayFieldName="value"
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            fieldName="takeAwayRequiresOrderReference"
                            caption="Requires Number for Takeaway order"
                            hintText="Taking order reference (table number, token number)"
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td width="50%" >
                        <MultiSelectComponent
                            options={domainConstants.getDispatchTypes()}
                            displayFieldName="dispatchType"
                            valueFieldName="dispatchType"
                            selectedOptions={selectedUnpaidDispatchTypes}
                            onSelectionChanged={(selectedOptions) => this.onDispatchTypeSelectionChanged(selectedOptions)}
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            fieldName="unpaidDispatchTypes"
                            caption="Order without payment in advance for dispatch type"
                            hintText="Dispatch types where order can be placed without payment in advance"
                        />
                    </td>
                    <td>
                        <MultiSelectComponent
                            options={domainConstants.getOpenCashDrawerPaymentMethods()}
                            displayFieldName="paymentMethod"
                            valueFieldName="paymentMethod"
                            selectedOptions={selectedOpenCashDrawerPaymentMethods}
                            onSelectionChanged={(selectedOptions) => this.onOpenCashDrawerPaymentMethodSelectionChanged(selectedOptions)}
                            clearable={false}
                            comboReadOnly={this.isReadOnly}
                            fieldName="openCashDrawerPaymentMethods"
                            caption="Payment types to open cash drawer for"
                            hintText="Payment types for which cash drawer will be opened"
                        />
                    </td>
                </tr>
            </table>
        );
    }

    onDispatchTypeSelectionChanged(selectedOptions)
    {
        let unpaidDispatchTypes = [];

        if (selectedOptions.length > 0)
        {
            unpaidDispatchTypes = selectedOptions.map(option => option.dispatchType);
        }

        this.state.formManager.viewModel.unpaidDispatchTypes = unpaidDispatchTypes;
        this.setState({});
    }

    onOpenCashDrawerPaymentMethodSelectionChanged(selectedOptions, props)
    {
        let openCashDrawerPaymentMethods = [];

        if (selectedOptions.length > 0)
        {
            openCashDrawerPaymentMethods = selectedOptions.map(option => option.paymentMethod);
        }
        this.state.formManager.viewModel.openCashDrawerPaymentMethods = openCashDrawerPaymentMethods;
        this.setState({});
    }
}

export default OperatingConfigurationPOSOptions;