import React, { Component } from 'react';
import {DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import MultiSelectComponent from '../../../../components/form/multiSelectComponent.js'
import InputComponent from '../../../../components/form/inputComponent.js'
import BooleanSelectComponent from '../../../../components/form/booleanSelectComponent'
import PreviewElement from './PreviewElement'

import SimpleTooltip from "../../../../components/tooltip/simpleToolTip";

const printingTemplateUtils = require('./printingTemplateUtils');
const domainConstants = require('../../../../utils/domain/constants');
const FormManager = require('../../../../utils/view/formManager');
const stringUtil = require('../../../../utils/string/stringUtil');

class PrintingTemplate extends Component 
{

    constructor(props) 
    {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.state.document = this.props.data.document;

        if (this.state.document.constraints == null)
        {
            this.state.document.constraints = {};
            this.state.document.constraints.dispatchTypes = [];
            this.state.document.constraints.venueCodes = [];
            this.state.document.constraints.paidUnpaids = [];
            this.state.document.constraints.storefronts = [];
        }

        if (this.state.document.elements == null)
        {
            this.state.document.elements = [];
        }
    }


    render() 
    {
        var tabHeaders = [];
        tabHeaders.push(<Tab>Designer</Tab>)
        tabHeaders.push(<Tab>Details</Tab>)

        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderDesigner()}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderDetails()}</TabPanel>);

        return <Tabs style={{ marginLeft: "-10px", marginTop: "15px" }}>
                    <TabList>
                        {tabHeaders}
                    </TabList>

                    {tabContents}
                </Tabs>;

    }

    renderDesigner()
    {
        return <table style={{width:'100%'}}>
                    <tr>
                        <td style={{width:'80%', textAlign:'center', paddingLeft:'20px', paddingRight:'20px', verticalAlign:'top'}}>
                            {this.renderPreview()}
                        </td>
                        <td style={{borderLeft:'1px solid #282828', paddingLeft:'2px', paddingRight:'5px', verticalAlign:'top'}}>
                                {this.renderControls()}
                        </td>
                    </tr>
        </table>
    }

    renderPreview()
    {
        var controls = [];

        if (this.state.document.elements.length <= 0)
        {
            controls.push(<div style={{marginTop:'200px', textAlign:'center', color:'#A9A9A9'}}>
                                Printing Preview
                         </div>)
        }
        else
        {
            const printingTemplateType = this.props.data.type;

            for (var index = 0; index < this.state.document.elements.length; index++)
            {
                let element = this.state.document.elements[index];

                let tooltip = element.title;
                if (!stringUtil.isStringNullOrEmpty(element.tooltip))
                    tooltip = element.tooltip;

                let id = stringUtil.replaceAll(element.type + element.title, " ", "_");
                id = stringUtil.replaceAll(id, "(", "");
                id = stringUtil.replaceAll(id, ")", "");
                id = `preview_printing_template_${id}`;

                controls.push(<Draggable key={`preview.element.key.${index}`} draggableId={`preview.element.id.${index}`} index={index}>
                                    {(provided, snapshot) => ( <div id={id} ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                                                                <PreviewElement element={element} printingTemplateType={printingTemplateType} />
                                                                <SimpleTooltip target={id} text={tooltip} />
                                                                </div>
                                                            )}
                                </Draggable>);
            }
        }

        return <div style={{width:'120mm', display:'inline-block', margin:'0 auto;', border:'1px solid #909090', minHeight:'700px', background:'#F5F5F5', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'10px', paddingRight:'10px'}}>
                    
                    <DragDropContext key="printing.element.drag.context" onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="signage.slide.droppable">
                            {(provided, snapshot) => (
                                                        <div ref={provided.innerRef}>
                                                            {controls}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                        </Droppable>
                    </DragDropContext>
               </div>
    }

    onDragEnd(result)
    {
        var source = result.source;
        var destination = result.destination;

        if (source == null)
            return;

        if (destination == null)
        {
            this.state.document.elements.splice(source.index, 1);
            this.setState({});
            return;
        }

        var element = this.state.document.elements[source.index];
        this.state.document.elements.splice(source.index, 1);
        this.state.document.elements.splice(destination.index, 0, element)
        this.setState({});
    }

    renderControls()
    {
        const elements = printingTemplateUtils.getElements(this.props.data.type);

        var controls = [];
        elements.forEach (element =>
            {
                let localElement = element;
                let tooltip = localElement.title;
                if (!stringUtil.isStringNullOrEmpty(localElement.tooltip))
                    tooltip = localElement.tooltip;

                let id = stringUtil.replaceAll(localElement.type + localElement.title, " ", "_");
                id = stringUtil.replaceAll(id, "(", "");
                id = stringUtil.replaceAll(id, ")", "");
                id = `printing_template_${id}`;
                controls.push(<div id={id}>
                                <div style={{background:'#F5F5F5', border:'1px solid #696969', marginBottom:'1px', textAlign:'center', paddingTop:'10px', paddingBottom:'10px', userSelect:'none', cursor:'pointer'}} 
                                   onClick={()=>
                                   {
                                        this.state.document.elements.push(JSON.parse(JSON.stringify(localElement)));
                                        this.setState({});
                                   }}>
                                    {localElement.title}
                                </div>
                                <SimpleTooltip target={id} text={tooltip} />
                            </div>)
            })
        
        return <div>
                    <div style={{background:'#303030', marginBottom:'2px', paddingTop:'20px', paddingBottom:'20px', fontSize:'0.8vw', fontWeight:'bold', color:'white', textAlign:'center'}}>
                        Elements
                    </div>
                    {controls}
        </div>;
    }

    renderDetails()
    {
        const selectedDispatchTypes = this.state.document.constraints.dispatchTypes.map(x => ({ dispatchType: x }));
        const selectedVenueCodes = this.state.document.constraints.venueCodes.map(x => ({ venueCode: x }));
        const selectedPaidUnpaids = this.state.document.constraints.paidUnpaids.map(x => ({ paidUnpaid: x }));

        const selectedStorefronts = [];
        
        if(!this.state.document.constraints.storefronts) this.state.document.constraints.storefronts = [];

        this.state.document.constraints.storefronts.forEach(storefront => 
            {
                var selectedStorefront = this.props.storefronts.find(sf => stringUtil.areStringSame(sf.identifier, storefront))
                if(selectedStorefront)
                    selectedStorefronts.push(selectedStorefront);
            })

        var paidUnpaid = []
        paidUnpaid.push({paidUnpaid: "Paid"});
        paidUnpaid.push({paidUnpaid: "UnPaid"});

        return <table style={{width:'100%'}}>
                    <tr>
                        <td width='50%'>
                            <InputComponent
                                style={{marginRight:'10px'}}
                                inputType="text"
                                caption="Template Name"
                                hintText="Current template name"
                                fieldName="name"
                                placeholder="Enter name of template"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>

                        <td width='50%'>
                            <BooleanSelectComponent
                                caption="Template Is Disabled"
                                fieldName="isDisabled"
                                hintText="Current template will not be used if marked as disabled"
                                clearable={false}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td width='50%'>
                            <MultiSelectComponent
                                style={{marginTop:'20px', marginRight:'10px'}}
                                options={paidUnpaid}
                                displayFieldName="paidUnpaid"
                                valueFieldName="paidUnpaid"
                                selectedOptions={selectedPaidUnpaids}
                                onSelectionChanged={(selectedOptions) => 
                                                    {
                                                        let paidUnpaids = [];
                                                        if (selectedOptions.length > 0)
                                                        {
                                                            paidUnpaids = selectedOptions.map(option => option.paidUnpaid);
                                                        }

                                                        this.state.document.constraints.paidUnpaids = paidUnpaids;
                                                        this.setState({});
                                                    }}
                                clearable={true}
                                caption="Applicable Sale Status"
                                hintText="Current template will only be used for selected order status"
                            />
                        </td>
                        <td width='50%'>
                            <MultiSelectComponent
                                style={{marginTop:'10px', marginLeft:'10px'}}
                                options={domainConstants.getDispatchTypes()}
                                displayFieldName="dispatchType"
                                valueFieldName="dispatchType"
                                selectedOptions={selectedDispatchTypes}
                                onSelectionChanged={(selectedOptions) => 
                                    {
                                        let dispatchTypes = [];
                                        if (selectedOptions.length > 0)
                                        {
                                            dispatchTypes = selectedOptions.map(option => option.dispatchType);
                                        }

                                        this.state.document.constraints.dispatchTypes = dispatchTypes;
                                        this.setState({});
                                    }}
                                clearable={true}
                                caption="Applicable Dispatch Types"
                                hintText="Current template will only be used for selected dispatch types"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td width='50%'>
                            <MultiSelectComponent
                                    style={{marginTop:'10px', marginRight:'10px'}}
                                    options={domainConstants.getVenueCodes()}
                                    displayFieldName="venueCode"
                                    valueFieldName="venueCode"
                                    selectedOptions={selectedVenueCodes}
                                    onSelectionChanged={(selectedOptions) => 
                                                        {
                                                            let venueCodes = [];
                                                            if (selectedOptions.length > 0)
                                                            {
                                                                venueCodes = selectedOptions.map(option => option.venueCode);
                                                            }

                                                            this.state.document.constraints.venueCodes = venueCodes;
                                                            this.setState({});
                                                        }}
                                    clearable={true}
                                    caption="Applicable Venues"
                                    hintText="Current template will only be used for selected venues"
                                />
                        </td>
                        <td width='50%'>
                            <MultiSelectComponent
                                style={{marginTop:'10px', marginLeft:'10px'}}
                                options={this.props.storefronts}
                                displayFieldName="name"
                                valueFieldName="identifier"
                                selectedOptions={selectedStorefronts}
                                onSelectionChanged={(selectedOptions) => 
                                    {
                                        let storefronts = [];
                                        if (selectedOptions.length > 0)
                                        {
                                            storefronts = selectedOptions.map(option => option.identifier);
                                        }

                                        this.state.document.constraints.storefronts = storefronts;
                                        this.setState({});
                                    }}
                                clearable={true}
                                caption="Applicable Storefronts"
                                hintText="Current template will only be used for selected storefronts"
                            />
                        </td>
                    </tr>
               </table>
    }
}

export default PrintingTemplate;

