
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import SelectEncodingView from '../selectEncodingView';

const FormManager = require('../../../../../utils/view/formManager.js');

const stationState = require('../../stationState');
const stationUtil = require('../../stationUtil');
const constants = require('../../../../../utils/domain/constants');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');

class LabelPrinter extends Component 
{
    constructor(props)
    {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.printer;
        this.formManager.view = this;

        this.formManager.onFieldChanged = (event) =>
        {
            if (event.target.id == "externalEntity")
            {
                if(event.target.value === "escpos.sunmi.cloud")
                {
                    this.formManager.viewModel.encodingType = constants.printerEncodings.sunmi.encodingType;
                    this.formManager.viewModel.encodingInstructions = constants.printerEncodings.sunmi.encodingInstructions;
                }
                else
                {
                    this.formManager.viewModel.encodingType = "";
                    this.formManager.viewModel.encodingInstructions = "";
                }

                this.setState({});
            }
        }
    }

    render()
    {
        return (<Card>
                    <CardHeader>
                        {this.renderToolbar()}
                    </CardHeader>
                    <CardBody>
                        <div style={{minHeight:window.innerHeight * 0.50}}>
                            {this.renderOptions()}
                        </div>
                    </CardBody>
                </Card>
                );
    }

    renderToolbar()
    {
        return (
                    <table width="100%">
                        <tr>
                            <td>
                                <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Label Printer</div>
                            </td>
                            <td align="right">
                                {
                                    !this.props.isReadOnly &&
                                    <>
                                        <Button color="danger" className="btn-danger" style={{ marginRight: '30px' }} onClick={this.onDelete}>
                                            Delete Printer
                                        </Button>
                                        <Button color="success" className="btn-success" style={{ marginRight: '5px' }} onClick={this.onSave}>
                                            Save
                                        </Button>
                                    </>
                                }
                                <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={()=>{this.props.onClose()}}>
                                    {this.props.isReadOnly ? "Close" : "Cancel"}
                                </Button>
                            </td>
                        </tr>
                    </table>
                );
    }

    renderOptions()
    {
        
        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td >
                                    <SelectComponent
                                        optionValues={this.getPrinterTypes()}
                                        optionFieldName="value"
                                        optionDisplayFieldName="value"
                                        caption="Choose Label Printer Type"
                                        fieldName="externalEntity"
                                        hintText="Select label printer type"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>
                                <td>
                                    <InputComponent
                                        inputType="text"
                                        caption="Printer Name"
                                        fieldName="printerName"
                                        placeholder="Enter printer name"
                                        hintText="Enter printer name (Required)"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />

                                </td>
                            </tr>
                            {this.renderPrinterTypeDetails()}
                        </tbody>
                    </ table>
            </div>
    }

    renderPrinterTypeDetails()
    {
        var components = [];

        if (this.props.printer.externalEntity == null)
            return components;

        if (this.props.printer.externalEntity == "escpos.generic.usb" || 
            this.props.printer.externalEntity == "label.BrotherQL700.usb" ||
            this.props.printer.externalEntity == "label.BrotherQL800.usb" ||
            this.props.printer.externalEntity == "zpl.generic.usb")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="System Name"
                                        fieldName="systemName"
                                        key="systemName"
                                        placeholder="Enter system printer name"
                                        hintText="Enter printer name (as registered in system)"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.generic.bluetooth" ||
           this.props.printer.externalEntity == "label.BrotherQL820.bluetooth")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="Bluetooth Address"
                                        fieldName="bluetoothAddress"
                                        key="bluetoothAddress"
                                        placeholder="Enter bluetooth address"
                                        hintText="Enter bluetooth address"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.generic.network" ||
            this.props.printer.externalEntity == "label.BrotherQL820.network" || 
            this.props.printer.externalEntity == "zpl.generic.network")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="IP Address"
                                        fieldName="ipAddress"
                                        key="ipAddress"
                                        placeholder="Enter IP address"
                                        hintText="Enter IP address"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.sunmi.cloud")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="Serial Number"
                                        fieldName="serialNumber"
                                        key="serialNumber"
                                        placeholder="Enter Serial number"
                                        hintText="Enter Serial number"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.generic.usb" || 
            this.props.printer.externalEntity == "escpos.generic.bluetooth" ||
            this.props.printer.externalEntity == "escpos.sunmi.cloud" ||
            this.props.printer.externalEntity == "escpos.generic.network")
        {
            components.push(
            <>
                <tr>
                    <td >
                        <div style={{ marginTop: "-40px" }}>
                            <SelectEncodingView
                                inputReadOnly={this.props.isReadOnly || this.props.printer.externalEntity == "escpos.sunmi.cloud"}
                                formManager={this.formManager} />
                        </div>
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Is Legacy Printer"
                            fieldName="isLegacyPrinter"
                            hintText="Legacy printers don't support some features like reverse printing"
                            clearable={false}
                            comboReadOnly={this.props.isReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Normal Font Column Size (ESC/POS Font A)"
                            fieldName="normalFontAColumnSize"
                            placeholder="Enter normal font column size"
                            hintText=
                            {
                                <div>
                                    <div>Default = Standard ESC/POS 80mm printer, 48 columns</div>
                                    <div style={{ fontSize: "0.80em" }}>Please specify normal Font A column size if your printer is different from the default</div>
                                </div>
                            }
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Condensed Font Column Size (ESC/POS Font B)"
                            fieldName="condensedFontBColumnSize"
                            placeholder="Enter condensed font column size"
                            hintText=
                            {
                                <div>
                                    <div>Default = Standard ESC/POS 80mm printer, 64 columns</div>
                                    <div style={{ fontSize: "0.80em" }}>Please specify condensed Font B column size if your printer is different from the default</div>
                                </div>
                            }
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>
            </>);
        }

        if (this.props.printer.externalEntity == "zpl.generic.usb" || 
            this.props.printer.externalEntity == "zpl.generic.network")
            {
                components.push(
                    <>
                        <tr>
                            <td >
                                <SelectComponent
                                    optionValues={this.getItemLabelPrintingTemplates()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="value"
                                    caption="Choose Item Label Printing Template"
                                    fieldName="itemLabelPrintingTemplate"
                                    hintText="Select Item Label Printing Template"
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </td>
                            <td >
                                <SelectComponent
                                    optionValues={this.getOrderLabelPrintingTemplates()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="value"
                                    caption="Choose Order Label Printing Template"
                                    fieldName="orderLabelPrintingTemplate"
                                    hintText="Select Order Label Printing Template"
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <SelectComponent
                                    optionValues={this.getKotLabelPrintingTemplates()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="value"
                                    caption="Choose KOT Label Printing Template"
                                    fieldName="kotLabelPrintingTemplate"
                                    hintText="Select KOT Label Printing Template"
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </td>
                            <td >
                                <SelectComponent
                                    optionValues={this.getExpiryLabelPrintingTemplate()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="value"
                                    caption="Choose Expiry Label Printing Template"
                                    fieldName="expiryLabelPrintingTemplate"
                                    hintText="Select Expiry Label Printing Template"
                                    clearable={true}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </td>
                        </tr>
                    </>);
            }

        return components;
    }

    async onSave()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.printer.externalEntity))
        {
            viewUtil.showErrorAlert("Please choose printer type");
            return;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.printer.printerName))
        {
            viewUtil.showErrorAlert("Please enter printer name");
            return;
        }

        if (this.props.printer.externalEntity == "escpos.generic.usb" || 
            this.props.printer.externalEntity == "label.BrotherQL700.usb" ||
            this.props.printer.externalEntity == "label.BrotherQL800.usb")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.systemName))
            {
                viewUtil.showErrorAlert("Please enter system name");
                return;
            }
        }

        if (this.props.printer.externalEntity == "escpos.generic.bluetooth" ||
           this.props.printer.externalEntity == "label.BrotherQL820.bluetooth")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.bluetoothAddress))
            {
                viewUtil.showErrorAlert("Please enter bluetooth address");
                return;
            }
        }

        if (this.props.printer.externalEntity == "escpos.generic.network" ||
            this.props.printer.externalEntity == "label.BrotherQL820.network")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.ipAddress))
            {
                viewUtil.showErrorAlert("Please enter IP address");
                return;
            }
        }

        if (this.props.printer.externalEntity == "escpos.sunmi.cloud")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.serialNumber))
            {
                viewUtil.showErrorAlert("Please enter Serial number");
                return;
            }
        }


        if (!stringUtil.isStringNullOrEmpty(this.props.printer.normalFontAColumnSize) && !validator.isInteger(this.props.printer.normalFontAColumnSize))
        {
            viewUtil.showErrorAlert("Invalid value for 'Normal Font Column Size'");
            return;
        }

        if (!stringUtil.isStringNullOrEmpty(this.props.printer.condensedFontBColumnSize) && !validator.isInteger(this.props.printer.condensedFontBColumnSize))
        {
            viewUtil.showErrorAlert("Invalid value for 'Condensed Font Column Size'");
            return;
        }

        
        var lastError = null;
        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            var shell = stationState.currentStationViewModel.shell;
            var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
            
            if (hardwareTerminal == null)
            {
                hardwareTerminal = stationUtil.createNewHardwareTerminal(stationState.currentStationViewModel);
                await stationUtil.addUpdateTerminal(hardwareTerminal);
                await stationUtil.addUpdateShell(shell);
            }

            var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
            if (hardwareTerminalIntegrations == null)
                hardwareTerminalIntegrations = [];

            hardwareTerminalIntegrations = hardwareTerminalIntegrations.filter(k => k.id != this.props.printer.id);
            
            var integration = stationUtil.createNewIntegration(hardwareTerminal, "LabelPrinterManagement", this.props.printer.externalEntity);
            integration.jsonArray.push({key: 'printerName', value: this.props.printer.printerName});
            integration.jsonArray.push({key: 'systemName', value: this.props.printer.systemName});
            integration.jsonArray.push({key: 'bluetoothAddress', value: this.props.printer.bluetoothAddress});
            integration.jsonArray.push({key: 'ipAddress', value: this.props.printer.ipAddress});
            integration.jsonArray.push({key: 'serialNumber', value: this.props.printer.serialNumber});
            integration.jsonArray.push({key: 'encodingType', value: this.props.printer.encodingType});
            integration.jsonArray.push({key: 'encodingInstructions', value: this.props.printer.encodingInstructions});
            integration.jsonArray.push({key: 'isLegacyPrinter', value: this.props.printer.isLegacyPrinter || false});
            integration.jsonArray.push({key: 'orderLabelPrintingTemplate', value: this.props.printer.orderLabelPrintingTemplate});
            integration.jsonArray.push({key: 'itemLabelPrintingTemplate', value: this.props.printer.itemLabelPrintingTemplate});
            integration.jsonArray.push({key: 'kotLabelPrintingTemplate', value: this.props.printer.kotLabelPrintingTemplate});
            integration.jsonArray.push({key: 'expiryLabelPrintingTemplate', value: this.props.printer.expiryLabelPrintingTemplate});

            if (validator.isInteger(this.props.printer.normalFontAColumnSize))
            {
                integration.jsonArray.push({ key: 'normalFontAColumnSize', value: this.props.printer.normalFontAColumnSize });
            }

            if (validator.isInteger(this.props.printer.condensedFontBColumnSize))
            {
                integration.jsonArray.push({ key: 'condensedFontBColumnSize', value: this.props.printer.condensedFontBColumnSize });
            }

            hardwareTerminalIntegrations.push(integration);
            stationUtil.updateTerminalIntegrations(hardwareTerminal, hardwareTerminalIntegrations);
            await stationState.loadIntegrations();
            await stationState.loadPrinters();
        }
        catch(error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }

        

    }

    async onDelete()
    {
        var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal == null)
        {
            this.props.onClose();
            return;
        }

        var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
        if (hardwareTerminalIntegrations == null || hardwareTerminalIntegrations.length <= 0)
        {
            this.props.onClose();
            return;
        }

        if (hardwareTerminalIntegrations.find(h => stringUtil.areStringSame(h.id, this.props.printer.id)) == null)
        {
            this.props.onClose();
            return;
        }

        var lastError = null;
        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            hardwareTerminalIntegrations = hardwareTerminalIntegrations.filter(k => k.id != this.props.printer.id);
            stationUtil.updateTerminalIntegrations(hardwareTerminal, hardwareTerminalIntegrations);
            await stationState.loadIntegrations();
            await stationState.loadPrinters();

        }
        catch(error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }

    getPrinterTypes()
    {
        var labelPrinterTypes = [];
        labelPrinterTypes.push({value:"escpos.generic.usb"});
        labelPrinterTypes.push({value:"escpos.generic.bluetooth"});
        labelPrinterTypes.push({value:"escpos.generic.network"});

        labelPrinterTypes.push({value:"label.BrotherQL700.usb"});
        labelPrinterTypes.push({value:"label.BrotherQL800.usb"});
        labelPrinterTypes.push({value:"label.BrotherQL820.bluetooth"});
        labelPrinterTypes.push({value:"label.BrotherQL820.network"});

        labelPrinterTypes.push({value:"label.sunmi.v2.pro"});
        labelPrinterTypes.push({value:"escpos.sunmi.cloud"});

        labelPrinterTypes.push({value:"zpl.generic.usb"});
        labelPrinterTypes.push({value:"zpl.generic.network"});

        return labelPrinterTypes;
    }

    getItemLabelPrintingTemplates()
    {
        const printingTemplates = []
        this.props.printingTemplates.forEach(template => 
        {
            if (template.type === constants.printingTemplateTypes.zplItemLabelTemplate)
            {
                printingTemplates.push({value: template.name});
            } 
        })

        return printingTemplates;
    }

    getOrderLabelPrintingTemplates()
    {
        const printingTemplates = [];

        this.props.printingTemplates.forEach(template => 
        {
            if (template.type === constants.printingTemplateTypes.zplOrderLabelTemplate)
            {
                printingTemplates.push({value: template.name});
            } 
        })

        return printingTemplates;
    }

    getKotLabelPrintingTemplates()
    {
        const printingTemplates = [];

        this.props.printingTemplates.forEach(template => 
        {
            if (template.type === constants.printingTemplateTypes.zplKotTemplate)
            {
                printingTemplates.push({value: template.name});
            } 
        })

        return printingTemplates;
    }

    getExpiryLabelPrintingTemplate()
    {
        const printingTemplates = [];

        this.props.printingTemplates.forEach(template => 
        {
            if (template.type === constants.printingTemplateTypes.zplExpiryTemplate)
            {
                printingTemplates.push({value: template.name});
            } 
        })

        return printingTemplates;
    }
}

export default LabelPrinter;