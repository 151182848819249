import React from 'react';
import TabularReport from './fixedCostsSummary/TabularReport';
import ChartReport from './fixedCostsSummary/ChartReport';

export default function FixedCostsSummary(props)
{
    return (
        <div className='p-3 d-flex align-items-start'>
            <div className='ignis-bi-tabular-report'>
                <TabularReport data={props.data} vatCaption={props.vatCaption} />
            </div>
            <div className='ms-2 ignis-bi-chart-report'>
                <ChartReport data={props.data} />
            </div>
        </div>
    );
}
