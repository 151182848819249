
const stateManager = require('../stateManager');
const catalogSelectors = require('./catalogSelectors');
const orgContextUtil = require('../../domain/org/orgContextUtil')
const domainConstants = require('../../domain/constants')

module.exports.selectCurrentOrgNode = function ()
{
    return stateManager.getStore().getState().currentOrgNode;
}

module.exports.selectCurrentOrgContext = function ()
{
    if (this.isCurrentSystemSelected()) return null;

    const currentOrgNode = this.selectCurrentOrgNode();

    return {
        franchisorId: currentOrgNode.franchisorId,
        franchiseeId: currentOrgNode.franchiseeId,
        propertyId: currentOrgNode.propertyId
    };
}

module.exports.selectAllPropertiesOrgContexts = function ()
{
    if (this.isCurrentSystemSelected()) return null;
    var propertyIds = this.selectAllProperties().map(property => property.id);

    return (propertyIds && propertyIds.length > 0) ? orgContextUtil.getOrgContextsFromPropertyIds(propertyIds) : [];
}

module.exports.isCurrentSystemSelected = function ()
{
    var currentOrgNode = this.selectCurrentOrgNode();
    if (currentOrgNode == null)
        return true;

    return currentOrgNode.franchisorId == null &&
        currentOrgNode.franchiseeId == null &&
        currentOrgNode.propertyId == null;
}

module.exports.isCurrentFranchisorSelected = function ()
{
    var currentOrgNode = this.selectCurrentOrgNode();
    if (currentOrgNode == null)
        return false;

    return currentOrgNode.franchisorId != null &&
        currentOrgNode.franchiseeId == null &&
        currentOrgNode.propertyId == null;
}

module.exports.isCurrentFranchiseeSelected = function ()
{
    var currentOrgNode = this.selectCurrentOrgNode();
    if (currentOrgNode == null)
        return false;

    return currentOrgNode.franchisorId != null &&
        currentOrgNode.franchiseeId != null &&
        currentOrgNode.propertyId == null;
}

module.exports.isCurrentPropertySelected = function ()
{
    var currentOrgNode = this.selectCurrentOrgNode();
    if (currentOrgNode == null)
        return false;

    return currentOrgNode.franchisorId != null &&
        currentOrgNode.franchiseeId != null &&
        currentOrgNode.propertyId != null;
}


module.exports.selectCurrentFranchisor = function ()
{
    if (!this.isCurrentFranchisorSelected())
        return null;

    return catalogSelectors.selectFranchisor(this.selectCurrentOrgNode().franchisorId);
}

module.exports.selectCurrentFranchisee = function ()
{
    if (!this.isCurrentFranchiseeSelected())
        return null;

    return catalogSelectors.selectFranchisee(this.selectCurrentOrgNode().franchiseeId);
}

module.exports.selectFirstAvailableFranchisee = function ()
{
    if (this.isCurrentSystemSelected()) return null;

    let currentOrgNode = this.selectCurrentOrgNode()
    return catalogSelectors.selectFranchisees().find(franchisee => franchisee.franchisorId == currentOrgNode.franchisorId)
}

module.exports.selectCurrentProperty = function ()
{
    if (!this.isCurrentPropertySelected())
        return null;

    return catalogSelectors.selectProperty(this.selectCurrentOrgNode().propertyId);
}

module.exports.selectAllProperties = function ()
{
    // Returns all catalog properties by currentOrgNode

    const currentOrgNode = this.selectCurrentOrgNode();

    if (currentOrgNode.propertyId != null)
    {
        return catalogSelectors.selectProperties().filter(property => property.id == currentOrgNode.propertyId);
    }
    else if (currentOrgNode.franchiseeId != null)
    {
        return catalogSelectors.selectProperties().filter(property => property.franchiseeId == currentOrgNode.franchiseeId);
    }
    else if (currentOrgNode.franchisorId != null)
    {
        return catalogSelectors.selectProperties().filter(property => property.franchisorId == currentOrgNode.franchisorId);
    }
    else
    {
        return [];
    }
}

module.exports.selectAllowedOrgNodes = function ()
{
    const allowedOrgNodes = {
        franchisors: [],
        franchisees: [],
        properties: []
    };

    if (this.isCurrentSystemSelected())
    {
        // All nodes are accessible at system node

        allowedOrgNodes.franchisors.push.apply(allowedOrgNodes.franchisors, catalogSelectors.selectFranchisors());
        allowedOrgNodes.franchisees.push.apply(allowedOrgNodes.franchisees, catalogSelectors.selectFranchisees());
        allowedOrgNodes.properties.push.apply(allowedOrgNodes.properties, catalogSelectors.selectProperties());

        return allowedOrgNodes;
    }

    if (this.isCurrentFranchisorSelected())
    {
        const currentFranchisor = this.selectCurrentFranchisor();

        // Entire franchisor branch is accessible at a franchisor node

        allowedOrgNodes.franchisors.push(currentFranchisor);
        allowedOrgNodes.franchisees.push.apply(allowedOrgNodes.franchisees,
            catalogSelectors.selectFranchisees().filter(franchisee => franchisee.franchisorId == currentFranchisor.id));
        allowedOrgNodes.properties.push.apply(allowedOrgNodes.properties,
            catalogSelectors.selectProperties().filter(property => property.franchisorId == currentFranchisor.id));

        return allowedOrgNodes;
    }

    if (this.isCurrentFranchiseeSelected())
    {
        const currentFranchisee = this.selectCurrentFranchisee();

        // Entire franchisee branch is accessible at a franchisee node

        allowedOrgNodes.franchisees.push(currentFranchisee);
        allowedOrgNodes.properties.push.apply(allowedOrgNodes.properties,
            catalogSelectors.selectProperties().filter(property => property.franchiseeId == currentFranchisee.id));

        return allowedOrgNodes;
    }

    // Only property is accessible at a property node
    allowedOrgNodes.properties.push(this.selectCurrentProperty());
    return allowedOrgNodes;
}
