import React from 'react';

import SalesSummary from './components/salesSummary/SalesSummary';
import SalesByProperty from './components/salesByProperty/SalesByProperty';
import SalesByStorefrontType from './components/salesByStorefrontType/SalesByStorefrontType';
import SalesByVenueCode from './components/salesByVenueCode/SalesByVenueCode';
import SalesByDispatchType from './components/salesByDispatchType/SalesByDispatchType';
import SalesByPaymentMethod from './components/salesByPaymentMethod/SalesByPaymentMethod';
import DiscountsByVenueCode from './components/discountsByVenue/DiscountsByVenueCode';
import ChargesByVenueCode from './components/chargesByVenue/ChargesByVenueCode';
import RefundsByVenueCode from './components/refundsByVenue/RefundsByVenueCode';

export default function SalesOverviewSummary(props)
{
    const data = props.data;

    return (
        <div className='mt-2 d-flex flex-column'>
            <div className='mb-4 shadow-sm'><SalesSummary data={data} /></div>
            <div className='mb-4 shadow-sm'><SalesByProperty data={data} index={0} /></div>
            <div className='mb-4 shadow-sm'><SalesByStorefrontType data={data} index={1} /></div>
            <div className='mb-4 shadow-sm'><SalesByVenueCode data={data} index={2} /></div>
            <div className='mb-4 shadow-sm'><SalesByDispatchType data={data} index={3} /></div>
            <div className='mb-4 shadow-sm'><SalesByPaymentMethod data={data} index={4} /></div>
            <div className='mb-4 shadow-sm'><DiscountsByVenueCode data={data} index={5} /></div>
            <div className='mb-4 shadow-sm'><ChargesByVenueCode data={data} index={6} /></div>
            <div className='mb-4 shadow-sm'><RefundsByVenueCode data={data} index={7} /></div>
        </div>
    );
}