import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridView from '../../../../../components/gridView/gridView';
import KitchenStationProfile from './KitchenStationProfile';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../../components/gridView/gridViewOptions');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const typeUtil = require('../../../../../utils/type/typeUtil');

const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const orgLookupAction = require('../../../../../utils/state/actions/orgData/lookup/lookupAction');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');


class KitchenStationProfiles extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadKitchenStationProfiles = this.loadKitchenStationProfiles.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Kitchen Station Profiles";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "rows";
        gridViewOptions.getRowData = this.loadKitchenStationProfiles;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () => { const newRow = { franchisorId: currentOrgNode.franchisorId }; return newRow; };

        gridViewOptions.getComponent = (isNew, row) =>
            <KitchenStationProfile
                data={row}
                kitchenStationProfiles={this.props.kitchenStationProfiles} />;

        gridViewOptions.getComponentTitle =
            (isNewRow, row, customButton) =>
                isNewRow ? "Add New Kitchen Station Profile" : `Edit Kitchen Station Profile: ${row.stationName}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new kitchen station profile.";

            return "Please wait while updating kitchen station profile.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New kitchen station profile added successfully";

            return "Kitchen station profile updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new kitchen station profile:";

            return "Following error occurred while updating kitchen station profile:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    loadKitchenStationProfiles()
    {
        let stationProfiles = typeUtil.deepCloneObject(this.props.kitchenStationProfiles);
        if (stationProfiles == null) stationProfiles = [];
        
        var profiles = [];

        stationProfiles.forEach(profile => 
        {
            profiles.push(this.enrichRowForDisplay(profile));
        })

        return arraySort(profiles, stringUtil.localeCompareProp("stationName"));
    }

    enrichRowForDisplay(stationProfile)
    {
        if(stationProfile.dependentStations.length > 0)
        {
            var stations = []

            stationProfile.dependentStations.forEach(stationId => {
                var dependentStation = this.props.kitchenStationProfiles.find(profile => profile.id == stationId);
                if(dependentStation) stations.push(dependentStation.stationName);
            })

            stationProfile.enrichedDependentStations = stations;
        }

        return stationProfile;
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.stationName))
            return "Station name cannot be left empty";

        if (!validator.isPresent(row.stationType))
            return "Station type cannot be left empty";

        return null;
    }

    async save(isNew, row)
    {
        try
        {
            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/kitchenStationProfiles`;
            let updatedStationProfile = await rmsApiProxy.post(resourceUrl, row);

            let stationProfiles = this.loadKitchenStationProfiles();
            if (stationProfiles == null) stationProfiles = [];

            const existingIndex = stationProfiles.findIndex(stationProfile => stationProfile.id == updatedStationProfile.id);
            updatedStationProfile = this.enrichRowForDisplay(updatedStationProfile);

            if (existingIndex > -1)
            {
                stationProfiles.splice(existingIndex, 1, updatedStationProfile);
            }
            else
            {
                stationProfiles.push(updatedStationProfile);
            }

            const orgState = orgStateSelectors.selectCurrentOrgState();
            actionDispatcher.dispatch(orgLookupAction.createUpdateAction(orgState, "kitchenStationProfiles", stationProfiles));

            return updatedStationProfile;

        } catch (error)
        {
            throw error
        }
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "stationName";
        header.headerName = "Station Name";
        header.pinned = true;
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Name of the station";
        headers.push(header);

        header = {};
        header.field = "stationType";
        header.headerName = "Station Type";
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Type of the station";
        headers.push(header);

        header = {};
        header.field = "dispatchTypes";
        header.headerName = "Dispatch Types";
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Dispatch types catered by the station";
        headers.push(header);


        header = {};
        header.field = "stationTags";
        header.headerName = "Station Tags";
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Tags associated with the station";
        headers.push(header);

        header = {};
        header.field = "enrichedDependentStations";
        header.headerName = "Dependent Stations";
        header.width = columnWidthConstants.code;
        header.headerTooltip = "Stations on which this station is depending";
        headers.push(header);

        header = {};
        header.field = "isFinalStation";
        header.headerName = "Is Final Station";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean + 25;
        headers.push(header);

        header = {};
        header.field = "isUnlinked";
        header.headerName = "Is Unlinked";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean + 25;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

const mapStateToProps = state => 
{
    return {
        kitchenStationProfiles: orgStateSelectors.selectCurrentOrgLookupData().kitchenStationProfiles,
    }
}

export default connect(mapStateToProps)(KitchenStationProfiles)
