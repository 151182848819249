// State dependency removed: stockItems


const arraySort = require('array-sort');

const stringUtil = require('../string/stringUtil');
const domainConstants = require('../domain/constants');

const coreUnitCodes = {
    kgs: "Kgs",
    gms: "Gms",
    lts: "Lts",
    mls: "Mls",
    pcs: "Pcs",
    dozens: "Dozens"
}

module.exports.unitTypes = {
    weight: "Weight",
    volume: "Volume",
    quantity: "Quantity"
}

module.exports.getUnitTypes = function ()
{
    return Object
        .keys(this.unitTypes)
        .sort()
        .map(key => (
            {
                code: this.unitTypes[key]
            }));
}

module.exports.getCoreUnitCodes = function (unitTypeCode)
{
    switch (unitTypeCode)
    {
        case this.unitTypes.weight:
            return [
                { id: coreUnitCodes.gms, code: coreUnitCodes.gms },
                { id: coreUnitCodes.kgs, code: coreUnitCodes.kgs },
            ]
        case this.unitTypes.volume:
            return [
                { id: coreUnitCodes.mls, code: coreUnitCodes.mls },
                { id: coreUnitCodes.lts, code: coreUnitCodes.lts },
            ]
        case this.unitTypes.quantity:
            return [
                { id: coreUnitCodes.pcs, code: coreUnitCodes.pcs },
                { id: coreUnitCodes.dozens, code: coreUnitCodes.dozens },
            ]
        default:
            return [];
    }
}

module.exports.getCoreUnitsForUnitType = function (unitTypeCode, coreUnitCode)
{
    switch (unitTypeCode)
    {
        case this.unitTypes.weight:
            return getCoreUnitsForWeight(coreUnitCode);
        case this.unitTypes.volume:
            return getCoreUnitsForVolume(coreUnitCode);
        case this.unitTypes.quantity:
            return getCoreUnitsForQuantity(coreUnitCode);
        default:
            throw `getCoreUnitsForUnitType: Invalid switch case: ${unitTypeCode}`;
    }
}

module.exports.getPossibleUnitsForStockItemId = function (stockItemId, stockItemsLookupData)
{


    if (stringUtil.isStringNullOrEmpty(stockItemId))
        return [];

    const stockItem = stockItemsLookupData.find(s => s.id == stockItemId);

    return this.getPossibleUnitsForStockItem(stockItem)
}

module.exports.getPossibleUnitsForStockItem = function (stockItem)
{
    const units = [];

    if (!stringUtil.isStringNullOrEmpty(stockItem.unitTypeCode))
    {
        //Get Core Units
        units.push.apply(units, this.getCoreUnitsForUnitType(stockItem.unitTypeCode, stockItem.coreUnitCode));

        // Get Supplemental Core Units
        units.push.apply(units, getSupplementalCoreUnitsForUnitType(stockItem.unitTypeCode));
    }

    //Get Custom Units
    units.push.apply(units, stockItem.unitTypes);

    return units;
}

module.exports.getAllUnitTypesByOperationType = function (stockItem, operationTypeCode)
{
    // Code copied from kitchen app
    
    const allPossibleUnitTypes = this.getPossibleUnitsForStockItem(stockItem);

    const operationalUnitTypeIds =
        stockItem.operationalUnitTypes
            .filter(unitType => unitType.operationTypeCode == operationTypeCode)
            .map(unitType => unitType.unitTypeId);

    if (operationalUnitTypeIds.length == 0)
    {
        // Use core unit if no operational units are defined for the operationTypeCode
        operationalUnitTypeIds.push(allPossibleUnitTypes.find(unitType => unitType.id == stockItem.coreUnitCode).id);
    }

    const operationalUnitTypes = allPossibleUnitTypes.filter(unitType => operationalUnitTypeIds.includes(unitType.id));

    return arraySort(operationalUnitTypes, "code");
}

module.exports.getUnitTypeByOperationType = function (stockItem, operationTypeCode)
{
    const allPossibleUnitTypes = this.getPossibleUnitsForStockItem(stockItem);

    let operationalUnitType = stockItem.operationalUnitTypes.find(unitType => unitType.operationTypeCode == operationTypeCode);

    if (operationalUnitType == null)
    {
        return allPossibleUnitTypes.find(unitType => unitType.id == stockItem.coreUnitCode);
    }

    return allPossibleUnitTypes.find(unitType => unitType.id == operationalUnitType.unitTypeId);
}

module.exports.getUnitTypeByReportingType = function (stockItem, reportingTypeCode)
{
    //
    // Note: reportingTypeCode == stockItemOperationTypes.operationTypeCode
    // Unlike getUnitTypeByOperationType, this method attempts to fall back to stockItemOperationTypes.reporting unit
    // if unit for reportingTypeCode is not available and only then returns stockItem.coreUnitCode
    //
    
    const allPossibleUnitTypes = this.getPossibleUnitsForStockItem(stockItem);

    let reportingUnitType = stockItem.operationalUnitTypes.find(unitType => unitType.operationTypeCode === reportingTypeCode);

    if (reportingUnitType == null && reportingTypeCode !== domainConstants.stockItemOperationTypes.reporting) 
    {
        // Try to fall back to generic stockItemOperationTypes.reporting unit
        reportingUnitType = stockItem.operationalUnitTypes.find(unitType => unitType.operationTypeCode === domainConstants.stockItemOperationTypes.reporting);
    }

    if (reportingUnitType == null)
    {
        // Fall back to coreUnit
        return allPossibleUnitTypes.find(unitType => unitType.id == stockItem.coreUnitCode);
    }

    return allPossibleUnitTypes.find(unitType => unitType.id == reportingUnitType.unitTypeId);
}

module.exports.getPossibleUnitForStockItemSupplier = function (stockItemId, supplierId, stockItemsLookupData, allUnits)
{


    if (stringUtil.isStringNullOrEmpty(stockItemId) || stringUtil.isStringNullOrEmpty(supplierId))
        return [];

    const stockItem = stockItemsLookupData.find(s => s.id === stockItemId);

    const stockItemSupplier = stockItem.suppliers.find(supplier => supplier.supplierId === supplierId);
    if (!stockItemSupplier) return [];

    //
    // Exclude any supplierUnitType for which there does not exist a corresponding stockItemUnitType
    //

    const stockItemUnitTypes =
        this.getPossibleUnitsForStockItemId(stockItemId, stockItemsLookupData)
            .filter(unit => !unit.isSupplementalUnit);

    return stockItemSupplier.unitTypes.filter(
            supplierUnitType => ((!supplierUnitType.isDisabled || allUnits) && stockItemUnitTypes.some(unitType => unitType.id === supplierUnitType.unitTypeId)));
}

module.exports.convertFromStockItemCoreUnits = function (stockItem, toUnitTypeId, quantityInCoreUnits)
{
    const unitTypes = this.getPossibleUnitsForStockItem(stockItem);
    return convertFromCoreUnits(unitTypes, toUnitTypeId, quantityInCoreUnits);
}

module.exports.convertToStockItemCoreUnitsFromDefaultUnits = function (stockItem, quantityInDefaultUnits)
{
    const fromUnitTypeId = getDefaultCoreUnitForUnitType(stockItem.unitTypeCode);
    return this.convertToStockItemCoreUnits(stockItem, fromUnitTypeId, quantityInDefaultUnits);
}

module.exports.convertToStockItemCoreUnitsFromDefaultUnitByStockItemId = function (stockItemId, quantityInDefaultUnits, stockItemsLookupData)
{
    const stockItem = stockItemsLookupData.find(s => s.id == stockItemId);
    const fromUnitTypeId = getDefaultCoreUnitForUnitType(stockItem.unitTypeCode);
    return this.convertToStockItemCoreUnitsByStockItemId(stockItemId, fromUnitTypeId, quantityInDefaultUnits, stockItemsLookupData);
}

module.exports.convertToStockItemCoreUnitsByStockItemId = function (stockItemId, fromUnitTypeId, quantityInFromUnits, stockItemsLookupData)
{
    const unitTypes = this.getPossibleUnitsForStockItemId(stockItemId, stockItemsLookupData);
    return convertToCoreUnits(unitTypes, fromUnitTypeId, quantityInFromUnits);
}

module.exports.convertToStockItemCoreUnits = function (stockItem, fromUnitTypeId, quantityInFromUnits)
{
    const unitTypes = this.getPossibleUnitsForStockItem(stockItem);
    return convertToCoreUnits(unitTypes, fromUnitTypeId, quantityInFromUnits)
}

//
// Private helper methods
//

function convertFromCoreUnits(unitTypes, toUnitTypeId, quantityInCoreUnits)
{
    const unitType = unitTypes.find(u => u.id == toUnitTypeId);


    const quantityInToUnit = quantityInCoreUnits / unitType.coreUnitConversionFactor;
    return quantityInToUnit;
}

function convertToCoreUnits(unitTypes, fromUnitTypeId, quantity)
{
    const unitType = unitTypes.find(x => x.id === fromUnitTypeId);

    if (!unitType)
        return NaN;

    const quantityInCoreUnits = quantity * unitType.coreUnitConversionFactor;
    return quantityInCoreUnits;
}

function getCoreUnitsForWeight(coreUnitCode)
{
    switch (coreUnitCode)
    {
        case coreUnitCodes.gms:
            return [
                { id: coreUnitCodes.gms, code: coreUnitCodes.gms, coreUnitConversionFactor: 1 },
                { id: coreUnitCodes.kgs, code: coreUnitCodes.kgs, coreUnitConversionFactor: 1000 },
            ]

        case coreUnitCodes.kgs:
            return [
                { id: coreUnitCodes.kgs, code: coreUnitCodes.kgs, coreUnitConversionFactor: 1 },
                { id: coreUnitCodes.gms, code: coreUnitCodes.gms, coreUnitConversionFactor: 0.001 },
            ]

        default:
            throw `getCoreUnitsForWeight: Invalid switch case: ${coreUnitCode}`;
    }
}

function getCoreUnitsForVolume(coreUnitCode)
{
    switch (coreUnitCode)
    {
        case coreUnitCodes.mls:
            return [
                { id: coreUnitCodes.mls, code: coreUnitCodes.mls, coreUnitConversionFactor: 1 },
                { id: coreUnitCodes.lts, code: coreUnitCodes.lts, coreUnitConversionFactor: 1000 },
            ]

        case coreUnitCodes.lts:
            return [
                { id: coreUnitCodes.lts, code: coreUnitCodes.lts, coreUnitConversionFactor: 1 },
                { id: coreUnitCodes.mls, code: coreUnitCodes.mls, coreUnitConversionFactor: 0.001 },
            ]

        default:
            throw `getCoreUnitsForVolume: Invalid switch case: ${coreUnitCode}`;
    }
}

function getCoreUnitsForQuantity(coreUnitCode)
{
    switch (coreUnitCode)
    {
        case coreUnitCodes.pcs:
            return [
                { id: coreUnitCodes.pcs, code: coreUnitCodes.pcs, coreUnitConversionFactor: 1 },
                { id: coreUnitCodes.dozens, code: coreUnitCodes.dozens, coreUnitConversionFactor: 12 },
            ]

        case coreUnitCodes.dozens:
            return [
                { id: coreUnitCodes.dozens, code: coreUnitCodes.dozens, coreUnitConversionFactor: 1 },
                { id: coreUnitCodes.pcs, code: coreUnitCodes.pcs, coreUnitConversionFactor: 1 / 12 },
            ]

        default:
            throw `getCoreUnitsForQuantity: Invalid switch case: ${coreUnitCode}`;
    }
}

function getSupplementalCoreUnitsForUnitType(unitTypeCode)
{
    //
    // Returns supplemental units that are dummy units use to pad unitTypes for a stockItem to handle cases
    // resulting from post-change in stockItem unitTypeCode (e.g from quantity/pcs to weight/gms)
    //

    switch (unitTypeCode)
    {
        case module.exports.unitTypes.quantity:
            return [
                { id: coreUnitCodes.gms, code: coreUnitCodes.gms, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.kgs, code: coreUnitCodes.kgs, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.mls, code: coreUnitCodes.mls, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.lts, code: coreUnitCodes.lts, coreUnitConversionFactor: 1, isSupplementalUnit: true }
            ];
        case module.exports.unitTypes.weight:
            return [
                { id: coreUnitCodes.pcs, code: coreUnitCodes.pcs, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.dozens, code: coreUnitCodes.dozens, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.mls, code: coreUnitCodes.mls, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.lts, code: coreUnitCodes.lts, coreUnitConversionFactor: 1, isSupplementalUnit: true }
            ];
        default:    // module.exports.unitTypes.volume
            return [
                { id: coreUnitCodes.pcs, code: coreUnitCodes.pcs, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.dozens, code: coreUnitCodes.dozens, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.gms, code: coreUnitCodes.gms, coreUnitConversionFactor: 1, isSupplementalUnit: true },
                { id: coreUnitCodes.kgs, code: coreUnitCodes.kgs, coreUnitConversionFactor: 1, isSupplementalUnit: true }
            ];
    }
}



function convertToUnitTypeDefaultUnitForStockItem  (stockItem, fromUnitTypeId, quantityInFromUnits)
{
    const quantityInStockItemCoreUnits = this.convertToStockItemCoreUnits(stockItem, fromUnitTypeId, quantityInFromUnits);

    return this.convertToDefaultCoreUnitFromCoreUnit(stockItem.unitTypeCode, quantityInStockItemCoreUnits, stockItem.coreUnitCode)
}

function convertToDefaultCoreUnitFromCoreUnit (unitTypeCode, quantity, coreUnitCode)
{
    switch (unitTypeCode)
    {
        case module.exports.unitTypes.weight:
            switch (coreUnitCode)
            {
                case coreUnitCodes.gms:
                    return quantity;
                case coreUnitCodes.kgs:
                    return quantity * 1000;
                default:
                    throw `convertToDefaultCoreUnitFromCoreUnit unitCode: Invalid switch case: ${coreUnitCode}`;
            }
        case module.exports.unitTypes.volume:
            switch (coreUnitCode)
            {
                case coreUnitCodes.mls:
                    return quantity;
                case coreUnitCodes.lts:
                    return quantity * 1000;
                default:
                    throw Error(`convertToDefaultCoreUnitFromCoreUnit unitCode: Invalid switch case: ${coreUnitCode}`);
            }
        case module.exports.unitTypes.quantity:
            switch (coreUnitCode)
            {
                case coreUnitCodes.pcs:
                    return quantity;
                case coreUnitCodes.dozens:
                    return quantity * 12;
                default:
                    throw Error(`convertToDefaultCoreUnitFromCoreUnit unitCode: Invalid switch case: ${coreUnitCode}`);
            }
        default:
            throw Error(`convertToDefaultCoreUnitFromCoreUnit unitTypeCode: Invalid switch case: ${unitTypeCode}`);
    }
}

function getDefaultCoreUnitForUnitType (unitTypeCode)
{
    switch (unitTypeCode)
    {
        case module.exports.unitTypes.weight:
            return coreUnitCodes.gms;
        case module.exports.unitTypes.volume:
            return coreUnitCodes.mls
        case module.exports.unitTypes.quantity:
            return coreUnitCodes.pcs;
        default:
            throw Error(`getDefaultCoreUnitForUnitType: Invalid switch case: ${unitTypeCode}`);
    }
}