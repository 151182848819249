import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

const localizationUtils = require('../../../../../../utils/domain/localizationUtils');
const securityManager = require('../../../../../../utils/domain/security/securityManager');

function FranchisorOverview(props) {

    let systemUserOptions = null;
    if (securityManager.isSystemAdmin() || securityManager.isSupportAdmin() || securityManager.isSupportElevatedUser())
    {
        systemUserOptions = <>
            <tr>
                <td>
                    <BooleanSelectComponent
                        caption="Is Enabled"
                        fieldName="isEnabled"
                        comboReadOnly={ false }
                        hintText="Is Franchisor Enabled"
                        clearable={ false }
                        formManager={ props.formManager } />
                </td>
                <td>
                    <BooleanSelectComponent
                        caption="Display Flipdish Branding"
                        fieldName="displayResellerBranding"
                        comboReadOnly={ false }
                        hintText="Display Flipdish Branding"
                        clearable={ false }
                        formManager={ props.formManager } />
                </td>
            </tr>
            {props.isCurrentSystemSelected && 
                <tr>
                    <td>
                        <InputComponent 
                            fieldName="appId"
                            caption="App Id"
                            hintText="App Id to be linked with the default brand (Required)"
                            placeholder="Enter App Id"
                            formManager={props.formManager}
                        />
                    </td>
                </tr>
            }
        </>;

    }

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent inputType="name" caption="Franchisor Name" hintText="Required"
                            fieldName="name" placeholder="Enter name of franchisor"
                            inputReadOnly={securityManager.isSupportUser() ? !securityManager.isSupportElevatedUser() : false}
                            formManager={ props.formManager } />
                    </td>
                    <td />
                </tr>

                <tr>
                    <td>
                        <InputComponent inputType="email" caption="Email Address" hintText="Required"
                            fieldName="emailAddress" placeholder="Enter franchisor's email address"
                            formManager={ props.formManager } />
                    </td>
                    <td>
                        <SelectComponent
                            caption="Country"
                            fieldName="country"
                            optionFieldName="name"
                            optionDisplayFieldName="name"
                            optionValues={ localizationUtils.getCountries() }
                            hintText="Country of operation (Required)"
                            clearable={ false }
                            comboReadOnly={ !props.isCurrentSystemSelected }
                            formManager={ props.formManager } />
                    </td>
                </tr>

                { systemUserOptions }

                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="text" multiLine={ true } caption="Notes" rows={ 4 }
                            fieldName="notes" placeholder="Enter any notes."
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>

    )

}
export default FranchisorOverview;