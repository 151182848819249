import React, { Component } from 'react';

import InputComponent from '../../../../components/form/inputComponent'
import SelectComponent from '../../../../components/form/selectComponent'

const FormManager = require('../../../../utils/view/formManager');

class SubCategory extends Component 
{

    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    getCategoryOptions()
    {
        const categories = this.props.categories.map(category => (
            {
                id: category.id,
                name: category.name,
            }));
        return categories;
    }

    render() 
    {
        return (
            <table className="component-table">

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Sub Category Name"
                            hintText="Required"
                            fieldName="name"
                            placeholder="Enter name of accounts sub category"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.state.formManager} />
                    </td>

                    <td>
                        <SelectComponent
                            optionValues={this.getCategoryOptions()}
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            fieldName="categoryId"
                            caption="Category"
                            hintText="Select type of accounts category" hintFieldName="notes"
                            clearable={false}
                            searchable={false}
                            comboReadOnly={this.props.isRowReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            multiLine={true}
                            caption="Notes"
                            rows={4}
                            fieldName="notes"
                            placeholder="Enter any notes."
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                </tr>

            </table>
        );
    }
}

export default SubCategory;

