import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from 'reactstrap';
import CampaignExecutionReport from '../CampaignExecutionReport';
import CampaignSummary from '../CampaignSummary';

const guidUtil = require('../../../../../../utils/guid/guidUtil');
const typeUtil = require('../../../../../../utils/type/typeUtil');
const FormManager = require('../../../../../../utils/view/formManager');

export default class ExistingCampaign extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.onRerunCampaign = this.onRerunCampaign.bind(this);
    }

    onRerunCampaign()
    {
        const newRow = typeUtil.deepCloneObject(this.props.data);
        newRow.id = guidUtil.generateGuid();
        newRow.aborted = false;
        newRow.actualStartDateTime = null;

        this.props.onClose();
        this.props.onClone(newRow);
    }

    render()
    {
        const campaign = this.props.data;


        var tabHeaders = [];
        tabHeaders.push(<Tab>Overview</Tab>)
        tabHeaders.push(<Tab>Execution Report</Tab>)

        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}><CampaignSummary data={campaign} isNew={false} ></CampaignSummary></TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}><CampaignExecutionReport data={this.props.data}></CampaignExecutionReport></TabPanel>);

        return <Modal open={!this.state.showAbortCampaign} onClose={this.props.onClose} center showCloseIcon={true}
            classNames={{
                overlay: 'customOverlay',
                modal: 'customModal',
            }}>
            {this.renderHeader()}
            <hr></hr>

            <Tabs>
                <TabList>
                    {tabHeaders}
                </TabList>

                {tabContents}
            </Tabs>
            <hr></hr>
            {this.renderFooter()}
        </Modal>
    }

    renderHeader()
    {
        return <table width="100%">
            <tr>
                <td align="left">
                    <h2 className="text-muted">{this.props.data.name}</h2>
                </td>

            </tr>
        </table>
    }

    renderFooter()
    {
        const abortDisabled = this.props.data.aborted;

        const buttonsPanel = <div>
            <Button color="primary" className="btn-primary" style={{ marginRight: '5px' }} onClick={this.onRerunCampaign} disabled={this.props.isReadOnly}>
                Rerun Campaign
            </Button>
            <Button disabled={abortDisabled || this.props.isReadOnly} color="primary" className="btn-secondary" style={{ marginRight: '10px' }} onClick={this.props.onAbortCampaign}>
                Abort Campaign
            </Button>
        </div>
        return <table width="100%">
            <tr>
                <td align="right">
                    {buttonsPanel}
                </td>
            </tr>
        </table>
    }
}
