import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';

const domainConstants = require('../../../../../../utils/domain/constants');

export default function FranchisorSMSConfigurationComponent(props)
{

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <SelectComponent
                            caption="SMS Provider"
                            fieldName="smsProvider"
                            optionFieldName="value"
                            optionDisplayFieldName="value"
                            optionValues={domainConstants.getSmsProviders()}
                            hintText="Required"
                            placeholder="Choose SMS Provider"
                            clearable={true}
                            formManager={ props.formManager } />
                    </td>

                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="smsFrom"
                            placeholder="SMS Sender name"
                            caption="SMS Sender name"
                            hintText="Sender name to use when sending SMS"
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
