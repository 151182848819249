import arraySort from 'array-sort';

import * as sortingUtil from '../../../../../../../utils/sorting/sortingUtil';

export function getMasterDataRows({ salesData })
{
    const masterRows = [];

    salesData.forEach(data =>
    {
        let row = masterRows.find(row => row.category === data.category && row.caption === data.caption);

        if (row == null)
        {
            masterRows.push({ ...data });
            return;
        }

        row.quantity += data.quantity;
        row.retailPrice += data.retailPrice;
    });

    return arraySort(masterRows, sortingUtil.getNumericComparer("quantity"));
}

export function getSalesByPropertyDetailDataRows(masterRow, { salesData })
{
    const { category, caption } = masterRow;

    const detailRows = [];

    salesData
        .filter(data => data.category === category && data.caption === caption)
        .forEach(data =>
        {
            const row = detailRows.find(row => row.propertyId === data.propertyId);

            if (row == null)
            {
                detailRows.push({ ...data });
                return;
            }

            row.quantity += data.quantity;
            row.retailPrice += data.retailPrice;
        });

    return arraySort(detailRows, "propertyName");
}

export function getSalesByVenueDetailDataRows(masterRow, { salesData })
{
    const { propertyId, category, caption } = masterRow;

    const detailRows = [];

    salesData
        .filter(data => data.propertyId === propertyId && data.category === category && data.caption === caption)
        .forEach(data =>
        {
            const row = detailRows.find(row => row.venueCode === data.venueCode);

            if (row == null)
            {
                detailRows.push({ ...data });
                return;
            }

            row.quantity += data.quantity;
            row.retailPrice += data.retailPrice;
        });

    return arraySort(detailRows, "venueCode");
}

export function getChartDataRows({ salesData })
{
    const chartDataRows = [];

    salesData.forEach(data =>
    {
        let row = chartDataRows.find(row => row.category === data.category && row.caption === data.caption);

        if (row == null)
        {
            chartDataRows.push({ ...data });
            return;
        }

        row.quantity += data.quantity;
        row.retailPrice += data.retailPrice;
    });

    return arraySort(chartDataRows, sortingUtil.getNumericComparer("quantity"));
}
