import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';

import InputComponent from '../../../components/form/inputComponent';
import BooleanSelectComponent from '../../../components/form/booleanSelectComponent';
import ErrorMessageComponent from '../../../components/error/errorMessage';
import OperativeVatBands from './components/OperativeVatBands';
import * as auditLogManager from '../../../utils/audits/auditLogManager';

const FormManager = require('../../../utils/view/formManager');
const viewUtil = require('../../../utils/view/viewUtil');
const stringUtil = require('../../../utils/string/stringUtil');
const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const validator = require('../../../utils/validator/validator');
const actionDispatcherUtil = require('../../../utils/state/actions/actionDispatcherUtil');
const orgSelectors = require('../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const helperUtils = require('./utils/helperUtils');
const auditConstants = require('../../../utils/constants/auditConstants');
const commonUtility = require('../../../utils/domain/commonUtility');
const constants = require('../../../utils/domain/constants');

export default class LocalizationSettingOverrides extends Component
{
    //
    // This single component serves localization setting overrides for both franchisor and property nodes
    //

    constructor(props)
    {
        super(props);

        this.state = {
            isLoading: true,
            errorMessage: null,
            vatCaption: null
        };

        this.state.operativeVatBands = [];
        this.setOperativeVatBands = this.setOperativeVatBands.bind(this);
    }

    async componentDidMount() 
    {
        viewUtil.showSystemModalSpinner("Loading localization settings, please wait");

        try
        {
            const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/localizations`;
            const resolvedLocalizationSettings = await rmsApiProxy.get(resourceUrl);

            // Draw override-able settings from resolvedLocalizationSettings

            const overrideSettings = {
                isVatApplicable: Boolean(resolvedLocalizationSettings.isVatApplicable),
                isVatInclusive: Boolean(resolvedLocalizationSettings.isVatInclusive),
                vatPercentage: Number(resolvedLocalizationSettings.vatPercentage) || 0,
                vatCaption: resolvedLocalizationSettings.vatCaption || "",
                doPrintUnitPriceInSaleReceipt: Boolean(resolvedLocalizationSettings.doPrintUnitPriceInSaleReceipt)
            };

            this.formManager = new FormManager();
            this.formManager.viewModel = overrideSettings;
            this.formManager.view = this;

            this.setState({ isLoading: false, operativeVatBands: resolvedLocalizationSettings.operativeVatBands, vatCaption: overrideSettings.vatCaption});
        }
        catch (error)
        {
            const errorMessage = 'Oops !! Following error occurred while loading localization settings:\n\n' + error.toString();
            this.setState({ isLoading: false, errorMessage: errorMessage });
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onSave = async () => 
    {
        const operativeVatBands = this.state.operativeVatBands.map((vatBand) => parseFloat(vatBand));
        const overrides = {...this.formManager.viewModel, operativeVatBands: operativeVatBands || [] };

        const validationMessage = this.validateOverrides(overrides);
        if (validationMessage != null) 
        {
            viewUtil.showErrorAlert(validationMessage);
            return;
        }

        viewUtil.showSystemModalSpinner('Saving data, please wait ...')

        try
        {
            await helperUtils.save(overrides);
            viewUtil.showSuccessAlert('Data saved successfully');

            const { propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();

            if (propertyId === null)
            {
                await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.configuration, 
                                                            'Franchisor Localization Settings have been altered', 
                                                            overrides);
            }
            else
            {
                await auditLogManager.addPropertyAuditLog(auditConstants.action.property.configuration, 
                                                          'Property Localization Settings have been altered', 
                                                          overrides);
            }

            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(`Failed to save data: ${error.toString()}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onCancel = () => 
    {
        actionDispatcherUtil.showOptionsUI();
    }

    validateOverrides = (overrides) =>
    {
        if (!(validator.isGreaterThanOrEqualToZero(overrides.vatPercentage) && validator.isLessThan(overrides.vatPercentage, 100)))
        {
            return "VAT percentage is invalid, must be between 0 and 100";
        }

        if (!validator.isPresent(overrides.vatCaption)) 
        {
            return "VAT caption is required";
        }

        if (!(overrides.operativeVatBands.every(element => typeof element === 'number' && isFinite(element) && !isNaN(element))))
        {
            return "Vat Band is Invalid";
        }
    }

    setOperativeVatBands(updatedVatBands)
    {
        this.setState(prevstate => ({ ...prevstate, operativeVatBands: updatedVatBands }));
    }


    render()
    {
        const { isLoading, errorMessage } = this.state;

        if (!stringUtil.isStringNullOrEmpty(errorMessage)) 
        {
            return <ErrorMessageComponent message={errorMessage} />
        }

        if (isLoading) return null;

        let title = "Localization Setting Overrides: ";
        const { franchisorId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();
        if (propertyId != null) 
        {
            const property = orgSelectors.selectProperty(propertyId);
            title += property.name;
        }
        else
        {
            const franchisor = orgSelectors.selectFranchisor(franchisorId);
            title += franchisor.name;
        }
        
        return (

            <Card>

                <CardHeader className='py-2 px-4 d-flex justify-content-between align-items-center'>
                    <div className='fw-bold'>{title}</div>
                    <div className='d-flex'>
                        <Button disabled={this.props.isReadOnly} color='success' className='px-4' onClick={this.onSave}>
                            <i className='fa fa-save' />&nbsp;Save
                        </Button>
                        { commonUtility.getApplicationMode() !== constants.applicationModes.embedded ? (
                        <Button color='dark' className='ms-3 px-3' onClick={this.onCancel}>
                            <i className='fa fa-close' />&nbsp;Cancel
                        </Button>
                        ) : null}
                    </div>
                </CardHeader>

                <CardBody>
                    <table className='component-table'>
                        <tbody>

                        <tr>
                                <td>
                                    <BooleanSelectComponent
                                        caption="Is VAT Applicable"
                                        fieldName="isVatApplicable"
                                        hintText="Specify whether VAT is applicable on prices (Required)"
                                        clearable={false}
                                        formManager={this.formManager} />
                                </td>
                                <td>
                                    <BooleanSelectComponent
                                        caption="Is VAT Inclusive"
                                        fieldName="isVatInclusive"
                                        hintText="Specify whether prices are inclusive of VAT (Required)"
                                        clearable={false}
                                        formManager={this.formManager} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <InputComponent
                                        inputType="number"
                                        caption="VAT Percentage"
                                        fieldName="vatPercentage"
                                        placeholder="Enter the VAT percentage"
                                        hintText="Specify VAT percentage (Required)"
                                        formManager={this.formManager} />
                                </td>
                                <td>
                                    <InputComponent
                                        inputType="code"
                                        caption="VAT Caption"
                                        fieldName="vatCaption"
                                        placeholder="Enter VAT caption"
                                        hintText="Specify how VAT tax is referred to in this locale (Required)"
                                        formManager={this.formManager} />
                                </td>
                            </tr>

                            <tr>
                                {
                                    propertyId == null ? 
                                    <td style={{verticalAlign: "Top" }}>

                                        <div style={{ display: "flex", flexFlow: "column", position: "relative" }} >

                                            <div style={{marginBottom: "6px"}}>{`Operative ${this.state.vatCaption} Bands`}</div>
                                            <OperativeVatBands operativeVatBands={this.state.operativeVatBands} setVatBands={this.setOperativeVatBands} vatCaption={this.state.vatCaption}/>
                                        </div>

                                    </td>
                                    : null
                                }
                                
                                <td style={{verticalAlign: "Top" }}>
                                    <BooleanSelectComponent
                                        caption="Print Unit Price In Sale Receipts"
                                        fieldName="doPrintUnitPriceInSaleReceipt"
                                        hintText="Specify whether unit prices should be printed on sale receipts (Required)"
                                        clearable={false}
                                        formManager={this.formManager} />
                                </td>

                            </tr>
                                
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        );
    }
}