import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import HintCard from '../../../../../components/hint/hintCard';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import InputComponent from '../../../../../components/form/inputComponent.js';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontTipConfigurationHelper = require('./storefrontTipConfigurationHelper');
const FormManager = require('../../../../../utils/view/formManager.js');
const viewUtil = require('../../../../../utils/view/viewUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontTipConfiguration extends Component
{
    constructor(props)
    {
        super(props);
        this.state = { isLoading: true, error: null }
    }

    get storefront() { return this.props.params; }

    async componentDidMount()
    {
        try
        {
            viewUtil.showSystemModalSpinner("Loading, please wait");

            const tipConfiguration = await storefrontTipConfigurationHelper.loadStorefrontTipConfiguration(this.storefront);

            this.formManager = new FormManager();
            this.formManager.viewModel = tipConfiguration || {};
            this.formManager.view = this;

            this.setState({});
        }
        catch (error)
        {
            logger.error(error, {}, error);
            this.setState({ error: error });
        }
        finally
        {
            this.setState({ isLoading: false });
            viewUtil.closeModalSpinner();
        }
    }

    onSaveClicked = async () =>
    {
        const tipConfiguration = this.formManager.viewModel;
        const validationError = storefrontTipConfigurationHelper.validateTipConfiguration(tipConfiguration)

        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        try
        {
            viewUtil.showSystemModalSpinner("Saving Storefront Tip Configuration");
            await storefrontTipConfigurationHelper.save(this.storefront, tipConfiguration);
            viewUtil.showSuccessAlert(`${this.storefront.name} tip configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration, 
                                                      `${this.storefront.name} storefront tip configurations have been altered`, 
                                                      tipConfiguration);
                                                      
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }

        return (
            <Card>

                <CardHeader>
                    <EditComponentHeader
                        title="Storefront Tip Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>

                    <div className='mb-5 d-flex flex-column'>

                        <div className='p-3' style={{ fontSize: "0.85em" }}>
                            <HintCard contextId="storefront.tipConfiguration" isDefaultClosed={true}>
                                <div className='px-4 d-flex flex-column'>
                                    <div className='mb-1'>Storefront Tip Configuration allows you to configure service tip options menu presented to the customer at payment.</div>
                                    <div className='mb-1'>You can choose to configure tip options either by fixed amount or by percentage of the bill amount.</div>
                                    <div className='mb-1'>
                                        Optionally, you can choose to also specify both fixed and percentage tip options simultaneously in which case
                                        you must specify the max bill cut-off amount after which tip menu will switch to percentage options &nbsp;&nbsp;&nbsp;
                                        <span className='text-muted' style={{ fontSize: "0.90em" }}>(See Cut-off Bill Amount for Percentage below)</span>
                                    </div>
                                </div>
                            </HintCard>
                        </div>

                        <div className=' px-2 py-3 fw-bold text-muted border rounded-top d-flex align-items-center' style={{ backgroundColor: "#f6f9ff" }}>
                            <div className='w-25 text-center px-2 border-end border-dark'>Tip Options Type</div>
                            <div className='w-25 text-center px-2 border-end border-dark'>1</div>
                            <div className='w-25 text-center px-2 border-end border-dark'>2</div>
                            <div className='w-25 text-center px-2'>3</div>
                        </div>

                        <div className='pb-2 d-flex flex-column border border-top-0'>

                            <div className='pt-1 text-center text-muted' style={{ fontSize: "0.65em" }}>(Values must be in increasing order from 1 to 3)</div>

                            <div className='pb-2 d-flex align-items-center'>
                                <div className='w-25 px-3 text-end d-flex justify-content-end align-items-center'>Tips By Fixed Amount</div>
                                <div className='w-25 px-2'>
                                    <InputComponent
                                        inputType="money"
                                        fieldName="amountTip1"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </div>
                                <div className='w-25 px-2'>
                                    <InputComponent
                                        inputType="money"
                                        fieldName="amountTip2"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </div>
                                <div className='w-25 px-2'>
                                    <InputComponent
                                        inputType="money"
                                        fieldName="amountTip3"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </div>
                            </div>

                        </div>


                        <div className=' pb-2 d-flex align-items-center border border-top-0'>
                            <div className='w-25 px-3 text-end d-flex justify-content-end align-items-center'>Tips By Percentage of Bill Amount</div>
                            <div className='w-25 px-2'>
                                <InputComponent
                                    inputType="percentage"
                                    fieldName="percentageTip1"
                                    inputReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className='w-25 px-2'>
                                <InputComponent
                                    inputType="percentage"
                                    fieldName="percentageTip2"
                                    inputReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className='w-25 px-2'>
                                <InputComponent
                                    inputType="percentage"
                                    fieldName="percentageTip3"
                                    inputReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                        </div>

                        <div className=' pb-2 d-flex align-items-center border border-top-0'>
                            <div className='w-25 px-3 text-end d-flex justify-content-end align-items-center'>Cut-off Bill Amount for Percentage</div>
                            <div className='w-25 px-2'>
                                <InputComponent
                                    inputType="money"
                                    fieldName="amountToPercentageCutoff"
                                    inputReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className='w-50 text-muted px-3 d-flex align-items-center' style={{ fontSize: "0.75em" }}>
                                "Cut-off Bill Amount for Percentage" determines max bill amount after which tip options
                                are presented to the customer by percentage. This option applies only if you choose to
                                configure tips by both fixed amount and percentage of the bill amount.
                            </div>
                        </div>

                    </div>

                </CardBody>

            </Card>
        );
    }
}

export default StorefrontTipConfiguration;