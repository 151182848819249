import React from "react";

const SignageIcon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 64 113" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_1_24579)">
                    <path
                         d="M6.33561 112.33C5.65577 111.744 5.01988 111.137 5.4272 110.109C5.82866 109.098 6.67847 109.019 7.60154 109.033C9.12826 109.057 10.6579 109.039 12.252 109.039V91.2401C11.1502 91.2401 10.0982 91.2459 9.04621 91.2401C3.80084 91.202 0.0177291 87.4834 0.0147987 82.2614C-0.00278355 57.8397 -0.00278355 33.4121 0.0118683 8.98745C0.0147987 3.74209 3.78326 0.0117215 9.02277 0.00879112C24.0527 -0.00293037 39.0825 -0.00293037 54.1153 0.00879112C59.3549 0.0117215 63.1233 3.73916 63.1262 8.98745C63.1438 33.4121 63.1438 57.8368 63.1262 82.2614C63.1233 87.4921 59.3431 91.202 54.0978 91.2401C53.0458 91.2489 51.9938 91.2401 50.8861 91.2401V109.039C52.6091 109.039 54.3527 108.963 56.0816 109.083C56.6325 109.121 57.33 109.57 57.6171 110.038C57.8457 110.414 57.623 111.126 57.4736 111.65C57.3974 111.917 57.0369 112.104 56.8054 112.327H6.33561V112.33ZM59.8442 45.7461C59.8442 33.6055 59.8442 21.4679 59.8442 9.32738C59.8442 5.45928 57.664 3.29667 53.7813 3.29667C39.0063 3.29667 24.2314 3.29667 9.45646 3.29667C5.44185 3.29667 3.29682 5.43584 3.29682 9.45045C3.29682 33.5469 3.29682 57.6434 3.29682 81.7398C3.29682 85.8189 5.43599 87.9522 9.52386 87.9551C24.2255 87.9551 38.9272 87.9551 53.6289 87.9551C57.7021 87.9551 59.8442 85.8101 59.8442 81.731C59.8442 69.737 59.8442 57.7459 59.8442 45.7519V45.7461ZM15.6747 91.2987V108.948H47.4663V91.2987H15.6747Z"
                         fill="black"
                    />
                    <path
                         d="M52.858 50.3322C52.7496 41.4473 52.8082 32.5624 52.8082 23.6775C52.8082 23.276 52.6822 22.7749 52.8697 22.4936C53.2243 21.9691 53.7342 21.2687 54.247 21.2072C54.7598 21.1456 55.4602 21.679 55.8822 22.1332C56.143 22.4145 56.0902 23.0328 56.0902 23.5017C56.099 42.9183 56.099 62.332 56.0961 81.7487C56.0961 83.7238 55.6067 84.2102 53.6287 84.2102C38.927 84.2102 24.2253 84.2102 9.52365 84.2102C7.52807 84.2102 7.03284 83.7296 7.03284 81.7721C7.03284 57.6757 7.03284 33.5792 7.03284 9.48568C7.03284 7.50768 7.51049 7.03589 9.49435 7.03589C24.2312 7.03589 38.971 7.03589 53.7078 7.03589C55.5979 7.03589 56.0902 7.53698 56.0902 9.45638C56.0902 11.5047 56.1019 13.553 56.0785 15.5984C56.0697 16.4482 55.6624 17.2131 54.8038 17.257C54.2236 17.2863 53.5408 16.8878 53.0543 16.4981C52.8023 16.2959 52.817 15.6893 52.8082 15.2644C52.7818 13.6644 52.7994 12.0644 52.7994 10.3941H10.3881V45.0809L10.3588 50.7512C10.3119 55.5043 10.3295 60.2573 10.3324 65.0075C10.3324 65.321 10.3588 65.6346 10.3735 65.9452L10.3295 80.8139H30.9828L36.7849 80.8901C41.7197 80.9458 46.6573 80.9223 51.5921 80.9223C51.9496 80.9223 52.3042 80.9223 52.7115 80.9223V56.6823L52.858 50.3351V50.3322Z"
                         fill="black"
                    />
               </g>
               <defs>
                    <clipPath id="clip0_1_24579">
                         <rect width="63.1408" height="112.33" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default SignageIcon;
