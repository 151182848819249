
import React, { Component } from 'react';
import { Button } from 'reactstrap';

const XLSX = require('sheetjs-style');

class ExcelExportButton extends Component 
{
    constructor(props) 
    {
        super(props);
        this.onClick = this.onClick.bind(this);

        this.state = {};
        this.state.downloadComponent = null;
    }

    render()
    {
        const exportButton =
            <Button
                disabled={this.props.disabled}
                color="dark"
                onClick={this.onClick}>
                <i className="fa fa-print"></i>&nbsp; {this.props.label || "Export"}
            </Button>;

        return <span style={{ margin: '0px', padding: '0px', marginRight: '5px'}}>{exportButton}</span>
    }

    async onClick()
    {
        const workbook = await this.props.onExport();

        const xlsWorkbook = XLSX.utils.book_new();

        const columnHeaderStyle = {
            fill: { pattern: "solid", bgColor: { rgb: "FFFFAA00" } },
            font: { sz: 14, bold: true, color: { rgb: "FFFFFFFF" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        workbook.workSheets.forEach(worksheet => 
        {
            const worksheetData = [];

            worksheetData.push(worksheet.columnHeaders.map(columnHeader => columnHeader.title));

            worksheet.rows.forEach(row => 
            {
                worksheetData.push(row.cells.map(cell => cell.value));
            });

            const xlsWorksheet = XLSX.utils.aoa_to_sheet(worksheetData);

            const wsCols = [];

            for (let column = 0; column < worksheet.columnHeaders.length; column++) 
            {
                wsCols.push({ wch: worksheet.columnHeaders[column].width.wch });

                const cell_address = XLSX.utils.encode_cell({ c: column, r: 0 });
                const header_cell = xlsWorksheet[cell_address];
                header_cell.s = columnHeaderStyle;
            }

            xlsWorksheet['!cols'] = wsCols;

            XLSX.utils.book_append_sheet(xlsWorkbook, xlsWorksheet, worksheet.workSheetName);
        });

        XLSX.writeFile(xlsWorkbook, `${workbook.workBookName}.xlsx`);
    }

}

export default ExcelExportButton;