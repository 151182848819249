import React from "react";

import purchaseOrderUtil from '../../utils/purchaseOrderUtil';
import formatter from '../../../../../../utils/formatter/formatter';
import * as localizationUtils from '../../../../../../utils/domain/localizationUtils';

export default function PurchaseOrderHeaderInfo({ data: purchaseOrder, lookupData })
{
    const vatCaption = localizationUtils.getVatCaption();
    const createdOn = purchaseOrderUtil.getCreatedOn(purchaseOrder);
    const submittedOn = purchaseOrderUtil.getSubmittedOn(purchaseOrder);
    const receivedOn = purchaseOrderUtil.getReceivedOn(purchaseOrder);
    const numberOfItems = purchaseOrderUtil.getNumberOfItems(purchaseOrder);

    let { totalPrice, totalAmount, totalVat } = purchaseOrderUtil.getApplicablePurchaseOrderTotalPrice(purchaseOrder);
    totalPrice = formatter.convertToCurrencyFormat(totalPrice);     // totalGross
    totalAmount = formatter.convertToCurrencyFormat(totalAmount);
    totalVat = formatter.convertToCurrencyFormat(totalVat);

    return (
        <div className="d-flex flex-row align-items-center" style={{ fontSize: "1.0em", color: "#FFFFFF" }}>

            <div className="h-100 d-flex flex-column justify-content-start align-items-end border-start border-end ms-2 ps-2 pe-1 ">
                {
                    createdOn &&
                    <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                        <span>Created:&nbsp;</span>
                        <span>{createdOn}</span>
                    </div>
                }
                {
                    submittedOn &&
                    <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                        <span>Submitted:&nbsp;</span>
                        <span>{submittedOn}</span>
                    </div>
                }
                {
                    receivedOn &&
                    <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                        <span>Received:&nbsp;</span>
                        <span>{receivedOn}</span>
                    </div>
                }
                <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                    <span>Items count:&nbsp;</span>
                    <span>{numberOfItems}</span>
                </div>
            </div>

            <div className="h-100 d-flex flex-column justify-content-center ms-2 pe-2">
                {
                    totalPrice &&
                    <div className="w-100 mb-1 d-flex justify-content-between align-items-center fw-bold">
                        <span>Total Gross Amount:&nbsp;</span>
                        <span>{totalPrice}</span>
                    </div>
                }
                {
                    totalAmount &&
                    <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                        <span>Total Amount:&nbsp;</span>
                        <span>{totalAmount}</span>
                    </div>
                }
                {
                    totalVat &&
                    <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                        <span>Total {vatCaption}:&nbsp;</span>
                        <span>{totalVat}</span>
                    </div>
                }
            </div>

        </div>
    );
}
