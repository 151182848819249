
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader.jsx';
import GridComponent from '../../../../../components/grid/gridComponent.js';
import BusinessHourOverride from "./components/BusinessHourOverride.jsx";
import ErrorMessage from '../../../../../components/error/errorMessage.js';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontBusinessHourOverridesHelper = require('./storefrontBusinessHourOverridesHelper.js');
const catalogSelectors = require('../../../../../utils/state/stateSelectors/catalogSelectors.js');
const typeUtil = require('../../../../../utils/type/typeUtil.js');
const viewUtil = require('../../../../../utils/view/viewUtil.js');
const guidUtil = require('../../../../../utils/guid/guidUtil.js');
const constants = require('../../../../../utils/domain/constants.js');
const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants.js');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontBusinessHourOverrides extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = 
        {
            storefrontBusinessHourOverrides: [],
            storefront: this.props.params
        }
        this.state.isLoading = true;
        this.state.error = null;

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() 
    {
        this.loadData();
    }

    async loadData() 
    {
        this.state.isLoading = true;
        this.state.error = null;
        this.setState({});

        viewUtil.showSystemModalSpinner("Loading, please wait");
        
        try 
        {
            let storefrontBusinessHourOverrides = await storefrontBusinessHourOverridesHelper.load(this.state.storefront);
            this.state.storefrontBusinessHourOverrides = storefrontBusinessHourOverrides;
        }
        catch (error) 
        {
            this.state.error = error;
        }

        this.state.isLoading = false;
        this.setState({});
        viewUtil.closeModalSpinner();
    }

    render()
    {
        if (this.state.error)
            return <ErrorMessage message={ this.state.error } />;

        const deleteButtonDisabled = this.findSelectedRow() == null || this.props.isReadOnly;

        const buttonPanels =   
                <table width="100%">
                    <tr>
                        <td align="right">
                            <Button 
                                color="primary" 
                                disabled={this.props.isReadOnly} 
                                className="btn-primary" 
                                style={{ marginRight: '3px' }} 
                                onClick={this.onAdd}>
                                    <i className="fa fa-plus"></i>&nbsp;Add Override
                                </Button>

                            <Button 
                                color="primary" 
                                disabled={deleteButtonDisabled} 
                                className="btn-danger" 
                                style={{ marginRight: '3px' }} 
                                onClick={this.onRemove}>
                                    <i className="fa fa-remove"></i>&nbsp;Remove Override
                                </Button>
                        </td>
                    </tr>
                </table>

        return (
            <Card>
                <CardHeader>                
                    <EditComponentHeader
                        title={`Business Hour Overrides: ${this.state.storefront.name}`}
                        isReadOnly={true}
                        onCancelClose={true} />        
                </CardHeader>

                <CardBody>                
                    <table width="100%">
                        <tr width="100%">
                            <td style={{paddingTop: '8px', paddingBottom: '8px'}} width="100%">
                                {buttonPanels}
                            </td>
                        </tr>

                        <tr width="100%">
                            <td width="100%">
                                <GridComponent 
                                    headers={this.constructGridColumnHeaders()}
                                    rows={this.state.storefrontBusinessHourOverrides}
                                    onRowDoubleClicked={this.onRowDoubleClicked}
                                    onRowSelected={this.onRowSelected}
                                    shouldReArrangeHeaders={true}
                                    onGridReady={this.onGridReady} />
                            </td>
                        </tr>
                    </table>
                </CardBody>
            </Card>
        )
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;

        return selectedNodes[0].data;
    }

    onAdd()
    {
        const businessHourOverride = 
        {
            id: guidUtil.generateGuid(),
        };

        this.addEdit(businessHourOverride);
    }

    onRemove()
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        viewUtil.showConfirmDialogue('Business Hour Override', `You are going to delete a business hour override record. Are you sure?`,
            async () =>
            {
                viewUtil.showSystemModalSpinner("Deleting Business Hour Override");
                try
                {
                    await storefrontBusinessHourOverridesHelper.deleteBusinessHourOverride(this.state.storefront, selectedRow.id);

                    await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.businessHourOverrides, 
                                                              `${this.state.storefront.name} Storefront business hour override has been deleted`, 
                                                              selectedRow);

                    this.state.storefrontBusinessHourOverrides = this.state.storefrontBusinessHourOverrides.filter(ig => ig.id != selectedRow.id);
                    this.setState({});

                    viewUtil.showSuccessAlert(`Business Hour Override deleted`);
                }
                catch(error)
                {
                    viewUtil.showCriticalAlert(error);
                }
                finally
                {
                    viewUtil.closeModalSpinner();
                }                
            });
    }

    onRowDoubleClicked(eventData)
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const businessHourOverride = this.state.storefrontBusinessHourOverrides.find(override => override.id === selectedRow.id);
        this.addEdit(businessHourOverride);
    }

    addEdit(businessHourOverride)
    {
        const cloneObject = typeUtil.deepCloneObject(businessHourOverride)
        viewUtil.openModalForm("Business Hour Override", (onFieldChanged) =>
        {
            return (
                <BusinessHourOverride
                    isReadOnly={this.props.isReadOnly}
                    data={cloneObject} />
            );
        },
            () => { this.save(cloneObject) },
            () => { return storefrontBusinessHourOverridesHelper.validateBusinessHourOverride(cloneObject) },
            this.props.isReadOnly);
    }

    async save(businessHourOverride)
    {
        viewUtil.showSystemModalSpinner("Saving Storefront Business Hour Overrides");
        try
        {
            await storefrontBusinessHourOverridesHelper.save(this.state.storefront, businessHourOverride);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.businessHourOverrides, 
                                                      `${this.state.storefront.name} storefront business hour override record has been altered`, 
                                                      businessHourOverride);

            this.state.storefrontBusinessHourOverrides = this.state.storefrontBusinessHourOverrides.filter(override => override.id != businessHourOverride.id);
            this.state.storefrontBusinessHourOverrides.push(businessHourOverride);        
            this.setState({});

            viewUtil.showSuccessAlert(`${this.state.storefront.name} business hour overrides saved`);
        }
        catch(error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    constructGridColumnHeaders()
    {
        const headers = [];

        let header = {};
        header.field = "isOpen";
        header.headerName = "Open / Closed";
        header.width = columnWidthConstants.code;
        header.valueFormatter = (data) => data.value ? "Open" : "Closed";
        headers.push(header);

        header = {};
        header.field = "formattedFromDateTime";
        header.headerName = "From Date Time";
        header.width = columnWidthConstants.dateTime + 100;
        headers.push(header);
        
        header = {};
        header.field = "formattedToDateTime";
        header.headerName = "To Date Time";
        header.width = columnWidthConstants.dateTime + 100;
        headers.push(header);

        header = {};
        header.field = "dispatchTypes";
        header.headerName = "Dispatch Types";
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }
}

export default StorefrontBusinessHourOverrides;