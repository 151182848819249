import React, { Component } from "react";
import { FormText } from 'reactstrap';

import CheckComponent from '../../../../../components/form/checkComponent';

import FormManager from '../../../../../utils/view/formManager';
import { EnumWeeks, allEnumWeeks } from '../../../../../utils/enums/enumUtil';

class SelectEnumWeeksComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = {};
        this.formManager.view = this;
        this.formManager.onFieldChanged = this.onFieldChanged;

        this.proxyViewModel = this.formManager.viewModel;
        this.dataViewModel = this.props.formManager.viewModel;

        this.dataBitField = this.dataViewModel.weeks || (this.dataViewModel.weeks = 0);

        //
        // Initialize with existing data value
        //

        Object.keys(EnumWeeks).forEach(key =>
        {
            this.proxyViewModel[key] = (this.dataBitField & EnumWeeks[key]) === EnumWeeks[key];
        });

        this.proxyViewModel["All"] = (this.dataBitField & allEnumWeeks) === allEnumWeeks;
    }

    onFieldChanged = (event) =>
    {
        const id = event.target.id;
        const value = event.target.checked;

        if (id == "All")
        {
            Object.keys(EnumWeeks).forEach(key => this.proxyViewModel[key] = value);
        }

        this.dataBitField =
            Object.keys(EnumWeeks)
                .reduce((accumulator, key) =>
                    accumulator +
                    (this.proxyViewModel[key] ? EnumWeeks[key] : 0),
                    0);

        this.proxyViewModel["All"] = (this.dataBitField & allEnumWeeks) == allEnumWeeks;

        this.dataViewModel.weeks = this.dataBitField;
    }

    render()
    {
        return (
            <div>
                <fieldset style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
                    <legend style={{ margin: "0px 20px -10px 20px", width: "200px", fontSize: "14px" }}>
                        {this.getEnumAllCheckComponent()}
                    </legend>
                    <div style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        marginLeft: "20px",
                        padding: "15px 0px",
                        justifyContent: "flex-start",
                    }}>
                        {
                            Object.keys(EnumWeeks).map(week =>
                                <span style={{ margin: "0px 15px 0px 0px" }}>
                                    {this.getEnumWeekCheckComponent(week)}
                                </span>)
                        }
                    </div>
                </fieldset>
                <FormText color="muted">{this.props.hintText}</FormText>
            </div>
        );
    }

    getEnumWeekCheckComponent(week)
    {
        return (
            <CheckComponent
                key={week}
                caption={week}
                fieldName={week}
                inputReadOnly={this.props.isRowReadOnly}
                formManager={this.formManager} />);
    }

    getEnumAllCheckComponent()
    {
        return (
            <CheckComponent
                key={"All"}
                caption="All Weeks of the Month"
                fieldName="All"
                inputReadOnly={this.props.isRowReadOnly}
                formManager={this.formManager} />);
    }
}

export default SelectEnumWeeksComponent;