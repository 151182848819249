import React, { Component } from 'react';
import { Row, Col } from "reactstrap";

import InputComponent from '../../../../../../components/form/inputComponent';
import FormManager from '../../../../../../utils/view/formManager';

const stringUtil = require('../../../../../../utils/string/stringUtil');

export default class StockItemUnitType extends Component
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
        this.formManager.onFieldChanged = (() =>
        {
            if (this.props.onFieldChanged)
                this.props.onFieldChanged();
        })
    }

    render()
    {
        return (
            <Col>
                {this.renderTable()}
            </Col>
        )
    }

    renderTable()
    {
        let conversionHintText = "";
        if(!stringUtil.isStringNullOrEmpty(this.props.data.code) && !stringUtil.isStringNullOrEmpty(this.props.data.coreUnitConversionFactor))
            conversionHintText = `1x ${this.props.data.code} = ${this.props.data.coreUnitConversionFactor} ${this.props.coreUnitCode}`;

        return (
            <table className="component-table">

                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Unit"
                            fieldName="code"
                            placeholder="Enter the unit name"
                            hintText="Enter name for the unit (required)"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>


                                        
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            caption="Conversion"
                            fieldName="coreUnitConversionFactor"
                            placeholder="Enter the conversion factor"
                            hintText={`Enter conversion factor in ${this.props.coreUnitCode} (required)`}
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                    <td></td>

                </tr>

                <tr>
                    <td colSpan="2" >
                        <InputComponent
                            inputType="text"
                            multiLine={true}
                            caption="Notes"
                            rows={4}
                            fieldName="notes"
                            placeholder="Enter any notes."
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2" align='center'>
                        <div className="mediumText muted-text">
                            {conversionHintText}
                        </div>
                    </td>
                </tr>
                
            </table>
        )
    }
}
