import React from 'react';

import InputComponent from '../../../../../../components/form/inputComponent';

const payrollRunUtils = require('../../utils/payrollRunUtils');

function EditStaffPayrollTaxes({ formManager })
{
    const isReadOnly = !payrollRunUtils.isEditingAllowed();

    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td width="50%">
                        <InputComponent inputType="money"
                            fieldName="paye"
                            caption="PAYE"
                            inputReadOnly={isReadOnly}
                            formManager={formManager}
                            style={{ marginRight: '20px' }}
                        />
                    </td>
                </tr >

                <tr>
                    <td width="50%">
                        <InputComponent inputType="money"
                            fieldName="employerNIContribution"
                            caption="Employer NI Contribution"
                            inputReadOnly={isReadOnly}
                            formManager={formManager}
                            style={{ marginRight: '20px' }}
                        />
                    </td>

                    <td width="50%">
                        <InputComponent inputType="money"
                            fieldName="employeeNIContribution"
                            caption="Employee NI Contribution"
                            inputReadOnly={isReadOnly}
                            formManager={formManager}
                        />
                    </td>
                </tr >

                <tr>
                    <td width="50%">
                        <InputComponent inputType="money"
                            fieldName="employerPensionContribution"
                            caption="Employer Pension Contribution"
                            inputReadOnly={isReadOnly}
                            formManager={formManager}
                            style={{ marginRight: '20px' }}
                        />
                    </td>

                    <td width="50%">
                        <InputComponent inputType="money"
                            fieldName="employeePensionContribution"
                            caption="Employee Pension Contribution"
                            inputReadOnly={isReadOnly}
                            formManager={formManager}
                        />
                    </td>
                </tr >

                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="text" multiLine={true} rows={4}
                            fieldName="notes"
                            placeholder="Enter any notes for the staff related to payload run."
                            caption="Notes"
                            inputReadOnly={isReadOnly}
                            formManager={formManager}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default EditStaffPayrollTaxes;