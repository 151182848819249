import React, { Component } from 'react';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

export default class OperatingConfigurationsDelivery extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    render()
    {
        return (            
            
            <table className='component-table'>
                <tr>
                    <td>
                        <BooleanSelectComponent
                            caption="Does Venue Handle Delivery"
                            fieldName="venueHandlesDelivery"
                            comboReadOnly={this.isReadOnly}
                            clearable={false}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <BooleanSelectComponent
                            caption="Does Property Handle Delivery"
                            fieldName="propertyHandlesDelivery"
                            comboReadOnly={this.isReadOnly}
                            clearable={false}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
            </table>
        );
    }    
}
