
var arraySort = require('array-sort');

const stringUtil = require('../../string/stringUtil');
const commonUtility = require('../commonUtility');
const constants = require('../constants');
const driverUnassigned = "Unassigned";

export default {
    enrichDriverName,
    filterAndSortDriverActionData,
    driverUnassigned: driverUnassigned
}

function enrichDriverName(saleHeaders, staffList)
{
    saleHeaders.forEach(saleHeader =>
    {
        saleHeader.driverName = driverUnassigned;

        if (!stringUtil.isStringNullOrEmpty(saleHeader.driverId))
        {
            const driver = staffList.find(s => s.id == saleHeader.driverId);

            if (driver != null)
            {
                saleHeader.driverName = commonUtility.getCompleteName(driver);
            }
        }
    });
}

function filterAndSortDriverActionData(saleHeaders, storefrontsOperatingConfigurations)
{
    const driverSales = saleHeaders.filter(saleHeader => !saleHeader.isCancelled && doesStorefrontHandleDeliverySale(saleHeader, storefrontsOperatingConfigurations));

    return arraySort(driverSales, ["dateTime", "number"]);
}

function doesStorefrontHandleDeliverySale(sale, storefrontsOperatingConfigurations)
{
    if (sale.dispatchType != constants.dispatchTypes.delivery)
        return false;

    const operatingConfiguration = storefrontsOperatingConfigurations.find(config => stringUtil.areStringSame(config.storefrontId, sale.storefrontId));
    if(operatingConfiguration == null)
        return false;

    if (!operatingConfiguration.propertyHandlesDelivery) return false;

    return true;
}