import React from 'react';
const stringUtil = require('../../../../../utils/string/stringUtil');
const localizationUtils = require('../../../../../utils/domain/localizationUtils.js');
const formatter = require('../../../../../utils/formatter/formatter.js');

function getLocalAmount(amount)
{
    let multiplier = 1;
    if(stringUtil.areStringSame(localizationUtils.getCountry(), "Pakistan"))
    {
        multiplier = 200;
    }

    return formatter.convertToCurrencyFormat(amount * multiplier);
}

function getDashSeparator()
{
    let separatorLine = "";
    for (var index = 0; index < 100; index++)
        separatorLine = `${separatorLine}-`;

    return <div style={{whiteSpace:'nowrap', textOverflow:'', overflow:'hidden'}}>
        {separatorLine}
    </div>
}


export {
    getLocalAmount,
    getDashSeparator
}