

import React, { Component } from 'react';
import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';
import CheckComponent from '../../../../components/form/checkComponent';

const stationState = require('../stationState');
const FormManager = require('../../../../utils/view/formManager.js');


class KDSConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.model;
        this.formManager.view = this;
    }

    render()
    {
        const printerOptions = [];
        stationState.labelPrinters.forEach(printer =>
            {
                const printerUrl = printer.url;
                printerOptions.push({ value: printerUrl, label: printerUrl });  
            });

        const fohOrderPrintFormatOptions = [];
        fohOrderPrintFormatOptions.push({ value: "headerOnly", label: 'Header Only'});
        fohOrderPrintFormatOptions.push({ value: "headerWithItems", label: 'Header With Items'});


        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td >
                                    <SelectComponent
                                        optionValues={printerOptions}
                                        optionFieldName="value"
                                        optionDisplayFieldName="label"
                                        caption="Choose Label Printer"
                                        fieldName="fohPrinter"
                                        hintText="Select label printer"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>

                                <td >
                                    <SelectComponent
                                        optionValues={fohOrderPrintFormatOptions}
                                        optionFieldName="value"
                                        optionDisplayFieldName="label"
                                        caption="Choose Label Format"
                                        fieldName="fohOrderPrintFormat"
                                        hintText="Select label format"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <InputComponent
                                        inputType="number"
                                        caption="Sale Card Width"
                                        fieldName="saleCardWidth"
                                        placeholder="Enter Width"
                                        hintText="Sets the size of sale card"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'50px'}}/>
                                </td>
                                <td >
                                    <InputComponent
                                        inputType="number"
                                        caption="Sale Card Width With Multiple Columns"
                                        fieldName="saleCardWidthWithMultipleColumns"
                                        placeholder="Enter Width"
                                        hintText="Sets the size of sale card with multiple columns"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'50px'}}/>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <InputComponent
                                        inputType="number"
                                        caption="Maximum Items In Sale Card"
                                        fieldName="maximumItemsInSaleCard"
                                        placeholder="Enter Max Items Count"
                                        hintText="Sets maximum no of items in sale card"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'10px'}}/>
                                </td>
                                <td >
                                    <InputComponent
                                        inputType="number"
                                        caption="Right Margin In Sale Card"
                                        fieldName="saleCardRightMargin"
                                        placeholder="Enter Right Margin"
                                        hintText="Sets right margin of sale card"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'10px'}}/>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <InputComponent
                                        inputType="number"
                                        caption="Sale Font Size"
                                        fieldName="saleFontSize"
                                        placeholder="Enter Font Size"
                                        hintText="Sets font size of sale"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'10px'}}/>
                                </td>

                                <td >
                                    <InputComponent
                                        inputType="number"
                                        caption="Minimum Width For Modifier"
                                        fieldName="minimumModifierWidth"
                                        placeholder="Enter Minimum Width"
                                        hintText="Sets minimum width for modifiers"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'10px'}}/>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <CheckComponent
                                        caption="Hide Customer Details"
                                        fieldName="hideCustomerDetails"
                                        inputReadOnly={this.props.isReadOnly} 
                                        formManager={this.formManager}
                                        style={{marginTop:'50px'}}/>
                                </td>
                                <td >
                                    <CheckComponent
                                        caption="Hide Guest Details"
                                        fieldName="hideGuestDetails"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'50px'}}/>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <CheckComponent
                                        caption="Hide Staff Details"
                                        fieldName="hideStaffDetails"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'-10px'}}/>
                                </td>

                                <td >
                                    <CheckComponent
                                        caption="Hide Freebies Details"
                                        fieldName="hideFreebiesDetails"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'-10px'}}/>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <CheckComponent
                                        caption="Hide Delivery Details"
                                        fieldName="hideDeliveryDetails"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'-10px'}}/>
                                </td>

                            </tr>
                        </tbody>
                    </ table>
                </div>
    }
}

export default KDSConfiguration;