import React, { Component } from 'react';
import { Row, Button, Card, CardHeader, CardBody} from 'reactstrap';
import ScaleLoader from "react-spinners/ScaleLoader";

import Select from 'react-select';
import 'react-select/dist/react-select.min.css';

import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

const arraySort = require('array-sort');

var rmsApiProxy = require('../../../utils/api/rmsApiProxy.js');
var viewUtil = require('../../../utils/view/viewUtil.js');
var guidUtil = require('../../../utils/guid/guidUtil.js');
var typeUtil = require('../../../utils/type/typeUtil.js');
var stringUtil = require('../../../utils/string/stringUtil.js');
var securityManager = require('../../../utils/domain/security/securityManager');
var configurationManager = require('../../../utils/config/configurationManager.js');
const currentOrgNodeSelectors = require('../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../../../utils/state/stateSelectors/catalogSelectors');

var attachmentRootFolder = "documents";
var attachmentObjectType = "documents";
var rootFolderId = 'bf5f1e90-b614-4741-a30c-233e0b5201c1';
var rootFolder = null;

export default class DocumentsExplorer extends Component 
{

    constructor(props)
    {
        super(props);

        rootFolder = {};
        rootFolder.fullyQualifiedName = "/";
        rootFolder.isRoot = true;
        rootFolder.id = rootFolderId;
        rootFolder.isEditable = true;
        rootFolder.name = "/";

        this.loadDocumentsExplorer = this.loadDocumentsExplorer.bind(this);
        this.loadFolderStructures = this.loadFolderStructures.bind(this);
        this.loadFileStructures = this.loadFileStructures.bind(this);
        this.populateFolders = this.populateFolders.bind(this);

        this.renderSpinnerComponent = this.renderSpinnerComponent.bind(this);
        this.renderFolderStructure = this.renderFolderStructure.bind(this);
        this.renderFolderStructureToolbar = this.renderFolderStructureToolbar.bind(this);

        this.addFolder = this.addFolder.bind(this);
        this.renameFolder = this.renameFolder.bind(this);
        this.addEditFolder = this.addEditFolder.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.refreshFolders = this.refreshFolders.bind(this);

        this.deleteFile = this.deleteFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);

        this.renderFilesStructureBody = this.renderFilesStructureBody.bind(this);
        this.renderFileUploadComponent = this.renderFileUploadComponent.bind(this);

        this.onUploadFile = this.onUploadFile.bind(this);
        this.onUploadCompleted = this.onUploadCompleted.bind(this);

        this.state = {};
        this.state.folders = [];
        this.state.selectedFolderId = null;
        this.state.files = [];
        this.selectedFileId = null;

        this.state.stage = "none";
        this.state.error = null;
    }

    render() 
    {

        if (this.state.stage == "none")
        {
                this.loadDocumentsExplorer();
                return this.renderSpinnerComponent();
        }

        if (this.state.stage == "error")
        {
            return (<div>
                        <Row className="justify-content-center" style={{ margin: '5px' }}>
                            <h5 className="text-muted">{this.state.error}</h5>
                        </Row>
                    </div>);
        }

        return this.renderFolderStructure();
    }

    renderSpinnerComponent()
    {
        return (<div style={{marginTop:'100px'}}>
                    <Row className="justify-content-center">
                    <ScaleLoader
                        height={50}
                        width={6}
                        color={"#808080"}
                        loading={true} />
                    </Row>
                    <Row className="justify-content-center" style={{ margin: '5px' }}>
                    <h4 className="text-muted">Please wait, initializing explorer</h4>
                    </Row>
                </div>);
    }

    renderFolderStructure()
    {
        return (<Card>
                    <CardHeader>
                        {this.renderFolderStructureToolbar()}
                    </CardHeader>
                    
                    {this.renderFileUploadComponent()}

                    <CardBody>
                        {this.renderFilesStructureBody()}
                    </CardBody>
                </Card>);
    }

    renderFolderStructureToolbar()
    {

        var options = [];
        this.state.folders.forEach (folder =>
        {
            var option = {};
            option.label = folder.fullyQualifiedName;
            option.value = folder.id;
            options.push(option);    
        });

        if (this.state.folders.find (f => f.id == this.state.selectedFolderId) == null)
            this.state.selectedFolderId = this.state.folders[0].id;
     
        return (<table width="100%">
                    <tr>
                    <td width="99%">
                        <div style={{marginRight:'50px'}}>
                        <Select
                                id={this.props.fieldName}
                                value={this.state.selectedFolderId}
                                options={options}
                                clearable={false}
                                onChange={(option)=>
                                    {
                                        this.state.selectedFolderId = option == null ? null : option.value;
                                        this.setState({})
                                    }}
                            />
                        </div>
                        
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <Button color="success" className="btn-success" style={{ marginRight: '3px' }} disabled={this.props.isReadOnly} onClick={() => this.addFolder()}>
                            <i className="fa fa-plus"></i>&nbsp;Add Folder
                        </Button>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <Button color="warning" className="btn-warning" style={{ marginRight: '3px' }} disabled={this.props.isReadOnly} onClick={() => this.renameFolder()}>
                            <i className="fa fa-edit"></i>&nbsp;Rename Folder
                        </Button>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <Button color="danger" className="btn-danger" style={{ marginRight: '3px' }} disabled={this.props.isReadOnly} onClick={() => this.deleteFolder()}>
                            <i className="fa fa-remove"></i>&nbsp;Delete Folder
                        </Button>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <Button color="primary" className="btn-primary" style={{ marginRight: '3px' }} onClick={() => this.refreshFolders()}>
                            <i className="fa fa-refresh"></i>&nbsp;Refresh
                        </Button>
                    </td>
                    </tr>
        </table>);
    }

    addFolder()
    {
        var selectedFolder = this.state.folders.find (f => f.id == this.state.selectedFolderId);
        if (selectedFolder == null)
            return;

        if (!selectedFolder.isEditable)
        {
            viewUtil.showErrorAlert(`You cannot create a sub folder under '${selectedFolder.name}' due to access rights.`);
            return;
        }

        var newFolder = {};
        newFolder.id = guidUtil.generateGuid();
        newFolder.isEditable = true;
        newFolder.parentId = selectedFolder.isRoot ? null : selectedFolder.id;
        newFolder.name = "";
        newFolder.franchisorId = this.getAdminFranchisorId();
        newFolder.franchiseeId = this.getAdminFranchiseeId();
        newFolder.notes = "";
        

        this.addEditFolder(newFolder);
    }

    renameFolder()
    {
        var selectedFolder = this.state.folders.find (f => f.id == this.state.selectedFolderId);
        if (selectedFolder == null)
            return;

        if (selectedFolder.isRoot || !selectedFolder.isEditable)
        {
            viewUtil.showErrorAlert(`You cannot rename folder '${selectedFolder.name}' due to access rights.`);
            return;
        }

        this.addEditFolder(selectedFolder);
    }

    deleteFolder()
    {
        var selectedFolder = this.state.folders.find (f => f.id == this.state.selectedFolderId);
        if (selectedFolder == null)
            return;

        if (selectedFolder.isRoot || !selectedFolder.isEditable)
        {
            viewUtil.showErrorAlert(`You cannot delete folder '${selectedFolder.name}' due to access rights.`);
            return;
        }

        var childFolders = this.state.folders.filter(f => f.parentId == selectedFolder.id);
        if (childFolders != null && childFolders.length > 0)
        {
            viewUtil.showErrorAlert(`You cannot delete folder '${selectedFolder.name}' as it has sub folders.`);
            return;
        }

        var files = this.state.files.filter(f => f.folderId == selectedFolder.id);
        if (files != null && files.length > 0)
        {
            viewUtil.showErrorAlert(`You cannot delete folder '${selectedFolder.name}' as current folder contains files.`);
            return;
        }

        const resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/folders/${selectedFolder.id}`;
        rmsApiProxy.deleted(resourceUrl);
        this.state.folders = this.state.folders.filter(f => f.id != selectedFolder.id);
        this.state.folders = arraySort(this.state.folders, "fullyQualifiedName");
        this.setState({});

    }

    refreshFolders()
    {
        this.state.stage = "none";
        this.setState({});
    }

    addEditFolder(folder)
    {
        this.state.cloneAddEditFolder = typeUtil.deepCloneObject(folder)
        
        var newName = stringUtil.trimString(window.prompt("Enter folder name", folder.name));
        if (stringUtil.isStringNullOrEmpty(newName) || newName == folder.name)
            return;

        folder.name = newName;

        if (stringUtil.isStringNullOrEmpty(folder.parentId))
        folder.fullyQualifiedName = `${rootFolder.fullyQualifiedName}${folder.name}`;
        else
        {
            var parentFolder = this.state.folders.find(f => f.id == folder.parentId);
            folder.fullyQualifiedName = `${parentFolder.fullyQualifiedName}/${folder.name}`;

        }
        
        this.state.folders = this.state.folders.filter(f => f.id != folder.id);
        this.state.folders.push(folder);
        this.state.folders = arraySort(this.state.folders, "fullyQualifiedName");

        rmsApiProxy.post(`${rmsApiProxy.getCurrentOrgNodeContextUrl()}/folders`, folder);

        this.state.selectedFolderId = folder.id;
        this.setState({});
    }

    

    renderFilesStructureBody()
    {

        var files = this.state.files.filter(f => f.folderId == this.state.selectedFolderId);

        var rows = [];
        if (files == null || files.length <= 0)
        {
            rows.push(<tr>
                        <td colspan="3" align="center">
                        No files found
                        </td>
                    </tr>);
        }
        else
        {
            var sortedFiles = arraySort(files, "originalFileName");
            sortedFiles.forEach(file =>
                {
                    let fileCopy = file;
                    rows.push(<tr>
                                <td>
                                    {file.originalFileName}
                                </td>
                                <td>
                                    <Button color="danger" className="btn-danger" style={{ marginRight: '3px', minWidth:'100px' }} onClick={() => this.deleteFile(fileCopy)}>
                                        <i className="fa fa-trash"></i>&nbsp;Delete
                                    </Button>
                                </td>
                                <td>
                                    <Button color="primary" className="btn-primary" style={{ marginRight: '3px', minWidth:'120px' }} onClick={() => this.downloadFile(fileCopy)}>
                                        <i className="fa fa-download"></i>&nbsp;Download
                                    </Button>
                                </td>
                    </tr>);
                })
        }

        return (<table width="100%" className="pure-table pure-table-bordered">
                <thead>
                    <tr>
                        <th width="99%">
                            File Name
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>
                            Delete
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>
                            Download
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>);
    }


    renderFileUploadComponent()
    {
        var folder = this.state.folders.find (f => f.id == this.state.selectedFolderId);
        var disabled = folder.isRoot || !folder.isEditable;

        return (<div style={{marginLeft:'20px', marginRight:'20px', marginTop:'5px'}}>
                <FilePond
                    style={{background:'red'}}
                    allowMultiple={false}
                    files={[]}
                    disabled={disabled}
                    server={
                            {
                                process: this.onUploadFile,
                                revert: null,
                                restore: null,
                                load: null,
                                fetch: null,
                            }
                            }
                    labelIdle={'Drag & Drop your files or <span className="filepond--label-action">Browse</span> to upload'}
                />
                </div>);
    }

    


    async loadDocumentsExplorer()
    {
        this.state.error = null;
        try
        {
            await this.loadFolderStructures();
            await this.loadFileStructures();
            this.state.stage = "loaded";
            this.setState({});
        }
        catch(error)
        {
            this.state.stage = "error";
            this.state.error = error + "";
            this.setState({});
        }
    }

    async loadFolderStructures()
    {
        this.state.folders = [];
        this.state.folders.push(rootFolder);
       
        var franchiseeId = this.getAdminFranchiseeId();
        let isEditableCB = () => true;
        if (franchiseeId != null) {
            isEditableCB = (f) =>  Boolean(f.franchiseeId);
        }

        var resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/folders`;
        var results = await rmsApiProxy.get(resourceUrl);
        this.populateFolders(rootFolder, results, isEditableCB );

    }

    async loadFileStructures()
    {
        
        this.state.files = [];
        var resourcesUrls = [];

        const localFolders = [];

        for (var index = 0; index < this.state.folders.length; index++)
        {
            const folder = this.state.folders[index];
            if(folder === rootFolder)
                continue;

            localFolders.push(folder);

            var resourceUrl;

            if(folder.franchiseeId != null)
            {
                const franchisee = catalogSelectors.selectFranchisees().find(franchisee => stringUtil.areStringSame(franchisee.id, folder.franchiseeId))
                resourceUrl = `core/organizations/${franchisee.franchisorId}/${franchisee.id}/fileSystem/${attachmentObjectType}/${folder.id}`;
            }

            else
                resourceUrl = `core/organizations/${folder.franchisorId}/fileSystem/${attachmentObjectType}/${folder.id}`;

            resourcesUrls.push(resourceUrl);
        }

        var rawFilesByFolder = await rmsApiProxy.get(resourcesUrls);


        for (var index = 0; index < localFolders.length; index++)
        {
            var folder = localFolders[index];
            
            var rawFiles = rawFilesByFolder[index];

            rawFiles.forEach(rawFile =>
            {
                rawFile.folderId = folder.id;
                this.state.files.push(rawFile);
            })
        }
     
    }

    populateFolders(rootFolder, rawFolders, isEditable)
    {
        let changed = true;
        while(changed)
        {
            changed = false;
            rawFolders.forEach (rawFolder =>
            {
                if (this.state.folders.find(f => f.id == rawFolder.id))
                    return;

                if (stringUtil.isStringNullOrEmpty(rawFolder.parentId))
                {
                    rawFolder.fullyQualifiedName = `${rootFolder.fullyQualifiedName}${rawFolder.name}`;
                    rawFolder.isEditable = isEditable(rawFolder);
                    this.state.folders.push(rawFolder);
                    changed = true;
                    return;
                }

                var parentFolder = this.state.folders.find (f => f.id == rawFolder.parentId);
                if (parentFolder != null)
                {
                    rawFolder.fullyQualifiedName = `${parentFolder.fullyQualifiedName}/${rawFolder.name}`;
                    rawFolder.isEditable = isEditable(rawFolder);
                    this.state.folders.push(rawFolder);
                    changed = true;
                    return;
                }
            });
        }

        this.state.folders = arraySort(this.state.folders, "fullyQualifiedName");
    }
    
    deleteFile(file)
    {
        var folder = this.state.folders.find (f => f.id == this.state.selectedFolderId);
        if (!folder.isEditable)
        {
            viewUtil.showErrorAlert(`You cannot delete file '${file.originalFileName}' due to access rights.`);
            return;
        }

        viewUtil.showConfirmDialogue("File Deletion", `You are going to delete file: '${file.originalFileName}'. Once file is deleted, it cannot be recovered.\nAre you sure you want to proceed?`,
                                     ()=>
                                     {
                                        var resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/fileSystem/${attachmentObjectType}/${this.state.selectedFolderId}/${file.id}`;
                                        rmsApiProxy.deleted(resourceUrl);
                                
                                        this.state.files = this.state.files.filter(f => f.id != file.id);
                                        this.setState({});
                                     }
                                     );
    }

    downloadFile(file)
    {
        var url = `${configurationManager.getConfig().apiServer}/${rmsApiProxy.getCurrentOrgNodeContextUrl()}/fileSystem/${attachmentObjectType}/${this.state.selectedFolderId}/${file.id}/fileRawData`;
        window.open(url);
    }

    onUploadFile(fieldName, file, metadata, load, error, progress, abort)
    {
        this.filePondErrorObject = error;
      
        const formData = new FormData();
        formData.append("payload", file, file.name);
       
        var url;
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        if(currentOrgNodeSelectors.isCurrentFranchisorSelected())
        {
            url = `${configurationManager.getConfig().apiServer}/core/organizations/${currentOrgNode.franchisorId}/fileSystem/${attachmentObjectType}/${this.state.selectedFolderId}`;
        }
        else
        {
            url = `${configurationManager.getConfig().apiServer}/core/organizations/${currentOrgNode.franchisorId}/${currentOrgNode.franchiseeId}/fileSystem/${attachmentObjectType}/${this.state.selectedFolderId}`;
        }
    
        this.uploadXmlHttpRequest = new XMLHttpRequest();
        this.uploadXmlHttpRequest.open('POST', url);
        this.uploadXmlHttpRequest.setRequestHeader("x-token", securityManager.getPassport().token);
        
        this.uploadXmlHttpRequest.upload.onprogress = (e) => 
                                    {
                                        progress(e.lengthComputable, e.loaded, e.total);
                                    };

        this.uploadXmlHttpRequest.onload = this.onUploadCompleted;

        this.uploadXmlHttpRequest.send(formData);

        return {
                abort: () => 
                {
                    this.uploadXmlHttpRequest.abort();
                    abort();
                }
            };
    }

    onUploadCompleted(response)
    {
        if (this.uploadXmlHttpRequest.status == 200) 
        {
            setTimeout(() => {  

                                var file = JSON.parse(this.uploadXmlHttpRequest.response);
                                file.folderId = this.state.selectedFolderId;
                                this.state.files.push(file);
                                this.setState({});
                             }, 500);
            return;
        }

        this.filePondErrorObject("Error occurred");
    }

    getAdminFranchisorId()
    {
        return currentOrgNodeSelectors.isCurrentFranchisorSelected() ? currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId : null;
    }

    getAdminFranchiseeId()
    {
        return currentOrgNodeSelectors.selectCurrentOrgNode().franchiseeId;
    }
}
