

const constants = require('../../constants');

export function getAllergyPresence(allergyCode, stockItem, stockItemsLookupData)
{


    if (stockItem.allergyProfile)
    {
        const allergy = stockItem.allergyProfile.allergies.find(x => x.allergyCode === allergyCode);
        if (allergy)
        {
            return allergy.presenceType;
        }
    }

    if (stockItem.recipeProfile)
    {
        return deriveAllergyFromRecipe(allergyCode, stockItem, stockItemsLookupData);
    }
}

function deriveAllergyFromRecipe(allergyCode, stockItem, stockItemsLookupData)
{


    if (!stockItem.recipeProfile || stockItem.recipeProfile.recipeItems.length == 0)
    {
        return constants.AllergyPresenceType.unknown;
    }

    const presenceTypes = stockItem.recipeProfile.recipeItems.map(recipeItem => 
    {
        const ingredientStockItem = stockItemsLookupData.find(x => x.id === recipeItem.ingredientStockItemId);
        const ingredientPresenceType = getAllergyPresence(allergyCode, ingredientStockItem, stockItemsLookupData);

        return ingredientPresenceType;
    });

    if (presenceTypes.some(presenceType => presenceType == constants.AllergyPresenceType.present))           //If any ingredient says, present, we must say it is present
        return constants.AllergyPresenceType.present;

    if (presenceTypes.some(presenceType => presenceType == constants.AllergyPresenceType.mayBe))          //If any ingredient says, may be, we must say it is may be
        return constants.AllergyPresenceType.mayBe;

    if (presenceTypes.length > 0 && presenceTypes.every(presenceType => presenceType == constants.AllergyPresenceType.notPresent))          //If all ingredient says, not present, we must say it is not present
        return constants.AllergyPresenceType.notPresent;

    return constants.AllergyPresenceType.unknown;
}
