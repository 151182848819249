
import React, { Component } from 'react';
import { FormGroup, FormText, Label, Card } from 'reactstrap';
import StandaloneColorPickerComponent from '../color/colorPickerComponent';

class ColorPickerComponent extends Component 
{
    render()
    {
        const captionTextComponent = <Label>{this.props.caption}</Label>;

        return (
            <FormGroup  {...this.props}>

                {captionTextComponent}

                <StandaloneColorPickerComponent
                    inputReadOnly={this.props.inputReadOnly}
                    color={this.props.formManager.getValue(this.props.fieldName)}
                    onColorChange={(color) =>
                    {
                        const target = {};
                        target.type = "select";
                        target.id = this.props.fieldName;
                        target.value = color;

                        const event = {};
                        event.target = target;
                        this.props.formManager.onValueChanged(event);
                    }} />

                {this.renderHintText()}

            </FormGroup>
        );
    }

    renderHintText()
    {
        let hintText = "Select color of your choice";
        let fieldValue = this.props.formManager.getValue(this.props.fieldName);
        if (fieldValue) hintText = `Selected color hex-code: '${fieldValue}'`
        return (<FormText color="muted">{hintText}</FormText>);
    }
}

export default ColorPickerComponent;