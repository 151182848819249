import React, { Component } from 'react';

const configurationManager = require('../../../utils/config/configurationManager');

class MainPortalBanner extends Component
{
    render()
    {
        return  <div className="mt-1" 
                    style={{ 
                        paddingLeft: "30px", 
                        paddingRight: "30px", 
                        paddingTop: "20px", 
                        paddingBottom: "5px", 
                        background: '#FDF2CD', 
                        borderWidth: "3px", 
                        borderColor: "#B2B0A6",
                        borderStyle: "solid",
                        borderRadius: "10px"
                    }}>

                    <div style={{ display: "flex", justifyContent: "center", color: "black" }}>
                        <p style={{ fontWeight: "bold", paddingRight: "5px" }}> Update: </p>
                        <p > {`We will be moving some of the modules below to the main portal by ${configurationManager.getConfig().modulesMainPortalShiftingDate}. Until then, you can access these services in both places.`} </p>
                    </div>
                </div>
    }
}

export default MainPortalBanner;