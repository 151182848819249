import { Component } from 'react';
import StockItemDetails from './Details';
import PropertyLevelOverrides from './PropertyLevelOverrides';

class Overview extends Component
{
    render()
    {
        return  <div>
                    <div style={{ paddingBottom: "20px" }}>
                        <StockItemDetails {...this.props} />
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <PropertyLevelOverrides {...this.props} />
                    </div>
                </div>
    }
}

export default Overview;