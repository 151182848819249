import React, { Component } from "react";

import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import FormManager from '../../../../../utils/view/formManager';

import catalogSelectors from '../../../../../utils/state/stateSelectors/catalogSelectors';

class SupplierServiceProfile extends Component
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        this.serviceTypes = catalogSelectors.selectLookupData().serviceTypes;
    }

    render()
    {
        return (
            <table className="component-table">
                <tbody>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Service Type"
                                fieldName="serviceTypeId"
                                optionValues={this.serviceTypes}
                                optionFieldName="id"
                                optionDisplayFieldName="name"
                                hintText="Select service type (required)"
                                clearable={false}
                                comboReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" >
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                multiLine={true} rows={4}
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                </tbody>
            </table>
        );
    }
}

export default SupplierServiceProfile;