import React, { Component } from 'react';
import { Row, Button } from 'reactstrap';

import GridComponent from '../../../../components/grid/gridComponent'
import validator from '../../../../utils/validator/validator';

import ApplianceServiceProfile from './ApplianceServiceProfile'

const arraySort = require('array-sort');

const typeUtil = require('../../../../utils/type/typeUtil');
const viewUtil = require('../../../../utils/view/viewUtil');
const guidUtil = require('../../../../utils/guid/guidUtil');
const stringUtil = require('../../../../utils/string/stringUtil');

const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

class ApplianceServiceProfiles extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.getRows = this.getRows.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onAddServiceProfile = this.onAddServiceProfile.bind(this);
        this.validateServiceProfile = this.validateServiceProfile.bind(this);
        this.addEditServiceProfile = this.addEditServiceProfile.bind(this);
        this.saveServiceProfile = this.saveServiceProfile.bind(this);
    }

    render()
    {
        var buttonPanels = null;

        if (!this.props.isRowReadOnly)
        {
            buttonPanels = <Row>
                <table width="100%">
                    <tr>
                        <td align="right">
                            <Button color="primary" className="btn-primary" style={{ marginRight: '3px' }} onClick={this.onAddServiceProfile}>
                                <i className="fa fa-plus"></i>&nbsp;Add Service Profile
                            </Button>
                        </td>
                    </tr>
                </table>
            </Row>
        }


        return (
            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                {buttonPanels}
                <Row style={{ marginTop: '10px' }}>
                    <GridComponent
                        headers={this.constructGridColumnHeaders()}
                        rows={this.getRows()}
                        onRowDoubleClicked={this.onRowDoubleClicked}
                        onRowSelected={this.onRowSelected}
                        shouldReArrangeHeaders={true}
                        onGridReady={this.onGridReady} />
                </Row>
            </div>);
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;

        return selectedNodes[0].data;
    }

    onAddServiceProfile()
    {
        const serviceProfile = { isNew: true, hazardTypes: [] };
        serviceProfile.id = guidUtil.generateGuid();
        this.addEditServiceProfile(serviceProfile);
    }

    onRowDoubleClicked(eventData)
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const serviceProfile = this.props.data.serviceProfiles.find(sp => sp.id == selectedRow.id);

        if (serviceProfile != null)
        {
            this.addEditServiceProfile(typeUtil.deepCloneObject(serviceProfile));
        }
    }

    addEditServiceProfile(serviceProfile)
    {
        viewUtil.openModalForm(
            "Service Profile",
            (onFieldChanged) =>
            {
                return (
                    <ApplianceServiceProfile
                        data={serviceProfile}
                        serviceProfiles={this.props.serviceProfiles}
                        onFieldChanged={() => onFieldChanged()}
                        isRowReadOnly={this.props.isRowReadOnly} />);
            },
            () => { this.saveServiceProfile(serviceProfile) },
            () => { return this.validateServiceProfile(serviceProfile) },
            this.props.isRowReadOnly);
    }

    validateServiceProfile(serviceProfile)
    {
        if (stringUtil.isStringNullOrEmpty(serviceProfile.name))
            return "Service Profile name cannot be empty";

        if (stringUtil.isStringNullOrEmpty(serviceProfile.serviceTypeId))
            return "Service type cannot be empty";

        if (stringUtil.isStringNullOrEmpty(serviceProfile.frequencyCode))
            return "Service frequency cannot be empty";

        if (stringUtil.isStringNullOrEmpty(serviceProfile.isMandatory))
            return "Service Profile Is Mandatory check cannot be empty";

        const existingServiceProfile =
            this.props.data.serviceProfiles.find(
                existingServiceProfile => existingServiceProfile.id != serviceProfile.id &&
                    existingServiceProfile.name == serviceProfile.name);

        if (existingServiceProfile)
            return "Similar service profile already exists.";

        if (!validator.isArray(serviceProfile.hazardTypes))
            return "Service Profile hazard types must be an array";

        return null;
    }

    getRows()
    {
        //Make a new array 
        const serviceProfiles = Array.from(this.props.data.serviceProfiles);
        this.enrichServiceProfile(serviceProfiles);

        return arraySort(serviceProfiles, "name");
    }

    saveServiceProfile(serviceProfile)
    {
        this.enrichServiceProfile(serviceProfile);
        this.props.data.serviceProfiles = this.props.data.serviceProfiles.filter(sp => sp.id != serviceProfile.id);
        this.props.data.serviceProfiles.push(serviceProfile);
        this.setState({});
    }

    enrichServiceProfile(serviceProfiles)
    {
        if (!Array.isArray(serviceProfiles))
        {
            serviceProfiles = [serviceProfiles];
        }

        const serviceTypes = catalogSelectors.selectLookupData().serviceTypes;

        serviceProfiles.forEach(serviceProfile =>
        {
            if (!stringUtil.isStringNullOrEmpty(serviceProfile.serviceTypeId))
            {
                const serviceType = serviceTypes.find(x => x.id === serviceProfile.serviceTypeId);
                serviceProfile.serviceType = serviceType.name;
            }
        });
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "serviceType";
        header.headerName = "Service Type";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "frequencyCode";
        header.headerName = "Frequency";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "isMandatory";
        header.headerName = "Is Mandatory";
        header.type = dataTypeConstants.boolean
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "hazardTypes";
        header.headerName = "Hazard Types";
        header.type = dataTypeConstants.name;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "nextDueDate";
        header.headerName = "Service Due";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        headers.push(header);

        header = {};
        header.field = "notifyDays";
        header.headerName = "Notify Days";
        header.type = dataTypeConstants.number;
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default ApplianceServiceProfiles;