
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import HintCard from '../../../../../components/hint/hintCard.js';
import PreviousNextDateRangeHeader from '../../../../../components/header/previousNextDateRangeHeader';
import EditStorefrontCommissionRun from './EditStorefrontCommissionRun';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');

const storefrontCommissionRunUtil = require('../utils/storefrontCommissionRunUtil');

class StorefrontCommissionRun extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            data: null,
        }

        this.onNextClicked = this.onNextClicked.bind(this);
        this.onPreviousClicked = this.onPreviousClicked.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.loadCurrentStorefrontCommissionRun();
    }

    async componentDidUpdate(prevProps)
    {
        if (this.props.propertyId !== prevProps.propertyId || this.props.storefrontId !== prevProps.storefrontId)
        {
            this.state.data = null;
            this.setState({});
            this.loadCurrentStorefrontCommissionRun();
        }
    }

    loadCurrentStorefrontCommissionRun()
    {
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${this.props.storefrontId}/commissionRuns?run=current`;
        this.loadStorefrontCommissionRun(resourceUrl);
    }

    onPreviousClicked()
    {
        if (this.state.data.fromDate != null)
        {
            const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${this.props.storefrontId}/commissionRuns?run=previous&beforeToDate=${this.state.data.fromDate}`
            this.loadStorefrontCommissionRun(resourceUrl);
        }
    }

    onNextClicked()
    {
        if (this.state.data.toDate != null)
        {
            const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${this.props.storefrontId}/commissionRuns?run=next&afterToDate=${this.state.data.toDate}`
            this.loadStorefrontCommissionRun(resourceUrl);
        }
    }

    render() 
    {
        if (this.state.data == null)
            return null;

        return <Col>
            {this.renderPayrollDateRange()}
            <HintCard>
                <div>
                    Storefront Commission run for the date range
                    <ul>
                        <li>Commission rate is setup in the property setting. Rate can be overridden here for a run.</li>
                        <li>Gross commission is calculated from the commission rate and total sales</li>
                        <li>Enter the rebate amount and additional charges raised by the storefront</li>
                        <li>Net commission rate is calculated using on sale amount and total amount paid to the storefront. This rates is flagged as high when the difference between net and gross rate is more than 2.</li>
                    </ul>
                </div>
            </HintCard>
            {this.renderEditStorefrontCommissionRun()}
        </Col>
    }

    renderEditStorefrontCommissionRun()
    {
        return (
            <EditStorefrontCommissionRun
                data={this.state.data}
                onChange={() =>
                {
                    storefrontCommissionRunUtil.updateCalculatedFields(this.state.data);
                    this.setState({});
                }}
            />);
    }

    renderPayrollDateRange()
    {
        return (
            <PreviousNextDateRangeHeader
                data={this.state.data}
                isNew={stringUtil.isStringNullOrEmpty((this.state.data != null) ? this.state.data.id : null)}
                onNextClicked={this.onNextClicked}
                onPreviousClicked={this.onPreviousClicked}
                onSaveClicked={this.onSaveClicked}
                isNextEnabled={() => this.state.data.canDoMoveNext}
                isPreviousEnabled={() => this.state.data.canDoMovePrevious}
            />);
    }


    async loadStorefrontCommissionRun(resourceUrl)
    {
        try 
        {
            viewUtil.showSystemModalSpinner("Loading storefront commission run");

            const result = await rmsApiProxy.get(resourceUrl);

            storefrontCommissionRunUtil.enrichViewModel(result);
            this.state.data = result;
            this.setState({});

            viewUtil.closeModalSpinner();
        }
        catch (error) 
        {
            viewUtil.closeModalSpinner();
            viewUtil.showCriticalAlert(`Storefront commission run load failed: ${error}`);
            this.setState({});
        }
    }

    async onSaveClicked()
    {
        try
        {
            let error = storefrontCommissionRunUtil.validate(this.state.data);
            if (error)
            {
                viewUtil.showErrorAlert(error);
                return;
            }

            viewUtil.showSystemModalSpinner("Saving storefront commission run");

            this.state.data = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${this.props.storefrontId}/commissionRuns`, this.state.data);

            viewUtil.closeModalSpinner();

            viewUtil.showSuccessAlert("Storefront commission run saved successfully");

            storefrontCommissionRunUtil.enrichViewModel(this.state.data);

            this.setState({})
        }
        catch (error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showCriticalAlert(`Storefront commission run save failed: ${error}`);
            this.setState({});
        }
    }
}

export default StorefrontCommissionRun;
