import React from 'react';
import Jumbotron from "../../../../../../components/jumbotron/jumbotron";

export default function NoPurchaseOrders({
    title = 'No purchase orders found',
    text = 'There are no existing purchase orders available',
    action = 'Hit Create button to create a new purchase order' })
{
    return <div>
        <Jumbotron>
            <h4>{title}</h4>
            <p className="lead">{text}</p>
            <hr className="my-2" />
            <p>{action}</p>
        </Jumbotron>
    </div>;
}
