const securityManager = require('../../../../../utils/domain/security/securityManager');
const constants = require('../../../../../utils/domain/constants');

module.exports.isEditingAllowed = function()
{
    if (securityManager.isSupportUser()) 
    {
        return securityManager.isSupportElevatedUser();
    }

    return true;
}


module.exports.getImageUrl = function (storefront) {
    if (storefront.logoImageUrl) return storefront.logoImageUrl;

    switch (storefront.type) {
        case constants.storefrontTypes.POS:
            return `app/back.office/storefronts/POS`;

        case constants.storefrontTypes.Deliveroo:
            return `app/back.office/storefronts/Deliveroo`;

        case constants.storefrontTypes.UberEats:
            return `app/back.office/storefronts/UberEats`;

        case constants.storefrontTypes.JustEats:
            return `app/back.office/storefronts/JustEats`;

        case constants.storefrontTypes.KIOSK:
            return `app/back.office/storefronts/KIOSK`;

        case constants.storefrontTypes.MobileApp:
            return `app/back.office/storefronts/MobileApp`;

        case constants.storefrontTypes.WebApp:
            return `app/back.office/storefronts/WebApp`;

        case constants.storefrontTypes.FlipdishKIOSK:
            return `app/back.office/storefronts/Flipdish`;

        case constants.storefrontTypes.FlipdishMobileApp:
            return `app/back.office/storefronts/Flipdish`;

        case constants.storefrontTypes.FlipdishWeb:
            return `app/back.office/storefronts/Flipdish`;

        case constants.storefrontTypes.ExternalApp:
            return `app/back.office/storefronts/ExternalApp`;

        default:
            return `app/back.office/storefronts/default`;
    }
};