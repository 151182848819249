import React, { Component } from "react";
import GridView from "../../../../../components/gridView/gridView";
import * as auditLogManager from "../../../../../utils/audits/auditLogManager";
import Brand from "./components/Brand";
import * as brandsUtil from "./brandsUtil";

const gridViewOptions = require("../../../../../components/gridView/gridViewOptions");
const auditLogConstants = require("../../../../../utils/constants/auditConstants");

class Brands extends Component {
    
    constructor(props)
    {
        super(props);
        this.loadBrands = this.loadBrands.bind(this);
        this.saveBrand = this.saveBrand.bind(this);
        this.gridViewOptions = this.getBrandsGridViewOptions();
    }

    render() 
    {
        return <GridView gridViewOptions={this.gridViewOptions} />
    }

    getBrandsGridViewOptions()
    {
        const gridOptions = new gridViewOptions();
        gridOptions.title = "Brands";
        gridOptions.getColumnDefinitions = brandsUtil.getGridColumns;
        gridOptions.gridDataMode = "async";
        gridOptions.getRowDataAsync = this.loadBrands;
        gridOptions.getPrimaryKeyValue = (row) => row.id;
        gridOptions.getNewRow = brandsUtil.getNewBrand;
        gridOptions.isReadOnly = this.props.isReadOnly;
        gridOptions.getComponent = (isNew, rowData) => <Brand row={rowData} isNew={isNew} isReadOnly={this.props.isReadOnly}/>;
        gridOptions.getComponentTitle = (isNew, row) => isNew ? 'Create New Brand' : `Configure ${row.name} Brand`;
        gridOptions.validate = brandsUtil.validate;
        gridOptions.save = this.saveBrand;

        return gridOptions
    }

    async loadBrands()
    {
        const brands = await brandsUtil.loadBrands();
        return brands;
    }

    async saveBrand(isNew, updatedRow, allRows)
    {
        const savedBrand = await brandsUtil.postBrand(updatedRow);
        
        const auditLogMessage = isNew ? `${savedBrand.name} brand has been created` : `${savedBrand.name} brand has been updated`;
        auditLogManager.addFranchisorAuditLog(auditLogConstants.action.franchisor.brands, auditLogMessage, updatedRow);

        const exisitngIndex = allRows.findIndex(row => row.id === savedBrand.id);
        if (exisitngIndex !== -1)
            allRows.splice(exisitngIndex, 1, savedBrand);
        else 
            allRows.push(savedBrand);

        return {
            remoteData: allRows,
            addUpdatedRow: savedBrand
        }
    }
}

export default Brands;