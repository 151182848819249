import React from "react";
import styled from "styled-components";

const ConnectedTypeLabel = ({ data }) => {

     if (!data) return null;

     return <Container color={data.color}>{data.label}</Container>;
};

export default ConnectedTypeLabel;

const Container = styled.div`
     position: absolute;
     bottom: 100%;
     left: 50%;
     transform: translateX(-50%);
     background-color: ${(props)=>props.color ?? "white"};
     padding: 8px 48px 2px 48px;
     color: #000000c0;
     font-weight: 700;
     font-size: 18px;
     border-top-right-radius: 15px;
     border-top-left-radius: 15px;
`;
