import React, { Component } from 'react';
import { Button } from 'reactstrap';

export default class ButtonCellRenderer extends Component
{
    btnClickedHandler = () =>
    {
        // this.props.value = header.field or the value returned by header.valueGetter 

        this.props.clicked(this.props.value);
    }

    render()
    {
        return (
            <Button
                disabled={this.props.isReadOnly}
                color='primary'
                className='px-3 py-1 my-1 text-small'
                onClick={this.btnClickedHandler}>{this.props.caption}</Button>
        );
    }
}