
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Modal, ModalBody, Row} from 'reactstrap';
import ScaleLoader from "react-spinners/ScaleLoader";

var stringUtil = require('../../utils/string/stringUtil.js');

class ModalSpinner extends Component 
{
    render()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.modalSpinnerDialogue))
            return null;

        return(
                <Modal isOpen={true} centered animation={false} fade={false}>
                    <ModalBody>
                        <div>

                        <Row className="justify-content-center">
                            <ScaleLoader
                                        height={50}
                                        width={6}
                                        color={"#808080"}
                                        loading={true}/>
                        </Row>
                        <Row className="justify-content-center" style={{margin:'5px'}}>
                            <h4 className="text-muted">{this.props.modalSpinnerDialogue}</h4>
                        </Row>
                        </div>
                        
                    </ModalBody>
                </Modal>
              )
    }
}

const mapStateToProps = state => 
{
    return { 
                modalSpinnerDialogue: state.modalSpinnerDialogue
           }
}

export default connect(mapStateToProps)(ModalSpinner)