
module.exports.isDefined = function(value)
{
    return typeof value != 'undefined';
}

module.exports.isUndefined = function(value)
{
    return !this.isDefined(value);
}

module.exports.isDefinedAndTrue = function(value)
{
    return this.isDefined(value) && value;
}

module.exports.isDefinedAndFalse = function(value)
{
    return this.isDefined(value) && !value;
}

module.exports.isObject = function(value)
{
    // null is an object in javascript
    
    return typeof value === 'object' && value !== null;
}

module.exports.deepCloneObject = function(value)
{
    return JSON.parse(JSON.stringify(value));
}

module.exports.getPropertyValue = function (obj, propertyName)
{
    //
    // Returns property value where propertyName could possibly be a nested property like "stockItem.code"
    // in which case returns the nested property value obj["stockItem"]["code"] (obj.stockItem.code)
    //

    let value = obj;

    const propertyNames = propertyName.split(".");

    for (let i = 0; i < propertyNames.length; i++)
    {
        value = value[propertyNames[i]];
    }

    return value;
}

module.exports.jsonStringify = function(value)
{
    return JSON.stringify(value, replacerJson)
}

module.exports.jsonParse = function(json)
{
    return JSON.parse(json, reviverJson);
}

function replacerJson(key, value) 
{
    if(value instanceof Map) 
    {
        return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } 
    else 
    {
        return value;
    }
}

function reviverJson(key, value) 
{
    if(typeof value === 'object' && value !== null) 
    {
        if (value.dataType === 'Map') 
        {
            return new Map(value.value);
        }
    }

    return value;
}
