import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import IncidentTypeOverview from './IncidentTypeOverview';
// import IncidentTypeProperties from './IncidentTypeProperties';
// import IncidentTypeConstraints from './IncidentTypeConstraints';
import IncidentTypeQuestions from './IncidentTypeQuestions';
// import IncidentTypeResponses from './IncidentTypeResponses';
// import IncidentTypeTokens from './IncidentTypeTokens';

import CreatedUpdatedHintsComponent from '../../utils/components/CreatedUpdatedHintsComponent';

import FormManager from '../../../../../utils/view/formManager';

export default class IncidentType extends Component
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
    }

    render() 
    {
        const tabHeaders = [];

        tabHeaders.push(<Tab>Overview</Tab>);
        tabHeaders.push(<Tab>Questions</Tab>);

        const tabContents = [];

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <IncidentTypeOverview isRowReadOnly={this.props.isRowReadOnly} formManager={this.formManager} />
        </TabPanel>)

        tabContents.push(<TabPanel style={{ marginTop: "30px" }}>
            <IncidentTypeQuestions isRowReadOnly={this.props.isRowReadOnly} formManager={this.formManager} />
        </TabPanel>)

        const incidentType = this.formManager.viewModel;

        return (
            <div>
                <CreatedUpdatedHintsComponent data={incidentType} />
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }
}

