const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const stringUtil = require('../../../../../utils/string/stringUtil');

export function createNew () 
{
    return {}
}

export async function save (data)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/addressOverrides`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function deleteConfiguration (id)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/addressOverrides/${id}`;
    return await rmsApiProxy.deleted(resourceUrl);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/addressOverrides`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{
    if (stringUtil.isStringNullOrEmpty(data.address))
        return "Address cannot be left empty";

    if (!stringUtil.isStringNullOrEmpty(data.deliveryCharges) && !validator.isGreaterThanOrEqualToZero(data.deliveryCharges))
        return "Delivery Charges must be a valid number greater than or equal to zero";

    return null;
}