import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';

import * as reportDataUtils from '../../utils/reportDataUtils';
import * as reportViewUtils from '../../../../../../views/reports/reportUtils/helperUtils/viewUtils';

export default function TabularReport(props)
{
    // Master rows = PurchaseOrder data grouped by Category

    const masterRows = getMasterRows(props);

    const totalsRow = getMasterTotalsRow(masterRows);

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForMasterRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getMasterGridColumns(),
        masterDetail: true,
        isRowMaster: row => row.categoryId != null,
        detailCellRendererParams: params => getFirstLevelDetailCellRendererParams(params, props),   // Renders StockItem Order details for the selected/expanded Category
    }

    return (
        <div className="ag-theme-alpine">
            <AgGridReact
                rowData={masterRows}
                pinnedBottomRowData={[totalsRow]}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
            />
        </div>
    );
}

//#region Master Rows = Orders grouped by Category

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            const totalsRow = getMasterTotalsRow(rows);
            e.api.setPinnedBottomRowData([totalsRow]);
        }
    });
}

function getMasterRows(props)
{
    const { reportData } = props;
    return reportDataUtils.getCategoryOrderSummary(reportData);
}

function getMasterTotalsRow(rows) 
{
    const totalRow = { orderCount: 0, orderAmount: 0, orderVatAmount: 0, receiptAmount: 0, receiptVatAmount: 0 };

    rows
        .filter(row => row != null)
        .forEach(row => 
        {
            totalRow.itemCount += row.itemCount;
            totalRow.orderAmount += row.orderAmount;
            totalRow.orderVatAmount += row.orderVatAmount;
            totalRow.receiptAmount += row.receiptAmount;
            totalRow.receiptVatAmount += row.receiptVatAmount;
        });

    return totalRow;
}

function getMasterGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "categoryId";
    header.type = "id";
    headers.push(header);

    header = {};
    header.field = "categoryName";
    header.headerName = "Category";
    header.cellRenderer = 'agGroupCellRenderer';
    headers.push(header);

    header = {};
    header.field = "itemCount";
    header.headerName = "Items";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "orderAmount";
    header.headerName = "Total Order Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "orderVatAmount";
    header.headerName = "Total Order Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptAmount";
    header.headerName = "Total Receipt Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptVatAmount";
    header.headerName = "Total Receipt Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

//#endregion

//#region First Level Child Rows = Order details for selected category

function getFirstLevelDetailCellRendererParams(params, props)
{
    // Renders StockItem Order details for the selected/expanded Category

    const { data } = params;
    const { reportData } = props;

    const firstLevelDetailRows = reportDataUtils.getOrderDetailsByCategoryId(data.categoryId, reportData);

    const { pagination, paginationPageSize } = biUtils.getDetailGridPaginationSettings(firstLevelDetailRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForDetailRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getFirstLevelDetailGridColumns(),
        masterDetail: true,
        isRowMaster: row => row.stockItemId != null,
        detailCellRendererParams: params => getSecondLevelDetailCellRendererParams(params, props)   // Renders Orders containing stockItem for the selected/expanded stockItem
    };

    return {
        detailGridOptions: gridOptions,
        getDetailRowData: params => params.successCallback(firstLevelDetailRows)
    };
}

function getFirstLevelDetailGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "stockItem";
    header.type = "id";
    headers.push(header);

    header = {};
    header.field = "stockItemName";
    header.headerName = "Stock Item";
    header.cellRenderer = 'agGroupCellRenderer';
    headers.push(header);

    header = {};
    header.field = "orderCount";
    header.headerName = "Orders";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "orderAmount";
    header.headerName = "Order Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "orderVatAmount";
    header.headerName = "Order Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptAmount";
    header.headerName = "Receipt Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptVatAmount";
    header.headerName = "Receipt Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "lastOrder";
    header.headerName = "Last Order Details";
    header.minWidth = 250;
    headers.push(header);

    return headers;
}

//#endregion

//#region Second Level Child Rows = Order details for selected stockItem

function getSecondLevelDetailCellRendererParams(params, props)
{
    // Renders Orders containing stockItem for the selected/expanded stockItem

    const { data } = params;
    const { reportData } = props;

    const secondLevelDetailRows = reportDataUtils.getOrderDetailsByStockItemId(data.stockItemId, reportData);

    const { pagination, paginationPageSize } = biUtils.getDetailGridPaginationSettings(secondLevelDetailRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForDetailRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getSecondLevelDetailGridColumns(),
    };

    return {
        detailGridOptions: gridOptions,
        getDetailRowData: params => params.successCallback(secondLevelDetailRows)
    };
}

function getSecondLevelDetailGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "orderNumber";
    header.headerName = "Order#";
    headers.push(header);

    header = {};
    header.field = "receiptDate";
    header.headerName = "Received On";
    header.type = dataTypeConstants.date;
    headers.push(header);

    header = {};
    header.field = "supplierName";
    header.headerName = "Supplier";
    headers.push(header);

    header = {};
    header.field = "orderUnitCode";
    header.headerName = "Order Units";
    headers.push(header);

    header = {};
    header.field = "orderQuantity";
    header.headerName = "Order Quantity";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "orderUnitPrice";
    header.headerName = "Order Unit Price";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "orderUnitVat";
    header.headerName = "Order Unit Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "orderAmount";
    header.headerName = "Order Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "orderVatAmount";
    header.headerName = "Order Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptUnitCode";
    header.headerName = "Receipt Units";
    headers.push(header);

    header = {};
    header.field = "receiptQuantity";
    header.headerName = "Receipt Quantity";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "receiptUnitPrice";
    header.headerName = "Receipt Unit Price";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptUnitVat";
    header.headerName = "Receipt Unit Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptAmount";
    header.headerName = "Receipt Amount";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "receiptVatAmount";
    header.headerName = "Receipt Vat";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

//#endregion
