import React, { Component } from 'react';
import { Button } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent';

const typeUtil = require('../../../../../utils/type/typeUtil');
const constants = require('../../../../../components/form/constants');

class QuestionTypeOptionsComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            isOptionAddNew: true,
            clonedOption: null
        }

        this.onAddOption = this.onAddOption.bind(this);
        this.onDeleteOption = this.onDeleteOption.bind(this);
        this.onSelectOption = this.onSelectOption.bind(this);
    }

    render()
    {
        const isReadOnly = this.props.isRowReadOnly;
        const formManager = this.props.formManager;
        const responseOptions = this.props.formManager.viewModel.responseOptions;
        const selectedOption = this.props.formManager.viewModel.selectedOption;

        const rowStyle = { cursor: "pointer" };
        const selectedRowStyle = { ...rowStyle, backgroundColor: "GoldenRod", fontWeight: "bold" };

        return  <div className='d-flex flex-column flex-nowrap pb-4'>
                    <div className='d-flex flex-row flex-nowrap px-4 py-2'>
                        <div style={{width: "90%"}}>
                            <InputComponent
                                inputType="identity"
                                caption="Response Options"
                                fieldName="selectedOption"
                                placeholder="Enter response options" 
                                hintText="(Required)"
                                inputReadOnly={isReadOnly}
                                formManager={formManager} />
                        </div>

                        <Button color="primary" style={{ width: "35px", height: "35px", marginTop: "29px", marginLeft: "10px" }}
                            disabled={isReadOnly}
                            onClick={this.onAddOption}>
                            <i className="fa fa-plus"></i>
                        </Button>
                    </div>
                    
                    <div className="d-flex flex-column flex-nowrap justify-content-start border rounded mx-4">
                        
                        <div className="d-flex flex-row flex-nowrap"
                            style={{ backgroundColor: "SlateGrey", color: "white", fontSize: "14px", fontWeight: "bold" }}>
                            <div style={{ width: "10%" }} className="border px-2 py-2"></div>
                            <div style={{ width: "90%" }} className="border px-2 py-2">Options</div>
                        </div>

                        <div className="d-flex flex-column flex-nowrap overflow-auto"
                            style={{                                    
                                maxHeight: "400px",
                                backgroundColor: isReadOnly ? constants.readOnlyBackgroundColor : null,
                                pointerEvents: isReadOnly ? "none" : null,
                            }}>
                            {
                                responseOptions.map(option =>
                                    <div
                                        className="d-flex flex-row flex-nowrap"
                                        style={ option == selectedOption ? selectedRowStyle : rowStyle }
                                        onDoubleClick={() => this.onSelectOption(option)}>

                                        <div
                                            className="border text-center px-2 py-1"
                                            style={{ width: "10%", fontSize: "18px", color: "red" }}
                                            onClick={(event) => this.onDeleteOption(event, option)}>

                                            <i className="fa fa-times" style={{ opacity: isReadOnly ? "0.25" : "1.0" }} />
                                        </div>
                                        <div style={{ width: "90%" }} className="border px-2 py-1">
                                            { option }
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                    </div>        
                </div>
    }

    onAddOption()
    {
        var responseOptions = this.props.formManager.viewModel.responseOptions;
        var selectedOption = this.props.formManager.viewModel.selectedOption;
        var isOptionAddNew = this.state.isOptionAddNew;
        var clonedOption;

        if(isOptionAddNew)
        {
            responseOptions.push(selectedOption);    
        }
        else
        {
            const index = responseOptions.indexOf(this.state.clonedOption);
            responseOptions[index] = selectedOption;
            clonedOption = typeUtil.deepCloneObject(selectedOption);
        }

        this.props.formManager.viewModel.selectedOption = "";
        this.setState({ selectedOption: null, clonedOption: clonedOption, isOptionAddNew: true })
    }

    onSelectOption(option)
    {
        if(!this.state.isOptionAddNew)
        {
            this.props.formManager.viewModel.selectedOption = "";
            this.setState({ clonedOption: null, isOptionAddNew: true });
        }
        else
        {
            var responseOptions = this.props.formManager.viewModel.responseOptions;;
            const index = responseOptions.indexOf(option);  // Reference equality    
            this.props.formManager.viewModel.selectedOption = responseOptions[index];
            this.setState({ clonedOption: typeUtil.deepCloneObject(option), isOptionAddNew: false });
        }
    }

    onDeleteOption(event, option)
    {
        var responseOptions = this.props.formManager.viewModel.responseOptions;;

        const index = responseOptions.indexOf(option);  // Reference equality

        if (index > -1)
        {
            responseOptions.splice(index, 1);
        }

        this.props.formManager.viewModel.selectedOption = "";
        this.setState({ clonedOption: null, isOptionAddNew: true });
        event.stopPropagation();
    }        
}

export default QuestionTypeOptionsComponent;