import { Component } from 'react';
import PrepProfile from './PrepProfile';
import RecipeProfiles from './RecipeProfiles';
import CheckComponent from '../../../../../../components/form/checkComponent';
import FormManager from '../../../../../../utils/view/formManager';

class RecipeAndPrepProfiles extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = props.data;
        this.state.formManager.view = this;
    }

    render()
    {
        return  <div>                    
                    <div style={{ paddingBottom: "20px" }}>
                        <PrepProfile {...this.props} />
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <RecipeProfiles {...this.props} />
                    </div>
                </div>
    }
}

export default RecipeAndPrepProfiles;