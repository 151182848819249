import BIToolBaseComponent from '../../../../bi/common/regularBITools/BIToolBaseComponent';

const localizationUtils = require('../../../../../utils/domain/localizationUtils');

class DailyChargesDetails extends BIToolBaseComponent
{
    render()
    {
        const data = this.props.data;

        return this.renderGrid(this.generateColumnDes(data), data);
    }

    generateColumnDes(data)
    {

        var uniquePropertyNames = [...new Set(data.map(e => e.propertyName))];
        var columnDefinitions = [];

        if (uniquePropertyNames.length > 1)
        {
            columnDefinitions.push({ field: 'franchisorName', type: 'rowGroup' });
            columnDefinitions.push({ field: 'scheme', type: 'rowGroup' });
            columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'dispatchType', type: 'rowGroup' });
            columnDefinitions.push({ field: 'internalName', type: 'rowGroup' });
            columnDefinitions.push({ field: 'propertyName', type: 'rowGroup' });
        }
        else
        {
            columnDefinitions.push({ field: 'propertyName', type: 'rowGroup' });
            columnDefinitions.push({ field: 'scheme', type: 'rowGroup' });
            columnDefinitions.push({ field: 'venueCode', type: 'rowGroup' });
            columnDefinitions.push({ field: 'dispatchType', type: 'rowGroup' });
            columnDefinitions.push({ field: 'internalName', type: 'rowGroup' });
        }


        columnDefinitions.push({ field: 'retailAmount', headerName: 'Retail Charge', type: 'money' });
        columnDefinitions.push({ field: 'refund', headerName: 'Charge Refund', type: 'money' });
        columnDefinitions.push({ valueGetter: ({ data }) => data.retailAmount - data.refund, headerName: 'Amount', type: 'money' });

        columnDefinitions.push({ field: 'tax', headerName: `Retail ${localizationUtils.getVatCaption()}`, type: 'money' });
        columnDefinitions.push({ field: 'refundTax', headerName: `Refund ${localizationUtils.getVatCaption()}`, type: 'money' });
        columnDefinitions.push({ valueGetter: ({ data }) => data.tax - data.refundTax, headerName: `${localizationUtils.getVatCaption()}`, type: 'money' });

        columnDefinitions.push({ valueGetter: ({ data }) => data.retailAmount - data.refund + data.tax - data.refundTax, headerName: "Total Amount", type: 'money' });
        


        columnDefinitions.push({ field: 'year', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'month', type: 'rowGroupEnabled, month' });
        columnDefinitions.push({ field: 'week', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfMonth', type: 'rowGroupEnabled' });
        columnDefinitions.push({ field: 'dayOfWeek', type: 'rowGroupEnabled' });

        return columnDefinitions;
    }
}

export default DailyChargesDetails;