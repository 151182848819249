
import React, { Component } from 'react';
import SimpleTooltip from "../../../../../../components/tooltip/simpleToolTip";

class SimpleToolbarButton extends Component 
{
    render()
    {
        const componentKey = `SimpleToolbarButton_${this.props.id}_${this.props.tag || this.props.caption}`;
        return (<span id={componentKey}>
            {this.props.button}
            <SimpleTooltip target={componentKey} text={this.props.tooltip} placement="top" />
        </span>)
    }
}

export default SimpleToolbarButton;