import React, { Component } from 'react';
import { Button, Card, CardBody, CardText } from 'reactstrap';
import { CloudinaryContext, Image } from 'cloudinary-react';

import PurchaseOrderStatus from '../purchaseOrder/PurchaseOrderStatus';
import NoPurchaseOrders from './NoPurchaseOrders';

import purchaseOrderUtil from '../../utils/purchaseOrderUtil';



const configurationManager = require('../../../../../../utils/config/configurationManager');
const domainConstants = require('../../../../../../utils/domain/constants');
const formatter = require('../../../../../../utils/formatter/formatter');
const localizationUtils = require('../../../../../../utils/domain/localizationUtils');

export default class PurchaseOrders extends Component
{
    render()
    {
        if (this.props.purchaseOrders.length == 0)
        {
            return <NoPurchaseOrders />
        }

        const purchaseOrderComponents = [];

        this.props.purchaseOrders.forEach(purchaseOrder =>
        {
            purchaseOrderComponents.push(this.renderPurchaseOrder(purchaseOrder));
        })

        return (<div>
            {purchaseOrderComponents}
        </div>);
    }

    renderPurchaseOrder(purchaseOrder)
    {
        const orderPrimaryLine = purchaseOrder.number;
        const createdOn = purchaseOrderUtil.getCreatedOn(purchaseOrder);
        const submittedOn = purchaseOrderUtil.getSubmittedOn(purchaseOrder);
        const receivedOn = purchaseOrderUtil.getReceivedOn(purchaseOrder);
        const numberOfItems = purchaseOrderUtil.getNumberOfItems(purchaseOrder);
        const vatCaption = localizationUtils.getVatCaption();

        let { totalPrice, totalAmount, totalVat } = purchaseOrderUtil.getApplicablePurchaseOrderTotalPrice(purchaseOrder);
        totalPrice = formatter.convertToCurrencyFormat(totalPrice);     // totalGross
        totalAmount = formatter.convertToCurrencyFormat(totalAmount);
        totalVat = formatter.convertToCurrencyFormat(totalVat);

        return (
            <Card
                style={{ userSelect: 'none', marginBottom: '10px', marginRight: '10px' }}
                onDoubleClick={() => { this.props.onEditPurchaseOrder(purchaseOrder); }}>

                <CardBody>

                    <div className="d-flex flex-row align-items-stretch">

                        <div className='pe-3 border-end'>{this.renderSupplierPanel(purchaseOrder)}</div>

                        <div className="px-3 border-end d-flex flex-column justify-content-center text-nowrap">

                            <div className='mb-1 fw-bold' style={{ fontSize: "1.25em" }}>{orderPrimaryLine}</div>

                            {
                                createdOn &&
                                <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                                    <span>Created:&nbsp;</span>
                                    <span>{createdOn}</span>
                                </div>
                            }
                            {
                                submittedOn &&
                                <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                                    <span>Submitted:&nbsp;</span>
                                    <span>{submittedOn}</span>
                                </div>
                            }
                            {
                                receivedOn &&
                                <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                                    <span>Received:&nbsp;</span>
                                    <span>{receivedOn}</span>
                                </div>
                            }

                            <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                                <span>Items count:&nbsp;</span>
                                <span>{numberOfItems}</span>
                            </div>

                            <div className="w-100">
                                <PurchaseOrderStatus data={purchaseOrder} lookupData={this.props.lookupData} />
                            </div>

                        </div>

                        <div className="px-3 border-end flex-grow-1 d-flex flex-column justify-content-center">

                            <div>
                                <div className="text-muted"><small>Instructions to supplier:</small></div>
                                <div className='text-justify'>{purchaseOrder.notes ?? 'No order instruction notes available'}</div>
                            </div>

                            {
                                (purchaseOrder.isDispatchedBySupplier || purchaseOrder.status === domainConstants.PurchaseOrderStatusType.received) &&
                                <>
                                    <div className="border-top mt-2"></div>
                                    <div>
                                        <div className="text-muted"><small>Supplier dispatch notes:</small></div>
                                        <div className='text-justify'>{purchaseOrder.receiptNotes ?? 'No supplier dispatch notes available'}</div>
                                    </div>
                                </>
                            }

                            {
                                // Highlight when a submitted order has been marked as dispatched by the supplier (central kitchen)
                                (purchaseOrder.status === domainConstants.PurchaseOrderStatusType.submitted && purchaseOrder.isDispatchedBySupplier) &&
                                <div className='py-2 mt-2 text-danger d-flex justify-content-center border rounded'>
                                    <small>This order has been marked as "Dispatched" by the supplier</small>
                                </div>
                            }
                        </div>

                        <div className="px-3 border-end d-flex flex-column justify-content-center text-nowrap">
                            {
                                totalPrice &&
                                <div className="w-100 mb-1 d-flex justify-content-between align-items-center fw-bold">
                                    <span>Total Gross Amount:&nbsp;</span>
                                    <span>{totalPrice}</span>
                                </div>
                            }
                            {
                                totalAmount &&
                                <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                                    <span>Total Amount:&nbsp;</span>
                                    <span>{totalAmount}</span>
                                </div>
                            }
                            {
                                totalVat &&
                                <div className="w-100 mb-1 d-flex justify-content-between align-items-center">
                                    <span>Total {vatCaption}:&nbsp;</span>
                                    <span>{totalVat}</span>
                                </div>
                            }
                        </div>

                        <div className="px-3 d-flex align-items-center">
                            <Button
                                color="primary" outline
                                onClick={() => { this.props.onEditPurchaseOrder(purchaseOrder); }}>
                                Open
                            </Button>
                        </div>

                    </div>

                </CardBody>
            </Card >);
    }

    renderSupplierPanel(purchaseOrder)
    {
        const suppliers = this.props.lookupData.suppliers;

        const supplier = suppliers.find(x => x.id === purchaseOrder.supplierId);
        const title = supplier.name;
        const logoImageUrl = supplier.logoImageUrl;

        return (
            <div className='p-2 d-flex flex-column align-items-center bg-light border rounded' style={{ width: "12.0em" }}>
                <div>{this.renderIcon(logoImageUrl)}</div>
                <div className="w-100 text-muted text-center">{title}</div>
            </div>
        );
    }

    renderIcon(logoImageUrl)
    {
        let cloudName;

        if (logoImageUrl != null)
        {
            cloudName = configurationManager.getConfig().cdnUsersCloudName;
        }
        else
        {
            logoImageUrl = 'app/back.office/icons/menu.icon.suppliers.svg';
            cloudName = configurationManager.getConfig().cdnAppCloudName;
        }

        return (<CloudinaryContext cloudName={cloudName}>
            <div>
                <Image publicId={logoImageUrl} width="48" height="48" />
            </div>
        </CloudinaryContext>);
    }
}