import arraySort from "array-sort";

export function getAggregatedDiscrepancyItems(reportData)
{
    // returns aggregated discrepancies by stockItem

    const { discrepancyData } = reportData;

    const aggregatedItems = [];

    discrepancyData.forEach(discrepancyItem => 
    {
        let aggregatedItem = aggregatedItems.find(item => item.stockItemId === discrepancyItem.stockItemId);

        if (aggregatedItem == null)
        {
            aggregatedItem = {
                stockItemId: discrepancyItem.stockItemId,
                stockItemName: discrepancyItem.stockItemName,
                itemType: discrepancyItem.itemType,
                reportingUnitCode: discrepancyItem.reportingUnitCode,
                adjustedQuantityInReportingUnits: 0,
                adjustedCost: 0,
                itemCount: 0
            };

            aggregatedItems.push(aggregatedItem);
        }

        aggregatedItem.adjustedQuantityInReportingUnits += discrepancyItem.adjustedQuantityInReportingUnits;
        aggregatedItem.adjustedCost += discrepancyItem.adjustedCost;
        aggregatedItem.itemCount++;
    });

    return arraySort(aggregatedItems, "stockItemName");
}

export function getDiscrepancyDetailItems(aggregatedStockItem, reportData)
{
    // returns date-wise discrepancy details by aggregatedStockItem.stockItemId

    const { discrepancyData } = reportData;

    const detailItems = [...discrepancyData.filter(discrepancyItem => discrepancyItem.stockItemId === aggregatedStockItem.stockItemId)];

    return arraySort(detailItems, ["businessDate", "dateTime"], { reverse: true });
}

export function getFlattenedWastageItems(reportData)
{
    const { discrepancyData } = reportData;

    return arraySort(discrepancyData, (a, b) =>
    {
        // custom compare function to sort by businessDate desc, stockItemName asc

        if (b.businessDate > a.businessDate) return 1;
        if (b.businessDate < a.businessDate) return -1;
        return a.stockItemName.localeCompare(b.stockItemName);
    });
}