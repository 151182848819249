
module.exports.canPlaceAnOrder = "can_place_an_order";
module.exports.canComplimentAnItem = "can_compliment_an_item";
module.exports.canApplyDiscount = "can_apply_discount";
module.exports.canCancelAnOrder = "can_cancel_an_order";
module.exports.canDeliverAnOrder = "can_deliver_an_order";
module.exports.canDoCashingUp = "can_do_cashing_up";
module.exports.canViewDailyReport = "can_view_daily_summary_report";
module.exports.canViewDriverSalesReport = "can_view_driver_sales_report";
module.exports.canOpenCashDrawer = "can_open_cash_drawer";
module.exports.canCreateAPurchaseOrder = "can_create_a_purchase_order";
module.exports.canSubmitAPurchaseOrder = "can_submit_a_purchase_order";
module.exports.canReceiveAPurchaseOrder = "can_receive_a_purchase_order";
module.exports.canChangeSettings = "can_change_settings";
module.exports.canChangeLayout = "can_change_layout";
module.exports.canViewBusinessInsights = "can_view_business_insights";