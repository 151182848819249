import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard.js';
import SalesDataGrid from './SaleDataGrid.jsx';

const formatter = require('../../../../../utils/formatter/formatter.js');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants.js');
const localizationUtils = require('../../../../../utils/domain/localizationUtils.js');
const nomenclatureUtils = require('../../../../../utils/domain/nomenclatureUtils.js');

const SaleCharges = (props) => {

    const totalCharges = props.sale.charges.reduce((previous, charge) => previous + charge.amount, 0);

    return <CollapsibleCard caption={`${nomenclatureUtils.getValue(nomenclatureUtils.attributes.saleCharges)} - ${formatter.convertToCurrencyFormat(totalCharges)}`} isDefaultOpen={props.isDefaultOpen}>
            <SalesDataGrid sale={props.sale} getColumnDefs={getColumnDefs} getData={() => getData(props.sale)} />
    </CollapsibleCard>

}
 
function getData(sale)
{
    return sale.charges;
}

function getColumnDefs()
{
    let header;
    const headers = [];

    header = {};
    header.field = "scheme";
    header.headerName = "Scheme";
    headers.push(header);

    header = {};
    header.field = "internalName";
    header.headerName = "Internal Name";
    headers.push(header);

    header = {};
    header.field = "displayName";
    header.headerName = "Display Name";
    header.suppressSizeToFit = false;
    header.type = dataTypeConstants.quantity;
    headers.push(header);

    header = {};
    header.field = "amount";
    header.headerName = "Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "retailAmount";
    header.headerName = "Retail Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "tax";
    header.headerName = localizationUtils.getVatCaption();
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "refund";
    header.headerName = "Refund";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "refundTax";
    header.headerName = `Refund ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

export default SaleCharges;