import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView'
import Category from './Category'

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const stringUtil = require('../../../../utils/string/stringUtil');
const validator = require('../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const domainConstants = require('../../../../utils/domain/constants');
const commonUtility = require('../../../../utils/domain/commonUtility');
const securityManager = require('../../../../utils/domain/security/securityManager');

// In the context of centralKitchen, categories can now be manipulated at property node. So the code must not
// assume that a franchisor node is always currently selected

class Categories extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadCategoriesAsync = this.loadCategoriesAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    get isCentralKitchenContext() 
    {
        return commonUtility.isCentralKitchenPropertySelected();
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Stock Item Categories";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadCategoriesAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () => 
        {
            const newRow = {};
            newRow.franchisorId = currentOrgNode.franchisorId;
            newRow.type = this.isCentralKitchenContext
                ? domainConstants.serviceContextTypes.centralKitchen
                : domainConstants.serviceContextTypes.store;
            return newRow;
        };

        gridViewOptions.getComponent = (isNew, row) => <Category data={row} isRowReadOnly={this.props.isReadOnly} />;
        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Stock Item Category - ${row.name}` : 'Stock Item Category - New';

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new category.";
            return "Please wait while updating category.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New category is added successfully";
            return "Category is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new category:";
            return "Following error occurred while updating category:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    async loadCategoriesAsync()
    {
        const type = this.isCentralKitchenContext
            ? domainConstants.serviceContextTypes.centralKitchen
            : domainConstants.serviceContextTypes.store;

        try
        {
            let categories = await apiLoadFacade.loadInventoryCategories();
            if (categories == null) categories = [];
            categories = categories.filter(category => category.type === type);
            return arraySort(categories, stringUtil.localeCompareProp("name"));
        }
        catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.name))
            return "Category name cannot be left empty";

        if (!validator.isPresent(row.franchisorId))
            return "franchisorId cannot be left empty";

        if (!validator.isValidEnumeration(row.type, domainConstants.serviceContextTypes))
            return "Invalid category type";

        return null;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
            const resourceUrl = `${baseUrl}/inventory/categories`;

            const updatedCategory = await rmsApiProxy.post(resourceUrl, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(category => category.id == updatedCategory.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedCategory);
            else
                remoteData.push(updatedCategory);

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedCategory;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    constructGridColumnHeaders()
    {

        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.type = dataTypeConstants.id;
        header.headerName = "Id";
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.type = columnWidthConstants.name;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "foregroundColor";
        header.headerName = "Foreground";
        header.type = dataTypeConstants.color;
        header.width = 125;
        headers.push(header);

        header = {};
        header.field = "backgroundColor";
        header.headerName = "Background";
        header.type = dataTypeConstants.color;
        header.width = 125;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Categories;
