
import React, { Component } from 'react';
import { Button } from 'reactstrap';

import GridComponent from '../../../../../../components/grid/gridComponent';
import PropertyLevelOverride from './PropertyLevelOverride';
import StockItemSections from '../StockItemSections';

const typeUtil = require('../../../../../../utils/type/typeUtil');
const viewUtil = require('../../../../../../utils/view/viewUtil');
const validator = require('../../../../../../utils/validator/validator');

const columnWidthConstants = require('../../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../../utils/constants/dataTypeConstants');
const orgSelectors = require('../../../../../../utils/state/stateSelectors/orgSelectors');


class PropertyLevelOverrides extends Component 
{
    constructor(props) 
    {
        // props: {stockItems:(Array) data:(stockItem) isNew:bool isReadOnly:bool isRowReadOnly:bool}
        // isRowReadOnly = cannot edit records is used here

        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);

        this.onAddDataRow = this.onAddDataRow.bind(this);
        this.onRemoveDataRow = this.onRemoveDataRow.bind(this);
        this.validateDataRow = this.validateDataRow.bind(this);
        this.addEditDataRow = this.addEditDataRow.bind(this);
        this.saveDataRow = this.saveDataRow.bind(this);
    }

    get stockItem()
    {
        return this.props.data;
    }

    get isRowReadOnly()
    {
        return this.props.isRowReadOnly;
    }

    render()
    {
        const deleteButtonDisabled = this.findSelectedRow() == null;

        return  <StockItemSections title="Property Level Overrides">
                    <div className="d-flex flex-column">

                        {
                            this.isRowReadOnly
                                ? null
                                : <div className="d-flex flex-row justify-content-end pb-3">
                                    <Button className="me-2" color="primary" onClick={this.onAddDataRow}>
                                        <i className="fa fa-plus"></i>&nbsp;Add Override 
                                    </Button>
                                    <Button color="primary" disabled={deleteButtonDisabled} className="btn-danger me-2" onClick={this.onRemoveDataRow}>
                                        <i className="fa fa-remove"></i>&nbsp;Remove Override
                                    </Button>
                                </div>
                        }

                        <div className="w-100">
                            <GridComponent
                                headers={this.constructGridColumnHeaders()}
                                rows={this.stockItem.propertyLevelOverrides}
                                onRowDoubleClicked={this.onRowDoubleClicked}
                                onRowSelected={this.onRowSelected}
                                shouldReArrangeHeaders={true}
                                onGridReady={this.onGridReady} />
                        </div>

                    </div>
                </StockItemSections>
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;

        return selectedNodes[0].data;
    }

    onAddDataRow()
    {
        const propertyLevelOverride = { stockItemId: this.stockItem.id, propertyId: null, minimumInventoryQuantity: null }
        this.addEditDataRow(true, propertyLevelOverride);
    }

    onRemoveDataRow()
    {
        const propertyLevelOverride = this.findSelectedRow();
        if (propertyLevelOverride == null) return;

        viewUtil.showConfirmDialogue(
            'Remove Override',
            `You are going to property level override for property '${this.resolvePropertyCode(propertyLevelOverride.propertyId)}'. Are you sure ?`,
            () =>
            {
                this.stockItem.propertyLevelOverrides =
                    this.stockItem.propertyLevelOverrides.filter(x => x.propertyId != propertyLevelOverride.propertyId);
                this.setState({});
            });
    }

    onRowDoubleClicked(eventData)
    {
        const propertyLevelOverride = this.findSelectedRow();
        if (propertyLevelOverride == null) return;

        this.addEditDataRow(false, propertyLevelOverride);
    }

    addEditDataRow(isNewRow, propertyLevelOverride)
    {
        const newpropertyLevelOverride = typeUtil.deepCloneObject(propertyLevelOverride);

        viewUtil.openModalForm(
            "Add Property Level Override",
            onFieldChanged =>
                <PropertyLevelOverride
                    isNewRow={isNewRow}
                    data={newpropertyLevelOverride}
                    stockItem={this.stockItem}
                    isRowReadOnly={this.isRowReadOnly} />,
                    () => { this.saveDataRow(isNewRow, propertyLevelOverride, newpropertyLevelOverride) },
                    () => { return this.validateDataRow(isNewRow, newpropertyLevelOverride) },
            this.isRowReadOnly);
    }

    saveDataRow(isNewRow, originalpropertyLevelOverride, newpropertyLevelOverride)
    {
        this.stockItem.propertyLevelOverrides =
            this.stockItem.propertyLevelOverrides.filter(
                propertyLevelOverride => !(propertyLevelOverride.propertyId === originalpropertyLevelOverride.propertyId));

        this.stockItem.propertyLevelOverrides.push(newpropertyLevelOverride);
        this.setState({});
    }

    validateDataRow(isNewRow, propertyLevelOverride)
    {
        if (!validator.isPresent(propertyLevelOverride.propertyId))
            return "property selection is required cannot be left empty";

        if (propertyLevelOverride.minimumInventoryQuantity && !validator.isNumeric(propertyLevelOverride.minimumInventoryQuantity))
            return "Minimum inventory quantity must be a number";

        if (isNewRow)
        {
            const existingpropertyLevelOverride =
                this.stockItem.propertyLevelOverrides.find(x => x.propertyId === propertyLevelOverride.propertyId);

            if (existingpropertyLevelOverride != null)
            {
                return `Property Level Override with matching Property '${this.resolvePropertyCode(existingpropertyLevelOverride.propertyId)}' already exists`;
            }
        }

        return null;
    }

    resolvePropertyCode(propertyId)
    {
        const property = orgSelectors.selectProperties().find(x => x.id === propertyId)
        return property.name;
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.headerName = "Property";
        header.pinned = true;
        header.colId = "propertyId";
        header.valueGetter = params =>
        {
            return this.resolvePropertyCode(params.data.propertyId);
        }
        header.width = columnWidthConstants.code;
        header.suppressSizeToFit = false;
        headers.push(header);

        header = {};
        header.headerName = `Minimum Inventory Quantity ${this.stockItem.coreUnitCode ? "(" + this.stockItem.coreUnitCode + ")" : ""}`;
        header.field = "minimumInventoryQuantity";
        header.type = dataTypeConstants.floatNumber;
        header.width = columnWidthConstants.name;
        headers.push(header);

        return headers;
    }
}

export default PropertyLevelOverrides;


