
const catalogSelectors = require('../state/stateSelectors/catalogSelectors');

export function filterStockItemSuppliers(suppliers)
{
    const serviceTypes = catalogSelectors.selectLookupData().serviceTypes;

    return suppliers.filter(
        supplier =>
            supplier.serviceProfiles.some(
                serviceProfile => 
                {
                    const serviceType = serviceTypes.find(serviceType => serviceType.id == serviceProfile.serviceTypeId);
                    return serviceType.isStockItemSupplier;
                }));
}
