import React, { useState } from "react";
import GridView from "../../../../../components/gridView/gridView";
import GridViewOptions from "../../../../../components/gridView/gridViewOptions";
import * as chargeUtils from "./chargeUtils";
import Charge from "./components/Charge";
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const auditConstants = require('../../../../../utils/constants/auditConstants.js');
const currentOrgNodeSelectors = require("../../../../../utils/state/stateSelectors/currentOrgNodeSelectors.js");


function Charges(props)
{
    const [storefrontsOptions, setStorefrontsOptions] = useState([]);
    
    async function fetchData() 
    {
        const storefrontsOptions = await chargeUtils.getStorefrontOptions();
        setStorefrontsOptions(storefrontsOptions);
        const charges = await chargeUtils.getCharges();
        charges.forEach(charge => chargeUtils.mapDataToRow(charge, storefrontsOptions, chargeUtils.getPropertyOptions()));
        return charges;
    }

    async function saveCharge(isNew, updatedRow, allRows)
    {        
        chargeUtils.mapRowToData(updatedRow);
        const newCharge = await chargeUtils.postCharge(updatedRow);

        const message = isNew ? `${newCharge.displayName} has been created` : `${newCharge.displayName} has been altered`;

        await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.charges, message, updatedRow);

        chargeUtils.mapDataToRow(newCharge, storefrontsOptions, chargeUtils.getPropertyOptions());

        const existingIndex = allRows.findIndex(row => row.id === newCharge.id);
        if (existingIndex !== -1)
            allRows.splice(existingIndex, 1, newCharge);
        else
            allRows.push(newCharge);

        return {
            remoteData: allRows,
            addUpdatedRow: newCharge
        }

    }

    const gridViewOptions = new GridViewOptions();
    gridViewOptions.title = "Charges Configuration";
    gridViewOptions.getColumnDefinitions = chargeUtils.getGridColumns;
    gridViewOptions.getPrimaryKeyValue = (row) => row.id;
    gridViewOptions.isRowReadOnly = () => props.isReadOnly;
    gridViewOptions.gridDataMode = "async";
    gridViewOptions.getRowDataAsync = fetchData;
    gridViewOptions.getComponent = (isNewRow, rowData) => <Charge row={rowData} isNew={isNewRow} storefrontOptions={storefrontsOptions} />;
    gridViewOptions.getComponentTitle = (isNew, row) => isNew ? 'Create New Charge' : `Configure - ${row.displayName}`
    gridViewOptions.getNewRow = chargeUtils.getEmptyCharge;
    gridViewOptions.validate = chargeUtils.validateCharge
    gridViewOptions.save = saveCharge;
    gridViewOptions.isReadOnly = props.isReadOnly;

    return (
        <GridView gridViewOptions={gridViewOptions} />
    )
}


export default Charges;