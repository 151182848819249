import React, { Component } from 'react';
import CdnImageUploader from '../../../../../../components/cdnImage/cdnImageUploader';

const FormManager = require('../../../../../../utils/view/formManager.js');
const configurationManager = require('../../../../../../utils/config/configurationManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

class WebAppLogo extends Component 
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }
    
    render()
    {
        const storefront = this.props.storefront;
        const imageFolder = `franchisors/${storefront.franchisorId}/franchisees/${storefront.franchiseeId}/properties/${storefront.propertyId}/storefronts/${storefront.id}/webApp`;
        return (

            <CdnImageUploader
                isReadOnly={this.isReadOnly}
                cloudName={configurationManager.getConfig().cdnUsersCloudName}
                imageFolder={imageFolder}
                imageUrl={this.props.data.favIconUrl}
                onImageChange={(url) => {
                    this.props.data.favIconUrl = url;
                    this.props.onImageUpdate();
                }}>
                <h4 className="display-5">No Image Found</h4>

                <p className="lead">A icon is much more than just an image; it is start of settings standards and following conventions.</p>
                <hr className="my-2" />
                <p>A well-designed icon effectively conveys the message.</p>
            </CdnImageUploader>

        )
    }

}
export default WebAppLogo;