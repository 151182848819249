
import React, { Component } from "react";
import SelectComponent from '../../../../../../components/form/selectComponent.js';
import MultiSelectComponent from '../../../../../../components/form/multiSelectComponent.js';
import DateTimeInputComponent from '../../../../../../components/form/dateTimeInputComponent.js';

const constants = require('../../../../../../utils/domain/constants.js');
const FormManager = require('../../../../../../utils/view/formManager.js');

class BusinessHourOverride extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        if (this.state.formManager.viewModel.isOpen == null)
            this.state.formManager.viewModel.isOpen = false;

        this.isStoreOpen =
            [
                {
                    label: "Open",
                    value: true
                },
                {
                    label: "Closed",
                    value: false
                }
            ]

        this.hours = [];
        for (let i = 0; i < 24; i++)
        {
            this.hours.push({ value: i });
        }

        this.minutes = [];
        for (let i = 0; i < 60; i++)
        {
            this.minutes.push({ value: i });
        }

        this.onDispatchTypesSelectionChanged = this.onDispatchTypesSelectionChanged.bind(this);
        this.getSelectedDispatchTypes = this.getSelectedDispatchTypes.bind(this);
    }

    getSelectedDispatchTypes()
    {
        const dispatchTypes = this.props.data.dispatchTypes || [];
        return constants.getDispatchTypes().filter(dispatchType => dispatchTypes.includes(dispatchType.dispatchType));
    }

    onDispatchTypesSelectionChanged(selectedOptions)
    {
        let dispatchTypes = [];

        if (selectedOptions.length > 0)
        {
            dispatchTypes = selectedOptions.map(option => option.dispatchType);
        }

        this.props.data.dispatchTypes = dispatchTypes;
        this.setState({});
    }

    render()
    {
        const paddingLeft = { paddingLeft: "10px" };
        const paddingRight = { paddingRight: "10px" };

        return (
            <table className='component-table'>
                <tbody>
                    <tr>
                        <td style={paddingRight}>
                            <SelectComponent
                                optionValues={this.isStoreOpen}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                caption="Open / Close Override"
                                fieldName="isOpen"
                                hintText="Select whether the override is to open or close the store"
                                clearable={false}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td style={paddingRight}>
                            <MultiSelectComponent
                                caption="Dispatch Types"
                                valueFieldName="dispatchType"
                                displayFieldName="dispatchType"
                                options={constants.getDispatchTypes()}
                                selectedOptions={this.getSelectedDispatchTypes()}
                                onSelectionChanged={this.onDispatchTypesSelectionChanged}
                                hintText="Select dispatch types"
                                comboReadOnly={this.props.isReadOnly} />
                        </td>
                    </tr>
                    <tr>
                        <td style={paddingRight}>
                            <DateTimeInputComponent
                                inputType="dateTime" 
                                caption="Start Date" 
                                hintText="Start date and time (Required)"
                                fieldName="fromDateTime"
                                placeholder="Enter start date and time of the override"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td style={paddingLeft}>
                            <DateTimeInputComponent
                                inputType="dateTime" 
                                caption="End Date" 
                                hintText="End date and time (Required)"
                                fieldName="toDateTime"
                                placeholder="Enter end date and time of the override"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default BusinessHourOverride;

