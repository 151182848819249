const dateUtil = require('./dateUtil');
const moment = require('moment');
const humanizeDuration = require('humanize-duration');

module.exports.getSamePeriodLastYearByWeekDay = function (startDate, endDate)
{
    const startDateMoment = moment.utc(startDate);
    const endDateMoment = moment.utc(endDate);
    const diffInDays = endDateMoment.diff(startDateMoment, 'days');

    const fromSameWeekdayLastYearMoment =
        startDateMoment
            .clone()
            .subtract(1, 'year')
            .isoWeek((startDateMoment.isoWeek()))
            .isoWeekday(startDateMoment.isoWeekday());

    // Code to check and handle edge cases which occur for periods near end/new year

    if (fromSameWeekdayLastYearMoment.year() > startDateMoment.clone().subtract(1, 'years').year())
    {
        // Restore year and isoWeekday, ignoring isoWeek in this case
        fromSameWeekdayLastYearMoment.subtract(1, 'years').isoWeekday(startDateMoment.isoWeekday());
    }

    const toSameWeekdayLastYearMoment = fromSameWeekdayLastYearMoment.clone().add(diffInDays, 'days');

    return {
        startDateLastYear: fromSameWeekdayLastYearMoment.format(dateUtil.getDateFormat()),
        endDateLastYear: toSameWeekdayLastYearMoment.format(dateUtil.getDateFormat())
    }
}

module.exports.getSamePrecedingPeriod = function (startDate, endDate)
{
    // Returns same size preceding period preceding, so
    // 2022-08-03 to 2022-08-04 -> 2022-08-01 to 2022-08-02

    const startDateMoment = moment.utc(startDate);
    const endDateMoment = moment.utc(endDate);
    const diffInDays = endDateMoment.diff(startDateMoment, 'days');

    const fromPrecedingMoment =
        startDateMoment
            .clone()
            .subtract(diffInDays + 1, 'day');

    const toPrecedingMoment = fromPrecedingMoment.clone().add(diffInDays, 'days');

    return {
        fromPrecedingDate: fromPrecedingMoment.format(dateUtil.getDateFormat()),
        toPrecedingDate: toPrecedingMoment.format(dateUtil.getDateFormat())
    }
}

module.exports.getDayOfTheWeek = function (date)
{
    return moment.weekdays(moment.utc(date).weekday());
}

module.exports.convertToLocalHourSegment = function (dateTime)
{
    const localDateTime = dateUtil.convertToLocalStandard(dateTime);
    let localHour = dateUtil.getHours(localDateTime);
    localHour = localHour < 10 ? `0${localHour}` : `${localHour}`;
    let localMinute = dateUtil.getMinutes(localDateTime);
    localMinute = localMinute < 10 ? `0${localMinute}` : `${localMinute}`;

    const localHourSegment = `${localHour}:${localMinute}`;
    return localHourSegment;
}

module.exports.convertMilliSecondsToHoursAndMinutes = function (timeInMilliSeconds) 
{
    if (timeInMilliSeconds)
    {
        return humanizeDuration(timeInMilliSeconds, { units: ['h', 'm'], round: true });;
    } else
    {
        return "N/A";
    }
}

module.exports.convertAndFormatToLocalTime = function (localDate, localHour, localMinute)
{
    let dateTime = dateUtil.setDateComponent(
        dateUtil.getToday(),
        dateUtil.getDay(localDate),
        dateUtil.getMonth(localDate),
        dateUtil.getYear(localDate)
    );
    dateTime = dateUtil.setHours(dateTime, localHour);
    dateTime = dateUtil.setMinutes(dateTime, localMinute);
    dateTime = dateUtil.setSeconds(dateTime, 0);
    dateTime = dateUtil.convertToUTCStandard(dateTime);

    const time = dateUtil.formatDate(dateUtil.convertToLocalStandard(dateTime), "HH:mm");
    return time;
}