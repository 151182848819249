
import React, { Component } from 'react';
import SelectComponent from './selectComponent';

export default class BooleanSelectComponent extends Component 
{
    constructor(props)
    {
        super(props)

        this.options = [
            {
                value: true,
                text: "Yes"
            }, {
                value: false,
                text: "No"
            }]
    }
    render()
    {
        return <SelectComponent {...this.props} optionValues={this.options}
            optionFieldName="value"
            optionDisplayFieldName="text"
        ></SelectComponent>
    }
}