import React from "react";
import { FormText } from 'reactstrap';

import stringUtil from '../../../../../utils/string/stringUtil';
import dateUtil from '../../../../../utils/dateUtil/dateUtil';

export default function CreatedUpdatedHintsComponent(props)
{
    let createdByHint, lastUpdatedByHint;

    const data = props.data;

    if (stringUtil.isStringNullOrEmpty(data.createdBy))
        return null;

    createdByHint = `Created by ${data.createdByName} on ${dateUtil.convertToLocalStandard(data.createdOn)}`;

    if (!stringUtil.isStringNullOrEmpty(data.lastUpdatedBy))
    {
        lastUpdatedByHint = `Last updated by ${data.lastUpdatedByName} on ${dateUtil.convertToLocalStandard(data.lastUpdatedOn)}`;
    }

    return (
        <div style={{ float: "right", margin: "-15px 10px 0px 0px" }}>
            <FormText color="muted">{createdByHint}</FormText>
            {
                lastUpdatedByHint
                    ? <FormText color="muted">{lastUpdatedByHint}</FormText>
                    : null
            }
        </div>
    );
}