import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';

import * as reportDataUtils from '../../utils/reportDataUtils';

class PurchasesByRawData extends Component
{
    render()
    {
        const { reportData } = this.props;
        const rowData = reportDataUtils.getPurchasesByRawData(reportData);
        const totalsRow = this.getTotalsRow(rowData);

        return (
            <div className="ag-theme-alpine" style={{ height: "80vh" }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={rowData}
                    columnDefs={this.getColumnDefs()}
                    pinnedBottomRowData={[totalsRow]}
                    gridOptions={biUtils.gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    onGridReady = (params) =>
    {
        params.api.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay)
            {
                const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
                const totalsRow = this.getTotalsRow(rows);
                e.api.setPinnedBottomRowData([totalsRow]);
            }
        });
    }

    getTotalsRow = (rows) =>
    {
        const totalRow = { orderAmount: 0, orderVatAmount: 0, receiptAmount: 0, receiptVatAmount: 0 };

        rows
            .filter(row => row != null)
            .forEach(row => 
            {
                totalRow.orderAmount += row.orderAmount;
                totalRow.orderVatAmount += row.orderVatAmount;
                totalRow.receiptAmount += row.receiptAmount;
                totalRow.receiptVatAmount += row.receiptVatAmount;
            });

        return totalRow;
    }


    getColumnDefs = () =>
    {
        const columnDefs = [];

        columnDefs.push({ field: 'category', type: 'rowGroupEnabled', hide: false });
        columnDefs.push({ field: 'stockItem', type: 'rowGroupEnabled', hide: false });

        columnDefs.push({ field: 'supplier', type: 'rowGroupEnabled', hide: false });

        columnDefs.push({ field: 'orderNumber', type: 'rowGroupEnabled', hide: false });
        columnDefs.push({ field: 'submissionDate', type: `${dataTypeConstants.date}, rowGroupEnabled`, hide: false });
        columnDefs.push({ field: 'receiptDate', type: `${dataTypeConstants.date}, rowGroupEnabled`, hide: false });

        columnDefs.push({ field: 'orderUnitCode', headerName: 'Order Units' });
        columnDefs.push({ field: 'orderQuantity', type: `${dataTypeConstants.number}` });
        columnDefs.push({ field: 'orderUnitPrice', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'orderUnitVat', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'orderAmount', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'orderVatAmount', type: `${dataTypeConstants.money}` });

        columnDefs.push({ field: 'receiptUnitCode', headerName: 'Receipt Units' });
        columnDefs.push({ field: 'receiptQuantity', type: `${dataTypeConstants.number}` });
        columnDefs.push({ field: 'receiptUnitPrice', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'receiptUnitVat', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'receiptAmount', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'receiptVatAmount', type: `${dataTypeConstants.money}` });

        return columnDefs;
    }
}

export default PurchasesByRawData;