import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';

const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

class VatDetailData extends Component
{
    render()
    {
        const { vatDetailData } = this.props;

        const totalRow = {
            totalTax: vatDetailData.reduce((previous, current) => previous + current.totalTax, 0),
            totalRetailOrderPrice: vatDetailData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
            originalTotalTax: vatDetailData.reduce((previous, current) => previous + current.originalTotalTax, 0),
            originalTotalRetailOrderPrice: vatDetailData.reduce((previous, current) => previous + current.originalTotalRetailOrderPrice, 0),
        }

        return (
            <div className="ag-theme-alpine" style={{ height: '62vh' }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={vatDetailData}
                    pinnedBottomRowData={[totalRow]}
                    gridOptions={biUtils.gridOptions}
                    columnDefs={this.getColumnDefs()}
                    onGridReady={this.onGridReady}
                />
            </div>);
    }

    onGridReady(params)
    {
        this.gridApi = params.api;

        this.gridApi.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay)
            {
                const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);

                const pinnedRows = [
                    {
                        totalTax: rows.reduce((previous, current) => previous + current.totalTax, 0),
                        totalRetailOrderPrice: rows.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0),
                        originalTotalTax: rows.reduce((previous, current) => previous + current.originalTotalTax, 0),
                        originalTotalRetailOrderPrice: rows.reduce((previous, current) => previous + current.originalTotalRetailOrderPrice, 0),
                    }
                ]

                e.api.setPinnedBottomRowData(pinnedRows);
            }
        });
    }

    getColumnDefs = () =>
    {
        const columnDefinitions = [];

        columnDefinitions.push({ field: 'propertyName', type: 'rowGroupEnabled', hide: currentOrgNodeSelectors.isCurrentPropertySelected() });
        columnDefinitions.push({ field: 'storefrontName', type: 'rowGroupEnabled', hide: false });

        columnDefinitions.push({ field: 'venueCode', type: 'rowGroupEnabled', hide: false });
        columnDefinitions.push({ field: 'dispatchType', type: 'rowGroupEnabled', hide: false });

        columnDefinitions.push({ field: 'category', type: 'rowGroupEnabled', hide: false });
        columnDefinitions.push({ headerName: "Item", field: 'caption', type: 'rowGroupEnabled', hide: false });
        columnDefinitions.push({ field: 'vatPercentage', type: 'percentage, rowGroupEnabled', hide: false });

        columnDefinitions.push({ field: 'totalQuantity', headerName: "Quantity", type: 'fixedNumber' });
        
        columnDefinitions.push({ headerName: "Retail Tax", field: 'totalTax', type: 'money', sort: 'desc' });
        columnDefinitions.push({ headerName: "Retail Price", field: 'totalRetailOrderPrice', type: 'money' });

        columnDefinitions.push({ headerName: "Original Retail Tax", field: 'originalTotalTax', type: 'money' });
        columnDefinitions.push({ headerName: "Original Retail Price", field: 'originalTotalRetailOrderPrice', type: 'money' });

        return columnDefinitions;
    }
}

export default VatDetailData;