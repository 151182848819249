
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux'

import Jumbotron from "../../../../components/jumbotron/jumbotron";
import ErrorMessage from '../../../../components/error/errorMessage';
import Spinner from '../../../../components/spinner/spinner';
import SelectComponent from '../../../../components/form/selectComponent';
import CloseButton from '../../../../components/button/closeButton';
import FormManager from '../../../../utils/view/formManager';

import StorefrontCommissionRun from './components/StorefrontCommissionRun';

const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');
const stringUtil = require('../../../../utils/string/stringUtil');


class StorefrontCommissionRuns extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            loading: false,
            saving: false,
            error: null,

            propertyStorefronts : [],
            propertyStorefrontOperatingConfigurations: [],

            data: {
                propertyId: currentOrgNodeSelectors.selectCurrentOrgNode().propertyId,
                storefrontId: null,
            },

            formManager: new FormManager()
        }

        this.state.formManager.viewModel = this.state.data;
        this.state.formManager.view = this;
    }

    render()
    {
        if (this.state.loading)
            return <Spinner text='Loading Data'></Spinner>;

        if (this.state.error)
            return <ErrorMessage message={this.state.error} />;

        return <Col>
            {this.renderSelection()}
            {this.renderStorefrontCommissionRun()}
        </Col>
    }

    async componentDidMount()
    {
        this.setState({loading: true});

        try 
        {
            const propertyStorefronts = await apiLoadFacade.loadPropertyStorefronts();
            const propertyStorefrontOperatingConfigurations = await apiLoadFacade.loadPropertyStorefrontOperatingConfigurations();

            this.setState({propertyStorefronts: propertyStorefronts, propertyStorefrontOperatingConfigurations: propertyStorefrontOperatingConfigurations, loading: false});            
        } 
        catch (error) 
        {
            this.setState({loading: false, error: error});
        }        
    }

    renderSelection()
    {        
        return (
            <div className="d-flex justify-content-between align-items-top">
                <div className="w-50">
                    <SelectComponent
                        optionValues={this.state.propertyStorefronts}
                        optionFieldName="id"
                        optionDisplayFieldName="name"
                        fieldName="storefrontId"
                        caption="Storefront"
                        hintText="(Required)"
                        formManager={this.state.formManager}
                        clearable={false}
                    />
                </div>
                <div>
                    <CloseButton disabled={this.state.loading || this.state.saving} />
                </div>
            </div>
        );
    }

    renderStorefrontCommissionRun()
    {
        if (this.state.data.propertyId == null || this.state.data.storefrontId == null)
        {
            return <Jumbotron className="me-3">
                <p className="lead">Select the Storefront for the Commission run</p>
                <hr className="my-2" />
                <p>To calculate commission choose the storefront against which to commission is paid.</p>
            </Jumbotron>
        }

        if(this.state.data.storefrontId)
        {
            const storefrontOperatingConfiguration = this.state.propertyStorefrontOperatingConfigurations.find(config => stringUtil.areStringSame(config.storefrontId, this.state.data.storefrontId));
            if(!storefrontOperatingConfiguration || !storefrontOperatingConfiguration.commissionRunFrequency)
            {
                return <Jumbotron className="me-3">
                    <p className="lead">Commission is not setup for this Storefront!</p>
                    <hr className="my-2" />
                    <p>To setup commission run, add commission run frequency in the storefront operating configuration</p>
                </Jumbotron>
            }
        }

        return <StorefrontCommissionRun propertyId={this.state.data.propertyId} storefrontId={this.state.data.storefrontId} />
    }
}

const mapStateToProps = state => 
{
    return {
        properties: orgSelectors.selectProperties(),
    }
}

export default connect(mapStateToProps)(StorefrontCommissionRuns)
