const rmsApiProxy = require("./rmsApiProxy");
const constants = require("../domain/constants");

export async function loadBrands()
{
    const url = `${rmsApiProxy.getFranchisorOrgContextUrl()}/brands`;
    const brands = await rmsApiProxy.get(url);
    brands.forEach(brand => {
        enrichAppId(brand)
    });
    return brands;
}

function enrichAppId(brand)
{
    if(brand.fieldAssociations && brand.fieldAssociations[constants.rmsExternalClientSystemNames.flipdish])
        brand.appId = brand.fieldAssociations[constants.rmsExternalClientSystemNames.flipdish]['appId']

    return brand;
}

export async function postBrand(brand)
{
    const url = `${rmsApiProxy.getFranchisorOrgContextUrl()}/brands`;
    brand = await rmsApiProxy.post(url, brand);
    enrichAppId(brand)
    return brand
}