const rmsApiProxy = require('../../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../../utils/validator/validator');
const constants = require('../../../../../../utils/domain/constants');

export function createNew () 
{
    return {}
}

export async function save (configuration)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/deliveryManagementConfiguration`;
    return await rmsApiProxy.post(resourceUrl, configuration);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/deliveryManagementConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function resetConfiguration (configuration)
{
    configuration.accountApiKey = null;
}

export function validate (configuration)
{
    if(!validator.isPresent(configuration.type))
    {
        return 'Delivery management type cannot be left empty';
    }
    
    if (configuration.type === constants.deliveryManagement.shipDay)
    {
        if (!validator.isPresent(configuration.accountApiKey))
            return 'accountApiKey cannot be left empty';
    }

    return null;
}