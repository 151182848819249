import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard.js';
import SalesDataGrid from './SaleDataGrid.jsx';

const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants.js');
const nomenclatureUtils = require('../../../../../utils/domain/nomenclatureUtils.js');

const SaleReceiptLines = (props) => {

    return <CollapsibleCard caption={`Receipt Lines`} isDefaultOpen={props.isDefaultOpen}>
        <SalesDataGrid getColumnDefs={getColumnDefs} getData={() => getData(props.sale)} />
    </CollapsibleCard>

}

function getData(sale)
{
    return sale.receiptLines;
}

function getColumnDefs()
{
    let header;
    const headers = [];

    header = {};
    header.field = "dateTime";
    header.headerName = "Time";
    headers.push(header);

    header = {};
    header.field = "type";
    header.headerName = "Type";
    headers.push(header);

    header = {};
    header.field = "paymentMethod";
    header.suppressSizeToFit = false;
    header.headerName = "Payment Method";
    headers.push(header);

    header = {};
    header.field = "amount";
    header.headerName = "Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;

}

export default SaleReceiptLines;