const OrgData = require('./actions/orgData/orgData/orgData');
const CatalogData = require('./actions/catalogData/catalogData/catalogData');
const domainConstants = require('../domain/constants')

module.exports.createState = function ()
{
    return {
        applicationMode: domainConstants.applicationModes.normal,
        applicationModeData: null,

        environmentInitialized: false,
        organizationInitialized: false,
        passport: null,
        logoutAction: null,

        availableFranchisorsLoaded: false,
        availableFranchisors: [],
        currentFranchisorId: null,
        
        catalogData: new CatalogData(),     // {organizationTaxonomy, lookupData}
        orgData: new OrgData(),             // {organizationTaxonomy}

        orgStates: new Map(),               // Map: {key=franchisorId, value=orgState}, orgState = {franchisorId, lookupData}
        currentOrgNode: null,

        propertyLicenseSubscriptions: new Map(),
        propertyLocalizations: new Map(),
        
        displayAccountSwitchScreen: false,
        
        sideBarSelectedOption: null,
        topBarSelectedOption: null,
        mainBarOptions: null,
        mainBarSearch: null,
        mainBarComponent: null,
        

        alertDialogue: null,
        confirmDialogue: null,
        modalSpinnerDialogue: null,
        modalForms: [],
        attachmentForm: null
    }
}
