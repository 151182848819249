export function getTotalChargesAmount(data) 
{
    return data.reduce((acc, item) => acc + item.amount, 0);
}

export function getTotalChargesVat(data) 
{
    return data.reduce((acc, item) => acc + item.tax, 0);
}

export function getAggregatedCharges(charges)
{
    const aggregatedCharges = [];

    charges.forEach(charge => 
    {
        let aggregateCharge = aggregatedCharges.find(item => item.scheme === charge.scheme && item.internalName === charge.internalName);

        if (aggregateCharge == null)
        {
            aggregateCharge = {
                scheme: charge.scheme,
                internalName: charge.internalName,
                amount: 0,
                tax: 0
            };
            aggregatedCharges.push(aggregateCharge);
        }

        aggregateCharge.amount += (charge.retailAmount - charge.refund);
        aggregateCharge.tax += (charge.tax - charge.refundTax);
    });

    return aggregatedCharges;
}