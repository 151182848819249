const logger = require('../logger/logger.js');
var stringUtil = require('../string/stringUtil');
var guidUtil = require('../guid/guidUtil');
var rmsApiProxy = require('../api/rmsApiProxy');

export async function publishMessage(message)
{
    try
    {
        var url = `${rmsApiProxy.getFranchisorOrgContextUrl()}/channels/worker.queue`;
        await rmsApiProxy.post(url, message);
    }
    catch(error)
    {
        logger.error('Error occurred while publishing a worker queue message. ' + error,  {}, error);
    }
}

