import React from "react";
import Jumbotron from "../../../../../components/jumbotron/jumbotron";

export default function EmptyRolesBanner({
    title = "No staff roles", 
    text = "There are no staff roles available for this property.",
    action = "Hit the New Staff Role button to create one"
})
{
    return (
        <Jumbotron>
            <h4>{title}</h4>
            <p className="lead">{text}</p>
            <hr className="my-2" />
            <p>{action}</p>
        </Jumbotron>
    )
}