import { Component } from 'react';
import CookingTemperatureProfile from './CookingTemperatureProfile';
import StorageTemperatureProfile from './StorageTemperatureProfile';

class StockItemUnits extends Component
{
    render()
    {
        return  <div>
                    <div style={{ paddingBottom: "20px" }}>
                        <CookingTemperatureProfile {...this.props} />
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <StorageTemperatureProfile {...this.props} />
                    </div>
                </div>
    }
}

export default StockItemUnits;