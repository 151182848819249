import React, { Component } from 'react';
import GridView from '../../../../components/gridView/gridView'
import GridViewOptions from '../../../../components/gridView/gridViewOptions';
import InventoryTrackingProfile from './InventoryTrackingProfile';
import * as inventoryTrackingProfileUtils from './utils/inventoryTrackingProfileUtils';

const arraySort = require('array-sort');


const stringUtil = require('../../../../utils/string/stringUtil');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const guidUtil = require('../../../../utils/guid/guidUtil');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const domainConstants = require('../../../../utils/domain/constants');
const securityConstants = require('../../../../utils/domain/security/securityConstants');
const commonUtility = require('../../../../utils/domain/commonUtility');
const securityManager = require('../../../../utils/domain/security/securityManager');

// In the context of centralKitchen, inventoryTrackingProfile can now be manipulated at property node. So the code must not
// assume that a franchisor node is always currently selected

export default class InventoryTrackingProfiles extends Component {
    
    constructor(props){
        super(props);

        this.loadInventoryTrackingProfilesAsync = this.loadInventoryTrackingProfilesAsync.bind(this);
        this.save = this.save.bind(this);

        this.state = {};        
        this.state.inventoryTrackingProfiles = [];        
        this.state.stockItems = [];
    }

    get isCentralKitchenContext() 
    {
        return commonUtility.isCentralKitchenPropertySelected();
    }

    render() 
    {
        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Inventory Tracking Profiles";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadInventoryTrackingProfilesAsync.bind(this);

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () =>
        {
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

            const newRow = {
                id: guidUtil.generateGuid(),
                franchisorId: currentOrgNode.franchisorId,
                type: this.isCentralKitchenContext
                    ? domainConstants.serviceContextTypes.centralKitchen
                    : domainConstants.serviceContextTypes.store,
                stockItems: []                 
            };

            return newRow;
        };

        gridViewOptions.getComponent = (isNew, row) =>
            <InventoryTrackingProfile isNew={isNew} data={row} isRowReadOnly={this.props.isReadOnly} stockItems={this.state.stockItems} />;

        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Inventory Tracking Profile - ${row.name}` : 'Inventory Tracking Profile - New';
        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;
        gridViewOptions.messageContext = "Inventory Tracking Profile ";

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Adding Tracking profile";
            return "Updating Tracking profile";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New inventory tracking profile is added successfully";
            return "Inventory tracking profile is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new inventory tracking profile:";
            return "Following error occurred while updating inventory tracking profile:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    async loadInventoryTrackingProfilesAsync()
    {
        const type = this.isCentralKitchenContext
            ? domainConstants.serviceContextTypes.centralKitchen
            : domainConstants.serviceContextTypes.store;

        try
        {
            let inventoryTrackingProfiles = await apiLoadFacade.loadInventoryTrackingProfiles();
            if (inventoryTrackingProfiles == null) inventoryTrackingProfiles = [];
            inventoryTrackingProfiles = inventoryTrackingProfiles.filter(trackingProfile => trackingProfile.type === type);
            this.state.inventoryTrackingProfiles = inventoryTrackingProfiles;

            let stockItems = await apiLoadFacade.loadStockItems();
            if (stockItems == null) stockItems = [];
            stockItems = stockItems.filter(stockItem => stockItem.type === type);
            this.state.stockItems = stockItems.filter(stockItem => stockItem.recipeProfile == null);

            return arraySort(inventoryTrackingProfiles, stringUtil.localeCompareProp("name"));
        }
        catch (error)
        {
            throw error;
        }
    }

    async save(isNew, row, inventoryTrackingProfiles)
    {
        try
        {
            const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
            const resourceUrl = `${baseUrl}/inventory/tracking/trackingProfiles`;

            const updatedInventoryTrackingProfile = await rmsApiProxy.post(resourceUrl, row);

            if (inventoryTrackingProfiles == null) inventoryTrackingProfiles = [];
            inventoryTrackingProfiles = inventoryTrackingProfiles.filter(profile => profile.id != updatedInventoryTrackingProfile.id);
            inventoryTrackingProfiles.push(updatedInventoryTrackingProfile);

            this.state.inventoryTrackingProfiles = inventoryTrackingProfiles;

            const response = {};
            response.remoteData = arraySort(inventoryTrackingProfiles, stringUtil.localeCompareProp("name"));
            response.addUpdatedRow = updatedInventoryTrackingProfile;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();



        return inventoryTrackingProfileUtils.validateInventoryProfile(row);
    }

    constructGridColumnHeaders()
    {

        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);                

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

