import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';

const domainConstants = require("../../../../../../utils/domain/constants");

export default function PropertyPrintConfigurationComponent(props)
{



    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            fieldName="printingHeader"
                            caption="Printing Header"
                            placeholder="Printing Header"
                            hintText="Optional"
                            multiLine={true} rows={2}
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent
                            inputType="text"
                            fieldName="printingFooter"
                            caption="Printing Footer"
                            placeholder="Printing Footer"
                            hintText="Optional"
                            multiLine={true} rows={2}
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td>
                        <SelectComponent
                            optionValues={domainConstants.getTriStateFlags()}
                            optionFieldName="key"
                            optionDisplayFieldName="value"
                            clearable={false}
                            comboReadOnly={false}
                            fieldName="printReceipt"
                            caption="Print Receipt"
                            hintText="Select option to print receipt after booking a sale"
                            formManager={ props.formManager } />
                    </td>

                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="noOfReceiptPrints"
                            placeholder="Enter No Of Receipt Prints"
                            caption="No Of Receipt Prints"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
