import React, { Component } from 'react';


import ResponseInputComponent from '../taskUtils/responseInputComponent';
import ResponseListComponent from '../taskUtils/responseListComponent';

import viewUtil from '../../../../../utils/view/viewUtil';
import typeUtil from '../../../../../utils/type/typeUtil';
import stringUtil from '../../../../../utils/string/stringUtil';


class TaskResponses extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            taskResponses: this.props.formManager.viewModel.taskResponses,
            selectedTaskResponse: {},
            clonedTaskResponse: null
        }
    }

    validateTaskResponse = (taskResponse) =>
    {
        if (stringUtil.isStringNullOrEmpty(taskResponse.response))
            return "Task response response is required";
    }

    onAddUpdateTaskResponse = (selectedTaskResponse) =>
    {
        let validationError = this.validateTaskResponse(selectedTaskResponse);
        if (validationError)
        {
            viewUtil.showErrorAlert(validationError);
            return;
        }

        const taskResponses = this.state.taskResponses;

        const tempTaskResponse =
            taskResponses.find(taskResponse =>
                taskResponse.response.toLowerCase() == selectedTaskResponse.response.toLowerCase());

        if (tempTaskResponse != null)
        {
            if (tempTaskResponse != selectedTaskResponse)
            {
                // Reference equality
                viewUtil.showErrorAlert("A matching task response response already exists");
                return;
            }
        }

        const index = taskResponses.indexOf(selectedTaskResponse);

        if (index > -1)
        {
            taskResponses.splice(index, 1, selectedTaskResponse);
        }
        else
        {
            taskResponses.push(selectedTaskResponse);
        }

        this.setState({ selectedTaskResponse: {} });
    }

    onDeleteTaskResponse = (selectedTaskResponse) =>
    {
        const taskResponses = this.state.taskResponses;
        const index = taskResponses.indexOf(selectedTaskResponse);  // Reference equality

        if (index > -1)
        {
            taskResponses.splice(index, 1);
        }

        this.setState({ selectedTaskResponse: {} });
    }

    onCancelTaskResponse = () => 
    {
        if (this.state.selectedTaskResponse)
        {
            if (this.state.clonedTaskResponse)
            {
                // Use clone to restore object back to its original state
                Object.assign(this.state.selectedTaskResponse, this.state.clonedTaskResponse);
            }
        }

        this.setState({ selectedTaskResponse: {} });
    }

    onSelectTaskResponse = (selectedTaskResponse) =>
    {
        //
        // Implies an existing object selected for edit,
        // save a clone in the state to restore original object back upon Cancel
        //




        this.setState({
            selectedTaskResponse: selectedTaskResponse,
            clonedTaskResponse: typeUtil.deepCloneObject(selectedTaskResponse)
        });
    }

    render()
    {
        const taskResponses = this.state.taskResponses;
        const selectedTaskResponse = this.state.selectedTaskResponse;

        return (
            <div className="d-flex flex-row">
                <div className="border rounded me-2 w-50">
                    <ResponseInputComponent
                        isRowReadOnly={this.props.isRowReadOnly}
                        selectedTaskResponse={selectedTaskResponse}
                        onAddUpdateTaskResponse={this.onAddUpdateTaskResponse}
                        onCancelTaskResponse={this.onCancelTaskResponse}
                    />
                </div>
                <div className="border rounded ms-2 w-100">
                    <ResponseListComponent
                        isRowReadOnly={this.props.isRowReadOnly}
                        taskResponses={taskResponses}
                        selectedTaskResponse={selectedTaskResponse}
                        onSelectTaskResponse={this.onSelectTaskResponse}
                        onDeleteTaskResponse={this.onDeleteTaskResponse}
                    />
                </div>
            </div>);
    }
}

export default TaskResponses;