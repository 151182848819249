const stateManager = require('../stateManager');

module.exports.selectOrgData = function ()
{
    return stateManager.getStore().getState().orgData;
}

module.exports.selectOrganizationTaxonomy = function ()
{
    return this.selectOrgData().organizationTaxonomy;
}

// Nested properties

module.exports.selectFranchisors = function ()
{
    return this.selectOrganizationTaxonomy().franchisors;
}

module.exports.selectFranchisees = function ()
{
    return this.selectOrganizationTaxonomy().franchisees;
}

module.exports.selectProperties = function ()
{
    return this.selectOrganizationTaxonomy().properties;
}

module.exports.selectSuppliers = function ()
{
    return this.selectOrganizationTaxonomy().suppliers;
}

module.exports.selectPropertyTaxonomies = function ()
{
    return this.selectOrganizationTaxonomy().propertyTaxonomies;
}

module.exports.selectFranchisor = function (franchisorId)
{
    return this.selectFranchisors().find(franchisor => franchisor.id == franchisorId);
}

module.exports.selectFranchisee = function (franchiseeId)
{
    return this.selectFranchisees().find(franchisee => franchisee.id == franchiseeId);
}

module.exports.selectProperty = function (propertyId)
{
    return this.selectProperties().find(property => property.id == propertyId);
}

module.exports.selectSupplier = function (supplierId)
{
    return this.selectSuppliers().find(supplier => supplier.id == supplierId);
}

module.exports.selectPropertyTaxonomy = function (propertyTaxonomyId)
{
    return this.selectPropertyTaxonomies().find(propertyTaxonomy => propertyTaxonomy.id == propertyTaxonomyId);
}
