const columnAlignments =
{
    alignLeft: "alignLeft",
    alignRight: "alignRight",
    alignCenter: "alignCenter"
}

const tableConstants = {
    columnAlignments
}

export default tableConstants;
