const stateManager = require('../stateManager');

module.exports.selectCatalogData = function ()
{
    return stateManager.getStore().getState().catalogData;
}

module.exports.selectOrganizationTaxonomy = function ()
{
    return this.selectCatalogData().organizationTaxonomy;
}

module.exports.selectLookupData = function ()
{
    return this.selectCatalogData().lookupData;
}

// Nested properties

module.exports.selectFranchisors = function ()
{
    return this.selectOrganizationTaxonomy().franchisors;
}

module.exports.selectFranchisees = function ()
{
    return this.selectOrganizationTaxonomy().franchisees;
}

module.exports.selectProperties = function ()
{
    return this.selectOrganizationTaxonomy().properties;
}

module.exports.selectFranchisor = function (franchisorId)
{
    return this.selectFranchisors().find(franchisor => franchisor.id == franchisorId);
}

module.exports.selectFranchisee = function (franchiseeId)
{
    return this.selectFranchisees().find(franchisee => franchisee.id == franchiseeId);
}

module.exports.selectProperty = function (propertyId)
{
    return this.selectProperties().find(property => property.id == propertyId);
}
