import React, { Component } from 'react';

import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import FormManager from '../../../../../../utils/view/formManager';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';

const domainConstants = require('../../../../../../utils/domain/constants');
const stringUtil = require('../../../../../../utils/string/stringUtil');

export default class PurchaseOrderHeader extends Component
{
    get isReadOnly()
    {
        return !purchaseOrderUtil.isUserAuthorizedForAnyAction() ||
            this.props.data.status !== domainConstants.PurchaseOrderStatusType.draft;
    }

    render()
    {
        const formManager = new FormManager();
        formManager.viewModel = this.props.data;
        formManager.onFieldChanged = (event) =>
        {
            if (this.props.onFieldChanged)
                this.props.onFieldChanged(event.target.id);

            this.setState({});
        }

        const supplierId = this.props.data.supplierId;

        let suppliers = this.props.lookupData.activeSuppliers.sort((a, b) => a.name.localeCompare(b.name));

        if (!stringUtil.isStringNullOrEmpty(supplierId))
        {
            const currentActiveSupplier = suppliers.find(x => x.id === supplierId);
            //Current supplier is no longer active but currently used with purchase order hence include the supplier
            if (currentActiveSupplier == null)
            {
                const supplier = this.props.lookupData.suppliers.find(x => x.id === supplierId);
                suppliers = [...suppliers];
                suppliers.push(supplier);
            }
        }

        return (
            <div className="d-flex flex-row align-items-center">

                <div style={{ minWidth: "30%", color: "#FFFFFF"}}>
                    <InputComponent
                        inputType="number"
                        caption="Order Number"
                        fieldName="number"
                        placeholder="Enter the order number"
                        inputReadOnly={this.isReadOnly}
                        formManager={formManager} />
                </div>

                <div style={{ width: "70%", marginLeft: "3%", color: "#FFFFFF" }}>
                    <SelectComponent
                        caption="Supplier"
                        fieldName="supplierId"
                        optionFieldName="id"
                        optionDisplayFieldName="name"
                        optionValues={suppliers}
                        clearable={false}
                        comboReadOnly={this.isReadOnly}
                        formManager={formManager} />
                </div>

            </div>
        );
    }
}