
import React, { Component } from 'react';
import formManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

export default class HandpointCreditCardSettingsComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.formManager = new formManager();
        this.formManager.viewModel = this.props.creditCardSettings;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>


                    <tr>
                        <td colSpan={2}>
                            <InputComponent
                                inputType="text"
                                caption="URL"
                                fieldName="url"
                                placeholder="Enter cloud url"
                                hintText="Enter cloud url provided by card provider (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>

                    </tr>

                    <tr>

                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Api Key"
                                fieldName="apiKey"
                                placeholder="Enter Api Key"
                                hintText="Enter Api Key url provided by card provider (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Terminal Id"
                                fieldName="terminalId"
                                placeholder="Enter Terminal Id"
                                hintText="Enter Terminal Id provided by card provider (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                </tbody>

            </ table>

        </div>
    }
}