import React, { Component } from "react";
import { FormText } from 'reactstrap';

import CheckComponent from '../../../../../components/form/checkComponent';

import FormManager from '../../../../../utils/view/formManager';
import { EnumDays, allEnumDays } from '../../../../../utils/enums/enumUtil';

class SelectEnumDaysComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = {};
        this.formManager.view = this;
        this.formManager.onFieldChanged = this.onFieldChanged;

        this.proxyViewModel = this.formManager.viewModel;
        this.dataViewModel = this.props.formManager.viewModel;

        this.dataBitField = this.dataViewModel.days || (this.dataViewModel.days = 0);

        //
        // Initialize with existing data value
        //

        Object.keys(EnumDays).forEach(key =>
        {
            this.proxyViewModel[key] = (this.dataBitField & EnumDays[key]) === EnumDays[key];
        });

        this.proxyViewModel["All"] = (this.dataBitField & allEnumDays) === allEnumDays;
    }

    onFieldChanged = (event) =>
    {
        const id = event.target.id;
        const value = event.target.checked;

        if (id == "All")
        {
            Object.keys(EnumDays).forEach(key => this.proxyViewModel[key] = value);
        }

        this.dataBitField =
            Object.keys(EnumDays)
                .reduce((accumulator, key) =>
                    accumulator +
                    (this.proxyViewModel[key] ? EnumDays[key] : 0),
                    0);

        this.proxyViewModel["All"] = (this.dataBitField & allEnumDays) == allEnumDays;

        this.dataViewModel.days = this.dataBitField;
    }

    render()
    {
        return (
            <div>
                <fieldset style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
                    <legend style={{ margin: "0px 20px -10px 20px", width: "200px", fontSize: "14px" }}>
                        {this.getEnumAllCheckComponent()}
                    </legend>
                    <div style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        marginLeft: "20px",
                        // padding: "6px 0px",
                        padding: "15px 0px",
                        justifyContent: "flex-start",
                    }}>
                        {
                            Object.keys(EnumDays).map(day =>
                                <span style={{ margin: "0px 15px 0px 0px" }}>
                                    {this.getEnumDayCheckComponent(day)}
                                </span>)
                        }
                    </div>
                </fieldset>
                <FormText color="muted">{this.props.hintText}</FormText>
            </div>
        );
    }

    getEnumDayCheckComponent(day)
    {
        return (
            <CheckComponent
                key={day}
                caption={day}
                fieldName={day}
                inputReadOnly={this.props.isRowReadOnly}
                formManager={this.formManager} />);
    }

    getEnumAllCheckComponent()
    {
        return (
            <CheckComponent
                key={"All"}
                caption="All Days of the Week"
                fieldName="All"
                inputReadOnly={this.props.isRowReadOnly}
                formManager={this.formManager} />);
    }
}

export default SelectEnumDaysComponent;