
import React, { Component } from 'react';
import GridComponent from '../../../../../../components/grid/gridComponent.js'
import EditStaffPayrollRun from '../../staff/EditStaffPayrollRun';

const viewUtil = require("../../../../../../utils/view/viewUtil");
const payrollRunUtils = require('../../utils/payrollRunUtils');
const payrollCalculatorUtils = require('../../utils/payrollCalculatorUtils');

const configurationManager = require('../../../../../../utils/config/configurationManager.js');

const columnWidthConstants = require('../../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../../utils/constants/dataTypeConstants');
const additionalWidth = 75;

class PayrollRunDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);
    }

    get isReadOnly() { return !payrollRunUtils.isEditingAllowed(); }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowDoubleClicked(eventData)
    {
        var selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        viewUtil.openModalForm(
            selectedRow.name,
            (onFieldChanged) =>
            {
                return (<EditStaffPayrollRun {...this.props} payrollRun={this.props.data} data={selectedRow} />);
            },
            () =>
            {
                payrollCalculatorUtils.updateCalculatedFields(this.props.data);
                this.setState({});
            },
            () => 
            {
                const error = payrollRunUtils.validateStaff(selectedRow);
                return error;
            },
            this.isReadOnly
        );
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    render()
    {
        return (<GridComponent
            headers={this.getPayrollHeaderColumns()}
            gridMode="controlled" //Controlled indicates data rows are updated, selection is managed by composer. The selected row is not retained ... gridView
            rows={this.props.data.staff}
            onRowDoubleClicked={this.onRowDoubleClicked}
            onGridReady={this.onGridReady}
            shouldReArrangeHeaders={false}
            paginationEnabled={true}
            paginationSize={10}
        />);
    }

    getPayrollHeaderColumns()
    {
        const headers = [];

        var header = {};
        header.field = "name";
        header.headerName = "Name";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        const cloudName = configurationManager.getConfig().cdnUsersCloudName;
        const defaultCloudName = configurationManager.getConfig().cdnAppCloudName;

        header = {};
        header.field = "pictUrl";
        header.headerName = "";
        header.type = dataTypeConstants.imagePic;
        header.width = columnWidthConstants.imagePic;
        header.pinned = true;
        header.width = 50;
        header.cellRenderer = 'imageRenderer';
        header.cellRendererParams = {
            cloudName : cloudName,
            defaultImage : "app/back.office/icons/default.staff.picture.png",
            defaultCloudName : defaultCloudName,
        }
        headers.push(header);

        this.getPayrollSummaryColumns().forEach(header => { headers.push(header) });
        this.getPayrollWagesColumns().forEach(header => { headers.push(header) });
        this.getPayrollTaxesColumns().forEach(header => { headers.push(header) });


        return headers;
    }


    getPayrollWagesColumns()
    {
        const headers = [];

        var header = {};
        header.field = "plannedWage";
        header.headerName = "Planned Wage";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "plannedHours";
        header.headerName = "Planned Hours";
        header.type = dataTypeConstants.hours
        header.width = columnWidthConstants.number + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "actualWage";
        header.headerName = "Actual Wage";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "actualHours";
        header.headerName = "Actual Hours";
        header.type = dataTypeConstants.hours
        header.width = columnWidthConstants.number + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "bonus";
        header.headerName = "Bonus";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "advancePayment";
        header.headerName = "Advance Payment";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "deduction";
        header.headerName = "Deduction";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        return headers;
    }

    getPayrollTaxesColumns()
    {
        const headers = [];

        var header = {};

        header.field = "paye";
        header.headerName = "PAYE";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "employerNIContribution";
        header.headerName = "Employer NI Contribution";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "employeeNIContribution";
        header.headerName = "Employee NI Contribution";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "employerPensionContribution";
        header.headerName = "Employer Pension Contribution";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "employeePensionContribution";
        header.headerName = "Employee Pension Contribution";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        return headers;
    }

    getPayrollSummaryColumns()
    {
        const headers = [];

        var header = {};

        header.field = "grossWage";
        header.headerName = "Gross Wage";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "netWage";
        header.headerName = "Net Wage";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "netWagePayment";
        header.headerName = "Net wage to pay";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "totalNIContribution";
        header.headerName = "Total NI Contribution";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "totalHMRCPayment";
        header.headerName = "Total HMRC Payment";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "totalPensionPayment";
        header.headerName = "Total Pension Contribution";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        header = {};
        header.field = "totalPayrollCost";
        header.headerName = "Total payroll cost";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money + additionalWidth;
        headers.push(header);

        return headers;
    }
}

export default PayrollRunDetails;