import React, { Component } from "react";
import SelectComponent from '../../../../../components/form/selectComponent';

const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const FormManager = require('../../../../../utils/view/formManager');

class TransferSales extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            formManager: new FormManager()
        }

        this.state.formManager.viewModel = props.targetProperty;
        this.state.formManager.view = this;
    }
    
    render()
    {
        let currentPropertyId = currentOrgNodeSelectors.selectCurrentOrgNode().propertyId;
        let selectProperties = orgSelectors.selectProperties().filter((property) => property.id !== currentPropertyId && property.name.toLowerCase().includes('training'));

        return  <div>
                    <SelectComponent
                        optionValues={selectProperties}
                        optionFieldName="id"
                        optionDisplayFieldName="name"
                        caption="Transfer To"
                        hintText="Required"
                        fieldName="id"
                        placeholder="Select Property"
                        inputReadOnly={false}
                        formManager={this.state.formManager}
                    />
                </div>
    }
}

export default TransferSales;