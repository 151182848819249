import { generateGuid } from "../../../../../utils/guid/guidUtil";
const staffActivities = require('../../../../../utils/domain/staffActivities');

const staffActions = [
    {
        code: staffActivities.canPlaceAnOrder,
        label: 'Can Place Orders',
        type: 'boolean',
        description: 'Choose if this role can place an order.',
        defaultValue: false
    },
    {
        code: staffActivities.canComplimentAnItem,
        label: 'Can Compliment Items',
        type: 'boolean',
        description: 'Choose if this role can compliment an item.',
        defaultValue: false
    },
    {
        code: staffActivities.canApplyDiscount,
        label: 'Can Apply Discounts',
        type: 'boolean',
        description: 'Choose if this role can apply a discount.',
        defaultValue: false
    },
    {
        code: staffActivities.canCancelAnOrder,
        label: 'Can Cancel Orders',
        type: 'boolean',
        description: 'Choose if this role can cancel an order.',
        defaultValue: false
    },
    {
        code: staffActivities.canDeliverAnOrder,
        label: 'Can Deliver an Order',
        type: 'boolean',
        description: 'Choose if this role can deliver an order.',
        defaultValue: false
    },
    {
        code: staffActivities.canDoCashingUp,
        label: 'Can Perform Cashing Up',
        type: 'boolean',
        description: 'Choose if this role can perform cashing up.',
        defaultValue: false
    },
    {
        code: staffActivities.canOpenCashDrawer,
        label: 'Can Open Cash Drawer',
        type: 'boolean',
        description: 'Choose if this role can open cash drawer.',
        defaultValue: false
    },
    {
        code: staffActivities.canChangeSettings,
        label: 'Can Change Settings',
        type: 'boolean',
        description: 'Choose if this role can change settings.',
        defaultValue: false
    },
    {
        code: staffActivities.canChangeLayout,
        label: 'Can Change Layout',
        type: 'boolean',
        description: 'Choose if this role can change layout.',
        defaultValue: false
    },
    {
        code: staffActivities.canViewDailyReport,
        label: 'Can View Daily Summary Report',
        type: 'boolean',
        description: 'Choose if this role can view daily summary report.',
        defaultValue: false
    },
    {
        code: staffActivities.canViewDriverSalesReport,
        label: 'Can View Driver Sales Report',
        type: 'boolean',
        description: 'Choose if this role can view driver sales report.',
        defaultValue: false
    },
    {
        code: staffActivities.canCreateAPurchaseOrder,
        label: 'Can Create a Purchase Order',
        type: 'boolean',
        description: 'Choose if this role can create a purchase order.',
        defaultValue: false
    },
    {
        code: staffActivities.canSubmitAPurchaseOrder,
        label: 'Can Submit a Purchase Order',
        type: 'boolean',
        description: 'Choose if this role can submit an purchase order.',
        defaultValue: false
    },
    {
        code: staffActivities.canReceiveAPurchaseOrder,
        label: 'Can Receive a Purchase Order',
        type: 'boolean',
        description: 'Choose if this role can receive a purchase order.',
        defaultValue: false
    },
    {
        code: staffActivities.canViewBusinessInsights,
        label: 'Can View Business Insights',
        type: 'boolean',
        description: 'Choose if this role can view business insights.',
        defaultValue: false
    }
]

export class RoleDataItem
{
    static staffActions = staffActions;
    constructor({ id, rowKey, name, description, actions })
    {
        this.id = id;
        this.rowKey = rowKey || generateGuid()
        this.name = name || "";
        this.description = description || "";
        this.actions = actions || [];
        this.inflateActions();
    }

    inflateActions()
    {
        const actionItems = RoleDataItem.staffActions.map(staffAction =>
        {
            const actionItem = new ActionItem(staffAction);
            const staffRoleAction = this.actions.find(staffRoleAction => staffRoleAction.actionCode === staffAction.code);
            if (staffRoleAction)
            {
                switch (actionItem.type)
                {
                    case "checkbox":
                        actionItem.value = true;
                        break
                    case "number":
                        actionItem.value = Number(staffRoleAction.context) || 0;
                        break
                    default:
                        actionItem.value = staffRoleAction.context;
                }
            }
            return actionItem;
        })
        this.actions = actionItems;
    }

    getDeflatedActions()
    {
        const definedActionItems = this.actions.filter(actionItem => actionItem.value);
        const deflatedActions = definedActionItems.map(allowedActionItem =>
        {
            const context = allowedActionItem.type === "checkbox" ? null : allowedActionItem.value;
            return {
                actionCode: allowedActionItem.code,
                context
            }
        });
        return deflatedActions;
    }

}
export class ActionItem
{
    constructor({ code, label, type, description, defaultValue })
    {
        this.code = code;
        this.label = label;
        this._type = type;
        this.description = description;
        this.value = defaultValue;
    }

    set _type(type)
    {
        let actionType;
        switch(type) {
            case "boolean":
                actionType = "checkbox";
                break
            case "number":
                actionType = "number";
                break;
            default:
                actionType = "text";
        }
        this.type = actionType;
    }
}




