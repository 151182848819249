/* eslint-disable eqeqeq */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-throw-literal */
import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import ErrorMessage from '../../../../components/error/errorMessage';
import Spinner from '../../../../components/spinner/spinner';
import PurchaseOrderList from './components/purchaseOrders/PurchaseOrderList';
import PurchaseOrdersHeader from './components/purchaseOrders/PurchaseOrdersHeader';
import PurchaseOrder from './components/purchaseOrder/PurchaseOrder';
import purchaseOrderUtil from './utils/purchaseOrderUtil';
import inventoryUtil from './utils/inventoryUtil';

import * as apiLoadFacade from '../../../../utils/api/apiLoadFacade';
import * as supplierServiceTypeUtil from '../../../../utils/domain/supplierServiceTypeUtil';

const arraySort = require('array-sort');

const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const stringUtil = require('../../../../utils/string/stringUtil');
const typeUtil = require('../../../../utils/type/typeUtil');
const guidUtil = require('../../../../utils/guid/guidUtil');
const commonUtility = require('../../../../utils/domain/commonUtility');
const domainConstants = require('../../../../utils/domain/constants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

export default class PurchaseOrders extends Component
{
    constructor(props)
    {
        super(props);

        this.onNewPurchaseOrder = this.onNewPurchaseOrder.bind(this);

        this.renderListView = this.renderListView.bind(this);
        this.renderPurchaseOrder = this.renderPurchaseOrder.bind(this);

        this.state = {};
        this.state.error = null;
        this.state.viewType = "loading";     // viewType = loading/error/list/new/edit
        this.state.listViewState = {};
        this.state.listViewState.purchaseOrders = [];
        this.state.listViewState.inventoryData = [];

        this.state.lookupData = { suppliers: [], stockItems: [], centralKitchensLookupData: [] };
    }

    async componentDidMount()
    {
        await this.loadPurchaseOrders();
    }

    get isCentralKitchenContext() 
    {
        return commonUtility.isCentralKitchenPropertySelected();
    }

    render()
    {
        if (stringUtil.areStringSame(this.state.viewType, "new") || stringUtil.areStringSame(this.state.viewType, "edit"))
            return this.renderPurchaseOrder();

        const header =
            <PurchaseOrdersHeader
                isReadOnly={this.props.isReadOnly}
                viewType={this.state.viewType}
                onNewPurchaseOrder={this.onNewPurchaseOrder} />;

        let body;

        if (stringUtil.areStringSame(this.state.viewType, "loading"))
            body = <Spinner text="Loading Purchase Orders" />;
        else if (stringUtil.areStringSame(this.state.viewType, "error"))
            body = <ErrorMessage message={this.state.error} />;
        else if (stringUtil.areStringSame(this.state.viewType, "list"))
            body = this.renderListView();
        else
            body = null;

        return (
            <Card>
                <CardHeader>
                    {header}
                </CardHeader>
                <CardBody>
                    {body}
                </CardBody>
            </Card>);
    }

    renderListView()
    {
        return <PurchaseOrderList
            viewType={this.state.viewType}
            purchaseOrders={this.state.listViewState.purchaseOrders}
            inventoryData={this.state.listViewState.inventoryData}
            lookupData={this.state.lookupData}
            onEditPurchaseOrder={(existingOrder) =>
            {
                this.state.viewType = "edit";
                this.state.currentOrder = typeUtil.deepCloneObject(existingOrder);
                this.state.purchaseOrderContext = { isNewOrder: false };
                this.state.originalOrder = existingOrder;

                this.setState({});
            }} />
    }

    renderPurchaseOrder()
    {
        return <PurchaseOrder
            isReadOnly={this.props.isReadOnly}
            viewType={this.state.viewType}
            data={this.state.currentOrder}
            purchaseOrderContext={this.state.purchaseOrderContext}
            purchaseOrders={this.state.listViewState.purchaseOrders}
            inventoryData={this.state.listViewState.inventoryData}
            lookupData={this.state.lookupData}
            onCancel={() =>
            {
                this.state.viewType = "list";
                this.state.currentOrder = null;
                this.state.purchaseOrderContext = null;
                this.state.originalOrder = null;

                this.setState({});
            }}
            onSaved={(updatedOrder) =>
            {

                if (this.state.originalOrder == null)
                {
                    this.state.listViewState.purchaseOrders.unshift(updatedOrder)
                }
                else
                {
                    var index = this.state.listViewState.purchaseOrders.findIndex(p => p.id == updatedOrder.id);
                    this.state.listViewState.purchaseOrders[index] = updatedOrder;
                }

                this.state.viewType = "list";
                this.state.currentOrder = null;
                this.state.purchaseOrderContext = null;
                this.state.originalOrder = null;
                this.setState({});
            }} />
    }

    onNewPurchaseOrder()
    {
        const context = "onNewPurchaseOrder";

        if (!currentOrgNodeSelectors.isCurrentPropertySelected())
            throw `${context}: Invalid currentOrgNode for requested operation`;

        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        this.state.viewType = "new";

        this.state.currentOrder = {};

        this.state.currentOrder.id = guidUtil.generateGuid();

        this.state.currentOrder.franchisorId = currentOrgNode.franchisorId;
        this.state.currentOrder.franchiseeId = currentOrgNode.franchiseeId;
        this.state.currentOrder.propertyId = currentOrgNode.propertyId;

        this.state.currentOrder.date = dateUtil.getToday();
        this.state.currentOrder.number = dateUtil.getToday();
        this.state.currentOrder.status = domainConstants.PurchaseOrderStatusType.draft;
        this.state.currentOrder.notes = "";
        this.state.currentOrder.orderItems = [];
        this.state.purchaseOrderContext = {isNewOrder : true};


        this.state.originalOrder = null;
        this.setState({});
    }

    async loadPurchaseOrders()
    {
        this.state.viewType = "loading";
        this.state.listViewState.purchaseOrders = [];
        this.state.listViewState.inventoryData = [];
        this.setState({});

        const type = this.isCentralKitchenContext
            ? domainConstants.serviceContextTypes.centralKitchen
            : domainConstants.serviceContextTypes.store;

        try 
        {
            // lookupData

            let suppliers = await apiLoadFacade.loadSuppliers();
            suppliers = suppliers.filter(supplier => supplier.type === type);

            let stockItems = await apiLoadFacade.loadStockItems();
            stockItems = stockItems.filter(stockItem => stockItem.type === type);

            let centralKitchensLookupData = [];
            if (!this.isCentralKitchenContext) 
            {
                centralKitchensLookupData = await apiLoadFacade.loadCentralKitchenEffectiveStockList();
            }

            this.state.lookupData.suppliers = supplierServiceTypeUtil.filterStockItemSuppliers(suppliers);
            this.state.lookupData.activeSuppliers = this.state.lookupData.suppliers.filter(supplier => !supplier.isDisabled);
            this.state.lookupData.stockItems = stockItems;
            this.state.lookupData.centralKitchensLookupData = centralKitchensLookupData;

            // inventoryData and purchaseOrders below are loaded by property context and hence no type related filtering is required

            // inventoryData
            const inventoryData = await inventoryUtil.getInventoryData(stockItems);

            // purchaseOrders
            let purchaseOrders = await purchaseOrderUtil.getPurchaseOrders();
            purchaseOrders = arraySort(purchaseOrders, "date", { reverse: true });

            this.state.viewType = "list";
            this.state.listViewState.inventoryData = inventoryData;
            this.state.listViewState.purchaseOrders = purchaseOrders;
            this.setState({});
        }
        catch (error) 
        {
            this.state.viewType = "error";
            this.state.error = error;

            this.state.listViewState.purchaseOrders = [];
            this.state.listViewState.inventoryData = [];

            this.state.lookupData.suppliers = [];
            this.state.lookupData.stockItems = [];

            this.setState({});
        }
    }
}