import React from 'react';
import { Col } from 'reactstrap'
import EditStaffPayrollWage from './EditStaffPayrollWage';
import EditStaffPayrollHolidayPay from './EditStaffPayrollHolidayPay';


function EditStaffPayrollDetails ({ formManager })
{
    return <Col>
        <EditStaffPayrollWage formManager={formManager} />
        <EditStaffPayrollHolidayPay formManager={formManager}/>
    </Col>
}

export default EditStaffPayrollDetails;