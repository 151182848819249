const configurationManager = require('../../utils/config/configurationManager');

module.exports.pos = "pos";
module.exports.kds = "kds";
module.exports.hardware = "hardware";
module.exports.kiosk = "kiosk";
module.exports.kitchen = "kitchen";
module.exports.signage = "signage";
module.exports.centralKitchenKds = "central.kitchen.kds";

module.exports.options =
    [
        //
        // list suitable for binding in SelectComponent
        //
        
        { terminalType: this.pos },
        { terminalType: this.kds },
        { terminalType: this.hardware },
        { terminalType: this.kiosk },
        { terminalType: this.kitchen },
        { terminalType: this.signage },
        { terminalType: this.centralKitchenKds },
    ];

module.exports.getShortCode = function(type)  
{
    switch (type) {
        case this.pos:
            return 'pos';
        case this.kds:
            return 'kds';
        case this.hardware:
            return 'hdw';
        case this.kiosk:
            return 'kiosk';
        case this.kitchen:
            return 'kitchen';
        case this.signage:
            return 'signage';
        case this.centralKitchenKds:
            return 'ck.kds';
        default:
            throw Error('Short code not defined');
    }
};

module.exports.displayNames = {
    pos: 'POS',
    kds: 'KDS',
    hardware: 'Hardware',
    kiosk: 'KIOSK',
    kitchen: 'Kitchen',
    signage: 'Signage',
    centralKitchenKds: 'Central Kitchen KDS'
};

module.exports.getImage = function(type)
{
    switch (type) {
        case this.kds:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/terminal.kds.png`;
    
        case this.kiosk:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/kiosk1.png`;
    
        case this.pos:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/terminal.pos1.png`;
    
        case this.hardware:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/terminal.hardware.png`;
    
        case this.kitchen:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/kitchen.app.png`;
    
        case this.signage:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/signage.png`;
        
        case this.centralKitchenKds:
            return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/central.kitchen.icon.png`
    
        default:
            return null;
    }
}