import * as arraySort from 'array-sort';

import * as calorieCalculator from '../../../../../utils/domain/inventory/calorieCalculator';
import * as stockItemNutritionUtil from '../../../../../utils/domain/inventory/stockItem/stockItemNutritionUtil';
import * as stockItemAllergyUtil from '../../../../../utils/domain/inventory/stockItem/stockItemAllergyUtil';

const ExcelWorkBook = require('../../../../../components/button/excel/excelWorkBook');
const catalogSelectors = require('../../../../../utils/state/stateSelectors/catalogSelectors');
const domainConstants = require('../../../../../utils/domain/constants');
const formatter = require('../../../../../utils/formatter/formatter');
const unitUtil = require('../../../../../utils/domain/unitUtil');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

export default
    {
        exportData
    }

function exportData(title, gridApi, exportOptions, stockItems, categories, suppliers)
{
    //
    // lookupData = stockItems, categories, suppliers
    //

    let worksheet;

    const filteredStockItems = [];
    gridApi.forEachNodeAfterFilterAndSort((node, index) => filteredStockItems.push(node.data));

    if (filteredStockItems.length === 0) return null;

    const workbook = new ExcelWorkBook();
    workbook.workBookName = "Stock Items Data";

    worksheet = workbook.addWorksheet("Stock Items");
    populateStockItemsDataWorksheet(filteredStockItems, worksheet);

    if (filteredStockItems.some(stockItem => stockItem.suppliers.length > 0))
    {
        worksheet = workbook.addWorksheet("Suppliers");
        populateSupplierDataWorksheet(filteredStockItems, worksheet, suppliers);
    }

    if (filteredStockItems.some(stockItem => stockItem.nutritionProfile != null))
    {
        worksheet = workbook.addWorksheet("Nutritions");
        populateNutritionDataWorksheet(filteredStockItems, worksheet, stockItems);
    }

    if (filteredStockItems.some(stockItem => stockItem.allergyProfile != null))
    {
        worksheet = workbook.addWorksheet("Allergies");
        populateAllergyDataWorksheet(filteredStockItems, worksheet, stockItems);
    }

    if (filteredStockItems.some(stockItem => stockItem.cookingTemperatureProfile != null))
    {
        worksheet = workbook.addWorksheet("Temperatures");
        populateTemperaturesDataWorksheet(filteredStockItems, worksheet);
    }

    if (workbook.workSheets.length === 0) return null;

    return workbook;
}

function populateStockItemsDataWorksheet(filteredStockItems, worksheet)
{
    worksheet.addColHeader("Code");
    worksheet.addColHeader("Name");
    worksheet.addColHeader("Category");
    worksheet.addColHeader("Is Product");
    worksheet.addColHeader("Is Wastage Tracked");
    worksheet.addColHeader("Is Inventory Tracked");
    worksheet.addColHeader("Measurement Type");
    worksheet.addColHeader("Measurement Unit");
    worksheet.addColHeader("Minimum Inventory");
    worksheet.addColHeader("Notes");

    filteredStockItems.forEach(stockItem =>
    {
        const dataRow = worksheet.addRow();

        dataRow.addDataCell(stockItem.code);
        dataRow.addDataCell(stockItem.name);
        dataRow.addDataCell(stockItem.category);
        dataRow.addDataCell(stockItem.isProduct);
        dataRow.addDataCell(stockItem.isWastageTracked);
        dataRow.addDataCell(stockItem.isInventoryTracked);
        dataRow.addDataCell(stockItem.unitTypeCode);
        dataRow.addDataCell(stockItem.coreUnitCode);
        dataRow.addDataCell(stockItem.minimumInventoryQuantity || "");
        dataRow.addDataCell(stockItem.notes || "");
    });
}

function populateSupplierDataWorksheet(filteredStockItems, worksheet, suppliers)
{
    const vatCaption = localizationUtils.getVatCaption();
    worksheet.addColHeader("Code");
    worksheet.addColHeader("Name");
    worksheet.addColHeader("Category");
    worksheet.addColHeader("Supplier");
    worksheet.addColHeader("Unit");
    worksheet.addColHeader("Conversion");
    worksheet.addColHeader("PLU Code");
    worksheet.addColHeader("Price");
    worksheet.addColHeader(vatCaption);
    worksheet.addColHeader("Minimum Order");

    filteredStockItems.forEach(stockItem =>
    {
        if (stockItem.suppliers.length === 0) return;

        const stockItemUnitTypes = unitUtil.getPossibleUnitsForStockItem(stockItem);

        stockItem.suppliers.forEach(stockItemSupplier => 
        {
            if (stockItemSupplier.unitTypes.length === 0) return;

            const supplier = suppliers.find(supplier => supplier.id === stockItemSupplier.supplierId);
            const supplierName = (supplier && supplier.name) || "";

            stockItemSupplier.unitTypes.forEach(supplierUnitType => 
            {
                const unitType = stockItemUnitTypes.find(unitType => unitType.id === supplierUnitType.unitTypeId);

                const unitTypeCode = (unitType && unitType.code) || "";
                const pluCode = supplierUnitType.pluCode || "";
                const price = supplierUnitType.price || "";
                const vat = supplierUnitType.vat || "";
                const minimumOrderQuantity = supplierUnitType.minimumOrderQuantity || "";

                const coreUnitCode = stockItem.coreUnitCode;
                const coreUnitConversion = (unitType && unitType.coreUnitConversionFactor) || "";

                const dataRow = worksheet.addRow();

                dataRow.addDataCell(stockItem.code);
                dataRow.addDataCell(stockItem.name);
                dataRow.addDataCell(stockItem.category);

                dataRow.addDataCell(supplierName);
                dataRow.addDataCell(unitTypeCode);
                dataRow.addDataCell(`${coreUnitConversion} ${coreUnitCode}/${unitTypeCode}`);
                dataRow.addDataCell(pluCode);
                dataRow.addDataCell(price);
                dataRow.addDataCell(vat);
                dataRow.addDataCell(minimumOrderQuantity);
            });
        });
    });
}

function populateNutritionDataWorksheet(filteredStockItems, worksheet, stockItems)
{
    const nutritionLookupData = arraySort(catalogSelectors.selectLookupData().nutritions, "code");

    worksheet.addColHeader("Code");
    worksheet.addColHeader("Name");
    worksheet.addColHeader("Category");
    worksheet.addColHeader("Base Quantity");
    worksheet.addColHeader("Base Quantity Unit");
    worksheet.addColHeader("Total Calories");

    nutritionLookupData.forEach(nutrition => worksheet.addColHeader(`${nutrition.code} (gms)`));

    filteredStockItems.forEach(stockItem =>
    {
        if (stockItem.nutritionProfile == null) return;

        const stockItemUnitTypes = unitUtil.getPossibleUnitsForStockItem(stockItem);

        const nutritionProfile = stockItem.nutritionProfile;
        const baseQuantity = nutritionProfile.baseQuantity;
        const unitTypeId = nutritionProfile.unitTypeId;
        const unitType = stockItemUnitTypes.find(unitType => unitType.id === unitTypeId);
        const nutritions = nutritionProfile.nutritions;

        const inferredNutritionQuantities = (baseQuantity && unitTypeId)
            ? nutritionLookupData.map(x =>
            ({
                nutritionCode: x.code,
                quantity: stockItemNutritionUtil.getNutritionQuantity(x.code, stockItem, stockItems)
            }))
            : [];

        const calories = calorieCalculator.calculateCalorie(inferredNutritionQuantities);
        const totalCalories = isNaN(calories) ? "N/A" : formatter.roundUp(calories, 0);

        const dataRow = worksheet.addRow();

        dataRow.addDataCell(stockItem.code);
        dataRow.addDataCell(stockItem.name);
        dataRow.addDataCell(stockItem.category);

        dataRow.addDataCell(baseQuantity || "");
        dataRow.addDataCell((unitType && unitType.code) || "");
        dataRow.addDataCell(totalCalories);

        nutritionLookupData.forEach(option => 
        {
            let nutritionOption = nutritions.find(nutrition => nutrition.nutritionCode === option.code);

            if (nutritionOption == null)
            {
                nutritionOption = {
                    nutritionCode: option.code,
                    coreUnitCode: 'gms',
                    quantity: ""
                }

                const inferredNutritionQuantity = inferredNutritionQuantities.find(x => x.nutritionCode == option.code);
                if (inferredNutritionQuantity != null && inferredNutritionQuantity.quantity)
                {
                    nutritionOption.quantity = inferredNutritionQuantity.quantity;
                    nutritionOption.inferred = true;
                }
            }

            dataRow.addDataCell(nutritionOption.quantity);
        });
    });
}

function populateAllergyDataWorksheet(filteredStockItems, worksheet, stockItems)
{
    const allergyLookupData = arraySort(catalogSelectors.selectLookupData().allergies, "code");

    worksheet.addColHeader("Code");
    worksheet.addColHeader("Name");
    worksheet.addColHeader("Category");

    allergyLookupData.forEach(allergy => worksheet.addColHeader(allergy.code));

    filteredStockItems.forEach(stockItem =>
    {
        if (stockItem.allergyProfile == null) return;

        const allergyProfile = stockItem.allergyProfile;
        const allergies = allergyProfile.allergies;

        const dataRow = worksheet.addRow();

        dataRow.addDataCell(stockItem.code);
        dataRow.addDataCell(stockItem.name);
        dataRow.addDataCell(stockItem.category);

        allergyLookupData.forEach(option => 
        {
            let allergyOption = allergies.find(allergy => allergy.allergyCode === option.code);

            if (allergyOption == null)
            {
                const inferredAllergyPresence = stockItemAllergyUtil.getAllergyPresence(option.code, stockItem, stockItems);

                if (inferredAllergyPresence && inferredAllergyPresence !== domainConstants.AllergyPresenceType.unknown)
                {
                    allergyOption = { allergyCode: option.code, presenceType: inferredAllergyPresence };
                }
            }

            if (allergyOption == null)
            {
                allergyOption = { allergyCode: option.code, presenceType: domainConstants.AllergyPresenceType.unknown }
            }

            dataRow.addDataCell(allergyOption.presenceType);
        });
    });
}

function populateTemperaturesDataWorksheet(filteredStockItems, worksheet)
{
    worksheet.addColHeader("Code");
    worksheet.addColHeader("Name");
    worksheet.addColHeader("Category");
    worksheet.addColHeader("Min Temperature");
    worksheet.addColHeader("Max Temperature");
    worksheet.addColHeader("Min Logs");
    worksheet.addColHeader("Auto Correction Logs");
    worksheet.addColHeader("Hazard Types");

    filteredStockItems.forEach(stockItem =>
    {
        if (stockItem.cookingTemperatureProfile == null) return;

        const dataRow = worksheet.addRow();

        dataRow.addDataCell(stockItem.code);
        dataRow.addDataCell(stockItem.name);
        dataRow.addDataCell(stockItem.category);
        dataRow.addDataCell(`${stockItem.cookingTemperatureProfile.minTemperature} °C`);
        dataRow.addDataCell(`${stockItem.cookingTemperatureProfile.maxTemperature} °C`);
        dataRow.addDataCell(stockItem.cookingTemperatureProfile.minTemperatureLogs);
        dataRow.addDataCell(stockItem.cookingTemperatureProfile.autoCorrectionLogs);
        dataRow.addDataCell(stockItem.cookingTemperatureProfile.hazardTypes.toString());
    });
}
