
import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import { FormGroup, InputGroup, Input, FormText, Card, CardHeader,  CardBody, CardFooter, Button, Label, Modal} from 'reactstrap';
import DateRangeComponent from '../../../../components/form/dateRangeComponent.js'



var dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
var stringUtil = require('../../../../utils/string/stringUtil.js');
const arraySort = require('array-sort');

class NewHolidayDialogue extends Component
{
    constructor(props)
    {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);

        this.renderBodyComponent = this.renderBodyComponent.bind(this);
        this.renderFullDayComponent = this.renderFullDayComponent.bind(this);
        this.renderPartialDayComponent = this.renderPartialDayComponent.bind(this);
    }
    render()
    {
        var saveDisabled = false;
        if (stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.staffId) || 
            stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.fromDate) || stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.toDate) ||
            dateUtil.isBefore(this.props.bookedHoliday.toDate, this.props.bookedHoliday.fromDate))
            saveDisabled = true;

        return(<Modal isOpen={true} centered animation={false} fade={false}  size="lg" style={{maxWidth: '1600px', width: '90%'}}>
                    <Card>
                        <CardHeader>
                            Holiday  - {this.getStatus()}
                        </CardHeader>
                        <CardBody>
                            <table width="100%">

                                <tr>
                                    <td>
                                        <FormGroup>

                                            Staff

                                            <Select
                                                value={this.props.bookedHoliday.staffId}
                                                options={this.getAllStaffsOptions()}
                                            
                                                onChange={(optionValue) =>
                                                {
                                                    this.props.bookedHoliday.staffId = optionValue == null || optionValue.underlyingObject == null ? null : optionValue.underlyingObject.id;
                                                    this.setState({});
                                                }}
                                            />
                                            <FormText color="muted">Select staff for holiday booking</FormText>
                                            

                                        </FormGroup>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{width:'50%'}}>
                                        <FormGroup {...this.props} width="600px">
                                             <span style={{marginRight:'30px'}}>Staff looking for partial day off</span>
                                            <Input type="checkbox" checked={!this.props.bookedHoliday.isFullDay}
                                                    onChange={(event)=>
                                                        {
                                                            const target = event.target;
                                                            this.props.bookedHoliday.isFullDay = !target.checked;

                                                            if (this.props.bookedHoliday.isFullDay)
                                                            {
                                                                this.props.bookedHoliday.fromDate = dateUtil.getDateComponent(this.props.bookedHoliday.fromDate);
                                                                this.props.bookedHoliday.toDate = dateUtil.getDateComponent(this.props.bookedHoliday.toDate);
                                                            }
                                                            else
                                                            {
                                                                this.props.bookedHoliday.fromDate = dateUtil.getDateComponent(this.props.bookedHoliday.fromDate);
                                                                this.props.bookedHoliday.toDate = this.props.bookedHoliday.fromDate;
                                                            }
                                                            this.setState({});
                                                        }}/>
                                            <FormText color="muted">Indicates if you are off for part of the day</FormText>
                                        </FormGroup>
                                    </td>

                                    <td style={{width:'50%'}}>
                                        {this.renderBodyComponent()}
                                    </td>
                                </tr>

                        
                                <tr>
                                    <td colSpan="2" style={{width:'100%'}}>
                                        <FormGroup {...this.props}>
                                            Instructions
                                            <Input type="textarea"  rows="2" value={this.props.bookedHoliday.notes} onChange={(event) => {
                                                        this.props.bookedHoliday.notes = event.target.value;
                                            }}/>
                                            <FormText color="muted">Enter any further details for holiday requests</FormText>
                                        </FormGroup>
                                    </td>
                                </tr>
                            </table>
                            
                        </CardBody>

                        <CardFooter>
                                    <table width="100%">
                                        <tr>
                                            <td align="right">
                                                <div>
                                                    <Button size="lg" color="danger" className="btn-secondary" style={{marginRight:'3px'}} onClick={this.onClose}>
                                                            Cancel
                                                    </Button>
                                                    <Button disabled={saveDisabled} size="lg" color="primary" className="btn-primary" style={{marginRight:'3px'}} onClick={this.onSave}>
                                                            Save
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                            </CardFooter>
                    </Card>
                </Modal>);
    }

    renderBodyComponent()
    {
        if (this.props.bookedHoliday.isFullDay)
            return this.renderFullDayComponent();

        return this.renderPartialDayComponent();
    }

    renderFullDayComponent()
    {
        return (<FormGroup>
                    Holiday Dates
                    <DateRangeComponent startDate={this.props.bookedHoliday.fromDate} endDate={this.props.bookedHoliday.toDate} 
                                        onDateRangeChange = {(startDate, endDate) =>
                                                            {
                                                                this.props.bookedHoliday.fromDate = startDate;
                                                                this.props.bookedHoliday.toDate = endDate;
                                                                this.setState({});
                                                            }}/>
                    <FormText color="muted">Choose holiday period</FormText>

                </FormGroup>);  
    }

    renderPartialDayComponent()
    {

        var fromHour = dateUtil.formatDate(dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate), "HH")
        var fromMinutes = dateUtil.formatDate(dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate), "mm")

        var toHour = dateUtil.formatDate(dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate), "HH")
        var toMinutes = dateUtil.formatDate(dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate), "mm")

        return (<table>
                    <tr>
                        <td>

                            <FormGroup>
                                <div style={{marginBottom:'8px'}}>From Time</div>
                                <InputGroup>
                                   <Label style={{paddingRight: '5px', paddingTop: '10px' }}>Hrs:</Label>
                                    <Select
                                    value={fromHour}
                                    options={this.getPossibleHours()}
                                    placeholder="Hrs"
                                    clearable={false}
                                    onChange={(optionValue) =>
                                    {
                                        if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value))
                                        {
                                            var fromDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate);
                                            fromDate = dateUtil.setHours(fromDate, 0);
                                            fromDate = dateUtil.setMinutes(fromDate, 0);
                                            this.props.bookedHoliday.fromDate = dateUtil.convertToUTCStandard(fromDate);
                                        }
                                        else
                                        {
                                            var fromDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate);
                                            fromDate = dateUtil.setHours(fromDate, optionValue.value);
                                            this.props.bookedHoliday.fromDate = dateUtil.convertToUTCStandard(fromDate);
                                        }

                                        this.setState({});
                                    }} />
                                    <Label style={{ paddingLeft: '10px', paddingRight: '5px', paddingTop: '10px' }}>Mins:</Label>
                                    <Select
                                        value={fromMinutes}
                                        options={this.getPossibleMinutes()}
                                        placeholder="Hrs"
                                        clearable={false}
                                        onChange={(optionValue) =>
                                        {
                                            if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value))
                                            {
                                                var fromDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate);
                                                fromDate = dateUtil.setHours(fromDate, 0);
                                                fromDate = dateUtil.setMinutes(fromDate, 0);
                                                this.props.bookedHoliday.fromDate = dateUtil.convertToUTCStandard(fromDate);
                                            }
                                            else
                                            {
                                                var fromDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate);
                                                fromDate = dateUtil.setMinutes(fromDate, optionValue.value);
                                                this.props.bookedHoliday.fromDate = dateUtil.convertToUTCStandard(fromDate);
                                        }
                                            this.setState({});
                                        }} />
                                </InputGroup>
                            </FormGroup>
                        </td>

                        <td>
                            <div style={{marginRight:'40px'}}></div>
                        </td>

                        <td>
                            <FormGroup>
                                <div style={{marginBottom:'8px'}}>To Time</div>
                                <InputGroup>
                                   <Label style={{paddingRight: '5px', paddingTop: '10px' }}>Hrs:</Label>
                                    <Select
                                    value={toHour}
                                    options={this.getPossibleHours()}
                                    placeholder="Hrs"
                                    clearable={false}
                                    onChange={(optionValue) =>
                                    {
                                        if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value))
                                        {
                                            var toDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate);
                                            toDate = dateUtil.setHours(toDate, 0);
                                            toDate = dateUtil.setMinutes(toDate, 0);
                                            this.props.bookedHoliday.toDate = dateUtil.convertToUTCStandard(toDate);
                                        }
                                        else
                                        {
                                            var toDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate);
                                            toDate = dateUtil.setHours(toDate, optionValue.value);
                                            this.props.bookedHoliday.toDate = dateUtil.convertToUTCStandard(toDate);
                                        }
                                        this.setState({});
                                    }} />
                                    <Label style={{ paddingLeft: '10px', paddingRight: '5px', paddingTop: '10px' }}>Mins:</Label>
                                    <Select
                                        value={toMinutes}
                                        options={this.getPossibleMinutes()}
                                        placeholder="Hrs"
                                        clearable={false}
                                        onChange={(optionValue) =>
                                        {
                                            if (optionValue == null || stringUtil.isStringNullOrEmpty(optionValue.value))
                                            {
                                                var toDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate);
                                                toDate = dateUtil.setHours(toDate, 0);
                                                toDate = dateUtil.setMinutes(toDate, 0);
                                                this.props.bookedHoliday.toDate = dateUtil.convertToUTCStandard(toDate);
                                            }
                                            else
                                            {
                                                var toDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate);
                                                toDate = dateUtil.setMinutes(toDate, optionValue.value);
                                                this.props.bookedHoliday.toDate = dateUtil.convertToUTCStandard(toDate);
                                            }
                                            this.setState({});
                                        }} />
                                </InputGroup>
                            </FormGroup>
                        </td>
                    </tr>
        </table>)
    }

    onClose()
    {
        if (this.props.onCancel)
            this.props.onCancel();
    }

    onSave()
    {
        if (this.props.onSave)
            this.props.onSave(this.props.bookedHoliday);
    }

    getPossibleHours()
    {
        var options = [];

        for (var i = 0; i <= 23; i++)
        {
            var formattedString = i + "";
            formattedString = formattedString.padStart(2, '0');

            let option = {};
            option.value = formattedString;
            option.label = formattedString;
            options.push(option);
        }
        return options;
    }

    getPossibleMinutes()
    {
        var options = [];

        for (var i = 0; i <= 59; i++)
        {
            var formattedString = i + "";
            formattedString = formattedString.padStart(2, '0');

            let option = {};
            option.value = formattedString;
            option.label = formattedString;
            options.push(option);
        }
        return options;
    }

    getStatus()
    {
        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "pending"))
            return "Pending";

        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "approved"))
            return "Approved";

        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "rejected"))
            return "Rejected";
    }

    getAllStaffsOptions()
    {
        var options = [];
        this.props.allStaffs.forEach(staff =>
        {
            if (!stringUtil.isStringNullOrEmpty(staff.resignationDate))
                return;

            var option = {};
            option.value = staff.id;
            option.label = `${staff.firstName} ${staff.lastName}`
            option.underlyingObject = staff;
            options.push(option);
        });

        options = arraySort(options, ['label']);

        return options;
    }
}

export default NewHolidayDialogue;