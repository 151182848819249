const actionTypes = require('../../../actionTypes');
const catalogSelectors = require('../../../stateSelectors/catalogSelectors')

const CatalogData = require('./catalogData');

module.exports.createAction = function (catalogData)
{
    return (
        {
            type: actionTypes.catalogData,
            payload: Object.assign(new CatalogData(), catalogData)
        }
    );
}

module.exports.changeState = function (previousState, payload)
{
    return Object.assign({}, previousState, { catalogData: payload });
}

module.exports.createOrganizationTaxonomyAction = function (organizationTaxonomyData)
{
    let organizationTaxonomy = catalogSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, organizationTaxonomyData);

    return this.createAction(Object.assign({}, catalogSelectors.selectCatalogData(), { organizationTaxonomy }));
}

// Update actions

module.exports.createFranchisorsUpdateAction = function (franchisors)
{
    let organizationTaxonomy = catalogSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { franchisors });

    return this.createAction(Object.assign({}, catalogSelectors.selectCatalogData(), { organizationTaxonomy }));
}

module.exports.createFranchiseesUpdateAction = function (franchisees)
{
    let organizationTaxonomy = catalogSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { franchisees });

    return this.createAction(Object.assign({}, catalogSelectors.selectCatalogData(), { organizationTaxonomy }));
}

module.exports.createPropertiesUpdateAction = function (properties)
{
    let organizationTaxonomy = catalogSelectors.selectOrganizationTaxonomy();
    organizationTaxonomy = Object.assign({}, organizationTaxonomy, { properties });

    return this.createAction(Object.assign({}, catalogSelectors.selectCatalogData(), { organizationTaxonomy }));
}
