
import React, { Component } from 'react';
import formManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

export default class KEKRAEndorsementComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new formManager();
        this.formManager.viewModel = this.props.saleEndorsementSettings;
        this.formManager.view = this;

        // Initialize defaults
        const viewModel = this.formManager.viewModel;
        viewModel.selectedCurrency = viewModel.selectedCurrency || "KSH";
        viewModel.serviceUrl = viewModel.serviceUrl || "http://localhost:8084/api/sign?invoice";
        viewModel.authorizationKey = viewModel.authorizationKey || "ZxZoaZMUQbUJDljA7kTExQ==2023";
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Invoice PIN Number"
                                fieldName="invoicePin"
                                placeholder="KRA Registration Number"
                                hintText="Restaurant KRA Registration Number (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Invoice Currency"
                                fieldName="selectedCurrency"
                                placeholder="Enter invoice currency"
                                hintText="Enter 'KSH' for Kenyan Shilling (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Invoice Number Prefix"
                                fieldName="invoiceNumberPrefix"
                                placeholder="Invoice Number Prefix"
                                hintText="Optional prefix to preceed every invoice number. (Optional)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Basic Authorization Key"
                                fieldName="authorizationKey"
                                placeholder="Basic Authorization Key"
                                hintText="Enter Basic Authorization Key (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Service URL"
                                fieldName="serviceUrl"
                                placeholder="Enter service url"
                                hintText="KRA ESD Service URL Endpoint (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                </tbody>
            </ table>
        </div>
    }
}