import React, { Component } from 'react';
import MultiSelectComponent from '../../../../../../components/form/multiSelectComponent';

const constants = require('../../../../../../utils/domain/constants');
const validator = require('../../../../../../utils/validator/validator');
const FormManager = require('../../../../../../utils/view/formManager.js');

const storefrontUtils = require('../../utils/storefrontUtils');

export default class WebAppConfigurationDispatchPaymentMethods extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
        
        this.onDineInPaymentMethodsSelectionChanged = this.onDineInPaymentMethodsSelectionChanged.bind(this);
        this.onCollectionPaymentMethodsSelectionChanged = this.onCollectionPaymentMethodsSelectionChanged.bind(this);
        this.onDeliveryPaymentMethodsSelectionChanged = this.onDeliveryPaymentMethodsSelectionChanged.bind(this);
        this.getPaymentMethods = this.getPaymentMethods.bind(this);
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    getPaymentMethods()
    {
        return(
            [
                {label: constants.paymentMethods.Cash},
                {label: constants.paymentMethods.Credit}
            ]
        );
    }

    onDineInPaymentMethodsSelectionChanged = (selectedOptions) =>
    {
        const webAppConfigurations = this.state.formManager.viewModel;

        webAppConfigurations.dineInPaymentMethods = [];

        if (selectedOptions.length > 0)
        {
            webAppConfigurations.dineInPaymentMethods = selectedOptions.map(option => option.label);
        }

        this.setState({});
    }

    onCollectionPaymentMethodsSelectionChanged = (selectedOptions) =>
    {
        const webAppConfigurations = this.state.formManager.viewModel;

        webAppConfigurations.collectionPaymentMethods = [];

        if (selectedOptions.length > 0)
        {
            webAppConfigurations.collectionPaymentMethods = selectedOptions.map(option => option.label);
        }

        this.setState({});
    }

    onDeliveryPaymentMethodsSelectionChanged = (selectedOptions) =>
    {
        const webAppConfigurations = this.state.formManager.viewModel;

        webAppConfigurations.deliveryPaymentMethods = [];

        if (selectedOptions.length > 0)
        {
            webAppConfigurations.deliveryPaymentMethods = selectedOptions.map(option => option.label);
        }

        this.setState({});
    }

    render()
    {
        return (            
            
            <table className='component-table'>
                <tr>                    
                    <td>
                        <MultiSelectComponent
                            caption="DineIn Payment Methods"
                            valueFieldName="label"
                            displayFieldName="label"
                            options={this.getPaymentMethods()}
                            selectedOptions={
                                                validator.isNonEmptyArray(this.state.formManager.viewModel.dineInPaymentMethods)
                                                    ? this.state.formManager.viewModel.dineInPaymentMethods.map(method => ({label: method}))
                                                    : []
                                            }
                            onSelectionChanged={this.onDineInPaymentMethodsSelectionChanged}
                            hintText="Select DineIn Payment Methods"
                            comboReadOnly={this.isReadOnly} />
                    </td>
                    <td>
                        <MultiSelectComponent
                            caption="Collection Payment Methods"
                            valueFieldName="label"
                            displayFieldName="label"
                            options={this.getPaymentMethods()}
                            selectedOptions={
                                                validator.isNonEmptyArray(this.state.formManager.viewModel.collectionPaymentMethods)
                                                    ? this.state.formManager.viewModel.collectionPaymentMethods.map(method => ({label: method}))
                                                    : []
                                            }
                            onSelectionChanged={this.onCollectionPaymentMethodsSelectionChanged}
                            hintText="Select Collection Payment Methods"
                            comboReadOnly={this.isReadOnly} />
                    </td>                    
                </tr>
                <tr>
                    <td>
                        <MultiSelectComponent
                            caption="Delivery Payment Methods"
                            valueFieldName="label"
                            displayFieldName="label"
                            options={this.getPaymentMethods()}
                            selectedOptions={
                                                validator.isNonEmptyArray(this.state.formManager.viewModel.deliveryPaymentMethods)
                                                    ? this.state.formManager.viewModel.deliveryPaymentMethods.map(method => ({label: method}))
                                                    : []
                                            }
                            onSelectionChanged={this.onDeliveryPaymentMethodsSelectionChanged}
                            hintText="Select Delivery Payment Methods"
                            comboReadOnly={this.isReadOnly} />
                    </td>
                    <td>

                    </td>
                </tr>
            </table>
        );
    }    
}
