
import React, { Component } from 'react';
import GridView from '../../../../components/gridView/gridView';
import GridViewButton from '../../../../components/gridView/gridViewButton'
import Sale from './components/Sale';
import TransferSales from './components/TransferSales';
import Spinner from '../../../../components/spinner/spinner';
import ErrorMessage from '../../../../components/error/errorMessage';
import arraySort from 'array-sort';

const reportsLoadingFacade = require('../../../../utils/api/reportsLoadingFacade');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const viewUtil = require('../../../../utils/view/viewUtil');
const stringUtil = require('../../../../utils/string/stringUtil');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const workerChannel = require('../../../../utils/messaging/workerChannel')
const saleComponentUtil = require('./utils/saleComponentUtil');

const MAX_SALES_TRANSFER_SIZE = 200;
const SALES_BATCH_SIZE = 50;

export default class Sales extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onSearchSales = this.onSearchSales.bind(this);

        this.getSalesTransferActionButton = this.getSalesTransferActionButton.bind(this);
        this.state = { staffList: [], storefronts: [], targetProperty: {id: null} };
    }

    async componentDidMount()
    {
        this.setState({ isLoading : true});
        
        try 
        {
            const staffList = await apiLoadFacade.loadStaff();
            const storefronts = await apiLoadFacade.loadPropertyStorefronts();

            const isLoading = false;
            this.setState({ staffList , storefronts, isLoading});
        }
        catch (error) 
        {
            this.setState({error});
        }
    }

    render() 
    {
        if ((this.state.isLoading))
            return <Spinner text='Loading Data'></Spinner>;

        if (this.state.error)
            return <ErrorMessage message={this.state.error} />;

        const brands = new Set(this.state.storefronts.map(x => x.brand));
        const showBrands = brands.size > 1;
        const gridViewOptions = saleComponentUtil.generateGridOptions(showBrands, "Sales", "Sale", (row) => <Sale sale={row} staffList={this.state.staffList} storefronts={this.state.storefronts} />, this.onSearchSales, [
            this.getSalesTransferActionButton(),
        ]);

        return (<GridView gridViewOptions={gridViewOptions} />);
    }


    async onSearchSales(searchCriteria)
    {
        try
        {
            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const results = await reportsLoadingFacade.getSales(fromDate, toDate);

            saleComponentUtil.enrichSales(results, this.state.storefronts, this.state.staffList);

            const sales = this.filterSales(results);

            arraySort(sales, "dateTime");

            return sales;
        }
        catch (error)
        {
            throw error;
        }
    }

    filterSales(sales) {
        return sales.filter(sale => !sale.isCancelled);
    }

    async onTransferSalesButtonPress(gridApi)
    {
        if(this.state.targetProperty.id)
                this.setState({targetProperty: {id: null}});

        return  new Promise((resolve, reject) =>
                {
                    viewUtil.openModalForm(
                        "Transfer Sales", 
                        () => 
                        {
                            return  <TransferSales 
                                        targetProperty={this.state.targetProperty}
                                    />;
                        },
                        () => { 
                            this.onTransfer(this.state.targetProperty.id, gridApi);
                            resolve(null); 
                        },
                        () => 
                        {
                            if(stringUtil.isStringNullOrEmpty(this.state.targetProperty.id)) 
                                return "Transfer Property cannot be empty";
                        },
                        false,
                        null,
                        () => resolve(true))
                });
    }

    onTransfer(targetPropertyId, gridApi)
    {
        let saleObjects = []

        gridApi.forEachNodeAfterFilter(node => 
        {
            saleObjects.push({id: node.data.id, businessDate: node.data.businessDate});
        });

        const batchSize = SALES_BATCH_SIZE;
        const type = 'saleMigration';
        const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
        
        for(let partIndex = 0; partIndex < Math.ceil(saleObjects.length / batchSize); partIndex++)
        {
            let batchSales = [];
            
            for (let index = batchSize * partIndex; (index < batchSize * (partIndex + 1)) && (index < saleObjects.length); index++)
            {
                batchSales.push(saleObjects[index]);       
            }

            let messagePayLoad = {};
            messagePayLoad.type = type;
            messagePayLoad.orgContext = orgContext;
            messagePayLoad.targetPropertyId = targetPropertyId;
            messagePayLoad.sales = batchSales;
            workerChannel.publishMessage(messagePayLoad);
        }
        
        const rowData = [];
        gridApi.forEachNodeAfterFilter((node) =>
        {
            rowData.push(node.data);
        });

        gridApi.applyTransaction(
        {
            remove: rowData,
        });

        viewUtil.showSuccessAlert("Sales scheduled successfully for transfer, this process might take several minutes to complete");
    }

    getSalesTransferActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Transfer Sales";
        button.color = "warning";
        button.onRowSelect = false;
        button.actionType = "user";

        button.actionConfirmationTitle = "Transfer Sales";
        button.onAction = async (gridApi) => 
        {
            let filteredRows = []

            gridApi.forEachNodeAfterFilter(node => 
            {
                filteredRows.push(node.data);
            });

            if(filteredRows.length <= 0)
            {
                viewUtil.showErrorAlert("No sales selected for transfer");
            }
            else if(filteredRows.length > MAX_SALES_TRANSFER_SIZE)
            {
                viewUtil.showErrorAlert(`Please select ${MAX_SALES_TRANSFER_SIZE} or less sales to transfer`);
            }
            else
                await this.onTransferSalesButtonPress(gridApi); 
        };
        return button;
    }
}
