
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import Spinner from '../../../components/spinner/spinner.js';
import { Input } from 'reactstrap';

import StationActivation from './StationActivation';
import StationConfiguration from './StationConfiguration.jsx';
import ReceiptPrinters from './printers/receiptPrinters/receiptPrinters';
import LabelPrinters from './printers/labelPrinters/labelPrinters';
import CreditCardSettings from './creditCardTerminals/creditCardSettings';
import PeripheralSettings from './peripheral/peripheralSettings';
import EndorsementSettings from './endorsements/endorsementSettings';
import KOTConfigurations from './kot/kotConfigurations.jsx';

import { AiFillChrome } from "react-icons/ai";
import { AiOutlineCopy } from "react-icons/ai";


const configurationManager = require('../../../utils/config/configurationManager');
const securityManager = require('../../../utils/domain/security/securityManager');
const terminalTypeConstants = require('../../../utils/constants/terminalTypeConstants');
const stationState = require('./stationState');
const stationUtil = require('./stationUtil');
const stringUtil = require('../../../utils/string/stringUtil');
const viewUtil = require('../../../utils/view/viewUtil');
const logger = require('../../../utils/logger/logger.js');



class Station extends Component 
{
    constructor(props)
    {
        super(props);

        this.deleteStation = this.deleteStation.bind(this);
        this.activateStation = this.activateStation.bind(this);
        this.onStationActivation = this.onStationActivation.bind(this);
        this.generateUrl = this.generateUrl.bind(this);

        this.state = {};
        this.state.stateInitialized = false;
        this.state.error = null;
      
        this.state.currentSettingsOptions = null;
    }

    render()
    {
        if (this.state.currentSettingsOptions == null)
        {
            return (<Card>
                        <CardHeader>
                            {this.renderToolbar()}
                        </CardHeader>
                        <CardBody>
                            <div style={{minHeight:window.innerHeight * 0.50}}>
                                {this.renderOptions()}
                            </div>
                        </CardBody>
                    </Card>
                    );
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "Settings"))
        {
            return (<StationConfiguration isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "Receipt Printers"))
        {
            return (<ReceiptPrinters isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "Label Printers"))
        {
            return (<LabelPrinters isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "Credit Card Terminals"))
        {
            return (<CreditCardSettings isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "Peripheral Devices"))
        {
            return (<PeripheralSettings isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "KOT"))
        {
            return (<KOTConfigurations isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }

        if (stringUtil.areStringSame(this.state.currentSettingsOptions, "Endorsements"))
        {
            return (<EndorsementSettings isReadOnly={this.props.isReadOnly} onClose={(isUpdated)=>
            {
                this.state.currentSettingsOptions = null;
                this.setState({});
            }}/>);
        }
    }

    renderToolbar()
    {
        var generateUrlButton = null;
        const generateURLBtnDisabled = !this.state.stateInitialized || this.state.error != null || !this.props.canGenerateStationURL;

        if (securityManager.isSystemUser())
        {
            generateUrlButton = <Button color="warning" className="btn-warning" disabled={generateURLBtnDisabled} style={{ marginRight: '100px' }} onClick={this.generateUrl}>
                                    <AiFillChrome style={{fontSize:'20px', marginTop:'-3px', marginRight:'5px', color:'#585858'}}/>Generate URL
                                </Button>
        }
        return (
                    <table width="100%">
                        <tr>
                            <td>
                                <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>Station - {this.props.stationViewModel.shell.name}</div>
                            </td>
                            <td align="right">

                                {generateUrlButton}

                                {
                                    !this.props.isReadOnly &&
                                    <>
                                        <Button color="danger" className="btn-danger" disabled={!this.state.stateInitialized || this.state.error != null} style={{ marginRight: '10px' }} onClick={this.deleteStation}>
                                            Delete Station
                                        </Button>

                                        <Button color="primary" className="btn-primary" disabled={!this.state.stateInitialized || this.state.error != null} style={{ marginRight: '30px' }} onClick={this.activateStation}>
                                            Activate Station
                                        </Button>
                                    </>
                                }

                                <Button color="secondary" className="btn-secondary" disabled={!this.state.stateInitialized} style={{ marginRight: '3px' }} onClick={()=>{this.props.onCancel()}}>
                                    Back
                                </Button>
                            </td>
                        </tr>
                    </table>
                );
    }

    renderOptions()
    {
        if (this.state.error)
            return this.renderError();

        if (!this.state.stateInitialized)
        {
            this.initializeState();
            return this.renderLoadingOptions();
        }

        return this.renderStationSettingComponents();

        
    }

    renderError()
    {
        return <div style={{marginTop:'100px'}}>
                    <div style={{color: '#585858', fontSize:'3em', textAlign:'center'}}>
                        Oops! Something went wrong.
                    </div>

                    <div style={{color: '#989898', fontSize:'1em', textAlign:'center'}}>
                        {this.state.error}
                    </div>
                </div>
    }

    async initializeState()
    {
        try
        {
            await stationState.setCurrentStationViewModelAndLoadIntegrations(this.props.stationViewModel);
            this.state.stateInitialized = true;
            this.setState({});
        }
        catch(error)
        {
            this.state.stateInitialized = true;
            this.state.error = error;
            this.setState({});
        }
    }

    

    renderLoadingOptions()
    {
        return <div style={{marginTop:'200px', textAlign:'center'}}>
                        <Spinner text="Please wait, loading station configurations" />
                </div>
    }   

    renderStationSettingComponents()
    {
        var stationSettingComponents = [];
        stationSettingComponents.push(this.renderOption("station.settings.png", "Settings", "Station Settings"));
        stationSettingComponents.push(this.renderOption("station.settings.receipt.printers.png", "Receipt Printers", "Attached Receipt Printers"));
        stationSettingComponents.push(this.renderOption("station.settings.label.printers.png", "Label Printers", "Attached Label Printers"));
        stationSettingComponents.push(this.renderOption("station.settings.kot.png", "KOT", "Kitchen Order Tickets"));
        
        stationSettingComponents.push(this.renderOption("station.settings.cli.png", "Peripheral Devices", "Telephone line (CLI) - Customer Display Screen"));
        stationSettingComponents.push(this.renderOption("station.settings.credit.cards.png", "Credit Card Terminals", "Credit Card Terminals"));
        stationSettingComponents.push(this.renderOption("station.settings.endorsements.png", "Endorsements", "Sales Endorsements"));

        return <div style={{background:'#F6F9FF', minHeight:'100%', marginTop:'-16px', marginLeft:'-16px', marginRight:'-16px', paddingTop:'40px', paddingLeft:'40px', paddingRight:'40px'}}>
                    <div className="d-flex flex-row flex-wrap w-100">
                            {stationSettingComponents}
                    </div>
                </div>
    }

    renderOption(image, title, description)
    {
        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;

        return <div style={{border: '1px solid #C8C8C8', background:'white', marginRight:'20px', 
                            marginBottom:'30px', minWidth:'400px', width:'25vw', minHeight:'100px', 
                            paddingTop:'20px', paddingBottom:'20px', cursor:'default', userSelect:'none'}} onClick={()=>
                            {
                                this.state.currentSettingsOptions = title;
                                this.setState({});
                            }}>

                    <table style={{width:'100%'}}>
                    <tr>
                        <td style={{width:'1%', whiteSpace:'nowrap'}}>
                            <img src={`${rootUrl}/app/back.office/menu/${configurationManager.getConfig().themeVersion}/${image}`} style={{width:'80px', marginRight:'20px', marginLeft:'20px'}}/>
                        </td>
                        <td style={{width:'99%'}}>
                            <table>
                                <tr>
                                    <td>
                                        <h3 style={{margin:'0px'}}>{title}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>{description}</small>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </table>
                </div>
    }

    deleteStation()
    {
        viewUtil.showConfirmDialogue(`You are going to remove station '${this.props.stationViewModel.shell.name}'`, `This action cannot be undone. Are you sure you want to continue`,
        async ()=>
        {
            try
            {
                viewUtil.showSystemModalSpinner("Please wait");
                await stationUtil.removeShell(this.props.stationViewModel.shell);
                viewUtil.closeModalSpinner();
                this.props.onCancel();
            }
            catch(error)
            {
                viewUtil.closeModalSpinner();
                viewUtil.showErrorAlert(error);
            }
        })
    }

    activateStation()
    {
        this.state.shellInstallation = {};
        this.state.shellInstallation.installationId = "";
        viewUtil.openModalForm("Shell Activation", () => <StationActivation data={this.state.shellInstallation}></StationActivation>,
            this.onStationActivation,
            () =>
            {
                if (stringUtil.isStringNullOrEmpty(this.state.shellInstallation.installationId))
                {
                    return "Installation Id is not populated";
                }

                return null;
            })
    }

    async onStationActivation()  
    {
        const installationId = this.state.shellInstallation.installationId;
        const licenseKey = this.props.stationViewModel.shell.licenseKey;
        
        viewUtil.showSystemModalSpinner("Linking Shell License");
        
        try
        {
            await stationUtil.linkShell(installationId, licenseKey);
            viewUtil.showSuccessAlert(`Shell license linked`);
        }
        catch (error)
        {
            logger.error("Error occurred while activating station", null, error);
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.state.shellInstallation.installationId = null;
            this.setState({ })
        }
    }

    async generateUrl()
    {
        var currentStationViewModel = stationState.currentStationViewModel;
        var terminals = currentStationViewModel.terminals;

        var terminal = null;
        var url = null;

        if (currentStationViewModel.stationType == terminalTypeConstants.pos)
        {
            url = configurationManager.getConfig().appUrlPOS;
            terminal = terminals.find (t => t.type == terminalTypeConstants.pos);
        }

        if (currentStationViewModel.stationType == terminalTypeConstants.kiosk)
        {
            url = configurationManager.getConfig().appUrlKiosk;
            terminal = terminals.find (t => t.type == terminalTypeConstants.kiosk);
        }

        if (currentStationViewModel.stationType == terminalTypeConstants.kds)
        {
            url = configurationManager.getConfig().appUrlKDS;
            terminal = terminals.find (t => t.type == terminalTypeConstants.kds);
        }

        if (currentStationViewModel.stationType == terminalTypeConstants.kitchen)
        {
            url = configurationManager.getConfig().appUrlKitchen;
            terminal = terminals.find (t => t.type == terminalTypeConstants.kitchen);
        }

        if (currentStationViewModel.stationType == terminalTypeConstants.centralKitchenKds)
        {
            url = configurationManager.getConfig().appUrlCentralKitchen;
            terminal = terminals.find (t => t.type == terminalTypeConstants.centralKitchenKds);
        }

        if (currentStationViewModel.stationType == terminalTypeConstants.signage)
        {
            url = configurationManager.getConfig().appUrlSignagePlayer;
            terminal = terminals.find (t => t.type == terminalTypeConstants.signage);
        }

        if (terminal == null)
        {
            viewUtil.showErrorAlert("Failed to find related terminal");
            return;
        }

        try
        {
            viewUtil.showSystemModalSpinner("Please wait");
            var result = await stationUtil.getAppIdForBrowser(terminal.code, 730);
            viewUtil.closeModalSpinner();

            var resolvedUrlWithoutPrinter = `${url}/#/?appId=${result.appId}`;
            var resolvedUrlWithPrinter = `${resolvedUrlWithoutPrinter}&pdfPrinter=true`;

            viewUtil.openModalForm(
                "Station URL",
                () =>
                {
                    return <table style={{width:'100%'}}>

                                <tr>
                                    <td colSpan={2}>
                                        <div style={{fontWeight:'bold', color:'#707070'}}>Station with printer</div>
                                        <small style={{color:'#808080'}}>This station have an attached pdf printer which will be used to print sale receipts and kitchen tickets</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'99%'}}>
                                        <Input
                                            type="name"
                                            disabled={true}
                                            value={resolvedUrlWithPrinter}
                                        />
                                    </td>
                                    <td style={{whiteSpace:'nowrap'}}>
                                        <Button color="secondary" className="btn-secondary" style={{marginLeft:'5px', background:'#D3D3D3'}}
                                                onClick={()=>
                                                {
                                                    navigator.clipboard.writeText(resolvedUrlWithPrinter);
                                                }}>
                                            <AiOutlineCopy style={{fontSize:'20px', color:'#484848'}}/>
                                        </Button>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={2}>
                                        <div style={{marginTop:'40px'}}></div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={2}>
                                        <div style={{fontWeight:'bold', color:'#707070'}}>Station without printer</div>
                                        <small style={{color:'#808080'}}>This station does not have any attached printer.</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Input
                                            type="name"
                                            disabled={true}
                                            value={resolvedUrlWithoutPrinter}
                                        />
                                    </td>
                                    <td>
                                        <Button color="secondary" className="btn-secondary" style={{marginLeft:'5px', background:'#D3D3D3'}}
                                                onClick={()=>
                                                {
                                                    navigator.clipboard.writeText(resolvedUrlWithoutPrinter);
                                                }}>
                                            <AiOutlineCopy style={{fontSize:'20px', color:'#484848'}}/>
                                        </Button>
                                    </td>
                                </tr>
                            </table>
                },
                null, null, true, null,
                () => {}
            );
        }
        catch(error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(error);
        }

    }
    
}

export default Station;