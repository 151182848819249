import React, { useState, useMemo, useCallback } from 'react';

import arraySort from 'array-sort';

import InputComponent from './components/InputComponent';
import ListComponent from './components/ListComponent';

import * as stringUtil from '../../utils/string/stringUtil';
import * as sortingUtil from '../../utils/sorting/sortingUtil';

/*
    Props:

    initialSearchString     = optional, searchString used to initialize component
    initialSelectedOption   = optional, selectedOption used to initialize component

    After initialization, component maintains/manages its own internal state for searchString/selectedOption

    options                 = required, array of option objects
    displayFieldName        = required, property used to access display string on option
    selector                = required, function(a,b)=>boolean, providing logic used to match selectedOption against options
    searchPlaceholder       = optional, placeholder string used in the search box
    onSelectedOptionChanged = required, function used to notify client of option selection
*/

export default function SearchableListComponent(props)
{
    const { selector, options, displayFieldName, searchPlaceholder, onSelectedOptionChanged, initialSearchString, initialSelectedOption } = props;

    const [searchString, setSearchString] = useState(initialSearchString || '');
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

    const handleSelectedOptionChanged = useCallback(selectedOption =>
    {
        setSelectedOption(selectedOption);
        onSelectedOptionChanged(selectedOption);
    }, [onSelectedOptionChanged]);

    const handleSearchStringChanged = useCallback(searchString => setSearchString(searchString), []);

    const filteredOptions = useMemo(() => getFilteredOptions(searchString, options, displayFieldName), [searchString, options, displayFieldName]);

    return (
        <div className='h-100 d-flex flex-column'>
            <div className='mb-1'>
                <InputComponent
                    searchString={searchString}
                    searchPlaceholder={searchPlaceholder}
                    onSearchStringChanged={handleSearchStringChanged} />
            </div>
            <div className='h-75 flex-grow-1'>
                <ListComponent
                    selector={selector}
                    options={filteredOptions}
                    selectedOption={selectedOption}
                    displayFieldName={displayFieldName}
                    onSelectedOptionChanged={handleSelectedOptionChanged} />
            </div>
        </div>
    );
}

function getFilteredOptions(searchString, options, displayFieldName)
{
    let filteredOptions = [...options];

    if (!stringUtil.isStringNullOrEmpty(searchString))
    {
        const regex = new RegExp(searchString, "i");
        filteredOptions = options.filter(option => option[displayFieldName].match(regex));
    }

    return arraySort(filteredOptions, sortingUtil.localeCompareProp(displayFieldName));
}
