const rmsApiProxy = require("../../../utils/api/rmsApiProxy");
const currentOrgNodeSelectors = require("../../../utils/state/stateSelectors/currentOrgNodeSelectors");
const orgSelectors = require("../../../utils/state/stateSelectors/orgSelectors");

export async function loadStations() {
     if (!currentOrgNodeSelectors.isCurrentPropertySelected()) {
          throw new Error("Property selection is required to generate stations menu");
     }

     const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/stations`;
     var stations = await rmsApiProxy.get(resourceUrl);

     if (stations == null) stations = [];

     return stations;
}

export async function getAppIdForBrowser (terminalCode, expiryInHours)
{
    var url = `core/system/security/urlEncoder?terminalCode=${terminalCode}&expiryInHours=${expiryInHours}`;
    var result = await rmsApiProxy.get(url);
    return result;
}

export async function linkShell(installationId, licenseKey)
{
    const linkShell = { installationId, licenseKey } ;
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/shells/link`;
    return await rmsApiProxy.post(resourceUrl, linkShell);
}

export async function updateStations(stations) {
     const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/stations`;
     return await rmsApiProxy.post(resourceUrl, stations);
}

export function getOrgContext() {
     const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();
     return { franchisorId, franchiseeId, propertyId };
}

export function generateStationCode() {
     const currentOrgNode = getOrgContext();
     const franchisor = orgSelectors.selectFranchisor(currentOrgNode.franchisorId);
     const property = orgSelectors.selectProperty(currentOrgNode.propertyId);

     return `${franchisor.code.toLowerCase()}.${property.code.toLowerCase()}.${Date.now()}.station`;
}
