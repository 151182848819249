var viewUtil = require('../view/viewUtil.js');
var validator = require('../validator/validator.js');
var dateUtil = require('../dateUtil/dateUtil.js');
var stringUtil = require('../string/stringUtil.js');

module.exports = function()
{
    this.viewModel = null;
    this.view = null;
    this.onFieldChanged = null;
    
    this.getValue = (fieldName) =>
    {
        var accessors = fieldName.split(".");
        var sourceObject = this.viewModel;

        for (var index = 0; index < accessors.length-1; index++)
        {
            sourceObject = sourceObject[accessors[index]];
        }

        return sourceObject[accessors[accessors.length-1]];
    }

    this.setValue = (fieldName, fieldValue) =>
    {
        var accessors = fieldName.split(".");
        var sourceObject = this.viewModel;

        for (var index = 0; index < accessors.length-1; index++)
        {
            sourceObject = sourceObject[accessors[index]];
        }

        sourceObject[accessors[accessors.length-1]] = fieldValue;
    }

    this.onValueChanged = (event) =>
    {
        if (event == null)
        {
            this.raiseChangeEvent(event);
            return;    
        }
        
        const target = event.target;
        var value = target.value;

        if (target.type === 'checkbox')
            value = target.checked;

        if (target.type === 'date' || target.type === 'time')
        {
            if (!stringUtil.isStringNullOrEmpty(value))
                value = dateUtil.convertToUTCStandard(value);       
        }

      
        this.setValue(target.id, value);
        viewUtil.clearAlert();

        this.raiseChangeEvent(event);
    }

    this.raiseChangeEvent = (event) =>
    {
        if (this.onFieldChanged != null)
                this.onFieldChanged(event);

        if (this.view)
            this.view.setState({});
    }
}