module.exports.dateComparator = (filter, value) => 
{
    //
    // dateComparator makes use of the following known facts about date in code:
    // 1 - date is always a string
    // 2 - dates are always in sortable "YYYY-MM-DD" format
    //

    // filter is javascript Date object, formatted below to our standard YYYY-MM-DD pattern
    // to leverage string comparison

    const formattedFilter = [
        filter.getFullYear(),
        ('0' + (filter.getMonth() + 1)).slice(-2),
        ('0' + filter.getDate()).slice(-2)
    ].join('-');

    return value.localeCompare(formattedFilter);
}
