import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';

function FranchisorDatabaseConfigurationComponent(props) {

    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <InputComponent inputType="name" caption="Database Name" hintText="Required"
                            fieldName="databaseName" placeholder="Enter Franchisor Database Name"
                            formManager={props.formManager} />
                    </td>

                    <td>
                        <InputComponent inputType="web"
                            caption="Database Server"
                            hintText="Required"
                            fieldName="databaseServer"
                            placeholder="Enter Franchisor Database Server"
                            formManager={props.formManager} />
                    </td>
                </tr>

            </tbody>
        </table>)

}
export default FranchisorDatabaseConfigurationComponent;