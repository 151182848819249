
import React, { Component } from 'react';
import { Badge} from 'reactstrap';

const stringUtil = require('../../../utils/string/stringUtil');
const dateUtil = require('../../../utils/dateUtil/dateUtil');

export default class DueDateRenderer extends Component
{
  render()
  {
      var rawCurrentValue = this.props.value;
      if (stringUtil.isStringNullOrEmpty(rawCurrentValue))
        return null;

      var days = dateUtil.differenceInDays(rawCurrentValue, dateUtil.getToday());

      var color = "success";
      
      if (days < 0)
      {
          days = days * -1;
          color = "danger";
      }
      else if (days == 0)
        color = "warning";
      else if (days < 3)
        color = "primary";

      var formattedValue = `${days}d`;
      if (days > 7 & days <= 31)
      {
        formattedValue = `${Math.round(days/7)}w`;
      }
      if (days > 31)
      {
        formattedValue = `${Math.round(days/30)}m`;
      }



      return <div>{dateUtil.formatDate(dateUtil.convertToLocalStandard(rawCurrentValue), "YYYY-MMM-DD")} <Badge style={{marginLeft:'5px', marginTop:'-8px'}} className="me-1" color={color}> {formattedValue} </Badge></div>
  }
}