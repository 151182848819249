
import React from 'react';
import Jumbotron from "../jumbotron/jumbotron";

export default function EmptyData({ title = 'No data available', text = 'There is no data available', action = '' })
{
    return <div>
        <Jumbotron>
            <h4>{title}</h4>
            <p className="lead">{text}</p>
            <hr className="my-2" />
            <p>{action}</p>
        </Jumbotron>
    </div>;
}
