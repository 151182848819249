import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import OverviewSummary from './components/OverviewSummary';
import WagesSummary from './components/WagesSummary';
import CogsSummary from './components/CogsSummary';
import VenueCommissionsSummary from './components/VenueCommissionsSummary';
import FixedCostsSummary from './components/FixedCostsSummary';

import CustomBIToolBaseComponent from '../../common/customBITools/CustomBIToolBaseComponent';

import * as stringUtil from '../../../../utils/string/stringUtil';
import * as localizationUtils from '../../../../utils/domain/localizationUtils.js';

import * as periodicCostSummaryModel from './periodicCostSummaryModel';

export default class PeriodicCostSummaryReport extends CustomBIToolBaseComponent
{
    render()
    {
        let resultComponent = null;

        if (this.state.stage === 0)
        {
            if (!stringUtil.isStringNullOrEmpty(this.state.error))
                resultComponent = this.renderErrorComponent(this.state.error)
            else
                resultComponent = this.renderCaptionComponent("BI (Business Intelligence) Tool :: Costs Overview Report");
        }

        if (this.state.stage === 1)
        {
            this.loadData();
            resultComponent = this.renderSpinnerComponent("Please Wait");
        }

        if (this.state.stage === 2)
        {
            resultComponent = this.renderSummaryReport();
        }

        return <div>
            {
                this.renderMultiDateSelectionHeaderComponent
                    (
                        "Costs Overview Report",
                        this.state.stage === 1,
                        true,
                        (searchCriteria) =>
                        {
                            this.state.searchCriteria = searchCriteria;
                            this.state.error = null;
                            this.state.stage = 1;
                            this.setState({});
                        },
                        () =>
                        {
                            this.state.error = null;
                            this.state.searchCriteria = null;
                            this.state.data = null;
                            this.state.stage = 0;
                            this.setState({});
                        })
            }
            {resultComponent}
        </div>;
    }

    renderSummaryReport = () =>
    {
        const vatCaption = localizationUtils.getVatCaption();

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab key="Overview">Overview</Tab>);
        tabContents.push(<TabPanel key="Overview" className='bg-white mt-n1'><OverviewSummary data={this.state.data} vatCaption={vatCaption} /></TabPanel>);

        tabHeaders.push(<Tab key="Wages">Wages</Tab>);
        tabContents.push(<TabPanel key="Wages" className='bg-white mt-n1'><WagesSummary data={this.state.data} vatCaption={vatCaption} /></TabPanel>);

        tabHeaders.push(<Tab key="COGS">COGS</Tab>);
        tabContents.push(<TabPanel key="COGS" className='bg-white mt-n1'><CogsSummary data={this.state.data} vatCaption={vatCaption} /></TabPanel>);

        tabHeaders.push(<Tab key="Venue Commissions">Venue Commissions</Tab>);
        tabContents.push(<TabPanel key="Venue Commissions" className='bg-white mt-n1'><VenueCommissionsSummary data={this.state.data} vatCaption={vatCaption} /></TabPanel>);

        tabHeaders.push(<Tab key="Fixed Costs">Fixed Costs</Tab>);
        tabContents.push(<TabPanel key="Fixed Costs" className='bg-white mt-n1'><FixedCostsSummary data={this.state.data} vatCaption={vatCaption} /></TabPanel>);

        return (
            <div className='p-2 mt-n3 bg-white'>
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }


    async loadData()
    {
        const fromDate = this.state.searchCriteria.startDate;
        const toDate = this.state.searchCriteria.endDate;

        try 
        {
            const data = await periodicCostSummaryModel.loadData(fromDate, toDate);

            this.setState({
                data: data,
                stage: 2
            });

        }
        catch (error) 
        {
            this.setState({
                error: error.toString(),
                stage: 0
            });
        }
    }
}

