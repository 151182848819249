
const ExcelWorksheet = require('./excelWorkSheet');

module.exports = function ()
{
    this.workBookName = null;
    this.workSheets = [];

    this.addWorksheet = function (title)
    {
        var worksheet = new ExcelWorksheet();
        worksheet.workSheetName = title;
        this.workSheets.push(worksheet);
        return worksheet;
    }
}