

import React, { Component } from 'react';
import SelectComponent from '../../../../components/form/selectComponent';

const stationState = require('../stationState');
const FormManager = require('../../../../utils/view/formManager.js');


class KitchenConfiguration extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.model;
        this.formManager.view = this;
    }

    render()
    {
        const printerOptions = [];
        stationState.labelPrinters.forEach(printer =>
            {
                const printerUrl = printer.url;
                printerOptions.push({ value: printerUrl, label: printerUrl });  
            });

        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td >
                                    <SelectComponent
                                        optionValues={printerOptions}
                                        optionFieldName="value"
                                        optionDisplayFieldName="label"
                                        caption="Choose Label Printer"
                                        fieldName="labelPrinter"
                                        hintText="Select label printer"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} />
                                </td>

                                <td >
                                    
                                </td>
                            </tr>

                        </tbody>
                    </ table>
                </div>
    }
}

export default KitchenConfiguration;