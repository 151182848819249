
import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';

import Spinner from '../../../../components/spinner/spinner';
import CloseButton from '../../../../components/button/closeButton';
import EmptyData from '../../../../components/empty/emptyData';
import ErrorMessage from '../../../../components/error/errorMessage';
import GridComponent from '../../../../components/grid/gridComponent';

import driverSalesUtils from '../../../../utils/domain/sale/driverSalesUtils';

import SearchBarComponent from '../../../../components/form/searchBarComponent';
import DriverDialogue from './components/DriverDialogue';

const stringUtil = require("../../../../utils/string/stringUtil");
const viewUtil = require("../../../../utils/view/viewUtil");
const saleUtils = require('../../../../utils/domain/sale/saleUtil');
const saleLoaderUtil = require('../../../../utils/domain/sale/saleLoaderUtil');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


class DriverSales extends Component 
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            isLoading: false, saleHeaders: null,
            isError: false, errorMessage: null,
            staffList: [],
            staffRoles: [],
            searchCriteria: { startDate: dateUtil.getDateComponent(dateUtil.subtractDays(dateUtil.getNow(), 7)), endDate: dateUtil.getDateComponent(dateUtil.getNow()) }
        };

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);
        this.onAssignDriver = this.onAssignDriver.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount()
    {
        this.onLoadReport(this.state.searchCriteria);
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onAssignDriver()
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const driver = { driverId: selectedRow.driverId };

        viewUtil.openModalForm(
            selectedRow.number,
            (onFormFieldChanged) =>
            {
                return (<DriverDialogue driver={driver} staffList={this.state.staffList} staffRoles={this.state.staffRoles} />);
            },
            () =>
            {
                this.onSave(selectedRow, driver.driverId);
            },
            () => 
            {
                //No validation
            },
            false
        );
    }

    onSave(saleHeader, driverId)
    {
        viewUtil.showSystemModalSpinner("Please wait, while assigning driver");

        saleUtils.assignDriver(saleHeader.propertyId, saleHeader.id, driverId, saleHeader.dateTime)
            .then(result =>
            {
                viewUtil.closeModalSpinner();

                if (!stringUtil.areStringSame(driverId, saleHeader.driverId))
                {
                    saleHeader.driverId = driverId;
                    driverSalesUtils.enrichDriverName([saleHeader], this.state.staffList);
                }

                this.setState({});

            }, error =>
            {
                viewUtil.closeModalSpinner();
                viewUtil.showCriticalAlert(error);

                this.setState({});
            });
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onCriteriaChanged = () =>
    {
        this.setState(
            {
                isLoading: false, saleHeaders: null,
                isError: false, errorMessage: null,
            });
    }

    onLoadReport = async (searchCriteria) =>
    {
        this.setState(
            {
                isLoading: true, saleHeaders: null,
                isError: false, errorMessage: null,
            });

        try
        {
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
            const propertyId = currentOrgNode.propertyId;

            const fromDate = searchCriteria.startDate;
            const toDate = searchCriteria.endDate;

            const saleHeaders = [];

            const result = await saleLoaderUtil.loadSaleHeaders(fromDate, toDate, propertyId);

            if (this.state.staffList.length == 0)
            {
                this.state.staffList = await apiLoadFacade.loadStaff();
                this.state.staffRoles = await apiLoadFacade.loadStaffRoles();
            }

            const storefrontsOperatingConfigurations = await apiLoadFacade.loadPropertyStorefrontOperatingConfigurations();

            const filtered = driverSalesUtils.filterAndSortDriverActionData(result, storefrontsOperatingConfigurations);
            driverSalesUtils.enrichDriverName(filtered, this.state.staffList);
            saleHeaders.push(...filtered);

            this.setState(
                {
                    isLoading: false,
                    saleHeaders: saleHeaders
                });

        } catch (error)
        {
            this.setState(
                {
                    isLoading: false, saleHeaders: null,
                    isError: true, errorMessage: `Failed to load report data with error: ${error}`,
                });
        }
    }

    render() 
    {
        return (

            <Card>

                <CardHeader>
                    {this.renderHeader()}
                </CardHeader>

                <CardBody>
                    {this.renderReportContents()}
                </CardBody>

            </Card>);
    }

    renderHeader()
    {
        var assignDriverButton = null;
        var exportButton = null;
        var closeButton = null;

        var disabled = this.state.isLoading;

        assignDriverButton = <Button disabled={disabled} color="success" className="btn-success" style={{ marginRight: '10px' }} onClick={() => this.onAssignDriver()}>
            <i className="fa fa-motorcycle"></i>&nbsp;Assign Driver
        </Button>;

        exportButton = <Button disabled={disabled} color="primary" className="btn-primary" style={{ marginRight: '3px' }} onClick={() => this.gridApi.exportDataAsCsv()}>
            <i className="fa fa-download"></i>&nbsp;Export
        </Button>;

        closeButton = <CloseButton disabled={disabled} />

        return (
            <table width="100%">
                <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>Driver Sales &nbsp;</div>
                    </td>

                    <td width="99%">
                        {this.renderSearchBar()}
                    </td>

                    <td style={{ whiteSpace: "nowrap" }} align="right">

                        {assignDriverButton}
                        {exportButton}
                        {closeButton}

                    </td>
                </tr>

            </table>)
    }

    renderSearchBar()
    {
        return (
            <SearchBarComponent
                searchButtonCaption="Load"
                disabled={this.state.isLoading}
                onSearch={this.onLoadReport}
                onChange={this.onCriteriaChanged}
                searchCriteria={this.state.searchCriteria}
            />);
    }

    renderReportContents()
    {
        const { isLoading, saleHeaders, isError, errorMessage } = this.state;

        if (isError)
        {
            return <ErrorMessage message={errorMessage} />;
        }

        if (isLoading)
        {
            return <Spinner text="Loading Data" />
        }

        if (!saleHeaders)
        {
            // Not loading, but no data either
            return <EmptyData action="Select or change criteria and hit Load button to load data" />;
        }

        return (
            <GridComponent
                headers={this.constructGridColumnHeaders()}
                onGridReady={this.onGridReady}
                paginationEnabled={true}
                paginationSize={25}
                gridMode="controlled"
                rows={saleHeaders}
                shouldReArrangeHeaders={true}
            />);
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "dateTime";
        header.headerName = "Sale Date";
        header.type = dataTypeConstants.dateTime;
        header.width = columnWidthConstants.date;
        headers.push(header);

        header = {}
        header.field = "number";
        header.headerName = "Order Number";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {}
        header.field = "storefrontName";
        header.headerName = "Storefront";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "driverName";
        header.headerName = "Driver Name";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "balance";
        header.headerName = "Total Due Amount";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "venueCode";
        header.headerName = "Venue";
        header.width = columnWidthConstants.code;
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }
}

export default DriverSales;