import React, { Component } from 'react';
import DateTimeInputComponent from '../../../../../components/form/dateTimeInputComponent.js';
import InputComponent from '../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../components/form/selectComponent';

import constants from '../../../../../utils/domain/constants';

class CampaignOverview extends Component 
{
    render() 
    {
        const options = Object.keys(constants.campaignType).map(x => { return { code: constants.campaignType[x] } });
        return (
            <table width="100%" >
                <tr>
                    <td width="50%" style={{paddingRight:'10px'}}>
                        <InputComponent inputType="name" caption="Campaign Name" hintText="Required" fieldName="name"
                            placeholder="Enter name of the campaign"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.props.formManager} />
                    </td>

                    <td width="50%" style={{paddingLeft:'10px'}}>
                        <SelectComponent
                            optionValues={options}
                            optionFieldName="code"
                            optionDisplayFieldName="code"
                            caption="Campaign Type"
                            fieldName="type"      // target.id
                            hintText="Enter type of campaign (Required)"
                            clearable={true}
                            comboReadOnly={this.props.isReadOnly}
                            formManager={this.props.formManager} />
                    </td>
                </tr>

                <tr>
                    <td width="50%" style={{paddingRight:'10px'}}>
                        <DateTimeInputComponent
                            inputType="dateTime" caption="Start Date" hintText="Start date of the campaign (Required)"
                            fieldName="startDateTime"
                            placeholder="Enter start date of the campaign"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.props.formManager} />
                    </td>

                    <td width="50%" style={{paddingLeft:'10px'}}>
                        <DateTimeInputComponent 
                            inputType="dateTime" caption="End Date" hintText="End date of campaign"
                            fieldName="endDateTime"
                            placeholder="Enter end date of campaign"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.props.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                            fieldName="notes" placeholder="Enter any notes"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.props.formManager} />
                    </td>
                </tr>
            </table>
        )
    }
}

export default CampaignOverview;

