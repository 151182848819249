
import React, { Component } from 'react';
import { connect } from 'react-redux'
import KioskSkinsListComponent from './list/KioskSkinsListComponent.js'
import KioskSkinComponent from './skin/KioskSkinComponent'


const stringUtil = require('../../../../utils/string/stringUtil.js');
const typeUtil = require('../../../../utils/type/typeUtil.js');
const guidUtil = require('../../../../utils/guid/guidUtil.js');

class KioskSkins extends Component
{
    constructor(props)
    {
        super(props);

        this.renderListView = this.renderListView.bind(this);
        this.renderSkin = this.renderSkin.bind(this);
       
        this.state = {};
        this.state.viewType = "list";
        this.state.listViewState = {};
    }

    render()
    {
        if (stringUtil.areStringSame(this.state.viewType, "list"))
            return this.renderListView();

        if (stringUtil.areStringSame(this.state.viewType, "new") || stringUtil.areStringSame(this.state.viewType, "edit"))
            return this.renderSkin();
    }

    renderListView()
    {
        return <KioskSkinsListComponent listViewState={this.state.listViewState} 
                                isReadOnly={this.props.isReadOnly}
                                onNewSkin={(franchisorId)=>
                                                {
                                                    this.state.viewType = "new";
                                                    
                                                    this.state.currentSkin = {};
                                                    this.state.currentSkin.id = guidUtil.generateGuid();
                                                    this.state.currentSkin.franchisorId = franchisorId;
                                                    this.state.currentSkin.playerId = null;
                                                    this.state.currentSkin.name = null;
                                                    this.state.currentSkin.notes = null;
                                                    this.state.currentSkin.fontName = "Lucida Console";
                                                    this.state.currentSkin.backgroundColor = "#f7f7f7";
                                                    this.state.currentSkin.width = 1080;
                                                    this.state.currentSkin.height = 1920;

                                                     /* HOME */
                                                    this.state.currentSkin.home = {};
                                                    this.state.currentSkin.home.backgroundColor = null;

                                                    this.state.currentSkin.home.orderHereButtonImage = null;
                                                    this.state.currentSkin.home.orderHereButtonWidth = null;
                                                    this.state.currentSkin.home.orderHereButtonHeight = null;
                                                    this.state.currentSkin.home.orderHereButtonLeft = null;
                                                    this.state.currentSkin.home.orderHereButtonTop = null;

                                                    this.state.currentSkin.home.dispatchDialogueWidth = null;
                                                    this.state.currentSkin.home.dispatchDialoguePadding = null;
                                                    this.state.currentSkin.home.dispatchButtonWidth = null;
                                                    this.state.currentSkin.home.dispatchButtonHeight = null;
                                                    this.state.currentSkin.home.dispatchTakeAwayButtonBackgroundColor = "#A80000 ";
                                                    this.state.currentSkin.home.dispatchTakeAwayButtonForegroundColor = "#F8F8F8";
                                                    this.state.currentSkin.home.dispatchDineInButtonBackgroundColor = "#3300FF";
                                                    this.state.currentSkin.home.dispatchDineInButtonForegroundColor = "#F8F8F8";
                                                    this.state.currentSkin.home.dispatchButtonFontFamily = null;
                                                    this.state.currentSkin.home.dispatchButtonFontSize = null;

                                                    /* SALE */
                                                    this.state.currentSkin.sale = {};
                                                    this.state.currentSkin.sale.backgroundColor = null;
                                                    this.state.currentSkin.sale.contentPanelHeight = null;

                                                    this.state.currentSkin.sale.categoryPanelWidth = 324;
                                                    this.state.currentSkin.sale.categoryPanelTopPadding = 40;
                                                    this.state.currentSkin.sale.categoryPanelSidePadding = 20;
                                                    
                                                    this.state.currentSkin.sale.categoryPanelLogoImage = null;
                                                    this.state.currentSkin.sale.categoryPanelLogoImageWidth = 160;
                                                    this.state.currentSkin.sale.categoryPanelLogoImageHeight = 160;
                                                    this.state.currentSkin.sale.categoryPanelLogoImageBottomPadding = 40;
                                                    
                                                    this.state.currentSkin.sale.categoryPanelCaption = "Menu";
                                                    this.state.currentSkin.sale.categoryPanelCaptionFontName = null;
                                                    this.state.currentSkin.sale.categoryPanelCaptionFontSize = 20;
                                                    this.state.currentSkin.sale.categoryPanelCaptionForegroundColor = "#B0B0B0";
                                                    this.state.currentSkin.sale.categoryPanelCaptionBottomPadding = 20;
                                                    
                                                    this.state.currentSkin.sale.menuCategoryFontName = null;
                                                    this.state.currentSkin.sale.menuCategoryFontSize = 20;
                                                    this.state.currentSkin.sale.menuCategoryBackgroundColor = '#FFFFFF';
                                                    this.state.currentSkin.sale.menuCategoryForegroundColor = '#888888';
                                                    this.state.currentSkin.sale.menuCategorySelectedCategoryColor = '#FF7722';
                                                    
                                                    this.state.currentSkin.sale.categoryItemsPanelTopPadding = 40;
                                                    this.state.currentSkin.sale.categoryItemsPanelSidePadding = 20;
                                                    this.state.currentSkin.sale.categoryItemsPanelCaptionFontName = null;
                                                    this.state.currentSkin.sale.categoryItemsPanelCaptionFontSize = 20;
                                                    this.state.currentSkin.sale.categoryItemsPanelCaption = null;
                                                    this.state.currentSkin.sale.categoryItemsPanelCaptionForegroundColor = "#B0B0B0";
                                                    this.state.currentSkin.sale.categoryItemsPanelCaptionBottomPadding = null;
                                                
                                                    this.state.currentSkin.sale.categoryItemBottomPadding = null;
                                                    this.state.currentSkin.sale.categoryItemImageRadius = null;
                                                    this.state.currentSkin.sale.categoryItemImageWidth = null;
                                                    this.state.currentSkin.sale.categoryItemImageHeight = null;
                                                    this.state.currentSkin.sale.categoryItemTitleFontName = null;
                                                    this.state.currentSkin.sale.categoryItemTitleFontSize = null;
                                                    this.state.currentSkin.sale.categoryItemNotesFontName = null;
                                                    this.state.currentSkin.sale.categoryItemNotesFontSize = null;
                                                    
                                                    this.state.currentSkin.sale.bottomPanelTopPadding = 40;
                                                    this.state.currentSkin.sale.bottomPanelSidePadding = 40;
                                                    this.state.currentSkin.sale.bottomPanelFontName = null;
                                                    this.state.currentSkin.sale.bottomPanelFontSize = 20;

                                                    this.state.currentSkin.sale.bottomPanelStartButtonBackgroundColor = null;
                                                    this.state.currentSkin.sale.bottomPanelStartButtonForegroundColor = null;
                                                    this.state.currentSkin.sale.bottomPanelPaymentBackgroundColor = null;
                                                    this.state.currentSkin.sale.bottomPanelPaymentForegroundColor = null;

                                                    this.state.originalSkin = null;
                                                    this.setState({});
                                                }}
                                onEditSkin={(existingSkin)=>
                                                {
                                                    this.state.viewType = "edit";
                                                    this.state.currentSkin = typeUtil.deepCloneObject(existingSkin);
                                                    this.state.originalSkin = existingSkin;

                                                    this.setState({});
                                                }}/>
    }

    renderSkin()
    {
        
        return <KioskSkinComponent  skin={this.state.currentSkin} players={this.state.listViewState.listOfPlayers}
                                        isReadOnly={this.props.isReadOnly}
                                        onCancel={()=>{
                                                            this.state.viewType = "list";
                                                            this.state.currentSkin = null;
                                                            this.state.originalSkin = null;

                                                            this.setState({});
                                                       }}
                                        onSaved={(updatedSkin)=>{

                                                        if (this.state.originalSkin == null)
                                                        {
                                                            this.state.listViewState.listOfSkins.push(updatedSkin)
                                                        }
                                                        else
                                                        {
                                                            var index = this.state.listViewState.listOfSkins.findIndex(p => p.id == updatedSkin.id);
                                                            this.state.listViewState.listOfSkins[index] = updatedSkin;
                                                        }

                                                        this.state.viewType = "list";
                                                        this.state.currentSkin = null;
                                                        this.state.originalSkin = null;

                                                        this.setState({});
                                                    }}/>
    }
}

export default KioskSkins;