import React from "react";
import { Button } from "reactstrap";

const commonUtility = require("../../../../../utils/domain/commonUtility");
const constants = require("../../../../../utils/domain/constants");

const CloseButton = ({ onClick }) => {
     return (
          commonUtility.getApplicationMode() === constants.applicationModes.embedded 
          ?   null 
          :    <Button color="secondary" onClick={onClick}>
                    Close
               </Button>
     );
};

export default CloseButton;
