import React, { Component } from 'react';

import GridComponent from '../grid/gridComponent';
import ExcelExportButton from '../button/excelExportButton';

import * as columnWidthConstants from '../../utils/constants/columnWidthConstants';
import * as reportExcelExport from '../../views/reports/reportUtils/components/reportExcelExport';

export default class ExternalIntegrationLogDataForm extends Component
{
    render()
    {
        return (
            <div className='d-flex flex-column'>

                <div className='align-self-end mb-3 me-1' style={{ marginTop: "-60px" }}>
                    <ExcelExportButton
                        onExport=
                        {
                            async () => reportExcelExport.exportWorkbook("Integration Log Data", this.gridApi)
                        } />
                </div>

                <div style={{ width: "700px", height: "275px" }}>
                    <GridComponent
                        rows={this.props.logData}
                        headers={this.getColumnHeaders()}
                        shouldReArrangeHeaders={true}
                        paginationEnabled={true}
                        paginationSize={5}
                        onGridReady={this.onGridReady} />
                </div>
            </div>
        );
    }

    onGridReady = (params) =>
    {
        this.gridApi = params.api;
    }

    getColumnHeaders = () =>
    {
        let header;

        const headers = [];

        header = {};
        header.field = "key";
        header.headerName = "Key";
        header.pinned = true;
        header.width = columnWidthConstants.code - 100;
        headers.push(header);

        header = {};
        header.field = "value";
        header.headerName = "Value";
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }
}
