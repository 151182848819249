import React, {Component} from "react";
import { Col, Row } from "reactstrap";

import InputComponent from "../../../../../../components/form/inputComponent";
import SelectComponent from "../../../../../../components/form/selectComponent";

const localizationUtils = require("../../../../../../utils/domain/localizationUtils");
const securityManager = require("../../../../../../utils/domain/security/securityManager");

class BrandDetails extends Component
{
    render()
    {
        return (
            <div>
                <Row>
                    <Col>
                        <InputComponent 
                            fieldName="name"
                            caption="Name"
                            hintText="Specify brand name (Required)"
                            placeholder="Enter name"
                            formManager={this.props.formManager}
                            inputReadOnly={this.props.isReadOnly}
                        />
                    </Col>
                    <Col>
                        <SelectComponent
                            caption="Country"
                            fieldName="country"
                            optionFieldName="name"
                            optionDisplayFieldName="name"
                            optionValues={localizationUtils.getCountries()}
                            hintText="Optional, required only if country of operation differs from franchisor country"
                            clearable={true}
                            comboReadOnly={!this.props.isNew || this.props.isReadOnly}
                            formManager={this.props.formManager} />
                    </Col>
                </Row>
                {securityManager.isSystemUser() && <Row>
                    <Col>
                        <InputComponent 
                            fieldName="appId"
                            caption="App Id"
                            hintText="App Id to be linked with the brand (Required)"
                            placeholder="Enter App Id"
                            formManager={this.props.formManager}
                            inputReadOnly={this.props.isReadOnly}
                        />
                    </Col>
                    <Col></Col>
                </Row>}
            </div>
        )
    }
}

export default BrandDetails;