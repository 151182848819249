import React from 'react';

export default function InputDisplayComponent({ caption, value, hint })
{
    //
    // This component emulates look and feel of a readonly state InputComponent
    //

    return (
        <div className="d-flex flex-column pb-3">
            <div className="mb-2">{caption}</div>
            <div style={{
                border: "1px solid lightgrey",
                borderRadius: "4px",
                background: "#F4F6F6",
                padding: "6px 10px 5px 10px",
            }}>
                {value || "\u202f"}
            </div>
            {hint && <div className="small text-muted mt-1">{hint}</div>}
        </div>
    );
}