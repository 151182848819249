import React from 'react';

import { getColorByIndex } from '../../../../reportUtils/helperUtils/viewUtils';

const formatter = require('../../../../../../utils/formatter/formatter');
const domainConstants = require('../../../../../../utils/domain/constants');

export function HeaderComponent({ caption, showDetails, onShowDetails, index })
{
    const color = getColorByIndex(index);

    return (
        <div className='py-2 position-relative d-flex justify-content-center align-items-center rounded-top' style={{ backgroundColor: color.background }}>
            <div className='fw-bold' style={{ color: color.foreground }}>{caption}</div>
            <div
                className='position-absolute end-0 me-2 border rounded bg-white px-2 py-1 user-select-none'
                style={{ fontSize: "0.65em", cursor: "pointer" }}
                onClick={onShowDetails}>
                {showDetails ? "Hide\u00A0" : "Show"} Details
            </div>
        </div>
    );
}

export function NoDataFoundComponent({ message = "No Data Found" })
{
    return (
        <div className='py-3 d-flex justify-content-center align-items-center'>
            <h6 className='text-danger'>{message}</h6>
        </div>
    );
}

export function getColumnCount(dataLength, maxCols = 4)
{
    let remainder;

    if (dataLength === 0) throw new Error("Invalid dataLength");
    if (maxCols < 2) throw new Error("Invalid maxCols");

    while (maxCols > 2) 
    {
        if (dataLength <= maxCols) return maxCols;

        remainder = dataLength % maxCols;
        if (remainder === 0 || remainder > 1) return maxCols;

        maxCols--;
    }

    return 2;
}

export function formatAmount(value) 
{
    return formatter.convertToAmountFormat(value, true, 2, 2);
}

export function formatPercentage(num, den)
{
    if (num === 0) return `0 %`;
    if (den === 0) return 'N/A';
    return `${formatter.roundUp(num / den * 100, 0)} %`;
}

export function getStorefrontTypeLabel(storefrontType)
{
    const storefront = domainConstants.getStorefrontTypes().find(storefront => storefront.value === storefrontType);
    return storefront ? storefront.label : "N/A";
}