
const ExcelWorkRow = require('./excelWorkRow');

module.exports = function ()
{
    this.workSheetName = null;
    this.rows = [];
    this.columnHeaders = [];

    this.addColHeader = function (title, charLength=30)
    {
        var columnHeader = {};
        columnHeader.title = title;
        columnHeader.width = {};
        columnHeader.width.wch = charLength;
        columnHeader.style = {};
        columnHeader.style.font = { sz: "14",  bold: true, color: {rgb: "FFF8F8F8"} };
        columnHeader.style.alignment = { horizontal: "center" };
        columnHeader.style.fill = {patternType: "solid", fgColor: {rgb: "FF505050"}};

        this.columnHeaders.push(columnHeader);
        return columnHeader;
    }

    this.addRow = function ()
    {
        var row = new ExcelWorkRow();
        this.rows.push(row);
        return row;
    }
}