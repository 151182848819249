
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import BooleanSelectComponent from '../../../../components/form/booleanSelectComponent';

const stationState = require('../stationState');
const stationUtil = require('../stationUtil');
const constants = require('../../../../utils/domain/constants');
const FormManager = require('../../../../utils/view/formManager.js');
const guidUtil = require('../../../../utils/guid/guidUtil');
const viewUtil = require('../../../../utils/view/viewUtil');
const stringUtil = require('../../../../utils/string/stringUtil');
const terminalTypeConstants = require('../../../../utils/constants/terminalTypeConstants');

class PeripheralSettings extends Component 
{
    constructor(props)
    {
        super(props);

        this.onSave = this.onSave.bind(this);

        this.state = {};

        this.state.model = {};
        this.state.model.telephoneLineEnabled = false;
        this.state.model.displaySignage = false;

        var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal != null && hardwareTerminal.profile != null && hardwareTerminal.profile.settings != null)
        {
            this.state.model.telephoneLineEnabled = hardwareTerminal.profile.settings.telephoneLineEnabled;
            this.state.model.displaySignage = hardwareTerminal.profile.settings.displaySignage;
        }

        this.formManager = new FormManager();
        this.formManager.viewModel = this.state.model;
        this.formManager.view = this;

    }

    render()
    {
        return (<Card>
                    <CardHeader>
                        {this.renderToolbar()}
                    </CardHeader>
                    <CardBody>
                        <div style={{minHeight:window.innerHeight * 0.50}}>
                            {this.renderOptions()}
                        </div>
                    </CardBody>
                </Card>
                );
    }

    renderToolbar()
    {
        return (
                    <table width="100%">
                        <tr>
                            <td>
                                <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Peripheral Devices</div>
                            </td>
                            <td align="right">
                                {
                                    !this.props.isReadOnly &&
                                    <Button color="success" className="btn-success" style={{ marginRight: '10px' }} onClick={this.onSave}>
                                        Save
                                    </Button>
                                }
                                <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={()=>{this.props.onClose()}}>
                                    {this.props.isReadOnly ? "Close" : "Cancel"}
                                </Button>
                            </td>
                        </tr>
                    </table>
                );
    }

    renderOptions()
    {
        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td>
                                    <BooleanSelectComponent
                                        caption="Does station have customer display"
                                        fieldName="displaySignage"
                                        hintText="Indicate if customer display is attached with station"
                                        clearable={false}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'10px'}}/>
                                </td>
                                <td>
                                    <BooleanSelectComponent
                                        caption="Is Telephone line attached"
                                        fieldName="telephoneLineEnabled"
                                        hintText="Indicate if telephone line is attached with station"
                                        clearable={false}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'10px'}}/>
                                </td>
                            </tr>
                        </tbody>

                    </ table>
            </div>
    }

    async onSave()
    {
        var lastError = null;

        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            var shell = stationState.currentStationViewModel.shell;
            var doesRequireHardwareTerminal = this.state.model.telephoneLineEnabled || this.state.model.displaySignage;
    
            var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
    
            if (doesRequireHardwareTerminal)
            {
                if (hardwareTerminal == null)
                    hardwareTerminal = stationUtil.createNewHardwareTerminal(stationState.currentStationViewModel);
    
                if (hardwareTerminal.profile == null)
                    hardwareTerminal.profile = {};
    
                if (hardwareTerminal.profile.settings == null)
                    hardwareTerminal.profile.settings = {};
    
                hardwareTerminal.profile.settings.telephoneLineEnabled = this.state.model.telephoneLineEnabled;
                hardwareTerminal.profile.settings.displaySignage = this.state.model.displaySignage;
    
                await stationUtil.addUpdateTerminal(hardwareTerminal);
                await stationUtil.addUpdateShell(shell);
            }
            else
            {
                if (hardwareTerminal == null)
                    return;

                if (hardwareTerminal.profile == null)
                    hardwareTerminal.profile = {};
    
                if (hardwareTerminal.profile.settings == null)
                    hardwareTerminal.profile.settings = {};
    
                hardwareTerminal.profile.settings.telephoneLineEnabled = this.state.model.telephoneLineEnabled;
                hardwareTerminal.profile.settings.displaySignage = this.state.model.displaySignage;
    
                await stationUtil.addUpdateTerminal(hardwareTerminal);
            }
        }
        catch(error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();

            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }
}

export default PeripheralSettings;