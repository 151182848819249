
const reduxStore = require('redux');
const rootReducer = require('./rootReducer.js');

const store = reduxStore.createStore(rootReducer.reducer);

module.exports.getStore = function ()
{
    return store;
}
