
import React, { Component } from 'react';
import { Row, Col, Card, CardTitle, CardText, CardHeader, Button, CardBody, CardFooter, CardImg } from 'reactstrap';
import { connect } from 'react-redux'

import { Modal } from 'react-responsive-modal';

var viewUtil = require('../../utils/view/viewUtil.js');

class ModalForm extends Component
{


    constructor(props)
    {
        super(props);

        this.onCloseButton = this.onCloseButton.bind(this);
        this.onSaveButton = this.onSaveButton.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onFormFieldChanged = this.onFormFieldChanged.bind(this);

        this.state = {};
        this.state.error = null;
    }

    render()
    {
        var errorComponent = null;
        if (this.state.error)
        {
            const hStyle = { color: '#FFA07A' };
            errorComponent = <div align="middle"><h5 style={ hStyle }>{this.state.error}</h5></div>
        }

        var buttonsPanel;

        if (this.props.modalForm.isReadOnly)
        {
            buttonsPanel = <div>
                                <Button color="primary" className="btn-secondary" style={{marginRight:'3px'}} onClick={this.onCloseButton}>
                                        Close
                                </Button>
                            </div>
        }
        else
        {
            var deleteButton = null;

            if (this.props.modalForm.onDelete)
            {
                deleteButton = <Button color="danger" className="btn-danger" style={{marginRight:'3px'}} onClick={this.onDelete}>
                                    Delete
                                </Button>
            }

            buttonsPanel = <div>
                                <Button color="primary" className="btn-secondary" style={{marginRight:'3px'}} onClick={this.onCloseButton}>
                                        Cancel
                                </Button>
                                {deleteButton}
                                <Button color="primary" className="btn-primary" style={{marginRight:'3px'}} onClick={this.onSaveButton}>
                                        {this.props.modalForm.options.confirmBtnTitle}
                                </Button>
                                
                            </div>
        }

        return(
                            
                <Modal  open={true} onClose={this.onCloseButton} center showCloseIcon={false}
                            classNames={{
                                            overlay: 'customOverlay',
                                            modal: 'customModal',
                                        }}>

                <h2 className="text-muted">{this.props.modalForm.title}</h2>
                <hr></hr>
                {errorComponent}
                {this.props.modalForm.componentCreator(this.onFormFieldChanged)}
                <hr></hr>
                <table width="100%">
                <tr>
                <td align="right">

                    {buttonsPanel}

                </td>
                </tr>
                </table>
                </Modal>
        )
    }

    onFormFieldChanged()
    {
        this.state.error = null;
        this.setState({});
    }

    onCloseButton()
    {
        if (this.props.modalForm.onCancel)
            this.props.modalForm.onCancel();
            
        viewUtil.closeModalForm(this.props.modalFormIndex);
    }

    async onSaveButton()
    {
        try
        {
            if (this.props.modalForm.onValidate)
            {
                var errorMessage = await this.props.modalForm.onValidate();
                if (errorMessage != null)
                {
                    this.state.error = errorMessage;
                    this.setState({});
                    return;
                }
            }
    
            await this.props.modalForm.onSave();
            viewUtil.closeModalForm(this.props.modalFormIndex);
        }
        catch(error)
        {
            this.state.error = error;
            this.setState({});
        }
    }

    onDelete()
    {
        if (this.props.modalForm.onDelete)
            this.props.modalForm.onDelete();

        viewUtil.closeModalForm(this.props.modalFormIndex);
    }

}

const mapStateToProps = (state, ownProps) => 
{
  return { 
            modalForm: state.modalForms[ownProps.modalFormIndex]
         }
}

export default connect(mapStateToProps)(ModalForm)
