
// To flag commission paid above threshold
const commission_variation_threshold = 2;

module.exports.updateComputedProperties = function (commissionRun)
{
    commissionRun.grossCommissionCharges = (Number(commissionRun.totalSales) * Number(commissionRun.commissionRate)) / 100;

    commissionRun.grossCommissionVAT = commissionRun.grossCommissionCharges * 0.2; 

    // net payable storefront commission
    commissionRun.netCommissionCharges =
        Number(commissionRun.grossCommissionCharges) + Number(commissionRun.additionalCharges) - Number(commissionRun.rebate);

    if (Number(commissionRun.totalSales) != 0)
    {
        // net payable commission rate 
        commissionRun.netCommissionRate =
            Math.round((Number(commissionRun.netCommissionCharges) / Number(commissionRun.totalSales) * 100) * 100) / 100;  // NaN when totalSales = 0.0

        if ((Number(commissionRun.netCommissionRate) - Number(commissionRun.commissionRate)) > commission_variation_threshold)
        {
            commissionRun.flagHighCommissionRate = true;
        }
        else
        {
            commissionRun.flagHighCommissionRate = false;
        }
    }
    else
    {
        commissionRun.netCommissionRate = 0;
        commissionRun.flagHighCommissionRate = false;
    }

}