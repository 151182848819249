import React from "react";
import GridComponent from "../../../components/grid/gridComponent";
import GridViewOptions from "../../../components/gridView/gridViewOptions";
import dateUtil from "../../../utils/dateUtil/dateUtil";
import moment from "moment";
import auditLogConstants from "../../../utils/constants/auditConstants";
import { MdOpenInNew } from "react-icons/md";
import dataTypeConstants from "../../../utils/constants/dataTypeConstants";

const ROW_HEIGHT = 50;

const AuditLogTable = ({ data, onClickExpand, onRowDoubleClicked, showOptionsCol }) => {
    function constructGridColumnHeaders() {
        var headers = [];

        var header = {};
        header.field = "timestamp";
        header.headerName = "Date Time";
        header.type = dataTypeConstants.dateTime;
        header.width = 140;
        header.cellRenderer = CustomDateTimeComp;
        headers.push(header);

        header = {};
        header.field = "user";
        header.headerName = "User";
        header.width = 250;
        header.cellRenderer = CustomUserComp;
        headers.push(header);

        header = {};
        header.field = "auditStatus";
        header.headerName = "Status";
        header.width = 140;
        header.cellRenderer = CustomStatusComp;
        headers.push(header);

        header = {};
        header.field = "auditAction";
        header.headerName = "Action";
        header.width = 230;
        header.cellRenderer = CustomActionComp;
        headers.push(header);

        header = {};
        header.field = "objectId";
        header.headerName = "Id";
        header.width = 330;
        header.hide = true;
        headers.push(header);

        header = {};
        header.field = "objectIdentifier";
        header.headerName = "Identifier";
        header.width = 250;
        headers.push(header);

        header = {};
        header.field = "auditMessage";
        header.headerName = "Message";
        header.cellRenderer = CustomMessageComp;
        header.flex = 1;
        header.minWidth = 400;
        headers.push(header);

        if (showOptionsCol)
        {
            header = {};
            header.field = "options";
            header.headerName = "";
            header.width = 80;
            header.cellRendererParams = {
                onClick: onClickExpand,
            };
            header.cellRenderer = CustomOptionAreaComp;
            header.pinned = "right";
            header.cellStyle = (params) => {
                return { display: "flex", justifyContent: "end" };
            };
            headers.push(header);
        }

        return headers;
    }

    const gridViewOptions = new GridViewOptions();

    gridViewOptions.getColumnDefinitions = constructGridColumnHeaders;

    gridViewOptions.getPrimaryKeyValue = (row) => row.partitionKey;

    gridViewOptions.closeEnabled = false;

    gridViewOptions.rowHeight = ROW_HEIGHT;

    return (
        <div>
            <GridComponent
                headers={gridViewOptions.getColumnDefinitions()}
                rows={data}
                gridOptions={gridViewOptions}
                onRowDoubleClicked={onRowDoubleClicked}
            />
        </div>
    );
};

export default AuditLogTable;

const CustomDateTimeComp = (props) => {
    const timestamp = props.data.timestamp;
    const convertedLocalDateTime = dateUtil.convertToLocalStandard(dateUtil.convertFromISODateTimeFormat(timestamp));

    return (
        <div className="d-flex flex-column gap-1">
            <div
                style={{ fontSize: "0.85rem", lineHeight: 1, color: "#000000a0", fontWeight: 500, marginBottom: "2px" }}
            >
                {moment(convertedLocalDateTime).format("DD MMM, YYYY")}
            </div>
            <div style={{ fontSize: "0.85rem", lineHeight: 1 }}>
                {moment(convertedLocalDateTime).format("HH:mm:ss")}
            </div>
        </div>
    );
};

const CustomUserComp = (props) => {
    const user = props.data.user;
    return <>{user}</>;
};

const CustomStatusComp = (props) => {
    const auditStatus = props.data.auditStatus;

    let color = "#000000";
    if (auditStatus === auditLogConstants.status.Success) color = "#1FB13F";
    if (auditStatus === auditLogConstants.status.Warning) color = "#F3884B";
    if (auditStatus === auditLogConstants.status.Failed) color = "#E22929";

    return (
        <div>
            <div
                className="rounded-3 d-flex flex-row align-items-center gap-2 px-2 py-1"
                style={{
                    backgroundColor: `${color}15`,
                    border: `0.25px solid ${color}`,
                    lineHeight: "1rem",
                    fontSize: "0.80rem",
                    color: color,
                    fontWeight: 500,
                }}
            >
                <span className="fs-5">•</span>
                <div>{auditStatus.toUpperCase()}</div>
            </div>
        </div>
    );
};

const CustomActionComp = (props) => {
    const auditAction = props.data.auditAction ?? "";
    return (
        <div>
            <div
                className="rounded-3 d-flex flex-row align-items-center gap-2 px-2 py-1"
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    lineHeight: "1rem",
                    fontSize: "0.80rem",
                    color: "#000000a0",
                    fontWeight: 500,
                }}
            >
                <div>{auditAction.replaceAll(".", " ").toUpperCase()}</div>
            </div>
        </div>
    );
};

const CustomMessageComp = (props) => {
    const auditMessage = props.data.auditMessage;
    return (
        <div
            style={{
                whiteSpace: "normal",
                lineHeight: 1.4,
                maxHeight: `${ROW_HEIGHT}px`,
                overflow: "auto",
                padding: "4px",
            }}
        >
            {auditMessage}
        </div>
    );
};

const CustomOptionAreaComp = (props) => {
    return (
        <div className="btn w-100" onClick={() => props.onClick(props)}>
            <MdOpenInNew size={20} />
        </div>
    );
};
