
const arraySort = require('array-sort');
const constants = require('../domain/constants');
const rmsApiProxy = require('./rmsApiProxy');
const stringUtil = require('../string/stringUtil');
const validator = require('../validator/validator');
const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../state/stateSelectors/orgSelectors');


export async function loadFranchisorOperatingConfiguration()
{
    const operatingConfiguration = await rmsApiProxy.get(`${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/operatingConfiguration`);
    return operatingConfiguration;
}

export async function loadAllStorefrontsForCurrentFranchisor()
{
    var promises = [];
    
    var properties = orgSelectors.selectProperties();
    properties = arraySort(properties, stringUtil.localeCompareProp("name"));

    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();

    properties.forEach(property => 
    {
        promises.push(rmsApiProxy.get(`${rmsApiProxy.getOrgContextUrl(orgContext.franchisorId, property.franchiseeId, property.id)}/storefronts`));
    })

    var storefronts = await Promise.all(promises);
    storefronts = storefronts.flat();
    
    return storefronts;
}

export async function loadAllStorefrontOrderIngestsForCurrentFranchisor()
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/storefrontOrderIngests`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadPropertyStorefronts()
{
    const storefronts = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts`);
    return storefronts;
}

export async function loadPropertyStorefrontOperatingConfigurations()
{
    const storefrontOperatingConfigurations = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/configurations/operatingConfigurations`);
    return storefrontOperatingConfigurations;
}

export async function loadDiscountOffers()
{
    const data = await rmsApiProxy.get(`${rmsApiProxy.getFranchisorOrgContextUrl()}/promotions/discountOffers`);
    return arraySort(data, stringUtil.localeCompareProp("code"));
}

export async function loadAllPaymentTypes()
{
    const data = await rmsApiProxy.get(`${rmsApiProxy.getFranchisorOrgContextUrl()}/promotions/paymentTypes`);
    return data;
}

export async function loadCharges()
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/charge/all`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadSuppliers()
{
    const { franchisorId, franchiseeId } = currentOrgNodeSelectors.selectCurrentOrgContext();

    const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId);
    const resourceUrl = `${baseUrl}/inventory/suppliers`;

    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadCentralKitchenEffectiveStockList()
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/centralKitchens`;

    const centralKitchens = await rmsApiProxy.get(resourceUrl);

    for (const centralKitchen of centralKitchens) 
    {
        const { franchisorId, franchiseeId, propertyId } = centralKitchen;
        const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId, propertyId);
        const resourceUrl = `${baseUrl}/centralKitchens/${centralKitchen.id}/menus/primary`;

        const data = await rmsApiProxy.get(resourceUrl);
        centralKitchen.effectiveSupplierStockItems = arraySort(data, stringUtil.localeCompareProp("pluCode"));
    }

    return arraySort(centralKitchens, stringUtil.localeCompareProp("name"));
}

export async function loadCentralKitchens()
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl = `${baseUrl}/centralKitchens`;

    return await rmsApiProxy.get(resourceUrl);
}

export async function loadCentralKitchenSales(centralKitchenId, fromDate, toDate)
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl =
        `${baseUrl}/centralKitchens/${centralKitchenId}/sales` +
        `${validator.isDateRange(fromDate, toDate) ? `fromDate=${fromDate}&toDate=${toDate}` : ''}`;

    return await rmsApiProxy.get(resourceUrl);
}


export async function loadCentralKitchenMenuItemsByCentralKitchenId(centralKitchenId)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/centralKitchens/${centralKitchenId}/menus/primary`;

    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("pluCode"));
}

export async function loadAccountCategoriesAndSubCategories()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
    

    const data =[];
    let result = await rmsApiProxy.get(`core/organizations/${orgContext.franchisorId}/accounts/chartOfAccounts`);
    data.push(...result);

    if(!stringUtil.isStringNullOrEmpty(orgContext.franchiseeId))
    {
        result = await rmsApiProxy.get(`core/organizations/${orgContext.franchisorId}/${orgContext.franchiseeId}/accounts/chartOfAccounts`);
        data.push(...result);
    }

    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadAccountCategoriesAndSubCategoriesForFranchisor()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
    

    const data =[];
    let result = await rmsApiProxy.get(`core/organizations/${orgContext.franchisorId}/accounts/chartOfAccounts`);
    data.push(...result);

    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadAllPropertiesForCurrentFranchisor()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
    

    const data =[];
    let result = await rmsApiProxy.get(`core/organizations/${orgContext.franchisorId}/properties`);
    data.push(...result);

    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadTransactionAccounts()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/transactionAccounts`;

    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadStockItems(queryParams)
{
    // example: queryParams = 'unitTypesOnly=true'
    
    const { franchisorId, franchiseeId } = currentOrgNodeSelectors.selectCurrentOrgContext();

    const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId);
    const resourceUrl = `${baseUrl}/inventory/stockItems${queryParams ? `?${queryParams}` : ''}`;
    
    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadAppliances()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/foodsafety/appliances`;
    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadTaskTypes()
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/foodsafety/tasks/taskTypes`;
    const data = await await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadPrintingTemplates(printingTemplateType)
{
    var resourceUrl;

    if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.receipt))
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/templates/printing/receipt`;
    
    else if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.kitchenStation))
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/templates/printing/kitchenStation`;
    else if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.voucher))
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/templates/printing/voucher`;

    else if (stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.dailySummaryReport))
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/templates/printing/${constants.printingTemplateTypes.dailySummaryReport}`;
    
    else
        resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/templates/printing`;
        
    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadSystemPrintingTemplates(printingTemplateType)
{
    let resourceUrl = `core/system/templates/printing`;

    if(!stringUtil.isStringNullOrEmpty(printingTemplateType))
    {
        if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.receipt))
            resourceUrl = `${resourceUrl}/receipt`;
        
        else if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.kitchenStation))
            resourceUrl = `${resourceUrl}/kitchenStation`;
        
        else if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.floatSession))
            resourceUrl = `${resourceUrl}/floatSession`;

        else if(stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.voucher))
            resourceUrl = `${resourceUrl}/voucher`;

        else if (stringUtil.areStringSame(printingTemplateType, constants.printingTemplateTypes.dailySummaryReport))
            resourceUrl = `${resourceUrl}/${constants.printingTemplateTypes.dailySummaryReport}`;
    }
        
    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadInventoryTrackingProfiles()
{
    const { franchisorId } = currentOrgNodeSelectors.selectCurrentOrgContext();

    const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId);

    const resourceUrl = `${baseUrl}/inventory/tracking/trackingProfiles`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadInventoryCategories()
{
    const { franchisorId } = currentOrgNodeSelectors.selectCurrentOrgContext();

    const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId);
    const resourceUrl = `${baseUrl}/inventory/categories`;

    return await rmsApiProxy.get(resourceUrl);
}

export async function loadInventoryProductHeaders()
{
    const { franchisorId } = currentOrgNodeSelectors.selectCurrentOrgContext();

    const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId);

    const resourceUrl = `${baseUrl}/inventory/stockItems?type=product&headersOnly=true`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadCurrentInventory()
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl = `${baseUrl}/inventory/tracking`;

    return await rmsApiProxy.get(resourceUrl);
}

export async function loadPurchaseOrders(purchaseOrderId)
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl = `${baseUrl}/inventory/purchaseOrders${purchaseOrderId ? `?purchaseOrderId=${purchaseOrderId}` : ''}`;

    return await rmsApiProxy.get(resourceUrl);
}

export async function loadPurchaseOrderReceipts(fromDate, toDate)
{
    // Load received purchaseOrders by current orgContext and date range

    const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgContext();

    const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId, propertyId);
    const resourceUrl = `${baseUrl}/inventory/purchaseOrders/receipts?fromDate=${fromDate}&toDate=${toDate}`;

    return await rmsApiProxy.get(resourceUrl);
}

export function loadStaff()
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl = `${baseUrl}/hr/staff`;
    return rmsApiProxy.get(resourceUrl);
}

export function loadStaffRoles()
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl = `${baseUrl}/hr/staffRoles`;
    return rmsApiProxy.get(resourceUrl);
}

export function loadPropertyFloatConfiguration()
{
    const baseUrl = rmsApiProxy.getPropertyOrgContextUrl();
    const resourceUrl = `${baseUrl}/configurations/floatConfiguration`;
    return rmsApiProxy.get(resourceUrl);
}