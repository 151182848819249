
var stateManager = require('../state/stateManager.js');

var alertAction = require('../state/actions/views/alertDialogue/alertAction.js');
var AlertPayload = require('../state/actions/views/alertDialogue/alertPayload.js');

var modalSpinnerAction = require('../state/actions/views/modalSpinnerDialogue/modalSpinnerAction.js');

var confirmAction = require('../state/actions/views/confirmDialogue/confirmAction.js');
var ConfirmPayload = require('../state/actions/views/confirmDialogue/confirmPayload.js');

var modalFormAction = require('../state/actions/views/modalForm/modalFormAction.js');
var ModalFormPayload = require('../state/actions/views/modalForm/modalFormPayload.js');

var attachmentFormAction = require('../state/actions/views/attachmentForm/attachmentFormAction.js');
var AttachmentFormPayload = require('../state/actions/views/attachmentForm/attachmentFormPayload.js');

var stringUtil = require('../string/stringUtil.js');
var logger = require('../logger/logger.js');


module.exports.showCriticalAlert = function (errorMessage)
{
    this.showAlert("critical", errorMessage + "");
}

module.exports.showErrorAlert = function (errorMessage)
{
    this.showAlert("error", errorMessage + "");
}

module.exports.showSuccessAlert = function (successMessage)
{
    this.showAlert("success", successMessage + "");
}

module.exports.showWarningAlert = function (warningMessage)
{
    this.showAlert("warning", warningMessage + "");
}

module.exports.showInfoAlert = function (infoMessage)
{
    this.showAlert("info", infoMessage + "");
}

module.exports.showAlert = function (type, message)
{
    if (stringUtil.isStringNullOrEmpty(message) || stringUtil.isStringNullOrEmpty(type));
    {
        stateManager.getStore().dispatch(alertAction.createAction(null));
    }

    var alertPayload = new AlertPayload();
    alertPayload.type = type;
    alertPayload.message = message;

    stateManager.getStore().dispatch(alertAction.createAction(alertPayload));
}

module.exports.clearAlert = function ()
{
    this.showAlert(null, null);
}

module.exports.showSystemModalSpinner = function (waitMessage)
{
    if (stringUtil.isStringNullOrEmpty(waitMessage))
        waitMessage = null;

    stateManager.getStore().dispatch(modalSpinnerAction.createAction(waitMessage));
}

module.exports.closeModalSpinner = function ()
{
    this.showSystemModalSpinner(null);
}




module.exports.showConfirmDialogue = function (title, message, onConfirm, onCancel)
{
    var confirmPayload= new ConfirmPayload();
    confirmPayload.title = title;
    confirmPayload.message = message;
    confirmPayload.onConfirm = onConfirm;
    confirmPayload.onCancel = onCancel;

    stateManager.getStore().dispatch(confirmAction.createAction(confirmPayload));
}

module.exports.closeConfirmDialogue = function ()
{
    stateManager.getStore().dispatch(confirmAction.createAction(null));
}

module.exports.openModalForm = function (title, componentCreator, onSave, onValidate, isReadOnly, onDelete, onCancel, options = {confirmBtnTitle: "Save"})
{
    var modalFormPayload = new ModalFormPayload();
    modalFormPayload.title = title;
    modalFormPayload.componentCreator = componentCreator;
    modalFormPayload.onSave = onSave;
    modalFormPayload.onValidate = onValidate;
    modalFormPayload.isReadOnly = isReadOnly;
    modalFormPayload.onDelete = onDelete;
    modalFormPayload.onCancel = onCancel;
    modalFormPayload.options = options;

    stateManager.getStore().dispatch(modalFormAction.createAction(modalFormPayload));
}

module.exports.closeModalForm = function (modalFormIndex)
{
    stateManager.getStore().dispatch(modalFormAction.createCloseAction(modalFormIndex));
}

module.exports.openAttachmentForm = function (title, orgContexts, rootFolder, objectType, objectId, isReadOnly, onClose, files)
{
    var attachmentFormPayload = new AttachmentFormPayload();
    attachmentFormPayload.title = title;
    attachmentFormPayload.orgContexts = orgContexts;
    attachmentFormPayload.rootFolder = rootFolder;
    attachmentFormPayload.objectType = objectType;
    attachmentFormPayload.objectId = objectId;
    attachmentFormPayload.onClose = onClose;
    attachmentFormPayload.isReadOnly = isReadOnly;
    attachmentFormPayload.files = files;

    stateManager.getStore().dispatch(attachmentFormAction.createAction(attachmentFormPayload));
}

module.exports.closeAttachmentForm = function ()
{
    var attachmentFormPayload = new AttachmentFormPayload();
    attachmentFormPayload.title = null;
    attachmentFormPayload.objectType = null;
    attachmentFormPayload.objectId = null;
    attachmentFormPayload.onClose = null;
    attachmentFormPayload.isReadOnly = null;
    attachmentFormPayload.files = null;

    stateManager.getStore().dispatch(attachmentFormAction.createAction(attachmentFormPayload));
}

module.exports.showImageUploadWidget = function(cloudName, imageFolder, uploadImageCallBack, maxImageSize)
{
    if (!maxImageSize)
        maxImageSize = 2000000;

    window.cloudinary.openUploadWidget({
        cloudName: cloudName, 
        uploadPreset: 'rms-jinoby-preset',
        multiple:false,
        cropping:true,
        croppingCoordinatesMode: 'custom',
        folder:imageFolder,
        maxFileSize: maxImageSize,
        singleUploadAutoClose: false,
        clientAllowedFormats:["png","gif", "jpeg", "jpg", "bmp"]
    }, 
    (error, result) => 
    { 
        if (!error && result && result.event === "success") 
        {
            if(uploadImageCallBack)
            {
                uploadImageCallBack(result.info.public_id);
            }
        }
        if(error)
        {
            logger.error("Error occurred while uploading image", null, error);
        }
    });
}