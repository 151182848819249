
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardHeader, Button, CardBody} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import InputComponent from '../../../../../components/form/inputComponent'
import SignagePlayerToolBarComponent from './SignagePlayerToolBarComponent.js'

import ImageSlideTileComponent from './slides/image/ImageSlideTileComponent.js'
import VideoSlideTileComponent from './slides/video/VideoSlideTileComponent.js'
import WeatherSlideTileComponent from './slides/weather/WeatherSlideTileComponent.js'
import ActiveOrdersSlideTileComponent from './slides/activeOrders/ActiveOrdersSlideTileComponent'
import ImageSlideDialogue from './slides/image/ImageSlideDialogue.js'
import VideoSlideDialogue from './slides/video/VideoSlideDialogue.js'
import WeatherSlideDialogue from './slides/weather/WeatherSlideDialogue.js'
import ActiveOrdersSlideDialogue from './slides/activeOrders/ActiveOrdersSlideDialogue.js'

var FormManager = require('../../../../../utils/view/formManager.js');
var constants = require('../../../../../utils/domain/constants.js');
var guidUtil = require('../../../../../utils/guid/guidUtil.js');
var viewUtil = require('../../../../../utils/view/viewUtil.js');
var stringUtil = require('../../../../../utils/string/stringUtil.js');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
var validator = require('../../../../../utils/validator/validator.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

class SignagePlayer extends Component
{
    constructor(props)
    {
        super(props);
        this.renderSlidesHeader = this.renderSlidesHeader.bind(this);
        this.renderSlidesContainer = this.renderSlidesContainer.bind(this);
        this.renderGeneralTab = this.renderGeneralTab.bind(this);
        this.renderSlidesTab = this.renderSlidesTab.bind(this);
        this.renderSlides = this.renderSlides.bind(this);
        this.createNewSlideType = this.createNewSlideType.bind(this);
        this.renderSlideDialogue = this.renderSlideDialogue.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.validatePlayer = this.validatePlayer.bind(this);
        this.validateSlide = this.validateSlide.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.player;
        this.state.formManager.view = this;
        this.state.displaySlideDialogue = false;
        this.state.slideForDialogue = null;

        this.state.mode = "slides";
    }

    render()
    {
        if (this.state.mode = "slides")
        {
            return (<Card style={{userSelect:'none'}} >
                        <CardHeader>
                            {this.renderSlidesHeader()} 
                        </CardHeader>
                    
                    <CardBody>
                        <div style={{marginTop:'20px'}}>
                            {this.renderSlidesContainer()}
                        </div>
                        {this.renderSlideDialogue()}
                    </CardBody>
                </Card>);
        }
    }

    renderSlidesHeader()
    {
        return(
            <table width="100%">
                <tr width="100%">
                    
                    <td style={{whiteSpace: "nowrap"}}>
                        <div style={{fontSize:'14px',color: '#606060', fontWeight:'bold'}}>Signage Designer</div>
                    </td>

                    <td  style={{whiteSpace: "nowrap", width:'99%'}} align = "right">
                        <Button disabled={this.props.isReadOnly} color="success" className="btn-success" style={{ marginRight: '3px' }} onClick={this.onSaveClicked}>
                            <i className="fa fa-save"></i>&nbsp;Save
                        </Button>

                        <Button color="secondary" className="btn-secondary" onClick={this.props.onCancel}>
                            Cancel
                        </Button>

                       
                    </td>
                </tr>
            </table>
          );
    }

    renderSlidesContainer()
    {

        return (
                <Tabs>
                    <TabList>
                        <Tab>General</Tab>
                        <Tab>Slides</Tab>
                    </TabList>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderGeneralTab()}
                    </TabPanel>
                    <TabPanel style={{ marginTop: '30px' }}>
                        {this.renderSlidesTab()}
                    </TabPanel>
                </Tabs>);
    }

    renderGeneralTab()
    {
        return (<table width="100%">
                    <tr>
                        <td>
                            <InputComponent inputType="text" caption="Player Name" hintText="Required" style={{ marginRight: '40px' }} fieldName="name" placeholder="Enter signage plater name" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" style={{ marginRight: '40px' }} multiLine={true} caption="Notes" rows={4}
                                fieldName="notes" placeholder="Enter any notes for signage player." formManager={this.state.formManager} />
                        </td>
                    </tr>

                </table>);
    }

    renderSlidesTab()
    {
        return <DragDropContext key="signage.slide.drag.context" onDragEnd={this.onDragEnd}>
                    <table style={{width:'100%', minHeight:'700px'}}>
                        <tr>
                            <td style={{whiteSpace: "nowrap", width:'99%', verticalAlign:'top', borderRight:'1px solid #585858', marginRight:'5px'}}>

                                <div style={{marginRight:'5px'}}>
                                    {this.renderSlides()}
                                </div>
                            </td>
                            <td  style={{whiteSpace: "nowrap", verticalAlign:'top'}}>
                                <div style={{marginLeft:'5px'}}>
                                    <SignagePlayerToolBarComponent onClick={(type)=>
                                                                                    {
                                                                                        this.props.player.slides.push(this.createNewSlideType(type));
                                                                                        this.setState({});
                                                                                    }}/>
                                </div>
                            </td>
                        </tr>
                    </table>
            </DragDropContext>
    }

    renderSlides()
    {
        if (this.props.player.slides.length <= 0)
        {
            return (
                <div className="text-muted" style={{textAlign:'center'}}>
                    No slides found for the player
                </div>
               );
        }

        var slideComponents = [];
        var index = 0;
        this.props.player.slides.forEach (slide =>
        {
            let currentSlide = slide;
            if (slide.type == constants.signageSlideTypes.Image)
            {
                slideComponents.push(<Draggable key={`signage.slide.key.${index}`} draggableId={`signage.slide.id.${index}`} index={index}>
                                                {(provided, snapshot) => ( <div ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                                                                                <ImageSlideTileComponent    slide={currentSlide} 
                                                                                                            onDelete={()=>this.onSlideDelete(currentSlide)}
                                                                                                            onEdit={()=>this.onSlideEdit(currentSlide)}>
                                                                                </ImageSlideTileComponent>
                                                                            </div>
                                                                        )}
                                            </Draggable>)
            }
            if (slide.type == constants.signageSlideTypes.Video)
            {
                slideComponents.push(<Draggable key={`signage.slide.key.${index}`} draggableId={`signage.slide.id.${index}`} index={index}>
                                                {(provided, snapshot) => ( <div ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                                                                                <VideoSlideTileComponent    slide={currentSlide} 
                                                                                                            onDelete={()=>this.onSlideDelete(currentSlide)}
                                                                                                            onEdit={()=>this.onSlideEdit(currentSlide)}>
                                                                                </VideoSlideTileComponent>
                                                                            </div>
                                                                        )}
                                            </Draggable>)
            }
            if (slide.type == constants.signageSlideTypes.Weather)
            {
                slideComponents.push(<Draggable key={`signage.slide.key.${index}`} draggableId={`signage.slide.id.${index}`} index={index}>
                                                {(provided, snapshot) => ( <div ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                                                                                <WeatherSlideTileComponent slide={slide} 
                                                                                                            onDelete={()=>this.onSlideDelete(currentSlide)}
                                                                                                            onEdit={()=>this.onSlideEdit(currentSlide)}>
                                                                                </WeatherSlideTileComponent>
                                                                            </div>
                                                                            
                                                                        )}
                                            </Draggable>)
            }

            if (slide.type == constants.signageSlideTypes.ActiveOrders)
            {
                slideComponents.push(<Draggable key={`signage.slide.key.${index}`} draggableId={`signage.slide.id.${index}`} index={index}>
                                                {(provided, snapshot) => ( <div ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                                                                                <ActiveOrdersSlideTileComponent slide={slide} 
                                                                                                            onDelete={()=>this.onSlideDelete(currentSlide)}
                                                                                                            onEdit={()=>this.onSlideEdit(currentSlide)}>
                                                                                </ActiveOrdersSlideTileComponent>
                                                                            </div>
                                                                            
                                                                        )}
                                            </Draggable>)
            }
            index = index + 1;
        });

        return (<div style={{maxHeight:"700px", overflowY:'auto'}}>
                    <Droppable droppableId="signage.slide.droppable">
                        {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} style={{minHeight:'200px'}}>
                                                        {slideComponents}
                                                        {provided.placeholder}
                                                    </div>
                                                 )}
                    </Droppable>
                </div>);
    }

    onSlideDelete(slide)
    {
        viewUtil.showConfirmDialogue("Delete", `You are about to delete slide '${slide.name}'. Are you sure?`, ()=>
        {
            this.props.player.slides = this.props.player.slides.filter(s => s.id != slide.id);
            this.setState({});
        });
    }

    onSlideEdit(slide)
    {
        this.state.displaySlideDialogue = true;
        this.state.slideForDialogue = slide;
        this.setState({});
    }

    renderSlideDialogue()
    {
        if (!this.state.displaySlideDialogue)
            return null;

        if (this.state.slideForDialogue.type == constants.signageSlideTypes.Image)
            return (<ImageSlideDialogue slide={this.state.slideForDialogue} player={this.props.player}
                                        onClose={()=>{

                                                        this.state.displaySlideDialogue = false;
                                                        this.state.slideForDialogue = null;
                                                        this.setState({});
                                                    }}>

                    </ImageSlideDialogue>)

        if (this.state.slideForDialogue.type == constants.signageSlideTypes.Video)
            return (<VideoSlideDialogue slide={this.state.slideForDialogue} player={this.props.player}
                                        onClose={()=>{

                                                        this.state.displaySlideDialogue = false;
                                                        this.state.slideForDialogue = null;
                                                        this.setState({});
                                                    }}>

                    </VideoSlideDialogue>)

        if (this.state.slideForDialogue.type == constants.signageSlideTypes.Weather)
            return (<WeatherSlideDialogue slide={this.state.slideForDialogue} player={this.props.player}
                            onClose={()=>{

                                            this.state.displaySlideDialogue = false;
                                            this.state.slideForDialogue = null;
                                            this.setState({});
                                        }}>

                    </WeatherSlideDialogue>)

        if (this.state.slideForDialogue.type == constants.signageSlideTypes.ActiveOrders)
            return (<ActiveOrdersSlideDialogue listOfPlayers={this.props.listOfPlayers} 
                                               slide={this.state.slideForDialogue} player={this.props.player}
                            onClose={()=>{

                                            this.state.displaySlideDialogue = false;
                                            this.state.slideForDialogue = null;
                                            this.setState({});
                                        }}>

                    </ActiveOrdersSlideDialogue>)
    }

    onDragEnd(result)
    {
        var source = result.source;
        var destination = result.destination;

        if (source != null && destination != null && source.droppableId == "signage.slide.droppable" && destination.droppableId == "signage.slide.droppable")
        {

            var slide = this.props.player.slides[source.index];
            this.props.player.slides.splice(source.index, 1);
            this.props.player.slides.splice(destination.index, 0, slide)
            this.setState({});
        }
    }

    createNewSlideType(type)
    {
        var slide = {};
        slide.id = guidUtil.generateGuid();
        slide.name = `Slide : ${this.props.player.slides.length + 1}`
        slide.notes = null;
        slide.type = type;
        slide.visibility = constants.signageSlideVisibility.Always;
        slide.timings = 10;
        slide.overrides = [];
        
        if (type == constants.signageSlideTypes.Image)
        {
            slide.image = null;
        }

        if (type == constants.signageSlideTypes.Video)
        {
            slide.videoURL = null;
            slide.mediaServerVideoID = null;
        }

        if (type == constants.signageSlideTypes.Weather)
        {
            slide.weatherCityCode = null;
            slide.weatherWidgetWidth = null;
            slide.weatherWidgetHeight = null;
        }
    
        return slide;
    }

    onSaveClicked()
    {
        if (!this.validatePlayer())
            return;


        viewUtil.showConfirmDialogue("Publish", "You are about to publish signage player. Are you sure you want to continue?", ()=>
        {
            this.props.player.versionId = guidUtil.generateGuid();

            var blob = {};
            blob.id = this.props.player.id;               // PK
            blob.franchisorId = this.props.player.franchisorId;
            blob.name = this.props.player.name;    
            blob.notes = this.props.player.notes;    
            blob.type = constants.blogTypes.SignagePlayers;
            blob.document = JSON.stringify(this.props.player);

            viewUtil.showSystemModalSpinner("Please wait, publishing");
            rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs`, blob)
                    .then((updatedSkin) => 
                            {
                                viewUtil.closeModalSpinner();
                                viewUtil.showSuccessAlert(`'${blob.name}' published successfully`);
                                this.props.onSaved(this.props.player);
                            }, error =>
                            {
                                viewUtil.closeModalSpinner();
                                viewUtil.showErrorAlert(`Failed to publish: ${error}`);
                                this.props.onCancel();
                            });
        })

        
       

    }

    validatePlayer()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.player.name))
        {
            viewUtil.showErrorAlert("Player name cannot be left empty");
            return false;
        }

        if (this.props.player.slides.length <= 0)
        {
            viewUtil.showErrorAlert("Player does not contain any slide");
            return false;
        }

        if (this.props.player.slides.filter(s => s.type == constants.signageSlideTypes.ActiveOrders).length > 1)
        {
            viewUtil.showErrorAlert("There can only be one Slide type 'Active Orders'");
            return false; 
        }


        if (this.props.player.slides.some(s => s.type == constants.signageSlideTypes.ActiveOrders) &&
            this.props.player.slides.some(s => s.type != constants.signageSlideTypes.ActiveOrders))
        {
            viewUtil.showErrorAlert("Slide type 'Active Orders' cannot be mixed with slide types");
            return false; 
        }

        var slideValidated = true;

        this.props.player.slides.forEach (slide =>
        {
            if (!this.validateSlide(slide))
                slideValidated = false;
        });

        return slideValidated;
    }

    validateSlide(slide)
    {
        if (stringUtil.isStringNullOrEmpty(slide.name))
        {
            viewUtil.showErrorAlert("Slide name cannot be left empty");
            return false;
        }

        if (slide.timings == null)
        {
            viewUtil.showErrorAlert(`"Slide timings is missing for slide '${slide.name}'`);
            return false;
        }

        if (stringUtil.areStringSame(slide.type, constants.signageSlideTypes.Image))
        {
            if (stringUtil.isStringNullOrEmpty(slide.image))
            {
                viewUtil.showErrorAlert(`"Slide image is missing for slide '${slide.name}'`);
                return false;
            }
        }

        if (stringUtil.areStringSame(slide.type, constants.signageSlideTypes.Video))
        {
            if (stringUtil.isStringNullOrEmpty(slide.videoURL) && stringUtil.isStringNullOrEmpty(slide.mediaServerVideoID))
            {
                viewUtil.showErrorAlert(`For video slide '${slide.name}', either enter Video URL or media server video ID.`);
                return false;
            }
        }

        if (stringUtil.areStringSame(slide.type, constants.signageSlideTypes.Weather))
        {
            if (stringUtil.isStringNullOrEmpty(slide.weatherCityCode))
            {
                viewUtil.showErrorAlert(`"City code is missing for slide '${slide.name}'`);
                return false;
            }
           
            if (slide.weatherWidgetWidth != null && !stringUtil.isStringNullOrEmpty(slide.weatherWidgetWidth) && !validator.isInteger(slide.weatherWidgetWidth))
            {
                viewUtil.showErrorAlert(`"Slide widget width is not valid for slide '${slide.name}'`);
                return false;
            }

            if (slide.weatherWidgetHeight != null && !stringUtil.isStringNullOrEmpty(slide.weatherWidgetHeight) && !validator.isInteger(slide.weatherWidgetHeight))
            {
                viewUtil.showErrorAlert(`"Slide widget height is not valid for slide '${slide.name}'`);
                return false;
            }
        }

        if (stringUtil.areStringSame(slide.type, constants.signageSlideTypes.ActiveOrders))
        {
            if (stringUtil.isStringNullOrEmpty(slide.title))
            {
                viewUtil.showErrorAlert(`"slide title is missing for slide '${slide.name}'`);
                return false;
            }

            if (stringUtil.isStringNullOrEmpty(slide.headerForegroundColor) && !stringUtil.isStringNullOrEmpty(slide.headerBackgroundColor))
            {
                viewUtil.showErrorAlert(`"Slide header foreground is missing for slide '${slide.backgroundColor}'`);
                return false;
            }

            if (!stringUtil.isStringNullOrEmpty(slide.headerForegroundColor) && stringUtil.isStringNullOrEmpty(slide.headerBackgroundColor))
            {
                viewUtil.showErrorAlert(`"Slide header background color is missing for slide '${slide.backgroundColor}'`);
                return false;
            }

            if (stringUtil.isStringNullOrEmpty(slide.titleForegroundColor) && !stringUtil.isStringNullOrEmpty(slide.titleBackgroundColor))
            {
                viewUtil.showErrorAlert(`"Slide title foreground is missing for slide '${slide.backgroundColor}'`);
                return false;
            }

            if (!stringUtil.isStringNullOrEmpty(slide.titleForegroundColor) && stringUtil.isStringNullOrEmpty(slide.titleBackgroundColor))
            {
                viewUtil.showErrorAlert(`"Slide title background color is missing for slide '${slide.backgroundColor}'`);
                return false;
            }
           
            
        }

        return true;
    }
}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(SignagePlayer);