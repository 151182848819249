import { createContext, useContext, useReducer } from "react";
import { generateGuid } from "../../../../utils/guid/guidUtil";

const NetworkDiagramContext = createContext();

export const NetworkDiagramActionTypes = {
     OPEN_STATION_POPUP: "OPEN_STATION_POPUP",
     CLOSE_STATION_POPUP: "CLOSE_STATION_POPUP",
     ADD_STATION: "ADD_STATION",
     UPDATE_STATION: "UPDATE_STATION",
     DELETE_STATION: "DELETE_STATION",

     OPEN_DEVICE_POPUP: "OPEN_DEVICE_POPUP",
     CLOSE_DEVICE_POPUP: "CLOSE_DEVICE_POPUP",
     ADD_DEVICE: "ADD_DEVICE",
     UPDATE_DEVICE: "UPDATE_DEVICE",
     DELETE_DEVICE: "DELETE_DEVICE",

     TOGGLE_STATION_EXPANSION: "TOGGLE_STATION_EXPANSION",
     UPDATE_IS_UPDATED: "UPDATE_IS_UPDATED",
};

function reducer(state, action) {
     const { OPEN_STATION_POPUP, ADD_STATION, CLOSE_STATION_POPUP, UPDATE_STATION, DELETE_STATION, OPEN_DEVICE_POPUP, CLOSE_DEVICE_POPUP, ADD_DEVICE, UPDATE_DEVICE, DELETE_DEVICE, TOGGLE_STATION_EXPANSION, UPDATE_IS_UPDATED } = NetworkDiagramActionTypes;
     switch (action.type) {
          case OPEN_STATION_POPUP: {
               return {
                    ...state,
                    popups: {
                         ...state.popups,
                         stationFormPopup: {
                              id: action?.payload?.id || null,
                              visible: true,
                         },
                    },
               };
          }
          case CLOSE_STATION_POPUP: {
               return {
                    ...state,
                    popups: {
                         ...state.popups,
                         stationFormPopup: {
                              id: null,
                              visible: false,
                         },
                    },
               };
          }
          case ADD_STATION: {
               const { type, stationName, configuration } = action.payload;
               const newStation = {
                    id: generateGuid(),
                    stationName,
                    type,
                    configuration,
               };
               return {
                    ...state,
                    stations: [...state.stations, newStation],
                    isUpdated: true
               };
          }
          case UPDATE_STATION: {
               const { id, stationName, configuration } = action.payload;
               return {
                    ...state,
                    stations: state.stations.map((station) => {
                         if (station.id === id) {
                              station.stationName = stationName;
                              station.configuration = configuration;
                         }
                         return station;
                    }),
                    isUpdated: true
               };
          }
          case DELETE_STATION: {
               const { id } = action.payload;
               const devicesIncludeStationId = [];
               return {
                    ...state,
                    devices: state.devices.filter((device) => {
                         if (device.stationId === id) {
                              devicesIncludeStationId.push(device.id);
                              return false;
                         }
                         return true;
                    }),
                    stations: state.stations
                         .filter((station) => station.id !== id)
                         .map((station) => {
                              // REMOVE DEVICE ID FROM STATION
                              if (station.configuration) {
                                   Object.entries(station.configuration).forEach(([key, value]) => {
                                        if (key === "receiptPrinterId" && devicesIncludeStationId.includes(value)) station.configuration[key] = null;
                                        if (key === "labelPrinterId" && devicesIncludeStationId.includes(value)) station.configuration[key] = null;
                                   });
                              }
                              return station;
                         }),
                    isUpdated: true     
               };
          }
          case OPEN_DEVICE_POPUP: {
               return {
                    ...state,
                    popups: {
                         ...state.popups,
                         deviceFormPopup: {
                              id: action?.payload?.id || null,
                              stationId: action?.payload?.stationId || null,
                              type: action?.payload?.type || null,
                              visible: true,
                         },
                    },
               };
          }
          case CLOSE_DEVICE_POPUP: {
               return {
                    ...state,
                    popups: {
                         ...state.popups,
                         deviceFormPopup: {
                              id: null,
                              stationId: null,
                              type: null,
                              visible: false,
                         },
                    },
               };
          }
          case ADD_DEVICE: {
               const { type, deviceName, stationId, configuration } = action.payload;
               const newDevice = {
                    id: generateGuid(),
                    deviceName,
                    type,
                    stationId,
                    configuration,
               };
               return {
                    ...state,
                    devices: [...state.devices, newDevice],
                    isUpdated: true
               };
          }
          case UPDATE_DEVICE: {
               const { id, deviceName, configuration } = action.payload;
               return {
                    ...state,
                    devices: state.devices.map((device) => {
                         if (device.id === id) {
                              device.deviceName = deviceName;
                              device.configuration = configuration;
                         }
                         return device;
                    }),
                    isUpdated: true
               };
          }
          case DELETE_DEVICE: {
               const { id } = action.payload;
               return {
                    ...state,
                    devices: state.devices.filter((device) => device.id !== id && device.stationId !== id),
                    stations: state.stations.map((station) => {
                         // REMOVE DEVICE ID FROM STATION
                         if (station.configuration) {
                              Object.entries(station.configuration).forEach(([key, value]) => {
                                   if (key === "receiptPrinterId" && value === id) station.configuration[key] = null;
                                   if (key === "labelPrinterId" && value === id) station.configuration[key] = null;
                              });
                         }
                         return station;
                    }),
                    isUpdated: true
               };
          }
          case TOGGLE_STATION_EXPANSION: {
               const { id } = action.payload;
               return {
                    ...state,
                    stations:state.stations.map(station => {
                         if(station.id === id){
                              station.isExpanded = !station.isExpanded;
                         }
                         return station;
                    })
               };
          }
          case UPDATE_IS_UPDATED: {
            return {
                ...state,
                isUpdated: action.payload
            };
          }
          default:
               return state;
     }
}

const initialState = {
     stations: [],
     devices: [],
     signagePlayers: [],
     storefronts: [],
     kioskSkins: [],
     kitchenStationProfiles: [],
     printingTemplates: [],
};

const useStore = (data, isReadOnly) => {
     const [state, dispatch] = useReducer(reducer, {
          ...data,
          popups: {
               stationFormPopup: {
                    id: null,
                    visible: false,
               },
               deviceFormPopup: {
                    id: null,
                    stationId: null,
                    type: null,
                    visible: false,
               },
          },
          isUpdated: false,
          isReadOnly,
     });

     return {
          state,
          dispatch,
     };
};

const NetworkDiagramProvider = ({ children, data = initialState, isReadOnly = false }) => {
     return <NetworkDiagramContext.Provider value={useStore(data, isReadOnly)}>{children}</NetworkDiagramContext.Provider>;
};

export default NetworkDiagramProvider;

export const useNetworkDiagramContext = () => {
     return useContext(NetworkDiagramContext);
};
