
import React from 'react';
import { Row } from 'reactstrap';

export default function ErrorMessage({ message })
{
    message = message || 'Oops! Some unspecified error occurred';

    const lines = message.toString().split('\n');

    return (<div>
        <Row className="justify-content-center" style={{ margin: '5px' }}>
            <h5>
                {
                    lines.map((line, index) =>
                        <>
                            <span key={index} className="text-danger">{line}</span><br />
                        </>)
                }
            </h5>
        </Row>
    </div>
    );
}
