
import React, { Component } from 'react';
import { Row, Col, Card, CardTitle, CardText, CardHeader, Button, CardBody, CardFooter, CardImg, Collapse } from 'reactstrap';

class HintCard extends Component
{

    constructor(props)
    {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {};

        //By default component is opening the contents.
        this.state.isOpen = true;
        
        //If default closed is configured
        if(this.props.isDefaultClosed != null)
            this.state.isOpen = !this.props.isDefaultClosed;

        //Later component, will itself decide based on the given context id and based on user's previous action.
        //contextId: sort of indicates the help context so component can save it as a user preference.
   }

    render()
    {

        var watchVideoButton = null;
        var caption ="Show Instructions";

        if (this.props.videoUrl)
        {
            watchVideoButton = <Button style={{marginLeft:'10px'}} outline color="secondary" className="text-muted"><i className="icon-control-play"></i> Watch Video</Button>;
        }

        if (this.state.isOpen)
            caption ="Hide Instructions";

        return (
                    <div>
                        <div align="right">
                            <Button outline color="secondary" className="text-muted" onClick={this.toggle}><i className="icon-info"></i> {caption}</Button>
                            {watchVideoButton}
                        </div>

                        <Collapse isOpen={this.state.isOpen}>
                            <Card style={{marginTop:'10px'}}>
                                <CardBody style={{padding:'0px'}}>
                                    <table width="100%">
                                        <tr>
                                            <td style={{width:'10px', background:'#FF7441'}}>

                                            </td>
                                            <td style={{padding:'10px'}}>
                                                    {this.props.children}
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
               
            );
    }

    toggle()
    {
        this.state.isOpen = !this.state.isOpen;
        this.setState({});
    }

}

export default HintCard;