import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Overview from './components/overview/Overview';
import AllergyAndNutritionProfiles from './components/allergyAndNutritionProfiles/AllergyAndNutritionProfiles';
import PrepAndRecipeProfile from './components/recipeAndPrepProfiles/RecipeAndPrepProfiles';
import TemperatureProfiles from './components/temperatureProfiles/TemperatureProfiles';
import StockItemUnits from './components/stockItemsUnits/StockItemUnits';
import StockItemSuppliers from './stockItemSuppliers/StockItemSuppliers';
import StockItemInventoryProfiles from './components/StockItemInventoryProfiles';

import CdnImageUploader from '../../../../components/cdnImage/cdnImageUploader';

const configurationManager = require('../../../../utils/config/configurationManager');


// A stockItem can now be
// inventory tracked (stockItem.isInventoryTracked=true) or
// wastage tracked (stockItem.isWastageTracked=true)
// irrespective of whether it is raw or recipe stock item

export default class StockItem extends Component
{
    constructor(props)
    {
        super(props);

        // props.data = stockItem !!

        props.data.recipeProfileEnabled = props.data.recipeProfile != null || props.data.prepProfile != null;
        props.data.cookingTemperatureProfileEnabled = props.data.cookingTemperatureProfile != null;
        props.data.storageTemperatureProfileEnabled = props.data.storageTemperatureProfile != null;
        props.data.allergyProfileEnabled = props.data.allergyProfile != null;
        props.data.nutritionProfileEnabled = props.data.nutritionProfile != null;

        // Make sure stockItem.inventoryProfiles is properly initialized

        props.data.inventoryProfiles =
            props.data.isInventoryTracked
                ? props.data.inventoryProfiles || []
                : [];
    }

    onFieldChanged = () =>
    {
        this.setState({});
    }

    render()
    {
        const props = this.props;

        const style = { marginTop: '30px' };

        const imageFolder = this.getImagesFolder();

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab>Overview</Tab>);
        tabContents.push(<TabPanel style={style}><Overview {...props} onFieldChanged={this.onFieldChanged} /></TabPanel>)

        tabHeaders.push(<Tab>Units</Tab>);
        tabContents.push(<TabPanel style={style}><StockItemUnits {...props} /></TabPanel>);

        if(props.data.recipeProfileEnabled)
        {
            tabHeaders.push(<Tab>Prep and Recipe</Tab>);
            tabContents.push(<TabPanel style={style}><PrepAndRecipeProfile {...props} /></TabPanel>);
        }
        else
        {
            tabHeaders.push(<Tab>Suppliers</Tab>);
            tabContents.push(<TabPanel style={style}><StockItemSuppliers {...props} /></TabPanel>);
        }
        
        tabHeaders.push(<Tab>Temperature</Tab>);
        tabContents.push(<TabPanel style={style}><TemperatureProfiles {...props} onProfileChanged={this.onProfileChanged} /></TabPanel>);
        
        tabHeaders.push(<Tab>Allergy and Nutrition</Tab>);
        tabContents.push(<TabPanel style={style}><AllergyAndNutritionProfiles {...props} /></TabPanel>);

        if (props.data.isInventoryTracked)
        {
            tabHeaders.push(<Tab>Tracking</Tab>);
            tabContents.push(<TabPanel style={style}><StockItemInventoryProfiles {...props} /></TabPanel>);
        }

        tabHeaders.push(<Tab>Image</Tab>);
        tabContents.push(
            <TabPanel style={style}>
                <CdnImageUploader isReadOnly={props.isRowReadOnly}
                    cloudName={configurationManager.getConfig().cdnUsersCloudName}
                    imageFolder={imageFolder}
                    imageUrl={props.data.imageUrl}
                    onImageChange={(url) =>
                    {
                        props.data.imageUrl = url;
                        this.setState({});
                    }}>
                    <h4 className="display-5">No Image Found</h4>

                    <p className="lead">A logo is much more than just an image; it is a point of recognition for clients and an important foundation for the branding of your company.</p>
                    <hr className="my-2" />
                    <p>A well-designed logo is an easy way to convey to potential customers that your business is professional, trustworthy, and provides quality goods or services.</p>
                </CdnImageUploader>
            </TabPanel>);

        return (
            <div style={{ position: "relative" }}>
                <Tabs>
                    <TabList>
                        {tabHeaders}
                    </TabList>
                    {tabContents}
                </Tabs>
            </div>
        );
    }

    getImagesFolder = () =>
    {
        const franchisorId = this.props.data.franchisorId;
        const franchiseeId = this.props.data.franchiseeId;



        return franchiseeId == null
            ? `franchisors/${franchisorId}/stockItems`
            : `franchisors/${franchisorId}/franchisees/${franchiseeId}/stockItems`;
    }
}