import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import SelectComponent from '../../../../../components/form/selectComponent';
import CheckComponent from '../../../../../components/form/checkComponent';
import FormManager from '../../../../../utils/view/formManager';
import StockItemSupplierQuestions from './StockItemSupplierQuestions';
import StockItemSupplierUnitTypes from './StockItemSupplierUnitTypes';

const arraySort = require('array-sort');

const stringUtil = require('../../../../../utils/string/stringUtil');


class StockItemSupplier extends Component
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
        this.formManager.onFieldChanged = (() =>
        {
            if (this.props.onFieldChanged)
                this.props.onFieldChanged();
        })
    }

    render()
    {
        const tabHeaders = [];

        tabHeaders.push(<Tab>Units</Tab>)
        tabHeaders.push(<Tab>Questions</Tab>)

        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}>{this.renderTable()}</TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}><StockItemSupplierQuestions {...this.props}></StockItemSupplierQuestions></TabPanel>);

        return (
            <Tabs>
                <TabList>
                    {tabHeaders}
                </TabList>
                {tabContents}
            </Tabs>
        );
    }

    renderTable()
    {
        const suppliersLookupData = arraySort(this.props.lookupData.suppliersLookupData, stringUtil.localeCompareProp("name"));

        return (
            <table className="component-table">

                <tr>
                    <td>
                        <SelectComponent
                            optionValues={suppliersLookupData}
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            caption="Supplier"
                            hintText="Select supplier (required)"
                            fieldName="supplierId"
                            formManager={this.formManager}
                            clearable={false}
                            comboReadOnly={this.props.isRowReadOnly || this.props.isSupplierReadonly} />
                    </td>

                    <td>
                        <CheckComponent
                            caption="Disable this supplier"
                            fieldName="isDisabled"
                            inputReadOnly={this.props.isRowReadOnly}
                            formManager={this.formManager} />
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <StockItemSupplierUnitTypes {...this.props}></StockItemSupplierUnitTypes>
                    </td>
                </tr>

            </table>
        )
    }
}

export default StockItemSupplier;