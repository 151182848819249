import React, { Component } from 'react';

import { getColumnCount, formatAmount } from '../../../utils/viewUtils';
import arraySort from 'array-sort';

export default class SalesByReportTagsSummary extends Component
{
    render()
    {
        const { salesByReportTags } = this.props.data;

        // salesByReportTags contains sales data by reportTags and storefrontType.
        // Since this report only requires sales by reportTags data, we summarize data by reportTags below
        // before feeding it to the report

        const salesByReportTagsSummary = [];

        salesByReportTags.forEach(data => 
        {
            let dataRow = salesByReportTagsSummary.find(dataRow => dataRow.reportTags === data.reportTags);

            if (dataRow == null)
            {
                dataRow = {
                    reportTags: data.reportTags,
                    totalQuantity: 0,
                    totalRetailOrderPrice: 0.0,
                    totalTax: 0.0
                }

                salesByReportTagsSummary.push(dataRow);
            }

            dataRow.totalQuantity += data.totalQuantity;
            dataRow.totalRetailOrderPrice += data.totalRetailOrderPrice;
            dataRow.totalTax += data.totalTax;
        });

        arraySort(salesByReportTagsSummary, "reportTags");

        const cols = getColumnCount(salesByReportTagsSummary.length);
        const rows = Math.ceil(salesByReportTagsSummary.length / cols);

        return (
            <div className='container p-0'>
                {
                    Array.from({ length: rows }, (_, index) => index).map(row => 
                    {
                        return (
                            <div key={row} className='row m-0'>
                                {
                                    Array.from({ length: cols }, (_, index) => index).map(col =>
                                    {
                                        const data = salesByReportTagsSummary[cols * row + col];
                                        return data === undefined
                                            ? null
                                            : <div key={data.venueCode} className='col border-bottom border-end py-3'>
                                                <div className=' d-flex flex-column justify-content-center align-items-center'>
                                                    <div className='fw-bold text-muted'>{data.reportTags}</div>
                                                    <div>{formatAmount(data.totalRetailOrderPrice)}</div>
                                                </div>
                                            </div>
                                    })
                                }
                            </div>)
                    })
                }
            </div>
        );
    }
}