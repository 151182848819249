module.exports.defaultColDef = {
    editable: true,
    sortable: true,
    resizable: true,
    suppressSizeToFit: true,
}

module.exports.getDefaultGridOptions = () =>
{
    return {

        animateRows: true,

        fontSize: 50,
        domLayout: 'normal',
        rowSelection: 'single',
        defaultColDef: this.defaultColDef,

        popupParent: document.body,
    };
}