
import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import { Card, CardHeader, CardBody } from 'reactstrap';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

import PropertyPayrollConfigurationComponent from './components/PropertyPayrollConfigurationComponent';
const propertyPayrollConfigurationHelper = require('./utils/propertyPayrollConfigurationHelper');

const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class PropertyFloatConfiguration extends Component
{
    constructor(props) 
    {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            payrollConfiguration: null
        }
    }

    async componentDidMount()
    {
        viewUtil.showSystemModalSpinner("Loading payroll configuration, please wait ...");

        try
        {
            let payrollConfiguration = await propertyPayrollConfigurationHelper.load()

            if (payrollConfiguration == null) 
            {
                payrollConfiguration = propertyPayrollConfigurationHelper.createNew();
            }

            this.setState({ payrollConfiguration: payrollConfiguration })
        }
        catch (error)
        {
            this.setState({ error: error });
        }
        finally
        {
            this.setState({ isLoading: false });
            viewUtil.closeModalSpinner();
        }
    }

    render() 
    {
        let component = null;

        if (this.state.isLoading)
        {
            component = null
        }
        else if (this.state.error)
        {
            component = <ErrorMessage message={this.state.error} />;
        }
        else
        {
            component = <PropertyPayrollConfigurationComponent data={this.state.payrollConfiguration} />
        }

        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader
                        title="Property Payroll Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true}
                    />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>
            </Card>
        );
    }

    onSaveClicked = async () =>
    {
        const payrollConfiguration = this.state.payrollConfiguration;
        const validationError = propertyPayrollConfigurationHelper.validate(payrollConfiguration)
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showErrorAlert(validationError);
            return;
        }

        try
        {
            viewUtil.showSystemModalSpinner("Saving Property Payroll Configuration ...");

            await propertyPayrollConfigurationHelper.save(payrollConfiguration);
            viewUtil.showSuccessAlert(`Property Payroll Configuration saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.configuration,
                                                      'Property Payroll Configuration has been altered', 
                                                      payrollConfiguration);

            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }
}

export default PropertyFloatConfiguration;

