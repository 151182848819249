import React, { Component } from 'react';

import arraySort from 'array-sort';

import SelectComponent from '../../../../../../components/form/selectComponent';
import FormManager from '../../../../../../utils/view/formManager';

const stringUtil = require('../../../../../../utils/string/stringUtil');
const unitUtil = require('../../../../../../utils/domain/unitUtil');
const domainConstants = require('../../../../../../utils/domain/constants');

export default class StockItemOperationalUnitType extends Component
{
    constructor(props) 
    {
        //props = {isNewRow:bool, data:{operationalUnitType} stockItem:{stockItem} isRowReadOnly:bool}

        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;
        this.formManager.onFieldChanged = (() =>
        {
            if (this.props.onFieldChanged)
                this.props.onFieldChanged();
        })
    }

    get operationalUnitType()
    {
        return this.formManager.viewModel;
    }

    get operationTypes()
    {
        const operationTypes = Object.values(domainConstants.stockItemOperationTypes).map(value => ({ operationTypeCode: value }));
        return arraySort(operationTypes, stringUtil.localeCompareProp("operationTypeCode"));
    }

    get stockItem()
    {
        return this.props.stockItem;
    }

    get unitTypes()
    {
        const unitTypes = unitUtil
            .getPossibleUnitsForStockItem(this.stockItem)
            .filter(unit => !unit.isSupplementalUnit);
                
        return arraySort(unitTypes, stringUtil.localeCompareProp("code"));
    }

    render()
    {
        return (
            <div style={{ minHeight: "200px" }}>
                {this.renderTable()}
            </div>
        );
    }

    renderTable()
    {
        const isNewRow = this.props.isNewRow;
        const isRowReadOnly = this.props.isRowReadOnly;
        const formManager = this.formManager;

        return (

            <table className="component-table">

                <tr>
                    <td>
                        <SelectComponent
                            optionValues={this.operationTypes}
                            optionFieldName="operationTypeCode"
                            optionDisplayFieldName="operationTypeCode"
                            fieldName="operationTypeCode"
                            caption="Operation Type"
                            hintText="Select stock item operation type"
                            clearable={false}
                            comboReadOnly={isRowReadOnly || !isNewRow}
                            searchable={false}
                            formManager={formManager} />
                    </td>
                    <td>
                        <SelectComponent
                            optionValues={this.unitTypes}
                            optionFieldName="id"
                            optionDisplayFieldName="code"
                            fieldName="unitTypeId"
                            caption="Unit Type"
                            hintText="Select operation unit type"
                            clearable={false}
                            comboReadOnly={isRowReadOnly}
                            searchable={false}
                            formManager={formManager} />
                    </td>
                </tr>

            </table>
        )
    }
}
