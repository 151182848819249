
module.exports.cash = "Cash";
module.exports.credit = "Credit";
module.exports.venuePaid = "VenuePaid";
module.exports.both = "Both";
module.exports.NA = "NA";

module.exports.options =
    [
        //
        // list suitable for binding in SelectComponent
        //
        
        { paymentMethod: module.exports.cash },
        { paymentMethod: module.exports.credit },
        { paymentMethod: module.exports.venuePaid },
        { paymentMethod: module.exports.both },
        { paymentMethod: module.exports.NA }
    ];
