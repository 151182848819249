import React, { Component } from 'react';

import { Card, CardHeader, CardBody } from 'reactstrap';
import CloseButton from '../../../../../components/button/closeButton';
import EmptyData from '../../../../../components/empty/emptyData';
import { Input, Button } from 'reactstrap';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const reportStyleUtils = require('../../../../../utils/reports/styleUtils');

const validator = require('../../../../../utils/validator/validator');
const stringUtil = require('../../../../../utils/string/stringUtil');

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const viewUtil = require('../../../../../utils/view/viewUtil');


class Nomenclatures extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onCaptionChange = this.onCaptionChange.bind(this);
        this.getInputComponent = this.getInputComponent.bind(this);
        this.getNomenclatures = this.getNomenclatures.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSave = this.onSave.bind(this);

        this.state = {
            nomenclatures: orgStateSelectors.selectCurrentOrgLookupData().nomenclatures,
            overrides: {}
        };
    }

    async onSave()
    {
        try
        {
            viewUtil.showSystemModalSpinner("Saving Nomenclatures...");
            const orgContexts = [currentOrgNodeSelectors.selectCurrentOrgContext()];

            const nomenclatureOverrides = [];
            this.state.nomenclatures.forEach(nomenclature => 
            {
                if(this.state.overrides[nomenclature.code] != null)
                {
                    if(!stringUtil.isStringNullOrEmpty(this.state.overrides[nomenclature.code]))
                    {
                        nomenclature.caption = this.state.overrides[nomenclature.code];
                    }
                    else
                    {
                        nomenclature.caption = nomenclature.defaultCaption;
                    }
                }

                if(!stringUtil.areStringSame(nomenclature.caption, nomenclature.defaultCaption))
                {
                    nomenclatureOverrides.push({code: nomenclature.code, caption: nomenclature.caption});
                }
            });

            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/nomenclatures`;
            await rmsApiProxy.post(resourceUrl, nomenclatureOverrides);

            this.state.overrides = {};
            this.setState({});
            viewUtil.showSuccessAlert("Nomenclatures updated successfully");

        } catch (error)
        {
            viewUtil.showErrorAlert(`Failed to save Nomenclatures. error: ${error}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    async onSaveClick()
    {
        viewUtil.showConfirmDialogue("Save Nomenclatures", "Are you sure you want to save Nomenclatures", this.onSave);
    }

    render() 
    {
        let contents = null;
        if (this.state.nomenclatures.length == 0)
        {
            contents = <EmptyData title="No Nomenclatures Available" text="No Nomenclatures Available" />
        }
        else 
        {
            contents = <div className='overflow-auto'>
                <table style={reportStyleUtils.tableDefaultStyle} className="pure-table pure-table-bordered">
                    <thead>
                        <tr>
                            <th width="70%" style={reportStyleUtils.thLeftAlignedStyle}>Term</th>
                            <th width="30%" style={reportStyleUtils.thLeftAlignedStyle}>Renamed Caption</th>
                        </tr >
                    </thead>
                    <tbody>
                        {this.getNomenclatures()}
                    </tbody>
                </table></div>;
        }

        return (
            <Card>
                <CardHeader>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold', marginRight: '10px' }}>Nomenclatures</div>
                        </div>

                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <Button disabled={this.props.isReadOnly} color="success" className="btn-success" style={{marginRight:'10px'}} onClick={this.onSaveClick}>
                                <i className="fa fa-save"></i>&nbsp;Save
                            </Button>
                            <CloseButton disabled={false} />
                        </div>
                    </div>
                </CardHeader>

                <CardBody>
                    {contents}
                </CardBody>
            </Card>

        );
    }

    onCaptionChange = (code, value) =>
    {
        this.state.overrides[code] = value;
        this.setState({});
    }

    getInputComponent(nomenclature)
    {
        let caption = "";
        if(this.state.overrides[nomenclature.code] != null)
        {
            caption = this.state.overrides[nomenclature.code];
        }
        else if (nomenclature.caption !== nomenclature.defaultCaption)
        {
            caption = nomenclature.caption;
        }
        
        let backgroundStyle = { }
        if(!stringUtil.isStringNullOrEmpty(caption))
        {
            backgroundStyle = { color: "#141414", backgroundColor: "#fffcfc", borderColor: "#ff9999", borderWidth: "1px"}
        }

        return <Input
            type="text"
            id={nomenclature.code}
            value={caption}
            placeholder={nomenclature.defaultCaption} 
            onChange={(event) => {this.onCaptionChange(nomenclature.code, event.target.value)}}
            style={backgroundStyle} />;
    }

    getNomenclatures()
    {
        const rows = [];

        this.state.nomenclatures.forEach(nomenclature =>
        {
            rows.push(<tr>
                <td style={reportStyleUtils.tdLeftAlignedStyle} >
                    <div className="d-flex flex-column align-items-start">
                        <div className="fw-bold">
                            {nomenclature.defaultCaption}
                        </div>
                        <div className="text-muted text-wrap" style={{fontSize: "10px"}}>
                            {nomenclature.notes}
                        </div>
                    </div>
                </td>
                <td style={reportStyleUtils.tdLeftAlignedStyle} >
                    {this.getInputComponent(nomenclature)}
                </td>
            </tr>);
        });

        return rows;
    }

    validateOverride(override)
    {
        if (!validator.isPresent(override.caption))
            return `Invalid Caption for '${override.code}'`;

        return null;
    }
}

export default Nomenclatures;
