
import React, { Component } from 'react';

import ClockLoader from "react-spinners/ClockLoader";
import { Row } from 'reactstrap';

class BISpinnerComponent extends Component
{
    render()
    {
        return (
            <div style={{ marginTop: '50px', textAlign: 'center' }}>
                <Row className="justify-content-center">
                    <ClockLoader
                        size={80}
                        color={"#808080"}
                        loading={true} />
                </Row>
                <Row className="justify-content-center" style={{ marginTop: '10px' }}>
                    <h4 className="text-muted">{this.props.text}</h4>
                </Row>
            </div>
        );
    }
}

export default BISpinnerComponent;