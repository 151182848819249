
import React, { Component } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ExcelExportButton from '../../../../../../components/button/excelExportButton';
import FloorManagementDialogue from './FloorManagementDialogue.js';
import FloorPlanner from './FloorPlanner.js';

const guidUtil = require('../../../../../../utils/guid/guidUtil.js');

const tableOrderingUtil = require('../utils/tableOrderingUtil');

class LayoutDesigner extends Component
{
    constructor(props)
    {
        super(props);

        this.renderFloorSelection = this.renderFloorSelection.bind(this);
        this.renderFloorManagementDialogue = this.renderFloorManagementDialogue.bind(this);
        this.onFloorManagement = this.onFloorManagement.bind(this);
        this.onFloorSelection = this.onFloorSelection.bind(this);

        if (this.layout == null)
        {
            this.layoutConfiguration.layout = {};

            this.layout.floors = [];

            const floor = {};
            floor.id = guidUtil.generateGuid();
            floor.title = "Default Zone";
            floor.stencils = [];

            this.layout.floors.push(floor);
        }

        this.state = {};
        this.state.selectedFloorId = this.layout.floors[0].id;
        this.state.renderFloorManagement = false;
    }

    get layoutConfiguration() { return this.props.data; }
    get layout() { return this.layoutConfiguration.layout; }

    render()
    {
        var selectedFloor = this.layout.floors.find(f => f.id == this.state.selectedFloorId);

        return (
            <div className="flex-column">

                <span style={{ float: "right", marginTop: "-64px", marginRight: "250px" }}>
                    <ExcelExportButton
                        label="Export QR Links"
                        onExport={() => tableOrderingUtil.excelExportTableOrderingUrls(this.layoutConfiguration, this.props.webAppConfiguration)} />
                </span>

                <FloorPlanner floorPlan={selectedFloor} floorSelection={this.renderFloorSelection()} webAppConfiguration={this.props.webAppConfiguration} />

                {this.renderFloorManagementDialogue()}

            </div>);
    }

    renderFloorSelection()
    {
        var selectedFloor = this.layout.floors.find(f => f.id == this.state.selectedFloorId);

        var dropDownOptions = [];
        this.layout.floors.forEach(floor =>
        {
            if (floor.id == this.state.selectedFloorId)
                return;

            dropDownOptions.push(<DropdownItem onClick={() => { this.onFloorSelection(floor) }}>{floor.title}</DropdownItem>);
        })

        dropDownOptions.push(<DropdownItem onClick={() => { this.onFloorManagement() }}><i className="fa fa-cog"></i>Configure</DropdownItem>);

        return (<ButtonDropdown isOpen={this.state.dropdownOpen} toggle={() => { this.state.dropdownOpen = !this.state.dropdownOpen; this.setState({}) }}>
            <DropdownToggle color="primary" caret>
                {selectedFloor.title}
            </DropdownToggle>
            <DropdownMenu>
                {dropDownOptions}
            </DropdownMenu>
        </ButtonDropdown>);
    }

    renderFloorManagementDialogue()
    {
        if (!this.state.renderFloorManagement)
            return null;

        return (<FloorManagementDialogue selectedFloorId={this.state.selectedFloorId} layout={this.layout}
            onSave={() =>
            {
                var selectedFloor = this.layout.floors.find(f => f.id == this.state.selectedFloorId);

                if (selectedFloor == null)
                    this.state.selectedFloorId = this.layout.floors[0].id;

                this.state.renderFloorManagement = false;
                this.setState({});
            }}>

        </FloorManagementDialogue>)
    }

    onFloorManagement()
    {
        this.state.renderFloorManagement = true;
        this.setState({});
    }

    onFloorSelection(floor)
    {
        this.state.selectedFloorId = floor.id;
        this.setState({});
    }

}

export default LayoutDesigner;