
import React, { Component } from 'react';
import { Button, Row } from 'reactstrap';
import GridComponent from '../grid/gridComponent';

const typeUtil = require('../../utils/type/typeUtil');
const viewUtil = require('../../utils/view/viewUtil');

//GridViewEmbedded is used where Add / Edit behavior is required within a component
//GridView provides for a complete form with persistence etc. while GridViewEmbedded is for local data updates
export default class GridViewEmbedded extends Component 
{

    constructor(props) 
    {
        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onValidate = this.onValidate.bind(this);
        this.onAddEdit = this.onAddEdit.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    render()
    {
        var buttonPanels = null;
        if (!this.props.isRowReadOnly)
        {
            const addDisabled = !this.props.gridViewOptions.getNewRow;
            const showRemove = this.props.gridViewOptions.remove !== undefined;
            const context = this.props.gridViewOptions.context || this.props.gridViewOptions.title;


            buttonPanels = <Row>
                <table width="100%">
                    <tr>
                        <td align="right">
                            <Button disabled={addDisabled} className="btn-primary" style={{ marginRight: '3px' }} onClick={() => this.onAddEdit(true, this.props.gridViewOptions.getNewRow())}>
                                <i className="fa fa-plus"></i>&nbsp;Add {context}
                            </Button>

                            {
                                (showRemove)
                                ? (<Button className="btn-danger" style={{ marginRight: '3px' }} onClick={() => this.onRemove()}>
                                    <i className="fa fa-remove"></i>&nbsp;Remove {context}
                                    </Button>)
                                : null
                            }
                        </td>
                    </tr>
                </table>
            </Row>
        }


        return (<div style={{ marginLeft: '20px', marginRight: '20px' }}>
            {buttonPanels}
            <Row style={{ marginTop: '10px' }}>
                <GridComponent headers={this.props.gridViewOptions.getColumnDefinitions()}
                    rows={this.props.gridViewOptions.getRowData()}
                    //TODO
                    //gridMode="controlled" //Controlled indicates data rows are updated, selection is managed by composer ... gridView
                    shouldReArrangeHeaders={true}
                    onRowDoubleClicked={this.onRowDoubleClicked}
                    onRowSelected={this.onRowSelected}
                    onGridReady={this.onGridReady} />
            </Row>
        </div>)
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onRowDoubleClicked(eventData)
    {
        var selectedRow = this.findSelectedRow();
        if (this.props.gridViewOptions.getComponent == null || selectedRow == null)
            return;

        this.onAddEdit(false, typeUtil.deepCloneObject(selectedRow));
    }

    onAddEdit(isNewRow, row)
    {
        viewUtil.openModalForm(this.props.gridViewOptions.getComponentTitle(), (onFieldChanged) =>
        {
            //TODO: Embed in our component
            return this.props.gridViewOptions.getComponent(isNewRow, row, onFieldChanged);
        },
            () => { this.onSave(isNewRow, row) },
            () => { return this.onValidate(isNewRow, row) },
            this.props.isRowReadOnly)

    }

    onRemove()
    {
        const context = this.props.gridViewOptions.context || this.props.gridViewOptions.title;

        const row = this.findSelectedRow();
        if(row == null)
        {
            viewUtil.showAlert('info', `Select the ${context} to remove`)
            return;
        }
            

        let deleteConfirmationTitle = `Remove ${context}`;
        let deleteConfirmationMessage = `Are you sure you want to remove the ${context}?`
        viewUtil.showConfirmDialogue(deleteConfirmationTitle, deleteConfirmationMessage,
        () => {
            this.props.gridViewOptions.remove(row);
        });
    }

    onValidate(isNewRow, row)
    {
        if (!this.props.gridViewOptions.validate)
        {
            return "No validate method defined to validate.";
        }

        return this.props.gridViewOptions.validate(isNewRow, row);
    }

    onSave(isNewRow, row)
    {
        if (!this.props.gridViewOptions.save)
        {
            return "No save method defined to save.";
        }

        return this.props.gridViewOptions.save(isNewRow, row);
    }

}
