import React, { Component } from 'react';
import ExcelExportButton from '../../../../../../components/button/excelExportButton';
import GridComponent from '../../../../../../components/grid/gridComponent';
import NoDataRows from '../../../../../reports/reportUtils/components/noDataRows/NoDataRows';

const reportExcelExport = require('../../../../../reports/reportUtils/components/reportExcelExport');
const dataTypeConstants = require('../../../../../../utils/constants/dataTypeConstants');
const columnWidthConstants = require('../../../../../../utils/constants/columnWidthConstants');

class ApplianceTemperatureLogsView extends Component
{
    constructor(props)
    {
        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.gridApi = null;
    }

    render()
    {   
        let disabled = false;

        const temperatureLogsData = this.props.reportData.temperatureLogsData;

        if (temperatureLogsData.length == 0)
        {
            disabled = true;
            return <NoDataRows message="No appliance temperature logs data available for the selection" />;
        }

        const dataRows = this.getDataRows(temperatureLogsData);
        const headers = this.getColumnHeaders(temperatureLogsData);

        return (

            // Using width:99% seems to solve the issue of horizontal scroll locating
            // above pinned row and behaving oddly on wider screen

            <div style={{ display: "inline-block", width: "98%" }}>

                <div className="mb-3 d-flex flex-row justify-content-end">
                    <ExcelExportButton disabled={disabled}
                                        onExport={async ()=>
                                                    {
                                                        return reportExcelExport.exportWorkbook("Stock Item Temperature Logs", this.gridApi);
                                                    }} />
                </div>

                <GridComponent
                    rows={dataRows}
                    headers={headers}
                    paginationEnabled={true}
                    paginationSize={25}
                    onGridReady={this.onGridReady}
                    shouldReArrangeHeaders={true} />

            </div>
        );
    }

    getDataRows(temperatureLogsData)
    {
        return temperatureLogsData;
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
    }

    getColumnHeaders(temperatureLogsData)
    {
        var setLength = 0;

        //This gives the total number of column sets (of four columns each set) required for the table 
        temperatureLogsData.forEach(log => 
        {            
            var length = (Object.keys(log).length - 2) / 4;
            if(length > setLength) setLength = length;
        })

        let header = {};
        const headers = [];        

        header.headerName = "Date";
        header.field = "date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.headerName = "Appliance";
        header.field = "appliance.name";        
        header.width = columnWidthConstants.name + 150;
        header.pinned = true;        
        header.cellRenderer = 'temperatureRangeRenderer';
        headers.push(header);

        for(let i = 1; i < setLength; i++)
        {                        
            header = {};
            header.headerName = `Temp: ${i}`;
            header.field = `temp${i}`;
            header.type = dataTypeConstants.optionalFloatNumber;
            header.width = columnWidthConstants.number;
            header.cellRenderer = 'temperatureColorRenderer';
            headers.push(header);
        }

        for(let i = 1; i < setLength; i++)
        {                        
            header = {};
            header.headerName = `Time: ${i}`;
            header.field = `dateTime${i}`;
            header.type = dataTypeConstants.hours;
            header.width = columnWidthConstants.time;
            headers.push(header);
        }

        for(let i = 1; i < setLength; i++)
        {                        
            header = {};
            header.headerName = `Logged By: ${i}`;
            header.field = `loggedBy${i}`;
            header.width = columnWidthConstants.name;
            headers.push(header);
        }

        return headers;
    }
}

export default ApplianceTemperatureLogsView;