
import React, { Component } from 'react';
import formManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

export default class PKFBREndorsementComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.formManager = new formManager();
        this.formManager.viewModel = this.props.saleEndorsementSettings;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="POS Id"
                                fieldName="POSID"
                                placeholder="Enter POS Id"
                                hintText="Enter POS Id registered with SRB (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>

                        <td />
                    </tr>

                    <tr>
                        <td colSpan={2}>
                            <InputComponent
                                inputType="text"
                                caption="Service URL"
                                fieldName="serviceUrl"
                                placeholder="Enter service url"
                                hintText="Enter service url (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                </tbody>
            </ table>
        </div>
    }
}