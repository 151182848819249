
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const configurationManager = require('../../utils/config/configurationManager');
const logger = require('../../utils/logger/logger');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const passportAction = require('../../utils/state/actions/passport/passportAction');
const stateManager = require('../../utils/state/stateManager');
const rmsApiProxy = require('../../utils/api/rmsApiProxy');
const securityManager = require('../../utils/domain/security/securityManager');

const Auth0View = () => 
{
  const { loginWithRedirect, isAuthenticated, isLoading, error, user, getAccessTokenSilently, logout } = useAuth0();
  const [verifying, setVerifying] = useState(false);

  useEffect(() => 
  {
    if (!isAuthenticated && !isLoading && !user && !error) 
    {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, user, error, loginWithRedirect]);

  useEffect(() => {
    const fetchAccessTokenAndLogin = async () => 
    {
      if (isAuthenticated && !verifying) 
      {
        setVerifying(true);

        try 
        {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: { redirect_uri: window.location.origin },
          });

          const requestBody = { accessToken };
          const passport = await rmsApiProxy.post(`core/system/security/authenticateAccessToken`, requestBody);

          onLogin(passport);

        } catch (error) 
        {
          console.error('Error getting access token: ', error);
          loginWithRedirect();
          setVerifying(false);
        }
      }
    };

    fetchAccessTokenAndLogin();
  }, [isAuthenticated, verifying, getAccessTokenSilently, loginWithRedirect]);

  const onLogin = (passport) => 
  {
    if (!securityManager.isValidPassport(passport)) throw new Error('Invalid passport encountered');

    const state = stateManager.getStore().getState();
    state.logoutAction = () => {logout({
      clientId: configurationManager.getConfig().portalAuth0ClientId,
      logoutParams: { returnTo: window.location.origin }
    })};
    state.sideBarSelectedOption = 'modules';
    state.topBarSelectedOption = 'expanded';
    state.mainBarOptions = null;
    state.environmentInitialized = false;
    actionDispatcher.dispatch(passportAction.createAction(passport));

    logger.setLoggedInUser(passport.emailAddress, passport.fullName);
  };

    const imageUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}/loadng.gif`;
    
    return (
      <div style={{ position: 'absolute', left: '50%', top: '40%', width: '400px', height: '400px', marginTop: '-200px', marginLeft: '-200px' }}>
        <div style={{ borderRadius: '50%', width: '220px', height: '220px', border: '1px solid #808080', marginLeft: '90px', marginTop: '90px' }}>
          <img src={imageUrl} style={{ width: '140px', height: '140px', marginLeft: '40px', marginTop: '40px' }} />
        </div>
        <div style={{ textAlign: 'center', color: '#05149E', marginTop: '10px', fontSize: '2em', fontWeight: 'bold' }}>
          Verifying Identity...
        </div>
        <div style={{ textAlign: 'center', color: '#707070', marginTop: '0px', fontSize: '1em' }}>
          Please wait while we verify your identity
        </div>
      </div>
    );
  
};

export default Auth0View;
