import React from 'react';

import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../../../components/form/multiSelectComponent';
import CheckComponent from '../../../../../../components/form/checkComponent';
import FormManager from '../../../../../../utils/view/formManager';
import StockItemSections from '../StockItemSections';

const arraySort = require('array-sort');

const stringUtil = require('../../../../../../utils/string/stringUtil');
const domainConstants = require('../../../../../../utils/domain/constants');


export default function StorageTemperatureProfile(props)
{
    const formManager = new FormManager();

    props.data.storageTemperatureProfile = props.data.storageTemperatureProfile || {};
    props.data.storageTemperatureProfile.hazardTypes = props.data.storageTemperatureProfile.hazardTypes || [];
    props.data.storageTemperatureProfile.minTemperatureLogType = domainConstants.minTemperatureLogType.fixed
    formManager.viewModel = props.data.storageTemperatureProfile;
    formManager.viewModel.storageTemperatureProfileEnabled = props.data.storageTemperatureProfileEnabled;

    const hazardTypes = domainConstants.getHazardTypeOptions();
    const minTemperatureLogTypes = domainConstants.getMinTemperatureLogTypes();
    const [state, setState] = React.useState({});

    formManager.onFieldChanged = (event) =>
    {
        if (event.target.id === "storageTemperatureProfileEnabled")
        {
            props.data.storageTemperatureProfileEnabled = formManager.viewModel.storageTemperatureProfileEnabled;
            setState({});
        }
    }

    const getSelectedHazardTypes = () =>
    {
        const hazardTypeCodes = props.data.storageTemperatureProfile.hazardTypes;
        return hazardTypes.filter(x => hazardTypeCodes.includes(x.value));
    }

    const onHazardTypesSelectionChanged = (selectedOptions) =>
    {
        let hazardTypeCodes = []
        if (selectedOptions.length > 0)
        {
            hazardTypeCodes = selectedOptions.map(option => option.value);
        }

        props.data.storageTemperatureProfile.hazardTypes = hazardTypeCodes;
        setState({});
    }

    return  <StockItemSections title="Storage Temperature Profile">
                <table className="component-table">
                    <tr>
                        <td>
                            <CheckComponent 
                                caption="Is Enabled" 
                                fieldName="storageTemperatureProfileEnabled" 
                                formManager={formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent inputType="number" caption="Minimum Temperature" hintText="Min temperature to maintain for this stock item"
                                fieldName="minTemperature"
                                placeholder="Enter minimum temperature"
                                inputReadOnly={props.isRowReadOnly || !formManager.viewModel.storageTemperatureProfileEnabled}
                                formManager={formManager} />
                        </td>
                        <td>
                            <InputComponent inputType="number" caption="Maximum Temperature" hintText="Max temperature to maintain for this stock item"
                                fieldName="maxTemperature"
                                placeholder="Enter maximum temperature"
                                inputReadOnly={props.isRowReadOnly || !formManager.viewModel.storageTemperatureProfileEnabled}
                                formManager={formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                caption="Minimum Temperature Log Type"
                                fieldName="minTemperatureLogType"
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                optionValues={minTemperatureLogTypes}
                                hintText="Required"
                                clearable={false}
                                comboReadOnly={props.isRowReadOnly || !formManager.viewModel.storageTemperatureProfileEnabled}
                                formManager={formManager} />
                        </td>
                        <td>
                            <InputComponent inputType="number" caption="Minimum Temperature Logs" hintText="Minimum number of temperature logs required for the stock item"
                                fieldName="minTemperatureLogs"
                                placeholder="Enter the minimum temperature logs"
                                inputReadOnly={props.isRowReadOnly || !formManager.viewModel.storageTemperatureProfileEnabled}
                                formManager={formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent inputType="number" caption="Auto Correction Logs" hintText="Minimum number of temperature logs to auto-correct stock item state"
                                fieldName="autoCorrectionLogs"
                                placeholder="Enter the minimum auto-correction logs"
                                inputReadOnly={props.isRowReadOnly || !formManager.viewModel.storageTemperatureProfileEnabled}
                                formManager={formManager} />
                        </td>
                        <td>
                            <MultiSelectComponent
                                caption="Hazard Types"
                                valueFieldName="value"
                                displayFieldName="label"
                                options={arraySort(hazardTypes, stringUtil.localeCompareProp("label"))}
                                selectedOptions={getSelectedHazardTypes()}
                                onSelectionChanged={onHazardTypesSelectionChanged}
                                hintText="Select Hazard Types"
                                comboReadOnly={props.isRowReadOnly || !formManager.viewModel.storageTemperatureProfileEnabled} />
                        </td>
                    </tr>

                </table>
            </StockItemSections>
}