import stringUtil from '../../../../../utils/string/stringUtil';
import validator from '../../../../../utils/validator/validator';

const domainConstants = require('../../../../../utils/domain/constants');

//#region Code copied from api

export function isRawStockItem(stockItem)
{
    // Raw stockItem is also unlikely to have prepProfile but same is not enforced here
    return stockItem.recipeProfile == null;
}

export function isBuildPlanStockItem(stockItem)
{
    return isRecipeStockItem(stockItem) && stockItem.recipeProfile.participateInBuildPlan === true;
}

export function isInventoryTrackedStockItem(stockItem)
{
    // isInventoryTracked flag dictates whether this stockItem appears in inventory tracking UIs
    return stockItem.isInventoryTracked;
}

function isRecipeStockItem(stockItem)
{
    return hasRecipeItems(stockItem);
}

function isInventoryTraceableStockItem(stockItem)
{
    // Only inventoryTraceableStockItems (raw/buildPlan items) can be inventory tracked

    if (isRawStockItem(stockItem)) return true;
    if (isBuildPlanStockItem(stockItem)) return true;
    return false;
}

function hasRecipeItems(stockItem)
{
    return (
        stockItem.recipeProfile != null &&
        stockItem.recipeProfile.recipeItems != null &&
        stockItem.recipeProfile.recipeItems.length > 0);
}

//#endregion

export function enrich(stockItems, lookupData)
{
    if (!Array.isArray(stockItems)) stockItems = [stockItems];

    const categories = lookupData.categoriesLookupData;

    stockItems.forEach(stockItem =>
    {
        const category = categories.find(x => x.id === stockItem.categoryId);
        stockItem.category = category && category.name;
    });
}

export function validateStockItem(stockItem)
{
    const validations = [
        validateOverview,
        validateRecipeProfile,
        validatePrepProfile,
        validateCookingTemperatureProfile,
        validateStorageTemperatureProfile,
        validateNutritionProfile,
        validateAllergyProfile,
    ]

    let error = null;

    for (const validation of validations)
    {
        error = validation(stockItem);

        if (!stringUtil.isStringNullOrEmpty(error))
            break;
    }

    return error;
}

//
// Validation methods
//

function validateOverview(stockItem)
{
    if (!validator.isPresent(stockItem.name))
        return "StockItem name cannot be left empty";

    if (!validator.isPresent(stockItem.code))
        return "StockItem name cannot be left empty";

    if (!validator.isValidEnumeration(stockItem.type, domainConstants.serviceContextTypes))
        return "Invalid StockItem type";

    if (!validator.isPresent(stockItem.categoryId))
        return "Category cannot be left empty";

    if (!validator.isPresent(stockItem.unitTypeCode))
        return "Unit type cannot be empty";

    if (!validator.isPresent(stockItem.coreUnitCode))
        return "Primary Unit cannot be empty";

    if (stockItem.isProduct && stockItem.coreUnitCode != "Pcs")
        return "Stock Item cannot be marked as product when unit is not set to Pcs";

    if (!validator.isOptionalGreaterThanOrEqualToZero(stockItem.markupRate))
        return "Invalid markup rate";

    if (stockItem.isInventoryTracked) 
    {
        if (!isInventoryTraceableStockItem(stockItem))
            return "Only inventory traceable stock items (raw items or build plan items) can be inventory tracked";
    }

    return null;
}

function validateRecipeProfile(stockItem)
{
    if(!stockItem.recipeProfileEnabled)
        return null;

    const recipeProfile = stockItem.recipeProfile;

    if (!validator.isPresent(recipeProfile.unitTypeId) &&
        !validator.isPresent(recipeProfile.baseQuantity) &&
        recipeProfile.recipeItems.length == 0)
    {
        // Handles case when recipeProfile tab is visible but user does not enter any recipe data
        stockItem.recipeProfile = null;
        return null;
    }

    if (!validator.isPresent(recipeProfile.unitTypeId))
        return 'Recipe: Unit cannot be left empty';

    if (!validator.isPresent(recipeProfile.baseQuantity))
        return 'Recipe: Base quantity cannot be left empty';

    if (recipeProfile.recipeItems.length == 0)
        return 'No receipt ingredients specified';

    for (const recipeItem of recipeProfile.recipeItems) 
    {
        if (!validator.isPresent(recipeItem.ingredientStockItemId))
            return 'Recipe Ingredient: ingredient cannot be left empty';

        if (!validator.isPresent(recipeItem.unitTypeId))
            return 'Recipe Ingredient: unit cannot be left empty';
    }

    return null;
}

function validatePrepProfile(stockItem)
{
    if(!stockItem.recipeProfileEnabled)
        return null;
    
    const prepProfile = stockItem.prepProfile;

    const prepTime = prepProfile.prepTime;
    const expiryTime = prepProfile.expiryTime;
    const minExpiryLabels = prepProfile.minExpiryLabels;

    if (!validator.isPresent(prepTime) &&
        !validator.isPresent(expiryTime) &&
        !validator.isPresent(minExpiryLabels))
    {
        // Handles case when prepProfile tab is visible but user does not enter any recipe data
        stockItem.prepProfile = null;
        return null;
    }

    if (validator.isAnyPresent(prepTime, expiryTime))
    {
        if (!validator.isGreaterThanOrEqualToZero(prepTime))
            return "Prep time is not valid";

        if (!validator.isGreaterThanZero(expiryTime))
            return "Expiry time is not valid";
    }

    if (validator.isPresent(minExpiryLabels))
    {
        if (!validator.isAnyPresent(prepTime, expiryTime))
            return "Expiry labels require prep/expiry time specifications";

        if (!(validator.isInteger(minExpiryLabels) && validator.isGreaterThanOrEqualToZero(minExpiryLabels)))
            return "Min Expiry Labels is not valid";
    }

    return null;
}

function validateCookingTemperatureProfile(stockItem)
{
    if (!stockItem.cookingTemperatureProfileEnabled)
        return null;

    const cookingTemperatureProfile = stockItem.cookingTemperatureProfile;

    if (!validator.isNumeric(cookingTemperatureProfile.minTemperature))
        return "Cooking Temperature: Min Temperature is invalid";

    if (!validator.isNumeric(cookingTemperatureProfile.maxTemperature))
        return "Cooking Temperature: Max Temperature is invalid";

    if (cookingTemperatureProfile.minTemperature * 1.0 > cookingTemperatureProfile.maxTemperature)
        return "Cooking Temperature: Min Temperature must be less than Max Temperature";

    if (!validator.isPresent(cookingTemperatureProfile.minTemperatureLogType))
        return "Cooking Temperature: Min Temperature Log Type cannot be left empty";

    if (!(validator.isInteger(cookingTemperatureProfile.minTemperatureLogs) &&
        validator.isGreaterThanOrEqualToZero(cookingTemperatureProfile.minTemperatureLogs)))
        return "Cooking Temperature: Min Temperature Logs is invalid";

    if (!(validator.isInteger(cookingTemperatureProfile.autoCorrectionLogs) &&
        validator.isGreaterThanOrEqualToZero(cookingTemperatureProfile.autoCorrectionLogs)))
        return "Cooking Temperature: Auto Correction Logs is invalid";

    if (!validator.isArray(cookingTemperatureProfile.hazardTypes))
        return "Cooking Temperature: hazard Types must be an array cannot be left empty";

    return null;
}

function validateStorageTemperatureProfile(stockItem)
{
    if (!stockItem.storageTemperatureProfileEnabled)
        return null;

    const storageTemperatureProfile = stockItem.storageTemperatureProfile;

    if (!validator.isNumeric(storageTemperatureProfile.minTemperature))
        return "Storage Temperature: Min Temperature is invalid";

    if (!validator.isNumeric(storageTemperatureProfile.maxTemperature))
        return "Storage Temperature: Max Temperature is invalid";

    if (storageTemperatureProfile.minTemperature * 1.0 > storageTemperatureProfile.maxTemperature)
        return "Storage Temperature: Min Temperature must be less than Max Temperature";

    if (!validator.isPresent(storageTemperatureProfile.minTemperatureLogType))
        return "Storage Temperature: Min Temperature Log Type cannot be left empty";

    if (!(validator.isInteger(storageTemperatureProfile.minTemperatureLogs) &&
        validator.isGreaterThanOrEqualToZero(storageTemperatureProfile.minTemperatureLogs)))
        return "Storage Temperature: Min Temperature Logs is invalid";

    if (!(validator.isInteger(storageTemperatureProfile.autoCorrectionLogs) &&
        validator.isGreaterThanOrEqualToZero(storageTemperatureProfile.autoCorrectionLogs)))
        return "Storage Temperature: Auto Correction Logs is invalid";

    if (!validator.isArray(storageTemperatureProfile.hazardTypes))
        return "Storage Temperature: hazard Types must be an array cannot be left empty";

    return null;
}

function validateNutritionProfile(stockItem)
{
    if(!stockItem.nutritionProfileEnabled)
        return null;

    const nutritionProfile = stockItem.nutritionProfile;

    const minNutritionLabels = nutritionProfile.minNutritionLabels;
    if (validator.isPresent(minNutritionLabels))
    {
        if (!(validator.isInteger(minNutritionLabels) && validator.isGreaterThanOrEqualToZero(minNutritionLabels)))
            return "Min Nutrition Labels is not valid";
    }

    if (validator.isPresent(nutritionProfile.baseQuantity))
    {
        if (!validator.isGreaterThanZero(nutritionProfile.baseQuantity))
            return `Base Quantity is invalid for nutrition profile`;

        if (!validator.isPresent(nutritionProfile.unitTypeId))
            return `Unit is invalid for nutrition profile`;
    }

    const nutritions = nutritionProfile.nutritions;

    for (const nutrition of nutritions)
    {
        if (!validator.isPresent(nutrition.nutritionCode))
            return "Nutrition code is missing in nutrition";

        if (!validator.isGreaterThanOrEqualToZero(nutrition.quantity))
            return `Quantity is invalid for nutrition = ${nutrition.nutritionCode}`;
    }

    return null;
}

function validateAllergyProfile(stockItem)
{
    if (!stockItem.allergyProfileEnabled)
        return null;

    const allergyProfile = stockItem.allergyProfile;

    const minAllergyLabels = allergyProfile.minAllergyLabels;
    if (validator.isPresent(minAllergyLabels))
    {
        if (!(validator.isInteger(minAllergyLabels) && validator.isGreaterThanOrEqualToZero(minAllergyLabels)))
            return "Min Allergy Labels is not valid";
    }

    const allergies = allergyProfile.allergies;
    for (const allergy of allergies)
    {
        if (!(validator.isPresent(allergy.allergyCode) && validator.isPresent(allergy.presenceType)))
            return `Invalid allergy data for allergyCode = ${allergy.allergyCode}`;
    }
}

