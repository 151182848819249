import React, { Component } from 'react';
import { Row } from 'reactstrap';
import SaleSummaryDetails from './SaleSummaryDetails.jsx';
import OrderDetails from './OrderDetails.jsx';
import OrderTimings from './OrderTimings.jsx';
import SaleDiscounts from './SaleDiscounts.jsx';
import SaleCharges from './SaleCharges.jsx';
import SaleRefunds from './SaleRefunds.jsx';
import SaleReceiptLines from './SaleReceiptLines.jsx';
import SaleDispatchDetails from './SaleDispatchDetails.jsx';

class Sale extends Component 
{
    constructor(props) 
    {
        super(props);

        this.renderSale = this.renderSale.bind(this);
        this.renderError = this.renderError.bind(this);
    }

    render() 
    {
        return this.renderSale();
    }

    renderError()
    {
        return (<div>
            <Row className="justify-content-center" style={{ margin: '5px' }}>
                <h5 className="text-muted">{this.state.error}</h5>
            </Row>
        </div>);
    }

    renderSale()
    {

        return (
            <div className="mt-1 d-flex flex-column" style={{rowGap: '0.8rem'}}>
                        {<SaleSummaryDetails sale={this.props.sale} isDefaultOpen={false}/>}
                        {<OrderDetails sale={this.props.sale} isDefaultOpen={false}/>}
                        {<OrderTimings sale={this.props.sale} isDefaultOpen={false}/>}
                        {<SaleDiscounts sale={this.props.sale} isDefaultOpen={false}/>}
                        {<SaleCharges sale={this.props.sale} isDefaultOpen={false}/>}
                        {<SaleRefunds sale={this.props.sale} isDefaultOpen={false}/>}
                        {<SaleReceiptLines sale={this.props.sale} isDefaultOpen={false}/>}
                        {<SaleDispatchDetails sale={this.props.sale} isDefaultOpen={false}/>}
            </div>
        )
    }
}

export default Sale;