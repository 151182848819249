import React from "react";
import { FormGroup, Label, Col, FormText } from "reactstrap";

export function FormField({ label, inputId, description, children }) 
{
    return (
        <FormGroup row>
            <Label for={inputId} sm={4}>
                {label}
            </Label>
            <Col sm={8}>
                {children}
                <FormText>{description}</FormText>
            </Col>
        </FormGroup>
    )
}