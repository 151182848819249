import arraySort from 'array-sort';

import { regexSplitByComma } from '../../../../../../utils/regex/regexUtil';

import * as rmsApiProxy from '../../../../../../utils/api/rmsApiProxy';
import * as domainConstants from '../../../../../../utils/domain/constants';

import * as validationUtils from './openingHourValidationUtils';

const allDayOpen = 'Open';
const allDayClose = 'Closed';

export const allDispatchTypes = "All Dispatch Type";

export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export async function loadData(storefront)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/openingHours`;
    const storefrontOpeningHours = await rmsApiProxy.get(resourceUrl);

    if (storefrontOpeningHours.length === 0)
    {
        return getDefaultOpeningHoursData(storefront);
    }

    return convertStorefrontOpeningHoursToOpeningHoursData(storefrontOpeningHours, storefront);
}

export async function save(storefront, dispatchTypes, openingHoursData)
{
    // code assumes openingHoursData is already validated

    let storefrontOpeningHours = convertOpeningHoursDataToStorefrontOpeningHours(storefront, dispatchTypes, openingHoursData);

    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/openingHours`;
    storefrontOpeningHours = await rmsApiProxy.post(resourceUrl, storefrontOpeningHours);

    return convertStorefrontOpeningHoursToOpeningHoursData(storefrontOpeningHours, storefront);
}

// Private methods

function convertStorefrontOpeningHoursToOpeningHoursData(storefrontOpeningHours, storefront)
{
    const openingHoursType = domainConstants.OpeningHoursType;

    const dispatchTypes = getDefaultDispatchTypes(storefront);

    const openingHoursData = [];

    days.forEach(day =>
    {
        const dayOpeningHours = Object.assign({ day }, ...Object.keys(dispatchTypes).map(dispatchType => ({ [dispatchType]: 'Closed' })));

        storefrontOpeningHours
            .filter(data => data.daysOfTheWeek.includes(day))
            .forEach(data =>
            {
                let openingHour;

                switch (data.type)
                {
                    case openingHoursType.timeSlot:
                        const { fromHour, fromMinute, toHour, toMinute } = data;
                        openingHour = `${fromHour.toString().padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}-${toHour.toString().padStart(2, '0')}:${toMinute.toString().padStart(2, '0')}`;
                        break;
                    case openingHoursType.allDayOpen:
                        openingHour = allDayOpen;
                        break;
                    default:
                        openingHour = allDayClose;
                        break;
                }

                if (data.dispatchTypes.length === 0)
                {
                    dayOpeningHours[allDispatchTypes] = getUpdatedOpeningHourString(dayOpeningHours[allDispatchTypes], openingHour);
                }
                else
                {
                    data.dispatchTypes.forEach(dispatchType => 
                    {
                        if (dispatchTypes[dispatchType] === undefined)
                        {
                            // Ignore any incoming data for dispatchType not included in the dispatchTypes list
                            // This is a fix to handle any pre-existing data
                            return;
                        }

                        dayOpeningHours[dispatchType] = getUpdatedOpeningHourString(dayOpeningHours[dispatchType], openingHour);

                        dispatchTypes[dispatchType] = true;
                        dispatchTypes[allDispatchTypes] = false;
                    });
                }
            });

        openingHoursData.push(dayOpeningHours);
    });

    return { dispatchTypes, openingHoursData }
}

function convertOpeningHoursDataToStorefrontOpeningHours(storefront, dispatchTypes, openingHoursData)
{
    const openingHoursType = domainConstants.OpeningHoursType;

    const storefrontOpeningHours = [];

    for (const dispatchType of Object.keys(dispatchTypes)) 
    {
        if (dispatchTypes[dispatchType] === false)
            continue;

        days.forEach(day => 
        {
            const data = {
                storefrontId: storefront.id,
                daysOfTheWeek: [day],
                dispatchTypes: dispatchType === allDispatchTypes ? [] : [dispatchType]
            }

            const openingHour = openingHoursData.find(data => data.day === day)[dispatchType];

            if (validationUtils.isOpeningHourAllDayOpen(openingHour))
            {
                storefrontOpeningHours.push({ ...data, type: openingHoursType.allDayOpen });
                return;
            }

            if (validationUtils.isOpeningHourAllDayClose(openingHour))
            {
                storefrontOpeningHours.push({ ...data, type: openingHoursType.allDayClose });
                return;
            }

            // Implies time slot based openingHour

            validationUtils
                .getTimeSlotsFromOpeningHour(openingHour)
                .forEach(timeSlot => { storefrontOpeningHours.push({ ...data, ...timeSlot, type: openingHoursType.timeSlot, }) });
        });
    }

    return storefrontOpeningHours;
}

function getUpdatedOpeningHourString(currentValue, newValue)
{
    if (newValue === allDayOpen || newValue === allDayClose)
    {
        return newValue;
    }

    // Implies newValue is time range

    if (currentValue === allDayOpen || currentValue === allDayClose)
    {
        // time slot overrides Open/Closed
        return newValue;
    }

    // sort time slots by startTime
    return arraySort(`${currentValue}, ${newValue}`.split(regexSplitByComma)).join(', ');
}

function getDefaultOpeningHoursData(storefront)
{
    const dispatchTypes = getDefaultDispatchTypes(storefront);

    const openingHoursData = days.map(day =>
    {
        const openingHours = { day };
        Object.keys(dispatchTypes).forEach(dispatchType => openingHours[dispatchType] = allDayClose);
        return openingHours;
    });

    return { dispatchTypes, openingHoursData, };
}

function getDefaultDispatchTypes(storefront)
{
    const { PropertyStorefronts, FlipdishStorefronts, dispatchTypes } = domainConstants;

    const allowedDispatchTypes = [allDispatchTypes];    // default for MarketplaceStorefronts

    if (Object.values(PropertyStorefronts).includes(storefront.type))
    {
        allowedDispatchTypes.push(dispatchTypes.collection, dispatchTypes.delivery, dispatchTypes.dineIn, dispatchTypes.takeAway);
    }
    else if (Object.values(FlipdishStorefronts).includes(storefront.type))
    {
        allowedDispatchTypes.push(dispatchTypes.collection, dispatchTypes.delivery);
    }

    return Object.assign(
        ...arraySort(allowedDispatchTypes).map(dispatchType => ({ [dispatchType]: dispatchType === allDispatchTypes })));
}