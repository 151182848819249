
const constants = require('./constants')

module.exports.getSlidesSignagePlayers = function(players)
{
    return players.filter(player => inferSignagePlayer(player) === constants.signagePlayerType.Slides)
}

module.exports.getOrderSignagePlayers = function(players)
{
    return players.filter(player => inferSignagePlayer(player) === constants.signagePlayerType.ActiveOrders)
}


function inferSignagePlayer(signagePlayer)
{
    if (signagePlayer.slides.length <= 0)
        return null;

    if (signagePlayer.slides.length == 1 && signagePlayer.slides[0].type == constants.signageSlideTypes.ActiveOrders)
        return constants.signagePlayerType.ActiveOrders;
    
    if (signagePlayer.slides.filter(s => s.type == constants.signageSlideTypes.ActiveOrders).length <= 0)
        return constants.signagePlayerType.Slides;

    return null;
}