import React from "react";
import ColorPickerComponent from "../../../../../components/form/colorPickerComponent";
import InputComponent from '../../../../../components/form/inputComponent'
import FormManager from '../../../../../utils/view/formManager';

export default function CampaignMessage(props)
{
    const formManager = new FormManager();
    formManager.viewModel = props.data;
    formManager.onFieldChanged = (event) =>
    {
        if (props.onChange)
            props.onChange();
    }

    return <table width="100%">
        <tr>
            <td colSpan="2">
                <InputComponent
                    inputType="text"
                    caption="Title"
                    fieldName="titleTemplate"
                    placeholder="Enter marketing title to send to the users"
                    hintText="Enter message title (Required)"
                    inputReadOnly={props.isReadOnly}
                    multiLine={true} rows={1}
                    formManager={formManager} />
            </td>
        </tr>

        <tr>
            <td colSpan="2">
                <InputComponent
                    inputType="text"
                    caption="Message"
                    fieldName="messageTemplate"
                    placeholder="Enter marketing message to send to the users"
                    hintText="Enter message with placeholders for customer name (Required)"
                    inputReadOnly={props.isReadOnly}
                    multiLine={true} rows={4}
                    formManager={formManager} />
            </td>
        </tr>
        <tr>
            <td width="50%">
                <ColorPickerComponent
                    fieldName="backgroundColor"
                    placeholder="Color"
                    caption="Choose selected back color"
                    formManager={formManager}
                    style={{ marginRight: "20px" }}
                />
            </td>
            <td>
                <ColorPickerComponent
                    fieldName="foregroundColor"
                    placeholder="Color"
                    caption="Choose selected fore color"
                    formManager={formManager}
                />
            </td>
        </tr>
        <tr>
            <td colSpan="2">
                <InputComponent
                    inputType="web"
                    caption="Image"
                    fieldName="imageUrl"
                    placeholder="Enter url for image to display"
                    hintText="Image to display with the message (Optional)"
                    formManager={formManager} />
            </td>
        </tr>
    </table>
}