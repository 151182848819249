const stateManager = require('../stateManager');
const currentOrgNodeSelectors = require('./currentOrgNodeSelectors')

module.exports.selectOrgStates = function ()
{
    return stateManager.getStore().getState().orgStates;
}

module.exports.selectCurrentOrgState = function ()
{
    const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    if (currentOrgNode.franchisorId == null)
        return null;

    const orgStates = this.selectOrgStates();
    return orgStates.get(currentOrgNode.franchisorId) || null;
}

module.exports.selectCurrentOrgLookupData = function ()
{
    const currentOrgState = this.selectCurrentOrgState();
    return currentOrgState ? currentOrgState.lookupData : null;
}

module.exports.selectOrgState = function (franchisorId)
{
    const orgStates = this.selectOrgStates();
    return orgStates.get(franchisorId) || null;
}

module.exports.selectOrgLookupData = function (franchisorId)
{
    const orgState = this.selectOrgState(franchisorId);
    return orgState ? orgState.lookupData : null;
}


