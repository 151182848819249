import React, { Component } from 'react';

import GridViewEmbedded from '../../../../../components/gridViewEmbedded/gridViewEmbedded';
import GridViewOptions from '../../../../../components/gridView/gridViewOptions';
import StockItemSupplier from './StockItemSupplier';

const arraySort = require('array-sort');

const formatter = require('../../../../../utils/formatter/formatter');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');
const unitUtil = require('../../../../../utils/domain/unitUtil');

const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');

class StockItemSuppliers extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.getSuppliers = this.getSuppliers.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
        this.enrich = this.enrich.bind(this);

        props.data.suppliers = props.data.suppliers || [];
    }

    render() 
    {
        var gridViewOptions = new GridViewOptions();
        gridViewOptions.title = "Suppliers";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;
        gridViewOptions.getRowData = this.getSuppliers;

        gridViewOptions.getNewRow = () => ({ unitTypes: [], questions: [] });

        gridViewOptions.getComponent = (isNew, row, onFieldChanged) =>
        {
            return <StockItemSupplier
                data={row}
                stockItem={this.props.data}
                isRowReadOnly={this.props.isRowReadOnly}
                isSupplierReadonly={!isNew} //Supplier cannot be changed for existing row
                lookupData={this.props.lookupData}
                onFieldChanged={onFieldChanged}
            />
        };

        gridViewOptions.getComponentTitle = (isNew, row) => "Supplier";

        gridViewOptions.context = "Supplier";
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;
        // Supplier cannot be removed due to reference to unit type
        // gridViewOptions.remove = this.remove;
        gridViewOptions.messageContext = "Supplier";

        return (<GridViewEmbedded isRowReadOnly={this.props.isRowReadOnly} gridViewOptions={gridViewOptions} />);
    }

    validateRow(isNewRow, row)
    {
        if (stringUtil.isStringNullOrEmpty(row.supplierId))
            return "Supplier cannot be left empty";

        if (row.unitTypes.length == 0)
            return "No supplier packaging units types setup";

        if (!row.isDisabled && row.unitTypes.filter(unitType => !unitType.isDisabled).length == 0)
            return "At least one supplier packaging unit type should be enabled";

        if(row.unitTypes.some(unitType => stringUtil.isStringNullOrEmpty(unitType.unitTypeId)))
            return "Unit is not selected for some of the supplier packaging unit types";

        if (row.unitTypes.some(unitType => !validator.isGreaterThanOrEqualToZero(unitType.price) || !validator.isGreaterThanOrEqualToZero(unitType.price)))
            return "Price is not set on some of the supplier packaging unit types";

        if (!validator.areAllItemsUnique(row.unitTypes, (x) => x.unitTypeId))
            return "Supplier packaging unit type is defined more than once";

        return null;
    }

    save(isNew, row)
    {
        const suppliers = [...this.props.data.suppliers];
        if (isNew)
        {
            suppliers.push(row);
        }
        else
        {
            const index = suppliers.findIndex(x => x.supplierId === row.supplierId);
            if (index != -1) suppliers.splice(index, 1, row);
        }

        this.props.data.suppliers = suppliers;
        
        this.setState({});
    }

    getSuppliers()
    {
        const suppliers = this.props.data.suppliers;
        this.enrich(suppliers);
        return arraySort(suppliers, stringUtil.localeCompareProp("supplierName"));
    }

    enrich(suppliers)
    {
        if (!Array.isArray(suppliers)) suppliers = [suppliers];

        const suppliersLookupData = this.props.lookupData.suppliersLookupData;

        suppliers.forEach(supplier =>
        {
            const supplierData = suppliersLookupData.find(x => x.id === supplier.supplierId);
            supplier.supplierName = supplierData.name;

            const supplierUnitTypes = supplier.unitTypes;
            const unitTypes = unitUtil.getPossibleUnitsForStockItem(this.props.data);

            const formattedUnitTypes =
                supplierUnitTypes.map(item =>
                {
                    const unitType = unitTypes.find(x => x.id === item.unitTypeId);
                    if (unitType.isSupplementalUnit) return "??";
                    return `${unitType.code} ((${formatter.convertToCurrencyFormat(item.price)}) (min: ${formatter.convertToAmountFormat(item.minimumOrderQuantity)})`;
                }).join(", ");

            supplier.formattedUnitTypes = formattedUnitTypes;

            const formattedQuestion = supplier.questions.map(item => item.question).join(", ");
            supplier.formattedQuestion = formattedQuestion;
        });
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "supplierName";
        header.headerName = "Supplier";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "formattedUnitTypes";
        header.headerName = 'Packaging Unit Types';
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "formattedQuestion";
        header.headerName = 'Questions';
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default StockItemSuppliers;