module.exports = function ()
{
    this.cells = [];

    this.addDataCell = function (dataValue, bgColor="FFFFFFFF", fgColor="FF282828")
    {
        var cell = {};
        cell.value = dataValue;
        cell.style = {};
        cell.style.font = { sz: "14",  bold: false, color: {rgb: fgColor} };
        cell.style.fill = {patternType: "solid", fgColor: {rgb: bgColor}};
        cell.style.border = {
                                top: {style: 'thin', color: {rgb: fgColor}},
                                bottom: {style: 'thin', color: {rgb: fgColor}},
                                left: {style: 'thin', color: {rgb: fgColor}},
                                right: {style: 'thin', color: {rgb: fgColor}},
        };

        this.cells.push(cell);
    }

    this.addRowHeaderCell = function (dataValue)
    {
        var cell = {};
        cell.value = dataValue;
        cell.style = {};
        cell.style.font = { sz: "14",  bold: false, color: {rgb: "FFF8F8F8"} };
        cell.style.fill = {patternType: "solid", fgColor: {rgb: "FF606060"}};
        cell.style.border = {
                                top: {style: 'thin', color: {rgb: "FF282828"}},
                                bottom: {style: 'thin', color: {rgb: "FF282828"}},
                                left: {style: 'thin', color: {rgb: "FF282828"}},
                                right: {style: 'thin', color: {rgb: "FF282828"}},
        };

        this.cells.push(cell);
    }

    this.addInfoCell = function(dataValue)
    {
        var cell = {};
        cell.value = dataValue;
        cell.style = {};
        cell.style.font = { sz: "14",  bold: false, color: {rgb: "FF282828"} };
        cell.style.fill = {patternType: "solid", fgColor: {rgb: "FF00CCFF"}};
        cell.style.border = {
                                top: {style: 'thin', color: {rgb: "FF282828"}},
                                bottom: {style: 'thin', color: {rgb: "FF282828"}},
                                left: {style: 'thin', color: {rgb: "FF282828"}},
                                right: {style: 'thin', color: {rgb: "FF282828"}},
        };

        this.cells.push(cell);
    }

    this.addWarningCell = function(dataValue)
    {
        var cell = {};
        cell.value = dataValue;
        cell.style = {};
        cell.style.font = { sz: "14",  bold: false, color: {rgb: "FF282828"} };
        cell.style.fill = {patternType: "solid", fgColor: {rgb: "FFFFFF99"}};
        cell.style.border = {
                                top: {style: 'thin', color: {rgb: "FF282828"}},
                                bottom: {style: 'thin', color: {rgb: "FF282828"}},
                                left: {style: 'thin', color: {rgb: "FF282828"}},
                                right: {style: 'thin', color: {rgb: "FF282828"}},
        };

        this.cells.push(cell);
    }

    this.addErrorCell = function(dataValue)
    {
        var cell = {};
        cell.value = dataValue;
        cell.style = {};
        cell.style.font = { sz: "14",  bold: false, color: {rgb: "FF282828"} };
        cell.style.fill = {patternType: "solid", fgColor: {rgb: "FFFF3366"}};
        cell.style.border = {
                                top: {style: 'thin', color: {rgb: "FF282828"}},
                                bottom: {style: 'thin', color: {rgb: "FF282828"}},
                                left: {style: 'thin', color: {rgb: "FF282828"}},
                                right: {style: 'thin', color: {rgb: "FF282828"}},
        };

        this.cells.push(cell);
    }
}