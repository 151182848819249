import React from "react";
import * as chargeUtils from "../chargeUtils";
import BooleanSelectComponent from "../../../../../../components/form/booleanSelectComponent";
import MultiSelectComponent from "../../../../../../components/form/multiSelectComponent";

const localizationUtils = require('../../../../../../utils/domain/localizationUtils');
const constants = require("../../../../../../utils/domain/constants");

function ChargeConstraints({ storefrontOptions, formManager })
{
    function setValue(fieldName, value)
    {
        const event = {
            target: {
                id: fieldName,
                value: value
            }
        }
        formManager.onValueChanged(event);
    }

    return (
        <div>
            <table className="component-table">

                <tbody>
                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption="Auto Applicable"
                                fieldName="isAutoApplicable"
                                formManager={formManager}
                                hintText="Select if charge can be applied automatically"
                                clearable={false}
                            />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                caption="Removable"
                                fieldName="canBeRemoved"
                                formManager={formManager}
                                hintText="Select if charge can be removed"
                                clearable={false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <BooleanSelectComponent
                                caption={`Apply Before ${localizationUtils.getVatCaption()}`}
                                fieldName="appliedBeforeVat"
                                formManager={formManager}
                                hintText="Select if charge should be calculated before applying VAT"
                                clearable={false}
                                disabled={formManager.getValue("type") === constants.Charges.types.fixed}
                            />
                        </td>
                        <td>
                            <BooleanSelectComponent
                                caption="Apply Before Discount"
                                fieldName="appliedBeforeDiscount"
                                formManager={formManager}
                                hintText="Select if charge should be applied before discount"
                                clearable={false}
                                disabled={formManager.getValue("type") === constants.Charges.types.fixed}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <MultiSelectComponent
                                options={chargeUtils.getDispatchTypeOptions()}
                                valueFieldName="value"
                                displayFieldName="label"
                                selectedOptions={formManager.getValue("selectedDispatchTypeOptions")}
                                onSelectionChanged={options => setValue("selectedDispatchTypeOptions", options)}
                                caption="Dispatch Types"
                                hintText="Select dispatch types on which this charge will apply (Leave blank to mark all dispatch types)"
                                clearable={true}
                            />
                        </td>
                        <td>

                            <MultiSelectComponent
                                options={chargeUtils.getVenueCodeOptions()}
                                valueFieldName="value"
                                displayFieldName="label"
                                selectedOptions={formManager.getValue("selectedVenueCodeOptions")}
                                onSelectionChanged={options => setValue("selectedVenueCodeOptions", options)}
                                caption="Venues"
                                hintText="Select venues on which this charge will apply (Leave blank to mark all venues)"
                                clearable={true}
                            />

                        </td>
                    </tr>

                    <tr>
                        <td>
                            <MultiSelectComponent
                                options={storefrontOptions}
                                valueFieldName="value"
                                displayFieldName="label"
                                selectedOptions={formManager.getValue("selectedStorefrontOptions")}
                                onSelectionChanged={options => setValue("selectedStorefrontOptions", options)}
                                caption="Storefronts"
                                hintText="Select storefronts on which this charge will apply (Leave blank to mark all storefronts)"
                                clearable={true}
                            />
                        </td>
                        <td>
                            <MultiSelectComponent
                                options={chargeUtils.getPropertyOptions()}
                                valueFieldName="value"
                                displayFieldName="label"
                                selectedOptions={formManager.getValue("selectedPropertyOptions")}
                                onSelectionChanged={options => setValue("selectedPropertyOptions", options)}
                                caption="Properties"
                                hintText="Select properties on which this charge will apply (Leave blank to mark all properties)"
                                clearable={true}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <MultiSelectComponent
                                options={chargeUtils.getChannelOptions()}
                                valueFieldName="value"
                                displayFieldName="label"
                                selectedOptions={formManager.getValue("selectedChannelOptions")}
                                onSelectionChanged={options => setValue("selectedChannelOptions", options)}
                                caption="Channels"
                                hintText="Select channels on which this charge will apply (Leave blank to mark all channels)"
                                clearable={true}
                            />
                        </td>
                        <td/>
                    </tr>
                </tbody>

            </table>
        </div>
    )
}

export default ChargeConstraints;