
import React, { Component } from 'react';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import FranchisorOperatingConfigurationOverviewComponent from './components/FranchisorOperatingConfigurationOverviewComponent';
import FranchisorSMSConfigurationComponent from './components/FranchisorSMSConfigurationComponent';
import ErrorMessage from '../../../../../components/error/errorMessage';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const franchisorOperatingConfigurationHelper = require('./franchisorOperatingConfigurationHelper');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class FranchisorOperatingConfiguration extends Component
{

    constructor(props) 
    {

        super(props);
        this.state = {}
        this.state.isLoading = true;
        this.state.error = null;
        this.state.formManager = new FormManager();

        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.state.formManager.viewModel = {};
        this.state.formManager.view = this;
    }

    async componentDidMount()
    {
        this.setState({isLoading: true});
        viewUtil.showSystemModalSpinner("Loading, please wait");
        try
        {
            let franchisorOperatingConfiguration = await franchisorOperatingConfigurationHelper.load();
            if (franchisorOperatingConfiguration)
            {
                this.state.formManager.viewModel = franchisorOperatingConfiguration;
            }
        }
        catch(error)
        {
            this.setState({ error: error });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({isLoading: false});
        }
    }

    render() 
    {
        let component = null;

        if (this.state.isLoading)
        {
            component = null
        }

        else if (this.state.error)
        {
            component = <ErrorMessage message={ this.state.errorMessage } />;
        }

        else 
        {
            const tabHeaders = [];
            const tabContents = [];
    
            tabHeaders.push(<Tab>Overview</Tab>);
            tabContents.push(
                <TabPanel>
                    <FranchisorOperatingConfigurationOverviewComponent formManager={this.state.formManager} />
                </TabPanel>);
    
            tabHeaders.push(<Tab>SMS Configuration</Tab>);
            tabContents.push(
                <TabPanel>
                    <FranchisorSMSConfigurationComponent formManager={this.state.formManager} />
                </TabPanel>);
    
            component = <Tabs>
                <TabList>{ tabHeaders }</TabList>
                { tabContents }
            </Tabs>

        }

        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader 
                        title="Franchisor Operating Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={true}
                        />
                </CardHeader>

                <CardBody>
                    {component}
                </CardBody>
            </Card>

        )
    }

    async onSaveClicked() 
    {
        const franchisorOperatingConfiguration = this.state.formManager.viewModel;

        const validationError = franchisorOperatingConfigurationHelper.validate(franchisorOperatingConfiguration);
        if(!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showErrorAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Franchisor Operating Configuration");
        
        try
        {
            await franchisorOperatingConfigurationHelper.save(franchisorOperatingConfiguration);
            viewUtil.showSuccessAlert(`Franchisor  Operating Configuration saved`);

            await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.configuration, 
                                                        'Franchisor Operating Configuration has been altered', 
                                                        franchisorOperatingConfiguration);

            const currentOptionAction = {
                type: actionTypes.mainBarComponent,
                payload: null
            };

            actionDispatcher.dispatch(currentOptionAction);
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

}

export default FranchisorOperatingConfiguration;

