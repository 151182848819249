
import React, { Component }  from 'react';
import { Card, CardHeader, Button} from 'reactstrap';
import ScaleLoader from "react-spinners/ScaleLoader";
import CdnImage from '../../../../components/cdnImage/cdnImage.js';

var stringUtil = require('../../../../utils/string/stringUtil.js');
var dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
var configurationManager = require('../../../../utils/config/configurationManager.js');

class HolidayCalendar extends Component 
{

    constructor(props)
    {
        super(props);
        this.renderHolidayCalendarMonth = this.renderHolidayCalendarMonth.bind(this);
        this.renderBookedHolidays = this.renderBookedHolidays.bind(this);
        this.renderPreviousButton = this.renderPreviousButton.bind(this);
        this.renderNextButton = this.renderNextButton.bind(this);
        this.renderNextPreviousContainer = this.renderNextPreviousContainer.bind(this);

        this.getLocalToday = this.getLocalToday.bind(this);
        this.findBookedHolidaysForDate = this.findBookedHolidaysForDate.bind(this);
        this.createCaption = this.createCaption.bind(this);
       
        this.state = {};
        this.state.isLoadingPrevious = false;
        this.state.isLoadingNext = false;
    }

    componentWillReceiveProps(nextProps) 
    {
        this.state.isLoadingPrevious = false;
        this.state.isLoadingNext = false;
        this.setState({});  
    }

    render()
    {
        var monthsComponent = [];
        var currentDate = this.props.startDate;

        monthsComponent.push(this.renderPreviousButton())

        while (currentDate <= this.props.endDate)
        {
            monthsComponent.push(<div style={{marginBottom:'40px'}}>
                                    <h4 style={{color:'#989898'}}>{dateUtil.formatDate(currentDate, "MMMM, yyyy")}</h4>
                                    {this.renderHolidayCalendarMonth(currentDate)}
                                </div>)
            currentDate = dateUtil.addMonths(currentDate, 1);
        }

        monthsComponent.push(this.renderNextButton())

        return monthsComponent;
    }

    renderPreviousButton()
    {
        if (!this.state.isLoadingPrevious)
        {
            return this.renderNextPreviousContainer(<Button color="outline-secondary" className="outline-secondary"
                                                            onClick={()=>
                                                                {
                                                                    this.props.onPrevious();
                                                                    this.state.isLoadingPrevious = true;
                                                                    this.setState({});
                                                                }}>
                                                                                Load More
                                                    </Button>);
        }

        return this.renderNextPreviousContainer(<ScaleLoader
                                                    height={25}
                                                    width={6}
                                                    color={"#D3D3D3"}
                                                    loading={true} />
                                                );
    }

    renderNextButton()
    {
        if (!this.state.isLoadingNext)
        {
            return this.renderNextPreviousContainer(<Button color="outline-secondary" className="outline-secondary"
                                                            onClick={()=>
                                                                {
                                                                    this.props.onNext();
                                                                    this.state.isLoadingNext = true;
                                                                    this.setState({});
                                                                }}>
                                                                                Load More
                                                    </Button>);
        }

        return this.renderNextPreviousContainer(<ScaleLoader
                                                    height={25}
                                                    width={6}
                                                    color={"#D3D3D3"}
                                                    loading={true} />);
    }

    renderNextPreviousContainer (childComponent)
    {
        return (<table style={{width:'100%'}}>
                        <tr>
                            <td style={{width:'50%'}}>
                                <hr style={{marginBottom:'0px', marginTop:'0px'}}/>
                            </td>
                            <td>
                                <div style={{textAlign:"center", marginLeft:'10px', marginRight:'10px', whiteSpace: "nowrap"}}>
                                    {childComponent}
                                </div>

                            </td>
                            <td style={{width:'50%'}}>
                                <hr/>
                            </td>
                        </tr>

                    </table> );
    }

    renderHolidayCalendarMonth(startOfMonthDate)
    {
        var tableHeaders = [];
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Sun</th>);
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Mon</th>);
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Tue</th>);
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Wed</th>);
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Thr</th>);
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Fri</th>);
        tableHeaders.push(<th style={{textAlign:'center', verticalAlign:'center'}}>Sat</th>);

        var currentDate = startOfMonthDate;
        let tableRows = [];
        while (dateUtil.getMonth(currentDate) == dateUtil.getMonth(startOfMonthDate))
        {
            let dateComponents = [];
            for (var iCol = 0; iCol <= 6; iCol++)
            {
                if (dateUtil.getDay(currentDate) == iCol && dateUtil.getMonth(currentDate) == dateUtil.getMonth(startOfMonthDate))
                {
                    var bookedHolidays = this.renderBookedHolidays(currentDate);
                   
                    var backgroundColor = null;
                    if (dateUtil.isSame(currentDate, this.getLocalToday()))
                    {
                        backgroundColor = '#fffaf0';
                    }
                   
                    let calendarDate = currentDate;
                    dateComponents.push(<td style={{ width: '14%', background: backgroundColor, verticalAlign: 'top' }} onClick={this.props.isReadOnly ? null : () => { this.props.onDateClick(calendarDate) }}>
                                            <div style={{minHeight:'150px', width:'100%'}}>
                                                <div style={{textAlign:'right'}}>
                                                    {dateUtil.formatDate(currentDate, "DD/MMM")}
                                                </div>
                                                {bookedHolidays.length > 0 ?  <div style={{marginTop:'10px'}}>{bookedHolidays}<div style={{minHeight:'50px'}}></div></div> : null}
                                            </div>
                                        </td>);

                    currentDate = dateUtil.addDays(currentDate, 1);
                }
                else
                {
                    dateComponents.push(<td style={{background:'#F5F5F5'}}><div style={{minHeight:'150px', width:'100%', textAlign:'right'}}></div></td>)
                }
            }

            tableRows.push(<tr>{dateComponents}</tr>);
        }

        return (<table width="100%" className="pure-table pure-table-bordered">
                    <thead>
                        <tr>
                            {tableHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>);
    }

    renderBookedHolidays(bookingDate)
    {
        var bookedHolidays = this.findBookedHolidaysForDate(bookingDate);
        var bookedHolidayComponents = [];

        bookedHolidays.forEach (bookedHoliday =>
        {
            var staff = this.props.allStaffs.find (s => s.id == bookedHoliday.staffId);
            var name = `${staff.firstName} ${staff.lastName}`;

            var imageComponent = null;
            if (!stringUtil.isStringNullOrEmpty(staff.pictUrl))
                imageComponent = <div> <CdnImage  cloudName={configurationManager.getConfig().cdnUsersCloudName} publicId={staff.pictUrl} width="30" height="30" radius="0"/></div>;
            else
                imageComponent = <div> <CdnImage  cloudName={configurationManager.getConfig().cdnAppCloudName} publicId='app/back.office/icons/default.staff.picture.png' width="30" height="30" radius="0"/></div>;

            var cardColor = "success";
            if (stringUtil.areStringSame(bookedHoliday.status, "pending"))
                cardColor = "primary";
            else if (stringUtil.areStringSame(bookedHoliday.status, "rejected"))
                cardColor = "danger";
                
            var htmlReference = React.createRef();
            if (!this.props.bookedComponentHtmlReferences.has(bookedHoliday.id))
                this.props.bookedComponentHtmlReferences.set(bookedHoliday.id, htmlReference);
          
            bookedHolidayComponents.push(<Card innerRef={htmlReference} color={cardColor} onClick={(e)=>{this.props.onBookedHolidayClick(bookedHoliday); e.stopPropagation();}}>
                                            <CardHeader>
                                                {imageComponent}
                                                <div style={{minWidth:'0px', margin:'0px', padding:'0px', textAlign:'center'}}>{name}</div> 
                                                <div style={{textAlign:'center'}}>
                                                    <small>{this.createCaption(bookedHoliday)}</small>
                                                </div>
                                            </CardHeader>
                                        </Card>);
        });


        return bookedHolidayComponents;
    }

    findBookedHolidaysForDate(bookingDate)
    {
        return this.props.bookedHolidays.filter(b => dateUtil.isSameOrAfter(bookingDate, dateUtil.getDateComponent(dateUtil.convertToLocalStandard(b.fromDate))) &&
                                                     dateUtil.isSameOrBefore(bookingDate, dateUtil.getDateComponent(dateUtil.convertToLocalStandard(b.toDate))));
    }

    getLocalToday()
    {
        return dateUtil.getDateComponent(dateUtil.convertToLocalStandard(dateUtil.getNow()));
    }

    createCaption(bookedHoliday)
    {
        var caption = `Full Day (${dateUtil.formatDate(bookedHoliday.fromDate, "DD/MMM")} - ${dateUtil.formatDate(bookedHoliday.toDate, "DD/MMM")})`;
            if (!bookedHoliday.isFullDay)
                caption = `${dateUtil.formatDate(dateUtil.convertToLocalStandard(bookedHoliday.fromDate), "HH:mm")} - ${dateUtil.formatDate(dateUtil.convertToLocalStandard(bookedHoliday.toDate), "HH:mm")}`;
        
        return caption;
    }

}

export default HolidayCalendar;