const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const validator = require('../../../../../utils/validator/validator');

module.exports.save = function (storefront, tipConfiguration)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/tipConfigurations`;
    return rmsApiProxy.post(resourceUrl, tipConfiguration);
}

module.exports.loadStorefrontTipConfiguration = function (storefront)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/tipConfigurations`;
    return rmsApiProxy.get(resourceUrl);
}

module.exports.validateTipConfiguration = function (tipConfiguration)
{
    const {
        amountTip1, amountTip2, amountTip3,
        percentageTip1, percentageTip2, percentageTip3,
        amountToPercentageCutoff
    } = tipConfiguration;
    
    if (validator.isAnyPresent(amountTip1, amountTip2, amountTip3))
    {
        if (!validator.isAllPresent(amountTip1, amountTip2, amountTip3)) return "All 3 tips amount values must be specified";
        if (!validator.isGreaterThanZero(amountTip1)) return "Fixed tip amount 1 is invalid";
        if (!validator.isGreaterThanZero(amountTip2)) return "Fixed tip amount 2 is invalid";
        if (!validator.isGreaterThanZero(amountTip3)) return "Fixed tip amount 3 is invalid";
        if (!validator.isGreaterThan(amountTip2, amountTip1)) return "Fixed tip amount 2 must be greater than amount 1";
        if (!validator.isGreaterThan(amountTip3, amountTip2)) return "Fixed tip amount 3 must be greater than amount 2";
    }

    if (validator.isAnyPresent(percentageTip1, percentageTip2, percentageTip3))
    {
        if (!validator.isAllPresent(percentageTip1, percentageTip2, percentageTip3)) return " All 3 tip percentages values must be specified";
        if (!validator.isGreaterThanZero(percentageTip1)) return "Percentage tip value 1 is invalid";
        if (!validator.isGreaterThanZero(percentageTip2)) return "Percentage tip value 2 is invalid";
        if (!validator.isGreaterThanZero(percentageTip3)) return "Percentage tip value 3 is invalid";
        if (!validator.isGreaterThan(percentageTip2, percentageTip1)) return "Percentage tip value 2 must be greater than value 1";
        if (!validator.isGreaterThan(percentageTip3, percentageTip2)) return "Percentage tip value 3 must be greater than value 2";
    }

    if (validator.isPresent(amountToPercentageCutoff)) 
    {
        if (!validator.isGreaterThanZero(amountToPercentageCutoff)) return "Percentage cut-off amount is invalid";
    }

    if (validator.isAllPresent(amountTip1, percentageTip1))
    {
        if (!validator.isPresent(amountToPercentageCutoff)) return "Percentage cut-off amount is required";
    }

    if (validator.isPresent(amountToPercentageCutoff))
    {
        if(!validator.isAllPresent(amountTip1, percentageTip1)) return "For Percentage cut-off, amount and percentage is required";
    }

    return null;
}