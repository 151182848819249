import TableColumn from './TableColumn';

export default class TableRow
{
    #columns;
    #childRows;
    #beforeBlankRow;
    #afterBlankRow;

    constructor(columnDefinitions)
    {
        this.#columns = columnDefinitions.map((columnDefinition) => new TableColumn(columnDefinition));
    }

    setColumnValues(...values)
    {
        this.#columns.forEach((column, index) => 
        {
            column.value = values[index] ?? "";
        });

        return this;
    }

    get columns() { return this.#columns; }

    get childRows() { return this.#childRows ?? [] }
    set childRows(value) { this.#childRows = value }

    get beforeBlankRow() { return this.#beforeBlankRow ?? false; }
    set beforeBlankRow(value) { this.#beforeBlankRow = Boolean(value); }

    get afterBlankRow() { return this.#afterBlankRow ?? false; }
    set afterBlankRow(value) { this.#afterBlankRow = Boolean(value); }

    renderPayload()
    {
        return {
            
            columns: this.#columns.map(col => col.renderPayload()),

            // optional/undefined fields
            beforeBlankRow: this.#beforeBlankRow,
            afterBlankRow: this.#afterBlankRow
        };
    }
}