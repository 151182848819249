
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../components/form/booleanSelectComponent';
import SelectEncodingView from '../selectEncodingView';

const FormManager = require('../../../../../utils/view/formManager.js');

const stationState = require('../../stationState');
const stationUtil = require('../../stationUtil');
const constants = require('../../../../../utils/domain/constants');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const validator = require('../../../../../utils/validator/validator');

class ReceiptPrinter extends Component 
{
    constructor(props)
    {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.printer;
        this.formManager.view = this;

        this.formManager.onFieldChanged = (event) =>
        {
            if (event.target.id == "externalEntity")
            {
                if(event.target.value === "escpos.sunmi.cloud")
                {
                    this.formManager.viewModel.encodingType = constants.printerEncodings.sunmi.encodingType;
                    this.formManager.viewModel.encodingInstructions = constants.printerEncodings.sunmi.encodingInstructions;
                }
                else
                {
                    this.formManager.viewModel.encodingType = "";
                    this.formManager.viewModel.encodingInstructions = "";
                }

                this.setState({});
            }
        }
    }

    render()
    {
        return (<Card>
                    <CardHeader>
                        {this.renderToolbar()}
                    </CardHeader>
                    <CardBody>
                        <div style={{minHeight:window.innerHeight * 0.50}}>
                            {this.renderOptions()}
                        </div>
                    </CardBody>
                </Card>
                );
    }

    renderToolbar()
    {
        return (
                    <table width="100%">
                        <tr>
                            <td>
                                <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - Receipt Printer</div>
                            </td>
                            <td align="right">
                                {
                                    !this.props.isReadOnly &&
                                    <>
                                        <Button color="danger" className="btn-danger" style={{ marginRight: '30px' }} onClick={this.onDelete}>
                                            Delete Printer
                                        </Button>

                                        <Button color="success" className="btn-success" style={{ marginRight: '5px' }} onClick={this.onSave}>
                                            Save
                                        </Button>
                                    </>
                                }

                                <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={()=>{this.props.onClose()}}>
                                    {this.props.isReadOnly ? "Close": "Cancel"}
                                </Button>
                            </td>
                        </tr>
                    </table>
                );
    }

    renderOptions()
    {
        
        return <div style={{ margin: "0px 10px" }}>
                    <table className="component-table">
                        <tbody>
                            <tr>
                                <td >
                                    <SelectComponent
                                        optionValues={this.getPrinterTypes()}
                                        optionFieldName="value"
                                        optionDisplayFieldName="value"
                                        caption="Choose Receipt Printer Type"
                                        fieldName="externalEntity"
                                        hintText="Select receipt printer type"
                                        clearable={true}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                                <td>
                                    <InputComponent
                                        inputType="text"
                                        caption="Printer Name"
                                        fieldName="printerName"
                                        placeholder="Enter printer name"
                                        hintText="Enter printer name (Required)"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>

                                </td>
                            </tr>
                            {this.renderPrinterTypeDetails()}
                        </tbody>
                    </ table>
            </div>
    }

    renderPrinterTypeDetails()
    {
        var components = [];

        if (this.props.printer.externalEntity == null)
            return components;

        if (this.props.printer.externalEntity == "escpos.generic.usb")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="System Name"
                                        fieldName="systemName"
                                        key="systemName"
                                        placeholder="Enter system printer name"
                                        hintText="Enter printer name (as registered in system)"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager}
                                        style={{marginTop:'30px'}}/>
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.generic.bluetooth")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="Bluetooth Address"
                                        fieldName="bluetoothAddress"
                                        key="bluetoothAddress"
                                        placeholder="Enter bluetooth address"
                                        hintText="Enter bluetooth address"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.generic.network")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="IP Address"
                                        fieldName="ipAddress"
                                        key="ipAddress"
                                        placeholder="Enter IP address"
                                        hintText="Enter IP address"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                            </tr>
                            );
        }

        if (this.props.printer.externalEntity == "escpos.sunmi.cloud")
        {
            components.push(<tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="Serial Number"
                                        fieldName="serialNumber"
                                        key="serialNumber"
                                        placeholder="Enter Serial number"
                                        hintText="Enter Serial number"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                            </tr>
                            );
        }

        components.push(
                        <tr>
                            <td >
                                <SelectEncodingView
                                    inputReadOnly={this.props.isReadOnly || this.props.printer.externalEntity == "escpos.sunmi.cloud"}
                                    formManager={this.formManager} />
                            </td>
                            <td>
                                <BooleanSelectComponent
                                    caption="Is Legacy Printer"
                                    fieldName="isLegacyPrinter"
                                    hintText="Legacy printers don't support some features like reverse printing"
                                    clearable={false}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} 
                                    style={{marginTop:'30px'}}/>
                            </td>
                        </tr>)

        if (this.props.printer.externalEntity == "escpos.generic.usb")
        {
            components.push(
                            <tr>
                                <td >
                                    <InputComponent
                                        inputType="text"
                                        caption="No Of Columns"
                                        fieldName="noOfColumns"
                                        key="noOfColumns"
                                        placeholder="Enter no of columns"
                                        hintText="Enter no of columns printer"
                                        inputReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>
                                </td>
                                <td>
                                    <BooleanSelectComponent
                                        caption="Logo Require Centering"
                                        fieldName="logoRequireCentering"
                                        hintText="Indicates if logo required to be centered"
                                        clearable={false}
                                        comboReadOnly={this.props.isReadOnly}
                                        formManager={this.formManager} 
                                        style={{marginTop:'30px'}}/>

                                </td>
                            </tr>)

            components.push(
                <tr>
                    <td >
                        <InputComponent
                            inputType="text"
                            caption="Logo Size"
                            fieldName="logoSize"
                            key="logoSize"
                            placeholder="Enter logo size"
                            hintText="Enter logo size"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.formManager} 
                            style={{marginTop:'30px'}}/>
                    </td>
                    <td>
                        <InputComponent
                            inputType="text"
                            caption="Logo Hex Code"
                            fieldName="logoHexCode"
                            key="logoHexCode"
                            placeholder="Enter logo hex code"
                            hintText="Enter logo hex code"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={this.formManager} 
                            style={{marginTop:'30px'}}/>

                    </td>
                </tr>)

                components.push(
                    <tr>
                        <td >
                            <InputComponent
                                inputType="text"
                                caption="Logo File Path"
                                fieldName="logoFilePath"
                                key="logoFilePath"
                                placeholder="Enter logo file path"
                                hintText="Enter logo file path"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} 
                                style={{marginTop:'30px'}}/>
                        </td>
         
                    </tr>)
        }

        return components;
    }

    async onSave()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.printer.externalEntity))
        {
            viewUtil.showErrorAlert("Please choose printer type");
            return;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.printer.printerName))
        {
            viewUtil.showErrorAlert("Please enter printer name");
            return;
        }

        if (this.props.printer.externalEntity == "escpos.generic.usb")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.systemName))
            {
                viewUtil.showErrorAlert("Please enter system name");
                return;
            }

            if (!stringUtil.isStringNullOrEmpty(this.props.printer.noOfColumns) && !validator.isInteger(this.props.printer.noOfColumns))
            {
                viewUtil.showErrorAlert("No Of Columns is not valid");
                return;
            }
        }

        if (this.props.printer.externalEntity == "escpos.generic.bluetooth")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.bluetoothAddress))
            {
                viewUtil.showErrorAlert("Please enter bluetooth address");
                return;
            }
        }

        if (this.props.printer.externalEntity == "escpos.generic.network")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.ipAddress))
            {
                viewUtil.showErrorAlert("Please enter IP address");
                return;
            }
        }

        if (this.props.printer.externalEntity == "escpos.sunmi.cloud")
        {
            if (stringUtil.isStringNullOrEmpty(this.props.printer.serialNumber))
            {
                viewUtil.showErrorAlert("Please enter Serial number");
                return;
            }
        }

        var lastError = null;
        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            var shell = stationState.currentStationViewModel.shell;
            var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
            
            if (hardwareTerminal == null)
            {
                hardwareTerminal = stationUtil.createNewHardwareTerminal(stationState.currentStationViewModel);
                await stationUtil.addUpdateTerminal(hardwareTerminal);
                await stationUtil.addUpdateShell(shell);
            }

            var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
            if (hardwareTerminalIntegrations == null)
                hardwareTerminalIntegrations = [];

            hardwareTerminalIntegrations = hardwareTerminalIntegrations.filter(k => k.id != this.props.printer.id);
            
            var integration = stationUtil.createNewIntegration(hardwareTerminal, "ReceiptPrinterManagement", this.props.printer.externalEntity);
            integration.jsonArray.push({key: 'printerName', value: this.props.printer.printerName});
            integration.jsonArray.push({key: 'systemName', value: this.props.printer.systemName});
            integration.jsonArray.push({key: 'bluetoothAddress', value: this.props.printer.bluetoothAddress});
            integration.jsonArray.push({key: 'ipAddress', value: this.props.printer.ipAddress});
            integration.jsonArray.push({key: 'serialNumber', value: this.props.printer.serialNumber});
            integration.jsonArray.push({key: 'encodingType', value: this.props.printer.encodingType});
            integration.jsonArray.push({key: 'encodingInstructions', value: this.props.printer.encodingInstructions});
            integration.jsonArray.push({key: 'isLegacyPrinter', value: this.props.printer.isLegacyPrinter || false});
            if(!stringUtil.isStringNullOrEmpty(this.props.printer.noOfColumns))
            {
                integration.jsonArray.push({key: 'noOfColumns', value: this.props.printer.noOfColumns});
            }
            integration.jsonArray.push({key: 'logoRequireCentering', value: this.props.printer.logoRequireCentering});
            integration.jsonArray.push({key: 'logoSize', value: this.props.printer.logoSize});
            integration.jsonArray.push({key: 'logoHexCode', value: this.props.printer.logoHexCode});
            integration.jsonArray.push({key: 'logoFilePath', value: this.props.printer.logoFilePath});
            integration.jsonArray.push({key: 'serialNumber', value: this.props.printer.serialNumber});

            hardwareTerminalIntegrations.push(integration);
            stationUtil.updateTerminalIntegrations(hardwareTerminal, hardwareTerminalIntegrations);
            await stationState.loadIntegrations();
            await stationState.loadPrinters();
        }
        catch(error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }

        

    }

    async onDelete()
    {
        var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal == null)
        {
            this.props.onClose();
            return;
        }

        var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
        if (hardwareTerminalIntegrations == null || hardwareTerminalIntegrations.length <= 0)
        {
            this.props.onClose();
            return;
        }

        if (hardwareTerminalIntegrations.find(h => stringUtil.areStringSame(h.id, this.props.printer.id)) == null)
        {
            this.props.onClose();
            return;
        }

        var lastError = null;
        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            hardwareTerminalIntegrations = hardwareTerminalIntegrations.filter(k => k.id != this.props.printer.id);
            stationUtil.updateTerminalIntegrations(hardwareTerminal, hardwareTerminalIntegrations);
            await stationState.loadIntegrations();
            await stationState.loadPrinters();

        }
        catch(error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }

    getPrinterTypes()
    {
        var receiptPrinterTypes = [];
        receiptPrinterTypes.push({value:"escpos.generic.usb"});
        receiptPrinterTypes.push({value:"escpos.generic.bluetooth"});
        receiptPrinterTypes.push({value:"escpos.generic.network"});

        receiptPrinterTypes.push({value:"escpos.sunmi.cloud"});

        return receiptPrinterTypes;
    }
}

export default ReceiptPrinter;