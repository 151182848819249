import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import FormManager from '../../../../../../utils/view/formManager';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import InputDisplayComponent from '../../../../../../components/form/displayComponents/InputDisplayComponent';

import domainConstants from '../../../../../../utils/domain/constants';
import unitUtil from '../../../../../../utils/domain/unitUtil';
import parseUtil from '../../../../../../utils/parsing/parseUtil';
import formatter from '../../../../../../utils/formatter/formatter';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';
import stringUtil from '../../../../../../utils/string/stringUtil';
import typeUtil from '../../../../../../utils/type/typeUtil';

const centralKitchenUtils = require('../../../../../setup/centralKitchen/utils/centralKitchenUtils');
const localizationUtils = require('../../../../../../utils/domain/localizationUtils');

export default class ItemOrderComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = props.data;    // orderItem
        this.formManager.view = this;
        this.formManager.onFieldChanged = this.onFieldChanged;
    }

    onFieldChanged = (event) =>
    {
        const id = event.target.id;

        if (id == "unitTypeId")
        {
            const unitTypeId = this.orderItem.unitTypeId;

            if (unitTypeId)
            {
                const supplierUnit = this.supplierUnits.find(unit => unit.unitTypeId == unitTypeId);

                const itemPrice = this.isCentralKitchen()
                    ? centralKitchenUtils.getCentralKitchenStockItemPrice(supplierUnit.pluCode, this.getEffectiveSupplierStockItems())
                    : { price: supplierUnit.price, vat: supplierUnit.vat };

                this.orderItem.unitPrice = itemPrice.price;
                this.orderItem.vat = itemPrice.vat;
            }
        }

        this.props.onFieldChanged(this.orderItem);
    }

    get purchaseOrder() 
    {
        return this.props.purchaseOrder;
    }

    get orderItem()
    {
        return this.formManager.viewModel;
    }

    get stockItem()
    {
        return purchaseOrderUtil.getStockItem(this.orderItem, this.props.lookupData);
    }

    get stockItemUnits()
    {
        const stockItemId = this.orderItem.stockItemId;
        return unitUtil
            .getPossibleUnitsForStockItemId(stockItemId, this.props.lookupData.stockItems)
            .filter(unit => !unit.isSupplementalUnit);
    }

    get supplierUnits()
    {
        const supplierId = this.purchaseOrder.supplierId;
        const stockItemId = this.orderItem.stockItemId;
        return unitUtil.getPossibleUnitForStockItemSupplier(stockItemId, supplierId, this.props.lookupData.stockItems, !this.props.purchaseOrderContext.isNewOrder);
    }

    get optionUnits()
    {
        const stockItemUnits = this.stockItemUnits;
        
        if (!this.isCentralKitchen())
        {
            const optionUnits = this.supplierUnits
                .map(supplierUnit => stockItemUnits.find(stockItemUnit => stockItemUnit.id === supplierUnit.unitTypeId))
                .filter(unit => unit != null);

            return optionUnits;
        }
        

        const centralKitchenStockItems = this.getEffectiveSupplierStockItems();

        const optionUnits = this.supplierUnits
            .map(supplierUnit =>
            {
                let unit = stockItemUnits.find(stockItemUnit => stockItemUnit.id === supplierUnit.unitTypeId);
                if (unit != null)
                {
                    unit = typeUtil.deepCloneObject(unit);
                    const unavailableReason = centralKitchenUtils.getStockItemUnitDisabledReason(supplierUnit, centralKitchenStockItems);
                    unit.disabled = !stringUtil.isStringNullOrEmpty(unavailableReason);
                }
                return unit;
            })
            .filter(unit => unit != null);


        return optionUnits;
    }

    get selectedUnit()
    {
        const unitTypeId = this.orderItem.unitTypeId;
        return this.optionUnits.find(unit => unit.id === unitTypeId);
    }

    get pluCode()
    {
        if (this.selectedUnit == null) return "N/A";

        const selectedUnitTypeId = this.selectedUnit.id;
        const supplierUnit = this.supplierUnits.find(unit => unit.unitTypeId == selectedUnitTypeId);
        return supplierUnit.pluCode;
    }

    get quantity()
    {
        return this.orderItem.quantity;
    }

    get quantityInCoreUnits()
    {
        if (this.selectedUnit == null) return NaN;

        const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
        if (quantity == null) return null;
        return unitUtil.convertToStockItemCoreUnitsByStockItemId(
            this.stockItem.id, this.selectedUnit.id, quantity, this.props.lookupData.stockItems);
    }

    get unitPrice()
    {
        return this.orderItem.unitPrice;
    }

    get vat()
    {
        return this.orderItem.vat;
    }

    get totalAmount()
    {
        const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
        const unitPrice = parseUtil.parseFloatGreaterThanOrEqualToZeroElseNull(this.unitPrice);
        if (quantity == null || unitPrice == null) return null;
        return unitPrice * quantity;
    }

    get totalVat()
    {
        const quantity = parseUtil.parseFloatGreaterThanZeroElseNull(this.quantity);
        const vat = parseUtil.parseFloatGreaterThanOrEqualToZeroElseNull(this.vat);
        if (quantity == null || vat == null) return null;
        return vat * quantity;
    }

    get totalGrossAmount()
    {
        if (this.totalAmount == null || this.totalVat == null) return null;
        return this.totalAmount + this.totalVat;
    }

    get isReadOnly()
    {
        return (
            !purchaseOrderUtil.isUserAuthorizedForAnyAction() ||
            this.purchaseOrder.status !== domainConstants.PurchaseOrderStatusType.draft);
    }

    isCentralKitchen()
    {
        return this.props.lookupData.centralKitchensLookupData.some(kitchen => kitchen.id == this.purchaseOrder.supplierId);
    }

    getEffectiveSupplierStockItems()
    {
        return this.props.lookupData.centralKitchensLookupData.find(kitchen => kitchen.id == this.purchaseOrder.supplierId).effectiveSupplierStockItems;
    }

    render()
    {
        const vatCaption = localizationUtils.getVatCaption();
        return (
            <Card className='h-100'>
                <CardHeader>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div className="fw-bold">Order Details</div>
                        <div>Plu Code: {this.pluCode}</div>
                        <div>Quantity In Core Units: {this.quantityInCoreUnits
                            ? `${this.quantityInCoreUnits} ${this.stockItem.coreUnitCode}`
                            : "n/a"}</div>
                    </div>
                </CardHeader>
                <CardBody style={{ padding: "5px 10px" }}>

                    <div className="d-flex flex-column">

                        <div className="d-flex flex-row align-items-center">
                            <div className="w-25 mx-2 my-1">
                                <SelectComponent
                                    caption="Unit Type"
                                    fieldName="unitTypeId"
                                    optionFieldName="id"
                                    optionDisplayFieldName="code"
                                    optionValues={this.optionUnits}
                                    clearable={false}
                                    comboReadOnly={this.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className="w-25 mx-2 my-1">
                                <InputComponent
                                    inputType="number"
                                    caption="Quantity"
                                    fieldName="quantity"
                                    placeholder="Enter quantity"
                                    inputReadOnly={this.isReadOnly}
                                    formManager={this.formManager} />
                            </div>
                            <div className="w-25 mx-2 my-1">
                                <InputDisplayComponent
                                    caption="Unit Price"
                                    value={formatter.convertToCurrencyFormat(this.unitPrice)} />
                            </div>
                            <div className="w-25 mx-2 my-1">
                                <InputDisplayComponent
                                    caption={vatCaption}
                                    value={formatter.convertToCurrencyFormat(this.vat)} />
                            </div>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                            <div className="w-25 mx-2">
                                <InputDisplayComponent
                                    caption="Total Amount"
                                    value={formatter.convertToCurrencyFormat(this.totalAmount)} />
                            </div>
                            <div className="w-25 mx-2">
                                <InputDisplayComponent
                                    caption={`Total ${vatCaption}`}
                                    value={formatter.convertToCurrencyFormat(this.totalVat)} />
                            </div>
                            <div className="w-25 mx-2">
                                <InputDisplayComponent
                                    caption="Total Gross"
                                    value={formatter.convertToCurrencyFormat(this.totalGrossAmount)} />
                            </div>
                            <div className="w-25 mx-2"></div>
                        </div>

                    </div>

                </CardBody>
            </Card>
        );
    }
}