import React, { Component } from 'react';

import ItemHeaderComponent from './ItemHeaderComponent';
import ItemOrderComponent from './ItemOrderComponent';
import ItemReceiptComponent from './ItemReceiptComponent';
import ItemInventoryComponent from './ItemInventoryComponent';
import SupplierAddedOrderItemComponent from './SupplierAddedOrderItemComponent';

import domainConstants from '../../../../../../utils/domain/constants';

export default class ItemEditComponent extends Component
{
    get purchaseOrder()
    {
        return this.props.purchaseOrder;
    }

    render()
    {
        const allowInventoryComponent =
            this.purchaseOrder.status == domainConstants.PurchaseOrderStatusType.draft;

        const allowReceiptComponent =
            this.purchaseOrder.status == domainConstants.PurchaseOrderStatusType.submitted ||
            this.purchaseOrder.status == domainConstants.PurchaseOrderStatusType.received;

        const isSupplierAdded = this.props.data.isSupplierAdded;

        return (
            <div className="bg-white d-flex flex-column border rounded p-3 m-2">

                <div><ItemHeaderComponent {...this.props} /></div>

                <div className="d-flex flex-row">

                    <div className="p-1 w-50">
                        {
                            !isSupplierAdded
                                ? <ItemOrderComponent {...this.props} />
                                : <SupplierAddedOrderItemComponent {...this.props} />
                        }
                    </div>

                    {
                        allowInventoryComponent &&
                        <div className="p-1 w-50"><ItemInventoryComponent {...this.props} /></div>
                    }

                    {
                        allowReceiptComponent &&
                        <div className="p-1 w-50"><ItemReceiptComponent {...this.props} /></div>
                    }

                </div>

            </div>
        );
    }
}