import React from "react";
import { Col, Row } from "reactstrap";
import { Field } from "../../../Form";

const Tab = ({ fields, active, onFieldValueChange }) => {
     return (
          <Row style={{ display: active ? "flex" : "none", padding: 12 }}>
               <Col>
                    {fields.map((field, fIdx) => (
                         <Field field={field} key={fIdx} onValueChange={onFieldValueChange} />
                    ))}
               </Col>
          </Row>
     );
};

export default Tab;
