import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BrandDetails from "./BrandDetails";
import BrandLogo from "./BrandLogo";

const FormManager = require("../../../../../../utils/view/formManager");

class Brand extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = {
            formManager: new FormManager()
        };
        this.state.formManager.viewModel = this.props.row;
        this.state.formManager.view = this;
    }

    render()
    {
        const tabContent = [
            <TabPanel>
                <BrandDetails formManager={this.state.formManager} isNew={this.props.isNew} isReadOnly={this.props.isReadOnly} />
            </TabPanel>,
            <TabPanel>
                <BrandLogo data={this.props.row} formManager={this.state.formManager} isNew={this.props.isNew} isReadOnly={this.props.isReadOnly} />
            </TabPanel>
        ];

        return (
            <Tabs>
                <TabList>
                    <Tab>Details</Tab>
                    <Tab>Logo</Tab>
                </TabList>
                {tabContent}
            </Tabs>
        )
    }
}

export default Brand;