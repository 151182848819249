
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';
import MultiSelectComponent from '../../../../components/form/multiSelectComponent.js';
const FormManager = require('../../../../utils/view/formManager.js');

const constants = require('../../../../utils/domain/constants');
const viewUtil = require('../../../../utils/view/viewUtil');
const stringUtil = require('../../../../utils/string/stringUtil');
const validator = require('../../../../utils/validator/validator');

const stationState = require('../stationState');
const stationUtil = require('../stationUtil');

export default class KOTConfiguration extends Component 
{
    constructor(props)
    {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.configuration;
        this.formManager.view = this;

        this.onStorefrontsSelectionChanged = this.onStorefrontsSelectionChanged.bind(this);
        this.getSelectedStorefronts = this.getSelectedStorefronts.bind(this);
        this.onDispatchTypeSelectionChanged = this.onDispatchTypeSelectionChanged.bind(this);
        this.getSelectedDispatchTypes = this.getSelectedDispatchTypes.bind(this);

    }


    render()
    {
        return (<Card>
            <CardHeader>
                {this.renderToolbar()}
            </CardHeader>
            <CardBody>
                <div style={{ minHeight: window.innerHeight * 0.50 }}>
                    {this.renderOptions()}
                </div>
            </CardBody>
        </Card>
        );
    }

    renderToolbar()
    {
        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{stationState.currentStationViewModel.shell.name} - KOT Configuration</div>
                    </td>
                    <td align="right">
                        {
                            !this.props.isReadOnly &&
                            <>
                                <Button color="danger" className="btn-danger" style={{ marginRight: '30px' }} onClick={this.onDelete}>
                                    Delete KOT Configuration
                                </Button>

                                <Button color="success" className="btn-success" style={{ marginRight: '5px' }} onClick={this.onSave}>
                                    Save
                                </Button>
                            </>
                        }
                        <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={() => { this.props.onClose() }}>
                            {this.props.isReadOnly ? "Close" : "Cancel"}
                        </Button>
                    </td>
                </tr>
            </table>
        );
    }

    onStorefrontsSelectionChanged(selectedOptions)
    {
        let storefronts = "";

        if (selectedOptions.length > 0)
        {
            storefronts = selectedOptions.map(option => option.id).join();  // comma separated storefronts
        }

        this.formManager.viewModel.storefronts = storefronts;

        this.setState({});
    }

    onDispatchTypeSelectionChanged(selectedOptions)
    {
        let dispatchTypes = "";

        if (selectedOptions.length > 0)
        {
            dispatchTypes = selectedOptions.map(option => option.dispatchType).join();  // comma separated dispatch types
        }

        this.formManager.viewModel.dispatchTypes = dispatchTypes;

        this.setState({});
    }

    getSelectedStorefronts()
    {
        const selectedStorefronts = [];

        if (!stringUtil.isStringNullOrEmpty(this.formManager.viewModel.storefronts))
        {
            const storefronts = this.props.storefronts;                           
            const storefrontsTokens = this.formManager.viewModel.storefronts.split(",");     

            storefrontsTokens.forEach(token => 
            {
                const storefront = storefronts.find(storefront => storefront.id == token);

                if (storefront != null)
                {
                    selectedStorefronts.push(storefront);
                }
            });
        }

        return selectedStorefronts;
    }

    getSelectedDispatchTypes()
    {
        const selectedDispatchTypes = [];

        if (!stringUtil.isStringNullOrEmpty(this.formManager.viewModel.dispatchTypes))
        {
            const dispatchTypes = constants.getDispatchTypes();                           // dispatch types lookup data
            const dispatchTypeTokens = this.formManager.viewModel.dispatchTypes.split(",");     // dispatch type tokens

            dispatchTypeTokens.forEach(token => 
            {
                const dispatchType = dispatchTypes.find(dispatchType => dispatchType.dispatchType == token);

                if (dispatchType != null)
                {
                    selectedDispatchTypes.push(dispatchType);
                }
            });
        }

        return selectedDispatchTypes;
    }

    renderOptions()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>
                    <tr>
                        <td >
                            <SelectComponent
                                optionValues={this.props.kitchenStationProfiles}
                                optionFieldName="id"
                                optionDisplayFieldName="stationName"
                                caption="Choose Kitchen Station Profile"
                                fieldName="kitchenStation"
                                hintText="Select kitchen station profile"
                                clearable={true}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.formManager}
                                style={{ marginTop: '30px' }} />
                        </td>
                        <td>
                            <SelectComponent
                                optionValues={this.props.receiptPrinters}
                                optionFieldName="name"
                                optionDisplayFieldName="name"
                                caption="Choose Printer"
                                fieldName="receiptPrinter"
                                hintText="Select the printer"
                                clearable={true}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.formManager}
                                style={{ marginTop: '30px' }} />

                        </td>
                    </tr>

                    <tr>
                        <td>
                            <MultiSelectComponent
                                caption="Dispatch Types"
                                valueFieldName="dispatchType"
                                displayFieldName="dispatchType"
                                options={constants.getDispatchTypes()}
                                selectedOptions={this.getSelectedDispatchTypes()}
                                onSelectionChanged={this.onDispatchTypeSelectionChanged}
                                hintText="Select dispatch types for this KOT"
                                style={{ marginTop: '30px' }} />
                        </td>
                        <td>
                            <MultiSelectComponent
                               caption="Storefronts"
                               valueFieldName="id"
                               displayFieldName="name"
                               options={this.props.storefronts}
                               selectedOptions={this.getSelectedStorefronts()}
                               onSelectionChanged={this.onStorefrontsSelectionChanged}
                               hintText="Select storefronts for this KOT"
                               style={{ marginTop: '30px' }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Number Of Copies"
                                fieldName="copiesToPrint"
                                placeholder="Enter number of copies"
                                hintText="Enter number of copies"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager}
                                style={{ marginTop: '30px' }} />
                        </td>
                    </tr>
                </tbody>

            </ table>

        </div>
    }

    async onSave()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.configuration.kitchenStation))
        {
            viewUtil.showErrorAlert("Kitchen Station cannot be left empty");
            return;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.configuration.receiptPrinter))
        {
            viewUtil.showErrorAlert("Receipt Printer cannot be left empty");
            return;
        }

        if (stringUtil.isStringNullOrEmpty(this.props.configuration.copiesToPrint) || !validator.isInteger(this.props.configuration.copiesToPrint))
        {
            viewUtil.showErrorAlert("Number of copies is not valid");
            return;
        }

        var lastError = null;

        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            const shell = stationState.currentStationViewModel.shell;
            var hardwareTerminal = stationState.currentStationViewModel.terminals.find(t => t.type == constants.TerminalTypes.hardware);
            if (hardwareTerminal == null)
            {
                hardwareTerminal = stationUtil.createNewHardwareTerminal(stationState.currentStationViewModel);
                await stationUtil.addUpdateTerminal(hardwareTerminal);
                await stationUtil.addUpdateShell(shell);
            }

            var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
            if (hardwareTerminalIntegrations == null)
                hardwareTerminalIntegrations = [];

            hardwareTerminalIntegrations = hardwareTerminalIntegrations.filter(k => k.id != this.props.configuration.id);

            var integration = stationUtil.createNewIntegration(hardwareTerminal, this.props.configuration.integrationType , this.props.configuration.externalEntity);
            integration.externalEntity = this.props.receiptPrinters.find(p => p.name === this.props.configuration.receiptPrinter).integrationType;
            integration.jsonArray.push({key: 'kitchenStation', value: this.props.configuration.kitchenStation});
            integration.jsonArray.push({key: 'receiptPrinter', value: this.props.configuration.receiptPrinter});
            integration.jsonArray.push({key: 'copiesToPrint', value: this.props.configuration.copiesToPrint});
            integration.jsonArray.push({key: 'storefronts', value: this.props.configuration.storefronts});
            integration.jsonArray.push({key: 'dispatchTypes', value: this.props.configuration.dispatchTypes});
            

            hardwareTerminalIntegrations.push(integration);
            stationUtil.updateTerminalIntegrations(hardwareTerminal, hardwareTerminalIntegrations);
            await stationState.loadIntegrations();

        }
        catch (error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }


    async onDelete()
    {
        var hardwareTerminal = stationState.currentStationViewModel.terminals.find (t => t.type == constants.TerminalTypes.hardware);
        if (hardwareTerminal == null)
        {
            this.props.onClose();
            return;
        }

        var hardwareTerminalIntegrations = stationState.integrations.get(hardwareTerminal.id);
        if (hardwareTerminalIntegrations == null || hardwareTerminalIntegrations.length <= 0)
        {
            this.props.onClose();
            return;
        }

        if (hardwareTerminalIntegrations.find(h => stringUtil.areStringSame(h.id, this.props.configuration.id)) == null)
        {
            this.props.onClose();
            return;
        }

        var lastError = null;
        try
        {
            viewUtil.showSystemModalSpinner("Please wait");

            hardwareTerminalIntegrations = hardwareTerminalIntegrations.filter(k => k.id != this.props.configuration.id);
            stationUtil.updateTerminalIntegrations(hardwareTerminal, hardwareTerminalIntegrations);
            await stationState.loadIntegrations();

        }
        catch(error)
        {
            lastError = error;
        }
        finally
        {
            viewUtil.closeModalSpinner();
            if (lastError)
                viewUtil.showErrorAlert(lastError);
            else
                this.props.onClose();
        }
    }
}