
import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Col, Row } from 'reactstrap';

import HintCard from '../../../../components/hint/hintCard.js'
import PreviousNextDateRangeHeader from '../../../../components/header/previousNextDateRangeHeader';

import PayrollRunDetails from './payrollRun/details/PayrollRunDetails';
import PayrollRunSummary from './payrollRun/summary/PayrollRunSummary';
import EditPayrollRun from './payrollRun/EditPayrollRun';

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const stringUtil = require('../../../../utils/string/stringUtil');
const payrollRunUtils = require('./utils/payrollRunUtils');
const payrollCalculatorUtils = require('./utils/payrollCalculatorUtils');
const viewUtil = require('../../../../utils/view/viewUtil');



class PayrollRun extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            loading: true,
            remoteDataError: null,
            data: null,
            staffLoaded: false,
            staff: []
        }

        this.loadPayrollRunForCurrentPeriod = this.loadPayrollRunForCurrentPeriod.bind(this);
        this.loadPayrollRun = this.loadPayrollRun.bind(this);

        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onNextClicked = this.onNextClicked.bind(this);
        this.onPreviousClicked = this.onPreviousClicked.bind(this);
        this.loadStaff = this.loadStaff.bind(this);
    }

    async componentDidMount()
    {
        return this.loadPayrollRunForCurrentPeriod();
    }

    render() 
    {
        if (this.state.loading)
            return null;

        if (this.state.remoteDataError != null)
            return this.renderError();

        return <div>
            {this.renderPayrollDateRange()}
            <HintCard isDefaultClosed={true}>
                <div>
                    <p>Payroll Run provides staff related costs for the payroll period. The costs include wages, taxes and pension.
                        <ul>
                            <li>Summary display total cost for all the staff members.</li>
                            <li>Details represented a line item for each staff member.</li>
                            <li>Double click on the details row to update the staff records</li>
                        </ul>
                    </p>

                    <p>Formula for the calculations
                        <ul>
                            <li><strong>Actual Wage</strong> = Attendance Hour x Hourly Wage</li>
                            <li><strong>Gross Wage</strong> = Actual Wage + Bonus + Holiday Pay - Deduction</li>
                            <li><strong>Net Wage</strong> = Gross Wage - Employee NI Contribution - Employee Pension Contribution - PAYE</li>
                            <li><strong>Net Wage To Pay</strong> = Net Wage - Advance Payment</li>
                            <li><strong>Total NI Contribution</strong> = Employee NI Contribution + Employer NI Contribution</li>
                            <li><strong>Net NI Payment</strong> = Total NI Contribution - Employer NI Allowance</li>
                            <li><strong>Total HMRC Payment</strong> = Net NI Payment + Total PAYE</li>
                            <li><strong>Total Pension Contribution</strong> = Employee Pension Contribution + Employer Pension Contribution </li>
                            <li><strong>Total Payroll Cost</strong> = Net Wage + Total HMRC Payment + Total Pension Payment</li>
                        </ul>
                    </p>
                </div>

            </HintCard>
            {this.renderEditPayroll()}
            {this.renderData()}
        </div>
    }


    renderError()
    {
        return (<div>
            <Row className="justify-content-center" style={{ margin: '5px' }}>
                <h5 className="text-muted">{this.state.remoteDataError}</h5>
            </Row>
        </div>);
    }

    renderEditPayroll()
    {
        return <EditPayrollRun data={this.state.data}
            onChange={() =>
            {
                payrollCalculatorUtils.updateCalculatedFields(this.state.data);
                this.setState({});
            }}
        ></EditPayrollRun>;
    }

    renderData()
    {
        if (this.state.data == null)
        {
            return <h1>No payroll data available</h1>
        }

        var tabHeaders = [];
        tabHeaders.push(<Tab>Summary</Tab>)
        tabHeaders.push(<Tab>Payroll</Tab>)

        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}><PayrollRunSummary data={this.state.data}></PayrollRunSummary></TabPanel>)
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}><PayrollRunDetails data={this.state.data}></PayrollRunDetails></TabPanel>);

        return <Tabs style={{ marginTop: "15px" }}>
            <TabList>
                {tabHeaders}
            </TabList>

            {tabContents}
        </Tabs>;
    }

    renderPayrollDateRange()
    {
        return <PreviousNextDateRangeHeader
            data={this.state.data}
            isNew={this.state.data?.isGenerated === true}
            onNextClicked={this.onNextClicked}
            onPreviousClicked={this.onPreviousClicked}
            onSaveClicked={this.onSaveClicked}
            isNextEnabled={() => (this.state.data == null) ? false : (this.state.data.nextFromDate != null && this.state.data.nextToDate != null )}
            isPreviousEnabled={() => (this.state.data == null) ? false : (this.state.data.previousFromDate != null && this.state.data.previousToDate != null )}
            isReadOnly={this.props.isReadOnly}
        >
        </PreviousNextDateRangeHeader>
    }

    async loadPayrollRunForCurrentPeriod()
    {
        if(!this.state.staffLoaded)
            await this.loadStaff();

        var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/payroll?run=current`;

        return this.loadPayrollRun(resourceUrl);
    }

    onNextClicked()
    {
        var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/payroll?run=next&lastEndDate=${this.state.data.toDate}`;        

        return this.loadPayrollRun(resourceUrl);
    }

    onPreviousClicked()
    {
        var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/payroll?run=previous&lastStartDate=${this.state.data.fromDate}`;        

        return this.loadPayrollRun(resourceUrl);
    }

    async loadStaff()
    {        
        try {

            viewUtil.showSystemModalSpinner("Loading payroll run");

            const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/hr/staff`;

            this.state.staff = await rmsApiProxy.get(resourceUrl);
            this.state.staffLoaded = true;
            viewUtil.closeModalSpinner();

        } catch (error) {
            // 
            this.state.loading = false;
            this.state.remoteDataError = error;
            this.state.staffLoaded = false;
            viewUtil.closeModalSpinner();
            viewUtil.showCriticalAlert(`Payroll run load failed: ${error}`);
            this.setState({});
        }
    }


    //Get payroll staff data 
    async loadPayrollRun(resourceUrl)
    {
        try 
        {
            viewUtil.showSystemModalSpinner("Loading payroll run");

            this.setState({ loading: true});

            const data = await rmsApiProxy.get(resourceUrl);

            payrollRunUtils.enrichViewModel(data, this.state.staff);
            payrollCalculatorUtils.updateCalculatedFields(data);
            viewUtil.closeModalSpinner();
            this.setState({ loading: false, data });
        }
        catch (error) 
        {
            this.state.loading = false;
            this.state.remoteDataError = error;
            viewUtil.closeModalSpinner();
            viewUtil.showCriticalAlert(`Payroll run load failed: ${error}`);
            this.setState({});
        }
    }

    async onSaveClicked()
    {
        try
        {
            //Validate before saving
            let error = payrollRunUtils.validate(this.state.data);
            if (error)
            {
                viewUtil.showErrorAlert(error);
                return;
            }

            //Show saving progress
            viewUtil.showSystemModalSpinner("Saving payroll run");

            //Post the data 
            this.state.data = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/hr/payroll`, this.state.data);

            //Closing saving progress
            viewUtil.closeModalSpinner();

            //Show success
            viewUtil.showSuccessAlert("Payroll run saved successfully");

            payrollRunUtils.enrichViewModel(this.state.data, this.state.staff);

            this.setState({})
        }
        catch (error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showCriticalAlert(`Payroll run save failed: ${error}`);
            this.setState({});
        }
    }
}

export default PayrollRun