
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import SelectComponent from '../../../../../components/form/selectComponent';
import InputComponent from '../../../../../components/form/inputComponent';
import CheckComponent from '../../../../../components/form/checkComponent';

const arraySort = require('array-sort');

const FormManager = require('../../../../../utils/view/formManager');
const stringUtil = require('../../../../../utils/string/stringUtil');
const unitUtil = require('../../../../../utils/domain/unitUtil');
const centralKitchenUtils = require('../../../../setup/centralKitchen/utils/centralKitchenUtils');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

class StockItemCentralKitchenUnitType extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            formManager: new FormManager()
        }

        this.state.formManager.viewModel = this.props.unitTypes;
        this.state.formManager.view = this;
        
        this.state.isExistingData = this.props.unitTypes.unitTypeId !== undefined;

        this.state.formManager.onFieldChanged = (event =>
        {
            if (event != null && event.target != null)
            {
                if (event.target.id === "pluCode")
                {
                    let itemPrice = centralKitchenUtils.getCentralKitchenStockItemPrice(this.state.formManager.viewModel.pluCode, this.getEffectiveSupplierStockItems());                    

                    this.state.formManager.viewModel.price = itemPrice.price;
                    this.state.formManager.viewModel.vat = itemPrice.vat;
                    this.setState({})
                }
            }
        })
    }

    render()
    {
        const vatCaption = localizationUtils.getVatCaption();
        let units = unitUtil
            .getPossibleUnitsForStockItem(this.props.stockItem)
            .filter(unit => !unit.isSupplementalUnit);

        units = arraySort(units, stringUtil.localeCompareProp("code"));

        let menuItems = [];

        this.getEffectiveSupplierStockItems().forEach(item => 
        {
            if(item.price != null && item.vat != null)
            {
                menuItems.push(item);
            }
        });        


        menuItems = arraySort(menuItems, stringUtil.localeCompareProp("pluCode"));

        return <Row className="align-items-center">
            <Col lg="2">
                <SelectComponent
                    optionValues={units}
                    optionFieldName="id"
                    optionDisplayFieldName="code"
                    caption="Unit"
                    fieldName="unitTypeId"
                    hintText="Select Supply Unit"
                    clearable={true}
                    comboReadOnly={this.props.isRowReadOnly || this.state.isExistingData || this.state.formManager.viewModel.isDisabled}
                    formManager={this.state.formManager} />
            </Col>
            <Col lg="2">
                <SelectComponent
                    optionValues={menuItems}
                    optionFieldName="pluCode"
                    optionDisplayFieldName="pluCode"
                    fieldName="pluCode"
                    caption="PLU Code"
                    hintText="Supplier's PLU Code"
                    clearable={false}
                    comboReadOnly={this.props.isRowReadOnly || this.state.formManager.viewModel.isDisabled}
                    formManager={this.state.formManager} />                                    
            </Col>
            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={true}
                    inputType="money"
                    fieldName="price"
                    caption="Price"
                    placeholder=""
                    hintText="Supplier's Price"
                />
            </Col>
            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={true}
                    inputType="money"
                    fieldName="vat"
                    caption={vatCaption}
                    placeholder=""
                    hintText={`Enter ${vatCaption}`}
                />
            </Col>

            <Col lg="2">
                <InputComponent
                    formManager={this.state.formManager}
                    inputReadOnly={this.props.isRowReadOnly  || this.state.formManager.viewModel.isDisabled}
                    inputType="number"
                    fieldName="minimumOrderQuantity"
                    caption="Min Order Qty"
                    placeholder=""
                    hintText="Enter Min Order Qty."
                />
            </Col>

            
            <Col>
                <CheckComponent
                    caption="Disable"
                    fieldName="isDisabled"
                    inputReadOnly={this.props.isRowReadOnly}
                    formManager={this.state.formManager} />
            </Col>
        </Row>
    }

    getEffectiveSupplierStockItems()
    {
        return this.props.lookupData.centralKitchensLookupData.find(kitchen => kitchen.id == this.props.data.supplierId).effectiveSupplierStockItems;
    }
}

export default StockItemCentralKitchenUnitType;