import React, { Component } from 'react';

import OpeningHour from './OpeningHour';

class DayOpeningHours extends Component
{
    render()
    {
        const { day, dispatchTypes, openingHoursData } = this.props;

        return (
            <div className='d-flex'>

                <div className='text-muted' style={{ fontSize: '1.15em', width: '75px' }}>{day.slice(0, 3)}</div>

                <div className='w-100 d-flex text-center'>

                    {
                        Object.keys(dispatchTypes).map(dispatchType =>
                            dispatchTypes[dispatchType] &&
                            <div key={dispatchType} className='w-50 px-4'>
                                <OpeningHour day={day} dispatchType={dispatchType} openingHoursData={openingHoursData} />
                            </div>
                        )
                    }

                </div>
            </div>
        );
    }
}

export default DayOpeningHours;