const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const constants = require('../../../../../utils/domain/constants');
const stringUtil = require('../../../../../utils/string/stringUtil');

module.exports.save = function(storefront, storefrontWebAppConfigurations)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/webAppConfigurations`;
    return rmsApiProxy.post(resourceUrl, storefrontWebAppConfigurations);
}

module.exports.loadStorefrontWebAppConfigurations = function(storefront)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/webAppConfigurations`;
    return rmsApiProxy.get(resourceUrl);
}

module.exports.validateWebAppConfigurations = function(webAppConfiguration)
{
    if(stringUtil.isStringNullOrEmpty(webAppConfiguration.applicationId))
        return 'Application Id cannot be left empty!';
    
    if(stringUtil.isStringNullOrEmpty(webAppConfiguration.isEnabled))
        return 'Is Enabled cannot be left empty!';
        
    if(webAppConfiguration.dineInPaymentMethods?.some(method => stringUtil.areStringSame(method, constants.paymentMethods.Credit))
        || webAppConfiguration.collectionPaymentMethods?.some(method => stringUtil.areStringSame(method, constants.paymentMethods.Credit))
        || webAppConfiguration.deliveryPaymentMethods?.some(method => stringUtil.areStringSame(method, constants.paymentMethods.Credit)))
    {
        if(stringUtil.isStringNullOrEmpty(webAppConfiguration.paymentProvider))
            return 'Payment Provider cannot be left empty when credit payment method is selected!';

        if(stringUtil.areStringSame(webAppConfiguration.paymentProvider, constants.webAppOnlinePaymentProviders.Stripe))
        {
            if(stringUtil.isStringNullOrEmpty(webAppConfiguration.publishableKey))
                return 'Publishable Key cannot be left empty!';

            if(stringUtil.isStringNullOrEmpty(webAppConfiguration.clientSecret))
                return 'Client Secret cannot be left empty!';
        }
    }

    if(stringUtil.isStringNullOrEmpty(webAppConfiguration.mode))
    {
        return 'Mode cannot be left empty!';
    }
    
    return null;
}