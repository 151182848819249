
import React, { Component } from 'react';
import styled from 'styled-components';

import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';

var dateUtil = require('../../utils/dateUtil/dateUtil.js');
const viewUtil = require('../../utils/view/viewUtil.js');

const StyledWrapper = styled.div`
                                .DateInput {
                                    width: 110px;
                                }
                            `;


class DateRangeComponent extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {};

        this.state.startDate = null;
        this.state.endDate = null;

        // if (this.props.startDate && dateUtil.isValidDate(this.props.startDate))
        //     this.state.startDate = moment(dateUtil.convertToLocalStandard(this.props.startDate), dateUtil.getDateFormat());

        // if (this.props.endDate && dateUtil.isValidDate(this.props.endDate))
        //     this.state.endDate = moment(dateUtil.convertToLocalStandard(this.props.endDate), dateUtil.getDateFormat());
    }

    //#region 2020-05-18: New life cycle method added

    //
    // Code commented above and replaced with this new method
    //

    static getDerivedStateFromProps(props, state)
    {
        let startDate = null;
        let endDate = null;

        if (props.startDate && dateUtil.isValidDate(props.startDate))
            startDate = moment(dateUtil.convertToLocalStandard(props.startDate), dateUtil.getDateFormat());

        if (props.endDate && dateUtil.isValidDate(props.endDate))
            endDate = moment(dateUtil.convertToLocalStandard(props.endDate), dateUtil.getDateFormat());

        return { startDate, endDate };
    }

    //#endregion

    render()
    {
        return (
            <StyledWrapper>
                <DateRangePicker
                    showClearDates
                    small
                    showDefaultInputIcon
                    disabled={this.props.disabled}
                    startDate={this.state.startDate}
                    startDateId="startDate"
                    endDate={this.state.endDate}
                    endDateId="endDate"
                    minimumNights={0}
                    displayFormat="DD MMM YYYY "
                    firstDayOfWeek={1}
                    onDatesChange={({ startDate, endDate }) => 
                    {
                        if(this.props.maxDays && this.state.startDate && endDate) {
                            const differenceInDays = Math.abs(dateUtil.differenceInDays(this.state.startDate.format(dateUtil.getDateFormat()), endDate.format(dateUtil.getDateFormat())));
                            
                            if(differenceInDays > (this.props.maxDays - 1)) {
                                viewUtil.showAlert('info', `Selected date range exceeds the maximum allowable limit of ${this.props.maxDays} days. Please choose a shorter date range.`)
                                return;
                            }
                        }

                        this.state.startDate = startDate;
                        this.state.endDate = endDate;

                        if (this.props.onDateRangeChange)
                        {
                            var utcStartDate = this.state.startDate;
                            if (utcStartDate)
                            {
                                utcStartDate = utcStartDate.format(dateUtil.getDateFormat());
                                utcStartDate = dateUtil.convertToUTCStandard(utcStartDate);
                            }

                            var utcEndDate = this.state.endDate;
                            if (utcEndDate)
                            {
                                utcEndDate = utcEndDate.format(dateUtil.getDateFormat());
                                utcEndDate = dateUtil.convertToUTCStandard(utcEndDate);
                            }

                            this.props.onDateRangeChange(utcStartDate, utcEndDate);
                        }


                        this.setState({});
                    }
                    }
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}

                    isOutsideRange={() => false} />
            </StyledWrapper>
        );
    }
}


export default DateRangeComponent;