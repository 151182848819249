
const terminalTypeConstants = require('../../../utils/constants/terminalTypeConstants');
const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const domainConstants = require('../../../utils/domain/constants');
const guidUtil = require('../../../utils/guid/guidUtil');
const currentOrgNodeSelectors = require('../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const stringUtil = require('../../../utils/string/stringUtil');
const validator = require('../../../utils/validator/validator');

export async function loadStorefronts()
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts`;
    let storefronts = await rmsApiProxy.get(resourceUrl);

    if (storefronts == null)
        storefronts = [];

    return storefronts;
}

export async function loadSignagePlayers()
{
    var signageUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs?type=${domainConstants.blogTypes.SignagePlayers}`;
    var signagePlayerBlobs = await rmsApiProxy.get(signageUrl);

    var signagePlayers = [];
    signagePlayerBlobs.forEach(signageBlob =>
    {
        signagePlayers.push(JSON.parse(signageBlob.document))
    });

    return signagePlayers;
}

export async function loadKioskSkins()
{
    var skinURL = `${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs?type=${domainConstants.blogTypes.KioskSkins}`;
    var skinBlobs = await rmsApiProxy.get(skinURL);

    var kioskSkins = [];
    skinBlobs.forEach(skinBlob =>
    {
        kioskSkins.push(JSON.parse(skinBlob.document))
    });

    return kioskSkins;
}

export async function loadShells()
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        throw new Error("Property selection is required to generate stations menu");
    }

    const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();
    let resourceUrl = `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/shells`;
    var shells = await rmsApiProxy.get(resourceUrl);

    if (shells == null)
        shells = [];

    return shells;
}

export async function loadTerminals()
{
    if (!currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        throw new Error("Property selection is required to generate stations menu");
    }

    const { franchisorId, franchiseeId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();
    var resourceUrl = `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/terminals`;
    var terminals = await rmsApiProxy.get(resourceUrl);

    if (terminals == null)
        terminals = [];

    terminals.forEach(terminal =>
        {
            if (terminal.type == terminalTypeConstants.pos)
            {
                if(terminal.profile.settings.cardSettings == null)
                {
                    terminal.profile.settings.cardSettings = {};
                    terminal.profile.settings.cardSettings.provider = terminal.profile.settings.cardProviderName;
                }
            }
        })

    return terminals;
}


export async function loadIntegrations(stationViewModel)
{
    var map = new Map();

    for (var index = 0; index < stationViewModel.terminals.length; index++)
    {
        let terminal = stationViewModel.terminals[index];
        
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/terminals/${terminal.id}/integrations`;
        let terminalIntegrations = await rmsApiProxy.get(resourceUrl);
        if (terminalIntegrations == null)
            terminalIntegrations = [];
        
        map.set(terminal.id, terminalIntegrations);
    }

    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/shells/${stationViewModel.shell.licenseKey}/integrations`;
    let shellIntegrations = await rmsApiProxy.get(resourceUrl);

    if (shellIntegrations == null)
        shellIntegrations = [];
    
    map.set(stationViewModel.shell.licenseKey, shellIntegrations);
    return map;
}



export async function loadReceiptPrinters()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/terminals/printers/receipt`;
    var receiptPrinters = await rmsApiProxy.get(resourceUrl);
    if (receiptPrinters == null)
        receiptPrinters = [];
        
    return receiptPrinters;
}

export async function loadLabelPrinters()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/terminals/printers/label`;
    var labelPrinters = await rmsApiProxy.get(resourceUrl);
    if (labelPrinters == null)
        labelPrinters = [];

    return labelPrinters;
}


export function generateGeneralSettingsModel(stationViewModel)
{
    var model = {};

    model.code = stationViewModel.shell.code;
    model.name = stationViewModel.shell.name;
    model.licenseKey = stationViewModel.shell.licenseKey;
    model.isEnabled = stationViewModel.shell.isEnabled;

    model.stationType = stationViewModel.stationType;
    model.shellType = stationViewModel.shell.shellType;
    model.shellRenderingMode = stationViewModel.shell.shellRenderingMode;
    model.shellScreenOrientation = stationViewModel.shell.shellScreenOrientation;

    model.machineIPAddress = stationViewModel.shell.machineIPAddress;
    model.machineId = stationViewModel.shell.machineId;
    model.operatingSystem = stationViewModel.shell.operatingSystem;
    model.operatingSystemVersion = stationViewModel.shell.operatingSystemVersion;
    
    return model;
}



export function generalTerminalSettingsModel(stationType, stationViewModel)
{
    if (stationType == terminalTypeConstants.pos)
        return generatePOSSettingsModel(stationViewModel);

    if (stationType == terminalTypeConstants.kiosk)
        return generateKIOSKSettingsModel(stationViewModel);

    if (stationType == terminalTypeConstants.kds)
        return generateKDSSettingsModel(stationViewModel);

    if (stationType == terminalTypeConstants.kitchen)
        return generateKitchenSettingsModel(stationViewModel);

    if (stationType == terminalTypeConstants.centralKitchenKds)
        return generateCentralKitchenSettingsModel(stationViewModel);

    if (stationType == terminalTypeConstants.signage)
        return generateSignageSettingsModel(stationViewModel);
}

function generatePOSSettingsModel(stationViewModel)
{
    var terminal = stationViewModel.terminals.find (t => t.type == terminalTypeConstants.pos);

    if (terminal == null)
    {
        terminal = {};
        terminal.isExisting = false;
        terminal.code = `${stationViewModel.shell.code}.pos`;
        terminal.enabled = true;
        terminal.id = guidUtil.generateGuid();
        terminal.storefrontId = null;
        terminal.type = terminalTypeConstants.pos;
        terminal.userName = `${stationViewModel.shell.code}.user`;
        terminal.floatIdentifier = null;
        
        terminal.profile = {};

        terminal.profile.settings = {};
        terminal.profile.settings.cardSettings = {};
        terminal.profile.settings.cardSettings.provider = null;

        terminal.profile.settings.displaySettings = {};
        terminal.profile.settings.displaySettings.signagePlayerId = null;

        stationViewModel.terminals.push(terminal);
    }

    var profile = terminal.profile;
    if (profile == null) profile = {};
    var settings = profile.settings;
    if (settings == null) settings = {};

    if (settings.displaySettings == null) settings.displaySettings = {};

    var model = {};
    model.terminal = terminal;
    model.code = terminal.code;
    model.id = terminal.id;
    model.enabled = true;
    model.type = terminal.type;
    model.userName = terminal.userName;

    model.storeFrontId = terminal.storefrontId;
    model.floatIdentifier = terminal.floatIdentifier;
    
    model.cardProviderName = settings.cardSettings.provider;

    model.signagePlayerId = settings.displaySettings.signagePlayerId;

    return model;

}

function generateKIOSKSettingsModel(stationViewModel)
{
    var terminal = stationViewModel.terminals.find (t => t.type == terminalTypeConstants.kiosk);
    if (terminal == null)
    {
        terminal = {};
        terminal.isExisting = false;
        terminal.code = `${stationViewModel.shell.code}.kiosk`;
        terminal.enabled = true;
        terminal.id = guidUtil.generateGuid();
        terminal.storefrontId = null;
        terminal.type = terminalTypeConstants.kiosk;
        terminal.userName = `${stationViewModel.shell.code}.user`;
        

        terminal.profile = {};

        terminal.profile.settings = {};
        terminal.profile.settings.cardProviderName = null;
        
        terminal.profile.settings.displaySettings = {};
        terminal.profile.settings.displaySettings.skinId = null;

        terminal.profile.settings.receiptPrinter = {};
        terminal.profile.settings.receiptPrinter.url = "";
        stationViewModel.terminals.push(terminal);
    }

    if (terminal == null) terminal = {};
    var profile = terminal.profile;
    if (profile == null) profile = {};
    var settings = profile.settings;
    if (settings == null) settings = {};

    if (settings.displaySettings == null) settings.displaySettings = {};
    if (settings.receiptPrinter == null) settings.receiptPrinter = {};

    var model = {};
    model.terminal = terminal;
    model.code = terminal.code;
    model.id = terminal.id;
    model.enabled = true;
    model.type = terminal.type;
    model.userName = terminal.userName;

    model.storeFrontId = terminal.storefrontId;
    
    model.cardProviderName = settings.cardProviderName;
    model.skinId = settings.displaySettings.skinId;
    model.receiptPrinter = settings.receiptPrinter.url;

    return model;
}

function generateKDSSettingsModel(stationViewModel)
{
    var terminal = stationViewModel.terminals.find (t => t.type == terminalTypeConstants.kds);
    if (terminal == null)
    {
        terminal = {};
        terminal.isExisting = false;
        terminal.code = `${stationViewModel.shell.code}.kds`;
        terminal.enabled = true;
        terminal.id = guidUtil.generateGuid();
        terminal.type = terminalTypeConstants.kds;
        terminal.userName = `${stationViewModel.shell.code}.user`;
        

        terminal.profile = {};

        terminal.profile.settings = {};
        terminal.profile.settings.defaultKitchenStation = null;
        terminal.profile.settings.fohOrderPrintFormat = "headerOnly";
        terminal.profile.settings.fohPrinter = null;
        terminal.profile.settings.kitchenStations = [];
        terminal.profile.settings.storefronts = [];
        
        terminal.profile.settings.screenSettings = {};
        terminal.profile.settings.screenSettings.hideCustomerDetails = false;
        terminal.profile.settings.screenSettings.hideDeliveryDetails = false;
        terminal.profile.settings.screenSettings.hideFreebiesDetails = false;
        terminal.profile.settings.screenSettings.hideGuestDetails = false;
        terminal.profile.settings.screenSettings.hideStaffDetails = false;
        terminal.profile.settings.screenSettings.maximumItemsInSaleCard = null;
        terminal.profile.settings.screenSettings.minimumModifierWidth = null;
        terminal.profile.settings.screenSettings.saleCardRightMargin = null;
        terminal.profile.settings.screenSettings.saleCardWidth = null;
        terminal.profile.settings.screenSettings.saleCardWidthWithMultipleColumns = null;
        terminal.profile.settings.screenSettings.saleFontSize = null;

        stationViewModel.terminals.push(terminal);
    }

    if (terminal == null) terminal = {};
    var profile = terminal.profile;
    if (profile == null) profile = {};
    var settings = profile.settings;
    if (settings == null) settings = {};

    if (settings.kitchenStations == null) settings.kitchenStations = [];
    if (settings.screenSettings == null) settings.screenSettings = {};
    if (settings.storefronts == null) settings.storefronts = [];

    var model = {};
    model.terminal = terminal;
    model.code = terminal.code;
    model.id = terminal.id;
    model.enabled = true;
    model.type = terminal.type;
    model.userName = terminal.userName;

    model.defaultKitchenStation = settings.defaultKitchenStation;
    model.fohOrderPrintFormat = settings.fohOrderPrintFormat;
    model.fohPrinter = settings.fohPrinter;
    model.kitchenStations = settings.kitchenStations;
    model.storefronts = settings.storefronts;

    model.hideCustomerDetails = settings.screenSettings.hideCustomerDetails;
    model.hideDeliveryDetails = settings.screenSettings.hideDeliveryDetails;
    model.hideFreebiesDetails = settings.screenSettings.hideFreebiesDetails;
    model.hideGuestDetails = settings.screenSettings.hideGuestDetails;
    model.hideStaffDetails = settings.screenSettings.hideStaffDetails;
    model.maximumItemsInSaleCard = settings.screenSettings.maximumItemsInSaleCard;
    model.minimumModifierWidth = settings.screenSettings.minimumModifierWidth;
    model.saleCardRightMargin = settings.screenSettings.saleCardRightMargin;
    model.saleCardWidth = settings.screenSettings.saleCardWidth;
    model.saleCardWidthWithMultipleColumns = settings.screenSettings.saleCardWidthWithMultipleColumns;
    model.saleFontSize = settings.screenSettings.saleFontSize;

    return model;
    
}

function generateKitchenSettingsModel(stationViewModel)
{
    var terminal = stationViewModel.terminals.find (t => t.type == terminalTypeConstants.kitchen);
    if (terminal == null)
    {
        terminal = {};
        terminal.isExisting = false;
        terminal.code = `${stationViewModel.shell.code}.kitchen`;
        terminal.enabled = true;
        terminal.id = guidUtil.generateGuid();
        terminal.type = terminalTypeConstants.kitchen;
        terminal.userName = `${stationViewModel.shell.code}.kitchen`;
        

        terminal.profile = {};

        terminal.profile.settings = {};
        terminal.profile.settings.labelPrinter = {};
        terminal.profile.settings.labelPrinter.url = null;

        stationViewModel.terminals.push(terminal);
    }

    if (terminal == null) terminal = {};
    var profile = terminal.profile;
    if (profile == null) profile = {};
    var settings = profile.settings;
    if (settings == null) settings = {};

    if (settings.labelPrinter == null) settings.labelPrinter = {};
    
    var model = {};
    model.terminal = terminal;
    model.code = terminal.code;
    model.id = terminal.id;
    model.enabled = true;
    model.type = terminal.type;
    model.userName = terminal.userName;

    model.labelPrinter = settings.labelPrinter.url;

    return model;
}

function generateCentralKitchenSettingsModel(stationViewModel)
{
    var terminal = stationViewModel.terminals.find (t => t.type == terminalTypeConstants.centralKitchenKds);
    if (terminal == null)
    {
        terminal = {};
        terminal.isExisting = false;
        terminal.code = `${stationViewModel.shell.code}.central.kitchen`;
        terminal.enabled = true;
        terminal.id = guidUtil.generateGuid();
        terminal.type = terminalTypeConstants.centralKitchenKds;
        terminal.userName = `${stationViewModel.shell.code}.central.kitchen`;
        

        terminal.profile = {};
        terminal.profile.settings = {};
        stationViewModel.terminals.push(terminal);
    }

    var model = {};
    model.terminal = terminal;
    model.code = terminal.code;
    model.id = terminal.id;
    model.enabled = true;
    model.type = terminal.type;
    model.userName = terminal.userName;
  
    return model;
}

function generateSignageSettingsModel(stationViewModel)
{
    var terminal = stationViewModel.terminals.find (t => t.type == terminalTypeConstants.signage);
    if (terminal == null)
    {
        terminal = {};
        terminal.isExisting = false;
        terminal.code = `${stationViewModel.shell.code}.signage`;
        terminal.enabled = true;
        terminal.id = guidUtil.generateGuid();
        terminal.type = terminalTypeConstants.signage;
        terminal.userName = `${stationViewModel.shell.code}.user`;
        

        terminal.profile = {};

        terminal.profile.settings = {};
        terminal.profile.settings.displaySettings = {};
        terminal.profile.settings.displaySettings.signagePlayerId = null;

        stationViewModel.terminals.push(terminal);
    }

    if (terminal == null) terminal = {};
    var profile = terminal.profile;
    if (profile == null) profile = {};
    var settings = profile.settings;
    if (settings == null) settings = {};

    if (settings.displaySettings == null) settings.displaySettings = {};
    
    var model = {};
    model.terminal = terminal;
    model.code = terminal.code;
    model.id = terminal.id;
    model.enabled = true;
    model.type = terminal.type;
    model.userName = terminal.userName;

    model.signagePlayerId = settings.displaySettings.signagePlayerId;

    return model;
}

export function validateGeneralSettingsModel (model)
{
    if (stringUtil.isStringNullOrEmpty(model.code))
        throw `Station code cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.name))
        throw `Station name cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.licenseKey))
        throw `Station license key cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.stationType))
        throw `Station license key cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.shellType))
        throw `Station license key cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.shellRenderingMode))
        throw `Station license key cannot be left empty`;
}

export function validateTerminalSettingsModel(model)
{
    if (model.terminal == null)
        throw "Failed to find terminal for this station";

    if (model.terminal.type == null)
        throw "Failed to find terminal type for this station";

    if (stringUtil.isStringNullOrEmpty(model.code))
        throw `Terminal code cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.id))
        throw `Terminal id cannot be left empty`;

    if (stringUtil.isStringNullOrEmpty(model.userName))
        throw `Terminal user name cannot be left empty`;

    if (model.terminal.type == terminalTypeConstants.pos)
    {
        if (stringUtil.isStringNullOrEmpty(model.storeFrontId))
            throw `Storefront cannot be left empty`;

        return;
        
    }

    if (model.terminal.type == terminalTypeConstants.kiosk)
    {
        if (stringUtil.isStringNullOrEmpty(model.storeFrontId))
            throw `Storefront cannot be left empty`;

        return;
    }

    if (model.terminal.type == terminalTypeConstants.kds)
    {
        if (stringUtil.isStringNullOrEmpty(model.fohOrderPrintFormat))
            throw `Print Format cannot be left empty`;

        if (!stringUtil.isStringNullOrEmpty(model.maximumItemsInSaleCard) && !validator.isInteger(model.maximumItemsInSaleCard))
            throw `Maximum Items In Sale Card ${model.maximumItemsInSaleCard} is not valid`;

        if (!stringUtil.isStringNullOrEmpty(model.minimumModifierWidth) && !validator.isInteger(model.minimumModifierWidth))
            throw `Minimum Modifier Width ${model.minimumModifierWidth} is not valid`;

        if (!stringUtil.isStringNullOrEmpty(model.saleCardRightMargin) && !validator.isInteger(model.saleCardRightMargin))
            throw `Sale Card Right Margin ${model.saleCardRightMargin} is not valid`;

        if (!stringUtil.isStringNullOrEmpty(model.saleCardWidth) && !validator.isInteger(model.saleCardWidth))
            throw `Sale Card Width ${model.saleCardWidth} is not valid`;

        if (!stringUtil.isStringNullOrEmpty(model.saleCardWidthWithMultipleColumns) && !validator.isInteger(model.saleCardWidthWithMultipleColumns))
            throw `Sale Card Width With Multiple Columns ${model.saleCardWidthWithMultipleColumns} is not valid`;

        if (!stringUtil.isStringNullOrEmpty(model.saleFontSize) && !validator.isInteger(model.saleFontSize))
            throw `Sale Font Size ${model.saleFontSize} is not valid`;

        return;
    }

    if (model.terminal.type == terminalTypeConstants.kitchen)
    {
        return;
    }

    if (model.terminal.type == terminalTypeConstants.centralKitchenKds)
    {
        return;
    }

    if (model.terminal.type == terminalTypeConstants.signage)
    {
        if (stringUtil.isStringNullOrEmpty(model.signagePlayerId))
            throw `Signage Player cannot be left empty`;

        return;
    }
}

export function updateShellWithModel (stationViewModel, model)
{
    var currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    stationViewModel.shell.franchisorId = currentOrgNode.franchisorId;
    stationViewModel.shell.franchiseeId = currentOrgNode.franchiseeId;
    stationViewModel.shell.propertyId = currentOrgNode.propertyId;

    stationViewModel.shell.code = model.code;
    stationViewModel.shell.name = model.name;
    stationViewModel.shell.licenseKey = model.licenseKey;
    stationViewModel.shell.isEnabled = model.isEnabled;

    stationViewModel.stationType = model.stationType;
    stationViewModel.shell.shellType = model.shellType
    stationViewModel.shell.shellRenderingMode = model.shellRenderingMode;
    stationViewModel.shell.shellScreenOrientation = model.shellScreenOrientation;

    stationViewModel.shell.machineIPAddress = model.machineIPAddress;
    stationViewModel.shell.machineId = model.machineId;
    stationViewModel.shell.operatingSystem = model.operatingSystem;
    stationViewModel.shell.operatingSystemVersion = model.operatingSystemVersion;
}

export function updateTerminalWithModel (model)
{
    var terminal = model.terminal;
    var profile = terminal.profile;
    var settings = profile.settings;

    terminal.code = model.code;
    terminal.enabled = model.enabled;
    terminal.id = model.id;
    terminal.type = model.type;
    terminal.userName = model.userName;

    var currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    terminal.franchisorId = currentOrgNode.franchisorId;
    terminal.franchiseeId = currentOrgNode.franchiseeId;
    terminal.propertyId = currentOrgNode.propertyId;

    if (terminal.type == terminalTypeConstants.pos)
    {
        terminal.storefrontId = model.storeFrontId;
      
        terminal.floatIdentifier = model.floatIdentifier;

        settings.cardSettings.provider = model.cardProviderName;
    
        settings.displaySettings.signagePlayerId = model.signagePlayerId;
    }

    if (terminal.type == terminalTypeConstants.kiosk)
    {
        terminal.storefrontId = model.storeFrontId;

        settings.cardProviderName = model.cardProviderName;
        settings.displaySettings.skinId = model.skinId;
        settings.receiptPrinter.url = model.receiptPrinter;
    }

    if (terminal.type == terminalTypeConstants.kds)
    {
        settings.defaultKitchenStation = model.defaultKitchenStation;
        settings.fohOrderPrintFormat = model.fohOrderPrintFormat;
        settings.fohPrinter = model.fohPrinter;
        settings.kitchenStations = model.kitchenStations;
        settings.storefronts = model.storefronts;

        settings.screenSettings.hideCustomerDetails = model.hideCustomerDetails;
        settings.screenSettings.hideDeliveryDetails = model.hideDeliveryDetails;
        settings.screenSettings.hideFreebiesDetails = model.hideFreebiesDetails;
        settings.screenSettings.hideGuestDetails = model.hideGuestDetails;
        settings.screenSettings.hideStaffDetails = model.hideStaffDetails;
        settings.screenSettings.maximumItemsInSaleCard = model.maximumItemsInSaleCard;
        settings.screenSettings.minimumModifierWidth = model.minimumModifierWidth;
        settings.screenSettings.saleCardRightMargin = model.saleCardRightMargin;
        settings.screenSettings.saleCardWidth = model.saleCardWidth;
        settings.screenSettings.saleCardWidthWithMultipleColumns = model.saleCardWidthWithMultipleColumns;
        settings.screenSettings.saleFontSize = model.saleFontSize;
    }

    if (terminal.type == terminalTypeConstants.kitchen)
    {
        settings.labelPrinter.url = model.labelPrinter;
    }

    if (terminal.type == terminalTypeConstants.centralKitchenKds)
    {
        
    }

    if (terminal.type == terminalTypeConstants.signage)
    {
        settings.displaySettings.signagePlayerId = model.signagePlayerId;
    }
}

export async function addUpdateTerminal(terminal)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/terminals`;
    await rmsApiProxy.post(resourceUrl, terminal);
}

export async function removeTerminals (terminals)
{
    for (var index = 0; index < terminals.length; index++)
    {
        const terminal = terminals[index];
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/terminals/${terminal.id}`;
        await rmsApiProxy.deleted(resourceUrl);
    }
}

export async function addUpdateShell(shell)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/shells`;
    await rmsApiProxy.post(resourceUrl, shell);
}


export async function removeShell (shell)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/shells/${shell.licenseKey}`;
    await rmsApiProxy.deleted(resourceUrl);
}

export async function updateTerminalIntegrations (terminal, integrations)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/terminals/${terminal.id}/integrations`;
    await rmsApiProxy.post(resourceUrl, integrations);
}

export function createNewHardwareTerminal(stationViewModel)
{
    var currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    var hardwareTerminal = {};
    hardwareTerminal.isExisting = false;
    hardwareTerminal.code = `${stationViewModel.shell.code}.hardware`;
    hardwareTerminal.enabled = true;
    hardwareTerminal.id = guidUtil.generateGuid();
    hardwareTerminal.type = terminalTypeConstants.hardware;
    hardwareTerminal.userName = `${stationViewModel.shell.code}.user`;
    hardwareTerminal.floatIdentifier = null;
    hardwareTerminal.franchisorId = currentOrgNode.franchisorId;
    hardwareTerminal.franchiseeId = currentOrgNode.franchiseeId;
    hardwareTerminal.propertyId = currentOrgNode.propertyId;
    
    hardwareTerminal.profile = {};
    hardwareTerminal.profile.settings = {};

    stationViewModel.shell.terminals.push(hardwareTerminal.id);
    stationViewModel.terminals.push(hardwareTerminal);

    return hardwareTerminal;
}


export function createNewIntegration(terminal, integrationType, externalEntity)
{
    var currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

    var integration = {};
    integration.franchisorId = currentOrgNode.franchisorId;
    integration.franchiseeId = currentOrgNode.franchiseeId;
    integration.propertyId = currentOrgNode.propertyId;
    integration.integrationType = integrationType;
    integration.isEnabled = true;
    integration.jsonArray = [];
    integration.id = guidUtil.generateGuid();
    integration.systemId = terminal.id;
    integration.systemType = "Terminal";
    integration.externalEntity = externalEntity;
    return integration;
}

export async function linkShell(installationId, licenseKey)
{
    const linkShell = { installationId, licenseKey } ;
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/shells/link`;
    await rmsApiProxy.post(resourceUrl, linkShell);
}

export async function getAppIdForBrowser (terminalCode, expiryInHours)
{
    var url = `core/system/security/urlEncoder?terminalCode=${terminalCode}&expiryInHours=${expiryInHours}`;
    var result = await rmsApiProxy.get(url);
    return result;
}
