import arraySort from "array-sort";

import * as inventoryConstants from '../../../../../utils/domain/inventoryConstants';
import * as domainConstants from '../../../../../utils/domain/constants';
import * as unitUtil from '../../../../../utils/domain/unitUtil';

const inventoryEventType = inventoryConstants.inventoryEventType;

export function getSummaryCogsData(reportData)
{
    const { categories, stockItems, cogsData } = reportData;

    const summaryData = [];

    cogsData.forEach(data => 
    {
        let summaryItem = summaryData.find(summaryItem => summaryItem.stockItemId === data.stockItemId);

        if (summaryItem == null)
        {
            const stockItem = stockItems.find(stockItem => stockItem.id === data.stockItemId);
            const category = categories.find(category => category.id === stockItem.categoryId);
            summaryItem = {
                stockItemId: data.stockItemId,
                categoryName: category.name,
                stockItemName: stockItem.name,
                saleQuantity: 0,
                saleCost: 0,
                wastageQuantity: 0,
                wastageCost: 0,
                discrepancyQuantity: 0,
                discrepancyCost: 0
            };

            summaryData.push(summaryItem);
        }

        updateEventAttributes(data, summaryItem);
    });

    updateAggregateAttributes(summaryData, stockItems);

    return arraySort(summaryData, "stockItemName");
}

export function getDetailCogsData(stockItemId, reportData)
{
    const { stockItems, cogsData } = reportData;

    const detailData = [];

    cogsData
        .filter(data => data.stockItemId === stockItemId)
        .forEach(data => 
        {
            let detailItem = detailData.find(detailItem => detailItem.businessDate === data.businessDate);

            if (detailItem == null)
            {
                detailItem = {
                    businessDate: data.businessDate,
                    stockItemId: stockItemId,
                    saleQuantity: 0,
                    saleCost: 0,
                    wastageQuantity: 0,
                    wastageCost: 0,
                    discrepancyQuantity: 0,
                    discrepancyCost: 0
                };

                detailData.push(detailItem);
            }

            updateEventAttributes(data, detailItem);
        });


    updateAggregateAttributes(detailData, stockItems);

    return arraySort(detailData, "businessDate", { reverse: true });
}


export function getRawCogsData(reportData)
{
    const { categories, stockItems, cogsData } = reportData;

    const rawData = [];

    const distinctStockItemIds = Array.from(new Set(cogsData.map(data => data.stockItemId)));

    distinctStockItemIds.forEach(stockItemId => 
    {
        const stockItem = stockItems.find(stockItem => stockItem.id === stockItemId);
        const category = categories.find(category => category.id === stockItem.categoryId);
        const reportingUnitType = unitUtil.getUnitTypeByReportingType(stockItem, domainConstants.stockItemOperationTypes.reporting);

        const rawItemData = [];

        cogsData
            .filter(data => data.stockItemId === stockItemId)
            .forEach(data =>
            {
                let rawItem = rawItemData.find(rawItem => rawItem.stockItemId === data.stockItemId && rawItem.businessDate === data.businessDate);

                if (rawItem == null)
                {
                    rawItem = {
                        stockItemId: data.stockItemId,
                        businessDate: data.businessDate,
                        categoryName: category.name,
                        stockItemName: stockItem.name,
                        unitCode: reportingUnitType.code,
                        saleQuantity: 0,
                        saleCost: 0,
                        wastageQuantity: 0,
                        wastageCost: 0,
                        discrepancyQuantity: 0,
                        discrepancyCost: 0
                    };

                    rawItemData.push(rawItem);
                }

                updateEventAttributes(data, rawItem);
            });


        rawData.push.apply(rawData, rawItemData);
    });

    updateAggregateAttributes(rawData, stockItems);

    return arraySort(rawData, (a, b) =>
    {
        // custom compare function to sort by businessDate desc, categoryName, stockItemName asc

        if (b.businessDate > a.businessDate) return 1;
        if (b.businessDate < a.businessDate) return -1;

        if (b.categoryName !== a.categoryName) return a.categoryName.localeCompare(b.categoryName);

        return a.stockItemName.localeCompare(b.stockItemName);
    });
}

function updateAggregateAttributes(items, stockItems)
{
    items.forEach(item =>
    {
        const stockItem = stockItems.find(stockItem => stockItem.id === item.stockItemId);
        const reportingUnitType = unitUtil.getUnitTypeByReportingType(stockItem, domainConstants.stockItemOperationTypes.reporting);

        item.saleQuantity = convertInventoryUnitsToReportingUnits(stockItem, reportingUnitType, item.saleQuantity);
        item.wastageQuantity = convertInventoryUnitsToReportingUnits(stockItem, reportingUnitType, item.wastageQuantity);
        item.discrepancyQuantity = convertInventoryUnitsToReportingUnits(stockItem, reportingUnitType, item.discrepancyQuantity);
        item.cogs = item.saleCost + item.wastageCost + item.discrepancyCost;
        item.unitCode = reportingUnitType.code;
    });
}

function updateEventAttributes(data, item)
{
    switch (data.eventType)
    {
        case inventoryEventType.sale:
        case inventoryEventType.centralKitchenSale:
            item.saleQuantity += data.quantity;
            item.saleCost += data.cost;
            break;
        case inventoryEventType.wastage:
            item.wastageQuantity += data.quantity;
            item.wastageCost += data.cost;
            break;
        case inventoryEventType.stockTake:
            item.discrepancyQuantity += data.quantity;
            item.discrepancyCost += data.cost;
            break;
        default:
            throw new Error(`Invalid eventType '${data.eventType}'`);
    }
}

function convertInventoryUnitsToReportingUnits(stockItem, reportingUnitType, quantityInInventoryUnits)
{
    const sign = Math.sign(quantityInInventoryUnits);
    const quantityInCoreUnits = unitUtil.convertToStockItemCoreUnitsFromDefaultUnits(stockItem, Math.abs(quantityInInventoryUnits));
    const quantityInReportingUnits = unitUtil.convertFromStockItemCoreUnits(stockItem, reportingUnitType.id, quantityInCoreUnits);
    return sign * quantityInReportingUnits;
}
