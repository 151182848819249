import React, { Component } from 'react';
import InputComponent from '../../../../../../components/form/inputComponent.js';
import SelectComponent from '../../../../../../components/form/selectComponent';
import * as arraySort from 'array-sort';

const constants = require('../../../../../../utils/domain/constants');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const FormManager = require('../../../../../../utils/view/formManager.js');
const storefrontUtils = require('../../utils/storefrontUtils');

export default class WebAppConfigurationPaymentSetup extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
        };

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
        this.getPaymentProviders = this.getPaymentProviders.bind(this);
    }

    get isReadOnly() { return !storefrontUtils.isEditingAllowed(); }

    getPaymentProviders()
    {
        let options = Object.values(constants.webAppOnlinePaymentProviders).map(provider => ({label: provider}));
        options = options.filter(op => !stringUtil.areStringSame(op.label, constants.webAppOnlinePaymentProviders.StripeOverPhone));
        return arraySort(options, "sortIndex", "label");
    }

    render()
    {
        var component;

        if(stringUtil.areStringSame(this.state.formManager.viewModel.paymentProvider,  constants.webAppOnlinePaymentProviders.Stripe))
        {
            component = 
                <>
                    <tr>
                        <td>
                            <SelectComponent
                                optionValues={this.getPaymentProviders()}
                                optionFieldName="label"
                                optionDisplayFieldName="label"
                                caption="Payment Provider"
                                fieldName="paymentProvider"
                                hintText="Required if payment method credit is selected"
                                hintFieldName="Enter Payment Provider"
                                clearable={true}
                                comboReadOnly={this.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                key="publishableKey"
                                fieldName="publishableKey"
                                caption="Publishable Key"
                                placeholder="Enter Publishable Key"
                                hintText="Required"
                                inputReadOnly={this.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                key="clientSecret"
                                fieldName="clientSecret"
                                caption="Client Secret"
                                placeholder="Enter Client Secret"
                                hintText="Required"
                                inputReadOnly={this.isReadOnly}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                </>
        }

        else if(stringUtil.areStringSame(this.state.formManager.viewModel.paymentProvider,  constants.webAppOnlinePaymentProviders.StripeOverPhone))
        {
            component = 
                <tr>
                    <td>
                        <SelectComponent
                            optionValues={this.getPaymentProviders()}
                            optionFieldName="label"
                            optionDisplayFieldName="label"
                            caption="Payment Provider"
                            fieldName="paymentProvider"
                            hintText="Required if payment method credit is selected"
                            hintFieldName="Enter Payment Provider"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    
                    <td>                        
                    </td>
                </tr>
        }

        else
        {
            component = 
                <tr>
                    <td>
                        <SelectComponent
                            optionValues={this.getPaymentProviders()}
                            optionFieldName="label"
                            optionDisplayFieldName="label"
                            caption="Payment Provider"
                            fieldName="paymentProvider"
                            hintText="Required if payment method credit is selected"
                            hintFieldName="Enter Payment Provider"
                            clearable={true}
                            comboReadOnly={this.isReadOnly}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        
                    </td>
                </tr>
        }

        return (            
            
            <table className='component-table'>                
                {component}                
            </table>
        );
    }    
}
