import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';

import CdnImage from '../../../../components/cdnImage/cdnImage.js'

var configurationManager = require('../../../../utils/config/configurationManager.js');
var dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
var formatter = require('../../../../utils/formatter/formatter.js');
var stringUtil = require('../../../../utils/string/stringUtil.js');
var rotaUtil = require('./RotaUtil.js');

class AttendanceCard extends Component 
{
    constructor(props)
    {
        super(props);

        this.getBgColor = this.getBgColor.bind(this);
        this.getImageComponent = this.getImageComponent.bind(this);

        this.renderSystemGenerated = this.renderSystemGenerated.bind(this);
        this.renderSystemGeneratedCardTooltip = this.renderSystemGeneratedCardTooltip.bind(this);

        this.renderVoidedAttendance = this.renderVoidedAttendance.bind(this);
        
        this.renderSystemGeneratedNoAdjustment = this.renderSystemGeneratedNoAdjustment.bind(this);
        this.renderSystemGeneratedNoAdjustmentCardTooltip = this.renderSystemGeneratedNoAdjustmentCardTooltip.bind(this);
        

        this.renderUserEntered = this.renderUserEntered.bind(this);
        this.renderUserEnteredCardTooltip = this.renderUserEnteredCardTooltip.bind(this);

        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    get isReadOnly() { return !rotaUtil.isEditingAllowed(); }

    render()
    {

        var componentId = `attendance-${this.props.rowIndex}-${this.props.colIndex}`

        var cardComponent = null;
        if (this.props.attendance.isSystemGenerated)
            cardComponent = this.renderSystemGenerated(componentId);
        else
            cardComponent = this.renderUserEntered(componentId);

        var deleteAttendanceIcon = null;
        var publishedAttendanceIcon = null;

        if (!this.props.attendance.isSystemGenerated && !this.isReadOnly)
            deleteAttendanceIcon =
                <>
                    <span id="deleteAttendanceIcon" onClick={this.onDeleteClick} style={{ cursor: "pointer", fontSize: "1.10em" }}><i className='fa fa-window-close-o' /></span>
                    <UncontrolledTooltip target="deleteAttendanceIcon">Delete Attendance</UncontrolledTooltip>
                </>;

        if (!this.props.attendance.isDraft)
            publishedAttendanceIcon =
                <>
                    <span id="publishedAttendanceIcon" className='me-2' style={{ fontSize: "1.10em" }}><i className='fa fa-check-square-o' /></span>
                    <UncontrolledTooltip target="publishedAttendanceIcon">Status=Published</UncontrolledTooltip>
                </>;

        var iconBar = null;
        if (deleteAttendanceIcon || publishedAttendanceIcon)
            iconBar = <div className="d-flex justify-content-end text-white" style={{ marginTop: '-15px' }}>
                            {publishedAttendanceIcon}
                            {deleteAttendanceIcon}
                     </div>
        
        return (<Card id={componentId} className={`text-white ${this.getBgColor()} text-center`} onDoubleClick={this.onDoubleClick}>
                    <CardBody>
                        <table  cellPadding='0px' cellSpacing="0px" style={{width:'100%', padding:'0x', margin:'0px', borderCollapse:'collapse', border:'none', borderWidth:'0px'}}>
                            <tr>
                                <td style={{border:'none', padding:'0px'}}>
                                    <div style={{padding:'0px', margin:'0px'}}>
                                        {this.getImageComponent()}
                                    </div>
                                </td>
                                <td width="100%" style={{border:'none', padding:'0px'}}>
                                    {iconBar}
                                    {cardComponent.body}
                                </td>
                            </tr>
                        </table>
                        {cardComponent.toolTip}
                    </CardBody>
                </Card>);
    }

    getBgColor()
    {
        var colorPallets = ['bg-dark'];
        var palletIndx = this.props.rowIndex % colorPallets.length;

        return colorPallets[palletIndx];
    }

    getImageComponent()
    {
        if (stringUtil.isStringNullOrEmpty(this.props.staff.pictUrl))
            return null;

        return (<div style={{marginRight:'10px'}}> <CdnImage  cloudName={configurationManager.getConfig().cdnUsersCloudName} publicId={this.props.staff.pictUrl} width="60" height="60" radius="0"/></div>)
    }

    renderSystemGenerated(componentId)
    {
        if (this.props.attendance.isVoided)
            return this.renderVoidedAttendance(componentId);

        if (dateUtil.isSame(this.props.attendance.start, this.props.attendance.adjustedStart))
        {

            if (stringUtil.isStringNullOrEmpty(this.props.attendance.adjustedEnd) ||                        //If we have end date, we definitely will have adjust end date.
                (!stringUtil.isStringNullOrEmpty(this.props.attendance.end) && dateUtil.isSame(this.props.attendance.end, this.props.attendance.adjustedEnd)))
                return this.renderSystemGeneratedNoAdjustment(componentId);

        }
       
        var actualTimeCaption = rotaUtil.compileAttendanceActualDateCaption(this.props.attendance);
        if (this.props.attendance.hasSystemClockedOut)
                actualTimeCaption = actualTimeCaption + "*";

        var actualCostCaption = rotaUtil.compileAttendanceActualCostCaption(this.props.attendance);
        var adjustedTimeCaption = rotaUtil.compileAttendanceAdjustedDateCaption(this.props.attendance);
        var adjustedCostCaption = rotaUtil.compileAttendanceAdjustedCostCaption(this.props.attendance);
       
        var componentId = `attendance-${this.props.rowIndex}-${this.props.colIndex}`


        var body = <div>
                    <div className="text-muted">Actual</div>
                    <div><hr style={{padding:'0px', marginTop:'0px', marginBottom:'5px', borderColor:'white'}}/></div>
                    <div>{actualTimeCaption}</div>
                    <div>{actualCostCaption}</div>

                    <div className="text-muted" style={{marginTop:'20px'}}>Adjusted</div>
                    <div><hr style={{padding:'0px', marginTop:'0px', marginBottom:'5px', borderColor:'white'}}/></div>
                    <div>{adjustedTimeCaption}</div>
                    <div>{adjustedCostCaption}</div>
                </div>;

        var toolTip = this.renderSystemGeneratedCardTooltip(componentId);

        return ({body, toolTip});
    }

    renderSystemGeneratedCardTooltip(componentId)
    {

        var systemClockOutDiv = null;
        if (this.props.attendance.hasSystemClockedOut)
            systemClockOutDiv = <div style={{color:'#FF9933'}}> Clocked out by system.</div>



        return (<UncontrolledTooltip placement="top" target={componentId} style={{background:'#484848', opacity: 1}}>
                    <div>
                        <div>
                            {`${this.props.staff.firstName} ${this.props.staff.lastName}  - ${formatter.convertToCurrencyFormat(this.props.attendance.hourlyWage)}/hour`}
                        </div>
                        <div><hr style={{padding:'0px', marginTop:'0px', marginBottom:'5px', borderColor:'#989898'}}/></div>
                        <div>
                            <div align="left" style={{marginTop:'20px'}}>Actual</div>
                            <table>
                                <tr>
                                <td align="left">
                                        From
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.start)}`}
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        To
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.end)}`}
                                    </td>
                                </tr>
                            </table>
                            {systemClockOutDiv}
                            <div align="left" style={{marginTop:'20px'}}>Adjusted</div>
                            <table>
                                <tr>
                                <td align="left">
                                        From
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.adjustedStart)}`}
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        To
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.adjustedEnd)}`}
                                    </td>
                                </tr>
                            </table>
                        </div>
                </div>
                </UncontrolledTooltip>);
    }

    renderSystemGeneratedNoAdjustment(componentId)
    {
        var timeCaption = rotaUtil.compileAttendanceActualDateCaption(this.props.attendance);
        if (this.props.attendance.hasSystemClockedOut)
            timeCaption = timeCaption + "*";
        var costCaption = rotaUtil.compileAttendanceActualCostCaption(this.props.attendance);

        var body = <div>
                        <div>{timeCaption}</div>
                        <div>{costCaption}</div>
                    </div>;
        var toolTip = this.renderSystemGeneratedNoAdjustmentCardTooltip(componentId);

        return ({body, toolTip});
    }


    renderSystemGeneratedNoAdjustmentCardTooltip(componentId)
    {
        var systemClockOutDiv = null;
        if (this.props.attendance.hasSystemClockedOut)
            systemClockOutDiv = <div style={{color:'#FF9933'}}> Clocked out by system.</div>

        return (<UncontrolledTooltip placement="top" target={componentId} style={{background:'#484848', opacity: 1}}>
                    <div>
                        <div>
                            {`${this.props.staff.firstName} ${this.props.staff.lastName}  - ${formatter.convertToCurrencyFormat(this.props.attendance.hourlyWage)}/hour`}
                        </div>
                        <div><hr style={{padding:'0px', marginTop:'0px', marginBottom:'5px', borderColor:'#989898'}}/></div>
                        <div>
                            <table>
                                <tr>
                                <td align="left">
                                        From
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.start)}`}
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        To
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.end)}`}
                                    </td>
                                </tr>
                            </table>
                            {systemClockOutDiv}
                        </div>
                </div>
                </UncontrolledTooltip>);
    }

    renderVoidedAttendance()
    {
        var timeCaption = rotaUtil.compileAttendanceActualDateCaption(this.props.attendance);
        if (this.props.attendance.hasSystemClockedOut)
            timeCaption = timeCaption + "*";

        var costCaption = rotaUtil.compileAttendanceActualCostCaption(this.props.attendance);


        var body = <div>
                        <div style={{textDecoration:"line-through"}}>{timeCaption}</div><div style={{textDecoration:"line-through"}}>{costCaption}</div>
                    </div>

        return ({body});
    }

    renderUserEntered()
    {
        var timeCaption = rotaUtil.compileAttendanceAdjustedDateCaption(this.props.attendance);
        var costCaption = rotaUtil.compileAttendanceAdjustedCostCaption(this.props.attendance);
        var componentId = `attendance-${this.props.rowIndex}-${this.props.colIndex}`


        var body = <div>
                        <div>{timeCaption}</div>
                        <div>{costCaption}</div>
                    </div>;

        var toolTip = this.renderUserEnteredCardTooltip(componentId);

        return({body, toolTip});
    }

    renderUserEnteredCardTooltip(componentId)
    {
        return (<UncontrolledTooltip placement="top" target={componentId} style={{background:'#484848', opacity: 1}}>
                    <div>
                        <div>
                            {`${this.props.staff.firstName} ${this.props.staff.lastName}  - ${formatter.convertToCurrencyFormat(this.props.attendance.hourlyWage)}/hour`}
                        </div>
                        <div><hr style={{padding:'0px', marginTop:'0px', marginBottom:'5px', borderColor:'#989898'}}/></div>
                        <div>
                            <table>
                                <tr>
                                <td align="left">
                                        From
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.adjustedStart)}`}
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        To
                                    </td>
                                    <td align="left">
                                        {`${rotaUtil.compileDateTooltip(this.props.attendance.adjustedEnd)}`}
                                    </td>
                                </tr>
                            </table>
                        </div>
                </div>
                </UncontrolledTooltip>);
    }

    onDoubleClick(event)
    {
        if (this.props.onDoubleClick)
        {
            this.props.onDoubleClick(this.props.attendance);
            event.stopPropagation();
        }
    }

    onDeleteClick(event)
    {
        if (this.props.onDeleteClick)
        {
            this.props.onDeleteClick(this.props.attendance);
        }
    }
}

export default AttendanceCard;