import React, { Component } from 'react';
import { Button } from 'reactstrap';



import * as clipboard from './clipboard';

export default class ClipboardComponent extends Component
{
    constructor(props)
    {
        super(props);



        this.state = { dataType: this.props.dataType }  // dataType is fixed on construction
    }

    onCopy = () =>
    {
        const data = this.props.onCopy();

        if (data == null) return;

        clipboard.setData(this.state.dataType, data);

        this.setState({});
    }

    onPaste = () =>
    {
        this.props.onPaste(clipboard.getData(this.state.dataType));
    }

    render()
    {
        const disabled = this.props.disabled;
        const copyEnabled = !disabled && this.props.onCopy != null;
        const pasteEnabled = !disabled && clipboard.hasData(this.state.dataType) && this.props.onPaste != null;

        return (
            <div className='d-inline-flex'>

                <Button
                    className='p-1 me-1'
                    color="success" outline
                    disabled={!copyEnabled}
                    onClick={this.onCopy}>
                    <i className="fa fa-files-o"/>&nbsp;Copy
                </Button>

                <Button
                    className='p-1'
                    color="info" outline
                    disabled={!pasteEnabled}
                    onClick={this.onPaste}>
                    <i className="fa fa-clipboard"/>&nbsp;Paste
                </Button>

            </div>
        );
    }
}
