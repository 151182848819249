// @ts-nocheck
const datadogLogger = require('@datadog/browser-logs');
const datadogRum = require('@datadog/browser-rum');
const configurationManager = require('../config/configurationManager');
const stateManager = require('../state/stateManager');
const guidUtil = require('../guid/guidUtil');
const stringUtil = require('../string/stringUtil');

var ddLogger;
var sessionId = guidUtil.generateGuid();

module.exports.initializeDatadog = function()
{
    if (ddLogger != null)
        return;

    if(stringUtil.isStringNullOrEmpty(configurationManager.getConfig().dataDogClientToken) || stringUtil.isStringNullOrEmpty(configurationManager.getConfig().dataDogSite))
        return;

    datadogLogger.datadogLogs.init({
        clientToken: configurationManager.getConfig().dataDogClientToken,
        site: configurationManager.getConfig().dataDogSite,
        env: module.exports.getEnvironment(), 
        service: module.exports.getService(),
        version: configurationManager.getConfig().version,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        beforeSend: (log) => 
        {
            if (log?.error?.source === "console") 
            {
                return false;
            }

            return true;
        },
    });

    datadogRum.datadogRum.init({
        useCrossSiteSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        applicationId: configurationManager.getConfig().dataDogRumApplicationIdForBackOffice,
        clientToken: configurationManager.getConfig().dataDogRumClientTokenForBackOffice,
        site: configurationManager.getConfig().dataDogRumSiteForBackOffice,
        service: module.exports.getService(),
        env: module.exports.getEnvironment(), 
        version: configurationManager.getConfig().version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [/https:\/\/.*\.flipdish\.co/],
        beforeSend: (log) => 
        {
            if (log?.error?.source === "console") 
            {
                return false;
            }

            return true;
        },
    });

    ddLogger = datadogLogger.datadogLogs.logger;
}

module.exports.resetGlobalContext = function()
{
    this.initializeDatadog();
    if (ddLogger == null)  return;

    datadogLogger.datadogLogs.setGlobalContext(
                                                { 
                                                    env: module.exports.getEnvironment(), 
                                                    shellType: "Browser",
                                                    renderingMode: "Desktop",
                                                    version: configurationManager.getConfig().version,
                                                    sessionId: sessionId
                                                });                                               

    const globalState = stateManager.getStore().getState();
    if(globalState.applicationModeData != null && globalState.applicationModeData.rumSessionId != null)
    {
        datadogRum.datadogRum.setGlobalContextProperty('portal_session_id', globalState.applicationModeData.rumSessionId);
    }
}

module.exports.info = function(message, messageContext = {}, tag)
{
    try
    {
        messageContext = initializeMessageContext(messageContext, tag);

        console.info(message);
        if (ddLogger != null) ddLogger.info(message, messageContext);
    }
    catch(error){}
}

module.exports.warn = function(message, messageContext = {}, error, tag)
{
    try
    {
        messageContext = initializeMessageContext(messageContext, tag);
        if (error != null)
        {
            messageContext.errorMessage = error.toString();
            message.errorStack = error.stack;
        }

        console.warn(message);
        if (ddLogger != null) ddLogger.warn(message, messageContext);
    }
    catch(error){}
}

module.exports.error = function(message, messageContext, error, tag)
{
    try
    {
        messageContext = initializeMessageContext(messageContext, tag);
        
        if (ddLogger != null) ddLogger.error(message, messageContext, error);
        else console.error(message); 
    }
    catch(error){}
}

module.exports.metrics = function (metricName, metricValue, metricDescription, messageContext)
{
    messageContext = initializeMessageContext(messageContext);
    messageContext.loggingType = "metrics";
    messageContext.metricName = metricName.toUpperCase();
    messageContext.metricValue = metricValue * 1;
    messageContext.metricDescription = metricDescription;

    var message = `Recording metric (${metricName})`;
    console.info(message);
    if (ddLogger != null) ddLogger.info(message, messageContext);
}

module.exports.usage = function (featureName, featureDescription, messageContext)
{
    datadogRum.datadogRum.addAction(featureName);

    messageContext = initializeMessageContext(messageContext);
    messageContext.loggingType = "features";
    messageContext.featureName = featureName;
    messageContext.featureCount = 1;
    messageContext.featureDescription = featureDescription;

    var message = `Recording feature (${featureName})`;
    console.info(message);
    if (ddLogger != null) ddLogger.info(message, messageContext);
}

module.exports.setLoggedInUser = function(email, name)
{
    try
    {
        datadogRum.datadogRum.setGlobalContextProperty("name", name);
        datadogRum.datadogRum.setGlobalContextProperty("email", email);
        datadogRum.datadogRum.setUser({email:email, name:name})
    }
    catch(error){}
}

function initializeMessageContext(messageContext, tag)
{
    if (messageContext == null)
        messageContext = {};

    messageContext.loggingDateTime = Date.now();
    messageContext.loggingType = "logging";
    messageContext.tag = tag;
    return messageContext;
}

module.exports.getEnvironment = function()
{
    var env = configurationManager.getConfig().environment;
    if (stringUtil.areStringSame(env, "ukwest"))
        env = "production";
        
    return env;
}

module.exports.getService = function ()
{
    return "RMS.APP.BACKOFFICE";
}
