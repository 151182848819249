const stringUtil = require('../../string/stringUtil');
const formatter = require('../../formatter/formatter');
const constants = require('../constants');

export function calculateCalorieForNutritionCode(nutritionCode, quantityInGm)
{
    if (stringUtil.isStringNullOrEmpty(nutritionCode))
        return NaN;

    if (nutritionCode == constants.NutritionCode.protein)
        return quantityInGm * 4;

    if (nutritionCode == constants.NutritionCode.fats)
        return quantityInGm * 9;

    if (nutritionCode == constants.NutritionCode.carbohydrates)
        return quantityInGm * 4;

    return 0;
}

export function calculateCalorie(nutritionProfiles)
{
    var calories = 0.0;
    
    nutritionProfiles.forEach (nutritionProfile =>
    {
        calories = calories + calculateCalorieForNutritionCode(nutritionProfile.nutritionCode, nutritionProfile.quantity)
    });
    return  isNaN(calories) ? calories : formatter.roundUp(calories, 2);
}