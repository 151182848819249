import React from 'react';

import StockItemStorageTemperatureLogsView from '../components/TabularView';
import arraySort from 'array-sort';

const domainConstants = require('../../../../../../utils/domain/constants');
const dateUtil = require('../../../../../../utils/dateUtil/dateUtil');
const commonUtility = require('../../../../../../utils/domain/commonUtility');
const apiLoadFacade = require('../../../../../../utils/api/apiLoadFacade');
const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const configurationManager = require('../../../../../../utils/config/configurationManager');
const rmsApiProxy = require('../../../../../../utils/api/rmsApiProxy');
const rmsApiProxyUtils = require('../../../../../../utils/api/rmsApiProxyUtils');

export default {

    getReportData,

    getReportTitle,
    getReportImageUrl,

    getReportContents,
}

async function getReportData(loadCriteria)
{
    const fromDate = loadCriteria.startDate;
    const toDate = loadCriteria.endDate;
    const franchiseeId = currentOrgNodeSelectors.selectCurrentOrgNode().franchiseeId;
    const promises = [];

    const resourceUrl = `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/foodsafety/stockItems/temperatureLogs/storage?fromDate=${fromDate}&toDate=${toDate}`;

    try
    {
        promises.push(rmsApiProxy.get(resourceUrl));
        promises.push(apiLoadFacade.loadStockItems());
        promises.push(apiLoadFacade.loadStaff());

        const type = commonUtility.isCentralKitchenPropertySelected()
        ? domainConstants.serviceContextTypes.centralKitchen
        : domainConstants.serviceContextTypes.store;

        const results = await Promise.all(promises);

        // temperatureLogsData is filtered below for stockItem.type as safety although probably needless
        
        const stockItems = results[1].filter(stockItem => stockItem.type === type);
        const temperatureLogsData = results[0].filter(log => stockItems.find(stockItem => stockItem.id === log.stockItemId));

        const staff = results[2];

        const reportData = { temperatureLogsData }

        enrichData(reportData, stockItems, staff);
        return reportData;

    } catch (error)
    {
        throw error;
    }
}

function enrichData(reportData, stockItems, staff)
{
    const temperatureLogsData = reportData.temperatureLogsData;
    if (temperatureLogsData.length == 0) return; 

    temperatureLogsData.forEach(log => 
    {
        log.stockItem = stockItems.find(x => x.id == log.stockItemId);


        log.staff = staff.find(s => s.id == log.updatedBy);
    });

    structurizeData(reportData)
}

function structurizeData(reportData)
{
    const temperatureLogsData = reportData.temperatureLogsData;
    let dates = [];
    let stockItems = [];
    const structuredTemperatureLogs = [];

    temperatureLogsData.forEach(log => 
    {
        dates.push(log.dateTime);        
        stockItems.push(log.stockItem);
    });

    dates = arraySort([...new Set(dates.map(date => dateUtil.getDateComponent(date)))]);
    stockItems = arraySort([...new Set(stockItems)]);

    dates.forEach(date => 
    {
        stockItems.forEach(stockItem => 
        {
            var logs = temperatureLogsData.filter(log => dateUtil.getDateComponent(log.dateTime) == date && log.stockItem.id == stockItem.id);
            let isTempOutOfRange = false;

            if(logs.length > 0)
            {
                var structuredLog = {};
                structuredLog.date = date;
                structuredLog.stockItem = stockItem;
                
                logs.forEach((log, index) => 
                {            
                    structuredLog[`dateTime${index + 1}`] = dateUtil.formatDate(log.dateTime, dateUtil.getHourMinuteFormat());
                    structuredLog[`temp${index + 1}`] = log.temperature;
                    structuredLog[`hazards${index + 1}`] = log.hazardTypes;
                    structuredLog[`loggedBy${index + 1}`] = `${log.staff.firstName} ${log.staff.lastName}`;

                    if(index == 0)
                    {
                        structuredLog.minTemperature = log.minTemperature;
                        structuredLog.maxTemperature = log.maxTemperature;
                    }

                    if(log.temperature < log.minTemperature || log.temperature > log.maxTemperature)
                    {
                        isTempOutOfRange = true;
                    }
                })

                structuredLog.isTempOutOfRange = isTempOutOfRange;
                structuredTemperatureLogs.push(structuredLog);
            }
        })
    })

    reportData.temperatureLogsData = structuredTemperatureLogs;
}

function getReportTitle()
{
    return "Stock Item Storage Temperature Logs";
}

function getReportImageUrl()
{
    return `app/back.office/menu/${configurationManager.getConfig().themeVersion}/stock.items.storage.logs.icon.png`;
}

function getReportContents(props)
{
    return <StockItemStorageTemperatureLogsView reportData={props.reportData} />;
}