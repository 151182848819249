import React, { Component } from 'react';
import { Button } from 'reactstrap';

import InputComponent from '../../../../../components/form/inputComponent';
import MultiSelectComponent from '../../../../../components/form/multiSelectComponent';
import FormManager from '../../../../../utils/view/formManager';

const domainConstants = require('../../../../../utils/domain/constants');
const stringUtil = require('../../../../../utils/string/stringUtil');

class ResponseInputComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = this.getState(this.props.selectedTaskResponse);
    }

    getState = (selectedTaskResponse) => 
    {
        selectedTaskResponse.response = selectedTaskResponse.response || "";
        selectedTaskResponse.hazardTypes = selectedTaskResponse.hazardTypes || [];

        const formManager = new FormManager();
        formManager.viewModel = selectedTaskResponse;
        formManager.view = this;

        formManager.onFieldChanged = this.onFieldChanged;

        return {
            isDirty: false,
            isAddNew: stringUtil.isStringNullOrEmpty(formManager.viewModel.response),
            formManager: formManager
        }
    }

    getSelectedHazardTypes = () =>
    {
        const viewModel = this.state.formManager.viewModel;
        const hazardTypes = viewModel.hazardTypes;
        const options = domainConstants.getHazardTypeOptions();
        return options.filter(option => hazardTypes.includes(option.value));
    }

    onSelectedHazardTypesChanged = (selectedOptions) =>
    {
        const viewModel = this.state.formManager.viewModel;
        viewModel.hazardTypes = selectedOptions.map(option => option.value);
        this.setState({ isDirty: true });
    }

    onFieldChanged = () => 
    {
        if (!this.state.isDirty)
        {
            this.setState({ isDirty: true });
        }
    }

    onAddUpdate = () => this.props.onAddUpdateTaskResponse(this.state.formManager.viewModel);
    onCancel = () => this.props.onCancelTaskResponse(this.state.formManager.viewModel);

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.selectedTaskResponse != prevProps.selectedTaskResponse)
        {
            this.setState(this.getState(this.props.selectedTaskResponse));
        }
    }

    render()
    {
        const isDirty = this.state.isDirty;
        const isAddNew = this.state.isAddNew;
        const formManager = this.state.formManager;
        const selectedTaskResponse = formManager.viewModel;
        const isReadOnly = this.props.isRowReadOnly;

        const addDisabled = isReadOnly || !isDirty || stringUtil.isStringNullOrEmpty(selectedTaskResponse.response);
        const cancelDisabled = isReadOnly;

        return (
            <div className="d-flex flex-column flex-nowrap">
                <div className="px-4 py-2">
                    <InputComponent
                        inputType="text"
                        caption="Response Option"
                        fieldName="response"
                        placeholder="Enter response option"
                        hintText="Enter response option (Required)"
                        multiLine={true} rows={2}
                        maxLength={256}
                        inputReadOnly={isReadOnly}
                        formManager={formManager} />
                </div>
                <div className="px-4 py-2">
                    <MultiSelectComponent
                        caption="Hazard Types"
                        valueFieldName="value"
                        displayFieldName="label"
                        options={domainConstants.getHazardTypeOptions()}
                        selectedOptions={this.getSelectedHazardTypes()}
                        onSelectionChanged={this.onSelectedHazardTypesChanged}
                        hintText="Select potential hazard types on selection of this response option"
                        comboReadOnly={isReadOnly} />
                </div>
                <div className="d-flex flex-row flex-nowrap justify-content-end">
                    <Button color="primary" style={{ width: "25%", margin: "0 10px 10px 0" }}
                        disabled={addDisabled}
                        onClick={this.onAddUpdate}>
                        {isAddNew ? "Add" : "Update"}
                    </Button>
                    <Button color="warning" style={{ width: "25%", margin: "0 25px 10px 0" }}
                        disabled={cancelDisabled}
                        onClick={this.onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

export default ResponseInputComponent;