const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const GridViewOptions = require('../../../../../components/gridView/gridViewOptions');
const salesGridUtil = require('./salesGridUtil');

export function enrichSales(sales, storefronts, staffList)
{
    if(sales && sales.length > 0)
    {
        sales.forEach(sale => 
        {
            const storefront = storefronts.find(storefront => storefront.id == sale.storefrontId);

            if (storefront)
            {
                sale.storefrontName = storefront.name;
                sale.brand = storefront.brand;
            }

            if(sale.driverId)
            {
                const driver = staffList.find(staff => staff.id == sale.driverId);

                if(driver)
                {
                    sale.driverName = `${driver.firstName} ${driver.lastName}`;
                }
            }

            sale.netCharge = sale.charge - sale.chargeRefund;
            sale.netChargeTax = sale.chargeTax - sale.chargeRefundTax;
        });
    }
}

export function generateGridOptions(showBrand, title = "", messageContext = "", component, onSearch, buttons = []) 
{
    const gridViewOptions = new GridViewOptions();

    gridViewOptions.title = title;
    gridViewOptions.messageContext = messageContext;

    gridViewOptions.gridDataMode = "search";
    gridViewOptions.fromSearchDate = dateUtil.getDateComponent(dateUtil.subtractDays(dateUtil.getNow(), 7));
    gridViewOptions.toSearchDate = dateUtil.getDateComponent(dateUtil.getNow());
    gridViewOptions.onSearch = onSearch;

    gridViewOptions.getPrimaryKeyValue = (row) => row.id;
    gridViewOptions.getComponent = (isNew, row) => component(row);

    gridViewOptions.paginationEnabled = true;
    gridViewOptions.isRowReadOnly = () => true;

    gridViewOptions.getColumnDefinitions = () => salesGridUtil.constructGridColumnHeaders(showBrand);
    gridViewOptions.getRowClassRules = salesGridUtil.constructRowHighlightRules;
    gridViewOptions.getPinnedRows = salesGridUtil.getPinnedRows;

    gridViewOptions.buttons = buttons;

    return gridViewOptions;
}