module.exports.parseFloatGreaterThanZeroElseNull = function (value)
{
    value = this.parseFloatElseNull(value);
    if (value == null) return null;
    if (value > 0) return value;
    return null;
}

module.exports.parseFloatGreaterThanOrEqualToZeroElseNull = function (value)
{
    value = this.parseFloatElseNull(value);
    if (value == null) return null;
    if (value >= 0) return value;
    return null;
}

module.exports.parseFloatElseNull = function (value)
{
    if (value === null || value === undefined)
        return null;

    const result = parseFloat(value);

    return isNaN(result) ? null : result;
}

module.exports.parseFloatElseZero = function (value)
{
    const result = this.parseFloatElseNull(value);
    if (result == null) return 0;
    return result;
}

module.exports.parseFloatElseThrow = function (value, context)
{
    const result = this.parseFloatElseNull(value);

    if (result == null)
    {
        throw (`${context}: Not a number error`);
    }

    return result;
}

module.exports.parseIntElseNull = function (value)
{
    if (value === null || value === undefined)
        return null;

    const result = parseInt(value);

    return isNaN(result) ? null : result;
}

module.exports.parseIntElseZero = function (value, context)
{
    const result = this.parseIntElseNull(value);
    if (result == null) return 0;
    return result;
}

module.exports.parseIntElseThrow = function (value, context)
{
    const result = this.parseIntElseNull(value);

    if (result == null)
    {
        throw (`${context}: Not a number error`);
    }

    return result;
}
