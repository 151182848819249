const arraySort = require('array-sort');
const constants = require('../../../../../../utils/domain/constants');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const localizationUtils = require('../../../../../../utils/domain/localizationUtils');


export function getDepositReturnsSummary(depositReturns, depositSales)
{
    const depositReturnsSummary = [];

    const isVatInclusive = localizationUtils.isVatInclusive();

    depositReturns.forEach(depositReturn => {
        depositReturn.depositItems.forEach(item => {
            const itemCaption = item.caption;
            let summary = depositReturnsSummary.find(summary => stringUtil.areStringSame(summary.itemCaption, itemCaption));
            if (summary == null)
            {
                summary = {
                    itemCaption, soldQuantity: 0, returnedQuantity: 0, totalAmountDeposited: 0, totalAmountReturned: 0
                }

                depositReturnsSummary.push(summary);
            }

            summary.returnedQuantity += item.quantity;
            summary.totalAmountReturned = isVatInclusive ? summary.totalAmountReturned + (item.depositCharge * item.quantity) : summary.totalAmountReturned + ((item.depositCharge * item.quantity) + item.vat);
        });
    });

    depositSales.forEach(sale =>
    {
        const saleDepositCharges = sale.charges.filter(charge => stringUtil.areStringSame(charge.scheme, constants.Charges.schemes.depositReturn));
        calculateDepositSale(sale.saleItems, depositReturnsSummary, saleDepositCharges);
    });

    return arraySort(depositReturnsSummary, "itemCaption");
}


function calculateDepositSale(saleItems, depositReturnsSummary, saleDepositCharges)
{
    saleItems.forEach(saleItem => {
        const saleItemDepositCharge = saleDepositCharges.find(s => s.saleItemId === saleItem.id);

        if (saleItemDepositCharge)
        {
            const itemCaption = saleItem.caption;
            let summary = depositReturnsSummary.find(summary => stringUtil.areStringSame(summary.itemCaption, itemCaption));

            if (summary == null)
            {
                summary = {
                    itemCaption, soldQuantity: 0, returnedQuantity: 0, totalAmountDeposited: 0, totalAmountReturned: 0
                }

                depositReturnsSummary.push(summary);
            }
            
            summary.soldQuantity += saleItem.quantity;
            summary.totalAmountDeposited += saleItemDepositCharge.amount;
        } 
        calculateDepositSale(saleItem.saleItems,  depositReturnsSummary, saleDepositCharges);
    });
}