
import React, { Component } from 'react';
import { Row} from 'reactstrap';
import ScaleLoader from "react-spinners/ScaleLoader";


import GridView from '../../../../components/gridView/gridView';
import ErrorMessageComponent from "../../../../components/error/errorMessage";
import * as expenseUtils from './utils/expenseUtils';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions');
const stringUtil = require('../../../../utils/string/stringUtil');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const guidUtil = require('../../../../utils/guid/guidUtil');
const localizationUtils = require('../../../../utils/domain/localizationUtils');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../../../../utils/state/stateSelectors/catalogSelectors');


class Expenses extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onSearchExpenses = this.onSearchExpenses.bind(this);
        this.save = this.save.bind(this);

        this.state = {
            isReferenceDataLoaded: false,
            suppliers: [],
            categories: [],
            transactionAccounts: []
        }
    }

    render() 
    {
        if (!stringUtil.isStringNullOrEmpty(this.state.error))
        {
            return (<div style={{marginTop:'40px'}}>
                        <ErrorMessageComponent message={this.state.error}/>
                    </div>)
        }

        if (!this.state.isReferenceDataLoaded)
        {
            this.loadReferenceData()
            .then(
            (results)=>
            {
                this.setState({});
            }, 
            (error) =>
            {
                this.state.error = error;
                this.setState({});
            });

            return (<div style={{marginTop:'40px'}}>
                        <Row className="justify-content-center">
                            <ScaleLoader
                                height={50}
                                width={6}
                                color={"#808080"}
                                loading={true} />
                        </Row>
                        <Row className="justify-content-center" style={{ margin: '5px' }}>
                            <h4 className="text-muted">Loading Data</h4>
                        </Row>
                    </div>)
        }

        return this.renderGrid();
    }

    async loadReferenceData()
    {
        const suppliers = await apiLoadFacade.loadSuppliers();
        const categories = await apiLoadFacade.loadAccountCategoriesAndSubCategories();
        const transactionAccounts = await apiLoadFacade.loadTransactionAccounts();

        this.state.suppliers = suppliers;
        this.state.categories = categories;
        this.state.transactionAccounts = transactionAccounts;
        this.state.isReferenceDataLoaded = true;
    }

    renderGrid()
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Expenses";
        gridViewOptions.messageContext = "Expense";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "search";
        gridViewOptions.onSearch = this.onSearchExpenses;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () =>
        {
            var newRow = {};
            newRow.id = guidUtil.generateGuid();
            newRow.propertyId = currentOrgNode.propertyId;
            newRow.expenseDate = dateUtil.getToday();
            return newRow;
        };

        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;

        gridViewOptions.isReadOnly = this.props.isReadOnly;

        gridViewOptions.getComponent = (isNew, row) => expenseUtils.getComponent(row, { ...this.state, ...this.props });
        gridViewOptions.getAttachmentsParam = (isNew, row) => expenseUtils.getAttachmentsParam(isNew, row);

        gridViewOptions.save = this.save;
        gridViewOptions.validate = (isNewRow, row) => expenseUtils.validateRow(isNewRow, row);

        gridViewOptions.paginationEnabled = true;
        gridViewOptions.displayPaginationControl = true;

        gridViewOptions.getPinnedRows = (rows) =>
        {
            if (rows == null || rows.length <= 0)
                return null;

            var totalAmount = 0;
            var totalVat = 0;

            rows.forEach(row =>
            {
                totalAmount = totalAmount + row.amount;
                totalVat = totalVat + row.vat;
            })

            const row = { amount: totalAmount, vat: totalVat };
            return [row];
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    

    async onSearchExpenses(searchCriteria)
    {
        try
        {
            let result = await rmsApiProxy.get(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/expenses?fromDate=${searchCriteria.startDate}&toDate=${searchCriteria.endDate}`);
            if (result == null) result = [];

            result = arraySort(result, "expenseDate", "fromDate", "toDate", "amount");
            this.enrichData(result);
            return result;

        }
        catch (error)
        {
            throw error;
        }
    }

    async save(isNewRow, row, remoteData)
    {
        try
        {
            const updatedExpense = await expenseUtils.save(row);
            this.enrichData(updatedExpense);

            if (remoteData == null) remoteData = [];

            const existingIndex = remoteData.findIndex(x => x.id == updatedExpense.id);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedExpense);
            else
                remoteData.push(updatedExpense)

            const response = {};
            response.remoteData = remoteData;
            response.addUpdatedRow = updatedExpense;

            return response;
        }
        catch (error)
        {
            throw error;
        }
    }

    enrichData = expenses =>
    {
        const properties = catalogSelectors.selectProperties();

        const categories = this.state.categories;
        const suppliers = this.state.suppliers;
        const transactionAccounts = this.state.transactionAccounts;

        if (!Array.isArray(expenses)) expenses = [expenses];

        expenses.forEach(expense => 
        {
            const property = properties.find(x => x.id === expense.propertyId);
            if (property != null) expense.propertyName = property.name;

            const supplier = suppliers.find(x => x.id === expense.supplierId);
            if (supplier != null) expense.supplierName = supplier.name;

            const transactionAccount = transactionAccounts.find(x => x.id === expense.accountId);
            if (transactionAccount != null) expense.accountName = transactionAccount.name;

            const category = categories.find(x => x.subCategories.some(s => s.id === expense.subCategoryId));
            if (category != null)
            {
                expense.categoryId = category.id;
                expense.categoryName = category.name;

                const subCategory = category.subCategories.find(x => x.id === expense.subCategoryId);
                if (subCategory != null) 
                {
                    expense.subCategoryName = subCategory.name;
                }
            }
        });
    }

    constructGridColumnHeaders()
    {
        let header
        const headers = [];
        const vatCaption = localizationUtils.getVatCaption();

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "expenseDate";
        header.headerName = "Expense Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "fromDate";
        header.headerName = "From Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "toDate";
        header.headerName = "To Date";
        header.type = dataTypeConstants.date;
        header.width = columnWidthConstants.date;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "propertyName";
        header.headerName = "Property";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "categoryName";
        header.headerName = "Category";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "subCategoryName";
        header.headerName = "Sub Category";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "supplierName";
        header.headerName = "Supplier";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "accountName";
        header.headerName = "Account Name";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "amount";
        header.headerName = "Amount";
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {};
        header.field = "vat";
        header.headerName = vatCaption;
        header.type = dataTypeConstants.money;
        header.width = columnWidthConstants.money;
        headers.push(header);

        header = {}
        header.field = "enteredBy";
        header.headerName = "Entered By";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Expenses;
