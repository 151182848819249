

import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import Station from './Station';
import StationConfiguration from './StationConfiguration.jsx';
import Spinner from '../../../components/spinner/spinner.js';

const stationState = require('./stationState');
const securityManager = require('../../../utils/domain/security/securityManager');
const localizationUtils = require('../../../utils/domain/localizationUtils');
const configurationManager = require('../../../utils/config/configurationManager');
const actionDispatcherUtil = require('../../../utils/state/actions/actionDispatcherUtil');
const commonUtility = require('../../../utils/domain/commonUtility');
const constants = require('../../../utils/domain/constants');

class StationsDesigner extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.error = null;
        this.state.reloadGlobalStateRequired = true;
        this.state.reloadShellAndTerminalsRequired = true;
        this.state.reloadPrintersRequired = true;

        this.state.showNewStationView = false;
        this.state.selectedStationViewModel = null;

    }

    render()
    {
        if (this.state.error)
        {
            return <div style={{marginTop:'100px'}}>
                    <div style={{color: '#585858', fontSize:'3em', textAlign:'center'}}>
                        Oops! Something went wrong.
                    </div>

                    <div style={{color: '#989898', fontSize:'1em', textAlign:'center'}}>
                        {this.state.error}
                    </div>
                </div>
        }

        if (this.state.reloadGlobalStateRequired || this.state.reloadShellAndTerminalsRequired || this.state.reloadPrintersRequired)
        {
            this.reloadState();
            return <div style={{marginTop:'200px', textAlign:'center'}}>
                            <Spinner text="Please wait, loading stations" />
                    </div>
        }

        if (this.state.showNewStationView)
        {
            return (<StationConfiguration onClose={(isUpdated)=>
                    {
                        stationState.setCurrentStationViewModel(null);
                        this.state.showNewStationView = false;
                        
                        if (isUpdated)
                            this.state.reloadShellAndTerminalsRequired = true;

                        this.setState({});
                    }}/>);
        }

        if (this.state.selectedStationViewModel != null)
            return <Station stationViewModel={this.state.selectedStationViewModel} canGenerateStationURL={securityManager.isSystemUser()} isReadOnly={this.props.isReadOnly} onCancel={()=>
            {
                this.state.selectedStationViewModel = null;
                this.state.reloadShellAndTerminalsRequired = true;
                this.state.reloadPrintersRequired = true;
                this.setState({});
            }}/>

        return (<Card>
                    <CardHeader>
                        {this.renderToolbar()}
                    </CardHeader>
                    <CardBody>
                        <div style={{minHeight:window.innerHeight * 0.50}}>
                            {this.renderStations()}
                        </div>
                    </CardBody>
                </Card>
                );
    }

    async reloadState()
    {
        try
        {
            if (this.state.reloadGlobalStateRequired)
                await stationState.initializeGlobalState();

            if (this.state.reloadShellAndTerminalsRequired)
                await stationState.initializeShellsAndTerminalsState();

            if (this.state.reloadPrintersRequired)
                await stationState.loadPrinters();

            this.state.reloadGlobalStateRequired = false;
            this.state.reloadShellAndTerminalsRequired = false;
            this.state.reloadPrintersRequired = false;
            this.setState({});
        }
        catch(error)
        {
            this.state.reloadGlobalStateRequired = false;
            this.state.reloadShellAndTerminalsRequired = false;
            this.state.reloadPrintersRequired = false;
            this.state.error = error;
            this.setState({});
        }
    }
    
    renderToolbar()
    {
        var addNewStationButton = null;

        if (!this.props.isReadOnly)
        {
            addNewStationButton = <Button color="success" className="btn-success" style={{ marginLeft: '3px', marginRight: '20px' }} onClick={()=>
                                    {
                                        stationState.setCurrentStationViewModel(stationState.generateNewStationViewModel());
                                        this.state.showNewStationView = true;
                                        this.setState({});
                                    }}>
                                    Add New Station
                                  </Button>
        }

        return (
            <table width="100%">
                <tr>
                    <td>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>Stations</div>
                    </td>
                    <td align="right">
                        {addNewStationButton}
                        {
                            commonUtility.getApplicationMode() === constants.applicationModes.embedded 
                            ?   null
                            :   <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={()=>{actionDispatcherUtil.showOptionsUI();}}>
                                    Close
                                </Button>
                        }
                    </td>
                </tr>
            </table>
        );
    }

    renderStations()
    {
        var stationComponents = [];

        stationState.stationsViewModels.forEach (stationViewModel =>
        {
            stationComponents.push(this.renderStation(stationViewModel));
        });

        if (stationComponents.length <= 0)
        {
            return <div style={{textAlign:'center', marginTop:'100px'}}> No Stations found.</div>
        }

        return <div style={{background:'#F6F9FF', minHeight:'100%', marginTop:'-16px', marginLeft:'-16px', marginRight:'-16px', paddingTop:'40px', paddingLeft:'40px', paddingRight:'40px'}}>
                    <div className="d-flex flex-row flex-wrap w-100">
                            {stationComponents}
                    </div>
                </div>
    }

    renderStation(stationViewModel)
    {
        var rootUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675445582`;

        return <div style={{border: '1px solid #C8C8C8', background:'white', marginRight:'20px', 
                            marginBottom:'30px', minWidth:'400px', width:'25vw', minHeight:'100px', 
                            paddingTop:'20px', paddingBottom:'20px', cursor:'default', userSelect:'none'}} onClick={()=>{
                                this.state.selectedStationViewModel = stationViewModel;
                                this.setState({});
                            }}>

                    <table style={{width:'100%'}}>
                    <tr>
                        <td style={{width:'1%', whiteSpace:'nowrap'}}>
                            <img src={`${rootUrl}/${stationViewModel.image}`} style={{width:'80px', marginRight:'20px', marginLeft:'20px'}}/>
                        </td>
                        <td style={{width:'99%'}}>
                            <table>
                                <tr>
                                    <td>
                                        <h3 style={{margin:'0px'}}>{localizationUtils.changePlaceHolderText(stationViewModel.title)}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>{localizationUtils.changePlaceHolderText(stationViewModel.hints)}</small>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </table>
                </div>
    }

    
}

export default StationsDesigner;