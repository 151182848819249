import { Component } from 'react';
import AllergyProfiles from './AllergyProfiles';
import NutritionProfiles from './NutritionProfiles';

class AllergyAndNutritionProfiles extends Component 
{
    render()
    {
        return  <div>
                    <div style={{ paddingBottom: "20px" }}>
                        <AllergyProfiles {...this.props} />
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                        <NutritionProfiles {...this.props} />
                    </div>
                </div>
    }
}

export default AllergyAndNutritionProfiles;