import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import SelectComponent from '../../../../../components/form/selectComponent';
import InputComponent from '../../../../../components/form/inputComponent';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontOperatingConfigurationsHelper = require('./storefrontOperatingConfigurationsHelper');
const FormManager = require('../../../../../utils/view/formManager.js');
const domainConstants = require('../../../../../utils/domain/constants');
const viewUtil = require('../../../../../utils/view/viewUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const brandApiFacade = require('../../../../../utils/api/brandApiFacade.js');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontStorePayoutConfigurations extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            formManager: new FormManager(),
            isLoading: false,
            storefront: this.props.params
        };
        
        this.state.storefrontOperatingConfigurations = {};
        this.state.formManager.viewModel = this.state.storefrontOperatingConfigurations;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onAddLocationClicked = this.onAddLocationClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({isLoading: true});
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let storefrontOperatingConfigurations = await storefrontOperatingConfigurationsHelper.loadStorefrontOperatingConfiguration(this.state.storefront);
            
            this.state.storefrontOperatingConfigurations = storefrontOperatingConfigurations || {};
            this.state.formManager.viewModel = this.state.storefrontOperatingConfigurations;

            this.setState({});
        }
        catch(error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({isLoading: false});
        }
    }

    async onSaveClicked()
    {
        const storefront = this.state.storefront;   
        const validationError = storefrontOperatingConfigurationsHelper.validateOperatingConfigurations(storefront, this.state.storefrontOperatingConfigurations)
        
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }
        
        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            await storefrontOperatingConfigurationsHelper.save(storefront, this.state.storefrontOperatingConfigurations)
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.payout, 
                                                      `${this.state.storefront.name} storefront payout configurations have been altered`, 
                                                      this.state.storefrontOperatingConfigurations);
            actionDispatcherUtil.showOptionsUI();
        }
        catch(error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }    

        let options = null;
        if (this.state.formManager.viewModel.payoutIntegrationType === domainConstants.storePayoutIntegrationTypes.flipdish)
        {
            options = <>
                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="payoutStoreId"
                            caption="Flipdish Store Id"
                            placeholder="Enter Flipdish Store Id"
                            hintText="Flipdish Store Id"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={ this.state.formManager } />
                    </td>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="appId"
                            caption="App Id"
                            placeholder="Enter App Id"
                            hintText="Application Id"
                            inputReadOnly={this.props.isReadOnly}
                            formManager={ this.state.formManager } />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='pt-3 mt-3'>
                            <InputComponent
                                inputType="text"
                                fieldName="locationId"
                                caption="Stripe Location Id"
                                placeholder="Enter Stripe Location Id"
                                hintText="Enter the Location Id for the store registered on Stripe. To create a new location in Stripe click 'Create New Stripe Location'"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={ this.state.formManager } />
                        </div>
                    </td>
                    <td/>
                </tr>
                <tr>
                    <td>
                        <div className='pb-2'>
                                <Button color="success" className="btn-success" onClick={this.onAddLocationClicked} disabled={this.props.isReadOnly}>
                                    <i className="fa fa-globe"></i>&nbsp;&nbsp;Create New Stripe Location
                                </Button>
                            </div>
                    </td>
                    <td/>
                </tr>
                <tr>
                    <td colSpan={2}><hr/></td>
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="connectAccountId"
                            caption="Connect Account Id"
                            hintText=""
                            inputReadOnly={true}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="transactionPercentageFee"
                            caption="Transaction Fee Percentage"
                            hintText=""
                            inputReadOnly={true}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="transactionFixedFee"
                            caption="Transaction Fixed Fee"
                            hintText=""
                            inputReadOnly={true}
                            formManager={this.state.formManager} />
                    </td>
                    <td>
                        <InputComponent
                            inputType="text"
                            fieldName="transactionTaxRate"
                            caption="Transaction Tax Rate"
                            hintText=""
                            inputReadOnly={true}
                            formManager={this.state.formManager} />
                    </td>
                </tr>
            </>
        }

        const contents = <table className='component-table'>
            <tr>
                <td>
                    <SelectComponent
                        optionValues={ domainConstants.getStorePayoutIntegrationTypes() }
                        optionFieldName="value"
                        optionDisplayFieldName="value"
                        caption="Payout Integration Type"
                        fieldName="payoutIntegrationType"
                        hintText="Optional"
                        clearable={ true }
                        comboReadOnly={this.props.isReadOnly}
                        formManager={ this.state.formManager } />
                </td>
                <td/>
            </tr>
            {options}

        </table>


        return (            
            <Card>

                <CardHeader>
                    <EditComponentHeader
                        title="Storefront Store Payout Configurations"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    {contents}
                </CardBody>

            </Card>
        );
    }    

    onAddLocationClicked()
    {
        // this.state.formManager
        const locationData = {};
        locationData.address = {};
        viewUtil.openModalForm("Add New Stripe Location", () =>
        {
            return <DialogueBox locationData={locationData}/>
        },
        async () => 
        {
            viewUtil.showSystemModalSpinner("Creating Stripe Location, please wait");

            const response = await rmsApiProxy.post(`gateway/payment/${rmsApiProxy.getRawOrgContextToUrl()}/location`, locationData)
            
            viewUtil.closeModalSpinner();
            if(response.error)
            {
                throw `Failed to create location with error: ${response.error}`;
            }
            else
            {
                this.state.formManager.viewModel.locationId = response.id;    
                this.setState({});    
            }
        },
        () =>
        {
            if (stringUtil.isStringNullOrEmpty(locationData.displayName))
                return "Display Name is required";

            if (stringUtil.isStringNullOrEmpty(locationData.address.country))
                return "Country is required";

            if (stringUtil.isStringNullOrEmpty(locationData.address.state))
                return "State is required";

            if (stringUtil.isStringNullOrEmpty(locationData.address.city))
                return "City is required";

            if (stringUtil.isStringNullOrEmpty(locationData.address.postalCode))
                return "Postal Code is required";

            if (stringUtil.isStringNullOrEmpty(locationData.address.line1))
                return "Address Line 1 is required";
        })
    }

}

class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.locationData;
        this.state.formManager.view = this;
    }

    render()
    {
        return <table style={{width:'100%'}}>
            <tr>
                <td>
                    <InputComponent
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        inputType="text"
                        caption="Name"
                        hintText="A name for the location"
                        fieldName="displayName"
                        placeholder="Enter Name"
                        formManager={this.state.formManager} />
                </td>
                <td/>
            </tr>
            <tr>
                <td>
                    <SelectComponent
                        optionValues={ [{value: "GB", label: "United Kingdom"}, {value: "IE", label: "Ireland" }] }
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        optionFieldName="value"
                        optionDisplayFieldName="label"
                        caption="Country"
                        fieldName="address.country"
                        hintText="Country"
                        clearable={ true }
                        formManager={this.state.formManager} />
                </td>
                <td>
                    <InputComponent
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        inputType="text"
                        caption="State"
                        hintText="State, county, province, or region"
                        fieldName="address.state"
                        placeholder="Enter State"
                        formManager={this.state.formManager} />
                </td>
            </tr>
            <tr>
                <td>
                    <InputComponent
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        inputType="text"
                        caption="City"
                        hintText="City, district, suburb, town, or village"
                        fieldName="address.city"
                        placeholder="Enter City"
                        formManager={this.state.formManager} />
                </td>
                <td>
                    <InputComponent
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        inputType="text"
                        caption="Postal Code"
                        hintText="ZIP or postal code"
                        fieldName="address.postalCode"
                        placeholder="Enter Postal Code"
                        formManager={this.state.formManager} />
                </td>
            </tr>
            <tr>
                <td>
                    <InputComponent
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        inputType="text"
                        caption="Address line 1"
                        hintText="Address line 1 (e.g., street, PO Box, or company name)."
                        fieldName="address.line1"
                        placeholder="Enter Address line 1"
                        formManager={this.state.formManager} />
                </td>
                <td>
                    <InputComponent
                        style={{marginLeft:'10px', marginRight:'10px'}}
                        inputType="text"
                        caption="Address line 2"
                        hintText="Address line 2 (e.g., apartment, suite, unit, or building)."
                        fieldName="address.line2"
                        placeholder="Enter Address line 2"
                        formManager={this.state.formManager} />
                </td>
            </tr>
        </table>;
    }
}


export default StorefrontStorePayoutConfigurations;