import React, { Component } from 'react';
import classNames from 'classnames';

import * as validationUtils from '../utils/openingHourValidationUtils';

class OpeningHour extends Component
{
    constructor(props)
    {
        super(props);
        this.state = { openingHour: this.getOpeningHoursFromProps() }
    }

    componentDidUpdate()
    {
        const openingHour = this.getOpeningHoursFromProps();

        if (openingHour !== this.state.openingHour)
        {
            this.setState({ openingHour });
        }
    }

    getOpeningHoursFromProps = () =>
    {
        const { day, dispatchType, openingHoursData } = this.props;
        return openingHoursData.find(data => data.day === day)[dispatchType];
    }

    onChange = (e) =>
    {
        const openingHour = e.target.value;

        const { day, dispatchType, openingHoursData } = this.props;
        openingHoursData.find(data => data.day === day)[dispatchType] = openingHour;

        this.setState({ openingHour })
    }

    render()
    {
        const openingHour = this.state.openingHour;

        const className = classNames(
            'w-100 border-0 border-bottom text-muted ignis-opening-hour-input',
            !validationUtils.isValidOpeningHour(openingHour) && 'ignis-opening-hour-invalid');

        return (
            <input
                type='text'
                className={className}
                placeholder='Closed'
                autoComplete='off'
                value={this.state.openingHour}
                onChange={this.onChange} />);
    }
}

export default OpeningHour;