
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import GridComponent from '../../../../../components/grid/gridComponent.js';
import StorefrontTimingOverride from './components/StorefrontTimingOverride';
import StorefrontTimingConfiguration from './components/StorefrontTimingConfiguration';
import ErrorMessage from '../../../../../components/error/errorMessage';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontTimingConfigurationHelper = require('./storefrontTimingConfigurationHelper');
const catalogSelectors = require('../../../../../utils/state/stateSelectors/catalogSelectors');
const typeUtil = require('../../../../../utils/type/typeUtil.js');
const viewUtil = require('../../../../../utils/view/viewUtil.js');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const dateUtil = require('../../../../../utils/dateUtil/dateUtil');
const domainConstants = require('../../../../../utils/domain/constants');
const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontTimingConfigurations extends Component
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            storefrontTimingConfiguration: {
                overrides: [], 
                orderInterval1: null,
                orderInterval2: null,
                orderInterval3: null,
            },
            storefront: this.props.params,
        }

        this.state.isLoading = true;
        this.state.error = null;

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onAdd = this.onAdd.bind(this);
        this.onRemoveOverride = this.onRemoveOverride.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.onSaveOverride = this.onSaveOverride.bind(this);
        this.onSaveConfiguration = this.onSaveConfiguration.bind(this);
    }

    componentDidMount() 
    {
        this.loadData();
    }

    async loadData() 
    {
        this.state.isLoading = true;
        this.state.error = null;
        this.setState({});

        viewUtil.showSystemModalSpinner("Loading, please wait");

        try 
        {
            let storefrontTimingConfiguration = await storefrontTimingConfigurationHelper.load(this.state.storefront);

            if (storefrontTimingConfiguration)
            {
                this.state.storefrontTimingConfiguration = storefrontTimingConfiguration;
            }
            else
            {
                this.state.storefrontTimingConfiguration =
                {
                    overrides: [],
                    defaultPrepTime: 20,
                    defaultDeliveryTime: 20,
                    orderInterval1: 5,
                    orderInterval2: 10,
                    orderInterval3: 15,
                }
            }

        }
        catch (error) 
        {
            this.state.error = error;
        }

        this.state.isLoading = false;
        this.setState({});
        viewUtil.closeModalSpinner();
    }

    render()
    {
        if (this.state.error)
            return <ErrorMessage message={this.state.error} />;


        const deleteButtonDisabled = this.findSelectedRow() == null || this.props.isReadOnly;

        const buttonPanels =
            <table width="100%">
                <tr>
                    <td align="right">
                        <Button color="primary" disabled={this.props.isReadOnly} className="btn-primary" style={{ marginRight: '3px' }} onClick={this.onAdd}>
                            <i className="fa fa-plus"></i>&nbsp;Add Timing Overrides
                        </Button>

                        <Button color="primary" disabled={deleteButtonDisabled} className="btn-danger" style={{ marginRight: '3px' }} onClick={this.onRemoveOverride}>
                            <i className="fa fa-remove"></i>&nbsp;Remove Timing Overrides
                        </Button>
                    </td>
                </tr>
            </table>

        return (
            <Card>
                <CardHeader>
                    <EditComponentHeader
                        title={`Timing Configuration: ${this.state.storefront.name}`}
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveConfiguration}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    <table width="100%">

                        <tr width="100%">
                            <StorefrontTimingConfiguration
                                data={this.state.storefrontTimingConfiguration} />
                        </tr>
                        <tr width="100%">
                            <td style={{ paddingTop: '8px', paddingBottom: '8px' }} width="100%">
                                {buttonPanels}
                            </td>
                        </tr>

                        <tr width="100%">
                            <td width="100%">
                                <GridComponent headers={this.constructGridColumnHeaders()}
                                    rows={this.state.storefrontTimingConfiguration.overrides}
                                    onRowDoubleClicked={this.onRowDoubleClicked}
                                    onRowSelected={this.onRowSelected}
                                    shouldReArrangeHeaders={true}
                                    onGridReady={this.onGridReady} />
                            </td>
                        </tr>
                    </table>
                </CardBody>
            </Card>
        )
    }

    async onSaveConfiguration()
    {
        const validationError = storefrontTimingConfigurationHelper.validateTimingConfiguration(this.state.storefrontTimingConfiguration)

        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            await storefrontTimingConfigurationHelper.save(this.state.storefront, this.state.storefrontTimingConfiguration);
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration, 
                                                      `${this.state.storefront.name} storefront timing configurations have been altered`, 
                                                      this.state.storefrontTimingConfiguration);
                                                      
            actionDispatcherUtil.showOptionsUI();
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onAdd()
    {
        const override = {
            id: guidUtil.generateGuid(),
        };
        this.addEdit(override);
    }

    onRemoveOverride()
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        viewUtil.showConfirmDialogue('Timing Override', `You are going to delete a timing override record. Are you sure?`,
            () =>
            {
                this.state.storefrontTimingConfiguration.overrides = this.state.storefrontTimingConfiguration.overrides.filter(override => override.id != selectedRow.id);
                this.setState({});
            });
    }

    onRowDoubleClicked(eventData)
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const override = this.state.storefrontTimingConfiguration.overrides.find(override => override.id == selectedRow.id);
        this.addEdit(override);
    }

    addEdit(override)
    {
        const weekDays = catalogSelectors.selectLookupData().frequencies.find(f => f.code == "Weekly").options;
        const cloneObject = typeUtil.deepCloneObject(override);

        viewUtil.openModalForm
            (
                "Timing Override",
                (onFieldChanged) =>
                {
                    return (
                        <StorefrontTimingOverride
                            data={cloneObject}
                            dispatchTypes={domainConstants.getDispatchTypes()}
                            weekDays={weekDays} />
                    );
                },
                () => { this.onSaveOverride(cloneObject) },
                () => { return storefrontTimingConfigurationHelper.validateTimingOverride(cloneObject) },
                this.props.isReadOnly
            );
    }

    onSaveOverride(override)
    {
        override.fromHour = null;
        override.fromMinute = null;
        override.formattedFromTime = null;
        if (!stringUtil.isStringNullOrEmpty(override.fromDateTime))
        {
            override.fromHour = dateUtil.getHours(dateUtil.convertToLocalStandard(override.fromDateTime));
            override.fromMinute = dateUtil.getMinutes(dateUtil.convertToLocalStandard(override.fromDateTime))
            override.formattedFromTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(override.fromDateTime), "HH:mm");
        }

        override.toHour = null;
        override.toMinute = null;
        override.formattedToTime = null;
        if (!stringUtil.isStringNullOrEmpty(override.toDateTime))
        {
            override.toHour = dateUtil.getHours(dateUtil.convertToLocalStandard(override.toDateTime));
            override.toMinute = dateUtil.getMinutes(dateUtil.convertToLocalStandard(override.toDateTime))
            override.formattedToTime = dateUtil.formatDate(dateUtil.convertToLocalStandard(override.toDateTime), "HH:mm");
        }

        this.state.storefrontTimingConfiguration.overrides = this.state.storefrontTimingConfiguration.overrides.filter(sp => sp.id != override.id);
        this.state.storefrontTimingConfiguration.overrides.push(override);
        this.setState({});
    }

    constructGridColumnHeaders()
    {
        const headers = [];

        var header = {};
        header.field = "prepTime";
        header.headerName = "Prep Time";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "deliveryTime";
        header.headerName = "Delivery Time";
        header.width = columnWidthConstants.number;
        headers.push(header);

        header = {};
        header.field = "dispatchType";
        header.headerName = "Dispatch Type";
        header.width = columnWidthConstants.code;
        headers.push(header);

        header = {};
        header.field = "orderPrice";
        header.headerName = "Order Price";
        header.width = columnWidthConstants.number;
        header.type = dataTypeConstants.money;
        headers.push(header);

        header = {};
        header.field = "autoAcceptOrder";
        header.headerName = "Auto Accept Order";
        header.width = columnWidthConstants.boolean;
        header.type = dataTypeConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "formattedFromTime";
        header.headerName = "From";
        header.width = columnWidthConstants.time;
        headers.push(header);


        header = {};
        header.field = "formattedToTime";
        header.headerName = "To";
        header.width = columnWidthConstants.time;
        headers.push(header);

        header = {};
        header.field = "dayOfTheWeek";
        header.headerName = "Day of Week";
        header.width = columnWidthConstants.code;
        headers.push(header);

        return headers;
    }
}

export default StorefrontTimingConfigurations;