
module.exports.organizationInitialized = 'action.organization.initialized';
module.exports.environmentInitialized = 'action.environment.initialized';
module.exports.passport = 'action.passport';

module.exports.sideBarOption = 'action.ui.side.bar.option';
module.exports.topBarOption = 'action.ui.top.bar.option';
module.exports.mainBarOption = 'action.ui.main.bar.option';
module.exports.mainBarSearch = 'action.ui.main.bar.search';
module.exports.mainBarComponent = 'action.ui.main.bar.component';
module.exports.alertDialogueUI = 'action.ui.alert.dialogue';
module.exports.modalSpinnerUI = 'action.ui.modal.spinner';
module.exports.confirmDialogueUI = 'action.ui.confirm.dialogue';
module.exports.modalFormUI = 'action.ui.modal.form';
module.exports.attachmentFormUI = 'action.ui.modal.attachment';
module.exports.displayAccountSwitch = 'action.ui.display.account.switch';

module.exports.catalogData = 'action.catalog.data';
module.exports.catalogLookupData = 'action.catalog.lookup.data';

module.exports.orgData = 'action.org.data';
module.exports.orgLookupData = 'action.org.lookup.data';    // Loaded contextually

module.exports.currentOrgNode = 'action.current.org.node';


