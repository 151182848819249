import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard.js';
import SalesDataGrid from './SaleDataGrid.jsx';

const formatter = require('../../../../../utils/formatter/formatter.js');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants.js');

const SaleDiscounts = (props) => {

    const totalDiscount = props.sale.discounts.reduce((previous, discount) => previous + discount.amount, 0);
    return <CollapsibleCard caption={`Sale Discounts - ${formatter.convertToCurrencyFormat(totalDiscount)}`} isDefaultOpen={props.isDefaultOpen}>
        <SalesDataGrid getColumnDefs={getColumnDefs} getData={() => getData(props.sale)} />
    </CollapsibleCard>

}
 
function getData(sale)
{
    return sale.discounts;
}

function getColumnDefs()
{
    let header;
    const headers = [];

    header = {};
    header.field = "discountScheme";
    header.headerName = "Scheme";
    headers.push(header);

    header = {};
    header.field = "discountIssuer";
    header.headerName = "Issuer";
    headers.push(header);

    header = {};
    header.field = "discountOfferType";
    header.headerName = "Offer Type";
    header.suppressSizeToFit = false;
    headers.push(header);

    header = {};
    header.field = "type";
    header.headerName = "Type";
    headers.push(header);

    header = {};
    header.field = "value";
    header.headerName = "Discount Value";
    headers.push(header);

    header = {};
    header.field = "minimumOrderAmount";
    header.headerName = "Minumum Order Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "amount";
    header.headerName = "Amount";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}

export default SaleDiscounts;