import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';

const currentOrgNodeSelectors = require('../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');


export default function ChargeOverviewDetails(props)
{
    const chargesData = props.data.chargesData;
    const columnDefs = getColumnDefs();

    return (
        <div className="ag-theme-alpine" style={{ height: "75vh" }}>
            <AgGridReact
                domLayout='normal'
                rowData={chargesData}
                pinnedBottomRowData={getPinnedRows(chargesData)}
                gridOptions={biUtils.gridOptions}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
            />
        </div>);
}

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            e.api.setPinnedBottomRowData(getPinnedRows(rows));
        }
    });
}

function getColumnDefs()
{
    const columnDefinitions = [];

    columnDefinitions.push({ field: 'propertyName', headerName: "Store", type: 'rowGroupEnabled', hide: currentOrgNodeSelectors.isCurrentPropertySelected() });
    columnDefinitions.push({ field: 'storefrontType', headerName: "Storefront Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'venueCode', headerName: "Venue", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'dispatchType', headerName: "Dispatch Type", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'scheme', headerName: "Scheme", type: 'rowGroupEnabled', hide: false });
    columnDefinitions.push({ field: 'internalName', headerName: "Internal Name", type: 'rowGroupEnabled', hide: true });

    columnDefinitions.push({ field: 'netCharge', headerName: "Charge", type: 'money' });
    columnDefinitions.push({ field: 'netTax', headerName: "Tax", type: 'money' });
    columnDefinitions.push({ field: 'totalCharges', headerName: "Total Charges", type: 'money' });

    return columnDefinitions;
}

function getPinnedRows(rows)
{
    return [
        {
            'netCharge': rows.reduce((previous, current) => previous + current.netCharge, 0),
            'netTax': rows.reduce((previous, current) => previous + current.netTax, 0),
            'totalCharges': rows.reduce((previous, current) => previous + current.totalCharges, 0),
        }
    ];
}

