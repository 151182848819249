var actionTypes = require('../../../actionTypes.js');

module.exports.createAction = function(payload)
{
    return (
            {
                type: actionTypes.confirmDialogueUI,
                payload: payload
            }
           );
}

module.exports.changeState = function (previousState, payload)
{
    return Object.assign({}, previousState, {confirmDialogue: payload});
}