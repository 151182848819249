import React from 'react';

import unitUtil from '../../../../../../utils/domain/unitUtil';
import formatter from '../../../../../../utils/formatter/formatter';
import purchaseOrderUtil from '../../utils/purchaseOrderUtil';
import inventoryUtil from '../../utils/inventoryUtil';

export default function ItemInventoryComponent(props)
{
    let inventoryQuantity;
    let inventoryMinInventoryQuantity;
    let inventoryMinReorderQuantity;
    let inventoryUnitTypeCode;

    let lastOrderItemQuantity;
    let lastOrderItemUnitTypeCode;

    let suggestedReorderQuantity;

    const orderItem = props.data;
    const purchaseOrder = props.purchaseOrder;
    const purchaseOrderContext = props.purchaseOrderContext;
    const purchaseOrders = props.purchaseOrders;
    const inventoryData = props.inventoryData;

    const stockItemId = orderItem.stockItemId;
    const unitTypeId = orderItem.unitTypeId;
    const supplierId = purchaseOrder.supplierId;

    const unitTypes = unitUtil
        .getPossibleUnitsForStockItemId(stockItemId, props.lookupData.stockItems)
        .filter(unit => !unit.isSupplementalUnit);
        
    const unitType = unitTypes.find(u => u.id === unitTypeId);

    if (unitType == null) 
    {
        //
        // Implies orderItem.unitTypeId does not exist any more, display an alert message in this case
        //

        return (
            <div className="w-100 h-100 border rounded px-5 d-flex flex-column justify-content-center bg-light">
                Your selected unit type is invalid and no inventory information can be displayed without a valid unit type.
                Please select a valid unit type from the dropdown to enable inventory info display
            </div>
        );
    }

    const inventory = inventoryUtil.getInventoryByStockItemId(stockItemId, inventoryData);
    const supplierUnits = unitUtil.getPossibleUnitForStockItemSupplier(stockItemId, supplierId, props.lookupData.stockItems, !purchaseOrderContext.isNewOrder );
    const supplierUnit = supplierUnits.find(unit => unit.unitTypeId == unitTypeId);
    const stockItem = purchaseOrderUtil.getStockItem(orderItem, props.lookupData);

    if (inventory)
    {
        inventoryQuantity = formatter.roundUp(inventory.quantity / unitType.coreUnitConversionFactor, 2);
        inventoryUnitTypeCode = unitType.code;
    }

    if (inventory && stockItem && supplierUnit)
    {
        inventoryMinInventoryQuantity = formatter.roundUp(inventoryUtil.getStockItemMinimumInventoryQuantity(stockItem) / unitType.coreUnitConversionFactor, 2);
        inventoryMinReorderQuantity = supplierUnit.minimumOrderQuantity;

        suggestedReorderQuantity = formatter.roundUp(inventoryUtil.getSuggestedReorderQuantityInSupplierUnits(inventory, stockItem, supplierUnit));    // in coreUnits
    }

    const lastOrderItem = inventoryUtil.getLastOrderItem(purchaseOrder, purchaseOrders, stockItemId, unitTypeId);

    if (lastOrderItem)
    {
        const lastOrderItemUnitType = unitTypes.find(u => u.id == lastOrderItem.unitTypeId);

        lastOrderItemQuantity = formatter.roundUp(lastOrderItem.quantity, 2);
        lastOrderItemUnitTypeCode = lastOrderItemUnitType.code;
    }

    const currentInventoryComponent =
        inventory == null
            ? <>
                <div>No inventory info available</div>
                <div className="border-top mb-1" />
            </>
            : <>
                <div>Suggested Reorder: {suggestedReorderQuantity} {inventoryUnitTypeCode}</div>
                <div className="border-top mb-1" />
                <div>Current Inventory: {inventoryQuantity} {inventoryUnitTypeCode}</div>
                <div>Minimum Inventory: {inventoryMinInventoryQuantity} {inventoryUnitTypeCode}</div>
                <div>
                    Minimum Reorder:
                    {
                        inventoryMinReorderQuantity
                            ? ` ${inventoryMinReorderQuantity} ${inventoryUnitTypeCode}`
                            : " Not specified"
                    }
                </div>
                <div className="border-top mb-1" />
            </>;

    const lastOrderItemComponent =
        lastOrderItem == null
            ? <div className="mt-1 d-inline-flex flex-row align-items-baseline">
                No last order info available
                <span className="small muted-text">&nbsp;&nbsp;&nbsp;(in selected units)</span>
            </div>
            : <div className="mt-1 d-inline-flex flex-row align-items-baseline">
                Last order quantity: {lastOrderItemQuantity} {lastOrderItemUnitTypeCode}
                <span className="small muted-text">&nbsp;&nbsp;&nbsp;(in selected units)</span>
            </div>;

    return (
        <div className="w-100 h-100 border rounded px-5 d-flex flex-column justify-content-center bg-light">
            {currentInventoryComponent}
            {lastOrderItemComponent}
        </div >
    );
}