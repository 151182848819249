const LookupData = require('../orgData/lookup/lookupData');

//
// state.orgStates is a map of orgState objects
// Map: {key=franchisorId: value={franchisorId, lookupDate}}
//

module.exports = function (franchisorId)
{
    this.franchisorId = franchisorId;
    this.lookupData = new LookupData();
    this.franchisorLocalization = {};
}