
import React, { Component } from 'react';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import FranchisorOverview from './components/FranchisorOverview';
import BlockFranchisor from './components/BlockFranchisor';
import FranchisorLogo from './components/FranchisorLogo';
import { Card, CardHeader, Button, CardBody } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const orgSelectors = require('../../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil.js');
const typeUtil = require('../../../../../utils/type/typeUtil');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const stateManager = require('../../../../../utils/state/stateManager');
const franchisorBasicConfigurationHelper = require('./franchisorBasicConfigurationHelper');
const displayAccountSwitchAction = require('../../../../../utils/state/actions/views/container/displayAccountSwitchAction');
const securityManager = require('../../../../../utils/domain/security/securityManager');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class FranchisorBasicConfiguration extends Component {

    constructor(props) {

        super(props);
        this.state = {}
        this.state.formManager = new FormManager();

        this.state.formManager.viewModel = {
            id: guidUtil.generateGuid(), 
            isEnabled: true,
            displayResellerBranding: true
        }
        this.isCurrentSystemSelected = currentOrgNodeSelectors.isCurrentSystemSelected();

        if(!this.isCurrentSystemSelected)
        {
            const franchisor = typeUtil.deepCloneObject(orgSelectors.selectFranchisor(currentOrgNodeSelectors.selectCurrentFranchisor().id));
            if (franchisor) {
                this.state.formManager.viewModel = franchisor;
            }    
        }

        this.state.formManager.view = this;
        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    render() {

        const tabHeaders = [];
        const tabContents = [];

        tabHeaders.push(<Tab>General</Tab>);
        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <FranchisorOverview
                    isCurrentSystemSelected={this.isCurrentSystemSelected}
                    formManager={ this.state.formManager } />
            </TabPanel>);

        if(!this.props.isReadOnly)
        {
            tabHeaders.push(<Tab>Block Franchisor</Tab>);
            tabContents.push(
                <TabPanel style={ { marginTop: '30px' } }>
                    <BlockFranchisor formManager={ this.state.formManager } />
                </TabPanel>);
        }

        tabHeaders.push(<Tab>Logo</Tab>);
        tabContents.push(
            <TabPanel style={{ marginTop: '30px' }}>
                <FranchisorLogo
                    formManager={ this.state.formManager }
                    onImageUpdate={ () =>
                    {
                        this.setState({})
                    } } />
            </TabPanel>);


        const contents = <Tabs>

            <TabList>
                {tabHeaders}
            </TabList>

            {tabContents}

        </Tabs>;


        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader title="Franchisor Basic Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    {contents}
                </CardBody>
            </Card>

        )
    }

    async onSaveClicked() {
        const franchisor = this.state.formManager.viewModel;
        const validationError = franchisorBasicConfigurationHelper.validate(franchisor)
        if (!stringUtil.isStringNullOrEmpty(validationError)) {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Franchisor");

        try {
            const updatedFranchisor = await franchisorBasicConfigurationHelper.save(this.isCurrentSystemSelected, franchisor);
            viewUtil.showSuccessAlert(`Franchisor ${updatedFranchisor.name} saved`); 

            await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.configuration, 
                                                        'Franchisor Basic Configuration has been altered', 
                                                        updatedFranchisor, updatedFranchisor);

            if(this.isCurrentSystemSelected)
            {
                viewUtil.showConfirmDialogue("Account Switch", `Do you want to switch to franchisor: ${this.state.formManager.viewModel.name}`,
                ()=>
                {
                    stateManager.getStore().getState().currentFranchisorId = this.state.formManager.viewModel.id;
                    stateManager.getStore().getState().sideBarSelectedOption = "configurations";
                    stateManager.getStore().getState().topBarSelectedOption = "expanded";
                    stateManager.getStore().getState().mainBarComponent = null;
                    stateManager.getStore().getState().mainBarOptions = "configurations.organization";
                    stateManager.getStore().getState().mainBarSearch = null;

                    actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
                    actionDispatcher.dispatch({ type: actionTypes.organizationInitialized, payload: false });
                }, ()=>
                {
                    const currentOptionAction = 
                    {
                        type: actionTypes.mainBarComponent,
                        payload: null
                    };

                    actionDispatcher.dispatch(currentOptionAction);
                });
            }
            else
            {

                const currentOptionAction = 
                {
                    type: actionTypes.mainBarComponent,
                    payload: null
                };

                actionDispatcher.dispatch(currentOptionAction);
            }
        }
        catch (error) {
            viewUtil.showCriticalAlert(error);
        }
        finally {
            viewUtil.closeModalSpinner();
        }
    }

}

export default FranchisorBasicConfiguration;

