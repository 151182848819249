import React, { useCallback, useState } from "react";
import { Field } from "../../../Form";
import styled from "styled-components";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEffect } from "react";
import { linkShell } from "../../../../networkDiagramUtil";
const viewUtil = require('../../../../../../../utils/view/viewUtil');
const logger = require('../../../../../../../utils/logger/logger.js');


const installationIdFieldConf = {
     label: "Installation Id",
     hintText: "Provided by client during installation",
     fieldName: "installationId",
     type: "input:text",
     defaultValue: null,
     fieldProps: {
          readOnly: false,
          required: true,
          placeholder: "Enter the installation ID provided by the client",
     },
};

const StationActivation = ({ visible = false, licenseKey, onClose = () => {} }) => {
     const [installationId, setInstallationId] = useState("");
     const [isDisabledActivateBtn, setIsDisabledActivateBtn] = useState(true);

     const handleValueChange = useCallback((value) => {
          if (value === null) value = "";

          setInstallationId(value.trim());
     }, []);

     const handleOnClosePopup = () => {
          setInstallationId("");
          setIsDisabledActivateBtn(true);

          onClose();
     };

     const handleActivateStation = async () => {

          viewUtil.showSystemModalSpinner("Linking Shell License");

          try {
               await linkShell(installationId, licenseKey);
               viewUtil.showSuccessAlert(`Shell license linked`);
          } catch (error) {
               logger.error("Error occurred while activating station", null, error);
               viewUtil.showCriticalAlert(error);
          } finally {
               viewUtil.closeModalSpinner();
               handleOnClosePopup();
          }
     };

     useEffect(() => {
          setIsDisabledActivateBtn(installationId === "" ? true : false);
     }, [installationId]);

     return (
          <ModalWrapper isOpen={visible}>
               <ModalHeader toggle={handleOnClosePopup}>Station Activation</ModalHeader>
               <ModalBody>
                    <Field field={installationIdFieldConf} onValueChange={handleValueChange} />
               </ModalBody>
               <ModalFooter>
                    <Button color="error" type="button" onClick={handleOnClosePopup}>
                         Cancel
                    </Button>
                    <Button color="success" type="submit" disabled={isDisabledActivateBtn} onClick={handleActivateStation}>
                         Activate
                    </Button>
               </ModalFooter>
          </ModalWrapper>
     );
};

export default StationActivation;

const ModalWrapper = styled(Modal)`
     margin-top: 200px;
     max-width: 500px;
`;
