const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const constants = require('../../../../../utils/domain/constants');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

module.exports.save = function(storefront, storefrontOverPhonePaymentConfigurations)
{
    enrichOverPhonePaymentConfiguration(storefrontOverPhonePaymentConfigurations);
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/webAppConfigurations`;
    return rmsApiProxy.post(resourceUrl, storefrontOverPhonePaymentConfigurations);
}

function enrichOverPhonePaymentConfiguration(overPhonePaymentConfiguration)
{
    const property = currentOrgNodeSelectors.selectCurrentProperty();

    overPhonePaymentConfiguration.mode = constants.webAppModes.OnlineOrderingApp;
    overPhonePaymentConfiguration.emailReplyToAddress = property.emailAddress;
    overPhonePaymentConfiguration.collectionPaymentMethods = [constants.paymentMethods.Credit];
    overPhonePaymentConfiguration.deliveryPaymentMethods = [constants.paymentMethods.Credit];
    overPhonePaymentConfiguration.dineInPaymentMethods = [constants.paymentMethods.Credit];
    overPhonePaymentConfiguration.paymentProvider = constants.webAppOnlinePaymentProviders.StripeOverPhone;
}

module.exports.loadStorefrontWebAppConfigurations = function(storefront)
{
    var resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/storefronts/${storefront.id}/configurations/webAppConfigurations`;
    return rmsApiProxy.get(resourceUrl);
}