
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardHeader,  CardBody, CardFooter, Button, Modal } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Jumbotron from "../../../../../../../components/jumbotron/jumbotron";
import InputComponent from '../../../../../../../components/form/inputComponent'
import MultiSelectComponent from '../../../../../../../components/form/multiSelectComponent';
import SelectComponent from '../../../../../../../components/form/selectComponent';
import ColorPickerComponent from "../../../../../../../components/form/colorPickerComponent.js";

var FormManager = require('../../../../../../../utils/view/formManager.js');
var domainConstants = require('../../../../../../../utils/domain/constants.js');
var stringUtil = require('../../../../../../../utils/string/stringUtil');


class ActiveOrdersSlideDialogue extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.slide;
        this.state.formManager.view = this;
    }

    render()
    {
        return(<Modal isOpen={true} centered animation={false} fade={false}  size="lg" style={{maxWidth: '1600px', width: '90%'}}>
                    <Card>
                        <CardHeader>
                            Image Slide  - {this.props.slide.name}
                        </CardHeader>
                        <CardBody>
                            <Tabs>
                                <TabList>
                                    <Tab>General</Tab>
                                    <Tab>Layout</Tab>
                                    <Tab>Overrides</Tab>
                                </TabList>
                                <TabPanel style={{ marginTop: '5px' }}>
                                    {this.renderGeneralTab()}
                                </TabPanel>
                                <TabPanel style={{ marginTop: '5px' }}>
                                    {this.renderLayoutTab()}
                                </TabPanel>
                                <TabPanel style={{ marginTop: '5px' }}>
                                    {this.renderOverridesTab()}
                                </TabPanel>
                            </Tabs>
                        </CardBody>

                        <CardFooter>
                                <table width="100%">
                                    <tr>
                                        <td align="right">
                                            <div>
                                                <Button size="lg" color="secondary" className="btn-secondary" style={{marginRight:'3px'}} onClick={()=>{this.props.onClose()}}>
                                                        Close
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                        </CardFooter>
                    </Card>
                </Modal>);
    }

    renderGeneralTab()
    {
        return <table width="100%">
                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" caption="Slide Name" hintText="Required" fieldName="name" placeholder="Enter slide name" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" caption="Title" hintText="Required" fieldName="title" placeholder="Enter title" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td style={{width:'50%'}}>
                            <ColorPickerComponent
                                fieldName="headerBackgroundColor"
                                placeholder="Color"
                                caption="Choose header back color"
                                formManager={this.state.formManager}
                                style={{ marginRight: "20px" }}
                            />
                        </td>
                        <td style={{width:'50%'}}>
                            <ColorPickerComponent
                                fieldName="headerForegroundColor"
                                placeholder="Color"
                                caption="Choose header foreground color"
                                formManager={this.state.formManager}
                                style={{ marginLeft:'20px' }}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td style={{width:'50%'}}>
                            <ColorPickerComponent
                                fieldName="titleBackgroundColor"
                                placeholder="Color"
                                caption="Choose title back color"
                                formManager={this.state.formManager}
                                style={{ marginRight: "20px" }}
                            />
                        </td>
                        <td style={{width:'50%'}}>
                            <ColorPickerComponent
                                fieldName="titleForegroundColor"
                                placeholder="Color"
                                caption="Choose title foreground color"
                                formManager={this.state.formManager}
                                style={{ marginLeft:'20px' }}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td style={{width:'50%'}}>
                            <MultiSelectComponent
                                    options={domainConstants.getVenueCodes()}
                                    displayFieldName="venueCode"
                                    valueFieldName="venueCode"
                                    selectedOptions={this.state.formManager.viewModel.venueCodes == null ? [] : this.state.formManager.viewModel.venueCodes.map(x => ({ venueCode: x }))}
                                    onSelectionChanged={(selectedOptions) => 
                                                        {
                                                            let venueCodes = [];

                                                            if (selectedOptions.length > 0)
                                                            {
                                                                venueCodes = selectedOptions.map(option => option.venueCode);
                                                            }

                                                            this.state.formManager.viewModel.venueCodes = venueCodes;
                                                            this.setState({});
                                                            
                                                        }}
                                    clearable={true}
                                    caption="Venues"
                                    hintText="Select venue codes for the slide"
                                />
                        </td>

                        <td style={{width:'50%'}}>
                            <div style={{marginLeft:'20px'}}>
                                <MultiSelectComponent
                                    options={domainConstants.getDispatchTypes()}
                                    displayFieldName="dispatchType"
                                    valueFieldName="dispatchType"
                                    selectedOptions={this.state.formManager.viewModel.dispatchTypes == null ? [] : this.state.formManager.viewModel.dispatchTypes.map(x => ({ dispatchType: x }))}
                                    onSelectionChanged={(selectedOptions) => 
                                                        {
                                                            let dispatchTypes = [];

                                                            if (selectedOptions.length > 0)
                                                            {
                                                                dispatchTypes = selectedOptions.map(option => option.dispatchType);
                                                            }

                                                            this.state.formManager.viewModel.dispatchTypes = dispatchTypes;
                                                            this.setState({});
                                                            
                                                        }}
                                    clearable={true}
                                    caption="Dispatch Types"
                                    hintText="Select dispatch type for the slide"
                                />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td style={{width:'50%'}}>
                            <SelectComponent
                                    optionValues={this.getFilterExistingPlayer()}
                                    optionFieldName="id"
                                    optionDisplayFieldName="name"
                                    fieldName="signagePlayerId"
                                    caption="Signage Player"
                                    hintText="(Required)"
                                    formManager={this.state.formManager}
                                    clearable={true} />
                        </td>
                        <td>
                        </td>
                    </tr>
                    
                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                fieldName="notes" placeholder="Enter any notes for slide." formManager={this.state.formManager} />
                        </td>
                    </tr>

                </table>
    }

    renderOverridesTab()
    {
        return <Jumbotron>
                    <h3>Slide Overrides!</h3>
                    <p className="lead">Here you can set overrides. e.g. This slide is only visible during specific time of day</p>
                    <hr className="my-2" />
                    <p>This feature will be enabled in future release. </p>
                </Jumbotron>
    }

    renderLayoutTab()
    {
        return <Jumbotron>
                    <h3>Slide Overrides!</h3>
                    <p className="lead">Here you can set overrides. e.g. This slide is only visible during specific time of day</p>
                    <hr className="my-2" />
                    <p>This feature will be enabled in future release. </p>
                </Jumbotron>
    }

    getFilterExistingPlayer()
    {
        var signagePlayers = this.props.listOfPlayers ?? [];
        signagePlayers  = signagePlayers.filter(p => !stringUtil.areStringSame(p.id, this.props.player.id));
        signagePlayers = signagePlayers.filter(p => p.slides != null && p.slides.length > 0 && p.slides.filter(s => s.type == domainConstants.signageSlideTypes.ActiveOrders).length <= 0);

        return signagePlayers;
    }
}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(ActiveOrdersSlideDialogue);