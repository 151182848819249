import { allBrandTotal } from '../../../../reportUtils/constants';

const stringUtil = require('../../../../../../utils/string/stringUtil');
const arraySort = require('array-sort');

export function getFormattedData(data, brand)
{
    if (brand == null)
    {
        // Implies data without brand info
        data = data.filter(data => stringUtil.isStringNullOrEmpty(data.storefrontBrand));
    }
    else if (brand !== allBrandTotal)
    {
        data = data.filter(data => stringUtil.areStringSame(brand, data.storefrontBrand));
    }

    const vatDetailData = data;

    if (vatDetailData.length === 0) return null;

    return {
        vatDetailData,

        vatSummary: getVatSummary(vatDetailData),
        vatByProperty: getVatByProperty(vatDetailData),
        vatByStorefront: getVatByStorefront(vatDetailData),
        vatByVenueCode: getVatByVenueCode(vatDetailData),
        vatByDispatchType: getVatByDispatchType(vatDetailData),

        vatByPercentage: getVatByPercentage(vatDetailData),

        vatByTop20Categories: getVatByTop20Categories(vatDetailData),
        vatByBottom20Categories: getVatByBottom20Categories(vatDetailData),
        vatByTop20Items: getVatByTop20Items(vatDetailData),
        vatByBottom20Items: getVatByBottom20Items(vatDetailData)
    };
}

function getVatSummary(vatDetailData)
{
    const totalRetailOrderPrice = vatDetailData.reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);
    const totalTax = vatDetailData.reduce((previous, current) => previous + current.totalTax, 0);

    return { totalRetailOrderPrice, totalTax };
}

function getVatByProperty(vatDetailData)
{
    const propertiesMap = new Map(vatDetailData.map(data => [data.propertyId, data.propertyName]));
    const propertyEntries = Array.from(propertiesMap.entries());

    const properties = arraySort(propertyEntries.map(entry => ({ propertyId: entry[0], propertyName: entry[1] })), "propertyName");

    return properties.map(property => 
    {
        const propertyId = property.propertyId;
        const propertyName = property.propertyName;
        const totalRetailOrderPrice = vatDetailData.filter(data => data.propertyId === property.propertyId).reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);
        const totalTax = vatDetailData.filter(data => data.propertyId === property.propertyId).reduce((previous, current) => previous + current.totalTax, 0);

        return { propertyId, propertyName, totalRetailOrderPrice, totalTax }
    });
}

function getVatByStorefront(vatDetailData)
{
    const storefrontsMap = new Map(vatDetailData.map(data => [data.storefrontId, data.storefrontName]));
    const storefrontEntries = Array.from(storefrontsMap.entries());

    const storefronts = arraySort(storefrontEntries.map(entry => ({ storefrontId: entry[0], storefrontName: entry[1] })), "storefrontName");

    return storefronts.map(storefront => 
    {
        const storefrontId = storefront.storefrontId;
        const storefrontName = storefront.storefrontName;
        const totalRetailOrderPrice = vatDetailData.filter(data => data.storefrontId === storefront.storefrontId).reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);
        const totalTax = vatDetailData.filter(data => data.storefrontId === storefront.storefrontId).reduce((previous, current) => previous + current.totalTax, 0);

        return { storefrontId, storefrontName, totalRetailOrderPrice, totalTax }
    });
}

function getVatByVenueCode(vatDetailData)
{
    const venueCodesSet = new Set(vatDetailData.map(data => data.venueCode));

    const venueCodes = arraySort(Array.from(venueCodesSet));

    return venueCodes.map(venueCode =>
    {
        const totalRetailOrderPrice = vatDetailData.filter(data => data.venueCode === venueCode).reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);
        const totalTax = vatDetailData.filter(data => data.venueCode === venueCode).reduce((previous, current) => previous + current.totalTax, 0);

        return { venueCode, totalRetailOrderPrice, totalTax }
    });
}

function getVatByDispatchType(vatDetailData)
{
    const dispatchTypesSet = new Set(vatDetailData.map(data => data.dispatchType));

    const dispatchTypes = arraySort(Array.from(dispatchTypesSet));

    return dispatchTypes.map(dispatchType =>
    {
        const totalRetailOrderPrice = vatDetailData.filter(data => data.dispatchType === dispatchType).reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);
        const totalTax = vatDetailData.filter(data => data.dispatchType === dispatchType).reduce((previous, current) => previous + current.totalTax, 0);

        return { dispatchType, totalRetailOrderPrice, totalTax }
    });
}

function getVatByPercentage(vatDetailData)
{
    const maxArraylength = 4;       // maxArraylength + 1 vatPercentages at most

    const vatByPercentage = [];

    vatDetailData.forEach(data => 
    {
        let item = vatByPercentage.find(item => item.vatPercentage === data.vatPercentage);

        if (item == null) 
        {
            item = { vatPercentage: data.vatPercentage, totalRetailOrderPrice: 0.0, totalTax: 0.0 }
            vatByPercentage.push(item);
        }

        item.totalRetailOrderPrice += data.totalRetailOrderPrice;
        item.totalTax += data.totalTax;
    });

    arraySort(vatByPercentage, "vatPercentage", { reverse: true });     // vatPercentage is numeric

    if (vatByPercentage.length > maxArraylength) 
    {
        // Aggregate extra data under "Others"
        const totalRetailOrderPrice = vatByPercentage.slice(maxArraylength).reduce((previous, current) => previous + current.totalRetailOrderPrice, 0);
        const totalTax = vatByPercentage.slice(maxArraylength).reduce((previous, current) => previous + current.totalTax, 0);
        vatByPercentage.splice(maxArraylength, Infinity, { vatPercentage: "Others", totalRetailOrderPrice, totalTax });
    }

    return vatByPercentage;
}

function getVatByTop20Categories(vatDetailData)
{
    const vatByCategory = [];

    vatDetailData.forEach(data => 
    {
        let item = vatByCategory.find(item => item.category === data.category);

        if (item == null)
        {
            item = { category: data.category, totalRetailOrderPrice: 0.0, totalTax: 0.0 };
            vatByCategory.push(item);
        }

        item.totalRetailOrderPrice += data.totalRetailOrderPrice;
        item.totalTax += data.totalTax;
    });

    arraySort(vatByCategory, ["totalTax", "totalRetailOrderPrice"], { reverse: true });

    return vatByCategory.slice(0, 20);
}

function getVatByBottom20Categories(vatDetailData)
{
    const vatByCategory = [];

    vatDetailData.forEach(data => 
    {
        let item = vatByCategory.find(item => item.category === data.category);

        if (item == null)
        {
            item = { category: data.category, totalRetailOrderPrice: 0.0, totalTax: 0.0 };
            vatByCategory.push(item);
        }

        item.totalRetailOrderPrice += data.totalRetailOrderPrice;
        item.totalTax += data.totalTax;
    });

    arraySort(vatByCategory, ["totalTax", "totalRetailOrderPrice"]);

    return vatByCategory.slice(0, 20);
}

function getVatByTop20Items(vatDetailData)
{
    const vatByItem = [];

    vatDetailData.forEach(data => 
    {
        let item = vatByItem.find(item =>
            item.caption === data.caption &&
            item.vatPercentage === data.vatPercentage);

        if (item == null)
        {
            item = { caption: data.caption, vatPercentage: data.vatPercentage, totalRetailOrderPrice: 0.0, totalTax: 0.0 };
            vatByItem.push(item);
        }

        item.totalRetailOrderPrice += data.totalRetailOrderPrice;
        item.totalTax += data.totalTax;
    });

    arraySort(vatByItem, ["totalTax", "totalRetailOrderPrice"], { reverse: true });

    return vatByItem.slice(0, 20);
}

function getVatByBottom20Items(vatDetailData)
{
    const vatByItem = [];

    vatDetailData.filter(data => data.totalTax > 0).forEach(data => 
    {
        let item = vatByItem.find(item =>
            item.caption === data.caption &&
            item.vatPercentage === data.vatPercentage);

        if (item == null)
        {
            item = { caption: data.caption, vatPercentage: data.vatPercentage, totalRetailOrderPrice: 0.0, totalTax: 0.0 };
            vatByItem.push(item);
        }

        item.totalRetailOrderPrice += data.totalRetailOrderPrice;
        item.totalTax += data.totalTax;
    });

    arraySort(vatByItem, ["totalTax", "totalRetailOrderPrice"]);

    return vatByItem.slice(0, 20);
}
