import React from "react";

const DetailsSectionContainer = (props) => {
    return (
        <div className="mb-3 me-2 overflow-hidden rounded-1">
            <table width="100%" className="pure-table pure-table-horizontal" style={{ fontSize: "0.9rem" }}>
                {props.children}
            </table>
        </div>
    );
};

export default DetailsSectionContainer;
