const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const stringUtil = require('../../../../../utils/string/stringUtil');

export function createNew () 
{
    return {}
}

export async function saveFromOrgContext(franchisorId, franchiseeId, propertyId, data)
{
    const resourceUrl = `${rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId, propertyId)}/configurations/geoConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function save (data)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/geoConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/geoConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{
    if (!stringUtil.isStringNullOrEmpty(data.rating) && !validator.isGreaterThanOrEqualToZero(data.rating))
        return "Rating must be a valid number greater than or equal to zero";

    if (!stringUtil.isStringNullOrEmpty(data.latitude) && !validator.isNumeric(data.latitude))
        return "Latitude must be a valid number";

    if (!stringUtil.isStringNullOrEmpty(data.longitude) && !validator.isNumeric(data.longitude))
        return "Longitude must be a valid number";

    return null;
}