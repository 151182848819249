import React from 'react';
import { Button } from 'reactstrap';
import AttachmentButton from '../attachment/attachmentButton';

const constants = require('../../utils/domain/constants');
const commonUtility = require('../../utils/domain/commonUtility');
const stringUtil = require('../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../utils/state/actions/actionDispatcherUtil');

export default function EditComponentHeader(props)
{
    var saveButton = null;
    var cancelButton = null;
    const actions = props.actions || [];

    let onCancelClickHandler = props.onCancelClicked
    if(!onCancelClickHandler && props.onCancelClose)
    {
        onCancelClickHandler = () => { actionDispatcherUtil.showOptionsUI(); }
    }

    var isNewRow = props.isNewRow

    if (isNewRow)
    {
        saveButton = <Button color="success" className="btn-success" style={{ marginLeft: '3px', marginRight: '3px' }} onClick={props.onSaveClicked} {...props.saveButtonProps}>
            <i className="fa fa-save"></i>&nbsp;Save
        </Button>;

        cancelButton = <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={onCancelClickHandler} {...props.closeButtonProps} >
            Cancel
        </Button>;
    }
    else
    {
        if (props.isReadOnly)
        {
            saveButton = null;
            cancelButton = <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={onCancelClickHandler} {...props.closeButtonProps} >
                Close
            </Button>;
        } 
        else
        {

            saveButton = <Button color="success" className="btn-success" style={{ marginLeft: '3px', marginRight: '3px' }} onClick={props.onSaveClicked} {...props.saveButtonProps} >
                <i className="fa fa-save"></i>&nbsp;Save
            </Button>;

            cancelButton = <Button color="secondary" className="btn-secondary" style={{ marginRight: '3px' }} onClick={onCancelClickHandler} {...props.closeButtonProps} >
                Cancel
            </Button>;
        }
    }

    let attachmentButton = null;
    if (props.fileParam)
    {
        attachmentButton = <AttachmentButton fileParam={props.fileParam} style={{ marginLeft: '3px'}}></AttachmentButton>
    }

    const actionButtons = [];
    actions.forEach(action =>
    {


        
        actionButtons.push(<Button color={action.color || "info"} style={{ marginRight: '3px' }} onClick={action.onClick}>
            {!stringUtil.isStringNullOrEmpty(action.icon) ? (<i className={action.icon}></i>) : null}&nbsp;{action.caption}
        </Button>);
    })





    var title = props.title;


    return (
        <table width="100%">
            <tr>
                <td>
                    <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{title}</div>
                </td>
                <td align="right">
                    {actionButtons}

                    {attachmentButton}
                    {saveButton}
                    {commonUtility.getApplicationMode() !== constants.applicationModes.embedded ? cancelButton : null}

                </td>
            </tr>

        </table>
    );
}
