
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const stateManager = require('../../../../utils/state/stateManager');


export async function save(overrides)
{
    let resourceUrl = `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/localizations`;
    await rmsApiProxy.post(resourceUrl, overrides);

    const { franchisorId, propertyId } = currentOrgNodeSelectors.selectCurrentOrgNode();

    if (propertyId == null)
    {
        const orgState = stateManager.getStore().getState().orgStates.get(franchisorId);
        orgState.franchisorLocalization = overrides;
    }
    else
    {
        stateManager.getStore().getState().propertyLocalizations.set(propertyId, overrides);
    }
}