
import * as domainConstants from '../../../../utils/domain/constants';

import * as rmsApiProxy from '../../../../utils/api/rmsApiProxy';
import * as rmsApiProxyUtils from '../../../../utils/api/rmsApiProxyUtils';

export async function loadData(fromDate, toDate)
{
    const data = {}

    data.payrollData = await loadPayrollData(fromDate, toDate);
    data.inventoryData = await loadInventoryData(fromDate, toDate);
    data.venueCommissionData = await loadVenueCommissionData(fromDate, toDate);
    data.fixedCostData = await loadFixedCostData(fromDate, toDate);

    return data;
}

async function loadPayrollData(fromDate, toDate)
{
    const resourceUrl =
        `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/accounts/dailyPayrollCosts` +
        `?fromDate=${fromDate}&toDate=${toDate}`;

    const entities = await rmsApiProxy.get(resourceUrl);

    const aggregatedEntities = [];  // payroll data collapsed by date

    entities.forEach(entity => 
    {
        let aggregatedEntity = aggregatedEntities.find(aggregatedEntity =>
            aggregatedEntity.staffId === entity.staffId);

        if (aggregatedEntity == null) 
        {
            aggregatedEntity = {
                staffId: entity.staffId,
                staff: entity.staff,
                scheduledHours: 0.0,
                actualHours: 0.0,
                adjustedHours: 0.0,
                scheduledWages: 0.0,
                actualWages: 0.0,
                adjustedWages: 0.0,
                holidayCost: 0.0,
                bonus: 0.0,
                pensionAndNationalInsurance: 0.0,
                wagesCost: 0.0,
            };

            aggregatedEntities.push(aggregatedEntity);
        }

        aggregatedEntity.scheduledHours += entity.scheduledHours;
        aggregatedEntity.actualHours += entity.actualHours;
        aggregatedEntity.adjustedHours += entity.adjustedHours;

        aggregatedEntity.scheduledWages += entity.scheduledHours * entity.scheduledHourlyWage;
        aggregatedEntity.actualWages += entity.actualHours * entity.actualHourlyWage;
        aggregatedEntity.adjustedWages += entity.adjustedHours * entity.actualHourlyWage;
        aggregatedEntity.holidayCost += entity.holidayHours * entity.holidayHourlyWage;
        aggregatedEntity.bonus += entity.bonus;
        aggregatedEntity.pensionAndNationalInsurance += (entity.employerPensionContribution + entity.employerNIContribution - entity.employerNIAllowance);

        aggregatedEntity.wagesCost += entity.wagesCost;
    });

    return aggregatedEntities;
}

async function loadInventoryData(fromDate, toDate)
{
    const resourceUrl =
        `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/inventory/dailyCogs` +
        `?fromDate=${fromDate}&toDate=${toDate}` +
        '&selectFields=category,totalSalePrice,totalWastagePrice,totalDiscrepancyPrice,cogs';

    const entities = await rmsApiProxy.get(resourceUrl);

    const aggregatedEntities = [];

    entities.forEach(entity => 
    {
        let aggregatedEntity = aggregatedEntities.find(aggregatedEntity =>
            aggregatedEntity.category === entity.category);

        if (aggregatedEntity == null)
        {
            aggregatedEntity = {
                category: entity.category,
                totalSalePrice: 0.0,
                totalWastagePrice: 0.0,
                totalDiscrepancyPrice: 0.0,
                cogs: 0.0
            };

            aggregatedEntities.push(aggregatedEntity);
        }

        aggregatedEntity.totalSalePrice += entity.totalSalePrice;
        aggregatedEntity.totalWastagePrice += entity.totalWastagePrice;
        aggregatedEntity.totalDiscrepancyPrice += entity.totalDiscrepancyPrice;
        aggregatedEntity.cogs += entity.cogs;
    });

    return aggregatedEntities;
}

async function loadVenueCommissionData(fromDate, toDate)
{
    const resourceUrl =
        `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/accounts/dailyRoyaltyByVenue` +
        `?fromDate=${fromDate}&toDate=${toDate}` +
        '&selectFields=venueCode,saleCount,retailOrderPrice,netOrderPrice,netOrderPriceIncTax,venueCommissionPercentage,venueCommission';

    const entities = await rmsApiProxy.get(resourceUrl);

    const aggregatedEntities = [];

    entities.forEach(entity => 
    {
        if (domainConstants.isCloseToZero(entity.venueCommission)) return;

        let aggregatedEntity = aggregatedEntities.find(aggregatedEntity =>
            aggregatedEntity.venueCode === entity.venueCode);

        if (aggregatedEntity == null) 
        {
            aggregatedEntity = {
                venueCode: entity.venueCode,
                saleCount: 0,
                retailOrderPrice: 0.0,
                netOrderPrice: 0.0,
                netOrderPriceIncTax: 0.0,
                // venueCommissionPercentage: entity.venueCommissionPercentage,
                venueCommission: 0.0
            }

            aggregatedEntities.push(aggregatedEntity);
        }

        aggregatedEntity.saleCount += entity.saleCount;
        aggregatedEntity.retailOrderPrice += entity.retailOrderPrice;
        aggregatedEntity.netOrderPrice += entity.netOrderPrice;
        aggregatedEntity.netOrderPriceIncTax += entity.netOrderPriceIncTax;
        aggregatedEntity.venueCommission += entity.venueCommission;
        aggregatedEntity.venueCommissionPercentage = aggregatedEntity.venueCommission / aggregatedEntity.netOrderPriceIncTax * 100;
    });

    return aggregatedEntities;
}

async function loadFixedCostData(fromDate, toDate)
{
    const resourceUrl =
        `${rmsApiProxyUtils.getCurrentReportingContextUrl()}/accounts/dailyFixedCosts` +
        `?fromDate=${fromDate}&toDate=${toDate}` +
        '&selectFields=accountCategory,accountSubCategory,amount,vat';

    const entities = await rmsApiProxy.get(resourceUrl);

    const aggregatedEntities = [];   // fixedCost data collapsed by date

    entities.forEach(entity => 
    {
        let aggregatedEntity = aggregatedEntities.find(aggregatedEntity =>
            aggregatedEntity.accountCategory === entity.accountCategory &&
            aggregatedEntity.accountSubCategory === entity.accountSubCategory);

        if (aggregatedEntity == null) 
        {
            aggregatedEntity = {
                franchisor: entity.franchisor,
                property: entity.property,
                accountCategory: entity.accountCategory,
                accountSubCategory: entity.accountSubCategory,
                amount: 0.0,
                tax: 0.0,
            }

            aggregatedEntities.push(aggregatedEntity);
        }

        aggregatedEntity.amount += entity.amount;
        aggregatedEntity.tax += entity.vat;
    });

    return aggregatedEntities;
}
