const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const validator = require('../../../../../utils/validator/validator');
const stringUtil = require('../../../../../utils/string/stringUtil');

export function createNew () 
{
    return {}
}

export async function save (data)
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/printConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load ()
{
    const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/configurations/printConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{
    if (!stringUtil.isStringNullOrEmpty(data.noOfReceiptPrints) && !validator.isGreaterThanOrEqualToZero(data.noOfReceiptPrints))
        return "No Of Receipt Prints must be a valid number greater than or equal to zero";

    return null;
}