
import React, { Component } from "react";
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';

const FormManager = require('../../../../../../utils/view/formManager');

export default class ApiIntegration extends Component
{
    constructor(props)
    {
        super(props);


        this.state =
        {
            formManager: new FormManager(),
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }
    
    render()
    {
        const entityTypes = [{label: "Sale", value: 'sale'}];

        return (
            <table className='component-table'>
                <tbody>
                    <tr>                    
                        <td width="50%">
                            <InputComponent
                                inputType="code"
                                fieldName="audience"
                                caption="Audience"
                                placeholder="Enter Audience"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="code"
                                fieldName="partner"
                                caption="Partner"
                                placeholder="Enter Partner"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="code"
                                fieldName="clientId"
                                caption="Client ID"
                                placeholder="Enter Client ID"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent
                                inputType="code"
                                fieldName="clientSecret"
                                caption="Client Secret"
                                placeholder="Enter Client Secret"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>                        
                    </tr>
                    <tr>                    
                        <td>
                            <InputComponent
                                inputType="code"
                                fieldName="webHookSecretToken"
                                caption="Webhook Secret Token"
                                placeholder="Enter Webhook Token"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent
                                inputType="code"
                                fieldName="webHookUrl"
                                caption="Webhook URL"
                                placeholder="Enter Webhook URL"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="code"
                                fieldName="grantType"
                                caption="Grant Type"
                                placeholder="Enter Grant Type"
                                hintText="(Required)"
                                inputReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <SelectComponent
                                caption="Entities"
                                fieldName="entities"
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                optionValues={entityTypes}
                                hintText="(Required)"
                                clearable={false}
                                comboReadOnly={false}
                                formManager={this.state.formManager} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

