import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';

import * as reportDataUtils from '../utils/reportDataUtils';

class CogsRawData extends Component
{
    render()
    {
        const { reportData } = this.props;
        const rowData = reportDataUtils.getRawCogsData(reportData);
        const totalsRow = this.getTotalsRow(rowData);

        return (
            <div className="ag-theme-alpine" style={{ height: "80vh" }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={rowData}
                    columnDefs={this.getColumnDefs()}
                    pinnedBottomRowData={[totalsRow]}
                    gridOptions={biUtils.gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    onGridReady = (params) =>
    {
        params.api.eventService.addEventListener('filterChanged', (e) => 
        {
            if (e.api.rowModel?.rowsToDisplay)
            {
                const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
                const totalsRow = this.getTotalsRow(rows);
                e.api.setPinnedBottomRowData([totalsRow]);
            }
        });
    }

    getTotalsRow = (rows) =>
    {
        rows = rows.filter(row => row != null);

        return {
            saleCost: rows.reduce((sum, row) => sum + row.saleCost, 0),
            wastageCost: rows.reduce((sum, row) => sum + row.wastageCost, 0),
            discrepancyCost: rows.reduce((sum, row) => sum + row.discrepancyCost, 0),
            cogs: rows.reduce((sum, row) => sum + row.cogs, 0),
        };
    }

    getColumnDefs = () =>
    {
        const columnDefs = [];

        columnDefs.push({ field: 'businessDate', headerName: 'Date', type: `${dataTypeConstants.date}, rowGroupEnabled`, hide: false });

        columnDefs.push({ field: 'categoryName', headerName: 'Category', type: 'rowGroupEnabled', hide: false });
        columnDefs.push({ field: 'stockItemName', headerName: 'Stock Item', type: 'rowGroupEnabled', hide: false });

        columnDefs.push({ field: 'cogs', headerName: 'COGs', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'saleCost', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'wastageCost', type: `${dataTypeConstants.money}` });
        columnDefs.push({ field: 'discrepancyCost', type: `${dataTypeConstants.money}` });

        columnDefs.push({ field: 'saleQuantity', type: `${dataTypeConstants.number}` });
        columnDefs.push({ field: 'wastageQuantity', type: `${dataTypeConstants.number}` });
        columnDefs.push({ field: 'discrepancyQuantity', type: `${dataTypeConstants.number}` });

        columnDefs.push({ field: 'unitCode', headerName: 'Units' });

        return columnDefs;
    }
}

export default CogsRawData;