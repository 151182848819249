
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import arraySort from 'array-sort';

import GridComponent from '../../../../../../components/grid/gridComponent';
import StockItemOperationalUnitType from './StockItemOperationalUnitType';
import StockItemSections from '../StockItemSections';

const unitUtil = require('../../../../../../utils/domain/unitUtil');
const stringUtil = require('../../../../../../utils/string/stringUtil');
const typeUtil = require('../../../../../../utils/type/typeUtil');
const viewUtil = require('../../../../../../utils/view/viewUtil');
const validator = require('../../../../../../utils/validator/validator');
const formatter = require('../../../../../../utils/formatter/formatter');

const columnWidthConstants = require('../../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../../utils/constants/dataTypeConstants');

class StockItemOperationalUnitTypes extends Component 
{
    constructor(props) 
    {
        // props: {stockItems:(Array) data:(stockItem) isNew:bool isReadOnly:bool isRowReadOnly:bool}
        // isRowReadOnly = cannot edit records is used here

        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);

        this.onAddDataRow = this.onAddDataRow.bind(this);
        this.onRemoveDataRow = this.onRemoveDataRow.bind(this);
        this.validateDataRow = this.validateDataRow.bind(this);
        this.addEditDataRow = this.addEditDataRow.bind(this);
        this.saveDataRow = this.saveDataRow.bind(this);

        this.unitTypes = unitUtil.getPossibleUnitsForStockItem(this.stockItem);
    }

    get stockItem()
    {
        return this.props.data;
    }

    get isRowReadOnly()
    {
        return this.props.isRowReadOnly;
    }

    render()
    {
        return  <StockItemSections title="Operational Units">
                    {this.renderGrid()}
                </StockItemSections>
    }

    renderGrid()
    {
        const deleteButtonDisabled = this.findSelectedRow() == null;

        return (
            <div className="d-flex flex-column">

                {
                    this.isRowReadOnly
                        ? null
                        : <div className="d-flex flex-row justify-content-end pb-3">
                            <Button className="me-2" color="primary" onClick={this.onAddDataRow}>
                                <i className="fa fa-plus"></i>&nbsp;Add Operational Unit
                            </Button>
                            {/* Delete is not allowed due to reference to unit type
                            <Button color="danger" disabled={deleteButtonDisabled} onClick={this.onRemoveDataRow}>
                                <i className="fa fa-remove"></i>&nbsp;Remove Operational Unit
                            </Button> */}
                        </div>
                }

                <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                    <GridComponent
                        headers={this.constructGridColumnHeaders()}
                        rows={arraySort(this.stockItem.operationalUnitTypes, stringUtil.localeCompareProp("operationTypeCode"))}
                        onRowDoubleClicked={this.onRowDoubleClicked}
                        onRowSelected={this.onRowSelected}
                        shouldReArrangeHeaders={true}
                        onGridReady={this.onGridReady} />
                </div>

            </div>
        );
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;

        return selectedNodes[0].data;
    }

    onAddDataRow()
    {
        const operationUnitType = { stockItemId: this.stockItem.id, operationTypeCode: null, unitTypeId: null }
        this.addEditDataRow(true, operationUnitType);
    }

    onRemoveDataRow()
    {
        const operationalUnitType = this.findSelectedRow();
        if (operationalUnitType == null) return;

        viewUtil.showConfirmDialogue(
            'Operational Unit',
            `You are going to delete operational unit type '${operationalUnitType.operationTypeCode}'. Are you sure ?`,
            () =>
            {
                this.stockItem.operationalUnitTypes =
                    this.stockItem.operationalUnitTypes.filter(x => x.operationTypeCode != operationalUnitType.operationTypeCode);
                this.setState({});
            });
    }

    onRowDoubleClicked(eventData)
    {
        const operationalUnitType = this.findSelectedRow();
        if (operationalUnitType == null) return;

        this.addEditDataRow(false, typeUtil.deepCloneObject(operationalUnitType));
    }

    addEditDataRow(isNewRow, operationalUnitType)
    {
        const newOperationalUnitType = typeUtil.deepCloneObject(operationalUnitType);

        viewUtil.openModalForm(
            "Operational Unit Type",
            onFieldChanged =>
                <StockItemOperationalUnitType
                    isNewRow={isNewRow}
                    data={newOperationalUnitType}
                    stockItem={this.stockItem}
                    isRowReadOnly={this.isRowReadOnly} />,
            () => { this.saveDataRow(isNewRow, operationalUnitType, newOperationalUnitType) },
            () => { return this.validateDataRow(isNewRow, newOperationalUnitType) },
            this.isRowReadOnly);
    }

    saveDataRow(isNewRow, originalOperationalUnitType, newOperationalUnitType)
    {
        this.stockItem.operationalUnitTypes =
            this.stockItem.operationalUnitTypes.filter(
                x => !(x.operationTypeCode === originalOperationalUnitType.operationTypeCode &&
                    x.unitTypeId === originalOperationalUnitType.unitTypeId));

        this.stockItem.operationalUnitTypes.push(newOperationalUnitType);
        this.setState({});
    }
    validateDataRow(isNewRow, operationalUnitType)
    {
        if (!validator.isPresent(operationalUnitType.operationTypeCode))
            return "Operation Type cannot be left empty";

        if (!validator.isPresent(operationalUnitType.unitTypeId))
            return "Unit Type cannot be left empty";

        if (isNewRow)
        {
            const existingOperationalUnitType =
                this.stockItem.operationalUnitTypes.find(
                    x => x.operationTypeCode == operationalUnitType.operationTypeCode &&
                        x.unitTypeId == operationalUnitType.unitTypeId);

            if (existingOperationalUnitType != null)
            {
                return `Operation Type '${operationalUnitType.operationTypeCode}' with matching unit already exists`;
            }
        }

        return null;
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.headerName = "Operation Type";
        header.field = "operationTypeCode";
        header.width = columnWidthConstants.code + 100;
        headers.push(header);

        header = {};
        header.colId = "unitType";
        header.headerName = "Unit Type";
        header.valueGetter = params =>
        {
            const unitType = this.unitTypes.find(x => x.id === params.data.unitTypeId);
            return unitType.isSupplementalUnit ? "??" : unitType.code;
        }
        header.width = columnWidthConstants.code + 100;
        headers.push(header);

        header = {};
        header.colId = "coreUnitConversion";
        header.headerValueGetter = params => `Core Unit Conversion (${this.stockItem.coreUnitCode})`;
        header.valueGetter = params => 
        {
            const unitType = this.unitTypes.find(x => x.id === params.data.unitTypeId)
            if (unitType.isSupplementalUnit) return "??";

            const coreUnitCode = this.stockItem.coreUnitCode;
            return `1 ${unitType.code} = ${formatter.roundUp(unitType.coreUnitConversionFactor, 2)} ${coreUnitCode}`;
        };
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default StockItemOperationalUnitTypes;


