import React from 'react';
import Spinner from "react-spinners/GridLoader";

const flipdishTheme = require('../../../../utils/domain/flipdishTheme');

export default function LoadingView(props)
{
    const { mainMessage, subMessage } = props;

    return (
        <div className='w-100 d-flex flex-column justify-content-center align-items-center'>

            <div className='mb-5'>
                <Spinner size={15} color={flipdishTheme.color} />
            </div>
            <div className='mb-1' style={{ color: `${flipdishTheme.color}` }}>{mainMessage}</div>
            <div style={{ fontSize: "0.75em" }}>{subMessage}</div>

        </div>
    );
}