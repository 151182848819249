import React from "react";

const KdsIcon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 96 78" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M47.7568 77.908C35.946 77.908 24.1263 77.6699 12.3243 77.994C4.76759 78.2013 -0.0958232 72.9859 0.00352028 65.7967C0.250775 47.8348 0.250775 29.8619 0.00131265 11.8999C-0.0936155 4.90043 4.97069 -0.0459882 11.7923 0.000322464C35.9504 0.167923 60.1107 0.0311962 84.271 0.0863279C91.5496 0.10397 95.6381 4.31383 95.6514 11.6508C95.6867 30.0647 95.6845 48.4765 95.6514 66.8905C95.6404 73.4997 91.4657 77.8022 84.8031 77.8595C72.4558 77.9632 60.1063 77.8904 47.759 77.8904C47.759 77.897 47.759 77.9014 47.759 77.908H47.7568ZM39.2331 73.4953C40.0809 73.6055 40.5114 73.7114 40.9441 73.7114C55.6116 73.7202 70.2769 73.7511 84.9444 73.7004C88.9446 73.6871 91.5165 70.796 91.5209 66.5046C91.5364 48.2803 91.5364 30.0559 91.5209 11.8316C91.5165 6.8565 88.896 4.27413 83.8891 4.26972C70.0274 4.25649 56.1657 4.32926 42.304 4.20797C39.7939 4.18592 39.1735 4.94894 39.1846 7.37033C39.2729 28.4527 39.2354 49.5373 39.2354 70.6196V73.4975L39.2331 73.4953ZM36.6613 39.2541C36.6613 28.5255 36.6061 17.799 36.7054 7.07041C36.7253 4.81883 35.9857 4.17931 33.7803 4.21459C26.4443 4.33588 19.104 4.24105 11.7658 4.26752C7.00392 4.28516 4.3371 6.9822 4.33269 11.7853C4.31944 29.9324 4.31723 48.0818 4.33269 66.2289C4.3371 71.0254 7.02379 73.7048 11.7901 73.7158C19.0399 73.7356 26.2898 73.6166 33.5352 73.7798C36.0232 73.8349 36.7385 73.0851 36.7098 70.6306C36.5906 60.1733 36.659 49.7115 36.659 39.2519L36.6613 39.2541Z"
                    fill="#3B3B3B"
               />
               <path d="M28.8846 13.4788H11.9932C11.1041 13.4788 10.3853 14.1707 10.3853 15.0257C10.3853 15.8807 11.1045 16.5722 11.9932 16.5722H28.8846C29.7737 16.5722 30.4929 15.8803 30.4929 15.0257C30.4929 14.1711 29.7737 13.4788 28.8846 13.4788Z" fill="#3C3C3C" />
               <path d="M17.8353 31.1558H8.90105C8.01123 31.1558 7.29187 31.8477 7.29187 32.7027C7.29187 33.5577 8.01162 34.2492 8.90105 34.2492H17.8353C18.7251 34.2492 19.4448 33.5573 19.4448 32.7027C19.4448 31.8481 18.7251 31.1558 17.8353 31.1558Z" fill="#3C3C3C" />
               <path d="M32.1978 31.1558H23.2636C22.3738 31.1558 21.6544 31.8477 21.6544 32.7027C21.6544 33.5577 22.3742 34.2492 23.2636 34.2492H32.1978C33.0876 34.2492 33.8074 33.5573 33.8074 32.7027C33.8074 31.8481 33.0876 31.1558 32.1978 31.1558Z" fill="#3C3C3C" />
               <path d="M17.8353 41.9829H8.90105C8.01123 41.9829 7.29187 42.7243 7.29187 43.6403C7.29187 44.5564 8.01162 45.2974 8.90105 45.2974H17.8353C18.7251 45.2974 19.4448 44.556 19.4448 43.6403C19.4448 42.7247 18.7251 41.9829 17.8353 41.9829Z" fill="#3C3C3C" />
               <path d="M32.1978 41.9829H23.2636C22.3738 41.9829 21.6544 42.7243 21.6544 43.6403C21.6544 44.5564 22.3742 45.2974 23.2636 45.2974H32.1978C33.0876 45.2974 33.8074 44.556 33.8074 43.6403C33.8074 42.7247 33.0876 41.9829 32.1978 41.9829Z" fill="#3C3C3C" />
               <path d="M17.8353 52.3684H8.90105C8.01123 52.3684 7.29187 53.1098 7.29187 54.0258C7.29187 54.9419 8.01162 55.6829 8.90105 55.6829H17.8353C18.7251 55.6829 19.4448 54.9415 19.4448 54.0258C19.4448 53.1102 18.7251 52.3684 17.8353 52.3684Z" fill="#3C3C3C" />
               <path d="M32.1978 52.3684H23.2636C22.3738 52.3684 21.6544 53.1098 21.6544 54.0258C21.6544 54.9419 22.3742 55.6829 23.2636 55.6829H32.1978C33.0876 55.6829 33.8074 54.9415 33.8074 54.0258C33.8074 53.1102 33.0876 52.3684 32.1978 52.3684Z" fill="#3C3C3C" />
               <path d="M61.5859 31.1558H52.6517C51.7618 31.1558 51.0425 31.8477 51.0425 32.7027C51.0425 33.5577 51.7622 34.2492 52.6517 34.2492H61.5859C62.4757 34.2492 63.1955 33.5573 63.1955 32.7027C63.1955 31.8481 62.4757 31.1558 61.5859 31.1558Z" fill="#3C3C3C" />
               <path d="M75.9484 31.1558H67.0142C66.1244 31.1558 65.405 31.8477 65.405 32.7027C65.405 33.5577 66.1248 34.2492 67.0142 34.2492H75.9484C76.8382 34.2492 77.558 33.5573 77.558 32.7027C77.558 31.8481 76.8382 31.1558 75.9484 31.1558Z" fill="#3C3C3C" />
               <path d="M61.5859 41.9829H52.6517C51.7618 41.9829 51.0425 42.7243 51.0425 43.6403C51.0425 44.5564 51.7622 45.2974 52.6517 45.2974H61.5859C62.4757 45.2974 63.1955 44.556 63.1955 43.6403C63.1955 42.7247 62.4757 41.9829 61.5859 41.9829Z" fill="#3C3C3C" />
               <path d="M75.9484 41.9829H67.0142C66.1244 41.9829 65.405 42.7243 65.405 43.6403C65.405 44.5564 66.1248 45.2974 67.0142 45.2974H75.9484C76.8382 45.2974 77.558 44.556 77.558 43.6403C77.558 42.7247 76.8382 41.9829 75.9484 41.9829Z" fill="#3C3C3C" />
               <path d="M61.5859 52.3684H52.6517C51.7618 52.3684 51.0425 53.1098 51.0425 54.0258C51.0425 54.9419 51.7622 55.6829 52.6517 55.6829H61.5859C62.4757 55.6829 63.1955 54.9415 63.1955 54.0258C63.1955 53.1102 62.4757 52.3684 61.5859 52.3684Z" fill="#3C3C3C" />
               <path d="M75.9484 52.3684H67.0142C66.1244 52.3684 65.405 53.1098 65.405 54.0258C65.405 54.9419 66.1248 55.6829 67.0142 55.6829H75.9484C76.8382 55.6829 77.558 54.9415 77.558 54.0258C77.558 53.1102 76.8382 52.3684 75.9484 52.3684Z" fill="#3C3C3C" />
               <path d="M72.8561 13.4788H55.9648C55.0756 13.4788 54.3568 14.1707 54.3568 15.0257C54.3568 15.8807 55.076 16.5722 55.9648 16.5722H72.8561C73.7453 16.5722 74.4645 15.8803 74.4645 15.0257C74.4645 14.1711 73.7453 13.4788 72.8561 13.4788Z" fill="#3C3C3C" />
          </svg>
     );
};

export default KdsIcon;
