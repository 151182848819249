import React from 'react';
import {ListGroup} from 'reactstrap';
import LogGroup from './logGroup';


export default function LogViewer(props) {

    const logsByGroup = {};

    props.logs.forEach(log => {
        const key = log.channelContext ? `Storefront Logs for ${log.channelContext.channel}` : log.message;
        logsByGroup[key] = logsByGroup[key] || [];
        logsByGroup[key].push(log);
    });

    const tableBody = Object.entries(logsByGroup).map(([key, logs]) => (
        <LogGroup key={key} color={props.color} caption={key} logs={logs} isDefaultOpen={props.isDefaultOpen} />
    ));


    return (
            <ListGroup>
                {tableBody}
            </ListGroup>
    )
}
