
import React, { Component } from 'react';
class Jumbotron extends Component
{
    render()
    {
        return <div className="mt-4 p-5 text-white rounded" style={{background:'#A9A9A9'}}>
                    {this.props.children}
                </div>
    }
}

export default Jumbotron;