

const unitUtil = require('../../../domain/unitUtil')


export function getNutritionQuantity(nutritionCode, stockItem, stockItemsLookupData, dispatchType = null)
{
    if (stockItem.nutritionProfile)
    {
        const nutrition = stockItem.nutritionProfile.nutritions.find(x => x.nutritionCode === nutritionCode);
        if (nutrition)
        {
            return nutrition.quantity;
        }
    }

    if (stockItem.recipeProfile)
    {
        return deriveNutritionFromRecipe(nutritionCode, stockItem, stockItemsLookupData, dispatchType);
    }
}

export function getIngredientNutritionQuantity(nutritionCode, ingredientStockItem, recipeItem, stockItemsLookupData, dispatchType = null)
{
    if (!ingredientStockItem.nutritionProfile)
    {
        return NaN;
    }

    const ingredientRatio = getIngredientNutritionRatio(ingredientStockItem, recipeItem);
    const ingredientNutritionQuantity = getNutritionQuantity(nutritionCode, ingredientStockItem, stockItemsLookupData, dispatchType);

    const quantity = (ingredientRatio * ingredientNutritionQuantity);
    return quantity;
}

function deriveNutritionFromRecipe(nutritionCode, stockItem, stockItemsLookupData, dispatchType = null)
{
    if (!stockItem.recipeProfile || stockItem.recipeProfile.recipeItems.length == 0)
    {
        return NaN;
    }

    const recipeItemsByDispatchType =
        stockItem.recipeProfile.recipeItems.filter(recipeItem =>
            recipeItem.dispatchTypes == null ||
            (dispatchType && recipeItem.dispatchTypes.includes(dispatchType)));

    if (recipeItemsByDispatchType.length === 0) 
    {
        return NaN;
    }

    let quantity = 0;

    recipeItemsByDispatchType.forEach(recipeItem => 
    {
        if (isNaN(quantity))
        {
            return;
        }

        const ingredientStockItem = stockItemsLookupData.find(x => x.id === recipeItem.ingredientStockItemId);
        const ingredientQuantity = getIngredientNutritionQuantity(nutritionCode, ingredientStockItem, recipeItem, stockItemsLookupData, dispatchType);
        quantity += ingredientQuantity;
    })

    if (stockItem.nutritionProfile && stockItem.nutritionProfile.unitTypeId && stockItem.nutritionProfile.baseQuantity)
    {
        const stockItemQty = unitUtil.convertToStockItemCoreUnits(
            stockItem, stockItem.nutritionProfile.unitTypeId, stockItem.nutritionProfile.baseQuantity);
        quantity = quantity * stockItemQty;
    }

    return quantity;
}

function getIngredientNutritionRatio(ingredientStockItem, recipeItem)
{
    const ingredientQuantityInCoreUnits =
        unitUtil.convertToStockItemCoreUnits(
            ingredientStockItem, recipeItem.unitTypeId, recipeItem.quantity);

    let ingredientBaseQuantityInCoreUnits = NaN;

    if (ingredientStockItem.nutritionProfile)
    {
        ingredientBaseQuantityInCoreUnits =
            unitUtil.convertToStockItemCoreUnits(
                ingredientStockItem, ingredientStockItem.nutritionProfile.unitTypeId, ingredientStockItem.nutritionProfile.baseQuantity);
    }
    else if (ingredientStockItem.recipeProfile)
    {
        ingredientBaseQuantityInCoreUnits =
            unitUtil.convertToStockItemCoreUnits(
                ingredientStockItem, ingredientStockItem.recipeProfile.unitTypeId, ingredientStockItem.recipeProfile.baseQuantity);
    }

    const ingredientRatio = ingredientQuantityInCoreUnits / ingredientBaseQuantityInCoreUnits;
    return ingredientRatio;
}
