
const dateUtil = require('../../../../utils/dateUtil/dateUtil');
const stringUtil = require('../../../../utils/string/stringUtil');
const formatter = require('../../../../utils/formatter/formatter');
const securityManager = require('../../../../utils/domain/security/securityManager');

module.exports.isEditingAllowed = function()
{
    if (securityManager.isSupportUser()) 
    {
        return securityManager.isSupportElevatedUser();
    }

    return true;
}

module.exports.calculateDifferenceInHours = function (start, end)
{
    if (stringUtil.isStringNullOrEmpty(start) || stringUtil.isStringNullOrEmpty(end))
        return Number.NaN;

    return formatter.roundUp(dateUtil.differenceInHours(end, start), 2);        //Forcing rounding up to match with payroll run which does in SQL level.
}

module.exports.calculateCost = function (start, end, hourlyWage)
{
    var differenceInHours = this.calculateDifferenceInHours(start, end);
    if (differenceInHours == Number.NaN)
        return Number.NaN;

    return differenceInHours * hourlyWage;
}

module.exports.compileDateCaption = function(dateTime)
{
    if (stringUtil.isStringNullOrEmpty(dateTime))
        return "N/A";

    return dateUtil.formatDate(dateUtil.convertToLocalStandard(dateTime), "HH:mm");
}

module.exports.compileDateTooltip = function(dateTime)
{
    if (stringUtil.isStringNullOrEmpty(dateTime))
        return "N/A";

    return dateUtil.formatDate(dateUtil.convertToLocalStandard(dateTime), "ddd DD/MMM HH:mm");
}

module.exports.compileCostCaption = function(start, end, hourlyWage)
{

    if (stringUtil.isStringNullOrEmpty(start) || stringUtil.isStringNullOrEmpty(end))
        return "N/A";

    var differenceInHours = this.calculateDifferenceInHours(start, end);
    var cost = this.calculateCost(start, end, hourlyWage);

    if (differenceInHours != Number.NaN)
        return `${formatter.roundUp(differenceInHours, 2)} hours / ${formatter.convertToCurrencyFormat(cost)}`;

    return "N/A";
}

module.exports.calculateRotaCost  = function(rota)
{
    return this.calculateCost(rota.start, rota.end, rota.hourlyWage);
}

module.exports.calculateRotaHours  = function(rota)
{
    return this.calculateDifferenceInHours(rota.start, rota.end);
}

module.exports.calculateAttendanceActualCost = function(attendance)
{
    return this.calculateCost(attendance.start, attendance.end, attendance.hourlyWage);
}


module.exports.calculateAttendanceAdjustedCost = function(attendance)
{
    return this.calculateCost(attendance.adjustedStart, attendance.adjustedEnd, attendance.hourlyWage);
}

module.exports.calculateAttendanceAdjustedHours = function(attendance)
{
    return this.calculateDifferenceInHours(attendance.adjustedStart, attendance.adjustedEnd);
}


module.exports.compileRotaDateCaption = function(rota)
{
    return this.compileDateCaption(rota.start) + " - " + this.compileDateCaption(rota.end);
}

module.exports.compileRotaDateTooltip = function(rota)
{
    return this.compileDateTooltip(rota.start) + " - " + this.compileDateTooltip(rota.end);
}

module.exports.compileRotaCostCaption = function(rota)
{
    return this.compileCostCaption(rota.start, rota.end, rota.hourlyWage);
}

module.exports.compileAttendanceActualDateCaption = function(attendance)
{
    return this.compileDateCaption(attendance.start) + " - " + this.compileDateCaption(attendance.end);
}

module.exports.compileAttendanceAdjustedDateCaption = function(attendance)
{
    return this.compileDateCaption(attendance.adjustedStart) + " - " + this.compileDateCaption(attendance.adjustedEnd);
}


module.exports.compileAttendanceActualCostCaption = function(attendance)
{
    return this.compileCostCaption(attendance.start, attendance.end, attendance.hourlyWage);
}

module.exports.compileAttendanceAdjustedCostCaption = function(attendance)
{
    return this.compileCostCaption(attendance.adjustedStart, attendance.adjustedEnd, attendance.hourlyWage);
}

module.exports.calculatedAggregatedCost = function (predicateForRota, predicateForAttendance, rotas, attendances)
{

    var totalRotaHours = 0;
    var totalRotaCost = 0;

    var totalActualAttendanceHours = 0;
    var totalActualAttendanceCost = 0;
    var totalAdjustedAttendanceHours = 0;
    var totalAdjustedAttendanceCost = 0;

    rotas.forEach (rota =>
    {
        if (!predicateForRota(rota))
            return;

        var rotaHours = this.calculateDifferenceInHours(rota.start,rota.end);
        var rotaCost = this.calculateCost(rota.start,rota.end, rota.hourlyWage);

        if (rotaHours != Number.NaN)
            totalRotaHours = totalRotaHours + rotaHours;

        if (rotaCost != Number.NaN)
            totalRotaCost = totalRotaCost + rotaCost;
    });

    attendances.forEach (attendance =>
    {
        if (!predicateForAttendance(attendance))
            return;

        var actualAttendanceHours = this.calculateDifferenceInHours(attendance.start, attendance.end);
        var actualAttendanceCost = this.calculateCost(attendance.start, attendance.end, attendance.hourlyWage);

        var adjustedAttendanceHours = this.calculateDifferenceInHours(attendance.adjustedStart, attendance.adjustedEnd);
        var adjustedAttendanceCost = this.calculateCost(attendance.adjustedStart, attendance.adjustedEnd, attendance.hourlyWage);
    
        if (!isNaN(actualAttendanceHours))
            totalActualAttendanceHours = totalActualAttendanceHours + actualAttendanceHours;

        if (!isNaN(actualAttendanceCost))
            totalActualAttendanceCost = totalActualAttendanceCost + actualAttendanceCost;

        if (!isNaN(adjustedAttendanceHours))
            totalAdjustedAttendanceHours = totalAdjustedAttendanceHours + adjustedAttendanceHours;

        if (!isNaN(adjustedAttendanceCost))
            totalAdjustedAttendanceCost = totalAdjustedAttendanceCost + adjustedAttendanceCost;
        
    });

    return {
                rotaHours: totalRotaHours,
                rotaCost: totalRotaCost,

                actualAttendanceHours: totalActualAttendanceHours,
                actualAttendanceCost: totalActualAttendanceCost,
                adjustedAttendanceHours: totalAdjustedAttendanceHours,
                adjustedAttendanceCost: totalAdjustedAttendanceCost,

        }
}

module.exports.calculatedAggregatedCostForDate = function (date, rotas, attendances)
{
    return this.calculatedAggregatedCost((rota) =>
                                         {
                                            return dateUtil.isDateComponentSame(date, rota.start)
                                         },
                                         (attendance) =>
                                         {
                                            return dateUtil.isDateComponentSame(date, attendance.adjustedStart) && !attendance.isVoided
                                         }, rotas, attendances);
    
}

module.exports.calculatedAggregatedCostForStaff = function (staff, rotas, attendances)
{
    return this.calculatedAggregatedCost((rota) =>
                                         {
                                            return rota.staffId == staff.id
                                         },
                                         (attendance) =>
                                         {
                                            return attendance.staffId == staff.id && !attendance.isVoided
                                         }, rotas, attendances);
}

module.exports.calculatedAggregatedCostForPeriod = function (rotas, attendances)
{
    return this.calculatedAggregatedCost((rota) =>
                                         {
                                            return true;
                                         },
                                         (attendance) =>
                                         {
                                            return !attendance.isVoided
                                         }, rotas, attendances);
}

module.exports.getProjectedSales = function (date, projectedSales, projectionOverrides)
{
    const projectionOverride = projectionOverrides.find(projection => dateUtil.isDateComponentSame(date, projection.date));
    if (projectionOverride?.totalOverriddenGrossSales != null) return projectionOverride.totalOverriddenGrossSales;

    const projectedSale = projectedSales.totalProjection.projections.find(projection => dateUtil.isDateComponentSame(date, projection.date));
    if (projectedSale?.grossSales != null) return projectedSale.grossSales;

    return 'N/A';
}

module.exports.getSales = function (date, dailySales)
{        
    const sale = dailySales.find(sale => dateUtil.isDateComponentSame(dateUtil.getDateComponent(dateUtil.convertFromISODateTimeFormat(sale.date)), date))
    if(sale != null)
    {
        return sale.salePriceIncTax;
    }

    return 0;
}
