import React, { Component } from "react";
import GridView from '../../../../components/gridView/gridView'
import GridViewButton from '../../../../components/gridView/gridViewButton';
import DiscountOffer from './components/DiscountOffer'

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../components/gridView/gridViewOptions.js');
const stringUtil = require('../../../../utils/string/stringUtil.js');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const typeUtil = require('../../../../utils/type/typeUtil.js');
const validator = require('../../../../utils/validator/validator.js');
const domainConstants = require("../../../../utils/domain/constants");
const constants = require('../../../../utils/domain/constants.js');

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

class DiscountOffers extends Component
{
    constructor(props)
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadDiscountOffersAsync = this.loadDiscountOffersAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render()
    {
        var gridViewOptions = new GridViewOptions();
        gridViewOptions.title = "Discount Offers";

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadDiscountOffersAsync;

        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;
        gridViewOptions.getRowData = this.loadDiscountOffers;

        gridViewOptions.getPrimaryKeyValue = (row) => row.code;
        gridViewOptions.getNewRow = 
        () =>
        {
            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
            var newRow = {};
            newRow.franchisorId = currentOrgNode.franchisorId;
            newRow.isEnabled = true;
            newRow.isAvailable = true;
            newRow.minimumOrderAmount = 0;
            newRow.paramsJson = null;
            newRow.propertyDiscountOfferOverrides = [];
            return newRow;
        };

        gridViewOptions.isReadOnly = this.props.isReadOnly;

        gridViewOptions.buttons = [this.getDeleteDiscountOfferActionButton()];

        gridViewOptions.getComponent = (isNew, row) =>
            <DiscountOffer
                isNew={isNew}
                data={row}
                isReadOnly={this.props.isReadOnly}
            />;

        gridViewOptions.getComponentTitle = (isNew, row) => { return isNew ? 'Discount Offer - New' : `Discount Offer - ${row.code}` }
        gridViewOptions.isRowReadOnly = (row) => this.props.isReadOnly;

        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew)
                return "Please wait while adding new discount Offer.";

            return "Please wait while updating discount Offer."
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew)
                return "New discount offer is added successfully.";

            return "Discount Offer is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew)
                return "Following error occurred while adding new discount offer:";

            return "Following error occurred while updating discount offer:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    validateRow(isNew, discountOffer)
    {
        if (stringUtil.isStringNullOrEmpty(discountOffer.code))
            return "Code cannot be left empty";

        if (stringUtil.isStringNullOrEmpty(discountOffer.discountOfferType))
            return "Discount Offer Type cannot be left empty";

        if (!validator.isGreaterThanZero(discountOffer.value))
            return "Value is not specified or is less than zero";

        if (!validator.isGreaterThanOrEqualToZero(discountOffer.minimumOrderAmount))
            return "Minimum order amount is not specified or is less than zero";

        if (typeUtil.isUndefined(discountOffer.isAvailable) || discountOffer.isAvailable == null)
            return "Discount isAvailable is not specified";

        if (typeUtil.isUndefined(discountOffer.isEnabled) || discountOffer.isEnabled == null)
            return "Discount Enabled is not specified";

        if (discountOffer.discountOfferType == constants.DiscountOfferTypes.Percentage && validator.isGreaterThan(discountOffer.value, 100))
            return "Value cannot be greater than 100 for Discount Type: Percentage";

        if (!validator.isArray(discountOffer.propertyDiscountOfferOverrides))
            return "propertyDiscountOfferOverrides must be array";

        for (const override of discountOffer.propertyDiscountOfferOverrides)
        {
            if (!validator.isPresent(override.discountOfferCode) || override.discountOfferCode !== discountOffer.code)
                return "Invalid discountOfferCode in one of the propertyDiscountOfferOverrides";

            if (!validator.isPresent(override.propertyId))
                return "propertyId is missing in one of the propertyDiscountOfferOverrides";

            if (typeUtil.isUndefined(override.isEnabled) || override.isEnabled == null)
                return "isEnabled is missing in one of the propertyDiscountOfferOverrides";

            if (override.isEnabled !== !discountOffer.isEnabled)
                return "isEnabled is invalid in one of the propertyDiscountOfferOverrides";
        }

        return null;
    }

    async save(isNew, row, remoteData)
    {
        //#region Stringify paramsJson

        //
        // row.paramsJson is a json object
        // paramsJson is saved in the row object/data table as string
        //

        try
        {
            row.paramsJson = JSON.stringify(row.paramsJson);

            const updatedDiscountOffer = await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/promotions/discountOffers`, row);
            this.enrichDiscountOffer(updatedDiscountOffer);
            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(offer => offer.code == updatedDiscountOffer.code);

            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedDiscountOffer);
            else
                remoteData.push(updatedDiscountOffer);

            const response = {};
            response.remoteData = arraySort(remoteData, stringUtil.localeCompareProp("code"));
            response.addUpdatedRow = updatedDiscountOffer;

            return response;

        } catch (error)
        {
            row.paramsJson = null;
            throw error;
        }
    }    

    getDeleteDiscountOfferActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Delete Discount Offer";
        button.color = "danger";

        button.onRowSelect = true;
        button.actionType = "action";

        button.actionConfirmationTitle = "Delete Discount Offer";
        button.onActionConfirmationMessage = (selectedDiscountOffer) => 
        {
            return `Do you wish to delete selected discount offer "${selectedDiscountOffer.code}" ?`
        }

        button.onActionWaitMessage = (selectedDiscountOffer, customButton) => "Please wait while deleting discount offer ...";
        button.onActionSuccessMessage = (selectedDiscountOffer, customButton) => "Selected discount offer deleted successfully";
        button.onActionFailMessage = (selectedDiscountOffer, customButton, error) => error.toString();

        button.onAction = async (selectedDiscountOffer, remoteData, customButton) =>
        {
            if (this.props.isReadOnly)
                throw new Error("Permission denied, you are not authorized to delete this discount offer !");

            const discountOfferCode = selectedDiscountOffer.code;

            try
            {
                const resourceUrl = encodeURI(`${rmsApiProxy.getFranchisorOrgContextUrl()}/promotions/discountOffers/${discountOfferCode}`);
                await rmsApiProxy.deleted(resourceUrl);

                if (!remoteData) remoteData = [];

                const existingIndex = remoteData.findIndex(discountOffer => discountOffer.code === discountOfferCode);
                remoteData.splice(existingIndex, 1);

                const response = {};
                response.remoteData = remoteData;
                response.addUpdatedRow = null;

                return response;

            } catch (error)
            {
                throw new Error(`Failed to delete discount offer with error: ${error}`);
            }
        }

        return button;
    }

    async loadDiscountOffersAsync()
    {
        const discountOffers = await apiLoadFacade.loadDiscountOffers();
        discountOffers.forEach(discountOffer => this.enrichDiscountOffer(discountOffer));
        return discountOffers;
    }

    enrichDiscountOffer(discountOffer)
    {
        // Adds exclusionTags (comma separated discount tags) 

        let exclusionTags = "";

        if (discountOffer.paramsJson) 
        {
            try 
            {
                const paramsJson = JSON.parse(discountOffer.paramsJson);
                exclusionTags = paramsJson.tags[domainConstants.propertyNames.discountExclusionTags];
            }
            catch { }
        }

        discountOffer.exclusionTags = exclusionTags;
    }

    constructGridColumnHeaders()
    {
        var headers = [];
        var header = {};

        //TODO: field type and width

        header = {};
        header.field = "code";
        header.headerName = "Code";
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "discountOfferType";
        header.headerName = "Type";
        header.valueFormatter = (params) => params.value ? constants.getDiscountOfferTypes().find(x => x.value === params.value).label : "";
        headers.push(header);

        header = {};
        header.field = "value";
        header.headerName = "Value";
        headers.push(header);

        header = {};
        header.field = "minimumOrderAmount";
        header.headerName = "Minimum Order Amount";
        header.width = columnWidthConstants.money;
        header.type = dataTypeConstants.money;
        headers.push(header);

        header = {};
        header.field = "isEnabled";
        header.headerName = "Enabled";
        header.width = columnWidthConstants.boolean;
        header.type = dataTypeConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "isAvailable";
        header.headerName = "Available";
        header.width = columnWidthConstants.boolean;
        header.type = dataTypeConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "exclusionTags";
        header.headerName = "Discount Exclusion Tags";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default DiscountOffers;