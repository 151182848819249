import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import CampaignMessage from '../../../components/CampaignMessage';
import campaignUtil from '../../../utils/campaignUtil';


const constants = require('../../../../../../../utils/domain/constants');
const stringUtil = require('../../../../../../../utils/string/stringUtil');


export default class CampaignMessageStep extends Component 
{
    render() 
    {
        const campaign = this.props.data;

        const message = <CampaignMessage
                onChange={this.props.onChange}
                data={campaign.profile.message} />

        return <Card>
            <CardHeader>
                Define Campaign Message
            </CardHeader>
            <CardBody>
                {message}
            </CardBody>
            <CardFooter>
                {this.renderNavigation()}
            </CardFooter>
        </Card>;
    }

    renderNavigation()
    {
        const campaign = this.props.data;

        const nextEnabled = stringUtil.isStringNullOrEmpty(campaignUtil.validateMessage(campaign));

        return (<table width="100%">
            <tr>
                <td align="right">
                    <Button color="secondary" style={{ marginRight: '10px' }} onClick={this.props.previousStep}>Back</Button>
                    <Button color="success" disabled={!nextEnabled} onClick={this.props.nextStep}>Next</Button>
                </td>
            </tr>
        </table>);
    }
}
