import { CreditTypes, DeviceTypes, LabelPrinterTypes, ReceiptPrinterTypes } from "../constants";
const constants = require('../../../../utils/domain/constants');

export const deviceFieldsConfiguration = {
     [DeviceTypes.receiptPrinter]: [
          {
               label: "Printer Name",
               hintText: "Enter printer name (Required)",
               fieldName: "deviceName",
               type: "input:text",
               defaultValue: null,
               fieldProps: {
                    readOnly: false,
                    required: true,
                    placeholder: "Enter Printer Name",
               },
          },
          {
               label: "Receipt Printer Type",
               hintText: "Select receipt printer type",
               fieldName: "receiptPrinterType",
               type: "select",
               defaultValue: ReceiptPrinterTypes.genericUSB,
               fieldProps: {
                    options: Object.values(ReceiptPrinterTypes).map((value) => ({
                         label: value,
                         value: value,
                    })),
                    required: true,
               },
               subFields: {
                    [ReceiptPrinterTypes.genericUSB]: [
                         {
                              label: "System Name",
                              hintText: "Enter printer name (as registered in system)",
                              fieldName: "systemName",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter system printer name",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                         {
                              label: "No Of Columns",
                              hintText: "Enter no of columns printer",
                              fieldName: "noOfColumns",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter no of columns printer",
                              },
                         },
                         {
                              label: "Logo Require Centering",
                              hintText: "Indicates if logo required to be centered",
                              fieldName: "logoRequireCentering",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Logo Size",
                              hintText: "Enter logo size",
                              fieldName: "logoSize",
                              type: "input:text",
                              defaultValue: "0",
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter logo size",
                              },
                         },
                         {
                              label: "Logo Hex Code",
                              hintText: "Enter logo hex code",
                              fieldName: "logoHexCode",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter logo hex code",
                              },
                         },
                         {
                              label: "Logo File Path",
                              hintText: "Enter logo file path",
                              fieldName: "logoFilePath",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter logo file path",
                              },
                         },
                    ],
                    [ReceiptPrinterTypes.genericBluetooth]: [
                         {
                              label: "Bluetooth Address",
                              hintText: "Enter bluetooth address",
                              fieldName: "bluetoothAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter bluetooth address",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                    ],
                    [ReceiptPrinterTypes.genericNetwork]: [
                         {
                              label: "IP Address",
                              hintText: "Enter IP address",
                              fieldName: "ipAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter IP address",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                    ],
                    [ReceiptPrinterTypes.sunmiCloudPrinter]: [
                         {
                              label: "Serial Number",
                              hintText: "Enter serial number",
                              fieldName: "serialNumber",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter Serial number",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: "UTF-8 / 28,67,255",
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                    ],
               },
          },
     ],
     [DeviceTypes.labelPrinter]: [
          {
               label: "Printer Name",
               hintText: "Enter printer name (Required)",
               fieldName: "deviceName",
               type: "input:text",
               defaultValue: null,
               fieldProps: {
                    readOnly: false,
                    required: true,
                    placeholder: "Enter Printer Name",
               },
          },
          {
               label: "Label Printer Type",
               hintText: "Select label printer type",
               fieldName: "labelPrinterType",
               type: "select",
               defaultValue: LabelPrinterTypes.genericUSB,
               fieldProps: {
                    options: Object.values(LabelPrinterTypes).map((value) => ({
                         label: value,
                         value: value,
                    })),
                    required: true,
               },
               subFields: {
                    [LabelPrinterTypes.genericUSB]: [
                         {
                              label: "System Name",
                              hintText: "Enter printer name (as registered in system)",
                              fieldName: "systemName",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter system printer name",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Normal Font Column Size (ESC/POS Font A)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 48 columns.\n Please specify normal Font A column size if your printer is different from the default.",
                              fieldName: "normalFontAColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter normal font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                         {
                              label: "Condensed Font Column Size (ESC/POS Font B)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 64 columns.\n Please specify condensed Font B column size if your printer is different from the default.",
                              fieldName: "condensedFontBColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter condensed font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },           
                    ],
                    [LabelPrinterTypes.genericBluetooth]: [
                         {
                              label: "Bluetooth Address",
                              hintText: "Enter bluetooth address",
                              fieldName: "bluetoothAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter bluetooth address",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Normal Font Column Size (ESC/POS Font A)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 48 columns.\n Please specify normal Font A column size if your printer is different from the default.",
                              fieldName: "normalFontAColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter normal font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                         {
                              label: "Condensed Font Column Size (ESC/POS Font B)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 64 columns.\n Please specify condensed Font B column size if your printer is different from the default.",
                              fieldName: "condensedFontBColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter condensed font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                    ],
                    [LabelPrinterTypes.genericNetwork]: [
                         {
                              label: "IP Address",
                              hintText: "Enter IP address",
                              fieldName: "ipAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter IP address",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Normal Font Column Size (ESC/POS Font A)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 48 columns.\n Please specify normal Font A column size if your printer is different from the default.",
                              fieldName: "normalFontAColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter normal font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                         {
                              label: "Condensed Font Column Size (ESC/POS Font B)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 64 columns.\n Please specify condensed Font B column size if your printer is different from the default.",
                              fieldName: "condensedFontBColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter condensed font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                    ],
                    [LabelPrinterTypes.sunmiCloudPrinter]: [
                         {
                              label: "Serial Number",
                              hintText: "Enter serial number",
                              fieldName: "serialNumber",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter Serial number",
                              },
                         },
                         {
                              label: "Encoding Type",
                              hintText: null,
                              fieldName: "encodingType",
                              type: "select",
                              defaultValue: "UTF-8 / 28,67,255",
                              fieldProps: {
                                   options: [
                                        {
                                             label: "UTF-8 / 28,67,255",
                                             value: "UTF-8 / 28,67,255",
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Is Legacy Printer",
                              hintText: "Legacy printers don't support some features like reverse printing",
                              fieldName: "isLegacyPrinter",
                              type: "select",
                              defaultValue: false,
                              fieldProps: {
                                   options: [
                                        {
                                             label: "Yes",
                                             value: true,
                                        },
                                        {
                                             label: "No",
                                             value: false,
                                        },
                                   ],
                              },
                         },
                         {
                              label: "Normal Font Column Size (ESC/POS Font A)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 48 columns.\n Please specify normal Font A column size if your printer is different from the default.",
                              fieldName: "normalFontAColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter normal font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                         {
                              label: "Condensed Font Column Size (ESC/POS Font B)",
                              hintText: "Default = Standard ESC/POS 80.0mm printer, 64 columns.\n Please specify condensed Font B column size if your printer is different from the default.",
                              fieldName: "condensedFontBColumnSize",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: false,
                                   placeholder: "Enter condensed font column size",
                                   pattern: "[0-9]*",
                                   title: 'Font column size should only contain numbers'
                              },
                         },
                    ],
                    [LabelPrinterTypes.brotherQL700Usb]: [
                         {
                              label: "System Name",
                              hintText: "Enter printer name (as registered in system)",
                              fieldName: "systemName",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter system printer name",
                              },
                         },
                    ],
                    [LabelPrinterTypes.brotherQL800Usb]: [
                         {
                              label: "System Name",
                              hintText: "Enter printer name (as registered in system)",
                              fieldName: "systemName",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter system printer name",
                              },
                         },
                    ],
                    [LabelPrinterTypes.brotherQL820Bluetooth]: [
                         {
                              label: "Bluetooth Address",
                              hintText: "Enter bluetooth address",
                              fieldName: "bluetoothAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter bluetooth address",
                              },
                         },
                    ],
                    [LabelPrinterTypes.brotherQL820Network]: [
                         {
                              label: "IP Address",
                              hintText: "Enter IP address",
                              fieldName: "ipAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter IP address",
                              },
                         },
                    ],
                    [LabelPrinterTypes.zplGenericNetwork]: [
                         {
                              label: "IP Address",
                              hintText: "Enter IP address",
                              fieldName: "ipAddress",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter IP address",
                              },
                         },
                         {
                              label: "Item Label Printing Template",
                              hintText: null,
                              fieldName: "itemLabelPrintingTemplate",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [],
                              },
                         },
                         {
                              label: "Order Label Printing Template",
                              hintText: null,
                              fieldName: "orderLabelPrintingTemplate",
                              type: "select",
                              defaultValue: "",
                              fieldProps: {
                                   options: [],
                              },
                         },
                         {
                              label: "KOT Label Printing Template",
                              hintText: null,
                              fieldName: "kotLabelPrintingTemplate",
                              type: "select",
                              defaultValue: "",
                              fieldProps: {
                                   options: [],
                              },
                         },
                         {
                              label: "Expiry Label Printing Template",
                              hintText: null,
                              fieldName: "expiryLabelPrintingTemplate",
                              type: "select",
                              defaultValue: "",
                              fieldProps: {
                                   options: [],
                              },
                         },
                    ],
                    [LabelPrinterTypes.zplGenericUSB]: [
                         {
                              label: "System Name",
                              hintText: "Enter printer name (as registered in system)",
                              fieldName: "systemName",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   readOnly: false,
                                   required: true,
                                   placeholder: "Enter system printer name",
                              },
                         },
                         {
                              label: "Item Label Printing Template",
                              hintText: null,
                              fieldName: "itemLabelPrintingTemplate",
                              type: "select",
                              defaultValue: null,
                              fieldProps: {
                                   options: [],
                              },
                         },
                         {
                              label: "Order Label Printing Template",
                              hintText: null,
                              fieldName: "orderLabelPrintingTemplate",
                              type: "select",
                              defaultValue: "",
                              fieldProps: {
                                   options: [],
                              },
                         },
                         {
                              label: "KOT Label Printing Template",
                              hintText: null,
                              fieldName: "kotLabelPrintingTemplate",
                              type: "select",
                              defaultValue: "",
                              fieldProps: {
                                   options: [],
                              },
                         },
                         {
                              label: "Expiry Label Printing Template",
                              hintText: null,
                              fieldName: "expiryLabelPrintingTemplate",
                              type: "select",
                              defaultValue: "",
                              fieldProps: {
                                   options: [],
                              },
                         },
                    ],
               },
          },
     ],
     [DeviceTypes.creditCardReader]: [
          {
               label: "Credit card provider",
               hintText: "Required",
               fieldName: "creditType",
               type: "select",
               defaultValue: "StripeReader",
               fieldProps: {
                    options: Object.entries(CreditTypes).map(([key, value]) => ({
                         label: value,
                         value: key,
                    })),
                    required: true,
               },
               subFields: {
                    HandPoint: [
                         {
                              label: "URL",
                              hintText: "Enter cloud url provided by card provider (Required)",
                              fieldName: "url",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter cloud url",
                              },
                         },
                         {
                              label: "Api Key",
                              hintText: "Enter Api Key url provided by card provider (Required)",
                              fieldName: "apiKey",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Api Key",
                              },
                         },
                         {
                              label: "Terminal Id",
                              hintText: "Enter Terminal Id provided by card provider (Required)",
                              fieldName: "terminalId",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Terminal Id",
                              },
                         },
                    ],
                    StripeReader: [
                         {
                              label: "Location Id",
                              hintText: "Enter Location Id provided by card provider (Required)",
                              fieldName: "locationId",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Location Id",
                              },
                         },
                         {
                              label: "Reader Id",
                              hintText: "Enter Terminal Id provided by card provider (Required)",
                              fieldName: "readerId",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Terminal Id",
                              },
                         },
                    ],
                    PaymentSense: [
                         {
                              label: "URL",
                              hintText: "Enter cloud url provided by card provider (Required)",
                              fieldName: "url",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter cloud url",
                              },
                         },
                         {
                              label: "Api Key",
                              hintText: "Enter Api Key url provided by card provider (Required)",
                              fieldName: "apiKey",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Api Key",
                              },
                         },
                         {
                              label: "Terminal Id",
                              hintText: "Enter Terminal Id provided by card provider (Required)",
                              fieldName: "terminalId",
                              type: "input:text",
                              defaultValue: null,
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Terminal Id",
                              },
                         },
                    ],
                    WorldPay: [
                         {
                              label: "IPC Status Port",
                              hintText: "Enter IPC Status Port(Required)",
                              fieldName: "ipcStatusPort",
                              type: "input:text",
                              defaultValue: "8000",
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter IPC Status Port",
                                   pattern: "[0-9]*",
                                   title: "IPC Status Port should only contain numbers",
                              },
                         },
                         {
                              label: "IPC Messaging Port",
                              hintText: "Enter IPC Messaging (Required)",
                              fieldName: "ipcMessagingPort",
                              type: "input:text",
                              defaultValue: "10000",
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter IPC Messaging Port",
                                   pattern: "[0-9]*",
                                   title: "IPC Messaging Port should only contain numbers",
                              },
                         },
                         {
                              label: "IPC Host",
                              hintText: "Enter IPC Host (Required)",
                              fieldName: "ipcHost",
                              type: "input:text",
                              defaultValue: "localhost",
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter IPC Host",
                              },
                         },
                         {
                              label: "Merchant Receipt File Name",
                              hintText: "Enter full path of the Merchant Receipt file (Required)",
                              fieldName: "merchantReceiptFileName",
                              type: "input:text",
                              defaultValue: "C:\\YESEFT\\MainReceipt.txt",
                              fieldProps: {
                                   required: true,
                                   placeholder: "Enter Terminal Id",
                              },
                         },
                    ],
                    Offline: [],
               },
          },
     ],
     [DeviceTypes.kotPrinter]: [
          {
               label: "Kitchen Station Profile",
               hintText: "Select kitchen station profile",
               fieldName: "kitchenStationId",
               type: "select",
               defaultValue: null,
               fieldProps: {
                    required: true,
                    options: [],
               },
          },
          {
               label: "Number Of Copies",
               hintText: "Enter number of copies",
               fieldName: "copiesToPrint",
               type: "input:text",
               defaultValue: "1",
               fieldProps: {
                    required: true,
                    placeholder: "Enter number of copies",
                    pattern: "[0-9]*",
                    title: "Number of copies should only contain numbers",
               },
          },
          {
               label: "Storefronts",
               hintText: "Select storefronts for this KOT",
               fieldName: "storefronts",
               type: "multiselect",
               defaultValue: null,
               fieldProps: {
                    required: false,
                    options: [],
               },
           },
          {
               label: "Dispatch Types",
               hintText: "Select dispatch types for this KOT",
               fieldName: "dispatchTypes",
               type: "multiselect",
               defaultValue: null,
               fieldProps: {
                    required: false,
                    options: Object.entries(constants.dispatchTypes).map(([key, value]) => ({
                        label: value,
                        value: value,
                   })),
               },
           },
     ],
     [DeviceTypes.telephoneCli]: [
          {
               label: "Does station have customer display",
               hintText: "Indicate if customer display is attached with station",
               fieldName: "displaySignage",
               type: "select",
               defaultValue: false,
               fieldProps: {
                    options: [
                         {
                              label: "Yes",
                              value: true,
                         },
                         {
                              label: "No",
                              value: false,
                         },
                    ],
               },
          },
     ],
};
