import React, { Component } from 'react';
import InputComponent from '../../../../components/form/inputComponent';
import SelectComponent from '../../../../components/form/selectComponent';

const FormManager = require('../../../../utils/view/formManager.js');
const shellConstants = require('../../../../utils/constants/shellConstants');
const terminalTypeConstants = require('../../../../utils/constants/terminalTypeConstants');


class GeneralConfiguration extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {};

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.model;
        this.formManager.view = this;

        this.formManager.onFieldChanged = (event) =>
        {
            if (event == null || event.target == null || event.target.id != "stationType")
                return;

            
            this.props.onStationTypeChange();
        }
    }

    render()
    {
        return <table className="component-table">
                    <tbody>
                        <tr>
                            <td>
                                <InputComponent
                                    inputType="code"
                                    inputReadOnly={true}
                                    caption="Station License Key"
                                    hintText="Auto Generated"
                                    fieldName="licenseKey"
                                    formManager={this.formManager} />
                            </td>


                            <td>
                                <InputComponent
                                    inputType="text"
                                    caption="Station Name"
                                    fieldName="name"
                                    placeholder="Enter station name"
                                    hintText="Required"
                                    inputReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={this.getAllStationTypes()}
                                    optionFieldName="value"
                                    optionDisplayFieldName="label"
                                    fieldName="stationType"
                                    caption="Station Type"
                                    hintText="Required"
                                    clearable={false}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} 
                                    style={{marginTop:'30px'}}/>
                            </td>
                            <td>
                                <SelectComponent
                                    optionValues={shellConstants.shellTypeOptions}
                                    optionFieldName="value"
                                    optionDisplayFieldName="label"
                                    fieldName="shellType"
                                    caption="Host Type"
                                    hintText="Required"
                                    clearable={false}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} 
                                    style={{marginTop:'30px'}}/>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={shellConstants.shellRenderingModeOptions}
                                    optionFieldName="value"
                                    optionDisplayFieldName="label"
                                    fieldName="shellRenderingMode"
                                    caption="Station Rendering Mode"
                                    hintText="Required"
                                    clearable={false}
                                    comboReadOnly={this.props.isReadOnly}
                                    formManager={this.formManager} 
                                    style={{marginTop:'30px'}}/>
                            </td>
                            <td>
                                <SelectComponent
                                optionValues={shellConstants.shellScreenOrientationOptions}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                fieldName="shellScreenOrientation"
                                caption="Screen Orientation"
                                hintText="Optional"
                                clearable={true}
                                comboReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} 
                                style={{marginTop:'30px'}}/>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <hr style={{marginTop:'30px'}}/>
                            </td>
                        </tr>

                    </tbody>
                </table>
    }

    getAllStationTypes()
    {
        return [
                    { value: terminalTypeConstants.pos, label: 'POS' },
                    { value: terminalTypeConstants.kiosk, label: 'KIOSK' },
                    { value: terminalTypeConstants.kds, label: 'KDS' },
                    { value: terminalTypeConstants.kitchen, label: 'Kitchen' },
                    { value: terminalTypeConstants.centralKitchenKds, label: 'Central Kitchen' },
                    { value: terminalTypeConstants.signage, label: 'Signage' },
                ];
    }
}

export default GeneralConfiguration;