import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent';
import CheckComponent from '../../../../../components/form/checkComponent';

const FormManager = require('../../../../../utils/view/formManager');


class Bill extends Component 
{
    constructor(props) 
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.viewModel = this.props.data;
        this.formManager.view = this;

        this.formManager.onFieldChanged = this.onFieldChanged;
    }

    onFieldChanged = event =>
    {
        const formManager = this.formManager;
        const targetId = event.target.id;

        switch (targetId)
        {
            case "categoryId":
                // Reset Sub Category selection when Category selection changes
                formManager.viewModel.subCategoryId = null;
                break;

            default:
                break;
        }
    }

    getSubcategories = () =>
    {
        //
        // Returns subCategories filtered by selected category
        //

        const categoryId = this.formManager.viewModel.categoryId;
        if (!categoryId) return [];

        const category = this.props.categories.find(x => x.id === categoryId);
        return category ? category.subCategories : [];
    }

    getTransactionsAccounts = () =>
    {
        //
        // Returns transactionAccounts filtered by selected property
        //

        const propertyId = this.formManager.viewModel.propertyId;
        if (!propertyId) return [];

        return this.props.transactionAccounts.filter(x => x.propertyId == propertyId);
    }

    render() 
    {
        //
        // subCategories and transactionAccounts require filter, hence are fed through get methods
        //

        const formManager = this.formManager;

        const suppliers = this.props.suppliers;
        const categories = this.props.categories;
        const periods = this.props.periods;

        const fromDate = formManager.viewModel.fromDate;
        const toDate = formManager.viewModel.toDate;

        return (

            <div>

                <table className="component-table">

                    <tbody>

                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={suppliers}
                                    optionFieldName="id"
                                    optionDisplayFieldName="name"
                                    fieldName="supplierId"
                                    caption="Supplier"
                                    hintText="Required"
                                    clearable={false}
                                    formManager={formManager} />
                            </td>
                            <td>
                                <CheckComponent
                                    fieldName="isBillPaidInAdvance"
                                    caption="Is Bill Paid In Advance"
                                    formManager={formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={categories}
                                    optionFieldName="id"
                                    optionDisplayFieldName="name"
                                    fieldName="categoryId"
                                    caption="Category"
                                    hintText="Required"
                                    clearable={false}
                                    formManager={formManager} />
                            </td>
                            <td>
                                <SelectComponent
                                    optionValues={this.getSubcategories()}
                                    optionFieldName="id"
                                    optionDisplayFieldName="name"
                                    fieldName="subCategoryId"
                                    caption="Sub Category"
                                    hintText="Required"
                                    clearable={false}
                                    formManager={formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={periods}
                                    optionFieldName="code"
                                    optionDisplayFieldName="code"
                                    fieldName="periodCode"
                                    caption="Period"
                                    hintText="Required"
                                    clearable={false}
                                    formManager={formManager} />
                            </td>
                            <td>
                                <InputComponent inputType="date"
                                    fieldName="nextDueDate"
                                    caption="Bill Due Date"
                                    placeholder="Bill due date"
                                    // hintText="Required"
                                    hintText={"Required " + (fromDate ? `(Period: ${fromDate} To ${toDate})` : "")}
                                    formManager={formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <SelectComponent
                                    optionValues={this.getTransactionsAccounts()}
                                    optionFieldName="id"
                                    optionDisplayFieldName="code"
                                    fieldName="accountId"
                                    caption="Account"
                                    hintText="Required"
                                    clearable={false}
                                    formManager={formManager} />
                            </td>
                            <td>
                                <InputComponent
                                    inputType="number"
                                    fieldName="amount"
                                    caption="Amount"
                                    placeholder="Enter the amount"
                                    hintText="Required"
                                    formManager={formManager} />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                <InputComponent
                                    inputType="text"
                                    fieldName="notes"
                                    caption="Notes"
                                    placeholder="Enter any notes."
                                    multiLine={true} rows={4}
                                    formManager={formManager} />
                            </td>
                        </tr>

                    </tbody>

                </table>

            </div>
        );
    }
}

export default Bill;