export function addFieldOptions(field, toAssignArr = [], valueKey = "id", labelKey = "title") {
     field.fieldProps.options = toAssignArr.map((fd) => ({
          value: fd[valueKey],
          label: fd[labelKey],
     }));
}

export function validateFields(fields = [], fieldsValues = {}) {
     for (let field of fields) {
          if (field?.fieldProps?.required) {
               const value = fieldsValues[field.fieldName];
               if (value === undefined || value === null || value === "") return false;
               
               if (field?.subFields) {
                    if (!validateFields(field?.subFields[value], fieldsValues)) return false;
               }
          }
     }
     return true;
}
