
import { useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, Button, Modal } from 'reactstrap';
import Spinner from '../spinner/spinner';
import ErrorMessage from '../error/errorMessage';

import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";

import Geocode from "react-geocode";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

const configurationManager = require('../../utils/config/configurationManager');
const stringUtil = require('../../utils/string/stringUtil');
const viewUtil = require('../../utils/view/viewUtil');
const guidUtil = require('../../utils/guid/guidUtil');
const rmsApiProxy = require('../../utils/api/rmsApiProxy');

export default function MapDialogue(props) 
{
    const googleMapsApiKey = configurationManager.getConfig().googleMapsApiKey;
    const libraries = ['places'];

    const { isLoaded, loadError } = useJsApiLoader(
        {
            googleMapsApiKey: googleMapsApiKey,
            libraries: libraries,
        }
    )

    Geocode.setApiKey(googleMapsApiKey);

    // Code to ensure that we do not initialize component internal state with an invalid props

    let props_selectedAddress = "", props_selectedLatLng = null, props_defaultCenter = null;

    if (props.selectedAddress != null)
    {
        props_selectedAddress = props.selectedAddress;
    }

    if (props.selectedLatLng != null && props.selectedLatLng.lat != null && props.selectedLatLng.lng != null)
    {
        props_selectedLatLng = props.selectedLatLng;
    }

    props_defaultCenter = 
    {
        lat: 53.2761532,
        lng: -6.2181159
    }

    var props_defaultZoom = 15;

    const [selectedAddress, setSelectedAddress] = useState(props_selectedAddress);      // string
    const [selectedLatLng, setSelectedLatLng] = useState(props_selectedLatLng);         // props.selectedLatLng = {lat:Number, lng:Number}
    const [defaultCenter , setDefaultCenter] = useState(props_defaultCenter);                  // defaultCenter = {lat:Number, lng:Number}
    const [defaultZoom, setDefaultZoom] = useState(props_defaultZoom);                                 // defaultZoom = Number

    useEffect(() =>
    {
        if (props_selectedLatLng == null)
        {
            (async () =>
            {
                try 
                {
                    // Try to get the missing geo coordinates if possible
                    viewUtil.showSystemModalSpinner("Loading geo coordinates ...");

                    if(!stringUtil.isStringNullOrEmpty(props_selectedAddress))
                    {
                        const results = await getGeocode({ address: props_selectedAddress });
                        const { lat, lng } = getLatLng(results[0]);
                        setSelectedLatLng({ lat, lng });
                    }
                    else if(!stringUtil.isStringNullOrEmpty(props.country))
                    {
                        const results = await getGeocode({ address: props.country });
                        const { lat, lng } = getLatLng(results[0]);
                        setDefaultCenter({ lat, lng });
                        setDefaultZoom(8);
                    }
                }
                catch (error)
                {
                    console.log("useEffect Error:", error.toString());
                }
                finally
                {
                    viewUtil.closeModalSpinner();
                }
            })();
        }
    }, [props_selectedAddress, props_selectedLatLng, props.country]);

    async function onSave()
    {
        props.onSave(selectedAddress, selectedLatLng);
        props.onClose();
    }

    async function onClick(event)
    {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLatLng({ lat, lng });

        try
        {
            viewUtil.showSystemModalSpinner("Loading geo coordinates from address ...");
            const response = await Geocode.fromLatLng(lat, lng);
            const address = response.results[0].formatted_address;
            setSelectedAddress(address)
        }
        catch (error)
        {
            setSelectedLatLng(null);
            setSelectedAddress(null)
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    const center =
    {
        lat: selectedLatLng?.lat ?? defaultCenter.lat,
        lng: selectedLatLng?.lng ?? defaultCenter.lng
    };

    let component;

    if (loadError != null) 
    {
        component = <ErrorMessage message={loadError} />
    }
    else if (!isLoaded)
    {
        component = <div style={{marginTop: '20px'}}><Spinner text="Loading map ..." /></div>
    }
    else
    {
        component =
            <>
                <div>
                    <PlacesAutocomplete
                        country={props.country}
                        setSelectedLatLng={setSelectedLatLng}
                        selectedLatLng={selectedLatLng}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                    />
                </div>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '70vh' }}
                    center={center}
                    zoom={defaultZoom}
                    onClick={onClick}
                    options={
                        {
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            minZoom: 4,
                            maxZoom: 20,
                        }
                    }>
                    {selectedLatLng && <MarkerF position={selectedLatLng} />}
                </GoogleMap>
            </>;
    }

    return (
        <Modal isOpen={true} centered size="lg" style={{ maxWidth: '1600px', width: '90%' }}>
            <Card style={{ border: '3px solid #BEBEBE' }}>
                <CardBody style={{ padding: '0px' }}>
                    {component}
                </CardBody>
                <CardFooter>
                    <div className='d-flex justify-content-end'>
                        <Button className='px-4' color="primary" disabled={!isLoaded || stringUtil.isStringNullOrEmpty(selectedAddress)} onClick={onSave}>Save</Button>
                        <Button className='ms-2' color="secondary" disabled={!isLoaded} onClick={props.onClose}>Cancel</Button>
                    </div>
                </CardFooter>
            </Card>
        </Modal>
    );
}

function PlacesAutocomplete(props)
{
    const { selectedAddress, selectedLatLng, setSelectedAddress, setSelectedLatLng } = props;

    const [ukAddressSuggestions, setUKAddressSuggestions] = useState(null);
    const [currentFetchId, setCurrentFetchId] = useState(null);

    const
        {
            ready,
            setValue,
            suggestions: { status, data },
            clearSuggestions,
        } = usePlacesAutocomplete();

    const handleSelect = async (address) => 
    {
        setCurrentFetchId(null);
        setValue(address, false);
        setSelectedAddress(address);
        clearSuggestions();
        setUKAddressSuggestions(null);

        try 
        {
            viewUtil.showSystemModalSpinner("Loading geo coordinates from address ...");
            const results = await getGeocode({ address });
            const { lat, lng } = getLatLng(results[0]);
            setSelectedLatLng({ lat, lng });
        }
        catch (error)
        {
            selectedLatLng(null);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    };

    const handleChange = (e) =>
    {
        const address = e.target.value;
        const length = address.length;

        props.setSelectedAddress(address);
        props.setSelectedLatLng(null);

        if (length < 5)
        {
            setUKAddressSuggestions(null);
            setValue(null);
            clearSuggestions();
            return;
        }

        if (length < 9 && stringUtil.areStringSame(props.country, "UK"))
        {
            if (address.split(" ").length - 1 < 2)
            {
                setValue(null);
                clearSuggestions();
                setCurrentFetchId(guidUtil.generateGuid());
                fetchUKAddressSuggestions(address);
                return;
            }
        }

        setValue(address);
    }

    const fetchUKAddressSuggestions = async (postCode) =>
    {
        try
        {
            const fetchId = currentFetchId;

            // viewUtil.showSystemModalSpinner("Attempting to fetch UK addresses by post code...");

            setUKAddressSuggestions(null);
            postCode = stringUtil.replaceAll(postCode, /[^a-zA-Z0-9 -]/, "");
            const resource = `gateway/geo/addressLookup/${postCode}`;

            const possibleAddresses = await rmsApiProxy.put(resource);

            if (fetchId === currentFetchId && possibleAddresses.length > 0) 
            {
                setUKAddressSuggestions(possibleAddresses);
            }
        }
        catch (error) 
        {
            console.log("fetchUKAddressSuggestions:", error.toString());
            return null;
        }
    }

    const possibleSuggestions = [];

    if (ukAddressSuggestions != null && ukAddressSuggestions.length > 0) 
    {
        // handle uk service suggestions
        ukAddressSuggestions.forEach(suggestion => possibleSuggestions.push({ place_id: suggestion, description: suggestion }));
    }
    else if (status === "OK" || data.length > 0)
    {
        // handle google service suggestions
        data.forEach(item => possibleSuggestions.push({ place_id: item.place_id, description: item.description }));
    }

    let contents = null;
    if(possibleSuggestions.length > 0)
    {
        contents = <ComboboxList>
            {
                possibleSuggestions.map(({ place_id, description }) => (
                    <ComboboxOption key={ place_id } value={ description } />
                ))
            }
        </ComboboxList>
    }

    return (
        <Combobox onSelect={handleSelect}>
            <ComboboxInput
                style={{ position: 'absolute', zIndex: 9999999, top: "3rem" }}
                value={selectedAddress}
                onChange={handleChange}
                disabled={!ready}
                className="combobox-input"
                placeholder="Search an address" />

            <ComboboxPopover style={{ "overflow-y": "auto",  "max-height":"60vh", zIndex: 9999999 }}>
                {contents}
            </ComboboxPopover>
        </Combobox>
    );
};