import React, { Component } from "react";
import { FormGroup, Input, Label } from 'reactstrap';
import HintCard from "../../../../../components/hint/hintCard";
import * as arraySort from 'array-sort';

const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

export default class TaskProperties extends Component
{
    constructor(props)
    {
        super(props);

        this.props.data.applicablePropertyIds = this.props.data.applicablePropertyIds || [];
    }

    render()
    {
        // Only properties available at the currentOrgNode are listed irrespective of applicablePropertyIds

        const properties = arraySort(currentOrgNodeSelectors.selectAllProperties(), "code");

        return (
            <div className="d-flex flex-column">
                <HintCard contextId="orgConstraintsHintCard">
                    <div className="p-2 text-muted">
                        <div>Choose properties from the list below to target tasks to selected properties.</div>
                        <hr className="my-2" />
                        <div>
                            The default is "All Properties" in which case you need not select any property from the list.
                            Selecting properties in the list will restrict the task to only the selected properties. Hence, do not select any item
                            in the list if the task is targeted to all properties.
                        </div>
                    </div>
                </HintCard>
                <fieldset className="border border-rounded">
                    <legend className="mb-n2" style={{ width: "175px", fontSize: "14px" }}>
                        <div className="ps-4 py-2">
                            {this.getAllCheckComponent()}
                        </div>
                    </legend>
                    <div className="d-flex flex-row flex-wrap p-3">
                        {
                            properties.map(property => (
                                <div key={property.id} className="d-flex align-items-center ms-2 mb-1" style={{ width: "250px", height: "40px" }}>
                                    {this.getPropertyCheckComponent(property)}
                                </div>)
                            )
                        }
                    </div>
                </fieldset>
            </div>
        );
    }

    isChecked(property)
    {
        return this.props.data.applicablePropertyIds.some(id => id === property.id);
    }

    onChange(event, property)
    {
        const applicablePropertyIds = this.props.data.applicablePropertyIds.filter(id => id !== property.id);

        if (event.target.checked)
        {
            applicablePropertyIds.push(property.id);
        }

        this.props.data.applicablePropertyIds = applicablePropertyIds;
        this.setState({});
    }

    getPropertyCheckComponent(property)
    {
        return (
            <FormGroup check>
                <Label check>
                    <Input
                        type="checkbox"
                        disabled={this.props.isRowReadOnly}
                        checked={this.isChecked(property)}
                        onChange={(event) => this.onChange(event, property)} />
                    {`${property.name}`}
                </Label>
            </FormGroup>
        );
    }

    getAllCheckComponent()
    {
        const properties = currentOrgNodeSelectors.selectAllProperties();
        const applicablePropertyIds = this.props.data.applicablePropertyIds;
        const allSelected = properties.length == applicablePropertyIds.length;

        return (
            <FormGroup check>
                <Label check>
                    <Input
                        type="checkbox"
                        disabled={this.props.isRowReadOnly}
                        checked={allSelected}
                        onChange=
                        {
                            () =>
                            {
                                const applicablePropertyIds = [];

                                if (!allSelected)
                                {
                                    applicablePropertyIds.push(...properties.map(property => property.id));
                                }

                                this.props.data.applicablePropertyIds = applicablePropertyIds;
                                this.setState({});
                            }
                        } />
                    {allSelected ? "Unselect All" : "Select All"} Items
                </Label>
            </FormGroup>
        );
    }
}