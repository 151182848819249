
import React, { Component } from 'react';

class BIErrorComponent extends Component
{
    render()
    {
        var errorMessage = `Error: ${this.props.error}`;
        return <div style={{marginTop:'40px', textAlign:'center'}}>
                    <h5><span className="text-danger">{errorMessage}</span></h5>
               </div>
    }
}

export default BIErrorComponent;