import React, { memo, useMemo } from "react";
import { ModalHeader } from "reactstrap";
import { TerminalTypes } from "../../../../constants";

const StationConfigurationHeader = ({ state, onClose = () => {} }) => {
     const title = useMemo(() => {
          if (state.type === null) return "Choose Add Station Type";

          if (state.id === null) {
               if (state.type === TerminalTypes.pos) return "Add POS Station";
               if (state.type === TerminalTypes.kds) return "Add KDS Station";
               if (state.type === TerminalTypes.kiosk) return "Add Kiosk Station";
               if (state.type === TerminalTypes.signage) return "Add Signage Station";
               if (state.type === TerminalTypes.kitchen) return "Add Kitchen Station";
               if (state.type === TerminalTypes.centralKitchen) return "Add Central Kitchen Station";
          } else {
               if (state.type === TerminalTypes.pos) return "Edit POS Station";
               if (state.type === TerminalTypes.kds) return "Edit KDS Station";
               if (state.type === TerminalTypes.kiosk) return "Edit Kiosk Station";
               if (state.type === TerminalTypes.signage) return "Edit Signage Station";
               if (state.type === TerminalTypes.kitchen) return "Edit Kitchen Station";
               if (state.type === TerminalTypes.centralKitchen) return "Edit Central Kitchen Station";
          }
          return "Add Station";
     }, [state.type, state.id]);

     return <ModalHeader toggle={onClose}>{title}</ModalHeader>;
};

export default memo(StationConfigurationHeader);
