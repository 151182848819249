import React, { Component } from "react";
import { FormText, InputGroup, InputGroupText, Input } from 'reactstrap';

import FormManager from '../../utils/view/formManager';
import stringUtil from '../../utils/string/stringUtil';

class TokenStringsInputComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = new FormManager();
        this.formManager.view = this;
        this.formManager.viewModel = { inputToken: null, tokens: [] };
        this.formManager.onFieldChanged = this.onFieldChanged;

        this.proxyViewModel = this.formManager.viewModel;

        this.dataViewModel = this.props.formManager.viewModel;
        this.dataProperty = this.props.dataProperty;

        if (!stringUtil.isStringNullOrEmpty(this.dataViewModel[this.dataProperty]))
        {
            // Implies incoming/existing data, 
            // split data into tokens and initialize proxy
            const dataValue = this.dataViewModel[this.dataProperty];
            this.proxyViewModel.tokens.push(...dataValue.split('\n'));
        }
    }

    onFieldChanged = (event) =>
    {
        // Invoked when new token is input in the input box

        const id = event.target.id;
        const value = event.target.value;

        switch (id)
        {
            case 'inputToken':
                this.proxyViewModel.inputToken = value;
                break;
            default:
                throw `Invalid switch case: ${id}`;
        }

        this.setState({});
    }

    onAddPressed = () => 
    {
        // Invoked when add token is pressed

        const inputToken = this.proxyViewModel.inputToken;

        this.proxyViewModel.inputToken = "";

        if (!stringUtil.isStringNullOrEmpty(stringUtil.trimString(inputToken)))
        {
            const tokens = this.proxyViewModel.tokens;

            if (!tokens.includes(inputToken))
            {
                tokens.push(inputToken);
                this.updateDataViewModel();
            }
        }

        this.setState({});
    }

    onRemovePressed = (index) => 
    {
        // Invoked when a existing token is removed from list

        const tokens = this.proxyViewModel.tokens;
        tokens.splice(index, 1);
        this.updateDataViewModel();
        this.setState({});
    }

    updateDataViewModel()
    {
        // Copy current token list into data property as a single string
        // joined by newline character

        const tokens = this.proxyViewModel.tokens;
        this.dataViewModel[this.dataProperty] = tokens.join('\n');

        //#region 

        //
        // Do I need to invoke onFieldChanged on the props formManager ??
        //

        // if (this.props.formManager.onFieldChanged)
        // {
        //     const event = {
        //         target: {
        //             id: this.dataProperty,
        //             value: this.dataViewModel[this.dataProperty];
        //         }
        //     }
        //     this.props.formManager.onFieldChanged(event);
        // }

        //#endregion
    }

    render()
    {
        const caption = this.props.caption;
        const hintText = this.props.hintText;
        const isReadOnly = this.props.isRowReadOnly;

        const minHeight = this.props.minHeight ?? "100px";
        const maxHeight = this.props.maxHeight ?? minHeight;

        const addDisabled = stringUtil.isStringNullOrEmpty(this.proxyViewModel.inputToken) || isReadOnly;

        return (
            <div style={{ display: "flex", flexFlow: "column nowrap", margin: "5px 0px 10px 0px" }}>

                <form onSubmit={(e) => { e.preventDefault(); this.onAddPressed(); }}>
                    <InputGroup>
                        <InputGroupText style={{ height: "36px" }}><span style={{ fontSize: "14px" }}>{caption}</span></InputGroupText>
                        <Input
                            type="text"
                            id="inputToken"
                            value={this.proxyViewModel.inputToken}
                            onChange={this.onFieldChanged}
                            readOnly={isReadOnly}
                            style={{ height: "36px" }} />
                        <button
                            type="submit"
                            disabled={addDisabled}
                            className={"btn " + (addDisabled ? "btn-secondary" : "btn-primary")}
                            style={{ width: "80px", height: "36px", margin: "0px 0px 0px 3px" }}>
                            <i className="fa fa-plus"></i>
                        </button>
                    </InputGroup>
                </form>

                <div
                    disabled={isReadOnly}
                    style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        marginTop: "3px",
                        overflow: "auto",
                        minHeight: minHeight,
                        maxHeight: maxHeight,
                    }}>
                    {
                        this.proxyViewModel.tokens.map(
                            (token, index) =>
                                <div
                                    key={token}
                                    style={{ display: "flex", flexFlow: "row", margin: "6px", }}>
                                    <span
                                        onClick={() => { if (!isReadOnly) this.onRemovePressed(index) }}
                                        style={{ margin: "0px 5px 0px 0px", color: isReadOnly ? "grey" : "red", cursor: "pointer" }}>
                                        <i className="fa fa-times"></i>
                                    </span>
                                    <span
                                        onClick={() => { if (!isReadOnly) this.onRemovePressed(index) }}
                                        style={{ display: "inline-block", cursor: "pointer" }}>{token}</span>
                                </div>)
                    }
                </div>
                <FormText color="muted">{hintText}</FormText>
            </div>
        );
    }
}

export default TokenStringsInputComponent;