import React, { Component } from 'react';

import PieChartReport from '../../../../../../reportUtils/chartComponents/PieChartReport';
import { getStorefrontTypeLabel } from '../../../utils/viewUtils';
import { getNomenclature } from '../../../../../../reportUtils/helperUtils/viewUtils';

const biUtils = require('../../../../../../../../utils/bi/biUtils');
const { formatCurrencyValue: currencyFormatter, formatAmount: numberFormatter, formatPercentage: percentageFormatter } = biUtils;


export default class SalesByStorefrontCharts extends Component
{
    render()
    {
        return (
            <div className='d-flex align-items-center' style={{ backgroundColor: "WhiteSmoke" }}>

                <div className='w-50 border-end d-flex justify-content-center align-items-center'>
                    {this.renderSaleCountChart()}
                </div>

                <div className='w-50 d-flex justify-content-center align-items-center'>
                    {this.renderSalePriceIncTaxChart()}
                </div>

            </div>
        );
    }

    renderSaleCountChart()
    {
        const title = "Sale Count";
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const { salesByStorefrontType } = this.props.data;

        const totalSaleCount = salesByStorefrontType.reduce((previous, current) => previous + current.saleCount, 0);

        if (totalSaleCount === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{numberFormatter(0)}</div>
                </div>
            );
        }

        const data = salesByStorefrontType.map(data => ({
            label: getStorefrontTypeLabel(data.storefrontType),
            value: data.saleCount,
            percentage: data.saleCount / totalSaleCount * 100
        }));

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={numberFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }

    renderSalePriceIncTaxChart()
    {
        const title = getNomenclature("salePriceIncTax");
        const options = {
            autoSize: false,
            width: 450,
            height: 225,
            padding: { top: 20, bottom: 20, left: 0, right: 0 },
            legend: {
                enabled: true,
                position: 'left',
                spacing: 0
            }
        };

        const { salesByStorefrontType } = this.props.data;

        const totalSalePriceIncTax = salesByStorefrontType.reduce((previous, current) => previous + current.totalSalePriceIncTax, 0);

        if (totalSalePriceIncTax === 0)
        {
            return (
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='fw-bold mb-2'>{title}</div>
                    <div>{currencyFormatter(0)}</div>
                </div>
            );
        }

        const data = salesByStorefrontType.map(data => ({
            label: getStorefrontTypeLabel(data.storefrontType),
            value: data.totalSalePriceIncTax,
            percentage: data.totalSalePriceIncTax / totalSalePriceIncTax * 100
        }));

        return (
            <PieChartReport
                title={title}
                data={data}
                valueFormatter={currencyFormatter}
                percentageFormatter={percentageFormatter}
                options={options} />
        );
    }
}