
import React, { Component } from 'react';
import StaffPermissionsComponent from './components/StaffPermissionsComponent';
import ErrorMessage from '../../../../../components/error/errorMessage';
import { Card, CardHeader, CardBody } from 'reactstrap';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const propertyOperatingConfigurationHelper = require('./propertyOperatingConfigurationHelper');
const viewUtil = require('../../../../../utils/view/viewUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StaffPermissionsConfiguration extends Component
{

    constructor(props) 
    {
        super(props);
        this.state = {}
        this.state.isLoading = true;
        this.state.error = null;
        this.state.formManager = new FormManager();

        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.state.formManager.viewModel = propertyOperatingConfigurationHelper.createNew();
        this.state.formManager.view = this;
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");
        try
        {
            let propertyOperatingConfiguration = await propertyOperatingConfigurationHelper.load();
            if (propertyOperatingConfiguration)
            {
                this.state.formManager.viewModel = propertyOperatingConfiguration;
            }
        }
        catch (error)
        {
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    render() 
    {
        let component = null;
        if (this.state.isLoading)
        {
            component = null
        }
        else if (this.state.error)
        {
            component = <ErrorMessage message={ this.state.errorMessage } />;
        }
        else
        {
            component = <StaffPermissionsComponent formManager={ this.state.formManager } />
        }

        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader 
                        title="Property Staff Permissions Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={true}
                        />
                </CardHeader>

                <CardBody>
                    { component }
                </CardBody>
            </Card>

        )
    }

    async onSaveClicked() 
    {

        const propertyOperatingConfiguration = this.state.formManager.viewModel;

        viewUtil.showSystemModalSpinner("Saving Property Staff Permissions Configuration");

        try
        {
            await propertyOperatingConfigurationHelper.save(propertyOperatingConfiguration);
            viewUtil.showSuccessAlert(`Property Staff Permissions Configuration saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.property.staffPermission,
                                                      'Property Staff Permissions Configuration has been altered', 
                                                      propertyOperatingConfiguration);

            const currentOptionAction = {
                type: actionTypes.mainBarComponent,
                payload: null
            };

            actionDispatcher.dispatch(currentOptionAction);
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

}

export default StaffPermissionsConfiguration;

