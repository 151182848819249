
import React, { Component } from 'react';
import { connect } from 'react-redux'
import SignagePlayersListComponent from './list/SignagePlayersListComponent.js'
import SignagePlayerComponent from './player/SignagePlayerComponent.js'


const stringUtil = require('../../../../utils/string/stringUtil.js');
const typeUtil = require('../../../../utils/type/typeUtil.js');
const guidUtil = require('../../../../utils/guid/guidUtil.js');

class SignagePlayers extends Component
{
    constructor(props)
    {
        super(props);

        this.renderListView = this.renderListView.bind(this);
        this.renderPlayer = this.renderPlayer.bind(this);
       
        this.state = {};
        this.state.viewType = "list";
        this.state.listViewState = {};
    }

    render()
    {
        if (stringUtil.areStringSame(this.state.viewType, "list"))
            return this.renderListView();

        if (stringUtil.areStringSame(this.state.viewType, "new") || stringUtil.areStringSame(this.state.viewType, "edit"))
            return this.renderPlayer();
    }

    renderListView()
    {
        return <SignagePlayersListComponent listViewState={this.state.listViewState} 
                                isReadOnly={this.props.isReadOnly}
                                onNewPlayer={(franchisorId)=>
                                                {
                                                    this.state.viewType = "new";
                                                    
                                                    this.state.currentPlayer = {};
                                                    this.state.currentPlayer.id = guidUtil.generateGuid();
                                                    this.state.currentPlayer.franchisorId = franchisorId;
                                                    this.state.currentPlayer.name = null;
                                                    this.state.currentPlayer.notes = null;
                                                    this.state.currentPlayer.slides = [];
                                                    
                                                    
                                                    this.state.originalPlayer = null;
                                                    this.setState({});
                                                }}
                                onEditPlayer={(existingPlayer)=>
                                                {
                                                    this.state.viewType = "edit";
                                                    this.state.currentPlayer = typeUtil.deepCloneObject(existingPlayer);
                                                    this.state.originalPlayer = existingPlayer;

                                                    this.setState({});
                                                }}/>
    }

    renderPlayer()
    {
        
        return <SignagePlayerComponent  listOfPlayers={this.state.listViewState.listOfPlayers} 
                                        isReadOnly={this.props.isReadOnly}
                                        player={this.state.currentPlayer} 
                                        onCancel={()=>{
                                                            this.state.viewType = "list";
                                                            this.state.currentPlayer = null;
                                                            this.state.originalPlayer = null;

                                                            this.setState({});
                                                       }}
                                        onSaved={(updatedPlayer)=>{

                                                        if (this.state.originalPlayer == null)
                                                        {
                                                            this.state.listViewState.listOfPlayers.push(updatedPlayer)
                                                        }
                                                        else
                                                        {
                                                            var index = this.state.listViewState.listOfPlayers.findIndex(p => p.id == updatedPlayer.id);
                                                            this.state.listViewState.listOfPlayers[index] = updatedPlayer;
                                                        }

                                                        this.state.viewType = "list";
                                                        this.state.currentPlayer = null;
                                                        this.state.originalPlayer = null;

                                                        this.setState({});
                                                    }}/>
    }
}

export default SignagePlayers;