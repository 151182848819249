import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ActionDropdownMenu from "./ActionDropdownMenu";
import ActionDropdownMenuBtn from "./ActionDropdownMenuBtn";

const ActionDropdown = ({ options = [] }) => {
     const [isOpen, setIsOpen] = useState(false);

     const optionPopupRef = useRef(null);

     const handleOnClose = () => {
          setIsOpen(false);
     };

     const handleOnToggle = useCallback(() => {
          setIsOpen((prev) => !prev);
     }, []);

     const handleClickOutside = (event) => {
          if (optionPopupRef.current && !optionPopupRef.current.contains(event.target)) {
               handleOnClose();
          }
     };

     useEffect(() => {
          document.addEventListener("click", handleClickOutside);

          return () => {
               document.removeEventListener("click", handleClickOutside);
          };
     }, []);

     return (
          <Container ref={optionPopupRef}>
               <ActionDropdownMenuBtn clicked={isOpen} onClick={handleOnToggle} />
               <ActionDropdownMenu isOpen={isOpen} options={options} />
          </Container>
     );
};

export default memo(ActionDropdown);

const Container = styled.div`
     position: absolute;
     top: 50%;
     right: 0;
     transform: translate(50%, -50%);
     z-index: 99;
`;
