
import React, { Component } from 'react';

const configurationManager = require('../../utils/config/configurationManager');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const stateManager = require('../../utils/state/stateManager');
const securityManager = require('../../utils/domain/security/securityManager');
const actionTypes = require('../../utils/state/actionTypes');
const loadingUtils = require('./utils/loadingUtils');
class LoadingView extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.initializationStarted = false;
        this.state.error = null;
    }

    render()
    {
        var imageUrl = `${configurationManager.getConfig().cdnCloudUrl}/${configurationManager.getConfig().cdnAppCloudName}/image/upload/v1675415277/app/back.office/menu/${configurationManager.getConfig().themeVersion}/loadng.gif`;
        
        if (!this.state.initializationStarted)
            this.startInitialization();

        if (this.state.error)
        {
            return  <div style={{marginTop:'100px'}}>
                        
                        <div style={{color: '#585858', fontSize:'3em', textAlign:'center'}}>
                            Oops! Something went wrong.
                        </div>

                        <div style={{color: '#989898', fontSize:'1em', textAlign:'center'}}>
                            {this.state.error}
                        </div>
                    </div>
                   
        }

        return <div style={{position:'absolute', left:'50%', top:'40%', width: '400px', height:'400px', marginTop:'-200px', marginLeft:'-200px'}}>
        
                    <div style={{borderRadius:'50%', width:'220px', height:'220px', border:'1px solid #808080', marginLeft:'90px', marginTop:'90px'}}>
                        <img src={imageUrl} style={{width:'140px', height:'140px', marginLeft:'40px', marginTop:'40px'}}/>
                    </div>

                    <div style={{textAlign:'center', color:'#05149E', marginTop:'10px', fontSize:'2em', fontWeight:'bold'}}>
                        Getting Things Ready...
                    </div>

                    <div style={{textAlign:'center', color:'#707070', marginTop:'0px', fontSize:'1em'}}>
                        It might take couple of seconds
                    </div>
                </div>
    }

    async startInitialization()
    {
        try
        {
            let loadingStages = loadingUtils.getOrganizationLoadingStages();
            let loadingState = {};
            await this.loadStages(loadingStages, loadingState);

            await this.selectCurrentOrgNode();

            var action = {};
            action.type = actionTypes.organizationInitialized;
            action.payload = true;

            actionDispatcher.dispatch(action);
            return;    
        }
        catch(error)
        {
            this.state.error = error;
            this.setState({});
        }
    }

    async loadStages(loadingStages, loadingState)
    {
        const loadingStage = loadingStages.find(stage => !stage.isLoaded);

        if (loadingStage == null)
            return;

        try
        {
            await loadingStage.loadData(loadingState);
            loadingStage.isLoaded = true;
        } 
        catch (error)
        {
            throw `${loadingStage.label} failed with following error:\n\n"${error}"`;
        }

        await this.loadStages(loadingStages, loadingState);
    }

    

    async selectCurrentOrgNode()
    {
        if (stateManager.getStore().getState().currentFranchisorId == null)
        {
            if (stateManager.getStore().getState().availableFranchisors.length > 1 ||
                securityManager.isSystemUser())
                stateManager.getStore().getState().displayAccountSwitchScreen = true;
        }
        
        var organizationTaxonomy = stateManager.getStore().getState().orgData.organizationTaxonomy;

        var selectedOrgNode =
        {
            franchisorId: null,
            franchiseeId: null,
            propertyId: null
        };

        if (organizationTaxonomy.franchisors.length  == 1)
        {
            selectedOrgNode.franchisorId = organizationTaxonomy.franchisors[0].id;
        }

        if (organizationTaxonomy.properties.length  == 1 ||
            (organizationTaxonomy.properties.length > 0 && !securityManager.doesHaveAnyAccessAtOrgLevel(organizationTaxonomy.franchisors[0].id,  null, null))
            )
        {
            selectedOrgNode.franchisorId = organizationTaxonomy.franchisors[0].id;
            selectedOrgNode.franchiseeId = organizationTaxonomy.properties[0].franchiseeId;
            selectedOrgNode.propertyId = organizationTaxonomy.properties[0].id
        }

        stateManager.getStore().getState().currentOrgNode = selectedOrgNode;
    }
}
export default LoadingView;