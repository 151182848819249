
import React from 'react'
import CaptionLabel from '../../../../../../../components/form/captionLabel';
import AmountLabel from '../../../../../../../components/form/amountLabel';

const PayrollRunSummaryUtils = require('../utils/PayrollRunSummaryUtils');
const formatter = require('../../../../../../../utils/formatter/formatter');

const LabelWidth = PayrollRunSummaryUtils.SummaryLabelWidth;
const AmountWidth = PayrollRunSummaryUtils.SummaryAmountWidth;

function PayrollRunWage({ data })
{
    return <table width="100%">
        <tbody>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Planned Wage (hours)"} />
                </td>
                <td width={AmountWidth}>
                    {getFormattedWage(data.plannedWage, data.plannedHours)}
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Bonus"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.bonus} />
                </td>
            </tr>
            <tr>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Actual Wage (hours)"} />
                </td>
                <td width={AmountWidth}>
                    {getFormattedWage(data.actualWage, data.actualHours)}
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Deduction"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.deduction} />
                </td>
            </tr>
            <tr>
            <td width={LabelWidth}>
                    <CaptionLabel text={"Holiday Pay"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.holidayPay} />
                </td>
                <td width={LabelWidth}>
                    <CaptionLabel text={"Advance Payment"} />
                </td>
                <td width={AmountWidth}>
                    <AmountLabel amount={data.advancePayment} />
                </td>
            </tr>
        </tbody>
    </table>
}

function getFormattedWage(amount, hours)
{
    return `${formatter.convertToCurrencyFormat(amount)} (${formatter.roundUp(hours,2)})`;
}

export default PayrollRunWage;