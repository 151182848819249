import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ProfileOverview from './components/ProfileOverview';

class InventoryTrackingProfile extends Component
{
    render() 
    {
        var tabHeaders = [];
        tabHeaders.push(<Tab>Overview</Tab>);

        var tabContents = [];
        tabContents.push(<TabPanel style={{ marginTop: '30px' }}><ProfileOverview {...this.props}></ProfileOverview></TabPanel>)             

        return (
            <Tabs>
                <TabList>
                    {tabHeaders}
                </TabList>

                {tabContents}
            </Tabs>
        );
    }
}

export default InventoryTrackingProfile;