import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import GridComponent from '../../../../components/grid/gridComponent.js';
import ErrorMessage from '../../../../components/error/errorMessage';
import CloseButton from '../../../../components/button/closeButton';

import PrintingTemplate from './PrintingTemplate'
import SimpleToolbarButton from '../../../modules/menus/menu/common/toolbar/SimpleToolbarButton';

const arraySort = require('array-sort');
const FileSaver = require('file-saver');
const validator = require('../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const stringUtil = require('../../../../utils/string/stringUtil');
const viewUtil = require('../../../../utils/view/viewUtil.js');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const securityManager = require('../../../../utils/domain/security/securityManager');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');


class PrintingTemplates extends Component 
{
    constructor(props) 
    {
        super(props);        

        this.state = 
        {
            addEditRow: null,
            isLoading: false,
            error: null,
            importDropDownOpen: false,
            printingTemplates: [],
            systemPrintingTemplates: [],
        }

        this.importButtonToggle = this.importButtonToggle.bind(this);
        this.inputFileRef = React.createRef();
        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onAddClicked = this.onAddClicked.bind(this);
        this.onPrintingTemplateSave = this.onPrintingTemplateSave.bind(this);
        this.getComponentTitle = this.getComponentTitle.bind(this);
        this.getOnImportCallback = this.getOnImportCallback.bind(this);
        this.handleImportTemplateFile = this.handleImportTemplateFile.bind(this);
        
        this.loadData = this.loadData.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
    }

    componentDidMount() 
    {
        this.loadData();
    }

    async loadData()
    {
        this.state.isLoading = true;
        this.state.error = null;
        this.setState({});

        viewUtil.showSystemModalSpinner("Loading, please wait");
        
        let printingTemplates = [];
        let systemPrintingTemplates = [];
        let storefronts = [];

        const promises =[];
        try 
        {
            if(!currentOrgNodeSelectors.isCurrentSystemSelected())
            {
                promises.push(apiLoadFacade.loadPrintingTemplates(this.props.type))
                promises.push(apiLoadFacade.loadSystemPrintingTemplates(this.props.type))
                promises.push(apiLoadFacade.loadAllStorefrontsForCurrentFranchisor())
            }
            else
            {
                promises.push(apiLoadFacade.loadSystemPrintingTemplates(this.props.type))
            }
            const results = await Promise.all(promises);

            if(!currentOrgNodeSelectors.isCurrentSystemSelected())
            {
                printingTemplates = results[0];    
                systemPrintingTemplates = results[1];
                storefronts = results[2];
            }
            else
            {
                printingTemplates = results[0]; 
                systemPrintingTemplates = results[0]; 
                storefronts = [];
            }

            var properties = orgSelectors.selectProperties();
            storefronts.forEach(storefront => 
                {
                    storefront.name = `${storefront.name} [${properties.find(property => property.id === storefront.propertyId).name}]`
                    storefront.identifier = `${storefront.propertyId}::${storefront.id}`;
                });
        }
        catch (error) 
        {
            printingTemplates = [];
            this.state.error = error;
        }

        this.state.printingTemplates = printingTemplates;
        this.state.systemPrintingTemplates = systemPrintingTemplates;
        this.state.storefronts = storefronts;
        this.state.isLoading = false;
        viewUtil.closeModalSpinner();
        this.setState({});
    }

    render() 
    {
        let contents = null;
        if (this.state.error)
            contents = <ErrorMessage message={ this.state.error } />;

        let header = null;

        if(this.state.addEditRow)
        {
            header = <table width="100%">
                <tr>
                    <td>
                        <div style={ { fontSize: '14px', color: '#606060', fontWeight: 'bold' } }>{ this.getComponentTitle() }</div>
                    </td>
                    <td align="right">
                        <input style={{ display: "none" }} accept=".json" ref={this.inputFileRef} onChange={this.handleImportTemplateFile} type="file"/>
                        {this.getToolTipDropdownButton({
                            color: "warning",
                            marginLeft: '5px',
                            marginRight: '5px',
                            iconClassName: "fa fa-download",
                            caption: "Import Template",
                            tooltip: 'Import from a file / on of the default templates',
                            systemPrintingTemplates: this.state.systemPrintingTemplates.filter(printingTemplate => printingTemplate.type === this.props.type)
                        })}
                        {this.getToolTipButton({
                            color: "dark",
                            disabled: !(this.state.addEditRow.data.document && this.state.addEditRow.data.document.elements && this.state.addEditRow.data.document.elements.length > 0),
                            marginLeft: '5px',
                            marginRight: '10px',
                            iconClassName: "fa fa-clipboard",
                            caption: "Export Template",
                            tooltip: 'Export the printing template as a file',
                            onClick: () => {
                                const templateName = this.state.addEditRow.data.name || "template";

                                try 
                                {
                                    const blob = new Blob([JSON.stringify(this.state.addEditRow.data.document.elements)], { type: "text/json;charset=utf-8" });
                                    FileSaver.saveAs(blob, `${this.state.addEditRow.data.name}.json`);
                                    viewUtil.showSuccessAlert(`Exported as '${templateName}.json'`);
                                }
                                catch(error)
                                {
                                    viewUtil.showErrorAlert(`Error occurred while exporting template: ${error}`);
                                }
                            },
                        })}
                        {
                            this.getToolTipButton({
                            color: "success",
                            disabled: this.props.isReadOnly,
                            marginLeft: '5px',
                            marginRight: '5px',
                            iconClassName: "fa fa-save",
                            caption: "Save",
                            tooltip: 'Save Printing Template',
                            onClick: this.onPrintingTemplateSave,
                        })}

                        <Button color="dark" className="btn-secondary" style={ { marginRight: '5px' } } onClick={ () =>
                        {
                            this.setState({ addEditRow: null });
                        }}>
                            Cancel
                        </Button>
                    </td>
                </tr>
            </table>
            contents = <PrintingTemplate data={ this.state.addEditRow.data } storefronts={ this.state.storefronts } />

        }
        else
        {
            header = <table width="100%">
                <tr>
                    <td>
                        <div style={ { fontSize: '14px', color: '#606060', fontWeight: 'bold' } }>{ this.getGridTitle() }</div>
                    </td>
                    <td align="right">
                        <Button disabled={this.props.isReadOnly} color="success" className="btn-success" style={ { marginLeft: '5px', marginRight: '10px' } }
                            onClick={this.onAddClicked}>
                            <i className="fa fa-plus"></i>&nbsp;Add
                        </Button>

                        <CloseButton />
                    </td>
                </tr>
            </table>

            contents = <GridComponent headers={ this.constructGridColumnHeaders() }
                rows={ this.state.printingTemplates }
                onRowDoubleClicked={ this.onRowDoubleClicked }
                shouldReArrangeHeaders={ true }
                onGridReady={ this.onGridReady } />
        }



        return (
            <Card>
                <CardHeader>      
                    {header}
                </CardHeader>

                <CardBody>                
                    <div className="p-1">
                        {contents}
                    </div>
                </CardBody>
            </Card>);

    }

    validateData(data)
    {
        if (!validator.isPresent(data.name))
            return "Template name cannot be left empty";

        if (!validator.isPresent(data.type))
            return "Template type cannot be left empty";

        return null;
    }

    async onPrintingTemplateSave()
    {

        const validationError = this.validateData(this.state.addEditRow.data);
        if (validationError)
        {
            viewUtil.showErrorAlert(validationError);
            return;
        }

        let printingTemplates = this.state.printingTemplates;
        if (!printingTemplates) printingTemplates = [];
        let addEditRow = this.state.addEditRow;

        let waitMessage = "Please wait while adding new template.";
        if(!addEditRow.data.isNew)
            waitMessage = "Please wait while updating template.";

        let saveSuccessMessage = "New template added successfully";
        if(!addEditRow.data.isNew)
            saveSuccessMessage = "Template updated successfully";

        let saveErrorMessage = "Following error occurred while adding new template:";
        if(!addEditRow.data.isNew)
            saveErrorMessage = "Following error occurred while updating template:";

        try
        {
            viewUtil.showSystemModalSpinner(waitMessage);
            const data = addEditRow.data;
            data.json = JSON.stringify(data.document);
            delete data.document;

            let baseUrl;
            if(currentOrgNodeSelectors.isCurrentSystemSelected())
                baseUrl = "core/system";
            else
                baseUrl = rmsApiProxy.getFranchisorOrgContextUrl()
                
            const updatedTemplate = await rmsApiProxy.post(`${baseUrl}/templates/printing`, data);

            const existingIndex = printingTemplates.findIndex(template => template.id == updatedTemplate.id);

            if (existingIndex > -1)
                printingTemplates.splice(existingIndex, 1, updatedTemplate);
            else
                printingTemplates.push(updatedTemplate);

            printingTemplates = arraySort(printingTemplates, stringUtil.localeCompareProp("name"));
            addEditRow = null;
            viewUtil.showSuccessAlert(saveSuccessMessage);
        } 
        catch (error)
        {
            viewUtil.showErrorAlert(`${saveErrorMessage} ${error}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }

        this.setState({
            printingTemplates,
            addEditRow
        });
    }

    constructGridColumnHeaders()
    {
        var headers = [];
        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "type";
        header.headerName = "Type";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean + 100;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onAddClicked()
    {
        const newRow = {};

        newRow.franchisorId = currentOrgNodeSelectors.isCurrentSystemSelected() ? "" : currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId;
        newRow.type = this.props.type;
        newRow.json = JSON.stringify({});
        newRow.document = {};
        newRow.isDisabled = false;
        this.state.addEditRow = {isNew: true, data: newRow};
        this.setState({});
    }

    onRowDoubleClicked()
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        this.state.addEditRow = {isNew: false, data: selectedRow};
        this.state.addEditRow.data.document = JSON.parse(this.state.addEditRow.data.json);
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        const selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;

        return selectedNodes[0].data;
    }

    getGridTitle()
    {
        switch (this.props.type)
        {
            case "receipt":
                return "Receipt Templates";
            case "kitchenStation":
                return "Kitchen Station Printing Templates";
            case "floatSession":
                return "Float Session Printing Templates";
            case "dailySummaryReport":
                return "Daily Summary Report Templates";
            default:
                return this.props.type;
        }
    }

    getComponentTitle()
    {
        let title;

        switch (this.props.type)
        {
            case "receipt":
                title = "Receipt Template";
                break;
            case "kitchenStation":
                title = "Kitchen Station Printing Template";
                break;
            case "floatSession":
                title = "Float Session Printing Templates";
                break;
            case "dailySummaryReport":
                title = "Daily Summary Report Template";
                break;
            default:
                title = this.props.type;
        }

        return this.state.addEditRow.isNew
            ? `${title} - New`
            : `${title} - ${this.state.addEditRow.data.name}`;
    }

    getToolTipButton({color, disabled, marginLeft, marginRight, iconClassName, caption, tooltip, onClick})
    {        
        const button = <Button
            disabled={disabled}
            color={color}
            style={{ marginLeft, marginRight }}
            onClick={disabled ? null : onClick}>
            <i className={iconClassName}></i>&nbsp;&nbsp;{caption}
        </Button>;
        return <SimpleToolbarButton id={stringUtil.replaceAll(caption, " ", "-")} button={button} tooltip={tooltip}/>; 
    }

    importButtonToggle()
    {
        this.setState({
            importDropDownOpen: !this.state.importDropDownOpen
        });
    }

    getToolTipDropdownButton({marginLeft, marginRight, iconClassName, caption, tooltip, systemPrintingTemplates})
    {        
        const button = <ButtonDropdown isOpen={this.state.importDropDownOpen} toggle={this.importButtonToggle} style={{marginLeft, marginRight}}>
        <DropdownToggle color="warning" caret>
            <i className={iconClassName}></i>&nbsp;&nbsp;Import Template&nbsp;
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem className='p-1'>
            <div className='text-center'>
                <Button className='m-0'
                    color="link"
                    style={{ marginLeft, marginRight }}
                    onClick={this.getOnImportCallback(async () => {this.inputFileRef.current.click();})}
                    >
                    <i className={iconClassName}></i>&nbsp;&nbsp;Import from file
                </Button>            
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header className='px-3 py-2 text-center'>
            Default Templates
          </DropdownItem>
          {
            systemPrintingTemplates.map(systemPrintingTemplate => (
                <>
                    <DropdownItem>
                        <Button
                            color="link"
                            style={{ marginLeft, marginRight }}
                            onClick={this.getOnImportCallback(() => {
                                const document = JSON.parse(systemPrintingTemplate.json);
                                this.state.addEditRow.data.document.elements = document.elements;
                                this.setState({});
                            })}
                        >
                    {systemPrintingTemplate.name}                        
                    </Button>     
                    </DropdownItem>
                </>
            ))
          }

        </DropdownMenu>
      </ButtonDropdown>
     return <SimpleToolbarButton id={stringUtil.replaceAll(caption, " ", "-")} button={button} tooltip={tooltip}/>; 
    }

    getOnImportCallback(importFunction)
    {
        if(this.state.addEditRow.data.document.elements && this.state.addEditRow.data.document.elements.length > 0)
        {
            return () => {
                viewUtil.showConfirmDialogue("Confirmation", "Importing a template this will replace all existing template elements. Are you sure you want to continue?", 
                importFunction);
            }
        }
        else 
        {
            return importFunction
        }
    }

    readFileAsync(file) 
    {
        return new Promise((resolve, reject) => 
        {
            let reader = new FileReader();
        
            reader.onload = () => 
            {
                resolve(reader.result);
            };
        
            reader.onerror = reject;
        
            reader.readAsText(file);
        });
    }

    async handleImportTemplateFile(e)
    {
        const { files } = e.target;
        if (files == null || files.length <= 0)
            return;

        var file = files[0];

        try
        {
            viewUtil.showSystemModalSpinner("Please wait, importing template");

            var text = await this.readFileAsync(file);
            
            if (stringUtil.isStringNullOrEmpty(text))
                throw 'File is empty';

            const importedTemplate = JSON.parse(text);

            this.state.addEditRow.data.document.elements = importedTemplate;
            this.setState({});

            viewUtil.closeModalSpinner();
            viewUtil.showSuccessAlert(`Template imported successfully`);
        }
        catch(error)
        {
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(`Error occurred while importing template: ${error}`);
        }
        e.target.value = null;
    }
}

export default PrintingTemplates;
