
module.exports = function()
{
    this.files = [];
    this.title = null;
    this.orgContexts = null;
    this.rootFolder = null;
    this.objectType = null;
    this.objectId = null;
    this.isReadOnly = false;
    this.onClose = null;
}