import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as chartUtils from '../../../../../../utils/bi/chartUtils';
import * as wagesCostSummaryViewUtil from '../../viewUtils/wagesCostSummaryViewUtil';

const { formatCurrencyValue: formatCurrency, formatPercentage } = biUtils;

export default function ChartReport(props)
{
    return <AgChartsReact options={getOptions(props)} />;
}

function getOptions(props)
{
    const options = chartUtils.getDefaultPieChartOptions();

    options.title = { text: 'Breakdown of Wages Cost (%)', fontSize: 18, };
    options.subtitle = { text: 'Breakdown of wages cost to employer', };

    options.autoSize = true;
    options.padding = { top: 30 };

    options.series[0].data = getData(props);
    options.series[0].calloutLabelKey = 'label';
    options.series[0].sectorLabelKey = 'percentage';
    options.series[0].angleKey = 'percentage';

    options.series[0].calloutLabel.enabled = false;
    options.series[0].sectorLabel.formatter = ({ datum, sectorLabelKey }) => formatPercentage(datum.percentage);

    options.series[0].tooltip.renderer = ({ datum, color, calloutLabelKey, sectorLabelKey }) => [
        `<div style="background-color: ${color}; padding: 4px 8px; border-top-left-radius: 5px; border-top-right-radius: 5px; font-weight: bold; color: white;">${datum.label}</div>`,
        `<div style="padding: 4px 8px">${formatCurrency(datum.value)} (${formatPercentage(datum.percentage)})</div>`,
    ].join('\n');

    return options;
}

function getData(props)
{
    const summaryData = wagesCostSummaryViewUtil.getPayrollSummaryData(props)[0];

    return [
        { label: 'Wages', value: summaryData.wages, percentage: summaryData.wages / summaryData.total * 100 },
        { label: 'Holidays', value: summaryData.holidays, percentage: summaryData.holidays / summaryData.total * 100 },
        { label: 'Bonus', value: summaryData.bonus, percentage: summaryData.bonus / summaryData.total * 100 },
        { label: 'Pension & NI', value: summaryData.pensionAndNI, percentage: summaryData.pensionAndNI / summaryData.total * 100 },
    ];
}
