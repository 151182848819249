
import React, { Component, Fragment } from 'react';
import { Card, CardHeader,  CardBody, CardFooter, Button, Modal} from 'reactstrap';
import InputComponent from '../../../../components/form/inputComponent'
import CheckComponent from '../../../../components/form/checkComponent.js'
import CollapsibleCard from '../../../../components/collapsibleCard/collapsibleCard.js'

var stringUtil = require('../../../../utils/string/stringUtil.js');
var dateUtil = require('../../../../utils/dateUtil/dateUtil.js');
var viewUtil = require('../../../../utils/view/viewUtil.js');
var validator = require('../../../../utils/validator/validator.js');

var FormManager = require('../../../../utils/view/formManager.js');

class HolidayDialogue extends Component
{
    constructor(props)
    {
        super(props);

        this.onClose = this.onClose.bind(this);
      
        this.renderBookingRequestPanel = this.renderBookingRequestPanel.bind(this);
        this.renderFullDayComponent = this.renderFullDayComponent.bind(this);
        this.renderPartialDayComponent = this.renderPartialDayComponent.bind(this);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.bookedHoliday;
        this.state.formManager.view = this;

        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "pending"))
        {
            this.props.bookedHoliday.paidHours = 7;
            this.props.bookedHoliday.hourlyWage = this.props.staff.hourlyWage;
        }
   }

    render()
    {
        return(<Modal isOpen={true} centered animation={false} fade={false}  size="lg" style={{maxWidth: '1600px', width: '90%'}}>
                    <Card>
                        <CardHeader>
                            Holiday  - {this.getStatus()}
                        </CardHeader>
                        <CardBody>
                            <table width="100%">

                                <tr>
                                    <td>
                                       Staff Name: {this.props.staff.firstName}  {this.props.staff.lastName}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <hr style={{marginTop:'4px', marginBottom:'10px'}}/>
                                    </td>
                                </tr>

                               {this.renderBookingRequestPanel()}

                                <tr>
                                    <td>
                                        <hr style={{marginTop:'4px', marginBottom:'30px'}}/>
                                    </td>
                                </tr>

                                {this.renderBookingActionPanel()}
                            </table>
                            
                        </CardBody>

                        <CardFooter>
                                    <table width="100%">
                                        <tr>
                                            <td align="right">
                                                <div>
                                                    <Button size="lg" color="secondary" className="btn-secondary" style={{marginRight:'3px'}} onClick={this.onClose}>
                                                            Close
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                            </CardFooter>
                    </Card>
                </Modal>);
    }

    renderBookingRequestPanel()
    {
        var component = null;

        if (this.props.bookedHoliday.isFullDay)
            component = this.renderFullDayComponent();
        else
            component = this.renderPartialDayComponent();

        return(<Fragment>
                <tr>
                    <td style={{width:'100%'}}>
                            {component}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="text-muted" style={{marginTop:'5px'}}>
                            {this.props.bookedHoliday.notes}
                        </div>
                    </td>
                </tr>
        </Fragment>) 
    }

    renderFullDayComponent()
    {
        var fromDate = dateUtil.formatDate(this.props.bookedHoliday.fromDate, "DD/MMM");
        var toDate = dateUtil.formatDate(this.props.bookedHoliday.toDate, "DD/MMM");
        return (<div><span><i className="fa fa-calendar"></i></span><span style={{marginLeft:'5px'}}>Whole Day [{fromDate} To {toDate}]</span></div>);  
    }

    renderPartialDayComponent()
    {
        var localFromDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.fromDate);
        var localToDate = dateUtil.convertToLocalStandard(this.props.bookedHoliday.toDate);
        return (<div>
                    <span><i className="fa fa-clock-o"></i></span>
                    <span style={{marginLeft:'5px'}}>
                        {dateUtil.formatDate(this.props.bookedHoliday.fromDate, "DD/MMM")} [{dateUtil.formatDate(localFromDate, "HH:mm")} - {dateUtil.formatDate(localToDate, "HH:mm")}]</span></div>);  
    }

    renderBookingActionPanel()
    {
        var paidControlsDisabled =  !this.props.bookedHoliday.isPaid;
        return (<Fragment>
                    <tr>
                        <td>
                            <CollapsibleCard caption="Approve Booking Request">
                                    <table style={{width:'100%'}}>
                                        <tr>
                                            <td colSpan="2">
                                                <CheckComponent
                                                fieldName="isPaid"
                                                caption="Is Holiday Paid"
                                                inputReadOnly={this.props.isReadOnly}
                                                formManager={this.state.formManager}
                                                style={{ marginLeft: "20px" }} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <InputComponent
                                                        inputType="number"
                                                        fieldName="paidHours"
                                                        caption="Paid Hours"
                                                        placeholder="Enter no of paid hours "
                                                        hintText="Required"
                                                        inputReadOnly={paidControlsDisabled || this.props.isReadOnly}
                                                        formManager={this.state.formManager} />
                                            </td>

                                            <td>
                                                <InputComponent
                                                        inputType="number"
                                                        fieldName="hourlyWage"
                                                        caption="Hourly Wage"
                                                        placeholder="Enter the hourly wage"
                                                        hintText="Required"
                                                        inputReadOnly={paidControlsDisabled || this.props.isReadOnly}
                                                        formManager={this.state.formManager} 
                                                        style={{ marginLeft: "20px" }} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2">
                                                <InputComponent inputType="text" multiLine={true} rows={4}
                                                                caption="Approval Reason"
                                                                fieldName="approvalNotes"
                                                                placeholder="Enter any approval notes."
                                                                inputReadOnly={this.props.isReadOnly}
                                                                formManager={this.state.formManager} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" style={{textAlign:'right'}}>
                                                <Button size="lg" color="success" className="btn-success" style={{marginRight:'3px'}} disabled={this.props.isReadOnly}
                                                        onClick={()=>
                                                        {
                                                            if (stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.approvalNotes))
                                                            {
                                                                viewUtil.showErrorAlert("Approval notes is missing.");
                                                                return;
                                                            }

                                                            if (this.props.bookedHoliday.isPaid)
                                                            {
                                                                if (stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.paidHours) ||
                                                                    !validator.isNumeric(this.props.bookedHoliday.paidHours))
                                                                {
                                                                    viewUtil.showErrorAlert("Paid Hours is invalid.");
                                                                    return;
                                                                }
                                                                if (stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.hourlyWage) ||
                                                                    !validator.isNumeric(this.props.bookedHoliday.hourlyWage))
                                                                {
                                                                    viewUtil.showErrorAlert("Hourly Wage is invalid.");
                                                                    return;
                                                                }
                                                            }
                                                            else
                                                            {
                                                                this.props.bookedHoliday.paidHours = null;
                                                                this.props.bookedHoliday.hourlyWage = null;
                                                            }

                                                            this.props.bookedHoliday.status = "approved";
                                                            this.props.bookedHoliday.rejectionNotes = null;
                                                            

                                                            this.props.onApprove(this.props.bookedHoliday);
                                                        }}>
                                                            Approve
                                                    </Button>
                                            </td>
                                        </tr>
                                        
                                    </table>
                            </CollapsibleCard>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <CollapsibleCard caption="Decline Booking Request">
                                <table style={{width:'100%'}}>
                                        <tr>
                                            <td>
                                                <InputComponent inputType="text" multiLine={true} rows={4}
                                                                caption="Rejection Reason"
                                                                fieldName="rejectionNotes"
                                                                placeholder="Enter any rejection notes."
                                                                formManager={this.state.formManager} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{textAlign:'right'}}>
                                                <Button size="lg" color="danger" className="btn-secondary" style={{marginRight:'3px'}} disabled={this.props.isReadOnly}
                                                        onClick={()=>
                                                        {
                                                            if (stringUtil.isStringNullOrEmpty(this.props.bookedHoliday.rejectionNotes))
                                                            {
                                                                viewUtil.showErrorAlert("Rejection reason is missing.");
                                                                return;
                                                            }

                                                            this.props.bookedHoliday.status = "rejected";
                                                            this.props.bookedHoliday.isPaid = false;
                                                            this.props.bookedHoliday.paidHours = null;
                                                            this.props.bookedHoliday.hourlyWage = null;
                                                            this.props.bookedHoliday.approvalNotes = null;
                                                            

                                                            this.props.onReject(this.props.bookedHoliday);
                                                        }}>
                                                            Decline
                                                    </Button>
                                            </td>
                                        </tr>
                                    </table>
                            </CollapsibleCard>
                        </td>
                    </tr>
        </Fragment>);
    }

    onClose()
    {
        if (this.props.onClose)
            this.props.onClose();
    }

    getStatus()
    {
        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "pending"))
            return "Pending";

        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "approved"))
            return "Approved";

        if (stringUtil.areStringSame(this.props.bookedHoliday.status, "rejected"))
            return "Rejected";
    }
}

export default HolidayDialogue;