import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import EmptyData from '../../../../components/empty/emptyData';
import GridComponent from '../../../../components/grid/gridComponent';
import GridPageSize from '../../../../components/gridView/gridPageSize';
import Spinner from '../../../../components/spinner/spinner';
import Customer from './components/Customer';
import CustomerSearch from './components/CustomerSearch';
import customerSearchUtil from './utils/customerSearchUtil';
import customerUtil from '../../../../utils/view/customers/utils';
import GiftRewardPointsDialogue from './components/GiftRewardPointsDialogue';

const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');

const viewUtil = require('../../../../utils/view/viewUtil');
const typeUtil = require('../../../../utils/type/typeUtil');

export default class Customers extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {};
        this.state.pageSize = 50;
        this.state.showSearch = true;
        if(currentOrgNodeSelectors.isCurrentFranchisorSelected())
            this.state.searchCriteria = customerSearchUtil.createSearchCriteria([]);
        else
            this.state.searchCriteria = customerSearchUtil.createSearchCriteria(currentOrgNodeSelectors.selectAllProperties().map(property => property.id));

        this.state.isLoading = false;
        this.state.data = null;

        this.franchisor = orgSelectors.selectFranchisor(currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId);

        this.onGridReady = this.onGridReady.bind(this);
        this.onShowSearch = this.onShowSearch.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchCriteriaChanged = this.onSearchCriteriaChanged.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);

        this.state.showGiftRewardPointsDialogue = false;
        this.state.giftRewardPointsButtonDisabled = true;
        this.state.selectedRow = null;
    }

    //#region Render

    render() 
    {
        return (
            <Card>
                <CardHeader>
                    {this.renderHeader()}
                    {this.renderSearch()}
                    {this.renderLoadButton()}
                </CardHeader>

                <CardBody>
                    {this.renderBody()}
                </CardBody>
            </Card>

        );
    }

    renderHeader()
    {
        var rotation = 180;
        if (!this.state.showSearch)
            rotation = 90;

        return (
            <table width="100%">
                <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                        <div style={{ fontSize: '14px', color: '#606060', fontWeight: 'bold' }}>{"Customer Management"}</div>
                    </td>

                    <td width="99%">
                        <table width="100%">
                            <tr>
                                <td align="left">
                                    <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                                        <a className="card-header-action btn btn-minimize" onClick={this.onShowSearch}>
                                            <i className="icon-arrow-up" style={{ transform: `rotate(${rotation}deg)` }}></i>
                                        </a>
                                    </div>
                                </td>
                                <td align="right">
                                    {this.renderRowsPerPageComponent()}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        );
    }

    renderSearch()
    {
        if (!this.state.showSearch)
            return null;
        return <CustomerSearch data={this.state.searchCriteria} onChange={this.onSearchCriteriaChanged}></CustomerSearch>
    }

    renderLoadButton()
    {
        if (!this.state.showSearch)
            return null;

        const searchCriteria = this.state.searchCriteria;

        const buttonDisabled = !customerSearchUtil.isSearchCriteriaValid(searchCriteria) || this.props.isReadOnly;

        return (
            <div className='d-flex'>
                <Button color="dark" className="btn-dark" disabled={buttonDisabled} style={{ marginLeft: '3px' }} onClick={this.onSearch}>
                    <i className="fa fa-search"></i>&nbsp;Search
                </Button>
                <div style={{position:'absolute', right: '0%'}}>
                    <Button color="success" className="btn-success" disabled={this.state.giftRewardPointsButtonDisabled || this.props.isReadOnly} style={{ marginRight: '1rem', paddingInline: '12px' }} onClick={() => this.setState({showRewardPointsTransferDialogue: true})}>
                        <i className="fa fa-gift"></i>&nbsp;&nbsp;Gift Reward Points
                    </Button>
                </div>
            </div>
        )
    }

    renderRowsPerPageComponent()
    {
        return <GridPageSize gridPageSize={this.state.pageSize}
            onChange={(value) => 
            {
                this.state.pageSize = value;

                if (this.gridApi != null)
                {
                    this.gridApi.paginationSetPageSize(Number(value));
                }
                this.setState({});
            }}></GridPageSize>
    }

    renderBody()
    {
        if ((this.state.isLoading))
            return <Spinner text='Loading Data'></Spinner>;

        if (this.state.searchCriteria == null || this.state.data == null)
            return <EmptyData title="Select search criteria" text="Select customer search criteria and Load"></EmptyData>;

        return (
            <>
                <GridComponent headers={customerUtil.constructGridColumnHeaders()}
                    onGridReady={this.onGridReady}
                    paginationEnabled={true}
                    paginationSize={this.state.pageSize}
                    onRowSelected={this.onRowSelected}
                    onRowDoubleClicked={this.onRowDoubleClicked}
                    gridMode="controlled"
                    rows={this.state.data}
                    shouldReArrangeHeaders={true}
                />
                {
                    this.state.showRewardPointsTransferDialogue 
                    ?   <GiftRewardPointsDialogue 
                            data={typeUtil.deepCloneObject(this.state.selectedRow)} 
                            onClose={() => this.setState({showRewardPointsTransferDialogue: false})}
                        /> 
                    :   null
                }
            </>
        )
    }

    //#endregion

    //#region Search

    onShowSearch()
    {
        this.setState({ showSearch: !this.state.showSearch });
    }

    onSearchCriteriaChanged()
    {
        this.setState({ data: null });
    }

    async onSearch()
    {
        this.setState({ isLoading: true, data: null });

        try 
        {
            const data = await customerSearchUtil.searchCustomers(this.state.searchCriteria);

            this.setState({ isLoading: false, data: data });
        }
        catch (error) 
        {
            this.setState({ isLoading: false, data: null });
            viewUtil.showErrorAlert(error);
        }
    }

    //#region Grid related
    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        if(eventData.data)
            this.setState({selectedRow: eventData.data, giftRewardPointsButtonDisabled: false});
    }

    onRowDoubleClicked(eventData)
    {
        var selectedRow = eventData.data;
        if (selectedRow == null)
            return;

        const editRow = typeUtil.deepCloneObject(selectedRow);

        viewUtil.openModalForm(
            selectedRow.name,
            (onFieldChanged) =>
            {
                return (<Customer {...this.props} data={editRow} />);
            },
            () =>
            {
                viewUtil.showSystemModalSpinner("Updating customer");
                customerUtil.saveCustomer(editRow, this.franchisor)
                    .then((savedRow) => 
                    {
                        var data = this.state.data;
                        if (data == null)
                            data = [];

                        const index = data.indexOf(selectedRow);


                        data.splice(index, 1, editRow);

                        this.setState({ data: data });
                        viewUtil.closeModalSpinner();
                    },
                        (error) => 
                        {
                            viewUtil.closeModalSpinner();
                            viewUtil.showErrorAlert(error);
                            this.setState({});
                        })
            },
            () => 
            {
                const error = customerUtil.validateCustomer(editRow, this.franchisor);
                return error;
            },
            this.props.isReadOnly
        );
    }
    //#endregion
}