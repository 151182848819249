import React, { Component } from 'react';


import GridView from '../../../../../components/gridView/gridView';
import CreditBook from './creditBook';

import * as  GridViewOptions from '../../../../../components/gridView/gridViewOptions';
import * as  apiLoadFacade from '../../../../../utils/api/apiLoadFacade';
import * as  currentOrgNodeSelectors from '../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';

import * as stringUtil from '../../../../../utils/string/stringUtil';
import * as  validator from '../../../../../utils/validator/validator';
import * as  rmsApiProxy from '../../../../../utils/api/rmsApiProxy';

import * as  columnWidthConstants from '../../../../../utils/constants/columnWidthConstants';
import * as  dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';

const arraySort = require('array-sort');


class CreditBooks extends Component 
{
    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();





        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Credit Books";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadData;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () =>
        {
            const newRow = {};
            newRow.franchisorId = currentOrgNode.franchisorId;
            newRow.franchiseeId = currentOrgNode.franchiseeId;
            newRow.propertyId = currentOrgNode.propertyId;
            return newRow;
        }

        gridViewOptions.getComponent = (isNew, row) => <CreditBook isNew={isNew} data={row} isRowReadOnly={this.props.isReadOnly} />;
        gridViewOptions.getComponentTitle = (isNew, row) => row.name ? `Credit Book - ${row.name}` : 'Credit Book - New';

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = this.isRowReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new Credit Book";

            return "Please wait while updating Credit Book";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New Credit Book added successfully";

            return "Credit Book updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new Credit Book:";

            return "Following error occurred while updating Credit Book:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row, customButton) => this.props.isReadOnly;

    validateRow = (isNew, row) =>
    {
        if (!validator.isPresent(row.name))
            return "Credit Book name cannot be left empty";

        if (!validator.isPresent(row.description))
            return "Credit Book description cannot be left empty";

        return null;
    }

    loadData = async () =>
    {
        const resourceUrl = `${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks`;

        const data = await rmsApiProxy.get(resourceUrl);
        return arraySort(data, stringUtil.localeCompareProp("name"));
    }

    save = async (isNew, row, remoteData) =>
    {
        const updatedCreditBook = await rmsApiProxy.post(`${rmsApiProxy.getPropertyOrgContextUrl()}/accounts/creditBooks`, row);

        if (!remoteData) remoteData = [];

        const existingIndex = remoteData.findIndex(creditBook => creditBook.id === updatedCreditBook.id);

        if (existingIndex > -1)
            remoteData.splice(existingIndex, 1, updatedCreditBook);
        else
            remoteData.push(updatedCreditBook);

        const response = {};
        response.remoteData = remoteData;
        response.addUpdatedRow = updatedCreditBook;

        return response;
    }

    constructGridColumnHeaders = () =>
    {
        let header = {};
        const headers = [];

        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Credit Book";
        header.width = columnWidthConstants.name;
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "description";
        header.headerName = "Description";
        header.width = columnWidthConstants.description;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default CreditBooks;
