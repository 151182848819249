import React from "react";
import dateUtil from '../../../../../utils/dateUtil/dateUtil';
import constants from '../../../../../utils/domain/constants';
import stringUtil from '../../../../../utils/string/stringUtil';
import customerSearchUtil from '../../customers/utils/customerSearchUtil';
import SyntaxHighlight from './SyntaxHighlight';



export default function CampaignSummary(props)
{
    const campaign = props.data;

    return <table width="100%">
        <tr>
            <td>
                <b>Summary</b>
            </td>
        </tr>
        <tr>
            <div style={{ paddingBottom: '20px' }}>
                {renderSummary(campaign)}
            </div>
        </tr>
        <tr>
            <td>
                <fieldset className="form-group border">
                    <legend className="w-auto ignis-legend" style={{ fontWeight: 'normal' }}>
                        &nbsp;&nbsp;Target Customers&nbsp;&nbsp;
                    </legend>
                    {renderTargetCriteria(campaign)}
                    <div style={{ paddingBottom: '20px' }}>
                        <hr></hr>
                        Customer count (projected) : {campaign.projectedTargetCount != null ? campaign.projectedTargetCount : 'N/A'}
                    </div>
                </fieldset>
            </td>
        </tr>


        <tr>
            <td>
                <fieldset className="form-group border">
                    <legend className="w-auto ignis-legend" style={{ fontWeight: 'normal' }}>
                        &nbsp;&nbsp;Message&nbsp;&nbsp;
                    </legend>
                    {renderMessage(campaign)}
                </fieldset>
            </td>
        </tr>
    </table>
}

function renderSummary(campaign, isNew)
{
    const startDateText = `${dateUtil.isAfter(campaign.startDateTime, dateUtil.getToday()) ? 'starts' : 'started'} on ${getFormattedDateTime(campaign.startDateTime)}`;

    let endDateText = null;
    if (!stringUtil.isStringNullOrEmpty(campaign.endDateTime))
        endDateText = `and ${dateUtil.isAfter(campaign.endDateTime, dateUtil.getToday()) ? 'finishes' : 'finished'} on ${getFormattedDateTime(campaign.endDateTime)}`


    return <p>
        {campaign.type == constants.campaignType.App ? 'App messaging campaign ' : 'SMS messaging campaign '}
        {startDateText} {endDateText}
    </p>;
}

function getFormattedDateTime(dateTime)
{
    let formattedDateTime = dateUtil.formatDate(dateTime, dateUtil.getDateFormat());

    const hours = dateUtil.getHours(dateTime);
    const minutes = dateUtil.getMinutes(dateTime);

    if (hours > 0 || minutes > 0)
        formattedDateTime += ` at ${dateUtil.formatDate(dateTime, dateUtil.getHourMinuteFormat())}`;

    return formattedDateTime;
}

function renderTargetCriteria(campaign)
{
    const targetCriteria = campaign.profile.targetCriteria;
    if (!customerSearchUtil.isSearchCriteriaValid(targetCriteria))
        return 'N/A';

    const descriptions = customerSearchUtil.buildSearchDescriptions(targetCriteria);

    return <div style={{ paddingTop: '10px' }}>
        {descriptions.map(d => <div>{d}</div>)}
    </div>
}

function renderMessage(campaign)
{
    const message = campaign.profile.message;

    return <div style={{ paddingBottom: '20px', paddingTop: '10px' }}>
        <div style={{ paddingBottom: '0px' }}>
            <SyntaxHighlight text={message.titleTemplate} className="previewTitle" />
        </div>
        <SyntaxHighlight text={message.messageTemplate} />
    </div>;
}