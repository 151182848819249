
const arraySort = require('array-sort');
const constants = require('../domain/constants');
const rmsApiProxy = require('./rmsApiProxy');
const stringUtil = require('../string/stringUtil');


export async function loadMenu(menuId, revisionId) 
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/${menuId}/revisions/${revisionId}`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function exportMenu(menuId, revisionId) 
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/${menuId}/revisions/${revisionId}/export/${constants.channels.Flipdish}?referencePriceDispatchType=Collection`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadPublishLogs(menuId)
{
    let baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    let resourceUrl = `${baseUrl}/menuManagement/publishLogs?menuId=${menuId}`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadPublishedMenuMetadata() 
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/published`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadMenuHeaders()
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/headers`;

    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadMenuHeadersIncludingArchived()
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/headers?includeArchived=true`;

    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadLatestMenuRevisionHeaders()
{
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/latest`;

    const data = await rmsApiProxy.get(resourceUrl);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}

export async function loadMenuRevisionHeaders(menuId) {
    const baseUrl = rmsApiProxy.getFranchisorOrgContextUrl();
    const resourceUrl = `${baseUrl}/menuManagement/${menuId}/revisions`;
    return await rmsApiProxy.get(resourceUrl);
}

export async function loadMenuHeadersExceptCentralKitchen()
{
    let data = await loadMenuHeaders(false);
    data = data.filter(menuHeader => menuHeader.type !== constants.serviceContextTypes.centralKitchen);
    return arraySort(data, stringUtil.localeCompareProp("name"));
}
