import React, { Component } from 'react';

import GridView from '../../../../../components/gridView/gridView';
import FreebieReason from './FreebieReason';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../../components/gridView/gridViewOptions');
const validator = require('../../../../../utils/validator/validator');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');


class FreebieReasons extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadFreebieReasonsAsync = this.loadFreebieReasonsAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Freebie Reasons";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadFreebieReasonsAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        gridViewOptions.getNewRow = () => { const newRow = { franchisorId: currentOrgNode.franchisorId }; return newRow; };
        gridViewOptions.getComponent = (isNew, row) => <FreebieReason data={row}/>;

        gridViewOptions.getComponentTitle =
            (isNewRow, row, customButton) =>
                isNewRow ? "Add New Freebie Reason" : `Edit Freebie Reason: ${row.code}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = () => this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new freebie reason.";

            return "Please wait while updating freebie reason.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New freebie reason added successfully";

            return "Freebie reason updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new freebie reason:";

            return "Following error occurred while updating freebie reason:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    async loadFreebieReasonsAsync()
    {
        try
        {            
            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/freebieReasons`;

            const freebieReasons = await rmsApiProxy.get(resourceUrl);

            return arraySort(freebieReasons, 'freebieReason');

        } catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.code))
            return "Freebie reason cannot be left empty";

        return null;
    }

    async save(isNew, row, remoteData)
    {
        try
        {
            const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/freebieReasons`;
            const updatedFreebieReason = await rmsApiProxy.post(resourceUrl, row);

            if (!remoteData) remoteData = [];

            const existingIndex = remoteData.findIndex(freebieReason => freebieReason.id === updatedFreebieReason.id);
    
            if (existingIndex > -1)
                remoteData.splice(existingIndex, 1, updatedFreebieReason);
            else
                remoteData.push(updatedFreebieReason);
    
            const response = {};
            response.remoteData =  arraySort(remoteData, 'freebieReason');
            response.addUpdatedRow = updatedFreebieReason;    
            return response;
    
        } catch (error)
        {
            throw error
        }
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "code";
        header.headerName = "Freebie Reason";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        header.headerTooltip = "Reason why freebie meal is offered";
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        header.suppressSizeToFit = false;
        headers.push(header);

        return headers;
    }
}

export default FreebieReasons;
