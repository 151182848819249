import { TerminalTypes } from "../constants";

const overviewTabFields = [
     {
          label: "License Key",
          hintText: "Auto Generated",
          fieldName: "licenseKey",
          type: "input:text",
          defaultValue: null,
          fieldProps: {
               readOnly: true,
               required: true,
               disabled: true,
          },
     },
     {
        label: "Shell Code",
        hintText: "Auto Generated",
        fieldName: "shellCode",
        type: "input:hidden",
        defaultValue: null,
        fieldProps: {
             readOnly: true,
             required: true,
             disabled: true,
        },
      },
     {
        label: "Terminal Code",
        hintText: "Auto Generated",
        fieldName: "terminalCode",
        type: "input:text",
        defaultValue: null,
        fieldProps: {
             readOnly: true,
             required: true,
             disabled: true,
        },
      },
     {
          label: "Station Name",
          hintText: "Required",
          fieldName: "stationName",
          type: "input:text",
          defaultValue: null,
          fieldProps: {
               readOnly: false,
               required: true,
               placeholder: "Enter Station Name",
          },
     },
     {
          label: "Station Rendering Mode",
          hintText: "Required",
          fieldName: "renderingMode",
          type: "select",
          defaultValue: "desktop",
          fieldProps: {
               required: true,
               options: [
                    { value: "tablet", label: "Tablet" },
                    { value: "desktop", label: "Desktop" },
                    { value: "tv", label: "TV" },
               ],
          },
     },
     {
          label: "Host Type",
          hintText: "Required",
          fieldName: "hostType",
          type: "select",
          defaultValue: "android",
          fieldProps: {
               required: true,
               options: [
                    { value: "iOS", label: "iOS" },
                    { value: "android", label: "Android" },
                    { value: "windows", label: "Windows" },
               ],
          },
     },
     {
          label: "Screen Orientation",
          hintText: "Optional",
          fieldName: "screenOrientation",
          type: "select",
          defaultValue: "landscapeLeft",
          fieldProps: {
               options: [
                    { value: "landscapeLeft", label: "Landscape Left" },
                    { value: "landscapeRight", label: "Landscape Right" },
                    { value: "portraitUp", label: "Portrait Up" },
                    { value: "portraitDown", label: "Portrait Down" },
               ],
          },
     },
];

export const stationFieldsConfiguration = {
     [TerminalTypes.pos]: {
          overviewTabFields: overviewTabFields,
          settingsTabFields: [
               {
                    label: "Signage Player",
                    hintText: "Select the signage player for this terminal",
                    fieldName: "signagePlayerId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {},
               },
               {
                    label: "Storefront",
                    hintText: "Select storefront",
                    fieldName: "storeFrontId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {
                         required: true,
                    },
               },
               {
                    label: "Float Identifier",
                    hintText: "Enter the Float Id",
                    fieldName: "floatIdentifier",
                    type: "input:text",
                    defaultValue: null,
                    fieldProps: {
                         readOnly: false,
                         placeholder: "Enter Float Id",
                    },
               },
          ],
     },
     [TerminalTypes.kds]: {
          overviewTabFields: overviewTabFields,
          settingsTabFields: [
               {
                    label: "Label Printer",
                    hintText: "Select label printer",
                    fieldName: "labelPrinterId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {},
               },
               {
                    label: "Label Format",
                    hintText: "Select label format",
                    fieldName: "labelFormat",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {
                         options: [
                              {
                                   value: "headerOnly",
                                   label: "Header Only",
                              },
                              {
                                   value: "headerWithItems",
                                   label: "Header With Items",
                              },
                         ],
                    },
               },
          ],
     },
     [TerminalTypes.kiosk]: {
          overviewTabFields: overviewTabFields,
          settingsTabFields: [
               {
                    label: "Receipt Printer",
                    hintText: "Select receipt printer",
                    fieldName: "receiptPrinterId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {},
               },
               {
                    label: "Skin",
                    hintText: "Select the skin for this terminal (Required)",
                    fieldName: "skinId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {
                         required: true,
                    },
               },
               {
                    label: "Storefront",
                    hintText: "Select storefront",
                    fieldName: "storeFrontId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {
                         required: true,
                    },
               },
          ],
     },
     [TerminalTypes.kitchen]: {
          overviewTabFields: overviewTabFields,
          settingsTabFields: [
               {
                    label: "Label Printer",
                    hintText: "Select label printer",
                    fieldName: "labelPrinterId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {},
               },
          ],
     },
     [TerminalTypes.signage]: {
          overviewTabFields: overviewTabFields,
          settingsTabFields: [
               {
                    label: "Signage Player",
                    hintText: "Select the signage player for this terminal (Required)",
                    fieldName: "signagePlayerId",
                    type: "select",
                    defaultValue: "",
                    fieldProps: {
                         required: true,
                    },
               },
          ],
     },
     [TerminalTypes.centralKitchen]: {
          overviewTabFields: overviewTabFields,
          settingsTabFields: [],
     },
};
