import React from "react";
import './syntax.css';
import Highlighter from "react-highlight-words";
import constants from '../../../../../utils/domain/constants';

export default function SyntaxHighlight(props)
{
    return <Highlighter
        highlightClassName="syntaxHighlight"
        className={props.className}
        searchWords={constants.highlightTerms}
        autoEscape={true}
        textToHighlight={props.text}></Highlighter>;
}