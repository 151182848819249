import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import CdnImage from '../../../../../components/cdnImage/cdnImage.js';

var commonUtility = require('../../../../../utils/domain/commonUtility.js');
var staffRoleUtil = require('../../../../../utils/domain/staffRoleUtil.js');
var stringUtil = require('../../../../../utils/string/stringUtil.js');
var configurationManager = require('../../../../../utils/config/configurationManager.js');

class DriverDialogue extends Component
{
    render()
    {
        var allStaffs = [...this.props.staffList].sort((a, b) =>
        {
            var firstFullName = (a.firstName + " " + a.lastName).trim();
            var secondFullName = (b.firstName + " " + b.lastName).trim();
            return firstFullName.localeCompare(secondFullName);
        });

        var components = [];

        allStaffs.forEach(staff =>
        {
            if (!staffRoleUtil.isStaffADriver(staff, this.props.staffRoles))
                return;
            components.push(this.convertToDriverComponent(staff, staff.id == this.props.driver.driverId));
        });


        if (components.length <= 0)
        {
            components.push(
                <table width="100%">
                    <tr>
                        <td style={{ textAlign: 'center' }}>
                            <h4 className="text-muted" style={{ padding: '0px' }}>No Drivers Found</h4>
                        </td>
                    </tr>
                </table>
            );
        }

        return (
            <Card>

                <CardHeader>
                    Drivers
                </CardHeader>

                <CardBody>
                    <div style={{ maxHeight: "60vh", overflowY: 'auto' }}>
                        {components}
                    </div>
                </CardBody>

            </Card>);
    }

    convertToDriverComponent(staff, isSelected)
    {
        var cloudName = configurationManager.getConfig().cdnUsersCloudName;

        var pictUrl = staff.pictUrl;

        if (stringUtil.isStringNullOrEmpty(pictUrl))
        {
            cloudName = configurationManager.getConfig().cdnAppCloudName;
            pictUrl = "app/back.office/icons/default.staff.picture.png"
        }

        var table = <table>
            <tr>
                <td>
                    <CdnImage cloudName={cloudName} width="40" height="40" publicId={pictUrl} />
                </td>
                <td valign="bottom">
                    <div style={{ marginLeft: '5px' }}><h4 className="text-muted">{(staff.firstName + " " + staff.lastName).trim()}</h4></div>
                </td>
            </tr>
        </table>

        var backgroundColor = isSelected ? '#FFCC99' : null;

        return (<Card style={{ margin: '0px', marginTop: '5px' }} onClick={() => { this.onDriverSelect(isSelected ? null : staff.id) }}>
            <CardHeader style={{ padding: '10px', background: backgroundColor }}>
                {table}
            </CardHeader>
        </Card>)
    }

    onDriverSelect(driverId)
    {
        this.props.driver.driverId = driverId;
        this.setState({});
    }
}

export default DriverDialogue;