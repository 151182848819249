import React, { Component } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { DroppableContainer } from '../../utils/components/dndComponents';
import QuestionComponent from './questionComponent';

class QuestionListComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {}
    }

    render()
    {
        const incidentTypeQuestions = this.props.incidentTypeQuestions;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>

                <div className="d-flex flex-column flex-nowrap">

                    <div className="d-flex flex-row flex-nowrap"
                        style={{ backgroundColor: "SlateGrey", color: "white", fontSize: "14px", fontWeight: "bold" }}>
                        <div style={{ width: "5%" }} className="border px-2 py-2"></div>
                        <div style={{ width: "65%" }} className="border px-2 py-2">Question</div>
                        <div style={{ width: "15%" }} className="border px-2 py-2">Response Type</div>
                        <div style={{ width: "15%" }} className="border px-2 py-2">Is Compulsory</div>
                    </div>

                    <Droppable droppableId="incidentTypes">
                        {
                            (provided, snapshot) =>
                            (
                                <DroppableContainer
                                    isRowReadOnly={this.props.isRowReadOnly}
                                    ref={provided.innerRef}
                                    isDraggingOver={snapshot.isDraggingOver}
                                    {...provided.droppableProps}>

                                    {
                                        incidentTypeQuestions.map((incidentTypeQuestion, index) =>
                                            <QuestionComponent key={incidentTypeQuestion.question} incidentTypeQuestion={incidentTypeQuestion} index={index} {...this.props} />)
                                    }

                                    {provided.placeholder}

                                </DroppableContainer>
                            )
                        }
                    </Droppable>

                    <div style={{ fontSize: "12px", color: "darkgray", margin: "10px 0px", textAlign: "center" }}>
                        Use drag-n-drop to reorder question list &nbsp;&nbsp;
                    </div>

                </div>

            </DragDropContext >
        );
    }

    onDragEnd = result =>
    {
        const { source, destination, type, mode, draggableId, reason } = result;

        if (!destination)
        {
            // Implies drop outside of drop zone
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index)
        {
            // Implies no change
            return;
        }

        const incidentTypeQuestions = this.props.incidentTypeQuestions;

        const [incidentTypeQuestion] = incidentTypeQuestions.splice(source.index, 1);
        incidentTypeQuestions.splice(destination.index, 0, incidentTypeQuestion);

        this.setState({});
    };
}

export default QuestionListComponent;