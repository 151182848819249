// @ts-nocheck

import * as  stringUtil from '../string/stringUtil';
import * as stateManager from '../state/stateManager';
import * as  configurationManager from '../config/configurationManager';
import * as passportAction from '../state/actions/passport/passportAction';

import * as currentOrgNodeSelectors from '../state/stateSelectors/currentOrgNodeSelectors';

const logger = require('../logger/logger.js');
const utils = require('../logger/utils');

const waitingTime = 500;


export function getFranchisorOrgContextUrl()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
    return `core/organizations/${orgContext.franchisorId}`;
}

export function getFranchiseeOrgContextUrl()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
    return `core/organizations/${orgContext.franchisorId}/${orgContext.franchiseeId}`;
}

export function getPropertyOrgContextUrl()
{
    return `core/organizations/${getRawOrgContextToUrl()}`;
}

export function getRawOrgContextToUrl()
{
    const orgContext = currentOrgNodeSelectors.selectCurrentOrgContext();
    return `${orgContext.franchisorId}/${orgContext.franchiseeId}/${orgContext.propertyId}`;
}

export function getOrgContextUrl(franchisorId, franchiseeId, propertyId)
{
    if (!franchisorId) throw new Error('getOrgContextUrl: franchisorId is missing');
    if (propertyId && !franchiseeId) throw new Error('getOrgContextUrl: franchiseeId is missing');

    return `core/organizations/${franchisorId}${franchiseeId ? `/${franchiseeId}` : ''}${propertyId ? `/${propertyId}` : ''}`;
}

export function getCurrentOrgNodeContextUrl()
{
    let urlByOrgNode = null;

    if(currentOrgNodeSelectors.isCurrentFranchisorSelected())
    {
        urlByOrgNode = getFranchisorOrgContextUrl();
    }
    else if(currentOrgNodeSelectors.isCurrentFranchiseeSelected())
    {
        urlByOrgNode = getFranchiseeOrgContextUrl();
    }
    else if(currentOrgNodeSelectors.isCurrentPropertySelected())
    {
        urlByOrgNode = getPropertyOrgContextUrl();
    }

    return urlByOrgNode;
}


export function get(resourceUrls)
{
    const isArrayGiven = Array.isArray(resourceUrls);
    if (!isArrayGiven)
    {
        resourceUrls = [resourceUrls];
    }

    const allPromises = [];
    resourceUrls.forEach(resourceUrl =>
    {
        const promise = getFetch(resourceUrl);
        allPromises.push(promise);
    });

    return Promise.all(allPromises)
        .then(
            results =>
            {
                if (!isArrayGiven)
                    return results[0];
                else
                    return results;
            },
            error =>
            {
                return Promise.reject(error);
            });
}

export function post(resourceUrl, body)
{
    return postFetch(resourceUrl, body);
}

export function put(resourceUrl, body)
{
    return putFetch(resourceUrl, body);
}

export function deleted(resourceUrl)
{
    return deleteFetch(resourceUrl);
}

function getFetch(url)
{
    const requestUrl = getCompleteUrl(url);

    const requestBody = {
        method: 'GET',
        headers: getHttpHeader(url, "GET")
    };

    return doFetch(requestUrl, requestBody);
}

function postFetch(url, body)
{
    const requestUrl = getCompleteUrl(url);

    const requestBody = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: getHttpHeader(url, "POST")
    };

    return doFetch(requestUrl, requestBody);
}

function putFetch(url, body)
{
    const requestUrl = getCompleteUrl(url);

    const requestBody = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: getHttpHeader(url, "PUT")
    };

    return doFetch(requestUrl, requestBody);
}

function deleteFetch(url)
{
    const requestUrl = getCompleteUrl(url);

    const requestBody = {
        method: 'DELETE',
        headers: getHttpHeader(url, "DELETE")
    };

    return doFetch(requestUrl, requestBody);
}


async function doFetch(requestUrl, requestBody)
{
    var tracingContext = JSON.parse(requestBody.headers["tracing-context"]);
    logger.info(`Sending request to URL: ${requestUrl}`, tracingContext);

    let errorMessage;

    requestBody = { ...requestBody, credentials: "include" }

    return fetch(requestUrl, requestBody)
        .then(
            response =>
            {
                if (response.status < 200 || response.status > 299)
                {
                    if (response.status == 404)
                    {
                        const errorMessage = `Resource URL '${requestUrl}' does not exist on server with method '${requestBody.method}'.`;
                        logger.error(errorMessage, tracingContext);
                        return Promise.reject(errorMessage);
                    }

                    errorMessage = `HTTP Error: Status Code = ${response.status}, Resource URL '${requestUrl}'`;
                    if (response.status == 401) {
                        removePassportOn401();
                    } else {
                        logger.error(errorMessage, tracingContext);
                    }

                    return response.text()
                        .then(text => Promise.reject(text || errorMessage));
                }

                logger.info(`Response received from URL: ${requestUrl}`, tracingContext);
                return response.json();
            },
            error =>
            {
                errorMessage = error.message;
                if (!errorMessage) errorMessage = error;
                logger.error(`Following error reported while accessing resource URL '${requestUrl}' with method '${requestBody.method}':  ${errorMessage}`, tracingContext, error);
                return Promise.reject(errorMessage);
            })
        .then(x => new Promise(resolve => setTimeout(() => resolve(x), waitingTime)), error => Promise.reject(error));
}


function getHttpHeader(url, verb)
{
    const headers = {
        "Content-Type": "application/json",
        "tracing-context": JSON.stringify(utils.createTracingContext(url, verb))
    };
    
    return headers;
}

function getCompleteUrl(url)
{
    if (stringUtil.isStringNullOrEmpty(url))
        return url;

    if (url.toLowerCase().startsWith("reporting") || url.toLowerCase().startsWith("reports"))
    {
        return `${configurationManager.getConfig().reportingServer}/${url}`;
    }

    if (url.toLowerCase().startsWith("gateway"))
    {
        return `${configurationManager.getConfig().gatewayServer}/${url}`;
    }
    
    if (isCookieAuthUrl(url))
    {
        return `${configurationManager.getConfig().cookieAuthenticationApiUrl}/${url}`;
    }
    
    return `${configurationManager.getConfig().apiServer}/${url}`;
}

function isCookieAuthUrl(url)
{
    return url.toLowerCase().endsWith("clearcookie") || url.toLowerCase().endsWith("authenticatecookie");
}

function removePassportOn401()
{
    const state = stateManager.getStore().getState();

    if (state.passport == null)
        return;

    stateManager.getStore().dispatch(passportAction.createAction(null));
}