import React, { Component } from 'react';
import { Card, CardHeader, Button, CardBody } from 'reactstrap';
import FranchisorDatabaseConfigurationComponent from './components/FranchisorDatabaseConfigurationComponent';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const FormManager = require('../../../../../utils/view/formManager.js');
const franchisorDatabaseConfigurationHelper = require('./franchisorDatabaseConfigurationHelper');
const viewUtil = require('../../../../../utils/view/viewUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionTypes = require('../../../../../utils/state/actionTypes');
const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class FranchisorDatabaseConfiguration extends Component
{

    constructor(props)
    {

        super(props);
        this.state = {}
        this.state.isLoading = true;
        this.state.error = null;
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = {};
        this.state.formManager.view = this;

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({ isLoading: true });
        viewUtil.showSystemModalSpinner("Loading, please wait");
        try
        {
            let franchisorDatabaseConfiguration = await franchisorDatabaseConfigurationHelper.load();
            if (franchisorDatabaseConfiguration)
            {
                this.state.formManager.viewModel = franchisorDatabaseConfiguration;
            }
        }
        catch (error)
        {
            this.setState({ error: error });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({ isLoading: false });
        }
    }

    render()
    {
        let component = null;
        if (this.state.isLoading)
        {
            component = null
        }
        else if (this.state.error)
        {
            component = <ErrorMessage message={ this.state.errorMessage } />;
        }
        else
        {
            component = <FranchisorDatabaseConfigurationComponent formManager={ this.state.formManager } />
        }

        return (

            <Card>
                <CardHeader>
                    <EditComponentHeader 
                        title="Franchisor Database Configuration"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={ this.onSaveClicked }
                        onCancelClose={true}
                        />
                </CardHeader>
                <CardBody>
                    { component }
                </CardBody>
            </Card>
        )
    }

    async onSaveClicked()
    {
        const franchisorDatabaseConfiguration = this.state.formManager.viewModel;

        const validationError = franchisorDatabaseConfigurationHelper.validate(franchisorDatabaseConfiguration)
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }

        viewUtil.showSystemModalSpinner("Saving Franchisor Database Configuration");
        try
        {
            await franchisorDatabaseConfigurationHelper.save(franchisorDatabaseConfiguration);
            viewUtil.showSuccessAlert(`Franchisor Database Configuration saved`);

            await auditLogManager.addFranchisorAuditLog(auditConstants.action.franchisor.configuration, 
                                                        'Franchisor Database Configuration has been altered', 
                                                        franchisorDatabaseConfiguration);

            const currentOptionAction = {
                type: actionTypes.mainBarComponent,
                payload: null
            };
            actionDispatcher.dispatch(currentOptionAction);
        }
        catch (error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }
}

export default FranchisorDatabaseConfiguration;

