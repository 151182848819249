
import React, { Component } from 'react';
import { FormGroup, FormText, Label } from 'reactstrap';

import Select from 'react-select';

const constants = require('./constants');

/*
    This component does not make use of FormManager
*/

class MultiSelectComponent extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(selectedOptions)
    {
        if (this.props.onSelectionChanged)
        {
            const options = [];

            if (Array.isArray(selectedOptions) && selectedOptions.length > 0)
            {
                selectedOptions.forEach(option => options.push(option.underlyingObject));
            }

            this.props.onSelectionChanged(options);
        }
    }

    getOptions()
    {
        const result = [];

        const options = this.props.options;

        if (Array.isArray(options) && options.length > 0)
        {
            const displayFieldName = this.props.displayFieldName;
            const valueFieldName = this.props.valueFieldName;

            options.forEach(option => 
            {
                result.push(
                    {
                        label: option[displayFieldName],
                        value: option[valueFieldName],
                        underlyingObject: option,
                        disabled: option.disabled
                    })
            });
        }

        return result;
    }

    getValue()
    {
        const result = [];

        const selectedOptions = this.props.selectedOptions;

        if (Array.isArray(selectedOptions) && selectedOptions.length > 0)
        {
            const displayFieldName = this.props.displayFieldName;
            const valueFieldName = this.props.valueFieldName;

            selectedOptions.forEach(option => 
            {
                result.push(
                    {
                        label: option[displayFieldName],
                        value: option[valueFieldName],
                        underlyingObject: option,
                        // prop.value support "clearableValue". Docs: https://github.com/JedWatson/react-select/tree/v1.3.0
                        clearableValue: !option.disabled,
                    })
            });
        }

        return result;
    }

    render()
    {
        //#region style overrides

        //
        // Extracts style property from props
        // BackgroundColor style is applied only to the <Select/> component
        // All the remaining style (like margins, paddings etc) is applied only to the <FormGroup/> component
        //

        const style = this.props.style || {};
        const backgroundColorStyle = { backgroundColor: style.backgroundColor };
        delete style.backgroundColor;

        if (this.props.comboReadOnly)
        {
            if (!backgroundColorStyle.backgroundColor)
            {
                backgroundColorStyle.backgroundColor = constants.readOnlyBackgroundColor;
            }
        }

        //#endregion

        return (

            <FormGroup {...this.props} style={style}>

                {
                    this.props.caption
                        ? <Label>{this.props.caption}</Label>
                        : null
                }

                <Select
                    id={this.props.valueFieldName}
                    options={this.getOptions()}
                    value={this.getValue()}
                    clearable={this.props.clearable}
                    placeholder={this.props.placeholder}
                    disabled={this.props.comboReadOnly}
                    onChange={this.onChange}
                    multi={true}
                    style={backgroundColorStyle} />

                {
                    this.props.hintText
                        ? <FormText color="muted">{this.props.hintText}</FormText>
                        : null
                }

            </FormGroup>
        )
    }
}

export default MultiSelectComponent;