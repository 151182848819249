import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import SalesByPropertySummary from './SalesByPropertySummary';
import SalesByPropertyDetails from './SalesByPropertyDetails';
import SalesByPropertyCharts from './SalesByPropertyCharts';

import { HeaderComponent } from '../../../utils/viewUtils';

export default class SalesByProperty extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { showDetails: false };
    }

    onShowDetails = () => this.setState({ showDetails: !this.state.showDetails })

    render()
    {
        const { showDetails } = this.state;

        return (
            <div className='d-flex flex-column border rounded-top'>

                <HeaderComponent caption="Sales By Store" showDetails={showDetails} onShowDetails={this.onShowDetails} index={this.props.index} />

                <Collapse isOpen={!showDetails}>
                    <SalesByPropertySummary data={this.props.data} />
                </Collapse>

                <Collapse isOpen={showDetails}>
                    <div className='d-flex flex-column'>
                        <div>
                            <SalesByPropertyDetails data={this.props.data} showDetails={showDetails} />
                        </div>
                        <div className='m-3 border rounded'>
                            <SalesByPropertyCharts data={this.props.data} />
                        </div>
                    </div>
                </Collapse>

            </div>
        );
    }
}