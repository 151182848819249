
import React, { Component } from "react";
import { Label, Input} from 'reactstrap';
import GridLines from 'react-gridlines';
import LayoutToolbar from './LayoutToolbar.js'
import StencilView from './StencilView.js'

const typeUtil = require('../../../../../../utils/type/typeUtil.js');
const guidUtil = require('../../../../../../utils/guid/guidUtil.js');
const constants = require('../../../../../../utils/domain/constants.js');

class FloorPlanner extends Component
{
    constructor(props)
    {
        super(props);

        this.renderDrawingArea = this.renderDrawingArea.bind(this);
        this.renderDrawingToolBar = this.renderDrawingToolBar.bind(this);
        this.onNewStencil = this.onNewStencil.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.getCoordinatesInformation = this.getCoordinatesInformation.bind(this);
        this.getTotalSeatings = this.getTotalSeatings.bind(this);
        
        this.selectedStencilId = null;
        this.shapeChanged = false;
        this.displayGridLines = true;
        this.isStencilDialogueOpened = false;

        this.state = {};
        
    }

    componentDidMount()
    {
        document.addEventListener("keydown", this.onKeyDown, false);
    }
    componentWillUnmount()
    {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    onKeyDown(event)
    {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") 
        {
            if (this.selectedStencilId == null || this.isStencilDialogueOpened)
                return;

            var selectedIndex = this.props.floorPlan.stencils.findIndex(v =>{return v.id == this.selectedStencilId});
            if (selectedIndex < 0)
                return;

            var floorPlan = this.props.floorPlan;
            floorPlan.stencils.splice(selectedIndex, 1);
            this.selectedStencilId = null;
            this.setState({});
        }
    }

    render()
    {
        var components = [];

        this.props.floorPlan.stencils.forEach (stencil =>
            {
                components.push(<StencilView stencil={stencil} isSelected={this.selectedStencilId == stencil.id} 
                                webAppConfiguration={this.props.webAppConfiguration}
                                onSelect={()=>{
                                                if (this.selectedStencilId == stencil.id)
                                                    return;

                                                this.selectedStencilId = stencil.id;
                                                this.setState({});
                                              }}
                                onChange={()=>
                                                    {
                                                        this.shapeChanged = true;
                                                        this.setState({});
                                                    }}
                                onDialogueOpenClose={(isStencilDialogueOpened) =>
                                {
                                    this.isStencilDialogueOpened = isStencilDialogueOpened;
                                }}/>)
            });

        return (
                    <div>
                        <table style={{width:'100%'}}>
                            <tr>
                                <td colSpan="2">
                                    {this.renderDrawingToolBar()}
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'99%'}}>
                                        <div style={{border:'1px solid #D0D0D0'}}>
                                            {this.renderDrawingArea(components)}
                                        </div>
                                </td>
                                <td  style={{whiteSpace: "nowrap", background:'#D0D0D0', }} valign="top">
                                    <div style={{paddingLeft:'20px', paddingRight:'20px', height:'60vh', overflowY:'auto'}}>
                                        <LayoutToolbar onStencil={this.onNewStencil}/>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
            
        );
    }

    renderDrawingToolBar()
    {
        var coordinatesInformation = this.getCoordinatesInformation();
        var coordinatesComponent = null;

        if (coordinatesInformation != null)
        {
            coordinatesComponent = <span style={{marginRight:'40px'}}>
                                        <small className="text-muted">{this.getCoordinatesInformation()}</small>
                                    </span>
        }

        return (<table style={{width:'100%'}}>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}} valign="bottom">
                            {this.props.floorSelection}
                        </td>

                        <td  style={{width:'99%', textAlign:'right'}} valign="bottom">
                            <div style={{marginLeft:'10px', marginRight:'10px'}}>
                                {coordinatesComponent}
                                <span>
                                        <Input className="form-check-input" type="checkbox" checked={this.displayGridLines} onChange={(event)=>
                                            {
                                                this.displayGridLines = !this.displayGridLines;
                                                this.setState({});
                                            }}/>
                                        <Label className="form-check-label" check htmlFor="inline-checkbox1">Display Grid Lines</Label>
                                </span>
                                
                            </div>
                        </td>
                    </tr>
                </table>);
    }

    renderDrawingArea(components)
    {
        if (this.displayGridLines)
        {
            return (<GridLines className="grid-area" cellWidth={60} strokeWidth={2} cellWidth2={12}>
                            {this.renderDrawingGrid(components)}
                        </GridLines>);
        }
        
        return this.renderDrawingGrid(components);
    }

    renderDrawingGrid(components)
    {
        return <div style={{height:'60vh', overflow:'auto', position:'relative'}} onClick={()=>
                            {
                                if (this.shapeChanged)
                                {
                                    this.shapeChanged = false;
                                    return;
                                }

                                this.selectedStencilId = null;
                                this.setState({});
                            }} >
                    {components}
                </div>
    }

    onNewStencil(stencil)
    {
        var model = typeUtil.deepCloneObject(stencil);
        model.left = 20;
        model.top = 60;
        model.id = guidUtil.generateGuid();
        model.qrOrderingAppId = guidUtil.generateGuid();
        this.selectedStencilId = model.id;
       
        this.props.floorPlan.stencils.push(typeUtil.deepCloneObject(model))
        this.setState({});
    }

    getCoordinatesInformation()
    {
        var coordinatesInformation = `Total Seating: ${this.getTotalSeatings()}`
        if (this.selectedStencilId != null)
        {
            var selectedIndex = this.props.floorPlan.stencils.findIndex(v =>{return v.id == this.selectedStencilId});
            if (selectedIndex < 0)
                return coordinatesInformation;

            var stencil = this.props.floorPlan.stencils[selectedIndex];
            coordinatesInformation = coordinatesInformation + `, x: ${Math.floor(stencil.left)}, y: ${Math.floor(stencil.top)}, width: ${Math.floor(stencil.width)}, height: ${Math.floor(stencil.height)}, rotation: ${stencil.rotationAngle}°`;
            if (stencil.type == constants.floorStencilType.Seating)
            {
                coordinatesInformation = coordinatesInformation + `, seating: ${stencil.noOfSeating}`;
            }
        }

        return coordinatesInformation;
    }

    getTotalSeatings()
    {
        var seatings = 0;

        this.props.floorPlan.stencils.forEach (stencil =>
            {
                if (stencil.type == constants.floorStencilType.Seating)
                    seatings = seatings + (stencil.noOfSeating * 1);
            });
            
        return seatings;
    }
}

export default FloorPlanner;