import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import SuppliersComponent from './views/setup/inventory/suppliers/Suppliers';
import StockItemCategoriesComponent from './views/setup/inventory/categories/Categories';
import StockItemsComponent from './views/setup/inventory/stockItems/StockItems';
import PurchaseOrdersComponent from './views/modules/inventory/purchaseOrders/PurchaseOrders';
import InventoryTrackingProfileComponent from './views/setup/inventory/inventoryTrackingProfiles/InventoryTrackingProfiles';

import SalesComponent from './views/modules/sales/viewSales/Sales';
import CancelledSalesComponent from './views/modules/sales/viewSales/CancelledSales';
import SalesProjectionsOverrideComponent from './views/modules/sales/salesProjectionOverride/SalesProjectionOverride';
import StorefrontCommissionsComponent from './views/modules/sales/storefrontCommissionRun/StorefrontCommissionRuns';
import DriverSalesComponent from './views/modules/sales/driverSales/DriverSales';

import TaskLogsComponent from './views/modules/foodSafety/taskLogs/TaskLogs';
import StockItemCookingTemperatureLogsComponent from './views/modules/foodSafety/stockItems/cookingTemperatureLogs/TemperatureLogs';
import StockItemStorageTemperatureLogsComponent from './views/modules/foodSafety/stockItems/storageTemperatureLogs/TemperatureLogs';
import ApplianceTemperatureLogsComponent from './views/modules/foodSafety/appliances/temperatureLogs/TemperatureLogs';
import IncidentLogsComponent from './views/modules/foodSafety/incidentLogs/IncidentLogs';
import IncidentTypesComponent from './views/setup/foodSafety/incidents/IncidentTypes';
import AppliancesComponent from './views/setup/foodSafety/appliances/Appliances';
import TasksComponent from './views/setup/foodSafety/tasks/Tasks';
import TaskTypesComponent from './views/setup/foodSafety/taskTypes/TaskTypes';

import CustomersComponent from './views/modules/marketing/customers/Customers';
import ImportCustomersComponent from './views/setup/imports/customers/ImportCustomers';
import DiscountOffersComponent from './views/modules/marketing/discountOffers/DiscountOffers';
import VoucherComponent from './views/modules/marketing/voucherCodes/VoucherCodes';
import CampaignsComponent from './views/modules/marketing/campaign/Campaigns';
import SignagePlayersComponent from './views/setup/display/Signage/SignagePlayers';
import KioskSkinsComponent from './views/setup/display/KIOSK/KioskSkins';

import StaffComponent from './views/modules/payroll/staff/Staff';
import StaffRoleComponent from './views/modules/payroll/role/Role';
import RotaComponent from './views/modules/payroll/rota/Rota';
import HolidayPlannerComponent from './views/modules/payroll/holiday/HolidayPlanner';
import PayrollCalculatorComponent from './views/modules/payroll/run/PayrollRun';

import ExpensesComponent from './views/modules/accounts/expenses/Expenses';
import BillsComponent from './views/modules/accounts/bills/Bills';
import TransactionAccountsComponent from './views/setup/accounts/transactionAccounts/TransactionAccounts';
import CreditBooksComponent from './views/setup/accounts/creditBooks/creditBooks/creditBooks';
import CreditAccountsComponent from './views/setup/accounts/creditBooks/creditAccounts/creditAccounts';
import CreditAccountStatusComponent from './views/modules/accounts/creditBooks/creditAccountStatus/CreditAccountStatus';
import AccountCategoriesComponent from './views/setup/accounts/categories/Categories';
import AccountSubCategoriesComponent from './views/setup/accounts/subCategories/SubCategories';
import DocumentsExplorerComponent from './views/modules/documents/DocumentsExplorer';

import UserRolesComponent from './views/security/users/UsersRoles';
import AuditLogsComponent from "./views/modules/auditLogs/AuditLogs"

import FranchisorBasicConfigurationComponent from './views/setup/organization/franchisor/franchisorBasicConfiguration/FranchisorBasicConfiguration';
import FranchisorOperatingConfigurationComponent from './views/setup/organization/franchisor/franchisorOperatingConfiguration/FranchisorOperatingConfiguration';
import FranchisorDatabaseConfigurationComponent from './views/setup/organization/franchisor/franchisorDatabaseConfiguration/FranchisorDatabaseConfiguration';
import FranchisorApiIntegrationsComponent from './views/setup/organization/franchisor/franchisorApiIntegration/FranchisorApiIntegrations';
import BrandsComponent from './views/setup/organization/franchisor/brands/Brands';
import FranchisorChargesConfigurationComponent from './views/setup/organization/franchisor/chargesConfiguration/Charges';

import PropertyBasicConfigurationComponent from './views/setup/organization/property/propertyBasicConfiguration/PropertyBasicConfiguration';
import PropertyOperatingConfigurationComponent from './views/setup/organization/property/propertyOperatingConfiguration/PropertyOperatingConfiguration';
import PropertyPayrollConfigurationComponent from './views/setup/organization/property/propertyPayrollConfiguration/PropertyPayrollConfiguration';
import PropertyLayoutConfigurationComponent from './views/setup/organization/property/propertyLayoutConfiguration/PropertyLayoutConfiguration';
import PropertyStaffPermissionsConfigurationComponent from './views/setup/organization/property/propertyOperatingConfiguration/StaffPermissionsConfiguration';
import PropertyPrintConfigurationComponent from './views/setup/organization/property/propertyPrintConfiguration/PropertyPrintConfiguration';
import PropertyGeoConfigurationComponent from './views/setup/organization/property/propertyGeoConfiguration/PropertyGeoConfiguration';
import PropertyFloatConfigurationComponent from './views/setup/organization/property/propertyFloatConfiguration/PropertyFloatConfiguration';
import PropertyAddressConfigurationsComponent from './views/setup/organization/property/propertyAddressConfiguration/PropertyAddressConfigurations';
import PropertyDeliveryManagementConfigurationComponent from './views/setup/organization/property/propertyDeliveryManagementConfiguration/PropertyDeliveryManagementConfiguration';
import LocalizationSettingOverrides from './views/setup/localization/LocalizationSettingOverrides';

import StorefrontCoreComponent from './views/setup/organization/storefront/core/StorefrontCore';
import StorefrontOperatingConfigurationsComponent from './views/setup/organization/storefront/operatingConfiguration/StorefrontOperatingConfigurations';
import StorefrontPayoutConfigurationsComponent from './views/setup/organization/storefront/operatingConfiguration/StorefrontStorePayoutConfigurations';
import StorefrontOpeningHoursComponent from './views/setup/organization/storefront/openingHours/StorefrontOpeningHours';
import StorefrontBusinessHourOverridesComponent from './views/setup/organization/storefront/businessHourOverrides/StorefrontBusinessHourOverrides';
import StorefrontDeliveryChargesComponent from './views/setup/organization/storefront/deliveryCharges/StorefrontDeliveryCharges';
import StorefrontTipConfigurationComponent from './views/setup/organization/storefront/tipConfiguration/StorefrontTipConfiguration';
import StorefrontOrderIngestComponent from './views/setup/organization/storefront/orderIngest/StorefrontOrderIngest';
import StorefrontTimingConfigurationsComponent from './views/setup/organization/storefront/timingConfiguration/StorefrontTimingConfigurations';
import StorefrontOverPhonePaymentConfigurationsComponent from './views/setup/organization/storefront/overPhonePaymentConfiguration/StorefrontOverPhonePaymentConfigurations';
import StorefrontWebAppConfigurationsComponent from './views/setup/organization/storefront/webAppConfiguration/StorefrontWebAppConfigurations';
import StorefrontMobileAppConfigurationsComponent from './views/setup/organization/storefront/mobileAppConfiguration/StorefrontMobileAppConfigurations';

import PrintingTemplatesComponent from './views/setup/system/printingTemplates/PrintingTemplates';
import StationsComponent from './views/setup/stations/StationsDesigner';
import NetworkDiagramComponent from './views/setup/networkDiagram/NetworkDiagram';
import TagsComponent from './views/setup/system/lookups/tags/Tags';
import KitchenStationProfilesComponent from './views/setup/system/lookups/kitchenStationProfiles/KitchenStationProfiles';
import PriceBandsComponent from './views/setup/system/lookups/priceBands/PriceBands';
import PaymentTypesComponent from './views/setup/system/lookups/paymentTypes/PaymentTypes';
import FreebieReasonsComponent from './views/setup/system/lookups/freebieReasons/FreebieReasons';
import GuestsComponent from './views/setup/system/lookups/guests/Guests';
import NomenclaturesComponent from './views/setup/system/lookups/nomenclatures/Nomenclatures';
import AddressMappingComponent from './views/setup/system/lookups/addressManagement/addressMapping/AddressMapping';

import SalesOverviewAnalyticsComponent from './views/reports/accounts/sales/salesAnalytics/SalesOverviewAnalytics';
import SalesVatAnalyticsComponent from './views/reports/accounts/sales/vat/SalesVatAnalytics';
import DailySummaryReportComponent from './views/reports/accounts/floatSessions/dailySummaryReport/DailySummaryReport';
import DailySalesDiscountsComponent from './views/bi/sales/DailySalesDiscounts';
import DailySalesRefundsComponent from './views/bi/sales/DailySalesRefunds';
import DailySalesChargesComponent from './views/bi/sales/DailySalesChargesReport/DailySalesCharges';
import DepositTransactionReportComponent from './views/reports/accounts/floatSessions/depositTransactionReport/DepositTransactionReport';
import HourlySalesComponent from './views/bi/sales/HourlySales';
import SaleTimingAnalyticsComponent from './views/reports/accounts/sales/timingAnalytics/SaleTimingAnalytics';

import PeriodicCostSummaryReportComponent from './views/bi/general/costsSummaryReport/PeriodicCostSummaryReport';
import DailyPayrollCostsComponent from './views/bi/payroll/DailyPayrollCosts/DailyPayrollCosts';
import DailyVenueCommissionsComponent from './views/bi/accounts/DailyVenueCommissions';
import CogsSummaryReportComponent from './views/bi/inventory/cogs/CogsSummaryReport';
import FixedCostsComponent from './views/bi/accounts/FixedCosts';
import DailyStaffMealsComponent from './views/bi/payroll/DailyStaffMeals';
import DailyFreebiesMealsComponent from './views/bi/payroll/DailyFreebiesMeals';
import DailyGuestMealsComponent from './views/bi/payroll/DailyGuestMeals';

import DailySalesByDriverComponent from './views/bi/payroll/DailySalesByDriver';
import DailyUpsellingPointsComponent from './views/bi/payroll/DailyUpsellingPoints';

import InventoryPurchaseReportComponent from './views/bi/inventory/purchaseReport/InventoryPurchaseReport';
import WastagesReportComponent from './views/bi/inventory/wastagesReport/WastagesReport';
import DiscrepanciesReportComponent from './views/bi/inventory/discrepancies/DiscrepanciesReport';
import InventoryStatusComponent from './views/bi/inventory/inventoryStatus/InventoryStatusReport';
import SalesByProductReportComponent from './views/bi/general/costsSummaryReport/PeriodicCostSummaryReport';

import SalesByMenuItemComponent from './views/reports/accounts/sales/menuSales/salesByMenuItem/SalesByMenuItem';
import HourlySalesByMenuItemComponent from './views/reports/accounts/sales/menuSales/hourlySalesByMenuItem/HourlySalesByMenuItem';
import SalesByReportingTagComponent from './views/reports/accounts/sales/menuSales/salesByReportingTag/SalesByReportingTag';
import HourlySalesByReportingTagComponent from './views/reports/accounts/sales/menuSales/hourlySalesByReportingTag/HourlySalesByReportingTag';
import RefundsByMenuItemComponent from './views/reports/accounts/sales/menuSales/refundsByMenuItem/RefundsByMenuItem';

var stateManager = require('./utils/state/stateManager.js');
class EmbeddedApp extends Component 
{

    render()
    {
        const globalState = stateManager.getStore().getState();
        const applicationModeData = globalState.applicationModeData;

        return (
            <Router>
                <Switch>
                    
                    <Route path="/modules/inventory/suppliers" component={SuppliersComponent} />
                    <Route path="/modules/inventory/stockItemCategories" component={StockItemCategoriesComponent} />
                    <Route path="/modules/inventory/stockItems" component={StockItemsComponent} />
                    <Route path="/modules/inventory/purchaseOrders" component={PurchaseOrdersComponent} />
                    <Route path="/modules/inventory/inventoryTrackingProfiles" component={InventoryTrackingProfileComponent} />

                    <Route path="/modules/sales/all" component={SalesComponent} />
                    <Route path="/modules/sales/cancelledSales" component={CancelledSalesComponent} />
                    <Route path="/modules/sales/salesProjections" component={SalesProjectionsOverrideComponent} />
                    <Route path="/modules/sales/storefrontCommissions" component={StorefrontCommissionsComponent} />
                    <Route path="/modules/sales/driverSales" component={DriverSalesComponent} />

                    <Route path="/modules/payroll/staff" component={StaffComponent} />
                    <Route path="/modules/payroll/role" component={StaffRoleComponent} />
                    <Route path="/modules/payroll/rota" component={RotaComponent} />
                    <Route path="/modules/payroll/holidayPlanner" component={HolidayPlannerComponent} />
                    <Route path="/modules/payroll/payrollCalculator" component={PayrollCalculatorComponent} />

                    <Route path="/modules/foodSafety/taskLogs" component={TaskLogsComponent} />
                    <Route path="/modules/foodSafety/stockItemCookingTemperatureLogs" component={StockItemCookingTemperatureLogsComponent} />
                    <Route path="/modules/foodSafety/stockItemStorageTemperatureLogs" component={StockItemStorageTemperatureLogsComponent} />
                    <Route path="/modules/foodSafety/applianceTemperatureLogs" component={ApplianceTemperatureLogsComponent} />
                    <Route path="/modules/foodSafety/incidentTypes" component={IncidentTypesComponent} />
                    <Route path="/modules/foodSafety/incidentLogs" component={IncidentLogsComponent} />
                    <Route path="/modules/foodSafety/appliance" component={AppliancesComponent} />
                    <Route path="/modules/foodSafety/tasks" component={TasksComponent} />
                    <Route path="/modules/foodSafety/taskTypes" component={TaskTypesComponent} />

                    <Route path="/modules/marketing/customers" component={CustomersComponent} />
                    <Route path="/modules/marketing/importCustomers" component={ImportCustomersComponent} />
                    <Route path="/modules/marketing/discountOffers" component={DiscountOffersComponent} />
                    <Route path="/modules/marketing/voucherCodes" component={VoucherComponent} />
                    <Route path="/modules/marketing/campaigns" component={CampaignsComponent} />
                    <Route path="/modules/display/signagePlayers" component={SignagePlayersComponent} />
                    <Route path="/modules/display/kioskSkins" component={KioskSkinsComponent} />

                    <Route path="/modules/accounts/expenses" component={ExpensesComponent} />
                    <Route path="/modules/accounts/bills" component={BillsComponent} />
                    <Route path="/modules/accounts/transactionAccounts" component={TransactionAccountsComponent} />
                    <Route path="/modules/accounts/creditBooks" component={CreditBooksComponent} />
                    <Route path="/modules/accounts/creditAccounts" component={CreditAccountsComponent} />
                    <Route path="/modules/accounts/creditAccountStatus" component={CreditAccountStatusComponent} />
                    <Route path="/modules/accounts/categories" component={AccountCategoriesComponent} />
                    <Route path="/modules/accounts/subCategories" component={AccountSubCategoriesComponent} />
                    <Route path="/modules/documents" component={DocumentsExplorerComponent} />

                    <Route path="/modules/security/userRoles" component={UserRolesComponent} />
                    <Route path="/modules/auditLogs" component={AuditLogsComponent} />

                    <Route path="/configurations/franchisors/basicConfiguration" component={FranchisorBasicConfigurationComponent} />
                    <Route path="/configurations/franchisors/operatingConfiguration" component={FranchisorOperatingConfigurationComponent} />
                    <Route path="/configurations/franchisors/dbConfiguration" component={FranchisorDatabaseConfigurationComponent} />
                    <Route path="/configurations/franchisors/apiIntegrations" component={FranchisorApiIntegrationsComponent} />
                    <Route path="/configurations/franchisors/chargesConfiguration" component={FranchisorChargesConfigurationComponent} />
                    <Route path="/configurations/franchisors/brands" component={BrandsComponent} />
                    <Route path="/configurations/franchisors/localizationConfiguration" component={LocalizationSettingOverrides} />
                    <Route path="/configurations/franchisors/properties/addNewProperty" component={PropertyBasicConfigurationComponent} />

                    <Route path="/configurations/franchisors/properties/basicConfiguration" component={PropertyBasicConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/operatingConfiguration" component={PropertyOperatingConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/payrollConfiguration" component={PropertyPayrollConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/layoutConfiguration" component={PropertyLayoutConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/staffPermissionsConfiguration" component={PropertyStaffPermissionsConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/printConfiguration" component={PropertyPrintConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/geoConfiguration" component={PropertyGeoConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/floatConfiguration" component={PropertyFloatConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/addressConfiguration" component={PropertyAddressConfigurationsComponent} />
                    <Route path="/configurations/franchisors/properties/deliveryConfiguration" component={PropertyDeliveryManagementConfigurationComponent} />
                    <Route path="/configurations/franchisors/properties/localizationConfiguration" component={LocalizationSettingOverrides} />

                    <Route path="/configurations/franchisors/properties/storefronts/addNewStorefront" render={(props) => <StorefrontCoreComponent params={{}} />}/>
                    <Route path="/configurations/franchisors/properties/storefronts/basicConfiguration" render={(props) => <StorefrontCoreComponent params={applicationModeData.selectedStorefront}/>}/>
                    <Route path="/configurations/franchisors/properties/storefronts/operatingConfiguration" render={(props) => <StorefrontOperatingConfigurationsComponent params={applicationModeData.selectedStorefront}/>}/>
                    <Route path="/configurations/franchisors/properties/storefronts/payoutConfiguration" render={(props) => <StorefrontPayoutConfigurationsComponent params={applicationModeData.selectedStorefront}/>}/>
                    <Route path="/configurations/franchisors/properties/storefronts/openingHours" render={(props) => <StorefrontOpeningHoursComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/openingHoursOverrides" render={(props) => <StorefrontBusinessHourOverridesComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/deliveryCharges" render={(props) => <StorefrontDeliveryChargesComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/tipConfiguration" render={(props) => <StorefrontTipConfigurationComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/orderIngest" render={(props) => <StorefrontOrderIngestComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/timingsConfiguration" render={(props) => <StorefrontTimingConfigurationsComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/overPhonePayment" render={(props) => <StorefrontOverPhonePaymentConfigurationsComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/webAppConfiguration" render={(props) => <StorefrontWebAppConfigurationsComponent params={applicationModeData.selectedStorefront}/>} />
                    <Route path="/configurations/franchisors/properties/storefronts/mobileAppConfiguration" render={(props) => <StorefrontMobileAppConfigurationsComponent params={applicationModeData.selectedStorefront}/>} />

                    <Route path="/configurations/system/printingTemplates/receipt" render={(props) => <PrintingTemplatesComponent type="receipt" />} />
                    <Route path="/configurations/system/printingTemplates/kitchenStation" render={(props) => <PrintingTemplatesComponent type="kitchenStation" />} />
                    <Route path="/configurations/system/printingTemplates/voucher" render={(props) => <PrintingTemplatesComponent type="voucher" />} />
                    <Route path="/configurations/system/printingTemplates/floatSession" render={(props) => <PrintingTemplatesComponent type="floatSession" />} />
                    <Route path="/configurations/system/printingTemplates/dailySummaryReport" render={(props) => <PrintingTemplatesComponent type="dailySummaryReport" />} />

                    <Route path="/configurations/system/stations" component={StationsComponent} />
                    <Route path="/configurations/system/networkDiagram" component={NetworkDiagramComponent} />
                    <Route path="/configurations/system/tags" component={TagsComponent} />
                    <Route path="/configurations/system/kitchenStationProfiles" component={KitchenStationProfilesComponent} />
                    <Route path="/configurations/system/priceBands" component={PriceBandsComponent} />
                    <Route path="/configurations/system/paymentTypes" component={PaymentTypesComponent} />
                    <Route path="/configurations/system/freebieReasons" component={FreebieReasonsComponent} />
                    <Route path="/configurations/system/guests" component={GuestsComponent} />
                    <Route path="/configurations/system/nomenclatures" component={NomenclaturesComponent} />
                    <Route path="/configurations/system/addressMapping" component={AddressMappingComponent} />

                    <Route path="/reports/sales/overview" component={SalesOverviewAnalyticsComponent} />
                    <Route path="/reports/sales/vat" component={SalesVatAnalyticsComponent} />
                    <Route path="/reports/sales/endOfDay" component={DailySummaryReportComponent} />
                    <Route path="/reports/sales/discounts" component={DailySalesDiscountsComponent} />
                    <Route path="/reports/sales/refunds" component={DailySalesRefundsComponent} />
                    <Route path="/reports/sales/charges" component={DailySalesChargesComponent} />
                    <Route path="/reports/sales/drs" component={DepositTransactionReportComponent} />
                    <Route path="/reports/sales/timings" component={SaleTimingAnalyticsComponent} />
                    <Route path="/reports/sales/hourly" component={HourlySalesComponent} />

                    <Route path="/reports/costs/overview" component={PeriodicCostSummaryReportComponent} />
                    <Route path="/reports/costs/wages" component={DailyPayrollCostsComponent} />
                    <Route path="/reports/costs/venueCommissions" component={DailyVenueCommissionsComponent} />
                    <Route path="/reports/costs/cogsSummary" component={CogsSummaryReportComponent} />
                    <Route path="/reports/costs/fixedCosts" component={FixedCostsComponent} />
                    <Route path="/reports/costs/staffMeals" component={DailyStaffMealsComponent} />
                    <Route path="/reports/costs/freebies" component={DailyFreebiesMealsComponent} />
                    <Route path="/reports/costs/guestMeals" component={DailyGuestMealsComponent} />
                    
                    <Route path="/reports/payroll/driverSales" component={DailySalesByDriverComponent} />
                    <Route path="/reports/payroll/upsellingPoints" component={DailyUpsellingPointsComponent} />
                    
                    <Route path="/reports/inventory/purchaseOrders" component={InventoryPurchaseReportComponent} />
                    <Route path="/reports/inventory/wastages" component={WastagesReportComponent} />
                    <Route path="/reports/inventory/discrepancies" component={DiscrepanciesReportComponent} />
                    <Route path="/reports/inventory/inventoryStatus" component={InventoryStatusComponent} />
                    <Route path="/reports/inventory/salesByProduct" component={SalesByProductReportComponent} />

                    <Route path="/reports/menu/salesByMenuItem" component={SalesByMenuItemComponent} />
                    <Route path="/reports/menu/hourlySalesByMenuItem" component={HourlySalesByMenuItemComponent} />
                    <Route path="/reports/menu/salesByReportingTag" component={SalesByReportingTagComponent} />
                    <Route path="/reports/menu/hourlySalesByReportingTag" component={HourlySalesByReportingTagComponent} />
                    <Route path="/reports/menu/refundsByMenuItem" component={RefundsByMenuItemComponent} />
                    
                </Switch>
            </Router>
        )
    }
}

export default EmbeddedApp;