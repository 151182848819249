import React, {useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ChargeConfig from "./ChargeConfig";
import ChargeConstraints from "./ChargeConstraints";

const FormManager = require("../../../../../../utils/view/formManager");
const constants = require("../../../../../../utils/domain/constants");

function Charge({ row, isNew, storefrontOptions })
{
    const [state, setState] = useState({});

    const formManager = new FormManager()
    formManager.viewModel = row;
    formManager.view = {};
    formManager.view.setState = setState;
    formManager.onFieldChanged = function (event) 
    {
        if (!event) return;
        switch (event.target.id)
        {
            case "scheme":
                if (event.target.value === constants.Charges.schemes.depositReturn)
                {
                    formManager.setValue("target", constants.Charges.targets.item);
                }
                break;
            case "type":
                if (event.target.value === constants.Charges.types.fixed)
                {
                    formManager.setValue("appliedBeforeVat", true);
                    formManager.setValue("appliedBeforeDiscount", true);
                }
                break;
                
            default:
                return
        }
    }

    const tabList = [
        <Tab>Configuration</Tab>,
        <Tab>Constraints</Tab>
    ];

    const tabContent = [
        <TabPanel>
            <ChargeConfig isNew={isNew} formManager={formManager} />
        </TabPanel>,
        <TabPanel>
            <ChargeConstraints isNew={isNew} storefrontOptions={storefrontOptions} formManager={formManager} />
        </TabPanel>
    ];
    return (
        <Tabs>
            <TabList>
                {tabList}
            </TabList>
            {tabContent}
        </Tabs>
    )
}

export default Charge;