import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../../../utils/bi/biUtils';

import { getDistinctStorefrontTypes } from '../../../utils/dataUtils';


export default class SalesByReportTagsDetails extends Component
{
    componentDidUpdate()
    {
        if (this.props.showDetails === true)
        {
            if (this.gridApi != null) this.gridApi.sizeColumnsToFit();
        }
    }

    render()
    {
        // Using same gridOptions object across multiple grids in the same component results
        // in stack overflow error in ag-grid        

        const rowData = this.getRowData();
        const columnDefs = this.getColumnDefs();

        const gridOptions = { ...biUtils.gridOptions };

        return (
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    sideBar={null}
                    rowGroupPanelShow="never"
                    domLayout='autoHeight'
                    gridOptions={gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>);
    }

    onGridReady = (params) =>
    {
        this.gridApi = params.api;

        if (this.props.showDetails === true)
        {
            this.gridApi.sizeColumnsToFit();
        }
    }

    getRowData()
    {
        const { salesByReportTags } = this.props.data;

        const dataRows = [];

        salesByReportTags.forEach(data => 
        {
            let dataRow = dataRows.find(dataRow => dataRow.reportTags === data.reportTags);

            if (dataRow == null)
            {
                dataRow = { reportTags: data.reportTags, total: 0.0 };
                dataRows.push(dataRow);
            }

            dataRow[data.storefrontType] = data.totalRetailOrderPrice;
            dataRow["total"] = dataRow["total"] + data.totalRetailOrderPrice;
        });

        return dataRows;
    }

    getColumnDefs()
    {
        const { salesByReportTags } = this.props.data;

        const distinctStorefrontTypes = getDistinctStorefrontTypes(salesByReportTags);

        const columnDefs = [
            {
                headerName: "Report Tags",
                field: "reportTags",
                minWidth: 150,
                suppressSizeToFit: false
            },
            {
                headerName: "Total",
                field: "total",
                type: "money"
            }
        ];

        columnDefs.push.apply(
            columnDefs,
            distinctStorefrontTypes.map(storefrontType => ({
                headerName: storefrontType,
                field: storefrontType,
                type: "money"
            }))
        );

        return columnDefs;
    }
}