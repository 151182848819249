import React, { Component } from "react";

import InputComponent from '../../../../../components/form/inputComponent';

export default class IncidentTypeOverview extends Component
{
    constructor(props)
    {
        super(props);

        this.formManager = this.props.formManager;
    }

    render() 
    {
        return (
            <table className="component-table">

                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="identity"
                                caption="Incident Type"
                                fieldName="name"
                                placeholder="Enter incident type"
                                hintText="Enter incident type (Required)"
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                multiLine={true} rows={4}
                                inputReadOnly={this.props.isRowReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>

                </tbody>

            </table>
        );
    }
}