
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardHeader,  CardBody, CardFooter, Button, Modal } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Jumbotron from "../../../../../../../components/jumbotron/jumbotron";
import InputComponent from '../../../../../../../components/form/inputComponent'
import SelectComponent from '../../../../../../../components/form/selectComponent.js'

var FormManager = require('../../../../../../../utils/view/formManager.js');
var constants = require('../../../../../../../utils/domain/constants.js');

class WeatherSlideDialogue extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.slide;
        this.state.formManager.view = this;
    }

    render()
    {
        return(<Modal isOpen={true} centered animation={false} fade={false}  size="lg" style={{maxWidth: '1600px', width: '90%'}}>
                    <Card>
                        <CardHeader>
                            Weaither Widget Slide  - {this.props.slide.name}
                        </CardHeader>
                        <CardBody>
                            <Tabs>
                                <TabList>
                                    <Tab>General</Tab>
                                    <Tab>Overrides</Tab>
                                </TabList>
                                <TabPanel style={{ marginTop: '5px' }}>
                                    {this.renderGeneralTab()}
                                </TabPanel>
                                <TabPanel style={{ marginTop: '5px' }}>
                                    {this.renderOverridesTab()}
                                </TabPanel>
                            </Tabs>
                        </CardBody>

                        <CardFooter>
                                <table width="100%">
                                    <tr>
                                        <td align="right">
                                            <div>
                                                <Button size="lg" color="secondary" className="btn-secondary" style={{marginRight:'3px'}} onClick={()=>{this.props.onClose()}}>
                                                        Close
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                        </CardFooter>
                    </Card>
                </Modal>);
    }

    renderGeneralTab()
    {

        var secondsOptions = [];

        for (var index = 5; index <=300; index++)
        {
            var option = {};
            option.label = `${index} seconds`
            option.value = index
            secondsOptions.push(option);
        }

        var visibilityOptions = [];
        var visibilityOption = {};
        visibilityOption.label = "Always"
        visibilityOption.value = constants.signageSlideVisibility.Always;
        visibilityOptions.push(visibilityOption);

        visibilityOption = {};
        visibilityOption.label = "Visible With Active Order"
        visibilityOption.value = constants.signageSlideVisibility.WithActiveOrder;
        visibilityOptions.push(visibilityOption);

        visibilityOption = {};
        visibilityOption.label = "Visible With No Active Order"
        visibilityOption.value = constants.signageSlideVisibility.WithoutActiveOrder;
        visibilityOptions.push(visibilityOption);

        visibilityOption = {};
        visibilityOption.label = "Hidden"
        visibilityOption.value = constants.signageSlideVisibility.Hidden;
        visibilityOptions.push(visibilityOption);


        return <table width="100%">
                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" caption="Slide Name" hintText="Required" fieldName="name" placeholder="Enter slide name" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td style={{width:'50%'}}>
                            <SelectComponent
                                optionValues={secondsOptions}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                caption="Timings"
                                fieldName="timings"
                                formManager={this.state.formManager}
                                clearable={false}
                                hintText = "Choose seconds this slide will remain visible"
                                style={{marginRight:'10px'}}
                            />
                        </td>
                        <td>
                            <SelectComponent
                                optionValues={visibilityOptions}
                                optionFieldName="value"
                                optionDisplayFieldName="label"
                                caption="Visibility"
                                fieldName="visibility"
                                formManager={this.state.formManager}
                                clearable={false}
                                hintText = "Choose visibility of this slide"
                                style={{marginRight:'10px'}}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" caption="Weather City Code" hintText="Required" fieldName="weatherCityCode" placeholder="Enter city code for weather" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent inputType="text" caption="Widget Width" hintText="Optional (Specify the width for display)" fieldName="weatherWidgetWidth" placeholder="Enter widget width" formManager={this.state.formManager} />
                        </td>

                        <td>
                            <InputComponent inputType="text" caption="Widget Height" hintText="Optional (Specify the height for display)" fieldName="weatherWidgetHeight" placeholder="Enter widget height" formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent inputType="text" multiLine={true} caption="Notes" rows={4}
                                fieldName="notes" placeholder="Enter any notes for slide." formManager={this.state.formManager} />
                        </td>
                    </tr>

                </table>
    }

    renderOverridesTab()
    {
        return <Jumbotron>
                    <h3>Slide Overrides!</h3>
                    <p className="lead">Here you can set overrides. e.g. This slide is only visible during specific time of day</p>
                    <hr className="my-2" />
                    <p>This feature will be enabled in future release. </p>
                </Jumbotron>
    }
}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(WeatherSlideDialogue);