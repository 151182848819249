import React from "react";
import styled from "styled-components";

const ActionDropdownMenuBtn = ({ clicked = false, onClick = () => {} }) => {
     return (
          <Btn onClick={onClick}>
               {clicked ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                         <path stroke="#f95a5a" strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
               ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                         <path  strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
               )}
          </Btn>
     );
};

const Btn = styled.div`
     width: 40px;
     height: 40px;
     transform: rotate(90deg);
     cursor: pointer;

     svg {
          fill: white;
          transition: 250ms ease;
     }

     svg:hover {
          filter: brightness(0.96);
     }
`;
export default ActionDropdownMenuBtn;
