import React, { useCallback, useEffect, useState } from "react";
import SaveButton from "./SaveButton";
import CloseButton from "./CloseButton";
import { NetworkDiagramActionTypes, useNetworkDiagramContext } from "../../store";
import DiagramToCallConverter from "../../middleware/DiagramToCallConverter";
import { updateStations } from "../../networkDiagramUtil";
import logger from "../../../../../utils/logger/logger";
import securityManager from "../../../../../utils/domain/security/securityManager";
const viewUtil = require("../../../../../utils/view/viewUtil");
const actionDispatcherUtil = require("../../../../../utils/state/actions/actionDispatcherUtil");

const ToolbarButtons = () => {
     const { state, dispatch } = useNetworkDiagramContext();
     const [isLoading, setIsLoading] = useState(false);

     const handleOnSaveClick = async () => {
          if (isLoading) return;
          try {
               setIsLoading(true);
               const stationsData = DiagramToCallConverter(state);
               await updateStations(stationsData);
               viewUtil.showSuccessAlert(`Stations has been updated.`);

               dispatch({
                   type: NetworkDiagramActionTypes.UPDATE_IS_UPDATED,
                   payload: false,
               });
               setIsLoading(false);
          } catch (e) {
               viewUtil.showErrorAlert(`Stations failed to update.`);
               logger.error(`Error occurred while converting diagram data to call data: ${e}`, {}, e);
          }
     };

     const handleOnCloseClick = useCallback(() => {
          if (state.isUpdated) {
               viewUtil.showConfirmDialogue(`Unsaved Changes`, `You have some unsaved changes. Are you sure you want to go back?`, () => {
                    actionDispatcherUtil.showOptionsUI();
               });
          } else {
               actionDispatcherUtil.showOptionsUI();
          }
     }, [state.isUpdated]);

     return (
          <>
               <SaveButton disabled={state.isReadOnly || !state.isUpdated || isLoading} isLoading={isLoading} onClick={handleOnSaveClick} />
               <CloseButton onClick={handleOnCloseClick} />
          </>
     );
};

export default ToolbarButtons;
