import React, { Component } from 'react';
import { Badge, ListGroup, ListGroupItem, TabPane, Button, ModalFooter, ModalHeader } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import LogViewer from './logViewer';
import { error } from '../../../../../../utils/logger/logger';

const viewUtil = require('../../../../../../utils/view/viewUtil');

class TabbedBodyContents extends Component {
    constructor(props) {
        super(props);

        let errors = [];
        let warnings = [];
        if (this.props.logs)
        {
            errors = this.props.logs.filter(log => log.severity == "error");
            warnings = this.props.logs.filter(log => log.severity == "warning");
        }

        this.state = {};
        this.state.tabs = [];
        if(errors.length > 0)
        {
            this.state.tabs.push({caption: "Errors", color:"danger", count:errors.length, data: errors});
        }
        if(warnings.length > 0)
        {
            this.state.tabs.push({caption: "Warnings", color:"warning", count:warnings.length, data: warnings});
        }

        this.state.selectedTabIndex = 0;
    }

    getTabs() {
        return <div style={{ position: "relative" }}>
            <div className='d-flex flex-row' >
                {this.state.tabs.map((item, index) => {
                    return (

                        <span className="py-2 text-uppercase" style={{
                            flexGrow: 1, display: "block", textAlign: 'center', color: "black",
                            background: this.state.selectedTabIndex === index ? "white" : "#eeeeee"
                        }}
                            onClick={() => {
                                this.state.selectedTabIndex = index;
                                this.setState({});
                            }}
                        >
                            {item.caption} 
                                <Badge color={item.color} style={{ marginLeft: '10px' }}>
                                    {item.count}
                                </Badge>
                        </span>
                    );
                })}
            </div>
        </div>

    }

    render() {
        return (
            <div style={{borderColor: "rgb(39, 117, 213)"}} className='d-flex flex-column border my-3'>
                {this.getTabs()}
                <div className='bg-white flex-grow-1'>
                    <TabPane>
                        {this.getTabPanel()}
                    </TabPane>
                </div>
            </div>
        )
    }

    getTabPanel() {

        return <div style={{ padding: '30px 20px',maxHeight:'45vh', overflowY: 'auto' }}>
            <LogViewer isDefaultOpen={false} logs={this.state.tabs[this.state.selectedTabIndex].data} color={this.state.tabs[this.state.selectedTabIndex].color} publish={this.props.publish}/>
        </div>
    }
}

export default TabbedBodyContents;