
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardHeader, Button, CardBody, Badge } from 'reactstrap';

import ScaleLoader from "react-spinners/ScaleLoader";
import { CloudinaryContext, Image } from 'cloudinary-react';

const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');
const signagePlayerUtil = require('../../../../../utils/domain/signagePlayerUtil');
const constants = require('../../../../../utils/domain/constants.js');
const stringUtil = require('../../../../../utils/string/stringUtil.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
var configurationManager = require('../../../../../utils/config/configurationManager.js');

class KioskSkinsListComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.onLoadSkin = this.onLoadSkin.bind(this);
        this.onNewSkin = this.onNewSkin.bind(this);
        this.renderSkin = this.renderSkin.bind(this);
        this.renderSkinImage = this.renderSkinImage.bind(this);
    }

    async componentDidMount()
    {
        if(!this.props.listViewState.listOfPlayers || !this.props.listViewState.listOfSkins)
            await this.onLoadSkin();
    }

    render()
    {
        return (<Card style={{userSelect:'none'}} >
                    <CardHeader>
                        {this.renderHeader()} 
                    </CardHeader>
                    
                    <CardBody>
                        <div style={{marginTop:'20px'}}>
                            {this.renderBody()}
                        </div>
                    </CardBody>
                </Card>);
    }

    renderHeader()
    {
        return(
            <table width="100%">
                <tr width="100%">
                    
                    <td style={{whiteSpace: "nowrap"}}>
                        <div style={{fontSize:'14px',color: '#606060', fontWeight:'bold'}}>KIOSK Skins</div>
                    </td>

                    <td  style={{whiteSpace: "nowrap", width:'99%'}} align = "right">
                        <Button disabled={this.props.listViewState.mode != 'loaded' || this.props.isReadOnly} 
                                color="success" className="btn-success" style={{ marginRight: '3px' }} onClick={this.onNewSkin}>
                            <i className="fa fa-plus"></i>&nbsp;New Skin
                        </Button>
                    </td>

                </tr>
            </table>
          );
    }

    onLoadSkin()
    {
        var signageUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs?type=${constants.blogTypes.SignagePlayers}`;
        var skinURL = `${rmsApiProxy.getFranchisorOrgContextUrl()}/blobs?type=${constants.blogTypes.KioskSkins}`;

        rmsApiProxy.get([signageUrl,  skinURL])
                .then(results =>
                    {
                        var signageBlobs = results[0];
                        var skinsBlobs = results[1];

                        var players = [];
                        if (signageBlobs != null && signageBlobs.length > 0)
                        {
                            signageBlobs.forEach (signageBlob =>
                            {
                                players.push(JSON.parse(signageBlob.document))
                            });
                        }

                        var skins = [];
                        if (skinsBlobs != null && skinsBlobs.length > 0)
                        {
                            skinsBlobs.forEach (skinBlobs =>
                            {
                                skins.push(JSON.parse(skinBlobs.document))
                            });
                        }

                        this.props.listViewState.mode = "loaded";
                        this.props.listViewState.listOfPlayers = signagePlayerUtil.getSlidesSignagePlayers(players);
                        this.props.listViewState.listOfSkins = skins;
                        this.setState({});
                    }, error =>
                    {
                        this.props.listViewState.mode = 'error';
                        this.props.listViewState.error = error;
                        this.setState({});
                    });

        this.props.listViewState.mode = 'searching';
        this.setState({});
    }

    onNewSkin()
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode()
        const franchisorId = currentOrgNode.franchisorId;
        this.props.onNewSkin(franchisorId);
    }

    renderBody()
    {
        if (this.props.listViewState.mode == null || this.props.listViewState.mode == 'none')
        {
            return (
                    <div className="text-muted" style={{textAlign:'center'}}>
                        Please select account and press load to fetch all skins
                    </div>
                   );
        }

        if (this.props.listViewState.mode == 'error')
        {
            return (
                    <div className="text-muted" style={{textAlign:'center'}}>
                        <div style={{color: '#FFA07A'}}>{this.props.listViewState.error}</div> 
                    </div>
                   );
        }

        if (this.props.listViewState.mode == 'searching')
        {
            return (
                <div>
                        <Row className="justify-content-center">
                            <ScaleLoader
                                height={50}
                                width={6}
                                color={"#808080"}
                                loading={true}/>
                        </Row>
                        <Row className="justify-content-center" style={{margin:'5px'}}>
                            <h4 className="text-muted">Please wait, while loading Skins</h4>
                        </Row>
                    </div>
             );
        }

        if (this.props.listViewState.mode == 'loaded' && (this.props.listViewState.listOfSkins == null || this.props.listViewState.listOfSkins.length <=0))
        {
            return (
                <div className="text-muted" style={{textAlign:'center'}}>
                    No KIOSK Skins found.
                </div>
               );
        }

        var skinComponents = [];
        this.props.listViewState.listOfSkins.forEach (skin =>
            {
                skinComponents.push(this.renderSkin(skin));
            })

        return (<div>
                    {skinComponents}
                </div>);
    }

    renderSkin(skin)
    {

        var player = this.props.listViewState.listOfPlayers.find(p => stringUtil.areStringSame(p.id, skin.playerId));
        var badges = [];
        if(player)
            badges.push(<Badge className="bg-success text-white" pill style={{marginRight:'5px'}}>{player.name}</Badge>);

        return (<Card style={{userSelect:'none', marginBottom:'10px', marginRight:'10px'}}>
                    <CardBody>
                        <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{whiteSpace: "nowrap", borderRight:'1px solid #C8C8C8'}}>

                                        <div style={{marginRight:'5px'}}>
                                            {this.renderSkinImage(skin)}
                                        </div>
                                    </td>
                                    <td style={{whiteSpace: "nowrap", width:'99%', verticalAlign:'top'}}>
                                        <div style={{marginLeft:'5px'}}>
                                        <table style={{width:'100%'}}>
                                                <tr>
                                                    <td style={{whiteSpace: "nowrap", width:'99%', borderRight:'1px solid #C8C8C8', verticalAlign:'top'}}>    
                                                        <table style={{width:'100%'}}>
                                                            <tr>
                                                                <td>
                                                                    {skin.name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <small className="text-muted">{skin.notes ?? 'No Descriptions'}</small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {badges}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style={{whiteSpace: "nowrap"}}>
                                                        <Button color="primary" outline block style={{marginLeft:'5px'}} onClick={()=>{this.props.onEditSkin(skin);}}>Edit</Button>
                                                    </td>
                                                </tr>
                                        </table>
                                        </div>
                                    </td>
                                </tr>
                        </table>
                    </CardBody>
                </Card>);
    }

    renderSkinImage(skin)
    {
        var imageUrl = 'app/back.office/icons/slide.image.png';
        var cloudName = configurationManager.getConfig().cdnAppCloudName;
        return (<CloudinaryContext cloudName={cloudName}>
                    <div>
                        <Image publicId={imageUrl} width="64" height="64" />
                    </div>
                </CloudinaryContext>);
    }
}

const mapStateToProps = (state) =>
{
    return {
    };
};

export default connect(mapStateToProps)(KioskSkinsListComponent);