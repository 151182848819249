import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../../utils/constants/dataTypeConstants';
import * as currentOrgNodeSelectors from '../../../../../../../utils/state/stateSelectors/currentOrgNodeSelectors';

class TabularReport extends Component
{
    render()
    {
        const { refundsData } = this.props;

        const totalsRow = this.getTotalsRow(refundsData);

        return (
            <div className="ag-theme-alpine" style={{ height: "80vh" }}>
                <AgGridReact
                    domLayout='normal'
                    rowData={refundsData}
                    columnDefs={this.getColumnDefs()}
                    pinnedBottomRowData={[totalsRow]}
                    gridOptions={biUtils.gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    onGridReady = (params) =>
    {
        params.api.sizeColumnsToFit();      // Size of columns to fit the available horizontal space

        params.api.eventService.addEventListener('filterChanged', (e) => 
        {
            const rows = [];
            e.api.forEachNodeAfterFilter(rowNode => rows.push(rowNode.data));

            const totalsRow = this.getTotalsRow(rows);
            e.api.setPinnedBottomRowData([totalsRow]);
        });
    }

    getTotalsRow = (rows) =>
    {
        return {
            quantity: rows.reduce((sum, row) => sum + row.quantity, 0),
            retailPrice: rows.reduce((sum, row) => sum + row.retailPrice, 0)
        };
    }

    getColumnDefs = () =>
    {
        const columnDefs = [];

        if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
        {
            columnDefs.push({ field: 'propertyName', type: 'rowGroup' });
        }

        columnDefs.push({ field: 'refundCode', type: 'rowGroup' });

        columnDefs.push({ field: 'category', type: 'rowGroupEnabled', hide: false });
        columnDefs.push({ field: 'caption', headerName: 'Menu Item' });

        columnDefs.push({ field: 'quantity', type: `${dataTypeConstants.number}` });
        columnDefs.push({ field: 'retailPrice', type: `${dataTypeConstants.money}` });

        // Allow columns to fit the available horizontal space
        columnDefs.forEach(colDef => colDef.suppressSizeToFit = false);

        return columnDefs;
    }
}

export default TabularReport;