import formatter from '../../../../../../utils/formatter/formatter';

export function getNormalizedTimingData(timingData)
{
    // timingData = [{dayName, hour, saleCount, averageTime}]
    // normalizedTimingData = [{hour:"01:00", Mon:{saleCount: 5, averageTime: 33},...Weekly:{saleCount: 15, averageTime: 25}}]

    const normalizedTimingData = [];

    Array(24).keys().forEach(hour => 
    {
        let totalSaleCount = 0, totalTime = 0;
        const hourData = { hour: `${hour.toString().padStart(2, '0')}:00` };

        dayNames
            .filter(dayName => dayName !== 'Weekly')
            .forEach(dayName => 
            {
                const data = timingData.find(data => data.dayName === dayName && data.hour === hour);

                if (data != null)
                {
                    hourData[dayName] = { saleCount: data.saleCount, averageTime: data.averageTime };

                    totalSaleCount += data.saleCount;
                    totalTime += data.saleCount * data.averageTime;
                }
            });

        if (totalSaleCount > 0)
        {
            hourData['Weekly'] = { saleCount: totalSaleCount, averageTime: formatter.roundUp(totalTime / totalSaleCount, 0) };
            normalizedTimingData.push(hourData);
        }
    });

    return normalizedTimingData;
}

export const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Weekly"];
