
module.exports = function()
{
    this.id = "";
    this.floorId = "";

    this.title = "";
    this.imageUrl = "";
    
    this.toolBarImageWidth = 0;
    this.toolBarImageHeight = 0;

    this.left = 0;
    this.top = 0;
    this.width = 0;
    this.height = 0;
    this.rotationAngle = 0;

    this.noOfSeating = 0;
    this.tableNumber = "";
    this.qrOrderingAppEnabled = false;
    this.qrOrderingAppId = "";
}