import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Handle, Position } from "reactflow";
import ActionDropdownMenu from "../components/ActionDropdown/ActionDropdownMenu";

const ConnectDevicesBtn = ({ actionOptions }) => {
     const [isActionOpen, setIsActionOpen] = useState(false);

     const optionPopupRef = useRef(null);

     const handleOnClose = () => {
          setIsActionOpen(false);
     };

     const handleOnToggle = useCallback(() => {
          setIsActionOpen((prev) => !prev);
     }, []);

     const handleClickOutside = (event) => {
          if (optionPopupRef.current && !optionPopupRef.current.contains(event.target)) {
               handleOnClose();
          }
     };

     useEffect(() => {
          document.addEventListener("click", handleClickOutside);

          return () => {
               document.removeEventListener("click", handleClickOutside);
          };
     }, []);

     return (
          <Container>
               <Line />

               <Btn onClick={handleOnToggle} ref={optionPopupRef}>
                    <Icon />
                    <PlusIcon />
                    <SideLabel>Connect</SideLabel>
                    <ActionDropdownMenu title="Add" options={actionOptions} isOpen={isActionOpen} />
               </Btn>

               <Handle position={Position.Bottom} type="source" />
          </Container>
     );
};

export default ConnectDevicesBtn;

const PlusIcon = (props) => {
     return (
          <PlusIconContainer>
               <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#000000" {...props}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
               </svg>
          </PlusIconContainer>
     );
};

const PlusIconContainer = styled.div`
     position: absolute;
     top: 100%;
     left: 50%;
     background-color: white;
     border-radius: 1000px;
     transform: translateX(-50%) translateY(-50%);
     z-index: 99999;
`;
const SideLabel = styled.div`
     position: absolute;
     top: 50%;
     left: calc(100% + 16px);
     transform: translateY(-50%);
     color: black;
`;

const Icon = () => {
     return (
          <svg width="100%" height="100%" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M17.3864 4.54935C16.2159 3.45352 16.4246 2.65231 17.6875 1.84645C20.6731 -0.0599019 24.6785 0.225411 27.2785 2.61155C29.9332 5.04661 30.7078 9.01071 29.1574 12.2237C27.5888 15.4751 24.1184 17.2731 20.5818 16.6652C17.1639 16.0783 14.4487 13.033 14.0768 9.44042C13.9662 8.37137 13.9 7.23944 13.503 6.26821C12.126 2.8957 8.33845 1.57046 5.18177 3.24041C2.12777 4.85679 1.05311 8.76149 2.82482 11.8033C4.65471 14.9429 8.48219 15.786 11.4575 13.6991C11.8408 13.4301 12.2059 13.1355 12.636 12.8106C12.8288 13.0575 12.9965 13.2205 13.1014 13.4173C13.2657 13.7248 13.3878 14.0555 13.5144 14.3443C10.8506 17.0996 7.02079 17.5701 3.86069 15.5857C0.683481 13.5908 -0.715176 9.70011 0.465583 6.13546C1.64178 2.58593 5.02662 0.259182 8.54608 0.579431C12.2891 0.920642 15.3158 3.74116 15.836 7.39432C15.9466 8.16991 15.9421 8.96529 16.0756 9.73622C16.5467 12.4624 18.7303 14.5376 21.3884 14.8311C23.9906 15.1199 26.6214 13.4756 27.6139 10.9404C28.5859 8.45988 27.8489 5.61025 25.7954 3.89721C23.7123 2.16088 20.7758 2.06073 18.5146 3.66081C18.1302 3.93214 17.7731 4.24424 17.3875 4.54819L17.3864 4.54935Z"
                    fill="#FC5555"
               />
          </svg>
     );
};

const Container = styled.div`
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     display: flex;
     flex-direction: column;
     align-items: center;
`;

const Line = styled.div`
     width: 1px;
     height: 60px;
     background-color: black;
`;
const Btn = styled.div`
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 52px;
     height: 52px;
     padding: 10px;
     background-color: white;
     border: 2px solid #fc5555;
     border-radius: 15px;
     cursor: pointer;
     transition: 150ms ease-in;

     svg:last-child {
          transition: 150ms ease-in;
     }

     :hover {
          box-shadow: 0 5px 20px #00000020;
          svg:last-child {
               fill: #000000;
               stroke: white;
          }
     }
`;
