
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';

import StockItemSupplierUnitType from './StockItemSupplierUnitType';
import StockItemCentralKitchenUnitType from './StockItemCentralKitchenUnitType';

class StockItemSupplierUnitTypes extends Component
{
    render()
    {
        const unitTypes = this.props.data.unitTypes.map(unitType =>
        {
            if(this.isCentralKitchen())
            {
                return <StockItemCentralKitchenUnitType
                        data={this.props.data}
                        lookupData={this.props.lookupData}
                        unitTypes={unitType}
                        stockItem={this.props.stockItem}
                        isRowReadOnly={this.props.isRowReadOnly}
                        onRemove={(item) =>
                        {
                            const index = this.props.data.unitTypes.findIndex(x => x === item);
                            this.props.data.unitTypes.splice(index, 1);
                            this.setState({});
                        }}>
                    </StockItemCentralKitchenUnitType>;
            }

            return <StockItemSupplierUnitType
                data={unitType}
                stockItem={this.props.stockItem}
                isRowReadOnly={this.props.isRowReadOnly}
                onRemove={(item) =>
                {
                    const index = this.props.data.unitTypes.findIndex(x => x === item);
                    this.props.data.unitTypes.splice(index, 1);
                    this.setState({});
                }}>
            </StockItemSupplierUnitType>;
        });

        return <Card>
            <CardHeader>
                <table width="100%">
                    <tr>
                        <td align="left">Supplier Packaging Units</td>
                        <td align="right">
                            {
                                this.props.isRowReadOnly
                                    ? null
                                    : <Button color="primary" className="btn-primary" style={{ marginRight: '3px' }}
                                        onClick={() =>
                                        {
                                            this.props.data.unitTypes.push({});
                                            this.setState({});
                                        }}>
                                        <i className="fa fa-plus"></i>&nbsp;Add
                                    </Button>
                            }
                        </td>
                    </tr>
                </table>
            </CardHeader>
            <CardBody>
                <Col>
                    {unitTypes}
                </Col>
            </CardBody>
        </Card>
    }

    isCentralKitchen()
    {
        return this.props.lookupData.centralKitchensLookupData.some(kitchen => kitchen.id == this.props.data.supplierId);        
    }
}

export default StockItemSupplierUnitTypes