import React, { Component } from 'react';
import { connect } from 'react-redux'

import GridView from '../../../../../components/gridView/gridView'
import PriceBand from './PriceBand';

const arraySort = require('array-sort');

const GridViewOptions = require('../../../../../components/gridView/gridViewOptions');
const GridViewButton = require('../../../../../components/gridView/gridViewButton');

const securityManager = require('../../../../../utils/domain/security/securityManager');
const validator = require('../../../../../utils/validator/validator');
const typeUtil = require('../../../../../utils/type/typeUtil');
const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy');

const actionDispatcher = require('../../../../../utils/state/actionDispatcher');
const orgLookupAction = require('../../../../../utils/state/actions/orgData/lookup/lookupAction');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');


class PriceBands extends Component 
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadPriceBands = this.loadPriceBands.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Price Bands";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "rows";
        gridViewOptions.getRowData = this.loadPriceBands;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;
        gridViewOptions.getNewRow = () => { const newRow = { franchisorId: currentOrgNode.franchisorId }; return newRow; };
        gridViewOptions.buttons = [this.getDeletePriceBandOverrideActionButton()];
        gridViewOptions.getComponent = (isNew, row) => <PriceBand data={row} />;

        gridViewOptions.getComponentTitle =
            (isNewRow, row, customButton) =>
                isNewRow ? "Add New Price Band" : `Edit Price Band: ${row.code}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new price band.";

            return "Please wait while updating price band.";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New price band is added successfully";

            return "Price Band is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new price band:";

            return "Following error occurred while updating price band:";
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    loadPriceBands()
    {
        let priceBands = typeUtil.deepCloneObject(this.props.priceBands);
        if (priceBands == null) priceBands = [];
        return arraySort(priceBands, 'code');
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.code))
            return "Price Band code cannot be left empty";

        if (!validator.isPresent(row.name))
            return "Price Band name cannot be left empty";

        return null;
    }

    getDeletePriceBandOverrideActionButton = () =>
    {
        const button = new GridViewButton();

        button.title = "Delete Price Band";
        button.color = "danger";

        button.onRowSelect = true;
        button.actionType = "action";

        button.actionConfirmationTitle = "Delete Price Band";
        button.onActionConfirmationMessage = (selectedPriceBand) => 
        {
            return `Do you wish to delete selected Price Band: "${selectedPriceBand.name}" ?`
        }

        button.onActionWaitMessage = (selectedPriceBand, customButton) => "Please wait while deleting Price Band ...";
        button.onActionSuccessMessage = (selectedPriceBand, customButton) => "Selected Price Band deleted successfully";
        button.onActionFailMessage = (selectedPriceBand, customButton, error) => error.toString();

        button.onAction = async (selectedPriceBand, remoteData, customButton) =>
        {
            const priceBandId = selectedPriceBand.id;

            try
            {
                await rmsApiProxy.deleted(`${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/priceBands/${priceBandId}`);

                let priceBands = this.loadPriceBands();
                if (priceBands == null) priceBands = [];
    
                const existingIndex = priceBands.findIndex(priceBand => priceBand.id == priceBandId);
                priceBands.splice(existingIndex, 1);
    
                const orgState = orgStateSelectors.selectCurrentOrgState();
                actionDispatcher.dispatch(orgLookupAction.createUpdateAction(orgState, "priceBands", priceBands));

            } catch (error)
            {
                throw new Error(`Failed to delete Price Band with error: ${error}`);
            }
        }

        return button;
    }

    async save(isNew, row)
    {
        try
        {
            const updatedPriceBand = await rmsApiProxy.post(`${rmsApiProxy.getFranchisorOrgContextUrl()}/lookups/priceBands`, row);

            let priceBands = this.loadPriceBands();
            if (priceBands == null) priceBands = [];

            const existingIndex = priceBands.findIndex(priceBand => priceBand.id == updatedPriceBand.id);

            if (existingIndex > -1)
            {
                priceBands.splice(existingIndex, 1, updatedPriceBand);
            }
            else
            {
                priceBands.push(updatedPriceBand);
            }

            const orgState = orgStateSelectors.selectCurrentOrgState();
            actionDispatcher.dispatch(orgLookupAction.createUpdateAction(orgState, "priceBands", priceBands));

            return updatedPriceBand;

        } catch (error)
        {
            throw error
        }
    }

    constructGridColumnHeaders()
    {
        let header;
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "code";
        header.headerName = "Code";
        header.width = columnWidthConstants.code;
        header.headerTooltip = "The unique code of the price band.";
        header.pinned = true;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

const mapStateToProps = state => 
{
    return {
        priceBands: orgStateSelectors.selectCurrentOrgLookupData().priceBands,
    }
}

export default connect(mapStateToProps)(PriceBands)
