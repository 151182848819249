
import React, { Component } from 'react';
import CdnImageUploader from '../../../../../components/cdnImage/cdnImageUploader'
import CdnImage from '../../../../../components/cdnImage/cdnImage.js';

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');


const stringUtil = require('../../../../../utils/string/stringUtil');
const configurationManager = require('../../../../../utils/config/configurationManager');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');

class GenericImageElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {
        var separatorCharacter = this.props.element.character;
        var lineText = "";
        for (var index = 0; index < 100; index++)
            lineText = `${lineText}${separatorCharacter}`;

        var imageComponent = <CdnImage  cloudName={configurationManager.getConfig().cdnAppCloudName} publicId="app/back.office/resellers/flipdish/logo.main" height="100" radius="0"/>;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.url))
        {
            imageComponent = <CdnImage  cloudName={configurationManager.getConfig().cdnUsersCloudName} publicId={this.props.element.url} radius="0"/>;
        }
       
        return <div style={{width:'100%', padding:'0px', margin:'0px', textAlign:'center'}}
                    onDoubleClick={()=>
                        {
                            var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                            viewUtil.openModalForm(this.props.header, ()=>
                            {
                                return <DialogueBox element={clonedElement}/>
                            }, 
                            ()=>
                            {
                                this.props.element.url = clonedElement.url;
                                this.setState({});
                            }, 
                            ()=>
                            {
                            })
                        }}>
                        {imageComponent}
                </div>;
    }
}

class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;
    }

    render()
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const logoImageFolder = `franchisors/${currentOrgNode.franchisorId}/printing/templates`;

        return <table style={{width:'100%'}}>
                    <tr>
                        <td>
                            <CdnImageUploader
                                cloudName={configurationManager.getConfig().cdnUsersCloudName}
                                imageFolder={logoImageFolder}
                                imageUrl={this.props.element.url}
                                onImageChange={url =>
                                {
                                    this.props.element.url = url;
                                    this.setState({});
                                }}>

                                <h4 className="display-5">No Image Found</h4>
                                <p className="lead">A logo is much more than just an image; it is a point of recognition for clients and
                                    an important foundation for the branding of your property.</p>
                                <hr className="my-2" />
                                <p>A well-designed logo is an easy way to convey to potential customers that your business is professional,
                                    trustworthy, and provides quality goods or services.</p>

                            </CdnImageUploader>
                        </td>
                    </tr>
               </table>;
    }
}

export default GenericImageElement;