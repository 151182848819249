import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import SalesDataGrid from './SaleDataGrid';

const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');
const nomenclatureUtils = require('../../../../../utils/domain/nomenclatureUtils');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

const OrderDetails = (props) =>
{

    return (<CollapsibleCard caption="Order Details" isDefaultOpen={props.isDefaultOpen}>
            <SalesDataGrid sale={props.sale} getColumnDefs={getColumnDefs} getData={() => getData(props.sale)} />
    </CollapsibleCard>)
}

function appendSaleItemData(saleItem, saleItemData, depth)
{
    saleItem.depth = depth;
    saleItemData.push(saleItem);

    saleItem.saleItems.forEach(subItem =>
    {
        appendSaleItemData(subItem, saleItemData, depth + 1);
    })
}

function getData(sale)
{

    let saleItemData = []
    sale.saleItems.forEach(saleItem =>
    {
        appendSaleItemData(saleItem, saleItemData, 0);
    });

    return saleItemData;
}

function getColumnDefs()
{
    let header;
    const headers = [];

    const PaddingCellRenderer = (props) => {
        const data = props.data;
        if(data)
        {
            return <span style={{paddingLeft: `${data.depth * 20}px`}}>{props.value}</span>
        }
        else
        {
            return <span>{props.value}</span>
        }
    }

    header = {};
    
    header.valueGetter = ({ data }) => {
        let indentation = "";
        for(let i = 0; i < data.depth; i++)
        {
            indentation += "   ";
        }
        return indentation + data.caption;
    };
    header.headerName = "Name"
    header.field = "caption"
    header.cellRenderer = PaddingCellRenderer
    headers.push(header);

    header = {};
    header.field = "category";
    header.headerName = "Category";
    headers.push(header);

    header = {};
    header.field = "quantity";
    header.headerName = "Quantity";
    header.type = dataTypeConstants.number;
    headers.push(header);

    header = {};
    header.field = "isComplimented";
    header.headerName = "Is Complimented";
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "isRefunded";
    header.headerName = "Is Refunded";
    header.type = dataTypeConstants.boolean;
    headers.push(header);

    header = {};
    header.field = "retailPrice";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.retailOrderPrice);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "discount";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.totalDiscounts);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "refund";
    header.headerName = "Refund";
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netRetailPrice";
    header.headerName = nomenclatureUtils.getValue(nomenclatureUtils.attributes.netOrderPrice);
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);


    header = {};
    header.field = "retailTax";
    header.headerName = `Retail ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "discountTax";
    header.headerName = `Discount ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "refundTax";
    header.headerName = `Refund ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "netRetailTax";
    header.headerName = `Net Retail ${localizationUtils.getVatCaption()}`;
    header.minWidth = "200";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {}
    header.field = "taxPercentage";
    header.headerName = `${localizationUtils.getVatCaption()} Percentage`
    header.minWidth = "200";
    header.type = dataTypeConstants.percentage;
    headers.push(header);

    return headers;

}

export default OrderDetails;