const stringUtil = require('../../../utils/string/stringUtil');
const currentOrgNodeSelectors = require('../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const securityManager = require('../../../utils/domain/security/securityManager');

export function isAccessAllowed(option)
{
    if (option.actionType === "menu")
        return true;

    const readRoles = option.readRoles ? option.readRoles : [];
    const writeRoles = option.writeRoles ? option.writeRoles : [];

    const roles = [...readRoles, ...writeRoles];
    
    if (roles.length <= 0)
        return true;
    
    for (var index = 0; index < roles.length; index++)
    {
        if (securityManager.isRoleAllowed(roles[index]))
            return true;
    }

    return false;
}


export function isModuleSubscriptionAllowed(propertyLicenseSubscriptions, option)
{
    if (stringUtil.isStringNullOrEmpty(option.subscription))
        return true;

    if (currentOrgNodeSelectors.isCurrentSystemSelected())
        return true;

    var propertyIds = [];
    if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
    {
        var properties = currentOrgNodeSelectors.selectAllProperties();
        properties.forEach (property =>
        {
            propertyIds.push(property.id);
        })
    }
    else
    {
        var currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        propertyIds.push(currentOrgNode.propertyId);
    }

    var doesAnyPropertyHaveSubscription = false;
    
    propertyIds.forEach(propertyId =>
    {
        if (isModuleSubscriptionAllowedForProperty(propertyLicenseSubscriptions, option, propertyId))
            doesAnyPropertyHaveSubscription = true;  
    })

    return doesAnyPropertyHaveSubscription;
}

function isModuleSubscriptionAllowedForProperty(propertyLicenseSubscriptions, option, propertyId)
{
    if (!propertyLicenseSubscriptions.has(propertyId))
        return false;

    var propertySubscriptions = propertyLicenseSubscriptions.get(propertyId);
    return propertySubscriptions.find(ps => stringUtil.areStringSame(ps.moduleName, option.subscription)) != null;
}

export function isComponentReadOnlyForUser(componentReadRoles, componentWriteRoles)
{
    const userRoles = securityManager.getUserRoleCodes();
    
    if (componentWriteRoles && componentWriteRoles.length > 0)
    {
        if (componentWriteRoles.some(writeRole => userRoles.includes(writeRole)))
            return false;
    }
    
    if (componentReadRoles && componentReadRoles.length > 0)
    {
        if (componentReadRoles.some(readRole => userRoles.includes(readRole)))
            return true;
    }

    return false;
}