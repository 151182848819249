const rmsApiProxy = require('../../../../../utils/api/rmsApiProxy.js');
const stringUtil = require('../../../../../utils/string/stringUtil');


export async function save (data) {
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/databaseConfiguration`;
    return await rmsApiProxy.post(resourceUrl, data);
}

export async function load () {
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/configurations/databaseConfiguration`;
    return await rmsApiProxy.get(resourceUrl);
}

export function validate (data)
{

    if (stringUtil.isStringNullOrEmpty(data.databaseName))
        return "databaseName cannot be left empty";

    if (stringUtil.isStringNullOrEmpty(data.databaseServer))
        return "databaseServer cannot be left empty";

    return null;
}