import React from 'react';
import InputComponent from '../../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../../components/form/selectComponent';
import BooleanSelectComponent from '../../../../../../components/form/booleanSelectComponent';

export default function PropertyFloatConfigurationComponent(props)
{
    return (

        <table className="component-table">
            <tbody>

                <tr>
                    <td>
                        <SelectComponent optionValues={ props.franchisorCategories }
                            optionFieldName="id"
                            optionDisplayFieldName="name"
                            fieldName="categoryId"
                            caption="Category"
                            hintText="Optional"
                            formManager={ props.formManager }
                            clearable={ true }
                        />
                    </td>

                    <td>
                        <SelectComponent optionValues={ props.propertyTransactionAccounts }
                            optionFieldName="id"
                            optionDisplayFieldName="code"
                            fieldName="accountId"
                            caption="Account"
                            hintText="Optional"
                            formManager={ props.formManager }
                            clearable={ true }
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        <InputComponent
                            inputType="number"
                            fieldName="maxFloatSessionDurationInHours"
                            caption="Max Float Session Duration (in Hours)"
                            placeholder="Enter duration in hours"
                            hintText="Optional"
                            formManager={ props.formManager } />
                    </td>
                </tr>

                <tr>
                    <td colSpan='2'>
                        <div className='pb-2 d-flex flex-column border'>
                            <div className='w-auto ignis-legend pt-1 pb-2' style={{ fontSize: "0.95rem", marginLeft: '4px' }}>Enable Cash Count&nbsp;&nbsp;</div>
                            <table width="100%">
                                <tr>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            caption='On Opening Session'
                                            fieldName="isCashCountEnabledOnOpeningSession"
                                            comboReadOnly={ false }
                                            clearable={ false }
                                            formManager={ props.formManager } />
                                    </td>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            caption='On Closing Session'
                                            fieldName="isCashCountEnabledOnClosingSession"
                                            comboReadOnly={ false }
                                            clearable={ false }
                                            formManager={ props.formManager } />
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <BooleanSelectComponent
                                            caption='Display Closing Balance Discrepancy'
                                            fieldName="displayClosingDiscrepancyWarning"
                                            comboReadOnly={ false }
                                            clearable={ false }
                                            formManager={ props.formManager } />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
    )

}
