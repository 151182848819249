
import React, { Component } from 'react';
import { FormGroup, InputGroup, InputGroupText, Input, FormText, Label } from 'reactstrap';

export default class InputStyleLabel extends Component
{
    render()
    {
        var hintTextComponent = null;
        var captionTextComponent = null;
        var addonIcon = null;

        var underlyingType = "text";

        switch (this.props.inputType)
        {
            case "name":
                addonIcon = "fa fa-mortar-board";
                break;

            case "code":
                addonIcon = "fa fa-key";
                break;

            case "type":
                addonIcon = "fa fa-file-code-o";
                break;

            case "phone":
                addonIcon = "fa fa-phone";
                break;

            case "address":
                addonIcon = "fa fa-map-o";
                break;

            case "email":
                addonIcon = "fa fa-envelope-o";
                break;

            case "web":
                addonIcon = "cui-globe icons";
                break;

            case "identity":
                addonIcon = "fa fa-bookmark-o";
                break;

            case "person":
                addonIcon = "fa fa-bookmark-o";
                break;

            case "money":
                addonIcon = "fa fa-money";
                break;

            case "time":
                addonIcon = "fa fa-clock-o";
                break;

            case "percentage":
                addonIcon = "fa fa-percent";
                break;

            case "password":
                addonIcon = "icon-lock";
                underlyingType = "password";
                break;

            case "reviews":
                addonIcon = "fa fa-star-half-empty";
                break;

            case "ratings":
                addonIcon = "fa fa-line-chart";
                break;

            case "mileage":
                addonIcon = "fa fa-road";
                break;

            case "question":
                addonIcon = "fa fa-question-circle-o";
                break;
                
            default:
                break;
        }

        if (this.props.hintText)
        {
            hintTextComponent = <FormText color="muted">{this.props.hintText}</FormText>
        }

        captionTextComponent = <Label>{this.props.caption}</Label>

        if (this.props.multiLine)
            underlyingType = "textarea";

        if (addonIcon == null)
        {
            return (
                <FormGroup style={this.style}>

                    {captionTextComponent}

                    <Input
                        className='bg-light'
                        readOnly={true}
                        type={underlyingType}
                        id={this.props.caption}
                        value={this.props.value}
                        {...this.props} />

                    {hintTextComponent}

                </FormGroup>
            );
        }
        else
        {
            return (
                <FormGroup  {...this.props} style={this.style}>

                    {captionTextComponent}

                    <InputGroup>

                        <InputGroupText><i className={addonIcon}></i></InputGroupText>
                        
                        <Input
                            className='bg-light'
                            readOnly={true}
                            type={underlyingType}
                            id={this.props.caption}
                            value={this.props.value}
                            {...this.props} />

                    </InputGroup>

                    {hintTextComponent}

                </FormGroup>
            );
        }
    }
}