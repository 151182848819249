
import React, { Component } from 'react';

import InputComponent from '../../../../../components/form/inputComponent';
import SelectComponent from '../../../../../components/form/selectComponent';

const FormManager = require('../../../../../utils/view/formManager');
const localizationUtils = require('../../../../../utils/domain/localizationUtils');

class Expense extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            formManager: new FormManager(),
            filteredSubCategories: [],
            filteredTransactionAccounts: [],
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;

        const category = this.props.categories.find(x => x.id === this.state.formManager.viewModel.categoryId)
        this.state.filteredSubCategories = category ? category.subCategories : [];
        this.state.filteredTransactionAccounts = this.props.transactionAccounts.filter(x => x.propertyId === this.state.formManager.viewModel.propertyId);

        this.state.formManager.onFieldChanged = (event =>
        {
            if (event != null && event.target != null)
            {
                if (event.target.id === "categoryId")
                {
                    const category = this.props.categories.find(x => x.id === this.state.formManager.viewModel.categoryId)
                    this.state.filteredSubCategories = category ? category.subCategories : [];
                    this.state.formManager.viewModel.subCategoryId = null;
                    this.setState({})
                }
            }
        })
    }

    render() 
    {
        const vatCaption = localizationUtils.getVatCaption();

        return (
            <table className="component-table">

                <tbody>

                    <tr>
                        <td>
                            <SelectComponent
                                optionValues={this.props.suppliers}
                                optionFieldName="id"
                                optionDisplayFieldName="name"
                                fieldName="supplierId"
                                caption="Supplier"
                                hintText="(Required)"
                                formManager={this.state.formManager}
                                clearable={false} />
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                optionValues={this.props.categories}
                                optionFieldName="id"
                                optionDisplayFieldName="name"
                                fieldName="categoryId"
                                caption="Category"
                                hintText="(Required)"
                                formManager={this.state.formManager}
                                clearable={false} />
                        </td>
                        <td>
                            <SelectComponent
                                optionValues={this.state.filteredSubCategories}
                                optionFieldName="id"
                                optionDisplayFieldName="name"
                                fieldName="subCategoryId"
                                hintText="(Required)" hintFieldName="notes"
                                caption="Sub Category"
                                formManager={this.state.formManager}
                                clearable={false} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <SelectComponent
                                optionValues={this.state.filteredTransactionAccounts}
                                optionFieldName="id"
                                optionDisplayFieldName="code"
                                caption="Account"
                                hintText="(Required)" hintFieldName="notes"
                                fieldName="accountId"
                                formManager={this.state.formManager}
                                clearable={false} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="date"
                                fieldName="expenseDate"
                                placeholder="Expense date"
                                caption="Expense Date"
                                hintText="Enter the expense date (Required)"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="date"
                                fieldName="fromDate"
                                placeholder="From date"
                                caption="From Date"
                                hintText="Enter the start date for applicable period of the expense (Required)"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="date"
                                fieldName="toDate"
                                placeholder="To date"
                                caption="To Date"
                                hintText="Enter the end date for applicable period of the expense (Required)"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="number"
                                fieldName="amount"
                                placeholder="Enter the amount"
                                caption="Amount"
                                hintText="Required"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="number"
                                fieldName="vat"
                                placeholder={`Enter the ${vatCaption} amount`}
                                caption={`${vatCaption}`}
                                hintText="Required"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <InputComponent
                                inputType="text"
                                multiLine={true} rows={4}
                                caption="Notes"
                                fieldName="notes"
                                placeholder="Enter any notes."
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                </tbody>

            </table>
        )
    }
}

export default Expense;