import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import * as rmsApiProxy from '../../utils/api/rmsApiProxy';
import * as viewUtil from '../../utils/view/viewUtil';

export default class PictureAttachment extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            isOpen: false,
            imageData: null
        }
    }

    onToggle = () => 
    {

        this.setState({ isOpen: false, imageData: null });
    }

    onClick = async (e) =>
    {



        try
        {
            viewUtil.showSystemModalSpinner("Please wait while loading image data ...");

            const resourceUrl = `${this.imageUrl}/fileRawData`;
            const imageData = await rmsApiProxy.get(resourceUrl);

            this.setState({ imageData: imageData, isOpen: true });

        } catch (error)
        {
            viewUtil.showCriticalAlert(`Failed to load image data from server, error: ${error}`);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    onDone = () =>
    {
        this.setState({ isOpen: false, imageData: null });
    }

    render()
    {
        //
        // imageData (originally stringified data) is already JSON.parsed (de-stringified) at api fetch
        //

        const pictureAttachment = this.props.pictureAttachment;
        const imageData = this.state.imageData;

        return (
            <>
                <div className='text-primary' style={{ cursor: "pointer" }} onClick={this.onClick}>
                    <i className="fa fa-picture-o" />
                </div>

                <Modal isOpen={this.state.isOpen} centered={true} toggle={this.onToggle} backdrop="static">

                    <ModalHeader>
                        <div className='text-muted'>{this.props.title}</div>
                    </ModalHeader>

                    <ModalBody>
                        <div className='p-2 rounded d-flex justify-content-center'>
                            <img width="100%" src={imageData} alt="attachment" />
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className='w-100 d-flex justify-content-between'>
                            <div className='text-muted'>Image: {pictureAttachment.originalFileName}</div>
                            <Button
                                className='px-4' color="dark"
                                onClick={this.onDone}>
                                Done
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    get imageUrl()
    {
        const pictureAttachment = this.props.pictureAttachment;

        const imagePath =
            `${rmsApiProxy.getCurrentOrgNodeContextUrl()}/fileSystem/${pictureAttachment.objectType}` +
            `/${pictureAttachment.objectId}/${pictureAttachment.id}`;

        return imagePath;
    }
}