import React from "react";
import { useNetworkDiagramContext } from "../../store";
import StationConfigurationPopup from "./StationConfigurationPopup/StationConfigurationPopup";
import DeviceConfigurationPopup from "./DeviceConfigurationPopup/DeviceConfigurationPopup";

const Popups = () => {
     const { state } = useNetworkDiagramContext();
     const { stationFormPopup,deviceFormPopup } = state.popups;
     return (
          <>
               <StationConfigurationPopup data={stationFormPopup} />
               <DeviceConfigurationPopup data={deviceFormPopup} />
          </>
     );
};

export default Popups;
