import React, { Component } from 'react';
import { Badge, ListGroup, ListGroupItem, TabPane, Button, ModalFooter, ModalHeader } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import LogViewer from './logViewer';

const viewUtil = require('../../../../../../utils/view/viewUtil');

class PdfContents extends Component {
    constructor(props) {
        super(props);

        let errors = [];
        let warnings = [];
        if (this.props.logs)
        {
            errors = this.props.logs.filter(log => log.severity === "error");
            warnings = this.props.logs.filter(log => log.severity === "warning");
        }

        this.sections = [];
        this.sections.push({title:"Errors", color:"danger", data: errors, count: errors.length});
        if(warnings.length > 0) {
            this.sections.push({title:"Warning", color:"warning", data: warnings, count: warnings.length});
        }
    }

    render() {

        return (
            <div className="d-flex flex-column p-3">
                <div>
                    <div className="py-3 text-center text-muted">
                    <h1>{this.props.title}</h1>
                    </div>
                    <hr className='py-2'></hr>
                </div>
                
                {
                    this.sections.map(section => {
                        return (
                            <div className="card my-3">
                            <div className="card-header text-center text-uppercase">
                                {section.title}
                                <Badge color={section.color} style={{ marginLeft: '10px' }}>
                                    {section.count}
                                </Badge>
                            </div>
                            <div className="card-body">
                                <LogViewer isDefaultOpen={true} logs={section.data} color={section.color} />
                            </div>
                        </div>
        
                        )
                    })
                }
            </div>)
    }
}

export default PdfContents;