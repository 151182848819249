const arraySort = require('array-sort');
const validator = require('../../../../../utils/validator/validator');
const securityManager = require('../../../../../utils/domain/security/securityManager');

module.exports.isEditingAllowed = function()
{
    if (securityManager.isSupportUser()) 
    {
        return securityManager.isSupportElevatedUser();
    }

    return true;
}

module.exports.enrichViewModel = function (payrollRun, staff)
{
    if (!Array.isArray(payrollRun.staff))
    {
        payrollRun.staff = [];
    }

    const staffList = staff;
    payrollRun.staff.forEach(payrollStaff =>
    {
        const staff = staffList.find(s => s.id === payrollStaff.staffId);
        payrollStaff.name = staff.firstName + " " + staff.lastName;
        payrollStaff.pictUrl = staff.pictUrl;
    });

    payrollRun.staff = arraySort(payrollRun.staff, 'name'); 
}


module.exports.validate = function (payrollRun)
{
    //Other fields are calculated
    if (!validator.isGreaterThanOrEqualToZero(payrollRun.employerNIAllowance))
        return "Employer NI Allowance is not valid";

    return null
}

module.exports.validateStaff = function (staff)
{
    if (!validator.isGreaterThanOrEqualToZero(staff.employeeNIContribution))
        return "Employee NI Contribution is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.employerNIContribution))
        return "Employer NI Contribution is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.employerPensionContribution))
        return "Employer Pension Contribution is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.employeePensionContribution))
        return "Employee Pension Contribution is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.paye))
        return "PAYE is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.bonus))
        return "Bonus is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.deduction))
        return "Deduction is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.advancePayment))
        return "Advance payment is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.holidayHours))
        return "Holidays hours is not valid";

    if (!validator.isGreaterThanOrEqualToZero(staff.holidayHourlyWage))
        return "Holiday hourly wage is not valid";

    return null;
}