
import React, { Component } from 'react';
import { Card, CardHeader,  CardBody, CardFooter, Button, Modal} from 'reactstrap';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from 'react-select';

const stateManager = require('../../utils/state/stateManager');
const rmsApiProxy = require('../../utils/api/rmsApiProxy');
const stringUtil = require('../../utils/string/stringUtil');
const securityManager = require('../../utils/domain/security/securityManager');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const displayAccountSwitchAction = require('../../utils/state/actions/views/container/displayAccountSwitchAction');
const actionTypes = require('../../utils/state/actionTypes');

class AccountSwitch extends Component
{
    constructor(props) 
    {
        super(props);

        this.onContinue = this.onContinue.bind(this);
        this.clearSelectedFranchisor = this.clearSelectedFranchisor.bind(this);

        this.state = {};
        this.state.selectedFranchisor = null;

        this.state.loadedFranchisors = [];
        this.state.lastSearchedQuery = "";

        this.state.currentQuery = "";
        this.state.componentKey = 1;
    }

    render()
    {
        var searchComponent = this.renderSearchComponent();
        const continueDisabled = this.state.selectedFranchisor == null;

        var cancelDisabled = true;
        if (securityManager.isSystemUser())
            cancelDisabled = false;

        var removeAccountButton = null;

        if (securityManager.isSystemUser() && stateManager.getStore().getState().currentFranchisorId != null)
        {
            removeAccountButton = <Button color="danger" className="btn-danger" onClick={this.removeCurrentAccount}>
                                    Switch To System
                                 </Button>
        }

        return <Modal isOpen={true} centered animation={false} fade={false}  size="lg" style={{maxWidth: '1600px', width: '90%'}}>
                    <Card>
                        <CardHeader>
                            <h3 style={{marginTop:'10px'}}>Switch Account</h3>
                        </CardHeader>
                        <CardBody style={{paddingTop:'50px', paddingBottom: '50px'}}>
                            {searchComponent}
                        </CardBody>
                        
                        <CardFooter style={{textAlign:'end'}}>
                            
                            <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                        {removeAccountButton}
                                    </td>

                                    <td style={{width:'99%', textAlign:'end'}}>
                                        <Button color="secondary" className="btn-secondary" style={{marginRight:'10px'}} cancelDisabled={cancelDisabled} onClick={this.onCancel}>
                                                Cancel
                                        </Button>

                                        <Button color="primary" className="btn-primary" disabled={continueDisabled} onClick={this.onContinue}>
                                            Continue
                                        </Button>

                                        
                                    </td>
                                </tr>
                            </table>
                            
                        </CardFooter>
                    </Card>
                </Modal>
    }

    clearSelectedFranchisor() {
        this.setState({selectedFranchisor: null});
    }

    renderSearchComponent()
    {
        let allFranchisors = stateManager.getStore().getState().availableFranchisors;
        if (securityManager.isSystemUser())
        {
            allFranchisors = this.state.loadedFranchisors
        }

        const options = allFranchisors.map(franchisor =>
        {
            var option = {};
            option.label = `${franchisor.name} (${franchisor.code})`;
            option.name = franchisor.name;
            option.code = franchisor.code;
            option.data = franchisor;
            return option;
        })

        const handleOnSelect = (option) => 
        {
            this.state.selectedFranchisor = option?.data;
            this.setState({});
        };

        let handleOnSearch = (query, results) => {}
        if (securityManager.isSystemUser())
        {
            handleOnSearch = async (query, results) => 
            {
                this.state.currentQuery = query;
                query = stringUtil.trimString(query);

                if (stringUtil.isStringNullOrEmpty(query))
                {
                    return;
                } 

                if (!stringUtil.isStringNullOrEmpty(this.state.lastSearchedQuery) && query.startsWith(this.state.lastSearchedQuery))
                {
                    return;
                }

                if (this.state.loadedFranchisors.length > 0)
                {
                    this.state.loadedFranchisors = [];
                    this.setState({});
                }

                if (query.length < 4)   
                    return;
                
                this.state.lastSearchedQuery = query;
                let returnedResults  = await rmsApiProxy.get(`core/organizations/franchisors?searchCriteria=${query}`);
                if (this.state.lastSearchedQuery != query)
                    return;

                this.state.loadedFranchisors = returnedResults;
                this.state.componentKey = this.state.componentKey + 1;
                this.setState({});
                
            }
        }
        
        return <ReactSearchAutocomplete
                    key={this.state.componentKey}
                    inputSearchString={this.state.currentQuery}
                    autoFocus={true}
                    items={options}
                    resultStringKeyName="label"
                    placeholder = "Please enter account name"
                    maxResults={15}
                    onClear={this.clearSelectedFranchisor}
                    onSelect={handleOnSelect}
                    onSearch={handleOnSearch}
                    showNoResults={false}
                    fuseOptions={{ keys: ["code", "name"] }} // Search in the description text as well
                    styling={{ zIndex: 3 }} // To display it on top of the search box below
                />
    }
    
    onContinue()
    {
        const franchisor = this.state.selectedFranchisor;
        stateManager.getStore().getState().currentFranchisorId = franchisor.id;
        stateManager.getStore().getState().sideBarSelectedOption = "modules";
        stateManager.getStore().getState().topBarSelectedOption = "expanded";
        stateManager.getStore().getState().mainBarComponent = null;
        stateManager.getStore().getState().mainBarOptions = null;
        stateManager.getStore().getState().mainBarSearch = null;

        actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
        actionDispatcher.dispatch({ type: actionTypes.organizationInitialized, payload: false });
    }

    async removeCurrentAccount()
    {
        stateManager.getStore().getState().currentFranchisorId = null;
        stateManager.getStore().getState().sideBarSelectedOption = "modules";
        stateManager.getStore().getState().topBarSelectedOption = "expanded";
        stateManager.getStore().getState().mainBarComponent = null;
        stateManager.getStore().getState().mainBarOptions = null;
        stateManager.getStore().getState().mainBarSearch = null;

        actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
        actionDispatcher.dispatch({ type: actionTypes.organizationInitialized, payload: false });
    }

    onCancel()
    {
        actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
    }
}

export default AccountSwitch;