import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../../utils/constants/dataTypeConstants';
import * as inventoryCostSummaryViewUtil from '../../viewUtils/inventoryCostSummaryViewUtil'

export default function TabularReport(props)
{
    const masterRows = getMasterRows(props);

    const totalRow = {
        category: 'Total',
        totalSalePrice: masterRows.reduce((value, row) => value + row.totalSalePrice, 0),
        totalWastagePrice: masterRows.reduce((value, row) => value + row.totalWastagePrice, 0),
        totalDiscrepancyPrice: masterRows.reduce((value, row) => value + row.totalDiscrepancyPrice, 0),
        cogs: masterRows.reduce((value, row) => value + row.cogs, 0),
    }

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    return (
        <div className="ag-theme-alpine">
            <AgGridReact
                rowData={masterRows}
                pinnedBottomRowData={[totalRow]}
                columnDefs={getMasterGridColumns()}
                gridOptions={biUtils.getGridOptionsForMasterRows()}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
            />
        </div>
    );
}

function getMasterRows(props)
{
    return inventoryCostSummaryViewUtil.getInventorySummaryCategoryData(props);
}

function getMasterGridColumns()
{
    let header;
    const headers = [];

    header = {};
    header.field = "category";
    header.headerName = "Category";
    headers.push(header);

    header = {};
    header.field = "cogs";
    header.headerName = "Total Cost";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "totalSalePrice";
    header.headerName = "Sales Cost";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "totalWastagePrice";
    header.headerName = "Wastage Cost";
    header.type = dataTypeConstants.money;
    headers.push(header);

    header = {};
    header.field = "totalDiscrepancyPrice";
    header.headerName = "Discrepancy Cost";
    header.type = dataTypeConstants.money;
    headers.push(header);

    return headers;
}
