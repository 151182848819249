const guidUtil = require('../../guid/guidUtil');
const validator = require('../../validator/validator');


module.exports.integrationTypes =
{
    deliveryManagement: "DeliveryManagement",
    inventoryOrderManagement: "InventoryOrderManagement",
    cardPaymentManagement: "CardPaymentManagement",
    receiptPrinterManagement: "ReceiptPrinterManagement",
    labelPrinterManagement: "LabelPrinterManagement",
    kitchenStationPrinterManagement: "KitchenStationPrinterManagement",
    saleEndorsement: "SaleEndorsement",
}

module.exports.externalEntities =
{
    deliveryManagement: {
        shipDay: "ShipDay"
    },
    saleEndorsement: {
        pkFBR: "PK.FBR",    // Pakistan: Federal Board of Revenue
        pkSRB: "PK.SRB",    // Pakistan: Sindh Revenue Board
        keKRA: "KE.KRA"     // Kenya: Kenya Revenue Authority
    },
    inventoryOrderManagement: {
        email: "Email",
        centralKitchen: "CentralKitchen"
    },
    cardPaymentManagement: {
        worldPay: "WorldPay",
        handPoint: "HandPoint",
        paymentSense: "PaymentSense",
        stripeReader: "StripeReader",
        offline: "Offline"
    },
    receiptPrinterManagement: {
        genericUSB: "escpos.generic.usb",
        genericBluetooth: "escpos.generic.bluetooth",
        genericNetwork: "escpos.generic.network",
        sunmiCloudPrinter: "escpos.sunmi.cloud",
    },
    labelPrinterManagement: {
        genericUSB: "escpos.generic.usb",
        genericBluetooth: "escpos.generic.bluetooth",
        genericNetwork: "escpos.generic.network",

        brotherQL700Usb: "label.BrotherQL700.usb",
        brotherQL800Usb: "label.BrotherQL800.usb",
        brotherQL820Bluetooth: "label.BrotherQL820.bluetooth",
        brotherQL820Network: "label.BrotherQL820.network",

        zebra410Usb: "label.Zebra410.usb",
        zebra410Bluetooth: "label.Zebra410.bluetooth",
        zebra410Network: "label.Zebra410.network",

        zebra420Usb: "label.Zebra420.usb",
        zebra420Bluetooth: "label.Zebra420.bluetooth",
        zebra420Network: "label.Zebra420.network",

        sunmiV2Pro: "label.sunmi.v2.pro",
    },
    kitchenStationPrinterManagement: {
        receiptPrinter: "ReceiptPrinter",
        labelPrinter: "LabelPrinter",
    },
}

module.exports.systemTypes =
{
    saleEndorsement: {
        terminal: "Terminal"
    },
    deliveryManagement: {
        property: "Property",
        staff: "Staff",
        sale: "Sale"
    },
    inventoryOrderManagement: {
        supplier: "Supplier",
        purchaseOrder: "PurchaseOrder"
    },
    cardPaymentManagement: {
        terminal: "Terminal"
    },
    receiptPrinterManagement: {
        terminal: "Terminal"
    },
    labelPrinterManagement: {
        terminal: "Terminal"
    },
    kitchenStationPrinterManagement: {
        terminal: "Terminal"
    },
    all: {
        franchisor: "Franchisor",
        property: "Property",
        staff: "Staff",
        sale: "Sale",
        brand: "Brand",
        table: "Table",
        terminal: "Terminal",
        shell: "Shell"
    }
}

module.exports.domainEntities =
{
    franchisor: "franchisor",
    franchisee: "franchisee",
    property: "property",
}

module.exports.getDomainEntity = function (integration)
{
    // Assumes integration will always have atleast franchisorId

    if (validator.isPresent(integration.propertyId)) return this.domainEntities.property;
    if (validator.isPresent(integration.franchiseeId)) return this.domainEntities.franchisee;
    return this.domainEntities.franchisor;
}

module.exports.getNewIntegration = function (orgContext, integrationType, systemType, systemId)
{
    return {
        id: guidUtil.generateGuid(),
        franchisorId: orgContext.franchisorId,
        franchiseeId: orgContext.franchiseeId,
        propertyId: orgContext.propertyId,
        integrationType: integrationType,
        systemType: systemType,
        systemId: systemId,
        externalEntity: "",
        externalPrimaryId: "",
        externalSecondaryId: "",
        externalTertiaryId: "",
        isEnabled: false,
        notes: "",
        jsonArray: [],
        jsonData: {}
    }
}