module.exports.module = 
{
    FranchisorManagement: 'Franchisor.Management',
    PropertyManagement: 'Property.Management',
    MenuManagement: 'Menu.Management',
    StaffManagement: 'Staff.Management',
    SaleManagement: 'Sale.Management',
    TerminalInternetConnectivity: 'Terminal.Internet.Connectivity',
    TerminalCashDrawer: 'Terminal.Cash.Drawer',
}

module.exports.status = 
{
    Success: 'Success',
    Warning: 'Warning',
    Failed: 'Failed'
}

module.exports.action =
{
    franchisor: {
        configuration: 'franchisor.configuration',
        integration: 'franchisor.integration',
        charges: 'franchisor.charges',
        brands: 'franchisor.brands'
    },
    property: {
        configuration: 'property.configuration',
        deliveryManagement: 'property.delivery.management',
        address: 'property.address',
        staffPermission: 'property.staff.permission',
        charges: 'property.charges'
    },
    storefront: {
        configuration: 'storefront.configuration',
        deliveryCharges: 'storefront.delivery.charges',
        orderIngest: 'storefront.order.ingest',
        businessHourOverrides: 'storefront.business.hour.overrides',
        openingHours: 'storefront.opening.hours',
        payout: 'storefront.payout',
    },
    menu: {
        publish: 'menu.publish',
        update: 'menu.update',
    },
    snooze: 'snooze',
    sale: {
        receive: 'sale.receive',
        cancel: 'sale.cancel',
        refund: 'sale.refund',
        paymentReceipt: 'sale.payment.receipt',
        paymentRefund: 'sale.payment.refund'
    },
    delivery: {
        request: "delivery.request",
        cancel: "delivery.cancel",
        statusUpdate: "delivery.status.update",
    },
    connection: {
        connected: 'connected',
        resynchronize: 'resynchronize'
    },
    general: {
        create: 'create',
        modify: 'modify',
        delete: 'delete',
    },
}