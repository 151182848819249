import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import * as biUtils from '../../../../../utils/bi/biUtils';
import * as dataTypeConstants from '../../../../../utils/constants/dataTypeConstants';

import * as reportDataUtils from '../utils/reportDataUtils';
import * as reportViewUtils from '../../../../../views/reports/reportUtils/helperUtils/viewUtils';

export default function CogsSummaryData(props)
{
    // Master rows = COGs summary data

    const masterRows = getMasterRows(props);

    const totalsRow = getMasterTotalsRow(masterRows);

    const { pagination, paginationPageSize } = biUtils.getMasterGridPaginationSettings(masterRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForMasterRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getMasterColumnDefs(),
        masterDetail: true,
        isRowMaster: row => true,
        detailCellRendererParams: params => getDetailCellRendererParams(params, props),   // Renders COGs detail data
    }

    return (
        <div className="ag-theme-alpine" style={{ height: "80vh" }}>
            <AgGridReact
                domLayout='normal'
                rowData={masterRows}
                pinnedBottomRowData={[totalsRow]}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
            />
        </div>
    );
}

//#region Master Rows = COGs summary data

function onGridReady(params)
{
    params.api.eventService.addEventListener('filterChanged', (e) => 
    {
        if (e.api.rowModel?.rowsToDisplay)
        {
            const rows = e.api.rowModel.rowsToDisplay.map(node => node.data);
            const totalsRow = getMasterTotalsRow(rows);
            e.api.setPinnedBottomRowData([totalsRow]);
        }
    });
}

function getMasterRows(props)
{
    const { reportData } = props;
    return reportDataUtils.getSummaryCogsData(reportData);
}

function getMasterTotalsRow(rows) 
{
    rows = rows.filter(row => row != null);

    return {
        saleCost: rows.reduce((sum, row) => sum + row.saleCost, 0),
        wastageCost: rows.reduce((sum, row) => sum + row.wastageCost, 0),
        discrepancyCost: rows.reduce((sum, row) => sum + row.discrepancyCost, 0),
        cogs: rows.reduce((sum, row) => sum + row.cogs, 0),
    };
}

function getMasterColumnDefs()
{
    const columnDefs = [];

    columnDefs.push({ field: 'categoryName', headerName: 'Category', hide: true });

    columnDefs.push({ field: 'stockItemName', headerName: 'Stock Item', cellRenderer: 'agGroupCellRenderer' });

    columnDefs.push({ field: 'cogs', headerName: 'COGs', type: `${dataTypeConstants.money}` });
    columnDefs.push({ field: 'saleCost', type: `${dataTypeConstants.money}` });
    columnDefs.push({ field: 'wastageCost', type: `${dataTypeConstants.money}` });
    columnDefs.push({ field: 'discrepancyCost', type: `${dataTypeConstants.money}` });

    columnDefs.push({ field: 'saleQuantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'wastageQuantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'discrepancyQuantity', type: `${dataTypeConstants.number}` });

    columnDefs.push({ field: 'unitCode', headerName: 'Units' });

    return columnDefs;
}

//#endregion

//#region First Level Child Rows = COGs detail data

function getDetailCellRendererParams(params, props)
{
    const { data } = params;
    const { reportData } = props;

    const detailRows = reportDataUtils.getDetailCogsData(data.stockItemId, reportData);

    const { pagination, paginationPageSize } = biUtils.getDetailGridPaginationSettings(detailRows.length);

    const gridOptions = {
        ...reportViewUtils.getGridOptionsForDetailRows(),
        pagination: pagination,
        paginationPageSize: paginationPageSize,
        columnDefs: getDetailColumnDefs(),
    };

    return {
        detailGridOptions: gridOptions,
        getDetailRowData: params => params.successCallback(detailRows)
    };
}

function getDetailColumnDefs()
{
    const columnDefs = [];

    columnDefs.push({ field: 'businessDate', headerName: 'Date', type: `${dataTypeConstants.date}` });

    columnDefs.push({ field: 'cogs', headerName: 'COGs', type: `${dataTypeConstants.money}` });
    columnDefs.push({ field: 'saleCost', type: `${dataTypeConstants.money}` });
    columnDefs.push({ field: 'wastageCost', type: `${dataTypeConstants.money}` });
    columnDefs.push({ field: 'discrepancyCost', type: `${dataTypeConstants.money}` });

    columnDefs.push({ field: 'saleQuantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'wastageQuantity', type: `${dataTypeConstants.number}` });
    columnDefs.push({ field: 'discrepancyQuantity', type: `${dataTypeConstants.number}` });

    return columnDefs;
}

//#endregion
