import React, { Component } from 'react';

import Spinner from '../../../../../components/spinner/spinner';
import EmptyData from '../../../../../components/empty/emptyData';
import ErrorMessage from '../../../../../components/error/errorMessage';

import ProjectionOverrideList from './ProjectionOverrideList';

class ProjectionOverrideContents extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        const { isLoading, overrideData, isError, errorMessage, onSetDirty } = this.props;

        if (isError)
        {
            return <ErrorMessage message={errorMessage} />;
        }

        if (isLoading)
        {
            return <Spinner text="Loading Data" />
        }

        if (!overrideData)
        {
            // Not loading, but no data either
            return <EmptyData action="Select or change criteria and hit Load button to load data" />;
        }

        return <ProjectionOverrideList overrideData={overrideData} onSetDirty={onSetDirty} />
    }
}

export default ProjectionOverrideContents;
