import React, { Component } from 'react';
import ErrorMessage from '../../../../../components/error/errorMessage';
import EditComponentHeader from '../../../../../components/componentHeader/editComponentHeader';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import OperatingConfigurationOverview from './components/OperatingConfigurationOverview';
import OperatingConfigurationDispatchOptions from './components/OperatingConfigurationDispatchOptions';
import OperatingConfigurationDelivery from './components/OperatingConfigurationDelivery';
import OperatingConfigurationPayments from './components/OperatingConfigurationPayments';
import OperatingConfigurationRewards from './components/OperatingConfigurationRewards';
import OperatingConfigurationPOSOptions from './components/OperatingConfigurationPOSOptions';
import OperatingConfigurationKIOSKOptions from './components/OperatingConfigurationKIOSKOptions';
import OperatingConfigurationSMSOptions from './components/OperatingConfigurationSMSOptions';
import * as auditLogManager from '../../../../../utils/audits/auditLogManager.js';

const storefrontOperatingConfigurationsHelper = require('./storefrontOperatingConfigurationsHelper');
const menuLoadFacade = require('../../../../../utils/api/menuLoadFacade.js');
const currentOrgNodeSelectors = require('../../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgStateSelectors = require('../../../../../utils/state/stateSelectors/orgStateSelectors');
const FormManager = require('../../../../../utils/view/formManager.js');
const domainConstants = require('../../../../../utils/domain/constants');
const viewUtil = require('../../../../../utils/view/viewUtil');
const logger = require('../../../../../utils/logger/logger.js');
const stringUtil = require('../../../../../utils/string/stringUtil');
const actionDispatcherUtil = require('../../../../../utils/state/actions/actionDispatcherUtil');
const auditConstants = require('../../../../../utils/constants/auditConstants.js');

class StorefrontOperatingConfigurations extends Component
{
    constructor(props)
    {
        super(props);        

        this.state =
        {            
            priceBands: orgStateSelectors.selectOrgLookupData(currentOrgNodeSelectors.selectCurrentOrgNode().franchisorId).priceBands,
            menuHeaders: [],
            formManager: new FormManager(),
            isLoading: false,
            storefront: this.props.params
        };
        
        this.state.storefrontOperatingConfigurations = {
            dineInRequiresOrderReference: 'never',
            takeAwayRequiresOrderReference: 'never',
        };
        this.state.formManager.viewModel = this.state.storefrontOperatingConfigurations;
        this.state.formManager.view = this;

        this.state.error = null;

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    async componentDidMount()
    {
        this.setState({isLoading: true});
        viewUtil.showSystemModalSpinner("Loading, please wait");

        try
        {
            let menuHeaders = await menuLoadFacade.loadMenuHeadersExceptCentralKitchen();
            let storefrontOperatingConfigurations = await storefrontOperatingConfigurationsHelper.loadStorefrontOperatingConfiguration(this.state.storefront);
            
            this.state.storefrontOperatingConfigurations = storefrontOperatingConfigurations || {};
            this.state.formManager.viewModel = this.state.storefrontOperatingConfigurations;

            this.state.menuHeaders = menuHeaders;

            this.setState({});
        }
        catch(error)
        {
            logger.error(error,  {}, error);
            this.setState({ error: error, isLoading: false });
        }
        finally
        {
            viewUtil.closeModalSpinner();
            this.setState({isLoading: false});
        }
    }

    async onSaveClicked()
    {
        const storefront = this.state.storefront;
        const validationError = storefrontOperatingConfigurationsHelper.validateOperatingConfigurations(storefront, this.state.storefrontOperatingConfigurations)
        
        if (!stringUtil.isStringNullOrEmpty(validationError))
        {
            viewUtil.showCriticalAlert(validationError);
            return;
        }
        
        viewUtil.showSystemModalSpinner("Saving Storefront Configurations");

        try
        {
            await storefrontOperatingConfigurationsHelper.save(storefront, this.state.storefrontOperatingConfigurations)
            viewUtil.showSuccessAlert(`${this.state.storefront.name} configurations saved`);

            await auditLogManager.addPropertyAuditLog(auditConstants.action.storefront.configuration, 
                                                     `${this.state.storefront.name} storefront operating configurations have been altered`, 
                                                     this.state.storefrontOperatingConfigurations);
            actionDispatcherUtil.showOptionsUI();
        }
        catch(error)
        {
            viewUtil.showCriticalAlert(error);
        }
        finally
        {
            viewUtil.closeModalSpinner();
        }
    }

    render()
    {
        if (this.state.isLoading)
        {
            return null;
        }

        if (this.state.error)
        {
            return <ErrorMessage message={this.state.error} />;
        }

        const tabHeaders = [];
        const tabPanels = [];

        tabHeaders.push(<Tab>Overview</Tab>);
        tabPanels.push(<TabPanel className='pt-1'>
            <OperatingConfigurationOverview data={ this.state.storefrontOperatingConfigurations } />
        </TabPanel>
        );


        tabHeaders.push(<Tab>Dispatch Options</Tab>);
        tabPanels.push(<TabPanel>
            <OperatingConfigurationDispatchOptions data={ this.state.storefrontOperatingConfigurations } />
        </TabPanel>
        );

        tabHeaders.push(<Tab>Delivery</Tab>);
        tabPanels.push(<TabPanel>
            <OperatingConfigurationDelivery data={ this.state.storefrontOperatingConfigurations } />
        </TabPanel>
        );
        
        tabHeaders.push(<Tab>Payments</Tab>);
        tabPanels.push(<TabPanel>
            <OperatingConfigurationPayments data={ this.state.storefrontOperatingConfigurations } />
        </TabPanel>
        );

        
        
        tabHeaders.push(<Tab>Rewards Menu</Tab>);
        tabPanels.push(<TabPanel>
            <OperatingConfigurationRewards
                data={ this.state.storefrontOperatingConfigurations }
                menuHeaders={ this.state.menuHeaders }
                priceBands={ this.state.priceBands } />
        </TabPanel>
        );

        if(this.state.storefront && this.state.storefront.type == domainConstants.storefrontTypes.POS)
        {
            tabHeaders.push(<Tab>POS Configurations</Tab>);
            tabPanels.push(<TabPanel>
                <OperatingConfigurationPOSOptions
                    data={ this.state.storefrontOperatingConfigurations }/>
            </TabPanel>
            );    
        }

        if(this.state.storefront && this.state.storefront.type == domainConstants.storefrontTypes.KIOSK)
        {
            tabHeaders.push(<Tab>KIOSK Configurations</Tab>);
            tabPanels.push(<TabPanel>
                <OperatingConfigurationKIOSKOptions
                    data={ this.state.storefrontOperatingConfigurations }/>
            </TabPanel>
            );    
        }

        const smsStorefronts = [
            domainConstants.storefrontTypes.POS, 
            domainConstants.storefrontTypes.KIOSK, 
            domainConstants.storefrontTypes.WebApp];

        if (this.state.storefront && smsStorefronts.includes(this.state.storefront.type))
        {
            tabHeaders.push(<Tab>SMS Configuration</Tab>);
            tabPanels.push(<TabPanel>
                <OperatingConfigurationSMSOptions
                    data={ this.state.storefrontOperatingConfigurations } />
            </TabPanel>);
        }

        return (            
            <Card>

                <CardHeader>
                    <EditComponentHeader
                        title="Storefront Operating Configurations"
                        isReadOnly={this.props.isReadOnly}
                        onSaveClicked={this.onSaveClicked}
                        onCancelClose={true} />
                </CardHeader>

                <CardBody>
                    <Tabs>
                        <TabList>{tabHeaders}</TabList>
                        {tabPanels}               
                    </Tabs>
                </CardBody>

            </Card>
        );
    }    
}

export default StorefrontOperatingConfigurations;