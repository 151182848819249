
const randomstring = require("randomstring");
const { v4: uuidv4 } = require('uuid');

module.exports.generateGuid = function()
{
    return uuidv4();
}

module.exports.generateUniqueCode = function()
{
    var options = {};
    options.length = 6;
    options.capitalization = "uppercase";
    options.charset = 'abcdefghijklmnpqrstuvwxz123456789';
    options.readable = true;
    return randomstring.generate(options);
}