import React, { Component } from 'react';

import GridView from '../../../../components/gridView/gridView';
import GridViewOptions from '../../../../components/gridView/gridViewOptions';
import Supplier from './Supplier';

const arraySort = require('array-sort');

const rmsApiProxy = require('../../../../utils/api/rmsApiProxy');
const apiLoadFacade = require('../../../../utils/api/apiLoadFacade');
const stringUtil = require('../../../../utils/string/stringUtil');
const validator = require('../../../../utils/validator/validator');
const guidUtil = require('../../../../utils/guid/guidUtil');
const currentOrgNodeSelectors = require('../../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../../utils/state/stateSelectors/orgSelectors');
const externalIntegrationUtils = require('../../../../utils/domain/externalIntegrations/externalIntegrationUtils');

const columnWidthConstants = require('../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../utils/constants/dataTypeConstants');

const domainConstants = require('../../../../utils/domain/constants');
const commonUtility = require('../../../../utils/domain/commonUtility');
const securityManager = require('../../../../utils/domain/security/securityManager');


class Suppliers extends Component
{
    constructor(props) 
    {
        super(props);

        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.loadSuppliersAsync = this.loadSuppliersAsync.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.save = this.save.bind(this);
    }

    get isCentralKitchenContext() 
    {
        return commonUtility.isCentralKitchenPropertySelected();
    }

    render() 
    {
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        const gridViewOptions = new GridViewOptions();

        gridViewOptions.title = "Suppliers";
        gridViewOptions.messageContext = "Supplier";
        gridViewOptions.getColumnDefinitions = this.constructGridColumnHeaders;

        gridViewOptions.gridDataMode = "async";
        gridViewOptions.getRowDataAsync = this.loadSuppliersAsync;

        gridViewOptions.getPrimaryKeyValue = (row) => row.id;

        gridViewOptions.getNewRow = () => 
        {
            // franchisorId/franchiseeId is mutually exclusive for supplier and is used to differentiate
            // between franchisor and franchisee created suppliers

            let franchisorId, franchiseeId, type;

            if (this.isCentralKitchenContext) 
            {
                franchisorId = currentOrgNode.franchisorId;
                franchiseeId = null;
                type = domainConstants.serviceContextTypes.centralKitchen;
            }
            else
            {
                franchiseeId = currentOrgNode.franchiseeId;
                franchisorId = franchiseeId ? null : currentOrgNode.franchisorId;
                type = domainConstants.serviceContextTypes.store;
            }

            const newRow = {
                id: guidUtil.generateGuid(),
                franchisorId: franchisorId,
                franchiseeId: franchiseeId,
                type: type,
                serviceProfiles: []
            };

            return newRow;
        };

        gridViewOptions.getComponent = (isNew, row) => <Supplier data={row} isRowReadOnly={this.isRowReadOnly(row)} />;
        gridViewOptions.getComponentTitle = (isNew, row) => isNew ? 'Supplier - New' : `Supplier - ${row.name}`;

        gridViewOptions.isReadOnly = this.props.isReadOnly;
        gridViewOptions.isRowReadOnly = this.isRowReadOnly;
        gridViewOptions.validate = this.validateRow;
        gridViewOptions.save = this.save;

        gridViewOptions.getWaitMessage = (isNew) =>
        {
            if (isNew) return "Please wait while adding new supplier";
            return "Please wait while updating supplier";
        }

        gridViewOptions.getSaveSuccessMessage = (isNew) =>
        {
            if (isNew) return "New supplier is added successfully";
            return "supplier is updated successfully.";
        }

        gridViewOptions.getSaveErrorMessage = (isNew) =>
        {
            if (isNew) return "Following error occurred while adding new supplier:";
            return "Following error occurred while updating supplier:";
        }

        gridViewOptions.getExternalIntegrationLogsParam = (isNew, row, customButton) => 
        {
            if (row.id == null) return null;

            const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

            return {
                ...currentOrgNode,
                systemType: externalIntegrationUtils.systemTypes.all.supplier,
                systemId: row.id,
            };
        }

        return (<GridView gridViewOptions={gridViewOptions} />);
    }

    isRowReadOnly = (row, customButton) =>
    {
        if (this.props.isReadOnly) return true;
        
        const currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();

        // Implies centralKitchen supplier
        if (row.type === domainConstants.serviceContextTypes.centralKitchen)
        {
            // Allow edit only when centralKitchen node is selected
            return !(currentOrgNode.franchisorId === row.franchisorId && this.isCentralKitchenContext);
        }

        // Implies store supplier
        if (row.franchisorId != null)
        {
            // Allows edit by franchisor node only
            if (!currentOrgNodeSelectors.isCurrentFranchisorSelected()) return true;
            return currentOrgNodeSelectors.selectCurrentFranchisor().id !== row.franchisorId;
        }

        // Allows edit by franchisee for franchisee created supplier at either of franchisee/property nodes
        if (row.franchiseeId != null) return currentOrgNode.franchiseeId !== row.franchiseeId;
    }

    async loadSuppliersAsync()
    {
        const type = this.isCentralKitchenContext
            ? domainConstants.serviceContextTypes.centralKitchen
            : domainConstants.serviceContextTypes.store

        try
        {
            let suppliers = await apiLoadFacade.loadSuppliers();
            if (suppliers == null) suppliers = [];
            suppliers = suppliers.filter(supplier => supplier.type === type);

            this.enrich(suppliers);

            return arraySort(suppliers, stringUtil.localeCompareProp("name"));
        }
        catch (error)
        {
            throw error;
        }
    }

    validateRow(isNew, row)
    {
        if (!validator.isPresent(row.name))
            return "Name cannot be left empty";

        if (!validator.isPresent(row.address))
            return "Address cannot be left empty";

        if (!validator.isPresent(row.phoneNumber))
            return "Phone number cannot be left empty";

        if (!validator.isPresent(row.emailAddress))
            return "Email address cannot be left empty";

        if (!validator.isOnlyOnePresent(row.franchisorId, row.franchiseeId))
            return "Either of franchisorId or franchiseeId must be present";

        if (!validator.isValidEnumeration(row.type, domainConstants.serviceContextTypes))
            return "Invalid supplier type";

        return null;
    }

    async save(isNew, row, suppliers)
    {
        const { franchisorId, franchiseeId } = currentOrgNodeSelectors.selectCurrentOrgContext();

        const baseUrl = rmsApiProxy.getOrgContextUrl(franchisorId, franchiseeId);
        const resourceUrl = `${baseUrl}/inventory/suppliers`;

        try
        {
            const updatedSupplier = await rmsApiProxy.post(resourceUrl, row);

            if (suppliers == null) suppliers = [];
            suppliers = suppliers.filter(supplier => supplier.id != updatedSupplier.id);
            suppliers.push(updatedSupplier);

            const response = {};
            response.remoteData = suppliers;
            response.addUpdatedRow = updatedSupplier;

            return response;

        } catch (error)
        {
            throw error;
        }
    }

    enrich(suppliers)
    {
        if (!Array.isArray(suppliers)) suppliers = [suppliers];

        const franchisors = orgSelectors.selectFranchisors();
        const franchisees = orgSelectors.selectFranchisees();

        suppliers.forEach(supplier =>
        {
            let createdBy = "N/A";

            if (validator.isPresent(supplier.franchisorId))
            {
                createdBy = franchisors.find(x => x.id == supplier.franchisorId).name;
            }
            else if (validator.isPresent(supplier.franchiseeId))
            {
                createdBy = franchisees.find(x => x.id == supplier.franchiseeId).name;
            }

            supplier.createdBy = createdBy;
        });
    }

    constructGridColumnHeaders()
    {
        let header
        const headers = [];

        header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "name";
        header.headerName = "Name";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "createdBy";
        header.headerName = "Created By";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "address";
        header.headerName = "Address";
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "phoneNumber";
        header.headerName = "Phone Number";
        header.type = dataTypeConstants.phoneNumber;
        header.width = columnWidthConstants.phoneNumber;
        headers.push(header);

        header = {};
        header.field = "emailAddress";
        header.headerName = "Email Address";
        header.width = columnWidthConstants.emailAddress;
        headers.push(header);

        header = {};
        header.field = "webSite";
        header.headerName = "Web Site";
        header.width = columnWidthConstants.webSite;
        headers.push(header);

        header = {};
        header.field = "isDisabled";
        header.headerName = "Is Disabled";
        header.type = dataTypeConstants.boolean;
        header.width = columnWidthConstants.boolean;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default Suppliers;
