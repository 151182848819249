import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js';
import './previewElement.css';

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');
const stringUtil = require('../../../../../utils/string/stringUtil');
const commonElementUtil = require('../../../../../utils/template/commonElementUtil');

class TableViewElement extends Component 
{
    render()
    {
        const { element, tableData } = this.props;

        let separatorLineComponent, newLineComponent;

        if (!stringUtil.isStringNullOrEmpty(element.separatorLineCharacter))
        {
            const separatorLine = element.separatorLineCharacter.repeat(100);
            separatorLineComponent = <div className='text-nowrap overflow-hidden'> {separatorLine}</div>
        }

        if (!stringUtil.isStringNullOrEmpty(element.newLine))
        {
            newLineComponent = <div style={{ minHeight: `${10 * element.newLine}px` }}></div>
        }

        return (
            <div className='mb-3' onDoubleClick={this.onDoubleClick}>
                <div className='d-flex flex-column'>
                    <div className='d-flex flex-column border'>
                        <div className='py-1 text-white bg-dark'>{element.title}</div>
                        {this.renderTableView(tableData)}
                    </div>
                    {separatorLineComponent}
                    {newLineComponent}
                </div>
            </div>
        );
    }

    renderTableView(tableData)
    {
        const { columnStyles, headerRow, dataRows, footerRow, footNotes } = tableData;

        return (
            <div className='d-flex flex-column'>

                {
                    headerRow &&
                    <div className='d-flex border border-bottom'>
                        {
                            headerRow.values.map((value, index) => <div key={index} className='fw-bold' style={columnStyles[index]}>{value}</div>)
                        }
                    </div>
                }

                {
                    dataRows &&
                    <>
                        {
                            dataRows.map((dataRow, index) =>
                                <>
                                    {
                                        dataRow.beforeBlankRow && <div className='py-2' />
                                    }
                                    <div key={index} className={`d-flex ${dataRow.isBold && 'fw-bold'}`}>
                                        {
                                            dataRow.values.map((value, index) =>
                                                <div key={index} style={columnStyles[index]}>
                                                    {
                                                        this.isMultilineValue(value)
                                                            ? this.renderMultilineValue(value)
                                                            : value
                                                    }
                                                </div>)
                                        }
                                    </div>
                                    {
                                        dataRow.afterBlankRow && <div className='py-2' />
                                    }
                                </>)
                        }
                    </>
                }

                {
                    footerRow &&
                    <div className='d-flex border-top'>
                        {
                            footerRow.values.map((value, index) => <div key={index} className='fw-bold' style={columnStyles[index]}>{value}</div>)
                        }
                    </div>
                }

                {
                    footNotes &&
                    <div className='d-flex flex-column align-items-end'>
                        {
                            footNotes.map(footNote => <span><small>{footNote}</small></span>)
                        }
                    </div>
                }

            </div>
        );
    }

    renderMultilineValue(value)
    {
        // For multiline we override alignment as text-start

        return (
            <div className='mb-2'>
                {
                    value.toString().split("\n").map(line => <div className='text-start'>{stringUtil.trimString(line)}</div>)
                }
            </div>
        );
    }

    isMultilineValue(value)
    {
        return value && value.toString().split("\n").length > 1;
    }

    onDoubleClick = () =>
    {
        const clonedElement = JSON.parse(JSON.stringify(this.props.element));

        viewUtil.openModalForm(
            clonedElement.title,
            () =>
            {
                return <DialogueBox element={clonedElement} />
            },
            () =>
            {
                Object.assign(this.props.element, clonedElement);
                this.setState({});
            },
            () =>
            {
                if (stringUtil.isStringNullOrEmpty(clonedElement.title))
                {
                    return "Data table Title is required";
                }

                return commonElementUtil.isValidElement(clonedElement);
            });
    }
}

class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;
    }

    render()
    {
        return (
            <table className="component-table">

                <tbody>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Title"
                                fieldName="title"
                                hintText="Title for the report"
                                placeholder="Enter title for the report"
                                formManager={this.state.formManager} />
                        </td>
                        <td />
                    </tr>

                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Line Separator Character"
                                fieldName="separatorLineCharacter"
                                placeholder="Line separator character"
                                hintText="Line separator after the report"
                                formManager={this.state.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Blank Lines"
                                hintText="Blank lines after report"
                                fieldName="newLine"
                                placeholder="Enter number of blank lines"
                                formManager={this.state.formManager} />
                        </td>
                    </tr>

                </tbody>

            </table>
        );
    }
}

export default TableViewElement;