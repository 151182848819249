import * as stringUtil from '../../../../../../utils/string/stringUtil';
import * as sortingUtil from '../../../../../../utils/sorting/sortingUtil';

export function getQuantityChartData(salesData, reverse = false, chartDataSize = 10)
{
    const sortedSalesData = [...salesData].sort(sortingUtil.getNumericComparer("quantity", reverse));

    const totalQuantity = sortedSalesData.reduce((sum, item) => sum + item.quantity, 0);

    const chartData = sortedSalesData
        .slice(0, chartDataSize)
        .map(data => ({
            label: stringUtil.truncateWithEllipsis(data.caption, 35).padEnd(37, ' '),
            value: data.quantity,
            percentage: data.quantity / totalQuantity * 100
        }));

    if (sortedSalesData.length > chartDataSize)
    {
        // Data beyond chartDataSize are clubbed together as 'Others'

        const othersQuantity = sortedSalesData
            .slice(chartDataSize)
            .reduce((sum, item) => sum + item.quantity, 0);

        chartData.push({ label: 'Others'.padEnd(37, ' '), value: othersQuantity, percentage: othersQuantity / totalQuantity * 100 });
    }

    return chartData;
}

export function getPriceChartData(salesData, reverse = false, chartDataSize = 10)
{
    const sortedSalesData = [...salesData].sort(sortingUtil.getNumericComparer("retailPrice", reverse));

    const totalRetailPrice = sortedSalesData.reduce((sum, item) => sum + item.retailPrice, 0);

    const chartData = sortedSalesData
        .slice(0, chartDataSize)
        .map(data => ({
            label: stringUtil.truncateWithEllipsis(data.caption, 35).padEnd(37, ' '),
            value: data.retailPrice,
            percentage: data.retailPrice / totalRetailPrice * 100
        }));

    if (sortedSalesData.length > chartDataSize)
    {
        // Data beyond chartDataSize are clubbed together as 'Others'

        const othersRetailPrice = sortedSalesData
            .slice(chartDataSize)
            .reduce((sum, item) => sum + item.retailPrice, 0);

        chartData.push({ label: 'Others'.padEnd(37, ' '), value: othersRetailPrice, percentage: othersRetailPrice / totalRetailPrice * 100 });
    }

    return chartData;
}