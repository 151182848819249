const columnWidthConstants = require("../../../../../utils/constants/columnWidthConstants");
const dataTypeConstants = require("../../../../../utils/constants/dataTypeConstants");
const stringUtil = require("../../../../../utils/string/stringUtil");
const currentOrgNodeSelectors = require("../../../../../utils/state/stateSelectors/currentOrgNodeSelectors");
const catalogSelectors = require("../../../../../utils/state/stateSelectors/catalogSelectors");
const rmsApiProxy = require("../../../../../utils/api/rmsApiProxy");
const constants = require("../../../../../utils/domain/constants");
const validator = require("../../../../../utils/validator/validator");

export function getGridColumns()
{
    return [
        {
            field: "name",
            headerName: "Name",
            pinned: true,
            width: columnWidthConstants.name,
        },
        {
            field: "displayName",
            headerName: "Display Name",
            width: columnWidthConstants.name,
        },
        {
            field: "type",
            headerName: "Type",
            width: columnWidthConstants.type,
        },
        {
            field: "target",
            headerName: "Target",
            width: columnWidthConstants.type,
        },
        {
            field: "scheme",
            headerName: "Scheme",
            width: columnWidthConstants.type,
        },
        {
            field: "value",
            headerName: "Value",
            width: columnWidthConstants.number,
        },
        {
            field: "vatPercentage",
            headerName: "VAT Percentage",
            width: columnWidthConstants.percentage,
        },
        {
            field: "isAutoApplicable",
            headerName: "Auto Applicable",
            type: dataTypeConstants.boolean,
            width: columnWidthConstants.boolean,
        },
        {
            field: "canBeRemoved",
            headerName: "Removable",
            type: dataTypeConstants.boolean,
            width: columnWidthConstants.boolean,
        },
        {
            field: "appliedBeforeVat",
            headerName: "Calculated Before Applying VAT",
            type: dataTypeConstants.boolean,
            width: columnWidthConstants.boolean,
        },
        {
            field: "appliedBeforeDiscount",
            headerName: "Applied Before Discount",
            type: dataTypeConstants.boolean,
            width: columnWidthConstants.boolean,
        },
        {
            field: "isDisabled",
            headerName: "Disabled",
            type: dataTypeConstants.boolean,
            width: columnWidthConstants.boolean,
        },
        {
            field: "selectedVenueCodeOptions",
            headerName: "Venues",
            width: columnWidthConstants.name,
            valueGetter: ({data}) =>
            {
                if (data.selectedVenueCodeOptions.length === 0)
                {
                    return "All";
                }
                return data.selectedVenueCodeOptions.map(venueOpt => venueOpt.label).join(", ");
            }
        },
        {
            field: "selectedChannelOptions",
            headerName: "Channels",
            width: columnWidthConstants.name,
            valueGetter: ({data}) =>
            {

                if (data.selectedChannelOptions.length === 0)
                {
                    return "All";
                }
                return data.selectedChannelOptions.map(channelOpt => channelOpt.label).join(", ");
            }

        },
        {
            field: "selectedStorefrontOptions",
            headerName: "Storefronts",
            width: columnWidthConstants.name,
            valueGetter: ({data}) =>
            {
                if (data.selectedStorefrontOptions.length === 0)
                {
                    return "All";
                }
                return data.selectedStorefrontOptions.map(storefrontOpt => storefrontOpt.label).join(", ");

            }
        },
        {
            field: "selectedDispatchTypeOptions",
            headerName: "Dispatch Types",
            width: columnWidthConstants.name,
            valueGetter: ({data}) =>
            {
                if (data.selectedDispatchTypeOptions.length === 0)
                {
                    return "All";
                }
                return data.selectedDispatchTypeOptions.map(dispatchTypeOpt => dispatchTypeOpt.label).join(", ");
            }
        },
        {
            field: "selectedPropertyOptions",
            headerName: "Properties",
            width: columnWidthConstants.name,
            valueGetter: ({data}) =>
            {
                if (data.selectedPropertyOptions.length === 0)
                {
                    return "All";
                }
                return data.selectedPropertyOptions.map(propertyOpt => propertyOpt.label).join(", ");
            }
        },
    ];
}

export function getEmptyCharge()
{
    return {
        name: "",
        displayName: "",
        type: null,
        target: null,
        scheme: null,
        value: null,
        vatPercentage: 0,
        isDisabled: false,

        isAutoApplicable: true,
        canBeRemoved: true,
        appliedBeforeVat: true,
        appliedBeforeDiscount: true,
        selectedVenueCodeOptions: [],
        selectedChannelOptions: [],
        selectedDispatchTypeOptions: [],
        selectedStorefrontOptions: [],
        selectedPropertyOptions: [],
    }
}

export async function getStorefrontOptions()
{
    const storefronts = await rmsApiProxy.get(`${rmsApiProxy.getFranchisorOrgContextUrl()}/storefronts`);
    return storefronts.map(storefront => ({ label: storefront.name, value: storefront.id }));
}

export async function getCharges()
{
    return await rmsApiProxy.get(`${rmsApiProxy.getFranchisorOrgContextUrl()}/charge/all`);
}

export async function postCharge(chargeData)
{
    const resourceUrl = `${rmsApiProxy.getFranchisorOrgContextUrl()}/charge`;
    return await rmsApiProxy.post(resourceUrl, chargeData);
}

export function mapDataToRow(chargeData, storefrontOptions, propertyOptions)
{
    chargeData.selectedVenueCodeOptions = getSelectedOptions(chargeData.venueCodes, getVenueCodeOptions());
    chargeData.selectedChannelOptions = getSelectedOptions(chargeData.channels, getChannelOptions());
    chargeData.selectedDispatchTypeOptions = getSelectedOptions(chargeData.dispatchTypes, getDispatchTypeOptions());
    chargeData.selectedStorefrontOptions = getSelectedOptions(chargeData.storefrontIds, storefrontOptions);
    chargeData.selectedPropertyOptions = getSelectedOptions(chargeData.propertyIds, propertyOptions);
}

function getSelectedOptions(ids, records)
{
    const data = [];
    ids.forEach(id =>
    {
        const entity = records.find(record => record.value === id);
        if (entity)
        {
            data.push(entity);
        }
    })
    return data;
}

export function mapRowToData(row)
{
    row.venueCodes = row.selectedVenueCodeOptions.map(venueCodeOpt => venueCodeOpt.value);
    delete row.selectedVenueCodeOptions;
    row.channels = row.selectedChannelOptions.map(channelOpt => channelOpt.value);
    delete row.selectedChannelOptions;
    row.dispatchTypes = row.selectedDispatchTypeOptions.map(dispatchTypeOpt => dispatchTypeOpt.value);
    delete row.selectedDispatchTypeOptions;
    row.storefrontIds = row.selectedStorefrontOptions.map(storefrontOpt => storefrontOpt.value);
    delete row.selectedStorefrontOptions;
    row.propertyIds = row.selectedPropertyOptions.map(propertyOpt => propertyOpt.value);
    delete row.selectedPropertyOptions;
}

export function getPropertyOptions()
{
    const properties = currentOrgNodeSelectors.selectAllProperties();
    return properties.map(property => ({ label: property.name, value: property.id }));
}

export function getDispatchTypeOptions()
{
    const dispatchTypes = constants.getDispatchTypes();
    return dispatchTypes.map(dt => ({ label: dt.dispatchType, value: dt.dispatchType }));
}

export function getChannelOptions()
{
    const channels = constants.getChannels();
    return channels.map(ch => ({ label: ch.channel, value: ch.channel }));
}

export function getVenueCodeOptions()
{
    const venues = catalogSelectors.selectLookupData().venues;
    return venues.map(venue => ({ label: venue.code, value: venue.code }));
}

export function getChargeSchemeOptions()
{
    return Object.keys(constants.Charges.schemes).map(key => (
        {
            label: constants.Charges.schemes[key].replace(".", " "),
            value: constants.Charges.schemes[key]
        })
    );
}

export function getChargeTargetOptions()
{
    return Object.keys(constants.Charges.targets).map(key => (
        {
            label: stringUtil.capitalize(constants.Charges.targets[key]),
            value: constants.Charges.targets[key]
        })
    );
}
export function getChargeTypeOptions()
{
    return Object.keys(constants.Charges.types).map(key => (
        {
            label: stringUtil.capitalize(constants.Charges.types[key]),
            value: constants.Charges.types[key]
        })
    );
}

export function getBooleanOptions()
{
    return [{ label: "Yes", value: true }, { label: "No", value: false }]
}

export function validateCharge(isNewRow, rowData)
{
    if (stringUtil.isStringNullOrEmpty(rowData.name))
    {
        return "Charge name cannot be empty";
    }

    if (stringUtil.isStringNullOrEmpty(rowData.displayName))
    {
        return "Charge display name cannot be empty";
    }

    if (stringUtil.isStringNullOrEmpty(rowData.value))
    {
        return "Please specify charge value";
    }

    if (!validator.isNumeric(rowData.value))
    {
        return "Charge value must be a number";
    }

    if (parseFloat(rowData.value) <= 0)
    {
        return "Charge value must be greater than 0";
    }

    if (stringUtil.isStringNullOrEmpty(rowData.vatPercentage))
    {
        return "Please specify charge VAT percentage";
    }

    if (!validator.isNumeric(rowData.vatPercentage))
    {
        return "Charge VAT percentage must be a number";
    }

    if (parseFloat(rowData.vatPercentage) < 0)
    {
        return "Charge VAT percentage cannot be a negative number";
    }

    if (stringUtil.isStringNullOrEmpty(rowData.scheme))
    {
        return "Please select a charge scheme";
    }

    if (stringUtil.isStringNullOrEmpty(rowData.type))
    {
        return "Please select a a charge type";
    }

    if (stringUtil.isStringNullOrEmpty(rowData.target))
    {
        return "Please select a charge target";
    }

    if (typeof rowData.isDisabled !== "boolean")
    {
        return "Please select if charge is disabled or not";
    }

    if (typeof rowData.isAutoApplicable !== "boolean")
    {
        return "Please select if charge is auto applicable";
    }

    if (typeof rowData.canBeRemoved !== "boolean")
    {
        return "Please select if charge is removable";
    }

    if (typeof rowData.appliedBeforeVat !== "boolean")
    {
        return "Please select if charge can be applied before VAT";
    }

    if (typeof rowData.appliedBeforeDiscount !== "boolean")
    {
        return "Please select if charge can be applied before discount";
    }

    return null;
}