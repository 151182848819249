
import React, { Component } from 'react';
import formManager from '../../../../../utils/view/formManager';
import InputComponent from '../../../../../components/form/inputComponent';

export default class StripeReaderCreditCardSettingsComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.formManager = new formManager();
        this.formManager.viewModel = this.props.creditCardSettings;
        this.formManager.view = this;
    }

    render()
    {
        return <div style={{ margin: "0px 10px" }}>
            <table className="component-table">
                <tbody>
                    <tr>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Location Id"
                                fieldName="locationId"
                                placeholder="Enter Location Id"
                                hintText="Enter Location Id provided by card provider (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                        <td>
                            <InputComponent
                                inputType="text"
                                caption="Reader Id"
                                fieldName="readerId"
                                placeholder="Enter Terminal Id"
                                hintText="Enter Terminal Id provided by card provider (Required)"
                                inputReadOnly={this.props.isReadOnly}
                                formManager={this.formManager} />
                        </td>
                    </tr>
                </tbody>
            </ table>
        </div>
    }
}