import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export default function SimpleTooltip(props)
{
    const { placement = "bottom", type = "dark", target, text } = props;

    const style = type == "dark"
        ? { background: 'rgba(55, 48, 46, 1)', color: 'white', zIndex: 100 }
        : { background: "white", color: "black", zIndex: 100 }

    return <UncontrolledTooltip key={target} target={target} placement={placement} style={style}>
        {text ? text : props.children}
    </UncontrolledTooltip>
}