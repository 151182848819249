import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import CloseButton from '../../../../../components/button/closeButton';
import SearchBarComponent from '../../../../../components/form/searchBarComponent';
import Spinner from '../../../../../components/spinner/spinner';
import ErrorMessage from '../../../../../components/error/errorMessage';
import CardView from '../../../../../components/cardView/CardView';
import TableView from '../../../../../components/tableView/TableView/TableView';

import BrandWiseTabbedReport from '../../../reportUtils/components/BrandWiseTabbedReport';
import { allBrandTotal } from '../../../reportUtils/constants';

import * as domainConstants from '../../../../../utils/domain/constants';
import * as stringUtil from '../../../../../utils/string/stringUtil';
import * as apiLoadFacade from '../../../../../utils/api/apiLoadFacade';
import * as reportUtils from './utils/dailySummaryReportUtils';

class DailySummaryReport extends Component
{
    constructor(props)
    {
        super(props);
        this.state = this.defaultState;
    }

    get defaultState()
    {
        return {
            searchCriteria: null,
            reportData: null,
            brands: null,
            showBrandWise: false,
            error: null,
            isLoading: false
        }
    };

    render()
    {
        let component;

        const { reportData, isLoading, error } = this.state;

        if (isLoading) 
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <Spinner text="Loading data, please wait ..." />
            </div>
        }
        else if (error)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <ErrorMessage message={`Error occurred while loading data:\n${error}`} />
            </div>
        }
        else if (reportData == null)
        {
            component = <div className='my-3 d-flex justify-content-center align-items-center'>
                <div className='fs-4'>Please select a date to view session summary data</div>
            </div>
        }
        else
        {
            component = this.renderReportData();
        }

        return (
                <Card>

                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <SearchBarComponent
                            searchSingleDate={true}
                            disabled={isLoading}
                            onSearch={this.onSearch} />
                        <div className='fs-5 fw-bold'>Daily Summary Report</div>
                        <CloseButton disabled={isLoading} />
                    </CardHeader>

                    <CardBody className='flex-grow-1 h-100 p-0'>
                        {component}
                    </CardBody>

                </Card>
        );
    }

    renderReportData()
    {
        return <BrandWiseTabbedReport noPadding={true} brands={this.state.brands} getReport={this.getReport} />
    }

    getReport = (brand) =>
    {
        const reportData = this.getBrandData(brand);

        const dataTables = this.getDataTables(reportData, brand);

        return (
            <div className='d-flex flex-column m-3'>
                {
                    dataTables.map((tableData, index) =>
                        <div key={`${tableData.field}-${index}`} className='mb-4'>
                            <CardView title={tableData.title} footNotes={tableData.footNotes}>
                                <TableView tableData={tableData} />
                            </CardView>
                        </div>)
                }
            </div>
        );
    }

    getDataTables(reportData, brand)
    {
        const dataTables = [];

        const availableFields = reportUtils.availableFields;

        const { currentSales, currentFloatSessions } = reportData;

        const doneSales = currentSales.filter(x => !x.isCancelled && x.saleType === domainConstants.saleTypes.sale);
        const cancelledSales = currentSales.filter(x => x.isCancelled && x.saleType === domainConstants.saleTypes.sale);

        this.templateElements.forEach(templateElement => 
        {
            switch (templateElement.field)
            {
                case availableFields.salesSummary:
                    dataTables.push(reportUtils.getSalesSummaryTableData(doneSales, templateElement));
                    break;

                case availableFields.salesByVenueCode:
                    dataTables.push(reportUtils.getSalesByVenueCodeTableData(doneSales, templateElement));
                    break;

                case availableFields.salesByDispatchType:
                    dataTables.push(reportUtils.getSalesByDispatchTypeTableData(doneSales, templateElement));
                    break;

                case availableFields.salesByPaymentMethod:
                    dataTables.push(reportUtils.getSalesByPaymentMethodTableData(doneSales, templateElement));
                    break;

                case availableFields.salesByMenuCategory:
                    dataTables.push(reportUtils.getSalesByMenuCategoryTableData(doneSales, templateElement));
                    break;

                case availableFields.salesByCovers:
                    dataTables.push(reportUtils.getSalesByCoversTableData(doneSales, templateElement));
                    break;

                case availableFields.saleDiscounts:
                    dataTables.push(reportUtils.getSaleDiscountsTableData(doneSales, templateElement));
                    break;

                case availableFields.saleCharges:
                    dataTables.push(reportUtils.getSaleChargesTableData(doneSales, templateElement));
                    break;

                case availableFields.saleRefunds:
                    dataTables.push(reportUtils.getSaleRefundsTableData(doneSales, templateElement));
                    break;

                case availableFields.saleCancellations:
                    dataTables.push(reportUtils.getSaleCancellationTableData(cancelledSales, templateElement));
                    break;

                case availableFields.saleVats:
                    dataTables.push(reportUtils.getSaleVatTableData(doneSales, templateElement));
                    break;

                case availableFields.floatSession:
                    if(brand !== allBrandTotal)
                        break;

                    const journalTemplateElement = this.templateElements.find(element => element.field === availableFields.floatSessionJournal);

                    currentFloatSessions.forEach((floatSession, index) =>
                    {
                        dataTables.push(reportUtils.getFloatSessionTableData(floatSession, index, doneSales, templateElement, this.staffData));

                        if (journalTemplateElement)
                        {
                            // Show floatSessionJournal summary for the current loop floatSession
                            dataTables.push(reportUtils.getFloatSessionJournalTableData(floatSession, index, doneSales, journalTemplateElement, this.staffData, this.categoriesData));
                        }
                    });
                    break;

                case availableFields.floatSessionJournal:
                    // Handled by case allFieldTypes.floatSession
                    break;

                case availableFields.saleDeletedItems:
                    dataTables.push(reportUtils.getDeletedItemsTableData(doneSales, templateElement))
                    break;

                default:
                    throw new Error(`Unknown Daily Summary Report fieldType = '${templateElement.field}'`);
            }
        });

        return dataTables;
    }

    onSearch = async (searchCriteria) =>
    {
        this.loadData(searchCriteria);
    }

    loadData = async (searchCriteria) =>
    {
        this.setState(Object.assign(this.defaultState, { searchCriteria, isLoading: true }));

        try
        {
            //
            // One-time loading of reference data
            //

            if (!this.categoriesData)
            {
                this.categoriesData = await apiLoadFacade.loadAccountCategoriesAndSubCategories();
                if (!this.categoriesData) throw new Error("No account categories data found");
            }

            if (!this.staffData)
            {
                this.staffData = await apiLoadFacade.loadStaff();
                if (!this.staffData) throw new Error("No staff data found");
            }

            if (!this.templateElements) 
            {
                this.templateElements = await reportUtils.getTemplateElements();
                if (!this.templateElements) throw new Error("No printing template found for Daily Summary Report");
            }
            
            //
            // Loading reportData by searchCriteria
            //

            const reportData = await reportUtils.loadReportData(searchCriteria);

            this.setState({ reportData: reportData, brands: this.getBrands(reportData) });
        }
        catch (error) 
        {
            this.setState({ error });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    getBrands(reportData)
    {
        const { currentSales } = reportData;
        return [...new Set(currentSales.map(x => x.storefrontBrand))];
    }

    getBrandData(brand)
    {
        let { currentSales, currentFloatSessions } = this.state.reportData;

        if (brand == null)
        {
            // Implies sales where no brand info is available
            currentSales = currentSales.filter(sale => stringUtil.isStringNullOrEmpty(sale.storefrontBrand));
        }
        else if (brand !== allBrandTotal)
        {
            currentSales = currentSales.filter(sale => stringUtil.areStringSame(brand, sale.storefrontBrand));
        }

        return { currentSales, currentFloatSessions };
    }
}

export default DailySummaryReport;