import React from 'react';
import { Button, Col, Row, Badge } from 'reactstrap';
import DateRangeComponent from '../form/dateRangeComponent';

function PreviousNextDateRangeHeader({ data, isNew,
    onSaveClicked, onNextClicked, onPreviousClicked,
    isNextEnabled = () => { return true },
    isPreviousEnabled = () => { return true },
    isReadOnly=false })
{
    return <div style={{ display: 'flex'}}>
                <div style={{ flex: 1 }}>
                    {renderLeftHandHeader(data, isNew, onNextClicked, onPreviousClicked, isNextEnabled, isPreviousEnabled, isReadOnly)}
                </div>
                <div style={{ flex: 1 }}>
                    {renderRightHandHeader(onSaveClicked, isReadOnly)}
                </div>
            </div>
}

function renderRightHandHeader(onSaveClicked, isReadOnly)
{
    return <Button color="success" className="btn-success float-end"  disabled={isReadOnly} onClick={onSaveClicked}>
        <i className="fa fa-save"></i>&nbsp; Save
        </Button>;
}

function renderLeftHandHeader(data, isNew, onNextClicked, onPreviousClicked, isNextEnabled, isPreviousEnabled, isReadOnly)
{
    const mode = isNew ? "New" : "Edit";

    return <table style={{ marginLeft: '-15px' }}>
        <tbody>
            <tr>
                <td>
                    <Button color="link" style={{ marginRight: '3px' }}
                        onClick={onPreviousClicked}
                        disabled={!isPreviousEnabled()}
                    >
                        <i className="icon-arrow-left icons"></i>
                    </Button>
                </td>
                <td>
                    <DateRangeComponent disabled={true} startDate={data.fromDate} endDate={data.toDate} />
                </td>
                <td>
                    <Button color="link" style={{ marginRight: '3px' }}
                        onClick={onNextClicked}
                        disabled={!isNextEnabled()}
                    >
                        <i className="icon-arrow-right icons"></i>
                    </Button>
                </td>
                <td>
                    <Badge color="secondary">{mode}</Badge>
                </td>
            </tr>
        </tbody>
    </table>;
}

export default PreviousNextDateRangeHeader;