
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import InputComponent from '../../../../../components/form/inputComponent';
import TokenStringsInputComponent from '../../../../../components/form/tokenStringsInputComponent';


const FormManager = require('../../../../../utils/view/formManager');

class StockItemSupplierQuestion extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            formManager: new FormManager()
        }

        this.state.formManager.viewModel = this.props.data;
        this.state.formManager.view = this;
    }

    render()
    {
        return <Row className="align-items-top" style={{ paddingTop: '5px', paddingBottom: '15px' }}>
            <Col lg="5">
                <InputComponent
                    inputReadOnly={this.props.isRowReadOnly}
                    inputType="text"
                    multiLine={true}
                    caption="Question" rows={4}
                    fieldName="question"
                    placeholder="Enter the question"
                    hintText="Specify question to check about stock item"

                    formManager={this.state.formManager} />
            </Col>
            <Col lg="5">
                <TokenStringsInputComponent
                    caption="Responses"
                    dataProperty="answers"
                    isRowReadOnly={this.props.isRowReadOnly}
                    hintText="Specify possible responses to the question"
                    minHeight="100px"
                    formManager={this.state.formManager} />
            </Col>

            <Col lg="2" style={{ marginTop: '15px' }}>
                {
                    this.props.isRowReadOnly
                        ? null
                        : <Button color="primary" className="btn-danger" style={{ marginTop: '-10px' }}
                            onClick={() => { this.props.onRemove(this.props.data) }}>
                            <i className="fa fa-remove"></i>&nbsp;Remove
                        </Button>
                }
            </Col>
        </Row>
    }
}

export default StockItemSupplierQuestion;