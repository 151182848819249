
import React, { Component } from 'react';
import { Row, Button } from 'reactstrap';
import GridComponent from '../../../../../components/grid/gridComponent'

import SupplierServiceProfile from './SupplierServiceProfile'

const arraySort = require('array-sort');

const typeUtil = require('../../../../../utils/type/typeUtil');
const viewUtil = require('../../../../../utils/view/viewUtil');
const guidUtil = require('../../../../../utils/guid/guidUtil');
const stringUtil = require('../../../../../utils/string/stringUtil');

const catalogSelectors = require('../../../../../utils/state/stateSelectors/catalogSelectors');

const columnWidthConstants = require('../../../../../utils/constants/columnWidthConstants');
const dataTypeConstants = require('../../../../../utils/constants/dataTypeConstants');

class SupplierServiceProfiles extends Component 
{
    constructor(props) 
    {
        super(props);

        this.onGridReady = this.onGridReady.bind(this);
        this.findSelectedRow = this.findSelectedRow.bind(this);

        this.getRows = this.getRows.bind(this);
        this.constructGridColumnHeaders = this.constructGridColumnHeaders.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.onAddServiceProfile = this.onAddServiceProfile.bind(this);
        this.validateServiceProfile = this.validateServiceProfile.bind(this);
        this.addEditServiceProfile = this.addEditServiceProfile.bind(this);
        this.saveServiceProfile = this.saveServiceProfile.bind(this);
    }

    render()
    {
        let buttonPanels = null;

        if (!this.props.isRowReadOnly)
        {
            buttonPanels = <Row>
                <table width="100%">
                    <tr>
                        <td align="right">
                            <Button
                                color="primary"
                                className="me-1"
                                onClick={this.onAddServiceProfile}>
                                <i className="fa fa-plus"></i>&nbsp;Add Service Profile
                            </Button>
                        </td>
                    </tr>
                </table>
            </Row>
        }

        return (
            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                {buttonPanels}
                <Row style={{ marginTop: '10px' }}>
                    <GridComponent
                        rows={this.getRows()}
                        headers={this.constructGridColumnHeaders()}
                        onRowDoubleClicked={this.onRowDoubleClicked}
                        onRowSelected={this.onRowSelected}
                        shouldReArrangeHeaders={true}
                        onGridReady={this.onGridReady} />
                </Row>
            </div>)
    }

    onGridReady(params)
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({});
    }

    onRowSelected(eventData)
    {
        this.setState({});
    }

    findSelectedRow()
    {
        if (this.gridApi == null)
            return null;

        var selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes == null || selectedNodes.length <= 0)
            return null;


        return selectedNodes[0].data;
    }

    onAddServiceProfile()
    {
        var serviceProfile = {};
        serviceProfile.id = guidUtil.generateGuid();
        this.addEditServiceProfile(serviceProfile);
    }

    onRowDoubleClicked(eventData)
    {
        const selectedRow = this.findSelectedRow();
        if (selectedRow == null)
            return;

        const serviceProfile = this.props.data.serviceProfiles.find(sp => sp.id == selectedRow.id);
        if (serviceProfile != null)
            this.addEditServiceProfile(typeUtil.deepCloneObject(serviceProfile));
    }

    addEditServiceProfile(serviceProfile)
    {
        viewUtil.openModalForm(
            "Service Profile",
            (onFieldChanged) =>
            {
                return (
                    <SupplierServiceProfile
                        data={serviceProfile}
                        serviceProfiles={this.props.serviceProfiles}
                        onFieldChanged={() => onFieldChanged()}
                        isRowReadOnly={this.props.isRowReadOnly}
                    />);
            },
            () => { this.saveServiceProfile(serviceProfile) },
            () => { return this.validateServiceProfile(serviceProfile) },
            this.props.isRowReadOnly)
    }

    saveServiceProfile(serviceProfile)
    {
        this.enrichServiceProfile(serviceProfile);
        this.props.data.serviceProfiles = this.props.data.serviceProfiles.filter(sp => sp.id != serviceProfile.id);
        this.props.data.serviceProfiles.push(serviceProfile);
        this.setState({});
    }

    enrichServiceProfile(serviceProfiles)
    {
        if (!Array.isArray(serviceProfiles)) serviceProfiles = [serviceProfiles];

        const serviceTypes = catalogSelectors.selectLookupData().serviceTypes;

        serviceProfiles.forEach(serviceProfile =>
        {
            if (!stringUtil.isStringNullOrEmpty(serviceProfile.serviceTypeId))
            {
                const serviceType = serviceTypes.find(x => x.id === serviceProfile.serviceTypeId);
                serviceProfile.serviceType = serviceType.name;
            }
        });
    }

    validateServiceProfile(serviceProfile)
    {
        if (stringUtil.isStringNullOrEmpty(serviceProfile.serviceTypeId))
            return "Supplier serviceProfile.serviceTypeId cannot be empty";

        const existingServiceProfile =
            this.props.data.serviceProfiles.find(
                existingServiceProfile =>
                    existingServiceProfile.id != serviceProfile.id &&
                    existingServiceProfile.serviceTypeId == serviceProfile.serviceTypeId);

        if (existingServiceProfile)
            return "Similar service profile already exists.";

        return null;
    }

    getRows()
    {
        //Make a new array 
        const serviceProfiles = Array.from(this.props.data.serviceProfiles);
        this.enrichServiceProfile(serviceProfiles);
        return arraySort(serviceProfiles, "serviceType");
    }

    constructGridColumnHeaders()
    {
        var headers = [];

        var header = {};
        header.field = "id";
        header.headerName = "Id";
        header.type = dataTypeConstants.id;
        headers.push(header);

        header = {};
        header.field = "serviceType";
        header.headerName = "Service Type";
        header.pinned = true;
        header.width = columnWidthConstants.name;
        headers.push(header);

        header = {};
        header.field = "notes";
        header.headerName = "Notes";
        header.type = dataTypeConstants.notes;
        headers.push(header);

        return headers;
    }
}

export default SupplierServiceProfiles;