
import { element } from 'prop-types';
import React, { Component } from 'react';
import InputComponent from '../../../../../components/form/inputComponent.js';
import * as previewUtils from './previewUtils';

const viewUtil = require('../../../../../utils/view/viewUtil');
const FormManager = require('../../../../../utils/view/formManager');
const stringUtil = require('../../../../../utils/string/stringUtil');
const commonElementUtil = require('../../../../../utils/template/commonElementUtil');

export default class ReceiptDiscountsGridElement extends Component 
{
    constructor(props) 
    {
        super(props);
    }

    render()
    {

        var separatorLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.separatorLineCharacter))
        {
            var lineText = "";
            for (var index = 0; index < 100; index++)
                lineText = `${lineText}${this.props.element.separatorLineCharacter}`;

            separatorLineComponent = <div style={{ width: '100%', whiteSpace: 'nowrap', textOverflow: '', overflow: 'hidden', padding: '0px', margin: '0px' }}> {lineText}</div>
        }

        var newLineComponent = null;
        if (!stringUtil.isStringNullOrEmpty(this.props.element.newLine))
        {
            newLineComponent = <div style={{ width: '100%', minHeight: `${10 * this.props.element.newLine}px`, padding: '0px', margin: '0px' }}></div>
        }

        return <div style={{ width: '100%', padding: '0px', margin: '0px' }}
            onDoubleClick={() =>
            {
                var clonedElement = JSON.parse(JSON.stringify(this.props.element));
                viewUtil.openModalForm(element.title, () =>
                {
                    return <DialogueBox element={clonedElement} />
                },
                    () =>
                    {
                        this.props.element.separatorLineCharacter = clonedElement.separatorLineCharacter;
                        this.props.element.newLine = stringUtil.isStringNullOrEmpty(clonedElement.newLine) ? 0 : clonedElement.newLine * 1;
                        this.setState({});
                    },
                    () =>
                    {
                        return commonElementUtil.isValidElement(clonedElement);
                    })
            }}>

            <div>
                <div className="justify-content-center fw-bold">Discounts</div>
                {previewUtils.getDashSeparator()}
                <div className="d-flex flex-row justify-content-start">
                    <div className="mx-2 text-start flex-grow-1">Discount Code</div>
                    <div>Amount</div>
                </div>
                {previewUtils.getDashSeparator()}
                
                <div className="d-flex flex-row justify-content-start">
                    <div className="mx-2 text-start flex-grow-1">MONDAY BLUES</div>
                    <div>{previewUtils.getLocalAmount(45)}</div>
                </div>
                <div className="d-flex flex-row justify-content-start">
                    <div className="mx-2 text-start flex-grow-1">MEMBER CARD</div>
                    <div>{previewUtils.getLocalAmount(103)}</div>
                </div>
                
                {previewUtils.getDashSeparator()}
            </div>
            {separatorLineComponent}
            {newLineComponent}
        </div>;
    }


}


class DialogueBox extends Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {};
        this.state.formManager = new FormManager();
        this.state.formManager.viewModel = this.props.element;
        this.state.formManager.view = this;
    }

    render()
    {

        return <table style={{ width: '100%' }}>
            <tr>
                <td>
                    <InputComponent
                        style={{ marginRight: '5px' }}
                        inputType="text"
                        caption="Line Separator Character"
                        hintText="Line Separator Character"
                        fieldName="separatorLineCharacter"
                        placeholder="Enter line Separator Character"
                        formManager={this.state.formManager} />
                </td>
                <td>
                    <InputComponent
                        style={{ marginLeft: '5px' }}
                        inputType="text"
                        caption="Number Of Empty Lines"
                        hintText="Number Of Empty Lines"
                        fieldName="newLine"
                        placeholder="Enter number Of Empty Lines"
                        formManager={this.state.formManager} />
                </td>
            </tr>
        </table>;
    }
}
