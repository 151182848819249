import React from 'react';

const flipdishTheme = require('../../../../utils/domain/flipdishTheme');

export default function ActivationSuccess(props)
{
    const { localizationSettings } = props;

    return (
        <div className='mt-3 d-flex flex-column'>

            <div className='fw-bolder text-center' style={{ color: `${flipdishTheme.color}`, fontSize:'1.5em'}}>Congratulations</div>
            <div className='mt-5 mb-4 text-center'>Your selected station has been activated successfully.</div>

            <div className='mt-2 mb-4 p-3 lead bg-white rounded' style={{ fontSize: "0.95em", color: `${flipdishTheme.color}` }}>
                Flipdish stations are designed to power restaurants to optimal operations and
                efficient customer services. We hope your new station will help you achieve
                both design goals to your satisfaction.
            </div>

            <div className='mt-5 d-flex flex-column align-items-center' style={{ marginLeft: "-10px", marginRight: "-10px", fontSize: "0.90em", color: `${flipdishTheme.color}` }}>
                <div>For any help, please contact our customer service</div>
                <div><i className='fa fa-phone' />&nbsp;&nbsp;{localizationSettings.supportPhone}</div>
                <div><i className='fa fa-envelope-o' />&nbsp;&nbsp;{localizationSettings.supportEmail}</div>
            </div>

        </div>
    );

}