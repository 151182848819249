
import React, { Component } from 'react';
var viewUtil = require('../../utils/view/viewUtil.js');

class GridViewAddEditContainerComponent extends Component 
{
    render()
    {
        return(<div>
                    {this.props.childComponent}
               </div>)
    }


    componentWillUnmount()
    {
        if (this.props.instructions.onUnloadHideAlert)
            viewUtil.clearAlert();
    }

}

export default GridViewAddEditContainerComponent;