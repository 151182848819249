import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

import * as arraySort from 'array-sort';

import * as biUtils from '../../../../../../utils/bi/biUtils';
import * as chartUtils from '../../../../../../utils/bi/chartUtils';
import * as stringUtil from '../../../../../../utils/string/stringUtil';
import * as fixedCostSummaryViewUtil from '../../viewUtils/fixedCostSummaryViewUtil';

const { formatCurrencyValue: formatCurrency, formatPercentage } = biUtils;

export default function ChartReport(props)
{
    return <AgChartsReact options={getOptions(props)} />;
}

function getOptions(props)
{
    const options = chartUtils.getDefaultPieChartOptions();

    options.title = { text: 'Fixed Cost Breakdown (%)', fontSize: 18, };
    options.subtitle = { text: 'Top 5 Fixed Cost breakdown by category', };

    options.autoSize = true;
    options.padding = { top: 30 };

    options.series[0].data = getData(props);
    options.series[0].calloutLabelKey = 'label';
    options.series[0].sectorLabelKey = 'percentage';
    options.series[0].angleKey = 'percentage';

    options.series[0].calloutLabel.enabled = false;
    options.series[0].sectorLabel.formatter = ({ datum, sectorLabelKey }) => formatPercentage(datum.percentage);

    options.series[0].tooltip.renderer = ({ datum, color, calloutLabelKey, sectorLabelKey }) => [
        `<div style="background-color: ${color}; padding: 4px 8px; border-top-left-radius: 5px; border-top-right-radius: 5px; font-weight: bold; color: white;">${datum.label}</div>`,
        `<div style="padding: 4px 8px">${formatCurrency(datum.value)} (${formatPercentage(datum.percentage)})</div>`,
    ].join('\n');

    return options;
}

function getData(props)
{
    const summaryData = fixedCostSummaryViewUtil.getFixedCostSummaryData(props);
    const sortedSummaryData = arraySort(summaryData, (a, b) => b.amount - a.amount);    // descending by amount

    const totalValue = sortedSummaryData.reduce((value, data) => value + data.amount, 0);

    const topFive = sortedSummaryData.slice(0, 5);
    const chartData = topFive.map(data => ({ label: data.category, value: data.amount, percentage: data.amount / totalValue * 100 }));

    if (topFive.length < sortedSummaryData.length)
    {
        const othersTotal = sortedSummaryData.slice(5).reduce((value, data) => value + data.amount, 0);
        chartData.push({ label: 'Others', value: othersTotal, percentage: othersTotal / totalValue * 100 });
    }

    return arraySort(chartData, stringUtil.localeCompareProp('label'));
}
