const datadogLogger = require('@datadog/browser-logs');
const guidUtil = require('../guid/guidUtil');
const stringUtil = require('../string/stringUtil');
const currentOrgNodeSelectors = require('../state/stateSelectors/currentOrgNodeSelectors');
const catalogSelectors = require('../state/stateSelectors/catalogSelectors');
const passportSelectors = require('../state/stateSelectors/passportSelectors');

module.exports.createTracingContext = function(url, verb)
{
    var tracingContext = {};
    tracingContext.originTraceId = guidUtil.generateGuid();

    try
    {
        var currentContext = datadogLogger.datadogLogs.getGlobalContext();
        tracingContext.originService = "RMS.APP.BACKOFFICE";
        tracingContext.originVersion = currentContext.version;
        tracingContext.originEnv = currentContext.env;
        tracingContext.originShellType = currentContext.shellType;
        tracingContext.originRenderingMode = currentContext.renderingMode;
        tracingContext.originSessionId = currentContext.sessionId;
        tracingContext.originUrl = url;
        tracingContext.originVerb = verb;
        
        tracingContext.originUserFullName = passportSelectors.selectFullName();
        tracingContext.originUserEmailAddress = passportSelectors.selectEmailAddress();

        var orgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
        if (currentOrgNodeSelectors.isCurrentFranchisorSelected())
        {
            var franchisor = catalogSelectors.selectFranchisor(orgNode.franchisorId);
            tracingContext.franchisorId = franchisor.id;
            tracingContext.franchisorCode = franchisor.code;
            tracingContext.franchisorName = stringUtil.sanitizeName(franchisor.name);
        }

        if (currentOrgNodeSelectors.isCurrentFranchiseeSelected())
        {
            var franchisor = catalogSelectors.selectFranchisor(orgNode.franchisorId);
            tracingContext.franchisorId = franchisor.id;
            tracingContext.franchisorCode = franchisor.code;
            tracingContext.franchisorName = stringUtil.sanitizeName(franchisor.name);

            var franchisee = catalogSelectors.selectFranchisee(orgNode.franchiseeId);
            tracingContext.franchiseeId = franchisee.id;
            tracingContext.franchiseeCode = franchisee.code;
            tracingContext.franchiseeName = stringUtil.sanitizeName(franchisee.name);
        }

        if (currentOrgNodeSelectors.isCurrentPropertySelected())
        {
            var franchisor = catalogSelectors.selectFranchisor(orgNode.franchisorId);
            tracingContext.franchisorId = franchisor.id;
            tracingContext.franchisorCode = franchisor.code;
            tracingContext.franchisorName = stringUtil.sanitizeName(franchisor.name);

            var franchisee = catalogSelectors.selectFranchisee(orgNode.franchiseeId);
            tracingContext.franchiseeId = franchisee.id;
            tracingContext.franchiseeCode = franchisee.code;
            tracingContext.franchiseeName = stringUtil.sanitizeName(franchisee.name);

            var property = catalogSelectors.selectProperty(orgNode.propertyId);
            tracingContext.propertyId = property.id;
            tracingContext.propertyCode = property.code;
            tracingContext.propertyName = stringUtil.sanitizeName(property.name);
        }
    }
    catch(error)
    {

    }
    return tracingContext;
}