

export var storefronts = [];
export var signagePlayers = [];
export var kioskSkins = [];

export var shells = [];
export var terminals = [];
export var stationsViewModels = [];

export var currentStationViewModel = null;
export var integrations = new Map();

export var receiptPrinters = [];
export var labelPrinters = [];

const arraySort = require('array-sort');
const configurationManager = require('../../../utils/config/configurationManager');
const stringUtil = require('../../../utils/string/stringUtil');
const currentOrgNodeSelectors = require('../../../utils/state/stateSelectors/currentOrgNodeSelectors');
const orgSelectors = require('../../../utils/state/stateSelectors/orgSelectors');
const terminalTypeConstants = require('../../../utils/constants/terminalTypeConstants');
const stationUtil = require('./stationUtil');
const shellConstants = require('../../../utils/constants/shellConstants');
const guidUtil = require('../../../utils/guid/guidUtil');

export async function initializeGlobalState()
{
    let results = await Promise.all([stationUtil.loadStorefronts(), stationUtil.loadSignagePlayers(), stationUtil.loadKioskSkins()]);
    storefronts = results[0];
    signagePlayers = results[1];
    kioskSkins = results[2];
}

export async function initializeShellsAndTerminalsState()
{
    let results = await Promise.all([stationUtil.loadShells(), stationUtil.loadTerminals()]);
    shells = results[0];
    terminals = results[1];
    
    integrations = [];
    createStationsViewModels();
    
    if (currentStationViewModel != null)
    {
        currentStationViewModel = stationsViewModels.find (s => stringUtil.areStringSame(s.licenseKey, currentStationViewModel));
        if (currentStationViewModel != null)
            await setCurrentStationViewModelAndLoadIntegrations(currentStationViewModel);
    }
}

export function createStationsViewModels()
{
    stationsViewModels = [];
    
    shells.forEach(shell =>
    {
        if (shell.terminals == null || shell.terminals.length <= 0)
            return;
            
        var stationViewModel = {};
        stationViewModel.shell = shell;
        stationViewModel.isExisting = true;
        stationViewModel.terminals = [];

        for (var index = 0; index < shell.terminals.length; index++)
        {
            var terminal = terminals.find(t => stringUtil.areStringSame(t.id, shell.terminals[index]));
            if (terminal == null)
                continue;

            terminal.isExisting = true;
            stationViewModel.terminals.push(terminal);

            if (terminal.type == terminalTypeConstants.pos)
            {
                stationViewModel.stationType = terminalTypeConstants.pos;
                stationViewModel.title = `${shell.name}`;
                stationViewModel.hints = `[${shell.shellType}]`;
                stationViewModel.image = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.pos.png`;
                stationViewModel.sortingIndex = 1;
            }

            if (terminal.type == terminalTypeConstants.kiosk)
            {
                stationViewModel.stationType = terminalTypeConstants.kiosk;
                stationViewModel.title = `${shell.name}`;
                stationViewModel.hints = `[${shell.shellType}]`;
                stationViewModel.image = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.kiosk.png`;
                stationViewModel.sortingIndex = 2;
            }

            if (terminal.type == terminalTypeConstants.kds)
            {
                stationViewModel.stationType = terminalTypeConstants.kds;
                stationViewModel.title = `${shell.name}`;
                stationViewModel.hints = `[${shell.shellType}]`;
                stationViewModel.image = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.kds.png`;
                stationViewModel.sortingIndex = 3;
            }

            if (terminal.type == terminalTypeConstants.kitchen)
            {
                stationViewModel.stationType = terminalTypeConstants.kitchen;
                stationViewModel.title = `${shell.name}`;
                stationViewModel.hints = `[${shell.shellType}]`;
                stationViewModel.image = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.kitchen.png`;
                stationViewModel.sortingIndex = 4;
            }

            if (terminal.type == terminalTypeConstants.centralKitchenKds)
            {
                stationViewModel.stationType = terminalTypeConstants.centralKitchenKds;
                stationViewModel.title = `${shell.name}`;
                stationViewModel.hints = `[${shell.shellType}]`;
                stationViewModel.image = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.central.kitchen.png`;
                stationViewModel.sortingIndex = 5;
            }

            if (terminal.type == terminalTypeConstants.signage)
            {
                stationViewModel.stationType = terminalTypeConstants.signage;
                stationViewModel.title = `${shell.name}`;
                stationViewModel.hints = `[${shell.shellType}]`;
                stationViewModel.image = `app/back.office/menu/${configurationManager.getConfig().themeVersion}/station.signage.png`;
                stationViewModel.sortingIndex = 6;
            }
        }

        if (stationViewModel.stationType != null)
        stationsViewModels.push(stationViewModel);
    });

    stationsViewModels = arraySort(stationsViewModels, ['sortingIndex', 'title']);
}

export function generateNewStationViewModel()
{
    var stationViewModel = {};
    stationViewModel.isExisting = false;
    stationViewModel.shell = {};
    stationViewModel.terminals = [];
    stationViewModel.shell.code = generateStationCode();
    stationViewModel.shell.name = ""
    stationViewModel.shell.licenseKey = guidUtil.generateGuid();
    stationViewModel.shell.isEnabled = true;

    stationViewModel.stationType = terminalTypeConstants.pos;
    stationViewModel.shell.shellType = shellConstants.shellTypes.android;
    stationViewModel.shell.shellRenderingMode = shellConstants.shellRenderingMode.desktop;
    stationViewModel.shell.shellScreenOrientation = shellConstants.shellScreenOrientation.landscapeLeft;

    stationViewModel.shell.machineIPAddress = "";
    stationViewModel.shell.machineId = "";
    stationViewModel.shell.operatingSystem= "";
    stationViewModel.shell.operatingSystemVersion = "";

    return stationViewModel;
}

export async function setCurrentStationViewModelAndLoadIntegrations(stationViewModel)
{
    currentStationViewModel = stationViewModel;
    await loadIntegrations();
}

export function setCurrentStationViewModel(stationViewModel)
{
    currentStationViewModel = stationViewModel;
}
export async function loadIntegrations()
{
    integrations = await stationUtil.loadIntegrations(currentStationViewModel);
}

export async function loadPrinters()
{
    let results = await Promise.all([stationUtil.loadReceiptPrinters(), stationUtil.loadLabelPrinters()]);
    receiptPrinters = results[0];
    labelPrinters = results[1];
}

export function generateStationCode()
{
    var currentOrgNode = currentOrgNodeSelectors.selectCurrentOrgNode();
    const franchisor = orgSelectors.selectFranchisor(currentOrgNode.franchisorId);
    const property = orgSelectors.selectProperty(currentOrgNode.propertyId);

    return `${franchisor.code.toLowerCase()}.${property.code.toLowerCase()}.${Date.now()}.station`;
}