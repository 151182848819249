import React, { Component } from 'react';
import { CloudinaryContext, Image } from 'cloudinary-react';
import ErrorMessage from '../../../components/error/errorMessage';

import GreetingMessage from './components/GreetingMessage';
import LoadingView from './components/LoadingView';
import PropertySelection from './components/PropertySelection';
import StationSelection from './components/StationSelection';
import ActivationSuccess from './components/ActivationSuccess';
import ContinueButton from './components/ContinueButton';
import CurrentPageIndicator from './components/CurrentPageIndicator';

const logger = require('../../../utils/logger/logger');
const rmsApiProxy = require('../../../utils/api/rmsApiProxy');
const FormManager = require('../../../utils/view/formManager');
const configurationManager = require('../../../utils/config/configurationManager');
const stringUtil = require('../../../utils/string/stringUtil');
const flipdishTheme = require('../../../utils/domain/flipdishTheme');
const shellConstants = require('../../../utils/constants/shellConstants');
const securityConstants = require('../../../utils/domain/security/securityConstants');
const securityManager = require('../../../utils/domain/security/securityManager');

const stateManager = require('../../../utils/state/stateManager');

const cloudName = configurationManager.getConfig().cdnAppCloudName;

class StationActivationWizard extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            franchisorId: null,
            localizationSettings: null,
            selectedProperty: null,
            selectedStation: null,
            franchisors: null, franchisees: null, properties: null, stations: null,
            wizardError: null, loginError: null,
            pageIndex: 0, stepSize: 6, stepIndex: 0,
            showSpinner: false,
        }

        this.formManager = new FormManager();
        this.formManager.viewModel = { userName: null, password: null }
        this.formManager.view = this;
        this.formManager.onFieldChanged = () => this.setState({ loginError: null });
    }

    componentDidMount()
    {
        this.loginUser();
    }

    onContinue = () =>
    {
        const { pageIndex, stepIndex } = this.state;

    if (pageIndex === 1)
        {
            if (this.state.properties.length === 1)
            {
                // Select lone property by default
                this.setState({ selectedProperty: this.state.properties[0], pageIndex: 3, stepSize: 3, stepIndex: 0 });
                return;
            }
        }

        this.setState({ pageIndex: pageIndex + 1, stepIndex: stepIndex + 1 })
    }

    onError = (error) =>
    {
        this.setState({ wizardError: error });
    }

    isContinueEnabled = () =>
    {
        const { pageIndex, showSpinner } = this.state;

        if (showSpinner === true) return false;

        switch (pageIndex) 
        {
            case 1:
                // loadOrganizationData
                return false;

            case 2:
                return this.state.selectedProperty != null;

            case 3:
                // loadStationData
                return false;

            case 4:
                return this.state.selectedStation != null;

            case 5:
                // activateStation
                return false;

            default:
                break;
        }

        return true;
    }

    render()
    {
        let component;

        const {
            wizardError, loginError,
            pageIndex, stepSize, stepIndex,
            selectedProperty, properties,
            stations, selectedStation,
            showSpinner } = this.state;

        if ((loginError || wizardError) != null) 
        {
            component = <ErrorMessage message={loginError || wizardError} />
        }
        else
        {
            switch (pageIndex)
            {
                case 0:
                    component = <GreetingMessage/>
                    break;

                case 1:
                    this.loadOrganizationData();
                    component = <LoadingView mainMessage="Loading organization data" subMessage="Please wait, this may take a while ..." />
                    break;

                case 2:
                    component = <PropertySelection
                        selectedProperty={selectedProperty}
                        properties={properties}
                        onSelectProperty={selectedProperty => this.setState({ selectedProperty: selectedProperty })} />
                    break;

                case 3:
                    this.loadStationData();
                    component = <LoadingView mainMessage="Loading station data" subMessage="Please wait, this may take a while ..." />
                    break;

                case 4:
                    component = <StationSelection
                        properties={properties}
                        stations={stations}
                        selectedStation={selectedStation}
                        onSelectStation={station => this.setState({ selectedStation: station })} />
                    break;

                case 5:
                    this.activateStation();
                    component = <LoadingView mainMessage="Activating selected station" subMessage="Please wait, this may take a while ..." />
                    break;

                case 6:
                    component = <ActivationSuccess localizationSettings={this.state.localizationSettings} />
                    break;

                default:
                    component = <div>Unknown pageIndex = {pageIndex}</div>
                    break;
            }
        }

        return (
            <div style={{ fontFamily: "'Roboto', 'Segoe UI', 'Arial', 'Helvetica',", fontSize: "1.0rem", background: "#F6F9FF", height: "100%", width: "100%" }}>

                <div className='h-100 px-4 d-flex flex-column justify-content-between' >

                    <div className='mt-5 position-relative'>

                        {
                            // Back button
                            (pageIndex === 4 && properties?.length > 1) &&
                            <div
                                className='fw-bold'
                                style={{ top: "10px", fontSize: "20px", position: "absolute", color: `${flipdishTheme.color}` }}
                                onClick={() => this.setState({
                                    stations: null, selectedStation: null,
                                    selectedProperty: null,
                                    pageIndex: 2, stepIndex: 2
                                })}>
                                <i className='fa fa-chevron-left' />
                            </div>
                        }

                        <div className='text-center'>
                            {this.renderLogo()}
                        </div>

                    </div>

                    <div className='w-100 d-flex' style={{ minHeight: "55%" }}>

                        {component}

                    </div>

                    <div>
                        {
                            pageIndex < 6 &&
                            <div className='d-flex' style={{marginBottom:'100px'}}>
                                <ContinueButton
                                    pageIndex={pageIndex}
                                    showSpinner={showSpinner}
                                    disabled={!this.isContinueEnabled()}
                                    onClick={this.onContinue} />
                                <div className='w-50'>
                                    <CurrentPageIndicator stepSize={stepSize} stepIndex={stepIndex} />
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>
        );
    }

    renderLogo()
    {
        return (
            <CloudinaryContext cloudName={cloudName}>
                <Image publicId={flipdishTheme.logoImageUrl} width="45%" />
            </CloudinaryContext>
        );
    }

    isLocalEnvironment = () =>
    {
        return configurationManager.getConfig().environment === "local";
    }

    loginUser = async () =>
    {
        this.setState({ showSpinner: true, loginError: null });

        try 
        {    
            let passport = null;
            try
            {
                if(this.isLocalEnvironment())   
                {
                    passport = await rmsApiProxy.get(`core/system/security/authenticateRMSCookie`);
                }
                else
                {
                    passport = await rmsApiProxy.get(`core/system/security/authenticateCookie`);
                }
            }
            catch (error)
            {
                if(this.isLocalEnvironment())   
                {
                    throw new Error("Failed to authenticate user. Please login to http://localhost:5391 first"); 
                }
                else
                {
                    const currentUrl = window.location.href;
                    window.location.href = `${configurationManager.getConfig().singleSignOnUrl}/login?redirectUrl=${encodeURIComponent(currentUrl)}`;
                    return;
                }        
            }

            if (!securityManager.isValidPassport(passport)) 
            {
                throw new Error("Failed to authenticate user");
            }

            if (!passport.roles.some(role => role.franchisorId != null))
            {
                throw new Error("Organization login is required to activate station");
            }

            const franchisorId = passport.roles.find(role => role.franchisorId != null).franchisorId;

            if (!passport.roles.some(role =>
                role.franchisorId === franchisorId &&
                (role.roleCode === securityConstants.roleCodes.Owner || role.roleCode === securityConstants.roleCodes.StoreOwner)))
            {
                // Required roles = Owner or StoreOwner
                throw new Error("Franchisor or property admin rights required to activate station");
            }

            // Required for rmsApiProxy to work properly
            stateManager.getStore().getState().passport = passport;

            this.setState({
                franchisorId: franchisorId,
                passport: passport,
                pageIndex: this.state.pageIndex + 1,
                stepIndex: this.state.stepIndex + 1
            });
        }
        catch (error) 
        {
            this.setState({ loginError: error });
        }
        finally
        {
            this.setState({ showSpinner: false });
        }
    }

    loadOrganizationData = async () =>
    {
        try 
        {
            const { franchisorId, pageIndex, stepIndex } = this.state;

            const localizationSettings = await rmsApiProxy.get(`core/organizations/${franchisorId}/localizations`);

            let { franchisors, franchisees, properties } =
                await rmsApiProxy.get(`core/organizations?franchisorId=${franchisorId}`);

            properties = this.getPropertiesFilteredByPassport(properties);

            if (properties.length === 0)
            {
                throw new Error("No stores exists for the current login");
            }

            if (properties.length === 1) 
            {
                this.setState({
                    localizationSettings,
                    franchisors, franchisees, properties,
                    selectedProperty: properties[0],
                    pageIndex: pageIndex + 2, stepIndex: stepIndex + 2
                });
            }
            else
            {
                this.setState({
                    localizationSettings,
                    franchisors, franchisees, properties,
                    selectedProperty: null,
                    pageIndex: pageIndex + 1, stepIndex: stepIndex + 1
                });
            }
        }
        catch (error)
        {
            this.setState({ wizardError: `Failed to fetch organization data with following error:\n${error}` });
        }
    }

    loadStationData = async () =>
    {
        try 
        {
            const { selectedProperty, pageIndex, stepIndex } = this.state;
            const { franchisorId, franchiseeId, id: propertyId } = selectedProperty;

            const promises = [];
            promises.push(rmsApiProxy.get(`core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/shells`));
            promises.push(rmsApiProxy.get(`core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/terminals`));

            const [shells, terminals] = await Promise.all(promises);

            const stations = [];

            shells
                .filter(shell => shell.isEnabled === true)
                .forEach(shell =>
                {
                    if (shell.terminals.length === 0) return;

                    const terminal = terminals.find(terminal =>
                        terminal.type !== "hardware" &&
                        terminal.enabled === true &&
                        shell.terminals.includes(terminal.id));

                    if (terminal == null) return;

                    stations.push({
                        shell: shell,
                        type: terminal.type,
                        isActivated: stringUtil.isStringNullOrEmpty(shell.machineId) || stringUtil.isStringNullOrEmpty(shell.machineIPAddress)
                    });
                });

            // stations.length === 0 is handled by StationSelect

            this.setState({
                stations: stations, selectedStation: null,
                pageIndex: pageIndex + 1, stepIndex: stepIndex + 1
            });

        } catch (error)
        {
            this.setState({ wizardError: `Failed to fetch station data with following error:\n${error}` });
        }
    }

    activateStation = async () =>
    {
        const { selectedProperty, selectedStation, pageIndex } = this.state;

        const { licenseKey } = selectedStation.shell;
        const { stationActivationCode: installationId, stationType: shellTypePassedInProps } = this.props.applicationModeData;
        const { franchisorId, franchiseeId, id: propertyId } = selectedProperty;

        logger.info(
            `StationActivationWizard: Activating station for ` +
            `franchisorId=${franchisorId}, franchiseeId=${franchiseeId}, propertyId=${propertyId}, ` +
            `shellName=${selectedStation.shell.name}, shellType=${selectedStation.shell.shellType}, licenseKey=${licenseKey}, ` +
            `stationActivationCode=${installationId}, stationType=${shellTypePassedInProps}`);

        try 
        {
            if (selectedStation.shell.shellType !== shellTypePassedInProps)
            {
                if (Object.values(shellConstants.shellTypes).includes(shellTypePassedInProps)) 
                {
                    // Implies selectedStation shellType is different from the one provided in props,
                    // existing shellType is replaced with the one from props

                    logger.warn(
                        `StationActivationWizard: Changing Operating system/shellType for ` +
                        `franchisorId=${franchisorId}, franchiseeId=${franchiseeId}, propertyId=${propertyId}, ` +
                        `shellName=${selectedStation.shell.name}, shellType=${selectedStation.shell.shellType}, licenseKey=${licenseKey}, ` +
                        `stationActivationCode=${installationId}, stationType=${shellTypePassedInProps}`);

                    const shell = selectedStation.shell;
                    shell.shellType = shellTypePassedInProps;
                    shell.machineId = "";
                    shell.machineIPAddress = "";
                    shell.operatingSystem = "";
                    shell.operatingSystemVersion = "";
                    shell.hardwareProfile = null;

                    await rmsApiProxy.post(`core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/shells`, shell);
                }
            }

            await rmsApiProxy.post(
                `core/organizations/${franchisorId}/${franchiseeId}/${propertyId}/shells/link`,
                { installationId, licenseKey });

            this.setState({ pageIndex: pageIndex + 1 });
        }
        catch (error) 
        {
            logger.error(
                `StationActivationWizard: Error occurred while station for ` +
                `franchisorId=${franchisorId}, franchiseeId=${franchiseeId}, propertyId=${propertyId}, ` +
                `shellName=${selectedStation.shell.name}, shellType=${selectedStation.shell.shellType}, licenseKey=${licenseKey}, ` +
                `stationActivationCode=${installationId}, stationType=${shellTypePassedInProps}`, null, error);

            this.setState({ wizardError: `Failed to activate station data with following error:\n${error}` });
        }
    }

    getPropertiesFilteredByPassport = (properties) =>
    {
        const { franchisorId, passport } = this.state;
        const { roles } = passport;

        if (roles.some(role => role.franchisorId === franchisorId && role.roleCode === securityConstants.roleCodes.Owner)) 
        {
            // Owner has access to all roles
            return properties;
        }

        // Next role supported is store owner (login can be store owner of several properties)

        const accessibleProperties = roles
            .filter(role => role.franchisorId === franchisorId && role.roleCode === securityConstants.roleCodes.StoreOwner)
            .map(role => role.propertyId);

        return properties.filter(property => accessibleProperties.includes(property.id));
    }
}

export default StationActivationWizard;