import React, { Component } from 'react';
import './App.scss';
import { connect } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react';

import SingleSignOnView from './views/login/SingleSignOnView';
import EnvironmentLoadingView from './views/loading/EnvironmentLoadingView';
import LoadingView from './views/loading/LoadingView';
import ContainerView from './views/container/ContainerView';

import StationActivationWizard from './views/applicationModes/stationActivationWizard/StationActivationWizard';
import Auth0View from './views/login/Auth0View';
import * as configurationManager from './utils/config/configurationManager';

const domainConstants = require('./utils/domain/constants');
const securityManager = require('./utils/domain/security/securityManager');

class App extends Component 
{
    render()
    {

      let component = null;

        if (!this.props.environmentInitialized)
        {
          component = <EnvironmentLoadingView/>
        }
        else if (this.props.applicationMode === domainConstants.applicationModes.stationActivationWizard) 
        {
            component = <StationActivationWizard applicationModeData={this.props.applicationModeData} />
        }
        else if (this.props.passport == null)
        {
          if (configurationManager.getConfig().environment === "local")
          {
              component = (
                <Auth0Provider
                  domain={configurationManager.getConfig().auth0Domain}
                  clientId={configurationManager.getConfig().portalAuth0ClientId}
                  authorizationParams={{
                    redirect_uri: window.location.origin,
                  }}
                >
                  <Auth0View />
                </Auth0Provider>
              );
          }
          else
          {
            component = <SingleSignOnView/>
          }
        }
        else if (!this.props.organizationInitialized)
        {
          component = <LoadingView/>
        }
        else
        {
            component = <ContainerView/>
        }

      return (
        <div className="App">
            {component}
        </div>
      );
    }
}
const mapStateToProps = state => 
{
    return {
        passport: state.passport,
        environmentInitialized: state.environmentInitialized,
        organizationInitialized: state.organizationInitialized,
        applicationMode: state.applicationMode,
        applicationModeData: state.applicationModeData
    }
}

export default connect(mapStateToProps)(App)
