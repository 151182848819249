import React from "react";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import humanizeDuration from "humanize-duration";
import DetailsSectionContainer from "./DetailsSectionContainer.jsx";

const stringUtil = require("../../../../../utils/string/stringUtil");
const dateUtil = require("../../../../../utils/dateUtil/dateUtil");

const backgroundColor = "#e0e0e0";

const OrderTimings = (props) => {
    const sale = props.sale;

    let actualDispatchDateTime = "N/A";
    let finalStationCompletionDateTime = "N/A";
    let originalPlannedDispatchDateTime = "N/A";
    let plannedDeliveryDateTime = "N/A";
    let actualDeliveryDateTime = "N/A";
    let actualPickupDateTime = "N/A";
    let actualDeliveryStartDateTime = "N/A";
    let expectedPlatformDateTime = "N/A";
    let deliveryCaptionSuffix = null;
    let dispatchCaptionSuffix = null;

    if (!stringUtil.isStringNullOrEmpty(sale.actualDispatchDateTime)) actualDispatchDateTime = dateUtil.convertToLocalStandard(sale.actualDispatchDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.finalStationCompletionDateTime)) finalStationCompletionDateTime = dateUtil.convertToLocalStandard(sale.finalStationCompletionDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.originalPlannedDispatchDateTime)) originalPlannedDispatchDateTime = dateUtil.convertToLocalStandard(sale.originalPlannedDispatchDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.plannedDeliveryDateTime)) plannedDeliveryDateTime = dateUtil.convertToLocalStandard(sale.plannedDeliveryDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.actualDeliveryDateTime)) actualDeliveryDateTime = dateUtil.convertToLocalStandard(sale.actualDeliveryDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.actualPickupDateTime)) actualPickupDateTime = dateUtil.convertToLocalStandard(sale.actualPickupDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.actualDeliveryStartDateTime)) actualDeliveryStartDateTime = dateUtil.convertToLocalStandard(sale.actualDeliveryStartDateTime);

    if (!stringUtil.isStringNullOrEmpty(sale.expectedPlatformDateTime)) expectedPlatformDateTime = dateUtil.convertToLocalStandard(sale.expectedPlatformDateTime);

    if (
        !stringUtil.isStringNullOrEmpty(sale.actualDispatchDateTime) &&
        !stringUtil.isStringNullOrEmpty(sale.originalPlannedDispatchDateTime) &&
        dateUtil.isBefore(sale.actualDispatchDateTime, sale.originalPlannedDispatchDateTime)
    ) {
        let differenceInMilliseconds = dateUtil.differenceInMilliseconds(sale.actualDispatchDateTime, sale.originalPlannedDispatchDateTime);
        let caption = humanizeDuration(differenceInMilliseconds, { delimiter: " and ", units: ["h", "m"], round: true });
        dispatchCaptionSuffix = `(${caption} early)`;
    } else if (
        !stringUtil.isStringNullOrEmpty(sale.actualDispatchDateTime) &&
        !stringUtil.isStringNullOrEmpty(sale.originalPlannedDispatchDateTime) &&
        dateUtil.isAfter(sale.actualDispatchDateTime, sale.originalPlannedDispatchDateTime)
    ) {
        let differenceInMilliseconds = dateUtil.differenceInMilliseconds(sale.actualDispatchDateTime, sale.originalPlannedDispatchDateTime);
        let caption = humanizeDuration(differenceInMilliseconds, { delimiter: " and ", units: ["h", "m"], round: true });
        dispatchCaptionSuffix = `(${caption} late)`;
    }

    if (!stringUtil.isStringNullOrEmpty(sale.plannedDeliveryDateTime) && !stringUtil.isStringNullOrEmpty(sale.actualDeliveryDateTime) && dateUtil.isBefore(sale.actualDeliveryDateTime, sale.plannedDeliveryDateTime)) {
        let differenceInMilliseconds = dateUtil.differenceInMilliseconds(sale.actualDeliveryDateTime, sale.plannedDeliveryDateTime);
        let caption = humanizeDuration(differenceInMilliseconds, { delimiter: " and ", units: ["h", "m"], round: true });
        deliveryCaptionSuffix = `(${caption} early)`;
    } else if (
        !stringUtil.isStringNullOrEmpty(sale.plannedDeliveryDateTime) &&
        !stringUtil.isStringNullOrEmpty(sale.actualDeliveryDateTime) &&
        dateUtil.isAfter(sale.actualDeliveryDateTime, sale.plannedDeliveryDateTime)
    ) {
        let differenceInMilliseconds = dateUtil.differenceInMilliseconds(sale.actualDeliveryDateTime, sale.plannedDeliveryDateTime);
        let caption = humanizeDuration(differenceInMilliseconds, { delimiter: " and ", units: ["h", "m"], round: true });
        deliveryCaptionSuffix = `(${caption} late)`;
    }

    var minWidth = "230px";
    return (
        <CollapsibleCard caption={`Order Timings`} isDefaultOpen={props.isDefaultOpen}>
            <table width="100%">
                <tr style={{ verticalAlign: "top" }}>
                    <td width="50%">
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Date</th>
                                <td width="49%">{dateUtil.convertToLocalStandard(sale.dateTime)}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Prep Time</th>
                                <td width="49%">{finalStationCompletionDateTime}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Pickup Time</th>
                                <td width="49%">{actualPickupDateTime}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Delivery Start Time</th>
                                <td width="49%">{actualDeliveryStartDateTime}</td>
                            </tr>

                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Requested For</th>
                                <td width="49%">{expectedPlatformDateTime}</td>
                            </tr>
                        </DetailsSectionContainer>
                    </td>
                    <td width="50%">
                        <DetailsSectionContainer>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Actual Dispatch Time</th>
                                <td width="49%">
                                    {actualDispatchDateTime}
                                    <br />
                                    {dispatchCaptionSuffix}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Expected Dispatch Time</th>
                                <td width="49%">{originalPlannedDispatchDateTime}</td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Actual Delivery Time</th>
                                <td width="49%">
                                    {actualDeliveryDateTime}
                                    <br />
                                    {deliveryCaptionSuffix}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ whiteSpace: "nowrap", minWidth: minWidth, background: backgroundColor }}>Planned Delivery Time</th>
                                <td width="49%">{plannedDeliveryDateTime}</td>
                            </tr>
                        </DetailsSectionContainer>
                    </td>
                </tr>
            </table>
        </CollapsibleCard>
    );
};

export default OrderTimings;
